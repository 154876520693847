import React, { useState, ReactElement} from "react";
import { ViewerProps } from "./types";
import { Button, CircularProgress, Grid } from "@mui/material";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { PdfBox, PdfBoxLoading, PdfToolBar } from "./styled";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import DownloadingRoundedIcon from '@mui/icons-material/DownloadingRounded';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import printJS from "print-js";
import { PDF_ZOOM_RATION, PFDJS_WORKER_BASE, PFDJS_WORKER_BASE_FILE } from "../../constants";
pdfjs.GlobalWorkerOptions.workerSrc = `${PFDJS_WORKER_BASE}/${pdfjs.version}/${PFDJS_WORKER_BASE_FILE}`;
/* istanbul ignore file */
export default function Viewer({ url, height, width }: ViewerProps): ReactElement {
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [isLoading, setIsloading] = useState(true);
  const [numPages, setNumPages] = useState(0);
  const gap = 10;
  const rotateAngle = 90;

  const zoomIn = (): void => {
    setScale(v => v + PDF_ZOOM_RATION)
  }

  const zoomOut = (): void => {
    setScale(v => v - PDF_ZOOM_RATION)
  }

  const onDocumentLoadSuccess = (pdf: pdfjs.PDFDocumentProxy): void => {
    setNumPages(pdf.numPages);
    setIsloading(false);
  }

  const onLoadError = (error: Error): void => {
    console.log(error)
    setIsloading(false);
  }

  const onRotate = (): void => {
    const newRotate = rotate + rotateAngle;
    setRotate(newRotate > 270 ? 0 : newRotate);
  }

  const onDowload = (): void => {
    const link = document.createElement("a");
    link.href = url;
    link.download = `download.pdf`;
    document.body.append(link);
    link.click();
    link.remove();
  }

  const printPdf = (): void => {
    printJS(url);
  }

  return (
    <>
      {url && (
        <>
          {isLoading &&
            <PdfBoxLoading width={width} height={height}>
              <CircularProgress size={25} color="inherit" role="loader" />
            </PdfBoxLoading>
          }

          {
            !isLoading && (
              <PdfToolBar width={width}>
                <Grid container alignItems={"center"} justifyContent="end">
                  <Grid item>
                    <Button variant="text" size="small" color="inherit" sx={{ minWidth: 10 }}>
                      <LocalPrintshopIcon onClick={printPdf}></LocalPrintshopIcon>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="text" size="small" color="inherit" sx={{ minWidth: 10 }}>
                      <DownloadingRoundedIcon onClick={onDowload}></DownloadingRoundedIcon>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="text" size="small" color="inherit" sx={{ minWidth: 10 }}>
                      <RotateRightIcon onClick={onRotate}></RotateRightIcon>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="text" size="small" color="inherit" sx={{ minWidth: 10 }}>
                      <AddCircleOutlineIcon onClick={zoomIn}></AddCircleOutlineIcon>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="text" size="small" color="inherit" sx={{ minWidth: 10 }}>
                      <RemoveCircleOutlineIcon onClick={zoomOut}></RemoveCircleOutlineIcon>
                    </Button>
                  </Grid>
                </Grid>
              </PdfToolBar>
            )
          }

          <PdfBox width={width - gap} height={height}>
            <Document file={url} onLoadSuccess={(pdf) => { onDocumentLoadSuccess(pdf) }} onLoadError={onLoadError}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} height={height} scale={scale} width={width - gap} rotate={rotate}/>
              ))}
            </Document>
          </PdfBox>

        </>
      )}
    </>

  );
}
