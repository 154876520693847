import { useCallback, useEffect } from "react";
import { getInboxStatuses } from "../api/inbox";
import { Status } from "../types/be/status";
import { InboxStatusCodes } from "../types/be/inbox";
import { useFetch } from "./useFetch";

interface UseInboxReturn {
  statusList: Status[] | null;
  isLoading: boolean;
  isError: boolean;
  findInboxStatus: (query: InboxStatusCodes | number | string) => Status | void;
}

const useInboxStatus = (): UseInboxReturn => {
  const { data, run, isLoading, isError } = useFetch<Status[]>();

  useEffect(() => {
    run(getInboxStatuses());
  }, []);

  const findInboxStatus = useCallback(
    (query: InboxStatusCodes | number | string) =>
      data?.find((item) => [item.code, item.id].includes(query)),
    [data]
  );

  return {
    statusList: data,
    isLoading,
    isError,
    findInboxStatus,
  };
};

export default useInboxStatus;
