import React, { ReactElement, useState } from "react";
import {
  Button,
  ButtonsWrapper,
  DownloadIconStyled,
  HoverMask,
  HoverMaskWrapper,
  ImageWrapper,
  DeleteIcon,
  PreviewImage,
  ImageThumbnail,
  FileName,
  FileNameWrapper,
  DialogTitleStyled,
  PreviewImageDialog,
} from "./styled";
/* istanbul ignore next */
const SimpleImageViewer = ({
  width,
  height,
  url,
  downloadLink,
  onDelete,
  name,
  isDisabled = false,
}: {
  width?: string;
  height?: string;
  name?: string;
  url: string;
  downloadLink?: string;
  onDelete?: (url: string) => void;
  isDisabled?: boolean;
}): ReactElement => {
  const [isHovered, setIsHovered] = useState(false);
  const [isPreviewZoomed, setIsPreviewZoomed] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const handleOnHover = (): void => {
    setIsHovered(true);
  };
  const handleOnBlur = (): void => {
    setIsHovered(false);
  };

  const handlePreviewClose = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    setIsPreviewOpen(false);
    setIsPreviewZoomed(false);
  };

  const openPreview = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    setIsPreviewOpen(true);
  };

  const handleOnDownload = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    if (downloadLink) window.open(downloadLink);
  };

  const handleOnDelete = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    onDelete && onDelete(url);
  };

  const handlePreviewImageClick = (
    e: React.MouseEvent<HTMLDivElement>
  ): void => {
    e.stopPropagation();
    e.preventDefault();
    setIsPreviewZoomed((val) => !val);
  };

  return (
    <>
      <ImageWrapper
        $width={width}
        $height={height}
        variant="outlined"
        onMouseEnter={handleOnHover}
        onMouseLeave={handleOnBlur}
        onClick={openPreview}
        data-testid="image-viewer"
      >
        {isHovered && !isDisabled && (
          <>
            <ButtonsWrapper>
              {downloadLink && (
                <Button onClick={handleOnDownload}>
                  <DownloadIconStyled color={"white"} />
                </Button>
              )}
              <Button onClick={handleOnDelete} data-testid="delete-btn">
                <DeleteIcon color={"white"} />
              </Button>
            </ButtonsWrapper>
            {name && (
              <FileNameWrapper>
                <FileName>{name}</FileName>
              </FileNameWrapper>
            )}
          </>
        )}
        <HoverMaskWrapper>
          <HoverMask $isHovered={isHovered} />
        </HoverMaskWrapper>
        <ImageThumbnail src={`${url}?width=120&height=120`} alt="" />
      </ImageWrapper>
      <PreviewImageDialog
        $isZoomed={isPreviewZoomed}
        onClose={handlePreviewClose}
        open={isPreviewOpen}
      >
        <DialogTitleStyled id="responsive-dialog-title">
          {name}
        </DialogTitleStyled>
        <PreviewImage
          $isZoomed={isPreviewZoomed}
          src={url}
          alt=""
          onClick={handlePreviewImageClick}
        />
      </PreviewImageDialog>
    </>
  );
};

export default SimpleImageViewer;
