import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled, { withTheme, ThemeProps } from "styled-components/macro";
import { Doughnut } from "react-chartjs-2";
import sum from "lodash/sum";

import { orange, red } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardHeader,
  Typography,
  Theme,
  CardContent as MuiCardContent,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  Skeleton,
  Grid,
  Alert,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";

import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFetch";
import { fetchRequestCounts } from "../../../api/requests";
import { RequestCountByStatus } from "../../../types/be/tenantRequest";
import { TenantRequests } from "../../../types/be/dashboard";
import { getTenantRequests } from "../../../api/dashboard";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { TableBodyRow, TableHeadRow } from "../styled";

ChartJS.register(ArcElement, Tooltip);

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

export const CardContent = styled(MuiCardContent)`
  > div > .MuiPaper-root {
    padding: ${(props): string => props.theme.spacing(0)};
  }
`;

interface DoughnutChartProps extends ThemeProps<Theme> {
  toggleTenantRequestsModal: () => void;
}

const DoughnutChart = ({
  theme,
  toggleTenantRequestsModal,
}: DoughnutChartProps): ReactElement => {
  const { t } = useTranslation();
  const { data, run } = useFetch<RequestCountByStatus>();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<TenantRequests[]>([]);
  const [unAssignRequestCount, setUnAssignRequestCount] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  const fetchList = useCallback(async () => {
    setIsLoading(true);
    const response = await getTenantRequests();
    if (response.status === HTTP_STATUS_CODES.OK) {
      const resJson = await response.json();
      setUnAssignRequestCount(resJson?.unassigned_tenant_requests_count);
      setList(resJson.data);
    } else {
      setError(t(SOMETHING_WENT_WRONG_ERROR));
    }
    setIsLoading(false);
  }, []);

  const dataArray = [data?.pending, data?.in_progress, data?.completed];
  const totalCount = useMemo(() => sum(dataArray), [dataArray]);

  const doughnutData = {
    labels: ["Pending", "In Progress", "Completed"],
    datasets: [
      {
        data: dataArray,
        backgroundColor: [theme.palette.secondary.main, red[500], orange[500]],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: "80%",
  };

  const handleSeeMore = (): void => {
    toggleTenantRequestsModal();
  };

  useEffect(() => {
    run(fetchRequestCounts());
    fetchList();
  }, []);

  return (
    <Card mb={6} sx={{ height: "100%", overflowY: "auto" }}>
      <CardHeader
        action={
          <Button
            aria-label="settings"
            size="small"
            aria-haspopup={true}
            onClick={handleSeeMore}
            color="primary"
            data-testid="tenant-requests-doughnut-icon-button"
          >
            {t("home.seeMore")}
          </Button>
        }
        title={`${t("widgets.tenantTickets")}`}
      />
      {error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <CardContent>
          <ChartWrapper>
            {!isLoading && (
              <DoughnutInner>
                <Typography variant="h4">+{totalCount}</Typography>
                <Typography variant="caption">{`${t(
                  "widgets.tenantTickets"
                )}`}</Typography>
              </DoughnutInner>
            )}
            {isLoading ? (
              <Skeleton
                variant="circular"
                width={168}
                height={168}
                sx={{ marginX: "auto" }}
              />
            ) : (
              <Doughnut data={doughnutData} options={options} />
            )}
          </ChartWrapper>
          <Grid
            container
            direction="row"
            gap={2}
            sx={{ pl: 4, fontWeight: 600 }}
          >
            <Grid>
              <Typography>
                {t("tenantRequests.unassignedTenantRequest")}:
              </Typography>
            </Grid>
            <Grid>
              <Typography>{unAssignRequestCount}</Typography>
            </Grid>
          </Grid>
          <Table sx={{ mt: 2 }}>
            {!isLoading && (
              <TableHead>
                <TableHeadRow>
                  <TableCell>
                    {t("tenantRequests.assetManagerUserName")}
                  </TableCell>
                  <TableCell>{t("tenantRequests.new")}</TableCell>
                  <TableCell>{t("tenantRequests.inProgress")}</TableCell>
                  <TableCell>{t("tenantRequests.inCoordination")}</TableCell>
                  <TableCell>{t("tenantRequests.completed")}</TableCell>
                </TableHeadRow>
              </TableHead>
            )}
            <TableBody>
              {isLoading && (
                <>
                  {new Array(4).fill(0).map((_, index) => (
                    <TableBodyRow key={index}>
                      <TableCell colSpan={5} sx={{ border: "none" }}>
                        <Skeleton width="100%" height={49} />
                      </TableCell>
                    </TableBodyRow>
                  ))}
                </>
              )}
              {list?.length > 0 &&
                list.map((row: TenantRequests, index) => (
                  <TableBodyRow key={`key-${index}`} $stripe={index % 2 === 0}>
                    <TableCell>{row?.full_name}</TableCell>
                    <TableCell>{row?.count?.pending}</TableCell>
                    <TableCell>{row?.count?.in_progress}</TableCell>
                    <TableCell>{row?.count?.in_coordination}</TableCell>
                    <TableCell>{row?.count?.completed}</TableCell>
                  </TableBodyRow>
                ))}
            </TableBody>
          </Table>
        </CardContent>
      )}
    </Card>
  );
};

export default withTheme(DoughnutChart);
