import React, { ReactElement, useState } from "react";
import { FormikValues } from "formik/dist/types";
import { Grid } from "@mui/material";
import { getUsers } from "../../../api/users";
import { User } from "../../../types/be/user";
import { useTranslation } from "react-i18next";
import Button from "../../../ui/button/Button";
import DatePicker from "../../../ui/datePicker";
import Dropdown from "../../../ui/dropdown";
import { AutocompleteOption } from "../../../ui/dropdown/types";

const Form = ({
  handleSubmit,
  setFieldValue,
  touched,
  errors,
  status,
  isSubmitting,
  onClose,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState<User>();

  const onSelectUser = async (
    id?: string,
    event?: AutocompleteOption | null
  ): Promise<void> => {
    setSelectedUser(event as User);
    setFieldValue("user_id", Number(id));
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={6} lg={6} sm={6} md={6}>
          <Dropdown
            id="user-autocomplete"
            name="user_id"
            size="medium"
            label={t("companyOwnership.employeeName")}
            placeholder={t("search")}
            getOptions={getUsers}
            onSelectOption={onSelectUser}
            optionLabel="full_name"
            optionValue="id"
            defaultValue={selectedUser || null}
            enableReInitialization
            error={Boolean(
              touched?.user_id && (status?.errors?.user_id || errors.user_id)
            )}
            helperText={
              touched?.user_id && (errors?.user_id || status?.errors?.user_id)
            }
          />
        </Grid>
        <Grid item xs={6} lg={6} sm={6} md={6}>
          <DatePicker
            name="received_date"
            required
            disableFuture
            label={t("companyOwnership.receivedOnDate")}
            status={status}
            shrink={true}
          />
        </Grid>
        <Grid
          item
          sx={{ display: "flex", justifyContent: "space-between" }}
          xs={12}
          lg={12}
          sm={12}
          md={12}
        >
          <Button
            onClick={onClose}
            title={t("cancel")}
            testId={"cancel-button"}
            variant={"text"}
            size="large"
            type="button"
          />
          <Button
            title={t("save")}
            color="success"
            type="submit"
            size="large"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
