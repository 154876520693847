import { Grid } from "@mui/material";
import { timestampToFromNow } from "../../utils/date";
import React, { ReactElement, useEffect } from "react";
import { Chat as BEChat } from "../../types/be/chat";
import useAppSelector from "../../hooks/useAppSelector";
import { ChatBodyWrapper, ChatLeftAt, ChatLeftBy, ChatWrapper } from "./styled";

/* istanbul ignore next */
const Chat = ({
  chat,
  handleChatRead
}: {
  chat: BEChat;
  handleChatRead: (chat: BEChat) => Promise<void>;
}): ReactElement => {
  const { _user } = useAppSelector((state) => state.user);

  useEffect(()=> {
    handleChatRead(chat)
  },[])
  return (
    <>
      <ChatWrapper container key={`chat-${chat.id}`} className={_user?.id === chat.user.id ? 'owner-chat': 'other-chat'}>
        <ChatBodyWrapper
          item
          xs={12}
          //TODO: don't forget to investigate how secure it is
          dangerouslySetInnerHTML={{ __html: chat.body }}
        ></ChatBodyWrapper>
        <Grid item xs={12}>
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              {chat.user && (
                <ChatLeftBy>{`${chat.user.first_name} ${chat.user.last_name}`}</ChatLeftBy>
              )}
              {!chat.user && <ChatLeftBy>{"---"}</ChatLeftBy>}
              <ChatLeftAt>
                {timestampToFromNow(`${chat.created_at}`)}
              </ChatLeftAt>
            </Grid>
          </Grid>
        </Grid>
      </ChatWrapper>
    </>
  );
};

export default Chat;
