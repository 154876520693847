import { TableCell } from "../../../../types/table";
import { ProviderInvoiceListFilter } from "./types";
import { ReactElement } from "react";
import {
  RELASE_BUTTONS_AM,
  RELASE_BUTTONS_PM,
  RELEASE_BUTTONS_FR,
  RELEASE_BUTTONS_USER,
} from "../../utils";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "property_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertyName",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "invoiceName",
  },
  {
    id: "number",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "number",
  },
  {
    id: "provider",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "provider",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "documentRelease.invoice.date",
    align: "center",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "amount",
    align: "right",
  },
  {
    id: "uploaded_on",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "offers.uploadedOn",
    align: "center",
  },
  {
    id: "latest_comment",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "comment",
    align: "left",
  },
  {
    id: "uploadedBy",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "uploadedBy",
    align: "left",
  },
  {
    id: RELEASE_BUTTONS_FR,
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "property.navLinks.finalReleaser",
    align: "center",
  },
  {
    id: RELASE_BUTTONS_AM,
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "AM",
    align: "center",
  },
  {
    id: RELASE_BUTTONS_PM,
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "PM",
    align: "center",
  },
  {
    id: RELEASE_BUTTONS_USER,
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "User",
    align: "center",
  },
  {
    id: "bic",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "optionalColumnsNames.bic",
  },
  {
    id: "iban",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "optionalColumnsNames.iban",
  },
  {
    id: "release_date",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "releaseDate",
  },
  {
    id: "is_debited_automatically",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "debit",
  },
  {
    id: "is_apportionable",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "apportionable",
  },
  {
    id: "is_active",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "active",
  },
  {
    id: "is_permanent",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "permanent",
  },
  {
    id: "contract_file_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "contractFileName",
  },
  {
    id: "offer_file_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "offerFileName",
  },
  {
    id: "due_date",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "dueDate",
    align: "center",
  },
  {
    id: "is_paid",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "documentRelease.invoice.markAsPaid",
  },
  {
    id: "is_booked",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "documentRelease.invoice.markAsBooked",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "",
  },
];
export const getCheckboxItemListHeadCell = (
  element: ReactElement
): TableCell => {
  return {
    id: "checkbox",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "checkbox",
    align: "left",
    renderElement: element,
  };
};

export const selectAllInit = false;

export const providerInvoiceListFilterInit: ProviderInvoiceListFilter = {
  contact_id: null,
};
