/* istanbul ignore file */

import React, { ReactElement } from "react";
import { RentedAndVacantSpaceSubdivisionProps } from "./types";
import { CalculationTableHead, CalculationTableTitle } from "./styled";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import {
  THOUSAND_SEPARATOR,
  DECIMAL_SEPARATOR,
  Measurement_Units,
} from "../../../../../../constants";

const SubdivisionOfRentedAndVacantSpace = ({
  commercial_not_rented_area,
  commercial_rented_area,
  commercial_area,
  living_not_rented_area,
  living_rented_area,
  living_area,
}: RentedAndVacantSpaceSubdivisionProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <CalculationTableHead>
        <CalculationTableTitle>
          {t("property.tenants.calculationResult.livingSqm")}
        </CalculationTableTitle>
      </CalculationTableHead>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          <TableRow>
            <TableCell align="left" size="small">
              {" "}
            </TableCell>
            <TableCell align="right" size="small">
              {t("property.tenants.calculationResult.vacancy")}
            </TableCell>
            <TableCell align="right" size="small">
              {t("property.tenants.calculationResult.rented")}
            </TableCell>
            <TableCell align="left" size="small">
              {" "}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" size="small">
              {t("property.tenants.calculationResult.business")}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                displayType={"text"}
                value={commercial_not_rented_area}
              />{" "}
              {commercial_not_rented_area !== null &&
                Measurement_Units.SQUARE_METER}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                displayType={"text"}
                value={commercial_rented_area}
              />{" "}
              {commercial_rented_area !== null &&
                Measurement_Units.SQUARE_METER}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                displayType={"text"}
                value={commercial_area}
              />{" "}
              {commercial_area !== null && Measurement_Units.SQUARE_METER}
            </TableCell>
          </TableRow>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell align="left" size="small">
              {t("property.tenants.calculationResult.living")}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                displayType={"text"}
                value={living_not_rented_area}
              />{" "}
              {living_not_rented_area !== null &&
                Measurement_Units.SQUARE_METER}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                displayType={"text"}
                value={living_rented_area}
              />{" "}
              {living_rented_area !== null && Measurement_Units.SQUARE_METER}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                displayType={"text"}
                value={living_area}
              />{" "}
              {living_area !== null && Measurement_Units.SQUARE_METER}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SubdivisionOfRentedAndVacantSpace;
