import React, { ReactElement, useState } from "react";
import { Formik } from "formik";
import Form from "./Form";
import useSnackbar from "../../../../../../../ui/snackbar1/useSnackbar";
import { handleUpdateEmptyAreaInformation } from "./utils";
import { Area } from "../../../../../../../types/be/area";
import { useTranslation } from "react-i18next";
import { EmptyAreaFormProps } from "../types";

const Information = ({ area, fetchArea }: EmptyAreaFormProps): ReactElement => {
  const { t } = useTranslation();
  const { snackbar, Snackbar } = useSnackbar();
  const [isEditInfo, setIsEditInfo] = useState(false);

  const afterSuccess = (): void => {
    fetchArea && fetchArea();
    setIsEditInfo(false);
  };

  const initialValues: Area = {
    ...area,
    empty_space_since: area?.empty_space_since
      ? area?.empty_space_since * 1000
      : null,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleUpdateEmptyAreaInformation(
          initialValues,
          t,
          afterSuccess,
          snackbar
        )}
        enableReinitialize
      >
        {(props) => (
          <Form
            {...props}
            snackbar={snackbar}
            isEditInfo={isEditInfo}
            setIsEditInfo={setIsEditInfo}
          />
        )}
      </Formik>
      {Snackbar}
    </>
  );
};

export default Information;
