import { TFunction } from "i18next";
import { Contract } from "../../../../types/be.interfaces";
export interface MaintenanceForm {
  description: string;
  contact_id: string;
  report_id: string;
  inspection: string;
  order_id: string;
  maintenance_type_id: string;
  contract_id: string;
}

export enum FileDialogType {
  REPORT = "report",
  ORDER = "order",
}

export interface HandleMaintenanceCreateProps {
  snackbar: {
    success: (title: string) => void;
    error: (title: string) => void;
  };
  afterCreateMaintenance: () => void;
  t: TFunction<"translation", undefined, "translation">;
  maintenanceId?: string;
}

export interface CreateContractModalProps {
  open: boolean;
  onClose: () => void;
  setContractModalValue: (contract: Contract) => void;
}
