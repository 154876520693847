import React, { ReactElement } from "react";
import { TabPageWrapperProps } from "./types";
import PageWrapper from "../pageWrapper/PageWrapper";

const TabPageWrapper = ({
  children,
  breadcrumbs,
  title,
  property,
  rightSideControls,
  wrap,
}: TabPageWrapperProps): ReactElement => {
  if (wrap) {
    return (
      <PageWrapper
        title={title}
        breadcrumbs={breadcrumbs}
        property={property}
        rightSideControls={rightSideControls}
      >
        {children}
      </PageWrapper>
    );
  }

  return children;
};

export default TabPageWrapper;
