import { Form } from "formik";
import { FormikValues } from "formik/dist/types";
import { Grid, Typography, Tooltip } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../ui/button/Button";
import { Measurement_Units } from "../../../../../../constants";
import { FormatNumber } from "../../../../../../ui/formsFields/formatNumber";
import { AreaFormProps } from "./types";
import SelectOrCreateContactsAutocompleteField from "../../../../../contacts/autocomplete";
import { formatTimestamp } from "../../../../../../utils/date";
import { AlertTriangle } from "react-feather";
import { getAreaRenters } from "../../../../../../api/area";
import { AreaRenter } from "../../../../../../types/be/area";
import {
  getRentEndDisabledDate,
  getRentEndDisabledMonth,
  getRentEndDisabledYears,
  getRentStartDisabledDate,
  getRentStartDisabledMonth,
  getRentStartDisabledYears,
} from "./utils";
import { useFetch } from "../../../../../../hooks/useFetch";
import Loader from "../../../../../Loader";
import { yellow } from "@mui/material/colors";
import DatePicker from "../../../../../../ui/datePicker";
import { filterNullTenants } from "../../../../utils";

const AddTenantForm = ({
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched,
  values,
  handleSubmit,
  isSubmitting,
  status,
  area,
  handleCreateTenantModalCancel,
}: FormikValues & AreaFormProps): ReactElement => {
  const { t } = useTranslation();
  const {
    data: renters,
    run: handleGetAreaRenters,
    isLoading,
  } = useFetch<AreaRenter[]>();

  const filterRenters = filterNullTenants(renters);

  const handleRentStartDateChange = (): ((date: Date | null) => void) => {
    return (date: Date | null): void => {
      setFieldValue("rent_end", null);
      setFieldTouched("rent_start", false);
      setFieldValue("rent_start", date);
    };
  };

  useEffect(() => {
    handleGetAreaRenters(getAreaRenters(area?.id?.toString()));
  }, []);

  return (
    <Form noValidate onSubmit={handleSubmit}>
      {isLoading || !filterRenters ? (
        <Loader />
      ) : (
        <>
          {area.future_tenant && (
            <Grid container spacing={2}>
              <Grid item>
                <AlertTriangle color={yellow[300]} />
              </Grid>
              <Grid item>
                <Typography variant="body2" sx={{ pt: 1 }}>
                  This area has a future tenant whose rent start date starts at{" "}
                  {formatTimestamp(area.future_tenant.rent_start)}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={6} md={6}>
              <DatePicker
                name="rent_start"
                label={t("property.rentStartDate")}
                status={status}
                onChangeValue={handleRentStartDateChange()}
                shouldDisableDate={getRentStartDisabledDate(filterRenters)}
                shouldDisableYear={getRentStartDisabledYears(filterRenters)}
                shouldDisableMonth={getRentStartDisabledMonth(filterRenters)}
                dataTestId={"rent-start"}
                minHeight={"60px"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Tooltip
                title={
                  !values.rent_start
                    ? t("tenant.startRentDateNeedsToBeSelected")
                    : null
                }
                placement="top"
              >
                <span>
                  <DatePicker
                    name="rent_end"
                    label={t("property.rentEnd")}
                    disabled={!values.rent_start}
                    status={status}
                    shouldDisableDate={getRentEndDisabledDate(
                      filterRenters,
                      values.rent_start
                    )}
                    shouldDisableYear={getRentEndDisabledYears(
                      filterRenters,
                      values.rent_start
                    )}
                    shouldDisableMonth={getRentEndDisabledMonth(
                      filterRenters,
                      values.rent_start
                    )}
                    dataTestId={"rent_end"}
                    minHeight={"60px"}
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <FormatNumber
                label={t("property.area.netRentPerMonth")}
                name="net_rent_per_month"
                required
                size="medium"
                status={status}
                inputProps={{
                  filter: "formatNum",
                  unit: Measurement_Units.EURO,
                  "data-testid": "net_rent_per_month",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <SelectOrCreateContactsAutocompleteField
                {...{
                  errors,
                  values,
                  touched,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                }}
                required
                label={t("tenant.contact_name")}
                placeholder={t("tenant.contact_name")}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Grid item>
              <Button
                onClick={handleCreateTenantModalCancel}
                title={t("cancel")}
                testId={"cancel-create-tenant-button"}
                variant={"text"}
                type="button"
                size="large"
              />
            </Grid>
            <Grid item>
              <Button
                title={t("create")}
                type="submit"
                sx={{ ml: "1rem" }}
                color="success"
                size="large"
                testId={"create-area-tenant"}
                disabled={isSubmitting}
                isLoading={isSubmitting}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Form>
  );
};

export default AddTenantForm;
