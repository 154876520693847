import { AssignPropertyToInboxData } from "../components/inbox/assignPropertyDialog/types";
import http from "../utils/http";
import { ContractForm, InsuranceCreate, Invoice } from "../types/fe.interfaces";
import { CreateReminderData } from "../components/inbox/list/inboxTypesSelect/createReminderModal/types";
import {
  FormRequestProps,
  CreateAttachmentFromInbox,
} from "../components/inbox/list/inboxTypesSelect/types";

export const getInboxList = async (params: string): Promise<Response> => {
  return await http.get(`/api/inboxes${params}`);
};

export const deleteInbox = async (id: number): Promise<Response> => {
  return await http.delete(`/api/inboxes/${id}`);
};

export const patchInboxStatus = async (
  inboxId: number,
  statusId: number
): Promise<Response> => {
  return await http.patch(
    `/api/inboxes/${inboxId}/status?status_id=${statusId}`,
    {}
  );
};

export const getInboxStatuses = async (): Promise<Response> => {
  return await http.get(`/api/inboxes/statuses`);
};

export const createInbox = async (data: FormData): Promise<Response> => {
  return await http.post("/api/inboxes", data);
};
/* istanbul ignore next */
export const forwardInbox = async (
  id: number,
  payload: FormData
): Promise<Response> => {
  return await http.post(`/api/inboxes/${id}/forward`, payload);
};

export const assignPropertyToInbox = async (
  id: string,
  data: AssignPropertyToInboxData
): Promise<Response> => {
  return await http.post(`/api/inboxes/${id}/properties`, data);
};

export const unassignPropertyToInbox = async (
  id: string
): Promise<Response> => {
  return await http.delete(`/api/inboxes/${id}/properties`);
};
/* istanbul ignore next */
export const updateInbox = async (
  id: number,
  data: FormData
): Promise<Response> => {
  return await http.post(`/api/inboxes/${id}`, data);
};

export const getInboxTypes = async (): Promise<Response> => {
  return await http.get("/api/inboxes/types");
};
/* istanbul ignore next */
export const createInvoiceFromInbox = async (
  id: number,
  data: Invoice
): Promise<Response> => {
  return await http.post(`/api/inboxes/${id}/invoices`, data);
};
/* istanbul ignore next */
export const createContractFromInbox = async (
  id: number,
  data: ContractForm
): Promise<Response> => {
  return await http.post(`/api/inboxes/${id}/contracts`, data);
};
/* istanbul ignore next */
export const createReminderFromInbox = async (
  id: number,
  data: CreateReminderData
): Promise<Response> => {
  return await http.post(`/api/inboxes/${id}/reminders`, data);
};
/* istanbul ignore next */
export const createAttachmentFromInbox = async (
  id: number,
  data: CreateAttachmentFromInbox
): Promise<Response> => {
  return await http.post(`/api/inboxes/${id}/attachments`, data);
};
/* istanbul ignore next */
export const createInsuranceFromInbox = async (
  id: number,
  data: InsuranceCreate
): Promise<Response> => {
  return await http.post(`/api/inboxes/${id}/insurances`, data);
};

/* istanbul ignore next */
export const assignInbox = async (
  id: number,
  data: FormRequestProps
): Promise<Response> => {
  return await http.post(`/api/inboxes/${id}/assign`, data);
};

/* istanbul ignore next */
export const reAssignInbox = async (
  id: number,
  data: FormRequestProps
): Promise<Response> => {
  return await http.post(`/api/inboxes/${id}/assign`, data);
};
