import { Search as BESearch, SearchPropertyItem as BESearchPropertyItem } from "../../types/be.interfaces";
import { SearchItem as FESearchItem } from "../../types/fe.interfaces";
import { SearchItem as BESearchItem } from "../../types/be.interfaces";

export const composeOptions = (data: BESearch): FESearchItem[] => [
  ...data.properties.map((option: BESearchPropertyItem) => ({
    ...option,
    type: propertiesSearchKey,
  })),
  ...data.contacts.map((option: BESearchItem) => ({
    ...option,
    type: contactsSearchKey,
  })),
  ...data.files.map((option: BESearchItem) => ({
    ...option,
    type: filesSearchKey,
  })),
];
export const propertiesSearchKey: keyof BESearch = "properties";
export const contactsSearchKey: keyof BESearch = "contacts";
export const filesSearchKey: keyof BESearch = "files";
