import { StringSchema } from "yup";
import { AnyObject } from "yup";
import {
  Offer as BEOffer,
  OfferTopic as BEOfferTopic,
  OfferTopic,
} from "../../../../types/be/offer";
import { ReleaseConfiguration } from "../../../../types/be/releaseFlow";
import { Status as OfferStatus, Status } from "../../../../types/be/status";
import { OfferTopic as FEOfferTopic } from "../../../../types/fe.interfaces";
import { AutocompleteOption } from "../../../../ui/dropdown/types";
import {
  Loading,
  ReleasePermission,
  handleForwardDocumentProps,
  handleReleaseDocumentProps,
} from "../../components/documentRelease/types";
import { IdPropType } from "../../../../types/models";

export enum CreateTopicForm {
  MODAL = "modal",
  LIST = "list",
}
export interface HandleTopicCreateSubmitProps {
  fetchOfferTopics: () => Promise<void>;
  setCreateOfferTopicFormMode: React.Dispatch<
    React.SetStateAction<CreateTopicForm | null>
  >;
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

interface DocumentReleaseProps {
  configuration: ReleaseConfiguration[] | null;
  handleReleaseDocument: (
    props: handleReleaseDocumentProps
  ) => void | Promise<void>;
  handleForwardDocument: (
    props: handleForwardDocumentProps
  ) => void | Promise<void>;
  releasePermission: ReleasePermission;
  isDocumentReleaseLoading: Loading | null;
}

export interface OfferListProps extends DocumentReleaseProps {
  useOnWidget?: boolean;
  offers?: BEOffer[];
  statuses: OfferStatus[];
  fetchOfferTopics?: (loading?: boolean) => Promise<void>;
  offerTopic?: BEOfferTopic;
  propertyId?: string;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  configuration: ReleaseConfiguration[] | null;
  isConfigurationLoading: boolean;
  handleReleaseDocument: (
    props: handleReleaseDocumentProps
  ) => void | Promise<void>;
  handleForwardDocument: (
    props: handleForwardDocumentProps
  ) => void | Promise<void>;
  releasePermission: ReleasePermission;
  isDocumentReleaseLoading: Loading | null;
}

export interface OfferListRowProps extends DocumentReleaseProps {
  useOnWidget?: boolean;
  row: BEOffer;
  topicStatusId?: number;
  handleCellClick: (event: React.MouseEvent<HTMLTableCellElement>) => void;
  handleEditClick: ({ id }: EditOfferPropType) => void;
  handleShowClick: ({ id }: EditOfferPropType) => void;
  openConfirmToRemoveModal: (row: BEOffer) => void;
  handleOpenComments: (row: BEOffer) => void;
  fetchOfferTopics: () => Promise<void>;
  setContactShowId: React.Dispatch<React.SetStateAction<string | null>>;
  setProviderOfferListFilter?: React.Dispatch<
    React.SetStateAction<ProviderOfferListFilter>
  >;
  handleHistoryModal: (id: IdPropType) => void;
}

export interface OfferListToolbarProps extends DocumentReleaseProps {
  name: string;
  id?: string;
  topicId?: number;
  handleTopicReset: () => Promise<void>;
  openConfirmToRemoveTopicModal: () => void;
  property?: string;
  offerTopic: OfferTopic;
  fetchOfferTopics?: () => void | Promise<void>;
}

export type EditOfferPropType = {
  id: number;
};

export interface TopicListToolbarProps {
  createOfferTopicFormMode: CreateTopicForm | null;
  createTopicInitialValues: FEOfferTopic;
  fetchOfferTopics: () => Promise<void>;
  setCreateOfferTopicFormMode: React.Dispatch<
    React.SetStateAction<CreateTopicForm | null>
  >;
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  name: StringSchema<string | undefined, AnyObject>;
  handleCreateTopic: () => void;
  statuses: OfferStatus[];
  handleTableSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  propertyId?: string;
  activeStatusId: number | null;

  isGeneral: string;
  property: AutocompleteOption | null | undefined;
  defaultStatus: string;
  onPropertySelect: (
    id?: string,
    event?: AutocompleteOption | null
  ) => Promise<void>;
  handleStatusChange: (status: number) => void;
}

export interface ProviderOfferListFilter {
  contact_id?: null | number;
  fr_status_id?: number;
}

export interface ProviderOfferListToolbarProps {
  childFilter: ProviderOfferListFilter;
  setChildFilter: React.Dispatch<React.SetStateAction<ProviderOfferListFilter>>;
  statuses: Status[];
}

export interface ProviderOfferListProps {
  onClose: () => void;
  parentFilter: ProviderOfferListFilter;
  configuration: ReleaseConfiguration[] | null;
  handleForwardDocument: (
    props: handleForwardDocumentProps
  ) => Promise<void> | void;
  releasePermission: ReleasePermission;
  handleReleaseDocument: (
    props: handleReleaseDocumentProps
  ) => Promise<void> | void;
  isDocumentReleaseLoading: Loading | null;
  statuses: Status[];
  handleHistoryModal: (id: IdPropType) => void;
}
