export default {
  companies: "Companies",
  companyName: "Company",
  createCompany: "Create company",
  companyCreated: "Company created",
  createNewCompany: "Create new company",
  companyLocation: "Company location",
  companyColumnName: "Company name",
  notice: "Notes",
}
