import { Status } from "./status";
import { Property } from "./property";
import { File } from "./file";
import { Contact } from "./contact";
import { User } from "../models";

export interface GeneralDocument {
  id: number;
  status: Status;
  contact: Contact;
  property: Pick<Property, "id" | "object_name" | "asset_managers"> | null;
  creator: GeneralDocumentCreator;
  file: File;
  latest_comment: null;
  comments_count: number;
  created_at: string;
  assigned_users: User[];
  is_user_can_change_status: boolean;
}

export interface GeneralDocumentCreator {
  id: number;
  first_name: string;
  last_name: string;
}

export enum GeneralDocumentStatusCodes {
  NEW = "new",
  COMPLETED = "completed",
}
