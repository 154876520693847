/* istanbul ignore file */

import React, { ReactElement, useState } from "react";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { Formik } from "formik";
import * as Yup from "yup";
import { ForwardDocumentDialogProps } from "../list/types";
import { forwardDocumentInitialValues, handleForwardDocument } from "./utils";
import useValidation from "../../../../hooks/useValidation";
import { FormMessageInItState } from "../../../../constants";
import { StyledDialogTitle } from "../list/styled";
import CloseButton from "../../../../ui/dialog/CloseButton";
import ForwardDocumentForm from "./Form";

const ForwardDocumentDialog = ({
  isOpen,
  handleCloseForwardModal,
  entityId,
  entityType,
  setSnackbarVisible,
  title,
}: ForwardDocumentDialogProps): ReactElement => {
  const {
    subject,
    comment,
    forwardDocument: { contact_id, email, user_id },
  } = useValidation();

  const [formMessage, setFormMessage] = useState(FormMessageInItState);

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={handleCloseForwardModal}
      aria-labelledby="max-width-dialog-title"
      data-testid="forward-dialog"
    >
      <StyledDialogTitle>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        <CloseButton onClick={handleCloseForwardModal} />
      </StyledDialogTitle>
      <Formik
        initialValues={forwardDocumentInitialValues}
        validationSchema={Yup.object().shape(
          {
            contact_id,
            email,
            user_id,
            subject,
            comment,
          },
          [
            ["email", "user_id"],
            ["contact_id", "user_id"],
            ["email", "contact_id"],
          ]
        )}
        onSubmit={handleForwardDocument({
          entityId,
          entityType,
          setSnackbarVisible,
          setFormMessage,
          handleCloseForwardModal,
        })}
      >
        {(props) => (
          <>
            <ForwardDocumentForm
              {...props}
              handleCloseForwardModal={handleCloseForwardModal}
              formMessage={formMessage}
            />
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default ForwardDocumentDialog;
