import { useState, Dispatch, SetStateAction, useEffect } from "react";
import { File } from "../types/be/file";
interface DropzoneState {
  visible: boolean;
  loading: boolean;
}

interface UseUploadDropzone {
  dropzone: DropzoneState;
  setDropzone: Dispatch<SetStateAction<DropzoneState>>;
  fileName: string;
  setFileName: Dispatch<SetStateAction<string>>;
  selectedFileId?: number;
  setSelectedFileId: Dispatch<SetStateAction<number | undefined>>;
  setIsOcrLoading: Dispatch<SetStateAction<boolean>>;
  handleOpenDropzone: () => void;
  handleCloseDropzone: () => void;
  isFileTreeVisible: boolean;
  isOcrLoading: boolean;
  handleOpenFileTree: () => void;
  handleCloseFileTree: () => void;
}

interface Props {
  file?: File;
}

/* istanbul ignore next */
const useUploadDropzone = (init?: Props): UseUploadDropzone => {
  const [dropzone, setDropzone] = useState<DropzoneState>({
    visible: false,
    loading: false,
  });

  const [isFileTreeVisible, setIsFileTreeVisible] = useState(false);

  const [isOcrLoading, setIsOcrLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [selectedFileId, setSelectedFileId] = useState(init?.file?.id);

  useEffect(() => {
    init?.file?.id && setSelectedFileId(init?.file?.id);
  }, [init?.file?.id]);

  useEffect(() => {
    init?.file?.name && setFileName(init?.file?.name);
  }, [init?.file?.name]);

  const handleOpenDropzone = (): void =>
    setDropzone((prevState) => ({ ...prevState, visible: true }));

  const handleCloseDropzone = (): void =>
    setDropzone((prevState) => ({ ...prevState, visible: false }));
  /* istanbul ignore next */
  const handleOpenFileTree = (): void => setIsFileTreeVisible(true);

  const handleCloseFileTree = (): void => setIsFileTreeVisible(false);

  return {
    dropzone,
    isFileTreeVisible,
    isOcrLoading,
    setDropzone,
    fileName,
    setFileName,
    selectedFileId,
    setSelectedFileId,
    setIsOcrLoading,
    handleOpenDropzone,
    handleCloseDropzone,
    handleOpenFileTree,
    handleCloseFileTree,
  };
};

export default useUploadDropzone;
