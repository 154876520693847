import React from "react";
import roles from "./roles";
import release from "./release";
import AuthGuard from "../../components/guards/AuthGuard";
import DashboardLayout from "../../layouts/dashboard";

export default {
  path: "/:client/settings",
  element: (
    <AuthGuard>
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [roles, release],
};
