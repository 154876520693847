import { Grid } from "@mui/material";
import styled from "styled-components/macro";
import { rgba } from "polished";

export const GridItem = styled(Grid)`
  display: flex;
  &:last-child {
    border-bottom: 1px solid
      ${(props) => rgba(props.theme.palette.divider, 0.12)};
  }

  & > div {
    flex-basic: 50%;
    width: 50%;
    &:not(:last-child) {
      border-bottom: 0;
    }
  }

  .left-item {
    border: 1px solid ${(props) => props.theme.palette.divider};
    padding: ${(props) => props.theme.spacing(2)};
    font-size: ${(props) => props.theme.spacing(4)};
    display: flex;
    align-items: center;
  }
  .right-item {
    border: 1px solid ${(props) => props.theme.palette.divider};
    padding: ${(props) => props.theme.spacing(2)};
    font-size: ${(props) => props.theme.spacing(4)};
    border-left: 0;
    border-bottom: 0;
  }
`;

export const FileName = styled.div`
  max-width: ${(props): string => props.theme.spacing(30)};
  padding-right: ${(props): string => props.theme.spacing(1)};
  cursor: pointer;
  display: block;
  text-decoration: none;
  word-break: break-all;
  &:hover {
    text-decoration: underline;
  }
`;
