export const TRANSLATION_PREFIX = "bankAccount.";

export const PAYMENTS_TABS = {
  ALL_SALES: `${TRANSLATION_PREFIX}allSales`,
  PROPOSALS: `${TRANSLATION_PREFIX}proposals`,
  ASSIGNED: `${TRANSLATION_PREFIX}assigned`,
  NOT_ASSIGNED: `${TRANSLATION_PREFIX}notAssigned`,
  AUTOMATICALLY_ASSIGNED: `${TRANSLATION_PREFIX}automaticallyAssigned`,
  IGNORED: `${TRANSLATION_PREFIX}ignored`,
};

export const paymentsQueryParams: { [key: string]: string } = {
  "all-sales": "",
  proposals: "only_proposal=1",
  assigned: "is_assigned=1&is_ignored=0",
  "not-assigned": "is_assigned=0&is_ignored=0",
  "automatically-assigned": "assign_type=auto",
  ignored: "is_ignored=1",
};
