import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Alert, Button, Grid, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  deleteRequest,
  fetchRequests,
  fetchRequestStatuses,
} from "../../../../api/requests";
import { TenantRequest } from "../../../../types/be/tenantRequest";
import { Status } from "../../../../types/be/status";
import Table from "../../../../ui/table/Table";
import useTable from "../../../../ui/table/useTable";
import { itemsListHeadCells } from "./utils";
import { Search as SearchIcon } from "react-feather";
import {
  DeleteIcon,
  EditIcon,
  Input,
  Search,
  SearchIconWrapper,
  ShowIcon,
} from "../../../../ui/table/styled";
import { useFetch } from "../../../../hooks/useFetch";
import { StatusButton } from "./styled";
import { TableCell, TextEllipsis } from "../../../../styled";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { route } from "../../../../utils/url";
import { prepareQueryParams } from "../../../../utils/common";
import { handleServerError } from "../../../../utils/http";
import {
  FormMessageInItState,
  REQUEST_STATUS_IDS,
  STARTING_PAGE,
} from "../../../../constants";
import StatusButtons, { IconMap, IconColorMap } from "./StatusButtons";
import useAccessControl from "../../../../hooks/useAccessControl";

const RequestsList = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, areaId } = useParams();

  const { isRole, _user } = useAccessControl();
  const isTenant = isRole("tenant");

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    setCurrentPage,
    openConfirmToRemoveModal,
    rowToDelete,
    handleDeleteLastPageData,
  } = useTable<TenantRequest>();

  const { data: statuses, run: runStatueses } = useFetch<Status[]>();

  const [statusId, setStatusId] = useState<number | null>(null);
  const [formMessage, setFormMessage] = useState(FormMessageInItState);

  const handleFetchRequests = useCallback(async () => {
    setIsLoading(true);
    const pObj: Record<string, string> = {
      property_area_id: areaId!,
    };

    if (statusId) {
      pObj.status_id = String(statusId);
    }

    if (isRole("tenant")) {
      pObj.tenant_id = String(_user?.id);
    }

    if (isTenant) {
      pObj.tenant_id = String(_user?.id);
    }
    const params = prepareQueryParams(queryParams, pObj);
    const response = await fetchRequests(params);

    const { data, meta } = await response.json();
    if (response.status === 200) {
      setData(data);
      setTotal(meta?.total);
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }
    setIsLoading(false);
  }, [queryParams, statusId, isRole, _user]);

  const handleChangeStatus = useCallback((statusId: number | null) => {
    setStatusId(statusId);
    setCurrentPage(STARTING_PAGE);
  }, []);

  const handleRowRemove = useCallback(async () => {
    const response = await deleteRequest(rowToDelete.id);
    if (response.status === 200) {
      handleDeleteLastPageData(handleFetchRequests);
    } else {
      const { errorMessage } = handleServerError(response);
      setFormMessage({ text: errorMessage, type: "error" });
    }
    handleConfirmToRemoveModalClose();
  }, [rowToDelete, handleFetchRequests]);

  useEffect(() => {
    queryParams && handleFetchRequests();
  }, [queryParams, statusId, statuses]);

  useEffect(() => {
    runStatueses(fetchRequestStatuses());
  }, []);

  const handleEditClick = (requestId: number): void => {
    navigate(route("properties.requests.edit", id, areaId, requestId));
  };

  const TableToolbar = (
    <Grid
      container
      mt={5}
      justifyContent={"space-between"}
      alignItems={"center"}
      spacing={2}
    >
      <Grid item xs="auto">
        {isTenant && (
          <Button
            component={NavLink}
            to={route("properties.requests.create", id, areaId)}
            size="small"
            color="success"
            variant="contained"
            data-testid="add-request-button"
          >
            {t("create")}
          </Button>
        )}
      </Grid>
      <Grid item xs="auto">
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs="auto">
            {statuses ? (
              <Grid container spacing={2}>
                <Grid item>
                  <StatusButton
                    title={t("requests.all")}
                    type="button"
                    color="primary"
                    size="small"
                    disabled={statusId === null}
                    onClick={() => handleChangeStatus(null)}
                  />
                </Grid>
                {statuses?.map((status) => (
                  <Grid item key={status.code}>
                    <StatusButton
                      title={status.name}
                      type="button"
                      color="primary"
                      size="small"
                      disabled={statusId === status.id}
                      onClick={() => handleChangeStatus(status.id)}
                      testId={`status-${status.name}`}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t("search")} onChange={handleTableSearch} />
            </Search>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderRow = (row: TenantRequest): ReactElement => {
    const Icon = IconMap[row.status.code];

    return (
      <>
        <TableCell mw="120px" align="left">
          {row.title}
        </TableCell>
        <TableCell mw="200px" align="left">
          <Tooltip title={row.description} placement="bottom">
            <TextEllipsis w="250px" textAlign="left" float="left">
              {row.description}
            </TextEllipsis>
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          {isRole("admin") || isRole("asset_manager") ? (
            <StatusButtons
              requestId={row.id}
              statuses={statuses}
              selectedStatus={row.status.id}
              updateHandler={handleFetchRequests}
              setFormMessage={setFormMessage}
            />
          ) : (
            <Tooltip title={row.status.name} placement="top">
              <Button
                color={IconColorMap[row.status.code]}
                variant="contained"
                size="small"
                sx={{ minWidth: 0 }}
              >
                <Icon color="inherit" />
              </Button>
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
          <ShowIcon
            role={"showIconRole"}
            size={18}
            onClick={() =>
              navigate(route("properties.requests.show", id, areaId, row.id))
            }
          />
          <EditIcon
            role={"editIconRole"}
            size={18}
            onClick={(): void => handleEditClick(row.id)}
          />
          <DeleteIcon
            size={18}
            data-testid="delete-icon"
            onClick={(): void => {
              row.status.id === REQUEST_STATUS_IDS.PENDING &&
                openConfirmToRemoveModal(row);
            }}
            className={
              row.status.id !== REQUEST_STATUS_IDS.PENDING ? "disabled" : ""
            }
          />
        </TableCell>
      </>
    );
  };

  return (
    <>
      {formMessage.text ? (
        <Alert severity={formMessage.type} sx={{ my: 2 }}>
          {formMessage.text}
        </Alert>
      ) : null}
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        onRowRemove={handleRowRemove}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        noDataIsAvailablePlaceholder={"table.noTenantRequestsAreAvailable"}
      />
    </>
  );
};

export default RequestsList;
