import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import useTable from "../../../ui/table/useTable";
import { Property } from "../../../types/be/property";
import Table from "../../../ui/table/Table";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TableCell,
} from "@mui/material";
import { Input, Search, SearchIconWrapper } from "../../../ui/table/styled";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import useIsMounted from "../../../hooks/useIsMounted";
import { getProperties } from "../../../api/property";
import { getJson } from "../../../utils/http";
import { BankAccount as FEBankAccount } from "../../../types/fe/bankAccount";
import { getBankAccount, updateBankAccount } from "../../../api/bankAccount";
import { useParams } from "react-router-dom";
import { propertiesHeadCells } from "./utils";
import AssignCheckbox from "./AssignCheckbox";
import DialogTitle from "../../../ui/dialog/DialogTitle";

const AttachToPayment = ({
  isModalOpen,
  onCloseClick,
}: {
  isModalOpen: boolean;
  onCloseClick: () => void;
}): ReactElement => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [propertyIds, setPropertyIds] = useState<number[]>([]);
  const [bankAccount, setBankAccount] = useState<FEBankAccount | null>(null);
  const { id } = useParams();
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
  } = useTable<Property>();
  /* istanbul ignore next */
  const handleOnIsAssignedChange = (
    e: ChangeEvent<HTMLInputElement>,
    property: Property
  ): void => {
    if (e.target.checked) attachPropertyIds([...propertyIds, property.id]);
    else attachPropertyIds([...propertyIds.filter((i) => i !== property.id)]);
  };
  /* istanbul ignore next */
  const attachPropertyIds = (ids: number[]): void => {
    setPropertyIds(ids);
    if (bankAccount)
      updateBankAccount({
        ...bankAccount,
        property_id: ids,
      });
  };

  const fetchBankAccount = (): void => {
    getBankAccount(id)
      .then(getJson)
      .then(({ data }) => {
        const propertyIds = data.properties.map(
          (property: Property) => property.id
        );
        delete data.properties;
        setBankAccount({
          ...data,
          property_id: propertyIds,
        });
        setPropertyIds(propertyIds);
      });
  };
  /* istanbul ignore next */
  useEffect(() => {
    if (isMounted() && isModalOpen) {
      setIsLoading(true);
      getProperties(queryParams)
        .then(getJson)
        .then(({ data, meta }) => {
          setData(data);
          setTotal(meta.total);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, [queryParams, isModalOpen]);

  useEffect(() => {
    fetchBankAccount();
  }, [isModalOpen]);

  const renderRow = (row: Property): ReactElement => {
    return (
      <>
        <TableCell align="left">{row.object_name}</TableCell>
        <TableCell align="left" padding={"none"}>
          <Box sx={{ py: 2 }}>{`${
            row.location
              ? /* istanbul ignore next */ row.location.full_address
              : /* istanbul ignore next */ "---"
          }`}</Box>
        </TableCell>
        <TableCell align="right" padding={"none"}>
          {bankAccount && (
            <AssignCheckbox
              id={row.id}
              ids={bankAccount.property_id}
              onChange={
                /* istanbul ignore next */ (e) =>
                  handleOnIsAssignedChange(e, row)
              }
            />
          )}
        </TableCell>
      </>
    );
  };

  const TableToolbar = (
    <Grid container justifyContent={"end"}>
      <Grid item>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Input
            placeholder={`${t("search")}...`}
            onChange={handleTableSearch}
          />
        </Search>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Dialog
        role="attach-property-modal"
        open={isModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth={"lg"}
      >
        <DialogTitle
          title="bankAccount.attachPropertyToBankAccount"
          onClose={onCloseClick}
        />
        <DialogContent>
          <Table
            data={data}
            total={total}
            currentPage={currentPage}
            order={order}
            orderBy={orderBy}
            error={error}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
            onSortChange={handleSortChange}
            onSelectAllClick={handleSelectAllClick}
            onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
            isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
            listHeadCells={propertiesHeadCells}
            renderRow={renderRow}
            tableToolbar={TableToolbar}
            noDataIsAvailablePlaceholder={"table.noPropertiesAreAvailable"}
          />
        </DialogContent>
        <DialogActions sx={{ pr: 12, pb: 5, pt: 1 }}>
          <Button
            role="close-button"
            variant="text"
            size="large"
            onClick={() => onCloseClick()}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AttachToPayment;
