import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Grid,
  TableCell,
  DialogTitle,
  Typography,
  Tooltip,
} from "@mui/material";
import Table from "../../ui/table/Table";
import useTable from "../../ui/table/useTable";
import { tenantRequestsListHeadCells } from "./tenantTicketsWidget/utils";
import { handleServerError } from "../../utils/http";
import useIsMounted from "../../hooks/useIsMounted";
import { getTenantRequests } from "../../api/tenantRequest";
import { User } from "../../types/be/user";
import ManagerAutocomplete from "../tenantRequests/toolBar/filters/manager";
import PropertyTenantAutocomplete from "../tenantRequests/toolBar/filters/propertyTenant";
import {
  DescriptionContainer,
  DialogContent,
  DialogTitleContainer,
} from "./styled";
import PropertyAutocomplete from "../properties/components/propertyAutocomplete";
import Dialog from "@mui/material/Dialog";
import CloseButton from "../../ui/dialog/CloseButton";
import { useTranslation } from "react-i18next";
import { ListDialogProps } from "./types";
import { objectGetParamsToString } from "../../utils/common";
import { TenantRequest as TenantRequestType } from "../../types/be/tenantRequest";
import { formatTimestamp } from "../../utils/date";
import { EMPTY_DATA } from "../../constants";

const TenantRequestsList = ({
  toggleListModal,
  visible,
}: ListDialogProps): ReactElement => {
  const { t } = useTranslation();
  const [propertyId, setPropertyId] = useState<string | number>();
  const [tenant, setTenant] = useState<User | null>(null);
  const [manager, setManager] = useState<User | null>(null);

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    handleRowRemove,
  } = useTable<TenantRequestType>();

  const isMounted = useIsMounted();
  const fetchList = useCallback(async () => {
    setIsLoading(true);
    let params: Record<string, string | number> | string = {};
    /* istanbul ignore next */ if (propertyId)
      /* istanbul ignore next */ params["property_id"] = propertyId;
    if (manager?.id) params["manager_id"] = manager?.id;
    if (tenant?.id) params["tenant_id"] = tenant?.id;
    params = objectGetParamsToString(params);
    const paramsStr = params ? `${queryParams}&${params}` : queryParams;
    const res = await getTenantRequests(paramsStr);
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const resJson = await res.json();
      setTotal(resJson?.meta?.total);
      setData(resJson.data);
    }
    setIsLoading(false);
  }, [
    queryParams,
    setIsLoading,
    setTotal,
    setData,
    setIsLoading,
    propertyId,
    tenant,
    manager,
  ]);

  useEffect(() => {
    if (!queryParams || !visible) return;
    isMounted() && fetchList();
  }, [queryParams, propertyId, tenant, manager, visible]);
  /* istanbul ignore next */
  const onSelectProperty = (id?: string | number): void => {
    setPropertyId(id);
  };

  const handleOnManagerSelect = (user: User | null): void => {
    setManager(user);
  };

  const onPropertyTenantSelect = (user: User | null): void => {
    setTenant(user);
  };

  const tableToolbarDefault = (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={4}>
        <PropertyAutocomplete
          onSelectProperty={onSelectProperty}
          name="property_id"
        />
      </Grid>
      <Grid item xs={4}>
        <ManagerAutocomplete onManagerSelect={handleOnManagerSelect} />
      </Grid>
      <Grid item xs={4}>
        <PropertyTenantAutocomplete
          onPropertyTenantSelect={onPropertyTenantSelect}
        />
      </Grid>
    </Grid>
  );

  const renderRow = (row: TenantRequestType): ReactElement => {
    return (
      <>
        <TableCell>{`#${row?.ticket_number}`}</TableCell>
        <TableCell>{row?.title}</TableCell>
        <TableCell>
          <Tooltip title={row?.description} placement="top">
            <DescriptionContainer>{row?.description}</DescriptionContainer>
          </Tooltip>
        </TableCell>
        <TableCell>{row.status?.name?.toUpperCase()}</TableCell>
        <TableCell>{row?.property_name || EMPTY_DATA}</TableCell>
        <TableCell>{row.area_name || EMPTY_DATA}</TableCell>
        <TableCell>
          {row?.manager
            ? `${row?.manager?.first_name} ${row?.manager?.last_name}`
            : EMPTY_DATA}
        </TableCell>
        <TableCell>{row?.tenant?.full_name || EMPTY_DATA}</TableCell>
        <TableCell>{formatTimestamp(row.created_at)}</TableCell>
      </>
    );
  };

  return (
    <Dialog
      role={"tenant-requests-list-modal"}
      fullWidth
      open={visible}
      onClose={toggleListModal}
      maxWidth="xl"
    >
      <DialogTitle sx={{ pt: 0, pb: 0 }}>
        <DialogTitleContainer>
          <Typography
            variant="h6"
            component="div"
            justifyContent="space-around"
            width="100%"
          >
            {t("widgets.tenantTickets")}
          </Typography>
          <CloseButton onClick={toggleListModal} />
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        <div>
          <Table
            data={data}
            total={total}
            currentPage={currentPage}
            order={order}
            orderBy={orderBy}
            error={error}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
            onRowRemove={handleRowRemove}
            onSortChange={handleSortChange}
            onSelectAllClick={handleSelectAllClick}
            onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
            isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
            listHeadCells={tenantRequestsListHeadCells}
            renderRow={renderRow}
            tableToolbar={tableToolbarDefault}
            tableDataMaxHeight={"50vh"}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TenantRequestsList;
