import {
  BankAccount as BEBankAccount,
  BankAccount,
} from "../../../../types/be/bankAccount";
import React, { ReactElement, useState } from "react";
import { BankAccountListItem as BankAccountListItemStyled } from "../../styled";
import { Badge, Grid, Paper, Typography } from "@mui/material";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { useParams } from "react-router-dom";
import { BadgeProps } from "material-ui";
import styled from "styled-components/macro";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -7,
    top: 4,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "2px 3px 0 4px",
  },
}));

export const BankAccountListItem = ({
  bankAccount,
  onClick,
  useOnWidget,
  selectedBankAccount,
}: {
  bankAccount: BankAccount;
  onClick: () => void;
  useOnWidget: boolean;
  selectedBankAccount?: BEBankAccount;
}): ReactElement => {
  const [, setIsHovered] = useState(false);
  const { id } = useParams();
  const isActive = useOnWidget
    ? /* istanbul ignore next */ selectedBankAccount?.id === bankAccount.id
    : +id! === bankAccount.id;
  const propertiesLength = bankAccount.properties.length;
  /* istanbul ignore next */
  const getBadgeValue = (val: number): number | string =>
    val - 1 ? `+${val - 1}` : 0;

  return (
    <Paper
      onClick={onClick}
      variant="outlined"
      sx={{ mb: 2 }}
      role={"bank-account-item"}
    >
      <BankAccountListItemStyled
        role="bank-account-item-styled"
        $isSelected={isActive}
        $isDisabled={isActive}
        container
        justifyContent={"space-between"}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Grid
          item
          justifyItems={"center"}
          sx={{ display: "flex" }}
          justifyContent={"center"}
        >
          <Grid
            container
            sx={{ height: "100%" }}
            justifyItems={"center"}
            justifyContent={"center"}
            direction="column"
          >
            <Grid item>
              <Typography
                sx={{
                  lineHeight: "0.9",
                }}
                variant={
                  propertiesLength
                    ? "caption"
                    : /* istanbul ignore next */ "subtitle2"
                }
                gutterBottom
              >
                {bankAccount.bank_name}{" "}
              </Typography>
              <Typography variant={"caption"}>{bankAccount.iban}</Typography>
            </Grid>
            {!!propertiesLength && (
              <Grid item>
                <Grid
                  container
                  spacing={
                    getBadgeValue(propertiesLength)
                      ? 6
                      : /* istanbul ignore next */ 2
                  }
                >
                  <Grid item>
                    <StyledBadge
                      badgeContent={getBadgeValue(propertiesLength)}
                      color="secondary"
                    >
                      <HomeWorkIcon color={"secondary"} fontSize={"small"} />
                    </StyledBadge>
                  </Grid>
                  <Grid item>
                    <Typography color={"gray"} variant="caption" gutterBottom>
                      {bankAccount.properties[0].object_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            sx={{ height: "100%" }}
            justifyItems={"center"}
            justifyContent={"center"}
            direction="column"
          ></Grid>
        </Grid>
      </BankAccountListItemStyled>
    </Paper>
  );
};

export default BankAccountListItem;
