import styled from "styled-components/macro";
import { Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
/* istanbul ignore next */
export const AutocompleteListItem = styled.li`
  font-size: ${(props): string => props.theme.spacing(2.75)};
  font-weight: 500;
`;
/* istanbul ignore next */
export const AutocompletePropertyName = styled(Typography)``;
/* istanbul ignore next */
export const AutocompletePropertyAddress = styled(Typography)`
  font-size: ${(props): string => props.theme.spacing(3)};
  color: ${(): string => blueGrey[200]};
`;
