import { FormikValues } from "formik";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../../constants";
import { FormMessageInterface } from "../../../../../../../types/form";
import { createInsuranceTopic } from "../../../../../../../api/insuranceTopics";
import {
  getFirstDayOfYear,
  getLastDayOfYear,
  replaceMomentDateByTimestamp,
} from "../../../../../../../utils/date";
import { InsuranceCreateTopic } from "../../../../../../../types/fe.interfaces";
import { HTTP_STATUS_CODES } from "../../../../../../../types/server";
import { route } from "../../../../../../../utils/url";
import { InsuranceTopic } from "../../../../../../../types/be/insurance";

export const initCreateTopicRange = [getFirstDayOfYear(), getLastDayOfYear()];
export interface CreateTopicProps {
  setFormMessage: React.Dispatch<React.SetStateAction<FormMessageInterface>>;
  handleDialog: () => void;
  range: (Date | null)[];
  id?: string;
  fetchInsuranceTopics?: () => Promise<void>;
  inboxData?: any;
  navigate?: any;
  afterCreateTopic?: (topic?: InsuranceTopic) => void;
}

export const createTopicFormInitData: InsuranceCreateTopic = {
  from: null,
  to: null,
  type: "",
  property_id: "",
};
/* istanbul ignore next */
export const handleCreateTopic = ({
  setFormMessage,
  handleDialog,
  range,
  fetchInsuranceTopics,
  inboxData,
  navigate,
  afterCreateTopic
}: CreateTopicProps) => {
  return async (
    values: InsuranceCreateTopic,
    { setSubmitting }: FormikValues
  ): Promise<void> => {
    const payload = {
      ...values,
      property_id: values.property_id
        ? Number(values.property_id)
        : inboxData && inboxData.property
        ? inboxData.property.id
        : "",
      type_id: values.type ? 1 : 2,
      ...replaceMomentDateByTimestamp({
        from: range[0],
        to: range[1],
      }),
    };
    const response = await createInsuranceTopic(payload);
    if (response.status === HTTP_STATUS_CODES.CREATED) {
      setFormMessage({ type: undefined, text: "" });
      setSubmitting(false);
      handleDialog();
      !inboxData && fetchInsuranceTopics && (await fetchInsuranceTopics());
      const { data } = await response.json();
      afterCreateTopic && afterCreateTopic(data.data)
      inboxData &&
        navigate(route("insurances.create", data.id), {
          state: { inboxData },
        });
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setSubmitting(false);
    }
  };
};
