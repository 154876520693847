import {
  Avatar,
  Checkbox,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { CreatedAt, MessageText, NotificationBox } from "./styled";
import { formatTimestamp } from "../../../utils/date";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React, { ReactElement, useEffect, useState } from "react";
import { Notification as BENotification } from "../../../types/be/notification";
import { useTranslation } from "react-i18next";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { Error } from "../../../components/profile/tabs/generalInformation/styled";
import { DATE_TIME_FORMAT, generateUrl } from "./utils";
import { useNavigate } from "react-router-dom";
import { notificationStatusToggle } from "../utils";

export const Notification = ({
  notification,
  handleClose,
}: {
  notification: BENotification;
  handleClose: () => void;
}): ReactElement => {
  const { t } = useTranslation();
  const dispatchStore = useAppDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [error, serError] = useState<string>("");
  const [url, setUrl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    notification.data.entity && setUrl(generateUrl(notification.data?.entity));
  }, [notification]);
  /* istanbul ignore next */
  const redirectToPage = (): void => {
    !notification.read_at &&
      notificationStatusToggle({ notification, dispatchStore, serError });
    navigate(url);
    handleClose();
  };

  return (
    <NotificationBox
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-testid="notification-box"
    >
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <Grid container>
            <Grid item xs={1} sx={{ pt: 1 }}>
              <Avatar
                sx={{ width: 24, height: 24 }}
                alt="A"
                src={
                  notification.sender && notification.sender.avatar
                    ? notification.sender.avatar
                    : "/static/images/avatar/1.jpg"
                }
              />
            </Grid>
            <Grid item xs={11}>
              <MessageText>{notification.data.message}</MessageText>
              <CreatedAt>
                {formatTimestamp(notification.created_at, DATE_TIME_FORMAT)}
              </CreatedAt>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <Link onClick={/* istanbul ignore next */ () => redirectToPage()}>
                <Typography sx={{ px: 2, fontSize: "10px" }}>
                  {notification.data.sub_message}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Tooltip
            title={
              notification.read_at
                ? t("notifications.markAsUnread")
                : t("notifications.markAsRead")
            }
            placement="bottom-start"
            arrow
          >
            <Checkbox
              checked={!notification.read_at}
              data-testid="notification-mark-read"
              size={"small"}
              onClick={() =>
                notificationStatusToggle({
                  notification,
                  dispatchStore,
                  serError,
                })
              }
              icon={
                isHovered ? <DoneAllIcon sx={{ fontSize: "15px" }} /> : <></>
              }
              checkedIcon={<FiberManualRecordIcon sx={{ fontSize: "10px" }} />}
            />
          </Tooltip>
        </Grid>
        {error && <Error>{error}</Error>}
      </Grid>
    </NotificationBox>
  );
};

export default Notification;
