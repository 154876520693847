import AccessGuard from "../../components/guards/AccessGuard";
import { PermissionsRead, UserRoles } from "../../constants";
import EmployeeOverview from "../../components/employeeOverview";
import React from "react";

export default {
  path: "employee-overview",
  element: (
    <AccessGuard
      permissions={[PermissionsRead.EMPLOYEE_OVERVIEW]}
      roles={[UserRoles.ADMIN]}
    >
      <EmployeeOverview />
    </AccessGuard>
  ),
};
