import { AssignPropertyToGeneralDocumentData } from "../components/generalDocuments/list/assignPropertyDialog/types";
import { GeneralDocument } from "../types/fe/generalDocument";
import http from "../utils/http";
/* istanbul ignore next */
export const createGeneralDocument = async (
  data: FormData
): Promise<Response> => {
  return await http.post("/api/general-documents", data);
};
/* istanbul ignore next */
export const getGeneralDocument = async (id: string): Promise<Response> => {
  return await http.get(`/api/general-documents/${id}`);
};
/* istanbul ignore next */
export const getGeneralDocuments = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/general-documents${params}`);
};

/* istanbul ignore next */
export const updateGeneralDocument = async (
  id: number,
  data: FormData
): Promise<Response> => {
  return await http.post(`/api/general-documents/${id}`, data);
};
/* istanbul ignore next */
export const editGeneralDocument = async (
  id: number,
  data: Partial<GeneralDocument>
): Promise<Response> => {
  return await http.put(`/api/general-documents/${id}`, data);
};
/* istanbul ignore next */
export const updateGeneralDocumentStatus = async (
  documentId: number,
  statusId: number
): Promise<Response> => {
  return await http.put(`/api/general-documents/${documentId}`, {
    status_id: statusId,
  });
};
/* istanbul ignore next */
export const assignPropertyToGeneralDocument = async (
  documentId: number,
  data: AssignPropertyToGeneralDocumentData
): Promise<Response> => {
  return await http.put(`/api/general-documents/${documentId}`, data);
};
/* istanbul ignore next */
export const deleteGeneralDocument = async (id: number): Promise<Response> => {
  return await http.delete(`/api/general-documents/${id}`);
};
