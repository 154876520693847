import React, { ReactElement } from "react";
import { MarkerProps } from "./types";
import { LocationOnIcon } from "./styled";

/* eslint-disable @typescript-eslint/no-unused-vars */ /* istanbul ignore next */
const DefaultPin = ({ lat, lng, onClick }: MarkerProps): ReactElement => {
  return (
    <LocationOnIcon
      onClick={onClick}
      sx={{ position: "absolute", transform: "translate(-50%, -50%)" }}
      color={"error"}
      fontSize={"large"}
    />
  );
};

export default DefaultPin;
