import React, { ReactElement } from "react";
import { Draggable } from "react-beautiful-dnd";
import {
  AreaName,
  AreaNameWrapper,
  CreatedAt,
  PropertyName,
  PropertyNameWrapper,
  TaskInformation,
  TicketNumberBadge,
  Title,
} from "./styled";
import { TenantRequest as TenantRequestType } from "../../../types/be/tenantRequest";
import { Grid } from "@mui/material";
import { formatTimestamp } from "../../../utils/date";
import { generateDraggableId } from "../utils";
import { route } from "../../../utils/url";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MessageBadgeCounter } from "../../properties/styled";
import { MessageCircleIcon } from "../../../ui/table/styled";
import useAccessControl from "../../../hooks/useAccessControl";
import { PermissionsUpdate } from "../../../constants";

const TaskCard = ({
  item,
  index,
}: {
  item: TenantRequestType;
  index: number;
}): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { can } = useAccessControl();

  const handleOnTenantRequestClick = (id: number): void => {
    return navigate(route("tenant-requests.edit", id));
  };

  return (
    <Draggable
      key={item.id}
      draggableId={`${item.id}`}
      index={index}
      isDragDisabled={!can(PermissionsUpdate.TENANT_REQUEST)}
    >
      {(provided) => (
        <div
          onClick={() => handleOnTenantRequestClick(item.id)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          role={"draggable"}
        >
          <TaskInformation elevation={2} id={generateDraggableId(item.id)}>
            <Grid container direction={"column"}>
              <Grid item sx={{ mb: 4 }}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item>
                    <TicketNumberBadge>{item.ticket_number}</TicketNumberBadge>
                    <Title>{item.title}</Title>
                  </Grid>
                  <Grid item alignSelf={"flex-end"}>
                    <MessageBadgeCounter
                      badgeContent={item.chat_count}
                      color="primary"
                      overlap={"rectangular"}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MessageCircleIcon
                        role={"chatIconRole"}
                        size={18}
                        $filled={Boolean(item.chat_count)}
                      />
                    </MessageBadgeCounter>
                  </Grid>
                </Grid>
              </Grid>
              <PropertyNameWrapper item>
                <PropertyName>
                  {`${t("tenantRequest.property")}: `}
                  {item.property_name}
                </PropertyName>
              </PropertyNameWrapper>
              <AreaNameWrapper item>
                <AreaName>
                  {`${t("tenantRequest.area")}: `}
                  {item.area_name}
                </AreaName>
              </AreaNameWrapper>
              {item.manager && (
                <AreaNameWrapper item>
                  <AreaName>
                    {`${t("tenantRequest.manager")}: `}
                    {`${item.manager?.first_name} ${item.manager?.last_name}`}
                  </AreaName>
                </AreaNameWrapper>
              )}
              <Grid item display={"flex"} justifyContent={"end"}>
                <CreatedAt>{formatTimestamp(item.created_at)}</CreatedAt>
              </Grid>
            </Grid>
          </TaskInformation>
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;
