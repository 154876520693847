import { TableCell } from "../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "property_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "propertiesList.objectName",
  },
  {
    id: "knto",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.apidatev.companyNumber",
  },
];
