import styled from "styled-components";
import { Close } from "@mui/icons-material";

export const ImageViewerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .image-gallery {
    width: 50%;
  }
`;

export const CloseIcon = styled(Close)`
  position: absolute;
  top: ${(props) => props.theme.spacing(3)};
  right: ${(props) => props.theme.spacing(3)};
  cursor: pointer;
`;
