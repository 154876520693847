import React, { ReactElement, useEffect, useState } from "react";
import Form from "./Form";
import { FormWrapper } from "./styled";
import { Stack } from "@mui/material";
import { Formik } from "formik";
import {
  FormMessageErrorState,
  FormMessageInItState,
} from "../../../../constants";
import AlertCustom from "../../../../ui/formAlert/FormAlert";
import Loader from "../../../Loader";
import { useFetch } from "../../../../hooks/useFetch";
import { getUserNotificationSettings } from "../../../../api/users";
import useAccessControl from "../../../../hooks/useAccessControl";
import { ErrorBox } from "../../../properties/objectDataSheet/tabs/generalInformation/styled";
import { notificationSettingOptionsForUser } from "../../../../api/notifications";
import {
  handleUpdateUserNotificationSettingsFormSubmit,
  notificationSettingsFormInitState,
} from "./utils";
import { NotificationCodes } from "./types";
import { NotificationSettings as BENotificationSettings } from "../../../../types/be/notification";
/* istanbul ignore next */
const NotificationSettings = (): ReactElement => {
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const { _user } = useAccessControl();
  const [notificationCodes, setNotificationCodes] =
    useState<NotificationCodes | null>(null);

  const {
    data: userNotifications,
    isLoading: isUserNotificationsLoading,
    isError: isUserNotificationsError,
    run: runUserNotifications,
  } = useFetch<BENotificationSettings[]>();

  const {
    data: notificationSettings,
    isLoading: isNotificationSettingsLoading,
    isError: isNotificationSettingsError,
    run: runNotificationSettings,
  } = useFetch<BENotificationSettings[]>();

  useEffect(() => {
    _user && runUserNotifications(getUserNotificationSettings(_user?.id));
    runNotificationSettings(notificationSettingOptionsForUser());
  }, []);

  useEffect(() => {
    if (!notificationSettings || !userNotifications) return;
    const notificationList = userNotifications.map(
      (notification) => notification.code
    );
    const auxNotificationCodes: NotificationCodes = {};
    notificationSettings.forEach(({ code }) => {
      auxNotificationCodes[code] = notificationList.includes(code);
    });
    setNotificationCodes(auxNotificationCodes);
  }, [notificationSettings, userNotifications]);

  if (isUserNotificationsError || isNotificationSettingsError)
    return <ErrorBox formMessage={FormMessageErrorState} />;
  if (
    isUserNotificationsLoading ||
    isNotificationSettingsLoading ||
    !notificationCodes
  )
    return <Loader />;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        <FormWrapper>
          {formMessage.text && <AlertCustom formMessage={formMessage} />}

          {userNotifications &&
            notificationSettings &&
            notificationCodes &&
            _user && (
              <Formik
                initialValues={notificationSettingsFormInitState}
                onSubmit={handleUpdateUserNotificationSettingsFormSubmit(
                  {
                    setFormMessage,
                  },
                  _user?.id
                )}
              >
                {(props): React.ReactElement => (
                  <Form
                    {...props}
                    notificationSettings={notificationSettings}
                    notificationCodes={notificationCodes}
                    userNotifications={userNotifications}
                    setNotificationCodes={setNotificationCodes}
                  />
                )}
              </Formik>
            )}
        </FormWrapper>
      </Stack>
    </>
  );
};

export default NotificationSettings;
