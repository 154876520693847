import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormikProps } from "formik";
import { Grid } from "@mui/material";
import Button from "../../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Tooltip,
  Autocomplete,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import {
  MAX_PRELOADED_OPTIONS,
  NODE_ENVIRONMENTS,
  SEARCH_PARAM_MIN_CHARACTERS,
} from "../../../../constants";
import debounce from "lodash/debounce";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { User } from "../../../../types/models";
import { isNodeEnv } from "../../../../utils/env";
import { StyledPaper } from "../../../contacts/autocomplete/styled";
import {
  AssetManagersAutocompleteTextField,
  Chip,
  SelectBox,
} from "../../../properties/objectDataSheet/tabs/generalInformation/styled";
import { removeDuplicatesFromUsers } from "../../../properties/areas/edit/tabs/areaForm/utils";
import { AssignUsersToGeneralDocumentData } from "./types";
import { handleOnChangeAutocompleteInputText } from "./utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="inherit" />;
const checkedIcon = <CheckBoxIcon fontSize="inherit" />;

/* istanbul ignore next */
const Form = ({
  handleSubmit,
  setFieldValue,
  touched,
  errors,
  isSubmitting,
  values,
  preloadData,
  handleBlur,
  propertyId,
  disabledItems,
  isPreloadDataLoading,
}: {
  preloadData: User[];
  propertyId: number | null;
  disabledItems: User[];
  isPreloadDataLoading: boolean;
} & FormikProps<AssignUsersToGeneralDocumentData>): ReactElement => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [showTooltip, setShowTooltip] = useState<Record<string, boolean>>({});

  const preloadOptions: User[] = useMemo(() => {
    const { length = 0 } = values.assigned_users;
    return removeDuplicatesFromUsers([
      ...disabledItems,
      ...values.assigned_users,
      ...preloadData.slice(
        0,
        length < MAX_PRELOADED_OPTIONS ? MAX_PRELOADED_OPTIONS - length : 0
      ),
    ]);
  }, [
    preloadData,
    disabledItems,
    values.assigned_users,
    MAX_PRELOADED_OPTIONS,
  ]);

  const [options, setOptions] = useState<User[] | null>(preloadOptions);
  const [searchResult, setSearchResult] = useState<User[]>(preloadOptions);

  useEffect(() => {
    setOptions(preloadOptions);
    setSearchResult(preloadOptions);
  }, [preloadOptions]);

  const onSearchFieldTextChange = useCallback(
    debounce(
      async (search: string): Promise<void> => {
        if (search.length < SEARCH_PARAM_MIN_CHARACTERS) {
          setOptions(preloadOptions);
          setSearchResult(preloadOptions);
        } else {
          setLoading(true);
          try {
            const response: User[] = await handleOnChangeAutocompleteInputText(
              search,
              propertyId
            );
            console.log("response", response);
            setOptions(
              removeDuplicatesFromUsers([...values.assigned_users, ...response])
            );
            setSearchResult(removeDuplicatesFromUsers([...response]));
            setLoading(false);
          } catch (e) {
            /* istanbul ignore next */
            setOptions([]);
          }
        }
      },
      /* istanbul ignore next */ isNodeEnv(NODE_ENVIRONMENTS.TEST) ? 0 : 500
    ),

    [handleOnChangeAutocompleteInputText, preloadOptions, values.assigned_users]
  );

  const getOptionLabel = ({ id, first_name, last_name, email }: User): string =>
    `${first_name}${id}${last_name}${email}`;

  /* istanbul ignore next */
  const filterOptions = useCallback(
    (options: User[]): User[] =>
      options.filter(
        (option) =>
          !(
            !searchResult.some((el) => el.id === option.id) &&
            values.assigned_users.map((el) => el.id).includes(option.id)
          )
      ),
    [searchResult, values.assigned_users]
  );

  const onAutocompleteChange = (_: unknown, newUsers: User[]): void => {
    const includesDisabled = newUsers.some(({ id }) => disabledItemsHasId(id));
    if (includesDisabled) return;
    setFieldValue("assigned_users", newUsers);
  };

  const disabledItemsHasId = (id: number): boolean =>
    disabledItems.map((item) => item.id).includes(id);

  const renderTags = (list: User[]): ReactElement => (
    <SelectBox>
      <Chip sx={{ maxHeight: 17 }} label={list[0].first_name} />
      {list.length > 1 && (
        <div className="more-info">{`${t(
          "tenant.selectedMoreThenOneTenantPartOne"
        )} ${list.length - 1} ${t(
          "tenant.selectedMoreThenOneTenantPartTwo"
        )} `}</div>
      )}
    </SelectBox>
  );

  const tooltipTitle =
    values.assigned_users.length === 0 || isMenuOpened
      ? null
      : values.assigned_users.map(
          ({ id, first_name, last_name }: User, index: number) => (
            <Typography key={id + " " + index}>
              {first_name + " " + last_name}
            </Typography>
          )
        );

  const isLoading = loading || isPreloadDataLoading;

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Tooltip placement="top" title={tooltipTitle}>
              <Autocomplete
                id="assigned_users"
                loading={isLoading}
                inputValue={inputValue}
                size={"small"}
                multiple
                limitTags={1}
                onOpen={() => setIsMenuOpened(true)}
                onClose={() => {
                  setIsMenuOpened(false);
                }}
                disableCloseOnSelect
                options={options || /* istanbul ignore next */ []}
                filterOptions={filterOptions}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={(option, value): boolean => {
                  return option.id === value.id;
                }}
                value={values.assigned_users}
                onChange={onAutocompleteChange}
                renderTags={renderTags}
                PaperComponent={StyledPaper}
                renderOption={(props, option, { selected }): ReactElement => (
                  <Tooltip
                    key={option.id}
                    placement="bottom"
                    open={showTooltip[option.id] || false}
                    onMouseEnter={() => {
                      setShowTooltip((prev) => ({
                        ...prev,
                        [option.id]: true,
                      }));
                    }}
                    onMouseLeave={() => {
                      setShowTooltip((prev) => ({
                        ...prev,
                        [option.id]: false,
                      }));
                    }}
                    title={
                      disabledItemsHasId(option.id)
                        ? t("generalDocuments.userIsAmOfTheProperty")
                        : null
                    }
                  >
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected || disabledItemsHasId(option.id)}
                        disabled={disabledItemsHasId(option.id)}
                      />
                      {`${option.first_name} ${option.last_name}`}
                    </li>
                  </Tooltip>
                )}
                renderInput={(params) => (
                  <>
                    <AssetManagersAutocompleteTextField
                      {...params}
                      label={t("generalDocuments.assignUsers")}
                      placeholder={t("search")}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setInputValue(e.target.value);
                        onSearchFieldTextChange(e.target.value);
                      }}
                      error={Boolean(
                        touched.assigned_users && errors.assigned_users
                      )}
                      helperText={
                        touched.assigned_users && errors.assigned_users
                      }
                      InputProps={{
                        ...params.InputProps,

                        className: `${params.InputProps.className} ${"small"}`,
                        endAdornment: (
                          <>
                            {isLoading && (
                              <CircularProgress color="inherit" size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  </>
                )}
              />
            </Tooltip>
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "flex-end" }}
            xs={12}
            lg={12}
            sm={12}
            md={12}
          >
            <Button
              title={t("save")}
              color="success"
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default Form;
