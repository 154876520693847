import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Form, FormikValues } from "formik";
import { PropertyManagementRestProps } from "../types";
import { Grid, MenuItem, Stack } from "@mui/material";
import TextField from "../../../../../../ui/formsFields/text";
import { useTranslation } from "react-i18next";
import Snackbar from "../../../../../../ui/Snackbar";
import { Company, User } from "../../../../../../types/models";
import { TextFieldSpinner } from "../styled";
import { useFetch } from "../../../../../../hooks/useFetch";
import { fetchUsersByCompanyId } from "../../../../../../api/companies";
import Button from "../../../../../../ui/button/Button";
import {
  FORMAT_NUMBER_DE,
  FR_STATUS_ID_PARAM,
  INPUT_MIN_HEIGHT,
  Measurement_Units,
  PROPERTY_ID_PARAM,
  PermissionsUpdate,
} from "../../../../../../constants";
import CreateCompanyModal from "../../../../../contacts/createCompanyModal";
import { createCompanyFormInitState } from "../../../../../contacts/createCompanyModal/utils";
import { FormatNumber } from "../../../../../../ui/formsFields/formatNumber";
import {
  parseNumber,
  prepareQueryParams,
} from "../../../../../../utils/common";
import DatePicker from "../../../../../../ui/datePicker";
import useAccessControl from "../../../../../../hooks/useAccessControl";
import Dropdown from "../../../../../../ui/dropdown";
import { RELEASED_CONTRACTS_FR_STATUS_ID } from "../../../../maintenance/create/utils";
import { getContracts } from "../../../../../../api/contracts";
import { pick } from "lodash";
import CompaniesAutocomplete from "../../../autocomplete/Companies";
import { useParams } from "react-router-dom";

const PropertyManagementForm = ({
  status,
  handleSubmit,
  isSubmitting,
  values,
  setFieldValue,
  caretakers,
  users,
  showSuccess,
  setShowSuccess,
  onCancel,
  companies,
  handleBlur,
  touched,
  errors,
  setStartContract,
}: FormikValues & PropertyManagementRestProps): ReactElement => {
  const { t } = useTranslation();
  const { can } = useAccessControl();
  const { id } = useParams();
  const [isCreateCompanyModalOpen, setIsCreateCompanyModalOpen] =
    useState<boolean>(false);
  const [companyWasCreated, setCompanyWasCreated] = useState(false);
  const [initialValues, setInitialValues] = useState(
    createCompanyFormInitState
  );
  const [createCompanyAutocompleteValue, setCreateCompanyAutocompleteValue] =
    useState<Company | null>(null);

  const {
    data,
    isLoading: isUsersLoading,
    run,
  } = useFetch<User[]>({ data: users ?? [] });

  useEffect(() => {
    setCompanyWasCreated(companyWasCreated);
  }, [status, showSuccess, setCompanyWasCreated]);

  useEffect(() => {
    setStartContract(values.contract_start);
  }, [values.contract_start]);

  const handleOpenModal = (): void => {
    setIsCreateCompanyModalOpen(true);
  };

  const handleCloseSnackbar = (): void => {
    setShowSuccess(false);
    setCompanyWasCreated(false);
  };

  const handleCreateCompanyModalClose = (): void => {
    setInitialValues(createCompanyFormInitState);
    setIsCreateCompanyModalOpen(false);
  };

  const calculatePer = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setFieldValue("property_management_cost", e.target.value);
    if (e.target.value && values.net_rent_per_year) {
      const percentage =
        (parseNumber(e.target.value) * 100) / values.net_rent_per_year;
      setFieldValue("percentage_net_rent", percentage.toFixed(2));
    } else {
      setFieldValue("percentage_net_rent", 0);
    }
  };

  const getContractOptions = (search: string): Promise<Response> => {
    const params = prepareQueryParams(
      search,
      {
        [PROPERTY_ID_PARAM]: id,
        [FR_STATUS_ID_PARAM]: RELEASED_CONTRACTS_FR_STATUS_ID,
      },
      true
    );

    return getContracts(params);
  };

  useEffect(() => {
    if (createCompanyAutocompleteValue === null) return;

    setFieldValue("company", createCompanyAutocompleteValue);
    setFieldValue("company_user_id", null);
  }, [createCompanyAutocompleteValue, setFieldValue]);

  useEffect(() => {
    values.company && run(fetchUsersByCompanyId(values.company.id));
  }, [values.company]);

  return (
    <React.Fragment>
      <Stack>
        {can(PermissionsUpdate.PROPERTY) && (
          <Stack direction="row" justifyContent="flex-start" mb={5}>
            <Button
              variant="contained"
              type="button"
              color="success"
              size="large"
              testId="open-company-modal"
              title={t("property.objectDataSheet.createCompany")}
              onClick={handleOpenModal}
            />
          </Stack>
        )}
        <Form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={6} columns={12}>
            <Grid item xs={12} sm={6} position="relative">
              <CompaniesAutocomplete
                values={values}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                companies={companies}
                touched={touched}
                errors={errors}
              />
              {isUsersLoading && <TextFieldSpinner color="primary" size={20} />}
              <TextField
                name="company_user_id"
                select
                label={t("property.companyUser")}
                sx={{ minHeight: INPUT_MIN_HEIGHT }}
                value={
                  data?.length && values.company_user_id
                    ? values.company_user_id
                    : ""
                }
                defaultValue=""
                disabled={
                  isUsersLoading ||
                  data?.length === 0 ||
                  !can(PermissionsUpdate.PROPERTY)
                }
                status={status}
              >
                {data &&
                  data.map((user: User) => (
                    <MenuItem key={user.id} value={user.id}>
                      {`${user.first_name} ${user.last_name}`}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                name="caretaker_id"
                select
                label={t("property.caretaker")}
                value={values.caretaker_id}
                disabled={!can(PermissionsUpdate.PROPERTY)}
                sx={{ minHeight: INPUT_MIN_HEIGHT }}
                defaultValue=""
                status={status}
              >
                {caretakers.map((caretaker: User) => (
                  <MenuItem key={caretaker.id} value={caretaker.id}>
                    {`${caretaker.first_name} ${caretaker.last_name}`}
                  </MenuItem>
                ))}
              </TextField>
              <FormatNumber
                name="property_management_cost"
                label={t("property.propertyManagementCost")}
                required
                sx={{ minHeight: INPUT_MIN_HEIGHT }}
                value={values.property_management_cost}
                disabled={!can(PermissionsUpdate.PROPERTY)}
                size="medium"
                onChange={calculatePer}
                status={status}
                inputProps={{
                  "data-testid": "property-management-cost-input",
                  filter: FORMAT_NUMBER_DE,
                  unit: Measurement_Units.EURO,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                name="contract_start"
                disableFuture
                label={t("property.contractStartDate")}
                status={status}
                disabled={!can(PermissionsUpdate.PROPERTY)}
              />
              <DatePicker
                name="contract_end"
                minDate={values.contract_start}
                label={t("property.contractExpiryDate")}
                status={status}
                disabled={!can(PermissionsUpdate.PROPERTY)}
              />
              <DatePicker
                name="contract_cancelled"
                disableFuture
                label={t("property.contractCancelDate")}
                status={status}
                disabled={!can(PermissionsUpdate.PROPERTY)}
              />
              <Dropdown
                id="contract-autocomplete"
                name="contract_id"
                value={values.contract_id}
                placeholder={t("contracts.searchContract")}
                getOptions={getContractOptions}
                onSelectOption={
                  /* istanbul ignore next */ (id?: string) =>
                    setFieldValue("contract_id", id)
                }
                defaultValue={
                  /* istanbul ignore next */ values?.contract
                    ? pick(values?.contract, ["id", "name"])
                    : null
                }
                optionLabel="name"
                optionValue="id"
                size="medium"
                error={Boolean(status?.errors?.contract_id)}
                helperText={status?.errors?.contract_id}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} columns={12}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="percentage_net_rent"
                label={t("property.percentageOfNetRent")}
                value={`${values.percentage_net_rent}%`}
                disabled
              />
            </Grid>
          </Grid>
          {can(PermissionsUpdate.PROPERTY) && (
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
              mt={3}
            >
              {onCancel && (
                <Button
                  title={t("cancel")}
                  type="button"
                  onClick={onCancel}
                  variant="text"
                  color="info"
                  size="large"
                />
              )}
              <Button
                title={t("update")}
                type="submit"
                color="success"
                size="large"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                testId={"property-management-update"}
              />
            </Stack>
          )}
          <Snackbar
            color="success"
            message={t("createContact.companyWasSuccessfullyCreated")}
            open={companyWasCreated}
            handleClose={handleCloseSnackbar}
          />
        </Form>
        <CreateCompanyModal
          isCreateCompanyModalOpen={isCreateCompanyModalOpen}
          handleCreateCompanyModalClose={handleCreateCompanyModalClose}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          isPropertyManagerRole={true}
          setCompanyAutocompleteValue={setCreateCompanyAutocompleteValue}
          setCompanyWasCreated={setCompanyWasCreated}
        />
      </Stack>
    </React.Fragment>
  );
};

export default PropertyManagementForm;
