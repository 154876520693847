import { TableCell } from "../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "counter.readings.date",
  },
  {
    id: "meter",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "counter.meter",
  },
  {
    id: "unit",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "counter.unit",
  },
  {
    id: "createdByUser",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "createdBy",
  },
  {
    id: "images",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "counter.images",
  },
];
