import React, { ReactElement } from "react";
import { DialogContent } from "@mui/material";
import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import Form from "./Form";
import {
  handleUpdateEmptyAreaInformation,
  prepareEditVacancyBeforeForm,
} from "./utils";
import { useTranslation } from "react-i18next";
import DialogTitle from "../../../ui/dialog/DialogTitle";
import { EditVacantProps } from "./types";

export const EditVacancyModal = ({
  open,
  row,
  handleCreateModalClose,
  snackbar,
  fetchList,
}: EditVacantProps): ReactElement => {
  const { t } = useTranslation();

  const onSuccess = (): void => {
    fetchList();
    handleCreateModalClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleCreateModalClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="edit-vacancy"
    >
      <DialogTitle
        title={t("vacancies.editVacancy")}
        onClose={handleCreateModalClose}
      />
      <DialogContent>
        <>
          <Formik
            initialValues={prepareEditVacancyBeforeForm(row)}
            onSubmit={handleUpdateEmptyAreaInformation({
              row,
              handleCreateModalClose,
              snackbar,
              t,
              onSuccess,
            })}
          >
            {(props): ReactElement => <Form {...props} row={row} />}
          </Formik>
        </>
      </DialogContent>
    </Dialog>
  );
};
