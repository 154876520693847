import { FORM_SELECT_ALL_OPTION_ID } from "../../../../../../constants";
import { PaidUnpaidFilter } from "../../../../types";
import { ProviderInvoiceListFilter } from "../../types";
/* istanbul ignore next */
export const childFilterInitValues: ProviderInvoiceListFilter = {
  fr_status_id: FORM_SELECT_ALL_OPTION_ID,
  is_paid: PaidUnpaidFilter.all,
  year: FORM_SELECT_ALL_OPTION_ID,
  month: FORM_SELECT_ALL_OPTION_ID,
};
