import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useTable from "../../../../ui/table/useTable";
import { handleServerError } from "../../../../utils/http";
import { IdPropType } from "../../../../types/models";
import { Grid, TableCell } from "@mui/material";
import {
  DeleteIcon,
  EditIcon,
  HistoryIcon,
  Input,
  Search,
  SearchIconWrapper,
  SendIcon,
} from "../../../../ui/table/styled";
import useIsMounted from "../../../../hooks/useIsMounted";
import Table from "../../../../ui/table/Table";
import { Search as SearchIcon } from "react-feather";
import { route } from "../../../../utils/url";
import { itemsListHeadCells } from "./utils";
import { deleteLOIListItem, getLOIList } from "../../../../api/loi";
import { formatTimestamp } from "../../../../utils/date";
import {
  EMPTY_DATA,
  PermissionsCreate,
  PermissionsDelete,
  PermissionsUpdate,
} from "../../../../constants";
import { LOI } from "../../../../types/be/loi";
import useSnackbar from "../../../../ui/snackbar1/useSnackbar";
import { SendLOIModal } from "../sendLOIModal";
import useAccessControl from "../../../../hooks/useAccessControl";
import { StyledButton } from "../../../../ui/button/styled";
import FileTableCell from "../../../properties/components/fileTableCell";

const LOIList = (): ReactElement => {
  const navigate = useNavigate();
  const { can } = useAccessControl();
  const { t } = useTranslation();
  const [row, setRow] = useState<LOI | null>(null);
  const { Snackbar, snackbar } = useSnackbar();
  /* istanbul ignore next */
  const handleCreateModalClose = (): void => {
    setRow(null);
  };
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleDeleteLastPageData,
  } = useTable<LOI>({ loading: true });

  useEffect(() => {
    if (isMounted()) fetchLOIList();
  }, [queryParams]);

  const fetchLOIList = useCallback(async () => {
    setIsLoading(true);
    const res = await getLOIList(queryParams);
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const resJson = await res.json();
      setTotal(resJson.meta.total);
      setData(resJson.data);
      setIsLoading(false);
    }
  }, [queryParams, setIsLoading, setTotal, setData, setIsLoading]);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteLOIListItem(rowToDelete);
    handleDeleteLastPageData(fetchLOIList);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteLOIListItem,
    fetchLOIList,
    handleConfirmToRemoveModalClose,
  ]);
  /* istanbul ignore next */
  const handleEditClick = useCallback(
    ({ id }: IdPropType): void => navigate(`${id}/edit`),
    []
  );

  const handleHistoryClick = useCallback(
    ({ id }: IdPropType): void => navigate(`${id}/history`),
    []
  );

  const renderRow = (row: LOI): ReactElement => {
    return (
      <>
        <TableCell>
          {row.user
            ? `${row.user.first_name} ${row.user.last_name}`
            : EMPTY_DATA}
        </TableCell>
        <TableCell align="left">
          {row.object_name ? row.object_name : EMPTY_DATA}
        </TableCell>
        <TableCell align="center">
          {row.date ? formatTimestamp(row.date) : EMPTY_DATA}
        </TableCell>
        <TableCell align="center">
          {row.sent_date ? formatTimestamp(row.sent_date) : EMPTY_DATA}
        </TableCell>
        <FileTableCell file={row.file} />
        <TableCell
          align="right"
          onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
            handleCellClick(e)
          }
          style={{ whiteSpace: "nowrap" }}
        >
          <SendIcon
            role={"sendIconRole"}
            onClick={/* istanbul ignore next */ (): void => setRow(row)}
            size={18}
            data-testid="send-icon"
          />
          {can(PermissionsUpdate.LOI) && (
            <EditIcon
              role={"editIconRole"}
              onClick={
                /* istanbul ignore next */ (): void => handleEditClick(row)
              }
              size={20}
            />
          )}
          <HistoryIcon
            role={"historyIconRole"}
            onClick={(): void => handleHistoryClick(row)}
          />
          {can(PermissionsDelete.LOI) && (
            <DeleteIcon
              role={"deleteIconRole"}
              onClick={(): void => openConfirmToRemoveModal(row)}
              size={22}
              data-testid="delete-icon"
            />
          )}
        </TableCell>
      </>
    );
  };

  const TableToolbar = (
    <Grid container sx={{ pt: 5, mb: 2 }}>
      <Grid item>
        {can(PermissionsCreate.LOI) && (
          <StyledButton
            data-testid={"dataItems-link"}
            component={NavLink}
            to={route(`loi.create`)}
            size="small"
            color="success"
            variant="contained"
          >
            {t("create")}
          </StyledButton>
        )}
      </Grid>
      <Grid item xs />
      <Grid item>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Input placeholder={t("search")} onChange={handleTableSearch} />
        </Search>
      </Grid>
    </Grid>
  );

  const isMounted = useIsMounted();

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onRowRemove={handleRowRemove}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        noDataIsAvailablePlaceholder={"table.noLOIsAreAvailable"}
      />

      {
        /* istanbul ignore next */ row && (
          <SendLOIModal
            open={!!row}
            row={row}
            handleCreateModalClose={handleCreateModalClose}
            snackbar={snackbar}
          />
        )
      }
      {/* istanbul ignore next */ Snackbar}
    </>
  );
};

export default LOIList;
