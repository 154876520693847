import { Grid, Typography, DialogContent } from "@mui/material";
import styled from "styled-components/macro";
import { Text } from "../../../../ui/formsFields/text";

export const TextField = styled(Text) <{
  $description?: string;
}>`
  background: ${(props): string =>
    props.$description === "Strength" ? "#dae9fc" :
      props.$description === "Weakness" ? "#fff2cc" :
        props.$description === "Opportunities" ? "#d6e7d4" : "#f7cfcc"
  };
  border-radius: ${(props): string => props.theme.spacing(5)};
  & > div {
    border-radius: ${(props): string => props.theme.spacing(5)};
  }
  & textarea:disabled {
    -webkit-text-fill-color: black
  }
  & textarea {
    color: black;
    padding: ${(props): string => props.theme.spacing(1, 2)};
  }
  & .Mui-focused {
    color: black;
    border-color: black
  }
`;

export const EditButton = styled.button`
background: transparent;
border: none;
color: #1e88e5;
cursor: pointer;
& :hover{
  text-decoration: none;
  background-color: rgba(30,136,229,0.08);
}
& > svg {
  min-width: ${(props): string => props.theme.spacing(12.5)};
  min-height: ${(props): string => props.theme.spacing(8.7)};
  padding: ${(props): string => props.theme.spacing(1.7, 2)};
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.75;
  border-radius: ${(props): string => props.theme.spacing(1)};
  transition: background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms;
}
`;

export const ButtonBox = styled.div`
  position: absolute;
  bottom: ${(props): string => props.theme.spacing(2.5)};
  right: ${(props): string => props.theme.spacing(2.5)};
`;

export const SwotGrid = styled(Grid)`
  width: ${(props): string => props.theme.spacing(80)};
  margin-top: ${(props): string => props.theme.spacing(7.5)};
`;

export const SwotLabel = styled(Typography) <{
  $swotDescription: boolean;
  $isFocused: boolean;
}>`
  color: ${(props): string =>
    props.$swotDescription || props.$isFocused ? "white" : "black"};
  position: absolute;
  top: ${(props): string =>
    props.$swotDescription || props.$isFocused ? "-5%" : "50%"};
  left: 50%;
  transform: translate(-50%, -50%);
  transition: top .5s, color .5s;
`;

export const SwotDialogContent = styled(DialogContent)`
  overflow: visible;
`