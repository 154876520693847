import styled from "styled-components/macro";
import { Grid, MenuItem } from "@mui/material";
import { TableCell as MuiTableCell } from "@mui/material";
import CustomTextField from "../../../ui/formsFields/textField";
import { Box } from "@mui/material";
import { Edit } from "react-feather";
/* istanbul ignore next */
export const StyledGrid = styled(Grid)`
  position: absolute;
  margin: ${(props): string => props.theme.spacing(0)};
  padding: ${(props): string => props.theme.spacing(0)};
  font-size: ${(props): string => props.theme.spacing(2.5)};
  display: flex;
  bottom: ${(props): string => props.theme.spacing(0.25)};
  left: ${(props): string => props.theme.spacing(0.5)};
  justify-content: space-between;
  width: 99%;
`;
/* istanbul ignore next */
export const PublicHolidayStyledGrid = styled(Grid)`
  position: absolute;
  margin: ${(props): string => props.theme.spacing(0)};
  padding: ${(props): string => props.theme.spacing(0)};
  font-size: ${(props): string => props.theme.spacing(3.25)};
  display: flex;
  top: ${(props): string => props.theme.spacing(0.25)};
  left: ${(props): string => props.theme.spacing(1.75)};
  justify-content: space-between !important;
  width: 99%;
`;

export const InnerStyledGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  width: 83%;
`;
/* istanbul ignore next */
export const EditIcon = styled(Edit)`
  &:hover {
    color: ${(props): string => props.theme.palette.grey[400]};
    cursor: pointer;
  }
  transition: color 150ms;
  z-index: 1;
`;
/* istanbul ignore next */
export const InfoBox = styled(Box)<{
  $getBackgroundColorByOptionId: ($optionId?: number) => string;
  $optionId?: number;
}>`
  background: ${(props): string =>
    props.$getBackgroundColorByOptionId(props.$optionId)};
  padding-top: ${(props): string => props.theme.spacing(2.125)};
  padding-bottom: ${(props): string => props.theme.spacing(2.125)};
  padding-left: ${(props): string => props.theme.spacing(3.5)};
  padding-right: ${(props): string => props.theme.spacing(8)};
  border-radius: ${(props): string => props.theme.spacing(1)};
  white-space: nowrap;
`;

export const TableCell = styled(MuiTableCell)<{
  $showBorderRight?: boolean;
}>`
  border-left: 1px solid
    ${(props): string =>
      props.$showBorderRight ? props.theme.table.borderBottom : "transparent"};
  padding-top: ${(props): string => props.theme.spacing(4)};
  padding-bottom: ${(props): string => props.theme.spacing(4)};
  padding-left: ${(props): string => props.theme.spacing(0.5)};
  padding-right: ${(props): string => props.theme.spacing(0.5)};

  .MuiGrid-container .MuiGrid-item {
    display: flex;
    justify-content: center;
  }
`;
/* istanbul ignore next */
export const TextField = styled(CustomTextField)<{
  $getBackgroundColorByOptionId: ($optionId?: number) => string;
  $optionId?: number;
  $isFieldServiceSelected?: boolean;
  $showPublicHolidayTitle?: boolean;
}>`
  min-height: auto;
  width: ${(props): string => props.theme.spacing(30)};

  ${(props): string => props.theme.breakpoints.up("xxl")} {
    width: 100%;
  }
  /* @media screen and (min-width: 1921px) {
    width: 100%;
  } */

  > .MuiInputBase-root {
    background: ${(props): string =>
      props.$getBackgroundColorByOptionId(props.$optionId)};
    display: flex;
    align-items: center;
    width: ${(props): string => props.theme.spacing(30)};

    ${(props): string => props.theme.breakpoints.up("xxl")} {
      width: 100%;
    }

    /* @media screen and (min-width: 1921px) {
      width: 100%;
    } */

    .MuiSelect-select {
      padding-right: ${(props): string => props.theme.spacing(0)};
      font-size: ${(props): string =>
        props.$showPublicHolidayTitle
          ? props.theme.spacing(2.5)
          : props.theme.spacing(3.25)};
    }

    > svg {
      right: 0;
    }
    .MuiSvgIcon-root {
      top: ${(props): string =>
        props.theme.spacing(
          props.$isFieldServiceSelected || props.$showPublicHolidayTitle
            ? 0.1
            : "calc(50% - 0.5em)"
        )};
    }
  }

  .MuiSelect-select {
    padding-top: ${(props): string =>
      props.theme.spacing(
        props.$isFieldServiceSelected
          ? 0.5
          : props.$showPublicHolidayTitle
          ? 4.25
          : 2.125
      )};
    padding-bottom: ${(props): string =>
      props.theme.spacing(
        props.$isFieldServiceSelected
          ? 3.75
          : props.$showPublicHolidayTitle
          ? 0
          : 2.125
      )};
    z-index: 1;
    padding-left: ${(props): string => props.theme.spacing(1)};
    padding-right: ${(props): string => props.theme.spacing(0)};

    svg {
      margin-bottom: -${(props): string => props.theme.spacing(0.3)};
      display: none;
    }
    span {
      margin-left: ${(props): string => props.theme.spacing(0.8)};
    }
  }
`;
/* istanbul ignore next */
export const DescriptionTextField = styled(CustomTextField)`
  min-height: ${(props): string => props.theme.spacing(18.5)};
  margin-top: ${(props): string => props.theme.spacing(1.5)};
`;
/* istanbul ignore next */
export const StyledMenuItem = styled(MenuItem)`
  align-items: center !important;

  svg {
    margin-right: ${(props): string => props.theme.spacing(0.8)};
  }

  span {
    margin-left: ${(props): string => props.theme.spacing(0.8)};
  }
`;
