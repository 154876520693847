import React, { ReactElement, useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Form from "./Form";
import PageWrapper from "../../../ui/pageWrapper/PageWrapper";
import { FormMessageInItState } from "../../../constants";
import FormAlert from "../../../ui/formAlert/FormAlert";
import {
  createPropertyFormInitState,
  handleCreatePropertyFormSubmit,
} from "./utils";
import useValidation from "../../../hooks/useValidation";
import { route } from "../../../utils/url";
import { Grid, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CreateProperty = (): ReactElement => {
  const navigate = useNavigate();
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [initialValues] = useState(createPropertyFormInitState);
  const { notes, property_type_id, purchase_price, object_name, location } =
    useValidation();
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: route("properties"),
      name: t("properties"),
    },
  ];

  return (
    <PageWrapper title={t("property.createProperty")} breadcrumbs={breadcrumbs}>
      <>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={12} lg={10} xl={8}>
            <Paper sx={{ padding: 6 }}>
              {formMessage.text && (
                /* istanbul ignore next */
                <FormAlert
                  formMessage={formMessage}
                  sx={{ marginBottom: "10px" }}
                />
              )}
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  notes,
                  purchase_price,
                  object_name,
                  property_type_id,
                  location,
                })}
                onSubmit={handleCreatePropertyFormSubmit({
                  setFormMessage,
                  navigate,
                })}
              >
                {(props): ReactElement => <Form {...props} />}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      </>
    </PageWrapper>
  );
};

export default CreateProperty;
