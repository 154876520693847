import { NotificationDataEntity } from "../../../types/be/notification";
import { RouteReturnType } from "../types";

export const NOTIFICATION_ROUTE = {
  PROPERTY: "Property",
  AREA: "Area",
  COUNTER: "Counter",
  READING: "Reading",
  CONTRACT: "Contract",
  TENANT_REQUEST: "TenantRequest",
  OFFER: "Offer",
  INVOICE: "Invoice",
  INSURANCE: "Insurance",
};

export const getRouteType = (
  notification: NotificationDataEntity
): RouteReturnType => {
  let ids = [notification?.id];
  let type = notification?.name;
  if (notification?.relationship) {
    const data = getRouteType(notification?.relationship);
    ids = [...data.ids, ...ids];
    type = data?.type + type;
  }
  return { ids, type };
};

export const generateUrl = (notification: NotificationDataEntity): string => {
  const { ids, type } = getRouteType(notification);
  const URL = `/${window.localStorage.getItem("client")}`;

  switch (type) {
    case NOTIFICATION_ROUTE.PROPERTY +
      NOTIFICATION_ROUTE.AREA +
      NOTIFICATION_ROUTE.COUNTER +
      NOTIFICATION_ROUTE.READING:
      return URL + `/meter/${ids?.[2]}`;

    case NOTIFICATION_ROUTE.PROPERTY +
      NOTIFICATION_ROUTE.AREA +
      NOTIFICATION_ROUTE.TENANT_REQUEST:
      return URL + `/tenant-requests/${ids?.[2]}`;

    case NOTIFICATION_ROUTE.PROPERTY + NOTIFICATION_ROUTE.CONTRACT:
      return URL + `/document-release/contracts/${ids?.[1]}/show`;

    case NOTIFICATION_ROUTE.PROPERTY + NOTIFICATION_ROUTE.OFFER:
      return URL + `/document-release/offers/${ids?.[1]}/show`;

    case NOTIFICATION_ROUTE.PROPERTY + NOTIFICATION_ROUTE.INVOICE:
      return URL + `/document-release/invoices/${ids?.[1]}/show`;

    case NOTIFICATION_ROUTE.PROPERTY + NOTIFICATION_ROUTE.INSURANCE:
      return URL + `/document-release/insurances/${ids?.[1]}/show`;

    case NOTIFICATION_ROUTE.INVOICE:
      return URL + `/document-release/invoices/${ids?.[0]}/show`;

    case NOTIFICATION_ROUTE.OFFER:
      return URL + `/document-release/offers/${ids?.[0]}/show`;

    case NOTIFICATION_ROUTE.INSURANCE:
      return URL + `/document-release/insurances/${ids?.[0]}/show`;

    case NOTIFICATION_ROUTE.CONTRACT:
      return URL + `/document-release/contracts/${ids?.[0]}/show`;
    default:
      return URL;
  }
};

export const DATE_TIME_FORMAT = "DD.MM.yyyy HH:mm:ss";