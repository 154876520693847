import React, { ReactElement, useState, useCallback, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import Autocomplete from "../../../ui/autocomplete/Autocomplete";
import { useTranslation } from "react-i18next";
import { Company } from "../../../types/models";
import { companiesAutocomplete } from "../../../api/companies";
import { CompanyAutocompleteProps } from "./types";
import { prepareQueryParams } from "../../../utils/common";
import { PAGE_LIMIT } from "../../../constants";
import { useFetch } from "../../../hooks/useFetch";

const CompanyAutocomplete = ({
  status,
  name,
  initialValue,
  disabled = false,
}: CompanyAutocompleteProps): ReactElement => {
  const { t } = useTranslation();
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const { data: preloadedData, run: runPreloadedCompany } =
    useFetch<Company[]>();

  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  useEffect(() => {
    initialValue && setSelectedCompany(initialValue);
  }, [initialValue]);

  useEffect(() => {
    runPreloadedCompany(companiesAutocomplete(""));
  }, []);

  const handleInputChange = useCallback(async (search: string) => {
    const params = prepareQueryParams("", {
      search,
      limit: String(PAGE_LIMIT._10),
    });
    const response = await companiesAutocomplete(params);
    const { data } = await response.json();
    return data;
  }, []);

  const onSelectCompany = async (event: Company): Promise<void> => {
    setSelectedCompany(event);
    setFieldValue(name, event ? event.id : "");
  };

  return (
    <>
      <Autocomplete
        id={name}
        name={name}
        handleOnChangeInputText={handleInputChange}
        handleOptionSelect={onSelectCompany}
        placeholder={t("search")}
        label={t("createContact.company")}
        value={field.value || ""}
        autocompleteValue={selectedCompany || null}
        error={Boolean(meta.touched && (status?.errors?.[name] || meta.error))}
        helperText={meta.touched ? meta.error || status?.errors?.[name] : ""}
        debounceTime={500}
        optionLabel="title"
        optionValue="id"
        disabled={disabled}
        preloadOptions={preloadedData}
      />
    </>
  );
};
export default CompanyAutocomplete;
