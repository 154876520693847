import React, { useEffect, useState } from "react";
import Button from "../../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { FormSectionTitle } from "../styled";
import { useFormTextField } from "../../../../hooks/useFormTextField";
import { useFormCheckboxField } from "../../../../hooks/useFormCheckboxField";
import { formFields } from "./utils";
import { FormFields as FormField } from "./types";
import { FormikValues } from "formik/dist/types";
import Snackbar from "../../../../ui/Snackbar";
import DatePicker from "../../../../ui/datePicker";
import { MIN_YEAR_1850 } from "../../../../constants";
import { handleFutureDisableYear } from "../../../properties/areas/edit/utils";
import { camelCase } from "lodash";

function Form({
  handleSubmit,
  isSubmitting,
  status,
  types,
  values,
  rankings,
  usages,
  propertyTypes,
  cancelEdit,
}: FormikValues): React.ReactElement {
  const { t } = useTranslation();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const formTextField = useFormTextField();
  const checkboxField = useFormCheckboxField();

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  const handleClose = (): void => setIsSnackbarVisible(false);

  return (
    <>
      {types?.length &&
        rankings?.length &&
        usages?.length &&
        propertyTypes?.length && (
          <form noValidate onSubmit={handleSubmit}>
            <FormSectionTitle>
              {t("searchProfile.searchProfile")}
            </FormSectionTitle>
            <Grid container spacing={6} columns={12}>
              {formFields({
                types,
                propertyTypes,
                usages,
                rankings,
                status,
              }).map((columnFields, key: number) => (
                <Grid key={key} item xs={12} sm={4} md={4}>
                  {Array.isArray(columnFields) &&
                    columnFields.map(
                      (formField: FormField | FormField[], key: number) =>
                        Array.isArray(formField) ? (
                          <Grid key={key} container spacing={2}>
                            {formField.map((inlineFormField: FormField) => (
                              <Grid key={inlineFormField.name} item xs={6}>
                                {formTextField(
                                  inlineFormField,
                                  "searchProfile",
                                  true
                                )}
                              </Grid>
                            ))}
                          </Grid>
                        ) : (
                          <React.Fragment key={key}>
                            {formField.name === "construction_year" ||
                            formField.name === "renovation" ? (
                              <>
                                <DatePicker
                                  name={formField.name}
                                  views={["year"]}
                                  label={t(
                                    `searchProfile.${camelCase(formField.name)}`
                                  )}
                                  inputFormat="yyyy"
                                  status={status}
                                  shouldDisableYear={handleFutureDisableYear()}
                                  minDate={new Date(MIN_YEAR_1850)}
                                />
                              </>
                            ) : formField.type !== "checkbox" ? (
                              formTextField(formField, "searchProfile", true)
                            ) : (
                              checkboxField(formField, "searchProfile")
                            )}
                          </React.Fragment>
                        )
                    )}
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              gap={3}
            >
              {values["id"] > 0 && (
                <Grid item>
                  <Button
                    testId="search-profile-cencel-edit"
                    title={t("searchProfile.cancelEdit")}
                    color="error"
                    type="button"
                    onClick={cancelEdit}
                    disabled={isSubmitting}
                  />
                </Grid>
              )}
              <Grid item>
                <Button
                  testId="search-profile-submit"
                  title={values["id"] ? t("update") : t("save")}
                  color="success"
                  type="submit"
                  size="large"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                />
              </Grid>
            </Grid>
            <Snackbar
              message={t("createContact.searchProfileWasSuccessfullyUpdated")}
              color="success"
              open={isSnackbarVisible}
              handleClose={handleClose}
            />
          </form>
        )}
    </>
  );
}

export default Form;
