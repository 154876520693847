import { AlignTableCellType, TableCell } from "../../../../types/table";
import {
  RELASE_BUTTONS_AM,
  RELASE_BUTTONS_PM,
  RELEASE_BUTTONS_FR,
  RELEASE_BUTTONS_USER,
} from "../../utils";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "property_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertyName",
    align: "left",
  },
  {
    id: "file_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "contracts.fileName",
    align: "left",
  },
  {
    id: "contract_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "contracts.contractName",
    align: "left",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "contracts.amount",
    align: "right" as AlignTableCellType,
  },
  {
    id: "start_date",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "contracts.startDate",
    align: "center",
  },
  {
    id: "end_date",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "contracts.endDate",
    align: "center",
  },
  {
    id: "signing_date",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "contracts.closedAt",
    align: "center",
  },
  {
    id: "cancellation_date",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "contracts.terminationAtTheLatest",
    align: "center",
  },
  {
    id: "cancelled_at",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "contracts.canceledAt",
    align: "center",
  },
  {
    id: "latest_comment",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "comment",
    align: "left",
  },
  {
    id: "created_by",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "uploadedBy",
    align: "left",
  },
  {
    id: "uploaded_on",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "offers.uploadedOn",
    align: "center",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "contracts.category",
    align: "left",
  },
  {
    id: RELEASE_BUTTONS_FR,
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.navLinks.finalReleaser",
    align: "center",
  },
  {
    id: RELASE_BUTTONS_AM,
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "AM",
    align: "left",
  },
  {
    id: RELASE_BUTTONS_PM,
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "PM",
    align: "left",
  },
  {
    id: RELEASE_BUTTONS_USER,
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "User",
    align: "left",
  },

  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "",
  },
];

export const historyModalInitState = {
  isVisible: false,
  historyId: 0,
};
