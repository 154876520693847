import http from "../utils/http";

export const getNotifications = async (params: string): Promise<Response> => {
  return await http.get(`/api/notifications${params}`);
};

/* istanbul ignore next */
export const notificationToggle = async (
  status: boolean,
  ids?: number[] | null
): Promise<Response> => {
  return await http.patch(`/api/notifications/toggle`, { id: ids, status });
};
/* istanbul ignore next */
export const notificationSettingOptionsForUser =
  async (): Promise<Response> => {
    return await http.get(`/api/email-types`);
  };
