import React, { ReactElement, useState, useCallback, useRef } from "react";
import { Grid, Paper, styled } from "@mui/material";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import TextField from "../../../../../ui/formsFields/textField";
import PageWrapper from "../../../../../ui/pageWrapper/PageWrapper";
import { handleSubmit, MAX_FILES } from "../utils";
import { Add, Close } from "@mui/icons-material";
import {
  AddImageButton,
  ImageItem,
  ReadingImage,
  ImagesContainer,
} from "../styled";
import {
  DEFAULT_FILE_TYPES,
  FormMessageInItState,
} from "../../../../../constants";
import { CreateReadingInitialValues, ViewableFile } from "../types";
import Button from "../../../../../ui/button/Button";
import useValidation from "../../../../../hooks/useValidation";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import FormAlert from "../../../../../ui/formAlert/FormAlert";
import { route } from "../../../../../utils/url";
import ImageViewer from "../../../../../ui/imageViewer/reactImageGallery";

const Input = styled("input")({
  display: "none",
});

const CreateReading = (): ReactElement => {
  const { t } = useTranslation();
  const { id, areaId, counterId } = useParams();
  const navigate = useNavigate();

  const { heatingValue } = useValidation();
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [showImageViewer, setShowImageViewer] = useState<number | null>(null);

  const ref = useRef<HTMLInputElement>(null);

  const handleFileChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      files: ViewableFile[],
      setFieldValue: (field: string, value: ViewableFile[]) => void
    ) => {
      const file = e?.target?.files?.[0];

      const newFiles: ViewableFile[] = [
        ...files,
        {
          file,
          url: file && URL.createObjectURL(file),
          id: Date.now(),
        },
      ];
      setFieldValue("file", newFiles);
      ref.current!.value = "";
    },
    []
  );

  const removeFile = useCallback(
    (
      id: number,
      files: ViewableFile[],
      setFieldValue: (field: string, value: ViewableFile[]) => void
    ) => {
      const newFiles = files.filter((item) => item.id !== id);
      setFieldValue("file", newFiles);
    },
    []
  );

  const openImageViewer = useCallback((index: number) => {
    setShowImageViewer(index);
  }, []);

  const closeImageViewer = useCallback(() => {
    setShowImageViewer(null);
  }, []);

  const breadcrumbs = [
    {
      to: route("properties.areas.edit.counter.reading", id, areaId, counterId),
      name: t("tenant.readings"),
    },
  ];

  const initialValues: CreateReadingInitialValues = {
    value: "",
    file: [],
    counter_id: counterId!,
  };

  return (
    <PageWrapper title={t("counter.readings.create")} breadcrumbs={breadcrumbs}>
      <Grid container justifyContent={"center"} spacing={2}>
        <Grid item xs={12} lg={6} xl={4}>
          <Paper sx={{ padding: 6 }}>
            <Grid item xs={12}>
              {formMessage.text ? (
                <FormAlert formMessage={formMessage} />
              ) : null}
            </Grid>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit(
                setFormMessage,
                navigate,
                id!,
                areaId!,
                counterId!
              )}
              validationSchema={Yup.object().shape({
                value: heatingValue,
              })}
              enableReinitialize
            >
              {({
                values,
                setFieldValue,
                status,
                isSubmitting,
                handleSubmit,
              }) => (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  data-testid="counter-form"
                >
                  <Grid item xs={12}>
                    <TextField
                      required
                      name="value"
                      label={t("counter.readings.value")}
                      fullWidth
                      status={status}
                      type="number"
                      inputProps={{ "data-testid": "heating" }}
                    />
                  </Grid>
                  <ImagesContainer>
                    {values.file?.map((item, index) => (
                      <ImageItem
                        key={index}
                        onClick={() => openImageViewer(index)}
                      >
                        <ReadingImage
                          src={item.url}
                          data-testid="upload-image"
                        />
                        <Close
                          className="removeIcon"
                          color="action"
                          onClick={() =>
                            removeFile(item.id, values.file, setFieldValue)
                          }
                          data-testid="remove-icon"
                        />
                      </ImageItem>
                    ))}
                    {values.file?.length < MAX_FILES ? (
                      <AddImageButton htmlFor="file">
                        <Add fontSize="large" />
                        <Input
                          accept={DEFAULT_FILE_TYPES}
                          type="file"
                          name="file"
                          id="file"
                          ref={ref}
                          onChange={(e) =>
                            handleFileChange(e, values.file, setFieldValue)
                          }
                          data-testid="select-file"
                        />
                      </AddImageButton>
                    ) : null}
                  </ImagesContainer>
                  {showImageViewer !== null && values?.file?.length ? (
                    <ImageViewer
                      images={values?.file?.map((item) => ({
                        original: item.url ?? "",
                        thumbnail: item.url ?? "",
                      }))}
                      handleClose={closeImageViewer}
                      startIndex={showImageViewer}
                    />
                  ) : null}
                  <Grid
                    item
                    xs={12}
                    sx={{ marginTop: 2 }}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      title={t("create")}
                      type="submit"
                      color="success"
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      size="medium"
                      testId="create-counter"
                    />
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default CreateReading;
