import { FormikValues } from "formik";
import { importContacts } from "../../../api/contacts";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import {
  setDownloadLink,
  setFileName,
} from "../../../redux/slices/importValidation/importValidation";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { TableCell } from "../../../types/table";
import { mapBackendValidationErrors } from "../../../utils/form";
import { handleManullyPropertyUploadProps, UploadFileType } from "./types";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "full_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "table.fullName",
  },
  {
    id: "company_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "createContact.company",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "email",
  },
  {
    id: "telephone",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "phoneNumber",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "address",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];

export const uploadDialogInitState: UploadFileType = {
  file: "",
};

export const handleManullyPropertyUpload = ({
  setFormMessage,
  onSuccess,
  setUploadInprogress,
  dispatchStore,
}: handleManullyPropertyUploadProps) => {
  return async (
    data: UploadFileType,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const reqData = new FormData();
    reqData.append("file", data.file!);
    const response = await importContacts(reqData);
    setUploadInprogress(true);
    const json = await response?.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      const {
        data: {
          file: { name, download_link },
        },
      } = json;
      dispatchStore(setFileName(name));
      dispatchStore(setDownloadLink(download_link));
      setStatus({
        success: true,
        errors: {},
      });
      setFormMessage({ type: undefined, text: "" });
      onSuccess();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setSubmitting(false);
    }
    setUploadInprogress(false);
  };
};
