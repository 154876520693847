import { useCallback, useEffect, useMemo } from "react";
import { useFetch } from "./useFetch";
import { InboxType } from "../types/be/inbox";
import { getInboxTypes } from "../api/inbox";
import useAccessControl from "./useAccessControl";

interface UseRolesProps {
  inboxTypes: InboxType[] | null;
  getInboxType: (query: string | number) => InboxType | undefined;
  isError: boolean;
}
/* istanbul ignore next */
const useInboxTypes = (): UseRolesProps => {
  const { data: inboxTypeData, isError, run } = useFetch<InboxType[]>();
  const { can } = useAccessControl();
  const mapPermssionWithCode = (code: string): string | string[] => {
    return `${code}.update`;
  };
  const inboxTypes = useMemo(() => {
    return (
      inboxTypeData?.filter((type) => {
        return can(mapPermssionWithCode(type.code));
      }) ?? null
    );
  }, [inboxTypeData]);
  useEffect(() => {
    run(getInboxTypes());
  }, []);

  const getInboxType = useCallback(
    (query: string | number): InboxType | undefined =>
      inboxTypeData?.find((inboxType) =>
        [inboxType.code, inboxType.id].includes(query)
      ),
    [inboxTypeData]
  );

  return {
    inboxTypes,
    getInboxType,
    isError,
  };
};

export default useInboxTypes;
