import useAppSelector from "./useAppSelector";
import difference from "lodash/difference";
import { User } from "../types/be/user";
import { useEffect, useState } from "react";

interface UseAccessControl {
  can: (can: string | string[]) => boolean;
  isRole: (role: string | string[]) => boolean;
  _user: null | User;
  isTenant: boolean | undefined;
  atLeastOnePermission: (can: string | string[]) => boolean;
}

const useAccessControl = (): UseAccessControl => {
  const { _user } = useAppSelector((state) => state.user);
  const [isTenant, setIsTenant] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (_user) {
      setIsTenant(_user.role.code === "tenant");
    }
  }, []);

  /* istanbul ignore next */
  const can = (can: string | string[]): boolean => {
    if (!_user) return false;
    const { permissions } = _user;
    if (Array.isArray(can)) return difference(can, permissions).length === 0;
    else return permissions.includes(can);
  };
  /* istanbul ignore next */
  const atLeastOnePermission = (can: string | string[]): boolean => {
    if (!_user) return false;
    const { permissions } = _user;
    if (Array.isArray(can)) {
      return permissions.some((permission) => {
        return can.includes(permission);
      });
    } else return permissions.includes(can);
  };

  const isRole = (role: string | string[]): boolean => {
    if (!_user) return false;
    return role.includes(_user.role.code);
  };

  return { can, isRole, _user, isTenant, atLeastOnePermission };
};

export default useAccessControl;
