import styled from "styled-components/macro";
import { Paper } from "@mui/material";

export const FormWrapper = styled(Paper)`
  margin-top: ${(props): string => props.theme.spacing(9)};
  padding: ${(props): string => props.theme.spacing(6)};
  ${(props): string => props.theme.breakpoints.up("lg")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 70%;
  }
  ${(props): string => props.theme.breakpoints.up("xl")} {
    padding: ${(props): string => props.theme.spacing(10)};
    width: 50%;
    max-width: 80%;
  }
  ${(props): string => props.theme.breakpoints.down("xs")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 100%;
  }
`;
