import React, { ReactElement } from "react";
import { DialogContent, Dialog, Grid } from "@mui/material";
import { AssignContactInitialValues, AssignPropertyModalProps } from "./types";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
import SelectOrCreateContactsAutocompleteField from "../../../contacts/autocomplete";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import Button from "../../../../ui/button/Button";
import * as Yup from "yup";
import useValidation from "../../../../hooks/useValidation";
import { handleConnectContact } from "./utils";
import useSnackbar from "../../../../ui/snackbar1/useSnackbar";

/* istanbul ignore next */
const AssignContactModal = ({
  isOpen,
  setIsOpen,
  id,
  afterSuccess,
}: AssignPropertyModalProps): ReactElement => {
  const { t } = useTranslation();
  const { contact_id } = useValidation();
  const { snackbar, Snackbar } = useSnackbar();
  const handleClose = (): void => {
    setIsOpen(false);
  };
  const initialValues: AssignContactInitialValues = {
    contact_id: "",
  };

  return (
    <Dialog
      maxWidth={"xs"}
      open={isOpen}
      onClose={handleClose}
      fullWidth
      data-testid="dialog-popup"
    >
      <DialogTitle
        title="documentRelease.invoice.dateVInvoice.biller"
        onClose={handleClose}
      />
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleConnectContact({ id, snackbar, t, afterSuccess })}
          validationSchema={Yup.object().shape({
            contact_id,
          })}
        >
          {({
            values,
            errors,
            handleChange,
            setFieldValue,
            status,
            touched,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form noValidate>
              <Grid container spacing={8}>
                <Grid item lg={12} mt={5}>
                  <SelectOrCreateContactsAutocompleteField
                    {...{
                      errors,
                      values,
                      touched,
                      status,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                    required={true}
                    label={t("documentRelease.invoice.provider")}
                    placeholder={t("documentRelease.invoice.provider")}
                    sx={{ minHeight: "auto" }}
                  />
                </Grid>
                <Grid
                  item
                  lg={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    title={"Connect"}
                    type="button"
                    onClick={handleSubmit}
                    color="success"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
      {Snackbar}
    </Dialog>
  );
};

export default AssignContactModal;
