import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import UIButton from "../../../../../../../ui/button/Button";
import { Dialog } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { FormMessageInterface } from "../../../../../../../types/form";
import DialogTitle from "../../../../../../../ui/dialog/DialogTitle";
import TopicDateRangeFilter from "../../../../../components/dataRangeFilter";
import { EditInsuranceTopicTitleProps } from "../../types";
import { FormMessageInItState } from "../../../../../../../constants";
import FormAlert from "../../../../../../../ui/formAlert/FormAlert";
import { Card } from "../../topicListToolbar/topicCreate/styled";
import { initCreateTopicRange } from "../../topicListToolbar/topicCreate/utils";
import { editinsuranceTopicTitleFormInitData, handleSubmit } from "./utils";
import { epochToTimeStamp } from "../../../../../../../utils/date";

const EditInsuranceTopicTitle = ({
  handleDialog,
  to,
  topicId,
  from,
  type,
  fetchInsuranceTopics,
}: EditInsuranceTopicTitleProps): React.ReactElement => {
  const { t } = useTranslation();
  const [range, setRange] = useState<(Date | null)[]>(initCreateTopicRange);

  const [formMessage, setFormMessage] =
    React.useState<FormMessageInterface>(FormMessageInItState);
  useEffect(() => {
    setRange([
      new Date(epochToTimeStamp(from)),
      new Date(epochToTimeStamp(to)),
    ]);
  }, []);

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="xs"
      aria-labelledby="max-width-dialog-title"
      data-testid="create-topic-modal"
    >
      <DialogTitle title="insurance.editTopic" onClose={handleDialog} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card sx={{ height: 100 }}>
              <Formik
                initialValues={editinsuranceTopicTitleFormInitData}
                enableReinitialize
                onSubmit={handleSubmit({
                  setFormMessage,
                  handleDialog,
                  range,
                  type,
                  topicId,
                  fetchInsuranceTopics,
                })}
              >
                {({ handleSubmit, isSubmitting, status }) => (
                  <form style={{ position: "fixed" }}>
                    {formMessage.text && (
                      <FormAlert formMessage={formMessage} sx={{ mb: 1 }} />
                    )}
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <TopicDateRangeFilter
                          range={range}
                          navigateProp
                          setRange={setRange}
                          status={status}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={1} sx={{ mt: 6 }}>
                      <UIButton
                        title={t("update")}
                        type="button"
                        sx={{ width: "auto" }}
                        onClick={handleSubmit}
                        fullWidth
                        color="success"
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        testId="create-topic-save"
                      />
                    </Grid>
                  </form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditInsuranceTopicTitle;
