import React, { ReactElement, useEffect, useState } from "react";
import { DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import { ListDialogProps } from "./types";
import CloseButton from "../../ui/dialog/CloseButton";
import {
  DialogContainWrapper,
  DialogContent,
  DialogTitleContainer,
  ErrorBox,
} from "./styled";
import InsuranceList from "../properties/insurances/list/topicList/insuranceList";
import { ReleaseStatus } from "../../types/be/status";
import { useFetch } from "../../hooks/useFetch";
import { getInsuranceStatuses } from "../../api/insurance";
import { FormMessageErrorState } from "../../constants";
import Loader from "../Loader";
import { DOCUMENT_RELEASE_MODULE } from "../../types/be/releaseFlow";
import useDocumentRelease from "../properties/components/documentRelease/useDocumentRelease";

const InsuranceListDialog = ({
  visible,
  toggleListModal,
}: ListDialogProps): ReactElement => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [insurances] = useState(null);
  const {
    data: statuses,
    run: runStatuses,
    isLoading: isStatusesLoading,
    isError: isStatusesError,
  } = useFetch<ReleaseStatus[]>();

  const {
    configuration,
    isConfigurationLoading,
    handleReleaseDocument,
    handleForwardDocument,
    releasePermission,
    snackbar,
    loading: isDocumentReleaseLoading,
  } = useDocumentRelease(DOCUMENT_RELEASE_MODULE.INSURANCE);

  useEffect(() => {
    runStatuses(getInsuranceStatuses());
  }, []);
  /* istanbul ignore next */
  if (isStatusesError || error)
    return <ErrorBox formMessage={FormMessageErrorState} />;

  if (isStatusesLoading) return <Loader />;

  return (
    <Dialog fullWidth open={visible} onClose={toggleListModal} maxWidth="xl">
      <DialogTitle sx={{ pt: 0, pb: 0 }}>
        <DialogTitleContainer>
          <Typography
            variant="h6"
            component="div"
            justifyContent="space-around"
            width="100%"
          >
            {t("home.allInsurances")}
          </Typography>
          <CloseButton onClick={toggleListModal} />
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        <div>
          <DialogContainWrapper>
            <InsuranceList
              propertyId=""
              insurances={insurances}
              setError={setError}
              statuses={statuses!}
              useOnWidget={true}
              configuration={configuration}
              handleForwardDocument={handleForwardDocument}
              handleReleaseDocument={handleReleaseDocument}
              isConfigurationLoading={isConfigurationLoading}
              releasePermission={releasePermission}
              isDocumentReleaseLoading={isDocumentReleaseLoading}
            />
          </DialogContainWrapper>
        </div>
        {snackbar.Snackbar}
      </DialogContent>
    </Dialog>
  );
};

export default InsuranceListDialog;
