import { Grid, MenuItem, TextField } from "@mui/material";
import { Formik } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormAlert from "../../../ui/formAlert/FormAlert";
import { FormWrapper } from "../roles/styled";
import { FormMessageInterface } from "../../../types/form";
import {
  FormMessageInItState,
  PermissionsRead,
  UserRoles,
} from "../../../constants";
import Form from "./form";
import { Role as BERole } from "../../../types/be/role";
import { useFetch } from "../../../hooks/useFetch";
import { getRoles } from "../../../api/roles";
import Loader from "../../Loader";
import {
  ReleaseConfiguration as BEReleaseConfiguration,
  ReleaseModule as BEReleaseModule,
} from "../../../types/be/releaseFlow";
import { getConfiguration, getModules } from "../../../api/releaseFlow";
import { handleReleasePermissionFormSubmit } from "./utils";
import useAccessControl from "../../../hooks/useAccessControl";
import * as Yup from "yup";
import useValidation from "../../../hooks/useValidation";

/* istanbul ignore next */
const Release = (): ReactElement => {
  const { t } = useTranslation();
  const { can } = useAccessControl();
  const [module, setModule] = useState("invoice");

  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const {
    data: roles,
    isLoading: isRolesLoading,
    run: runRoles,
  } = useFetch<BERole[]>();
  const {
    data: modules,
    isLoading: isModulesLoading,
    run: runModules,
  } = useFetch<BEReleaseModule[]>();
  const {
    data: configuration,
    setState: setConfiguration,
    isLoading: isConfigurationLoading,
    run: runConfiguration,
  } = useFetch<BEReleaseConfiguration[]>();

  const { release_name_length } = useValidation();

  useEffect(() => {
    runRoles(getRoles());

    runModules(getModules());
  }, []);

  useEffect(() => {
    if (!module) return;
    runConfiguration(getConfiguration(`?module_code=${module}`));
  }, [module]);

  if (isRolesLoading || isModulesLoading || isConfigurationLoading)
    return <Loader />;

  return (
    <Grid container>
      {can(PermissionsRead.SETTINGS_RELEASE) && (
        <Grid item sm={12}>
          <FormWrapper>
            {formMessage.text && (
              <FormAlert
                formMessage={formMessage}
                sx={{ marginBottom: "10px" }}
              />
            )}

            {roles && configuration && modules && (
              <>
                <Grid container pt={4}>
                  <Grid item xs={2}>
                    <TextField
                      name="module"
                      fullWidth
                      select
                      size="small"
                      value={module}
                      label={t("settings.release.module")}
                      inputProps={{
                        "aria-label": "area-type-select",
                      }}
                      onChange={(e) => {
                        setModule(e.target.value);
                      }}
                    >
                      {modules.map(({ id, code, name }: BEReleaseModule) => (
                        <MenuItem key={id} value={code}>
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Formik
                  initialValues={configuration}
                  onSubmit={handleReleasePermissionFormSubmit({
                    setFormMessage,
                  })}
                  enableReinitialize
                  validationSchema={Yup.array().of(
                    Yup.object().shape({
                      name: release_name_length,
                    })
                  )}
                >
                  {(props): React.ReactElement => (
                    <>
                      <Grid container>
                        <Form
                          {...props}
                          roles={roles.sort(({ code }) =>
                            code === UserRoles.ADMIN ? -1 : 1
                          )}
                          code={modules.find((el) => el.code === module)?.name}
                          setConfiguration={setConfiguration}
                        />
                      </Grid>
                    </>
                  )}
                </Formik>
              </>
            )}
          </FormWrapper>
        </Grid>
      )}
    </Grid>
  );
};

export default Release;
