/*
 *  Companies
 */
import http from "../utils/http";
import { CreateCompanyFormData } from "../components/contacts/createCompanyModal/types";

export const createCompany = async (
  data: CreateCompanyFormData
): Promise<Response> => {
  return await http.post("/api/companies", data);
};

export const companiesAutocomplete = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/companies${params}`, true);
};

export const fetchPropertyManagementCompanies = async (
  params?: string
): Promise<Response> => {
  return await http.get(`/api/companies${params}`);
};

export const fetchUsersByCompanyId = async (id?: number): Promise<Response> => {
  return await http.get(`/api/companies/${id}/users`);
};
