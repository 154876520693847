import { FormikHelpers } from "formik";
import { FESollKonto, SubmitDebitAccountProps } from "./types";
import { HTTP_STATUS_CODES } from "../../../../../../types/server";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../constants";
import { mapBackendValidationErrors } from "../../../../../../utils/form";
import { createDebitAccount } from "../../../../../../api/sollconto";
import { getModifiedKeys } from "../../../../../../utils/common";

export const createInitSollKonto: FESollKonto = {
  property_id: "",
  konto: "",
  account_caption: "",
  language_id: "",
  account_label_long: "",
};

export const prepareSollKontoItemBeforeSubmission = (
  reqData: FESollKonto
): FESollKonto => {
  const newObj = { ...reqData };
  return newObj;
};

export const createNewDebitAccount = ({
  t,
  snackbar,
  onSuccess,
}: SubmitDebitAccountProps) => {
  return async (
    values: FESollKonto,
    { setStatus }: FormikHelpers<FESollKonto>
  ): Promise<void | Response> => {
    const response = await createDebitAccount(
      getModifiedKeys(
        createInitSollKonto,
        prepareSollKontoItemBeforeSubmission(values)
      )
    );
    const result = await response.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response?.status
      )
    ) {
      onSuccess();
      snackbar.success(
        t("documentRelease.sollkonto.debitAccountCreatedSuccessfully")
      );
    } else if (response?.status == HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(result.errors),
      });
    } else {
      snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
    }
  };
};
