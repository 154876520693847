import React, { ReactElement, useEffect, useState } from "react";
import { Formik } from "formik";
import { DialogContent, Stack } from "@mui/material";
import * as Yup from "yup";
import {
  FormMessageErrorState,
  FormMessageInItState,
} from "../../../constants";
import FormAlert from "../../../ui/formAlert/FormAlert";
import Loader from "../../Loader";
import { ErrorBox } from "../../home/styled";
import useValidation from "../../../hooks/useValidation";
import {
  handleSubmitNewEntryModalForm,
  initEmployeeScheduleRange,
} from "./utils";
import { NewEntryModalProps } from "./types";
import { EmployeeScheduleOption as BEEmployeeScheduleOption } from "../../../types/be.interfaces";
import { Dialog } from "./styled";
import Form from "./Form";
import useAccessControl from "../../../hooks/useAccessControl";
import DialogTitle from "../../../ui/dialog/DialogTitle";

const NewEntryModal = ({
  isNewEntryModalOpen,
  handleNewEntryModalClose,
  options,
  isLoading,
  isError,
  handleSuccessfulNewEntrySubmission,
}: NewEntryModalProps): ReactElement => {
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [optionId, setOptionId] = useState<number>();
  const [dateRange, setDateRange] = useState<(Date | null)[]>(
    initEmployeeScheduleRange
  );
  const [startDate, endDate] = dateRange;
  const { option_id } = useValidation();
  const { isRole, _user } = useAccessControl();

  useEffect(() => {
    !isNewEntryModalOpen && resetValues();
  }, [isNewEntryModalOpen]);

  const handleAutocompleteChange = (
    _: unknown,
    value: BEEmployeeScheduleOption | null
  ): void => setOptionId(value?.id);

  const resetValues = (): void => {
    setDateRange(initEmployeeScheduleRange);
    setOptionId(undefined);
  };

  const handleClear = (event: React.MouseEvent<SVGSVGElement>): void => {
    event.stopPropagation();
    setDateRange(initEmployeeScheduleRange);
  };

  return (
    <Dialog
      open={isNewEntryModalOpen}
      aria-labelledby="max-width-dialog-title"
      data-testid="new-entry-modal"
      onClose={handleNewEntryModalClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle title="create" onClose={handleNewEntryModalClose} />
      <DialogContent>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {isError ? (
              <ErrorBox formMessage={FormMessageErrorState} />
            ) : (
              <Stack>
                {formMessage.text && (
                  <FormAlert formMessage={formMessage} sx={{ mb: 10 }} />
                )}
                <Formik
                  enableReinitialize
                  initialValues={{
                    option_id: optionId,
                    dates: [{ date_from: startDate!, date_to: endDate! }],
                  }}
                  validationSchema={Yup.object().shape({
                    option_id,
                  })}
                  onSubmit={handleSubmitNewEntryModalForm({
                    setFormMessage,
                    handleSuccessfulNewEntrySubmission,
                    handleNewEntryModalClose,
                    isAdmin: isRole("admin"),
                    id: _user?.id,
                  })}
                >
                  {(props): ReactElement => (
                    <Form
                      handleNewEntryModalClose={handleNewEntryModalClose}
                      options={options}
                      handleAutocompleteChange={handleAutocompleteChange}
                      startDate={startDate}
                      endDate={endDate}
                      handleClear={handleClear}
                      setDateRange={setDateRange}
                      {...props}
                    />
                  )}
                </Formik>
              </Stack>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default NewEntryModal;
