import { FormikValues } from "formik/dist/types";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { mapBackendValidationErrors } from "../../../utils/form";
import { CreateCompanyFormData, CreateCompanyFormSubmitProps } from "./types";
import { createCompany } from "../../../api/companies";
import { HTTP_STATUS_CODES } from "../../../types/server";

export const createCompanyFormInitState = {
  title: "",
  company_type_id: "1",
};

const touchedInitState = {
  title: false,
};

export const handleCreateCompanyFormSubmit = ({
  setFormMessage,
  setInitialValues,
  handleCreateCompanyModalClose,
  setCompanyAutocompleteValue,
  setCompanyWasCreated,
}: CreateCompanyFormSubmitProps): ((
  values: CreateCompanyFormData,
  { setStatus, setSubmitting, setValues, setTouched }: FormikValues
) => Promise<void | Response>) => {
  return async (
    values: CreateCompanyFormData,
    { setStatus, setSubmitting, setTouched }: FormikValues
  ): Promise<void | Response> => {
    const response = await createCompany(values);
    const json = await response.json();
    if (response.status === HTTP_STATUS_CODES.CREATED) {
      setStatus({
        success: true,
        errors: {},
      });
      setCompanyWasCreated(true);
      setCompanyAutocompleteValue(json.data);
      setInitialValues(createCompanyFormInitState);
      handleCreateCompanyModalClose();
      setTouched(touchedInitState);
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
      setSubmitting(false);
    }
  };
};
