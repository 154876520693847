import http from "../utils/http";
import { IdPropType } from "../types/models";
import { LOI } from "../types/fe/loi";

export const getLOIList = async (params: string): Promise<Response> => {
  return await http.get(`/api/loi${params}`);
};

export const deleteLOIListItem = async ({
  id,
}: IdPropType): Promise<Response> => {
  return await http.delete(`/api/loi/${id}`);
};

export const getLOIHistory = async (params: string): Promise<Response> => {
  return await http.get(`/api/loi-history${params}`);
};
/* istanbul ignore next */
export const createLOI = async (data: LOI): Promise<Response> => {
  return await http.post(`/api/loi`, data);
};
/* istanbul ignore next */
export const editLOI = async (id: string, values: LOI): Promise<Response> => {
  return await http.put(`/api/loi/${id}`, values);
};
/* istanbul ignore next */
export const getLOI = async (id: string): Promise<Response> => {
  return await http.get(`/api/loi/${id}`);
};
/* istanbul ignore next */
export const previewLOIPdf = async (data: Partial<LOI>): Promise<Response> => {
  return await http.post(`/api/loi/preview-pdf`, data);
};
/* istanbul ignore next */
export const sendLOI = async (
  id: number,
  formData: FormData
): Promise<Response> => {
  return await http.post(`/api/loi/${id}/email`, formData);
};
