import { Grid } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import CommentsList from "../list/CommentsList";
import UsersList from "../list/userList";
import { TABS } from "./utils";
import camelCase from "lodash/camelCase";
import { StyledGrid, TabLink } from "./styled";
import { CommentsStatusIds } from "../../../../types/be/comment";
import { DialogContainWrapper } from "../../styled";

const TABS_LIST = [TABS.OPEN_COMMENTS, TABS.DONE, TABS.ALL];

const OpenCommentsWidgetTabs = (): ReactElement => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(TABS.OPEN_COMMENTS);
  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{ pl: 10, zIndex: 111 }}
        position="relative"
      >
        {TABS_LIST.map((tabListItem: TABS) => (
          <Grid key={tabListItem} item>
            <TabLink
              disabled={currentTab === tabListItem}
              onClick={() => {
                setCurrentTab(tabListItem);
              }}
              data-testid={`tab-${camelCase(tabListItem)}`}
            >
              {t(`comments.${camelCase(tabListItem)}`)}
            </TabLink>
          </Grid>
        ))}
      </Grid>
      <Grid container>
        <StyledGrid item>
          {currentTab === TABS.OPEN_COMMENTS && (
            <DialogContainWrapper>
              <CommentsList statusId={CommentsStatusIds.OPEN_COMMENTS} />
            </DialogContainWrapper>
          )}
          {currentTab === TABS.DONE && (
            <DialogContainWrapper>
              <CommentsList statusId={CommentsStatusIds.DONE} />
            </DialogContainWrapper>
          )}
          {currentTab === TABS.ALL && <UsersList />}
        </StyledGrid>
      </Grid>
    </>
  );
};

export default OpenCommentsWidgetTabs;
