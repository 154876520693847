import { Dialog, DialogContent } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import DialogTitle from "../../../../../../../ui/dialog/DialogTitle";
import { EditBKADialogProps } from "./types";
import { Formik } from "formik";
import { handleEditBKA, prepareInitialValues } from "./utils";
import { FormMessageInItState } from "../../../../../../../constants";
import { FormMessageInterface } from "../../../../../../../types/form";
import FormAlert from "../../../../../../../ui/formAlert/FormAlert";
import Form from "../create/Form";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../../../hooks/useFetch";
import { getCostsById } from "../../../../../../../api/bka";
import { prepareQueryParams } from "../../../../../../../utils/common";
import { BKACost } from "../../../../../../../types/be/tenant";

const EditBKADialog = ({
  open,
  onClose,
  data,
  afterEditBKA,
}: EditBKADialogProps): ReactElement => {
  const { t } = useTranslation();
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);

  const { data: costs, run: fetchCosts } = useFetch<BKACost[]>();

  const initialValues = prepareInitialValues(data, costs || []);

  useEffect(() => {
    const params = prepareQueryParams("", { bka_id: data.id });
    fetchCosts(getCostsById(params));
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="edit-bka-dialog"
    >
      <DialogTitle title={t("edit")} onClose={onClose} />
      <DialogContent sx={{ marginX: 5 }}>
        {formMessage.text ? (
          <FormAlert formMessage={formMessage} sx={{ mb: 2 }} />
        ) : null}
        <Formik
          initialValues={initialValues}
          onSubmit={handleEditBKA({
            setFormMessage,
            afterEditBKA,
            initialValues,
            id: data?.id,
          })}
          enableReinitialize
        >
          {(props) => <Form {...props} isEdit />}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EditBKADialog;
