import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Button from "../../../../../../../../../../ui/button/Button";
import { Formik } from "formik";
import SelectOrCreateContactsAutocompleteField from "../../../../../../../../../contacts/autocomplete";
import { handleUpdateFutureTenantContact } from "./utils";
import * as Yup from "yup";
import useValidation from "../../../../../../../../../../hooks/useValidation";
import { SelectOrCreateContactModalProps } from "../types";
import DialogTitle from "../../../../../../../../../../ui/dialog/DialogTitle";
import ContactInfo from "./ContactInfo";
/*istanbul ignore next*/
export const SelectOrCreateContactModal = ({
  isContactModalOpened,
  setIsContactModalOpened,
  tenantHistory,
  refetchData,
}: SelectOrCreateContactModalProps): ReactElement => {
  const { t } = useTranslation();
  const { contact_id: contactIdValidation } = useValidation();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState<{
    id: number;
    contact_id: number | null;
  } | null>();

  useEffect(() => {
    if (tenantHistory) {
      const { id, contact } = tenantHistory;
      setFormData({
        id,
        contact_id: contact ? contact.id : null,
      });
    }
  }, [tenantHistory, isOpen]);

  useEffect(() => {
    setIsOpen(isContactModalOpened);
  }, [isContactModalOpened]);

  const onClose = (): void => {
    setIsOpen(false);
    setIsContactModalOpened(false);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="max-width-dialog-title"
        data-testid="select-create-contact-modal"
        onClose={onClose}
      >
        <DialogTitle title="contact" onClose={onClose} />
        <DialogContent>
          <Grid container spacing={2} justifyContent="top" alignItems="top">
            <Grid item xs={12}>
              {formData && (
                <Formik
                  initialValues={formData}
                  validationSchema={Yup.object().shape({
                    contact_id: contactIdValidation,
                  })}
                  enableReinitialize
                  onSubmit={handleUpdateFutureTenantContact(
                    onClose,
                    refetchData
                  )}
                >
                  {({
                    errors,
                    values,
                    touched,
                    status,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    isSubmitting,
                    submitForm,
                  }): React.ReactElement => (
                    <>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="top"
                        alignItems="top"
                      >
                        <Grid item xs={12}>
                          <SelectOrCreateContactsAutocompleteField
                            {...{
                              errors,
                              values,
                              touched,
                              status,
                              handleBlur,
                              handleChange,
                              setFieldValue,
                            }}
                            required={true}
                            placeholder={t("createContact.searchContact")}
                          />
                        </Grid>
                        {tenantHistory?.contact && (
                          <ContactInfo {...tenantHistory.contact} />
                        )}
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        gap={2}
                      >
                        <Grid item>
                          <Button
                            onClick={onClose}
                            title={t("cancel")}
                            variant={"text"}
                            type="button"
                            testId="dataItems-cancel-contact-select"
                            disabled={isSubmitting}
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            title={t("save")}
                            onClick={() => {
                              submitForm();
                            }}
                            disabled={isSubmitting}
                            isLoading={isSubmitting}
                            color="success"
                            testId="dataItems-save-contact-select"
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Formik>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SelectOrCreateContactModal;
