import React, { useState, ReactElement, useEffect, useCallback } from "react";
import { PDFViewerProps } from "./types";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { base64ToUrl, getFileFromId } from "./utils";
import Viewer from "./viewer";
import { COMPUTED_HEIGHT_RATIO } from "../../constants";
import { Skeleton, Stack, Typography } from "@mui/material";
import { NoPdfBox } from "./styled";
import { useTranslation } from "react-i18next";
import useIsMounted from "../../hooks/useIsMounted";
/* istanbul ignore file */
export default function PdfViewer({
  file,
  containerRef,
}: PDFViewerProps): ReactElement {
  const { t } = useTranslation();
  const [url, setUrl] = useState("");
  const computedHeight =
    (containerRef?.current?.offsetWidth || 0) * COMPUTED_HEIGHT_RATIO;
  const computedWidth = containerRef?.current?.offsetWidth || 0;
  useEffect(() => {
    setFileUrl();
  }, [file]);

  const isMounted = useIsMounted();

  const setFileUrl = useCallback(async (): Promise<void> => {
    if (file.url) {
      setUrl(file.url);
    } else if (file.base64) {
      setUrl(base64ToUrl(file.base64));
    } else if (file.fileId) {
      const fileUrl = await getFileFromId(file.fileId);
      isMounted() && setUrl(fileUrl);
    }
  }, [file, isMounted]);

  return (
    <>
      {url ? (
        <Viewer
          url={url}
          height={computedHeight}
          width={computedWidth}
        ></Viewer>
      ) : (
        <NoPdfBox>
          <Stack spacing={1} mt={5} width={"100%"}>
            <Typography textAlign={"center"} variant="h4">
              {t("pdfViewer.noPdfError")}
            </Typography>
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem" }}
              animation={false}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={200}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem" }}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem" }}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem" }}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem" }}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem" }}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem" }}
              animation={false}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem" }}
              animation={false}
            />
          </Stack>
        </NoPdfBox>
      )}
    </>
  );
}
