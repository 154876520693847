import { Role } from "./role";

export enum DOCUMENT_RELEASE_MODULE {
  INVOICE = "invoice",
  CONTRACT = "contract",
  OFFER = "offer",
  INSURANCE = "insurance",
  OFFER_TOPIC = "topic",
  INSURANCE_TOPIC = "insurance_topic",
}

export enum DOCUMENT_RELEASE_LEVEL {
  FIRST_LEVEL = "I",
  SECOND_LEVEL = "II",
  THIRD_LEVEL = "III",
  FOURTH_LEVEL = "IV",
}

export type ReleaseModule = {
  id: number;
  code: DOCUMENT_RELEASE_MODULE;
  name: string;
};

export interface ReleaseLevel {
  id: number;
  code: DOCUMENT_RELEASE_LEVEL;
  name: string;
}

export interface ReleaseConfiguration {
  id: number;
  module: ReleaseModule;
  level: ReleaseLevel;
  roles: Role[];
  name: string;
  is_active: boolean;
}
