/* istanbul ignore file */
import React, { ReactElement, useContext, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DatePicker from "../../../../../../../ui/datePicker";
import Text from "../../../../../../../ui/formsFields/text";
import Button from "../../../../../../../ui/button/Button";
import {
  EMPTY_DATA,
  MAX_PROPERTY_IMAGES_LIMIT,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../../../../constants";
import FileUploader from "../../../../../../../ui/fileUploader";
import { updateAreaWithFormData } from "../../../../../../../api/area";
import { HTTP_STATUS_CODES } from "../../../../../../../types/server";
import { AreaEditContext } from "../../../AreaEditContext";
import {
  EditInfoCard,
  RentedInfoContainer,
  SmallLightText,
} from "../../rentedForm/styled";
import { Edit } from "@mui/icons-material";
import { formatTimestamp } from "../../../../../../../utils/date";
import { InfoFormProps } from "./types";

const Form = ({
  handleSubmit,
  status,
  values,
  setFieldValue,
  isSubmitting,
  snackbar,
  resetForm,
  isEditInfo,
  setIsEditInfo,
}: InfoFormProps): ReactElement => {
  const areaEditContext = useContext(AreaEditContext);
  const { t } = useTranslation();

  const [isFileChanging, setIsFileChanging] = useState(false);

  /* istanbul ignore next */
  const isMaxImagesLimitReached = (newFilesLength: number): boolean =>
    values?.images_not_rented.length >= MAX_PROPERTY_IMAGES_LIMIT ||
    values?.images_not_rented.length + newFilesLength >
      MAX_PROPERTY_IMAGES_LIMIT;

  /* istanbul ignore next */
  const handleOnFileChange = async (filesToUpload: File[]): Promise<void> => {
    if (isMaxImagesLimitReached(filesToUpload.length)) {
      snackbar.error(t("property.general.fileUploadLimit"));
      return;
    }
    setIsFileChanging(true);
    const formData = new FormData();
    formData.append("_method", "PUT");
    filesToUpload.forEach((file) => {
      formData.append("images_not_rented[]", file);
    });

    const response = await updateAreaWithFormData(String(values?.id), formData);

    if (response?.status !== HTTP_STATUS_CODES.OK) {
      snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
    } else {
      snackbar.success(t("tenantRequest.uploaded"));
      areaEditContext?.fetchArea && areaEditContext?.fetchArea();
    }
    setIsFileChanging(false);
  };

  /* istanbul ignore next */
  const handleOnFileDelete = (filesToUpload: File[]): void => {
    setFieldValue("images_not_rented", [...filesToUpload]);
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={7.5} sx={{ py: 2 }}>
        <Grid item sm={12}>
          <EditInfoCard className="edit-card">
            {isEditInfo ? (
              <Grid container>
                <RentedInfoContainer item sm={6}>
                  <Text
                    size="medium"
                    label={t("property.area.name")}
                    name="name"
                    fullWidth
                    status={status}
                    value={values?.name}
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={6}>
                  <DatePicker
                    name="empty_space_since"
                    label={t("tenant.emptySpaceSince")}
                    status={status}
                    size="medium"
                    disableFuture
                    fullWidth
                    value={values.empty_space_since}
                    minHeight={"50px"}
                    sx={{ minHeight: "auto" }}
                  />
                </RentedInfoContainer>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent={"flex-end"}
                  gap={4}
                  m={4}
                >
                  <Button
                    title={t("cancel")}
                    variant="text"
                    type="button"
                    color="primary"
                    size="large"
                    onClick={() => {
                      resetForm();
                      setIsEditInfo(false);
                    }}
                  />
                  <Button
                    title={t("save")}
                    color="success"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Edit
                  className="edit-icon"
                  data-testid="edit-info-icon"
                  onClick={() => setIsEditInfo(true)}
                />
                <Grid container textAlign={"center"}>
                  <RentedInfoContainer item sm={6}>
                    <Typography variant="h4">
                      {values?.name || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>{t("property.area.name")}</SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4}>
                    <Typography variant="h4">
                      {values?.empty_space_since
                        ? formatTimestamp(values?.empty_space_since / 1000)
                        : EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("tenant.emptySpaceSince")}
                    </SmallLightText>
                  </RentedInfoContainer>
                </Grid>
              </>
            )}
          </EditInfoCard>
        </Grid>
        <Grid item sm={12}>
          <EditInfoCard className="edit-card">
            <FileUploader
              accept="image"
              isDisabled={isSubmitting && isFileChanging}
              onFileChange={handleOnFileChange}
              onFileDelete={handleOnFileDelete}
              filesInitState={values?.images_not_rented}
              uploadDisabled={
                (isSubmitting && isFileChanging) ||
                values?.images_not_rented?.length >= MAX_PROPERTY_IMAGES_LIMIT
              }
              maxAllowed={MAX_PROPERTY_IMAGES_LIMIT}
              placeHolderText={t("property.imageUploadPlaceHolder")}
              uploadButtonText={t("property.uploadFiles")}
            />
          </EditInfoCard>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
