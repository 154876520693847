import { FormikValues } from "formik";
import {
  HandleChangeTotalUserVacationsProps,
  VacationUpdateTotalData,
} from "./types";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../constants";
import { updateUserVacation } from "../../../../api/vacationRequests";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { HTTP_STATUS_CODES } from "../../../../types/server";

export const handleUpdateUserTotalVacation = ({
  id,
  setSnackbarData,
  t,
}: HandleChangeTotalUserVacationsProps) => {
  return async (
    data: VacationUpdateTotalData,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const formData = new FormData();
    formData.append("total", String(data?.total));
    formData.append("_method", "PUT");
    const response = await updateUserVacation(id, formData);
    const json = await response.json();
    if (response.ok) {
      setStatus({
        success: true,
        errors: {},
      });
      setSnackbarData({
        visible: true,
        text: t("vacationRequests.totalVacationWasChangedSuccessfully"),
        color: "success",
      });
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setSnackbarData({
        visible: true,
        text: t(SOMETHING_WENT_WRONG_ERROR),
        color: "error",
      });
      setSubmitting(false);
    }
  };
};
