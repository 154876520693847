import { FormikValues } from "formik";
import { TFunction } from "i18next";
import moment from "moment";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../../constants";
import { Area } from "../../../../../../../types/be/area";
import { HTTP_STATUS_CODES } from "../../../../../../../types/server";
import { Snackbar } from "../../../../../../../ui/snackbar1/useSnackbar";
import {
  getModifiedKeys,
  parseNumber,
} from "../../../../../../../utils/common";
import { mapBackendValidationErrors } from "../../../../../../../utils/form";
import { updateArea } from "../../../../../../../api/area";

const prepareDataBeforeSubmit = (data: Area): Area => {
  return {
    ...data,
    empty_space_since: data?.empty_space_since
      ? moment(data?.empty_space_since).unix()
      : null,
    empty_space_eur: data?.empty_space_eur
      ? parseNumber(data?.empty_space_eur)
      : null,
  };
};

export const handleUpdateEmptyAreaInformation = (
  initialValues: Area,
  t: TFunction<"translation", undefined, "translation">,
  onSuccessCallback: () => void,
  snackBar: Snackbar
) => {
  return async (
    values: Area,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const data = prepareDataBeforeSubmit(
      getModifiedKeys(initialValues, values, [
        "empty_space_since",
        "empty_space_eur",
      ])
    );
    const response = await updateArea(String(values?.id), data);
    const json = await response.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      snackBar.success(
        t("property.emptyAreaForm.informationUpdatedSuccessfully")
      );
      onSuccessCallback();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
    } else {
      snackBar.error(t(SOMETHING_WENT_WRONG_ERROR));
    }
    setSubmitting(false);
  };
};
