/* istanbul ignore file */

import React, { ReactElement, useEffect, useState } from "react";
import Button from "../../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Snackbar from "../../../../ui/Snackbar";
import { Form, FormikValues } from "formik";
import { Text as TextField } from "../../../../ui/formsFields/text";
import { FormSectionTitle } from "../../../contacts/styled";

function OperationAndUseForm({
  handleSubmit,
  isSubmitting,
  status,
}: FormikValues): ReactElement {
  const { t } = useTranslation();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  const handleSnackbarClose = (): void => setIsSnackbarVisible(false);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormSectionTitle>
          {t("property.operationAndUse.nonApportionableAncillaryCosts")}
        </FormSectionTitle>
        <Grid container sx={{ mb: 3, mt: 2 }} spacing={3}>
          <Grid item xs={4}>
            <TextField
              size={"small"}
              name="maintenance_non_apportionable"
              label={t("property.operationAndUse.maintenanceNonApportionable")}
              status={status}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size={"small"}
              name="operating_costs_non_revenueable"
              label={t("property.operationAndUse.operatingCostsNonRevenueable")}
              status={status}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size={"small"}
              name="property_management_non_revenueable"
              label={t(
                "property.operationAndUse.propertyManagementNonRevenueable"
              )}
              status={status}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size={"small"}
              name="depreciation"
              label={t("property.operationAndUse.depreciation")}
              status={status}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent={"end"}>
          <Grid item>
            <Button
              title={t("update")}
              type="submit"
              color="success"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            />
          </Grid>
        </Grid>
      </Form>
      <Snackbar
        open={isSnackbarVisible}
        message={t("property.propertyWasSuccessfullyUpdated")}
        color="success"
        handleClose={handleSnackbarClose}
      />
    </>
  );
}

export default OperationAndUseForm;
