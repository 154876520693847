/* istanbul ignore file */

import styled from "styled-components/macro";
import { Chip as MuiChip, Box } from "@mui/material";

export const SelectBox = styled(Box)`
  gap: ${(props): string => props.theme.spacing(0.5)};
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Chip = styled(MuiChip)`
  height: ${(props): string => props.theme.spacing(5)};
`;
