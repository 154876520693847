import styled from "styled-components/macro";
import { TextField as MuiTextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog as MuiDialog } from "@mui/material";

export const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
  z-index: 1000111;
`;

export const TextField = styled(MuiTextField)`
  min-height: ${(props): string => props.theme.spacing(18.5)};
  margin-top: ${(props): string => props.theme.spacing(1.5)};
`;

export const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    overflow-y: hidden;
  }
`;
