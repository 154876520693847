/* istanbul ignore file */

import moment, { Moment } from "moment";
import { FormikValues } from "formik";
import { updateBKA } from "../../../../api/bka";
import {
  savePropertyAdvertisement,
  storeDunning,
  updateDunning,
  updatePropertyAdvertisement,
} from "../../../../api/propertyTenant";
import { mapBackendValidationErrors } from "../../../../utils/form";
import {
  HandleUpdateTenantProps,
  TabAreaControls,
  handleUpdateFutureTenantProps,
} from "./types";
import {
  replaceNullOrUndefinedByEmptyString,
  getModifiedKeys,
  constructFormData,
  replaceStringByParsedNumber,
  replaceEntityById,
  parseNumber,
} from "../../../../utils/common";
import {
  ONLY_ONE_FUTURE_TENANT,
  SOMETHING_WENT_WRONG_ERROR,
  TENANT_ID_PARAM,
} from "../../../../constants";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { Dispatch, SetStateAction } from "react";
import {
  Advertisement,
  BKA,
  DunningLetters,
} from "../../../../types/be/tenant";
import { FormMessageInterface } from "../../../../types/form";
import { replaceMomentDateByTimestamp } from "../../../../utils/date";
import { Area, AreaRenter } from "../../../../types/be/area";
import { Area as FEArea } from "../../../../types/be/area";
import {
  TenantHistoryProps,
  TenantHistoryTableData,
  payloadProps,
} from "./tabs/history/list/types";
import {
  updateAreaById,
  updateTenant,
  createAreaTenantWithFormData,
  updateAreaTenant,
} from "../../../../api/area";
import isNil from "lodash/isNil";
import { futureTenantInitialValueProps } from "./tabs/rentedForm/types";

export const simpleTabProps = function (index: number): TabAreaControls {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const convertTimestampToDate = (
  date: number | null | Moment
): Moment | null => {
  return typeof date === "number"
    ? moment(moment.unix(date), "MM/DD/YYYY")
    : null;
};

export enum TenantForms {
  Renter = "renter",
  Bka = "bka",
  DunningLetters = "dunningLetters",
  MobileUser = "mobileUser",
}

export const advertisementInitialValue: Advertisement = {
  is_immoscout_enabled: false,
  immoscout_url: "",
  is_immowelt_enabled: false,
  immowelt_url: "",
  is_ebay_enabled: false,
  ebay_url: "",
  is_agent_commissioned_enabled: false,
  agent_commissioned_url: "",
};

export const dunningLettersInitialValues: DunningLetters = {
  is_warning_enabled: false,
  warning_text: "",
  is_legal_dunning_enabled: false,
  legal_dunning_text: "",
  is_installment_enabled: false,
  installment_text: "",
  warning_file: null,
  legal_dunning_file: null,
  installment_file: null,
};

const saveOrUpdateAdvertisement = async (
  values: Area
): Promise<number | undefined> => {
  let response;
  if (values?.active_tenant?.advertisement?.id) {
    response = await updatePropertyAdvertisement(
      values?.active_tenant?.advertisement,
      String(values?.active_tenant?.id)
    );
  } else {
    if (values?.active_tenant?.advertisement)
      response = await savePropertyAdvertisement(
        values?.active_tenant?.advertisement,
        String(values?.active_tenant?.id)
      );
  }

  return response?.status;
};

export const prepareExtendedForm = (data: TenantHistoryTableData): any => {
  return {
    ...data,
    rent_start: convertTimestampToDate(data.rent_start),
    rent_end: convertTimestampToDate(data?.rent_end),
  };
};

export const prepareForm = (data: Area): any => {
  return {
    ...data,
    last_modernization: convertTimestampToDate(data.last_modernization),
    empty_space_since: convertTimestampToDate(data.empty_space_since),
    contact: data.active_tenant?.contact,
    contact_id: data.active_tenant?.contact?.id,
    rental_contract_file: data.active_tenant?.rental_contract_file,
    index: data.index ?? "",
    active_tenant: data.active_tenant
      ? {
          ...data.active_tenant,
          rent_start: convertTimestampToDate(data.active_tenant?.rent_start),
          rent_end: convertTimestampToDate(data?.active_tenant?.rent_end),
          cancellation_until: convertTimestampToDate(
            data?.active_tenant?.cancellation_until
          ),
          tenants: data.active_tenant?.tenants.map((el) => el.id),
          closing_tenant_contract: convertTimestampToDate(
            data?.active_tenant?.closing_tenant_contract
          ),
          special_cancellation_right: convertTimestampToDate(
            data?.active_tenant?.special_cancellation_right
          ),
          advertisement: data?.active_tenant?.advertisement
            ? {
                ...replaceNullOrUndefinedByEmptyString(
                  data?.active_tenant?.advertisement
                ),
              }
            : advertisementInitialValue,
          dunning_letter: data?.active_tenant?.dunning_letter
            ? {
                ...replaceNullOrUndefinedByEmptyString(
                  data?.active_tenant?.dunning_letter
                ),
              }
            : dunningLettersInitialValues,
          bka: data.active_tenant.bka
            ? data.active_tenant.bka?.map((item) => ({
                ...item,
                date: convertTimestampToDate(item?.date),
                date_bka: convertTimestampToDate(item?.date_bka),
              }))
            : null,
        }
      : null,
  };
};
export const prepareFormForUpdateFutureTenant = (
  data: TenantHistoryProps | null
): any => {
  return {
    ...data,
    contact: data?.contact,
    active_tenant: data
      ? {
          ...data,
          rent_start: convertTimestampToDate(data?.rent_start),
          rent_end: convertTimestampToDate(data?.rent_end),
          cancellation_until: convertTimestampToDate(data?.cancellation_until),
          closing_tenant_contract: convertTimestampToDate(
            data?.closing_tenant_contract
          ),
          special_cancellation_right: convertTimestampToDate(
            data?.special_cancellation_right
          ),
        }
      : null,
  };
};

export const prepareFormBeforeUpdate = (payload: Area): any => {
  return {
    ...payload,
    tenant_id: payload.active_tenant?.tenants,
    square_meters: parseNumber(payload.square_meters),
    empty_space_since: payload?.empty_space_since
      ? moment(payload?.empty_space_since).unix()
      : null,
    empty_space_eur: !isNil(payload?.empty_space_eur)
      ? parseNumber(payload?.empty_space_eur)
      : null,
    ...replaceEntityById({
      category_id: payload?.category,
      usage_id: payload?.usage,
      type_id: payload?.type,
    }),
    asset_managers: (payload.asset_managers /*istanbul ignore next*/ ?? []).map(
      ({ id }) => id
    ),
    active_tenant: payload?.active_tenant
      ? {
          ...payload?.active_tenant,
          ...replaceMomentDateByTimestamp({
            rent_start: payload?.active_tenant?.rent_start,
            rent_end: payload?.active_tenant?.rent_end,
            cancellation_until: payload?.active_tenant?.cancellation_until,
            closing_tenant_contract:
              payload?.active_tenant?.closing_tenant_contract,
            special_cancellation_right:
              payload?.active_tenant?.special_cancellation_right,
          }),
          rental_contract_file: payload?.rental_contract_file,
          ...replaceStringByParsedNumber({
            area: payload.active_tenant.area,
            net_rent_per_month: payload.active_tenant.net_rent_per_month,
            rent_free: payload.active_tenant.rent_free,
            others_incomings_eur: payload.active_tenant.others_incomings_eur,
            rent_eur: payload.active_tenant.rent_eur,
            deposit: payload.active_tenant.deposit,
            costs_for_modification:
              payload.active_tenant.costs_for_modification,
            vacancy_in_eur: payload.active_tenant.vacancy_in_eur,
            vacancy_in_sq: payload.active_tenant.vacancy_in_sq,
            tax: payload.active_tenant.tax,
            additional_costs_per_month:
              payload.active_tenant.additional_costs_per_month,
          }),
          new: Boolean(payload.active_tenant.new),
          contact_id: payload?.contact_id,
        }
      : null,
  };
};

export const handleUpdateArea = (
  initialValues: FEArea,
  setFormMessage: Dispatch<SetStateAction<FormMessageInterface>>,
  onSuccessCallback: () => void
) => {
  return async (values: Area): Promise<void> => {
    const updateValue = {
      ...values,
      ...replaceMomentDateByTimestamp({
        last_modernization: values?.last_modernization,
      }),
    };
    const payload = prepareFormBeforeUpdate(
      getModifiedKeys(initialValues, updateValue, [
        "id",
        "property",
        "category",
        "usage",
        "type",
        "square_meters",
        "empty_space_eur",
      ])
    );

    const responses = [];
    const response = await updateAreaById(payload);
    responses.push(response.status);

    let advertiseResponse;
    if (initialValues?.active_tenant) {
      advertiseResponse = await saveOrUpdateAdvertisement(values);
      responses.push(advertiseResponse);
    }

    if (
      responses.every(
        (item) =>
          item === HTTP_STATUS_CODES.OK || item === HTTP_STATUS_CODES.CREATED
      )
    ) {
      onSuccessCallback();
    } else {
      setFormMessage({ text: SOMETHING_WENT_WRONG_ERROR, type: "error" });
    }
  };
};

export const handleCreateFutureTenant = (
  initialValues: futureTenantInitialValueProps,
  setFormMessage: Dispatch<SetStateAction<FormMessageInterface>>,
  onSuccessCallback: () => void,
  propertyId?: string,
  areaId?: string
) => {
  return async (values: Area, { setStatus }: FormikValues): Promise<void> => {
    const payload = prepareFormBeforeUpdate({
      ...values,
    });

    const getModifiedKeysData = replaceNullOrUndefinedByEmptyString(
      getModifiedKeys(initialValues?.active_tenant, payload?.active_tenant)
    );

    const formData = constructFormData(getModifiedKeysData);

    formData.append("area_id", areaId!);
    formData.append("property_id", propertyId!);

    const response = await createAreaTenantWithFormData(formData);

    if (response.status === HTTP_STATUS_CODES.CREATED) {
      onSuccessCallback();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      const { errors } = await response.json();
      const mappedErrors: Record<string, string[]> = {};
      Object.keys(errors).forEach((key) => {
        mappedErrors[`active_tenant.${key}`] = errors[key];
      });

      setStatus({
        success: false,
        errors: mapBackendValidationErrors(mappedErrors),
      });
      errors.rent_end &&
        setFormMessage({ text: ONLY_ONE_FUTURE_TENANT, type: "error" });
    } else {
      setFormMessage({ text: SOMETHING_WENT_WRONG_ERROR, type: "error" });
    }
  };
};

export const handleUpdateTenant = ({
  initialValues,
  setFormMessage,
  onSuccessCallback,
  saveOnlyTenantsButtonClick,
  setSaveOnlyTenantsButtonClick,
  setIsEditInfo,
  setIsEditTenant,
  setIsUploadingRentalContract,
}: HandleUpdateTenantProps) => {
  return async (values: Area, { setStatus }: FormikValues): Promise<void> => {
    const payload = prepareFormBeforeUpdate(values);
    if (!initialValues?.active_tenant || !payload?.active_tenant) return;
    const getModifiedKeysData = replaceNullOrUndefinedByEmptyString(
      getModifiedKeys(initialValues?.active_tenant, payload?.active_tenant)
    );
    const formData = constructFormData(getModifiedKeysData);

    const onlyTenantUpdate = new FormData();
    onlyTenantUpdate.append("contact_id", payload.active_tenant.contact_id);
    formData.append("contact_id", payload.active_tenant.contact_id);

    onlyTenantUpdate.append("tenant_id", "");
    formData.append("tenant_id", "");

    payload.active_tenant.tenants.forEach((el: number) => {
      formData.append(TENANT_ID_PARAM, el.toString());
      onlyTenantUpdate.append(TENANT_ID_PARAM, el.toString());
    });

    const response = await updateTenant(
      String(initialValues?.id),
      saveOnlyTenantsButtonClick ? onlyTenantUpdate : formData
    );
    setSaveOnlyTenantsButtonClick(false);
    setIsEditInfo(false);
    setIsEditTenant(false);
    setIsUploadingRentalContract(false);

    if (response.status === HTTP_STATUS_CODES.OK) {
      onSuccessCallback();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      const { errors } = await response.json();
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(errors),
      });
    } else {
      setFormMessage({ text: SOMETHING_WENT_WRONG_ERROR, type: "error" });
    }
  };
};

export const prepareFormBeforeUpdateFutureTenant = (
  payload: payloadProps
): any => {
  return {
    ...payload?.active_tenant,
    ...replaceMomentDateByTimestamp({
      rent_start: payload?.active_tenant?.rent_start,
      rent_end: payload?.active_tenant?.rent_end,
      cancellation_until: payload?.active_tenant?.cancellation_until,
      closing_tenant_contract: payload?.active_tenant?.closing_tenant_contract,
      special_cancellation_right:
        payload?.active_tenant?.special_cancellation_right,
    }),
    ...replaceStringByParsedNumber({
      net_rent_per_month: payload.active_tenant.net_rent_per_month,
      rent_free: payload.active_tenant.rent_free,
      others_incomings_eur: payload.active_tenant.others_incomings_eur,
      rent_eur: payload.active_tenant.rent_eur,
      deposit: payload.active_tenant.deposit,
      costs_for_modification: payload.active_tenant.costs_for_modification,
      vacancy_in_eur: payload.active_tenant.vacancy_in_eur,
      vacancy_in_sq: payload.active_tenant.vacancy_in_sq,
      tax: payload.active_tenant.tax,
      additional_costs_per_month:
        payload.active_tenant.additional_costs_per_month,
    }),
    contact_id: payload?.contact_id,
  };
};

export const handleUpdateFutureTenant = ({
  initialValues: rowData,
  onSuccessCallback,
  setFormMessage,
}: handleUpdateFutureTenantProps) => {
  return async (
    values: payloadProps,
    { setStatus }: FormikValues
  ): Promise<void> => {
    const updatedValue = prepareFormBeforeUpdateFutureTenant(values);
    delete updatedValue.active_tenant;
    const data = getModifiedKeys(rowData, updatedValue);

    if (data?.contact_id === rowData?.contact?.id) {
      delete data?.contact_id;
    }
    const payload = constructFormData(data);
    payload.append("_method", "PUT");

    const response = await updateAreaTenant(values.id, payload);
    if (response.status === HTTP_STATUS_CODES.OK) {
      onSuccessCallback();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      const { errors } = await response.json();
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(errors),
      });
    } else {
      setFormMessage({ text: SOMETHING_WENT_WRONG_ERROR, type: "error" });
    }
  };
};

export const saveOrUpdateDunning = (
  initialValues: Area,
  setFormMessage: Dispatch<SetStateAction<FormMessageInterface>>,
  onSuccessCallback: () => void
) => {
  return async (values: Area, { setStatus }: FormikValues): Promise<void> => {
    let response;
    const initialDunning = initialValues?.active_tenant?.dunning_letter;
    const updatedDunning = values?.active_tenant?.dunning_letter;

    if (initialDunning && updatedDunning) {
      const data = getModifiedKeys(initialDunning, updatedDunning);
      const payload = constructFormData(data);

      if (values?.active_tenant?.dunning_letter?.id) {
        payload.append("_method", "PUT");
        response = await updateDunning(
          String(values?.active_tenant?.id),
          values?.active_tenant?.dunning_letter?.id,
          payload
        );
      } else {
        response = await storeDunning(
          String(values?.active_tenant?.id),
          payload
        );
      }
    }

    if (
      response?.status === HTTP_STATUS_CODES.OK ||
      response?.status === HTTP_STATUS_CODES.CREATED
    ) {
      onSuccessCallback();
    } else if (response?.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      const errors = await response.json();

      setStatus({
        success: false,
        errors: mapBackendValidationErrors(errors),
      });
    } else {
      setFormMessage({ text: SOMETHING_WENT_WRONG_ERROR, type: "error" });
    }
  };
};

export const handleUpdateBKA = (
  initialValues: BKA,
  setFormMessage: Dispatch<SetStateAction<FormMessageInterface>>,
  onSuccessCallback: () => void
) => {
  return async (
    values: BKA,
    { setSubmitting, setStatus }: FormikValues
  ): Promise<void> => {
    const data = {
      ...values,
      ...replaceMomentDateByTimestamp({
        date_bka: values.date_bka,
        date: values.date,
      }),
      ...replaceStringByParsedNumber({ amount: values.amount }),
    };

    const formData = constructFormData(getModifiedKeys(initialValues, data));

    formData.append("_method", "PUT");
    const response = await updateBKA(formData, values.id);

    if (response.status === HTTP_STATUS_CODES.OK) {
      onSuccessCallback();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      const { errors } = await response.json();

      setStatus({
        success: false,
        errors: mapBackendValidationErrors(errors),
      });
    } else {
      setFormMessage({ text: SOMETHING_WENT_WRONG_ERROR, type: "error" });
    }

    setSubmitting(false);
  };
};

/* istanbul ignore next */
export const handleDateDisable = (
  rentStart?: number
): ((date: Date) => boolean) => {
  const startYear = moment(rentStart).year();
  const startMonth = moment(rentStart).month();
  const startDate = moment(rentStart).date();

  return (date: Date) => {
    const dataYear = moment(date).year();
    const dataMonth = moment(date).month();
    const dataDate = moment(date).date();

    return (
      (dataYear <= startYear && dataMonth < startMonth) ||
      (dataMonth === startMonth && dataDate < startDate)
    );
  };
};

/* istanbul ignore next */
export const handleDisableYear = (
  rentStart?: number
): ((date: Date) => boolean) => {
  return (date: Date) => {
    return moment(date).year() < moment(rentStart).year();
  };
};

const checkIsRentStartDateDisabledForFutureTenant = (
  renters: AreaRenter[],
  calendarDateTime: number,
  todayTime: number
): boolean => {
  return renters.some((item) => {
    const renterRentEndTime = (item.rent_end || 0) * 1000,
      renterRentStartTime = item.rent_start * 1000;

    return (
      (renterRentEndTime !== 0
        ? calendarDateTime <= renterRentEndTime
        : calendarDateTime <= renterRentStartTime) ||
      calendarDateTime <= todayTime
    );
  });
};

const checkIsRentStartDateDisabledForCurrentTenant = (
  renters: AreaRenter[],
  rentStartTime: number,
  calendarDateTime: number,
  todayTime: number
): boolean => {
  return renters.some((item) => {
    const renterRentEndTime = (item.rent_end || 0) * 1000;
    return (
      (renterRentEndTime
        ? renterRentEndTime < rentStartTime &&
          renterRentEndTime >= calendarDateTime
        : false) || calendarDateTime > todayTime
    );
  });
};

export const getRentStartDisabledDates =
  (renters: AreaRenter[], rent_start: Date, isFutureTenant?: boolean | null) =>
  (date: Date): boolean => {
    const calendarDateTime = date?.getTime(),
      todayTime = new Date().getTime(),
      rentStartTime = rent_start?.getTime();
    if (isFutureTenant) {
      return checkIsRentStartDateDisabledForFutureTenant(
        renters,
        calendarDateTime,
        todayTime
      );
    }
    return checkIsRentStartDateDisabledForCurrentTenant(
      renters,
      rentStartTime,
      calendarDateTime,
      todayTime
    );
  };

const checkIsRentStartYearDisabledForFutureTenant = (
  renters: AreaRenter[],
  calendarDateYear: number,
  currentYear: number
): boolean => {
  return renters?.some((item) => {
    const renterRentEndTime = (item.rent_end || 0) * 1000,
      renterRentEndYear = new Date(renterRentEndTime)?.getFullYear(),
      renterRentStartTime = item.rent_start * 1000,
      renterRentStartYear = new Date(renterRentStartTime)?.getFullYear();

    return (
      (renterRentEndTime !== 0
        ? calendarDateYear < renterRentEndYear
        : calendarDateYear < renterRentStartYear) ||
      calendarDateYear < currentYear
    );
  });
};

const checkIsRentStartYearDisabledForCurrentTenant = (
  renters: AreaRenter[],
  rentStartTime: number,
  calendarDateYear: number,
  currentYear: number
): boolean => {
  return renters?.some((item) => {
    const renterRentEndTime = (item.rent_end || 0) * 1000,
      renterRentEndYear = new Date(renterRentEndTime)?.getFullYear();

    return (
      (renterRentEndTime
        ? renterRentEndTime <= rentStartTime &&
          renterRentEndYear > calendarDateYear
        : false) || calendarDateYear > currentYear
    );
  });
};

export const getRentStartDisabledYears =
  (renters: AreaRenter[], rent_start: Date, isFutureTenant?: boolean | null) =>
  (date: Date): boolean => {
    const currentYear = new Date().getFullYear(),
      calendarDateYear = date?.getFullYear(),
      rentStartTime = rent_start?.getTime();
    if (isFutureTenant) {
      return checkIsRentStartYearDisabledForFutureTenant(
        renters,
        calendarDateYear,
        currentYear
      );
    }
    return checkIsRentStartYearDisabledForCurrentTenant(
      renters,
      rentStartTime,
      calendarDateYear,
      currentYear
    );
  };

const checkIsRentStartMonthDisabledForFutureTenant = (
  renters: AreaRenter[],
  calendarDateMonth: number,
  calendarDateYear: number,
  currentMonth: number,
  currentYear: number
): boolean => {
  return renters?.some((item) => {
    const renterRentEndTime = (item.rent_end || 0) * 1000,
      renterRentEndYear = new Date(renterRentEndTime)?.getFullYear(),
      renterRentEndMonth = new Date(renterRentEndTime)?.getMonth(),
      renterRentStartTime = item.rent_start * 1000,
      renterRentStartMonth = new Date(renterRentStartTime)?.getMonth();

    return (
      (renterRentEndTime !== 0
        ? calendarDateYear <= renterRentEndYear &&
          calendarDateMonth < renterRentEndMonth
        : calendarDateMonth < renterRentStartMonth) ||
      (calendarDateYear <= currentYear && calendarDateMonth < currentMonth)
    );
  });
};

const checkIsRentStartMonthDisabledForCurrentTenant = (
  renters: AreaRenter[],
  rentStartTime: number,
  calendarDateMonth: number,
  calendarDateYear: number,
  currentMonth: number,
  currentYear: number
): boolean => {
  return renters?.some((item) => {
    const renterRentEndTime = (item.rent_end || 0) * 1000,
      renterRentEndYear = new Date(renterRentEndTime)?.getFullYear(),
      renterRentEndMonth = new Date(renterRentEndTime)?.getMonth();
    return (
      (renterRentEndTime
        ? renterRentEndTime <= rentStartTime &&
          renterRentEndYear >= calendarDateYear &&
          renterRentEndMonth > calendarDateMonth
        : false) ||
      (calendarDateYear >= currentYear && calendarDateMonth > currentMonth)
    );
  });
};

export const getRentStartDisabledMonths =
  (renters: AreaRenter[], rent_start: Date, isFutureTenant?: boolean | null) =>
  (date: Date): boolean => {
    const currentMonth = new Date().getMonth(),
      currentYear = new Date().getFullYear(),
      calendarDateMonth = date?.getMonth(),
      calendarDateYear = date?.getFullYear(),
      rentStartTime = rent_start?.getTime();

    if (isFutureTenant) {
      return checkIsRentStartMonthDisabledForFutureTenant(
        renters,
        calendarDateMonth,
        calendarDateYear,
        currentMonth,
        currentYear
      );
    }

    return checkIsRentStartMonthDisabledForCurrentTenant(
      renters,
      rentStartTime,
      calendarDateMonth,
      calendarDateYear,
      currentMonth,
      currentYear
    );
  };

const checkIsRentEndDateDisabledForFutureTenant = (
  calendarDateTime: number,
  rentStartTime: number
): boolean => calendarDateTime <= rentStartTime;

const checkIsRentEndDateDisabledForCurrentTenant = (
  renters: AreaRenter[],
  calendarDateTime: number,
  rentEndTime: number,
  rentStartTime: number
): boolean => {
  const futureTenant = renters.find(
    (item) => item?.rent_start * 1000 > rentEndTime
  );

  if (futureTenant) {
    const renterRentStartTime = futureTenant.rent_start * 1000;
    return (
      (renterRentStartTime > rentEndTime &&
        renterRentStartTime <= calendarDateTime) ||
      rentStartTime >= calendarDateTime
    );
  }

  return rentStartTime >= calendarDateTime;
};

export const getRentEndDisabledDates =
  (
    renters: AreaRenter[],
    rent_start: Date,
    rent_end: Date | null,
    isFutureTenant?: boolean | null
  ) =>
  (date: Date): boolean => {
    const calendarDateTime = date?.getTime(),
      rentStartTime = rent_start?.getTime(),
      rentEndTime = rent_end?.getTime() || 0;

    if (isFutureTenant) {
      return checkIsRentEndDateDisabledForFutureTenant(
        calendarDateTime,
        rentStartTime
      );
    }

    return checkIsRentEndDateDisabledForCurrentTenant(
      renters,
      calendarDateTime,
      rentEndTime,
      rentStartTime
    );
  };

const checkIsRentEndYearDisabledForFutureTenant = (
  calendarDateYear: number,
  rentStartYear: number
): boolean => calendarDateYear < rentStartYear;

const checkIsRentEndYearDisabledForCurrentTenant = (
  renters: AreaRenter[],
  calendarDateYear: number,
  calendarDateTime: number,
  rentStartTime: number,
  rentStartYear: number,
  rentEndTime: number,
  rentEndYear: number
): boolean => {
  const futureTenant = renters.find(
    (item) => item?.rent_start * 1000 > rentEndTime
  );

  if (futureTenant) {
    const renterRentStartTime = futureTenant.rent_start * 1000,
      renterRentStartYear = new Date(renterRentStartTime)?.getFullYear();
    return (
      (renterRentStartTime > rentEndTime &&
        renterRentStartTime <= calendarDateTime &&
        renterRentStartYear >= rentEndYear &&
        renterRentStartYear < calendarDateYear) ||
      (rentStartTime >= calendarDateTime && rentStartYear > calendarDateYear)
    );
  }

  return rentStartTime >= calendarDateTime && rentStartYear > calendarDateYear;
};

export const getRentEndDisabledYears =
  (
    renters: AreaRenter[],
    rent_start: Date,
    rent_end: Date | null,
    isFutureTenant?: boolean | null
  ) =>
  (date: Date): boolean => {
    const calendarDateYear = date?.getFullYear(),
      calendarDateTime = date?.getTime(),
      rentStartTime = rent_start?.getTime(),
      rentStartYear = rent_start?.getFullYear(),
      rentEndTime = rent_end?.getTime() || 0,
      rentEndYear = rent_end?.getFullYear() || 0;

    if (isFutureTenant) {
      return checkIsRentEndYearDisabledForFutureTenant(
        calendarDateYear,
        rentStartYear
      );
    }

    return checkIsRentEndYearDisabledForCurrentTenant(
      renters,
      calendarDateYear,
      calendarDateTime,
      rentStartTime,
      rentStartYear,
      rentEndTime,
      rentEndYear
    );
  };

const checkIsRentEndMonthDisabledForFutureTenant = (
  calendarDateMonth: number,
  calendarDateYear: number,
  rentStartMonth: number,
  rentStartYear: number
): boolean => {
  return (
    calendarDateYear <= rentStartYear && calendarDateMonth < rentStartMonth
  );
};

const checkIsRentEndMonthDisabledForCurrentTenant = (
  renters: AreaRenter[],
  calendarDateMonth: number,
  calendarDateYear: number,
  calendarDateTime: number,
  rentStartMonth: number,
  rentStartYear: number,
  rentEndTime: number,
  rentEndYear: number,
  rentEndMonth: number
): boolean => {
  const futureTenant = renters.find(
    (item) => item?.rent_start * 1000 > rentEndTime
  );
  if (futureTenant) {
    const renterRentStartTime = futureTenant.rent_start * 1000,
      renterRentStartYear = new Date(renterRentStartTime)?.getFullYear(),
      renterRentStartMonth = new Date(renterRentStartTime)?.getMonth();
    return (
      (renterRentStartTime > rentEndTime &&
        renterRentStartTime <= calendarDateTime &&
        renterRentStartYear >= rentEndYear &&
        renterRentStartMonth >= rentEndMonth &&
        renterRentStartYear <= calendarDateYear &&
        renterRentStartMonth < calendarDateMonth) ||
      (rentStartMonth > calendarDateMonth && rentStartYear >= calendarDateYear)
    );
  }

  return (
    rentStartMonth > calendarDateMonth && rentStartYear >= calendarDateYear
  );
};

export const getRentEndDisabledMonths =
  (
    renters: AreaRenter[],
    rent_start: Date,
    rent_end: Date | null,
    isFutureTenant?: boolean | null
  ) =>
  (date: Date): boolean => {
    const calendarDateMonth = date?.getMonth(),
      calendarDateYear = date?.getFullYear(),
      calendarDateTime = date?.getTime(),
      rentStartYear = rent_start?.getFullYear(),
      rentStartMonth = rent_start?.getMonth(),
      rentEndTime = rent_end?.getTime() || 0,
      rentEndYear = rent_end?.getFullYear() || 0,
      rentEndMonth = rent_end?.getMonth() || 0;

    if (isFutureTenant) {
      return checkIsRentEndMonthDisabledForFutureTenant(
        calendarDateMonth,
        calendarDateYear,
        rentStartMonth,
        rentStartYear
      );
    }

    return checkIsRentEndMonthDisabledForCurrentTenant(
      renters,
      calendarDateMonth,
      calendarDateYear,
      calendarDateTime,
      rentStartMonth,
      rentStartYear,
      rentEndTime,
      rentEndYear,
      rentEndMonth
    );
  };

export const tenantFormTabs = [
  {
    label: "tenant.rentalContract",
    value: TenantForms.Renter,
  },
  {
    label: "tenant.warnings",
    value: TenantForms.DunningLetters,
  },
  {
    label: "tenant.bka",
    value: TenantForms.Bka,
  },
  {
    label: "tenant.mobileUser",
    value: TenantForms.MobileUser,
  },
];

/* istanbul ignore next */
export const handleFutureDisableYear = (): ((date: Date) => boolean) => {
  return (date: Date) => {
    return moment(date).year() >= moment().year();
  };
};
