import React, { ReactElement } from "react";
import { Typography } from "@mui/material";
import { TableCell } from "../../styled";
import { formatTimestamp } from "../../../../utils/date";
import { useParams } from "react-router-dom";
import {
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  Measurement_Units,
  THOUSAND_SEPARATOR,
} from "../../../../constants";
import { NumericFormat } from "react-number-format";
import ExpandableComment from "../../../../ui/expandableComment/ExpandableComment";
import FileTableCell from "../../components/fileTableCell";
import RedirectProperty from "../../../../ui/redirectProperty";
import { DocumentRelaseHistory } from "../../../../types/be/history";
import { HISTORY } from "./types";

/* istanbul ignore next */
const HistoryRow = ({
  row,
  documentRelease,
}: {
  row: DocumentRelaseHistory;
  documentRelease: HISTORY;
}): ReactElement => {
  const { id } = useParams();

  const property = row?.[documentRelease]?.property ?? null;

  return (
    <>
      {!id ? (
        property ? (
          <TableCell align="left">
            <RedirectProperty property={property} />
          </TableCell>
        ) : (
          <TableCell>{EMPTY_DATA}</TableCell>
        )
      ) : null}
      <TableCell align="left">
        <Typography noWrap>
          {row.creator?.first_name} {row.creator?.last_name}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography noWrap>{row.type?.name}</Typography>
      </TableCell>
      <FileTableCell file={row?.[documentRelease]?.file} />
      <TableCell align="right">
        {row?.[documentRelease]?.amount !== null &&
        row?.[documentRelease]?.amount !== undefined ? (
          <Typography noWrap>
            <NumericFormat
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              decimalScale={DECIMAL_SCALE}
              fixedDecimalScale
              displayType={"text"}
              value={row?.[documentRelease]?.amount}
            />{" "}
            {Measurement_Units.EURO}
          </Typography>
        ) : (
          EMPTY_DATA
        )}
      </TableCell>
      <TableCell align="center">{formatTimestamp(row.updated_at)}</TableCell>
      <TableCell align="left">
        <Typography noWrap>{row.status?.name}</Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: 50 }}>
        {row.comment ? (
          <ExpandableComment commentData={row.comment} />
        ) : (
          EMPTY_DATA
        )}
      </TableCell>
    </>
  );
};

export default HistoryRow;
