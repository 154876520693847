import { FormikValues } from "formik";
import { createReminders } from "../../../../../api/reminder";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../constants";
import { HTTP_STATUS_CODES } from "../../../../../types/server";
import { route } from "../../../../../utils/url";
import {
  HandleReminderCreateProps,
  ReminderCreateInitialValuesType,
} from "./types";

export const reminderCreateInitialValues = {
  file: null,
  relation_id: "",
  relation: "invoice",
  property_id: "",
};

export const handleCreate = ({
  snackbar,
  t,
  navigate,
  property_id,
}: HandleReminderCreateProps) => {
  return async (
    data: ReminderCreateInitialValuesType,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const formData = new FormData();
    data?.file && formData.append("file", data?.file);
    data?.relation_id && formData.append("relation_id", data?.relation_id);
    data?.relation && formData.append("relation", data?.relation);
    data?.property_id && formData.append("property_id", data?.property_id);
    const response = await createReminders(formData);
    if (
      [HTTP_STATUS_CODES.CREATED, HTTP_STATUS_CODES.OK].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      navigate(
        property_id
          ? route("properties.invoices.reminders", property_id)
          : route("invoices.reminders")
      );
      snackbar.success(t("reminder.create.reminderCreatedSuccessfully"));
    } else {
      snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
      setSubmitting(false);
    }
  };
};
