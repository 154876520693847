/* istanbul ignore file */
import { Autocomplete, Box, Grid, Tooltip, Typography } from "@mui/material";
import { FormikValues } from "formik";
import TextField from "@mui/material/TextField";
import { debounce } from "lodash";
import React, {
  ReactElement,
  SyntheticEvent,
  useState,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { ContactListData } from "../../../contacts/list/types";
import { objTypeGuard, prepareQueryParams } from "../../../../utils/common";
import { contactAutocomplete } from "../../../../api/contacts";
import { SEARCH_PARAM_MIN_CHARACTERS } from "../../../../constants";
import { Chip, SelectBox } from "../../../properties/areas/edit/styled";
import { TagAutoCompleteProps } from "./types";

const TagAutocomplete = ({
  values,
  setFieldValue,
  touched,
  errors,
  status,
  field,
  label,
}: FormikValues & TagAutoCompleteProps): ReactElement => {
  const [options, setOptions] = useState<readonly ContactListData[] | null>(
    null
  );
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const { t } = useTranslation();

  const handleInputChange = useCallback(async (search: string) => {
    try {
      const params = prepareQueryParams("", {
        search,
      });
      const response = await contactAutocomplete(params);
      const { data } = await response.json();
      return data;
    } catch (error) {
      return [];
    }
  }, []);

  const handleEmailChange = (
    _: SyntheticEvent<Element, Event>,
    emails: string[],
    field: string
  ): void => {
    setFieldValue(field, emails);
  };

  const onSearchFieldTextChange = useCallback(
    debounce(async (search: string): Promise<void> => {
      if (search.length < SEARCH_PARAM_MIN_CHARACTERS) setOptions(null);
      else {
        setLoading(true);

        try {
          const response = await handleInputChange(search);
          setOptions([...response]);
          setLoading(false);
        } catch (e) {
          setOptions([]);
        }
      }
    }, 0),
    [handleInputChange]
  );

  const handleMailSelect = (
    _: SyntheticEvent<Element, Event>,
    emails: (string | ContactListData)[],
    field: string
  ): void => {
    const temp = emails[emails.length - 1];
    if (objTypeGuard<ContactListData>(temp, ["email"])) {
      emails.splice(-1);
      setFieldValue(field, [...emails, temp.email]);
    } else {
      setFieldValue(field, emails);
    }
  };

  const handleInputOnSpacebar = (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string,
    field: string
  ): void => {
    if (
      newInputValue.length !== newInputValue.trimEnd().length &&
      newInputValue.trim().length > 0
    ) {
      event.preventDefault();
      event.stopPropagation();
      handleEmailChange(event, [...values[field], newInputValue.trim()], field);
      setInputValue("");
    } else {
      onSearchFieldTextChange(newInputValue.trim());
      setInputValue(newInputValue.trim());
    }
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: ContactListData
  ): ReactElement => (
    <Box key={`${option.id}-${option.email}`} component="li" {...props}>
      <Grid>
        <Grid>
          <Typography sx={{ fontWeight: "600" }}>{option.full_name}</Typography>
          <Typography sx={{ fontSize: 11 }}>{option.email}</Typography>
        </Grid>
        <Grid sx={{ fontSize: 11, fontStyle: "italic" }}>
          {option.company_name}
        </Grid>
      </Grid>
    </Box>
  );

  const renderToolTipTitle = useCallback((): ReactElement => {
    return (
      values?.[field].length > 0 &&
      values?.[field].map((value: string) => {
        return (
          <>
            <span>{value}</span>
            <br />
          </>
        );
      })
    );
  }, [values[field]]);

  return (
    <>
      <Autocomplete
        multiple
        options={options ?? []}
        freeSolo
        value={values[field]}
        size="small"
        getOptionLabel={(option): string => {
          if (
            objTypeGuard<ContactListData>(option, [
              "email",
              "full_name",
              "company_name",
            ])
          ) {
            return `${option.full_name} ${option.email} ${
              option.company_name ? option.company_name : ""
            }`;
          }
          return "";
        }}
        inputValue={inputValue}
        onInputChange={(event, value) =>
          handleInputOnSpacebar(event, value, field)
        }
        renderOption={renderOption}
        onChange={(event, value) => handleMailSelect(event, value, field)}
        renderTags={(list) => {
          return (
            <SelectBox>
              <Chip sx={{ maxHeight: 17 }} label={`${list[0]}`} />
              {list.length > 1 && (
                <div>{`${t("tenant.selectedMoreThenOneTenantPartOne")} ${
                  list.length - 1
                } ${t("tenant.selectedMoreThenOneTenantPartTwo")} `}</div>
              )}
            </SelectBox>
          );
        }}
        renderInput={(params): ReactElement => {
          return (
            <Tooltip
              placement="top"
              aria-label="add"
              open={showTooltip}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              title={renderToolTipTitle()}
            >
              <TextField
                {...params}
                name={"contact_id"}
                variant="outlined"
                label={t(label)}
                placeholder={t("createContact.enterEmail")}
                margin="normal"
                fullWidth
                error={Boolean(
                  touched?.[field] &&
                    (status?.errors?.[field] || errors?.[field])
                )}
                helperText={
                  touched?.[field] &&
                  (status?.errors?.[field] || errors?.[field])
                }
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            </Tooltip>
          );
        }}
      />
    </>
  );
};

export default TagAutocomplete;
