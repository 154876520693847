import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import {
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import MUIDialogContent from "@mui/material/DialogContent";
import FormAlert from "../../../ui/formAlert/FormAlert";
import TextField from "../../../ui/formsFields/textField";

export const Divider = styled(MuiDivider)(spacing);
export const Typography = styled(MuiTypography)(spacing);

export const ErrorBox = styled(FormAlert)(spacing);

export const TextFieldCustom = styled(TextField)`
  margin-top: ${(props): string => props.theme.spacing(1.6)};
  min-height: ${(props): string => props.theme.spacing(12.5)};
`;

export const DialogContent = styled(MUIDialogContent)`
  & > div {
    max-height: "80vh";
    width: "100%";
    > .MuiPaper-root {
      padding: 0;
    }
    .MuiTable-root {
      min-width: unset;
    }
  }
`;
