import React, { ReactElement, useEffect, useState } from "react";
import { PermissionCodesDict } from "../../types";
import { useFetch } from "../../../../../hooks/useFetch";
import { Formik } from "formik";
import { PermissionsCategory as BEPermissionsCategory } from "../../../../../types/be/permissions";
import useValidation from "../../../../../hooks/useValidation";
import { getPermissionCategories } from "../../../../../api/permissions";
import Loader from "../../../../Loader";
import { Grid } from "@mui/material";
import FormAlert from "../../../../../ui/formAlert/FormAlert";
import { FormMessageInterface } from "../../../../../types/form";
import { FormMessageInItState } from "../../../../../constants";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { RoleDetails } from "../../../../../types/be/role";
import { getRole } from "../../../../../api/roles";
import Form from "../../create/Form";
import { handleUpdateRoleFormSubmit } from "./utils";
/* istanbul ignore next */
const Permissions = (): ReactElement => {
  const { name } = useValidation();
  const { id } = useParams();
  const [permissionCodes, setPermissionCodes] =
    useState<PermissionCodesDict | null>(null);
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);

  const {
    data: permissionCategories,
    run: runPermissionCategories,
    isLoading: isPermissionCategoriesLoading,
  } = useFetch<BEPermissionsCategory[]>();

  const {
    data: roleDetails,
    run: fetchRoleDetails,
    isLoading: isRoleDetailsLoading,
  } = useFetch<RoleDetails>();

  useEffect(() => {
    runPermissionCategories(getPermissionCategories());
  }, []);

  useEffect(() => {
    fetchRoleDetails(getRole(id!));
  }, []);

  useEffect(() => {
    if (!permissionCategories || !roleDetails) return;
    const auxPermissionCodes: PermissionCodesDict = {};
    const mapped = permissionCategories
      .map((category) => {
        const firstLevel = category.groups.map((group) => {
          const secondLevel = group.permissions.map((el) => el.code);
          return secondLevel;
        });
        return firstLevel.flat(1);
      })
      .flat(1)
      .filter((item, pos, self) => self.indexOf(item) == pos);

    const codesFromRoleDetails = roleDetails.permissions.map(
      ({ code }) => code
    );

    mapped.forEach((code) => {
      auxPermissionCodes[code] = codesFromRoleDetails.includes(code);
    });

    setPermissionCodes(auxPermissionCodes);
  }, [permissionCategories, roleDetails]);

  if (isRoleDetailsLoading || isPermissionCategoriesLoading) return <Loader />;

  return (
    <Grid container>
      <Grid item xs={12}>
        <>
          {formMessage.text && (
            <FormAlert
              formMessage={formMessage}
              sx={{ marginBottom: "10px" }}
            />
          )}
          {permissionCategories && permissionCodes && (
            <Formik
              initialValues={{
                name: roleDetails?.name ?? "",
              }}
              onSubmit={handleUpdateRoleFormSubmit({
                roleId: roleDetails?.id,
                setFormMessage,
                permissionCodes,
              })}
              validationSchema={Yup.object().shape({
                name,
              })}
              enableReinitialize
            >
              {(props): React.ReactElement => (
                <Form
                  {...props}
                  permissionCategories={permissionCategories}
                  permissionCodes={permissionCodes}
                  setPermissionCodes={setPermissionCodes}
                  mode="edit"
                />
              )}
            </Formik>
          )}
        </>
      </Grid>
    </Grid>
  );
};

export default Permissions;
