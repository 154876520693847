import {
  Checkbox,
  Box,
  Paper,
  FormControlLabel,
  Divider,
  CircularProgress,
} from "@mui/material";
import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  ReactElement,
} from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Dropdown } from "../../../ui/autocomplete/styled";
import { TextField } from "../vacationHolidayManagementModal/styled";
import { Role as BERole } from "../../../types/be/role";
import { VacationHolidayManagementContext } from "../vacationHolidayManagementModal";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { SelectBox } from "./styled";
import { FormikValues } from "formik";
/* istanbul ignore next */
const indeterminateCheckBoxIcon = (
  <IndeterminateCheckBoxIcon fontSize="small" />
);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
/* istanbul ignore next */
const RolesAutocomplete = ({
  handleBlur,
  touched,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();
  const { roles, isRolesLoading, selectedRoles, setSelectedRoles } = useContext(
    VacationHolidayManagementContext
  );

  const [selectedAll, setSelectedAll] = useState(false);

  const onAutocompleteChange = (_: unknown, newRoles: BERole[]): void => {
    setSelectedRoles(newRoles);
  };

  const indeterminate = useMemo(() => {
    if (!roles) return;
    return selectedRoles.length > 0 && selectedRoles.length < roles?.length;
  }, [selectedRoles, roles]);

  useEffect(() => {
    if (!roles) return;
    selectedAll ? setSelectedRoles(roles) : setSelectedRoles([]);
  }, [selectedAll, roles]);

  const getPaperComponent = useCallback(
    (paperProps) => {
      const { children, ...restPaperProps } = paperProps;
      return (
        <Dropdown>
          <Paper {...restPaperProps}>
            {Boolean(roles?.length) && (
              <Box onMouseDown={(e) => e.preventDefault()} pl={6.5} py={0.5}>
                <FormControlLabel
                  style={{ marginRight: 8, border: "1x solid red" }}
                  label={t("property.area.selectAll")}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedAll((prevState) => !prevState);
                  }}
                  sx={{ width: "100%" }}
                  control={
                    <Checkbox
                      indeterminateIcon={indeterminateCheckBoxIcon}
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      indeterminate={indeterminate}
                      checked={selectedAll}
                    />
                  }
                />
              </Box>
            )}
            <Divider />
            {children}
          </Paper>
        </Dropdown>
      );
    },
    [selectedAll, indeterminate, setSelectedAll]
  );

  return (
    <Autocomplete
      id="roles"
      options={roles || []}
      multiple
      disabled={isRolesLoading}
      loading={isRolesLoading}
      disableCloseOnSelect
      size="small"
      getOptionLabel={(role) => `${role.name} ${role.code}`}
      value={selectedRoles}
      onChange={onAutocompleteChange}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderTags={(list) => {
        const displayList = list.map((item) => item.name);
        return (
          <SelectBox>
            <div>{`${t("selected")} ${displayList.length} ${t(
              "settings.role.roles"
            )}`}</div>
          </SelectBox>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("settings.role.roles")}
          placeholder={t("search")}
          onBlur={handleBlur}
          error={Boolean(Object.keys(touched).length && !selectedRoles.length)}
          helperText={
            Boolean(Object.keys(touched).length && !selectedRoles.length) &&
            t("form.roleIsRequired")
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isRolesLoading && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      PaperComponent={getPaperComponent}
    />
  );
};

export default RolesAutocomplete;
