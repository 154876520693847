import React, { ReactElement, useEffect, useState } from "react";
import { Formik } from "formik";
import Form from "../CreateAndEditInventoryItemForm";
import { FormWrapper } from "../styled";
import { Stack } from "@mui/material";
import {
  FormMessageErrorState,
  FormMessageInItState,
} from "../../../constants";
import FormAlert from "../../../ui/formAlert/FormAlert";
import useValidation from "../../../hooks/useValidation";
import * as Yup from "yup";
import {
  createInventoryItemInitState,
  handleCreateInventoryItemFormSubmit,
} from "./utils";
import { FormMessageInterface } from "../../../types/form";
import { useFetch } from "../../../hooks/useFetch";
import { TypeOfInventoryItem } from "../../../types/be/companyOwnership";
import { getTypesOfInventoryItems } from "../../../api/companyOwnership";
import Loader from "../../Loader";
import { ErrorBox } from "../../properties/objectDataSheet/tabs/generalInformation/styled";
import ConfirmationModal from "../../../ui/confirmationModal";
import { useNavigate } from "react-router-dom";
import { route } from "../../../utils/url";
import { useTranslation } from "react-i18next";
import PageWrapper from "../../../ui/pageWrapper/PageWrapper";

const CreateInventoryItem = (): ReactElement => {
  const { t } = useTranslation();
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const [initialValues] = useState(createInventoryItemInitState);
  const [showAskPopup, setShowAskPopup] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    inventory_type_id: type_id,
    brand,
    serial_number: number,
    department,
  } = useValidation();

  const {
    data: typesOfInventoryItems,
    isError: isTypesOfInventoryItemsError,
    run: runTypesOfInventoryItems,
  } = useFetch<TypeOfInventoryItem[]>();

  useEffect(() => {
    getData();
  }, []);

  const getData = (): void => {
    runTypesOfInventoryItems(getTypesOfInventoryItems());
  };

  if (isTypesOfInventoryItemsError) {
    return <ErrorBox formMessage={FormMessageErrorState} />;
  }

  const breadcrumbs = [
    {
      to: route("human-resources.company-ownership"),
      name: t("sidebar.companyOwnership"),
    },
  ];

  return (
    <PageWrapper title={t("create")} breadcrumbs={breadcrumbs}>
      <>
        {typesOfInventoryItems ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            <FormWrapper>
              {formMessage.text && (
                <FormAlert
                  formMessage={formMessage}
                  sx={{ marginBottom: "10px" }}
                />
              )}
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  type_id,
                  brand,
                  number,
                  department,
                })}
                onSubmit={handleCreateInventoryItemFormSubmit({
                  setFormMessage,
                  afterSubmit: () => setShowAskPopup(true),
                })}
              >
                {(props): ReactElement => (
                  <Form
                    {...props}
                    typesOfInventoryItems={typesOfInventoryItems}
                  />
                )}
              </Formik>
            </FormWrapper>
          </Stack>
        ) : (
          <Loader />
        )}
        <ConfirmationModal
          visible={showAskPopup}
          confirmText={t("yes")}
          cancelText={t("no")}
          titleText={t("companyOwnership.continueCreatingInventory")}
          setVisible={setShowAskPopup}
          handleCancel={() =>
            navigate(route("human-resources.company-ownership"))
          }
          handleConfirm={() => {
            setShowAskPopup(false);
          }}
        />
      </>
    </PageWrapper>
  );
};

export default CreateInventoryItem;
