import React, { ReactElement } from "react";
import { DialogContent } from "@mui/material";
import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import { CreateTenantReminderModalProps } from "./types";
import Form from "./Form";
import { createTenantReminderInitState, handleCreateReminders } from "./utils";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import useValidation from "../../../../../../../hooks/useValidation";
import DialogTitle from "../../../../../../../ui/dialog/DialogTitle";
/* istanbul ignore next */
export const CreateTenantReminderModal = ({
  isCreateModalOpen,
  handleCreateModalClose,
  snackbar,
  fetchReminder,
}: CreateTenantReminderModalProps): ReactElement => {
  const { file, tenantReminderSentDate } = useValidation();
  const { t } = useTranslation();
  return (
    <Dialog
      maxWidth="sm"
      open={isCreateModalOpen}
      onClose={handleCreateModalClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="create-tenant-reminder-dialog"
    >
      <DialogTitle
        title="reminder.createReminder"
        onClose={handleCreateModalClose}
      />
      <DialogContent>
        <>
          <Formik
            initialValues={createTenantReminderInitState}
            validationSchema={Yup.object().shape({
              file,
              date: tenantReminderSentDate,
            })}
            onSubmit={handleCreateReminders({
              handleCreateModalClose,
              snackbar,
              t,
              fetchReminder,
            })}
          >
            {(props): ReactElement => (
              <Form
                {...props}
                handleCreateModalClose={handleCreateModalClose}
              />
            )}
          </Formik>
        </>
      </DialogContent>
    </Dialog>
  );
};
