import { InventoryItem as BEInventoryItem } from "../../../types/be/companyOwnership";
import { EditInventoryItemType } from "../addEmployeeDialog/types";

export const prepareInventoryItemBeforeForm = (
  data: BEInventoryItem
): Partial<EditInventoryItemType> => ({
  type_id: data?.type?.id,
  brand: data?.brand,
  department: data?.department,
  number: data?.number,
});
