import { TabAreaControls } from "../types";
/* istanbul ignore next */
export enum TABS {
  PERMISSIONS = "permissions",
  PROPERTIES = "properties",
  COMPANIES = "companies",
}
/* istanbul ignore next */
export const tabsLabels: TABS[] = [
  TABS.PERMISSIONS,
  TABS.PROPERTIES,
  TABS.COMPANIES,
];
/* istanbul ignore next */
export const simpleTabProps = (index: number): TabAreaControls => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
});
