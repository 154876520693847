import { Button, Grid } from "@mui/material";
import { t } from "i18next";
import React, { ReactElement } from "react";
import { CreateTenantReminderToolbarProps } from "./types";

/* istanbul ignore next */
const ToolBar = ({
  handleCreateModalOpen,
}: CreateTenantReminderToolbarProps): ReactElement => {
  return (
    <>
      <Grid item flexDirection="row" md={3} mt={3}>
        <Button
          sx={{ mr: 1 }}
          type="button"
          onClick={handleCreateModalOpen}
          size="medium"
          color="success"
          variant="contained"
          data-testid={"create-tenant-button"}
        >
          {t("reminder.createReminder")}
        </Button>
      </Grid>
    </>
  );
};

export default ToolBar;
