import AuthGuard from "../../components/guards/AuthGuard";
import DashboardLayout from "../../layouts/dashboard";
import BankAccounts from "../../components/bankAccount";
import React from "react";
import { PermissionsRead } from "../../constants";
import AccessGuard from "../../components/guards/AccessGuard";

export default {
  path: "/:client/bank-accounts",
  element: (
    <AuthGuard clientModuleName="bank-accounts">
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: (
        <AccessGuard permissions={[PermissionsRead.BANK_ACCOUNT]}>
          <BankAccounts />
        </AccessGuard>
      ),
    },
    {
      path: ":id/payments/:tab",
      element: (
        <AccessGuard permissions={[PermissionsRead.BANK_ACCOUNT]}>
          <BankAccounts />
        </AccessGuard>
      ),
    },
  ],
};
