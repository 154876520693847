import { FormikValues } from "formik";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { Insurance } from "../../../../types/fe.interfaces";
import { updateInsurance } from "../../../../api/insurance";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { HandleUpdateProps } from "./types";
import { parseNumber } from "../../../../utils/common";
import { omit } from "lodash";

export const handleUpdate = ({
  insuranceId,
  setFormMessage,
  setIsSnackbarVisible,
}: HandleUpdateProps) => {
  return async (
    values: Insurance,
    { setStatus }: FormikValues
  ): Promise<void> => {
    const apiCalls = [
      updateInsurance(insuranceId, {
        ...omit(values, ["file"]),
        amount: parseNumber(values.amount, true),
      }),
    ];

    const response = await Promise.all(apiCalls);
    const statusCodes = response.map((res) => res.status);

    if (statusCodes.every((code) => code === 200)) {
      setStatus({ success: true });
      setIsSnackbarVisible(true);
      setFormMessage(FormMessageInItState);
    } else if (statusCodes.includes(422)) {
      const result = await response.find((res) => res.status === 422)?.json();
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(result?.errors),
      });
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
    }
  };
};
