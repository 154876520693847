import React, { useEffect, useState } from "react";
import { objectGetParamsToString } from "../../utils/common";
import useCKEditor from "../ckeditor/useCKEditor";
import { createChats, deleteChat, getChats, readChat } from "../../api/tenantRequest";
import { Chat } from "../../types/be/chat";
import { HTTP_STATUS_CODES } from "../../types/server";
import { SOMETHING_WENT_WRONG_ERROR } from "../../constants";

interface Props {
  id: number;
}

interface ReturnType {
  fetchChats: () => Promise<void>;
  chats: Chat[];
  currentPage: number;
  totalPages: number;
  handlePaginationChange: (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => void;
  handleChatDelete: (chat: Chat) => Promise<void>;
  handleChatSave: () => Promise<void>;
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  deleting: boolean;
  handleChatRead: (chat: Chat) => Promise<void>;
  setChats: React.Dispatch<React.SetStateAction<Chat[]>>
  error: string;
}
/* istanbul ignore next */
const useChats = ({ id }: Props): ReturnType => {
  const { content, setContent } = useCKEditor();
  const [chats, setChats] = useState<Chat[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [deleting, setDeleting] = useState(false);
  const perPageRecord = 100;
  const [error, setError] = useState("");

  const fetchChats = async (): Promise<void> => {
    const response = await getChats(
        id,
      `?${objectGetParamsToString({
        page: currentPage,
        limit: perPageRecord,
      })}`
    );
    const dataJson = await response.json();
    setTotalPages(dataJson?.meta?.last_page);
    setChats(dataJson.data);
  };

  const handleChatSave = async (): Promise<void> => {
    if (!content) return;
    const response = await createChats(id, content);
    if (response.status === HTTP_STATUS_CODES.CREATED) {
      const { data } = await response.json();
      setContent("");
      setChats((chats) => [data,...chats]);
    } else {
      setError(SOMETHING_WENT_WRONG_ERROR)
    }
  };

  const handleChatRead = async (chat: Chat): Promise<void> => {
    try {
      !chat.is_read && await readChat(id, chat.id);
    } catch (e) {
      console.log(e);
    } finally {
      setDeleting(false);
    }
  };

  const handleChatDelete = async (chat: Chat): Promise<void> => {
    setDeleting(true);

    try {
      await deleteChat(id, chat.id);
      fetchChats();
    } catch (e) {
      console.log(e);
    } finally {
      setDeleting(false);
    }
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ): void => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (id) {
      fetchChats();
    }
  }, [currentPage,id]);

  return {
    fetchChats,
    chats,
    currentPage,
    totalPages,
    handlePaginationChange,
    handleChatDelete,
    handleChatSave,
    content,
    setContent,
    deleting,
    handleChatRead,
    setChats,
    error
  };
};

export default useChats;
