import React, { ReactElement } from "react";
import { TotalVacationTableCellProps } from "./types";
import { handleUpdateUserTotalVacation } from "./utils";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import useValidation from "../../../../hooks/useValidation";
import * as Yup from "yup";
import Form from "./Form";

const TotalVacationTableCell = ({
  userVacation,
  setSnackbarData,
}: TotalVacationTableCellProps): ReactElement => {
  const { t } = useTranslation();

  const { totalVacationLengths: total } = useValidation();

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          total: userVacation.total,
        }}
        validationSchema={Yup.object().shape({
          total,
        })}
        onSubmit={handleUpdateUserTotalVacation({
          id: userVacation.id,
          setSnackbarData,
          t,
        })}
      >
        {(props): React.ReactElement => (
          <Form
            userVacation={userVacation}
            {...props}
          />
        )}
      </Formik>
    </>
  );
};

export default TotalVacationTableCell;
