import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import General from "./general";
import Repayment from "./exitAndRepaymentBond";
import { useNavigate, useParams } from "react-router-dom";
import Costs from "./costs";
import FinanceStructure from "./finance";
import OperationAndUse from "./operationAndUse";
import { ErrorBox } from "../objectDataSheet/tabs/generalInformation/styled";
import {
  APP_ENVIRONMENTS,
  COMMENTABLE,
  PermissionsRead,
  SOMETHING_WENT_WRONG_ERROR,
  XLSX_FILE_TYPE,
  XLSX_MIME_TYPE,
} from "../../../constants";
import Loader from "../../Loader";
import { route } from "../../../utils/url";
import { useTranslation } from "react-i18next";
import { Grid, Menu, MenuItem } from "@mui/material";
import { Input, TabLink } from "./styled";
import { Paper } from "@mui/material";
import useComments from "../../../ui/сomments/useComments";
import Comments from "../../../ui/сomments";
import MUIButton from "@mui/material/Button";
import useAccessControl from "../../../hooks/useAccessControl";
import SwotModal from "../components/swotModal/SwotModal";
import { getJson } from "../../../utils/http";
import {
  downloadFile,
  replaceNullOrUndefinedByEmptyString,
} from "../../../utils/common";
import { getProperty } from "../../../api/property";
import {
  downloadTenantSampleFile,
  importTenants,
} from "../../../api/propertyTenant";
import { HTTP_STATUS_CODES } from "../../../types/server";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TABS } from "./types";
import { isAppEnv } from "../../../utils/env";
import { Email } from "@mui/icons-material";

const TABS_LIST = [
  TABS.GENERAL,
  TABS.COSTS,
  TABS.FINANCE,
  TABS.OPERATION,
  TABS.REPAYMENT,
];
/* istanbul ignore next */
const EditProperty = (): ReactElement => {
  const { id, tab } = useParams();
  const { t } = useTranslation();
  const [property, setProperty] = useState<any | undefined>(undefined);
  const [isPropertyLoading, setIsPropertyLoading] = useState<boolean>(false);
  const [swotModalVisible, setSwotModalVisible] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const { can, isRole } = useAccessControl();
  const ref = useRef<HTMLInputElement>(null);

  const {
    comments,
    content,
    setContent,
    currentPage,
    totalPages,
    handleCommentSave,
    handleCommentDelete,
    handlePaginationChange,
    deleting,
    loading,
  } = useComments({
    relation: COMMENTABLE.PROPERTY,
    relation_id: id!,
  });

  useEffect(() => {
    setIsPropertyLoading(true);
    getPropertyData();
    setIsPropertyLoading(false);
  }, [id]);

  const getPropertyData = (): void => {
    getProperty(id!)
      .then(getJson)
      .then(({ data }) => {
        setProperty(replaceNullOrUndefinedByEmptyString(data));
      })
      .catch(
        /* istanbul ignore next */ (err) => {
          console.error(err);
          setIsError(true);
          //TODO: snackbar with an error notification
        }
      );
  };

  const openAnalysisFile = (): any => {
    return window.open(
      property?.immo24_report_file,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const isSwotButtonVisible = isRole([
    "admin",
    "asset_manager",
    "transaction_manager",
  ]);

  const toggleSwotModal = (value?: boolean): void => {
    setSwotModalVisible(value ?? false);
  };

  const handleSelectFile = (): void => {
    handleClose();
    ref.current?.click();
  };

  const downloadSampleFile = async (): Promise<void> => {
    handleClose();
    const res = await downloadTenantSampleFile();
    if (res.status !== HTTP_STATUS_CODES.OK) {
      setIsError(true);
    } else {
      const blob = await res.blob();
      downloadFile(new Blob([blob]), t("example-xlsx"), XLSX_FILE_TYPE);
    }
  };

  const uploadTenantsFile = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = e?.target?.files?.[0];
    const formData = new FormData();
    formData.append("file", file!);

    const response = await importTenants(formData);
    if (response.status !== HTTP_STATUS_CODES.CREATED) {
      setIsError(true);
    }
    if (ref.current) ref.current.value = "";
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    const currentTab = event.currentTarget;
    setAnchor(currentTab);
  };

  const handleClose = (): void => {
    setAnchor(null);
  };

  if (isError) {
    return (
      <ErrorBox
        formMessage={{ type: "error", text: SOMETHING_WENT_WRONG_ERROR }}
      />
    );
  }

  if (isPropertyLoading || !property) {
    return <Loader />;
  }

  const filteredTabsForProd = isAppEnv(APP_ENVIRONMENTS.PROD)
    ? TABS_LIST.filter((i) => i === TABS.GENERAL)
    : TABS_LIST;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} xl={8}>
          <Paper>
            <Grid container direction={"column"} sx={{ pt: 2 }}>
              <Grid item>
                <Grid
                  container
                  spacing={3}
                  justifyContent={"space-between"}
                  sx={{ px: 5 }}
                >
                  <Grid item>
                    <Grid container spacing={3} sx={{ pt: 3 }}>
                      {filteredTabsForProd.map((tabName: TABS) => (
                        <Grid key={tabName} item>
                          <TabLink
                            disabled={tabName === tab}
                            onClick={
                              /* istanbul ignore next */ () => {
                                navigate(
                                  route("properties.edit", property.id, tabName)
                                );
                              }
                            }
                          >
                            {t(`property.tabs.${tabName}`)}
                          </TabLink>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      sx={{ py: 2 }}
                    >
                      <Grid item>
                        <Grid container justifyContent={"end"} spacing={1}>
                          {isAppEnv([
                            APP_ENVIRONMENTS.DEVELOPMENT,
                            APP_ENVIRONMENTS.LOCAL,
                          ]) && (
                            <Grid item>
                              <MUIButton
                                color="success"
                                size="small"
                                onClick={() => openAnalysisFile()}
                                disabled={!property?.immo24_report_file}
                              >
                                {t("location.analysis")}
                              </MUIButton>
                            </Grid>
                          )}
                          {isAppEnv([
                            APP_ENVIRONMENTS.DEVELOPMENT,
                            APP_ENVIRONMENTS.LOCAL,
                          ]) && (
                            <Grid item>
                              <MUIButton
                                color="success"
                                size="small"
                                aria-haspopup="true"
                                variant="text"
                                disableElevation
                                endIcon={<KeyboardArrowDownIcon />}
                                onClick={handleClick}
                                disabled
                              >
                                {t("property.importTenants")}
                              </MUIButton>
                              <Menu
                                anchorEl={anchor}
                                open={Boolean(anchor)}
                                onClose={handleClose}
                              >
                                <MenuItem
                                  onClick={handleSelectFile}
                                  disableRipple
                                >
                                  {t("property.importTenants")}
                                </MenuItem>
                                <MenuItem
                                  onClick={downloadSampleFile}
                                  disableRipple
                                >
                                  {t("property.tenantsSampleDownload")}
                                </MenuItem>
                              </Menu>
                            </Grid>
                          )}
                          {isSwotButtonVisible && (
                            <Grid item>
                              <MUIButton
                                color="success"
                                size="small"
                                onClick={() => toggleSwotModal(true)}
                              >
                                {t("swot.swot")}
                              </MUIButton>
                            </Grid>
                          )}
                          {can([PermissionsRead.INBOX]) && (
                            <Grid
                              item
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <Email
                                color="primary"
                                width={22}
                                fontSize="small"
                                height={22}
                                onClick={() =>
                                  navigate(route("properties.inbox", id))
                                }
                                data-testid="email-icon"
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <>
                  {(() => {
                    /* istanbul ignore next */
                    switch (tab) {
                      case TABS.GENERAL:
                        return (
                          <General
                            property={property}
                            getPropertyData={getPropertyData}
                          />
                        );
                      case TABS.COSTS:
                        return (
                          <Costs
                            property={property}
                            updateSuccessCallback={getPropertyData}
                          />
                        );
                      case TABS.FINANCE:
                        return <FinanceStructure property={property} />;
                      case TABS.OPERATION:
                        return <OperationAndUse property={property} />;
                      case TABS.REPAYMENT:
                        return <Repayment property={property} />;
                    }
                  })()}
                </>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Input
          accept={XLSX_MIME_TYPE}
          type="file"
          ref={ref}
          name="importFile"
          onChange={uploadTenantsFile}
          data-testid="importFile-input"
        />
        <Grid item xs={12} sm={12} md={12} xl={4}>
          <Comments
            editorContent={content}
            setEditorContent={setContent}
            handleSaveComment={handleCommentSave}
            comments={comments}
            currentPage={currentPage}
            totalPages={totalPages}
            handleDeleteComment={handleCommentDelete}
            handlePaginationChange={handlePaginationChange}
            deleting={deleting}
            loading={loading}
          />
        </Grid>
      </Grid>
      {isSwotButtonVisible && (
        <SwotModal
          visible={swotModalVisible}
          toggleSwotModal={() => toggleSwotModal()}
        />
      )}
    </>
  );
};

export default EditProperty;
