import { FormikValues } from "formik/dist/types";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { route } from "../../../utils/url";
import { mapBackendValidationErrors } from "../../../utils/form";
import { createContact } from "../../../api/contacts";
import { EDIT_CONTACT_TABS } from "../edit/utils";
import { CreateContactType, FormSubmitHandlerProps } from "./types";

export const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  salutation: "",
  salutation_id: "",
  type: "",
  type_id: "",
  place_id: "",
  business_phone: "",
  company_id: "",
};

export const touchedInitState = {
  first_name: false,
  last_name: false,
  email: false,
  salutation: false,
  salutation_id: false,
  type: false,
  type_id: false,
  business_phone: false,
  company_id: false,
  city: false,
  zip_code: false,
  street: false,
  house_number: false,
  country_id: false,
  state_id: false,
  country: false,
  place_id: false,
};

/* istanbul ignore next */
export const handleCreateContactFormSubmit = ({
  setFormMessage,
  navigate,
  modalWindow,
  patchCreatedContact,
  handleCloseCreateContactModal,
}: FormSubmitHandlerProps) => {
  return async (
    values: CreateContactType,
    { setStatus, setSubmitting, setValues, setTouched }: FormikValues
  ): Promise<void> => {
    const response = await createContact(values);
    const json = await response.json();
    if (response.status === 201) {
      setStatus({
        success: true,
        errors: {},
      });
      setValues(initialState);
      setTouched(touchedInitState);
      modalWindow && patchCreatedContact && patchCreatedContact(json.data);
      modalWindow &&
        handleCloseCreateContactModal &&
        handleCloseCreateContactModal();
      !modalWindow &&
        navigate(
          route(
            "contact.edit.tab",
            json.data.id,
            EDIT_CONTACT_TABS.CONTACT_DETAILS
          )
        );
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
      setSubmitting(false);
    }
  };
};
