import http from "../utils/http";

export const uploadFile = async (formData: FormData): Promise<Response> => {
  return await http.post(`/api/files`, formData);
};
/* istanbul ignore next */
export const multiUploadFiles = async (
  formData: FormData
): Promise<Response> => {
  return await http.post(`/api/files/multi-upload`, formData);
};
/* istanbul ignore next */
export const uploadAndAttachFiles = async (
  formData: FormData
): Promise<Response> => {
  return await http.post(`/api/files/attach`, formData);
};

export const getFilesSearch = async (params: string): Promise<Response> => {
  return await http.get(`/api/files${params}`);
};
/* istanbul ignore next */
export const getFileOCR = async (file_id: number): Promise<Response> => {
  return await http.get(`/api/files/${file_id}/ocr`);
};
/* istanbul ignore next */
export const getFile = async (file_id: number): Promise<Response> => {
  return await http.get(`/api/files/${file_id}`);
};

export const downloadZipFile = async (uuid: string[]): Promise<Response> => {
  return await http.post(`/api/files/download-zip`, {
    uuid,
  });
};
/* istanbul ignore next */
export const deleteFile = async (id: string): Promise<Response> => {
  return await http.delete(`/api/files/${id}`);
};
/* istanbul ignore next */
export const moveFile = async (data: {
  file_id: number[];
  folder_id: number;
}): Promise<Response> => {
  return await http.post("/api/files/move", data);
};
