import { CreatePropertyFormInitState, FormSubmitHandlerProps } from "./types";
import { FormikValues } from "formik/dist/types";
import { mapBackendValidationErrors } from "../../../utils/form";
import { createProperty } from "../../../api/property";
import { DEFAULT_MAP_LAT, DEFAULT_MAP_LNG } from "../../../ui/map/utils";
import { route } from "../../../utils/url";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { parseNumber } from "../../../utils/common";

export const createPropertyFormInitState: CreatePropertyFormInitState = {
  property_type_id: "",
  object_name: "",
  notes: "",
  purchase_price: "",
  location: {
    place_id: "",
    lat: DEFAULT_MAP_LAT,
    lng: DEFAULT_MAP_LNG,
    city: "",
    house_number: "",
    zip_code: "",
    country_code: "",
    state_code: "",
  },
};

export const touchedInitState = {
  property_type_id: false,
  state: false,
  object_name: false,
  street_id: false,
  city: false,
  house_number: false,
  zip_code: false,
  purchase_price: false,
  notes: false,
};

/* istanbul ignore next */
export const handleCreatePropertyFormSubmit = ({
  setFormMessage,
  navigate,
}: FormSubmitHandlerProps) => {
  return async (
    values: CreatePropertyFormInitState,
    { setStatus, setSubmitting, resetForm }: FormikValues
  ): Promise<void> => {
    const response = await createProperty({
      ...values,
      purchase_price: parseNumber(values.purchase_price),
    });
    const json = await response.json();
    if (response.status === HTTP_STATUS_CODES.CREATED) {
      resetForm({
        errors: {},
        touched: {},
      });
      setStatus({
        success: true,
        errors: {},
        property: json.data,
      });
      navigate(route("properties"));
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({ type: "error", text: "errorSomethingWentWrong" });
      setSubmitting(false);
    }
  };
};

export const initPlaceAddressData = {
  place_id: undefined,
  street: undefined,
  house_number: undefined,
  zip_code: undefined,
  city: undefined,
  state_code: undefined,
  country_code: undefined,
};
