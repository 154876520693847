import { Area } from "../types/be/area";
import http from "../utils/http";
import { UpdateAreaPayload } from "../types/fe/area";
import { CreateAreaTenant, CreatePropertyArea } from "../types/be/area";
/* istanbul ignore next */
export const getAreas = async (
  params = "",
  cancelPrevious = false
): Promise<Response> => {
  return await http.get(`/api/areas${params}`, cancelPrevious);
};

export const getArea = async (id: string): Promise<Response> => {
  return await http.get(`/api/areas/${id}`);
};
/* istanbul ignore next */
export const getAreaTenantUsers = async (id: string): Promise<Response> => {
  return await http.get(`/api/areas/${id}/tenant-users`);
};

/* istanbul ignore next */
export const getAreaRenters = async (id: string): Promise<Response> => {
  return await http.get(`/api/areas/${id}/renters`);
};

/* istanbul ignore next */
export const updateAreaWithFormData = async (
  id: string,
  data: FormData
): Promise<Response> => {
  return await http.post(`/api/areas/${id}`, data);
};

/* istanbul ignore next */
export const updateArea = async (
  id: string,
  data: Partial<Area>
): Promise<Response> => {
  return await http.put(`/api/areas/${id}`, data);
};

/* istanbul ignore next */
export const getAreaStates = async (params = ""): Promise<Response> => {
  return await http.get(`/api/areas/states${params}`);
};

/* istanbul ignore next */
export const getAreaMarketingTypes = async (params = ""): Promise<Response> => {
  return await http.get(`/api/areas/marketing-types${params}`);
};

/* istanbul ignore next */
export const getAreaStoreTypes = async (params = ""): Promise<Response> => {
  return await http.get(`/api/areas/store-types${params}`);
};

/* istanbul ignore next */
export const getAreaPriceUnits = async (params = ""): Promise<Response> => {
  return await http.get(`/api/areas/price-units${params}`);
};

/* istanbul ignore next */
export const getAreaSubjectCommission = async (
  params = ""
): Promise<Response> => {
  return await http.get(`/api/areas/subject-commissions${params}`);
};
/* istanbul ignore next */
export const updateAreaStatus = async (
  areaId: number,
  isActive: boolean
): Promise<Response> => {
  return await http.put(`/api/areas/${areaId}`, {
    is_active: isActive,
  });
};

/* istanbul ignore next */
export const deleteArea = async (area_id: string): Promise<Response> => {
  return await http.delete(`/api/areas/${area_id}`);
};

export const exportPdfAreasList = async (data: {
  id: string;
}): Promise<Response> => {
  return http.post(`/api/properties/${data.id}/areas/export/pdf`, data);
};

export const exportExcelAreasList = async (data: {
  id: string;
}): Promise<Response> => {
  return http.post(`/api/properties/${data.id}/areas/export/xls`, data);
};

export const getTypes = async (): Promise<Response> => {
  return await http.get("/api/areas/types");
};

/* istanbul ignore next */
export const updateAreaById = async (
  payload: UpdateAreaPayload
): Promise<Response> => {
  return await http.put(`/api/areas/${payload?.id}`, payload);
};

/* istanbul ignore next */
export const updateTenant = async (
  areaId: string,
  payload: FormData
): Promise<Response> => {
  return await http.post(`/api/areas/${areaId}/property-tenants`, payload);
};

export const importAreaTenantContact = async (
  data: FormData
): Promise<Response> => {
  return await http.post("/api/imports/area-tenant-contact/xls", data);
};
/* istanbul ignore next */
export const createAreaTenant = async (
  data: CreateAreaTenant
): Promise<Response> => {
  return await http.post(`/api/property-tenants`, data);
};
/* istanbul ignore next */
export const updateAreaTenant = async (
  id: number,
  data: FormData
): Promise<Response> => {
  return await http.post(`/api/property-tenants/${id}`, data);
};

/* istanbul ignore next */
export const createAreaTenantWithFormData = async (
  data: FormData
): Promise<Response> => {
  return await http.post(`/api/property-tenants`, data);
};

export const downloadExampleFile = async (): Promise<Response> => {
  return await http.get(`/api/imports/area-tenant-contact/xls/example`);
};

export const getAreaCategories = async (): Promise<Response> => {
  return await http.get("/api/areas/categories");
};

export const getAreaFloors = async (): Promise<Response> => {
  return await http.get("/api/areas/floors");
};

/* istanbul ignore next */
export const createArea = async (
  data: CreatePropertyArea
): Promise<Response> => {
  return await http.post(`/api/areas`, data);
};
