import TreeView from "@mui/lab/TreeView";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import React, { ReactElement, SyntheticEvent, useEffect } from "react";
import { FileTreeProps } from "./types";
import { FileTreeEnum } from "../../../../constants";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import head from "lodash/head";
import FileTreeItems from "./FileTreeItems";
import { FileTreeContext, iconClicked } from "./utils";

const FileTree = ({
  data,
  handleFileClick,
  handleFolderClick,
  selected,
  expanded: expandedProp,
  onIconClick,
  iconClickExpandOnly = false,
  isLoading,
  onRightClick,
  minWidth = "400px",
  maxWidth = "400px",
}: FileTreeProps): ReactElement => {
  const { t } = useTranslation();
  const fileTreeData = head(data);
  const [expanded, setExpanded] = React.useState<string[]>(expandedProp ?? []);

  const handleToggle = (event: SyntheticEvent, nodeIds: string[]): void => {
    event.persist();
    (!iconClickExpandOnly || iconClicked(event, iconClickExpandOnly)) &&
      setExpanded(nodeIds);
  };

  useEffect(() => {
    expandedProp && setExpanded(expandedProp);
  }, [expandedProp]);

  const handleFileTreeItemClick =
    (
      callbackAfterFile?: (...args: string[]) => void,
      callbackAfterFolder?: (...args: string[]) => void
    ) =>
    (event: SyntheticEvent, nodeId: string): void => {
      if (iconClicked(event, iconClickExpandOnly)) {
        onIconClick &&
          onIconClick(
            ...nodeId
              .replace(FileTreeEnum.FOLDER, "")
              .split(FileTreeEnum.SEPARATOR)
          );
        return void 0;
      } else {
        callbackAfterFile &&
          nodeId.includes(FileTreeEnum.FILE) &&
          callbackAfterFile(
            ...nodeId
              .replace(FileTreeEnum.FILE, "")
              .split(FileTreeEnum.SEPARATOR)
          );
        callbackAfterFolder &&
          nodeId.includes(FileTreeEnum.FOLDER) &&
          callbackAfterFolder(
            ...nodeId
              .replace(FileTreeEnum.FOLDER, "")
              .split(FileTreeEnum.SEPARATOR),
            String(+iconClicked(event, true))
          );
      }
    };

  return (
    <FileTreeContext.Provider value={{ selected, isLoading }}>
      {fileTreeData ? (
        <TreeView
          aria-label="file-tree"
          defaultExpanded={["1"]}
          defaultCollapseIcon={
            <ArrowDropDownIcon data-testid="collapese-icon" />
          }
          defaultExpandIcon={<ArrowRightIcon data-testid="expand-icon" />}
          defaultEndIcon={<div style={{ width: 24 }} />}
          sx={{
            flexGrow: 1,
            minWidth,
            maxWidth,
            overflowY: "auto",
          }}
          onNodeSelect={handleFileTreeItemClick(
            handleFileClick,
            handleFolderClick
          )}
          selected={selected}
          expanded={expanded}
          onNodeToggle={handleToggle}
          onContextMenu={onRightClick}
        >
          <FileTreeItems
            folders={fileTreeData.children}
            files={fileTreeData.files}
            selected={selected}
          />
        </TreeView>
      ) : (
        /* istanbul ignore next */
        <Box>{t("property.noFileTree")}</Box>
      )}
    </FileTreeContext.Provider>
  );
};

export default FileTree;
