import React, { ReactElement, useState } from "react";
import { FormikValues } from "formik/dist/types";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/button/Button";
import { getProperties } from "../../../../api/property";
import { Property } from "../../../../types/be/property";
import { AutocompleteOption } from "../../../../ui/dropdown/types";
import Dropdown from "../../../../ui/dropdown";

/* istanbul ignore next */
const Form = ({
  handleSubmit,
  setFieldValue,
  touched,
  errors,
  status,
  isSubmitting,
  onClose,
}: FormikValues & { onClose: () => void }): ReactElement => {
  const { t } = useTranslation();
  const [selectedProperty, setSelectedProperty] = useState<Property>();

  const onPropertySelect = async (
    id?: string,
    event?: AutocompleteOption | null
  ): Promise<void> => {
    setSelectedProperty(event as Property);
    setFieldValue("property_id", id);
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Dropdown
              id="property-autocomplete"
              name="property_id"
              size="medium"
              label={t("property.property")}
              placeholder={t("search")}
              getOptions={getProperties}
              onSelectOption={onPropertySelect}
              optionLabel="object_name"
              optionValue="id"
              defaultValue={selectedProperty || null}
              enableReInitialization
              error={Boolean(
                touched?.property_id &&
                  (status?.errors?.property_id || errors.property_id)
              )}
              helperText={
                touched?.property_id &&
                (errors?.property_id || status?.errors?.property_id)
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 6,
            }}
            xs={12}
            lg={12}
            sm={12}
            md={12}
          >
            <Button
              title={t("cancel")}
              onClick={onClose}
              type="button"
              size="large"
              testId="cancel-button"
              variant="text"
            />
            <Button
              title={t("save")}
              color="success"
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default Form;
