export default {
  module: "Modul",
  submit: "Einreichen",
  configurationWasSuccessfullyUpdated:
    "Konfiguration wurde erfolgreich aktualisiert",
  finalReleaser: "Finale Freigabe",
  firstReleaseSuggestion: "Freigabevorschlag 1",
  secondReleaseSuggestionN: "Freigabevorschlag 2",
  userReleaseSuggestion: "Freigabevorschlag von bestimmten Nutzern",
};
