/* istanbul ignore file */

import { route } from "../../../utils/url";

export const getRoute = (
  path: string,
  ...params: (string | number | undefined)[]
): string => {
  const { pathname } = window.location;
  if (pathname.includes("properties")) {
    return route(`properties.${path}`, ...params);
  }
  return route(`companies.${path}`, ...params);
};
