/* istanbul ignore file */

import React, { ReactElement, useEffect, useState } from "react";
import Number from "../../../../ui/formsFields/number";
import Button from "../../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Snackbar from "../../../../ui/Snackbar";
import { Form, FormikValues } from "formik";
import { Property } from "../../../../types/be/property";
import { PropertyExtraCalculationFields } from "../../../../types/fe/property";

function PropertyFinanceForm({
  handleSubmit,
  isSubmitting,
  values,
  status,
  setFieldValue,
}: Property & PropertyExtraCalculationFields & FormikValues): ReactElement {
  const { t } = useTranslation();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  const handleSnackbarClose = (): void => setIsSnackbarVisible(false);

  enum CALCULATE_FIELDS {
    percents_first = "own_resources",
    eur_first = "own_resources_in_eur",
    percents_second = "bank_loan",
    eur_second = "bank_loan_in_eur",
    purchase_price = "purchase_price",
  }

  const getPers = (value: number, purchaseValue?: number): number => {
    if (purchaseValue !== undefined) {
      return (value * 100) / purchaseValue;
    }
    return 100 - value;
  };

  const getEur = (purchaseValue: number, fieldValue: number): number => {
    return (purchaseValue * fieldValue) / 100;
  };

  const handleOnChange = (value: string, fieldName: string): void => {
    const changedValue = parseFloat(value);
    let eurFirst = values[CALCULATE_FIELDS.eur_first];
    let percentFirst = values[CALCULATE_FIELDS.percents_first];
    let eurSecond = values[CALCULATE_FIELDS.eur_second];
    let percentsSecond = values[CALCULATE_FIELDS.percents_second];
    const purchasePrice = values.purchase_price;

    if (fieldName === CALCULATE_FIELDS.percents_first) {
      percentFirst = changedValue;
      percentsSecond = getPers(changedValue);
      eurSecond = getEur(purchasePrice, percentsSecond);
      eurFirst = getEur(purchasePrice, changedValue);
    }

    if (fieldName === CALCULATE_FIELDS.percents_second) {
      percentsSecond = changedValue;
      percentFirst = getPers(changedValue);
      eurSecond = getEur(purchasePrice, changedValue);
      eurFirst = getEur(purchasePrice, percentFirst);
    }

    if (fieldName === CALCULATE_FIELDS.eur_first) {
      eurFirst = changedValue;
      percentFirst = getPers(changedValue, purchasePrice);
      percentsSecond = getPers(percentFirst);
      eurSecond = getEur(purchasePrice, percentsSecond);
    }

    if (fieldName === CALCULATE_FIELDS.eur_second) {
      eurSecond = changedValue;
      percentsSecond = getPers(changedValue, purchasePrice);
      percentFirst = getPers(percentsSecond);
      eurFirst = getEur(purchasePrice, percentFirst);
    }
    setFieldValue([CALCULATE_FIELDS.percents_second], percentsSecond);
    setFieldValue([CALCULATE_FIELDS.percents_first], percentFirst);
    setFieldValue([CALCULATE_FIELDS.eur_first], eurFirst);
    setFieldValue([CALCULATE_FIELDS.eur_second], eurSecond);
  };

  return (
    <>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Grid container sx={{ mb: 3, mt: 2 }} spacing={3}>
          <Grid item xs={4}>
            <Number
              size={"small"}
              name="purchase_price"
              label={t("property.cost.purchasePrice")}
              inputProps={{
                "data-testid": "purchase_price",
              }}
              value={values.purchase_price}
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mb: 3, mt: 2 }} spacing={3}>
          <Grid item xs={6}>
            <Number
              size={"small"}
              name="own_resources"
              onChange={(e) =>
                e.target.value &&
                handleOnChange(e.target.value, "own_resources")
              }
              label={t("property.finance.ownResources")}
              inputProps={{ "data-testid": "own-resources" }}
              value={values.own_resources}
              status={status}
            />
          </Grid>
          <Grid item xs={6}>
            <Number
              size={"small"}
              name="own_resources_in_eur"
              label={t("property.finance.ownResourcesInEur")}
              inputProps={{ "data-testid": "own-resources-in-eur" }}
              value={values.own_resources_in_eur ?? null}
              onChange={(e) =>
                e.target.value &&
                handleOnChange(e.target.value, "own_resources_in_eur")
              }
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mb: 3, mt: 2 }} spacing={3}>
          <Grid item xs={6}>
            <Number
              size={"small"}
              name="bank_loan"
              value={values.bank_loan}
              label={t("property.finance.bankLoan")}
              inputProps={{ "data-testid": "bank-loan" }}
              onChange={(e) =>
                e.target.value && handleOnChange(e.target.value, "bank_loan")
              }
              status={status}
            />
          </Grid>
          <Grid item xs={6}>
            <Number
              size={"small"}
              name="bank_loan_in_eur"
              value={values.bank_loan_in_eur ?? null}
              label={t("property.finance.bankLoanInEur")}
              inputProps={{ "data-testid": "bank-loan-in-eur" }}
              onChange={(e) =>
                e.target.value &&
                handleOnChange(e.target.value, "bank_loan_in_eur")
              }
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mb: 3, mt: 2 }} spacing={3}>
          <Grid item xs={4}>
            <Number
              size={"small"}
              name="interest_on_own_funds"
              value={values.interest_on_own_funds}
              label={t("property.finance.interestOnOwnFunds")}
              inputProps={{
                "data-testid": "interest-on-own-founds",
              }}
              status={status}
            />
          </Grid>
          <Grid item xs={4}>
            <Number
              size={"small"}
              name="interest_bank_loan"
              value={values.interest_bank_loan}
              label={t("property.finance.interestBankLoan")}
              inputProps={{
                "data-testid": "interest-bank-loan",
              }}
              status={status}
            />
          </Grid>
          <Grid item xs={4}>
            <Number
              size={"small"}
              name="repayment_bank"
              value={values.repayment_bank}
              label={t("property.finance.repaymentBank")}
              inputProps={{ "data-testid": "repayment-bank" }}
              status={status}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent={"end"}>
          <Grid item>
            <Button
              title={t("update")}
              type="submit"
              color="success"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            />
          </Grid>
        </Grid>
      </Form>
      <Snackbar
        open={isSnackbarVisible}
        message={t("property.financesWasSuccessfullyUpdated")}
        color="success"
        handleClose={handleSnackbarClose}
      />
    </>
  );
}

export default PropertyFinanceForm;
