import React, {
  HTMLAttributes,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import Loader from "../../../../Loader";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { Property } from "../../../../../types/be/property";
import { getProperties } from "../../../../../api/property";
import { getJson } from "../../../../../utils/http";
import { AutocompleteListItem } from "../../../../properties/edit/general/styled";
import { Dropdown } from "../../../../../ui/autocomplete/styled";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import { Grid, TextField } from "@mui/material";
import debounce from "lodash/debounce";
import {
  NODE_ENVIRONMENTS,
  PAGE_LIMIT,
  SEARCH_PARAM_MIN_CHARACTERS,
} from "../../../../../constants";
import {
  AutocompletePropertyAddress,
  AutocompletePropertyName,
} from "./styled";
import { useFetch } from "../../../../../hooks/useFetch";
import { DEBOUNCE_TIME, prepareQueryParams } from "../../../../../utils/common";
import { isNodeEnv } from "../../../../../utils/env";

const PropertyAutocomplete = ({
  onPropertySelect,
}: {
  onPropertySelect: (property: Property | null) => void;
}): ReactElement => {
  const [isLoading, setLoading] = useState(false);
  const [property, setProperty] = useState<Property | null>(null);
  const [propertyAutocompleteInputValue, setPropertyAutocompleteInputValue] =
    useState("");
  const [predictions, setPredictions] = useState<Property[]>([]);
  const { t } = useTranslation();

  const { data: preloadedData, run } = useFetch<Property[]>();

  useEffect(() => {
    const params = prepareQueryParams("", {
      limit: String(PAGE_LIMIT._10),
    });
    run(getProperties(params));
  }, []);

  useEffect(() => {
    preloadedData && setPredictions(preloadedData);
  }, [preloadedData]);

  useEffect(() => {
    setPropertyAutocompleteInputValue(property?.object_name ?? "");
    onPropertySelect(property);
  }, [property]);

  const handlePropertySelect = (property: Property): void => {
    setProperty(property);
  };

  const handleAutocompleteOnChange = (
    _: SyntheticEvent,
    value: Property | null
  ): void => {
    if (value) handlePropertySelect(value);
    else {
      setProperty(null);
    }
  };

  const onLocationSearchKeyDown = React.useCallback(
    debounce(
      async (search: string): Promise<void> => {
        if (search.length < SEARCH_PARAM_MIN_CHARACTERS) setPredictions([]);
        else {
          setLoading(true);
          getProperties(`?search=${search}&limit=1000`)
            .then(getJson)
            .then(({ data }) => {
              setPredictions(data);
              setLoading(false);
            })
            .catch(() => {
              setPredictions([]);
            });
        }
      },
      /* istanbul ignore next */ isNodeEnv(NODE_ENVIRONMENTS.TEST)
        ? 0
        : DEBOUNCE_TIME
    ),
    []
  );

  const handleRenderInput = (
    params: AutocompleteRenderInputParams
  ): ReactNode => (
    <TextField
      {...params}
      size={"small"}
      label={t("property.property")}
      onChange={(e) => {
        setPropertyAutocompleteInputValue(e.target.value);
        onLocationSearchKeyDown(e.target.value);
      }}
      placeholder={t("search")}
    />
  );

  return (
    <Autocomplete
      role={"property-filter"}
      fullWidth
      loading={isLoading}
      loadingText={`${t("loading")}...`}
      inputValue={propertyAutocompleteInputValue}
      popupIcon={isLoading ? <Loader size={25} /> : <ArrowDropDownIcon />}
      options={predictions!}
      isOptionEqualToValue={handleIsOptionEqualToValue}
      getOptionLabel={handleGetOptionLabel}
      filterOptions={handleFilterOptions}
      onChange={handleAutocompleteOnChange}
      renderOption={handleRenderOption}
      PaperComponent={autocompletePaperComponent}
      renderInput={handleRenderInput}
    />
  );
};

export default PropertyAutocomplete;

const handleIsOptionEqualToValue = (
  option: Property,
  value: Property
): boolean => {
  return option.id === value.id;
};

const handleGetOptionLabel = (property: Property): string => {
  return `${property.object_name}`;
};

const handleFilterOptions = (options: Property[]): Property[] => options;

const handleRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  property: Property
): ReactNode => {
  return (
    <AutocompleteListItem {...props} key={property.id}>
      <Grid container direction={"column"}>
        <Grid item>
          <AutocompletePropertyName>
            {property.object_name}
          </AutocompletePropertyName>
        </Grid>
        <Grid item>
          <AutocompletePropertyAddress>
            {property.location.full_address}
          </AutocompletePropertyAddress>
        </Grid>
      </Grid>
    </AutocompleteListItem>
  );
};

const autocompletePaperComponent = ({
  children,
}: {
  children?: ReactNode;
}): ReactElement | null => <Dropdown>{children}</Dropdown>;
