/* istanbul ignore file */

import React, { ReactElement } from "react";
import { Grid, Menu, MenuItem, Box } from "@mui/material";
import {
  Input,
  Search,
  SearchIconWrapper,
} from "../../../../../../ui/table/styled";
import { MoreVertical, Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { AreaTableToolbarProps } from "../../../../../../types/fe/area";
import AreaTableFilters from "./Filters";
import AreaImportData from "./Import";
import { MoreVerticalButton } from "../../../../list/styled";
import { AnchorDict } from "../../../../list/types";
import UploadManuallyDialog from "./upload";
import useAccessControl from "../../../../../../hooks/useAccessControl";
import {
  PermissionsCreate,
  PermissionsUpdate,
} from "../../../../../../constants";
import { StyledButton } from "../../../../../../ui/button/styled";

const AreaTableToolbar = ({
  handleTableSearch,
  openCreateModal,
  handleExportPDF,
  handleExportExcel,
  areaTypes,
  setQueryParams,
  importSuccessCallback,
  isTenant,
}: AreaTableToolbarProps): ReactElement => {
  const { t } = useTranslation();
  const { can } = useAccessControl();
  const [uploadModelVisible, setUploadModalVisible] = React.useState(false);
  const importExportTab = "importExport";
  const [anchorDict, setAnchorDict] = React.useState<AnchorDict>({
    importExport: null,
  });

  const handleClick =
    (tab: string) => (event: React.MouseEvent<HTMLElement>) => {
      const currentTab = event.currentTarget;
      setAnchorDict((d) => ({ ...d, [tab]: currentTab }));
    };

  const handleClose = (): void => {
    setAnchorDict({ importExport: null });
  };

  const onUploadSuccess = (): void => {
    setUploadModalVisible(false);
    importSuccessCallback();
  };

  const importCallback = (): void => {
    setUploadModalVisible(true);
  };

  return (
    <>
      <Grid
        container
        columns={14}
        sx={{
          mb: 5,
          mt: 5,
          flexDirection: "column",
          alignItems: "flex-start",
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        {!isTenant && (
          <>
            <Grid item flexDirection="row" md={3}>
              {can(PermissionsCreate.AREA) && (
                <StyledButton
                  sx={{ mr: 1 }}
                  type="button"
                  onClick={(): void => openCreateModal()}
                  size="small"
                  color="success"
                  variant="contained"
                  data-testid={"create-tenant-button"}
                >
                  {t("property.area.createAreaButton")}
                </StyledButton>
              )}
            </Grid>
          </>
        )}

        <Grid
          item
          xs={14}
          sm={14}
          md={14}
          sx={{
            display: "flex",
            minWidth: "100%",
            justifyContent: "space-between",
            py: 2,
            pr: 2,
          }}
        >
          <AreaTableFilters
            areaTypes={areaTypes}
            setQueryParams={setQueryParams}
          />
          <Box display="flex">
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input
                placeholder={t("search")}
                onChange={(e) => handleTableSearch(e)}
              />
            </Search>
            {!isTenant && (
              <>
                <MoreVerticalButton
                  aria-haspopup="true"
                  size={"small"}
                  type="button"
                  color="inherit"
                  variant="text"
                  disableElevation
                  data-testid={"upload-manually-menu"}
                  onClick={handleClick(importExportTab)}
                >
                  <MoreVertical />
                </MoreVerticalButton>
                <Menu
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  anchorEl={anchorDict.importExport}
                  open={Boolean(anchorDict.importExport)}
                  data-testid={"menu"}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={(): void => {
                      handleExportExcel();
                      handleClose();
                    }}
                    disableRipple
                    data-testid={"download-sample-link"}
                  >
                    {t("export.excel")}
                  </MenuItem>
                  <MenuItem
                    onClick={(): void => {
                      handleExportPDF();
                      handleClose();
                    }}
                    disableRipple
                  >
                    {t("export.pdf")}
                  </MenuItem>
                  {can(PermissionsUpdate.AREA) && (
                    <AreaImportData
                      importCallback={importCallback}
                      handleClose={handleClose}
                    ></AreaImportData>
                  )}
                </Menu>
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <UploadManuallyDialog
        onSuccess={onUploadSuccess}
        toggleModel={setUploadModalVisible}
        visible={uploadModelVisible}
      ></UploadManuallyDialog>
    </>
  );
};

export default AreaTableToolbar;
