import styled from "styled-components";
import { Card as MuiCard, Box, Grid, Typography, Paper } from "@mui/material";
import TextFieldComponent from "../../../../ui/formsFields/textField";
import MultiSelectComponent from "../../../../ui/formsFields/multiSelect";
import { Chip as MuiChip } from "@mui/material";

/* istanbul ignore next */
export const TextField = styled(TextFieldComponent)``;

/* istanbul ignore next */
export const FormHeader = styled(MuiCard)`
  font-size: ${(props): string => `${props.theme.spacing(4)}`};
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 10px;
`;

export const AreaType = styled(Typography)``;

/* istanbul ignore next */
export const Card = styled(MuiCard)`
  padding: ${(props): string =>
    `${props.theme.spacing(0)} ${props.theme.spacing(3)} ${props.theme.spacing(
      3
    )} ${props.theme.spacing(3)}`};

  text-align: left;
  margin-top: ${(props): string => `${props.theme.spacing(4)}`};
`;

/* istanbul ignore next */
export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

/* istanbul ignore next */
export const BKATitleContainer = styled.div`
  border-bottom: 1px solid #000;
  font-size: 18px;
  margin-bottom: 20px;
`;

/* istanbul ignore next */
export const MultiSelect = styled(MultiSelectComponent)``;

/* istanbul ignore next */
export const SelectBox = styled(Box)`
  gap: ${(props): string => props.theme.spacing(0.5)};
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

/* istanbul ignore next */
export const Chip = styled(MuiChip)`
  height: ${(props): string => props.theme.spacing(5)};
`;

/* istanbul ignore next */
export const SaveButton = styled(Grid)`
  margin-top: ${(props): string => `${props.theme.spacing(5)}`};
`;

export const EditableSection = styled(Paper)`
  position: relative;

  p {
    word-break: break-all;
  }
`;

/* istanbul ignore next */
export const EditableFormHeader = styled(MuiCard)`
  font-size: ${(props): string => `${props.theme.spacing(6)}`};
  font-weight: bold;
  padding-bottom: ${(props): string => `${props.theme.spacing(6)}`};
`;
