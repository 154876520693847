import http from "../utils/http";
import { CreatePropertyFormInitState } from "../components/properties/create/types";
import { PropertyUpdate, Bank } from "../types/fe/property";
import { CreateCompanyFormInitState } from "../components/company/create/types";

/* istanbul ignore next */
export const createProperty = async (
  values: CreatePropertyFormInitState | CreateCompanyFormInitState
): Promise<Response> => {
  return await http.post("/api/properties", values);
};

export const exportPdfPropertyList = async (): Promise<Response> => {
  return await http.get("/api/properties/export/pdf");
};

export const exportExcelPropertyList = async (): Promise<Response> => {
  return await http.get("/api/properties/export/xls");
};

export const downloadSampleFile = async (): Promise<Response> => {
  return await http.get("/api/properties/import/example-xls");
};

export const uploadFile = async (data: FormData): Promise<Response> => {
  return await http.post("/api/properties/import/xls", data);
};

export const getProperties = async (params = ""): Promise<Response> => {
  return await http.get(`/api/properties${params}`);
};

export const getProperty = async (id?: string): Promise<Response> => {
  return await http.get(`/api/properties/${id}`);
};

export const updateProperty = async (
  data: Partial<PropertyUpdate>
): Promise<Response> => {
  return http.put(`/api/properties/${data.id}`, data);
};

/* istanbul ignore next */
export const updatePropertyWithPost = async (
  id: number,
  data: FormData
): Promise<Response> => {
  return http.post(`/api/properties/${id}`, data);
};

/* istanbul ignore next */
export const updatePropertyWithFormData = async (
  id: string,
  data: FormData,
  progressHandler: (event: ProgressEvent) => void
): Promise<Response> => {
  return http.uploadFileHandler(
    "POST",
    `/api/properties/${id}`,
    data,
    progressHandler
  );
};

export const getAssetManagersHistory = async (
  id: number,
  params: string
): Promise<Response> => {
  return await http.get(
    `/api/properties/${id}/asset-management-history${params}`
  );
};

export const getPropertyTopTenants = async (id?: string): Promise<Response> => {
  return await http.get(`/api/properties/${id}/property-tenants/top`);
};

export const getPropertyFileTree = async (id?: string): Promise<Response> => {
  return await http.get(`/api/properties/${id}/folders`);
};

export const updateBankData = async (values: Bank): Promise<Response> => {
  return await http.post(`/api/banks`, values);
};

export const getPropertyShort = async (id?: string): Promise<Response> => {
  return await http.get(`/api/properties/${id}/breadcrumb`);
};
/* istanbul ignore next */
export const getPropertyCompanies = async (params = ""): Promise<Response> => {
  return await http.get(`/api/property-companies${params}`);
};
