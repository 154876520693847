import { FormikValues } from "formik/dist/types";
import { mapBackendValidationErrors } from "../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR, SUBMIT_STATUS } from "../../../constants";
import { HTTP_STATUS_CODES } from "../../../types/server";
import {
  replaceMomentByTimestamp,
  replaceTimestampByMomentDatepicker,
} from "../../../utils/date";
import {
  parseNumber,
  removeEmptyValues,
  replaceNullOrUndefinedByEmptyString,
} from "../../../utils/common";
import omit from "lodash/omit";
import { route } from "../../../utils/url";
import { OutgoingInvoice as BEOutgoingInvoice } from "../../../types/be/outgoingInvoices";
import { OutgoingInvoice as FEOutgoingInvoice } from "../../../types/fe/outgoingInvoice";
import {
  createOutgoingInvoice,
  previewOutgoingInvoicePdf,
  updateOutgoingInvoice,
} from "../../../api/outgoingInvoice";
import { OutgoingInvoiceCreateOrEditTypes } from "./types";

export const createOutgoingInvoiceInitState: FEOutgoingInvoice = {
  property_id: "",
  offer_id: "",
  contract_id: "",
  recipient_id: "",
  contact_id: "",
  contact: null,
  offer: null,
  contract: null,
  number: "",
  total: "",
  amount: null,
  date: null,
  date_from: null,
  date_to: null,
  currency_code: "EUR",
  notice: "",
  is_active: false,
  is_confidential: false,
  is_permanent: false,
  repeat_from: "",
  repeat_to: "",
  repeat_day: "",
  repetition_period_id: "",
  file_id: "",
  services: [
    {
      description: "",
      quantity: 1,
      price: null,
      tax_price: 0,
      tax: 0,
      total: 0,
    },
  ],
  is_dynamic: true,
};

export const touchedInitState = {
  property_id: false,
  offer_id: false,
  contract_id: false,
  recipient_id: false,
  repetition_period_id: false,
  number: false,
  amount: false,
  date: false,
  date_from: false,
  date_to: false,
  currency_code: false,
  notice: false,
  comment: false,
  is_active: false,
  is_confidential: false,
  is_permanent: false,
  total: false,
  repeat_from: false,
  repeat_to: false,
  repeat_day: false,
  file_id: false,
  services: [
    {
      description: false,
      quantity: false,
      price: false,
      tax: false,
    },
  ],
};
/* istanbul ignore next */
export const prepareOutgoingInvoiceBeforeSubmission = (
  reqData: FEOutgoingInvoice
): FEOutgoingInvoice => {
  const newObj = { ...reqData };
  newObj.date = newObj.date ? replaceMomentByTimestamp(newObj.date) : null;
  newObj.date_from = newObj.date_from
    ? replaceMomentByTimestamp(newObj.date_from)
    : null;
  newObj.date_to = newObj.date_to
    ? replaceMomentByTimestamp(newObj.date_to)
    : null;
  newObj.amount = newObj.amount ? parseNumber(newObj.amount) : null;
  if (!newObj.is_permanent) {
    delete newObj.total;
    delete newObj.repeat_from;
    delete newObj.repeat_to;
    delete newObj.repeat_day;
    delete newObj.repetition_period_id;
  } else {
    newObj.repeat_from = replaceMomentByTimestamp(newObj.repeat_from);
    newObj.repeat_to = replaceMomentByTimestamp(newObj.repeat_to);
    newObj.repeat_day = Number(newObj?.repeat_day);
  }
  newObj.services = newObj.services.map((item) => ({
    ...item,
    price: parseNumber(item?.price),
  }));
  newObj.recipient_id = newObj.contact_id;
  return removeEmptyValues(newObj);
};
/* istanbul ignore next */
export const prepareOutgoingInvoiceBeforeForm = (
  data: BEOutgoingInvoice
): FEOutgoingInvoice => ({
  ...omit(data, ["file"]),
  ...replaceNullOrUndefinedByEmptyString(
    omit(data, [
      "date",
      "date_from",
      "date_to",
      "repeat_from",
      "repeat_to",
      "file",
    ])
  ),
  contact_id: data.recipient?.id,
  contact: data.recipient,
  datev_debtor_id: data.datev_debtor?.id,
  property_id: data.property?.id,
  offer_id: data.offer?.id,
  contract_id: data.contract?.id,
  contract: data.contract,
  date: replaceTimestampByMomentDatepicker(data.date),
  date_from: replaceTimestampByMomentDatepicker(data.date_from),
  date_to: replaceTimestampByMomentDatepicker(data.date_to),
  repeat_from: replaceTimestampByMomentDatepicker(data.repeat_from),
  repeat_to: replaceTimestampByMomentDatepicker(data.repeat_to),
  repetition_period_id: data?.repetition_period?.id || "",
  file: data.file,
  file_id: data.file.id,
  is_dynamic: data.is_dynamic,
});
/* istanbul ignore next */
export const handleCreateOrEditOutgoingInvoiceFormSubmit = ({
  id,
  submitAction,
  setFormMessage,
  setPrevImage,
  setSelectedFileId,
  invoiceId,
  navigate,
}: OutgoingInvoiceCreateOrEditTypes) => {
  return async (
    values: FEOutgoingInvoice,
    { setStatus, setSubmitting, setTouched }: FormikValues
  ): Promise<void> => {
    const response =
      submitAction === SUBMIT_STATUS.PREVIEW
        ? await previewOutgoingInvoicePdf(
            prepareOutgoingInvoiceBeforeSubmission(values)
          )
        : invoiceId
        ? await updateOutgoingInvoice(
            invoiceId,
            prepareOutgoingInvoiceBeforeSubmission(values)
          )
        : await createOutgoingInvoice(
            prepareOutgoingInvoiceBeforeSubmission(values)
          );
    if (submitAction === SUBMIT_STATUS.PREVIEW) {
      response.blob().then((myBlob) => {
        const objectURL = URL.createObjectURL(myBlob);
        setSelectedFileId && setSelectedFileId(undefined);
        setPrevImage(objectURL);
      });
      return;
    }
    const json = await response.json();
    if (
      response.status === HTTP_STATUS_CODES.CREATED ||
      response.status === HTTP_STATUS_CODES.OK
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      setTouched(touchedInitState);
      navigate &&
        navigate(
          id
            ? route("properties.outgoing-invoices", id)
            : route("outgoing-invoices")
        );
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
      setSubmitting(false);
    }
  };
};
