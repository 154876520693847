import { FileTreeFile } from "../be.interfaces";

export interface File extends FileTreeFile {
  id: number;
  name: string;
  size: string;
  url: string;
  has_relation: boolean;
  path: string;
  extension: string;
}

/* istanbul ignore next */
export const isImage = <T extends Pick<File, "extension">>(
  file: T
): boolean => {
  const { extension } = file;
  return extension === "png" || extension === "jpg" || extension === "jpeg";
};

/* istanbul ignore next */
export const isPdf = <T extends Pick<File, "extension">>(file: T): boolean => {
  const { extension } = file;
  return extension === "pdf";
};
