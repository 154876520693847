import React, { ReactElement, useEffect, useCallback, useState } from "react";
import { TabPanelItemProps } from "../../../types";
import useTable from "../../../../../../ui/table/useTable";
import Table from "../../../../../../ui/table/Table";
import {
  itemsListHeadCells,
  snackbarInitState,
  tabs,
  UPLOAD_DATE_AND_TIME_FORMAT,
} from "./utils";
import { TableCell, Grid, Tooltip } from "@mui/material";
import {
  archiveAttachments,
  deleteAttachments,
  getAttachments,
} from "../../../../../../api/attachment";
import { handleServerError } from "../../../../../../utils/http";
import { Attachment } from "../../../../../../types/be/attachment";
import { ActionButtonWrapper } from "./styled";
import { formatTimestamp } from "../../../../../../utils/date";
import {
  DeleteIcon,
  MailIcon,
  Search,
  SearchIconWrapper,
  Input,
} from "../../../../../../ui/table/styled";
import { HTTP_STATUS_CODES } from "../../../../../../types/server";
import { NavButton } from "../../../../../inbox/list/styled";
import { ATTACHMENTS_TABS, SnackbarMessage } from "./types";
import { prepareQueryParams } from "../../../../../../utils/common";
import { useTranslation } from "react-i18next";
import { Archive, Unarchive } from "@mui/icons-material";
import UploadFile from "../uploadDialog";
import Snackbar from "../../../../../../ui/Snackbar";
import {
  COMMENTABLE,
  PermissionsUpdate,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../../../constants";
import SendEmail from "../forward";
import CommentsPopup from "../../../../../../ui/commentsPopUp";
import { showCommentsModalInitState } from "../../../../../../ui/commentsPopUp/utils";
import Button from "../../../../../../ui/button/Button";
import useAccessControl from "../../../../../../hooks/useAccessControl";
import { Search as SearchIcon } from "react-feather";
import ExpandableComment from "../../../../../../ui/expandableComment/ExpandableComment";
import FileTableCell from "../../../../components/fileTableCell";

/* istanbul ignore next */
const Files = ({ id, setDisabled }: TabPanelItemProps): ReactElement => {
  const { t } = useTranslation();
  const { can } = useAccessControl();
  const {
    data,
    setData,
    setTotal,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    queryParams,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    openConfirmToRemoveModal,
    rowToDelete,
    handleDeleteLastPageData,
    handleTableSearch,
  } = useTable<Attachment>();

  const [tab, setTab] = useState<ATTACHMENTS_TABS>(ATTACHMENTS_TABS.FILE);
  const [snackbarData, setSnackbarData] =
    useState<SnackbarMessage>(snackbarInitState);
  const [selectedFileId, setSelectedFileId] = useState<number | null>(null);
  const [isShowCommentsModal, setIsShowCommentsModal] = useState(
    showCommentsModalInitState
  );
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const handleFetchList = async (loading = true): Promise<void> => {
    setIsLoading(loading);
    const params = prepareQueryParams(queryParams, {
      property_id: id,
      is_archived: tab,
    });
    const response = await getAttachments(params);
    if (response.status !== HTTP_STATUS_CODES.OK) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const json = await response.json();
      setData(json?.data);
      setTotal(json?.meta?.total);
    }
    setIsLoading(false);
  };

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteAttachments(rowToDelete.id);
    handleDeleteLastPageData(handleFetchList);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteAttachments,
    handleFetchList,
    handleConfirmToRemoveModalClose,
  ]);

  const handleArchive = async (id: string): Promise<void> => {
    const response = await archiveAttachments(id);
    const result = await response.json();
    if (response.status !== HTTP_STATUS_CODES.OK) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
      setSnackbarData({
        visible: true,
        text: t(SOMETHING_WENT_WRONG_ERROR),
        color: "error",
      });
    } else {
      handleFetchList();
      setSnackbarData({
        visible: true,
        text: result?.is_archived
          ? t("property.objectDataSheet.attachments.fileUnArchivedSuccessfully")
          : t("property.objectDataSheet.attachments.fileArchivedSuccessfully"),
        color: "success",
      });
    }
  };

  const handleCloseSnackbar = (): void => setSnackbarData(snackbarInitState);

  const handleCloseSendEmailModal = (): void => {
    setSelectedFileId(null);
  };

  const handleOpenComments = useCallback((row: Attachment): void => {
    setIsShowCommentsModal({ isVisible: true, rowId: row?.id });
  }, []);

  const handleCloseCommentsModal = useCallback((): void => {
    setIsShowCommentsModal(showCommentsModalInitState);
    handleFetchList();
  }, [handleFetchList]);

  const afterUploadFile = useCallback((): void => {
    handleFetchList();
    setIsUploadDialogOpen(false);
  }, [handleFetchList]);

  useEffect(() => {
    setDisabled(false);
    handleFetchList();
  }, [tab, id, queryParams]);

  const renderRow = (attachment: Attachment): ReactElement => (
    <>
      <FileTableCell file={attachment?.file} />
      <TableCell>
        {attachment?.uploaded_by?.first_name}{" "}
        {attachment?.uploaded_by?.last_name}
      </TableCell>
      <TableCell>
        {formatTimestamp(attachment?.uploaded_at, UPLOAD_DATE_AND_TIME_FORMAT)}
      </TableCell>
      <TableCell>
        <ExpandableComment
          seeAllComment={() => handleOpenComments(attachment)}
          commentData={attachment?.latest_comment}
        ></ExpandableComment>
      </TableCell>
      <TableCell>
        <Grid container justifyContent="flex-end">
          {can(PermissionsUpdate.PROPERTY) &&
            (attachment.is_archived ? (
              <Grid item>
                <Tooltip
                  title={t("property.objectDataSheet.attachments.unArchive")}
                  placement="top"
                >
                  <Grid container>
                    <ActionButtonWrapper item>
                      <Unarchive
                        color="action"
                        width={22}
                        height={22}
                        onClick={() => handleArchive(String(attachment?.id))}
                        fontSize="small"
                      />
                    </ActionButtonWrapper>
                  </Grid>
                </Tooltip>
              </Grid>
            ) : (
              <Grid item>
                <Tooltip
                  title={t("property.objectDataSheet.attachments.archive")}
                  placement="top"
                >
                  <Grid container>
                    <ActionButtonWrapper item>
                      <Archive
                        color="action"
                        width={22}
                        height={22}
                        onClick={() => handleArchive(String(attachment?.id))}
                        fontSize="small"
                      />
                    </ActionButtonWrapper>
                  </Grid>
                </Tooltip>
              </Grid>
            ))}
          <Grid item>
            <MailIcon
              size={18}
              onClick={() => setSelectedFileId(attachment?.id)}
            />
          </Grid>
          {can(PermissionsUpdate.PROPERTY) && (
            <Grid item>
              <DeleteIcon
                onClick={(): void => openConfirmToRemoveModal(attachment)}
                size={18}
              />
            </Grid>
          )}
        </Grid>
      </TableCell>
    </>
  );

  const TableToolbar = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container mt={1} spacing={2}>
          {tabs.map((item) => (
            <Grid item key={"file"}>
              <NavButton
                size="small"
                disabled={tab === item.id}
                key={item.id}
                onClick={() => setTab(item.id)}
                title={t(item.title)}
                variant="text"
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container flexDirection="row" justifyContent="space-between">
          <Grid item xs={6} display="flex" alignItems={"center"} gap={2}>
            {can(PermissionsUpdate.PROPERTY) &&
            tab === ATTACHMENTS_TABS.FILE ? (
              <Grid item>
                <Button
                  title={t("property.upload")}
                  onClick={() => setIsUploadDialogOpen(true)}
                  color="success"
                  size="medium"
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid item>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t("search")} onChange={handleTableSearch} />
            </Search>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onRowRemove={handleRowRemove}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        paperSx={{ width: "100%", my: 2, px: 5, py: 1, height: "100%" }}
      />
      {selectedFileId ? (
        <SendEmail
          handleCloseSendEmailModal={handleCloseSendEmailModal}
          fileId={selectedFileId}
          showSnackbar={() =>
            setSnackbarData({
              visible: true,
              text: t(
                "property.objectDataSheet.attachments.mailSendSuccessfully"
              ),
              color: "success",
            })
          }
        />
      ) : null}
      {isShowCommentsModal.isVisible && (
        <CommentsPopup
          handleCloseDialog={handleCloseCommentsModal}
          id={String(isShowCommentsModal?.rowId)}
          relation={COMMENTABLE.ATTACHMENT}
        />
      )}
      {isUploadDialogOpen ? (
        <UploadFile
          afterUploadFile={afterUploadFile}
          setSnackbarData={setSnackbarData}
          open={isUploadDialogOpen}
          onClose={() => setIsUploadDialogOpen(false)}
        />
      ) : null}
      <Snackbar
        message={snackbarData.text}
        color={snackbarData.color}
        open={snackbarData.visible}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

export default Files;
