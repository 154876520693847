import { TableCell } from "../../../../../../types/table";
import { ATTACHMENTS_TABS, SnackbarMessage } from "./types";
/* istanbul ignore next */
export const itemsListHeadCells: TableCell[] = [
  {
    id: "fileName",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.objectDataSheet.attachments.fileName",
  },
  {
    id: "uploaded_by",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.objectDataSheet.attachments.uploadedBy",
  },
  {
    id: "uploaded_at",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.objectDataSheet.attachments.uploadedAt",
  },
  {
    id: "comments",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "comment",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];

/* istanbul ignore next */
export const tabs = [
  {
    title: "property.objectDataSheet.attachments.files",
    id: ATTACHMENTS_TABS.FILE,
  },
  {
    title: "property.objectDataSheet.attachments.archived",
    id: ATTACHMENTS_TABS.ARCHIVED,
  },
];

/* istanbul ignore next */
export const snackbarInitState: SnackbarMessage = {
  text: "",
  visible: false,
  color: "success",
};

/* istanbul ignore next */
export const UPLOAD_DATE_AND_TIME_FORMAT = "DD.MM.yyyy HH:mm:ss";
