import React, { ReactElement, useState, useEffect, ChangeEvent } from "react";
import { FormSectionTitle } from "../generalInformation/styled";
import { useTranslation } from "react-i18next";
import UpdateButton from "../../../../ui/button/Button";
import { Checkbox, FormControlLabel, Grid, Box } from "@mui/material";
import { FormikValues } from "formik/dist/types";
import Snackbar from "../../../../ui/Snackbar";
import { NotificationSettings } from "../../../../types/be/notification";
/* istanbul ignore next */
function NotificationSettingsForm({
  status,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  notificationSettings,
  notificationCodes,
  setNotificationCodes,
}: FormikValues): ReactElement {
  const { t } = useTranslation();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState<boolean>(false);
  const handleClose = (): void => setIsSnackbarVisible(false);

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  useEffect(() => {
    setFieldValue(
      "code",
      Object.keys(notificationCodes).filter(function (x) {
        return notificationCodes[x] !== false;
      })
    );
  }, [notificationCodes]);

  const handleChangeAll = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!notificationSettings) return;
    const auxNotificationCodes = JSON.parse(JSON.stringify(notificationCodes));
    Object.keys(notificationCodes).forEach((el) => {
      auxNotificationCodes[el] = event.target.checked;
    });
    setNotificationCodes(auxNotificationCodes);
  };

  const handleChangeOne =
    (code: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setNotificationCodes((prevState: Array<string>) => ({
        ...prevState,
        [code]: event.target.checked,
      }));
    };

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container>
          <Grid item mb={12} sm={12} xl={12} pl={12}>
            <FormSectionTitle mb={4}>
              {t("profile.notificationSettings")}
            </FormSectionTitle>
            {notificationCodes && (
              <Box sx={{ borderBottom: 1 }}>
                <FormControlLabel
                  label={t("settings.role.all")}
                  control={
                    <Checkbox
                      checked={Object.values(notificationCodes).every((el) =>
                        Boolean(el)
                      )}
                      onChange={handleChangeAll}
                    />
                  }
                />
              </Box>
            )}
            {notificationSettings.map(
              (notificationSetting: NotificationSettings) => (
                <Box key={notificationSetting.code} sx={{ borderBottom: 1 }}>
                  <FormControlLabel
                    label={notificationSetting.name}
                    control={
                      <Checkbox
                        checked={Boolean(
                          notificationCodes[notificationSetting.code]
                        )}
                        onChange={handleChangeOne(notificationSetting.code)}
                      />
                    }
                  />
                </Box>
              )
            )}
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={0}
          >
            <UpdateButton
              testId="update-submit"
              title={t("update")}
              type="submit"
              color="success"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              size="large"
            />
          </Grid>
          <Snackbar
            message={t("userWasSuccessfullyUpdated")}
            color="success"
            open={isSnackbarVisible}
            handleClose={handleClose}
            data-testid="snackbar-invoice-success"
          />
        </Grid>
      </form>
    </>
  );
}

export default NotificationSettingsForm;
