import http from "../utils/http";
import { IdPropType } from "../types/models";
import {
  OfferForm,
  ReleaseProcessRoles,
  UpdateOfferPayload,
} from "../types/fe.interfaces";

export const getOffer = async (offerId: string): Promise<Response> => {
  return await http.get(`/api/offers/${offerId}`);
};

export const getOffers = async (params: string): Promise<Response> => {
  return await http.get(`/api/offers${params}`, true);
};
/* istanbul ignore next */
export const getOfferStatuses = async (): Promise<Response> => {
  return await http.get(`/api/offers/statuses`);
};
/* istanbul ignore next */
export const deleteOffer = async ({ id }: IdPropType): Promise<Response> => {
  return await http.delete(`/api/offers/${id}`);
}; /* istanbul ignore next */
export const updateOfferStatus = async (
  offer_id: number,
  status_id: number,
  who: ReleaseProcessRoles
): Promise<Response> => {
  return http.patch(`/api/offers/${offer_id}/statuses/${status_id}/${who}`, {});
};

export const createOffer = async (data: OfferForm): Promise<Response> => {
  return await http.post(`/api/offers`, data);
};

export const updateOffer = async (
  payload: Partial<UpdateOfferPayload>,
  offerId: string
): Promise<Response> => {
  return await http.put(`/api/offers/${offerId}`, payload);
};

/* istanbul ignore next */
export const forwardRequiredReleaseToAdmin = async (
  offerId: number | number[],
  text?: string
): Promise<Response> => {
  return await http.post(`/api/offers/${offerId}/final-releaser`, {
    text,
  });
};

/* istanbul ignore next */
export const releaseOfferAM = async (
  id: string,
  release_am: number | null
): Promise<Response> => {
  return await http.patch(
    `/api/offers/${id}/release/asset-manager?release_am=${release_am}`,
    {}
  );
};
