import styled from "styled-components/macro";
import {
  CardContent as MuiCardContent,
  Card as MuiCard,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
} from "@mui/material";
import MuiDialog from "@mui/material/Dialog";

export const CommentLeftBy = styled.span.attrs(() => ({}))`
  font-weight: 700;
  color: ${(props): string => props.theme.comments.textColor};
  cursor: pointer;
`;

export const Dialog = styled(MuiDialog)`
  .MuiDialog-container {
    > .MuiPaper-root {
      height: 100vh;
    }
  }
`;

export const CardContent = styled(MuiCardContent)`
  > div > .MuiPaper-root {
    padding: ${(props): string => props.theme.spacing(0)};
  }
`;

export const Card = styled(MuiCard)<{ mb: number }>`
  overflow-y: auto;
`;

export const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

export const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;
