import { Dialog, DialogContent, Grid } from "@mui/material";
import { Formik } from "formik";
import React, { ReactElement, useState } from "react";
import DialogTitle from "../../../../../../../ui/dialog/DialogTitle";
import { prepareExtendedForm } from "../../../utils";
import DatePicker from "../../../../../../../ui/datePicker";
import { getMinDateForExtentContract, handleSubmitExtend } from "./utils";
import ButtonCustom from "../../../../../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import { ExtendDialogProps } from "./types";
import { FormMessageInterface } from "../../../../../../../types/form";
import { FormMessageInItState } from "../../../../../../../constants";
import FormAlert from "../../../../../../../ui/formAlert/FormAlert";

/*istanbul ignore next*/
const ExtendDialog = ({
  initialValues,
  extendModal,
  handleCloseModal,
}: ExtendDialogProps): ReactElement => {
  const { t } = useTranslation();
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);

  return (
    <Dialog
      open={extendModal}
      aria-labelledby="max-width-dialog-title"
      data-testid="extend-modal"
      onClose={handleCloseModal()}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        title="property.area.extendContract"
        onClose={handleCloseModal()}
      />
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={prepareExtendedForm(initialValues)}
          onSubmit={handleSubmitExtend({
            setFormMessage,
            onSuccess: handleCloseModal(true),
          })}
        >
          {({ values, status, isSubmitting, handleSubmit }): ReactElement => (
            <form>
              {formMessage.text && (
                <FormAlert formMessage={formMessage} sx={{ mb: 2.5 }} />
              )}
              <Grid container spacing={6} columns={12}>
                <Grid item xs={12} pt={0} mt={2}>
                  <DatePicker
                    name="rent_end"
                    label={t("tenant.endRentDate")}
                    status={status}
                    value={values?.rent_end}
                    minDate={getMinDateForExtentContract(
                      initialValues?.rent_end,
                      values?.rent_start
                    )}
                    minHeight={"50px"}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
                mt={4}
              >
                <Grid item>
                  <ButtonCustom
                    onClick={handleCloseModal()}
                    title={t("cancel")}
                    variant={"text"}
                    type="button"
                    size="large"
                  />
                </Grid>
                <Grid item>
                  <ButtonCustom
                    onClick={handleSubmit}
                    title={t("update")}
                    sx={{ ml: 1 }}
                    color="success"
                    size="large"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ExtendDialog;
