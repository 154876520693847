import styled from "styled-components/macro";
import { TextField as MuiTextField, Box } from "@mui/material";
import CloseIconMui from "@mui/icons-material/Close";

export const TextField = styled(MuiTextField)`
  .MuiInputBase-root {
    color: ${(props): string => props.theme.palette.grey[600]};
  }
  .MuiSelect-select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const DatepickerTextField = styled(MuiTextField)`
  min-width: ${(props): string => props.theme.spacing(53.3)};
`;

export const CloseIcon = styled(CloseIconMui)`
  cursor: pointer;
  z-index: 1000111;
`;

export const SelectBox = styled(Box)`
  gap: ${(props): string => props.theme.spacing(0.5)};
  display: flex;
  flex-wrap: wrap;
`;
