import React, { ReactElement, SyntheticEvent } from "react";
import { Grid, Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import camelCase from "lodash/camelCase";
import { useNavigate, useParams } from "react-router-dom";

import {
  StyledGridTabs,
  StyledGridTabsContent,
  StyledTabContent,
} from "./styled";
import { TABS, simpleTabProps, tabsLabels } from "./utils";
import Creditor from "./creditor/list";
import Debtor from "./debtor/list";
import DebitAccount from "./debitAccount";
import TabPanel from "../../../../ui/tabs/tabPanel";
import { route } from "../../../../utils/url";
import Config from "./config";

const APIDateV = (): ReactElement => {
  const { t } = useTranslation();
  const { tab, id } = useParams();
  const navigate = useNavigate();

  const handleTabChange = (newValue: TABS): void => {
    navigate(route("properties.invoices.apidatev.tab", id, newValue));
  };

  return (
    <Grid container spacing={0} columns={12}>
      <StyledGridTabs item xs={12} sm={12} mb={4}>
        <Tabs
          value={tab}
          onChange={(e: SyntheticEvent, value: TABS) => {
            handleTabChange(value);
          }}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          {tabsLabels.map((tab, index) => (
            <Tab
              value={tab}
              label={t(`documentRelease.invoice.apidatev.${camelCase(tab)}`)}
              key={`${tab}`}
              {...simpleTabProps(index)}
            />
          ))}
        </Tabs>
      </StyledGridTabs>
      <StyledGridTabsContent item xs={12} sm={12}>
        <StyledTabContent>
          <TabPanel key={TABS.CREDITOR} value={tab!} index={TABS.CREDITOR}>
            <Creditor />
          </TabPanel>
          <TabPanel key={TABS.DEBTOR} value={tab!} index={TABS.DEBTOR}>
            <Debtor />
          </TabPanel>
          <TabPanel
            key={TABS.DEBITACCOUNT}
            value={tab!}
            index={TABS.DEBITACCOUNT}
          >
            <DebitAccount />
          </TabPanel>
          <TabPanel key={TABS.CONFIG} value={tab!} index={TABS.CONFIG}>
            <Config />
          </TabPanel>
        </StyledTabContent>
      </StyledGridTabsContent>
    </Grid>
  );
};

export default APIDateV;
