/* istanbul ignore file */

import React, { ReactElement, useEffect, useState } from "react";
import Button from "../../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Snackbar from "../../../../ui/Snackbar";
import { Form, FormikValues } from "formik";
import { Text as TextField } from "../../../../ui/formsFields/text";

function ExitAndRepaymentBondForm({
  handleSubmit,
  isSubmitting,
  status,
}: FormikValues): ReactElement {
  const { t } = useTranslation();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  const handleSnackbarClose = (): void => setIsSnackbarVisible(false);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid container sx={{ mb: 3, mt: 2 }}>
          <Grid item xs={10} sm={10} md={4} sx={{ mb: 3 }}>
            <TextField
              name="property_value_development"
              size="medium"
              label={t("property.propertyValueDevelopment")}
              status={status}
              inputProps={{
                "data-testid": "property-value-development",
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1}>
            <Button
              title={t("update")}
              type="submit"
              sx={{ ml: "1rem", mt: "0.6rem" }}
              color="success"
              testId={"create-vacation-holiday-button"}
              disabled={isSubmitting}
              isLoading={isSubmitting}
            />
          </Grid>
        </Grid>
      </Form>
      <Snackbar
        open={isSnackbarVisible}
        message={t("property.propertyWasSuccessfullyUpdated")}
        color="success"
        handleClose={handleSnackbarClose}
      />
    </>
  );
}

export default ExitAndRepaymentBondForm;
