import React from "react";
import EditRole from "../../../components/settings/roles/edit";
import AccessGuard from "../../../components/guards/AccessGuard";
import {
  PermissionsCreate,
  PermissionsRead,
  PermissionsUpdate,
} from "../../../constants";
import CreateRole from "../../../components/settings/roles/create";
import RolesList from "../../../components/settings/roles/list";

export default {
  path: "roles",
  children: [
    {
      path: "",
      element: (
        <AccessGuard permissions={[PermissionsRead.SETTINGS_ROLE]}>
          <RolesList />
        </AccessGuard>
      ),
    },
    {
      path: ":id/:tab",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.SETTINGS_ROLE]}>
          <EditRole />
        </AccessGuard>
      ),
    },
    {
      path: "create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.SETTINGS_ROLE]}>
          <CreateRole />
        </AccessGuard>
      ),
    },
  ],
};
