export default {
  companies: "Unternehmen",
  companyName: "Firmenname",
  createCompany: "Unternehmen erstellen",
  companyCreated: "Unternehmen erstellt",
  createNewCompany: "Neues Unternehmen gründen",
  companyLocation: "Standort des Unternehmens",
  companyColumnName: "Name des Unternehmens",
  notice: "Notizen",
}
