import {
  SwotCreateDto,
  InitSwotFieldState,
} from "../components/properties/components/swotModal/types";
import http from "../utils/http";

export const getSwots = async (params: string): Promise<Response> => {
  return await http.get(`/api/swot${params}`);
};

export const getSwotTypes = async (): Promise<Response> => {
  return await http.get(`/api/swot/types`);
};

export const updateSwot = async (
  id: string,
  data: InitSwotFieldState
): Promise<Response> => {
  return await http.put(`/api/swot/${id}`, data);
};

export const createSwot = async (data: SwotCreateDto): Promise<Response> => {
  return await http.post(`/api/swot`, data);
};
