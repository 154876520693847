import { Button, Grid } from "@mui/material";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommentWrapper, ExpandableCommentWrapper } from "./styled";
import { ExpandableCommentProps } from "./types";
import { formatTimestamp } from "../../utils/date";
/* istanbul ignore next */
const ExpandableComment = ({
  seeAllComment,
  commentData,
}: ExpandableCommentProps): ReactElement => {
  const collapseLimit = 45;
  const comment = commentData?.body || "";

  const ref = useRef<HTMLDivElement | null>(null);
  const [limit, setLimit] = useState(collapseLimit);
  const [collapsable, setCollapsable] = useState(false);
  const { t } = useTranslation();
  const explandClick = (isExpand: boolean): void => {
    setLimit(isExpand ? comment?.length : collapseLimit);
  };

  useEffect(() => {
    if (
      comment !== "" &&
      ref.current?.offsetHeight &&
      ref.current?.offsetHeight > collapseLimit
    ) {
      setCollapsable(true);
    } else {
      setCollapsable(false);
    }
  }, [ref.current, comment]);

  const prepareComment = (): string => {
    return `${
      commentData?.user && commentData?.body
        ? `<span class="comment-user">${commentData?.user?.first_name} ${
            commentData?.user?.last_name
          } ${formatTimestamp(
            `${commentData?.created_at}`,
            "DD.MM.yyyy HH:mm"
          )}</span></br>`
        : ""
    }<div class="comment">${comment}</div>`;
  };
  return (
    <>
      <ExpandableCommentWrapper>
        <CommentWrapper
          maxHeight={collapsable && limit === collapseLimit ? "35px" : "auto"}
          breakWord={!(collapsable && limit === comment?.length)}
          dangerouslySetInnerHTML={{
            __html: prepareComment(),
          }}
          ref={ref}
        ></CommentWrapper>
        <Grid container>
          <Grid>
            {collapsable && limit == collapseLimit && (
              <>
                ...
                <Button
                  size="small"
                  color="success"
                  variant="text"
                  onClick={() => explandClick(true)}
                  data-testid="see-more-comment"
                  sx={{ pr: 5 }}
                >
                  {t("seeMore")}{" "}
                </Button>
              </>
            )}
            {collapsable && limit === comment?.length && (
              <Button
                size="small"
                color="success"
                variant="text"
                data-testid="see-less-comment"
                onClick={() => explandClick(false)}
                sx={{ pr: 5 }}
              >
                {t("seeLess")}
              </Button>
            )}
          </Grid>
        </Grid>
        {seeAllComment && (
          <Grid>
            <Button
              size="small"
              color="primary"
              variant="contained"
              data-testid="see-all-comments"
              sx={{ lineHeight: "1.4", pl: 1.25, pr: 1.25 }}
              onClick={() => seeAllComment()}
            >
              {t("tenant.comment")}
            </Button>
          </Grid>
        )}
      </ExpandableCommentWrapper>
    </>
  );
};

export default ExpandableComment;
