import React, { ReactElement } from "react";
import { FormControl, FormControlLabel } from "@mui/material";
import kebabCase from "lodash/kebabCase";
import camelCase from "lodash/camelCase";
import { useTranslation } from "react-i18next";

import { FormFields } from "../components/contacts/edit/searchProfile/types";
import Checkbox from "../ui/formsFields/checkbox/index";

export const useFormCheckboxField = (): ((
  formField: FormFields,
  transPrefix: string
) => ReactElement) => {
  const { t } = useTranslation();

  return (formField: FormFields, transPrefix: string): ReactElement => {
    const label = t(`${transPrefix}.${camelCase(formField.name)}`);

    const inputProps = {
      "data-testid": `${kebabCase(formField.name)}-input`,
    };

    return (
      <FormControl key={formField.name}>
        <FormControlLabel
          control={<Checkbox name={formField.name} {...inputProps} />}
          label={label}
        />
      </FormControl>
    );
  };
};
