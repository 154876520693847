import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  NODE_ENVIRONMENTS,
  SEARCH_PARAM_MIN_CHARACTERS,
} from "../../constants";
import { User } from "../../types/models";
import debounce from "lodash/debounce";
import { prepareQueryParams } from "../../utils/common";
import { getUsers } from "../../api/users";
import { isNodeEnv } from "../../utils/env";
import {
  MentionedUserItem,
  MentionedUserName,
  MentionedUsersItems,
} from "./styled";
import { Dropdown } from "../autocomplete/styled";
import { createMentionedUsers } from "../../api/comments";
import { getJson } from "../../utils/http";
import { DeleteIcon } from "../table/styled";
import { getUniqUsers } from "./utils";
import useSnackbar from "../snackbar1/useSnackbar";
import { MentionedUsersProps } from "./types";
/* istanbul ignore next */
const MentionedUsers = ({
  users,
  mentionedUsers,
  setMentionedUsers,
  isUsersLoading,
  relation,
  relationId: relation_id,
}: MentionedUsersProps): ReactElement => {
  const { t } = useTranslation();
  const { Snackbar, snackbar } = useSnackbar();
  const uniqUsers = useMemo(
    () => getUniqUsers(mentionedUsers, users),
    [mentionedUsers, users]
  );
  const [options, setOptions] = useState<User[] | null>(uniqUsers);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!users) return;

    setOptions(uniqUsers);
  }, [users, uniqUsers]);

  const getOptionLabel = useCallback((user: User | null): string => {
    if (!user) return "";
    const { first_name, last_name, id } = user;
    return `${first_name} ${last_name} ${id}`;
  }, []);

  const onAutocompleteChange = useCallback(
    async (_: unknown, newUser: User | null) => {
      if (!newUser) return;
      const user_id = [...mentionedUsers.map(({ id }) => id), newUser.id];
      try {
        setLoading(true);
        createMentionedUsers({
          relation_id,
          relation,
          user_id,
        })
          .then(getJson)
          .then((res) => {
            const { data } = res;
            setMentionedUsers((prevState) => ({ ...prevState, data }));
            snackbar.success(t("userWasSuccessfullyAdded"));
          })
          .catch((err) => snackbar.error(err.toString()))
          .finally(() => setLoading(false));
      } catch (error) {
        snackbar.error(String(error));
      }

      setInputValue("");
    },
    [mentionedUsers, uniqUsers]
  );

  const filterOptions = useCallback(
    (options: User[]): User[] => {
      return options.filter(
        (option) => !mentionedUsers.some((el) => el.id === option.id)
      );
    },
    [mentionedUsers]
  );

  const handleAutocompleteInputChange = async (
    search: string
  ): Promise<User[]> => {
    const params = prepareQueryParams("", {
      search,
    });
    const response = await getUsers(params);
    const { data } = await response.json();

    return data;
  };

  const onSearchFieldTextChange = useCallback(
    debounce(
      async (search: string): Promise<void> => {
        if (search.length < SEARCH_PARAM_MIN_CHARACTERS) {
          setOptions(null);
        } else {
          setLoading(true);
          try {
            const response: User[] = await handleAutocompleteInputChange(
              search
            );
            setOptions([...mentionedUsers, ...response]);
            setLoading(false);
          } catch (e) {
            setOptions([]);
          }
        }
      },
      /* istanbul ignore next */ isNodeEnv(NODE_ENVIRONMENTS.TEST) ? 0 : 500
    ),

    [
      mentionedUsers,
      handleAutocompleteInputChange,
      debounce,
      SEARCH_PARAM_MIN_CHARACTERS,
    ]
  );

  const handleDeleteMentionedUser = useCallback(
    (id: number) => () => {
      const user_id = (mentionedUsers || [])
        .filter((el) => el.id !== id)
        .map(({ id }) => id);
      try {
        setLoading(true);
        createMentionedUsers({
          relation_id,
          relation,
          user_id,
        })
          .then(getJson)
          .then((res) => {
            const { data } = res;
            setMentionedUsers((prevState) => ({ ...prevState, data }));
          })
          .catch((err) => snackbar.error(String(err)))
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
      }
    },
    [mentionedUsers]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Autocomplete
          loading={isUsersLoading || loading}
          disabled={isUsersLoading || loading}
          fullWidth
          inputValue={inputValue}
          options={options || /* istanbul ignore next */ []}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={(option, value): boolean =>
            option?.id === value?.id
          }
          filterOptions={filterOptions}
          onChange={onAutocompleteChange}
          renderOption={(props, option): ReactElement => (
            <li {...props}>
              {option?.first_name} {option?.last_name}
            </li>
          )}
          PaperComponent={(paperProps) => {
            const { children, ...restPaperProps } = paperProps;
            return (
              <Dropdown>
                <Paper {...restPaperProps}>{children}</Paper>
              </Dropdown>
            );
          }}
          componentsProps={{
            clearIndicator: {
              onClick: () => {
                setOptions(uniqUsers);
                setInputValue("");
              },
              sx: {
                display: inputValue ? "inline-flex" : "none",
              },
            },
          }}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                label={t("users")}
                placeholder={t("Select user")}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  onSearchFieldTextChange(e.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {(loading || isUsersLoading) && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            </>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <MentionedUsersItems>
          {mentionedUsers.map(({ id, first_name, last_name }) => {
            return (
              <MentionedUserItem key={id}>
                <MentionedUserName>
                  {first_name} {last_name}
                </MentionedUserName>
                <Box>
                  <DeleteIcon
                    size={15}
                    onClick={handleDeleteMentionedUser(id)}
                    data-testid={"delete-user"}
                  />
                </Box>
              </MentionedUserItem>
            );
          })}
        </MentionedUsersItems>
      </Grid>
      {Snackbar}
    </Grid>
  );
};

export default MentionedUsers;
