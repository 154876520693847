import { FormikValues } from "formik";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { mapBackendValidationErrors } from "../../../utils/form";
import { EditVacancyValueProps, SubmitEditVacancyProps } from "./types";
import { Vacancy } from "../../../types/be/vacancies";
import { updateArea } from "../../../api/area";
import { getModifiedKeys, parseNumber } from "../../../utils/common";
import moment from "moment";

const prepareDataBeforeSubmit = (
  data: EditVacancyValueProps
): EditVacancyValueProps => {
  return {
    ...data,
    empty_space_since: data?.empty_space_since
      ? moment(data?.empty_space_since).unix()
      : null,
    price: parseNumber(data?.price),
  };
};

export const handleUpdateEmptyAreaInformation = ({
  row,
  snackbar,
  t,
  onSuccess,
}: SubmitEditVacancyProps) => {
  return async (
    values: EditVacancyValueProps,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const data = getModifiedKeys(
      prepareEditVacancyBeforeForm(row),
      prepareDataBeforeSubmit(values)
    );
    const response = await updateArea(String(row?.id), data);
    const json = await response.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      snackbar.success(
        t("property.emptyAreaForm.informationUpdatedSuccessfully")
      );
      onSuccess();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
    } else {
      snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
    }
    setSubmitting(false);
  };
};

export const prepareEditVacancyBeforeForm = (
  row: Vacancy
): EditVacancyValueProps => {
  return {
    empty_space_since: row?.empty_space_since
      ? row?.empty_space_since * 1000
      : null,
    price: row.potential_rent_income,
  };
};
