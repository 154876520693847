import { UserRoles } from "../constants";
import http from "../utils/http";

/* istanbul ignore next */
export const getRole = async (idOrCode: number | string): Promise<Response> => {
  return await http.get(`/api/roles/${idOrCode}`);
};

/* istanbul ignore next */
export const getRoles = async (params = ""): Promise<Response> => {
  return await http.get(`/api/roles${params}`);
};

/* istanbul ignore next */
export const updateRole = async (
  id: number,
  role: Partial<UpdateRoleDto>
): Promise<Response> => {
  return await http.put(`/api/roles/${id}`, role);
};

/* istanbul ignore next */
export const syncProperty = async (
  id: number,
  role: Partial<UpdateRoleDto>
): Promise<Response> => {
  return await http.put(`/api/roles/${id}/sync-properties`, role);
};

/* istanbul ignore next */
export const fetchUsersByRoleId = async (
  role: UserRoles
): Promise<Response> => {
  return await http.get(`/api/roles/${role}/users`);
};

/* istanbul ignore next */
export const deleteRoles = async (id: string): Promise<Response> => {
  return await http.delete(`/api/roles/${id}`);
};

export interface UpdateRoleDto {
  property_category_code: string;
  name?: string;
  permission_code?: string[];
  property_id?: number[];
  all_properties?: boolean;
}
