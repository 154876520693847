import { InsuranceCreate } from "../../../../types/fe.interfaces";
import { HandleSubmitProps } from "./types";
import { FormikHelpers } from "formik";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { createInsurance } from "../../../../api/insurance";
import { getReleaseRoute } from "../../utils";
import { parseNumber } from "../../../../utils/common";
import { createInsuranceFromInbox } from "../../../../api/inbox";

export const initialData: InsuranceCreate = {
  contact_id: "",
  file_id: "",
  amount: "",
  cancellation_period: "",
  release_am: null,
};
/* istanbul ignore next */
export const handleSubmit = ({
  insuranceId,
  topicId,
  setFormMessage,
  navigate,
  inboxData,
}: HandleSubmitProps) => {
  return async (
    values: InsuranceCreate,
    { setStatus }: FormikHelpers<InsuranceCreate>
  ): Promise<void> => {
    const payload: InsuranceCreate = {
      property_id: insuranceId,
      topic_id: topicId,
      contact_id: values.contact_id,
      file_id: +values.file_id,
      cancellation_period: +values.cancellation_period,
      amount: parseNumber(values.amount, true),
    };
    const apiCalls = [
      inboxData
        ? createInsuranceFromInbox(inboxData.id, payload)
        : createInsurance(payload),
    ];
    const response = await Promise.all(apiCalls);
    const statusCodes = response.map((res) => res.status);
    if (statusCodes.every((code) => [201, 200].includes(code))) {
      setStatus({ success: true });
      setFormMessage(FormMessageInItState);
      navigate &&
        !inboxData &&
        navigate(getReleaseRoute("insurances", insuranceId));
      inboxData && inboxData && window.history.back();
    } else if (statusCodes.includes(422)) {
      const result = await response.find((res) => res.status === 422)?.json();
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(result?.errors),
      });
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
    }
  };
};
