import { Grid, Tab, Tabs } from "@mui/material";
import React, { ReactElement, SyntheticEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  StyledGridTabs,
  StyledGridTabsContent,
  StyledTabContent,
} from "../../properties/objectDataSheet/styled";
import { EDIT_CONTACT_TABS } from "./utils";
import ContactDetails from "./contactDetails/ContactDetails";
import FinanceProfile from "./financeProfile/FinanceProfile";
import SearchProfile from "./searchProfile/SearchProfile";
import { getContact } from "../../../api/contacts";
import { useFetch } from "../../../hooks/useFetch";
import { Contact } from "./types";
import { route } from "../../../utils/url";
import TabPanel from "../../../ui/tabs/tabPanel";
import camelCase from "lodash/camelCase";

const EditContactTabs = (): ReactElement => {
  const { t } = useTranslation();
  const { tab, id } = useParams();
  const navigate = useNavigate();

  const handleTabChange = (newValue: EDIT_CONTACT_TABS): void => {
    navigate(route("contact.edit.tab", id, newValue));
  };

  const tabsLabels = [
    EDIT_CONTACT_TABS.CONTACT_DETAILS,
    EDIT_CONTACT_TABS.SEARCH_PROFILE,
    EDIT_CONTACT_TABS.FINANCE_PROFILE,
  ];

  const { data: contact, run: runContact } = useFetch<Contact>();
  /* istanbul ignore next */
  useEffect(() => {
    if (id) getData();
  }, []);

  const getData = (): void => {
    runContact(getContact(id!));
  };

  return (
    <React.Fragment>
      <Grid container spacing={0} columns={12}>
        <StyledGridTabs item xs={12} sm={12} mb={4}>
          <Tabs
            value={tab}
            onChange={(e: SyntheticEvent, value: EDIT_CONTACT_TABS) =>
              handleTabChange(value)
            }
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            {tabsLabels.map((tab, index) => (
              <Tab
                value={tab}
                label={t(`createContact.${camelCase(tab)}`)}
                id={`simple-tab-${index}`}
                key={`${tab}`}
              />
            ))}
          </Tabs>
        </StyledGridTabs>
        <StyledGridTabsContent item xs={12} sm={12}>
          <StyledTabContent>
            {contact && (
              <>
                <TabPanel
                  key={"contact-edit"}
                  value={tab!}
                  index={EDIT_CONTACT_TABS.CONTACT_DETAILS}
                >
                  <ContactDetails id={id!} contact={contact!} />
                </TabPanel>
                <TabPanel
                  key={"searchProfile"}
                  value={tab!}
                  index={EDIT_CONTACT_TABS.SEARCH_PROFILE}
                >
                  <SearchProfile id={id!} contact={contact!} />
                </TabPanel>
                <TabPanel
                  key={"financeProfile"}
                  value={tab!}
                  index={EDIT_CONTACT_TABS.FINANCE_PROFILE}
                >
                  <FinanceProfile id={id!} contact={contact!} />
                </TabPanel>
              </>
            )}
          </StyledTabContent>
        </StyledGridTabsContent>
      </Grid>
    </React.Fragment>
  );
};

export default EditContactTabs;
