import { FormikValues } from "formik";
import { createLocation, updateLocation } from "../../../../api/userSchedules";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { TableCell } from "../../../../types/table";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { LocationListData, SubmitLocationForm } from "./types";

export const getLocationInitialState = (
  locationItemId: number,
  locationItemName: string
): LocationListData => {
  return {
    id: locationItemId,
    name: locationItemName,
  };
};

export const itemsListHeadCells: TableCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "employeeOverview.locationName",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    sortable: true,
    label: "",
  },
];

export const handleSubmitLocation = ({
  isCreate,
  setIsCreate,
  setFormMessage,
  fetchLocation,
  setRow,
  handleSuccessfulNewLocation,
}: SubmitLocationForm) => {
  return async (
    values: LocationListData,
    { setStatus }: FormikValues
  ): Promise<void> => {
    const response = isCreate
      ? await createLocation({ name: values.name })
      : await updateLocation(String(values.id), { name: values.name });

    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      setFormMessage(FormMessageInItState);
      setStatus({
        success: true,
        errors: {},
      });
      fetchLocation();
      setIsCreate(true);
      setRow({ id: 0, name: "" });
      handleSuccessfulNewLocation();
    } else if (response.status === 422) {
      const res = await response.json();
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(res.errors),
      });
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
    }
  };
};
