export default {
  sizePerM2: "Size, m2",
  potentialRentalIncomings: "Potential Rental Incomings",
  netRentedLivingAreas: "Number of not rented living areas",
  averageVacancyPeriod: "Average vacancy period",
  notRentedCommercialAreas: "Number of not rented commercial areas",
  notRented: "Not rented",
  living: "Living",
  commercial: "Commercial",
  m2: "m2",
  areaByType: "Area by type",
  areaVacancies: "Area Vacancies",
  emptySpace: "Empty space",
  amount: "Amount",
  editVacancy: "Edit Vacancy",
  potentialRentalIncomePerMonth: "Potential rental income per month",
};
