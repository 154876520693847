import React, { MouseEvent, ReactElement } from "react";
import { useDropzone } from "react-dropzone";
import {
  DeleteIconContainer,
  DownloadFileText,
  DropZoneBox,
  SmallLightText,
} from "./styled";
import { Tooltip, Typography } from "@mui/material";
import { Upload, FileText, Download } from "react-feather";
import { MAX_FILES_UPLOAD } from "../../../../../../constants";
import { useTranslation } from "react-i18next";
import { UploadContractProps } from "./types";
import Loader from "../../../../../Loader";
import { FILE_SIZE_5_MB } from "../../../../../../utils/common";
import { DeleteIcon } from "../../../../../../ui/table/styled";

/* istanbul ignore next */
const UploadContract = ({
  file,
  onDrop,
  isLoading,
  handleDelete,
  isDeletingRentalContract,
  title,
  disabled = false,
}: UploadContractProps): ReactElement => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      maxFiles: MAX_FILES_UPLOAD,
      accept: { "application/pdf": [".pdf"] },
      onDrop,
      minSize: 0,
      maxSize: FILE_SIZE_5_MB,
      disabled: isLoading || disabled,
    });

  const additionalStyle = isDragAccept
    ? "accept"
    : isDragReject
    ? "reject"
    : "";

  return (
    <DropZoneBox
      additionalstyle={additionalStyle}
      data-testid="drop-box"
      className="drop-box"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isLoading ? (
        <>
          <Upload size={60} />
          <Typography variant="h3">{t("uploading")}</Typography>
          <Loader />
        </>
      ) : file ? (
        <>
          <FileText size={60} />
          <Tooltip title={file.name} placement="top">
            <Typography variant="h3" className="fileName">
              {file.name}
            </Typography>
          </Tooltip>
          {file?.url && (
            <DownloadFileText
              onClick={(e) => {
                e.stopPropagation();
                window.open(file.url);
              }}
            >
              <u>{t("tenant.download")}</u> <Download />
            </DownloadFileText>
          )}
          {file?.url && handleDelete && (
            <DeleteIconContainer>
              {isDeletingRentalContract ? (
                <Loader size={16} />
              ) : (
                <DeleteIcon
                  onClick={(e: MouseEvent<SVGElement>) => {
                    e.stopPropagation();
                    handleDelete(file);
                  }}
                />
              )}
            </DeleteIconContainer>
          )}
        </>
      ) : (
        <>
          <Upload size={60} />
          <Typography variant="h3">{title}</Typography>
          <div>
            <SmallLightText>
              {t("fileFormateMustBe")} <strong>.pdf</strong>
            </SmallLightText>
            <SmallLightText>
              {t("fileSizeMustBe")} <strong>{"<5mb"}</strong>
            </SmallLightText>
          </div>
        </>
      )}
    </DropZoneBox>
  );
};

export default UploadContract;
