import React, { useState } from "react";
import { DialogTitle, Grid, Typography } from "@mui/material";
import {
  ManuallyUploaDialogProps,
  UploadCSVFileType,
  handleCreditorPropertyUpload,
} from "./utils";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

import { DialogBox, DialogContent, DialogTitleContainer } from "./styled";
import CloseButton from "../../../../ui/dialog/CloseButton";
import useValidation from "../../../../hooks/useValidation";
import FileInputField from "../../../../ui/formsFields/fileInput";
import Button from "../../../../ui/button/Button";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import { FormMessageInItState } from "../../../../constants";
import useAppDispatch from "../../../../hooks/useAppDispatch";

const UploadManuallyDialog = ({
  toggleModel,
  visible,
  onSuccess,
  title,
  type: importType,
}: ManuallyUploaDialogProps): React.ReactElement => {
  const { t } = useTranslation();
  const { file, property_id, type } = useValidation();
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const dispatchStore = useAppDispatch();
  const { id } = useParams();

  const initialValues: UploadCSVFileType = {
    property_id: id!,
    type: importType,
    file: "",
  };

  return (
    <>
      <DialogBox open={visible} data-testid="import-modal">
        <DialogTitle>
          <DialogTitleContainer>
            <Typography
              variant="h6"
              component="div"
              justifyContent="space-around"
              mt={3}
              width="100%"
            >
              {title}
            </Typography>
            <CloseButton
              onClick={() => {
                toggleModel(false);
              }}
            />
          </DialogTitleContainer>
        </DialogTitle>
        <DialogContent>
          <div>
            {formMessage.text && (
              <FormAlert
                formMessage={formMessage}
                sx={{ marginBottom: "10px" }}
              />
            )}
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={Yup.object().shape({
                file,
                property_id,
                type,
              })}
              onSubmit={handleCreditorPropertyUpload({
                setFormMessage,
                onSuccess,
                dispatchStore,
              })}
            >
              {(props): React.ReactElement => {
                return (
                  <Form noValidate onSubmit={props.handleSubmit}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item xs={12}>
                          <FileInputField
                            required
                            name="file"
                            directUpload={false}
                            label={t("property.upload")}
                            error={Boolean(
                              props.touched.file && props.errors.file
                            )}
                            helperText={
                              props.touched?.file && props.errors.file
                            }
                            inputProps={{ "data-testid": "import-file" }}
                            status={props.status}
                            values={props.values}
                            accept=".csv, text/csv"
                            errorText={t("property.csvOnly")}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                      <Button
                        testId="import-data"
                        title={t("property.import")}
                        type="submit"
                        color="success"
                        size="small"
                        sx={{ marginTop: "10px" }}
                        isLoading={props.isSubmitting}
                        disabled={props.isSubmitting}
                      />
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </DialogContent>
      </DialogBox>
    </>
  );
};

export default UploadManuallyDialog;
