import { AssignPropertyToInboxData, FormSubmitHandlerProps } from "./types";
import { FormikValues } from "formik";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { mapBackendValidationErrors } from "../../../utils/form";
import { assignPropertyToInbox } from "../../../api/inbox";

export const assignPropertyInitialValues: AssignPropertyToInboxData = {
  property_id: null,
};

export const handleAssignProperty = (
  id: string,
  { setFormMessage, afterAssignProperty }: FormSubmitHandlerProps
) => {
  return async (
    values: AssignPropertyToInboxData,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const response = await assignPropertyToInbox(id, values);
    const json = await response.json();

    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      afterAssignProperty && afterAssignProperty();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
    }
    setSubmitting(false);
  };
};
