import React, { ReactElement, useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import { AssignedUsersDialogProps } from "./types";
import Form from "./Form";
import { getSearchQueryForUsersToBeAssigned, handleAssignUsers } from "./utils";
import { FormMessageInterface } from "../../../../types/form";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import { FormMessageInItState } from "../../../../constants";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
import { useFetch } from "../../../../hooks/useFetch";
import { User } from "../../../../types/models";
import { prepareQueryParams } from "../../../../utils/common";
import { getUsers } from "../../../../api/users";
/* istanbul ignore next */
const AssignedUsersDialog = ({
  open,
  onClose,
  afterAssignUsers,
  assignedUsers,
}: AssignedUsersDialogProps): ReactElement => {
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const { data, isLoading: isPreloadDataLoading, run } = useFetch<User[]>();

  useEffect(() => {
    const params = prepareQueryParams(
      "",
      getSearchQueryForUsersToBeAssigned(assignedUsers.propertyId)
    );

    run(getUsers(params));
  }, [assignedUsers]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="assign-property-dialog"
    >
      <DialogTitle title="Assign users" onClose={onClose} />
      <DialogContent>
        {formMessage.text ? (
          <FormAlert formMessage={formMessage} sx={{ marginBottom: "10px" }} />
        ) : null}
        <Formik
          initialValues={{ assigned_users: assignedUsers.data }}
          onSubmit={handleAssignUsers(assignedUsers.documentId!, {
            setFormMessage,
            afterAssignUsers,
          })}
        >
          {(props) => (
            <Form
              {...props}
              preloadData={data || []}
              propertyId={assignedUsers.propertyId}
              disabledItems={assignedUsers.assetManagers}
              isPreloadDataLoading={isPreloadDataLoading}
            />
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AssignedUsersDialog;
