import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../../../hooks/useFetch";
import { OfferTopic as BEOfferTopic } from "../../../../../types/be/offer";
import { Status as OfferStatus } from "../../../../../types/be/status";
import useTable from "../../../../../ui/table/useTable";
import { getOfferTopics } from "../../../../../api/offerTopic";
import { getOfferStatuses } from "../../../../../api/offer";
import snakeCase from "lodash/snakeCase";
import find from "lodash/find";
import useIsMounted from "../../../../../hooks/useIsMounted";
import { FILTER_TYPE, prepareQueryParams } from "../../../../../utils/common";
import { handleServerError } from "../../../../../utils/http";
import { Box, Paper, TablePagination } from "@mui/material";
import { OfferTopic as FEOfferTopic } from "../../../../../types/fe.interfaces";
import OfferList from "./offerList";
import Loader from "../../../../Loader";
import {
  FormMessageErrorState,
  FORWADED_STATUS,
  HISTORY_STATUS,
  REQUEST_STATUS_IDS,
  STARTING_PAGE,
  TOPICS_PER_PAGE,
  UserRoles,
  FORWARDED_ROUTE,
} from "../../../../../constants";
import { ROWS_PER_PAGE_OPTIONS } from "../../../../../ui/table/utils";
import useValidation from "../../../../../hooks/useValidation";
import Snackbar from "../../../../../ui/Snackbar";
import { ErrorBox } from "../styled";
import TopicListToolbar from "./topicListToolbar";
import { route } from "../../../../../utils/url";
import CreateTopicDialog from "./topicListToolbar/createTopicDialog";
import { CreateTopicForm } from "../types";
import { DOCUMENT_RELEASE_MODULE } from "../../../../../types/be/releaseFlow";
import useAccessControl from "../../../../../hooks/useAccessControl";
import { usePropertySelect } from "../../../../../hooks/usePropertySelect";
import TabPageWrapper from "../../../../../ui/tabPageWrapper";
import HistoryList from "../../../components/history/HistoryList";
import { HISTORY } from "../../../components/history/types";
import useDocumentRelease from "../../../components/documentRelease/useDocumentRelease";

const TopicList = (): ReactElement => {
  const { t } = useTranslation();
  const { id, status } = useParams();
  const { isRole } = useAccessControl();
  const [activeStatusId, setActiveStatusId] = useState<number | null>(null);
  const [createOfferTopicFormMode, setCreateOfferTopicFormMode] =
    useState<CreateTopicForm | null>(null);
  const isMounted = useIsMounted();
  const { name } = useValidation();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [offerStatusWithReleaser, setOfferStatusWithReleaser] = useState<
    OfferStatus[]
  >([]);
  const { propertyId, property, isGeneral, onPropertySelect } =
    usePropertySelect(id);

  const [createTopicInitialValues] = useState<FEOfferTopic>({
    name: "",
    property_id: propertyId,
  });
  const navigate = useNavigate();

  const {
    configuration,
    isConfigurationLoading,
    handleReleaseDocument,
    handleForwardDocument,
    releasePermission,
    snackbar,
    loading: isDocumentReleaseLoading,
  } = useDocumentRelease(
    DOCUMENT_RELEASE_MODULE.OFFER,
    DOCUMENT_RELEASE_MODULE.OFFER_TOPIC
  );

  const {
    data: statuses,
    run: runStatuses,
    isLoading: isStatusesLoading,
    isError: isStatusesError,
  } = useFetch<OfferStatus[]>();
  const {
    data: offerTopics,
    setData,
    total,
    setTotal,
    isLoading: isOfferTopicsLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    setRowsPerPage,
    currentPage,
    setCurrentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleTableSearch,
    queryParams,
  } = useTable<BEOfferTopic>();

  useEffect(() => setIsSnackbarVisible(isSuccess), [isSuccess]);

  useEffect(() => {
    runStatuses(getOfferStatuses());
    setRowsPerPage(TOPICS_PER_PAGE);
  }, []);

  useEffect(() => {
    if (!configuration) return;

    /* istanbul ignore next */
    const historyStatus = isRole(UserRoles.ADMIN) ? [HISTORY_STATUS] : [];

    statuses &&
      setOfferStatusWithReleaser([
        FORWADED_STATUS,
        ...statuses,
        ...historyStatus,
      ]);

    /* istanbul ignore next */
    status === undefined && setActiveStatusId(FORWADED_STATUS.id);
  }, [statuses, configuration, status]);

  useEffect(() => {
    if (!configuration) return;

    const activeStatus = find(
      offerStatusWithReleaser,
      (o) => o.code === snakeCase(status)
    );
    if (activeStatus) setActiveStatusId(activeStatus.id);
  }, [offerStatusWithReleaser, configuration]);

  useEffect(() => setCurrentPage(STARTING_PAGE), [status]);

  useEffect(() => {
    isMounted() && fetchOfferTopics();
  }, [
    queryParams,
    activeStatusId,
    propertyId,
    isGeneral,
    offerStatusWithReleaser.length,
  ]);

  const fetchOfferTopics = useCallback(
    async (loading = true) => {
      if (
        !activeStatusId ||
        !configuration ||
        !offerStatusWithReleaser.length ||
        !queryParams ||
        activeStatusId === HISTORY_STATUS.id
      )
        return;
      setIsLoading(loading);

      const params: Record<string, string | number | undefined> = {};

      /* istanbul ignore next */
      if (isGeneral === FILTER_TYPE.GENERAL || propertyId || id) {
        params.property_id = propertyId || id || "";
      }

      /* istanbul ignore next */
      if (activeStatusId === -1) {
        params.is_was_forwarded = 1;
        params.fr_status_id = REQUEST_STATUS_IDS.NEW;
      } else {
        params.fr_status_id = activeStatusId;
      }

      const response = await getOfferTopics(
        prepareQueryParams(queryParams, params, true)
      );

      /* istanbul ignore next */
      if (response.status !== 200) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        const resJson = await response.json();
        setTotal(resJson.meta.total);
        setData(resJson.data);
      }
      setIsLoading(false);
    },
    [
      activeStatusId,
      queryParams,
      setIsLoading,
      setTotal,
      setData,
      setIsLoading,
      status,
      propertyId,
      isGeneral,
      configuration,
      offerStatusWithReleaser,
    ]
  );

  const handleCreateTopic = (): void => {
    if (id) {
      setCreateOfferTopicFormMode(CreateTopicForm.LIST);
    } else {
      setCreateOfferTopicFormMode(CreateTopicForm.MODAL);
    }
  };

  const afterCreateTopic = (): void => {
    fetchOfferTopics();
    setCreateOfferTopicFormMode(null);
    setIsSuccess(true);
  };

  const handleCloseSnackbar = (): void => setIsSuccess(false);
  /* istanbul ignore next */
  const redirectOfferHistory = (): string => {
    if (id) {
      return route("properties.offers.history", id);
    } else {
      return route("offers.history");
    }
  };

  /* istanbul ignore next */
  const handleStatusChange = (status: number): void => {
    if (status === HISTORY_STATUS.id) {
      navigate(redirectOfferHistory());
    } else {
      setActiveStatusId(status);
    }
  };

  if (isStatusesError || error)
    return <ErrorBox formMessage={FormMessageErrorState} />;

  if (isStatusesLoading || isConfigurationLoading) {
    return <Loader />;
  }

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
  ];

  return (
    <TabPageWrapper
      breadcrumbs={breadcrumbs}
      title={t("offers.offer")}
      wrap={!id}
    >
      <>
        <Box>
          {
            /* istanbul ignore next */ status === "history" ? (
              <HistoryList
                documentRelease={HISTORY.OFFER}
                activeStatusId={activeStatusId}
                handleStatusChange={handleStatusChange}
                statuses={offerStatusWithReleaser || []}
                useOnWidget={false}
                noDataMessage="table.noOfferHistoryIsAvailable"
              />
            ) : (
              <>
                {statuses && (
                  <TopicListToolbar
                    createOfferTopicFormMode={createOfferTopicFormMode}
                    createTopicInitialValues={createTopicInitialValues}
                    fetchOfferTopics={fetchOfferTopics}
                    setCreateOfferTopicFormMode={setCreateOfferTopicFormMode}
                    setIsSuccess={setIsSuccess}
                    name={name}
                    handleCreateTopic={handleCreateTopic}
                    statuses={offerStatusWithReleaser}
                    handleTableSearch={handleTableSearch}
                    propertyId={id}
                    activeStatusId={activeStatusId}
                    property={property}
                    isGeneral={isGeneral}
                    onPropertySelect={onPropertySelect}
                    defaultStatus={FORWARDED_ROUTE}
                    handleStatusChange={handleStatusChange}
                  />
                )}
                {isOfferTopicsLoading ? (
                  <Loader />
                ) : (
                  <>
                    {offerTopics.length ? (
                      offerTopics.map((offerTopic: BEOfferTopic) => (
                        <OfferList
                          key={offerTopic.id}
                          offers={offerTopic.offers}
                          statuses={statuses!}
                          fetchOfferTopics={fetchOfferTopics}
                          offerTopic={offerTopic}
                          propertyId={propertyId}
                          setError={setError}
                          configuration={configuration}
                          handleForwardDocument={handleForwardDocument}
                          handleReleaseDocument={handleReleaseDocument}
                          isDocumentReleaseLoading={isDocumentReleaseLoading}
                          isConfigurationLoading={isConfigurationLoading}
                          releasePermission={releasePermission}
                        />
                      ))
                    ) : (
                      <Box>{t("table.noOffersAreAvailable")}</Box>
                    )}
                    {offerTopics.length ? (
                      <Paper sx={{ width: "100%", mb: 10, px: 10, py: 1 }}>
                        <TablePagination
                          data-testid={"table-pagination"}
                          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                          component="div"
                          count={total}
                          rowsPerPage={rowsPerPage}
                          labelRowsPerPage={t("offers.topicsPerPage")}
                          page={currentPage}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : null}
                  </>
                )}
                <Snackbar
                  message={t("offers.topicWasSuccessfullyCreated")}
                  color="success"
                  open={isSnackbarVisible}
                  handleClose={handleCloseSnackbar}
                  data-testid="snackbar-topic-success"
                />
                {createOfferTopicFormMode === CreateTopicForm.MODAL ? (
                  <CreateTopicDialog
                    onClose={() => setCreateOfferTopicFormMode(null)}
                    open={createOfferTopicFormMode === CreateTopicForm.MODAL}
                    afterCreateTopic={afterCreateTopic}
                  />
                ) : null}
              </>
            )
          }
        </Box>
        {snackbar.Snackbar}
      </>
    </TabPageWrapper>
  );
};

export default TopicList;
