import { Grid } from "@mui/material";
import React, { ReactElement } from "react";
import Text from "../../../../../ui/formsFields/text";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import Button from "../../../../../ui/button/Button";
import { Config } from "./types";

const Form = ({
  handleSubmit,
  status,
  isSubmitting,
  dirty,
}: FormikProps<Config>): ReactElement => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Grid container gap={4} p={4}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Text
              label={t("documentRelease.invoice.apidatev.companyNumber")}
              placeholder={t("documentRelease.invoice.apidatev.companyNumber")}
              name="konto"
              required
              size="medium"
              status={status}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Button
            disabled={isSubmitting || !dirty}
            type="submit"
            variant="contained"
            color="success"
            size="large"
            isLoading={isSubmitting}
            title={t("save")}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
