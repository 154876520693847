import styled from "styled-components/macro";
import { Box, Paper } from "@mui/material";

export const FormSectionTitle = styled(Box)`
  font-size: 18px;
  padding-bottom: 10px;
`;

export const LoadingBox = styled.div`
  width: 100%;
  height: 50vh;
`;

export const FormWrapper = styled(Paper)`
  padding: ${(props): string => props.theme.spacing(6)};
  ${(props): string => props.theme.breakpoints.up("lg")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 80%;
  }
  ${(props): string => props.theme.breakpoints.up("xl")} {
    &.popup-wrapper {
      max-width: 90%;
    }
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 60%;
  }
  ${(props): string => props.theme.breakpoints.down("xs")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 100%;
  }
  &.fullWidth {
    max-width: 100% !important;
    padding: 0 !important;
    margin-top: ${(props): string => props.theme.spacing(1.5)};
  }
`;
