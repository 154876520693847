import React, { ReactElement } from "react";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
import { Dialog, DialogContent } from "@mui/material";
import { CreateContractModalProps } from "./types";
import { useTranslation } from "react-i18next";
import ContractCreate from "../../contracts/create/ContractCreate";

const CreateContractModal = ({
  open,
  onClose,
  setContractModalValue,
}: CreateContractModalProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <>
        <DialogTitle title={t("contracts.createContract")} onClose={onClose} />
        <DialogContent>
          <ContractCreate
            onClose={onClose}
            isModal={true}
            setContractModalValue={setContractModalValue}
          />
        </DialogContent>
      </>
    </Dialog>
  );
};

export default CreateContractModal;
