import styled from "styled-components/macro";
import { Box as MUIBox } from "@mui/material";

export const Wrapper = styled.div`
  padding: ${(props): string => props.theme.spacing(3)};
  text-align: center;
  background: transparent;

  ${(props): string => props.theme.breakpoints.up("md")} {
    padding: ${(props): string => props.theme.spacing(10)};
  }
`;

export const Box = styled(MUIBox)`
  padding: ${(props): string => props.theme.spacing(3)} 0;
  text-align: left;
`;

