import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { usePropertySelect } from "../../../../hooks/usePropertySelect";
import useTable from "../../../../ui/table/useTable";
import { DocumentRelaseHistory } from "../../../../types/be/history";
import { getDocumentReleaseHistory } from "../../../../api/history";
import { Search as SearchIcon } from "react-feather";
import Table from "../../../../ui/table/Table";
import { Search, SearchIconWrapper, Input } from "../../../../ui/table/styled";
import {
  FILTER_TYPE,
  generalPropertyOption,
  getFilteredHeadCellsByProperty,
  objectGetParamsToString,
} from "../../../../utils/common";
import { FORWARDED_ROUTE } from "../../../../constants";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { handleServerError } from "../../../../utils/http";
import useIsMounted from "../../../../hooks/useIsMounted";
import { Grid } from "@mui/material";
import StatusButtons from "../../components/statusButtons";
import Dropdown from "../../../../ui/dropdown";
import { getProperties } from "../../../../api/property";
import DocumentsAutocomplete from "../../components/documentsAutocomplete";
import HZScrollController from "../../../../ui/HZScrollController";
import {
  itemsListHeadCells,
  documentReleaseHistoryScrollId,
  StatusButtonsDict,
} from "./utils";
import {
  DocumentReleaseHistoryFilterProps,
  HistoryListProps,
  RelationTypes,
} from "./types";
import HistoryRow from "./HistoryRow";

/* istanbul ignore next */
const HistoryList = ({
  documentRelease,
  statuses,
  handleStatusChange,
  activeStatusId,
  useOnWidget,
  noDataMessage,
}: HistoryListProps): ReactElement => {
  const { t } = useTranslation();
  const { id, status } = useParams();
  const [relation, setRelation] =
    useState<RelationTypes[typeof documentRelease]>(null);
  const { propertyId, property, isGeneral, onPropertySelect } =
    usePropertySelect(id, () => {
      setRelation(null);
    });

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
  } = useTable<DocumentRelaseHistory>();

  const fetchDocumentReleaseHistory = useCallback(async () => {
    setIsLoading(true);
    const params: DocumentReleaseHistoryFilterProps = {
      relation: documentRelease,
      relation_id: relation?.id,
    };

    if (isGeneral === FILTER_TYPE.GENERAL || propertyId || id) {
      params.property_id = propertyId || id || "";
    }

    const response = await getDocumentReleaseHistory(
      `${queryParams}&${objectGetParamsToString(params)}`
    );

    if (response.status !== HTTP_STATUS_CODES.OK) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const resJson = await response.json();
      setTotal(resJson.meta.total);
      setData(resJson.data);
      setIsLoading(false);
    }
  }, [
    propertyId,
    queryParams,
    setIsLoading,
    setTotal,
    setData,
    setIsLoading,
    status,
    relation,
    isGeneral,
    documentRelease,
  ]);

  useEffect(() => {
    if (isMounted()) {
      fetchDocumentReleaseHistory();
    }
  }, [queryParams, propertyId, relation, isGeneral, documentRelease]);

  const onDocumentSelect = (
    document: RelationTypes[typeof documentRelease]
  ): void => {
    setRelation(document);
  };

  const isMounted = useIsMounted();

  const tableHeaderTitles = useMemo(() => {
    return getFilteredHeadCellsByProperty(
      itemsListHeadCells,
      "property_name",
      id
    );
  }, []);

  const TableToolbar = (
    <Grid
      container
      mt={1}
      justifyContent={id ? "space-between" : "flex-end"}
      alignItems={"center"}
      className="table-toolbar"
    >
      <Grid
        item
        xs={12}
        alignItems="flex-start"
        pt={4}
        pb={3}
        display="flex"
        justifyContent="flex-start"
        className="toolbar-container"
      >
        <StatusButtons
          subPath={StatusButtonsDict[documentRelease]}
          tabPosition="left"
          statuses={statuses}
          handleStatusChange={handleStatusChange}
          navigateOnChange={!useOnWidget}
          activeStatusId={activeStatusId}
          useSpecialLabels={true}
          defaultStatus={FORWARDED_ROUTE}
        />
      </Grid>
      <Grid item xs={12} mt={5} className="filter-container">
        <Grid container display="flex" alignItems="center" rowSpacing={2}>
          {!id && (
            <Grid item xs={12} sm={3} md={4} lg={2.5} pr={2}>
              <Dropdown
                id="property-autocomplete"
                name="property_id"
                placeholder={t("documentRelease.invoice.searchProperty")}
                getOptions={getProperties}
                onSelectOption={onPropertySelect}
                optionLabel="object_name"
                size="small"
                defaultValue={property}
                defaultOption={[generalPropertyOption]}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={3} md={4} lg={2.5}>
            <DocumentsAutocomplete
              document={relation}
              onDocumentSelect={onDocumentSelect}
              documentType={documentRelease}
              propertyId={propertyId}
              isGeneral={isGeneral}
            />
          </Grid>
          <Grid item xs />
          <Grid item mr={2}>
            <HZScrollController scrollerId={documentReleaseHistoryScrollId} />
          </Grid>
          <Grid item>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t("search")} onChange={handleTableSearch} />
            </Search>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderRow = (row: DocumentRelaseHistory): ReactElement => (
    <HistoryRow row={row} documentRelease={documentRelease} />
  );

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={tableHeaderTitles}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        noDataIsAvailablePlaceholder={noDataMessage}
        refId={`hzScroll${documentReleaseHistoryScrollId}`}
      />
    </>
  );
};

export default HistoryList;
