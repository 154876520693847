import { LocationOn } from "@mui/icons-material";
import styled from "styled-components/macro";
import { Paper, Typography } from "@mui/material";
import shadows from "../../../../theme/shadows";
/* istanbul ignore next */
export const LocationOnIcon = styled(LocationOn).attrs(
  (props: { $hovered?: boolean }) => ({
    $hovered: props.$hovered || false,
  })
)`
  transition: transform 0.05s ease-in;
  //transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
  will-change: transform;
  transform: ${(props): string =>
    props.$hovered ? "translate(-1rem, -1.2ex) scale(1.4, 1.4)" : ""};
  &:hover {
    cursor: pointer;
    transform: translate(-1rem, -1.2ex) scale(1.4, 1.4);
  }
`;
/* istanbul ignore next */
export const Popover = styled(Paper)`
  height: 70px;
  width: 150px;
  cursor: pointer;
  padding: 5px;
  background: #6573c3;
  box-shadow: ${(): string => shadows[3]};
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 100%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: ${(): string => "#6573c3"};
    border-bottom: 0;
    margin-left: 65px;
    margin-bottom: -75px;
  }
`;

export const OpenInNewTabLink = styled(Typography)`
  font-size: 10px;
  color: #33c9dc;
  &:hover {
    text-decoration: underline #33c9dc;
  }
`;

export const PopoverWrapper = styled.div`
  height: 0;
  width: 0;
  z-index: 999999;
  position: relative;
  top: -100px;
  right: 73px;
`;
