import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import client from "./client/client";
import user from "./user/user";
import news from "./news";
import tenant from "./tenant/tenant";
import contract from "./contract";
import invoice from "./invoice";
import socket from "./socket";
import notification from "./notification";
import globalSearch from "./globalSearch";
import archive from "./archive/archive";
import area from "./area/area";
import importValidation from "./importValidation/importValidation";
import offers from "./offers";
import insurances from "./insurances";
import property from "./property";
import inbox from "./inbox";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["socket", "news", "client.status"],
};

const rootReducer = combineReducers({
  client,
  user,
  tenant,
  contract,
  invoice,
  socket,
  notification,
  globalSearch,
  news,
  archive,
  area,
  importValidation,
  offers,
  insurances,
  property,
  inbox,
});

export default persistReducer(persistConfig, rootReducer);
