/* istanbul ignore file */

import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Tooltip,
  Paper,
  Link,
  FormControlLabel,
  Box,
} from "@mui/material";
import { useFetch } from "../../../../hooks/useFetch";
import { ErrorBox } from "../../objectDataSheet/tabs/generalInformation/styled";
import {
  COMMENTABLE,
  CURRENCIES,
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  FormMessageErrorState,
  Measurement_Units,
  THOUSAND_SEPARATOR,
} from "../../../../constants";
import { LoadingBox } from "../../../contacts/styled";
import Loader from "../../../Loader";
import { route } from "../../../../utils/url";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "../../../../utils/date";
import { IconWrapper, LabelText } from "../../invoices/show/styled";
import { InlineBlock, IsActiveIcon, TextEllipsis } from "../../../../styled";
import { CheckCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import Comments from "../../../../ui/сomments";
import useComments from "../../../../ui/сomments/useComments";
import { getReleaseRoute } from "../../utils";
import { NumericFormat } from "react-number-format";
import useAccessControl from "../../../../hooks/useAccessControl";
import { Android12Switch } from "../../../../ui/formsFields/switch/styled";
import PdfViewer from "../../../../ui/pdfViewer";
import { HeaderText } from "../../edit/general/styled";
import { OutgoingInvoice } from "../../../../types/be/outgoingInvoices";
import {
  getOutgoingInvoice,
  updateOutgoingInvoice,
} from "../../../../api/outgoingInvoice";
import { Services } from "../../../../types/fe/outgoingInvoice";
import { parseNumber } from "../../../../utils/common";
import TabPageWrapper from "../../../../ui/tabPageWrapper";

function OutgoingInvoiceShow(): ReactElement {
  const { t } = useTranslation();
  const { id, invoiceId } = useParams();
  const { can } = useAccessControl();
  const [isBookedError, setIsBookedError] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const pdfContainerRef = useRef<HTMLObjectElement | null>(null);
  const [invoiceFileId, setInvoiceFileId] = useState<number | undefined>();
  const {
    data: outgoingInvoice,
    isLoading,
    isError,
    run,
  } = useFetch<OutgoingInvoice>();
  const {
    comments,
    content,
    setContent,
    totalPages,
    handleCommentSave,
    handleCommentDelete,
    handlePaginationChange,
    currentPage,
    deleting,
    loading,
  } = useComments({
    relation: COMMENTABLE.OUTGOING_INVOICES,
    relation_id: invoiceId!,
  });

  const getData = async (): Promise<void> => {
    await run(getOutgoingInvoice(invoiceId!));
    setIsDataLoaded(true);
  };

  useEffect(() => {
    outgoingInvoice &&
      outgoingInvoice?.file?.id &&
      setInvoiceFileId(outgoingInvoice?.file?.id);
    outgoingInvoice && setIsBooked(outgoingInvoice.is_booked);
  }, [outgoingInvoice]);

  useEffect(() => {
    getData();
  }, [id, invoiceId]);

  const grossInvoiceAmount =
    outgoingInvoice?.services?.reduce(
      (old: number, item: Services): number => old + parseNumber(item?.total),
      0
    ) ?? "";

  const nettoInvoiceAmount =
    outgoingInvoice?.services?.reduce(
      (old: number, item: Services): number => old + parseNumber(item?.price),
      0
    ) ?? "";

  const markAsBooked = async (invoice: OutgoingInvoice): Promise<void> => {
    setIsBooked(!isBooked);
    const response = await updateOutgoingInvoice(String(invoice.id), {
      is_booked: !invoice.is_booked,
      services: { ...invoice.services },
    });
    if (response.ok) {
      setIsBookedError(false);
      await getData();
    } else {
      setIsBookedError(true);
    }
  };

  const invoiceFile = useMemo(() => {
    return { fileId: invoiceFileId };
  }, [invoiceFileId]);

  if (isError || isBookedError) {
    return <ErrorBox formMessage={FormMessageErrorState} />;
  }

  if (!isDataLoaded && (isLoading || !outgoingInvoice)) {
    return (
      <div data-testid="loader">
        <LoadingBox>
          <Loader />
        </LoadingBox>
      </div>
    );
  }
  /* istanbul ignore next */
  if (!outgoingInvoice) {
    return <></>;
  }

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("invoices", Number(id)),
      name: t("property.navLinks.invoices"),
    },
  ];

  return (
    <TabPageWrapper
      title={
        outgoingInvoice.file
          ? outgoingInvoice.file?.name
          : String(outgoingInvoice.number)
      }
      breadcrumbs={breadcrumbs}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} xl={12}>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12} p={4}>
              <Grid container spacing={4}>
                <Grid item xs={12} ref={pdfContainerRef}>
                  <PdfViewer
                    file={invoiceFile}
                    containerRef={pdfContainerRef}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Paper variant="outlined" sx={{ p: 4, textAlign: "center" }}>
                    <Grid
                      container
                      justifyContent={"center"}
                      spacing={4}
                      sx={{ textAlign: "left" }}
                    >
                      <Grid item xs={12}>
                        <HeaderText> {outgoingInvoice.number} </HeaderText>
                        {outgoingInvoice.file ? (
                          <LabelText>
                            <Link
                              href={outgoingInvoice.file?.url}
                              target="_blank"
                              sx={{
                                wordBreak: "break-all",
                                fontSize: "11px",
                              }}
                            >
                              {outgoingInvoice.file?.name}
                            </Link>{" "}
                            | {formatTimestamp(outgoingInvoice.date)}
                          </LabelText>
                        ) : (
                          <LabelText>
                            {formatTimestamp(outgoingInvoice.date)}
                          </LabelText>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container justifyContent={"center"} mt={6}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          <NumericFormat
                            thousandSeparator={THOUSAND_SEPARATOR}
                            decimalSeparator={DECIMAL_SEPARATOR}
                            decimalScale={DECIMAL_SCALE}
                            fixedDecimalScale
                            displayType={"text"}
                            value={outgoingInvoice.amount}
                          />{" "}
                          {outgoingInvoice.amount !== null &&
                            Measurement_Units.EURO}
                        </HeaderText>
                        <LabelText>{t("amount")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {outgoingInvoice.creator
                            ? `${outgoingInvoice.creator?.first_name} ${outgoingInvoice.creator?.last_name}`
                            : /* istanbul ignore next */ EMPTY_DATA}
                        </HeaderText>
                        <LabelText>{t("createdBy")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {" "}
                          {formatTimestamp(outgoingInvoice.created_at)}
                        </HeaderText>
                        <LabelText>
                          {t("documentRelease.invoice.show.createdAt")}
                        </LabelText>
                      </Grid>
                    </Grid>

                    <Grid container justifyContent={"center"} mt={6}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          <Link
                            href={route(
                              "properties.edit.main",
                              outgoingInvoice.property?.id
                            )}
                            target="_blank"
                          >
                            {outgoingInvoice.property?.object_name}
                          </Link>
                        </HeaderText>
                        <LabelText>
                          {t("documentRelease.invoice.show.propertyName")}
                        </LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        {outgoingInvoice.contract &&
                        outgoingInvoice.contract.file ? (
                          <Tooltip title={outgoingInvoice.contract.file?.name}>
                            <TextEllipsis>
                              <Link
                                href={outgoingInvoice?.contract.file?.url}
                                target="_blank"
                              >
                                <HeaderText>
                                  {outgoingInvoice.contract?.file?.name}
                                </HeaderText>
                              </Link>
                            </TextEllipsis>
                          </Tooltip>
                        ) : (
                          <HeaderText>{EMPTY_DATA}</HeaderText>
                        )}
                        <LabelText>{t("contracts.contract")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        {outgoingInvoice.offer && outgoingInvoice.offer.file ? (
                          <Tooltip title={outgoingInvoice.offer.file?.name}>
                            <TextEllipsis w="100%">
                              <Link
                                href={outgoingInvoice?.offer.file?.url}
                                target="_blank"
                              >
                                <HeaderText>
                                  {outgoingInvoice.offer?.file?.name}
                                </HeaderText>
                              </Link>
                            </TextEllipsis>
                          </Tooltip>
                        ) : (
                          <HeaderText>{EMPTY_DATA}</HeaderText>
                        )}
                        <LabelText>{t("offerFileName")}</LabelText>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper
                    variant="outlined"
                    sx={{ p: 4, textAlign: "center", mt: 4 }}
                  >
                    <Grid
                      container
                      justifyContent={"flex-start"}
                      spacing={4}
                      sx={{ textAlign: "left", pb: 2 }}
                    >
                      <Grid item xs={12} sm={6}>
                        <HeaderText>
                          {t("documentRelease.outgoingInvoice.services")}
                        </HeaderText>
                      </Grid>
                    </Grid>
                    {outgoingInvoice?.services?.map((service) => (
                      <Grid
                        container
                        key={service?.id}
                        borderBottom={"1px solid gray"}
                        py={2}
                      >
                        <Grid container justifyContent={"start"} mt={4} mb={4}>
                          <Grid item xs={4} sm={4}>
                            <HeaderText>
                              {service?.description || EMPTY_DATA}
                            </HeaderText>
                            <LabelText>
                              {t("documentRelease.outgoingInvoice.description")}
                            </LabelText>
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <HeaderText>
                              {service?.quantity || EMPTY_DATA}
                            </HeaderText>
                            <LabelText>
                              {t("documentRelease.outgoingInvoice.quantity")}
                            </LabelText>
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <HeaderText>
                              <NumericFormat
                                thousandSeparator={THOUSAND_SEPARATOR}
                                decimalSeparator={DECIMAL_SEPARATOR}
                                decimalScale={DECIMAL_SCALE}
                                fixedDecimalScale
                                displayType={"text"}
                                value={service?.price}
                              />{" "}
                              {service?.price !== null &&
                              outgoingInvoice?.currency_code === CURRENCIES[0]
                                ? Measurement_Units.EURO
                                : Measurement_Units.DOLLAR}
                            </HeaderText>
                            <LabelText>
                              {t("documentRelease.outgoingInvoice.price")}
                            </LabelText>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent={"center"}>
                          <Grid item xs={4} sm={4}>
                            <HeaderText>
                              <NumericFormat
                                thousandSeparator={THOUSAND_SEPARATOR}
                                decimalSeparator={DECIMAL_SEPARATOR}
                                decimalScale={DECIMAL_SCALE}
                                fixedDecimalScale
                                displayType={"text"}
                                value={service?.tax}
                              />{" "}
                              {service?.tax !== null &&
                                Measurement_Units.PERCENTAGE}
                            </HeaderText>
                            <LabelText>
                              {t("documentRelease.outgoingInvoice.tax")}
                            </LabelText>
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <HeaderText>
                              <NumericFormat
                                thousandSeparator={THOUSAND_SEPARATOR}
                                decimalSeparator={DECIMAL_SEPARATOR}
                                decimalScale={DECIMAL_SCALE}
                                fixedDecimalScale
                                displayType={"text"}
                                value={service?.total - service?.price}
                              />{" "}
                              {service?.price !== null &&
                              outgoingInvoice?.currency_code === CURRENCIES[0]
                                ? Measurement_Units.EURO
                                : Measurement_Units.DOLLAR}
                            </HeaderText>
                            <LabelText>
                              {t("documentRelease.outgoingInvoice.taxPrice")}
                            </LabelText>
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <HeaderText>
                              <NumericFormat
                                thousandSeparator={THOUSAND_SEPARATOR}
                                decimalSeparator={DECIMAL_SEPARATOR}
                                decimalScale={DECIMAL_SCALE}
                                fixedDecimalScale
                                displayType={"text"}
                                value={service?.total}
                              />{" "}
                              {service?.total !== null &&
                              outgoingInvoice?.currency_code === CURRENCIES[0]
                                ? Measurement_Units.EURO
                                : Measurement_Units.DOLLAR}
                            </HeaderText>
                            <LabelText>
                              {t("documentRelease.outgoingInvoice.totalPrice")}
                            </LabelText>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid container justifyContent={"flex-start"} mt={6}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          <NumericFormat
                            thousandSeparator={THOUSAND_SEPARATOR}
                            decimalSeparator={DECIMAL_SEPARATOR}
                            decimalScale={DECIMAL_SCALE}
                            fixedDecimalScale
                            displayType={"text"}
                            value={nettoInvoiceAmount}
                          />{" "}
                          {nettoInvoiceAmount !== null &&
                          outgoingInvoice?.currency_code === CURRENCIES[0]
                            ? Measurement_Units.EURO
                            : Measurement_Units.DOLLAR}
                        </HeaderText>
                        <LabelText>
                          {t(
                            "documentRelease.outgoingInvoice.fullInvoicePrice"
                          )}
                        </LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          <NumericFormat
                            thousandSeparator={THOUSAND_SEPARATOR}
                            decimalSeparator={DECIMAL_SEPARATOR}
                            decimalScale={DECIMAL_SCALE}
                            fixedDecimalScale
                            displayType={"text"}
                            value={
                              Number(grossInvoiceAmount) -
                              Number(nettoInvoiceAmount)
                            }
                          />{" "}
                          {grossInvoiceAmount !== null &&
                          outgoingInvoice?.currency_code === CURRENCIES[0]
                            ? Measurement_Units.EURO
                            : Measurement_Units.DOLLAR}
                        </HeaderText>
                        <LabelText>
                          {t("documentRelease.outgoingInvoice.fullInvoiceTax")}
                        </LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          <NumericFormat
                            thousandSeparator={THOUSAND_SEPARATOR}
                            decimalSeparator={DECIMAL_SEPARATOR}
                            decimalScale={DECIMAL_SCALE}
                            fixedDecimalScale
                            displayType={"text"}
                            value={grossInvoiceAmount}
                          />{" "}
                          {grossInvoiceAmount !== null &&
                          outgoingInvoice?.currency_code === CURRENCIES[0]
                            ? Measurement_Units.EURO
                            : Measurement_Units.DOLLAR}
                        </HeaderText>
                        <LabelText>
                          {t(
                            "documentRelease.outgoingInvoice.fullInvoicePriceWithTax"
                          )}
                        </LabelText>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper variant="outlined" sx={{ p: 4, mt: 4 }}>
                    <Grid container spacing={4}>
                      <Grid item sm={4} xs={12}>
                        <Grid container spacing={2} mt={3}>
                          <Grid item xs={5} md={6}>
                            <LabelText>
                              {t("documentRelease.invoice.permanent")}
                            </LabelText>
                          </Grid>
                          <Grid item xs={6}>
                            <IconWrapper>
                              {outgoingInvoice.is_permanent ? (
                                <CheckCircleOutline
                                  color="success"
                                  fontSize="medium"
                                />
                              ) : (
                                <RemoveCircleOutline
                                  color="error"
                                  fontSize="medium"
                                />
                              )}
                            </IconWrapper>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Grid container spacing={2} mt={3}>
                          <Grid item xs={5}>
                            <LabelText>{t("activeInvoice")}</LabelText>
                          </Grid>
                          <Grid item xs={6}>
                            <InlineBlock>
                              <IsActiveIcon
                                type={
                                  outgoingInvoice.is_active
                                    ? "success"
                                    : "error"
                                }
                              />
                            </InlineBlock>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Grid container spacing={2} mt={3}>
                          <Grid item xs={5}>
                            <LabelText>
                              {t("documentRelease.invoice.markAsBooked")}
                            </LabelText>
                          </Grid>
                          <Grid item xs={7}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Android12Switch
                                    checked={isBooked}
                                    onChange={() =>
                                      markAsBooked(outgoingInvoice)
                                    }
                                    disabled={!can("invoice.accountant")}
                                    data-testid="toggle-invoice-booked"
                                  />
                                }
                                label={
                                  isBooked
                                    ? t("documentRelease.invoice.booked")
                                    : t("documentRelease.invoice.notBooked")
                                }
                                sx={{ margin: 0 }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={12} pt={4}>
                <Comments
                  editorContent={content}
                  setEditorContent={setContent}
                  handleSaveComment={handleCommentSave}
                  comments={comments}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handleDeleteComment={handleCommentDelete}
                  handlePaginationChange={handlePaginationChange}
                  deleting={deleting}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TabPageWrapper>
  );
}

export default OutgoingInvoiceShow;
