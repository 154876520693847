import styled from "styled-components/macro";
import { Paper } from "@mui/material";
/* istanbul ignore next */
export const FormWrapper = styled(Paper)`
  margin-top: ${(props): string => props.theme.spacing(10)};
  ${(props): string => props.theme.breakpoints.up("lg")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 90%;
    padding-left: ${(props): string => props.theme.spacing(0)};
  }
`;
