import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, Button, Link, Popover, Typography, Grid } from "@mui/material";
import React, { useState } from "react";
import { ReactElement } from "react";
import { MAX_NOTIFICATIONS_LIMIT } from "../../../constants";
import useAppSelector from "../../../hooks/useAppSelector";
import NotificationBox from "../notificationBox";
import { Notification as BENotification } from "../../../types/be/notification";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { notificationStatusToggle } from "../utils";
import { Error } from "../../../components/profile/tabs/generalInformation/styled";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

export const NotificationBell = (): ReactElement => {
  const { notifications } = useAppSelector((state) => state.notification);
  const { t } = useTranslation();
  const dispatchStore = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [error, serError] = useState<string>("");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  /* istanbul ignore next */
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  /* istanbul ignore next */
  const unreadNotificationsCount = (): number => {
    if (notifications)
      return notifications.filter(
        (notification: BENotification) => !notification.read_at
      ).length;
    else return 0;
  };

  const badgeProps = (): any => {
    return {
      badgeContent: unreadNotificationsCount(),
      color: "error",
    };
  };

  return (
    <>
      <Badge {...badgeProps()}>
        <Button
          aria-describedby={id}
          data-testid="notification-bell-icon"
          size={"small"}
          sx={{
            px: 0,
            width: "30px",
            minWidth: "0",
          }}
          onClick={handleClick}
        >
          <NotificationsIcon sx={{ m: 0 }} />
        </Button>
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ maxHeight: "80vh" }}
        PaperProps={{
          sx: {
            width: "400px",
            maxHeight: "90vh",
            overflowY: "scroll",
            px: 2,
            py: 2,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {notifications?.length === 0 && (
          <>
            <Grid container spacing={1}>
              <Grid item xs={11}>
                <Typography sx={{ px: 2, fontSize: "12px" }}>
                  {t("notifications.youHaveReadAllYourNotifications")}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <CloseIcon
                  sx={{ fontSize: "12px", cursor: "pointer" }}
                  onClick={handleClose}
                />
              </Grid>
            </Grid>
          </>
        )}
        {notifications &&
          notifications.filter((notification) => !notification.read_at).length >
            0 && (
            <>
              <Link
                onClick={() =>
                  notificationStatusToggle({ dispatchStore, serError })
                }
              >
                <Typography
                  align={"right"}
                  sx={{ px: 2, fontSize: "12px", cursor: "pointer" }}
                >
                  {t("notifications.markAllAsRead")}
                </Typography>
              </Link>
              {error && <Error>{error}</Error>}
            </>
          )}
        {notifications &&
          notifications
            .slice(0, MAX_NOTIFICATIONS_LIMIT)
            .map((notification: BENotification) => (
              <NotificationBox
                key={notification.id}
                notification={notification}
                handleClose={handleClose}
              />
            ))}
      </Popover>
    </>
  );
};

export default NotificationBell;
