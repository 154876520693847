import React, { ReactElement } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteIcon, EditIcon } from "../../../../../../../ui/table/styled";
import { route } from "../../../../../../../utils/url";
import { TABS } from "../../../../edit/types";
import { MailIcon } from "../../../styled";
import { ActionsTableCellProps } from "../../../types";
import { TableCell } from "../styled";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import useAccessControl from "../../../../../../../hooks/useAccessControl";
import {
  PermissionsDelete,
  PermissionsUpdate,
} from "../../../../../../../constants";
/* istanbul ignore next */
const ActionsTableCell = ({
  handleCellClick,
  handleSendCredentials,
  openConfirmToRemoveModal,
  row,
}: ActionsTableCellProps): ReactElement => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { can } = useAccessControl();
  return (
    <TableCell
      align="right"
      onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
        handleCellClick(e)
      }
      style={{ whiteSpace: "nowrap" }}
      $isActive={row.is_active}
    >
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {can(PermissionsUpdate.AREA) && (
          <Button
            color="primary"
            variant="outlined"
            size="small"
            sx={{ marginRight: 3 }}
            onClick={(): void => {
              navigate(route("properties.areas.edit.history", id, row.id));
            }}
          >
            {t("tenant.history.history")}
          </Button>
        )}
        {row.contact?.email ? (
          <MailIcon
            role={"sendCredentials"}
            size={18}
            onClick={handleSendCredentials}
          />
        ) : null}
        {can(PermissionsUpdate.AREA) && (
          <EditIcon
            role={"editIconRole"}
            onClick={(): void => {
              navigate(
                route("properties.areas.edit.tab", id, row.id, TABS.AREA)
              );
            }}
            size={18}
          />
        )}
        {!row.is_active && can(PermissionsDelete.AREA) ? (
          <DeleteIcon
            onClick={(): void => openConfirmToRemoveModal(row)}
            size={20}
            data-testid="delete-icon"
          />
        ) : null}
      </Box>
    </TableCell>
  );
};

export default ActionsTableCell;
