/* istanbul ignore file */
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  COMMENTABLE,
  EMPTY_DATA,
  PermissionsCreate,
  PermissionsUpdate,
  TENANT_USERS_FILTER,
} from "../../../../../../constants";
import { AreaListRowProps } from "../../types";
import CommentsPopup from "../../../../../../ui/commentsPopUp";
import { sendCredentials } from "../../../../../../api/requests";
import { HTTP_STATUS_CODES } from "../../../../../../types/server";
import { useTranslation } from "react-i18next";
import { prepareRowForTable } from "./utils";
import { mainFields, mainFieldsTenantUser } from "../../utils";
import AreaTableCell from "./cell";
import { CreateTenantModal } from "../../../components/tenants/create";
import ActionsTableCell from "./cell/Actions";
import { showCommentsModalInitState } from "../../../../../../ui/commentsPopUp/utils";
import { route } from "../../../../../../utils/url";
import { TABS } from "../../../edit/types";
import { EditIcon } from "../../../../../../ui/table/styled";
import { useNavigate, useParams } from "react-router-dom";
import { Button, TableCell } from "@mui/material";
import useAccessControl from "../../../../../../hooks/useAccessControl";
import useTable from "../../../../../../ui/table/useTable";
import { User } from "../../../../../../types/models";
import { Area as FEArea } from "../../../../../../types/fe/area";
import { StyledBox as Box, TableCell as AddTenantTableCell } from "./styled";
import { Chip } from "../../../../objectDataSheet/tabs/generalInformation/styled";
import CloseIcon from "@mui/icons-material/Close";
import { UpdateLeaseForActiveTenantModal } from "../../../components/updateLeaseForActiveTenantModal";
import ExpandableComment from "../../../../../../ui/expandableComment/ExpandableComment";
const AreaTableRow = ({
  row: rowFromParent,
  handleCellClick,
  setSendCredentialsSnackbar: setSnackbarMessage,
  additionalFields,
  openConfirmToRemoveModal,
  optionsDict,
  fetchAreas,
  isTenant,
  refreshAreaList,
}: AreaListRowProps): ReactElement => {
  const { t } = useTranslation();
  const [isCreateTenantModalOpen, setIsCreateTenantModalOpen] = useState(false);
  const [isDeleteActiveTenantModalOpen, setIsDeleteActiveTenantModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { can } = useAccessControl();
  const [isShowCommentsModal, setIsShowCommentsModal] = useState({
    isVisible: false,
    rowId: 0,
  });
  const [row, setRow] = useState<FEArea>(() =>
    prepareRowForTable(rowFromParent)
  );
  const { queryParams } = useTable<User>();
  const { _user } = useAccessControl();
  const params = isTenant
    ? `${queryParams}${TENANT_USERS_FILTER}${_user?.id}`
    : queryParams;

  const handleCloseCommentsModal = (): void => {
    refreshAreaList();
    setIsShowCommentsModal(showCommentsModalInitState);
  };

  const handleSendCredentials = useCallback(async () => {
    const response = await sendCredentials(String(row?.contact?.email));

    const { message } = await response.json();
    if (response.status === HTTP_STATUS_CODES.OK) {
      setSnackbarMessage({
        visible: true,
        text: t("requests.sendCredentialsSuccess"),
        color: "success",
      });
    } else {
      setSnackbarMessage({
        visible: true,
        text: message,
        color: "error",
      });
    }
  }, [row]);

  const handleCreateTenantModalCancel = (): void => {
    setIsCreateTenantModalOpen(false);
  };

  const handleCreateTenantModalClose = (): void => {
    setIsCreateTenantModalOpen(false);
    fetchAreas(params);
  };

  const openCreateTenantModal = useCallback(async () => {
    setIsCreateTenantModalOpen(true);
  }, []);

  const handleDeleteActiveTenantModalClose = (): void => {
    setIsDeleteActiveTenantModalOpen(false);
  };

  const fetchAreasAfterLeaseUpdate = (): void => {
    fetchAreas(params);
  };

  useEffect(() => {
    if (row.comments_count !== rowFromParent.comments_count) {
      setRow((prevState) => ({
        ...prevState,
        comments_count: rowFromParent.comments_count,
      }));
    }
  }, [row, rowFromParent]);

  return (
    <>
      {!isTenant && (
        <>
          <AddTenantTableCell $isActive={row.is_active}>
            <Box>
              {row.active_tenant ? (
                <Chip
                  color="success"
                  label={t("property.area.isRented")}
                  onClick={(): void => {
                    navigate(
                      route(
                        "properties.areas.edit.tab",
                        id,
                        row.id,
                        TABS.TENANT
                      )
                    );
                  }}
                  deleteIcon={
                    can(PermissionsUpdate.TENANT) ? <CloseIcon /> : <></>
                  }
                  onDelete={() => setIsDeleteActiveTenantModalOpen(true)}
                />
              ) : row.is_active && can(PermissionsCreate.TENANT) ? (
                <Button
                  role={"create-tenant-button"}
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={openCreateTenantModal}
                >
                  {t("property.area.addTenant")}
                </Button>
              ) : (
                EMPTY_DATA
              )}
            </Box>
          </AddTenantTableCell>
          {mainFields.map(({ name, type, unit }) => (
            <AreaTableCell
              name={name}
              type={type}
              unit={unit}
              key={name}
              options={optionsDict[name]}
              isActive={row.is_active}
            >
              {row[name]}
            </AreaTableCell>
          ))}
          {additionalFields.map(
            ({ visible, name, type, unit, label }) =>
              visible && (
                <AreaTableCell
                  name={name}
                  type={type}
                  unit={unit}
                  key={label}
                  options={optionsDict[name]}
                  isActive={row.is_active}
                >
                  {row.active_tenant?.[name]}
                </AreaTableCell>
              )
          )}
          <TableCell align="left">
            <ExpandableComment
              seeAllComment={() =>
                setIsShowCommentsModal({ isVisible: true, rowId: row?.id })
              }
              commentData={row?.latest_comment}
            ></ExpandableComment>
          </TableCell>
          <ActionsTableCell
            row={row}
            handleCellClick={handleCellClick}
            handleSendCredentials={handleSendCredentials}
            setIsShowCommentsModal={setIsShowCommentsModal}
            openConfirmToRemoveModal={openConfirmToRemoveModal}
          />

          {isShowCommentsModal.isVisible && (
            <CommentsPopup
              handleCloseDialog={handleCloseCommentsModal}
              id={String(isShowCommentsModal?.rowId)}
              relation={COMMENTABLE.AREA}
            />
          )}
          <CreateTenantModal
            isCreateTenantModalOpen={isCreateTenantModalOpen}
            handleCreateTenantModalClose={handleCreateTenantModalClose}
            handleCreateTenantModalCancel={handleCreateTenantModalCancel}
            area={row}
            setRow={setRow}
            setSnackbarMessage={setSnackbarMessage}
          />
          <UpdateLeaseForActiveTenantModal
            area={row}
            isConfirmToRemoveModalOpen={isDeleteActiveTenantModalOpen}
            handleConfirmToRemoveModalClose={handleDeleteActiveTenantModalClose}
            fetchAreasAfterLeaseUpdate={fetchAreasAfterLeaseUpdate}
          />
        </>
      )}
      {isTenant && (
        <>
          {mainFieldsTenantUser.map(({ name, type, unit }, index) => (
            <AreaTableCell
              name={name}
              type={type}
              unit={unit}
              key={name}
              options={optionsDict[name]}
              sx={index === 0 ? { paddingLeft: 0 } : null}
              isActive={row.is_active}
            >
              {row[name]}
            </AreaTableCell>
          ))}
          <TableCell>
            <EditIcon
              role={"editIconRole"}
              onClick={(): void => {
                navigate(
                  route("properties.areas.edit.tab", id, row.id, TABS.AREA)
                );
              }}
              size={18}
            />
          </TableCell>
        </>
      )}
    </>
  );
};

export default AreaTableRow;
