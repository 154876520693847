import React, { useEffect, useState } from "react";
import Button from "../../ui/button/Button";
import Snackbar from "../../ui/Snackbar";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, Stack } from "@mui/material";
import { FormikValues } from "formik/dist/types";
import TextField from "../../ui/formsFields/text";
import { INPUT_MIN_HEIGHT } from "../../constants";
import { TypeOfInventoryItem } from "../../types/be/companyOwnership";
import { route } from "../../utils/url";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

function CreateAndEditInventoryItemForm({
  handleSubmit,
  isSubmitting,
  values,
  status,
  typesOfInventoryItems,
  edit = false,
}: FormikValues): React.ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  const handleCloseSnackbar = (): void => {
    setIsSnackbarVisible(false);
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={6} columns={12}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="brand"
            required
            label={t("companyOwnership.brand")}
            sx={{ minHeight: INPUT_MIN_HEIGHT }}
            inputProps={{ "data-testid": "brand-input" }}
            status={status}
          />
          <TextField
            name="department"
            label={t("companyOwnership.department")}
            inputProps={{ "data-testid": "department-input" }}
            sx={{ minHeight: INPUT_MIN_HEIGHT }}
            status={status}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="type_id"
            select
            required
            label={t("companyOwnership.type")}
            sx={{ minHeight: INPUT_MIN_HEIGHT }}
            inputProps={{ "data-testid": "typeId-input" }}
            status={status}
            value={values.type_id || ""}
          >
            {typesOfInventoryItems?.map(
              (typesOfInventoryItem: TypeOfInventoryItem) => (
                <MenuItem
                  key={typesOfInventoryItem.id}
                  value={typesOfInventoryItem.id}
                >
                  {typesOfInventoryItem.name}
                </MenuItem>
              )
            )}
          </TextField>
          <TextField
            name="number"
            required
            inputProps={{ "data-testid": "number-input" }}
            label={t("companyOwnership.licensePlateSerialNumber")}
            sx={{ minHeight: INPUT_MIN_HEIGHT }}
            status={status}
          />
        </Grid>
      </Grid>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
      >
        <Button
          type="button"
          color={"primary"}
          variant="text"
          title={t("cancel")}
          disabled={isSubmitting}
          size="large"
          onClick={
            /* istanbul ignore next */ () =>
              doesAnyHistoryEntryExist
                ? navigate(-1)
                : navigate(route("human-resources.company-ownership"))
          }
        />
        <Button
          title={edit ? t("update") : t("create")}
          sx={{ ml: "1rem" }}
          color="success"
          type="submit"
          size="large"
          disabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Stack>
      <Snackbar
        message={
          edit
            ? t("companyOwnership.inventoryItemWasSuccessfullyUpdated")
            : t("companyOwnership.inventoryItemWasSuccessfullyCreated")
        }
        color="success"
        open={isSnackbarVisible}
        handleClose={handleCloseSnackbar}
      />
    </form>
  );
}

export default CreateAndEditInventoryItemForm;
