import { Grid } from "@mui/material";
import { Formik } from "formik";
import React, { ReactElement } from "react";
import { createNewDebitAccount, createInitSollKonto } from "./utils";
import useValidation from "../../../../../../hooks/useValidation";
import * as Yup from "yup";
import Form from "./Form";
import useSnackbar from "../../../../../../ui/snackbar1/useSnackbar";
import { useTranslation } from "react-i18next";
import { TABS } from "../../utils";
import { route } from "../../../../../../utils/url";
import { useNavigate, useParams } from "react-router-dom";
import { Paper } from "../../creditor/create/styled";

export const CreateDebitAccount = (): ReactElement => {
  const { Snackbar, snackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { property_id, debtor } = useValidation();

  const onSuccess = (): void => {
    navigate(route("properties.invoices.apidatev.tab", id, TABS.DEBITACCOUNT));
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={4}
        mt={0}
      >
        <Paper sx={{ p: 5 }}>
          <Grid item>
            <Formik
              initialValues={{ ...createInitSollKonto, property_id: id! }}
              validationSchema={Yup.object().shape({
                property_id,
                konto: debtor.konto,
              })}
              onSubmit={createNewDebitAccount({
                t,
                snackbar,
                onSuccess,
              })}
            >
              {(props): ReactElement => <Form {...props} />}
            </Formik>
          </Grid>
        </Paper>
        {Snackbar}
      </Grid>
    </>
  );
};
