/* istanbul ignore file */

import styled from "styled-components/macro";
import {
  TableCell as MUITableCell,
  TextField,
  Box,
  Chip as MuiChip,
} from "@mui/material";
import MultiSelectCustom from "../../../../../../ui/formsFields/multiSelect";
import { rgba } from "polished";

export const TableCell = styled(MUITableCell)<{ $isActive: boolean }>`
  white-space: nowrap;
  background-color: ${(props) =>
    !props.$isActive
      ? rgba(props.theme.palette.grey[600], 0.6)
      : "transparent"};
`;

export const StyledTextField = styled(TextField)`
  min-height: auto !important;
`;

export const MultiSelect = styled(MultiSelectCustom)`
  padding: ${(props): string => props.theme.spacing(1.5)} 0;
`;

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
`;

export const SelectBox = styled(Box)`
  gap: ${(props): string => props.theme.spacing(0.5)};
  display: flex;
  flex-wrap: wrap;
`;

export const Chip = styled(MuiChip)<{ $transparent?: boolean }>`
  padding: ${(props): string => props.theme.spacing(4.2, 0)};
  font-size: ${(props): string => props.theme.spacing(3.3)};
  margin-bottom: ${(props): string => props.theme.spacing(1)};
  background-color: ${(props): string =>
    props.$transparent ? "transparent" : "default"};
  &:hover {
    background-color: ${(props): string =>
      props.$transparent ? "transparent" : "default"};
  }
`;
