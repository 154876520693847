import React, { ReactElement, useEffect, useState } from "react";
import { Grid, Paper, Stack, Tabs, Tab, Box } from "@mui/material";
import Comments from "../../../ui/сomments";
import useComments from "../../../ui/сomments/useComments";
import { COMMENTABLE, FormMessageInItState } from "../../../constants";
import { useParams } from "react-router-dom";
import { getTenantRequest } from "../../../api/tenantRequest";
import { getJson } from "../../../utils/http";
import { FormWrapper } from "./styled";
import { useTranslation } from "react-i18next";
import useValidation from "../../../hooks/useValidation";
import { FormMessageInterface } from "../../../types/form";
import FormAlert from "../../../ui/formAlert/FormAlert";
import * as Yup from "yup";
import { Formik } from "formik";
import Form from "./Form";
import {
  editTenantRequestInitState,
  handleTenantRequestFormSubmit,
} from "./utils";
import useChats from "../../../ui/chats/useChats";
import Chats from "../../../ui/chats";
import { TenantRequest } from "../../../types/be/tenantRequest";
import { useCustomEventListener } from "react-custom-events";
import { Chat } from "../../../types/be/chat";
import {
  StyledGridTabs,
  StyledGridTabsContent,
  StyledTabContent,
} from "../../properties/objectDataSheet/styled";
import { simpleTabProps } from "../../properties/objectDataSheet/utils";
import { TabPanelProps } from "./types";
import PageWrapper from "../../../ui/pageWrapper/PageWrapper";
import { route } from "../../../utils/url";

function TabPanel({
  children,
  value,
  index,
  ...props
}: TabPanelProps): ReactElement {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const TenantRequestDetails = (): ReactElement => {
  const { tenant_request: tenantRequestValidation } = useValidation();
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const { id } = useParams();
  const { t } = useTranslation();
  const [tenantRequest, setTenantRequest] = useState<TenantRequest | null>(
    null
  );
  const [statusCode, setStatusCode] = useState("");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    fetchTenantRequest();
  }, [id]);

  /* istanbul ignore next */
  useCustomEventListener(
    "TenentRequestChatMessageReceived",
    async (data: Chat) => {
      if (data.tenant_request_id === tenantRequest?.id) {
        setChats((chats) => [...chats, data]);
      }
    }
  );

  const {
    comments,
    content,
    setContent,
    totalPages,
    handleCommentSave,
    handleCommentDelete,
    handlePaginationChange,
    currentPage,
    deleting,
    loading,
  } = useComments({
    relation: COMMENTABLE.TENANT_REQUEST,
    relation_id: id!,
  });

  const {
    chats,
    content: chatContent,
    setContent: setChatContent,
    totalPages: chatTotalPages,
    handleChatSave,
    handlePaginationChange: handleChatPaginationChange,
    currentPage: chatCurrentPage,
    handleChatRead,
    setChats,
  } = useChats({
    id: Number(id!),
  });

  const fetchTenantRequest = (): void => {
    getTenantRequest(+id!)
      .then(getJson)
      .then(({ data }) => {
        setStatusCode(data?.status?.code);
        setTenantRequest(data);
      });
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: number): void => {
    setTabValue(newValue);
  };

  const breadcrumbs = [
    {
      to: route("tenant-requests"),
      name: t("sidebar.tenantRequests"),
    },
  ];

  return (
    <PageWrapper
      title={tenantRequest?.title ?? /* istanbul ignore next */ ""}
      breadcrumbs={breadcrumbs}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} xl={8}>
          <Paper>
            <Grid container>
              <Grid item xs={12}>
                <Stack
                  display={"block"}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {formMessage.text && (
                    <FormAlert
                      formMessage={formMessage}
                      sx={{ marginBottom: "10px" }}
                    />
                  )}
                  <FormWrapper>
                    <Formik
                      initialValues={editTenantRequestInitState}
                      validationSchema={Yup.object().shape(
                        tenantRequestValidation
                      )}
                      onSubmit={handleTenantRequestFormSubmit({
                        setFormMessage,
                      })}
                    >
                      {(props): ReactElement => (
                        <Form
                          {...props}
                          fetchTenantRequest={fetchTenantRequest}
                          statusCode={statusCode}
                          tenantRequest={tenantRequest}
                          setTenantRequest={setTenantRequest}
                        />
                      )}
                    </Formik>
                  </FormWrapper>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} xl={4}>
          <StyledGridTabs item xs={12} sm={12}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <Tab label={t("chats")} {...simpleTabProps(0)} />
              <Tab label={t("widgets.comments")} {...simpleTabProps(1)} />
            </Tabs>
          </StyledGridTabs>
          <StyledGridTabsContent item xs={12} sm={12}>
            <StyledTabContent>
              <TabPanel
                key={0}
                value={tabValue}
                index={0}
                data-testid={`tab-content-${0}`}
              >
                <Chats
                  editorContent={chatContent}
                  setEditorContent={setChatContent}
                  handleSaveChat={handleChatSave}
                  chats={chats}
                  currentPage={chatCurrentPage}
                  totalPages={chatTotalPages}
                  handlePaginationChange={handleChatPaginationChange}
                  handleChatRead={handleChatRead}
                  chatDisabled={!tenantRequest?.tenant}
                />
              </TabPanel>
              <TabPanel
                key={1}
                value={tabValue}
                index={1}
                data-testid={`tab-content-${1}`}
              >
                <Comments
                  editorContent={content}
                  setEditorContent={setContent}
                  handleSaveComment={handleCommentSave}
                  comments={comments}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handleDeleteComment={handleCommentDelete}
                  handlePaginationChange={handlePaginationChange}
                  deleting={deleting}
                  loading={loading}
                />
              </TabPanel>
            </StyledTabContent>
          </StyledGridTabsContent>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default TenantRequestDetails;
