import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import Form from "./Form";
import { FormWrapper } from "./styled";
import { Stack } from "@mui/material";
import {
  FormMessageErrorState,
  FormMessageInItState,
  UserRoles,
} from "../../../constants";
import FormAlert from "../../../ui/formAlert/FormAlert";
import useValidation from "../../../hooks/useValidation";
import * as Yup from "yup";
import { createUserFormInitState, handleCreateUserFormSubmit } from "./utils";
import { FormMessageInterface } from "../../../types/form";
import { useFetch } from "../../../hooks/useFetch";
import { getSalutations } from "../../../api/salutations";
import { ErrorBox } from "../../properties/objectDataSheet/tabs/generalInformation/styled";
import Loader from "../../Loader";
import { Salutation } from "../../../types/models";
import useRoles from "../../../hooks/useRoles";
import PageWrapper from "../../../ui/pageWrapper/PageWrapper";
import { route } from "../../../utils/url";
import { useTranslation } from "react-i18next";

const CreateUser = (): ReactElement => {
  const { t } = useTranslation();
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const [initialValues] = useState(createUserFormInitState);
  const navigate = useNavigate();
  const [selectedUserRoll, setSelectedUserRoll] = useState("");
  const {
    first_name,
    last_name,
    email,
    salutation_id,
    phone_number,
    mobile_number,
    role: role_id,
    password,
    password_confirmation,
  } = useValidation();

  const { roles, isError: isRolesError, getRole } = useRoles();

  const {
    data: salutations,
    isError: isSalutationsError,
    run: runSalutations,
  } = useFetch<Salutation[]>();

  useEffect(() => {
    getData();
  }, []);

  const getData = (): void => {
    runSalutations(getSalutations());
  };

  const adminId = String(getRole(UserRoles.ADMIN)?.id);
  const assetManagerId = String(getRole(UserRoles.ASSET_MANAGER)?.id);
  const transactionManagerId = String(getRole(UserRoles.TRANSACTION_MANAGER)?.id);
  const propertyManagerId = String(getRole(UserRoles.PROPERTY_MANAGER)?.id);

  const isInternalRole = [adminId, assetManagerId, transactionManagerId];

  const selectedInternalRole = isInternalRole.includes(selectedUserRoll);

  if (isRolesError || isSalutationsError) {
    return <ErrorBox formMessage={FormMessageErrorState} />;
  }

  const breadcrumbs = [
    {
      to: route("users"),
      name: t("sidebar.userManagement"),
    },
  ];

  return (
    <PageWrapper title={t("create")} breadcrumbs={breadcrumbs}>
      <>
        {roles && salutations ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            <FormWrapper>
              {formMessage.text && (
                <FormAlert
                  formMessage={formMessage}
                  sx={{ marginBottom: "10px" }}
                />
              )}
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  first_name,
                  last_name,
                  email,
                  salutation_id,
                  phone_number,
                  mobile_number,
                  role_id,
                  password: password.required,
                  password_confirmation: password_confirmation.required,
                })}
                onSubmit={handleCreateUserFormSubmit({
                  setFormMessage,
                  navigate,
                  selectedInternalRole,
                })}
              >
                {(props): ReactElement => (
                  <Form
                    salutations={salutations}
                    setSelectedUserRoll={setSelectedUserRoll}
                    selectedInternalRole={selectedInternalRole}
                    propertyManagerId={propertyManagerId}
                    {...props}
                  />
                )}
              </Formik>
            </FormWrapper>
          </Stack>
        ) : (
          <Loader />
        )}
      </>
    </PageWrapper>
  );
};

export default CreateUser;
