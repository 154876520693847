/* istanbul ignore file */

import styled from "styled-components/macro";
import { Paper, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { blue, red } from "@mui/material/colors";
import Button from "../../../../ui/button/Button";
import CloseIcon from "@mui/icons-material/Close";
export const FormWrapper = styled(Paper)`
  padding: ${(props): string => `0 0px ${props.theme.spacing(4)}`};
  background: ${(props): string => props.theme.palette.background.default};
`;

export const AutocompleteListItem = styled.li`
  font-size: 11px;
  font-weight: 500;
`;

export const HeaderText = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`;

export const LocationText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: ${(props): string => props.theme.palette.grey[500]};
`;

export const LabelText = styled(Typography)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: ${(props): string => props.theme.palette.grey[500]};
`;

export const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

export const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

export const EditSectionButton = styled(EditIcon)`
  color: ${(): string => blue[500]};
  position: absolute;
  top: 5px;
  right: 5px;
  transition: 0.75s;
  cursor: pointer;
`;

export const SaveSectionButton = styled(Button)`
  position: absolute;
  top: 1px;
  right: 50px;
  transition: 0.75s;
  cursor: pointer;
  min-width: 0;
  svg {
    font-size: 22px !important;
  }
`;

export const CancelSectionButton = styled(CloseIcon)`
  color: ${(): string => red[600]};
  position: absolute;
  top: -2px;
  right: 5px;
  transition: 0.75s;
  cursor: pointer;
  font-size: 35px !important;
`;

export const EditableSection = styled(Paper)`
  position: relative;

  p {
    word-break: break-all;
  }
`;

export const EmptyEditableSection = styled(Paper)`
  background: ${(props): string => props.theme.palette.background.default};
  padding: ${(props): string => props.theme.spacing(2)};
  border-radius: 0;
`;
