import React, { ReactElement, useCallback, useEffect } from "react";
import Table from "../../../../../ui/table/Table";
import useTable from "../../../../../ui/table/useTable";
import { TableCell, Typography, Grid } from "@mui/material";
import { itemsListHeadCells } from "../utils";
import {
  DeleteIcon,
  Input,
  Search,
  SearchIconWrapper,
} from "../../../../../ui/table/styled";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { deleteReading, getReadingsList } from "../../../../../api/reading";
import { handleServerError } from "../../../../../utils/http";
import { prepareQueryParams } from "../../../../../utils/common";
import { useNavigate, useParams } from "react-router-dom";
import { Reading } from "../../../../../types/be/readings";
import Button from "../../../../../ui/button/Button";
import { route } from "../../../../../utils/url";
import WestIcon from "@mui/icons-material/West";
import { BackButton } from "../styled";
import useAccessControl from "../../../../../hooks/useAccessControl";
import { UserRoles } from "../../../../../constants";
/* istanbul ignore next */
const Readings = (): ReactElement => {
  const { t } = useTranslation();
  const { id, areaId, counterId } = useParams();
  const navigate = useNavigate();
  const {
    data,
    setData,
    total,
    order,
    orderBy,
    setTotal,
    isLoading,
    setError,
    error,
    rowsPerPage,
    queryParams,
    setIsLoading,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleTableSearch,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    rowToDelete,
    openConfirmToRemoveModal,
  } = useTable<Reading>();

  const { isRole, _user } = useAccessControl();
  const isTenant = isRole(UserRoles.TENANT);

  const fetchReadings = useCallback(async () => {
    setIsLoading(true);
    const apiParams: Record<string, string> = {
      counter_id: counterId!,
    };

    if (isTenant) {
      apiParams.created_by = String(_user?.id);
    }

    const params = prepareQueryParams(queryParams, apiParams);
    const response = await getReadingsList(params);
    if (response.status !== 200) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const json = await response.json();
      setTotal(json?.meta?.total);
      setData(json.data);
    }
    setIsLoading(false);
  }, [queryParams, counterId, isTenant]);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteReading(rowToDelete?.id);
    await fetchReadings();
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteReading,
    fetchReadings,
    handleConfirmToRemoveModalClose,
  ]);

  useEffect(() => {
    queryParams && fetchReadings();
  }, [queryParams]);

  const renderRow = (row: Reading): ReactElement => (
    <>
      <TableCell padding="none">
        <Typography
          noWrap
        >{`${row.created_by?.first_name} ${row.created_by?.last_name}`}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{row.value}</Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap>{row.date}</Typography>
      </TableCell>
      <TableCell align="right">
        <DeleteIcon
          onClick={(): void => openConfirmToRemoveModal(row)}
          size={20}
          data-testid="delete-icon"
        />
      </TableCell>
    </>
  );

  const TableToolbar = (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems="center"
      sx={{ marginBottom: 2 }}
    >
      <Grid item>
        <Button
          data-testid={"dataItems-link"}
          size="small"
          color="success"
          variant="contained"
          title={t("create")}
          onClick={() =>
            navigate(
              route(
                "properties.areas.edit.counter.reading.create",
                id,
                areaId,
                counterId
              )
            )
          }
        />
      </Grid>
      <Grid item>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Input placeholder={t("search")} onChange={handleTableSearch} />
        </Search>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid container sx={{ pt: 5 }}>
        <Typography variant="body1" gutterBottom>
          <BackButton
            onClick={() =>
              navigate(
                route("properties.areas.edit.tab", id, areaId, "counters")
              )
            }
            sx={{ cursor: "pointer" }}
            data-testid="back-button"
          >
            <WestIcon fontSize="small" />
            {t("tenant.counters")}
          </BackButton>
        </Typography>
        <Table
          data={data}
          total={total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={itemsListHeadCells}
          renderRow={renderRow}
          tableToolbar={TableToolbar}
          onRowRemove={handleRowRemove}
        />
      </Grid>
    </>
  );
};

export default Readings;
