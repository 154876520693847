import React, { ReactElement, useContext } from "react";
import ContextMenu from "../../ui/contextMenu";
import { contextMenuWidth } from "./utils";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
} from "@mui/material";
import { Cloud, ContentCopy } from "@mui/icons-material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useTranslation } from "react-i18next";
import useAccessControl from "../../hooks/useAccessControl";
import { PermissionsDelete, PermissionsUpdate } from "../../constants";
import { FileManagementContext } from "./contexts/FileManagementContext";

const FileManagementContextMenu = (): ReactElement => {
  const { t } = useTranslation();
  const { can } = useAccessControl();
  const {
    contextMenuTop,
    contextMenuLeft,
    onClose,
    contextMenuClickedOnItem,
    disabledDelete,
    hasRelation,
    handleContextMenuDeleteClick,
    handleContextMenuRenameClick,
    handleContextMenuMoveToClick,
    handleContextMenuCreateFolderClick,
    handleContextMenuDownloadClick,
    handleContextMenuUploadFileClick,
  } = useContext(FileManagementContext);

  return (
    <ContextMenu top={contextMenuTop} left={contextMenuLeft} close={onClose}>
      <Paper sx={{ width: contextMenuWidth, maxWidth: "100%" }}>
        {contextMenuClickedOnItem ? (
          <MenuList>
            <MenuItem
              disabled={
                disabledDelete ||
                hasRelation ||
                !can([PermissionsDelete.FILE_MANAGEMENT])
              }
              onClick={handleContextMenuDeleteClick}
            >
              <ListItemIcon>
                <DeleteForeverIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("delete")}</ListItemText>
            </MenuItem>
            <MenuItem
              disabled={
                disabledDelete || !can([PermissionsUpdate.FILE_MANAGEMENT])
              }
              onClick={handleContextMenuRenameClick}
            >
              <ListItemIcon>
                <DriveFileRenameOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("rename")}</ListItemText>
            </MenuItem>
            <MenuItem
              disabled={
                disabledDelete || !can([PermissionsUpdate.FILE_MANAGEMENT])
              }
              onClick={handleContextMenuMoveToClick}
            >
              <ListItemIcon>
                <ContentCopy fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("moveTo")}</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleContextMenuDownloadClick}>
              <ListItemIcon>
                <FileDownloadIcon sx={{ width: 23, height: 23 }} />
                <ListItemText sx={{ paddingLeft: 3.5 }}>
                  {t("download")}
                </ListItemText>
              </ListItemIcon>
            </MenuItem>
          </MenuList>
        ) : (
          <MenuList>
            <MenuItem onClick={handleContextMenuCreateFolderClick}>
              <ListItemIcon>
                <Cloud fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("createFolder")}</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleContextMenuUploadFileClick}>
              <ListItemIcon>
                <CloudUploadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("uploadFile")}</ListItemText>
            </MenuItem>
          </MenuList>
        )}
      </Paper>
    </ContextMenu>
  );
};

export default FileManagementContextMenu;
