import React, { ReactElement, useState, useCallback, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import { Grid } from "@mui/material";
import Autocomplete from "../../../ui/autocomplete/Autocomplete";
import { getUsers } from "../../../api/users";
import { useTranslation } from "react-i18next";
import { User } from "../../../types/be.interfaces";
import { UserAutocompleteProps } from "./types";
import { DEBOUNCE_TIME, objectGetParamsToString } from "../../../utils/common";
import { PAGE_LIMIT } from "../../../constants";
import { PERMISSIONS_CODE_ARRAY } from "../../tenantRequests/utils";

const UsersAutocomplete = ({
  status,
  roleCode,
  permissionCodes,
  placeholder,
  label,
  user,
  fieldName = "user_id",
  optionLabel,
  size = "medium",
  sx = { minHeight: "74px", mb: 1 },
  preloadOptions,
  disabled = false,
}: UserAutocompleteProps): ReactElement => {
  const { t } = useTranslation();
  const [field, meta] = useField(fieldName);
  const { setFieldValue } = useFormikContext();

  const [selectedUser, setSelectedUser] = useState<Pick<
    User,
    "id" | "first_name" | "last_name"
  > | null>(null);

  const handleInputChange = useCallback(async (search: string) => {
    const params = `?${objectGetParamsToString({
      search,
      role_code: roleCode,
      limit: String(PAGE_LIMIT._10),
    })}${
      /* istanbul ignore next */
      permissionCodes && permissionCodes.length > 0
        ? permissionCodes.map(
            (item: string) => `&${PERMISSIONS_CODE_ARRAY}=${item}`
          )
        : ""
    }`;
    const response = await getUsers(params);
    const { data } = await response.json();

    return data.map((res: User) => {
      return {
        ...res,
        name: `${res.first_name} ${res.last_name}`,
      };
    });
  }, []);

  useEffect(() => {
    user && !selectedUser && /* istanbul ignore next */ setSelectedUser(user);
    !field.value && !user && /* istanbul ignore next */ setSelectedUser(null);
  }, [user, field.value]);

  const onSelectUser = async (event: User): Promise<void> => {
    setSelectedUser(event);
    event && setFieldValue(fieldName, event.id);
  };
  /* istanbul ignore next */
  useEffect(() => {
    if (preloadOptions)
      preloadOptions.unshift({
        ...preloadOptions[0],
        id: -1,
        first_name: t("start"),
        last_name: t("typing"),
      });
  }, [preloadOptions]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Autocomplete
            id="user-autocomplete"
            name={fieldName}
            handleOnChangeInputText={handleInputChange}
            handleOptionSelect={onSelectUser}
            placeholder={t(placeholder)}
            label={t(label)}
            value={field.value || ""}
            autocompleteValue={selectedUser || null}
            error={Boolean(
              meta.touched && (status?.errors?.[fieldName] || meta.error)
            )}
            helperText={
              meta.touched ? meta.error || status?.errors?.[fieldName] : ""
            }
            optionValue="id"
            optionLabel={optionLabel}
            debounceTime={DEBOUNCE_TIME}
            size={size}
            sx={sx}
            preloadOptions={selectedUser ? null : preloadOptions}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default UsersAutocomplete;
