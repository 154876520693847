import { SidebarItemsType } from "./types";
import {
  Clipboard,
  File,
  FileText,
  Home,
  Inbox,
  Settings,
  Sliders,
  Users,
} from "react-feather";
import { Contacts } from "@mui/icons-material";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import { APP_ENVIRONMENTS, PermissionsRead, UserRoles } from "../../constants";
import SavingsIcon from "@mui/icons-material/Savings";
import GasMeter from "@mui/icons-material/GasMeter";

const pagesSection = [
  {
    href: "root",
    icon: Sliders,
    title: "sidebar.dashboard",
    regexpPath: "/:client/",
    permissions: [PermissionsRead.DASHBOARD],
  },
  {
    href: "properties",
    icon: Home,
    title: "sidebar.properties",
    regexpPath: "/:client/properties(.*)",
    permissions: [PermissionsRead.PROPERTY],
    moduleName: "properties",
    children: [
      {
        href: "properties",
        title: "sidebar.properties",
        regexpPath: "/:client/properties(.*)",
        permissions: [PermissionsRead.PROPERTY],
        moduleName: "properties",
      },
      {
        href: "vacancies",
        title: "sidebar.vacancies",
        regexpPath: "/:client/vacancies",
        permissions: [PermissionsRead.PROPERTY],
        moduleName: "properties",
      },
      {
        href: "maintenance",
        title: "property.maintenance.title",
        regexpPath: "/:client/maintenance(.*)",
        permissions: [PermissionsRead.MAINTENANCE],
        moduleName: "maintenance",
      },
    ],
  },
  {
    href: "",
    icon: File,
    title: "sidebar.transactionManager",
    regexpPath: "/:client/transaction-management(.*)",
    env: [
      APP_ENVIRONMENTS.DEVELOPMENT,
      APP_ENVIRONMENTS.LOCAL,
      APP_ENVIRONMENTS.STAGING,
    ],
    children: [
      {
        href: "loi",
        title: "sidebar.loi",
        regexpPath: "/:client/transaction-management/loi(.*)",
        permissions: [PermissionsRead.LOI],
      },
    ],
    permissions: [PermissionsRead.LOI],
    moduleName: "transaction-management",
  },
  {
    href: "companies",
    icon: Home,
    title: "sidebar.companies",
    env: [APP_ENVIRONMENTS.DEVELOPMENT, APP_ENVIRONMENTS.LOCAL],
    regexpPath: "/:client/companies(.*)",
    permissions: [PermissionsRead.PROPERTY],
    moduleName: "companies",
  },
  {
    href: "",
    icon: File,
    title: "sidebar.documentRelease",
    regexpPath: "/:client/document-release(.*)",
    permissions: [
      PermissionsRead.INVOICE,
      PermissionsRead.CONTRACT,
      PermissionsRead.OFFER,
      PermissionsRead.INSURANCE,
      PermissionsRead.OUTGOING_INVOICE,
    ],
    moduleName: "document-release",
    children: [
      {
        title: "sidebar.invoices",
        regexpPath: "/:client/document-release/invoices((?!/reminders).*)",
        permissions: [
          PermissionsRead.OUTGOING_INVOICE,
          PermissionsRead.INVOICE,
        ],
        children: [
          {
            href: "invoices",
            title: "sidebar.invoices",
            regexpPath: "/:client/document-release/invoices((?!/reminders).*)",
            permissions: [PermissionsRead.INVOICE],
          },
          {
            href: "invoices.reminders",
            title: "sidebar.reminders",
            regexpPath: "/:client/document-release/invoices/reminders(.*)",
            permissions: [PermissionsRead.INVOICE],
          },
          {
            href: "outgoing-invoices",
            title: "sidebar.outgoingInvoices",
            regexpPath: "/:client/document-release/outgoing-invoices(.*)",
            permissions: [PermissionsRead.OUTGOING_INVOICE],
          },
        ],
      },
      {
        href: "contracts",
        title: "sidebar.contracts",
        regexpPath: "/:client/document-release/contracts(.*)",
        permissions: [PermissionsRead.CONTRACT],
      },
      {
        href: "offers",
        title: "sidebar.offers",
        regexpPath: "/:client/document-release/offers(.*)",
        permissions: [PermissionsRead.OFFER],
      },
      {
        href: "insurances",
        title: "sidebar.insurances",
        regexpPath: "/:client/document-release/insurances(.*)",
        permissions: [PermissionsRead.INSURANCE],
      },
    ],
  },
  {
    href: "",
    icon: Users,
    title: "users",
    regexpPath: "/:client/users(.*)",
    children: [
      {
        href: "users",
        title: "sidebar.userManagement",
        roles: [UserRoles.ADMIN],
        regexpPath: "/:client/users/user-management(.*)",
        permissions: [PermissionsRead.USER_MANAGEMENT],
      },
      {
        href: "users.activity",
        title: "sidebar.activity",
        roles: [UserRoles.ADMIN],
        regexpPath: "/:client/users/activity",
        moduleName: "human-resources",
      },
    ],
    permissions: [PermissionsRead.USER_MANAGEMENT],
  },
  {
    href: "",
    icon: Users,
    title: "hr",
    regexpPath: "/:client/human-resources(.*)",
    permissions: [
      PermissionsRead.COMPANY_OWNERSHIP,
      PermissionsRead.EMPLOYEE_OVERVIEW,
      PermissionsRead.VACATION_REQUEST,
    ],
    moduleName: "human-resources",
    children: [
      {
        href: "human-resources.employees",
        title: "sidebar.employeeOverview",
        roles: [UserRoles.ADMIN],
        permissions: [PermissionsRead.EMPLOYEE_OVERVIEW],
        regexpPath: "/:client/human-resources/employee-overview(.*)",
      },
      {
        href: "human-resources.vacation-requests",
        title: "sidebar.vacationRequests",
        env: [APP_ENVIRONMENTS.DEVELOPMENT, APP_ENVIRONMENTS.LOCAL],
        regexpPath: "/:client/human-resources/vacation-requests(.*)",
        permissions: [PermissionsRead.VACATION_REQUEST],
      },
      {
        href: "human-resources.company-ownership",
        title: "sidebar.companyOwnership",
        regexpPath: "/:client/human-resources/company-ownership(.*)",
        permissions: [PermissionsRead.COMPANY_OWNERSHIP],
      },
    ],
  },
  {
    href: "contacts",
    icon: Contacts,
    title: "sidebar.contacts",
    regexpPath: "/:client/contacts(.*)",
    permissions: [PermissionsRead.CONTACT],
  },
  {
    href: "bank-accounts",
    icon: SavingsIcon,
    title: "sidebar.bankAccount",
    roles: [UserRoles.ADMIN, UserRoles.ASSET_MANAGER],
    regexpPath: "/:client/bank-accounts(.*)",
    permissions: [PermissionsRead.BANK_ACCOUNT],
    moduleName: "bank-accounts",
  },
  {
    href: "file-management",
    icon: DriveFileMoveIcon,
    title: "sidebar.fileManagement",
    regexpPath: "/:client/file-management(.*)",
    permissions: [PermissionsRead.FILE_MANAGEMENT],
    moduleName: "file-management",
  },
  {
    href: "inbox",
    icon: Inbox,
    title: "sidebar.inbox",
    regexpPath: "/:client/inbox(.*)",
    permissions: [PermissionsRead.INBOX],
    moduleName: "inbox",
  },
  {
    href: "general-documents",
    icon: FileText,
    title: "sidebar.generalDocuments",
    regexpPath: "/:client/general-documents(.*)",
    permissions: [PermissionsRead.GENERAL_DOCUMENT],
    moduleName: "general-documents",
  },
  {
    href: "meter",
    icon: GasMeter,
    title: "sidebar.meter",
    regexpPath: "/:client/meter(.*)",
    permissions: [PermissionsRead.METER],
    moduleName: "mobile-app",
  },
  {
    href: "tenant-requests",
    icon: Clipboard,
    title: "sidebar.tenantRequests",
    regexpPath: "/:client/tenant-requests(.*)",
    permissions: [PermissionsRead.TENANT_REQUEST],
    moduleName: "mobile-app",
  },
  {
    icon: Settings,
    title: "sidebar.settings",
    regexpPath: "/:client/settings(.*)",
    permissions: [
      PermissionsRead.SETTINGS_ROLE,
      PermissionsRead.SETTINGS_RELEASE,
    ],
    children: [
      {
        href: "settings.roles",
        title: "sidebar.roles",
        regexpPath: "/:client/settings/roles(.*)",
        permissions: [PermissionsRead.SETTINGS_ROLE],
      },
      {
        href: "settings.release",
        title: "sidebar.release",
        regexpPath: "/:client/settings/release(.*)",
        permissions: [PermissionsRead.SETTINGS_RELEASE],
      },
    ],
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "",
    pages: pagesSection,
  },
];

export default navItems;
