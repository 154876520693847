/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  createBankAccount,
  deleteBankAccount,
  getBankAccount,
  getBankAccounts,
  sync,
  updateBankAccount,
} from "../../api/bankAccount";
import { getJson } from "../../utils/http";
import { BankConnection } from "../../types/be/bankAccount";
import { BankAccount as FEBankAccount } from "../../types/fe/bankAccount";
import { BankAccount as BEBankAccount } from "../../types/be/bankAccount";
import { Grid, Paper } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSnackbar from "../../ui/snackbar1/useSnackbar";
import { route } from "../../utils/url";
import ToolBar from "./components/toolBar";
import AttachPropertyToBankAccount from "./properties/AttachPropertyToBankAccount";
import useIsMounted from "../../hooks/useIsMounted";
import Payments from "./payments";
import FinApiWebFormModal from "./components/finApiWebForm/FinApiWebFormModal";
import BankConnectionModal from "./components/bankConnection/BankConnectionModal";
import BankAccountList from "./components/bankAccountList";
import { isNodeEnv } from "../../utils/env";
import { NODE_ENVIRONMENTS, PAGE_LIMIT } from "../../constants";
import { BankAccountsPayments } from "./styled";
import { prepareQueryParams } from "../../utils/common";

const BankAccountPage = ({
  useOnWidget = false,
  setError,
}: {
  useOnWidget?: boolean;
  setError?: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
  const { id } = useParams();
  const [isWebFormModalOpen, setIsWebFormModalOpen] = useState(false);
  const [isBankConnectionModalOpen, setIsBankConnectionModalOpen] =
    useState(false);
  const [bankAccounts, setBankAccounts] = useState<BEBankAccount[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isBankAccountsLoading, setIsBankAccountsLoading] = useState(false);
  const [isAttachPropertyModalOpen, setIsAttachPropertyModalOpen] =
    useState(false);
  const [
    isCreateBankAccountButtonDisabled,
    setIsCreateBankAccountButtonDisabled,
  ] = useState(false);
  const [bankConnection, setBankConnection] = useState<BankConnection | null>(
    null
  );
  const [selectedBankAccount, setSelectedBankAccount] = useState<
    BEBankAccount | undefined
  >(undefined);
  const [fetchBankPayments, setFetchBankPayments] = useState<() => void>(
    () => {}
  );
  const [errorTableOnSync, setErrorTableOnSync] = useState("");
  const navigate = useNavigate();
  const { Snackbar, snackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    setIsBankAccountsLoading(true);
    fetchBankAccounts();
  }, []);

  useEffect(() => {
    if (isMounted() && !isAttachPropertyModalOpen) fetchBankAccounts();
  }, [isAttachPropertyModalOpen]);

  const fetchBankAccounts = (): void => {
    const params = prepareQueryParams("", {
      limit: PAGE_LIMIT._20,
    });

    getBankAccounts(params)
      .then(getJson)
      .then((res) => {
        const { data, meta } = res;
        setBankAccounts(data);
        setCurrentPage(meta.current_page + 1);
      })
      .catch(
        /* istanbul ignore next */ (err) => {
          snackbar.error(err.toString());
          setError && setError(true);
        }
      )
      .finally(() => setIsBankAccountsLoading(false));
  };

  /* istanbul ignore next */
  const fetchData = useCallback((): void => {
    const params = prepareQueryParams("", {
      limit: PAGE_LIMIT._20,
      page: currentPage,
    });

    getBankAccounts(params)
      .then(getJson)
      .then((res) => {
        const { data, meta } = res;
        setBankAccounts((post) => [...post, ...data]);

        if (currentPage > meta.last_page) return;
        setCurrentPage(meta.current_page + 1);

        setHasMore(currentPage < meta.last_page);
      })
      .catch(
        /* istanbul ignore next */ (err) => {
          snackbar.error(err.toString());
          setError && setError(true);
        }
      )
      .finally(() => setIsBankAccountsLoading(false));
  }, [currentPage]);

  const getBankAccountDetails = async () => {
    const response = await getBankAccount(id);
    const bankDetails = await response.json();
    setSelectedBankAccount(bankDetails?.data);
  };

  const handleOnBankSelect = (bankConnection: BankConnection): void =>
    setBankConnection(bankConnection);

  const handleOnConnectButtonClick = (): void => {
    setIsWebFormModalOpen(true);
    setIsBankConnectionModalOpen(false);
  };
  /* istanbul ignore next */
  const handleOnWebFormFail = (): void => setBankConnection(null);
  /* istanbul ignore next */
  const handleOnWebFormAbort = (): void => setBankConnection(null);
  /* istanbul ignore next */
  const handleOnWebFormMounted = (): void => {
    if (!isNodeEnv(NODE_ENVIRONMENTS.TEST))
      console.log("handleOnWebFormMounted");
  };
  /* istanbul ignore next */
  const handleOnWebFormLoadError = (): void => {
    if (!isNodeEnv(NODE_ENVIRONMENTS.TEST))
      console.log("handleOnWebFormComplete");
  };
  /* istanbul ignore next */
  const handleWebFormModalClose = (): void => {};
  /* istanbul ignore next */
  const handleOnWebFormComplete = (): void => {
    sync()
      .then(getJson)
      .then(() => snackbar.success(t("snackbar.imported")))
      .catch((err) => snackbar.error(err.toString()))
      .finally(() => setBankConnection(null));
  };

  const handleBankAccountClick = (bankAccount: BEBankAccount): void => {
    setSelectedBankAccount(bankAccount);
    !useOnWidget && navigate(route("bank-account.all-sales", bankAccount.id));
  };

  const handleOnManagePropertiesButtonClick = (): void =>
    setIsAttachPropertyModalOpen(true);

  const handleOnAttachPropertyModalClose = (): void => {
    setIsAttachPropertyModalOpen(false);
  };

  const handleOnBankConnectionModalClose = (): void => {
    setIsBankConnectionModalOpen(false);
    setBankConnection(null);
  };

  const handleLinkBankAccountButtonClick = (): void => {
    setIsBankConnectionModalOpen(true);
    setBankConnection(null);
  };

  const handleOnCreate = (): void => {
    setIsCreateBankAccountButtonDisabled(true);
    createBankAccount({
      bank_name: "Default bank name",
    })
      .then(getJson)
      .then(() => {
        fetchBankAccounts();
        snackbar.success("Created");
      })
      .catch(/* istanbul ignore next */ (err) => snackbar.error(err.toString()))
      .finally(() => setIsCreateBankAccountButtonDisabled(false));
  };

  const handleOnDelete = (): void => {
    /* istanbul ignore next */
    if (!selectedBankAccount) return;
    deleteBankAccount(selectedBankAccount?.id)
      .then(getJson)
      .then(() => {
        snackbar.success("Deleted");
        setSelectedBankAccount(undefined);
        !useOnWidget && navigate(route("bank-accounts"));
        fetchBankAccounts();
      })
      .catch(
        /* istanbul ignore next */ (err) => snackbar.error(err.toString())
      );
  };

  const handleOnSave = (bankAccount: FEBankAccount): void => {
    updateBankAccount(bankAccount)
      .then(getJson)
      .then(() => {
        snackbar.success(t("snackbar.updated"));
        fetchBankAccounts();
      })
      .catch(
        /* istanbul ignore next */ (err) => snackbar.error(err.toString())
      );
  };

  useEffect(() => {
    id && getBankAccountDetails();
  }, []);

  const isMounted = useIsMounted();

  return (
    <>
      <Paper sx={{ height: "100%" }}>
        <Grid container sx={{ height: "100%" }}>
          <BankAccountList
            onLinkBankAccountButtonClick={handleLinkBankAccountButtonClick}
            isCreateBankAccountButtonDisabled={
              isCreateBankAccountButtonDisabled
            }
            onCreate={handleOnCreate}
            isBankAccountsLoading={isBankAccountsLoading}
            bankAccounts={bankAccounts}
            onBankAccountClick={handleBankAccountClick}
            useOnWidget={useOnWidget}
            selectedBankAccount={selectedBankAccount}
            onFetchMore={fetchData}
            hasMore={hasMore}
          />
          <Grid item md={9} lg={9} sx={{ pl: 2 }}>
            <BankAccountsPayments container direction={"column"}>
              <Grid item>
                {selectedBankAccount && (
                  <ToolBar
                    bankAccount={selectedBankAccount}
                    onDelete={handleOnDelete}
                    onSave={handleOnSave}
                    onManagePropertiesButtonClick={
                      handleOnManagePropertiesButtonClick
                    }
                    fetchBankPayments={fetchBankPayments}
                    setErrorTableOnSync={setErrorTableOnSync}
                    useOnWidget={useOnWidget}
                  />
                )}
                {selectedBankAccount && (
                  <Payments
                    setFetchBankPayments={setFetchBankPayments}
                    errorTableOnSync={errorTableOnSync}
                    properties={
                      selectedBankAccount?.properties ??
                      /* istanbul ignore next */ []
                    }
                    useOnWidget={useOnWidget}
                    selectedBankAccount={selectedBankAccount}
                  />
                )}
              </Grid>
            </BankAccountsPayments>
          </Grid>
        </Grid>
      </Paper>

      {bankConnection && (
        <FinApiWebFormModal
          isOpen={isWebFormModalOpen}
          token={bankConnection.id}
          onClose={handleWebFormModalClose}
          onFail={handleOnWebFormFail}
          onLoadError={handleOnWebFormLoadError}
          onWebFormMounted={handleOnWebFormMounted}
          onAbort={handleOnWebFormAbort}
          onComplete={handleOnWebFormComplete}
        />
      )}
      <BankConnectionModal
        isOpen={isBankConnectionModalOpen}
        onClose={handleOnBankConnectionModalClose}
        isConnectButtonDisabled={!bankConnection}
        handleOnBankSelect={handleOnBankSelect}
        onConnect={handleOnConnectButtonClick}
      />
      {selectedBankAccount && (
        <AttachPropertyToBankAccount
          isModalOpen={isAttachPropertyModalOpen}
          onCloseClick={() => handleOnAttachPropertyModalClose()}
        />
      )}
      {Snackbar}
    </>
  );
};

export default BankAccountPage;
