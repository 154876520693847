import React, { ReactElement } from "react";
import { File as BEFile } from "../../types/be/file";
import { Box } from "@mui/material";
/* istanbul ignore next */
const Video = ({
  video,
  maxWidth,
  height,
  controls,
}: {
  video: BEFile;
  maxWidth: number;
  height: number;
  controls: boolean;
}): ReactElement => {
  const handleMouseEvent = (
    e: React.MouseEvent<HTMLVideoElement>,
    status: boolean
  ): void => {
    if (!controls) {
      if (status) {
        /*  @ts-ignore */
        e.target?.play();
      } else {
        /*  @ts-ignore */
        e.target?.pause();
      }
    }
  };
  return (
    <>
      <Box
        component="video"
        sx={{
          height: height,
          display: "block",
          maxWidth: maxWidth,
          overflow: "hidden",
          width: "100%",
          borderRadius: "15px",
        }}
        controls={controls}
        src={video.url}
        onMouseOver={(e: React.MouseEvent<HTMLVideoElement>) =>
          handleMouseEvent(e, true)
        }
        onMouseOut={(e: React.MouseEvent<HTMLVideoElement>) =>
          handleMouseEvent(e, false)
        }
      />
    </>
  );
};

export default Video;
