import { TableCell } from "../../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "user",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "transactionManagement.loi.transactionManager",
  },
  {
    id: "object_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "transactionManagement.loi.objectName",
  },
  {
    id: "send_date",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "transactionManagement.loi.date",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "companyOwnership.createdDate",
  },
  {
    id: "file_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "transactionManagement.loi.pdf",
  },
];
