import { TFunction } from "i18next";
import { TableCell } from "../../../../types/table";
import { CreateUnitForm } from "./types";
import { FormikHelpers } from "formik";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../constants";
import { createMaintenanceUnit } from "../../../../api/maintenance";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "name",
    align: "left",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.maintenance.description",
    align: "left",
  },
  {
    id: "resolved",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.maintenance.resolved",
    align: "left",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];

export const handleCreateUnit = (
  t: TFunction<"translation", undefined, "translation">,
  snackbar: {
    success: (title: string) => void;
    error: (title: string) => void;
  },
  afterSuccess: () => void
) => {
  return async (
    values: CreateUnitForm,
    { setStatus, setSubmitting }: FormikHelpers<CreateUnitForm>
  ): Promise<void> => {
    const response = await createMaintenanceUnit(values);
    const json = await response.json();

    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      snackbar.success(t("property.maintenance.unitCreatedSuccessfully"));
      afterSuccess();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
    } else {
      snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
    }

    setSubmitting(false);
  };
};
