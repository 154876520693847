import React from "react";
import AuthGuard from "../../components/guards/AuthGuard";
import InboxCreate from "../../components/inbox/create";
import InboxList from "../../components/inbox/list";
import DashboardLayout from "../../layouts/dashboard";
import { PermissionsCreate, PermissionsRead } from "../../constants";
import AccessGuard from "../../components/guards/AccessGuard";

export default {
  path: "/:client/inbox",
  element: (
    <AuthGuard clientModuleName="inbox">
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: (
        <AccessGuard permissions={[PermissionsRead.INBOX]}>
          <InboxList />
        </AccessGuard>
      ),
    },
    {
      path: ":status",
      element: (
        <AccessGuard permissions={[PermissionsRead.INBOX]}>
          <InboxList />
        </AccessGuard>
      ),
    },
    {
      path: "/:client/inbox/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INBOX]}>
          <InboxCreate />
        </AccessGuard>
      ),
    },
  ],
};
