import React, { ReactElement } from "react";
import { DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import { ListDialogProps } from "./types";
import ContractList from "../properties/contracts/list/ContractList";
import { DialogContent } from "@mui/material";
import { DialogContainWrapper, DialogTitleContainer } from "./styled";
import CloseButton from "../../ui/dialog/CloseButton";

const ContractListDialog = ({
  visible,
  toggleListModal,
}: ListDialogProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth open={visible} onClose={toggleListModal} maxWidth="xl">
      <DialogTitle sx={{ pt: 0, pb: 0 }}>
        <DialogTitleContainer>
          <Typography
            variant="h6"
            component="div"
            justifyContent="space-around"
            width="100%"
          >
            {t("home.allContracts")}
          </Typography>
          <CloseButton onClick={toggleListModal} />
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        <DialogContainWrapper>
          <ContractList useOnWidget={true} />
        </DialogContainWrapper>
      </DialogContent>
    </Dialog>
  );
};

export default ContractListDialog;
