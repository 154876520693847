import { FILTER_TYPES, itemsListHeadCells } from "./utils";
import Table from "../../../ui/table/Table";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { IdPropType } from "../../../types/models";
import { User } from "../../../types/be/user";
import useTable from "../../../ui/table/useTable";
import { deleteUser, getUsers } from "../../../api/users";
import { Avatar, Grid, MenuItem, TableCell, TextField } from "@mui/material";
import { NavLink } from "react-router-dom";
import { route } from "../../../utils/url";
import { Search as SearchIcon } from "react-feather";
import useIsMounted from "../../../hooks/useIsMounted";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  DeleteIcon,
  EditIcon,
  Input,
  Search,
  SearchIconWrapper,
} from "../../../ui/table/styled";
import { handleServerError } from "../../../utils/http";
import { Status } from "./styled";
import { ProfilePhotoContainer } from "../create/styled";
import { prepareQueryParams } from "../../../utils/common";
import { StyledButton } from "../../../ui/button/styled";
import {
  PermissionsCreate,
  PermissionsDelete,
  PermissionsUpdate,
} from "../../../constants";
import useAccessControl from "../../../hooks/useAccessControl";

const UsersList = (): ReactElement => {
  const navigate = useNavigate();
  const { can } = useAccessControl();
  const { t } = useTranslation();
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleDeleteLastPageData,
  } = useTable<User>({ loading: true });

  const [filterType, setFilterType] = useState(FILTER_TYPES?.[1]?.value);
  const [deleteUserName, setDeleteUserName] = useState("");

  useEffect(() => {
    if (isMounted()) fetchUsers();
  }, [queryParams, filterType]);

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    const res = await getUsers(
      prepareQueryParams(queryParams, {
        is_active: filterType === "-1" ? "" : filterType,
      })
    );
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const resJson = await res.json();
      setTotal(resJson.meta.total);
      setData(resJson.data);
      setIsLoading(false);
    }
  }, [queryParams, setIsLoading, setTotal, setData, setIsLoading, filterType]);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteUser(rowToDelete);
    handleDeleteLastPageData(fetchUsers);
    handleConfirmToRemoveModalClose();
  }, [rowToDelete, deleteUser, fetchUsers, handleConfirmToRemoveModalClose]);

  const handleEditClick = useCallback(
    ({ id }: IdPropType): void => navigate(`edit/${id}`),
    []
  );

  const onDeleteIconClick = (row: User): void => {
    setDeleteUserName(`${row.first_name} ${row.last_name}`);
    !row?.has_relation && openConfirmToRemoveModal(row);
  };

  const renderRow = (row: User): ReactElement => {
    const profilePhoto = row?.avatar_file?.url;
    return (
      <>
        <TableCell align="left">
          {!profilePhoto ? (
            <ProfilePhotoContainer>
              <Avatar
                sx={{
                  bgcolor: "grey",
                  textTransform: "uppercase",
                }}
              >{`${row.first_name[0]}${row.last_name[0]}`}</Avatar>
            </ProfilePhotoContainer>
          ) : (
            /* istanbul ignore next */ <ProfilePhotoContainer>
              <Avatar src={profilePhoto} />
            </ProfilePhotoContainer>
          )}
        </TableCell>
        <TableCell padding="none">{row.first_name}</TableCell>
        <TableCell align="left">{row.last_name}</TableCell>
        <TableCell align="left">{row.email}</TableCell>
        <TableCell align="left">{row.role?.name}</TableCell>
        <TableCell align="center">
          <div>{<Status type={row.is_active ? "success" : "error"} />}</div>
        </TableCell>
        <TableCell
          align="right"
          onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
            handleCellClick(e)
          }
          style={{ whiteSpace: "nowrap" }}
        >
          {can(PermissionsUpdate.USER_MANAGEMENT) && (
            <EditIcon
              role={"editIconRole"}
              onClick={(): void => handleEditClick(row)}
              size={18}
            />
          )}
          {can(PermissionsDelete.USER_MANAGEMENT) && (
            <DeleteIcon
              onClick={(): void => onDeleteIconClick(row)}
              size={20}
              data-testid="delete-icon"
              className={
                row?.has_relation ? /* istanbul ignore next */ "disabled" : ""
              }
            />
          )}
        </TableCell>
      </>
    );
  };

  const TableToolbar = (
    <>
      <Grid container sx={{ pt: 5, mb: 2 }}>
        <Grid item mr={2}>
          {can(PermissionsCreate.USER_MANAGEMENT) && (
            <StyledButton
              data-testid={"dataItems-link"}
              component={NavLink}
              to={route(`users.create`)}
              size="small"
              color="success"
              variant="contained"
            >
              {t("usersList.createUserTitle")}
            </StyledButton>
          )}
        </Grid>
        <Grid item xs={3} lg={2} sm={6}>
          <TextField
            value={filterType}
            onChange={(event) => setFilterType(event?.target?.value)}
            select
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              "aria-label": "filter-select",
            }}
          >
            {FILTER_TYPES?.map((filter) => (
              <MenuItem key={filter.value} value={filter.value}>
                {t(filter.name)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs />
        <Grid item>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input placeholder={t("search")} onChange={handleTableSearch} />
          </Search>
        </Grid>
      </Grid>
    </>
  );

  const isMounted = useIsMounted();

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onRowRemove={handleRowRemove}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        noDataIsAvailablePlaceholder={t("table.noUsersAreAvailable")}
        confirmationText={t("usersList.deleteUserTitle", {
          userName: deleteUserName,
        })}
      />
    </>
  );
};

export default UsersList;
