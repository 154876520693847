import React from "react";
import AuthGuard from "../../components/guards/AuthGuard";
import DashboardLayout from "../../layouts/dashboard";
import InvoiceList from "../../components/properties/invoices/list/InvoiceList";
import InvoiceCreate from "../../components/properties/invoices/create/InvoiceCreate";
import InvoiceEdit from "../../components/properties/invoices/edit/InvoiceEdit";
import InvoiceShow from "../../components/properties/invoices/show/InvoiceShow";
import InsuranceCreate from "../../components/properties/insurances/create";
import ContractList from "../../components/properties/contracts/list/ContractList";
import ContractCreate from "../../components/properties/contracts/create/ContractCreate";
import ContractEdit from "../../components/properties/contracts/edit/ContractEdit";
import ContractShow from "../../components/properties/contracts/show/ContractShow";
import TopicList from "../../components/properties/offers/list/topicList";
import OfferCreate from "../../components/properties/offers/create";
import OfferEdit from "../../components/properties/offers/edit/OfferEdit";
import OfferShow from "../../components/properties/offers/show/OfferShow";
import InsuranceTopicList from "../../components/properties/insurances/list/topicList";
import InsuranceEdit from "../../components/properties/insurances/edit/EditInsurance";
import AccessGuard from "../../components/guards/AccessGuard";
import {
  PermissionsCreate,
  PermissionsRead,
  PermissionsUpdate,
} from "../../constants";
import ReminderList from "../../components/properties/invoices/reminder/list";
import InsuranceDetails from "../../components/properties/insurances/show";
import ReminderCreate from "../../components/properties/invoices/reminder/create";
import OutgoingInvoiceCreate from "../../components/properties/outgoingInvoices/create";
import OutgoingInvoiceShow from "../../components/properties/outgoingInvoices/show";
import OutgoingInvoiceList from "../../components/properties/outgoingInvoices/list";
import OutgoingInvoiceEdit from "../../components/properties/outgoingInvoices/edit";
import CreateDateVInvoice from "../../components/properties/invoices/createDateVInvoice";
import ShowDateVInvoice from "../../components/properties/invoices/showDateVInvoice";
import EditDateVInvoice from "../../components/properties/invoices/editDateVInvoice";

export default {
  path: "/:client/document-release",

  element: (
    <AuthGuard clientModuleName="document-release">
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "invoices",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <InvoiceList />
        </AccessGuard>
      ),
    },
    {
      path: "invoices/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <InvoiceList />
        </AccessGuard>
      ),
    },
    {
      path: "invoices/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INVOICE]}>
          <InvoiceCreate />
        </AccessGuard>
      ),
    },
    {
      path: "invoices/datev/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.DATEV_INVOICE]}>
          <CreateDateVInvoice />
        </AccessGuard>
      ),
    },
    {
      path: "invoices/datev/:invoiceId/show",
      element: (
        <AccessGuard permissions={[PermissionsRead.DATEV_INVOICE]}>
          <ShowDateVInvoice />
        </AccessGuard>
      ),
    },
    {
      path: "invoices/datev/:invoiceId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.DATEV_INVOICE]}>
          <EditDateVInvoice />
        </AccessGuard>
      ),
    },
    {
      path: "invoices/:invoiceId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.INVOICE]}>
          <InvoiceEdit />
        </AccessGuard>
      ),
    },
    {
      path: "invoices/:invoiceId/show",
      element: <InvoiceShow />,
    },
    {
      path: "invoices/reminders",
      children: [
        {
          path: "create",
          element: (
            <AccessGuard permissions={[PermissionsCreate.INVOICE]}>
              <ReminderCreate />
            </AccessGuard>
          ),
        },
        {
          path: "",
          element: (
            <AccessGuard permissions={[PermissionsRead.INVOICE]}>
              <ReminderList />
            </AccessGuard>
          ),
        },
        {
          path: ":status",
          element: (
            <AccessGuard permissions={[PermissionsRead.INVOICE]}>
              <ReminderList />
            </AccessGuard>
          ),
        },
      ],
    },
    {
      path: "contracts",
      element: (
        <AccessGuard permissions={[PermissionsRead.CONTRACT]}>
          <ContractList />
        </AccessGuard>
      ),
    },
    {
      path: "contracts/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.CONTRACT]}>
          <ContractList />
        </AccessGuard>
      ),
    },
    {
      path: "contracts/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.CONTRACT]}>
          <ContractCreate />
        </AccessGuard>
      ),
    },
    {
      path: "contracts/:contractId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.CONTRACT]}>
          <ContractEdit />
        </AccessGuard>
      ),
    },
    {
      path: "contracts/:contractId/show",
      element: <ContractShow />,
    },
    {
      path: "offers",
      element: (
        <AccessGuard permissions={[PermissionsRead.OFFER]}>
          <TopicList />
        </AccessGuard>
      ),
    },
    {
      path: "offers/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.OFFER]}>
          <TopicList />
        </AccessGuard>
      ),
    },
    {
      path: "offers/:topicId/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.OFFER]}>
          <OfferCreate />
        </AccessGuard>
      ),
    },
    {
      path: "offers/:offerId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.OFFER]}>
          <OfferEdit />
        </AccessGuard>
      ),
    },
    {
      path: "offers/:offerId/show",
      element: <OfferShow />,
    },
    {
      path: "insurances",
      element: (
        <AccessGuard permissions={[PermissionsRead.INSURANCE]}>
          <InsuranceTopicList />
        </AccessGuard>
      ),
    },
    {
      path: "insurances/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.INSURANCE]}>
          <InsuranceTopicList />
        </AccessGuard>
      ),
    },
    {
      path: "insurances/:type/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.INSURANCE]}>
          <InsuranceTopicList />
        </AccessGuard>
      ),
    },
    {
      path: "insurances/:insuranceId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.INSURANCE]}>
          <InsuranceEdit />
        </AccessGuard>
      ),
    },
    {
      path: "insurances/:insuranceId/show",
      element: <InsuranceDetails />,
    },
    {
      path: "insurances/:topicId/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INSURANCE]}>
          <InsuranceCreate />
        </AccessGuard>
      ),
    },
    {
      path: "outgoing-invoices",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <OutgoingInvoiceList />
        </AccessGuard>
      ),
    },
    {
      path: "outgoing-invoices/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INVOICE]}>
          <OutgoingInvoiceCreate />
        </AccessGuard>
      ),
    },
    {
      path: "outgoing-invoices/:invoiceId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.INVOICE]}>
          <OutgoingInvoiceEdit />
        </AccessGuard>
      ),
    },
    {
      path: "outgoing-invoices/:invoiceId/show",
      element: <OutgoingInvoiceShow />,
    },
  ],
};
