import { FormikProps } from "formik";
import { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { FetchFlags, FetchState } from "../../../hooks/useFetch";
import { Property as BEProperty, Property } from "../../../types/be/property";
import {
  Company as BECompany,
  Company,
  User as BEUserModel,
  User,
} from "../../../types/models";

export interface TabPanelProps {
  children?: ReactNode;
  index: ObjectDataSheetTabs;
  value: ObjectDataSheetTabs;
}

export interface TabAreaControls {
  id: string;
  "aria-controls": string;
}

export interface TabPanelItemProps {
  id?: string;
  setDisabled: Dispatch<SetStateAction<boolean>>;
}

export interface TabPanels {
  Component: FC<TabPanelItemProps>;
  index: ObjectDataSheetTabs;
}

export enum ObjectDataSheetTabs {
  GENERAL_INFO = "general-info",
  PROPERTY_MANAGEMENT = "property-management",
  BANK = "bank",
  ATTACHMENTS = "attachments",
}

export interface ObjectDataSheetContextType {
  useFetchProperty: FetchState<BEProperty> & FetchFlags<BEProperty>;
  useFetchCaretakers: FetchState<BEUserModel[]> & FetchFlags<BEUserModel[]>;
  useFetchAllAssetManagers: FetchState<BEUserModel[]> &
    FetchFlags<BEUserModel[]>;
  useFetchCompanies: FetchState<BECompany[]> & FetchFlags<BECompany[]>;
  useFetchUsers: FetchState<BEUserModel[]> & FetchFlags<BEUserModel[]>;
  propertyBreadcrumbs: BEProperty | null;
  isPropertyBreadcrumbsLoading: boolean;
  handleFetchPropertyBreadcrumbs: () => Promise<void>;
}

export interface GeneralInformationAutocompleteProps
  extends Pick<
    FormikProps<Property>,
    "values" | "setFieldValue" | "handleBlur" | "touched" | "errors"
  > {
  assetManagers: User[];
  size?: "small" | "medium";
}

export interface PropertyManagementAutocompleteProps
  extends Pick<
    FormikProps<Property>,
    "values" | "setFieldValue" | "handleBlur" | "touched" | "errors"
  > {
  companies: Company[];
  size?: "small" | "medium";
}
