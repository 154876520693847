import { Formik } from "formik";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import useValidation from "../../../../hooks/useValidation";
import Form from "../Form2";
import {
  ContactIdDict,
  FormDict,
  FormikFormProps,
  PagesDict,
  PreparedDict,
} from "../types";
import { handleAllInvoicesFormSubmit, prepareFormKey } from "../utils";
import * as Yup from "yup";
import { FINAL_RELEASER_STATUS_ID } from "../../../../constants";
import { Contact } from "../../../contacts/edit/types";
import { Button, Grid, TablePagination } from "@mui/material";
import CutomButton from "../../../../ui/button/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DeleteConfirmationModal from "../../../../ui/table/DeleteConfirmationModal";
import { CloseButton } from "./styled";
import { route } from "../../../../utils/url";
import { useLocation } from "react-router";
/* istanbul ignore next */
const FormikForm = ({
  forms: formsParam,
  page,
  id,
  setFormMessage,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  numberOfPages,
  setSendCredentialsSnackbar,
  setNumberOfPages,
  inboxData,
  setForms: setFormsParam,
  invoicePeriod,
}: FormikFormProps): ReactElement => {
  const { t } = useTranslation();

  const [forms, setForms] = useState<FormDict>(formsParam);
  const [fileName, setFileName] = useState("");
  const [preparedDict, setPreparedDict] = useState<PreparedDict>({});
  const [contactIdDict, setContactIdDict] = useState<ContactIdDict>({});
  const [toggleSubmit, setToggleSubmit] = useState(false);
  const [pagesDict, setPagesDict] = useState<PagesDict>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    number,
    invoice_date: date,
    file_id,
    invoice_provider,
    invoiceAmount,
    apportionable_percent,
    property_id,
    currency_code,
    repeat_from,
    repeat_to,
    repeat_day,
    repetition_period_id,
  } = useValidation();
  const pageFormKey = prepareFormKey(Number(pagesDict[page]));

  useEffect(() => {
    setForms(formsParam);
  }, [formsParam]);

  useEffect(() => {
    setNumberOfPages(Object.keys(forms).length);
  }, [Object.keys(forms).length]);

  useEffect(() => {
    const auxPagesDict: PagesDict = {};
    Object.values(forms)
      .map(({ file_id }) => file_id)
      .forEach((el, i) => {
        auxPagesDict[i] = String(el);
      });
    setPagesDict({ ...auxPagesDict });
  }, [Object.keys(forms).length]);

  const changeFormState = useCallback(
    (field: string, value: unknown) => {
      setForms((prevState) => ({
        ...prevState,
        [pageFormKey]: {
          ...prevState[pageFormKey],
          [field]: value,
        },
      }));
    },
    [setForms, pageFormKey]
  );

  const changePreparedDictState = useCallback(
    (hasErrors: boolean): void => {
      setPreparedDict((prevState: any) => ({
        ...prevState,
        [pageFormKey]: hasErrors,
      }));
    },
    [setPreparedDict, pageFormKey]
  );

  const changeContactIdDictState = useCallback(
    (contact: Contact) => {
      setContactIdDict((prevState: ContactIdDict) => ({
        ...prevState,
        [pageFormKey]: contact,
      }));
    },
    [setContactIdDict, pageFormKey]
  );

  const disabled = !(
    Object.values(forms).filter((el) => el).length ===
    Object.values(preparedDict).filter((el) => el).length
  );

  const handleConfirmToRemoveTopicModalClose = (): void =>
    setIsModalOpen(false);

  const handleInvoiceRemove = async (): Promise<void> => {
    const auxForms = { ...forms };
    const auxPreparedDict = { ...preparedDict };

    delete auxForms[pageFormKey];
    delete auxPreparedDict[pageFormKey];

    setPage(0);
    setForms({ ...auxForms });
    setFormsParam({ ...auxForms });
    setPreparedDict({ ...auxPreparedDict });
    handleConfirmToRemoveTopicModalClose();
    setSendCredentialsSnackbar({
      text: t("fileManagement.itemWasSuccessfullyDeleted"),
      visible: true,
      color: "success",
    });
  };

  return (
    <>
      {forms[pageFormKey] && (
        <>
          <CloseButton
            onClick={(): void => {
              setIsModalOpen(true);
            }}
          />
          <Formik
            initialValues={forms[pageFormKey]}
            validationSchema={Yup.object().shape({
              number,
              date,
              file_id,
              contact_id: invoice_provider,
              amount: invoiceAmount,
              repeat_from,
              repeat_to,
              repeat_day,
              repetition_period_id,
              apportionable_percent,
              property_id,
              currency_code,
            })}
            enableReinitialize
            onSubmit={() => {}}
          >
            {(props): React.ReactElement => (
              <Form
                propertyId={id}
                frStatusId={FINAL_RELEASER_STATUS_ID}
                fileName={fileName}
                setFileName={setFileName}
                setOuterState={changeFormState}
                toggleSubmit={toggleSubmit}
                setPreparedDict={changePreparedDictState}
                setContactIdDict={changeContactIdDictState}
                contactIdDict={contactIdDict}
                inboxData={inboxData}
                invoicePeriod={invoicePeriod}
                {...props}
              />
            )}
          </Formik>
          <Grid item xs={12}>
            <Grid container display="flex" justifyContent="space-between">
              <Grid item>
                <Button
                  type="button"
                  color="primary"
                  variant="text"
                  disabled={isSubmitting}
                  title={t("cancel")}
                  size="large"
                  onClick={() =>
                    doesAnyHistoryEntryExist
                      ? navigate(-1)
                      : id
                      ? navigate(route("properties.invoices", id))
                      : navigate(route("invoices"))
                  }
                >
                  {t("cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="large"
                  color="error"
                  variant="contained"
                  onClick={(): void => {
                    setIsModalOpen(true);
                  }}
                >
                  {t("documentRelease.invoice.delete")}
                </Button>
                <CutomButton
                  testId="create-invoice"
                  title={t("documentRelease.invoice.createAll")}
                  type="button"
                  size="large"
                  color="success"
                  sx={{ ml: "0.5rem" }}
                  disabled={isSubmitting || disabled}
                  isLoading={isSubmitting}
                  onClick={() => {
                    setToggleSubmit((prevState) => !prevState);
                    handleAllInvoicesFormSubmit({
                      data: Object.values(forms),
                      setFormMessage,
                      id,
                      edit: false,
                      navigate,
                      setIsSubmitting,
                      inboxData,
                      setSendCredentialsSnackbar,
                      t,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TablePagination
              sx={{ mr: -4, mt: 4 }}
              data-testid={"table-pagination"}
              rowsPerPageOptions={[1]}
              component="div"
              count={Object.keys(forms).length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={t("documentRelease.invoice.invoicesPerPage")}
              page={page}
              onPageChange={(_: unknown, newPage: number): void => {
                setPage(newPage);
              }}
              onRowsPerPageChange={(
                event: React.ChangeEvent<HTMLInputElement>
              ): void => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
              nextIconButtonProps={{
                disabled:
                  !preparedDict[pageFormKey] || page >= numberOfPages - 1,
              }}
            />
          </Grid>
          <DeleteConfirmationModal
            isConfirmToRemoveModalOpen={isModalOpen}
            handleConfirmToRemoveModalClose={
              handleConfirmToRemoveTopicModalClose
            }
            onRowRemove={handleInvoiceRemove}
            confirmationText={t("documentRelease.invoice.deleteConfirmation")}
          />
        </>
      )}
    </>
  );
};

export default FormikForm;
