import styled from "styled-components/macro";
import { Grid, Avatar as MuiAvatar } from "@mui/material";
import { grey } from "@mui/material/colors";

/* istanbul ignore next */
export const CommentWrapper = styled(Grid)`
  padding: ${(props): string => props.theme.spacing(1)};
  margin-bottom: ${(props): string => props.theme.spacing(3)};
  border-bottom: 1px solid rgb(97, 97, 97);
`;

export const CommentBodyWrapper = styled(Grid)`
  padding-left: ${(props): string => props.theme.spacing(2)};
  padding-top: ${(props): string => props.theme.spacing(3)};
  padding-bottom: ${(props): string => props.theme.spacing(2)};
  * {
    margin-top: 0;
    margin-bottom: 0;
    word-break: break-all;
  }
  a {
    color: ${(props): string => props.theme.palette.primary.main};
  }
`;

export const CommentLeftBy = styled.span.attrs(() => ({}))`
  font-weight: 700;
  color: ${(props): string => props.theme.comments.textColor};
  cursor: pointer;
`;

export const CommentLeftAt = styled.span.attrs(() => ({}))`
  font-weight: 500;
  font-size: 10px;
  color: ${(props): string => props.theme.comments.textColor};
  margin-left: 10px;
`;
/* istanbul ignore next */
export const Avatar = styled(MuiAvatar)`
  height: ${(props): string => props.theme.spacing(8)};
  width: ${(props): string => props.theme.spacing(8)};
  margin-right: ${(props): string => props.theme.spacing(3)};
`;
/* istanbul ignore next */
export const AvatarDefault = styled.div`
  height: ${(props): string => props.theme.spacing(8)};
  width: ${(props): string => props.theme.spacing(8)};
  margin-right: ${(props): string => props.theme.spacing(3)};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  position: relative;
  border-radius: ${(props): string => props.theme.spacing(25)};
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  background-color: #fff5e9;
  border-color: #ffe0bd;
  color: #f98600;
`;
/* istanbul ignore next */
export const CommentScrollContainer = styled.div<{ $isPopup: boolean }>`
  height: ${(props) =>
    props.$isPopup ? "calc(100vh - 415px)" : "calc(100vh - 355px)"};
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background: ${(props): string => props.theme.scrollbar.horizontal.thumb};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(): string => grey[800]};
    border-radius: 10px;
  }
`;
