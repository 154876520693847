/* istanbul ignore file */
import styled from "styled-components";
import Box from "@mui/material/Box";

export const CarouselContainer = styled(Box)`
  line-height: 22px;
  font-size: 15px;
  margin: 0 auto;
  position: relative;
  &:hover .hover-visible {
    visibility: visible;
  }
`;

export const SliderNavigationContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  opacity: 0.6;
  visibility: hidden;
  left: 0;
`;

export const NoPicturesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 255px;

  p {
    font-weight: 500;
    position: absolute;
    top: 80%;
  }
`;
