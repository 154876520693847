import styled from "styled-components/macro";
import { Paper, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
/* istanbul ignore next */
export const DropZone = styled(Paper).attrs(
  (props: { $isDragActive: boolean; $isError: boolean }) => ({
    $isDragActive: props.$isDragActive || false,
    $isError: props.$isError || false,
  })
)`
  display: flex;
  flex-direction: column;
  justify-items: center;
  border-style: dashed;
  justify-content: center;
  border-width: 2px;
  transition: background 0.3s;
  opacity: ${(props): number => (props.$isDragActive ? 0.8 : 1)};
  border-color: ${(props): number =>
    props.$isError ? red[600] : props.theme.border};
  };
`;

export const DropZoneHintText = styled(Typography)``;
export const DropZoneErrorText = styled(Typography)`
  color: ${red[600]};
  font-size: 12px;
`;
