import styled from "styled-components/macro";
import { grey } from "@mui/material/colors";
import { Box as MuiBox } from "@mui/material";

import CloseButton from "../../ui/dialog/CloseButton";
import { green, red } from "@mui/material/colors";

export const Box = styled(MuiBox)<{
  additionalstyle: string;
}>`
  text-align: center;
  padding: ${(props): string => props.theme.spacing(5)};
  background: ${(props): string => props.theme.palette.background.paper};
  border: 2px dashed
    ${(props): string =>
      props.additionalstyle === "accept"
        ? green[500]
        : props.additionalstyle === "reject"
        ? red[500]
        : grey[400]};
  color: #bdbdbd;
  position: absolute;
  min-width: 30vw;
  margin: auto auto;
  height: 100vh;
  opacity: 0.85;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ErrorBox = styled(MuiBox)`
  color: ${red[500]};
`;

export const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const InfoBox = styled(MuiBox)`
  margin-top: -${(props): string => props.theme.spacing(25)};
`;
