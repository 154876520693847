import { FormikValues } from "formik/dist/types";
import { mapBackendValidationErrors } from "../../../../../utils/form";
import { FetchBanksProps, FormSubmitHandlerProps } from "./types";
import { fetchBankDataByIban } from "../../../../../api/iban";
import { updateBankData } from "../../../../../api/property";
import pick from "lodash/pick";
import { replaceNullOrUndefinedByEmptyString } from "../../../../../utils/common";
import { Dispatch, SetStateAction } from "react";
import { FetchState } from "../../../../../hooks/useFetch";
import { Property } from "../../../../../types/be/property";
import { Bank } from "../../../../../types/fe/property";

export const BANK_TYPES = {
  RENT: 1,
  LOAN: 2,
};

export const touchedInitState = {
  iban: false,
  contact_person: false,
};

export const EmptyBankForm: Bank = {
  iban: "",
  name: "",
  bic: "",
  code: "",
  type_id: null,
  zip: "",
  city: "",
  contact_person: "",
};

export const prepareBankBeforeForm = (data: Bank): Bank => ({
  ...data,
  ...pick({ ...replaceNullOrUndefinedByEmptyString(data) }, ["contact_person"]),
  type_id: data.type?.id,
});
/* istanbul ignore next */
export const handleUpdateBankFormSubmit = (
  id: string | undefined,
  { setFormMessage }: FormSubmitHandlerProps,
  setProperty: Dispatch<SetStateAction<FetchState<Property>>>,
  property: Property,
  data: Bank,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return async (
    values: Bank,
    { setStatus, setSubmitting, setTouched }: FormikValues
  ): Promise<void> => {
    const response = await updateBankData({
      ...values,
      property_id: id,
      type_id: data.type?.id || data.type_id,
    });
    const json = await response.json();

    if (response.ok) {
      const id = json.data.type.id;
      const data =
        id === BANK_TYPES.RENT
          ? { ...property, banks: { ...property.banks, rent: json.data } }
          : { ...property, banks: { ...property.banks, loan: json.data } };
      setProperty((prevState) => ({ ...prevState, data }));
      setStatus({
        success: true,
        errors: {},
      });
      setOpen(false);
      setTouched(touchedInitState);
    } else {
      setFormMessage({ type: "error", text: "errorSomethingWentWrong" });
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json),
      });
      setSubmitting(false);
    }
  };
};

export const fetchBanksData = async (
  bankIban: string,
  {
    rentBank,
    loanBank,
    setRentBank,
    setLoanBank,
    setIsRentIbanError,
    setIsLoanIbanError,
    setIsUpdatingRentBank,
    setIsUpdatingLoanBank,
    rentMode,
    setNewIbanCheckedRent,
    setNewIbanCheckedLoan,
    contact,
  }: FetchBanksProps
): Promise<void> => {
  if (rentMode) {
    setIsUpdatingRentBank(true);
  } else {
    setIsUpdatingLoanBank(true);
  }

  const response = await fetchBankDataByIban(bankIban);
  const json = await response.json();
  if (response.ok) {
    const { data } = json;
    if (rentMode) {
      setRentBank({
        ...rentBank,
        type_id: BANK_TYPES.RENT,
        iban: data.iban,
        name: data.name,
        bic: data.bic,
        code: data.code,
        zip: data.zip,
        city: data.city,
        contact_person: contact,
      });
      setIsUpdatingRentBank(false);
      setIsUpdatingLoanBank(false);
      setIsRentIbanError(false);
      setNewIbanCheckedRent(data.iban);
    }
    if (!rentMode) {
      setLoanBank({
        ...loanBank,
        type_id: BANK_TYPES.LOAN,
        iban: data.iban,
        name: data.name,
        bic: data.bic,
        code: data.code,
        zip: data.zip,
        city: data.city,
        contact_person: contact,
      });
      setIsUpdatingRentBank(false);
      setIsUpdatingLoanBank(false);
      setIsLoanIbanError(false);
      setNewIbanCheckedLoan(data.iban);
    }
  } else {
    if (rentMode) {
      setIsRentIbanError(true);
      setNewIbanCheckedRent("");
    } else {
      setIsLoanIbanError(true);
      setNewIbanCheckedLoan("");
    }
    setIsUpdatingRentBank(false);
    setIsUpdatingLoanBank(false);
  }
};
