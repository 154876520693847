import React, { ReactElement, useMemo } from "react";
import {
  StatusLeftByContainer,
  StatusLeftByItem,
  StatusLeftBy as StatusLeftByStyled,
} from "./styled";
import useAppSelector from "../../../../hooks/useAppSelector";
import { Tooltip } from "@mui/material";
import { formatTimestamp } from "../../../../utils/date";
import { StatusLeftByProps } from "./types";
/* istanbul ignore next */
const StatusLeftBy = ({
  first_name,
  last_name,
  email,
  align = "start",
  tooltip = true,
  created_at,
}: StatusLeftByProps): ReactElement => {
  const { _user } = useAppSelector((state) => state.user);
  const statusLeftBy = useMemo(() => {
    if (
      _user &&
      _user.first_name === first_name &&
      _user.last_name === last_name &&
      _user.email === email
    )
      return { full_name: "You", sort_name: "You" };
    else
      return {
        full_name: `${first_name} ${last_name}`,
        sort_name: `${first_name?.[0]}.${last_name?.[0]}.`,
      };
  }, [first_name, last_name, email]);
  // console.log("====>>>   statusLeftBy", statusLeftBy);
  return (
    <StatusLeftByContainer container justifyContent={align} mt={1}>
      <StatusLeftByItem item>
        <Tooltip
          title={
            tooltip
              ? statusLeftBy.full_name === "You"
                ? ""
                : statusLeftBy.full_name
              : ""
          }
          placement="top"
        >
          <StatusLeftByStyled>
            {statusLeftBy.sort_name}{" "}
            {created_at ? formatTimestamp(created_at) : null}
          </StatusLeftByStyled>
        </Tooltip>
      </StatusLeftByItem>
    </StatusLeftByContainer>
  );
};

export default StatusLeftBy;
