import { TableCell } from "../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "full_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "companyOwnership.user",
  },
  {
    id: "total",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "vacationRequests.totalVacationDays",
  },
];
