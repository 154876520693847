import http from "../utils/http";

export const addTag = async (
  contactId: string | number,
  tag: string
): Promise<Response> => {
  return await http.post(`/api/contacts/${contactId}/tags`, {
    title: tag,
    code: tag,
  });
};

export const deleteTag = async (id: number | string): Promise<Response> => {
  return await http.delete(`/api/tags/${id}`);
};
