import * as React from "react";
import { Navigate, useParams } from "react-router-dom";
import { isSessionExpired } from "../../utils/auth";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import { fetchClientBySlug } from "../../redux/slices/client/client";
import PageSkeleton from "../../ui/pageSkeleton";

interface AuthGuardType {
  children: React.ReactNode;
  clientModuleName?: string;
}
/* istanbul ignore next */
function AuthGuard({
  children,
  clientModuleName,
}: AuthGuardType): React.ReactElement {
  const dispatchStore = useAppDispatch();
  const { client } = useParams();
  const { _user } = useAppSelector((state) => state.user);
  const { _client } = useAppSelector((state) => state.client);
  const [isLoading, setIsLoading] = React.useState(true);

  /* istanbul ignore next */
  React.useEffect(() => {
    client &&
      dispatchStore(
        fetchClientBySlug({ client, callback: () => setIsLoading(false) })
      );
  }, [client]);

  if (isSessionExpired()) {
    window.localStorage.removeItem("tokenExpirationDate");
    window.localStorage.removeItem("accessToken");
    return (
      <Navigate
        to={`/${client}/auth/sign-in`}
        state={{ from: location.pathname }}
      />
    );
  }

  if (_user && _user.is_new_password) {
    return (
      <Navigate
        to={`/${client}/auth/sign-in`}
        state={{ from: location.pathname }}
      />
    );
  }

  if (
    clientModuleName &&
    !_client?.modules?.includes(clientModuleName) &&
    !isLoading
  ) {
    return <Navigate to={`/${client}/403`} />;
  }

  return <>{!isLoading ? children : <PageSkeleton />}</>;
}

export default AuthGuard;
