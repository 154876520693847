import React, { ReactElement, useCallback, useRef, useState } from "react";
import { Grid } from "@mui/material";
import Dropzone from "../../../../ui/dropzone";
import { Paper } from "../styled";
import { route } from "../../../../utils/url";
import { useTranslation } from "react-i18next";
import { getReleaseRoute } from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import useSnackbar from "../../../../ui/snackbar1/useSnackbar";
import {
  CURRENCIES,
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { getFile, getFileOCR } from "../../../../api/files";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import PdfViewer from "../../../../ui/pdfViewer";
import Form from "./Form";
import { Formik } from "formik";
import {
  createDateVInvoiceFormInitState,
  handleCreateDateVInvoice,
  handleMultiUploadFiles,
} from "./utils";
import useValidation from "../../../../hooks/useValidation";
import * as Yup from "yup";
import TabPageWrapper from "../../../../ui/tabPageWrapper";

/* istanbul ignore next */
const CreateDateVInvoice = (): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { Snackbar, snackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    property_id,
    datev_creditor_id,
    datev_due_date,
    apportionable_percent,
    datev_invoice_positions,
    repeat_from,
    repeat_to,
    repeat_day,
    repetition_period_id,
    has_creditor_contact,
    dateVInvoice_date,
  } = useValidation();
  const ref = useRef<HTMLObjectElement | null>(null);

  const [isDropzoneVisible, setIsDropzoneVisible] = useState(true);
  const [isDropzoneLoading, setIsDropzoneLoading] = useState(false);
  const [base64Dict, setBase64Dict] = useState<string>("");
  const [formMessage] = useState(FormMessageInItState);
  const [initialValues, setInitialValues] = useState(
    createDateVInvoiceFormInitState()
  );

  const afterSuccess = (): void => {
    navigate(getReleaseRoute("invoices", Number(id)));
  };

  const handleError = ({ message }: { message: string }): void => {
    snackbar.success(message || t(SOMETHING_WENT_WRONG_ERROR));
  };

  const handleSetBase64Dict = async (file_id: number): Promise<void> => {
    const responseGetFile = await getFile(file_id);
    const jsonGetFile = await responseGetFile.json();
    if (responseGetFile.status === HTTP_STATUS_CODES.OK) {
      const {
        data: { file },
      } = jsonGetFile;
      setBase64Dict(file);
    } else {
      handleError(jsonGetFile);
    }
  };

  const handleOsc = async (
    file_id: number,
    file_name: string
  ): Promise<void> => {
    const responseGetFileOCR = await getFileOCR(file_id);
    const jsonGetFileOCR = await responseGetFileOCR.json();
    if (responseGetFileOCR.status === HTTP_STATUS_CODES.OK) {
      const { data } = jsonGetFileOCR;
      setInitialValues({
        ...createDateVInvoiceFormInitState(),
        property_id: id || "",
        property: null,
        area_id: "",
        amount: data?.total,
        invoice_number: data?.invoice_number || "",
        date: data?.date,
        file_id,
        due_date: data?.due_date,
        currency_code: CURRENCIES.includes(data?.currency_code)
          ? data?.currency_code
          : "",
        file_name,
        iban: data?.vendor?.iban,
        bic: data?.vendor?.bank_swift,
      });
      setIsDropzoneVisible(false);
      setIsDropzoneLoading(false);
    } else {
      handleError(jsonGetFileOCR);
    }
    await handleSetBase64Dict(file_id);
  };

  const handleFile = useCallback(
    async <T extends File>(file: FileList | T[] | null): Promise<void> => {
      setIsDropzoneLoading(true);
      const filesData = await handleMultiUploadFiles(file, snackbar, t, id);
      filesData.forEach(async ({ id: file_id, name: file_name }) => {
        await handleOsc(file_id, file_name);
      });
      setIsDropzoneLoading(true);
    },
    []
  );

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("invoices", Number(id)),
      name: t("property.navLinks.invoices"),
    },
  ];

  return (
    <TabPageWrapper
      title={t("documentRelease.invoice.dateVInvoice.upload")}
      breadcrumbs={breadcrumbs}
      wrap={!id}
    >
      <Grid container justifyContent="center">
        {formMessage.text && (
          <FormAlert formMessage={formMessage} sx={{ marginBottom: "10px" }} />
        )}
        {isDropzoneVisible ? (
          <Paper sx={{ position: "relative" }}>
            <Grid
              container
              data-testid="dropzone-container"
              sx={{
                height: "100%",
                position: "relative",
                justifyContent: "center",
              }}
              onDragOver={() => {
                setIsDropzoneVisible(true);
              }}
            >
              <Grid item sm={6} xs={12}>
                <Dropzone
                  open={isDropzoneVisible}
                  onDrop={handleFile}
                  isLoading={isDropzoneLoading}
                  accept={{ "application/pdf": [".pdf"] }}
                />
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <>
            <Paper sx={{ position: "relative", mt: 4, ml: 4 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={3.7} ref={ref}>
                      <PdfViewer
                        file={{
                          base64: base64Dict,
                        }}
                        containerRef={ref}
                      />
                    </Grid>
                    <Grid item xs={8.3} pl={6}>
                      <Formik
                        initialValues={initialValues}
                        onSubmit={handleCreateDateVInvoice({
                          snackbar,
                          t,
                          afterSuccess,
                        })}
                        validationSchema={Yup.object().shape({
                          property_id,
                          datev_creditor_id,
                          due_date: datev_due_date,
                          apportionable_percent,
                          datev_invoice_positions,
                          repeat_from,
                          repeat_to,
                          repeat_day,
                          repetition_period_id,
                          has_creditor_contact,
                          date: dateVInvoice_date,
                        })}
                      >
                        {(props) => <Form {...props} snackbar={snackbar} />}
                      </Formik>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
        {Snackbar}
      </Grid>
    </TabPageWrapper>
  );
};

export default CreateDateVInvoice;
