import React, { ReactElement, useCallback, useContext } from "react";
import {
  DropzoneContainer,
  FileIconContainer,
  FileIconLoader,
  FolderContainer,
  FolderIconStyled,
  GridWithPadding,
  Tooltip,
  UploadFileIconStyled,
} from "../styled";
import { Box, Grid } from "@mui/material";
import { TextEllipsis } from "../../../styled";
import { FileManagementContext } from ".././contexts/FileManagementContext";
import {
  PageViewTypes,
  PreviewDialogVisibility,
  SET_IS_DIALOG_VISIBLE,
  SET_OPTIMIZED,
  SET_PRELOADING,
  SET_PREVIEW_FILE,
  SET_VISIBILITY,
} from "../types";
import Dropzone from "../../../ui/dropzone";
import { HOVER_DELAY } from "../../../constants";
import { useEffect } from "react";
import { handleFileDoubleClick } from "../utils";
import { FileTreeFile } from "../../../types/be.interfaces";
import { FileManagementFolderContainerContext } from "../contexts/FileManagementFolderContainerContext";
import FileManagementPreview from "./Preview";
import ListView from "./ListView";
/* istanbul ignore next */
const FileManagementFolderContainer = (): ReactElement => {
  const {
    memoedFolder: folder,
    layout,
    actionItemId,
    setActionItemId,
    actionItemType,
    setActionItemType,
    isDropzoneVisible,
    isDropzoneLoading,
    setIsDropzoneVisible,
    handleCloseDropzone,
    onRightClick,
    handleDoubleClick,
    handleFile,
  } = useContext(FileManagementContext);

  const {
    state: { isDialogVisible, previewFile, isFileLoading },
    dispatch,
  } = useContext(FileManagementFolderContainerContext);

  const handleOpenDialog = (): void => {
    dispatch({
      type: SET_IS_DIALOG_VISIBLE,
      payload: true,
    });
  };

  const handleCloseDialog = (): void => {
    dispatch({
      type: SET_IS_DIALOG_VISIBLE,
      payload: false,
    });
    dispatch({
      type: SET_VISIBILITY,
      payload: PreviewDialogVisibility.HIDDEN,
    });
    dispatch({
      type: SET_OPTIMIZED,
      payload: false,
    });
  };

  const handleDownloadFromPreview = useCallback(() => {
    previewFile?.url && window.open(previewFile?.url, "_blank");
  }, [previewFile]);

  useEffect(() => {
    dispatch({
      type: SET_PRELOADING,
      payload: isDialogVisible,
    });
  }, [isDialogVisible]);

  const handleFileClick = (file: FileTreeFile) => (): void => {
    dispatch({
      type: SET_PREVIEW_FILE,
      payload: { ...file, previewUrl: "" },
    });
    setActionItemId(file.id.toString());
    setActionItemType("file");
  };

  return (
    <Grid
      container
      data-testid="current-folder-container"
      sx={{ height: "100%", position: "relative" }}
      onDragOver={() => {
        setIsDropzoneVisible(true);
      }}
    >
      <DropzoneContainer item xs={12}>
        <Dropzone
          open={isDropzoneVisible}
          handleClose={handleCloseDropzone}
          onDrop={handleFile}
          isLoading={isDropzoneLoading}
        />
      </DropzoneContainer>
      {folder && (
        <FolderContainer item xs={12}>
          {layout === PageViewTypes.LIST ? (
            <ListView
              handleFileDoubleClick={handleFileDoubleClick}
              handleOpenDialog={handleOpenDialog}
            />
          ) : (
            <Grid container>
              {folder.children.map(({ id, name, can_remove }) => (
                <GridWithPadding item key={id} xs={2} md={3} xl={2}>
                  <Tooltip
                    title={name}
                    placement="bottom"
                    onClick={() => {
                      setActionItemId(id.toString());
                      setActionItemType("folder");
                    }}
                    onDoubleClick={handleDoubleClick(id.toString(), name)}
                    $actionItem={
                      parseInt(actionItemId!) === id &&
                      actionItemType === "folder"
                    }
                    onContextMenu={onRightClick(
                      id.toString(),
                      name,
                      "folder",
                      null,
                      can_remove
                    )}
                    enterDelay={HOVER_DELAY}
                    enterNextDelay={HOVER_DELAY}
                  >
                    <div>
                      <FolderIconStyled fontSize="large" />
                      <TextEllipsis w="110px" textAlign="center">
                        {name}
                      </TextEllipsis>
                    </div>
                  </Tooltip>
                </GridWithPadding>
              ))}
              {folder.files.map(({ id, name, url, has_relation }, i, arr) => (
                <GridWithPadding item key={id} xs={2} md={3} xl={2}>
                  <Tooltip
                    title={name}
                    placement="bottom"
                    onClick={handleFileClick(arr[i])}
                    onDoubleClick={handleFileDoubleClick({
                      file: arr[i],
                      dispatch,
                      handleOpenDialog,
                    })}
                    $actionItem={
                      parseInt(actionItemId!) === id &&
                      actionItemType === "file"
                    }
                    onContextMenu={onRightClick(
                      id.toString(),
                      name,
                      "file",
                      url,
                      true,
                      has_relation
                    )}
                    enterDelay={HOVER_DELAY}
                    enterNextDelay={HOVER_DELAY}
                  >
                    <Box>
                      <FileIconContainer>
                        {isFileLoading && previewFile?.id === id && (
                          <FileIconLoader
                            size={30}
                            color="inherit"
                            thickness={15}
                          />
                        )}
                        <UploadFileIconStyled fontSize="large" />
                      </FileIconContainer>
                      <TextEllipsis w="110px" textAlign="center">
                        {name}
                      </TextEllipsis>
                    </Box>
                  </Tooltip>
                </GridWithPadding>
              ))}
            </Grid>
          )}
        </FolderContainer>
      )}
      {/* istanbul ignore next */}
      <FileManagementPreview
        handleCloseDialog={handleCloseDialog}
        handleDownloadFromPreview={handleDownloadFromPreview}
      />
    </Grid>
  );
};

export default FileManagementFolderContainer;
