import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import Form from "./Form";
import { Stack } from "@mui/material";
import {
  BANK_TYPES,
  EmptyBankForm,
  fetchBanksData,
  handleUpdateBankFormSubmit,
  prepareBankBeforeForm,
} from "./utils";
import * as Yup from "yup";
import { ErrorBox } from "../generalInformation/styled";
import Loader from "../../../../Loader";
import useValidation from "../../../../../hooks/useValidation";
import { FormMessageInItState } from "../../../../../constants";
import FormAlert from "../../../../../ui/formAlert/FormAlert";
import { FormWrapper } from "../../../../../ui/formWrapper/styled";
import { TabPanelItemProps } from "../../types";
import { LoadingBox } from "../../styled";
import { ObjectDataSheetContext } from "../../ObjectDataSheetContexts";
import { Bank as FEBank } from "../../../../../types/fe/property";
import BankShow from "./Show";

const Bank = ({ id, setDisabled }: TabPanelItemProps): ReactElement => {
  const [isUpdatingRentBank, setIsUpdatingRentBank] = useState(false);
  const [isUpdatingLoanBank, setIsUpdatingLoanBank] = useState(false);
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [rentBank, setRentBank] = useState<FEBank | null>(null);
  const [loanBank, setLoanBank] = useState<FEBank | null>(null);
  const [isRentIbanError, setIsRentIbanError] = useState(false);
  const [isLoanIbanError, setIsLoanIbanError] = useState(false);
  const [isEditRent, setIsEditRent] = useState(false);
  const [isEditLoan, setIsEditLoan] = useState(false);
  const [newIbanCheckedRent, setNewIbanCheckedRent] = useState("");
  const [newIbanCheckedLoan, setNewIbanCheckedLoan] = useState("");
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

  const { iban } = useValidation();

  const {
    useFetchProperty: {
      data: property,
      isLoading: isPropertyLoading,
      isError: isPropertyError,
      setState: setProperty,
    },
  } = useContext(ObjectDataSheetContext);

  const newRent = (
    ibanNumber: string,
    rentMode: boolean,
    contact: string
  ): void => {
    fetchBanksData(ibanNumber, {
      rentBank,
      loanBank,
      setRentBank,
      setLoanBank,
      setIsRentIbanError,
      setIsLoanIbanError,
      setIsUpdatingRentBank,
      setIsUpdatingLoanBank,
      rentMode,
      setNewIbanCheckedRent,
      setNewIbanCheckedLoan,
      contact,
    });
  };

  useEffect(() => {
    if (property) {
      setRentBank(
        property.banks.rent ?? { ...EmptyBankForm, type_id: BANK_TYPES.RENT }
      );
      setLoanBank(
        property.banks.loan ?? { ...EmptyBankForm, type_id: BANK_TYPES.LOAN }
      );
    }
  }, [property]);

  useEffect(() => {
    const isLoadingFinished = isPropertyError || property;
    setDisabled(Boolean(!isLoadingFinished));
  }, [isPropertyError, property]);

  const handleClose = (): void => setIsSnackbarVisible(false);

  if (isPropertyError) {
    return (
      <ErrorBox
        formMessage={{ type: "error", text: "errorSomethingWentWrong" }}
      />
    );
  }

  if (isPropertyLoading) {
    return (
      <LoadingBox>
        <Loader />
      </LoadingBox>
    );
  }

  return (
    <>
      {formMessage.text && (
        <FormAlert formMessage={formMessage} sx={{ marginBottom: "10px" }} />
      )}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={10}
        sx={{ py: 2 }}
      >
        {property && rentBank && loanBank && (
          <>
            {isEditRent ? (
              <Formik
                initialValues={prepareBankBeforeForm(rentBank)}
                validationSchema={Yup.object().shape({
                  iban,
                })}
                onSubmit={handleUpdateBankFormSubmit(
                  id,
                  {
                    setFormMessage,
                  },
                  setProperty,
                  property,
                  rentBank,
                  setIsEditRent
                )}
                enableReinitialize
              >
                {(props): ReactElement => (
                  <FormWrapper>
                    <Form
                      newRent={newRent}
                      rentMode
                      isRentIbanError={isRentIbanError}
                      isLoanIbanError={isLoanIbanError}
                      isUpdatingRentBank={isUpdatingRentBank}
                      isUpdatingLoanBank={isUpdatingLoanBank}
                      setIsRentIbanError={setIsRentIbanError}
                      setIsLoanIbanError={setIsLoanIbanError}
                      onCancel={() => setIsEditRent(false)}
                      newIbanChecked={newIbanCheckedRent}
                      setIsSnackbarVisible={setIsSnackbarVisible}
                      {...props}
                    />
                  </FormWrapper>
                )}
              </Formik>
            ) : (
              <BankShow
                bank={rentBank}
                setIsEdit={setIsEditRent}
                rentMode
                isSnackbarVisible={isSnackbarVisible}
                handleClose={handleClose}
              />
            )}
            {isEditLoan ? (
              <Formik
                initialValues={prepareBankBeforeForm(loanBank)}
                validationSchema={Yup.object().shape({
                  iban,
                })}
                onSubmit={handleUpdateBankFormSubmit(
                  id,
                  {
                    setFormMessage,
                  },
                  setProperty,
                  property,
                  loanBank,
                  setIsEditLoan
                )}
                enableReinitialize
              >
                {(props): ReactElement => (
                  <FormWrapper>
                    <Form
                      newRent={newRent}
                      isRentIbanError={isRentIbanError}
                      isLoanIbanError={isLoanIbanError}
                      isUpdatingRentBank={isUpdatingRentBank}
                      isUpdatingLoanBank={isUpdatingLoanBank}
                      setIsRentIbanError={setIsRentIbanError}
                      setIsLoanIbanError={setIsLoanIbanError}
                      onCancel={() => setIsEditLoan(false)}
                      newIbanChecked={newIbanCheckedLoan}
                      setIsSnackbarVisible={setIsSnackbarVisible}
                      {...props}
                    />
                  </FormWrapper>
                )}
              </Formik>
            ) : (
              <BankShow
                bank={loanBank}
                setIsEdit={setIsEditLoan}
                isSnackbarVisible={isSnackbarVisible}
                handleClose={handleClose}
              />
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default Bank;
