import React from "react";
import AuthGuard from "../../components/guards/AuthGuard";
import DashboardLayout from "../../layouts/dashboard";
import ContractCreate from "../../components/properties/contracts/create/ContractCreate";
import RequestShow from "../../components/properties/requests/show/RequestShow";
import EditRequest from "../../components/properties/requests/edit";
import CreateRequest from "../../components/properties/requests/create";
import {
  PermissionsCreate,
  PermissionsRead,
  PermissionsUpdate,
} from "../../constants";
import AccessGuard from "../../components/guards/AccessGuard";
import CompanyList from "../../components/company/list";
import CreateCompany from "../../components/company/create";
import PropertyWrapper from "../../components/properties/propertyWrapper";

/* istanbul ignore next */
export default {
  path: "/:client/companies",

  element: (
    <AuthGuard clientModuleName="properties">
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: (
        <AccessGuard permissions={[PermissionsRead.PROPERTY]}>
          <CompanyList />
        </AccessGuard>
      ),
    },
    {
      path: "create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.PROPERTY]}>
          <CreateCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/edit",
      element: (
        <AccessGuard permissions={[PermissionsRead.PROPERTY]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INVOICE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/:invoiceId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.INVOICE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/:invoiceId/show",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/reminders/create",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/reminders",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/reminders/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/datev/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INVOICE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/datev/:invoiceId/show",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INVOICE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/datev/:invoiceId/edit",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INVOICE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/outgoing-invoices/",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/outgoing-invoices/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INVOICE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/outgoing-invoices/:invoiceId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.INVOICE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/outgoing-invoices/:invoiceId/show",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.INVOICE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/contracts",
      element: (
        <AccessGuard permissions={[PermissionsRead.CONTRACT]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/contracts/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.CONTRACT]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/contracts/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.CONTRACT]}>
          <ContractCreate />
        </AccessGuard>
      ),
    },
    {
      path: ":id/contracts/:contractId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.CONTRACT]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/contracts/:contractId/show",
      element: (
        <AccessGuard permissions={[PermissionsRead.CONTRACT]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/offers",
      element: (
        <AccessGuard permissions={[PermissionsRead.OFFER]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/offers/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.OFFER]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/offers/:topicId/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.OFFER]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/offers/:offerId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.OFFER]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/offers/:offerId/show",
      element: (
        <AccessGuard permissions={[PermissionsRead.OFFER]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/insurances",
      element: (
        <AccessGuard permissions={[PermissionsRead.INSURANCE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/insurances/:type/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.INSURANCE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/insurances/:insuranceId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.INSURANCE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/insurances/:insuranceId/show",
      element: (
        <AccessGuard permissions={[PermissionsRead.INSURANCE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/insurances/:topicId/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INSURANCE]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/tenants/:areaId/requests/:requestId/show",
      element: <RequestShow />,
    },
    {
      path: ":id/tenants/:areaId/requests/:requestId/edit",
      element: <EditRequest />,
    },
    {
      path: ":id/tenants/:areaId/requests/create",
      element: <CreateRequest />,
    },
    {
      path: ":id/inbox",
      element: (
        <AccessGuard permissions={[PermissionsRead.INBOX]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/inbox/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.INBOX]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/inbox/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INBOX]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    // General documents
    {
      path: ":id/general-documents",
      element: (
        <AccessGuard permissions={[PermissionsRead.GENERAL_DOCUMENT]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/general-documents/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.GENERAL_DOCUMENT]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/general-documents/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.GENERAL_DOCUMENT]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/general-documents/:documentId/show",
      element: (
        <AccessGuard permissions={[PermissionsRead.GENERAL_DOCUMENT]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/api-datev/:tab",
      element: (
        <AccessGuard permissions={[PermissionsCreate.PROPERTY]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/api-datev/creditor/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.PROPERTY]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/api-datev/debtor/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.PROPERTY]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/api-datev/debit-account/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.PROPERTY]}>
          <PropertyWrapper isCompany />
        </AccessGuard>
      ),
    },
    {
      path: ":id/file-management",
      element: (
        <AccessGuard permissions={[PermissionsRead.FILE_MANAGEMENT]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
  ],
};
