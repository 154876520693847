import { FormikValues } from "formik";
import { AppDispatch } from "../../../../redux/store";
import { FormMessageInterface } from "../../../../types/form";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { uploadCSVFile } from "../../../../api/creditor";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../constants";

export interface TabAreaControls {
  id: string;
  "aria-controls": string;
}

export enum TABS {
  CREDITOR = "creditor",
  DEBTOR = "debtor",
  DEBITACCOUNT = "debit-account",
  CONFIG = "config",
}

export const tabsLabels: TABS[] = [
  TABS.CREDITOR,
  TABS.DEBTOR,
  TABS.DEBITACCOUNT,
  TABS.CONFIG,
];

export enum FETabs {
  CREDITOR = "creditor",
  DEBITOR = "debitor",
  SOLLKONTO = "sollkonto",
}

export const simpleTabProps = (index: number): TabAreaControls => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
});

export interface ManuallyUploaDialogProps {
  toggleModel: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
  onSuccess: () => void;
  title: string;
  type: string;
}

export interface handleManullyPropertyUploadProps {
  setFormMessage: React.Dispatch<React.SetStateAction<FormMessageInterface>>;
  onSuccess: () => void;
  dispatchStore: AppDispatch;
}

export type UploadCSVFileType = Partial<File> & {
  property_id: string;
  type: string;
  file: File | string;
};

export const handleCreditorPropertyUpload = ({
  setFormMessage,
  onSuccess,
}: handleManullyPropertyUploadProps) => {
  return async (
    data: UploadCSVFileType,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const reqData = new FormData();
    reqData.append("file", data.file!);
    reqData.append("property_id", data.property_id);
    reqData.append("type", data.type);

    const response = await uploadCSVFile(reqData);
    const json = await response.json();

    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      setFormMessage({ type: undefined, text: "" });
      onSuccess();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setSubmitting(false);
    }
  };
};
