import { MaintenanceForm } from "../components/properties/maintenance/create/types";
import { CreateMaintenanceType } from "../components/properties/maintenance/typeManagement/types";
import { CreateUnitForm } from "../components/properties/maintenance/MaintenanceUnits/types";
import http from "../utils/http";

export const getMaintenanceList = async (params: string): Promise<Response> => {
  return await http.get(`/api/maintenances${params}`);
};

export const getMaintenanceDetails = async (id: string): Promise<Response> => {
  return await http.get(`/api/maintenances/${id}`);
};

export const createMaintenance = async (
  data: MaintenanceForm
): Promise<Response> => {
  return await http.post("/api/maintenances", data);
};

export const editMaintenance = async (
  id: string,
  data: MaintenanceForm
): Promise<Response> => {
  return await http.put(`/api/maintenances/${id}`, data);
};

export const deleteMaintenance = async (id: string): Promise<Response> => {
  return await http.delete(`/api/maintenances/${id}`);
};

export const getMaintenanceType = async (params: string): Promise<Response> => {
  return await http.get(`/api/maintenance-types${params}`);
};

export const createMaintenanceType = async (
  data: CreateMaintenanceType
): Promise<Response> => {
  return await http.post("/api/maintenance-types", data);
};

export const deleteMaintenanceType = async (id: string): Promise<Response> => {
  return await http.delete(`/api/maintenance-types/${id}`);
};

export const getMaintenanceHistory = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/maintenance-history${params}`);
};

export const getMaintenanceUnits = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/maintenance-units${params}`);
};

export const createMaintenanceUnit = async (
  payload: CreateUnitForm
): Promise<Response> => {
  return await http.post("/api/maintenance-units", payload);
};

export const deleteMaintenanceUnit = async (id: string): Promise<Response> => {
  return await http.delete(`/api/maintenance-units/${id}`);
};
