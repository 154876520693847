import styled from "styled-components/macro";
import { TextField as MuiTextField } from "@mui/material";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-height: ${(props): string => props.theme.spacing(80)};
  justify-content: space-between;
`;

export const TextField = styled(MuiTextField)`
  min-height: ${(props): string => props.theme.spacing(18.5)};
  margin-top: ${(props): string => props.theme.spacing(1.5)};
`;
