/* istanbul ignore file */
import styled from "styled-components";
import { Box, Card, Grid, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";

export const SmallLightText = styled(Typography)`
  font-size: ${(props): string => `${props.theme.spacing(3.5)}`};
  color: ${(props): string => props.theme.palette.grey[500]};
  font-weight: 500;
  font-style: normal;
  text-align: center;
`;

export const DownloadFileText = styled(Typography)`
  font-size: ${(props): string => `${props.theme.spacing(3.5)}`};
  color: ${(props): string => props.theme.palette.primary.main};
  font-weight: 500;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props): string => `${props.theme.spacing(1)}`};
  cursor: pointer;
`;

export const DeleteIconContainer = styled(Box)`
  position: absolute;
  right: ${(props): string => props.theme.spacing(3)};
  top: ${(props): string => props.theme.spacing(3)};
  cursor: pointer;
`;

export const RentedInfoContainerForRentEndDataWithTooltip = styled(Grid)`
  padding: ${(props): string => `${props.theme.spacing(4)}`};
`;

export const RentedInfoContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props): string => `${props.theme.spacing(1)}`};
  flex-direction: column;
  padding: ${(props): string => `${props.theme.spacing(4)}`};
  &.row-start {
    justify-content: flex-start;
    flex-direction: row;
  }
  &.border-bottom {
    border-bottom: ${(props): string => `${props.theme.spacing(0.5)}`} solid
      ${(props): string => props.theme.border.color};
  }
  &.align-start {
    align-items: flex-start;
  }
`;

export const DropZoneBox = styled(Box)<{
  additionalstyle: string;
}>`
  text-align: center;
  padding: ${(props): string => props.theme.spacing(5)};
  border: ${(props): string => props.theme.spacing(0.5)} dashed
    ${(props): string =>
      props.additionalstyle === "accept"
        ? green[500]
        : props.additionalstyle === "reject"
        ? red[500]
        : props.theme.palette.textColor.default};
  border-radius: ${(props): string => props.theme.spacing(2.5)};
  height: 100%;
  min-height: ${(props): string => props.theme.spacing(44)};
  width: 100%;
  display: flex;
  gap: ${(props): string => props.theme.spacing(2.5)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .fileName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

export const EditInfoCard = styled(Card)`
  position: relative;
  .edit-icon {
    position: absolute;
    right: ${(props): string => props.theme.spacing(5)};
    top: ${(props): string => props.theme.spacing(5)};
    cursor: pointer;
    color: ${(props): string => props.theme.palette.primary.main};
  }
  .close-icon {
    color: ${red[500]};
    position: absolute;
    right: ${(props): string => props.theme.spacing(3)};
    top: ${(props): string => props.theme.spacing(2)};
    font-size: ${(props): string => props.theme.spacing(7)};
    cursor: pointer;
  }
  .save-icon {
    position: absolute;
    right: ${(props): string => props.theme.spacing(12)};
    top: ${(props): string => props.theme.spacing(2)};
  }
`;
