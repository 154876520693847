import React, { ReactElement, useCallback, useState, useEffect } from "react";
import { Formik } from "formik";
import { Stack } from "@mui/material";
import { FormWrapper } from "./styled";
import {
  FormMessageErrorState,
  FormMessageInItState,
  UserRoles,
} from "../../../constants";
import { handleEditUserFormSubmit, setUserOptions } from "./utils";
import Form from "./Form";
import { useParams } from "react-router-dom";
import { Salutation, User } from "../../../types/models";
import Loader from "../../Loader";
import AlertCustom from "../../../ui/formAlert/FormAlert";
import useValidation from "../../../hooks/useValidation";
import * as Yup from "yup";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import { getSalutations } from "../../../api/salutations";
import { getUser } from "../../../api/users";
import { useFetch } from "../../../hooks/useFetch";
import { ErrorBox } from "../../properties/objectDataSheet/tabs/generalInformation/styled";
import useRoles from "../../../hooks/useRoles";
import { useTranslation } from "react-i18next";
import PageWrapper from "../../../ui/pageWrapper/PageWrapper";
import { route } from "../../../utils/url";

const EditUser = (): ReactElement => {
  const { t } = useTranslation();
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { _user } = useAppSelector((state) => state.user);
  const [selectedUserRoll, setSelectedUserRoll] = useState("");
  const [passwordFieldValue, setPasswordFieldValue] = useState("");

  const isLoggedUser = useCallback(() => id == _user?.id, []);

  const { roles, isError: isRolesError, getRole } = useRoles();

  const {
    first_name,
    last_name,
    email,
    salutation_id,
    phone_number,
    mobile_number,
    role: role_id,
    password,
    password_confirmation,
  } = useValidation();

  const {
    data: salutations,
    isError: isSalutationsError,
    run: runSalutations,
  } = useFetch<Salutation[]>();

  const { data: user, isError: isUserError, run: runUser } = useFetch<User>();

  useEffect(() => {
    getData();
  }, []);

  const getData = (): void => {
    runSalutations(getSalutations());
    runUser(getUser(id!));
  };

  const adminId = String(getRole(UserRoles.ADMIN)?.id);
  const assetManagerId = String(getRole(UserRoles.ASSET_MANAGER)?.id);
  const transactionManagerId = String(
    getRole(UserRoles.TRANSACTION_MANAGER)?.id
  );
  const propertyManagerId = String(getRole(UserRoles.PROPERTY_MANAGER)?.id);

  const isInternalRole = [adminId, assetManagerId, transactionManagerId];

  const selectedInternalRole = isInternalRole.includes(selectedUserRoll);

  if (isRolesError || isUserError || isSalutationsError) {
    return <ErrorBox formMessage={FormMessageErrorState} />;
  }

  const breadcrumbs = [
    {
      to: route("users"),
      name: t("sidebar.userManagement"),
    },
  ];

  return (
    <PageWrapper title={t("edit")} breadcrumbs={breadcrumbs}>
      <>
        {roles && user && salutations ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            <FormWrapper>
              {formMessage.text && (
                <AlertCustom
                  formMessage={formMessage}
                  sx={{ marginBottom: "10px" }}
                />
              )}

              <Formik
                initialValues={setUserOptions(user)}
                validationSchema={Yup.object().shape({
                  first_name,
                  last_name,
                  email,
                  phone_number,
                  mobile_number,
                  role_id,
                  salutation_id,
                  password: password.optional,
                  password_confirmation:
                    passwordFieldValue !== ""
                      ? password_confirmation.required
                      : password_confirmation.optional,
                })}
                onSubmit={handleEditUserFormSubmit(
                  id!,
                  { setFormMessage },
                  dispatch,
                  isLoggedUser(),
                  selectedInternalRole
                )}
                enableReinitialize
              >
                {(props): React.ReactElement => (
                  <Form
                    salutations={salutations}
                    user={user}
                    setSelectedUserRoll={setSelectedUserRoll}
                    selectedInternalRole={selectedInternalRole}
                    propertyManagerId={propertyManagerId}
                    setPasswordFieldValue={setPasswordFieldValue}
                    {...props}
                  />
                )}
              </Formik>
            </FormWrapper>
          </Stack>
        ) : (
          <Loader />
        )}
      </>
    </PageWrapper>
  );
};

export default EditUser;
