import styled from "styled-components";
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";

/* istanbul ignore next */
export const Dialog = styled(MuiDialog)<{ $loading?: boolean }>`
  .MuiPaper-root {
    visibility: ${(props): string => (props.$loading ? "hidden" : "unset")};
    opacity: ${(props): number => (props.$loading ? 0 : 1000)};
    overflow-x: hidden;
  }
`;
/* istanbul ignore next */
export const LoaderRoot = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`;
/* istanbul ignore next */
export const HistoryDialogContent = styled(MuiDialogContent)`
  .MuiTableContainer-root {
    max-height: auto;
  }
`;
/* istanbul ignore next */
export const DocumentReleaseHistoryIcon = styled(HistoryIcon)`
  margin-left: 10px;
  &:hover {
    color: ${(props): string => props.theme.palette.grey[400]};
    cursor: pointer;
  }
  transition: color 150ms;
  &.disabled {
    color: ${(props): string => props.theme.palette.red[600]};
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
