import attachments from "./attachments/de";

export default {
  attachments: attachments,
  dataSheet: "Datenblatt",
  title: "Objektdatenblatt",
  generalInfo: "Allgemeine Informationen",
  propertyManagement: "Hausverwaltung",
  bank: "Bank",
  createCompany: "Neue Hausverwaltung anlegen",
  top3Tenant: "Top 3 Mieter",
};
