import React, { useRef } from "react";
import { Dialog, Grid } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { CreateContactModalProps } from "../../../types/fe/contact";
import CreateContact from "../create/CreateContact";
import DialogTitle from "../../../ui/dialog/DialogTitle";
import PdfViewer from "../../../ui/pdfViewer";
/* istanbul ignore next */
function CreateContactPopup({
  isModalOpen,
  patchCreatedContact,
  handleCloseCreateContactModal,
  fileId,
}: CreateContactModalProps): React.ReactElement {
  const pdfContainerRef = useRef<HTMLObjectElement | null>(null);
  return (
    <Dialog
      open={isModalOpen}
      fullWidth={true}
      maxWidth={fileId ? "lg" : "md"}
      aria-labelledby="max-width-dialog-title"
      data-testid="create-contact-modal"
    >
      <DialogTitle
        title="property.tenants.createContact"
        onClose={handleCloseCreateContactModal}
      />
      <DialogContent>
        <Grid container justifyContent="space-between" spacing={6}>
          {
            fileId && (
              <Grid item xs={12} sm={5} ref={pdfContainerRef}>
                <PdfViewer file={{ fileId }} containerRef={pdfContainerRef} />
              </Grid>
            )
          }
          <Grid item xs={12} sm={fileId ? 7 : 12}>
            <CreateContact
              modalWindow={true}
              handleCloseCreateContactModal={handleCloseCreateContactModal}
              patchCreatedContact={patchCreatedContact}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CreateContactPopup;
