export default {
  dashboard: "Dashboard",
  properties: "Objekte",
  companies: "Unternehmen",
  transactionManager: "Transaction management",
  loi: "LOI",
  documentRelease: "Dokumente freigeben",
  invoices: "Rechnungen",
  list: "Liste",
  reminders: "Mahnungen",
  outgoingInvoices: "Ausgangsrechnungen",
  apidatev: "API-DATEv",
  contracts: "Verträge",
  insurances: "Versicherungen",
  offers: "Angebot",
  users: "Nutzer",
  contacts: "Kontakte",
  userManagement: "Benutzerverwaltung",
  activity: "Nutzeraktivitäten",
  companyOwnership: "Firmeneigentum",
  hr: "HR",
  employeeOverview: "Mitarbeiterübersicht",
  vacationRequests: "Urlaubsanträge",
  bankAccount: "Bankkonto",
  fileManagement: "Dateiverwaltung",
  inbox: "Posteingang",
  meter: "Zählerstände",
  tenantRequests: "Mieteranfragen",
  settings: "Einstellungen",
  roles: "Rollen",
  release: "Freigabe",
  vacancies: "Leerstände",
  generalDocuments: "Allgemeine Dokumente",
};
