import { FormikHelpers } from "formik";
import { uploadAttachment } from "../../../../../../api/attachment";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../constants";
import { HTTP_STATUS_CODES } from "../../../../../../types/server";
import { UploadAttachmentProps, UploadFileFormValues } from "./types";

/* istanbul ignore next */
export const handleUploadFile = ({
  setSnackbarData,
  t,
  afterUploadFile,
}: UploadAttachmentProps) => {
  return async (
    data: UploadFileFormValues,
    { setStatus, setSubmitting, resetForm }: FormikHelpers<UploadFileFormValues>
  ): Promise<void> => {
    const formData = new FormData();
    data?.file && formData.append("file", data?.file);
    formData.append("property_id", data?.property_id);
    const response = await uploadAttachment(formData);
    if (
      [HTTP_STATUS_CODES.CREATED, HTTP_STATUS_CODES.OK].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      resetForm();
      afterUploadFile();
      setSnackbarData({
        visible: true,
        text: t(
          "property.objectDataSheet.attachments.attachmentUploadedSuccessfully"
        ),
        color: "success",
      });
    } else {
      setSnackbarData({
        visible: true,
        text: t(SOMETHING_WENT_WRONG_ERROR),
        color: "error",
      });
      setSubmitting(false);
    }
  };
};
