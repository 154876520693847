import React, { ReactElement, useEffect, useState } from "react";
import useAppSelector from "../../../hooks/useAppSelector";
import { CircularProgress, Grid, Snackbar, Typography } from "@mui/material";
import useAppDispatch from "../../../hooks/useAppDispatch";
import {
  ImportValidationAlert,
  CircularProgressWithLabel,
  HourglassTopTwoToneIcon,
  Label,
} from "./styled";
import { setFileName } from "../../../redux/slices/importValidation/importValidation";
import { Notification as BENotification } from "../../../types/be/notification";
import { finalPercentage, initProgressState, initType } from "./utils";

export const ImportValidation = ({
  socketChannel,
}: {
  socketChannel: string;
}): ReactElement => {
  const dispatchStore = useAppDispatch();
  const [progress, setProgress] = useState(initProgressState);
  const [type, setType] = useState(initType);
  const [notification, setNotification] = useState<BENotification | null>(null);
  const { importValidationNotification, fileName, downloadLink } =
    useAppSelector((state) => state.importValidation);

  const isFinished = progress === finalPercentage;
  const validationFail = type === "ValidationFailNotification";

  useEffect(() => {
    importValidationNotification?.socket_channel === socketChannel &&
      setNotification(importValidationNotification);
  }, [importValidationNotification]);

  useEffect(() => {
    if (!notification) return;
    const { type } = notification;
    const index = type.lastIndexOf("\\");
    setType(type.slice(index + 1));
  }, [notification]);

  useEffect(() => {
    if (!notification) return;
    const {
      data: { message },
    } = notification;
    if (type === "ValidationSuccessNotification") {
      setProgress(finalPercentage);
    } else if (type === "ValidationProgressNotification") {
      setProgress(Number(message));
    } else {
      resetProgress();
    }
  }, [type]);

  const resetProgress = (): void => setProgress(initProgressState);
  const resetType = (): void => setType(initType);

  const handleDownloadFolderClick = (): void => {
    window.location.assign(downloadLink);
    onClose();
  };

  const onClose = (): void => {
    resetType();
    dispatchStore(setFileName(""));
    setNotification(null);
    resetProgress();
  };

  return (
    <Snackbar
      open={Boolean(notification?.data.message && fileName)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transitionDuration={{ exit: 0 }}
    >
      <ImportValidationAlert
        onClose={onClose}
        severity={!validationFail ? "success" : "error"}
      >
        <Grid container flexDirection="column">
          {!validationFail && (
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <CircularProgressWithLabel>
                    <CircularProgress variant="determinate" value={progress} />
                    <Label>
                      <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                      >{`${Math.round(progress)}%`}</Typography>
                    </Label>
                  </CircularProgressWithLabel>
                </Grid>
                <Grid item display="flex">
                  <Typography variant="h6" sx={{ marginLeft: 8 }}>
                    {fileName}
                  </Typography>
                  {!isFinished && <HourglassTopTwoToneIcon color="disabled" />}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={handleDownloadFolderClick}
            >
              {notification?.data.message}
            </Typography>
          </Grid>
        </Grid>
      </ImportValidationAlert>
    </Snackbar>
  );
};

export default ImportValidation;
