import { FormSubmitHandlerProps } from "../../../../types/form";
import { FormikValues } from "formik/dist/types";
import { updateUserNotificationSettings } from "../../../../api/users";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { NotificationSettingsTypes } from "./types";

export const notificationSettingsFormInitState: NotificationSettingsTypes = {
  code: [""],
};
/* istanbul ignore next */
export const handleUpdateUserNotificationSettingsFormSubmit = (
  { setFormMessage }: FormSubmitHandlerProps,
  id: number
) => {
  return async (
    data: NotificationSettingsTypes,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const response = await updateUserNotificationSettings(id, data);

    const json = await response.json();
    if (response.ok) {
      setStatus({
        success: true,
        errors: {},
      });
      setFormMessage(FormMessageInItState);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    }
  };
};
