import React from "react";
import LOIList from "../../components/transactionManagement/loi/list";
import AuthGuard from "../../components/guards/AuthGuard";
import DashboardLayout from "../../layouts/dashboard";
import CreateLOI from "../../components/transactionManagement/loi/create";
import EditLOI from "../../components/transactionManagement/loi/edit";
import LOIItemHistory from "../../components/transactionManagement/loi/history";
import AccessGuard from "../../components/guards/AccessGuard";
import {
  PermissionsCreate,
  PermissionsRead,
  PermissionsUpdate,
} from "../../constants";

export default {
  path: "/:client/transaction-management",
  element: (
    <AuthGuard clientModuleName="transaction-management">
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "loi",
      element: (
        <AccessGuard permissions={[PermissionsRead.LOI]}>
          <LOIList />
        </AccessGuard>
      ),
    },
    {
      path: "loi/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.LOI]}>
          <CreateLOI />
        </AccessGuard>
      ),
    },
    {
      path: "loi/:id/history",
      element: (
        <AccessGuard permissions={[PermissionsRead.LOI]}>
          <LOIItemHistory />
        </AccessGuard>
      ),
    },
    {
      path: "loi/:loiId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.LOI]}>
          <EditLOI />
        </AccessGuard>
      ),
    },
  ],
};
