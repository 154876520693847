import { TableCell } from "../../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "property.object_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "Role",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];
