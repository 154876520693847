import React, { ReactElement, useEffect, useState } from "react";
import { Formik } from "formik";
import Form from "./Form";
import { Grid, Stack } from "@mui/material";
import { FormMessageInItState } from "../../../../constants";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import * as Yup from "yup";
import { FormWrapper, SearchProfilesTableWrapper } from "./styled";
import {
  handleSearchProfileFormSubmit,
  searchProfileFormInitState,
} from "./utils";
import { FormMessageInterface } from "../../../../types/form";
import SearchProfilesTable from "./Table";
import { SearchProfile as SearchProfileInterface } from "../../../../types/models";
import useValidation from "../../../../hooks/useValidation";
import { getTenantUsage } from "../../../../api/usage";
import { getPropertyTypes } from "../../../../api/propertyTypes";
import { getRankings } from "../../../../api/ranking";
import {
  getSearchProfile,
  getSearchProfilesTypes,
} from "../../../../api/searchProfile";
import Loader from "../../../Loader";
import {
  replaceEntityById,
  replaceNullOrUndefinedByEmptyString,
} from "../../../../utils/common";
import {
  replaceTimestampByMomentDateOrNull,
  yearToDate,
} from "../../../../utils/date";
import { useFetch } from "../../../../hooks/useFetch";
import { LoadingBox } from "../styled";
import { TabPanelItemProps } from "../types";

const SearchProfile = ({ id, contact }: TabPanelItemProps): ReactElement => {
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const [searchProfileUpdated, setSearchProfileUpdated] =
    useState<boolean>(false);
  const [isSearchProfileData, setIsSearchProfileData] = useState<boolean>(
    Boolean(contact.search_profiles?.length)
  );
  const {
    searchProfileTitle: title,
    qm_to,
    qm_from,
    sale_area_to,
    rentable_area_to,
    office_area_to,
    practice_area_to,
    commercial_area_to,
    living_area_to,
    other_area_to,
    inhabitants_to,
    plot_area_to,
    construction_year,
    renovation,
    notes,
    source,
    searchProfileZipCode: zip_code,
  } = useValidation();
  const [initialValues, setInitialValues] = useState<SearchProfileInterface>(
    searchProfileFormInitState
  );
  const [loading, setLoading] = useState(false);

  const handleEditClick = async (id: number): Promise<void> => {
    window.scrollTo(0, 0);
    setLoading(true);
    const res = await getSearchProfile(id);
    const { data } = await res.json();
    replaceNullOrUndefinedByEmptyString(data);

    setInitialValues({
      ...data,
      ...replaceTimestampByMomentDateOrNull({
        bnl: data.bnl,
      }),
      ...replaceEntityById({
        ranking_id: data.ranking,
        usage_id: data.usage,
        property_type_id: data.property_type,
        type_id: data.type,
      }),
      construction_year: yearToDate(data.construction_year),
      renovation: yearToDate(data.renovation),
    });
    setLoading(false);
  };

  const cancelEdit = (): void => {
    setInitialValues(searchProfileFormInitState);
  };

  const {
    data: types,
    isLoading: isLoadingTypes,
    run: runTypes,
  } = useFetch<[]>();

  const {
    data: rankings,
    isLoading: isLoadingRanking,
    run: runRankings,
  } = useFetch<[]>();

  const {
    data: propertyTypes,
    isLoading: isLoadingPropertyTypes,
    run: runPropertyTypes,
  } = useFetch<[]>();

  const {
    data: usages,
    isLoading: isLoadingUsages,
    run: runUsages,
  } = useFetch<[]>();

  const getData = (): void => {
    runTypes(getSearchProfilesTypes());
    runRankings(getRankings());
    runUsages(getTenantUsage());
    runPropertyTypes(getPropertyTypes());
  };

  useEffect(() => {
    getData();
  }, []);

  if (
    isLoadingTypes ||
    isLoadingRanking ||
    isLoadingUsages ||
    isLoadingPropertyTypes
  ) {
    return (
      <div data-testid="loader">
        <LoadingBox>
          <Loader />
        </LoadingBox>
      </div>
    );
  }

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0}
    >
      <Grid container>
        <Grid item xs={12}>
          <FormWrapper>
            {formMessage.text && (
              <FormAlert formMessage={formMessage} sx={{ mb: "10px" }} />
            )}
            {loading ? (
              <Loader />
            ) : (
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  title,
                  qm_to,
                  qm_from,
                  sale_area_to,
                  rentable_area_to,
                  office_area_to,
                  practice_area_to,
                  commercial_area_to,
                  living_area_to,
                  other_area_to,
                  inhabitants_to,
                  plot_area_to,
                  zip_code,
                  construction_year,
                  renovation,
                  notes,
                  source,
                })}
                onSubmit={handleSearchProfileFormSubmit({
                  setInitialValues,
                  setFormMessage,
                  id,
                  setSearchProfileUpdated,
                  setIsSearchProfileData,
                })}
              >
                {(props): ReactElement => (
                  <Form
                    {...{
                      ...props,
                      types,
                      rankings,
                      usages,
                      propertyTypes,
                      cancelEdit,
                    }}
                  />
                )}
              </Formik>
            )}
          </FormWrapper>
        </Grid>
        {isSearchProfileData ? (
          <SearchProfilesTableWrapper item xs={12}>
            <SearchProfilesTable
              handleEditClick={handleEditClick}
              id={id}
              searchProfileUpdated={searchProfileUpdated}
              setSearchProfileUpdated={setSearchProfileUpdated}
              setIsSearchProfileData={setIsSearchProfileData}
            />
          </SearchProfilesTableWrapper>
        ) : (
          <></>
        )}
      </Grid>
    </Stack>
  );
};

export default SearchProfile;
