/* istanbul ignore file */

import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { FormikProps } from "formik";
import { ForwardDocumentPayload } from "./types";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  DialogActions,
  Grid,
  Tooltip,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { debounce } from "lodash";
import { getUsers, searchUser } from "../../../../api/users";
import {
  INPUT_MIN_HEIGHT,
  SEARCH_PARAM_MIN_CHARACTERS,
} from "../../../../constants";
import { User } from "../../../../types/models";
import Button from "../../../../ui/button/Button";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import Text from "../../../../ui/formsFields/text";
import { Chip, SelectBox } from "./styled";
import { useTranslation } from "react-i18next";
import { FormMessageInterface } from "../../../../types/form";
import SelectOrCreateContactsAutocompleteField from "../../../contacts/autocomplete";
import { StyledPaper } from "../../../contacts/autocomplete/styled";
const icon = <CheckBoxOutlineBlankIcon fontSize="inherit" />;
const checkedIcon = <CheckBoxIcon fontSize="inherit" />;

interface ForwardDocumentFormProps extends FormikProps<ForwardDocumentPayload> {
  handleCloseForwardModal: () => void;
  formMessage: FormMessageInterface;
}

const ForwardDocumentForm = ({
  status,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  errors,
  values,
  touched,
  handleBlur,
  handleChange,
  handleCloseForwardModal,
  formMessage,
}: ForwardDocumentFormProps): ReactElement => {
  const { t } = useTranslation();
  const [showUserTooltip, setUserShowTooltip] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User[]>([]);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly User[] | null>([]);
  const [defaultOptions, setDefaultOptions] = useState<readonly User[] | null>(
    []
  );
  const [loading, setLoading] = useState(false);

  const renderUserToolTipTitle = useCallback(() => {
    return (
      selectedUser?.length > 0 &&
      selectedUser?.map((value: User, index: number) => {
        return (
          <div key={index}>
            {value?.first_name} {value?.last_name}
          </div>
        );
      })
    );
  }, [selectedUser]);

  const resetOptions = (): void => {
    setOptions(defaultOptions);
  };

  const onSearchFieldTextChange = useCallback(
    debounce(async (search: string): Promise<void> => {
      if (search.length < SEARCH_PARAM_MIN_CHARACTERS) return resetOptions();
      else {
        setLoading(true);
        try {
          const response = await searchUser(search);
          const { data } = await response.json();
          setOptions(data);
          setLoading(false);
        } catch (e) {
          setOptions([]);
        }
      }
    }, 0),
    []
  );

  const handleUserSelect = useCallback((_: unknown, users: User[]) => {
    setSelectedUser(users);
    setFieldValue(
      "user_id",
      users?.map((item) => item.id)
    );
  }, []);

  const getUser = useCallback(async () => {
    try {
      const response = await getUsers();
      const { data } = await response.json();
      setDefaultOptions(data);
      setOptions(data);
    } catch (e) {
      setOptions([]);
    }
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (options?.length === 0) {
      resetOptions();
    }
    if (!open) resetOptions();
  }, [selectedUser, options, open]);

  return (
    <>
      <DialogContent sx={{ width: "500px" }}>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          {formMessage.text ? (
            <Grid item xs={12}>
              <FormAlert formMessage={formMessage} />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Text
              name="subject"
              label={t("inbox.forward.subject")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            />
          </Grid>
          <Grid item xs={12}>
            <Tooltip
              id="userToolTip"
              placement="top"
              aria-label="add"
              open={showUserTooltip}
              onMouseEnter={() => setUserShowTooltip(true)}
              onMouseLeave={() => setUserShowTooltip(false)}
              title={renderUserToolTipTitle()}
            >
              <Autocomplete
                id="user_id"
                multiple
                limitTags={1}
                value={selectedUser}
                open={open}
                onOpen={(): void => setOpen(true)}
                onClose={(): void => setOpen(false)}
                onChange={handleUserSelect}
                onInputChange={(event, value) => onSearchFieldTextChange(value)}
                disableCloseOnSelect
                options={options || []}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name} ${option.email}`
                }
                isOptionEqualToValue={(option, value): boolean =>
                  `${option.email}` === `${value.email}`
                }
                PaperComponent={StyledPaper}
                renderTags={(list) => {
                  const displayList = list.map((item) => item.first_name);
                  return (
                    <SelectBox>
                      <Chip
                        sx={{ maxHeight: 17 }}
                        label={`${list[0].first_name} ${list[0].last_name}`}
                      />
                      {list.length > 1 && (
                        <div>{`${t(
                          "tenant.selectedMoreThenOneTenantPartOne"
                        )} ${displayList.length - 1} ${t(
                          "tenant.selectedMoreThenOneTenantPartTwo"
                        )} `}</div>
                      )}
                    </SelectBox>
                  );
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.first_name} {option.last_name}
                  </li>
                )}
                renderInput={(params) => (
                  <Text
                    {...params}
                    name="user_id"
                    label={t("searchUser")}
                    InputLabelProps={{ shrink: true }}
                    placeholder={t("search")}
                    status={status}
                    error={Boolean(
                      touched.user_id &&
                        (status?.errors.user_id || errors.user_id)
                    )}
                    helperText={
                      touched.user_id &&
                      (status?.errors.user_id || errors.user_id)
                    }
                    sx={{ mb: 4 }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <SelectOrCreateContactsAutocompleteField
              {...{
                errors,
                values,
                touched,
                handleBlur,
                handleChange,
                setFieldValue,
              }}
              required
              label={t("tenant.contact_name")}
              placeholder={t("tenant.contact_name")}
            />
          </Grid>
          <Grid item xs={12}>
            <Text
              name="email"
              label={t("email")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            />
          </Grid>
          <Grid item xs={12}>
            <Text
              name="comment"
              value={values.comment}
              label={t("comments.comment")}
              placeholder={t("comments.comment")}
              rows={5}
              multiline
              status={status}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid sx={{ width: "100%" }} px={5}>
          <Grid container>
            <Grid item xs={8}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="is_add_comment"
                      value={values.is_add_comment}
                      checked={values.is_add_comment}
                      onChange={() =>
                        setFieldValue("is_add_comment", !values.is_add_comment)
                      }
                    />
                  }
                  label={t("inbox.forward.includeComments")}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap={2}
            >
              <Button
                title={t("cancel")}
                color="primary"
                variant="text"
                size="large"
                onClick={handleCloseForwardModal}
              />
              <Button
                title={t("send")}
                onClick={handleSubmit}
                isLoading={isSubmitting}
                disabled={isSubmitting}
                size="large"
                testId="submit-forward"
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default ForwardDocumentForm;
