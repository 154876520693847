import objectDataSheet from "./objectDataSheet/en";
import maintenance from "./maintenance/en";
import { IBAN_CHARACTERS_NUMBER } from "../../constants";

export default {
  objectDataSheet,
  maintenance,
  property: "Property",
  objectName: "Object name",
  street: "Street",
  houseNumber: "House number",
  zipCode: "ZIP",
  purchasePrice: "Purchase price, €",
  notes: "Notes",
  brokerPrice: "Broker price",
  yearOfConstruction: "Construction Year",
  fullArea: "Land in m²",
  parkingLots: "Parking lots",
  commercialAreaRentedInM2: "Commercial area rented in m²",
  emptyCommercialAreaInM2: "Empty commercial area in m²",
  rentedLivingAreaInM2: "Living area rented in m²",
  emptyLivingAreaInM2: "Living area not rented in m²",
  standardGroundValue: "Standart ground value",
  netRentActual: "Net rent actual",
  netRentTarget: "Net rent (target) Increase p.a. in %",
  maintenanceNonReversible: "Maintenance non-reversable increase p.a. in €",
  operatingCostsNonReversible:
    "Operating costs non-reversible Increase p.a. in %",
  nonCirculatingPropertyManagement:
    "Non-circulating property management Increase p.a. in %",
  taxes: "Taxes in %",
  state: "State",
  propertyType: "Property type",
  contactType: "Contact type",
  createProperty: "Create property",
  createNewObject: "Create New Object",
  propertyCreated: "Property created",
  createPropertyManagement: "Create property management",
  updateProperty: "Update property",
  propertyWasSuccessfullyCreated: "Property was successfully created",
  propertyWasSuccessfullyUpdated: "Property was successfully updated",
  financesWasSuccessfullyUpdated: "Finance was successfully updated",
  propertyManagementWasSuccessfullyCreated:
    "Property management was successfully created",
  propertyManagementWasSuccessfullyUpdated:
    "Property management was successfully updated",
  anchorTenant: "Anchor Tenant",
  rentedArea: "Rented area",
  rentStartDate: "Rent start date",
  rentEnd: "Rent end date",
  rentalIncome: "Rental income on property in %",
  netRentPerYear: "Net rent per year",
  potencialPerYear: "Potential per year",
  assetManager: "Asset Manager",
  assetManagers: "AM",
  assetManagersHistoryBtn: "AM-history",
  assetManagersHistoryTitle: "AM-history",
  dateOfTakingOver: "Date of taking over",
  takeover: "Takeover of the property",
  supervisor: "Supervisor",
  caretaker: "Caretaker",
  expenses: "Expenses",
  expensesHelperText: "*1 = easy, 6 = very difficult",
  wault: "Wault",
  portfolio: "Portfolio",
  taxNumber: "Tax number",
  vat: "VAT",
  architect: "Architect",
  architectHelperText: "updated",
  city: "City",
  contractStartDate: "Contract start date",
  contractExpiryDate: "Contract expiry date",
  contractCancelDate: "Contract cancel date",
  propertyManagementCost: "Property management cost, €",
  percentageOfNetRent: "Percentage of net rent",
  companyUser: "Company user",
  propertyValueDevelopment: "Property value development in %",
  commertialType: "Commertial Type",
  livingType: "Living Type",
  registrationNumber: "Registration court no./trade registration number",
  outgoingAddress: "Outgoing address",
  managingDirector: "Managing director",
  propertyEmail: "Email",
  taxId: "Tax ID",
  logo: "Logo",
  uploadLogo: "Upload logo",
  tabs: {
    general: "General property information",
    costs: "Additional costs",
    finance: "Finance structure",
    operation: "Operation and use",
    repayment: "Exit / Repayment Bond",
  },
  manualImport: "Manually Import",
  downloadExample: "Download sample import file",
  upload: "Upload",
  fileIsRequired: "File is required",
  excelOnly: "Upload Excel",
  csvOnly: "Upload CSV",
  importTitle: "Import properties",
  import: "Import",
  importProperties: "Import properties",
  importTenants: "Import Tenants",
  tenantsSampleDownload: "Download Sample File",
  bank: {
    rentAccount: "Rent account",
    loanAccount: "Loan account",
    contactPerson: "Contact person",
    iban: "IBAN",
    bankName: "Bank name",
    bic: "BIC",
    accountNumber: "Account number",
    checkIban: "Check iban",
    ibanErrorMessage: "IBAN does not exist",
    ibanCharactersNumber: `Must be ${IBAN_CHARACTERS_NUMBER} characters`,
    bankWasSuccessfullyUpdated: "Bank was successfully updated",
    ibanIsRequired: "Iban is required",
    assignProperty: "Assign Property",
    searchProperty: "Search Property",
    propertyAssignedSuccessfully: "Property assigned successfully",
    linkNewBankAccount: "Link a new bank account",
    addAccountToAnExistingBank: "Add account to an existing bank",
    importSales: "Import sales",
    deleteBankAccount: "Delete bank account",
    syncBankAccount: "Sync bank account",
    reloadBankAccountData: "Reload bank account data",
    allSales: "All sales",
    proposals: "proposals",
    notAssigned: "notAssigned",
    automaticallyAssigned: "automaticallyAssigned",
    ignored: "ignored",
    downloadSalesExampleFile: "Download sales example file",
  },
  finance: {
    ownResources: "Own resources in %",
    bankLoan: "Bank loan in %",
    interestOnOwnFunds: "Interest on own funds in %",
    interestBankLoan: "Interest bank loan in %",
    repaymentBank: "Repayment bank in %",
    bankLoanInEur: "Bank loan in €",
    ownResourcesInEur: "Own resources in €",
  },
  noFileTree: "No file tree for this property",
  selectAnyFileToLink: "Select any file to link.",
  operationAndUse: {
    nonApportionableAncillaryCosts: "Non-apportionable ancillary costs",
    maintenanceNonApportionable: "Maintenance non-apportionable in %",
    operatingCostsNonRevenueable: "Operating costs non-revenueable in %",
    propertyManagementNonRevenueable:
      "Property management non-revenueable in %",
    depreciation: "Depreciation in %",
  },
  area: {
    activeTenant: "Active tenant",
    futureTenant: "Future tenant",
    areaList: "AreaList",
    name: "Area name",
    squareMeters: "Area m²",
    index: "Index",
    emptySpaceEuro: "Empty space in EU",
    emptySpaceSince: "Empty space since",
    isRented: "Rented",
    isNotRented: "Not rented",
    isActive: "Active",
    typeName: "Type",
    categoryName: "Category",
    usage: "Usage",
    edit: {
      tabs: {
        area: "Area",
        requests: "Requests",
        counters: "Counters",
        tenant: "Tenant",
        history: "History",
        "empty-area": "Empty Area",
      },
      success: "Area was successfully updated",
      deactivate: "Deactivate",
      activate: "Activate",
      deactivateConfirm:
        "By deactivating, all values of the area will no longer be considered in the calculations.\n\nDo you want to deactivate this area now?",
      activateConfirm:
        "By activating this area, all values will be considered in the calculations.\n\nDo you want to activate this area now?",
      editArea: "Edit area and tenant",
      leaveWithoutSaving:
        "You did not save your changes.\n\nDo you want to leave?",
      emptySpaceEurRequired: "Empty space in Eur is required",
    },
    contactName: "Contact name",
    netRentPerMonth: "Net rent per month",
    rentStart: "Start rent date",
    rentEnd: "End rent date",
    isCancelled: "Canceled / not renewed",
    tenantName: "Tenant name",
    comment: "Comment",
    cancellationUntil: "Cancellation until",
    closingTenantContract: "Conclusion / rental contract",
    specialCancellationRight: "Special right of cancellation",
    rentInEurPerParkingSpace: "Rent per parking space",
    deposit: "Deposit",
    reminder: "Reminder",
    new: "New",
    assetManager: "Asset manager",
    costsForModification: "Costs for modification",
    art: "Art",
    options: "Options",
    remainingTerm: "Remaining term",
    remainingTermInEur: "Remaining term, €",
    usageName: "Usage",
    tenantCategoryName: "Category",
    netRentPerM2: "Net rent per m2",
    totalNetRentPerYear: "Net rent per year",
    totalNetRentPerMonth: "Total net rent per month",
    tax: "Tax",
    totalGrossNetRentPerMonth: "Total gross per month",
    totalGrossNetRentPerYear: "Total gross per year",
    othersIncomingsEur: "Additional costs",
    emptySpace: "Empty space",
    emptySpaceAtPurchase: "Empty space at purchase",
    squareMetersRequired: "Area square in m2 is required",
    commercialLiving: "Commercial/Living",
    areaName: "Area name",
    areaSqureInM2: "Area square in m2",
    createArea: "Create Area",
    createAreaButton: "Create Area",
    areaWasSuccessfullyCreated: "Area created successfully",
    createTenant: "Create Tenant",
    addTenant: "Add tenant",
    tenantWasSuccessfullyCreated: "Tenant created successfully",
    saveHistory: "Keep area history",
    importTitle: "Import Areas",
    extendContract: "Extend contract",
    updateTenant: "Update Tenant",
    searchArea: "Search Area",
    submit: "Submit",
    cancelTheLeaseOn: "Cancel the lease on",
    updateTenantLease: "Update tenant lease",
    nextTenantAvailable: "Next tenant available",
    selectAll: "Select All",
  },
  tenants: {
    areaDesignation: "Area Designation",
    chooseType: "choose type",
    rentalAreaInM2: "Rental area in m²",
    use: "use",
    indexing: "indexing",
    calculationResult: {
      business: "Commercial",
      living: "Living",
      livingSqm: "Breakdown of property area by rented and vacant space in sqm",
      vacancy: "Vacancy",
      rented: "Rented",
      reside: "Reside",
      total: "Total",
      pa: "annually",
      pm: "monthly",
      averageNetRentPerSquareMeter: "Average net rent in € per sqm",
      calculatedRemainingTerm: "Net Cold Rent",
      remainingTermTotal: "Remaining term in €",
      commercialAndLivingArea: "Commercial and living area in sqm",
      landAllocation: "Land allocation",
      objectTotal: "Object Total",
      businessInSqm: "Commercial area",
      livingInSqm: "Living area",
      rentableArea: "Rentable area",
      totalArea: "Total area",
      shareOfISTNME: "Share of net cold rent in",
      subdivisionOfRentedAndVacantSpace:
        "Subdivision of rented and vacant space",
      vacancyRate: "Vacancy rate",
      vacancyValued: "Potential of empty spaces in €",
    },
    selectContact: "Select Contact",
    createContact: "Create Contact",
  },
  navLinks: {
    assetManager: "Asset management",
    transactionManager: "Transaction management",
    release: "Release",
    dataSheet: "Object data sheet",
    tenantList: "Area list",
    quickSheet: "Quick sheet",
    invoices: "Invoices",
    contracts: "Contracts",
    new: "New",
    pending: "Pending",
    released: "Released",
    finalReleased: "Final released",
    rejected: "Rejected",
    existingOldContracts: "Existing old contracts",
    finalReleaser: "Final Releaser",
    offers: "Offers",
    insurances: "Insurances",
    building: "Building",
    liability: "Liability",
    edit: "QuickSheet",
    reminders: "Reminders",
    outgoingInvoices: "Outgoing invoices",
  },
  cost: {
    purchasePrice: "Purchase price",
    buildingInPercents: "Building in %",
    buildingInEuro: "Building in €",
    landInPercents: "Land in %",
    landInEuro: "Land in €",
    propertyTransferTax: "Property transfer tax in %",
    realEstateAgent: "Real estate agent in %",
    notary: "Notary / land register in %",
    dueDilligence: "Due Dilligence in %",
    other: "Other in %",
    buffer: "Buffer in %",
    otherEuro: "Other in €",
    bufferEuro: "Buffer in €",
    validation: {
      buildingInPercentsIsRequired: "Building in % is required",
      buildingInEuroIsRequired: "Building in euro is required",
      landInPercentsIsRequired: "Land in % is required",
      propertyTransferTaxIsRequired: "Property transfer tax is required",
      realEstateAgentIsRequired: "Real estate agent in % is required",
      notaryIsRequired: "Notary in % is required",
      dueDilligenceIsRequired: "Due dilligence is required",
      otherInPercentsIsRequired: "Other in % is required",
      bufferInPercentsIsRequired: "buffer in % is required",
    },
  },
  general: {
    fileUploadLimit: "Max 10 images can be upload",
  },
  noPictures: "No pictures available",
  more: "More",
  filterBy: "Filter by",
  from: "from",
  list: "List",
  grid: "Grid",
  imageUploadPlaceHolder: "Drag and drop your images here or",
  carouselFilesUpload: "Upload images/video",
  carouselImageUploadPlaceHolder: "Drag and drop your images/video here or",
  uploadFiles: "Upload images",
  rented: "Rented",
  rentalIncomePerYear: "Rental income per year",
  emptyAreaForm: {
    information: "Information",
    listing: "Listing",
    exposeHeadline: "Expose headline",
    street: "Street",
    houseNumber: "House number",
    zipCode: "ZIP code",
    city: "City",
    publishPropertyAddress: "Publish property address?",
    contactPerson: "Contact person",
    storeType: "Store type",
    deposit: "Deposit",
    propertyState: "Property state",
    constructionYear: "Construction year",
    numberOfParkingLots: "Number of parking lots",
    lastModernization: "Last modernization",
    marketingType: "Marketing type",
    price: "Price",
    validPriceForAreaOrPeriod: "Valid price for area / period",
    salesArea: "Sales area",
    totalArea: "Total area",
    subjectToCommission: "Subject to commission",
    propertyDescription: "Property description",
    location: "Location",
    equipment: "Equipment",
    otherInformation: "Other information",
    informationUpdatedSuccessfully: "Information updated successfully",
    listingUpdatedSuccessfully: "Listing updated successfully",
  },
  confirmRemoveText:
    "Do you really want to delete the property and with it all the content belonging to the property?",
};
