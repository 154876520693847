import http from "../utils/http";
import { BankAccount as BEBankAccount } from "../types/be/bankAccount";
import { BankAccount as FEBankAccount } from "../types/fe/bankAccount";
/* istanbul ignore next */
export const getBankAccounts = async (params = ""): Promise<Response> => {
  return await http.get(`/api/bank-accounts${params}`);
};
/* istanbul ignore next */
export const getBankAccount = async (id = ""): Promise<Response> => {
  return await http.get(`/api/bank-accounts/${id}`);
};
/* istanbul ignore next */
export const banksAutocomplete = async (params: string): Promise<Response> => {
  return await http.get(`/api/fin-api/banks${params}`);
};

export const bankConnectionImport = async (data: {
  bank_connection_name: string;
  bank_id: number;
  redirect_url?: "";
}): Promise<Response> => {
  return await http.post(`/api/fin-api/bank-connections`, data);
};

export const getBankPayments = async (params: string): Promise<Response> => {
  return await http.get(`/api/bank-payments${params}`);
};

export const createBankAccount = async (
  bankAccount: Partial<BEBankAccount>
): Promise<Response> => {
  return await http.post(`/api/bank-accounts`, bankAccount);
};

export const updateBankAccount = async (
  bankAccount: FEBankAccount
): Promise<Response> => {
  return await http.put(`/api/bank-accounts/${bankAccount.id}`, bankAccount);
};

export const getAreasByBankAccountId = async (
  bankAccountId: number
): Promise<Response> => {
  return await http.get(`/api/bank-accounts/${bankAccountId}/areas`);
};
/* istanbul ignore next */
export const updatePayment = async (
  bankAccountId: number,
  body: {
    is_ignored?: boolean;
    area_id?: number;
  }
): Promise<Response> => {
  return await http.put(`/api/bank-payments/${bankAccountId}`, body);
};

/* istanbul ignore next */
export const deleteBankAccount = async (id: number): Promise<Response> => {
  return await http.delete(`/api/bank-accounts/${id}`);
};
/* istanbul ignore next */
export const downloadSales = async (): Promise<Response> => {
  return await http.get(`/api/bank-payments/import/example-xls`);
};
/* istanbul ignore next */
export const importSales = async (body: FormData): Promise<Response> => {
  return await http.post(`/api/bank-payments/import/xls`, body);
};
/* istanbul ignore next */
export const sync = async (): Promise<Response> => {
  return await http.post(`/api/fin-api/sync`, {});
};

/* istanbul ignore next */
export const getBankPaymentsGraph = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/bank-payments/graph${params}`);
};

/* istanbul ignore next */
export const syncOneBankAccount = async (id: number): Promise<Response> => {
  return await http.get(`/api/bank-accounts/${id}/sync`);
};
