import { FEDebtor } from "../components/properties/invoices/apidatev/debtor/create/types";
import http from "../utils/http";

export const getDebtors = async (
  params?: string,
  cancelPrevious = false
): Promise<Response> => {
  return await http.get(`/api/datev/debitors${params}`, cancelPrevious);
};

export const deleteDebtor = async (debtorId: number): Promise<Response> => {
  return await http.delete(`/api/datev/debitors/${debtorId}`);
};

export const createDebtor = async (payload: FEDebtor): Promise<Response> => {
  return await http.post(`/api/datev/debitors`, payload);
};

export const bulkDelete = async (): Promise<Response> => {
  return await http.delete("/api/datev/debitors");
};
