import React, { ReactElement, useCallback, useEffect, useState } from "react";
import useTable from "../../../../ui/table/useTable";
import Table from "../../../../ui/table/Table";
import { Grid, TableCell, Typography } from "@mui/material";
import { EditIcon, DeleteIcon } from "../../../../ui/table/styled";
import { handleSuccessfulNewLocationType, LocationListData } from "./types";
import {
  handleSubmitLocation,
  itemsListHeadCells,
  getLocationInitialState,
} from "./utils";
import * as Yup from "yup";
import { handleServerError } from "../../../../utils/http";
import useIsMounted from "../../../../hooks/useIsMounted";
import AlertCustom from "../../../../ui/formAlert/FormAlert";
import Form from "./LocationForm";
import { Formik } from "formik";
import useValidation from "../../../../hooks/useValidation";
import { FormMessageInItState } from "../../../../constants";
import { FormMessageInterface } from "../../../../types/form";
import {
  deleteLocation,
  getEmployeeScheduleOptions,
} from "../../../../api/userSchedules";

const LocationList = ({
  handleSuccessfulNewLocation,
}: handleSuccessfulNewLocationType): ReactElement => {
  const [isCreate, setIsCreate] = useState<boolean>(true);
  const [row, setRow] = useState<LocationListData>();
  const [locationItemId, setLocationItemId] = useState<number>(0);
  const [locationItemName, setLocationItemName] = useState<string>("");
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const isMounted = useIsMounted();
  const {
    data,
    setData,
    total,
    order,
    orderBy,
    setTotal,
    isLoading,
    setError,
    error,
    rowsPerPage,
    queryParams,
    setIsLoading,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleCellClick,
    openConfirmToRemoveModal,
    rowToDelete,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    handleDeleteLastPageData,
  } = useTable<LocationListData>();
  const { name } = useValidation();

  useEffect(() => {
    setData([{}]);
  }, []);

  const handleEditClick = (row: LocationListData): void => {
    setRow(row);
    setIsCreate(false);
  };

  const fetchLocation = useCallback(async () => {
    if (!queryParams) return;
    setIsLoading(true);
    const res = await getEmployeeScheduleOptions(
      `${queryParams}&is_editable=1`
    );

    isMounted() && handleServerResponse(res);
  }, [queryParams, setTotal, setData, setIsLoading]);

  const handleServerResponse = async (response: Response): Promise<void> => {
    if (response.status !== 200) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const resJson = await response.json();
      setTotal(resJson.meta.total);
      setData(resJson.data);
    }
    setIsLoading(false);
  };

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteLocation(rowToDelete);
    handleDeleteLastPageData(fetchLocation);
    handleConfirmToRemoveModalClose();
    handleSuccessfulNewLocation();
  }, [
    rowToDelete,
    deleteLocation,
    fetchLocation,
    handleConfirmToRemoveModalClose,
  ]);

  useEffect(() => {
    isMounted() && fetchLocation();
  }, [queryParams]);

  const renderRow = (row: LocationListData): ReactElement => (
    <>
      <TableCell>
        <Typography noWrap>{row.name}</Typography>
      </TableCell>
      <TableCell
        padding="none"
        align="right"
        onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
          handleCellClick(e)
        }
        style={{ whiteSpace: "nowrap" }}
      >
        <EditIcon
          role={"editIconRole"}
          onClick={(): void => handleEditClick(row)}
          size={18}
          data-testid={`${row.id}-edit-icon`}
        />
        <DeleteIcon
          onClick={(): void => openConfirmToRemoveModal(row)}
          size={20}
          data-testid="delete-icon"
        />
      </TableCell>
    </>
  );

  const TableToolbar = (
    <Grid container>
      <Grid item>
        {formMessage.text && (
          <AlertCustom
            formMessage={formMessage}
            sx={{ marginBottom: "10px" }}
          />
        )}
        <Formik
          initialValues={getLocationInitialState(
            locationItemId,
            locationItemName
          )}
          validationSchema={Yup.object().shape({
            name,
          })}
          enableReinitialize
          onSubmit={handleSubmitLocation({
            isCreate,
            row,
            setFormMessage,
            fetchLocation,
            setIsCreate,
            setRow,
            handleSuccessfulNewLocation,
          })}
        >
          {(props): React.ReactElement => (
            <Form
              isCreate={isCreate}
              row={row}
              setLocationItemName={setLocationItemName}
              setLocationItemId={setLocationItemId}
              {...props}
            />
          )}
        </Formik>
      </Grid>
      <Grid item xs />
    </Grid>
  );

  return (
    <Table
      data={data}
      total={total}
      currentPage={currentPage}
      order={order}
      orderBy={orderBy}
      error={error}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={handleChangePage}
      onRowRemove={handleRowRemove}
      onSortChange={handleSortChange}
      onSelectAllClick={handleSelectAllClick}
      onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
      rowsPerPage={rowsPerPage}
      isLoading={isLoading}
      isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
      listHeadCells={itemsListHeadCells}
      renderRow={renderRow}
      tableToolbar={TableToolbar}
      tableDataMaxHeight={"50vh"}
    />
  );
};

export default LocationList;
