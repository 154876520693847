import React, { ReactElement, useEffect, useState } from "react";
import Button from "../../../ui/button/Button";
import Snackbar from "../../../ui/Snackbar";
import { useTranslation } from "react-i18next";
import { Grid, Stack } from "@mui/material";
import { FormikValues } from "formik/dist/types";
import TextField from "../../../ui/formsFields/text";
import {
  FORMAT_NUMBER_DE,
  INPUT_MIN_HEIGHT,
  Measurement_Units,
  PAGE_LIMIT,
  SUBMIT_STATUS,
  UserRoles,
} from "../../../constants";
import SelectOrCreateContactsAutocompleteField from "../../contacts/autocomplete";
import { handleDatepicker } from "../../../utils/form";
import { DatePicker } from "@mui/x-date-pickers";
import CompanyAutocomplete from "../../contacts/companyAutocomplete";
import { Company } from "../../../types/models";
import CreateCompanyModal from "../../contacts/createCompanyModal";
import { createCompanyFormInitState } from "../../contacts/createCompanyModal/utils";
import { FormatNumber } from "../../../ui/formsFields/formatNumber";
import { PlusIcon } from "../../../ui/table/styled";
import UsersAutocomplete from "../../users/autocomplete";
import Text from "../../../ui/formsFields/text";
import { useFetch } from "../../../hooks/useFetch";
import { User } from "../../../types/be/user";
import { prepareQueryParams } from "../../../utils/common";
import { getUsers } from "../../../api/users";
import { route } from "../../../utils/url";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
/* istanbul ignore next */
function CreateAndEditLOIForm({
  handleSubmit,
  isSubmitting,
  status,
  edit = false,
  errors,
  values,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  submitAction,
  setSubmitAction,
}: FormikValues): React.ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [companyAutocompleteValue, setCompanyAutocompleteValue] =
    useState<Company | null>(values.company?.title ? values.company : null);
  const [isCreateCompanyModalOpen, setIsCreateCompanyModalOpen] =
    useState<boolean>(false);
  const [companyWasCreated, setCompanyWasCreated] = useState(false);
  const [initialValues, setInitialValues] = useState(
    createCompanyFormInitState
  );

  const { data: preloadedUser, run: runPreloadedUser } = useFetch<User[]>();

  const handleCreateCompanyModalClose = (): void => {
    setInitialValues(createCompanyFormInitState);
    setIsCreateCompanyModalOpen(false);
  };

  const handleClose = (): void => {
    setIsSnackbarVisible(false);
    setCompanyWasCreated(false);
  };

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  useEffect(() => {
    companyAutocompleteValue !== null &&
      setFieldValue("company_id", companyAutocompleteValue?.id);
  }, [companyAutocompleteValue]);

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
    setCompanyWasCreated(companyWasCreated);
  }, [status, setCompanyWasCreated]);

  useEffect(() => {
    const params = prepareQueryParams("", {
      limit: String(PAGE_LIMIT._10),
      role_code: UserRoles.TRANSACTION_MANAGER,
    });
    runPreloadedUser(getUsers(params));
  }, []);

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={6} columns={12}>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={11}>
                <CompanyAutocomplete
                  status={status}
                  name={"company_id"}
                  initialValue={companyAutocompleteValue}
                />
              </Grid>
              <Grid item xs={1} pt={3}>
                <PlusIcon
                  role={"dataItems-create-company"}
                  onClick={() => setIsCreateCompanyModalOpen(true)}
                  size={18}
                  style={{ cursor: "pointer" }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectOrCreateContactsAutocompleteField
              {...{
                errors,
                values,
                touched,
                status,
                handleBlur,
                handleChange,
                setFieldValue,
              }}
              label={t("createContact.searchContact")}
              placeholder={t("createContact.searchContact")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="recipient_street"
              required
              inputProps={{ "data-testid": "recipient-street-input" }}
              label={t("transactionManagement.loi.recipientStreet")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="recipient_city"
              required
              inputProps={{ "data-testid": "recipient-city-input" }}
              label={t("transactionManagement.loi.recipientCity")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="city"
              required
              inputProps={{ "data-testid": "city-input" }}
              label={t("transactionManagement.loi.city")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DatePicker
              openTo="year"
              views={["year", "month", "day"]}
              inputFormat="dd/MM/yyyy"
              value={values.date}
              label={t("transactionManagement.loi.date")}
              onChange={handleDatepicker({ setFieldValue }, "date")}
              renderInput={(params): ReactElement => (
                <TextField
                  name="date"
                  type="date"
                  required
                  status={status}
                  sx={{ minHeight: INPUT_MIN_HEIGHT }}
                  value={values.date}
                  fullWidth
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormatNumber
              name="purchase_price"
              label={t("transactionManagement.loi.purchasePrice")}
              required
              size="medium"
              status={status}
              fullWidth
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{
                "data-testid": "purchase_price",
                filter: FORMAT_NUMBER_DE,
                unit: Measurement_Units.EURO,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="object_name"
              required
              inputProps={{ "data-testid": "object_name" }}
              label={t("transactionManagement.loi.objectName")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name="first_line_of_email"
              required
              inputProps={{ "data-testid": "first-line-of-email-input" }}
              label={t("transactionManagement.loi.firstLineOfEmail")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Text
              name="message"
              required
              value={values.message}
              label={t("transactionManagement.loi.htmlMessage")}
              rows={5}
              multiline
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="title_transaction_manager"
              required
              inputProps={{ "data-testid": "title-input" }}
              label={t("transactionManagement.loi.titleOfTransactionManager")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UsersAutocomplete
              status={status}
              roleCode={UserRoles.TRANSACTION_MANAGER}
              placeholder={"transactionManagement.loi.search"}
              label={"transactionManagement.loi.transactionManager"}
              user={values.user}
              optionLabel="full_name"
              preloadOptions={preloadedUser}
            />
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={0}
        >
          <Button
            title={t("transactionManagement.loi.preview")}
            sx={{ ml: "1rem", mb: "1rem" }}
            type="submit"
            size="large"
            onClick={() => {
              setSubmitAction("preview");
            }}
            disabled={submitAction === SUBMIT_STATUS.PREVIEW && isSubmitting}
            isLoading={submitAction === SUBMIT_STATUS.PREVIEW && isSubmitting}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
        >
          <Button
            type="button"
            color={"primary"}
            variant="text"
            size="large"
            title={t("cancel")}
            disabled={isSubmitting}
            onClick={() =>
              doesAnyHistoryEntryExist ? navigate(-1) : navigate(route("loi"))
            }
          />
          <Button
            title={edit ? t("update") : t("create")}
            sx={{ ml: "1rem" }}
            color="success"
            type="submit"
            size="large"
            onClick={() => {
              setSubmitAction(SUBMIT_STATUS.CREATE_OR_EDIT);
            }}
            disabled={
              submitAction === SUBMIT_STATUS.CREATE_OR_EDIT && isSubmitting
            }
            isLoading={
              submitAction === SUBMIT_STATUS.CREATE_OR_EDIT && isSubmitting
            }
          />
        </Stack>
        <Snackbar
          message={t(
            companyWasCreated
              ? "createContact.companyWasSuccessfullyCreated"
              : "userWasSuccessfullyCreated"
          )}
          open={isSnackbarVisible || companyWasCreated}
          handleClose={handleClose}
        />
      </form>
      <CreateCompanyModal
        isCreateCompanyModalOpen={isCreateCompanyModalOpen}
        handleCreateCompanyModalClose={handleCreateCompanyModalClose}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        setCompanyAutocompleteValue={setCompanyAutocompleteValue}
        setCompanyWasCreated={setCompanyWasCreated}
      />
    </>
  );
}

export default CreateAndEditLOIForm;
