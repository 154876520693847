/* istanbul ignore next */
import http from "../utils/http";
import { OfferTopic as FEOfferTopic } from "../types/fe.interfaces";

export const getOfferTopics = async (params: string): Promise<Response> => {
  return await http.get(`/api/offer-topics${params}`);
};
/* istanbul ignore next */
export const createOfferTopic = async (
  data: FEOfferTopic
): Promise<Response> => {
  return await http.post("/api/offer-topics", data);
};
/* istanbul ignore next */
export const deleteOfferTopic = async (id: number): Promise<Response> => {
  return await http.delete(`/api/offer-topics/${id}`);
};
/* istanbul ignore next */
export const getOfferTopicsById = async (id: number): Promise<Response> => {
  return await http.get(`/api/offer-topics/${id}`);
};
/* istanbul ignore next */
export const resetOfferTopic = async (id: number): Promise<Response> => {
  return await http.patch(`/api/offer-topics/${id}/reset`, {});
};

/* istanbul ignore next */
export const getOfferTopicsSearch = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/offer-topics/search${params}`);
};

/* istanbul ignore next */
export const assignUserToOfferTopic = async (
  id: number,
  assignedUserId: number
): Promise<Response> => {
  return await http.patch(`/api/offer-topics/${id}/users/${assignedUserId}`, {
    assigned_user_id: assignedUserId,
  });
};
