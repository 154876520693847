import { TableCell } from "../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "user",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "companyOwnership.user",
  },
  {
    id: "operation",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "companyOwnership.operation",
  },
  {
    id: "whoAdded",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "companyOwnership.whoAdded",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "companyOwnership.createdDate",
  },
];

export const EVENT_DATE_AND_TIME_FORMAT = "DD.MM.yyyy HH:mm:ss";
