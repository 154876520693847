import React, { ChangeEvent, useEffect, useState } from "react";
import Button from "../../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import { Grid, Stack, MenuItem } from "@mui/material";
import { FormSectionTitle } from "../../styled";
import FormTextField from "../../../../ui/formsFields/textField";
import { Measurement_Units } from "../../../../constants";
import { FormikValues } from "formik/dist/types";
import Snackbar from "../../../../ui/Snackbar";
import { State } from "../../../../types/be/country";

function Form({
  handleSubmit,
  isSubmitting,
  status,
  initialStates,
  setFieldValue,
  values,
}: FormikValues & { initialStates: State[] }): React.ReactElement {
  const { t } = useTranslation();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

  const handleStateChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value },
    } = event;
    setFieldValue("state_id", value);
  };

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  const handleClose = (): void => setIsSnackbarVisible(false);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <FormSectionTitle>{t("financeProfile.title")}</FormSectionTitle>
      <Grid container spacing={6} columns={12}>
        <Grid item xs={12} sm={3}>
          <FormTextField
            type="number"
            name="zip_code"
            status={status}
            required
            label={t("financeProfile.zipCode")}
            inputProps={{ "data-testid": "zip-code-input" }}
          />
        </Grid>
        <Grid item xs={12} sm={3} data-testid="select-input">
          <FormTextField
            name="state_id"
            label={t("employeeOverview.federalState")}
            value={values?.state_id}
            onChange={handleStateChange}
            select
          >
            {initialStates
              ?.filter(({ abbreviation }) => abbreviation)
              ?.map(({ id, abbreviation, title }) => (
                <MenuItem
                  key={id}
                  value={id}
                  sx={{ justifyContent: "space-between" }}
                >
                  <span>{title}</span>
                  <span className="state-abbreviation">{abbreviation}</span>
                </MenuItem>
              ))}
          </FormTextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormTextField
            name="finance_volume_from_value"
            status={status}
            required
            label={t("financeProfile.financeVolumeFromValue")}
            inputProps={{
              "data-testid": "finance-volume-from-value-input",
              filter: "formatNum",
              unit: Measurement_Units.EURO,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormTextField
            name="finance_volume_to_value"
            status={status}
            required
            label={t("financeProfile.financeVolumeToValue")}
            inputProps={{
              "data-testid": "finance-volume-to-value-input",
              filter: "formatNum",
              unit: Measurement_Units.EURO,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6} columns={12}>
        <Grid item xs={12} sm={3}>
          <FormTextField
            name="ltv"
            status={status}
            required
            label={t("financeProfile.ltv")}
            inputProps={{
              "data-testid": "ltv-input",
              filter: "formatNum",
              unit: Measurement_Units.EURO,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormTextField
            name="avg_interest_rate"
            type="number"
            status={status}
            required
            label={t("financeProfile.avgInterestRate")}
            inputProps={{
              "data-testid": "avg-interest-rate-input",
              maxLength: 2,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormTextField
            name="avg_repayment"
            status={status}
            required
            label={t("financeProfile.avgRepayment")}
            inputProps={{
              "data-testid": "avg-repayment-input",
              filter: "formatNum",
              unit: Measurement_Units.EURO,
            }}
          />
        </Grid>
      </Grid>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={0}
      >
        <Grid item>
          <Button
            testId="finance-submit"
            title={t("save")}
            color="success"
            type="submit"
            size="large"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
        </Grid>
      </Stack>
      <Snackbar
        message={t("createContact.financeProfileWasSuccessfullyUpdated")}
        color="success"
        open={isSnackbarVisible}
        handleClose={handleClose}
      />
    </form>
  );
}

export default Form;
