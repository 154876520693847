import React, { createContext, ReactElement, useMemo, useReducer } from "react";
import { ActionMap } from "../../../types/fe/context";
import {
  FileManagementFolderContainerActionTypes,
  FileManagementFolderContainerContextState,
  FileManagementFolderContainerContextType,
  PreviewDialogVisibility,
  SET_IS_DIALOG_VISIBLE,
  SET_IS_FILE_LOADING,
  SET_OPTIMIZED,
  SET_PRELOADING,
  SET_PREVIEW_FILE,
  SET_VISIBILITY,
} from "../types";
/* istanbul ignore next */
const initState: FileManagementFolderContainerContextState = {
  isDialogVisible: false,
  previewFile: null,
  visibility: PreviewDialogVisibility.HIDDEN,
  optimized: false,
  preloading: false,
  isFileLoading: false,
};
/* istanbul ignore next */
const FileManagementFolderContainerContext =
  createContext<FileManagementFolderContainerContextType>(undefined!);
/* istanbul ignore next */
const reducer = (
  state: FileManagementFolderContainerContextState,
  action: ActionMap<FileManagementFolderContainerActionTypes>[keyof ActionMap<FileManagementFolderContainerActionTypes>]
): FileManagementFolderContainerContextState => {
  switch (action.type) {
    case SET_IS_DIALOG_VISIBLE:
      return {
        ...state,
        isDialogVisible: action.payload,
      };
    case SET_PREVIEW_FILE:
      return {
        ...state,
        previewFile: action.payload,
      };
    case SET_VISIBILITY:
      return {
        ...state,
        visibility: action.payload,
      };
    case SET_OPTIMIZED:
      return {
        ...state,
        optimized: action.payload,
      };
    case SET_PRELOADING:
      return {
        ...state,
        preloading: action.payload,
      };
    case SET_IS_FILE_LOADING:
      return {
        ...state,
        isFileLoading: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
/* istanbul ignore next */
function FileManagementFolderContainerProvider({
  children,
}: {
  children: ReactElement;
}): React.ReactElement {
  const [state, dispatch] = useReducer(reducer, initState);

  const fileManagementFolderContainerContext = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return (
    <FileManagementFolderContainerContext.Provider
      value={fileManagementFolderContainerContext}
    >
      {children}
    </FileManagementFolderContainerContext.Provider>
  );
}

export {
  FileManagementFolderContainerProvider,
  FileManagementFolderContainerContext,
};
