import styled from "styled-components/macro";

import {
  Chip as MuiChip,
  Card,
  Grid,
  Typography,
  Paper,
  TextField as MuiTextField,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import FormAlert from "../../../../../ui/formAlert/FormAlert";
import MultiSelectComponent from "../../../../../ui/formsFields/multiSelect";
import { blue, green, red } from "@mui/material/colors";
/* istanbul ignore next */
export const SelectBox = styled(Box)`
  gap: ${(props): string => props.theme.spacing(0.5)};
  display: flex;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;
/* istanbul ignore next */
export const AMHistoryButton = styled.span`
  font-size: ${(props): string => props.theme.typography.fontSize};
  cursor: pointer;
  background: ${blue[700]};
  font-weight: 500;
  color: white;
  padding: 0 3px;
  border-radius: 8px;
  float: right;
`;

export const ErrorBox = styled(FormAlert)(spacing);
/* istanbul ignore next */
export const Chip = styled(MuiChip)`
  height: ${(props): string => props.theme.spacing(7)};
`;

/* istanbul ignore next */
export const MultiSelect = styled(MultiSelectComponent)`
  height: ${(props): string => props.theme.spacing(13)};
  min-height: ${(props): string => props.theme.spacing(13)};
`;

/* istanbul ignore next */
export const EditInfoCard = styled(Card)`
  position: relative;
  .edit-icon {
    position: absolute;
    right: ${(props): string => props.theme.spacing(5)};
    top: ${(props): string => props.theme.spacing(5)};
    cursor: pointer;
    color: ${(props): string => props.theme.palette.primary.main};
  }
  .close-icon {
    color: ${red[500]};
    position: absolute;
    right: ${(props): string => props.theme.spacing(3)};
    top: ${(props): string => props.theme.spacing(2)};
    font-size: ${(props): string => props.theme.spacing(7)};
    cursor: pointer;
  }
  .save-icon {
    position: absolute;
    right: ${(props): string => props.theme.spacing(12)};
    top: ${(props): string => props.theme.spacing(2)};
  }
  padding: 0px !important;
`;

/* istanbul ignore next */
export const RentedInfoContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props): string => `${props.theme.spacing(1)}`};
  flex-direction: column;
  padding: ${(props): string => `${props.theme.spacing(4)}`};
  &.row-start {
    justify-content: flex-start;
    flex-direction: row;
  }
  &.border-bottom {
    border-bottom: ${(props): string => `${props.theme.spacing(0.5)}`} solid
      ${(props): string => props.theme.border.color};
  }
  &.align-start {
    align-items: flex-start;
  }
`;
/* istanbul ignore next */
export const LogoContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${(props): string => `${props.theme.spacing(0, 4, 4, 4)}`};
`;

/* istanbul ignore next */
export const SmallLightText = styled(Typography)`
  font-size: ${(props): string => `${props.theme.spacing(3.5)}`};
  color: ${(props): string => props.theme.palette.grey[500]};
  font-weight: 500;
  font-style: normal;
  text-align: center;
`;

/* istanbul ignore next */
export const FormWrapper = styled(Paper)<{ $fullwidth?: boolean }>`
  width: 100%;
  text-align: left;
  ${(props): string => props.theme.breakpoints.up("lg")} {
    max-width: ${(props): string => (props.$fullwidth ? "100%" : "95%")};
  }
  ${(props): string => props.theme.breakpoints.up("xl")} {
    max-width: ${(props): string => (props.$fullwidth ? "100%" : "85%")};
  }
  ${(props): string => props.theme.breakpoints.down("xs")} {
    max-width: 100%;
  }
  padding: 0px !important;
`;
/* istanbul ignore next */
export const AssetManagersAutocompleteTextField = styled(MuiTextField)`
  .MuiInputBase-root:not(.small) {
    padding-right: ${(props): string => props.theme.spacing(0)} !important;
    .more-info {
      position: absolute;
      bottom: 0;
      left: ${(props): string => props.theme.spacing(3.25)};
      font-size: ${(props): string => props.theme.spacing(2.75)};
    }
  }
`;
/* istanbul ignore next */
export const DownloadFileText = styled(Typography)`
  font-size: ${(props): string => `${props.theme.spacing(3.5)}`};
  color: ${(props): string => props.theme.palette.primary.main};
  font-weight: 500;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props): string => `${props.theme.spacing(1)}`};
  cursor: pointer;
`;

/* istanbul ignore next */
export const DeleteIconContainer = styled(Box)`
  position: absolute;
  right: ${(props): string => props.theme.spacing(3)};
  top: ${(props): string => props.theme.spacing(3)};
  cursor: pointer;
`;

/* istanbul ignore next */
export const PropertyLogo = styled.img`
  width: ${(props) => props.theme.spacing(30)};
  height: ${(props) => props.theme.spacing(30)};
  object-fit: contain;
`;

/* istanbul ignore next */
export const DropZoneBox = styled(Box)<{
  additionalstyle: string;
}>`
  text-align: center;
  padding: ${(props): string => props.theme.spacing(5)};
  border: ${(props): string => props.theme.spacing(0.5)} dashed
    ${(props): string =>
      props.additionalstyle === "accept"
        ? green[500]
        : props.additionalstyle === "reject"
        ? red[500]
        : props.theme.palette.textColor.default};
  border-radius: ${(props): string => props.theme.spacing(2.5)};
  height: 100%;
  min-height: ${(props): string => props.theme.spacing(44)};
  width: 100%;
  display: flex;
  gap: ${(props): string => props.theme.spacing(2.5)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .fileName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;
