import { TableCell } from "../../../../../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "fileName",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "name",
  },
  {
    id: "year",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "tenant.year",
  },
  {
    id: "date_bka",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenant.invoiceDate",
  },
  {
    id: "comment",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.history.comment",
    align: "left",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenant.paymentDateBKA",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenant.amountBKA",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];
