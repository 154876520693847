import React, { ReactElement, useState } from "react";
import FormAlert from "../../../../../../ui/formAlert/FormAlert";
import { useTranslation } from "react-i18next";
import { DialogContent, Grid } from "@mui/material";
import Dropzone from "../../../../../../ui/dropzone";
import { FileInfo, Paper } from "./styled";
import Button from "../../../../../../ui/button/Button";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import useValidation from "../../../../../../hooks/useValidation";
import { UploadFileFormValues, UploadFileProps } from "./types";
import { handleUploadFile } from "./utils";
import { Dialog } from "@mui/material";
import DialogTitle from "../../../../../../ui/dialog/DialogTitle";
import { compareFileSize } from "../../../../../../utils/common";
import { MAX_PDF_FILE_SIZE } from "../../../../../../constants";

/* istanbul ignore next */
const UploadFile = ({
  afterUploadFile,
  setSnackbarData,
  onClose,
  open,
}: UploadFileProps): ReactElement => {
  const { t } = useTranslation();
  const { pdfFile } = useValidation();
  const { id = "" } = useParams();

  const initialValues: UploadFileFormValues = {
    property_id: id!,
    file: null,
  };

  const [isDropzoneVisible, setIsDropzoneVisible] = useState(true);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="max-width-dialog-title"
      data-testid="upload-file-modal"
    >
      <DialogTitle
        title="property.objectDataSheet.attachments.uploadFile"
        onClose={onClose}
      />
      <DialogContent>
        <Paper>
          <Formik
            initialValues={initialValues}
            onSubmit={handleUploadFile({
              setSnackbarData,
              t,
              afterUploadFile,
            })}
            validationSchema={Yup.object().shape({
              file: pdfFile,
            })}
          >
            {({
              isSubmitting,
              handleSubmit,
              setFieldValue,
              errors,
              values,
              setFieldError,
            }: FormikProps<UploadFileFormValues>) => (
              <Grid
                container
                data-testid="dropzone-container"
                onDragOver={() => {
                  setIsDropzoneVisible(true);
                }}
                spacing={4}
              >
                <Grid item xs={12}>
                  {errors?.file && (
                    <FormAlert
                      formMessage={{
                        type: "error",
                        text: errors?.file,
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Dropzone
                    open={isDropzoneVisible}
                    onDrop={async <T extends File>(
                      files: FileList | T[] | null
                    ): Promise<void> => {
                      const file = files?.[0];
                      if (
                        file &&
                        compareFileSize(file.size, MAX_PDF_FILE_SIZE)
                      ) {
                        setFieldError("file", t("fileSize100MB"));
                      } else {
                        setFieldError("file", "");
                        setFieldValue("file", files?.[0]);
                      }
                    }}
                  />
                </Grid>
                {values?.file && !errors?.file ? (
                  <Grid item xs={12}>
                    <FileInfo>
                      <span className="file-name">{values?.file?.name}</span>
                      <span
                        onClick={() => setFieldValue("file", null)}
                        className="remove-btn"
                      >
                        {t("remove")}
                      </span>
                    </FileInfo>
                  </Grid>
                ) : null}
                <Grid
                  item
                  sx={{ display: "flex", justifyContent: "end" }}
                  xs={12}
                >
                  <Button
                    title={t("property.upload")}
                    testId="upload-file-btn"
                    color="success"
                    size="large"
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                  />
                </Grid>
              </Grid>
            )}
          </Formik>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default UploadFile;
