import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useTable from "../../../ui/table/useTable";
import { IdPropType, User } from "../../../types/models";
import { handleServerError } from "../../../utils/http";
import { Grid, TableCell } from "@mui/material";
import { formatTimestamp } from "../../../utils/date";
import {
  DeleteIcon,
  EditIcon,
  Input,
  Search,
  SearchIconWrapper,
} from "../../../ui/table/styled";
import { Search as SearchIcon } from "react-feather";
import useIsMounted from "../../../hooks/useIsMounted";
import Table from "../../../ui/table/Table";
import { itemsListHeadCells } from "../utils";
import {
  deleteVacationRequest,
  getVacationList,
  getVacationRequestStatuses,
} from "../../../api/vacationRequests";
import {
  UserInVacationRequest,
  VacationRequest,
} from "../../../types/be/vacationRequests";
import FilterByVacationStatus from "./filterByVacationStatus";
import { objectGetParamsToString } from "../../../utils/common";
import {
  PermissionsCreate,
  PermissionsDelete,
  PermissionsUpdate,
  VACATION_REQUEST_STATUS_ID_PARAM,
} from "../../../constants";
import { Status } from "../../../types/be/status";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { StyledButton } from "../../../ui/button/styled";
import CreateEditVacationRequest from "./createAndEdit";
import Snackbar from "../../../ui/snackbar1";
import { initInsuranceRange } from "./createAndEdit/utils";
import useAccessControl from "../../../hooks/useAccessControl";

const VacationList = (): ReactElement => {
  const { can } = useAccessControl();
  const [vacationStatuses, setVacationStatuses] = useState<Status[]>([]);
  const [vacationStatusId, setVacationStatusId] = useState<number>(0);
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [editRowId, setEditRowId] = useState<number | null>(null);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] =
    useState<UserInVacationRequest | null>(null);
  const [range, setRange] = useState<(Date | null)[]>(initInsuranceRange);
  const [rangeDifference, setRangeDifference] = useState<number | null>(null);
  const { t } = useTranslation();
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleDeleteLastPageData,
  } = useTable<User>();

  useEffect(() => {
    fetchVacationRequestStatuses();
  }, []);

  useEffect(() => {
    if (isMounted()) fetchVacationList();
  }, [queryParams, vacationStatuses, vacationStatusId]);

  const fetchVacationList = useCallback(async () => {
    setIsLoading(true);
    let params = queryParams;
    if (vacationStatusId)
      params = `${queryParams}&${objectGetParamsToString({
        [VACATION_REQUEST_STATUS_ID_PARAM]: vacationStatusId,
      })}`;
    const res = await getVacationList(params);
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const resJson = await res.json();
      setTotal(resJson.meta.total);
      setData(resJson.data);
      setIsLoading(false);
    }
  }, [
    queryParams,
    setIsLoading,
    setTotal,
    setData,
    setIsLoading,
    vacationStatusId,
  ]);

  const fetchVacationRequestStatuses = useCallback(async () => {
    setIsLoading(true);
    const res = await getVacationRequestStatuses();
    if (res.status !== HTTP_STATUS_CODES.OK) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const resJson = await res.json();
      setVacationStatuses(resJson.data);
      setIsLoading(false);
    }
  }, [setIsLoading, setIsLoading]);

  const openCreateEditModal = (id?: number): void => {
    id ? setEditRowId(id) : setEditRowId(null);
    setIsCreateEditModalOpen(true);
  };

  const handleCloseCreateEditModal = (): void => {
    !editRowId && setRange(initInsuranceRange);
    !editRowId && setSelectedUser(null);
    !editRowId && setRangeDifference(null);
    setIsCreateEditModalOpen(false);
  };

  const handleSuccessVacationRequestSubmission = (): void => {
    setIsCreateEditModalOpen(false);
    fetchVacationList();
  };

  const handleSnackbarClose = (): void => setIsSnackbarVisible(false);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteVacationRequest(rowToDelete);
    handleDeleteLastPageData(fetchVacationList);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteVacationRequest,
    fetchVacationList,
    handleConfirmToRemoveModalClose,
  ]);

  const handleEditClick = useCallback(
    ({ id }: IdPropType): void => openCreateEditModal(id),
    []
  );

  const renderRow = (row: VacationRequest): ReactElement => {
    return (
      <>
        <TableCell>{`${row.user.first_name} ${row.user.last_name}`}</TableCell>
        <TableCell align="center">{formatTimestamp(row.from)}</TableCell>
        <TableCell align="center">{formatTimestamp(row.to)}</TableCell>
        <TableCell align="left">{row.vacation_request_status.name}</TableCell>
        <TableCell align="right">{row.user.remainder_year_vacations}</TableCell>
        <TableCell
          align="right"
          onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
            handleCellClick(e)
          }
          style={{ whiteSpace: "nowrap" }}
        >
          {can([PermissionsUpdate.VACATION_REQUEST]) && (
            <EditIcon
              role={"editIconRole"}
              onClick={(): void => handleEditClick(row)}
              size={20}
            />
          )}
          {can([PermissionsDelete.VACATION_REQUEST]) && (
            <DeleteIcon
              role={"deleteIconRole"}
              onClick={(): void => openConfirmToRemoveModal(row)}
              size={22}
              data-testid="delete-icon"
            />
          )}
        </TableCell>
      </>
    );
  };

  const TableToolbar = (
    <Grid container sx={{ pt: 5, mb: 2 }}>
      <Grid
        item
        xs={2}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
      >
        {can([PermissionsCreate.VACATION_REQUEST]) && (
          <Grid sx={{ pr: 5 }}>
            <StyledButton
              sx={{ mr: 1 }}
              type="button"
              onClick={(): void => openCreateEditModal()}
              size="small"
              color="success"
              variant="contained"
              data-testid={"dataItems-create-vacation-request"}
            >
              {t("create")}
            </StyledButton>
          </Grid>
        )}
        {vacationStatuses && (
          <Grid item sx={{ width: 200 }}>
            <Grid>
              <FilterByVacationStatus
                vacationStatuses={vacationStatuses}
                setVacationStatusId={setVacationStatusId}
                vacationStatusId={vacationStatusId}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs />
      <Grid item>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Input placeholder={t("search")} onChange={handleTableSearch} />
        </Search>
      </Grid>
    </Grid>
  );

  const isMounted = useIsMounted();

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onRowRemove={handleRowRemove}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        noDataIsAvailablePlaceholder={"table.noVacationsAreAvailable"}
      />
      <CreateEditVacationRequest
        editRowId={editRowId}
        isCreateEditModalOpen={isCreateEditModalOpen}
        handleCloseCreateEditModal={handleCloseCreateEditModal}
        handleSuccessVacationRequestSubmission={
          handleSuccessVacationRequestSubmission
        }
        setIsSnackbarVisible={setIsSnackbarVisible}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        range={range}
        setRange={setRange}
        rangeDifference={rangeDifference}
        setRangeDifference={setRangeDifference}
      />
      <Snackbar
        open={isSnackbarVisible}
        title={t(
          `vacationRequests.vacationRequestsWas${
            editRowId ? "Updated" : "Created"
          }Successfully`
        )}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
      />
    </>
  );
};
export default VacationList;
