import { MAX_REPEAT_DAY, MIN_REPEAT_DAY } from "../../../constants";

export default {
  uploadInvoice: "Upload Invoices",
  number: "Invoice number",
  name: "File name",
  fileUpload: "Upload Invoice",
  provider: "Invoice provider",
  CreateInvoice: "Create Invoice",
  editInvoice: "Edit Invoice",
  apportionablePercent: "Apportionable Percent",
  isDebitedAutomatically: "Will be debited automatically",
  isApportionable: "Apportionable",
  isConfidential_invoice: "Confidential invoice",
  isActiveInvoice: "Activate invoice",
  permanent: "Permanent",
  isConfidentialInvoice: "Confidential invoice",
  releaseDate: "Release date",
  apportionable: "Apportionable",
  confidential: "Confidential",
  invoiceAmount: "Amount",
  debitedAutomatically: "Debited automatically",
  date: "Invoice Date",
  invalidDate: "Please enter valid Date",
  SelectOffer: "Select Offer",
  invoiceSaveSuccessfully: "Invoice saved successfully",
  isPermanentInvoice: "Repeating invoice",
  dynamicFileName: "Dynamic File Name",
  forwardInvoice: "Forward Invoice",
  invoiceForwardSuccess: "Invoice forwarded successfully",
  currency: "Currency",
  error: {
    amountType: "Amount should be number",
    fileId: "Invoice File is required",
    contactId: "Contact is required",
    amount: "Amount is required",
    until: "Until date is required",
    total: "Total is required",
    monthly: "Monthly is required",
    contractId: "contract is required",
    offerId: "Offer is required",
    apportionablePercent: "Apportionable percent is required",
    date: "Invoice date is required",
    comment: "Comment is required",
    pdfOnly: "Only PDF files are allowed",
    userId: "User is required",
    number: "Invoice number is required",
    currency: "Currency is required",
    currencyCode: "Currency code is required",
    dueDateMin: "Due date must be later than invoice date",
    invoiceDate: "Invoice date should not be in the future",
    invoiceProvider: "Invoice provider is required",
    invoiceAmountType: "Amount should be number",
    invoiceAmount: "Amount is required",
    repeatFrom: "Repeat from is required",
    repeatFromMinDay: "Repeat from can't be in the past",
    repeatTo: "Repeat to is required",
    repeatToMinDay: "Repeat to can't be greyer then Repeat from",
    repeatDay: "Repeat day is required",
    repeatDayMin: `Repeat day min value should be ${MIN_REPEAT_DAY}`,
    repeatDayMax: `Repeat day max value can't be greater then ${MAX_REPEAT_DAY}`,
    repetitionPeriodId: "Repetition period is required",
    pleaseConnectCreditorWithProvide: "Please connect creditor with provider",
  },
  show: {
    show: "Show Invoice",
    propertyName: "Property name",
    file: "File",
    total: "Total",
    monthly: "Monthly",
    createdAt: "Created at",
    createdBy: "Created by",
    comment: "Comment",
    amount: "Amount",
    contact: "Contact",
  },
  release: {
    title: "Message",
    commentRequired: "Message is required",
  },
  markAsPaid: "Mark as paid",
  markAsBooked: "Mark as booked",
  paid: "Paid",
  unpaid: "Unpaid",
  bankTransfer: "Paid/unpaid",
  booked: "Booked",
  notBooked: "Not booked",
  all: "All",
  monthly: "Monthly",
  halfYearly: "Half yearly",
  twoMonths: "2 Months",
  quarterly: "Quarterly",
  annual: "Annual",
  periodType: "Interval time",
  releaseAllSelectedInvoices: "Release all selected invoices",
  reviewAllSelectedInvoices: "Review all selected invoices",
  rejectAllSelectedInvoices: "Reject all selected invoices",
  markAllSelectedInvoicesAsUnpaid: "Mark all selected invoices as (un)paid",
  notSelectedInvoice: "Please select invoice",
  history: {
    title: "History",
    userName: "User name",
    clickedButton: "Clicked Button",
    fileName: "File name",
    invoiceNumber: "Invoice Number",
    dateOfUpdate: "Date of update",
    currentInvoiceStatus: "Current Invoice Status",
    comment: "Comment",
  },
  notSelectedInvoices: "Please select invoice",
  invoicesPerPage: "Invoices per page",
  showContact: "Show contact",
  createAll: "Create All",
  delete: "Delete",
  iban: "IBAN",
  bic: "BIC",
  directDebit: "Direct debit",
  deleteConfirmation: "Do you really want to delete the invoice?",
  searchProperty: "Search property",
  total: "Total amount",
  until: "Repeat calculation until",
  isAutomaticReleaseRepeatingInvoice:
    "After first final releasing the invoice, release all further invoices automatically",
  offer: "Select an offer",
  apidatev: {
    creditor: "Creditor",
    debtor: "Debtor",
    debitAccount: "Debit Account",
    konto: "Account",
    addresseeTypeCompany: "Name (addressee type company)",
    addresseeType: "Addressee type",
    abbreviation: "Abbreviation",
    addressType: "Address type",
    street: "Street",
    postalCode: "Postal code",
    location: "Location",
    codeCorrespondenceAddress: "Code Correspondence address",
    addressValidFrom: "Address Valid from",
    users: "User",
    date: "Date",
    deleteAll: "Delete All",
    import: "Import",
    importCreditor: "Import creditor",
    importDebitor: "Import debtor",
    importDebitAccount: "Import debit account",
    areYouSureYouWantToDeleteAllDebtor:
      "Are you sure you want to delete all Debtor?",
    config: "Config",
    companyNumber: "Company number",
    companyNumberUpdatedSuccessfully: "Company number updated successfully",
    companyNumberIsRequired: "Company number is required",
  },
  repeatFrom: "Repeat from",
  repeatTo: "Repeat to",
  repeatDay: "Repeat day",
  createInvoice: "Create Invoice",
  dateVInvoice: {
    upload: "Upload DATEv Invoice",
    dateOfPerformance: "Date of performance",
    bookingText: "Booking text",
    creditor: "Creditor/Biller",
    postingItems: "Posting items",
    debitAccount: "Debit account",
    vatIncl: "VAT Incl.",
    amountGross: "Amount gross",
    netAmount: "Net amount",
    invoiceAmountNet: "Invoice amount net",
    grossInvoiceAmount: "Gross invoice amount",
    biller: "Biller",
    contactAssignedSuccessfully: "Contact assigned successfully",
  },
  noLogoAvailable: "No logo available",
  providerInvoices: {
    all: "All",
    pending: "Pending",
    released: "Released",
    finalReleased: "Released",
    rejected: "Rejected",
    month: "Month",
    providerInvoices: "Provider invoices",
  },
  uploadOneInvoice: "Upload invoice",
};
