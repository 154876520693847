import { Layout } from "react-grid-layout";
import { PermissionsRead } from "../../constants";
import {
  ContractDoughnutChartData,
  InsuranceDoughnutChartData,
  InvoiceDoughnutChartData,
  OfferDoughnutChartData,
} from "../../types/be/dashboard";

export const dashboardWidgetsInitData = {
  users: 0,
  contacts: 0,
  netRent: 0,
  potential: 0,
  areaRented: 0,
  invoice: {
    count: 0,
    status: {
      new: 0,
      pending: 0,
      final_released: 0,
      rejected: 0,
    },
    list: [],
  } as InvoiceDoughnutChartData,
  insurance: {
    count: 0,
    status: {
      new: 0,
      pending: 0,
      final_released: 0,
      rejected: 0,
    },
    list: [],
  } as InsuranceDoughnutChartData,
  offer: {
    count: 0,
    status: {
      new: 0,
      pending: 0,
      final_released: 0,
      rejected: 0,
    },
    list: [],
  } as OfferDoughnutChartData,
  contracts: {
    count: 0,
    status: {
      new: 0,
      final_released: 0,
      existing_old_contracts: 0,
    },
    list: [],
  } as ContractDoughnutChartData,
  expiringTenantCount: 0,
  comments: [],
};

export const deleteWidgetIconStyle = {
  position: "absolute",
  right: "2px",
  fontSize: "15px",
  top: 0,
  cursor: "pointer",
};

export const WIDGET_IDS = Object.freeze({
  USERS: "users",
  AREA_RENTED: "areaRented",
  NET_RENT: "netRent",
  POTENTIAL: "potential",
  CONTACTS: "contacts",
  EXPIRING_TENANTS: "expiringTenants",
  PROPERTIES: "properties",
  INVOICES: "invoices",
  INSURANCES: "insurances",
  OFFERS: "offers",
  TENANT_TICKETS: "tenantTickets",
  CONTRACTS: "contracts",
  COMMENTS: "comments",
  BANK_ACCOUNT_PAYMENTS: "bankAccountPayments",
  API_DATEv_WIDGET: "apiDATEvWidget",
});

export const WIDGET_IDS_MODULE = Object.freeze({
  [WIDGET_IDS.USERS]: "",
  [WIDGET_IDS.AREA_RENTED]: "",
  [WIDGET_IDS.NET_RENT]: "",
  [WIDGET_IDS.POTENTIAL]: "",
  [WIDGET_IDS.CONTACTS]: "",
  [WIDGET_IDS.EXPIRING_TENANTS]: "",
  [WIDGET_IDS.COMMENTS]: "",
  [WIDGET_IDS.PROPERTIES]: "properties",
  [WIDGET_IDS.INVOICES]: "document-release",
  [WIDGET_IDS.INSURANCES]: "document-release",
  [WIDGET_IDS.OFFERS]: "document-release",
  [WIDGET_IDS.TENANT_TICKETS]: "mobile-app",
  [WIDGET_IDS.CONTRACTS]: "document-release",
  [WIDGET_IDS.BANK_ACCOUNT_PAYMENTS]: "",
  [WIDGET_IDS.API_DATEv_WIDGET]: "",
});

const statsMinMaxSize = {
  minW: 2,
  maxW: 4,
  minH: 3,
  maxH: 4,
};

const invoicesMinMaxSize = {
  minW: 6,
  minH: 6,
  maxH: 12,
  maxW: 9,
};

const insurancesMinMaxSize = {
  minW: 6,
  minH: 6,
  maxH: 12,
  maxW: 9,
};

const tenantTicketsMinMaxSize = {
  minW: 6,
  minH: 6,
  maxH: 12,
  maxW: 9,
};

const offersMinMaxSize = {
  minW: 6,
  minH: 6,
  maxH: 12,
  maxW: 9,
};

const contractsMinMaxSize = {
  minW: 6,
  minH: 6,
  maxH: 12,
  maxW: 9,
};

const propertiesMapMinMaxSize = {
  minW: 4,
  minH: 6,
  maxH: 12,
  maxW: 9,
};

const openCommentsMinMaxSize = {
  minW: 6,
  minH: 6,
  maxH: 12,
  maxW: 9,
};

const bankAccountPaymentsMinMaxSize = {
  minW: 6,
  minH: 9,
};

export const layoutLgInitSettings: Layout[] = [
  { i: WIDGET_IDS.USERS, x: 0, y: 0, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.AREA_RENTED, x: 3, y: 0, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.NET_RENT, x: 6, y: 0, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.POTENTIAL, x: 0, y: 4, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.CONTACTS, x: 3, y: 4, w: 3, h: 3, ...statsMinMaxSize },
  {
    i: WIDGET_IDS.EXPIRING_TENANTS,
    x: 6,
    y: 4,
    w: 3,
    h: 3,
    ...statsMinMaxSize,
  },
  {
    i: WIDGET_IDS.API_DATEv_WIDGET,
    x: 6,
    y: 4,
    w: 3,
    h: 3,
    ...statsMinMaxSize,
  },
  {
    i: WIDGET_IDS.PROPERTIES,
    x: 0,
    y: 10,
    w: 6,
    h: 12,
    ...propertiesMapMinMaxSize,
  },
  { i: WIDGET_IDS.INVOICES, x: 6, y: 10, w: 6, h: 12, ...invoicesMinMaxSize },
  {
    i: WIDGET_IDS.TENANT_TICKETS,
    x: 0,
    y: 10,
    w: 6,
    h: 12,
    ...tenantTicketsMinMaxSize,
  },
  {
    i: WIDGET_IDS.INSURANCES,
    x: 6,
    y: 10,
    w: 6,
    h: 12,
    ...insurancesMinMaxSize,
  },
  { i: WIDGET_IDS.OFFERS, x: 0, y: 10, w: 6, h: 12, ...offersMinMaxSize },
  { i: WIDGET_IDS.CONTRACTS, x: 6, y: 10, w: 6, h: 12, ...contractsMinMaxSize },
  {
    i: WIDGET_IDS.COMMENTS,
    x: 0,
    y: 10,
    w: 6,
    h: 12,
    ...openCommentsMinMaxSize,
  },
  {
    i: WIDGET_IDS.BANK_ACCOUNT_PAYMENTS,
    x: 6,
    y: 10,
    w: 6,
    h: 12,
    ...bankAccountPaymentsMinMaxSize,
  },
];

export const layoutMdInitSettings: Layout[] = [
  { i: WIDGET_IDS.USERS, x: 0, y: 0, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.AREA_RENTED, x: 3, y: 0, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.NET_RENT, x: 6, y: 0, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.POTENTIAL, x: 0, y: 4, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.CONTACTS, x: 3, y: 4, w: 3, h: 3, ...statsMinMaxSize },
  {
    i: WIDGET_IDS.EXPIRING_TENANTS,
    x: 6,
    y: 4,
    w: 3,
    h: 3,
    ...statsMinMaxSize,
  },
  {
    i: WIDGET_IDS.API_DATEv_WIDGET,
    x: 6,
    y: 4,
    w: 3,
    h: 3,
    ...statsMinMaxSize,
  },
  {
    i: WIDGET_IDS.PROPERTIES,
    x: 0,
    y: 10,
    w: 9,
    h: 12,
    ...propertiesMapMinMaxSize,
  },
  { i: WIDGET_IDS.INVOICES, x: 0, y: 10, w: 9, h: 12, ...invoicesMinMaxSize },
  {
    i: WIDGET_IDS.TENANT_TICKETS,
    x: 0,
    y: 10,
    w: 9,
    h: 12,
    ...tenantTicketsMinMaxSize,
  },
  {
    i: WIDGET_IDS.INSURANCES,
    x: 0,
    y: 10,
    w: 9,
    h: 12,
    ...insurancesMinMaxSize,
  },
  { i: WIDGET_IDS.OFFERS, x: 0, y: 10, w: 9, h: 12, ...offersMinMaxSize },
  { i: WIDGET_IDS.CONTRACTS, x: 0, y: 10, w: 9, h: 12, ...contractsMinMaxSize },
  {
    i: WIDGET_IDS.COMMENTS,
    x: 0,
    y: 10,
    w: 9,
    h: 12,
    ...openCommentsMinMaxSize,
  },
  {
    i: WIDGET_IDS.BANK_ACCOUNT_PAYMENTS,
    x: 0,
    y: 10,
    w: 9,
    h: 12,
    ...bankAccountPaymentsMinMaxSize,
  },
];

export const layoutSmInitSettings: Layout[] = [
  { i: WIDGET_IDS.USERS, x: 0, y: 0, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.AREA_RENTED, x: 3, y: 0, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.NET_RENT, x: 6, y: 0, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.POTENTIAL, x: 0, y: 4, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.CONTACTS, x: 3, y: 4, w: 3, h: 3, ...statsMinMaxSize },
  {
    i: WIDGET_IDS.EXPIRING_TENANTS,
    x: 0,
    y: 4,
    w: 3,
    h: 3,
    ...statsMinMaxSize,
  },
  {
    i: WIDGET_IDS.API_DATEv_WIDGET,
    x: 0,
    y: 4,
    w: 3,
    h: 3,
    ...statsMinMaxSize,
  },
  {
    i: WIDGET_IDS.PROPERTIES,
    x: 0,
    y: 10,
    w: 9,
    h: 12,
    ...propertiesMapMinMaxSize,
  },
  { i: WIDGET_IDS.INVOICES, x: 0, y: 10, w: 9, h: 12, ...invoicesMinMaxSize },
  {
    i: WIDGET_IDS.INSURANCES,
    x: 0,
    y: 10,
    w: 9,
    h: 12,
    ...insurancesMinMaxSize,
  },
  { i: WIDGET_IDS.OFFERS, x: 0, y: 10, w: 9, h: 12, ...offersMinMaxSize },
  { i: WIDGET_IDS.CONTRACTS, x: 0, y: 10, w: 9, h: 12, ...contractsMinMaxSize },
  {
    i: WIDGET_IDS.PROPERTIES,
    x: 0,
    y: 10,
    w: 9,
    h: 12,
    ...contractsMinMaxSize,
  },
  {
    i: WIDGET_IDS.COMMENTS,
    x: 0,
    y: 10,
    w: 9,
    h: 12,
    ...openCommentsMinMaxSize,
  },
];

export const layoutXsInitSettings: Layout[] = [
  { i: WIDGET_IDS.USERS, x: 0, y: 0, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.AREA_RENTED, x: 3, y: 0, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.NET_RENT, x: 6, y: 0, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.POTENTIAL, x: 0, y: 4, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.CONTACTS, x: 3, y: 4, w: 3, h: 3, ...statsMinMaxSize },
  {
    i: WIDGET_IDS.EXPIRING_TENANTS,
    x: 6,
    y: 4,
    w: 3,
    h: 3,
    ...statsMinMaxSize,
  },
  {
    i: WIDGET_IDS.API_DATEv_WIDGET,
    x: 6,
    y: 4,
    w: 3,
    h: 3,
    ...statsMinMaxSize,
  },
  {
    i: WIDGET_IDS.PROPERTIES,
    x: 0,
    y: 10,
    w: 9,
    h: 12,
    ...propertiesMapMinMaxSize,
  },
  { i: WIDGET_IDS.INVOICES, x: 0, y: 10, w: 9, h: 12, ...invoicesMinMaxSize },
  {
    i: WIDGET_IDS.INSURANCES,
    x: 0,
    y: 10,
    w: 9,
    h: 12,
    ...insurancesMinMaxSize,
  },
  { i: WIDGET_IDS.OFFERS, x: 0, y: 10, w: 9, h: 12, ...offersMinMaxSize },
  { i: WIDGET_IDS.CONTRACTS, x: 0, y: 10, w: 9, h: 12, ...contractsMinMaxSize },
  {
    i: WIDGET_IDS.COMMENTS,
    x: 0,
    y: 10,
    w: 9,
    h: 12,
    ...openCommentsMinMaxSize,
  },
];

export const layoutXxsInitSettings: Layout[] = [
  { i: WIDGET_IDS.USERS, x: 0, y: 0, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.AREA_RENTED, x: 3, y: 0, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.NET_RENT, x: 6, y: 0, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.POTENTIAL, x: 0, y: 4, w: 3, h: 3, ...statsMinMaxSize },
  { i: WIDGET_IDS.CONTACTS, x: 3, y: 4, w: 3, h: 3, ...statsMinMaxSize },
  {
    i: WIDGET_IDS.EXPIRING_TENANTS,
    x: 6,
    y: 4,
    w: 3,
    h: 3,
    ...statsMinMaxSize,
  },
  {
    i: WIDGET_IDS.API_DATEv_WIDGET,
    x: 6,
    y: 4,
    w: 3,
    h: 3,
    ...statsMinMaxSize,
  },
  {
    i: WIDGET_IDS.PROPERTIES,
    x: 0,
    y: 10,
    w: 9,
    h: 12,
    ...propertiesMapMinMaxSize,
  },
  { i: WIDGET_IDS.INVOICES, x: 0, y: 10, w: 9, h: 12, ...invoicesMinMaxSize },
  {
    i: WIDGET_IDS.INSURANCES,
    x: 0,
    y: 10,
    w: 9,
    h: 12,
    ...insurancesMinMaxSize,
  },
  { i: WIDGET_IDS.OFFERS, x: 0, y: 10, w: 9, h: 12, ...offersMinMaxSize },
  { i: WIDGET_IDS.CONTRACTS, x: 0, y: 10, w: 9, h: 12, ...contractsMinMaxSize },
  {
    i: WIDGET_IDS.COMMENTS,
    x: 0,
    y: 10,
    w: 9,
    h: 12,
    ...openCommentsMinMaxSize,
  },
];

export const responsiveLayoutInitSettings = {
  lg: layoutLgInitSettings,
  md: layoutMdInitSettings,
  sm: layoutSmInitSettings,
  xs: layoutXsInitSettings,
  xxs: layoutXxsInitSettings,
};

export const parseLocalStorageSettings = (
  localStorageKey: string
): {
  layouts: Record<string, Layout[]>;
  activeWidgets: string[];
  isLocked: boolean;
} => {
  const settingsJson = global.localStorage.getItem(localStorageKey) ?? "{}";
  return JSON.parse(settingsJson);
};

export const layoutInitState = (localStorageKey: string): Layout[] => {
  const { layouts } = parseLocalStorageSettings(localStorageKey);
  return layouts ? layouts.lg : layoutLgInitSettings;
};

export const responsiveLayoutInitState = (
  localStorageKey: string
): Record<string, Layout[]> => {
  const { layouts } = parseLocalStorageSettings(localStorageKey);
  return layouts ? layouts : responsiveLayoutInitSettings;
};

export const dashboardLockInitState = (localStorageKey: string): boolean => {
  const { isLocked } = parseLocalStorageSettings(localStorageKey);
  return isLocked === undefined ? true : isLocked;
};

export const permissionsToRead = {
  [WIDGET_IDS.USERS]: [
    PermissionsRead.DASHBOARD,
    PermissionsRead.USER_MANAGEMENT,
  ],
  [WIDGET_IDS.AREA_RENTED]: [
    PermissionsRead.DASHBOARD,
    PermissionsRead.PROPERTY,
  ],
  [WIDGET_IDS.NET_RENT]: [PermissionsRead.DASHBOARD, PermissionsRead.PROPERTY],
  [WIDGET_IDS.POTENTIAL]: [PermissionsRead.DASHBOARD, PermissionsRead.PROPERTY],
  [WIDGET_IDS.CONTACTS]: [PermissionsRead.DASHBOARD, PermissionsRead.CONTACT],
  [WIDGET_IDS.EXPIRING_TENANTS]: [
    PermissionsRead.DASHBOARD,
    PermissionsRead.PROPERTY,
  ],
  [WIDGET_IDS.API_DATEv_WIDGET]: [
    PermissionsRead.DASHBOARD,
    PermissionsRead.PROPERTY,
  ],
  [WIDGET_IDS.INVOICES]: [PermissionsRead.DASHBOARD, PermissionsRead.INVOICE],
  [WIDGET_IDS.INSURANCES]: [
    PermissionsRead.DASHBOARD,
    PermissionsRead.INSURANCE,
  ],
  [WIDGET_IDS.TENANT_TICKETS]: [
    PermissionsRead.DASHBOARD,
    PermissionsRead.PROPERTY,
  ],
  [WIDGET_IDS.OFFERS]: [PermissionsRead.DASHBOARD, PermissionsRead.OFFER],
  [WIDGET_IDS.CONTRACTS]: [PermissionsRead.DASHBOARD, PermissionsRead.CONTRACT],
  [WIDGET_IDS.COMMENTS]: [PermissionsRead.DASHBOARD, PermissionsRead.PROPERTY],
  [WIDGET_IDS.PROPERTIES]: [
    PermissionsRead.DASHBOARD,
    PermissionsRead.PROPERTY,
  ],
  [WIDGET_IDS.BANK_ACCOUNT_PAYMENTS]: [
    PermissionsRead.DASHBOARD,
    PermissionsRead.BANK_ACCOUNT,
  ],
};
