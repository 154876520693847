import { createContext, SyntheticEvent } from "react";
import { FileTreeEnum } from "../../../../constants";
import { FileTreeContextType } from "./types";

export const composeNodeId = (
  id: string,
  name?: string,
  type: keyof typeof FileTreeEnum = FileTreeEnum.FOLDER
): string => `${type}${id}`;

export const extractItemDataFromNodeId = (
  nodeId: string,
  type: keyof typeof FileTreeEnum = FileTreeEnum.FOLDER
): string[] => Array(nodeId.replace(type, ""));

export const iconClicked = (
  event: SyntheticEvent,
  iconClickExpandOnly: boolean
): boolean =>
  Boolean(
    iconClickExpandOnly &&
      event.target instanceof Element &&
      event.target.closest(FILE_TREE_ICON_SELECTOR)
  );

export const FILE_TREE_ICON_SELECTOR = ".MuiTreeItem-iconContainer";

export const FILE_TREE_ITEM_SELECTOR = ".MuiTreeItem-root";

export const FileTreeContext = createContext<FileTreeContextType | null>(null);
