import React, { ReactElement, useState } from "react";
import { Dialog } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
import DialogTitle from "../../../../../../ui/dialog/DialogTitle";
import { handleSendEmail, initialValues } from "./utils";
import Form from "./form";
import { SendEmailProps } from "./types";
import useValidation from "../../../../../../hooks/useValidation";
import * as Yup from "yup";
import { FormMessageInItState } from "../../../../../../constants";
import FormAlert from "../../../../../../ui/formAlert/FormAlert";

/* istanbul ignore next */
const SendEmail = ({
  handleCloseSendEmailModal,
  fileId,
  showSnackbar,
}: SendEmailProps): ReactElement => {
  const { message, email } = useValidation();

  const [formMessage, setFormMessage] = useState(FormMessageInItState);

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="max-width-dialog-title"
      data-testid="forward-file-modal"
      onClose={handleCloseSendEmailModal}
    >
      <DialogTitle
        title="property.objectDataSheet.attachments.sendEmail"
        onClose={handleCloseSendEmailModal}
      />
      <DialogContent>
        {formMessage.text ? <FormAlert formMessage={formMessage} /> : null}
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            text: message,
            email,
          })}
          onSubmit={handleSendEmail(
            fileId,
            showSnackbar,
            handleCloseSendEmailModal,
            setFormMessage
          )}
        >
          {(props) => <Form {...props} />}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default SendEmail;
