import React, { ReactElement } from "react";
import { Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  OpenInNewTabLink,
  Popover,
} from "../../../../ui/map/marker/pinWithPopover/styled";
import { route } from "../../../../utils/url";
import { Property } from "../../../../types/be/property";
import { useTranslation } from "react-i18next";
/* istanbul ignore next */
const MarkerPopover = ({
  handleOnHover,
  handleOnBlur,
  property,
}: {
  handleOnHover: () => void;
  handleOnBlur: () => void;
  property: Property;
}): ReactElement => {
  const { t } = useTranslation();
  const handleOpenInNewTabLink = (): void => {
    window.open(route("properties.edit", property.id, "general"), "_blank");
  };
  return (
    <Popover onMouseEnter={handleOnHover} onMouseLeave={handleOnBlur}>
      <Grid
        height={"100%"}
        container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item>
          <Typography
            variant="body2"
            sx={{ color: grey[100], fontSize: "11px" }}
          >
            {property.object_name}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: "9px", color: grey[400] }}
          >
            {property.location && property.location.full_address}
          </Typography>
        </Grid>
        <Grid item>
          <OpenInNewTabLink onClick={() => handleOpenInNewTabLink()}>
            {t("openInNewTab")}
          </OpenInNewTabLink>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default MarkerPopover;
