import React, { ReactElement, useEffect } from "react";
import { ContactShowDialogProps } from "./types";
import { Dialog, DialogContent } from "@mui/material";
import DialogTitle from "../../../../../ui/dialog/DialogTitle";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../hooks/useFetch";
import { getContact } from "../../../../../api/contacts";
import ContactDetails from "../../../../contacts/edit/contactDetails/ContactDetails";
import { Contact } from "../../../../contacts/edit/types";
import Loader from "../../../../Loader";

/* istanbul ignore next */
const ContactShowDialog = ({
  id,
  open,
  onClose,
}: ContactShowDialogProps): ReactElement => {
  const { t } = useTranslation();
  const { data, run, isLoading } = useFetch<Contact>();

  useEffect(() => {
    run(getContact(id!));
  }, []);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle
        title={t("createContact.contactDetails")}
        onClose={onClose}
      />
      <DialogContent>
        {isLoading ? <Loader /> : null}
        {!isLoading && data && (
          <ContactDetails isModal id={id!} contact={data} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ContactShowDialog;
