import React, { ReactElement, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { route } from "../../utils/url";
import { Grid, Tab, Tabs } from "@mui/material";
import {
  StyledGridTabs,
  StyledGridTabsContent,
  StyledTabContent,
} from "../properties/objectDataSheet/styled";
import camelCase from "lodash/camelCase";
import TabPanel from "../../ui/tabs/tabPanel";
import VacationList from "./vacationList";
import ApprovalProcess from "./approvalProcess";
import ManagingVacationLengths from "./managingVacationLengths";
import { VACATION_REQUESTS_TABS } from "./utils";
import useAppSelector from "../../hooks/useAppSelector";
import { UserRoles } from "../../constants";

const VacationRequests = (): ReactElement => {
  const { _user } = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const { tab } = useParams();
  const navigate = useNavigate();

  const handleTabChange = (newValue: VACATION_REQUESTS_TABS): void => {
    navigate(route("vacation-requests.tab", newValue));
  };

  const tabsLabels = [
    VACATION_REQUESTS_TABS.VACATION_LIST,
    VACATION_REQUESTS_TABS.APPROVAL_PROCESS,
    VACATION_REQUESTS_TABS.MANAGING_VACATION_LENGTHS,
  ];

  const isAdmin = _user?.role.code === UserRoles.ADMIN;

  const willBeRequestToBackForList = true;

  return (
    <React.Fragment>
      <Grid container spacing={0} columns={12}>
        <StyledGridTabs item xs={12} sm={12} mb={4}>
          {isAdmin && (
            <Tabs
              value={tab}
              onChange={(e: SyntheticEvent, value: VACATION_REQUESTS_TABS) =>
                handleTabChange(value)
              }
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              {tabsLabels.map((tab, index) => (
                <Tab
                  value={tab}
                  label={t(`vacationRequests.${camelCase(tab)}`)}
                  id={`simple-tab-${index}`}
                  key={`${tab}`}
                />
              ))}
            </Tabs>
          )}
        </StyledGridTabs>
        <StyledGridTabsContent item xs={12} sm={12}>
          <StyledTabContent>
            {willBeRequestToBackForList && (
              <>
                <TabPanel
                  key={VACATION_REQUESTS_TABS.VACATION_LIST}
                  value={tab!}
                  index={VACATION_REQUESTS_TABS.VACATION_LIST}
                >
                  <VacationList />
                </TabPanel>
                <TabPanel
                  key={VACATION_REQUESTS_TABS.APPROVAL_PROCESS}
                  value={tab!}
                  index={VACATION_REQUESTS_TABS.APPROVAL_PROCESS}
                >
                  <ApprovalProcess />
                </TabPanel>
                <TabPanel
                  key={VACATION_REQUESTS_TABS.MANAGING_VACATION_LENGTHS}
                  value={tab!}
                  index={VACATION_REQUESTS_TABS.MANAGING_VACATION_LENGTHS}
                >
                  <ManagingVacationLengths />
                </TabPanel>
              </>
            )}
          </StyledTabContent>
        </StyledGridTabsContent>
      </Grid>
    </React.Fragment>
  );
};

export default VacationRequests;
