import styled from "styled-components/macro";
import { Dialog, DialogTitle, Paper } from "@mui/material";
import { orange, grey } from "@mui/material/colors";
import { Download as DownloadIcon, Trash2 as Trash2Icon } from "react-feather";

export const DeleteIcon = styled(Trash2Icon)`
  position: relative;
  right: -4px;
  top: -1px;
  width: 12px;
  height: 12px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 0;
  position: relative;
`;

export const Button = styled.div`
  position: relative;
  top: 2px;
  right: 2px;
  width: 22px;
  height: 18px;
  border-radius: 2px;
  margin-left: 3px;
  background: ${orange[500]};
  border: 1px solid ${grey[200]};
  z-index: 5;
  opacity: 0.9;

  &:hover {
    background: ${orange[700]};
  }
  &:active {
    background: ${orange[800]};
  }
`;

export const DownloadIconStyled = styled(DownloadIcon)`
  position: relative;
  right: -3px;
  width: 14px;
  height: 14px;
`;

export const HoverMaskWrapper = styled.div`
  position: relative;
  height: 0;
`;
/* istanbul ignore next */
export const HoverMask = styled.div.attrs(
  ({ $isHovered }: { $isHovered: boolean }) => ({
    $isHovered: $isHovered || false,
  })
)`
  position: relative;
  width: 120px;
  height: 120px;
  background: black;
  z-index: 4;
  transition: opacity 0.1s;
  opacity: ${(props) => (props.$isHovered ? 0.3 : 0)};
`;
/* istanbul ignore next */
export const ImageWrapper = styled(Paper).attrs(
  ({ $height, $width }: { $height: string; $width: string }) => ({
    $height: $height || "120px",
    $width: $width || "120px",
  })
)`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  cursor: pointer;
  object-fit: cover;
  img {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
  }
`;

export const ImageThumbnail = styled.img`
  object-fit: cover;
`;

export const DialogTitleStyled = styled(DialogTitle)`
  background: black;
  opacity: 0.5;
  color: white;
  height: 20px;
  padding: 3px 10px;
  font-size: 11px;
`;
/* istanbul ignore next */
export const PreviewImage = styled.img.attrs(
  ({ $isZoomed }: { $isZoomed: boolean }) => ({
    $isZoomed: $isZoomed || false,
  })
)`
  cursor: ${(props) => (props.$isZoomed ? "zoom-out" : "zoom-in")};
  object-fit: contain;
`;

export const FileNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 0;
  opacity: 1;
  position: relative;
  z-index: 11;
  padding: 0 5px;
`;

export const FileName = styled.div`
  top: 25px;
  position: relative;
  color: white;
  z-index: 10;
  font-size: 10px;
  line-height: 0.9;
`;
/* istanbul ignore next */
export const PreviewImageDialog = styled(Dialog).attrs(
  ({ $isZoomed }: { $isZoomed: boolean }) => ({
    $isZoomed: $isZoomed || false,
  })
)`
  transition: transform 0.1s;
  transform: ${(props) => (props.$isZoomed ? "scale(1.5)" : "scale(1)")};
`;
