import { Form } from "formik";
import { FormikValues } from "formik/dist/types";
import { Grid } from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../../ui/button/Button";
import { TenantReminderFormProps } from "./types";
import { DatePicker } from "@mui/x-date-pickers";
import FileInput from "../../../../../../../ui/formsFields/fileInput";
import { handleDatepicker } from "../../../../../../../utils/form";
import TextField from "../../../../../../../ui/formsFields/textField";
/* istanbul ignore next */
const TenantReminderForm = ({
  values,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  handleCreateModalClose,
}: FormikValues & TenantReminderFormProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={6} md={6}>
          <FileInput
            name="file"
            label={t("tenant.rentalContact")}
            directUpload={false}
            size="small"
            value={values?.file}
            accept="application/pdf"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DatePicker
            disablePast
            openTo="year"
            views={["year", "month", "day"]}
            inputFormat="dd/MM/yyyy"
            value={values?.date}
            label={t("reminder.reminderSentDate")}
            onChange={handleDatepicker({ setFieldValue }, "date")}
            renderInput={(params) => (
              <TextField
                type="date"
                fullWidth
                size="small"
                name="date"
                value={values?.date}
                inputProps={{ "data-testid": "rent-start" }}
                error={false}
                {...params}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <Grid item>
          <Button
            onClick={handleCreateModalClose}
            title={t("cancel")}
            testId={"cancel-create-tenant-button"}
            variant={"text"}
            type="button"
            size="large"
          />
        </Grid>
        <Grid item>
          <Button
            title={t("create")}
            type="submit"
            color="success"
            size="large"
            testId={"create-area-button"}
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default TenantReminderForm;
