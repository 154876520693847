import styled from "styled-components/macro";
import { Cancel } from "@mui/icons-material";
import {
  Grid,
  Typography,
  Button as MuiButton,
  DialogTitle,
} from "@mui/material";
import UiCustomButton from "../../../../ui/button/Button";

export const FileName = styled.div`
  width: ${(props): string => props.theme.spacing(50)};
  overflow-wrap: break-word;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(3.75)};
  word-break: break-all;
`;

export const AutocompleteWrap = styled.div`
  position: relative;
  height: 30.4px;
  bottom: 2px;
`;
/* istanbul ignore next */
export const CancelIcon = styled(Cancel)`
  position: relative;
  top: ${(props): string => props.theme.spacing(1)};
  right: ${(props): string => props.theme.spacing(0.5)};
  cursor: pointer;
`;

/* for Provider Info Block*/

export const HoverBox = styled(Grid)`
  background: rgb(30 42 57);
  min-height: ${(props): string => props.theme.spacing(30)};
  min-width: ${(props): string => props.theme.spacing(90)};
  padding-left: ${(props): string => props.theme.spacing(5)};
  padding-bottom: ${(props): string => props.theme.spacing(3)};
`;

export const AvatarBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props): string => props.theme.avatarBox.background};
  color: white;
  font-size: ${(props): string => props.theme.spacing(12)};
  border: 1px solid white;
  border-radius: 50%;
  padding: ${(props): string => props.theme.spacing(3, 5)};
  margin: ${(props): string => props.theme.spacing(5, 5, 0, 0)};
  width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const InfoBox = styled.div`
  display: flex;
  color: white;
  margin-top: ${(props): string => props.theme.spacing(3)};
  justify-content: flex-start;
  align-items: center;
`;

export const MainText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: ${(props): string => props.theme.spacing(3.5)};
  padding: none;
  margin-left: ${(props): string => props.theme.spacing(3)};
  width: 165px;
  overflow: hidden;
`;

export const SubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: ${(props): string => props.theme.spacing(3)};
  margin-left: ${(props): string => props.theme.spacing(3)};
  width: 165px;
  overflow: hidden;
`;
/* istanbul ignore next */
export const CustomButton = styled(UiCustomButton)`
  line-height: 22px;
  font-size: 15px;
  padding: ${(props): string => props.theme.spacing(1.5, 2)};
  min-width: auto;
  span {
    margin: ${(props): string => props.theme.spacing(0)};
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CommentText = styled.div`
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/* istanbul ignore next */
export const HistoryButton = styled(MuiButton)`
  color: ${(props) => props.theme.tabs.textColor};
  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.tabs.textColor};
    background: transparent;
  }
  padding: ${(props): string => props.theme.spacing(0)};
  min-width: auto;
  margin-left: ${(props): string => props.theme.spacing(4)};
  font-size: 15px;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  line-height: 26px;
  font-weight: bold;
`;
