import styled from "styled-components/macro";
import { Grid,IconButton as MUIIconButton } from "@mui/material";

export const Input = styled("input")({
  display: "none",
});

export const GridStyledWrapper = styled(Grid)`
  padding: ${(props): string => `${props.theme.spacing(2)}`};
  border-bottom: ${(props) =>
    `3px solid ${props.theme.palette.background.default}`};};
`;

export const IconButton = styled(MUIIconButton)`
  padding-bottom: ${(props): string => `${props.theme.spacing(1.5)}`};
  padding-right: ${(props): string => `${props.theme.spacing(0)}`};
  padding-top: ${(props): string => `${props.theme.spacing(0)}`};
  padding-left: ${(props): string => `${props.theme.spacing(0)}`};
`
