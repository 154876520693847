import React, { ReactElement } from "react";
import { FileTreeEnum } from "../../../../constants";
import FileTreeItem from "./FileTreeItem";
import { FileTreeItemsProps } from "./types";
import FolderIcon from "@mui/icons-material/Folder";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const FileTreeItems = ({
  folders,
  files,
}: FileTreeItemsProps): ReactElement => {
  return (
    <>
      {Array.isArray(folders) &&
        folders.map(({ children, files, id, name, has_children, has_files }) =>
          has_children || (has_files && !!files?.length) ? (
            <FileTreeItem
              nodeId={`${FileTreeEnum.FOLDER}${id}`}
              labelText={name}
              labelIcon={FolderIcon}
              key={`${FileTreeEnum.FOLDER}${id}`}
            >
              <FileTreeItems folders={children} files={files} />
            </FileTreeItem>
          ) : (
            <FileTreeItem
              nodeId={`${FileTreeEnum.FOLDER}${id}`}
              labelText={name}
              labelIcon={FolderIcon}
              key={`${FileTreeEnum.FOLDER}${id}`}
            />
          )
        )}
      {files &&
        files.map(({ id, name, extension }) => (
          <FileTreeItem
            nodeId={`${FileTreeEnum.FILE}${id}${FileTreeEnum.SEPARATOR}${name}`}
            labelText={`${name}${
              extension ? "." + extension : /* istanbul ignore next */ ""
            }`}
            labelIcon={UploadFileIcon}
            key={`${FileTreeEnum.FILE}${id}`}
          />
        ))}
    </>
  );
};

export default FileTreeItems;
