import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "../../../ui/button/Button";
import Snackbar from "../../../ui/Snackbar";
import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormikValues } from "formik/dist/types";
import FileInputField from "../../../ui/formsFields/fileInput";
import TextField from "../../../ui/formsFields/text";
import {
  CURRENCIES,
  FINAL_RELEASER_STATUS_ID,
  FOLDER_NAMES,
  FORMAT_NUMBER_DE,
  INPUT_MIN_HEIGHT,
  Measurement_Units,
  MIME_TYPES,
  SUBMIT_STATUS,
  VAT_INCL_OPTIONS,
} from "../../../constants";
import SelectOrCreateContactsAutocompleteField from "../../contacts/autocomplete";
import DatePicker from "../../../ui/datePicker";
import { FormatNumber } from "../../../ui/formsFields/formatNumber";
import Text from "../../../ui/formsFields/text";
import Dropdown from "../../../ui/dropdown";
import { getProperties } from "../../../api/property";
import { parseNumber, prepareQueryParams } from "../../../utils/common";
import SwitchWrapper from "../../../ui/formsFields/switch";
import {
  calculateDateDifference,
  calculatePeriodTotal,
} from "../invoices/utils";
import { StyledGrid } from "./styled";
import { FormSection } from "../../profile/tabs/generalInformation/styled";
import { pick, get } from "lodash";
import { PlusIcon } from "../../../ui/table/styled";
import { PlusIcon as AddServiceIcon } from "./styled";
import { getReleasedAndOldContract } from "../../../api/contracts";
import CreateContractModal from "../maintenance/create/createContractModal";
import { Contract } from "../../../types/be.interfaces";
import { Services } from "../../../types/fe/outgoingInvoice";
import { ItemWrapper, MinusIcon } from "./styled";
import { getAreas } from "../../../api/area";
import { getDebtors } from "../../../api/debtor";
import { Contact } from "../../../types/models";
import { route } from "../../../utils/url";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { getOffers } from "../../../api/offer";

/* istanbul ignore next */
function CreateAndEditOutgoingInvoicesForm({
  handleSubmit,
  isSubmitting,
  status,
  edit = false,
  errors,
  values,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  submitAction,
  setSubmitAction,
  setFieldTouched,
  propertyId,
  invoicePeriod,
  handleOpenDialog,
  fileName,
  setFileName,
  handleOsc,
  selectedFileId,
  setIsFileLoading,
  setIsDynamicFile,
}: FormikValues): React.ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const [isNewSearch, setIsNewSearch] = useState(false);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

  const [contractModalOpen, setContractModalOpen] = useState(false);

  const handleContractModalOpen = (): void => {
    setContractModalOpen(true);
  };

  const handleContractModalClose = (): void => {
    setContractModalOpen(false);
  };

  const handleClose = (): void => {
    setIsSnackbarVisible(false);
  };

  const checkDateDifference = useMemo(() => {
    return Number(
      calculateDateDifference({
        from: values.repeat_from,
        to: values.repeat_to,
      })
    );
  }, [values]);

  const checkDisabledInvoicePeriod = useCallback(
    (period) => {
      const periodValue = invoicePeriod?.find((item: { name: string }) => {
        if (item?.name === period) return item;
      });
      return periodValue && checkDateDifference < periodValue?.period;
    },
    [values]
  );

  const getContractOptions = useCallback(
    (defaultParams: string): Promise<Response> => {
      const params = prepareQueryParams(
        defaultParams,
        {
          property_id:
            !isNewSearch && Number(values?.property_id)
              ? values?.property_id
              : propertyId,
        },
        true
      );
      return getReleasedAndOldContract(params);
    },
    [values?.property_id, propertyId, isNewSearch]
  );

  const getAreaOptions = useCallback(
    (defaultParams: string): Promise<Response> => {
      const params = prepareQueryParams(defaultParams, {
        property_id:
          !isNewSearch && Number(values?.property_id)
            ? values?.property_id
            : propertyId,
      });
      return getAreas(params);
    },
    [values?.property_id, propertyId, isNewSearch]
  );

  const getDebtorsOptions = useCallback(
    (search: string): Promise<Response> => {
      const params = prepareQueryParams(
        search,
        {
          property_id:
            !isNewSearch && Number(values?.property_id)
              ? values?.property_id
              : propertyId,
        },
        true
      );

      return getDebtors(params);
    },
    [values?.property_id, propertyId, isNewSearch]
  );

  const getOffersOptions = useCallback(
    (search: string): Promise<Response> => {
      const params = prepareQueryParams(search, {
        property_id:
          !isNewSearch && Number(values?.property_id)
            ? values?.property_id
            : propertyId,
        frStatusId: FINAL_RELEASER_STATUS_ID,
      });
      return getOffers(params);
    },
    [values?.property_id, propertyId, isNewSearch]
  );

  const handleAddServicesItem = (): void => {
    setFieldValue("services", [
      ...values.services,
      {
        tax: 0,
        price: "",
        quantity: 1,
        tax_price: "",
        description: "",
        total: "",
      },
    ]);
  };

  const handleRemoveServiceItem = (index: number): void => {
    setFieldValue(
      "services",
      values.services.filter((_: unknown, i: number) => i !== index)
    );
  };

  const handleChangeTax = (index: number, value: string): void => {
    setFieldValue(`services.${index}.tax`, value);
    const amountNetto = parseNumber(values?.services?.[index]?.price);
    const amountBrutto = amountNetto * (Number(value) / 100);
    setFieldValue(
      `services.${index}.tax_price`,
      amountBrutto * Number(values?.services?.[index]?.quantity)
    );
    setFieldValue(
      `services.${index}.total`,
      (amountNetto + amountBrutto) * Number(values?.services?.[index]?.quantity)
    );
  };

  const handleChangeQuantity = (index: number, value: string): void => {
    const amountNetto = parseNumber(values?.services?.[index]?.price);
    const taxAmount =
      amountNetto * (Number(values?.services?.[index]?.tax) / 100);
    setFieldValue(
      `services.${index}.tax_price`,
      Number(value) !== 0 ? taxAmount * Number(value) : "0"
    );
    setFieldValue(
      `services.${index}.total`,
      (parseNumber(values?.services?.[index]?.price) + taxAmount) *
        Number(value)
    );
    setFieldValue(`services.${index}.quantity`, value);
  };

  const handleChangePriceNetto = (index: number, value: string): void => {
    const priceNetto = parseNumber(value);
    const priceBrutto =
      priceNetto * (Number(values?.services?.[index]?.tax) / 100);
    setFieldValue(
      `services.${index}.total`,
      (priceBrutto + priceNetto) * Number(values?.services?.[index]?.quantity)
    );
    setFieldValue(
      `services.${index}.tax_price`,
      priceBrutto * Number(values?.services?.[index]?.quantity)
    );
    setFieldValue(`services.${index}.price`, value);
  };

  const grossInvoiceAmount =
    values?.services?.reduce(
      (old: number, item: Services): number => old + Number(item?.total),
      0
    ) ?? "";

  const fullInvoiceTaxPrice =
    values?.services?.reduce(
      (old: number, item: Services): number =>
        Number(item?.quantity) !== 0
          ? old +
            parseNumber(item?.price) *
              ((Number(item?.tax) / 100) * item?.quantity)
          : old,
      0
    ) ?? "";

  const nettoInvoiceAmount =
    values?.services?.reduce(
      (old: number, item: Services): number =>
        old + parseNumber(item?.price) * item?.quantity,
      0
    ) ?? "";

  const handleDateFromDateChange = (): ((date: Date | null) => void) => {
    return (date: Date | null): void => {
      setFieldValue("date_to", null);
      setFieldTouched("date_from", false);
      setFieldValue("date_from", date);
    };
  };

  const totalValue = useMemo(() => {
    const selectedPeriod = invoicePeriod?.find((item: { id: number }) => {
      if (item?.id === values?.repetition_period_id) return item;
    });

    return Number(
      selectedPeriod &&
        calculatePeriodTotal({
          date: values.repeat_from,
          until: values.repeat_to,
          monthly: selectedPeriod.period,
          amount: grossInvoiceAmount,
        })
    ).toFixed(2);
  }, [values]);

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  useEffect(() => {
    setFieldValue("total", Number(totalValue));
  }, [totalValue]);

  useEffect(() => {
    !edit && values?.file_id && setFieldValue("is_dynamic", false);
    edit &&
      values?.is_dynamic === true &&
      values?.file?.id !== values.file_id &&
      setFieldValue("is_dynamic", false);
    setIsDynamicFile && setIsDynamicFile(values?.is_dynamic);
  }, [values?.file_id]);

  const setContractModalValue = (contract: Contract): void => {
    setFieldValue("contract", contract);
    setFieldValue("contract_id", contract?.id);
  };

  const setOutState = (contact: Contact): void => {
    setFieldValue("contact", contact);
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={6} columns={12}>
          <Grid item sm={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={11}>
                <FileInputField
                  name="file_id"
                  label={t("documentRelease.invoice.uploadOneInvoice")}
                  error={Boolean(
                    touched.file_id &&
                      (status?.errors.file_id || errors.file_id)
                  )}
                  helperText={
                    touched.file_id &&
                    (status?.errors.file_id || errors.file_id)
                  }
                  foldername={FOLDER_NAMES.INVOICE}
                  propertyid={propertyId}
                  inputProps={{ "data-testid": "contract-file" }}
                  accept={MIME_TYPES.PDF}
                  errorText={t("contracts.error.pdfOnly")}
                  outerFileName={fileName}
                  setOuterFileName={setFileName}
                  handleOsc={handleOsc}
                  setLoader={setIsFileLoading}
                  sx={{ minHeight: INPUT_MIN_HEIGHT }}
                />
              </Grid>
              <StyledGrid item xs={1} pt={3}>
                <IconButton
                  color="primary"
                  aria-label="uploadPdf"
                  component="span"
                  size="small"
                  onClick={handleOpenDialog}
                >
                  <InsertDriveFileIcon />
                </IconButton>
              </StyledGrid>
            </Grid>
          </Grid>
          {!propertyId && (
            <Grid item xs={12} sm={6}>
              <Dropdown
                id="property-autocomplete"
                name="property_id"
                label={t("form.propertyId")}
                placeholder={t("form.propertyId")}
                getOptions={getProperties}
                onSelectOption={(id?: string) => {
                  setIsNewSearch(true);
                  setFieldValue("property_id", id);
                }}
                optionLabel="object_name"
                optionValue="id"
                size="medium"
                helperText={
                  status?.errors.property_id ||
                  (touched.property_id && errors.property_id)
                }
                error={Boolean(
                  status?.errors.property_id ||
                    (touched.property_id && errors.property_id)
                )}
                defaultValue={values.property}
                sx={{ minHeight: INPUT_MIN_HEIGHT }}
              />
            </Grid>
          )}
          <Grid item sm={12} lg={6}>
            <Dropdown
              id="property-area-autocomplete"
              name="area_id"
              value={values.area_id || ""}
              label={t("counter.searchArea")}
              placeholder={t("counter.searchArea")}
              getOptions={getAreaOptions}
              onSelectOption={(id?: string) => setFieldValue("area_id", id)}
              size="medium"
              dontLoad={!Number(values?.property_id) && !propertyId}
              defaultValue={values?.area}
              error={Boolean(status?.errors?.area_id || errors.area_id)}
              helperText={errors?.area_id || status?.errors?.area_id}
              disabled={!Number(values?.property_id) && !propertyId}
              isAreaSearch={isNewSearch}
              propertyId={values?.property_id}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              resetValue={!propertyId && values?.property_id === ""}
              tooltipText={t("generalDocuments.pleaseSelectProperty")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectOrCreateContactsAutocompleteField
              {...{
                errors,
                values,
                touched,
                status,
                handleBlur,
                handleChange,
                setFieldValue,
              }}
              required={true}
              label={t("documentRelease.outgoingInvoice.searchReceiver")}
              placeholder={t("documentRelease.outgoingInvoice.searchReceiver")}
              setOutState={setOutState}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("documentRelease.invoice.number")}
              value={values.number}
              name="number"
              required
              size="medium"
              status={status}
              inputProps={{ "data-testid": "number" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              required
              disableFuture
              name="date"
              label={t("documentRelease.invoice.date")}
              status={status}
              dataTestId={"outgoing-invoice-date-input"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              id="creditor-autocomplete"
              name="datev_debtor_id"
              value={values?.datev_debtor_id || ""}
              label={t("documentRelease.outgoingInvoice.debitor")}
              placeholder={t("documentRelease.outgoingInvoice.debitorSearch")}
              getOptions={getDebtorsOptions}
              disabled={!Number(values?.property_id) && !propertyId}
              dontLoad={!Number(values?.property_id) && !propertyId}
              isAreaSearch={isNewSearch}
              onSelectOption={(id?: string) =>
                setFieldValue("datev_debtor_id", id)
              }
              optionLabel="konto"
              size="medium"
              defaultValue={values?.datev_debtor}
              error={Boolean(
                errors?.datev_debtor || status?.errors?.datev_debtor_id
              )}
              helperText={
                errors?.datev_debtor_id || status?.errors?.datev_debtor_id
              }
              propertyId={values?.property_id}
              resetValue={!propertyId && values?.property_id === ""}
              tooltipText={t("generalDocuments.pleaseSelectProperty")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              required
              name="date_from"
              onChangeValue={handleDateFromDateChange()}
              label={t("documentRelease.outgoingInvoice.dateFrom")}
              status={status}
              dataTestId={"outgoing-invoice-date-from-input"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              required
              disabled={!values.date_from}
              name="date_to"
              minDate={values?.date_from}
              label={t("documentRelease.outgoingInvoice.dateTo")}
              status={status}
              dataTestId={"outgoing-invoice-date-to-input"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="currency_code"
              select
              value={values.currency_code || ""}
              label={t("documentRelease.invoice.currency")}
              inputProps={{ "data-testid": "currencies_code-input" }}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
            >
              {CURRENCIES?.map((currency: string) => (
                <MenuItem key={`${currency}`} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Text
              name="notice"
              required
              value={values.notice}
              label={t("documentRelease.outgoingInvoice.notice")}
              rows={5}
              multiline
              status={status}
            />
          </Grid>
          <ItemWrapper container>
            <Grid item xs={12} paddingY={4}>
              <Typography variant="h4">
                {t("documentRelease.outgoingInvoice.services")}
              </Typography>
            </Grid>
            {values?.services?.map((item: any, index: number) => (
              <Grid container key={index} spacing={4} paddingY={4} pb={10}>
                <Grid item xl={3.7} lg={3.7} md={6} sm={12}>
                  <TextField
                    required
                    name={`services.${index}.description`}
                    label={t("documentRelease.outgoingInvoice.description")}
                    value={item?.description}
                    fullWidth
                    rows={6}
                    multiline
                    status={status}
                    sx={{ minHeight: INPUT_MIN_HEIGHT }}
                  />
                </Grid>
                <Grid
                  item
                  xl={3.7}
                  lg={3.7}
                  md={6}
                  sm={12}
                  container
                  direction="column"
                  spacing={2}
                >
                  <Grid item xl={3} lg={3} md={6} sm={12}>
                    <TextField
                      name={`services.${index}.quantity`}
                      type="number"
                      required
                      value={item?.quantity}
                      label={t("documentRelease.outgoingInvoice.quantity")}
                      status={status}
                      onChange={(e) =>
                        handleChangeQuantity(index, e.target.value)
                      }
                      sx={{ minHeight: INPUT_MIN_HEIGHT }}
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={6} sm={12}>
                    <TextField
                      name={`services.${index}.tax`}
                      required
                      select
                      value={item?.tax}
                      label={t("documentRelease.outgoingInvoice.tax")}
                      status={status}
                      onChange={(e) => handleChangeTax(index, e.target.value)}
                      sx={{ minHeight: INPUT_MIN_HEIGHT }}
                    >
                      {VAT_INCL_OPTIONS?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {`${item} %`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xl={3.7}
                  lg={3.7}
                  md={6}
                  sm={12}
                  container
                  direction="column"
                  spacing={2}
                >
                  <Grid item sm={3}>
                    <FormatNumber
                      required
                      name={`services.${index}.price`}
                      label={t("documentRelease.outgoingInvoice.price")}
                      value={item?.price ?? ""}
                      status={status}
                      onChange={(e) =>
                        handleChangePriceNetto(index, e.target.value)
                      }
                      sx={{ minHeight: INPUT_MIN_HEIGHT }}
                      inputProps={{
                        "data-testid": "price",
                        filter: FORMAT_NUMBER_DE,
                        unit: Measurement_Units.EURO,
                      }}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item sm={3} xl={5.8} lg={5.8} md={5.8}>
                        <FormatNumber
                          name={`services.${index}.tax_price`}
                          label={t("documentRelease.outgoingInvoice.taxPrice")}
                          disabled
                          value={
                            item?.tax_price || item?.total - item?.price || 0
                          }
                          status={status}
                          sx={{ minHeight: INPUT_MIN_HEIGHT }}
                          inputProps={{
                            "data-testid": "tax_price",
                            filter: "formatNum",
                            unit: Measurement_Units.EURO,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item sm={3} xl={5.8} lg={5.8} md={5.8}>
                        <FormatNumber
                          name={`services.${index}.total`}
                          label={t(
                            "documentRelease.outgoingInvoice.totalPrice"
                          )}
                          disabled
                          value={item?.total || 0}
                          status={status}
                          sx={{ minHeight: INPUT_MIN_HEIGHT }}
                          inputProps={{
                            "data-testid": "total",
                            filter: "formatNum",
                            unit: Measurement_Units.EURO,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={0.2} lg={0.2} sm={12} md={0.2}>
                  {index ? (
                    <Tooltip
                      title={t(
                        "documentRelease.outgoingInvoice.deleteServices"
                      )}
                    >
                      <MinusIcon
                        color="red"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveServiceItem(index)}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={t("documentRelease.outgoingInvoice.addServices")}
                    >
                      <AddServiceIcon
                        size={18}
                        style={{ cursor: "pointer" }}
                        type="button"
                        color="green"
                        onClick={handleAddServicesItem}
                      />
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            ))}
            <Grid container spacing={4} paddingY={6}>
              <Grid item sm={4}>
                <FormatNumber
                  name="invoice_amount_net"
                  label={t("documentRelease.outgoingInvoice.fullInvoicePrice")}
                  disabled
                  value={nettoInvoiceAmount}
                  status={status}
                  sx={{ minHeight: INPUT_MIN_HEIGHT }}
                  inputProps={{
                    "data-testid": "invoiceAmountNet",
                    filter: "formatNum",
                    unit: Measurement_Units.EURO,
                  }}
                />
              </Grid>
              <Grid item sm={4}>
                <FormatNumber
                  name="invoice_amount_net"
                  label={t("documentRelease.outgoingInvoice.fullInvoiceTax")}
                  value={fullInvoiceTaxPrice}
                  disabled
                  status={status}
                  sx={{ minHeight: INPUT_MIN_HEIGHT }}
                  inputProps={{
                    "data-testid": "invoiceFullTax",
                    filter: "formatNum",
                    unit: Measurement_Units.EURO,
                  }}
                />
              </Grid>
              <Grid item sm={4}>
                <FormatNumber
                  name="gross_invoice_amount"
                  disabled
                  label={t(
                    "documentRelease.outgoingInvoice.fullInvoicePriceWithTax"
                  )}
                  value={grossInvoiceAmount}
                  status={status}
                  sx={{ minHeight: INPUT_MIN_HEIGHT }}
                  inputProps={{
                    "data-testid": "grossInvoiceAmount",
                    filter: "formatNum",
                    unit: Measurement_Units.EURO,
                  }}
                />
              </Grid>
            </Grid>
          </ItemWrapper>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={11}>
                <Dropdown
                  id="contract-autocomplete"
                  name="contract_id"
                  value={values?.contract_id || ""}
                  isAreaSearch={isNewSearch}
                  disabled={!Number(values?.property_id) && !propertyId}
                  dontLoad={!Number(values?.property_id) && !propertyId}
                  resetValue={!propertyId && values?.property_id === ""}
                  label={t("contracts.searchContract")}
                  placeholder={t("contracts.searchContract")}
                  getOptions={getContractOptions}
                  onSelectOption={(id?: string) =>
                    setFieldValue("contract_id", id)
                  }
                  defaultValue={
                    values?.contract
                      ? {
                          ...pick(values?.contract, ["id"]),
                          fileName: get(values?.contract, "file.name"),
                        }
                      : null
                  }
                  optionLabel="fileName"
                  optionValue="id"
                  size="medium"
                  error={Boolean(
                    status?.errors?.contract_id || errors.contract_id
                  )}
                  helperText={
                    errors?.contract_id || status?.errors?.contract_id
                  }
                  propertyId={values?.property_id}
                  tooltipText={t("generalDocuments.pleaseSelectProperty")}
                />
              </Grid>
              <Grid item xs={1} pt={3}>
                <Tooltip title={t("contracts.createContract")}>
                  <PlusIcon
                    role={"dataItems-create-contract"}
                    onClick={handleContractModalOpen}
                    size={18}
                    style={{ cursor: "pointer" }}
                    type="button"
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              id="offer-autocomplete"
              name="offer_id"
              value={values?.offer_id || ""}
              label={t("documentRelease.invoice.offer")}
              placeholder={t("documentRelease.invoice.SelectOffer")}
              getOptions={getOffersOptions}
              isAreaSearch={isNewSearch}
              disabled={!Number(values?.property_id) && !propertyId}
              dontLoad={!Number(values?.property_id) && !propertyId}
              resetValue={!propertyId && values?.property_id === ""}
              onSelectOption={(id?: string) => setFieldValue("offer_id", id)}
              defaultValue={
                values?.offer
                  ? {
                      ...pick(values?.offer, ["id"]),
                      fileName: get(values?.offer, "file.name"),
                    }
                  : null
              }
              optionLabel="fileName"
              optionValue="id"
              size="medium"
              error={Boolean(status?.errors?.offer_id || errors.offer_id)}
              helperText={errors?.offer_id || status?.errors?.offer_id}
              propertyId={values?.property_id}
              tooltipText={t("generalDocuments.pleaseSelectProperty")}
            />
          </Grid>
          <Grid item xl={4} md={6} sm={12}>
            <SwitchWrapper
              name="is_permanent"
              label={t("documentRelease.invoice.isPermanentInvoice")}
              checked={!!values.is_permanent}
              data-testid="is-permanent-invoice"
            />
          </Grid>
          <Grid item xl={4} md={6} sm={12}>
            <SwitchWrapper
              name="is_debited_automatically"
              label={t("documentRelease.invoice.isDebitedAutomatically")}
              checked={!!values.is_debited_automatically}
              data-testid="is-debited-automatically"
            />
          </Grid>
          <Grid item xl={4} md={6} sm={12}>
            <SwitchWrapper
              name="is_apportionable"
              label={t("documentRelease.invoice.isApportionable")}
              checked={!!values.is_apportionable}
              data-testid="is-apportionable"
            />
          </Grid>
          <Grid item xl={4} md={6} sm={12}>
            <SwitchWrapper
              name="is_confidential"
              label={t("documentRelease.invoice.isConfidentialInvoice")}
              checked={!!values.is_confidential}
              data-testid="is-confidential-invoice"
            />
          </Grid>
          <Grid item xl={4} md={6} sm={12}>
            <SwitchWrapper
              name="is_active"
              label={t("documentRelease.invoice.isActiveInvoice")}
              checked={!!values.is_active}
              data-testid="is-active-invoice"
            />
          </Grid>
          {values?.is_permanent && (
            <Grid item xl={4} md={6} sm={12}>
              <SwitchWrapper
                name="is_automatic_release_repeating_invoice"
                label={t(
                  "documentRelease.invoice.isAutomaticReleaseRepeatingInvoice"
                )}
                checked={!!values.is_automatic_release_repeating_invoice}
                data-testid="is_automatic_release_repeating_invoice"
              />
            </Grid>
          )}
        </Grid>
        <FormSection mt={10}>
          <Grid container spacing={6}>
            {values.is_permanent && (
              <>
                <Grid item lg={4} sm={12}>
                  <DatePicker
                    name="repeat_from"
                    label={t("documentRelease.invoice.repeatFrom")}
                    status={status}
                    minDate={new Date()}
                    dataTestId={"repeat-from-input"}
                  />
                </Grid>
                <Grid item lg={4} sm={12}>
                  <DatePicker
                    name="repeat_to"
                    label={t("documentRelease.invoice.repeatTo")}
                    status={status}
                    minDate={new Date()}
                    dataTestId={"repeat-to-input"}
                  />
                </Grid>
                <Grid item lg={4} sm={12}>
                  <TextField
                    label={t("documentRelease.invoice.repeatDay")}
                    type="number"
                    value={values.repeat_day}
                    name="repeat_day"
                    required
                    fullWidth
                    size="medium"
                    inputProps={{ "data-testid": "repeat_day" }}
                    status={status}
                  />
                </Grid>
                <Grid item lg={4} sm={12}>
                  <Text
                    name="repetition_period_id"
                    select
                    required
                    label={t("documentRelease.invoice.periodType")}
                    data-testid="period-type-input"
                    status={status}
                  >
                    {invoicePeriod?.map(
                      (period: { id: number; name: string }) => (
                        <MenuItem
                          key={`${period.name}`}
                          value={period.id}
                          disabled={checkDisabledInvoicePeriod(period.name)}
                        >
                          {period.name}
                        </MenuItem>
                      )
                    )}
                  </Text>
                </Grid>
                <Grid item lg={4} sm={12}>
                  <TextField
                    name="total"
                    label={t("documentRelease.invoice.total")}
                    disabled
                    value={values?.total || ""}
                    inputProps={{
                      "data-testid": "total-input",
                      filter: "formatNum",
                      unit: Measurement_Units.EURO,
                    }}
                    status={status}
                  />
                </Grid>
              </>
            )}
            {values.is_apportionable && (
              <Grid item lg={6} sm={12}>
                <TextField
                  type="number"
                  required={values.is_apportionable}
                  name="apportionable_percent"
                  label={t("documentRelease.invoice.apportionablePercent")}
                  value={values.apportionable_percent}
                  inputProps={{
                    "data-testid": "apportionable_percent",
                    max: 100,
                    min: 0,
                  }}
                  status={status}
                />
              </Grid>
            )}
          </Grid>
        </FormSection>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={0}
        >
          <Button
            title={t("transactionManagement.loi.preview")}
            sx={{ ml: "1rem", mb: "1rem" }}
            type="submit"
            size="large"
            onClick={() => {
              setSubmitAction("preview");
            }}
            disabled={
              (submitAction === SUBMIT_STATUS.PREVIEW && isSubmitting) ||
              (!edit && selectedFileId) ||
              (edit && values?.is_dynamic === false)
            }
            isLoading={submitAction === SUBMIT_STATUS.PREVIEW && isSubmitting}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
        >
          <Button
            type="button"
            color="primary"
            variant="text"
            title={t("cancel")}
            disabled={isSubmitting}
            size="large"
            onClick={() =>
              doesAnyHistoryEntryExist
                ? navigate(-1)
                : propertyId
                ? navigate(route("properties.outgoing-invoices", propertyId))
                : navigate(route("outgoing-invoices"))
            }
          />
          <Button
            title={edit ? t("update") : t("create")}
            sx={{ ml: "1rem" }}
            color="success"
            type="submit"
            size="large"
            onClick={() => {
              setSubmitAction(SUBMIT_STATUS.CREATE_OR_EDIT);
            }}
            disabled={
              submitAction === SUBMIT_STATUS.CREATE_OR_EDIT && isSubmitting
            }
            isLoading={
              submitAction === SUBMIT_STATUS.CREATE_OR_EDIT && isSubmitting
            }
          />
        </Stack>
        <Snackbar
          message={t("documentRelease.invoice.invoiceSaveSuccessfully")}
          open={isSnackbarVisible}
          handleClose={handleClose}
        />
      </form>
      <CreateContractModal
        open={contractModalOpen}
        onClose={handleContractModalClose}
        setContractModalValue={setContractModalValue}
      />
    </>
  );
}

export default CreateAndEditOutgoingInvoicesForm;
