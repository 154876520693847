import { useCallback, useEffect } from "react";
import { getRoles } from "../api/roles";
import { Role } from "../types/be/role";
import { useFetch } from "./useFetch";

interface UseRolesProps {
  roles: Role[] | null;
  getRole: (query: string | number) => Role | undefined;
  isError: boolean;
  isLoading: boolean;
}

const useRoles = (): UseRolesProps => {
  const { data: roles, isError, run, isLoading } = useFetch<Role[]>();

  useEffect(() => {
    run(getRoles());
  }, []);

  const getRole = useCallback(
    (query: string | number): Role | undefined =>
      roles?.find((role) => [role.code, role.id].includes(query)),
    [roles]
  );

  return {
    roles,
    getRole,
    isError,
    isLoading,
  };
};

export default useRoles;
