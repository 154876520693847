import { FormikValues } from "formik";
import { updateReminder } from "../../../../../api/reminder";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../constants";
import { HTTP_STATUS_CODES } from "../../../../../types/server";
import { TableCell } from "../../../../../types/table";
import { mapBackendValidationErrors } from "../../../../../utils/form";
import {
  AssignPropertyToInboxData,
  FormSubmitHandlerProps as PropertyFormSubmitHandlerProps,
} from "../../../../inbox/assignPropertyDialog/types";
import {
  AssignInvoiceData,
  FormSubmitHandlerProps as InvoiceFormSubmitHandlerProps,
} from "../../assignInvoiceDialog/types";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "companyOwnership.whoAdded",
  },
  {
    id: "fileName",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "contracts.fileName",
  },
  {
    id: "propertyName",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "propertyName",
  },
  {
    id: "latest_comment",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "comment",
    align: "left",
  },
  {
    id: "dateTime",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "createdAt",
    align: "center",
  },
  {
    id: "relation_id",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "number",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];

export const handleAssignInvoice = (
  id: string,
  { setFormMessage, afterAssignInvoice }: InvoiceFormSubmitHandlerProps
) => {
  return async (
    values: AssignInvoiceData,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const response = await updateReminder(Number(id), {
      relation_id: values?.invoice_id,
      relation: "invoice",
      property_id: values?.property_id,
    });
    const json = await response.json();

    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      afterAssignInvoice && afterAssignInvoice();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
    }
    setSubmitting(false);
  };
};

export const handleAssignProperty = (
  id: string,
  { setFormMessage, afterAssignProperty }: PropertyFormSubmitHandlerProps
) => {
  return async (
    values: AssignPropertyToInboxData,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const response = await updateReminder(Number(id), {
      property_id: values?.property_id,
    });
    const json = await response.json();

    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      afterAssignProperty && afterAssignProperty(String(values?.property_id));
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
    }
    setSubmitting(false);
  };
};
