import React, { ReactElement, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Grid, Tab, Tabs } from "@mui/material";
import { StyledGridTabs } from "../styled";
import { useTranslation } from "react-i18next";
import { simpleTabProps, TABS, tabsLabels } from "./utils";
import TabPanel from "../../../../ui/tabs/tabPanel";

import Properties from "./properties";
import Permissions from "./permissions";
import useAccessControl from "../../../../hooks/useAccessControl";
/* istanbul ignore next */
const EditRole = (): ReactElement => {
  const { tab: tabParam } = useParams();
  const { t } = useTranslation();
  const [tab, setTab] = useState(TABS.PERMISSIONS);

  useEffect(() => {
    setTab(tabParam as TABS);
  }, [tabParam]);

  const handleTabChange = (tab: TABS): void => {
    setTab(tab);
  };

  const { client } = useParams();
  const { can } = useAccessControl();
  const redirectLink = <Navigate to={`/${client}/403`} />;

  if (!can("role.update")) {
    return redirectLink;
  }

  return (
    <Grid container>
      <Grid item sm={12}>
        <StyledGridTabs>
          <Tabs
            value={tab}
            onChange={(_: unknown, value: TABS) => handleTabChange(value)}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            {tabsLabels.map((tab, index) => (
              <Tab
                key={`settings.role.${tab}`}
                value={tab}
                label={t(`settings.role.${tab}`)}
                {...simpleTabProps(index)}
              />
            ))}
          </Tabs>
        </StyledGridTabs>
      </Grid>
      <Grid item sm={12}>
        <TabPanel key={TABS.PERMISSIONS} value={tab} index={TABS.PERMISSIONS}>
          <Permissions />
        </TabPanel>
        <TabPanel key={TABS.PROPERTIES} value={tab} index={TABS.PROPERTIES}>
          <Properties />
        </TabPanel>
        <TabPanel key={TABS.COMPANIES} value={tab} index={TABS.COMPANIES}>
          <Properties company />
        </TabPanel>
      </Grid>
    </Grid>
  );
};
export default EditRole;
