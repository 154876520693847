import React, { ReactElement, useCallback } from "react";
import { load } from "@finapi/web-form";
import { FIN_API_TARGET_ENVIRONMENT, FIN_API_TARGET_URL } from "../../config";
import { isNodeEnv } from "../../utils/env";
import { NODE_ENVIRONMENTS } from "../../constants";

/* istanbul ignore next */
const FinApiWebForm = ({
  token,
  onComplete,
  onFail,
  onAbort,
  onLoadError,
  onWebFormMounted,
}: {
  token: string;
  onComplete?: () => void;
  onFail?: () => void;
  onAbort?: () => void;
  onLoadError?: () => void;
  onWebFormMounted?: () => void;
}): ReactElement => {
  const createWebForm = useCallback(async (target: HTMLElement) => {
    load(
      target,
      {
        token,
        targetEnvironment: FIN_API_TARGET_ENVIRONMENT as "sandbox" | "live",
        targetUrl: FIN_API_TARGET_URL,
      },
      {
        onComplete: function () {
          console.log("onComplete");
          onComplete && onComplete();
        },
        onFail: function () {
          onFail && onFail();
          console.log("FinApiWebForm onFail");
        },
        onAbort: function () {
          onAbort && onAbort();
          console.log("FinApiWebForm onAbort");
        },
        onLoadError: function () {
          onLoadError && onLoadError();
          console.log("FinApiWebForm onLoadError");
        },
      }
    );
  }, []);

  const containerRef = (container: HTMLDivElement): void => {
    if (container) {
      createWebForm(container).then((res) => {
        onWebFormMounted && onWebFormMounted();
        if (!isNodeEnv(NODE_ENVIRONMENTS.TEST))
          console.log("FinApiWebForm createWebForm(): ", res);
      });
    }
  };

  return <div ref={containerRef}></div>;
};

export default FinApiWebForm;
