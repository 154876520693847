import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";
import UIButton from "../../../../../ui/button/Button";
import { Dialog, Autocomplete } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { FormMessageInterface } from "../../../../../types/form";
import {
  FormMessageInItState,
  SEARCH_PARAM_MIN_CHARACTERS,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../../constants";
import FormAlert from "../../../../../ui/formAlert/FormAlert";
import { DialogTitle } from "../selectOrCreateInsuranceTopicModal/styled";
import debounce from "lodash/debounce";
import { HTTP_STATUS_CODES } from "../../../../../types/server";
import { prepareQueryParams } from "../../../../../utils/common";
import { getInvoices } from "../../../../../api/invoices";
import { Invoice } from "../../../../../types/be.interfaces";
import { handleInboxCreateReminder } from "./utils";
import { CreateReminderFromInboxFormProps } from "./types";
import { StyledPaper } from "../../../../contacts/autocomplete/styled";
/* istanbul ignore next */
const CreateReminderModal = ({
  handleDialogCreateReminder,
  inboxData,
  setSnackbarData,
  handleFetchList,
}: CreateReminderFromInboxFormProps): React.ReactElement => {
  const { t } = useTranslation();
  const [searchOptions, setSearchOptions] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState(false);
  const [formMessage, setFormMessage] =
    React.useState<FormMessageInterface>(FormMessageInItState);

  const onTextChange = React.useCallback(
    debounce(async (search: string): Promise<void> => {
      if (search.length < SEARCH_PARAM_MIN_CHARACTERS) setSearchOptions([]);
      else {
        setLoading(true);
        const params = prepareQueryParams("", {
          search,
          fr_status_id: "1",
          property_id: String(inboxData.property?.id ?? ""),
        });
        const response = await getInvoices(params);
        if (response.status === HTTP_STATUS_CODES.OK) {
          const json = await response.json();
          setSearchOptions(json.data);
        } else {
          setFormMessage({
            type: "error",
            text: SOMETHING_WENT_WRONG_ERROR,
          });
        }
      }
      setLoading(false);
    }, 300),
    []
  );

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="xs"
      aria-labelledby="max-width-dialog-title"
      data-testid="create-topic-modal"
    >
      <DialogTitle
        title="reminder.createReminder"
        onClose={handleDialogCreateReminder}
      />
      <DialogContent>
        <Formik
          initialValues={{
            invoice_id: "",
          }}
          enableReinitialize
          onSubmit={handleInboxCreateReminder({
            t,
            inboxData,
            setSnackbarData,
            handleFetchList,
            handleDialogCreateReminder,
          })}
        >
          {({ setFieldValue, handleSubmit, isSubmitting }) => (
            <form style={{ width: "400px" }}>
              {formMessage.text && (
                <FormAlert formMessage={formMessage} sx={{ mb: "10px" }} />
              )}
              <Grid container justifyContent="center" alignItems="center">
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item md={12} mt={3} mb={3}>
                    <Autocomplete
                      options={searchOptions}
                      getOptionLabel={(option) => `${option.name}`}
                      onChange={(_, value: Invoice | null) =>
                        setFieldValue("invoice_id", value?.id)
                      }
                      loading={loading}
                      PaperComponent={StyledPaper}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size={"small"}
                          label={t("form.invoiceId")}
                          placeholder={t("search")}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => onTextChange(e.target.value)}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end" alignItems="center">
                <Grid item mt={2} ml={2}>
                  <UIButton
                    title={t("create")}
                    type="button"
                    onClick={handleSubmit}
                    fullWidth
                    color="success"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                    testId="create-reminder"
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CreateReminderModal;
