import React, { ReactElement, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  TextField as MuiTextField,
  CircularProgress,
} from "@mui/material";
import {
  MAX_PRELOADED_OPTIONS,
  NODE_ENVIRONMENTS,
  SEARCH_PARAM_MIN_CHARACTERS,
} from "../../../../constants";
import { removeDuplicatesFromUsers } from "../../areas/edit/tabs/areaForm/utils";
import debounce from "lodash/debounce";
import { isNodeEnv } from "../../../../utils/env";
import { SupervisorAutocompleteProps } from "../tabs/generalInformation/types";
import { User } from "../../../../types/models";
import { handleOnChangeAutocompleteInputText } from "../utils";
import { StyledPaper } from "../../../contacts/autocomplete/styled";

/* istanbul ignore next */
const SupervisorAutocomplete = ({
  values,
  setFieldValue,
  handleBlur,
  assetManagers,
  touched,
  errors,
}: SupervisorAutocompleteProps): ReactElement => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const preloadOptions: User[] = useMemo(() => {
    const auxUsers = values.supervisor
      ? [
          values.supervisor,
          ...assetManagers.slice(0, MAX_PRELOADED_OPTIONS - 1),
        ]
      : [...assetManagers.slice(0, MAX_PRELOADED_OPTIONS)];
    return removeDuplicatesFromUsers(auxUsers);
  }, [assetManagers, values.supervisor, MAX_PRELOADED_OPTIONS]);

  const [options, setOptions] = useState<User[] | null>(preloadOptions);
  const [searchResult, setSearchResult] = useState<User[]>(preloadOptions);

  /* istanbul ignore next */
  const filterOptions = useCallback(
    (options: User[]): User[] =>
      options.filter(
        (option) =>
          !(
            !searchResult.some((el) => el.id === option.id) &&
            values.supervisor?.id === option.id
          )
      ),
    [searchResult, values.supervisor?.id]
  );

  const getOptionLabel = (user: User | null): string => {
    if (!user) return "";
    const { first_name, last_name } = user;
    return `${first_name} ${last_name}`;
  };

  const onAutocompleteChangeSuperVisor = (
    _: unknown,
    newSupervisor: User | null
  ): void => {
    setFieldValue("supervisor", newSupervisor);
  };

  const onSearchFieldTextChange = useCallback(
    debounce(
      async (search: string): Promise<void> => {
        if (search.length < SEARCH_PARAM_MIN_CHARACTERS) {
          setOptions(preloadOptions);
          setSearchResult(preloadOptions);
        } else {
          setLoading(true);
          try {
            const response: User[] = await handleOnChangeAutocompleteInputText(
              search
            );
            const auxUsers = values.supervisor
              ? [values.supervisor, ...response]
              : [...response];
            setOptions(removeDuplicatesFromUsers(auxUsers));
            setSearchResult(removeDuplicatesFromUsers([...response]));
            setLoading(false);
          } catch (e) {
            /* istanbul ignore next */
            setOptions([]);
          }
        }
      },
      /* istanbul ignore next */ isNodeEnv(NODE_ENVIRONMENTS.TEST) ? 0 : 500
    ),

    [handleOnChangeAutocompleteInputText, preloadOptions, values.supervisor]
  );

  return (
    <Autocomplete
      id="supervisor_id"
      ListboxProps={{ style: { maxHeight: "100vh" } }}
      loading={loading}
      options={options || /* istanbul ignore next */ []}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value): boolean => option.id === value.id}
      value={values.supervisor || null}
      onChange={onAutocompleteChangeSuperVisor}
      renderOption={(props, option): ReactElement => (
        <li {...props}>{`${option.first_name} ${option.last_name}`}</li>
      )}
      PaperComponent={StyledPaper}
      renderInput={(params) => (
        <>
          <MuiTextField
            {...params}
            label={t("property.supervisor")}
            placeholder={t("property.supervisor")}
            onBlur={handleBlur}
            onChange={(e) => {
              onSearchFieldTextChange(e.target.value);
            }}
            error={Boolean(touched.supervisor && errors.supervisor)}
            helperText={touched.supervisor && errors.supervisor}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default SupervisorAutocomplete;
