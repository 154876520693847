import { ContactCreateAndEditType } from "./types";

export const initialState: ContactCreateAndEditType = {
  contact: {
    //contact edit
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    salutation: "",
    salutation_id: "",
    type: "",
    type_id: "",
    place_id: "",
    birthday: "",
    position: "",
    business_phone: "",
    mobile: "",
    private_phone: "",
    //contact address
    company: "",
    company_id: "",
    city: "",
    zip_code: "",
    street: "",
    house_number: "",
    country_id: "",
    state_id: "",
    country: "",
    //additional information
    note: "",
    ranking: null,
    department_number: "",
    worldwide_department_number: "",
    source: "",
    website_link: "",
    area: "",
    purchase_link: "",
    assets: "",
    tags: [],
    focus: [],
    management: "",
    search_profiles: [],
    finance_profiles: [],
    is_blocked: false,
    category: "",
    has_relation: false,
    state: "",
    location: {
      place_id: null,
      lat: null,
      lng: null,
      full_address: null,
      zip_code: null,
      street: null,
      city: null,
      house_number: null,
      country_code: null,
      state_code: null,
      state: null,
    },
  },
  country: null,
  company: null,
  states: [],
  countryState: null,
  companyTypes: [],
  isCreateCompanyModalOpen: false,
  searchProfiles: {
    data: [],
  },
  refreshFinanceProfiles: false,
  financeProfiles: {
    data: [],
  },
  refreshSearchProfile: false,
  isCreateContactModalVisible: false,
};

export const touchedInitState = {
  first_name: false,
  last_name: false,
  email: false,
  salutation: false,
  salutation_id: false,
  type: false,
  type_id: false,
  business_phone: false,
  mobile: false,
  private_phone: false,
  company_id: false,
  city: false,
  zip_code: false,
  street: false,
  house_number: false,
  country_id: false,
  state_id: false,
  country: false,
  place_id: false,
};

export enum EDIT_CONTACT_TABS {
  CONTACT_DETAILS = "contact-details",
  SEARCH_PROFILE = "search-profile",
  FINANCE_PROFILE = "finance-profiles",
}
