import { ManageAccounts } from "@mui/icons-material";
import { Button, Grid, Tooltip } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import StatusLeftBy from "../statusLeftBy/StatusLeftBy";
import { CancelIcon } from "../../invoices/list/styled";
import { getUsers } from "../../../../api/users";
import { User } from "../../../../types/be/user";
import { AssignUserProps } from "./types";
import { DOCUMENT_RELEASE_MODULE } from "../../../../types/be/releaseFlow";
import { editContract } from "../../../../api/contracts";
import { updateInvoice } from "../../../../api/invoices";
import Dropdown from "../../../../ui/dropdown";
import { AutocompleteOption } from "../../../../ui/dropdown/types";
import { assignUserToOfferTopic } from "../../../../api/offerTopic";
import { assignUserToInsuranceTopic } from "../../../../api/insuranceTopics";

/* istanbul ignore next */
const AssignUser = ({
  relation,
  relationId,
  afterAssignUser,
  assignedUser,
  propertyId,
  hasAssignUserPermission,
}: AssignUserProps): ReactElement => {
  const { t } = useTranslation();
  const [isAssignUserFormOpen, setIsAssignUserFormOpen] = useState(false);
  const [isWaitingForUserAssignment, setIsWaitingForUserAssignment] =
    useState(false);

  const [userAssigned, setUserAssigned] = useState<null | User>(null);

  const handleUserAssign = async (
    id?: string,
    event?: AutocompleteOption | null
  ): Promise<void> => {
    setIsWaitingForUserAssignment(true);
    setUserAssigned(event as User);
    if (relation === DOCUMENT_RELEASE_MODULE.CONTRACT) {
      await editContract({
        id: Number(relationId),
        assigned_user_id: Number(id),
      });
    } else if (relation === DOCUMENT_RELEASE_MODULE.INVOICE) {
      await updateInvoice({
        id: Number(relationId),
        assigned_user_id: Number(id),
      });
    } else if (
      [
        DOCUMENT_RELEASE_MODULE.OFFER,
        DOCUMENT_RELEASE_MODULE.OFFER_TOPIC,
      ].includes(relation)
    ) {
      await assignUserToOfferTopic(Number(relationId), Number(id));
    } else if (
      [
        DOCUMENT_RELEASE_MODULE.INSURANCE_TOPIC,
        DOCUMENT_RELEASE_MODULE.INSURANCE,
      ].includes(relation)
    ) {
      await assignUserToInsuranceTopic(Number(relationId), Number(id));
    }

    afterAssignUser && afterAssignUser();
    setIsWaitingForUserAssignment(false);
    setIsAssignUserFormOpen(false);
  };

  if (isAssignUserFormOpen) {
    return (
      <Grid item xs={10} display="flex">
        <Dropdown
          id="assign-to-autocomplete"
          name="assign_to"
          size="small"
          label={t("search")}
          placeholder={t("search")}
          getOptions={getUsers}
          onSelectOption={handleUserAssign}
          optionLabel={"full_name"}
          optionValue={"id"}
          defaultValue={userAssigned || null}
          enableReInitialization
          disabled={isWaitingForUserAssignment}
          sx={{ minHeight: 0, mb: 0, mr: 2, width: "150px" }}
          error={false}
          propertyId={propertyId}
          isAssignUserSearch
          relation={relation}
        />
        <CancelIcon onClick={() => setIsAssignUserFormOpen(false)} />
      </Grid>
    );
  }

  return (
    <>
      <Tooltip title={t("assignTo")} placement="top">
        <span>
          <Button
            color={"primary"}
            variant={"outlined"}
            size="small"
            sx={{ minWidth: 0 }}
            onClick={() => setIsAssignUserFormOpen(true)}
            disabled={!hasAssignUserPermission}
          >
            <ManageAccounts />
          </Button>
        </span>
      </Tooltip>
      {assignedUser ? (
        <StatusLeftBy {...assignedUser} tooltip align="end" />
      ) : null}
    </>
  );
};

export default AssignUser;
