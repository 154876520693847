/* istanbul ignore file */

import { Paper as MuiPaper } from "@mui/material";
import styled from "styled-components";

export const Paper = styled(MuiPaper)`
  padding: ${(props): string => props.theme.spacing(6)};
  width: ${(props): string => props.theme.spacing(200)};
  .drop-box {
    position: static;
    height: 40vh;
    margin: ${(props): string => props.theme.spacing(0)};
    .MuiBox-root {
      margin: ${(props): string => props.theme.spacing(0)};
    }
  }
`;
