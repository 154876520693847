import { Grid, IconButton } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormikValues } from "formik";
import Button from "../../../../ui/button/Button";
import ContactAutoCompleteField from "../../../../components/contacts/autocomplete";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import {
  FOLDER_NAMES,
  FORMAT_NUMBER_DE,
  Measurement_Units,
} from "../../../../constants";
import { FormatNumber } from "../../../../ui/formsFields/formatNumber";
import Dropdown from "../../../../ui/dropdown";
import { getAreas } from "../../../../api/area";
import { route } from "../../../../utils/url";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router";
import FileInputField from "../../../../ui/formsFields/fileInput";
import { StyledGrid } from "./styled";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const Form = ({
  errors,
  values,
  touched,
  status,
  handleBlur,
  handleChange,
  setFieldValue,
  handleSubmit,
  isSubmitting,
  formMessage,
  offer,
  fileName,
  setFileName,
  setFormInitState,
  handleOsc,
  handleOpenDialog,
  setSelectedFileId,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  /*istambul ignore next*/
  useEffect(() => {
    setSelectedFileId(values?.file_id);
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      {formMessage.text ? (
        <FormAlert formMessage={formMessage} sx={{ mb: "10px" }} />
      ) : null}
      <Grid container spacing={6}>
        <Grid item sm={6} md={6}>
          <Dropdown
            id="property-area-autocomplete"
            name="area_id"
            value={values?.area_id || ""}
            label={t("counter.searchArea")}
            placeholder={t("counter.searchArea")}
            getOptions={getAreas}
            onSelectOption={(id?: string) => {
              setFieldValue("area_id", id);
            }}
            size="medium"
            defaultValue={values?.area}
            enableReInitialization
            error={Boolean(status?.errors?.area_id || errors?.area_id)}
            helperText={errors?.area_id || status?.errors?.area_id}
            isAreaSearch={true}
            propertyId={offer?.property?.id}
            isShowAllOption={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormatNumber
            name="amount"
            label={t("offers.amount")}
            required
            allowNegative
            size="medium"
            status={status}
            fullWidth
            inputProps={{
              "data-testid": "amount-field",
              filter: FORMAT_NUMBER_DE,
              unit: Measurement_Units.EURO,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={11}>
              <FileInputField
                required
                name="file_id"
                label={t("offers.uploadfile")}
                error={Boolean(
                  touched.file_id && (status?.errors.file_id || errors.file_id)
                )}
                helperText={
                  touched.file_id && (status?.errors.file_id || errors.file_id)
                }
                foldername={FOLDER_NAMES.OFFER}
                propertyid={id}
                testId="offer-file"
                inputProps={{ "data-testid": "offer-file" }}
                accept="application/pdf"
                errorText={t("offers.error.pdfOnly")}
                outerFileName={fileName}
                setOuterFileName={setFileName}
                setFormInitState={setFormInitState}
                handleOsc={handleOsc}
              />
            </Grid>
            <StyledGrid item xs={1} pt={3}>
              <IconButton
                color="primary"
                aria-label="uploadPdf"
                component="span"
                size="small"
                onClick={handleOpenDialog}
              >
                <InsertDriveFileIcon />
              </IconButton>
            </StyledGrid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ContactAutoCompleteField
            {...{
              errors,
              values,
              touched,
              status,
              handleBlur,
              handleChange,
              setFieldValue,
            }}
          />
        </Grid>
        <Grid item sm={12} display="flex" justifyContent="space-between">
          <Grid item>
            <Button
              type="button"
              color="primary"
              variant="text"
              title={t("cancel")}
              disabled={isSubmitting}
              size="large"
              onClick={
                /* istanbul ignore next */ () =>
                  doesAnyHistoryEntryExist
                    ? navigate(-1)
                    : id
                    ? navigate(route("properties.offers.new", id))
                    : navigate(route("offers.new"))
              }
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="success"
              title={t("save")}
              type="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting}
              testId="submit-offer"
              size="large"
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
