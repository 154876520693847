import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import DialogTitle from "../../../../../ui/dialog/DialogTitle";
import { Divider } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import { TenantsListModalProps } from "../types";
import { formatTimestamp } from "../../../../../utils/date";
/*istanbul ignore next*/
const TenantsListModal = ({
  isTenantsListModalOpen,
  handleTenantsListModalClose,
  tenantAutocompleteValue,
  handleRemoveTenant,
  values,
  setFieldValue,
  handleSendCredentials,
}: TenantsListModalProps): ReactElement => {
  const { t } = useTranslation();

  useEffect(() => {
    tenantAutocompleteValue?.length === 0 && handleTenantsListModalClose();
  }, [tenantAutocompleteValue]);

  return (
    <Dialog
      maxWidth="xs"
      open={isTenantsListModalOpen}
      onClose={handleTenantsListModalClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="create-vacation-holiday-dialog"
      fullWidth
    >
      <DialogTitle
        title="tenant.tenantsList"
        onClose={handleTenantsListModalClose}
      />
      <DialogContent>
        <Stack>
          <List
            sx={{
              width: "100%",
              maxWidth: 400,
            }}
          >
            {tenantAutocompleteValue?.map((value) => (
              <React.Fragment key={value.id}>
                <Divider />
                <ListItem
                  disableGutters
                  secondaryAction={
                    <>
                      <IconButton
                        aria-label="comment"
                        onClick={() => handleSendCredentials(value)}
                      >
                        <EmailIcon />
                      </IconButton>
                      <IconButton
                        aria-label={t("property.tenants.delete")}
                        onClick={() =>
                          handleRemoveTenant(value, {
                            values,
                            setFieldValue,
                          })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  }
                >
                  <ListItemText
                    sx={{ width: 130 }}
                    primary={`${value.first_name} ${value.last_name}`}
                  />
                  {value.send_credential_history && true ? (
                    <ListItemText
                      primary={t("tenant.credentialsWasSent")}
                      secondary={formatTimestamp(
                        value.send_credential_history?.created_at
                      )}
                    />
                  ) : (
                    <ListItemText primary={"---"} />
                  )}
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
          >
            <Grid item>
              <Button
                onClick={handleTenantsListModalClose}
                title={t("cancel")}
                testId={"cancel-create-company-button"}
                variant={"text"}
                type="button"
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default TenantsListModal;
