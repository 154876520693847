import React, { ReactElement, useEffect, useState } from "react";
import { DatePickerWrapper } from "../../../../../ui/datePicker/styled";
import DatePicker, { registerLocale } from "react-datepicker";
import { DATEPICKER_DATE_FORMAT } from "../../../../../constants";
import { CloseIcon, DatepickerTextField } from "../styled";
import { initUserActivityRange, langDict, LOCALE_KEY } from "../../utils";
import { DateRange, DateRangeFilterProps } from "../../types";

const DateRangeFilter = ({
  dateRange,
  setDateRange,
}: DateRangeFilterProps): ReactElement => {
  const currentLang = window.localStorage.getItem("lang");
  currentLang && registerLocale(LOCALE_KEY, langDict[currentLang]);
  const [auxDateRange, setAuxDateRange] = useState(dateRange);
  const [auxStartDate, auxEndDate] = auxDateRange;

  useEffect(() => {
    setAuxDateRange(dateRange);
  }, [dateRange]);

  const handleClear = (event: React.MouseEvent<SVGSVGElement>): void => {
    event.stopPropagation();
    setDateRange(initUserActivityRange);
  };
  const handleChange = (update: DateRange): void => {
    setAuxDateRange(update);
    update.filter((date) => date).length > 1 && setDateRange(update);
  };

  return (
    <DatePickerWrapper>
      <DatePicker
        locale={LOCALE_KEY}
        selectsRange={true}
        startDate={auxStartDate}
        endDate={auxEndDate}
        onChange={handleChange}
        dateFormat={DATEPICKER_DATE_FORMAT}
        customInput={
          <DatepickerTextField
            inputProps={{
              readOnly: true,
              "data-testid": "date-range-input",
            }}
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <CloseIcon
                  data-testid="remove-from-date-range"
                  onClick={handleClear}
                />
              ),
            }}
          />
        }
      />
    </DatePickerWrapper>
  );
};

export default DateRangeFilter;
