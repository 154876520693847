/*
 *  Contracts
 */
import http from "../utils/http";
import { ContractForm, ReleaseProcessRoles } from "../types/fe.interfaces";
import { IdPropType } from "../types/models";
import { RELEASE_LEVEL } from "../utils/common";

export const getContracts = async (params: string): Promise<Response> => {
  return await http.get(`/api/contracts${params}`);
};

export const getContractById = async (id: string): Promise<Response> => {
  return await http.get(`/api/contracts/${id}`);
};

export const getContract = async (id?: string): Promise<Response> => {
  return await http.get(`/api/contracts/${id}`);
};

export const editContract = async (
  data: Partial<ContractForm>
): Promise<Response> => {
  return await http.put(`/api/contracts/${data.id}`, data);
};

/*
 *  Contracts create
 */
export const createContract = async (data: ContractForm): Promise<Response> => {
  return await http.post(`/api/contracts`, data);
};
/*
 *  Contracts statuses
 */
/* istanbul ignore next */
export const updateContractStatus = async (
  id: number,
  status_id: number,
  who: ReleaseProcessRoles
): Promise<Response> => {
  return http.patch(`/api/contracts/${id}/statuses/${status_id}/${who}`, {});
};

/*
 *  Contracts statuses
 */
export const getContractStatuses = async (): Promise<Response> => {
  return await http.get(`/api/contracts/statuses`);
};

/*
 *  Contracts statuses
 */
export const deleteContract = async ({ id }: IdPropType): Promise<Response> => {
  return await http.delete(`/api/contracts/${id}`);
};

export const updateBulkContractsStatus = async (
  contract_id: number[],
  status_id: number
): Promise<Response> => {
  return http.post(`/api/bulk-contracts/${status_id}/statuses`, {
    ids: contract_id,
  });
};

/* istanbul ignore next */
export const forwardRequiredReleaseToAdmin = async (
  contractId: number | number[],
  text?: string
): Promise<Response> => {
  return await http.post(`/api/contracts/${contractId}/final-releaser`, {
    text,
  });
};

/* istanbul ignore next */
export const releaseContract = async (
  id: string,
  level: RELEASE_LEVEL,
  params: string
): Promise<Response> => {
  return await http.patch(`/api/contracts/${id}/${level}${params}`, {});
};

export const getReleasedAndOldContract = async (
  params: string,
  cancelPrevious = false
): Promise<Response> => {
  return await http.get(
    `/api/contracts/released-or-old${params}`,
    cancelPrevious
  );
};
