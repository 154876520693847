import React, { ReactElement, useState, useCallback, useEffect } from "react";
import Table from "../../../../../../ui/table/Table";
import useTable from "../../../../../../ui/table/useTable";
import {
  deleteReminder,
  getReminderList,
} from "../../../../../../api/reminder";
import { getJson } from "../../../../../../utils/http";
import useSnackbar from "../../../../../../ui/snackbar1/useSnackbar";
import { Reminder } from "../../../../../../types/be/reminder";
import TenantReminderListRow from "./TenantReminderListRow";
import ToolBar from "./Toolbar";
import { CreateTenantReminderModal } from "./create";
import { itemsListHeadCells } from "./TenantReminderListRow/utils";

/* istanbul ignore next */
const TenantReminder = (): ReactElement => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { Snackbar, snackbar } = useSnackbar();

  const handleCreateModalOpen = (): void => {
    setIsCreateModalOpen(true);
  };
  const handleCreateModalClose = (): void => {
    setIsCreateModalOpen(false);
  };

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleDeleteLastPageData,
  } = useTable<Reminder>({});

  const fetchReminder = useCallback(() => {
    setIsLoading(true);
    getReminderList(queryParams)
      .then(getJson)
      .then(({ data, meta }) => {
        setData(data);
        setTotal(meta.total);
        setIsLoading(false);
      })
      .catch((err) => setError(err));
  }, [queryParams, setIsLoading, setTotal, setData]);

  useEffect(() => {
    fetchReminder();
  }, [queryParams]);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteReminder(rowToDelete.id);
    handleDeleteLastPageData(fetchReminder);
    handleConfirmToRemoveModalClose();
  }, [rowToDelete, fetchReminder, handleConfirmToRemoveModalClose]);

  const renderRow = (row: Reminder): ReactElement => (
    <>
      <TenantReminderListRow
        row={row}
        snackbar={snackbar}
        openConfirmToRemoveModal={openConfirmToRemoveModal}
        fetchReminder={fetchReminder}
      />
    </>
  );

  const tableToolbar = (
    <ToolBar handleCreateModalOpen={handleCreateModalOpen}></ToolBar>
  );

  return (
    <>
      <CreateTenantReminderModal
        isCreateModalOpen={isCreateModalOpen}
        handleCreateModalClose={handleCreateModalClose}
        snackbar={snackbar}
        fetchReminder={fetchReminder}
      />
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onRowRemove={handleRowRemove}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={tableToolbar}
        noDataIsAvailablePlaceholder={"table.noAreasAreAvailable"}
      />
      {Snackbar}
    </>
  );
};

export default TenantReminder;
