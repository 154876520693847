import { TableCell } from "../../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "requests.title",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "requests.description",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "requests.status",
    align: "center",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];
