import { FormikValues } from "formik/dist/types";
import { mapBackendValidationErrors } from "../../../utils/form";
import { AppDispatch } from "../../../redux/store";
import { setUser } from "../../../redux/slices/user/user";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { editUser } from "../../../api/users";
import { replaceNullOrUndefinedByEmptyString } from "../../../utils/common";
import {
  formationDateForBackEnd,
  replaceTimestampByMomentDateOrNull,
} from "../../../utils/date";
import { FormSubmitHandlerProps } from "../types";
import { Role, Salutation, User } from "../../../types/models";
import omit from "lodash/omit";

export const touchedInitState = {
  first_name: false,
  last_name: false,
  email: false,
  password: false,
  password_confirmation: false,
  phone_number: false,
  mobile_number: false,
  salutation: false,
};

export const setUserOptions = (data: User): User => ({
  ...data,
  ...replaceNullOrUndefinedByEmptyString(omit(data, ["birthday"])),
  ...replaceTimestampByMomentDateOrNull({
    birthday: data.birthday,
  }),
  role_id: String((data.role as Role)?.id),
  salutation_id: (data.salutation as Salutation)?.id,
  company_id: data?.company?.id,
  password: "",
  password_confirmation: "",
});

export const prepareEditUserBeforeSubmission = (
  reqData: User,
  selectedInternalRole: boolean
): User => {
  const newObj = { ...reqData };
  newObj.birthday = formationDateForBackEnd(newObj.birthday);
  newObj.company_id = !selectedInternalRole ? newObj.company_id : null;

  if (newObj.password === "") {
    delete newObj.password;
    delete newObj.password_confirmation;
  }

  return newObj;
};

export const handleEditUserFormSubmit = (
  id: string,
  { setFormMessage }: FormSubmitHandlerProps,
  dispatch: AppDispatch,
  isLoggedUser: boolean,
  selectedInternalRole: boolean
) => {
  return async (
    values: User,
    { setStatus, setTouched, setSubmitting }: FormikValues
  ): Promise<void> => {
    const response = await editUser(
      id,
      prepareEditUserBeforeSubmission(values, selectedInternalRole)
    );
    const json = await response.json();

    if (response.status === 200) {
      const { data } = json;
      setStatus({
        success: true,
        errors: {},
      });
      setTouched(touchedInitState);
      isLoggedUser && dispatch(setUser(data));
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
      setSubmitting(false);
    }
  };
};
