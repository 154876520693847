import React, { ReactElement, useEffect } from "react";
import { Form, FormikValues } from "formik";
import { Grid, IconButton, MenuItem } from "@mui/material";
import TextField from "../../../../ui/formsFields/textField";
import Button from "../../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import { FORMAT_NUMBER_DE, Measurement_Units } from "../../../../constants";
import { FormatNumber } from "../../../../ui/formsFields/formatNumber";
import SelectOrCreateContactsAutocompleteField from "../../../contacts/autocomplete";
import { route } from "../../../../utils/url";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router";
import FileInputField from "../../../../ui/formsFields/fileInput";
import { StyledGrid } from "../create/styled";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const EditForm = ({
  status,
  values,
  isSubmitting,
  setFieldValue,
  errors,
  touched,
  handleOpenDialog,
  fileName,
  setFileName,
  setSelectedFileId,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";

  useEffect(() => {
    setSelectedFileId(values.file_id);
  }, [values.file_id]);

  return (
    <Form noValidate>
      <Grid container spacing={6}>
        <Grid item md={6} sm={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={11}>
              <FileInputField
                required
                name="file_id"
                label={t("insurance.uploadfile")}
                error={Boolean(
                  touched?.file_id &&
                    (status?.errors?.file_id || errors?.file_id)
                )}
                helperText={
                  touched?.file_id &&
                  (status?.errors?.file_id || errors?.file_id)
                }
                foldername="Verträge"
                propertyid={id}
                inputProps={{ "data-testid": "insurance-file" }}
                accept="application/pdf"
                errorText={t("contracts.error.pdfOnly")}
                outerFileName={fileName}
                setOuterFileName={setFileName}
              />
            </Grid>
            <StyledGrid item xs={1} pt={3}>
              <IconButton
                color="primary"
                aria-label="uploadPdf"
                component="span"
                size="small"
                onClick={handleOpenDialog}
              >
                <InsertDriveFileIcon />
              </IconButton>
            </StyledGrid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <SelectOrCreateContactsAutocompleteField
            {...{
              errors,
              values,
              touched,
              setFieldValue,
            }}
            required
            label={t("tenant.contact_name")}
            placeholder={t("tenant.contact_name")}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <FormatNumber
            name="amount"
            label={t("insurance.amount")}
            required
            allowNegative
            size="medium"
            status={status}
            fullWidth
            inputProps={{
              filter: FORMAT_NUMBER_DE,
              unit: Measurement_Units.EURO,
            }}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <TextField
            name="cancellation_period"
            select
            label={t("insurance.cancellationPeriod")}
            fullWidth
            status={status}
            required
            data-testid="cancellation-period-select"
          >
            {[1, 3].map((item) => (
              <MenuItem
                value={item}
                key={item}
                data-testid={`cancellation-period-${item}`}
              >
                {item} {t("insurance.month")}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid container display="flex" justifyContent="flex-end" ml={4}>
          <Grid item>
            <Button
              type="button"
              color="primary"
              variant="text"
              title={t("cancel")}
              disabled={isSubmitting}
              size="large"
              onClick={
                /* istanbul ignore next */ () =>
                  doesAnyHistoryEntryExist
                    ? navigate(-1)
                    : id
                    ? navigate(route("properties.insurances.new", id))
                    : navigate(route("insurances.new"))
              }
            />
          </Grid>
          <Grid item>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="success"
              size="large"
              title={t("update")}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default EditForm;
