import { Box, Grid } from "@mui/material";
import { Formik, FormikProps } from "formik";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  CURRENCIES,
  FINAL_RELEASER_STATUS_ID,
  FormMessageErrorState,
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { useFetch } from "../../../../hooks/useFetch";
import useValidation from "../../../../hooks/useValidation";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import { getInvoice, getInvoicePeriod } from "../../../../api/invoices";
import Form from "../Form";
import { handleInvoiceFormSubmit, prepareInvoiceBeforeForm } from "../utils";
import * as Yup from "yup";
import {
  Invoice as BEInvoice,
  InvoicePeriod,
} from "../../../../types/be.interfaces";
import { ErrorBox } from "../styled";
import Loader from "../../../Loader";
import { route } from "../../../../utils/url";
import { useTranslation } from "react-i18next";
import { getReleaseRoute } from "../../utils";
import TabPageWrapper from "../../../../ui/tabPageWrapper";
import FileTreeDialog from "../../../../ui/fileList/index";
import { Invoice as FEInvoice } from "../../../../types/fe.interfaces";
import { getFileOCR } from "../../../../api/files";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { handleServerError } from "../../../../utils/http";
import useUploadDropzone from "../../../../hooks/useUploadDropzone";
import PdfViewerWrapper from "../../components/PdfViewerWrapper";
import { LoaderContainer, Paper } from "./styled";

const InvoiceEdit = (): ReactElement => {
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const { id, invoiceId } = useParams();
  const { data, isLoading, isError, run } = useFetch<BEInvoice>();
  const { t } = useTranslation();

  const {
    number,
    date,
    invoice_provider,
    invoiceAmount,
    total,
    apportionable_percent,
    property_id,
    due_date,
    repeat_from,
    repeat_to,
    repeat_day,
    repetition_period_id,
  } = useValidation();
  const {
    setIsOcrLoading,
    isFileTreeVisible,
    isOcrLoading,
    handleOpenFileTree,
    handleCloseFileTree,
    fileName,
    setFileName,
    selectedFileId,
    setSelectedFileId,
  } = useUploadDropzone({ file: data?.file });

  const {
    run: runInvoicePeriod,
    data: invoicePeriod,
    isLoading: isInvoicePeriodLoading,
  } = useFetch<InvoicePeriod[]>();
  const formRef = useRef<FormikProps<FEInvoice> | null>(null);

  useEffect(() => {
    run(getInvoice(invoiceId));
    fetchInvoicePeriod();
  }, []);

  const fetchInvoicePeriod = useCallback(() => {
    runInvoicePeriod(getInvoicePeriod());
  }, []);
  /* istanbul ignore next */
  const handleFileTreeClick = async (
    file_id: string,
    file_name: string
  ): Promise<void> => {
    formRef?.current?.setFieldValue("file_id", parseInt(file_id));
    setFileName(file_name);
    handleCloseFileTree();
    handleOsc(+file_id, file_name);
  };
  /* istanbul ignore next */
  const handleOsc = async (fileId: number, fileName: string): Promise<void> => {
    setIsOcrLoading(true);
    try {
      const res = await getFileOCR(fileId);
      const data = await res.json();
      if (res.status === HTTP_STATUS_CODES.OK) {
        const {
          data: {
            total: amount,
            invoice_number: number,
            date,
            due_date,
            currency_code,
            vendor: { iban, bank_swift },
          },
        } = data;

        formRef?.current?.setValues({
          ...formRef?.current.values,
          amount,
          number,
          date: date ? new Date(date) : null,
          due_date,
          currency_code: CURRENCIES.includes(currency_code)
            ? currency_code
            : "",
          iban,
          bic: bank_swift,
          file_name: fileName,
        });
        setSelectedFileId(fileId);
      } else {
        const { errorMessage } = handleServerError(res);
        formRef?.current?.setFieldError("file_id", errorMessage);
        console.log(errorMessage);
      }
    } catch (error) {
      formRef?.current?.setFieldError("file_id", SOMETHING_WENT_WRONG_ERROR);
      console.log(error);
    } finally {
      setIsOcrLoading(false);
    }
  };

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      number,
      date,
      contact_id: invoice_provider,
      amount: invoiceAmount,
      total,
      apportionable_percent,
      property_id,
      due_date,
      repeat_from,
      repeat_to,
      repeat_day,
      repetition_period_id,
    });
  }, [data]);

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("invoices", Number(id), Number(invoiceId)),
      name: t("property.navLinks.invoices"),
    },
  ];

  if (isError) return <ErrorBox formMessage={FormMessageErrorState} />;

  if (isLoading || isInvoicePeriodLoading) return <Loader />;

  return (
    <>
      {data! && (
        <TabPageWrapper
          title={data.file?.name}
          breadcrumbs={breadcrumbs}
          wrap={!id}
        >
          <Paper sx={{ p: 5, position: "relative" }}>
            {
              /* istanbul ignore next */ isOcrLoading && (
                <LoaderContainer>
                  <Box>
                    <Loader />
                  </Box>
                </LoaderContainer>
              )
            }
            <Grid container justifyContent={"center"} position="relative">
              {selectedFileId && (
                <Grid item sm={6} xs={12}>
                  <PdfViewerWrapper data={selectedFileId} type="fileId" />
                </Grid>
              )}
              <Grid item xs={6} pl={6}>
                {formMessage.text && (
                  <FormAlert
                    formMessage={formMessage}
                    sx={{ marginBottom: "10px" }}
                  />
                )}

                <Formik
                  initialValues={prepareInvoiceBeforeForm(data)}
                  validationSchema={validationSchema}
                  onSubmit={handleInvoiceFormSubmit({
                    setFormMessage,
                  })}
                  innerRef={formRef}
                >
                  {(props): React.ReactElement => (
                    <Form
                      {...props}
                      propertyId={id}
                      frStatusId={FINAL_RELEASER_STATUS_ID}
                      edit={true}
                      invoicePeriod={invoicePeriod}
                      handleOpenDialog={handleOpenFileTree}
                      fileName={fileName}
                      setFileName={setFileName}
                      handleOsc={handleOsc}
                    />
                  )}
                </Formik>
                {
                  /* istanbul ignore next */ isFileTreeVisible && (
                    <FileTreeDialog
                      onClose={handleCloseFileTree}
                      handleFileTreeClick={handleFileTreeClick}
                      extensions={["pdf"]}
                    />
                  )
                }
              </Grid>
            </Grid>
          </Paper>
        </TabPageWrapper>
      )}
    </>
  );
};

export default InvoiceEdit;
