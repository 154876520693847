/* istanbul ignore next */
export const route = (...params: (string | number | undefined)[]): string => {
  const path = `/${window.localStorage.getItem("client")}`;

  switch (params[0]) {
    //auth
    case "root":
      return `${path}/`;
    case "sign-in":
      return `${path}/auth/sign-in`;
    case "forgot-password":
      return `${path}/auth/password/forgot`;
    case "reset-password":
      return `${path}/auth/password/reset`;
    case "profile.general-information":
      return `${path}/profile/general-information`;
    case "profile.notification-settings":
      return `${path}/profile/notification-settings`;

    //users
    case "users":
      return `${path}/users/user-management`;
    case "users.edit":
      return `${path}/users/user-management/edit/${params[1]}`;
    case "users.create":
      return `${path}/users/create`;
    case "users.activity":
      return `${path}/users/activity`;

    //HR
    case "human-resources.employees":
      return `${path}/human-resources/employee-overview`;
    case "human-resources.vacation-requests":
      return `${path}/human-resources/vacation-requests/vacation-list`;
    case "human-resources.company-ownership":
      return `${path}/human-resources/company-ownership`;

    //contact
    case "contacts":
      return `${path}/contacts`;
    case "contact.create":
      return `${path}/contacts/create`;
    case "contact.edit.tab":
      return `${path}/contacts/edit/${params[1]}/${params[2]}`;
    case "contacts.edit.first-tab":
      return `${path}/contacts/edit/${params[1]}/contact-details`;

    //properties
    case "properties":
      return `${path}/properties`;
    case "properties.create":
      return `${path}/properties/create`;
    case "properties.edit":
      return `${path}/properties/${params[1]}/edit/${params[2]}`;
    case "properties.edit.main":
      return `${path}/properties/${params[1]}/edit/general`;

    //property tenants
    case "properties.tenants":
      return `${path}/properties/${params[1]}/tenants`;
    case "properties.tenants.edit":
      return `${path}/properties/${params[1]}/tenants/${params[2]}/edit/surface`;
    case "properties.tenants.edit.history":
      return `${path}/properties/${params[1]}/tenants/${params[2]}/edit/history`;
    case "properties.object-data-sheet.tab":
      return `${path}/properties/${params[1]}/object-data-sheet/${params[2]}`;

    //property areas
    case "properties.areas.edit.tab":
      return `${path}/properties/${params[1]}/areas/${params[2]}/edit/${params[3]}`;
    case "properties.areas.edit.requests":
      return `${path}/properties/${params[1]}/areas/${params[2]}/edit/requests/`;
    case "properties.areas.edit.counter.reading":
      return `${path}/properties/${params[1]}/areas/${params[2]}/edit/counters/${params[3]}/readings`;
    case "properties.areas.edit.counter.reading.create":
      return `${path}/properties/${params[1]}/areas/${params[2]}/edit/counters/${params[3]}/readings/create`;
    case "properties.areas.edit.history":
      return `${path}/properties/${params[1]}/areas/${params[2]}/edit/history`;
    case "properties.areas":
      return `${path}/properties/${params[1]}/areas`;

    //company
    case "companies":
      return `${path}/companies`;
    case "companies.create":
      return `${path}/companies/create`;
    case "companies.edit":
      return `${path}/companies/${params[1]}/edit`;
    case "companies.edit.main":
      return `${path}/companies/${params[1]}/edit`;

    //outgoing invoices
    case "properties.outgoing-invoices":
      return `${path}/properties/${params[1]}/outgoing-invoices`;
    case "properties.outgoing-invoices.history":
      return `${path}/properties/${params[1]}/outgoing-invoices/history`;
    case "properties.outgoing-invoices.show":
      return `${path}/properties/${params[1]}/outgoing-invoices/${params[2]}/show`;
    case "properties.outgoing-invoices.create":
      return `${path}/properties/${params[1]}/outgoing-invoices/create`;
    case "properties.outgoing-invoices.edit":
      return `${path}/properties/${params[1]}/outgoing-invoices/${params[2]}/edit`;
    case "companies.outgoing-invoices":
      return `${path}/companies/${params[1]}/outgoing-invoices`;
    case "companies.outgoing-invoices.history":
      return `${path}/companies/${params[1]}/outgoing-invoices/history`;
    case "companies.outgoing-invoices.show":
      return `${path}/companies/${params[1]}/outgoing-invoices/${params[2]}/show`;
    case "companies.outgoing-invoices.create":
      return `${path}/companies/${params[1]}/outgoing-invoices/create`;
    case "companies.outgoing-invoices.edit":
      return `${path}/companies/${params[1]}/outgoing-invoices/${params[2]}/edit`;

    //invoices
    case "properties.invoices":
      return `${path}/properties/${params[1]}/invoices`;
    case "properties.invoices.tab":
      return `${path}/properties/${params[1]}/invoices/${params[2]}`;
    case "properties.invoices.history":
      return `${path}/properties/${params[1]}/invoices/history`;
    case "properties.invoices.new":
      return `${path}/properties/${params[1]}/invoices/new`;
    case "properties.invoices.pending":
      return `${path}/properties/${params[1]}/invoices/pending`;
    case "properties.invoices.show":
      return `${path}/properties/${params[1]}/invoices/${params[2]}/show`;
    case "properties.invoices.show.datev":
      return `${path}/properties/${params[1]}/invoices/datev/${params[2]}/show`;
    case "properties.invoices.final-released":
      return `${path}/properties/${params[1]}/invoices/final-released`;
    case "properties.invoices.forwarded":
      return `${path}/properties/${params[1]}/invoices/forwarded`;
    case "properties.invoices.rejected":
      return `${path}/properties/${params[1]}/invoices/rejected`;
    case "properties.invoices.create":
      return `${path}/properties/${params[1]}/invoices/create`;
    case "properties.invoices.edit":
      return `${path}/properties/${params[1]}/invoices/${params[2]}/edit`;
    case "properties.invoices.edit.datev":
      return `${path}/properties/${params[1]}/invoices/datev/${params[2]}/edit`;
    case "properties.invoices.reminders":
      return `${path}/properties/${params[1]}/invoices/reminders`;
    case "properties.invoice.reminders.tab":
      return `${path}/properties/${params[1]}/invoices/reminders/${params[2]}`;
    case "properties.invoice.reminders.create":
      return `${path}/properties/${params[1]}/invoices/reminders/create`;
    case "properties.invoice.datev.create":
      return `${path}/properties/${params[1]}/invoices/datev/create`;
    case "companies.invoices":
      return `${path}/companies/${params[1]}/invoices`;
    case "companies.invoices.tab":
      return `${path}/companies/${params[1]}/invoices/${params[2]}`;
    case "companies.invoices.history":
      return `${path}/companies/${params[1]}/invoices/history`;
    case "companies.invoices.new":
      return `${path}/companies/${params[1]}/invoices/new`;
    case "companies.invoices.pending":
      return `${path}/companies/${params[1]}/invoices/pending`;
    case "companies.invoices.show":
      return `${path}/companies/${params[1]}/invoices/${params[2]}/show`;
    case "companies.invoices.show.datev":
      return `${path}/companies/${params[1]}/invoices/datev/${params[2]}/show`;
    case "companies.invoices.final-released":
      return `${path}/companies/${params[1]}/invoices/final-released`;
    case "companies.invoices.forwarded":
      return `${path}/companies/${params[1]}/invoices/forwarded`;
    case "companies.invoices.rejected":
      return `${path}/companies/${params[1]}/invoices/rejected`;
    case "companies.invoices.create":
      return `${path}/companies/${params[1]}/invoices/create`;
    case "companies.invoices.edit":
      return `${path}/companies/${params[1]}/invoices/${params[2]}/edit`;
    case "companies.invoices.edit.datev":
      return `${path}/companies/${params[1]}/invoices/datev/${params[2]}/edit`;
    case "companies.invoices.reminders":
      return `${path}/companies/${params[1]}/invoices/reminders`;
    case "companies.invoice.reminders.tab":
      return `${path}/companies/${params[1]}/invoices/reminders/${params[2]}`;
    case "companies.invoice.reminders.create":
      return `${path}/companies/${params[1]}/invoices/reminders/create`;
    case "companies.invoice.datev.create":
      return `${path}/companies/${params[1]}/invoices/datev/create`;

    // API-DATEv [properties]
    case "properties.invoices.apidatev":
      return `${path}/properties/${params[1]}/api-datev/creditor`;
    case "properties.invoices.apidatev.tab":
      return `${path}/properties/${params[1]}/api-datev/${params[2]}`;
    case "properties.invoices.apidatev.tab.create":
      return `${path}/properties/${params[1]}/api-datev/${params[2]}/create`;
    case "properties.invoices.apidatev.debtor":
      return `${path}/properties/${params[1]}/api-datev/debtor`;
    case "properties.invoices.apidatev.debitaccount":
      return `${path}/properties/${params[1]}/api-datev/debit-account`;
    case "companies.invoices.apidatev":
      return `${path}/companies/${params[1]}/api-datev/creditor`;
    case "companies.invoices.apidatev.tab":
      return `${path}/companies/${params[1]}/api-datev/${params[2]}`;
    case "companies.invoices.apidatev.tab.create":
      return `${path}/companies/${params[1]}/api-datev/${params[2]}/create`;
    case "companies.invoices.apidatev.debtor":
      return `${path}/companies/${params[1]}/api-datev/debtor`;
    case "companies.invoices.apidatev.debitaccount":
      return `${path}/companies/${params[1]}/api-datev/debit-account`;

    //contracts
    case "properties.contracts.create":
      return `${path}/properties/${params[1]}/contracts/create`;
    case "properties.contracts.edit":
      return `${path}/properties/${params[1]}/contracts/${params[2]}/edit`;
    case "properties.contracts.show":
      return `${path}/properties/${params[1]}/contracts/${params[2]}/show`;
    case "properties.contracts":
      return `${path}/properties/${params[1]}/contracts`;
    case "properties.contracts.tab":
      return `${path}/properties/${params[1]}/contracts/${params[2]}`;
    case "properties.contracts.history":
      return `${path}/properties/${params[1]}/contracts/history`;
    case "properties.contracts.new":
      return `${path}/properties/${params[1]}/contracts/new`;
    case "properties.contracts.pending":
      return `${path}/properties/${params[1]}/contracts/pending`;
    case "properties.contracts.final-released":
      return `${path}/properties/${params[1]}/contracts/final-released`;
    case "properties.contracts.forwarded":
      return `${path}/properties/${params[1]}/contracts/forwarded`;
    case "properties.contracts.rejected":
      return `${path}/properties/${params[1]}/contracts/rejected`;
    case "properties.contracts.existing-old-contracts":
      return `${path}/properties/${params[1]}/contracts/existing-old-contracts`;
    case "properties.contracts.old-contracts":
      return `${path}/properties/${params[1]}/contracts/old-contracts`;
    //contracts
    case "companies.contracts.create":
      return `${path}/companies/${params[1]}/contracts/create`;
    case "companies.contracts.edit":
      return `${path}/companies/${params[1]}/contracts/${params[2]}/edit`;
    case "companies.contracts.show":
      return `${path}/companies/${params[1]}/contracts/${params[2]}/show`;
    case "companies.contracts":
      return `${path}/companies/${params[1]}/contracts`;
    case "companies.contracts.tab":
      return `${path}/companies/${params[1]}/contracts/${params[2]}`;
    case "companies.contracts.history":
      return `${path}/companies/${params[1]}/contracts/history`;
    case "companies.contracts.new":
      return `${path}/companies/${params[1]}/contracts/new`;
    case "companies.contracts.pending":
      return `${path}/companies/${params[1]}/contracts/pending`;
    case "companies.contracts.final-released":
      return `${path}/companies/${params[1]}/contracts/final-released`;
    case "companies.contracts.forwarded":
      return `${path}/companies/${params[1]}/contracts/forwarded`;
    case "companies.contracts.rejected":
      return `${path}/companies/${params[1]}/contracts/rejected`;
    case "companies.contracts.existing-old-contracts":
      return `${path}/companies/${params[1]}/contracts/existing-old-contracts`;
    case "companies.contracts.old-contracts":
      return `${path}/companies/${params[1]}/contracts/old-contracts`;
    //offers
    case "properties.offers.create":
      return `${path}/properties/${params[1]}/offers/${params[2]}/create`;
    case "properties.offers.edit":
      return `${path}/properties/${params[1]}/offers/${params[2]}/edit`;
    case "properties.offers.show":
      return `${path}/properties/${params[1]}/offers/${params[2]}/show`;
    case "properties.offers":
      return `${path}/properties/${params[1]}/offers`;
    case "properties.offers.tab":
      return `${path}/properties/${params[1]}/offers/${params[2]}`;
    case "properties.offers.history":
      return `${path}/properties/${params[1]}/offers/history`;
    case "properties.offers.new":
      return `${path}/properties/${params[1]}/offers/new`;
    case "properties.offers.pending":
      return `${path}/properties/${params[1]}/offers/pending`;
    case "properties.offers.final-released":
      return `${path}/properties/${params[1]}/offers/final-released`;
    case "properties.offers.rejected":
      return `${path}/properties/${params[1]}/offers/rejected`;
    case "properties.offers.forwarded":
      return `${path}/properties/${params[1]}/offers/forwarded`;
    case "properties.offers.existing-old-contracts":
      return `${path}/properties/${params[1]}/offers/existing-old-contracts`;
    case "companies.offers.create":
      return `${path}/companies/${params[1]}/offers/${params[2]}/create`;
    case "companies.offers.edit":
      return `${path}/companies/${params[1]}/offers/${params[2]}/edit`;
    case "companies.offers.show":
      return `${path}/companies/${params[1]}/offers/${params[2]}/show`;
    case "companies.offers":
      return `${path}/companies/${params[1]}/offers`;
    case "companies.offers.tab":
      return `${path}/companies/${params[1]}/offers/${params[2]}`;
    case "companies.offers.history":
      return `${path}/companies/${params[1]}/offers/history`;
    case "companies.offers.new":
      return `${path}/companies/${params[1]}/offers/new`;
    case "companies.offers.pending":
      return `${path}/companies/${params[1]}/offers/pending`;
    case "companies.offers.final-released":
      return `${path}/companies/${params[1]}/offers/final-released`;
    case "companies.offers.rejected":
      return `${path}/companies/${params[1]}/offers/rejected`;
    case "companies.offers.forwarded":
      return `${path}/companies/${params[1]}/offers/forwarded`;
    case "companies.offers.existing-old-contracts":
      return `${path}/companies/${params[1]}/offers/existing-old-contracts`;
    //insurances
    case "properties.insurances":
      return `${path}/properties/${params[1]}/insurances/building/forwarded`;
    case "properties.insurances.type.status":
      return `${path}/properties/${params[1]}/insurances/${params[2]}/${params[3]}`;
    case "properties.insurances.building":
      return `${path}/properties/${params[1]}/insurances/building/${params[2]}`;
    case "properties.insurances.liability":
      return `${path}/properties/${params[1]}/insurances/liability/${params[2]}`;
    case "properties.insurances.new":
      return `${path}/properties/${params[1]}/insurances/${params[2]}/new`;
    case "properties.insurances.pending":
      return `${path}/properties/${params[1]}/insurances/${params[2]}/pending`;
    case "properties.insurances.final-released":
      return `${path}/properties/${params[1]}/insurances/${params[2]}/final-released`;
    case "properties.insurances.rejected":
      return `${path}/properties/${params[1]}/insurances/${params[2]}/rejected`;
    case "properties.insurances.forwarded":
      return `${path}/properties/${params[1]}/insurances/${params[2]}/forwarded`;
    case "properties.insurances.create":
      return `${path}/properties/${params[1]}/insurances/${params[2]}/create`;
    case "properties.insurances.edit":
      return `${path}/properties/${params[1]}/insurances/${params[2]}/edit`;
    case "properties.insurances.show":
      return `${path}/properties/${params[1]}/insurances/${params[2]}/show`;
    case "properties.insurances.history":
      return `${path}/properties/${params[1]}/insurances/history`;
    case "companies.insurances":
      return `${path}/companies/${params[1]}/insurances/building/forwarded`;
    case "companies.insurances.type.status":
      return `${path}/companies/${params[1]}/insurances/${params[2]}/${params[3]}`;
    case "companies.insurances.building":
      return `${path}/companies/${params[1]}/insurances/building/${params[2]}`;
    case "companies.insurances.liability":
      return `${path}/companies/${params[1]}/insurances/liability/${params[2]}`;
    case "companies.insurances.new":
      return `${path}/companies/${params[1]}/insurances/${params[2]}/new`;
    case "companies.insurances.pending":
      return `${path}/companies/${params[1]}/insurances/${params[2]}/pending`;
    case "companies.insurances.final-released":
      return `${path}/companies/${params[1]}/insurances/${params[2]}/final-released`;
    case "companies.insurances.rejected":
      return `${path}/companies/${params[1]}/insurances/${params[2]}/rejected`;
    case "companies.insurances.forwarded":
      return `${path}/companies/${params[1]}/insurances/${params[2]}/forwarded`;
    case "companies.insurances.create":
      return `${path}/companies/${params[1]}/insurances/${params[2]}/create`;
    case "companies.insurances.edit":
      return `${path}/companies/${params[1]}/insurances/${params[2]}/edit`;
    case "companies.insurances.show":
      return `${path}/companies/${params[1]}/insurances/${params[2]}/show`;
    case "companies.insurances.history":
      return `${path}/companies/${params[1]}/insurances/history`;
    // counters
    case "properties.counter.create":
      return `${path}/properties/${params[1]}/tenants/${params[2]}/counters/create`;
    //requests
    case "properties.requests.create":
      return `${path}/properties/${params[1]}/tenants/${params[2]}/requests/create`;
    case "properties.requests.show":
      return `${path}/properties/${params[1]}/tenants/${params[2]}/requests/${params[3]}/show`;
    case "properties.requests.edit":
      return `${path}/properties/${params[1]}/tenants/${params[2]}/requests/${params[3]}/edit`;
    //file-management
    case "properties.file-management":
      return `${path}/properties/${params[1]}/file-management`;
    case "companies.file-management":
      return `${path}/companies/${params[1]}/file-management`;

    //imprint
    case "imprint":
      return `${path}/imprint`;

    //file-management
    case "file-management":
      return `${path}/file-management`;

    //employee-overview
    case "employee-overview":
      return `${path}/employee-overview`;

    //bankAccount account
    case "bank-accounts":
      return `${path}/bank-accounts`;
    case `bank-account.all-sales`:
      return `${path}/bank-accounts/${params[1]}/payments/all-sales`;
    case `bank-account.proposals`:
      return `${path}/bank-accounts/${params[1]}/payments/proposals`;
    case `bank-account.assigned`:
      return `${path}/bank-accounts/${params[1]}/payments/assigned`;
    case `bank-account.not-assigned`:
      return `${path}/bank-accounts/${params[1]}/payments/not-assigned`;
    case `bank-account.automatically-assigned`:
      return `${path}/bank-accounts/${params[1]}/payments/automatically-assigned`;
    case `bank-account.ignored`:
      return `${path}/bank-accounts/${params[1]}/payments/ignored`;

    //company-ownership
    case "company-ownership.create":
      return `${path}/human-resources/company-ownership/create`;
    case "company-ownership.edit":
      return `${path}/human-resources/company-ownership/${params[1]}/edit`;
    case "company-ownership.history":
      return `${path}/human-resources/company-ownership/${params[1]}/history`;

    //vacation-requests
    case "vacation-requests.tab":
      return `${path}/human-resources/vacation-requests/${params[1]}`;

    //privacy
    case "privacy":
      return `${path}/privacy`;
    default:
      return `${path}/`;

    //general invoices
    case "invoices":
      return `${path}/document-release/invoices`;
    case "invoices.new":
      return `${path}/document-release/invoices/new`;
    case "invoices.pending":
      return `${path}/document-release/invoices/pending`;
    case "invoices.show":
      return `${path}/document-release/invoices/${params[1]}/show`;
    case "invoices.show.datev":
      return `${path}/document-release/invoices/datev/${params[1]}/show`;
    case "invoices.final-released":
      return `${path}/document-release/invoices/final-released`;
    case "invoices.forwarded":
      return `${path}/document-release/invoices/forwarded`;
    case "invoices.rejected":
      return `${path}/document-release/invoices/rejected`;
    case "invoices.create":
      return `${path}/document-release/invoices/create`;
    case "invoice.datev.create":
      return `${path}/document-release/invoices/datev/create`;
    case "invoices.edit":
      return `${path}/document-release/invoices/${params[1]}/edit`;
    case "invoices.edit.datev":
      return `${path}/document-release/invoices/datev/${params[1]}/edit`;
    case "invoices.history":
      return `${path}/document-release/invoices/history`;
    case "invoices.reminders":
      return `${path}/document-release/invoices/reminders`;

    // API-DATEv [document-release]
    case "invoice.reminders.tab":
      return `${path}/document-release/invoices/reminders/${params[1]}`;
    case "invoice.reminders.create":
      return `${path}/document-release/invoices/reminders/create`;

    //general outgoing invoices
    case "outgoing-invoices":
      return `${path}/document-release/outgoing-invoices`;
    case "outgoing-invoices.history":
      return `${path}/document-release/outgoing-invoices/history`;
    case "outgoing-invoices.show":
      return `${path}/document-release/outgoing-invoices/${params[1]}/show`;
    case "outgoing-invoices.create":
      return `${path}/document-release/outgoing-invoices/create`;
    case "outgoing-invoices.edit":
      return `${path}/document-release/outgoing-invoices/${params[1]}/edit`;

    //general contracts
    case "contracts.create":
      return `${path}/document-release/contracts/create`;
    case "contracts.edit":
      return `${path}/document-release/contracts/${params[1]}/edit`;
    case "contracts.show":
      return `${path}/document-release/contracts/${params[1]}/show`;
    case "contracts":
      return `${path}/document-release/contracts`;
    case "contracts.new":
      return `${path}/document-release/contracts/new`;
    case "contracts.pending":
      return `${path}/document-release/contracts/pending`;
    case "contracts.final-released":
      return `${path}/document-release/contracts/final-released`;
    case "contracts.forwarded":
      return `${path}/document-release/contracts/forwarded`;
    case "contracts.rejected":
      return `${path}/document-release/contracts/rejected`;
    case "contracts.existing-old-contracts":
      return `${path}/document-release/contracts/existing-old-contracts`;
    case "contracts.old-contracts":
      return `${path}/document-release/contracts/old-contracts`;
    case "contracts.history":
      return `${path}/document-release/contracts/history`;

    //general offers
    case "offers.create":
      return `${path}/document-release/offers/${params[1]}/create`;
    case "offers.edit":
      return `${path}/document-release/offers/${params[1]}/edit`;
    case "offers.show":
      return `${path}/document-release/offers/${params[1]}/show`;
    case "offers":
      return `${path}/document-release/offers`;
    case "offers.new":
      return `${path}/document-release/offers/new`;
    case "offers.pending":
      return `${path}/document-release/offers/pending`;
    case "offers.final-released":
      return `${path}/document-release/offers/final-released`;
    case "offers.forwarded":
      return `${path}/document-release/offers/forwarded`;
    case "offers.rejected":
      return `${path}/document-release/offers/rejected`;
    case "offers.existing-old-contracts":
      return `${path}/document-release/offers/existing-old-contracts`;
    case "offers.history":
      return `${path}/document-release/offers/history`;

    //general insurances
    case "insurances":
      return `${path}/document-release/insurances`;
    case "insurances.building":
      return `${path}/document-release/insurances/building/${params[1]}`;
    case "insurances.liability":
      return `${path}/document-release/insurances/liability/${params[1]}`;
    case "insurances.create":
      return `${path}/document-release/insurances/${params[1]}/create`;
    case "insurances.edit":
      return `${path}/document-release/insurances/${params[1]}/edit`;
    case "insurances.show":
      return `${path}/document-release/insurances/${params[1]}/show`;
    case "insurances.new":
      return `${path}/document-release/insurances/${params[1]}/new`;
    case "insurances.pending":
      return `${path}/document-release/insurances/${params[1]}/pending`;
    case "insurances.final-released":
      return `${path}/document-release/insurances/${params[1]}/final-released`;
    case "insurances.rejected":
      return `${path}/document-release/insurances/${params[1]}/rejected`;
    case "insurances.forwarded":
      return `${path}/document-release/insurances/${params[1]}/forwarded`;
    case "insurances.history":
      return `${path}/document-release/insurances/history`;

    //general maintenance
    case "maintenance":
      return `${path}/maintenance`;
    case "maintenance.create":
      return `${path}/maintenance/create`;
    case "maintenance.edit":
      return `${path}/maintenance/${params[1]}/edit`;
    case "maintenance.units":
      return `${path}/maintenance/${params?.[1]}/units`;

    // inbox
    case "inbox":
      return `${path}/inbox`;
    case "inbox.tab":
      return `${path}/inbox/${params[1]}`;
    case "inbox.create":
      return `${path}/inbox/create`;
    case "properties.inbox":
      return `${path}/properties/${params[1]}/inbox`;
    case "properties.inbox.create":
      return `${path}/properties/${params[1]}/inbox/create`;
    case "properties.inbox.tab":
      return `${path}/properties/${params[1]}/inbox/${params[2]}`;
    case "companies.inbox":
      return `${path}/companies/${params[1]}/inbox`;
    case "companies.inbox.create":
      return `${path}/companies/${params[1]}/inbox/create`;
    case "companies.inbox.tab":
      return `${path}/companies/${params[1]}/inbox/${params[2]}`;

    // general-documents
    case "general-documents":
      return `${path}/general-documents`;
    case "general-documents.tab":
      return `${path}/general-documents/${params[1]}`;
    case "general-documents.create":
      return `${path}/general-documents/create`;
    case "general-documents.show":
      return `${path}/general-documents/${params[1]}/show`;
    case "properties.general-documents":
      return `${path}/properties/${params[1]}/general-documents`;
    case "properties.general-documents.create":
      return `${path}/properties/${params[1]}/general-documents/create`;
    case "properties.general-documents.show":
      return `${path}/properties/${params[1]}/general-documents/${params[2]}/show`;
    case "properties.general-documents.tab":
      return `${path}/properties/${params[1]}/general-documents/${params[2]}`;
    case "companies.general-documents":
      return `${path}/companies/${params[1]}/general-documents`;
    case "companies.general-documents.create":
      return `${path}/companies/${params[1]}/general-documents/create`;
    case "companies.general-documents.show":
      return `${path}/companies/${params[1]}/general-documents/${params[2]}/show`;
    case "companies.general-documents.tab":
      return `${path}/companies/${params[1]}/general-documents/${params[2]}`;

    // meter
    case "meter":
      return `${path}/meter`;
    case "createMeter":
      return `${path}/meter/create`;
    case "meter.edit":
      return `${path}/meter/${params[1]}`;
    //tenant-requests
    case "tenant-requests":
      return `${path}/tenant-requests`;
    case "tenant-requests.outdated":
      return `${path}/tenant-requests/outdated`;
    case "tenant-requests.create":
      return `${path}/tenant-requests/create`;
    case "tenant-requests.edit":
      return `${path}/tenant-requests/${params[1]}`;
    case "tenant-requests.history":
      return `${path}/tenant-requests/history`;

    //settings
    case "settings.roles":
      return `${path}/settings/roles`;
    case "settings.roles.create":
      return `${path}/settings/roles/create`;
    case "settings.roles.permissions":
      return `${path}/settings/roles/${params[1]}/permissions`;
    case "settings.roles.properties":
      return `${path}/settings/roles/${params[1]}/properties`;
    case "settings.release":
      return `${path}/settings/release`;

    // maintenance
    case "properties.maintenance":
      return `${path}/properties/${params[1]}/maintenance`;
    case "properties.maintenance.create":
      return `${path}/properties/${params[1]}/maintenance/create`;
    case "properties.maintenance.edit":
      return `${path}/properties/${params[1]}/maintenance/${params?.[2]}/edit`;
    case "properties.maintenance.units":
      return `${path}/properties/${params[1]}/maintenance/${params?.[2]}/units`;

    //loi
    case "loi":
      return `${path}/transaction-management/loi`;
    case "loi.create":
      return `${path}/transaction-management/loi/create`;
    case "loi.history":
      return `${path}/transaction-management/loi/${params[1]}/history`;
    case "loi.edit":
      return `${path}/transaction-management/loi/${params[1]}/edit`;

    // vacancies
    case "vacancies":
      return `${path}/vacancies`;
  }
};
