import React, { ReactElement } from "react";
import PageWrapper from "../../../ui/pageWrapper/PageWrapper";
import usePropertyWrapper from "./usePropertyWrapper";
import LinkMenu from "../components/linkMenu/LinkMenu";
import { PropertyWrapperProps } from "./types";

/* istanbul ignore next */
const PropertyWrapper = ({ isCompany }: PropertyWrapperProps): ReactElement => {
  const { getBreadcrumb, getTitle, propertyBreadcrumbs, getComponent } =
    usePropertyWrapper();

  return (
    <PageWrapper
      breadcrumbs={getBreadcrumb()}
      title={getTitle()}
      property={propertyBreadcrumbs}
      rightSideControls={() => <LinkMenu isCompany={isCompany} />}
    >
      {getComponent()}
    </PageWrapper>
  );
};

export default PropertyWrapper;
