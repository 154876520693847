import React, {
  HTMLAttributes,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useState,
  useCallback,
  useEffect,
} from "react";
import Loader from "../../../Loader";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Autocomplete from "@mui/material/Autocomplete";
import { Area } from "../../../../types/be/area";
import { useTranslation } from "react-i18next";
import {
  AutocompleteAreaName,
  AutocompleteListItem,
  AutocompletePropertyName,
} from "./styled";
import { Grid, TextField } from "@mui/material";
import { Dropdown } from "../../../../ui/autocomplete/styled";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import debounce from "lodash/debounce";
import {
  NODE_ENVIRONMENTS,
  PAGE_LIMIT,
  SEARCH_PARAM_MIN_CHARACTERS,
} from "../../../../constants";
import { getJson } from "../../../../utils/http";
import { getAreas } from "../../../../api/area";
import { FormikValues } from "formik";
import { DEBOUNCE_TIME, prepareQueryParams } from "../../../../utils/common";
import { useFetch } from "../../../../hooks/useFetch";
import { isNodeEnv } from "../../../../utils/env";
/* istanbul ignore next */
const PropertyAreaAutocomplete = ({
  onPropertyAreaSelect,
  propertyId: initialPropertyId,
  predictions,
  setPredictions,
  touched,
  errors,
  status,
  propertyAreaInitState = null,
  label = "",
  isDisabled = false,
}: FormikValues & {
  onPropertyAreaSelect: (propertyArea: Area | null) => void;
  propertyAreaInitState?: Area | null;
  label?: string;
  isDisabled?: boolean;
}): ReactElement => {
  const [propertyId, setPropertyId] = useState<number | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [area, setArea] = useState<Area | null>(propertyAreaInitState);
  const { t } = useTranslation();
  const [areaAutocompleteInputValue, setAreaAutocompleteInputValue] =
    useState("");

  const { data: areaData, run: runArea } = useFetch<Area[]>();

  useEffect(() => {
    setArea(propertyAreaInitState);
  }, [propertyAreaInitState]);

  useEffect(() => {
    if (propertyId) {
      const params = prepareQueryParams("", {
        is_rented: "1",
        limit: String(PAGE_LIMIT._10),
        property_id: propertyId!,
      });
      runArea(getAreas(params));
    }
  }, [propertyId]);

  useEffect(() => {
    propertyId && setPredictions(areaData);
  }, [areaData, propertyId]);

  useEffect(() => {
    if (status && status.success) reset();
  }, [status]);

  useEffect(() => {
    if (initialPropertyId) setPropertyId(initialPropertyId);
    else {
      setPropertyId(null);
      setArea(null);
      setAreaAutocompleteInputValue("");
      setPredictions([]);
    }
  }, [initialPropertyId]);

  const reset = (): void => {
    setAreaAutocompleteInputValue("");
    setPredictions([]);
    setArea(null);
  };

  useEffect(() => {
    setAreaAutocompleteInputValue(area?.name ?? "");
    onPropertyAreaSelect(area);
  }, [area]);

  const handleAreaSelect = (area: Area): void => {
    setArea(area);
  };

  const handleAutocompleteOnChange = (
    _: SyntheticEvent,
    value: Area | null
  ): void => {
    if (value) handleAreaSelect(value);
    else {
      setArea(null);
    }
  };

  const handleRenderInput = (
    params: AutocompleteRenderInputParams
  ): ReactNode => (
    <TextField
      {...params}
      name={"property_area_id"}
      disabled={isDisabled}
      size={"small"}
      label={label ?? `${t("tenantRequest.area")}*`}
      onChange={(e) => {
        setAreaAutocompleteInputValue(e.target.value);
        onSearchKeyDown(e.target.value);
      }}
      placeholder={t("tenantRequest.search")}
      error={Boolean(
        touched.property_area_id &&
          (status?.errors.property_area_id || errors.property_area_id)
      )}
      helperText={
        touched.property_area_id &&
        (status?.errors.property_area_id || errors.property_area_id)
      }
    />
  );

  const onSearchKeyDown = useCallback(
    debounce(
      async (search: string): Promise<void> => {
        if (search.length < SEARCH_PARAM_MIN_CHARACTERS)
          areaData && setPredictions(areaData);
        else {
          setLoading(true);
          const params = prepareQueryParams("", {
            search,
            is_rented: "1",
            limit: PAGE_LIMIT._10,
            property_id: propertyId!,
          });
          getAreas(params)
            .then(getJson)
            .then(({ data }) => {
              setPredictions(data);
            })
            .catch(() => {
              setPredictions([]);
            }).finally(() => {
              setLoading(false);
            });
        }
      },
      isNodeEnv(NODE_ENVIRONMENTS.TEST) ? 0 : DEBOUNCE_TIME
    ),
    [propertyId, areaData]
  );

  return (
    <>
      <Autocomplete
        disabled={!initialPropertyId || isDisabled}
        loading={isLoading || !predictions}
        loadingText={`${t("loading")}...`}
        inputValue={areaAutocompleteInputValue}
        popupIcon={isLoading ? <Loader size={25} /> : <ArrowDropDownIcon />}
        options={predictions ? predictions : []}
        isOptionEqualToValue={handleIsOptionEqualToValue}
        getOptionLabel={handleGetOptionLabel}
        filterOptions={(options) => options}
        onChange={handleAutocompleteOnChange}
        renderOption={handleRenderOption}
        PaperComponent={autocompletePaperComponent}
        renderInput={handleRenderInput}
      />
    </>
  );
};

export default PropertyAreaAutocomplete;
/* istanbul ignore next */
const handleIsOptionEqualToValue = (option: Area, value: Area): boolean => {
  return option.id === value.id;
};
/* istanbul ignore next */
const handleGetOptionLabel = (area: Area): string => {
  return `${area.name}`;
};
/* istanbul ignore next */
const handleRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  area: Area
): ReactNode => {
  return (
    <AutocompleteListItem {...props} key={area.id}>
      <Grid container direction={"column"}>
        <Grid item>
          <AutocompleteAreaName>{area.name}</AutocompleteAreaName>
        </Grid>
        {area.property && (
          <Grid item>
            <AutocompletePropertyName>
              {area.property.object_name}
            </AutocompletePropertyName>
          </Grid>
        )}
      </Grid>
    </AutocompleteListItem>
  );
};
/* istanbul ignore next */
const autocompletePaperComponent = ({
  children,
}: {
  children?: ReactNode;
}): ReactElement | null => <Dropdown>{children}</Dropdown>;
