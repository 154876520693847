import { Grid } from "@mui/material";
import React, { ReactElement } from "react";
import Text from "../../../../../../ui/formsFields/text";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { FEDebtor } from "./types";
import Button from "../../../../../../ui/button/Button";
import { route } from "../../../../../../utils/url";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router";

const Form = ({
  handleSubmit,
  status,
  isSubmitting,
}: FormikProps<FEDebtor>): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Grid container gap={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Text
              label={t("documentRelease.invoice.apidatev.konto")}
              placeholder={t("documentRelease.invoice.apidatev.konto")}
              name="konto"
              required
              size="medium"
              status={status}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Text
              label={t("documentRelease.invoice.apidatev.addresseeTypeCompany")}
              name="company_name"
              size="medium"
              status={status}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Text
              label={t("documentRelease.invoice.apidatev.addresseeType")}
              name="addressee_type"
              size="medium"
              status={status}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Text
              label={t("documentRelease.invoice.apidatev.abbreviation")}
              name="abbreviation"
              size="medium"
              status={status}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Text
              label={t("documentRelease.invoice.apidatev.addressType")}
              name="address_type"
              size="medium"
              status={status}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Text
              label={t("documentRelease.invoice.apidatev.street")}
              name="street"
              size="medium"
              status={status}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Text
              label={t("documentRelease.invoice.apidatev.postalCode")}
              name="postal_code"
              size="medium"
              status={status}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Text
              label={t("documentRelease.invoice.apidatev.location")}
              name="location"
              size="medium"
              status={status}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Text
              label={t(
                "documentRelease.invoice.apidatev.codeCorrespondenceAddress"
              )}
              name="code_correspondence_address"
              size="medium"
              status={status}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Text
              label={t("documentRelease.invoice.apidatev.addressValidFrom")}
              name="address_valid_from"
              size="medium"
              status={status}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Button
            type="button"
            color="primary"
            variant="text"
            sx={{ mt: "1rem" }}
            size="large"
            title={t("cancel")}
            disabled={isSubmitting}
            onClick={
              /* istanbul ignore next */ () =>
                doesAnyHistoryEntryExist
                  ? navigate(-1)
                  : navigate(
                      route("properties.invoices.apidatev.debitaccount", id)
                    )
            }
          />
          <Button
            disabled={isSubmitting}
            type="submit"
            size="large"
            variant="contained"
            color="success"
            isLoading={isSubmitting}
            title={t("create")}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
