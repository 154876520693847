/*istanbul ignore file*/

import React, { createContext, useEffect, useReducer, useState } from "react";
import {
  SET_AREA,
  SET_IS_SHOW_TENANT_HISTORY_DETAILS_BUTTON_DISABLED,
  SET_TENANT_HISTORY_DETAILS,
  EditAreaContextType,
  EditAreaStateType,
} from "./tabs/history/list/types";
import {
  Area,
  AreaCategory,
  AreaFloor,
  AreaType,
} from "../../../../types/be/area";
import { ActionMap } from "../../../../types/fe/context";
import { useParams } from "react-router-dom";
import {
  getArea,
  getAreaCategories,
  getAreaFloors,
  getTypes,
} from "../../../../api/area";
import { getJson } from "../../../../utils/http";
import { TenantHistory } from "../../../../types/be/tenantHistory";
import {
  PAGE_LIMIT,
  PERMISSION_CODE_PARAM,
  UserPermissions,
  UserRoles,
} from "../../../../constants";
import { useFetch } from "../../../../hooks/useFetch";
import { User } from "../../../../types/be/user";
import useAccessControl from "../../../../hooks/useAccessControl";
import { prepareQueryParams } from "../../../../utils/common";
import { getUsers } from "../../../../api/users";

export type AreaEditActionTypes = {
  [SET_AREA]: {
    area: Area;
  };
  [SET_TENANT_HISTORY_DETAILS]: {
    tenantHistoryDetails: TenantHistory | null;
  };
  [SET_IS_SHOW_TENANT_HISTORY_DETAILS_BUTTON_DISABLED]: {
    isShowTenantHistoryDetailsButtonDisabled: boolean;
  };
};

const AreaEditContext = createContext<EditAreaContextType | null>({
  area: null,
  isTenant: false,
  areaCategories: null,
  isAreaCategoriesLoading: false,
  areaTypes: null,
  isAreaTypesLoading: false,
  assetManagers: null,
  isAssetManagersLoading: false,
  fetchArea: null,
  tenantHistoryDetails: null,
  isShowTenantHistoryDetailsButtonDisabled: false,
  openShowTenantHistoryDetailsModal: null,
  closeShowTenantHistoryDetailsModal: null,
  dispatch: null,
  areaFloors: null,
  isAreaFloorsLoading: false,
});

const reducer = (
  state: EditAreaStateType,
  action: ActionMap<AreaEditActionTypes>[keyof ActionMap<AreaEditActionTypes>]
): EditAreaStateType => {
  switch (action.type) {
    case SET_AREA:
      return {
        ...state,
        area: action.payload.area,
      };
    case SET_TENANT_HISTORY_DETAILS:
      return {
        ...state,
        tenantHistoryDetails: action.payload.tenantHistoryDetails,
      };
    case SET_IS_SHOW_TENANT_HISTORY_DETAILS_BUTTON_DISABLED:
      return {
        ...state,
        isShowTenantHistoryDetailsButtonDisabled:
          action.payload.isShowTenantHistoryDetailsButtonDisabled,
      };
    default:
      return {
        ...state,
      };
  }
};

function AreaEditProvider({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement {
  const [area, setArea] = useState<Area | null>(null);
  const { isRole } = useAccessControl();
  const { areaId } = useParams();
  const isTenant = isRole(UserRoles.TENANT);
  const [state, dispatch] = useReducer(reducer, {
    area: null,
    tenantHistoryDetails: null,
    isShowTenantHistoryDetailsButtonDisabled: false,
  });

  const fetchArea = (): void => {
    if (areaId)
      getArea(areaId)
        .then(getJson)
        .then(({ data }) => {
          setArea(data);
        })
        .catch((err) => {
          console.log(err.message);
        });
  };

  const toggleIsShowTenantHistoryDetailsButtonDisabled = (
    val: boolean
  ): void => {
    dispatch({
      type: SET_IS_SHOW_TENANT_HISTORY_DETAILS_BUTTON_DISABLED,
      payload: {
        isShowTenantHistoryDetailsButtonDisabled: val,
      },
    });
  };

  // const openShowTenantHistoryDetailsModal = (id: number): void => {
  //   toggleIsShowTenantHistoryDetailsButtonDisabled(true);
  //   getPropertyTenant(id)
  //     .then(getJson)
  //     .then(({ data }) => {
  //       dispatch({
  //         type: SET_TENANT_HISTORY_DETAILS,
  //         payload: { tenantHistoryDetails: data },
  //       });
  //       toggleIsShowTenantHistoryDetailsButtonDisabled(false);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };

  const openShowTenantHistoryDetailsModal = (row: any): void => {
    toggleIsShowTenantHistoryDetailsButtonDisabled(true);
    dispatch({
      type: SET_TENANT_HISTORY_DETAILS,
      payload: { tenantHistoryDetails: row },
    });
    toggleIsShowTenantHistoryDetailsButtonDisabled(false);
  };

  const closeShowTenantHistoryDetailsModal = (): void => {
    dispatch({
      type: SET_TENANT_HISTORY_DETAILS,
      payload: { tenantHistoryDetails: null },
    });
  };

  const {
    data: areaCategories,
    run: runAreaCategories,
    isLoading: isAreaCategoriesLoading,
  } = useFetch<AreaCategory[]>();

  const {
    data: areaTypes,
    run: runAreaTypes,
    isLoading: isAreaTypesLoading,
  } = useFetch<AreaType[]>();

  const {
    data: areaFloors,
    run: runAreaFloor,
    isLoading: isAreaFloorsLoading,
  } = useFetch<AreaFloor[]>();

  const {
    data: assetManagers,
    run: runAssetManagers,
    isLoading: isAssetManagersLoading,
  } = useFetch<User[]>();

  useEffect(() => {
    runAreaTypes(getTypes());
    runAreaCategories(getAreaCategories());
    runAreaFloor(getAreaFloors());
  }, []);

  useEffect(() => {
    if (!area) return;
    const { asset_managers } = area;
    const asset_managers_ids = asset_managers.map((el) => el.id);

    const params = prepareQueryParams(
      "",
      {
        limit: PAGE_LIMIT._10,
        [PERMISSION_CODE_PARAM]: UserPermissions.PROPERTY_ASSET_MANAGER,
      },
      false,
      [...asset_managers_ids]
    );
    runAssetManagers(getUsers(params));
  }, [area]);

  return (
    <AreaEditContext.Provider
      value={{
        ...state,
        area: area,
        isTenant: isTenant,
        areaCategories: areaCategories,
        isAreaCategoriesLoading: isAreaCategoriesLoading,
        areaTypes: areaTypes,
        isAreaTypesLoading: isAreaTypesLoading,
        assetManagers: assetManagers,
        isAssetManagersLoading: isAssetManagersLoading,
        fetchArea: fetchArea,
        dispatch: dispatch,
        openShowTenantHistoryDetailsModal: openShowTenantHistoryDetailsModal,
        closeShowTenantHistoryDetailsModal: closeShowTenantHistoryDetailsModal,
        areaFloors: areaFloors,
        isAreaFloorsLoading: isAreaFloorsLoading,
      }}
    >
      {children}
    </AreaEditContext.Provider>
  );
}

export { AreaEditProvider, AreaEditContext };
