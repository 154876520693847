import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { HideEmployeeProps } from "./types";
import DialogTitle from "../../../ui/dialog/DialogTitle";
import { EMPTY_DATA } from "../../../constants";
import {
  createPermission,
  getUser,
  getUsers,
  removePermission,
} from "../../../api/users";
import useTable from "../../../ui/table/useTable";
import { User } from "../../../types/models";
import { useFetch } from "../../../hooks/useFetch";
import Table from "../../../ui/table/Table";
import {
  getCheckboxItemListHeadCell,
  selectAllInit,
} from "../../properties/invoices/list/utils";
import { TableCell } from "../../properties/styled";
import { Input, Search, SearchIconWrapper } from "../../../ui/table/styled";
import { Search as SearchIcon } from "react-feather";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { prepareQueryParams } from "../../../utils/common";
import { getJson, handleServerError } from "../../../utils/http";
import useIsMounted from "../../../hooks/useIsMounted";
import { CheckedDict } from "../../properties/invoices/list/types";
import { ALL_ROLES, itemsListHeadCells } from "./utils";
import { getRoles } from "../../../api/roles";
import { Role } from "../../../types/be/role";
import { formatTimestamp } from "../../../utils/date";
import useAppSelector from "../../../hooks/useAppSelector";
import { setUser } from "../../../redux/slices/user/user";
import useAppDispatch from "../../../hooks/useAppDispatch";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/* istanbul ignore next */
const HideEmployeeModal = ({
  isHideEmployeeModalOpen,
  handleHideEmployeeModalClose,
  userScheduleAccessPermissionsId,
}: HideEmployeeProps): ReactElement => {
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [checkedDict, setCheckedDict] = useState<CheckedDict | null>(null);
  const [selectAll, setSelectAll] = useState(selectAllInit);
  const [selectedRole, setSelectedRole] = useState<string>(ALL_ROLES);
  const { t } = useTranslation();
  const { _user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
  } = useTable<User>();

  const { run: runRoles, data: roles } = useFetch<Role[]>();

  useEffect(() => {
    runRoles(
      getRoles(
        prepareQueryParams(queryParams, {
          limit: 10000,
        })
      )
    );
  }, []);

  useEffect(() => {
    if (isMounted()) fetchUsers();
  }, [queryParams, selectedRole]);

  useEffect(() => {
    if (!data) return;
    const auxCheckedDict: CheckedDict = {};
    data.forEach((el: User) => {
      auxCheckedDict[el.id] = !el.permissions.includes("user-schedule.access");
    });
    setSelectAll(
      data?.length ===
        Object.values(auxCheckedDict).filter((item) => item).length
    );
    setCheckedDict(auxCheckedDict);
  }, [data]);

  const fetchUsers = useCallback(
    async (loading = true) => {
      setIsLoading(loading);
      const res = await getUsers(
        prepareQueryParams(queryParams, {
          role_code: selectedRole === ALL_ROLES ? undefined : selectedRole,
        })
      );
      if (!res.ok) {
        const { errorMessage } = handleServerError(res);
        setError(errorMessage);
      } else {
        const resJson = await res.json();
        setTotal(resJson.meta.total);
        setData(resJson.data);
        setIsLoading(false);
      }
    },
    [queryParams, setIsLoading, setTotal, setData, setIsLoading, selectedRole]
  );

  const handleAllCheckbox = async (checked: boolean): Promise<void> => {
    setSelectAll(checked);
    const auxCheckedDict: CheckedDict = {};
    data.forEach((el: User) => {
      auxCheckedDict[el.id] = checked;
    });
    setCheckedDict(auxCheckedDict);
    setSelectAll(
      data?.length ===
        Object.values(auxCheckedDict).filter((item) => item).length
    );
    if (!checked) {
      await createPermission(
        userScheduleAccessPermissionsId,
        Object.keys(checkedDict!)
      );
    } else {
      await removePermission(
        userScheduleAccessPermissionsId,
        Object.keys(checkedDict!)
      );
    }
    fetchUsers(false);
  };

  const handleItemCheckbox =
    (id: number) =>
    async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      const { checked } = event.target;
      if (!checked) {
        await createPermission(userScheduleAccessPermissionsId, [String(id)]);
      } else {
        await removePermission(userScheduleAccessPermissionsId, [String(id)]);
      }

      setCheckedDict((state) => ({ ...state, [id]: checked }));
      const isSelectedAll =
        data?.length ===
        Object.values({ ...checkedDict, [id]: checked }).filter((item) => item)
          .length;
      setSelectAll(isSelectedAll);
      setHasChanged(true);
      fetchUsers(false);

      getUser(String(_user?.id))
        .then(getJson)
        .then((resJson) => {
          dispatch(setUser(resJson.data));
        })
        .catch((err) => {
          console.log(err);
        });
    };

  const TableToolbar = (
    <>
      <Grid container sx={{ pt: 5 }}>
        <Grid item xs={4} lg={3} sm={6}>
          <TextField
            value={selectedRole}
            onChange={(event) => setSelectedRole(event?.target?.value)}
            select
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              "aria-label": "filter-select",
            }}
          >
            <MenuItem value={ALL_ROLES} selected>
              {t("employeeOverview.allRoles")}
            </MenuItem>
            {roles?.map(({ code, name }) => (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs />
        <Grid item>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input placeholder={t("search")} onChange={handleTableSearch} />
          </Search>
        </Grid>
      </Grid>
    </>
  );

  const tableHeaderTitles = useMemo(() => {
    const checkboxItemListHeadCell = getCheckboxItemListHeadCell(
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        checked={selectAll}
        data-testid={"select-all"}
        onChange={({
          target: { checked },
        }: React.ChangeEvent<HTMLInputElement>) => {
          handleAllCheckbox(checked);
        }}
      />
    );
    return [checkboxItemListHeadCell, ...itemsListHeadCells];
  }, [selectAll, checkedDict]);

  const renderRow = (row: User): ReactElement => (
    <>
      <TableCell padding="none">
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          onChange={handleItemCheckbox(row.id)}
          checked={checkedDict?.[row.id]}
          data-testid={`checkbox-${row.id}`}
        />
      </TableCell>
      <TableCell align="left">
        {row.first_name} {row.last_name}
      </TableCell>
      <TableCell align="left">
        {row.role ? row.role.name : EMPTY_DATA}
      </TableCell>
      <TableCell align="left">
        {row.created_at ? formatTimestamp(row.created_at) : EMPTY_DATA}
      </TableCell>
    </>
  );

  const handleClose = useCallback((): void => {
    handleHideEmployeeModalClose(hasChanged);
  }, [handleHideEmployeeModalClose, hasChanged]);

  const isMounted = useIsMounted();

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isHideEmployeeModalOpen}
      aria-labelledby="max-width-dialog-title"
      data-testid="create-company-modal"
    >
      <DialogTitle
        title="employeeOverview.hideEmployee"
        onClose={handleClose}
      />
      <DialogContent>
        <Table
          data={data}
          total={total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={tableHeaderTitles}
          renderRow={renderRow}
          tableToolbar={TableToolbar}
          tableDataMaxHeight={"50vh"}
        />
      </DialogContent>
    </Dialog>
  );
};

export default HideEmployeeModal;
