import React from "react";
import Release from "../../../components/settings/release";
import AccessGuard from "../../../components/guards/AccessGuard";
import { PermissionsRead } from "../../../constants";

export default {
  path: "release",
  element: (
    <AccessGuard permissions={[PermissionsRead.SETTINGS_RELEASE]}>
      <Release />
    </AccessGuard>
  ),
};
