/* istanbul ignore file */

import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { FormikProps } from "formik";
import { FormatNumber } from "../formsFields/formatNumber";
import TextField from "../formsFields/text";
import { EDITABLE_FIELDS_TYPE } from "../../components/properties/edit/general/utils";
import { Measurement_Units } from "../../constants";
import DatePicker from "../../ui/datePicker";
import { parseNumber } from "../../utils/common";
import { formatDateToTimespamp } from "../../utils/date";
import { convertTimestampToDate } from "../../components/properties/areas/edit/utils";

interface EditableFieldFieldProps extends FormikProps<Record<string, unknown>> {
  label: JSX.Element;
  isDisabled?: boolean;
  type: EDITABLE_FIELDS_TYPE;
  name: string;
  setIsopen: Dispatch<SetStateAction<boolean>>;
}

const InputField = ({
  name,
  initialValues,
  setFieldValue,
  type,
  values,
  setIsopen,
}: EditableFieldFieldProps): ReactElement => {
  switch (type) {
    case EDITABLE_FIELDS_TYPE.TEXT:
      return (
        <TextField label={""} name={name} size="small" type="text" id={name} />
      );
    case EDITABLE_FIELDS_TYPE.FORMAT_NUMBER:
      return (
        <FormatNumber
          label={""}
          name={name}
          size={"small"}
          value={initialValues[name]}
          inputProps={{
            filter: "formatNum",
            unit: Measurement_Units.EURO,
          }}
          onChange={(e) => {
            setFieldValue(name, String(parseNumber(`${e.target.value}`, true)));
          }}
        />
      );
    case EDITABLE_FIELDS_TYPE.DATE: {
      const fieldValue = values[name];
      const value =
        typeof fieldValue === "string"
          ? convertTimestampToDate(+fieldValue)
          : "";
      return (
        <DatePicker
          sx={{ minHeight: 0 }}
          name={name}
          label={""}
          size="small"
          onChangeValue={(date: Date | null) => {
            date && setFieldValue(name, `${formatDateToTimespamp(date)}`);
          }}
          disableFuture
          touchedValue={true}
          value={value}
          onClose={() => {
            setIsopen(false);
          }}
          onOpen={() => {
            setIsopen(true);
          }}
        />
      );
    }
    default:
      return <></>;
  }
};

export default InputField;
