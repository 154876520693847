import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Offer as BEOffer } from "../../../../../../types/be/offer";
import useTable from "../../../../../../ui/table/useTable";
import find from "lodash/find";
import snakeCase from "lodash/snakeCase";
import {
  deleteOfferTopic,
  resetOfferTopic,
} from "../../../../../../api/offerTopic";
import { deleteOffer, getOffers } from "../../../../../../api/offer";
import Table from "../../../../../../ui/table/Table";
import DeleteConfirmationModal from "../../../../../../ui/table/DeleteConfirmationModal";
import { Box, Grid } from "@mui/material";
import {
  historyModalInitState,
  itemsListHeadCells,
  mapFESortField,
  providerOfferListFilterInit,
} from "../../utils";
import {
  EditOfferPropType,
  OfferListProps,
  ProviderOfferListFilter,
} from "../../types";
import OfferListRow from "./OfferListRow";
import OfferListToolbar from "./OfferListToolbar";
import { handleServerError } from "../../../../../../utils/http";
import { useTranslation } from "react-i18next";
import filter from "lodash/filter";
import {
  FILTER_TYPE,
  generalPropertyOption,
  getPropertyHeadByWidget,
  prepareQueryParams,
  replaceNullOrUndefinedByEmptyString,
} from "../../../../../../utils/common";
import CommentsPopup from "../../../../../../ui/commentsPopUp";
import {
  COMMENTABLE,
  FORWADED_STATUS,
  HISTORY_STATUS,
  REQUEST_STATUS_IDS,
  STARTING_PAGE,
  UserRoles,
  FORWARDED_ROUTE,
} from "../../../../../../constants";
import { showCommentsModalInitState } from "../../../../../../ui/commentsPopUp/utils";
import StatusButtons from "../../../../components/statusButtons";
import {
  Input,
  Search,
  SearchIconWrapper,
} from "../../../../../../ui/table/styled";
import { GridSearchIcon } from "@mui/x-data-grid";
import useIsMounted from "../../../../../../hooks/useIsMounted";
import {
  getItemsWithActiveReleasePermissions,
  getReleaseRoute,
} from "../../../../utils";
import useAppSelector from "../../../../../../hooks/useAppSelector";
import { getProperties } from "../../../../../../api/property";
import _ from "lodash";
import { OffersFilterProps } from "../../../../insurances/list/topicList/types";
import useAccessControl from "../../../../../../hooks/useAccessControl";
import { Status } from "../../../../../../types/be/status";
import Dropdown from "../../../../../../ui/dropdown";
import { usePropertySelect } from "../../../../../../hooks/usePropertySelect";
import ContactShowDialog from "../../../../components/provider/contactShowDialog";
import ProviderOfferList from "./providerOffers/list";
import { IdPropType } from "../../../../../../types/models";
import HistoryPopup from "../../../../components/history/HistoryPopup";
import { HISTORY } from "../../../../components/history/types";
import HistoryList from "../../../../components/history/HistoryList";

const OfferList = ({
  useOnWidget = false,
  offers: offerData,
  statuses: statusesProp,
  fetchOfferTopics,
  offerTopic,
  propertyId,
  setError,
  configuration,
  handleForwardDocument,
  handleReleaseDocument,
  releasePermission,
  isDocumentReleaseLoading,
  isConfigurationLoading,
}: OfferListProps): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShowCommentsModal, setIsShowCommentsModal] = useState(
    showCommentsModalInitState
  );
  const [activeStatusId, setActiveStatusId] = useState<number | null>(null);
  const { id } = useParams();
  const { optionalColumnIds, selectedOptionalColumnsIds } = useAppSelector(
    (state) => state.offers
  );

  const [statuses, setStatuses] = useState<Status[]>([]);
  const [providerOfferListFilter, setProviderOfferListFilter] =
    useState<ProviderOfferListFilter>(providerOfferListFilterInit);

  const {
    propertyId: selectedPropertyId,
    property,
    isGeneral,
    onPropertySelect,
  } = usePropertySelect(id);

  const {
    order,
    orderBy,
    isLoading,
    error,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleDeleteLastPageData,
    setIsLoading,
    queryParams,
    setTotal,
    setData,
    data,
    total,
    handleTableSearch,
    setCurrentPage,
  } = useTable<BEOffer>();
  const { isRole } = useAccessControl();
  const [showHistoryModal, setShowHistoryModal] = useState(
    historyModalInitState
  );
  const [contactShowId, setContactShowId] = useState<string | null>(null);

  useEffect(() => {
    isMounted() && !offerData && activeStatusId && fetchOffers();
  }, [queryParams, activeStatusId, isGeneral, selectedPropertyId]);

  const dataPaginated = useMemo(
    () =>
      offerData &&
      _.orderBy(offerData, [mapFESortField(orderBy)], [order]).slice(
        currentPage * rowsPerPage,
        (currentPage + 1) * rowsPerPage
      ),
    [offerData, currentPage, rowsPerPage, order, orderBy]
  );
  /* istanbul ignore next */
  const fetchOffers = useCallback(
    async (loading = true) => {
      if (activeStatusId === HISTORY_STATUS.id) return;
      setIsLoading(loading);
      const params: OffersFilterProps = {
        fr_status_id: activeStatusId,
      };

      if (
        isGeneral === FILTER_TYPE.GENERAL ||
        propertyId ||
        selectedPropertyId
      ) {
        params.property_id = propertyId || selectedPropertyId || "";
      }

      if (activeStatusId === -1) {
        params.is_was_forwarded = 1;
        params.fr_status_id = REQUEST_STATUS_IDS.NEW;
      } else if (activeStatusId) {
        params.fr_status_id = activeStatusId;
      }

      const response = await getOffers(
        prepareQueryParams(
          queryParams,
          replaceNullOrUndefinedByEmptyString(params),
          true
        )
      );

      if (response.status !== 200) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        const resJson = await response.json();
        setTotal(resJson.meta.total);
        setData(resJson.data);
        setIsLoading(false);
      }
    },
    [
      activeStatusId,
      queryParams,
      setIsLoading,
      setTotal,
      setData,
      setError,
      isGeneral,
      selectedPropertyId,
      propertyId,
    ]
  );
  /* istanbul ignore next */
  const handleStatusChange = (status: number): void => {
    setCurrentPage(STARTING_PAGE);
    setActiveStatusId(status);
  };

  /* istanbul ignore next */
  const setActiveStatusByStatus = (): void => {
    const activeStatus = find(statuses, (o) => o.code === snakeCase(status));
    setActiveStatusId(activeStatus?.id || FORWADED_STATUS.id);
  };

  const handleRowRemove = useCallback(async (): Promise<void> => {
    const response = await deleteOffer(rowToDelete);
    if (response.status === 200) {
      fetchOfferTopics ? await fetchOfferTopics() : await fetchOffers();
      isMounted() && handleConfirmToRemoveModalClose();
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }
  }, [
    rowToDelete,
    deleteOffer,
    fetchOfferTopics,
    fetchOffers,
    handleConfirmToRemoveModalClose,
  ]);

  const handleEditClick = useCallback(
    ({ id: offer_id }: EditOfferPropType): void =>
      navigate(
        getReleaseRoute("offers.edit", id ? Number(id) : undefined, offer_id)
      ),
    []
  );

  const handleShowClick = useCallback(
    ({ id: offer_id }: EditOfferPropType): void =>
      navigate(
        getReleaseRoute("offers.show", id ? Number(id) : undefined, offer_id)
      ),
    []
  );

  /* istanbul ignore next */
  const getStatuses = useCallback(async () => {
    const historyStatus = isRole(UserRoles.ADMIN) ? [HISTORY_STATUS] : [];
    setStatuses([FORWADED_STATUS, ...statusesProp, ...historyStatus]);
  }, [statuses, statusesProp]);

  useEffect(() => {
    configuration && getStatuses();
  }, [configuration]);

  useEffect(() => {
    statuses.length && setActiveStatusByStatus();
  }, [statuses]);

  const handleConfirmToRemoveTopicModalClose = (): void =>
    setIsModalOpen(false);

  const handleTopicRemove = async (): Promise<void> => {
    const response = await deleteOfferTopic(offerTopic!.id);
    if (response.status === 200) {
      handleConfirmToRemoveTopicModalClose();
      handleDeleteLastPageData(fetchOfferTopics);
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }
  };

  /* istanbul ignore next */
  const handleHistoryCloseModal = (): void => {
    setShowHistoryModal(historyModalInitState);
  };
  /* istanbul ignore next */
  const handleHistoryModal = ({ id: historyId }: IdPropType): void => {
    setShowHistoryModal({ isVisible: true, historyId });
  };

  const handleTopicReset = async (): Promise<void> => {
    const response = await resetOfferTopic(offerTopic!.id);
    if (response.status === 200) {
      fetchOfferTopics && (await fetchOfferTopics());
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }
  };
  const openConfirmToRemoveTopicModal = (): void => setIsModalOpen(true);

  const tableHeaderTitles = useMemo(() => {
    let filteredItems = itemsListHeadCells;
    /* istanbul ignore next */
    filteredItems = filter(
      filteredItems,
      (o) =>
        !optionalColumnIds.includes(o.id) ||
        selectedOptionalColumnsIds.includes(o.id)
    );

    filteredItems = getPropertyHeadByWidget(
      filteredItems,
      "property_name",
      !useOnWidget
    );

    return getItemsWithActiveReleasePermissions(filteredItems, configuration);
  }, [selectedOptionalColumnsIds, itemsListHeadCells, configuration]);

  const TableToolbar = (
    <>
      {statuses && offerTopic ? (
        <OfferListToolbar
          id={propertyId}
          topicId={offerTopic.id}
          name={offerTopic.name}
          property={offerTopic.property?.object_name}
          handleTopicReset={handleTopicReset}
          openConfirmToRemoveTopicModal={openConfirmToRemoveTopicModal}
          offerTopic={offerTopic}
          configuration={configuration}
          handleForwardDocument={handleForwardDocument}
          handleReleaseDocument={handleReleaseDocument}
          isDocumentReleaseLoading={isDocumentReleaseLoading}
          releasePermission={releasePermission}
          fetchOfferTopics={fetchOfferTopics}
        />
      ) : (
        <Grid container gap={5} mb={2}>
          <Grid container>
            <Grid item>
              <StatusButtons
                subPath="offers"
                statuses={statuses}
                handleStatusChange={handleStatusChange}
                navigateOnChange={false}
                activeStatusId={activeStatusId}
                useSpecialLabels={true}
                defaultStatus={FORWARDED_ROUTE}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Dropdown
                id="property-autocomplete"
                name="property_id"
                placeholder={t("documentRelease.invoice.searchProperty")}
                getOptions={getProperties}
                onSelectOption={onPropertySelect}
                optionLabel="object_name"
                size="small"
                defaultValue={property}
                defaultOption={[generalPropertyOption]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={8}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Grid item>
                <Search>
                  <SearchIconWrapper>
                    <GridSearchIcon />
                  </SearchIconWrapper>
                  <Input
                    placeholder={t("search")}
                    onChange={handleTableSearch}
                  />
                </Search>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );

  const handleOpenComments = (row: BEOffer): void => {
    setIsShowCommentsModal({ isVisible: true, rowId: row?.id });
  };

  const handleCloseCommentsModal = (): void => {
    setIsShowCommentsModal(showCommentsModalInitState);
    /* istanbul ignore next */
    fetchOfferTopics ? fetchOfferTopics(false) : fetchOffers(false);
  };

  const renderRow = (row: BEOffer): ReactElement => (
    <>
      <OfferListRow
        row={row}
        topicStatusId={offerTopic?.status?.id}
        handleCellClick={handleCellClick}
        handleEditClick={handleEditClick}
        handleShowClick={handleShowClick}
        openConfirmToRemoveModal={openConfirmToRemoveModal}
        handleOpenComments={handleOpenComments}
        fetchOfferTopics={fetchOfferTopics || fetchOffers}
        useOnWidget={useOnWidget}
        setContactShowId={setContactShowId}
        setProviderOfferListFilter={setProviderOfferListFilter}
        handleForwardDocument={handleForwardDocument}
        handleReleaseDocument={handleReleaseDocument}
        releasePermission={releasePermission}
        isDocumentReleaseLoading={isDocumentReleaseLoading}
        configuration={configuration}
        handleHistoryModal={handleHistoryModal}
      />
    </>
  );

  const isMounted = useIsMounted();

  return (
    <Box sx={{ mb: 1 }}>
      {activeStatusId === HISTORY_STATUS.id ? (
        /* istanbul ignore next */ <HistoryList
          documentRelease={HISTORY.OFFER}
          activeStatusId={activeStatusId}
          handleStatusChange={handleStatusChange}
          statuses={statuses}
          useOnWidget={useOnWidget}
          noDataMessage="table.noOfferHistoryIsAvailable"
        />
      ) : (
        <Table
          data={offerData ? dataPaginated : data}
          total={offerData ? offerData.length : total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onRowRemove={handleRowRemove}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading || isConfigurationLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={tableHeaderTitles}
          renderRow={renderRow}
          tableToolbar={TableToolbar}
          labelRowsPerPage={t("offers.offersPerTable")}
          tableDataMaxHeight={statuses && offerTopic ? "75vh" : "65vh"}
          refId={`hzScroll${offerTopic?.id}`}
        />
      )}
      <DeleteConfirmationModal
        isConfirmToRemoveModalOpen={isModalOpen}
        handleConfirmToRemoveModalClose={handleConfirmToRemoveTopicModalClose}
        onRowRemove={handleTopicRemove}
      />
      {isShowCommentsModal.isVisible && (
        <CommentsPopup
          handleCloseDialog={handleCloseCommentsModal}
          id={String(isShowCommentsModal?.rowId)}
          relation={COMMENTABLE.OFFER}
        />
      )}
      {
        /* istanbul ignore next */ contactShowId && (
          <ContactShowDialog
            id={contactShowId}
            onClose={() => setContactShowId(null)}
            open={true}
          />
        )
      }
      {
        /* istanbul ignore next */ providerOfferListFilter.contact_id && (
          <ProviderOfferList
            onClose={() =>
              setProviderOfferListFilter({
                contact_id: null,
              })
            }
            parentFilter={providerOfferListFilter}
            configuration={configuration}
            handleForwardDocument={handleForwardDocument}
            handleReleaseDocument={handleReleaseDocument}
            releasePermission={releasePermission}
            isDocumentReleaseLoading={isDocumentReleaseLoading}
            statuses={statuses}
            handleHistoryModal={handleHistoryModal}
          />
        )
      }
      {
        /* istanbul ignore next */ showHistoryModal.isVisible && (
          <HistoryPopup
            handleCloseDialog={handleHistoryCloseModal}
            historyId={String(showHistoryModal?.historyId)}
            documentRelease={HISTORY.OFFER}
          />
        )
      }
    </Box>
  );
};

export default OfferList;
