import React, {
  ReactElement,
  useEffect,
  useCallback,
  useRef,
  useState,
} from "react";
import { Grid, MenuItem, Tooltip } from "@mui/material";
import { FormikProps } from "formik";
import { FormInitialValues, ViewableFile } from "./types";
import Text from "../../../ui/formsFields/text";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFetch";
import { getCounterList, getCounterTypes } from "../../../api/reading";
import { CounterType } from "../../../types/fe.interfaces";
import Button from "../../../ui/button/Button";
import Autocomplete from "../../../ui/autocomplete/Autocomplete";
import { getProperties } from "../../../api/property";
import {
  DEBOUNCE_TIME,
  prepareQueryParams,
  replaceNullOrUndefinedByEmptyString,
} from "../../../utils/common";
import { Property } from "../../../types/be/property";
import { Counter } from "../../../types/be/counter";
import { Area } from "../../../types/be/area";
import {
  AddImageButton,
  ImageItem,
  ImagesContainer,
  Input,
  ReadingImage,
} from "./styled";
import { Add, Close } from "@mui/icons-material";
import { DEFAULT_FILE_TYPES, PAGE_LIMIT } from "../../../constants";
import ImageViewer from "../../../ui/imageViewer/reactImageGallery";
import { MAX_FILES } from "./utils";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { getAreas } from "../../../api/area";
import { AutocompleteOption } from "../../../ui/autocomplete/types";
import DatePicker from "../../../ui/datePicker";
import { route } from "../../../utils/url";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

const Form = ({
  handleSubmit,
  setFieldValue,
  values,
  status,
  isSubmitting,
  errors,
  touched,
  setValues,
}: FormikProps<FormInitialValues>): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const { data: counterTypes, run: handleGetCountersTypes } =
    useFetch<CounterType[]>();
  const imageRef = useRef<HTMLInputElement>(null);

  const [property, setProperty] = useState<Property | null>(null);
  const [area, setArea] = useState<Area | null>(null);
  const [counter, setCounter] = useState<Counter | null>(null);
  const [showImageViewer, setShowImageViewer] = useState<number | null>(null);

  const handlePropertyInputChange = useCallback(async (search: string) => {
    const params = prepareQueryParams("", { search });
    const response = await getProperties(params);
    const { data } = await response.json();

    return data;
  }, []);

  const { data: preloadedPropertyData, run: runPreloadedProperties } =
    useFetch<AutocompleteOption[]>();
  const { data: preloadedAreaData, run: runPreloadedAreas } =
    useFetch<AutocompleteOption[]>();
  const { data: preloadedCounterListData, run: runPreloadedCounterList } =
    useFetch<AutocompleteOption[]>();

  useEffect(() => {
    const params = prepareQueryParams("", {
      limit: String(PAGE_LIMIT._10),
    });
    runPreloadedProperties(getProperties(params));
  }, []);

  useEffect(() => {
    const params = prepareQueryParams("", {
      property_area_id: values?.property_area_id,
      property_id: values?.property_id,
      limit: String(PAGE_LIMIT._10),
    });
    runPreloadedCounterList(getCounterList(params));
  }, [values?.property_area_id, values?.property_id]);

  useEffect(() => {
    if (property) {
      const params = prepareQueryParams("", {
        property_id: property?.id,
        limit: String(PAGE_LIMIT._10),
      });
      runPreloadedAreas(getAreas(params));
    }
  }, [property]);

  const handlePropertyAreaInputChange = useCallback(
    async (search: string) => {
      let options = [];

      const params = prepareQueryParams("", {
        property_id: values?.property_id,
        search,
      });
      const response = await getAreas(params, true);
      const { data } = await response.json();

      response.status === HTTP_STATUS_CODES.OK && (options = data);

      return options;
    },
    [values?.property_id, getAreas]
  );

  const handleCounterInputChange = useCallback(
    async (search: string) => {
      const params = prepareQueryParams("", {
        property_area_id: values?.property_area_id,
        property_id: values?.property_id,
        search,
      });
      const response = await getCounterList(params);
      const { data } = await response.json();
      return data;
    },
    [values?.property_area_id, getAreas, values?.property_id]
  );

  const onPropertySelect = useCallback(
    async (event: Property): Promise<void> => {
      const newValues = {
        ...values,
        property_id: event?.id?.toString(),
        property_area_id: "",
        counter_id: "",
        number: "",
      };
      replaceNullOrUndefinedByEmptyString(newValues);

      setValues(newValues);
      setProperty(event);
      setArea(null);
      setCounter(null);
    },
    [values]
  );

  const onPropertyAreaSelect = useCallback(
    async (event: Area): Promise<void> => {
      const newValues = {
        ...values,
        property_area_id: event?.id?.toString(),
        counter_id: "",
        number: "",
      };

      replaceNullOrUndefinedByEmptyString(newValues);

      setValues(newValues);
      setArea(event);
      setCounter(null);
    },
    [values]
  );

  const onCounterSelect = useCallback(
    async (event: Counter): Promise<void> => {
      const newValues = {
        ...values,
        counter_id: event?.id?.toString(),
        number: event?.number?.toString(),
      };

      replaceNullOrUndefinedByEmptyString(newValues);

      setValues(newValues);
      setCounter(event);
    },
    [values]
  );

  const openImageViewer = useCallback(
    (index: number) => {
      setShowImageViewer(index);
    },
    [setShowImageViewer]
  );

  const removeFile = useCallback(
    (
      id: number,
      files: ViewableFile[],
      setFieldValue: (field: string, value: ViewableFile[]) => void
    ) => {
      const newFiles = files.filter((item) => item.id !== id);
      setFieldValue("file", newFiles);
    },
    [setFieldValue]
  );

  const handleFileChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      files: ViewableFile[],
      setFieldValue: (field: string, value: ViewableFile[]) => void
    ) => {
      const file = e?.target?.files?.[0];

      const newFiles: ViewableFile[] = [
        ...files,
        {
          file,
          url: file && URL.createObjectURL(file),
          id: Date.now(),
        },
      ];
      setFieldValue("file", newFiles);
      imageRef.current!.value = "";
    },
    []
  );

  const closeImageViewer = useCallback(() => {
    setShowImageViewer(null);
  }, []);

  useEffect(() => {
    handleGetCountersTypes(getCounterTypes());
  }, []);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={4} sx={{ mt: 0.5 }}>
        <Grid item sm={12} xl={6} lg={6}>
          <Autocomplete
            id="property-autocomplete"
            name="property_id"
            handleOnChangeInputText={handlePropertyInputChange}
            handleOptionSelect={onPropertySelect}
            placeholder={t("counter.searchProperty")}
            value={values.property_id || ""}
            autocompleteValue={property}
            error={Boolean(
              touched?.property_id &&
                (status?.errors?.property_id || errors.property_id)
            )}
            helperText={
              touched?.property_id &&
              (errors?.property_id || status?.errors?.property_id)
            }
            debounceTime={DEBOUNCE_TIME}
            sx={{ minHeight: 1, mb: 0 }}
            optionLabel="object_name"
            optionValue="id"
            preloadOptions={preloadedPropertyData}
          />
        </Grid>
        <Grid item sm={12} xl={6} lg={6}>
          <Tooltip
            title={!property ? t("counter.selectPropertyFirst") : ""}
            placement="top-start"
          >
            <span>
              <Autocomplete
                id="property-area-autocomplete"
                name="property_area_id"
                handleOnChangeInputText={handlePropertyAreaInputChange}
                handleOptionSelect={onPropertyAreaSelect}
                placeholder={t("counter.searchArea")}
                value={values.property_area_id || ""}
                autocompleteValue={area}
                disabled={Boolean(!property)}
                error={Boolean(
                  touched?.property_area_id &&
                    (status?.errors?.property_area_id ||
                      errors.property_area_id)
                )}
                helperText={
                  touched?.property_area_id &&
                  (errors?.property_area_id || status?.errors?.property_area_id)
                }
                debounceTime={DEBOUNCE_TIME}
                sx={{ minHeight: 1, mb: 0 }}
                optionLabel="name"
                optionValue="id"
                preloadOptions={preloadedAreaData}
              />
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5.8}>
              <Text
                label={t("counter.create.meterNumber")}
                name="number"
                required
                size="medium"
                status={status}
                disabled={!!values?.counter_id}
                inputProps={{
                  "data-testid": "number",
                }}
              />
            </Grid>
            <Grid item xs={0.4}>
              {t("or")}
            </Grid>
            <Grid item xs={5.8}>
              <Autocomplete
                id="counter-autocomplete"
                name="counter_id"
                handleOnChangeInputText={handleCounterInputChange}
                handleOptionSelect={onCounterSelect}
                placeholder={t("counter.create.searchMeterNumber")}
                value={values.counter_id || ""}
                autocompleteValue={counter}
                error={Boolean(
                  (touched?.counter_id || touched?.number) &&
                    (status?.errors?.counter_id ||
                      errors.counter_id ||
                      errors?.number)
                )}
                helperText={
                  (touched?.counter_id || touched?.number) &&
                  (status?.errors?.counter_id ||
                    errors.counter_id ||
                    errors?.number)
                }
                debounceTime={DEBOUNCE_TIME}
                sx={{ minHeight: 1, mb: 0 }}
                optionLabel="number"
                optionValue="id"
                preloadOptions={preloadedCounterListData}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={12} md={6}>
          <Text
            name="type_id"
            select
            required
            label={t("counter.searchType")}
            status={status}
            disabled={!!values?.counter_id}
          >
            {counterTypes ? (
              counterTypes?.map((counter) => (
                <MenuItem key={counter.id} value={counter.id}>
                  {counter.name}
                </MenuItem>
              ))
            ) : (
              <div></div>
            )}
          </Text>
        </Grid>
        <Grid item xs={6} sm={12} md={6}>
          <Text
            label={t("counter.create.meterReadings")}
            name="value"
            required
            size="medium"
            status={status}
            type="number"
            inputProps={{
              "data-testid": "readings",
            }}
          />
        </Grid>
        <Grid item xs={6} sm={12} md={6}>
          <DatePicker
            name="date"
            required
            label={t("date")}
            status={status}
            sx={{ marginBottom: 0 }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <ImagesContainer>
            {values.file?.map((item, index) => (
              <ImageItem key={index} onClick={() => openImageViewer(index)}>
                <ReadingImage src={item.url} data-testid="upload-image" />
                <Close
                  className="removeIcon"
                  color="action"
                  onClick={() =>
                    removeFile(item.id, values.file, setFieldValue)
                  }
                  data-testid="remove-icon"
                />
              </ImageItem>
            ))}
            {values.file?.length < MAX_FILES ? (
              <AddImageButton htmlFor="file">
                <Add fontSize="large" />
                <Input
                  accept={DEFAULT_FILE_TYPES}
                  type="file"
                  name="file"
                  id="file"
                  ref={imageRef}
                  onChange={(e) =>
                    handleFileChange(e, values.file, setFieldValue)
                  }
                  data-testid="select-file"
                />
              </AddImageButton>
            ) : null}
          </ImagesContainer>
          {showImageViewer !== null && values?.file?.length ? (
            <ImageViewer
              images={values?.file?.map((item) => ({
                original: item.url ?? "",
                thumbnail: item.url ?? "",
              }))}
              handleClose={closeImageViewer}
              startIndex={showImageViewer}
            />
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            type="button"
            color="primary"
            variant="text"
            title={t("cancel")}
            size="large"
            disabled={isSubmitting}
            onClick={
              /* istanbul ignore next */ () =>
                doesAnyHistoryEntryExist
                  ? navigate(-1)
                  : navigate(route("meter"))
            }
          />
          <Button
            type={"submit"}
            isLoading={isSubmitting}
            title={t("counter.create.createMeter")}
            color="success"
            size="large"
            disabled={isSubmitting}
            testId="createBtn"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
