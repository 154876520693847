import { createReminderFromInbox } from "../../../../../api/inbox";
import { HTTP_STATUS_CODES } from "../../../../../types/server";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../constants";
import { CreateReminderData, InboxCreateReminder } from "./types";
/* istanbul ignore next */
export const handleInboxCreateReminder = ({
  t,
  inboxData,
  setSnackbarData,
  handleFetchList,
  handleDialogCreateReminder,
}: InboxCreateReminder) => {
  return async (data: CreateReminderData): Promise<void> => {
    const response = await createReminderFromInbox(inboxData.id, data);
    if (response.status === HTTP_STATUS_CODES.OK) {
      handleDialogCreateReminder();
      handleFetchList();
      setSnackbarData({
        visible: true,
        text: t("attachments.attachmentUploadedSuccessfully"),
        color: "success",
      });
    } else {
      setSnackbarData({
        visible: true,
        text: t(SOMETHING_WENT_WRONG_ERROR),
        color: "error",
      });
    }
  };
};
