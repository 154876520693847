import React from "react";
import { Helmet } from "react-helmet-async";
import { Typography } from "@mui/material";
import { Wrapper, Box } from "./styled";
import { useTranslation } from "react-i18next";

function Imprint(): React.ReactElement {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Helmet title={t("footer.imprint")} />
      <Typography variant="h4" align="left">{t("footer.imprint")}</Typography>
      <Box>
        <Typography>FCR Innovation GmbH</Typography>
        <Typography>Kirchplatz 1</Typography>
        <Typography>D-82049 Pullach im Isartal</Typography>
        <Typography>www.fcr-innovation.de</Typography>
      </Box>
      <Box>
        <Typography>Telefon +49 89 413 2496 00</Typography>
        <Typography>Fax +49 89 413 2496 99</Typography>
        <Typography>E-Mail info(at)fcr-immobilien.de</Typography>
      </Box>
      <Box>
        <Typography>Vorstand: Thorsten Raudies</Typography>
        <Typography>THRB 244445 | Amtsgericht München</Typography>
        <Typography>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE321203479</Typography>
      </Box>
      <Box>
        <Typography>Redaktionelle Verantwortung</Typography>
        <Typography>Thorsten Raudies, Kirchplatz 1, D-82049 Pullach im Isartal</Typography>
      </Box>
      <Box>
        <Typography>Haftungshinweis</Typography>
        <Typography>Trotz sorgfältiger Kontrolle übernimmt die “FCR Innovation GmbH” keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Ungeachtet der Sorgfalt, die auf die Erstellung der Texte, Abbildungen, Dokumente und Programme verwendet wurde, können wir für mögliche Fehler und deren Folgen keine juristische Verantwortung oder irgendeine Haftung übernehmen. Die in dieser Website und in allen anderen Bereichen wiedergegebenen Gebrauchsnamen, Handelsnamen, Warenbezeichnungen usw. können auch ohne besondere Kennzeichnung Marken sein und als solche den gesetzlichen Bestimmungen unterliegen.</Typography>
      </Box>
    </Wrapper>
  );
}

export default Imprint;

