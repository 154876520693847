import React, { ReactElement, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import useValidation from "../../../../../../../hooks/useValidation";
import useSnackbar from "../../../../../../../ui/snackbar1/useSnackbar";
import Form from "./Form";
import { handleUpdateEmptyAreaListing } from "./utils";
import { useTranslation } from "react-i18next";
import { EmptyAreaFormProps } from "../types";

const Listing = ({ area, fetchArea }: EmptyAreaFormProps): ReactElement => {
  const { t } = useTranslation();
  const { headline } = useValidation();
  const { snackbar, Snackbar } = useSnackbar();
  const [isEditInfo, setIsEditInfo] = useState(false);
  const [isEditDescription, setIsEditDescription] = useState(false);

  const initialValues = {
    ...area,
    contact_asset_manager_id: area.contact_asset_manager?.id || "",
  };

  const afterSuccess = (): void => {
    fetchArea && fetchArea();
    setIsEditDescription(false);
    setIsEditInfo(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleUpdateEmptyAreaListing(
          initialValues,
          t,
          afterSuccess,
          snackbar
        )}
        enableReinitialize
        validationSchema={Yup.object().shape({
          headline,
        })}
      >
        {(props) => (
          <Form
            {...props}
            isEditInfo={isEditInfo}
            setIsEditInfo={setIsEditInfo}
            isEditDescription={isEditDescription}
            setIsEditDescription={setIsEditDescription}
          />
        )}
      </Formik>
      {Snackbar}
    </>
  );
};

export default Listing;
