import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getProperty } from "../../../../api/property";
import {
  getAreas,
  exportPdfAreasList,
  exportExcelAreasList,
} from "../../../../api/area";
import {
  FormMessageErrorState,
  PROPERTY_ID_FILTER,
  TENANT_USERS_FILTER,
  UserRoles,
} from "../../../../constants";
import useAppSelector from "../../../../hooks/useAppSelector";
import useAreaTypes from "../../../../hooks/useAreaTypes";
import { useFetch } from "../../../../hooks/useFetch";
import useRoles from "../../../../hooks/useRoles";
import { Area } from "../../../../types/be/area";
import { Property } from "../../../../types/be/property";
import { User } from "../../../../types/be/user";
import Snackbar from "../../../../ui/Snackbar";
import Table from "../../../../ui/table/Table";
import useTable from "../../../../ui/table/useTable";
import { getJson, handleServerError } from "../../../../utils/http";
import CalculationResult from "./components/calculationResult";
import { CreateAreaModal } from "../create";
import AreaTableRow from "./components/row";
import { ErrorBox } from "./styled";
import AreaTableToolbar from "./components/toolbar";
import {
  additionalItemsListHeadCells,
  mainItemsListHeadCells,
  mainItemsListHeadCellsTenantUser,
  prepareIsRentedParam,
  prepareQueryParams,
  requiredItemsListHeadCells,
} from "./utils";
import { OptionsDict, SnackbarMessage } from "./types";
import { downloadFile } from "../../../../utils/common";
import useAccessControl from "../../../../hooks/useAccessControl";
import Loader from "../../../Loader";

const AreaList = (): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading: isTableLoading,
    setIsLoading,
    error,
    setError,
    setQueryParams,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
  } = useTable<User>();

  const {
    run: runProperty,
    data: propertyDetails,
    isLoading: isPropertyLoading,
  } = useFetch<Property>();

  const {
    areaTypes: types,
    isError: isTypesError,
    isLoading: isTypesLoading,
  } = useAreaTypes();
  const { additionalFields, areaTypeId, areaIsRentedOption } = useAppSelector(
    (state) => state.area
  );
  const { _user } = useAccessControl();
  const { getRole } = useRoles();
  const tenantRoleId = getRole(UserRoles.TENANT)?.id;
  const userRoleId = _user?.role.id;
  const isTenant = tenantRoleId === userRoleId;

  const params = isTenant
    ? `${queryParams}${TENANT_USERS_FILTER}${_user?.id}`
    : queryParams;
  useEffect(() => {
    runProperty(getProperty(id));
  }, []);

  useEffect(() => {
    if (!queryParams && !isTenant) {
      setQueryParams((queryParams: string) =>
        prepareQueryParams(queryParams, areaTypeId, id!)
      );
      return;
    }

    fetchAreas(prepareFilterParams(params));
  }, [queryParams, areaTypeId, areaIsRentedOption]);

  const prepareFilterParams = useCallback(
    (params: string): string => {
      const paramsWithAreaTypeId = prepareQueryParams(params, areaTypeId, id!);
      return prepareIsRentedParam(paramsWithAreaTypeId, areaIsRentedOption);
    },
    [areaTypeId, id, areaIsRentedOption]
  );

  /* istanbul ignore next */
  const fetchAreas = useCallback(
    (params: string, showLoader = true) => {
      setIsLoading(showLoader);

      const paramsWithPropertyId = params.concat(`${PROPERTY_ID_FILTER}${id}`);

      getAreas(paramsWithPropertyId)
        .then(getJson)
        .then(({ data, meta }) => {
          setData(data);
          setTotal(meta.total);
          setIsLoading(false);
        })
        .catch((err) => setError(err));
    },
    [
      queryParams,
      setIsLoading,
      setTotal,
      setData,
      setIsLoading,
      areaIsRentedOption,
    ]
  );
  /* istanbul ignore next */
  const refreshAreaList = useCallback(() => {
    fetchAreas(params, false);
  }, [fetchAreas]);

  const handleCreateModalClose = (): void => {
    setIsCreateModalOpen(false);
    fetchAreas(params);
  };

  const handleCreateModalCancel = (): void => {
    setIsCreateModalOpen(false);
  };

  const [sendCredentialsSnackbar, setSendCredentialsSnackbar] =
    useState<SnackbarMessage>({ text: "", visible: false, color: "success" });

  const handleExportPDF = useCallback(async () => {
    const res = await exportPdfAreasList({ id: id! });
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const blob = await res.blob();
      downloadFile(
        new Blob([blob]),
        `${t("property.area.areaList")}-${id}`,
        "pdf"
      );
    }
  }, []);

  const handleTenantsExportExcel = useCallback(async () => {
    const res = await exportExcelAreasList({ id: id! });
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const blob = await res.blob();
      downloadFile(
        new Blob([blob]),
        `${t("property.area.areaList")}-${id}`,
        "xls"
      );
    }
  }, []);

  const openCreateModal = useCallback(async () => {
    setIsCreateModalOpen(true);
  }, []);

  const handleCloseSnackbar = (): void =>
    setSendCredentialsSnackbar({
      text: "",
      visible: false,
      color: "success",
    });

  const importSuccessCallback = (): void => {
    fetchAreas(params);
  };

  const tableToolbar = (): ReactElement => (
    <>
      <AreaTableToolbar
        handleTableSearch={handleTableSearch}
        handleExportPDF={handleExportPDF}
        handleExportExcel={handleTenantsExportExcel}
        openCreateModal={openCreateModal}
        areaTypes={types}
        setQueryParams={setQueryParams}
        importSuccessCallback={importSuccessCallback}
        isTenant={isTenant}
      />
    </>
  );
  const renderRow = (row: Area): ReactElement => (
    <AreaTableRow
      row={row}
      handleCellClick={handleCellClick}
      setSendCredentialsSnackbar={setSendCredentialsSnackbar}
      additionalFields={additionalFields}
      openConfirmToRemoveModal={openConfirmToRemoveModal}
      optionsDict={optionsDict}
      fetchAreas={fetchAreas}
      refreshAreaList={refreshAreaList}
      isTenant={isTenant}
    />
  );

  const itemsListHeadCellsMemo = useMemo(
    () => [
      ...mainItemsListHeadCells,
      ...additionalItemsListHeadCells.filter((cell) =>
        additionalFields.some(
          (field) => cell.id === field.name && field.visible
        )
      ),
      ...requiredItemsListHeadCells,
    ],
    [additionalFields]
  );

  /* istanbul ignore next */
  const optionsDict: OptionsDict = {
    type_id: types,
  };

  const isLoading = isTableLoading || isTypesLoading;

  if (isPropertyLoading) {
    return <Loader />;
  }

  if (isTypesError) return <ErrorBox formMessage={FormMessageErrorState} />;

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={
          isTenant ? mainItemsListHeadCellsTenantUser : itemsListHeadCellsMemo
        }
        renderRow={renderRow}
        tableToolbar={tableToolbar()}
        noDataIsAvailablePlaceholder={"table.noAreasAreAvailable"}
        tableDataMaxHeight={"40vh"}
      />
      {!isTenant && <CalculationResult property={propertyDetails!} />}
      <Snackbar
        data-testid={"send-credentials-snackbar"}
        message={sendCredentialsSnackbar.text}
        color={sendCredentialsSnackbar.color}
        open={sendCredentialsSnackbar.visible}
        handleClose={handleCloseSnackbar}
      />
      <CreateAreaModal
        isCreateModalOpen={isCreateModalOpen}
        handleCreateModalClose={handleCreateModalClose}
        setSendCredentialsSnackbar={setSendCredentialsSnackbar}
        areaTypes={types!}
        handleCreateModalCancel={handleCreateModalCancel}
      />
    </>
  );
};

export default AreaList;
