import React, { memo, ReactElement, useRef } from "react";
import { Grid } from "@mui/material";
import PdfViewer from "../../../ui/pdfViewer";

interface PdfViewerWrapperProps {
  data: string | number;
  type: "url" | "base64" | "fileId";
}
/* istanbul ignore next */
const PdfViewerWrapper = ({
  data,
  type = "url",
}: PdfViewerWrapperProps): ReactElement => {
  const pdfContainerRef = useRef<HTMLObjectElement | null>(null);

  const file = { [type]: data };

  return (
    <Grid container>
      <Grid item xs={12} ref={pdfContainerRef}>
        <PdfViewer file={file} containerRef={pdfContainerRef} />
      </Grid>
    </Grid>
  );
};

export default memo(PdfViewerWrapper);
