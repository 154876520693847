import AuthGuard from "../../components/guards/AuthGuard";
import DashboardLayout from "../../layouts/dashboard";
import Imprint from "../../components/imprint/Imprint";
import React from "react";

export default {
  path: "/:client/imprint",
  element: (
    <AuthGuard>
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: <Imprint />,
    },
  ],
};
