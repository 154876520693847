/* istanbul ignore file */

import React, { ReactElement, useEffect, useState } from "react";
import NumberField from "../../../../ui/formsFields/number";
import Button from "../../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Snackbar from "../../../../ui/Snackbar";
import { Form, FormikValues } from "formik";

function PropertyAdditionalCostsForm({
  handleSubmit,
  isSubmitting,
  values,
  status,
  setFieldValue,
}: any & FormikValues): ReactElement {
  const { t } = useTranslation();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

  const FIELDS_A = {
    percents_first: "building_in_percents",
    eur_first: "building_in_eur",
    percents_second: "land_in_percents",
    eur_second: "land_in_eur",
    purchase_price: "purchase_price",
  };

  const FIELDS_B = {
    percents_first: "other",
    eur_first: "other_in_eur",
    percents_second: "buffer",
    eur_second: "buffer_in_eur",
    purchase_price: "purchase_price",
  };

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  const getPers = (value: any, purchaseValue?: any): any => {
    if (purchaseValue !== undefined) {
      return (value * 100) / purchaseValue;
    }
    return 100 - value;
  };

  const getEur = (purchaseValue: any, fieldValue: any): any => {
    return (purchaseValue * fieldValue) / 100;
  };

  const handlePurchaseOnChange = (e: any): void => {
    const { value } = e.target;
    const purchasePrice = Number(value);

    setFieldValue(
      FIELDS_A.eur_first,
      getEur(purchasePrice, values.building_in_percents)
    );
    setFieldValue(
      FIELDS_A.eur_second,
      getEur(purchasePrice, values.land_in_percents)
    );
    setFieldValue(FIELDS_B.eur_first, getEur(purchasePrice, values.other));
    setFieldValue(FIELDS_B.eur_second, getEur(purchasePrice, values.buffer));
  };

  const handleOnChange = (e: any, fieldName: string, fields: any): void => {
    const { value } = e.target;

    let eurFirst = values[fields.eur_first];
    let percentFirst = values[fields.percents_first];
    let eurSecond = values[fields.eur_second];
    let percentsSecond = values[fields.percents_second];
    const purchasePrice = values.purchase_price;

    if (fieldName === fields.percents_first) {
      percentFirst = Number(value);
      percentsSecond = getPers(value);
      eurSecond = getEur(purchasePrice, percentsSecond);
      eurFirst = getEur(purchasePrice, value);
    }

    if (fieldName === fields.percents_second) {
      percentsSecond = Number(value);
      percentFirst = getPers(value);
      eurSecond = getEur(purchasePrice, value);
      eurFirst = getEur(purchasePrice, percentFirst);
    }

    if (fieldName === fields.eur_first) {
      eurFirst = Number(value);
      percentFirst = getPers(value, purchasePrice);
      percentsSecond = getPers(percentFirst);
      eurSecond = getEur(purchasePrice, percentsSecond);
    }

    if (fieldName === fields.eur_second) {
      eurSecond = Number(value);
      percentsSecond = getPers(value, purchasePrice);
      percentFirst = getPers(percentsSecond);
      eurFirst = getEur(purchasePrice, percentFirst);
    }
    setFieldValue([fields.percents_second], percentsSecond);
    setFieldValue([fields.percents_first], percentFirst);
    setFieldValue([fields.eur_first], eurFirst);
    setFieldValue([fields.eur_second], eurSecond);
  };

  const handleSnackbarClose = (): void => setIsSnackbarVisible(false);

  return (
    <>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Grid container sx={{ mb: 3, mt: 2 }} spacing={3}>
          <Grid item xs={4}>
            <NumberField
              size={"small"}
              name="purchase_price"
              onChange={(e) => handlePurchaseOnChange(e)}
              label={t("property.cost.purchasePrice")}
              inputProps={{
                "data-testid": "purchase_price",
              }}
              value={values.purchase_price}
              status={status}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mb: 3, mt: 2 }} spacing={3}>
          <Grid item xs={4}>
            <NumberField
              id="building_in_percents"
              size={"small"}
              name="building_in_percents"
              onChange={(e) =>
                handleOnChange(e, "building_in_percents", FIELDS_A)
              }
              value={values.building_in_percents}
              label={t("property.cost.buildingInPercents")}
              inputProps={{
                "data-testid": "building_in_percents",
              }}
              status={status}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberField
              id="building_in_eur"
              size={"small"}
              name="building_in_eur"
              onChange={(e) => handleOnChange(e, "building_in_eur", FIELDS_A)}
              value={values.building_in_eur}
              label={t("property.cost.buildingInEuro")}
              inputProps={{
                "data-testid": "building_in_eur",
              }}
              status={status}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mb: 3, mt: 2 }} spacing={3}>
          <Grid item xs={4}>
            <NumberField
              size={"small"}
              name="land_in_percents"
              onChange={(e) => handleOnChange(e, "land_in_percents", FIELDS_A)}
              value={values.land_in_percents}
              label={t("property.cost.landInPercents")}
              inputProps={{
                "data-testid": "land_in_percents",
              }}
              status={status}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberField
              size={"small"}
              name="land_in_eur"
              onChange={(e) => handleOnChange(e, "land_in_eur", FIELDS_A)}
              value={values.land_in_eur}
              label={t("property.cost.landInEuro")}
              inputProps={{
                "data-testid": "land_in_eur",
              }}
              status={status}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mb: 3, mt: 2 }} spacing={3}>
          <Grid item xs={7}>
            <NumberField
              size={"small"}
              name="property_transfer_tax"
              value={values.property_transfer_tax}
              label={t("property.cost.propertyTransferTax")}
              inputProps={{
                "data-testid": "property_transfer_tax",
              }}
              status={status}
            />
          </Grid>
          <Grid item xs={7}>
            <NumberField
              size={"small"}
              name="real_estate_agent"
              value={values.real_estate_agent}
              label={t("property.cost.realEstateAgent")}
              inputProps={{
                "data-testid": "real_estate_agent",
              }}
              status={status}
            />
          </Grid>
          <Grid item xs={7}>
            <NumberField
              size={"small"}
              name="notary"
              value={values.notary}
              label={t("property.cost.notary")}
              inputProps={{
                "data-testid": "notary",
              }}
              status={status}
            />
          </Grid>
          <Grid item xs={7}>
            <NumberField
              size={"small"}
              name="due_dilligence"
              value={values.due_dilligence}
              label={t("property.cost.dueDilligence")}
              inputProps={{
                "data-testid": "due_dilligence",
              }}
              status={status}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mb: 3, mt: 2 }} spacing={3}>
          <Grid item xs={4}>
            <NumberField
              size={"small"}
              name="other"
              onChange={(e) => handleOnChange(e, "other", FIELDS_B)}
              value={values.other}
              label={t("property.cost.other")}
              inputProps={{
                "data-testid": "other_in_percents",
              }}
              status={status}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberField
              size={"small"}
              name="other_in_eur"
              onChange={(e) => handleOnChange(e, "other_in_eur", FIELDS_B)}
              value={values.other_in_eur}
              label={t("property.cost.otherEuro")}
              inputProps={{
                "data-testid": "other",
              }}
              status={status}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mb: 3, mt: 2 }} spacing={3}>
          <Grid item xs={4}>
            <NumberField
              size={"small"}
              name="buffer"
              onChange={(e) => handleOnChange(e, "buffer", FIELDS_B)}
              value={values.buffer}
              label={t("property.cost.buffer")}
              inputProps={{
                "data-testid": "buffer_in_percents",
              }}
              status={status}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberField
              size={"small"}
              name="buffer_in_eur"
              onChange={(e) => handleOnChange(e, "buffer_in_eur", FIELDS_B)}
              value={values.buffer_in_eur}
              label={t("property.cost.bufferEuro")}
              inputProps={{
                "data-testid": "buffer",
              }}
              status={status}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent={"end"}>
          <Grid item>
            <Button
              title={t("update")}
              type="submit"
              color="success"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            />
          </Grid>
        </Grid>
      </Form>
      <Snackbar
        open={isSnackbarVisible}
        message={t("property.financesWasSuccessfullyUpdated")}
        color="success"
        handleClose={handleSnackbarClose}
      />
    </>
  );
}

export default PropertyAdditionalCostsForm;
