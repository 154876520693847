/* istanbul ignore file */

import React, { ReactElement } from "react";
import { NetColdRentProps } from "./types";
import { CalculationTableHead, CalculationTableTitle } from "./styled";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import {
  THOUSAND_SEPARATOR,
  DECIMAL_SEPARATOR,
  DECIMAL_SCALE,
  Measurement_Units,
} from "../../../../../../constants";

const NetColdRent = ({
  commercial_net_rent_per_month,
  commercial_net_rent_per_year,
  commercial_net_rent_percentage,
  living_net_rent_per_month,
  living_net_rent_per_year,
  living_net_rent_percentage,
  total_net_rent_per_month,
  total_net_rent_per_year,
}: NetColdRentProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <CalculationTableHead>
        <CalculationTableTitle>
          {t("property.tenants.calculationResult.calculatedRemainingTerm")}
        </CalculationTableTitle>
      </CalculationTableHead>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" size="small">
              {" "}
            </TableCell>
            <TableCell align="right" size="small">
              {t("property.tenants.calculationResult.pm")}
            </TableCell>
            <TableCell align="right" size="small">
              {t("property.tenants.calculationResult.pa")}
            </TableCell>
            <TableCell align="right" size="small">
              {t("property.tenants.calculationResult.shareOfISTNME")} %
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left" size="small">
              {t("property.tenants.calculationResult.business")}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                decimalScale={DECIMAL_SCALE}
                fixedDecimalScale
                displayType={"text"}
                value={commercial_net_rent_per_month}
              />{" "}
              {commercial_net_rent_per_month !== null && Measurement_Units.EURO}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                decimalScale={DECIMAL_SCALE}
                fixedDecimalScale
                displayType={"text"}
                value={commercial_net_rent_per_year}
              />{" "}
              {commercial_net_rent_per_year !== null && Measurement_Units.EURO}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                displayType={"text"}
                value={commercial_net_rent_percentage}
              />
              {Measurement_Units.PERCENTAGE}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" size="small">
              {t("property.tenants.calculationResult.living")}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                decimalScale={DECIMAL_SCALE}
                fixedDecimalScale
                displayType={"text"}
                value={living_net_rent_per_month}
              />{" "}
              {Measurement_Units.EURO}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                decimalScale={DECIMAL_SCALE}
                fixedDecimalScale
                displayType={"text"}
                value={living_net_rent_per_year}
              />{" "}
              {Measurement_Units.EURO}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                displayType={"text"}
                value={living_net_rent_percentage}
              />
              {Measurement_Units.PERCENTAGE}
            </TableCell>
          </TableRow>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell align="left" size="small">
              {t("property.tenants.calculationResult.total")}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                decimalScale={DECIMAL_SCALE}
                fixedDecimalScale
                displayType={"text"}
                value={total_net_rent_per_month}
              />{" "}
              {Measurement_Units.EURO}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                decimalScale={DECIMAL_SCALE}
                fixedDecimalScale
                displayType={"text"}
                value={total_net_rent_per_year}
              />{" "}
              {Measurement_Units.EURO}
            </TableCell>
            <TableCell align="right" size="small"></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NetColdRent;
