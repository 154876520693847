import React, { ReactElement } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "../../../ui/button/Button";
import { DATEPICKER_DATE_FORMAT } from "../../../constants";
import Autocomplete from "@mui/material/Autocomplete";
import { NewEntryModalFormProps } from "./types";
import DatePicker, { registerLocale } from "react-datepicker";
import { CloseIconStyled, TextField } from "./styled";
import { DatePickerWrapper } from "../../../ui/datePicker/styled";
import moment from "moment";
import { langDict } from "./utils";
import { isWeekend } from "../../../utils/date";
import { Form } from "../styled";
import { StyledPaper } from "../../contacts/autocomplete/styled";

const NewEntryModalForm = ({
  handleNewEntryModalClose,
  options,
  handleAutocompleteChange,
  startDate,
  endDate,
  handleClear,
  setDateRange,
  handleSubmit,
  isSubmitting,
  handleBlur,
  touched,
  errors,
  status,
  values,
}: NewEntryModalFormProps): ReactElement => {
  const { t } = useTranslation();
  const currentLang = window.localStorage.getItem("lang");
  currentLang && registerLocale("lang", langDict[currentLang]);
  const filterWeekend = (date: Date): boolean => isWeekend(moment(date));

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={6} columns={12}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="new-entry-modal-selection"
            options={options!}
            data-testid="search-option"
            getOptionLabel={(option) => option.name}
            onChange={handleAutocompleteChange}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            fullWidth
            PaperComponent={StyledPaper}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("employeeOverview.locationOffice")}
                placeholder={t("search")}
                value={values?.option_id}
                onBlur={handleBlur}
                error={Boolean(
                  touched.option_id &&
                    (status?.errors.option_id || errors.option_id)
                )}
                helperText={
                  touched.option_id &&
                  (status?.errors.option_id || errors.option_id)
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} sx={{ marginTop: "auto" }}>
          <DatePickerWrapper>
            <DatePicker
              locale="lang"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              dateFormat={DATEPICKER_DATE_FORMAT}
              filterDate={filterWeekend}
              customInput={
                <TextField
                  inputProps={{
                    readOnly: true,
                    "data-testid": "date-range-input",
                  }}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <CloseIconStyled
                        data-testid="remove-from-date-range"
                        onClick={handleClear}
                      />
                    ),
                  }}
                />
              }
            />
          </DatePickerWrapper>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <Grid item>
          <Button
            onClick={handleNewEntryModalClose}
            title={t("cancel")}
            variant={"text"}
            type="button"
            size="large"
          />
        </Grid>
        <Grid item>
          <Button
            title={t("create")}
            type="submit"
            sx={{ ml: "1rem" }}
            color="success"
            size="large"
            testId={"create-new-entry-button"}
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default NewEntryModalForm;
