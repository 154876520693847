import React, { ReactElement, useState } from "react";
import { FileWrapper } from "./styled";
import {
  ButtonsWrapper,
  DeleteIcon,
  DownloadIconStyled,
} from "../../../imageViewer/simple/styled";
import { Button, FileNameWrapper, FileName } from "./styled";
/* istanbul ignore next */
const Thumbnail = ({
  name,
  onDownloadClick,
  onDeleteClick,
  isDisabled = false,
}: {
  name?: string;
  onDownloadClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onDeleteClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  isDisabled?: boolean;
}): ReactElement => {
  const [isHovered, setIsHovered] = useState(false);
  const handleOnHover = (): void => {
    setIsHovered(true);
  };
  const handleOnBlur = (): void => {
    setIsHovered(false);
  };
  return (
    <FileWrapper
      onMouseEnter={handleOnHover}
      onMouseLeave={handleOnBlur}
      elevation={2}
      data-testid="image-viewer"
    >
      {isHovered && !isDisabled && (
        <>
          <ButtonsWrapper>
            {onDownloadClick && (
              <Button onClick={onDownloadClick}>
                <DownloadIconStyled color={"white"} />
              </Button>
            )}
            <Button onClick={onDeleteClick} data-testid="delete-btn">
              <DeleteIcon color={"white"} />
            </Button>
          </ButtonsWrapper>
          {name && (
            <FileNameWrapper>
              <FileName>{name}</FileName>
            </FileNameWrapper>
          )}
        </>
      )}
      <img src={`/static/img/files/file.png`} alt="" />
    </FileWrapper>
  );
};

export default Thumbnail;
