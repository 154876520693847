import React, { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { OfferListToolbarProps } from "../../types";
import { useTranslation } from "react-i18next";
import {
  RELASE_BUTTONS_AM,
  RELASE_BUTTONS_PM,
  RELEASE_BUTTONS_FR,
  RELEASE_BUTTONS_USER,
  getReleaseRoute,
  isAvailableReleaseButtonTableCell,
} from "../../../../utils";
import { StyledButton } from "../../../../../../ui/button/styled";
import {
  PermissionsCreate,
  PermissionsDelete,
  PermissionsUpdate,
} from "../../../../../../constants";
import useAccessControl from "../../../../../../hooks/useAccessControl";
import HZScrollController from "../../../../../../ui/HZScrollController";
import DocumentRelease from "../../../../components/documentRelease";
import { RELEASE_LEVEL } from "../../../../../../utils/common";
import {
  DOCUMENT_RELEASE_LEVEL,
  DOCUMENT_RELEASE_MODULE,
} from "../../../../../../types/be/releaseFlow";
const OfferListToolbar = ({
  name,
  id,
  topicId,
  handleTopicReset,
  openConfirmToRemoveTopicModal,
  property,
  offerTopic,
  fetchOfferTopics,
  configuration,
  handleForwardDocument,
  handleReleaseDocument,
  isDocumentReleaseLoading,
  releasePermission,
}: OfferListToolbarProps): ReactElement => {
  const { t } = useTranslation();
  const { can } = useAccessControl();

  return (
    <>
      <Grid
        container
        mt={5}
        mb={2}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid item>
          <Typography component="h2" variant="h5" align="center" gutterBottom>
            {property ? `${name} - ${property}` : name}
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={3}
            justifyContent={"start"}
            alignItems={"start"}
          >
            <Grid item alignItems="center" display="flex">
              <HZScrollController scrollerId={topicId} />
            </Grid>
            {can(PermissionsCreate.OFFER) && (
              <Grid item>
                <StyledButton
                  component={NavLink}
                  to={getReleaseRoute("offers.create", Number(id), topicId)}
                  size="small"
                  color="success"
                  variant="contained"
                  sx={{ mr: 2 }}
                >
                  {t("offers.addOffers")}
                </StyledButton>
              </Grid>
            )}
            <Grid item>
              {
                /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                  RELEASE_BUTTONS_FR,
                  configuration
                ) ? (
                  <DocumentRelease
                    id={offerTopic?.id}
                    releaser={null}
                    releaseStatus={null}
                    forwardedAt={offerTopic?.fr_forwarded_at}
                    forwardedBy={offerTopic?.fr_forwarded_by}
                    overdue={offerTopic?.fr_overdue}
                    afterSuccess={fetchOfferTopics}
                    handleReleaseDocument={handleReleaseDocument}
                    handleForwardDocument={handleForwardDocument}
                    level={RELEASE_LEVEL.FIRST_LEVEL_RELEASE}
                    hasForwardPermission={
                      releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
                      releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL] ||
                      releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
                        offerTopic.assigned_user
                      )
                    }
                    hasReleasePermission={
                      releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL]
                    }
                    relation={DOCUMENT_RELEASE_MODULE.OFFER}
                    loading={isDocumentReleaseLoading}
                    isButtonVisible={{
                      pendingButton: false,
                      rejectButton: false,
                      releaseButton: false,
                    }}
                  />
                ) : null
              }
            </Grid>
            <Grid item>
              {
                /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                  RELASE_BUTTONS_AM,
                  configuration
                ) ? (
                  <DocumentRelease
                    id={offerTopic?.id}
                    releaser={null}
                    releaseStatus={null}
                    forwardedAt={offerTopic?.am_forwarded_at}
                    forwardedBy={offerTopic?.am_forwarded_by}
                    overdue={offerTopic?.am_overdue}
                    afterSuccess={fetchOfferTopics}
                    handleReleaseDocument={handleReleaseDocument}
                    handleForwardDocument={handleForwardDocument}
                    level={RELEASE_LEVEL.SECOND_LEVEL_RELEASE}
                    hasForwardPermission={
                      releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
                      releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL] ||
                      releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
                        offerTopic.assigned_user
                      )
                    }
                    hasReleasePermission={
                      releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL]
                    }
                    relation={DOCUMENT_RELEASE_MODULE.OFFER}
                    loading={isDocumentReleaseLoading}
                    isButtonVisible={{
                      pendingButton: false,
                      rejectButton: false,
                      releaseButton: false,
                    }}
                  />
                ) : null
              }
            </Grid>
            <Grid item>
              {
                /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                  RELASE_BUTTONS_PM,
                  configuration
                ) ? (
                  <DocumentRelease
                    id={offerTopic?.id}
                    releaser={null}
                    releaseStatus={null}
                    forwardedAt={offerTopic?.pm_forwarded_at}
                    forwardedBy={offerTopic?.pm_forwarded_by}
                    overdue={offerTopic?.pm_overdue}
                    afterSuccess={fetchOfferTopics}
                    handleReleaseDocument={handleReleaseDocument}
                    handleForwardDocument={handleForwardDocument}
                    level={RELEASE_LEVEL.THIRD_LEVEL_RELEASE}
                    hasForwardPermission={
                      releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
                      releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
                      releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
                        offerTopic.assigned_user
                      )
                    }
                    hasReleasePermission={
                      releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL]
                    }
                    relation={DOCUMENT_RELEASE_MODULE.OFFER}
                    loading={isDocumentReleaseLoading}
                    isButtonVisible={{
                      pendingButton: false,
                      rejectButton: false,
                      releaseButton: false,
                    }}
                  />
                ) : null
              }
            </Grid>
            <Grid item>
              {
                /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                  RELEASE_BUTTONS_USER,
                  configuration
                ) ? (
                  <DocumentRelease
                    id={offerTopic?.id}
                    releaser={null}
                    releaseStatus={null}
                    forwardedAt={offerTopic?.au_forwarded_at}
                    forwardedBy={offerTopic?.au_forwarded_by}
                    overdue={offerTopic?.au_overdue}
                    afterSuccess={fetchOfferTopics}
                    handleReleaseDocument={handleReleaseDocument}
                    handleForwardDocument={handleForwardDocument}
                    level={RELEASE_LEVEL.FOURTH_LEVEL_RELEASE}
                    hasForwardPermission={
                      releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
                      releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL] ||
                      releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL]
                    }
                    hasReleasePermission={releasePermission[
                      DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                    ](null)}
                    relation={DOCUMENT_RELEASE_MODULE.OFFER}
                    loading={isDocumentReleaseLoading}
                    isButtonVisible={{
                      pendingButton: false,
                      rejectButton: false,
                      releaseButton: false,
                    }}
                    assignedUser={offerTopic.assigned_user}
                    hasAssignUserPermission={can(PermissionsUpdate.OFFER)}
                  />
                ) : null
              }
            </Grid>
            <Grid item>
              <StyledButton
                data-testid="reset-topic-button"
                size="small"
                color="primary"
                variant="contained"
                onClick={handleTopicReset}
                sx={{ mr: 2 }}
              >
                {t("offers.reset")}
              </StyledButton>
            </Grid>
            {can(PermissionsDelete.OFFER) && (
              <Grid item>
                <StyledButton
                  data-testid="delete-topic-button"
                  size="small"
                  color="error"
                  variant="contained"
                  onClick={openConfirmToRemoveTopicModal}
                  sx={{ mr: 2 }}
                >
                  {t("offers.delete")}
                </StyledButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default OfferListToolbar;
