import { getNotifications, notificationToggle } from "../../api/notifications";
import { handleServerError } from "../../utils/http";
import { prepareQueryParams } from "../../utils/common";
import { MAX_NOTIFICATIONS_LIMIT } from "../../constants";
import { setNotifications } from "../../redux/slices/notification";
import { NotificationStatusToggleProps } from "./types";

export const notificationStatusToggle = async ({
  dispatchStore,
  serError,
  notification,
}: NotificationStatusToggleProps): Promise<void> => {
  const response = notification
    ? await notificationToggle(!notification.read_at, [notification.id])
    : await notificationToggle(true);
  if (response.status !== 200) {
    const { errorMessage } = handleServerError(response);
    serError(errorMessage);
  } else {
    const params = prepareQueryParams("", {
      limit: String(MAX_NOTIFICATIONS_LIMIT),
    });
    const res = await getNotifications(params);
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      serError(errorMessage);
    } else {
      const json = await res.json();
      const { data } = json;
      dispatchStore(setNotifications(data));
    }
  }
};
