import React, { ReactElement, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import { AddEmployeeDialogProps } from "./types";
import Form from "./form";
import { assignPropertyInitialValues } from "./utils";
import { FormMessageInterface } from "../../../../types/form";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import { FormMessageInItState } from "../../../../constants";
import * as Yup from "yup";
import useValidation from "../../../../hooks/useValidation";
import DialogTitle from "../../../../ui/dialog/DialogTitle";

/* istanbul ignore next */
const AssignPropertyDialog = ({
  open,
  onClose,
  id,
  afterAssignProperty,
  handleAssignProperty,
}: AddEmployeeDialogProps): ReactElement => {
  const { property_id } = useValidation();
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="assign-property-dialog"
    >
      <DialogTitle title="bankAccount.assignProperty" onClose={onClose} />
      <DialogContent>
        {formMessage.text ? (
          <FormAlert formMessage={formMessage} sx={{ marginBottom: "10px" }} />
        ) : null}
        <Formik
          initialValues={assignPropertyInitialValues}
          onSubmit={handleAssignProperty(id, {
            setFormMessage,
            afterAssignProperty,
          })}
          validationSchema={Yup.object().shape({
            property_id,
          })}
        >
          {(props) => <Form {...props} onClose={onClose}/>}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AssignPropertyDialog;
