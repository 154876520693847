import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
} from "react";
import { Form } from "formik";
import { Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/button/Button";
import { CreateRoleFormProps } from "../types";
import PermissionCatergories from "./permissionsCatergories";
import useSnackbar from "../../../../ui/snackbar1/useSnackbar";
import { langDict } from "./utils";

/* istanbul ignore next */
const CreateRoleForm = ({
  values,
  status,
  isSubmitting,
  handleSubmit,
  permissionCategories,
  permissionCodes,
  setPermissionCodes,
  mode = "create",
}: CreateRoleFormProps): ReactElement => {
  const { t } = useTranslation();
  const { Snackbar, snackbar } = useSnackbar();

  useEffect(() => {
    if (status?.success)
      snackbar.success(t(`settings.role.${langDict.snackbar?.[mode]}`));
  }, [status]);

  const handleChangeParent = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const auxPermissionCodes = JSON.parse(JSON.stringify(permissionCodes));
      Object.keys(permissionCodes).forEach((key) => {
        auxPermissionCodes[key] = event.target.checked;
      });
      setPermissionCodes(auxPermissionCodes);
    },
    [permissionCodes]
  );

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Grid container pt={4}>
        <Grid item xs={12}>
          <PermissionCatergories
            permissionCategories={permissionCategories}
            permissionCodes={permissionCodes}
            setPermissionCodes={setPermissionCodes}
            values={values}
            status={status}
            handleChangeParent={handleChangeParent}
          />
        </Grid>
        <Grid item xs={12} sm={12} pt={6} pb={4}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={0}
          >
            <Button
              color="success"
              testId="create-role-submit"
              title={t(`settings.role.${langDict.submitButton?.[mode]}`)}
              type="submit"
              size="large"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            />
          </Stack>
        </Grid>
      </Grid>
      {Snackbar}
    </Form>
  );
};

export default CreateRoleForm;
