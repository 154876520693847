import { TableCell } from "../../../types/table";

export const tenantRequestsListHeadCells: TableCell[] = [
  {
    id: "ticket_number",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.ticketNumber",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.title",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.description",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.status",
  },
  {
    id: "property_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.propertyName",
  },
  {
    id: "area_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.areaName",
  },
  {
    id: "manager",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.manager",
  },
  {
    id: "tenant",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.tenant",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.createdAt",
  },
];

