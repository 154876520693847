import { BoardColumn } from "./types";
import {
  TenantRequest as TenantRequestType,
  TenantRequestStatusType,
} from "../../types/be/tenantRequest";
import { blue, green, grey } from "@mui/material/colors";

export const PENDING_STATUS = "pending";
export const IN_PROGRESS_STATUS = "in_progress";
export const IN_COORDINATION_STATUS = "in_coordination";
export const COMPLETED_STATUS = "completed";

export const PENDING_TITLE = "New";
export const IN_PROGRESS_TITLE = "In progress";
export const IN_COORDINATION_TITLE = "In coordination";
export const COMPLETED_TITLE = "Completed";

export const columnsFromBackend = {
  [PENDING_STATUS]: {
    title: PENDING_TITLE,
    items: [] as TenantRequestType[],
  },
} as BoardColumn;

export const generateDraggableId = (id: number): string => {
  return `data-rbd-draggable-id-${id}`;
};

export const statusBackground = (
  statusCode: TenantRequestStatusType
): string => {
  const statusColours = {
    [PENDING_STATUS]: grey[400],
    [IN_COORDINATION_STATUS]: blue[300],
    [IN_PROGRESS_STATUS]: blue[300],
    [COMPLETED_STATUS]: green[300],
  };
  return statusColours[statusCode];
};

export const TENANT_REQUEST_READ_PERMISSION = "tenant_request.read";
export const PERMISSIONS_CODE_ARRAY = "permission_code[]";
