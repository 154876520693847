import { Form } from "formik";
import { FormikValues } from "formik/dist/types";
import { Grid, MenuItem } from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/button/Button";
import { Text } from "../../../../ui/formsFields/text";
import { Measurement_Units } from "../../../../constants";
import { FormatNumber } from "../../../../ui/formsFields/formatNumber";
import { AreaFormProps } from "./types";
import { AreaType } from "../../../../types/be/area";
const AreaForm = ({
  handleSubmit,
  isSubmitting,
  areaTypes,
  status,
  handleCreateModalClose,
}: FormikValues & AreaFormProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6} sm={6} md={6}>
          <Text
            name="type_id"
            select
            required
            label={t("property.area.commercialLiving")}
            data-testid="area_type_dd"
            status={status}
            inputProps={{ "data-testid": "area_type" }}
          >
            {areaTypes?.map((areaType: AreaType) => (
              <MenuItem key={areaType.id} value={areaType.id}>
                {areaType.name}
              </MenuItem>
            ))}
          </Text>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Text
            label={t("property.area.areaName")}
            name="name"
            required
            size="medium"
            status={status}
            inputProps={{ "data-testid": "area_name" }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <FormatNumber
            label={t("property.area.areaSqureInM2")}
            name="square_meters"
            required
            size="medium"
            status={status}
            inputProps={{
              filter: "formatNum",
              unit: Measurement_Units.SQUARE_METER,
              "data-testid": "area_square_meters",
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <Grid item>
          <Button
            onClick={handleCreateModalClose}
            title={t("cancel")}
            testId={"cancel-create-area-button"}
            variant={"text"}
            size="large"
            type="button"
          />
        </Grid>
        <Grid item>
          <Button
            title={t("create")}
            type="submit"
            sx={{ ml: "1rem" }}
            color="success"
            size="large"
            testId={"create-area-button"}
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default AreaForm;
