import styled from "styled-components/macro";
import { Paper, Typography, MenuItem as MUIMenuItem } from "@mui/material";

export const FormWrapper = styled(Paper)`
  padding: ${(props): string => props.theme.spacing(6)};
  ${(props): string => props.theme.breakpoints.up("lg")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 80%;
    width: 80%;
  }
  ${(props): string => props.theme.breakpoints.up("xl")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 60%;
    width: 60%;
  }
  ${(props): string => props.theme.breakpoints.down("xs")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 100%;
    width: 100%;
  }
`;

export const AutocompleteTenantFullName = styled(Typography)`
  display: block;
  margin-right: 5px;
`;

export const AutocompleteTenantEmail = styled(Typography)`
  font-size: 12px;
  color: #b0bec5;
  display: block;
`;

export const MenuItem = styled(MUIMenuItem)``;
