import moment from "moment";
import en from "date-fns/locale/en-US";
import de from "date-fns/locale/de";

export const initInsuranceRange = [
  moment().subtract(1, "months").startOf("month").toDate(),
  moment().subtract(1, "months").endOf("month").toDate(),
];

export const langDict: Record<string, Locale> = {
  en,
  de,
};
