import React, { ReactElement, useCallback, useMemo, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  Tooltip,
  Typography,
  TextField as MuiTextField,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Chip, SelectBox } from "../styled";
import { RolesAutocompleteProps } from "../types";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Role as BERole } from "../../../../types/be/role";
import {
  MAX_PRELOADED_OPTIONS,
  SEARCH_PARAM_MIN_CHARACTERS,
  UserRoles,
} from "../../../../constants";
import { omitRolesFromOtherLevels, removeDuplicatesFromRoles } from "../utils";
import { prepareQueryParams } from "../../../../utils/common";
import { getRoles } from "../../../../api/roles";
import debounce from "lodash/debounce";
import { StyledPaper } from "../../../contacts/autocomplete/styled";

/* istanbul ignore next */
const icon = <CheckBoxOutlineBlankIcon fontSize="inherit" />;
const checkedIcon = <CheckBoxIcon fontSize="inherit" />;
/* istanbul ignore next */
const RolesAutocomplete = ({
  values,
  index,
  roles,
  setConfiguration,
  handleBlur,
  configuration,
  disabled = false,
}: RolesAutocompleteProps): ReactElement => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isMenuOpened, setIsMenuOpened] = useState(true);
  const ommited = omitRolesFromOtherLevels(roles, configuration, values.id);

  const preloadOptions = useMemo(() => {
    const valuesRoleIds = values.roles.map((el) => el.id);
    const rolesFromFormik = [...values.roles];
    const lengthRolesFromFormik = rolesFromFormik.length;

    return lengthRolesFromFormik >= MAX_PRELOADED_OPTIONS
      ? rolesFromFormik
      : [
          ...rolesFromFormik,
          ...removeDuplicatesFromRoles(ommited)
            .filter((el) => !valuesRoleIds.includes(el.id))
            .slice(0, MAX_PRELOADED_OPTIONS - lengthRolesFromFormik),
        ];
  }, [roles, values.roles, configuration, ommited]);

  const [options, setOptions] = useState<BERole[] | null>(preloadOptions);

  const handleOnChangeInputText = useCallback(async (search: string) => {
    const params = prepareQueryParams("", {
      search,
    });
    const response = await getRoles(params);
    const { data } = await response.json();
    return data;
  }, []);

  const onSearchFieldTextChange = useCallback(
    debounce(async (search: string): Promise<void> => {
      if (search.length < SEARCH_PARAM_MIN_CHARACTERS) {
        setOptions(preloadOptions || []);
      } else {
        setLoading(true);
        try {
          const response: BERole[] = await handleOnChangeInputText(search);
          setOptions(
            omitRolesFromOtherLevels(
              removeDuplicatesFromRoles([...values.roles, ...response]),
              configuration,
              values.id
            )
          );
          setLoading(false);
        } catch (e) {
          setOptions([]);
        }
      }
    }, 500),

    [handleOnChangeInputText, preloadOptions]
  );

  const onOpen = (): void => {
    setOptions(preloadOptions);
    setIsMenuOpened(true);
  };

  const onClose = (): void => setIsMenuOpened(false);

  const onChange = (_: unknown, value: BERole[]): void =>
    setConfiguration((prevState) => {
      if (!prevState.data) return prevState;
      const clone = [...prevState.data];
      clone[index].roles = value;
      return {
        ...prevState,
        data: [...clone],
      };
    });

  return (
    <>
      <Tooltip
        placement="top"
        title={
          values.roles.length === 0 || isMenuOpened
            ? null
            : values?.roles.map((selected, index: number) => {
                const role = roles.find(
                  (dataElem: BERole) => dataElem.id === selected.id
                );
                return (
                  <Typography key={role?.id + " " + index}>
                    {role?.name}
                  </Typography>
                );
              })
        }
      >
        <Autocomplete
          id="role_code"
          ListboxProps={{ style: { maxHeight: "100vh" } }}
          multiple
          loading={loading}
          inputValue={inputValue}
          limitTags={1}
          onOpen={onOpen}
          onClose={onClose}
          disabled={disabled}
          disableCloseOnSelect
          options={options || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value): boolean =>
            option.code === value.code
          }
          value={values.roles}
          onChange={onChange}
          renderTags={(list) => {
            const displayList = list.map((item) => item.name);
            return (
              <SelectBox>
                <Chip sx={{ maxHeight: 17 }} label={list[0].name} />
                {list.length > 1 && (
                  <div>{`${t("tenant.selectedMoreThenOneTenantPartOne")} ${
                    displayList.length - 1
                  } ${t("tenant.selectedMoreThenOneTenantPartTwo")} `}</div>
                )}
              </SelectBox>
            );
          }}
          PaperComponent={StyledPaper}
          renderOption={(props, option, { selected }) => {
            const adminOption =
              option.code === UserRoles.ADMIN && values.level.code === "I";
            const disabled = adminOption ? { onClick: undefined } : {};
            return (
              <li {...props} {...disabled}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  onChange={() => {}}
                  checked={selected}
                  disabled={adminOption}
                />
                {option.name}
              </li>
            );
          }}
          renderInput={(params) => (
            <>
              <MuiTextField
                {...params}
                disabled={disabled}
                label={t("employeeOverview.roles")}
                placeholder={t("typeToSearch")}
                onBlur={handleBlur}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  onSearchFieldTextChange(e.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            </>
          )}
        />
      </Tooltip>
    </>
  );
};

export default RolesAutocomplete;
