import React, { ReactElement } from "react";
import { Grid, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { VacationStatusFilterProps } from "./types";
import { TextField } from "./styled";
import { Status } from "../../../../types/be/status";

const FilterByVacationStatus = ({
  vacationStatuses,
  setVacationStatusId,
  vacationStatusId,
}: VacationStatusFilterProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" columns={12}>
      <Grid item xs={12} sx={{ maxHeight: 36 }}>
        <TextField
          name="vacation_request_status_id"
          fullWidth
          select
          size="small"
          value={vacationStatusId}
          onChange={(e) => setVacationStatusId(parseInt(e.target.value))}
          inputProps={{
            "aria-label": "vacation-status-select",
          }}
        >
          <MenuItem key={0} value={0}>
            {t("vacationRequests.allStatuses")}
          </MenuItem>
          {vacationStatuses.map(({ id, name }: Status) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default FilterByVacationStatus;
