import { Grid, Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import React, { ReactElement, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  StyledGridTabs,
  StyledGridTabsContent,
  StyledTabContent,
} from "./styled";
import Bank from "./tabs/bank/Bank";
import GeneralInformation from "./tabs/generalInformation/GeneralInformation";
import PropertyManagement from "./tabs/propertyManagement/PropertyManagement";
import { ObjectDataSheetTabs, TabPanelProps, TabPanels } from "./types";
import { simpleTabProps } from "./utils";
import { route } from "../../../utils/url";
import Loader from "../../Loader";
import Attachments from "./tabs/attachments/list";
import {
  ObjectDataSheetContext,
  ObjectDataSheetContextProvider,
} from "./ObjectDataSheetContexts";

function TabPanel({
  children,
  value,
  index,
  ...props
}: TabPanelProps): ReactElement {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const ObjectDataSheetNoContext = (): ReactElement => {
  const { t } = useTranslation();
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);

  const {
    useFetchProperty: { data, isLoading: isPropertyLoading },
  } = useContext(ObjectDataSheetContext);
  /* istanbul ignore next */
  const tabPanels: TabPanels[] = useMemo(
    () => [
      {
        index: ObjectDataSheetTabs.GENERAL_INFO,
        Component: GeneralInformation,
      },
      {
        index: ObjectDataSheetTabs.PROPERTY_MANAGEMENT,
        Component: PropertyManagement,
      },
      {
        index: ObjectDataSheetTabs.BANK,
        Component: Bank,
      },
      {
        index: ObjectDataSheetTabs.ATTACHMENTS,
        Component: Attachments,
      },
    ],
    []
  );
  /* istanbul ignore next */
  const handleChange = (
    _: React.SyntheticEvent,
    newValue: ObjectDataSheetTabs
  ): void => {
    setDisabled(true);
    navigate(route("properties.object-data-sheet.tab", id, newValue));
  };

  if (isPropertyLoading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container spacing={0} columns={12}>
        <StyledGridTabs item xs={12} sm={12}>
          <Tabs
            value={tab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tab
              label={t("property.objectDataSheet.generalInfo")}
              {...simpleTabProps(0)}
              disabled={disabled}
              value={ObjectDataSheetTabs.GENERAL_INFO}
            />
            <Tab
              label={t("property.objectDataSheet.propertyManagement")}
              {...simpleTabProps(1)}
              disabled={disabled}
              value={ObjectDataSheetTabs.PROPERTY_MANAGEMENT}
            />
            <Tab
              label={t("property.objectDataSheet.bank")}
              {...simpleTabProps(2)}
              disabled={disabled}
              value={ObjectDataSheetTabs.BANK}
            />
            <Tab
              label={t("property.objectDataSheet.attachments.attachments")}
              {...simpleTabProps(3)}
              disabled={disabled}
              value={ObjectDataSheetTabs.ATTACHMENTS}
            />
          </Tabs>
        </StyledGridTabs>
        <StyledGridTabsContent item xs={12} sm={12}>
          {data && (
            <StyledTabContent>
              {tabPanels.map((Item) => (
                <TabPanel
                  key={Item.Component.name}
                  value={tab as ObjectDataSheetTabs}
                  index={Item.index}
                  data-testid={`tab-content-${Item.Component.name}`}
                >
                  <Item.Component id={id} setDisabled={setDisabled} />
                </TabPanel>
              ))}
            </StyledTabContent>
          )}
        </StyledGridTabsContent>
      </Grid>
    </>
  );
};

const ObjectDataSheet = (): ReactElement => {
  return (
    <ObjectDataSheetContextProvider>
      <ObjectDataSheetNoContext />
    </ObjectDataSheetContextProvider>
  );
};

export default ObjectDataSheet;
