import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Typography,
  TableCell,
  useTheme,
  Menu,
  MenuItem,
} from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { FilterCard, InfoContainer, StatisticsCard } from "./styled";
import Dropdown from "../../ui/dropdown";
import { getProperties } from "../../api/property";
import { useTranslation } from "react-i18next";
import { getAreas } from "../../api/area";
import {
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  FormMessageErrorState,
  FormMessageInItState,
  Measurement_Units,
  THOUSAND_SEPARATOR,
} from "../../constants";
import { NumberContainerProps } from "../properties/areas/edit/tabs/rentedForm/types";
import { NumericFormat } from "react-number-format";
import { SmallLightText } from "../properties/areas/edit/tabs/rentedForm/styled";
import Table from "../../ui/table/Table";
import useTable from "../../ui/table/useTable";
import { itemsListHeadCells } from "./utils";
import { red } from "@mui/material/colors";
import { ChartWrapper, DoughnutInner } from "../properties/edit/general/styled";
import { downloadFile, prepareQueryParams } from "../../utils/common";
import { handleServerError } from "../../utils/http";
import {
  exportPDF,
  exportXLS,
  getVacanciesList,
  getVacanciesStatistics,
} from "../../api/vacancies";
import { formatTimestamp } from "../../utils/date";
import { useFetch } from "../../hooks/useFetch";
import { Vacancy, VacanciesStatistics } from "../../types/be/vacancies";
import Loader from "../Loader";
import { EditIcon } from "../../ui/table/styled";
import { EditVacancyModal } from "./editModal";
import useSnackbar from "../../ui/snackbar1/useSnackbar";
import FormAlert from "../../ui/formAlert/FormAlert";
import { AnchorDict } from "../properties/list/types";
import { MoreVerticalButton } from "../properties/list/styled";
import { MoreVertical } from "react-feather";
import RedirectProperty from "../../ui/redirectProperty";

const Vacancies = (): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [row, setRow] = useState<null | Vacancy>(null);
  const { Snackbar, snackbar } = useSnackbar();
  const importExportTab = "importExport";

  const [anchorDict, setAnchorDict] = React.useState<AnchorDict>({
    importExport: null,
  });

  const handleClose = (): void => {
    setAnchorDict({ importExport: null });
  };

  const handleClick =
    (tab: string) => (event: React.MouseEvent<HTMLElement>) => {
      const currentTab = event.currentTarget;
      setAnchorDict((d) => ({ ...d, [tab]: currentTab }));
    };

  const handleCreateModalClose = (): void => {
    setRow(null);
  };

  const {
    data: statistics,
    run: getStatistics,
    isLoading: isStatisticsLoading,
  } = useFetch<VacanciesStatistics>();

  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleSortChange,
    handleSelectAllClick,
    setTotal,
    handleConfirmToRemoveModalClose,
  } = useTable<Vacancy>();

  const doughnutData = {
    labels: ["Living", "Commercial"],
    datasets: [
      {
        data: [statistics?.living_area, statistics?.commercial_area],
        backgroundColor: [theme.palette.secondary.main, red[500]],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: "80%",
  };

  const [propertyAreaId, setPropertyAreaId] = useState<string>();
  const [propertyId, setPropertyId] = useState<string>();
  const [exportingError, setExportingError] = useState(FormMessageInItState);

  const onPropertyAreaSelect = useCallback(
    async (id?: string): Promise<void> => {
      propertyId && setPropertyAreaId(id);
    },
    [propertyId]
  );

  const onPropertySelect = useCallback((id?: string) => {
    setPropertyId(id);
    setPropertyAreaId("");
  }, []);

  const handleExportPDF = useCallback(async () => {
    const params = prepareQueryParams("", {
      property_id: propertyId,
      area_id: propertyAreaId,
    });
    const res = await exportPDF(params);
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const blob = await res.blob();
      downloadFile(
        new Blob([blob]),
        `vacancies_${new Date().toLocaleDateString()}`,
        "pdf"
      );
    }
  }, [propertyId, propertyAreaId]);

  const handleExportXLS = useCallback(async () => {
    try {
      const params = prepareQueryParams("", {
        property_id: propertyId,
        area_id: propertyAreaId,
      });
      const res = await exportXLS(params);

      if (res.ok) {
        const blob = await res.blob();
        downloadFile(
          new Blob([blob]),
          `vacancies_${new Date().toLocaleDateString()}`,
          "xls"
        );
        setExportingError(FormMessageInItState);
      }
    } catch {
      /* istanbul ignore next */
      setExportingError(FormMessageErrorState);
    }
  }, [propertyId, propertyAreaId]);

  const fetchList = useCallback(async () => {
    setIsLoading(true);
    const params = prepareQueryParams(queryParams, {
      property_id: propertyId,
      area_id: propertyAreaId,
    });
    const response = await getVacanciesList(params);

    if (response.ok) {
      const resJson = await response.json();
      setTotal(resJson?.meta?.total);
      setData(resJson.data);
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }

    setIsLoading(false);
  }, [queryParams, propertyId, propertyAreaId]);

  useEffect(() => {
    fetchList();
  }, [propertyId, propertyAreaId, queryParams]);

  useEffect(() => {
    const params = prepareQueryParams("", {
      property_id: propertyId,
      area_id: propertyAreaId,
    });
    getStatistics(getVacanciesStatistics(params));
  }, [propertyId, propertyAreaId]);

  const commercial =
    statistics && statistics?.total_area > 0
      ? ((statistics?.commercial_area * 100) / statistics?.total_area).toFixed(
          2
        )
      : 0;

  const NumberContainer = ({
    value,
    measurementUnits = Measurement_Units.EURO,
  }: NumberContainerProps): ReactElement => {
    return (
      <Typography variant="h4">
        {value ? (
          <>
            <NumericFormat
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              fixedDecimalScale
              decimalScale={DECIMAL_SCALE}
              displayType={"text"}
              value={value}
            />{" "}
            {measurementUnits}
          </>
        ) : (
          EMPTY_DATA
        )}
      </Typography>
    );
  };

  const TableToolbar = <></>;

  const renderRow = (row: Vacancy): ReactElement => (
    <>
      <TableCell align="left">
        <RedirectProperty property={row?.property} />
      </TableCell>
      <TableCell align="left">{row?.name}</TableCell>
      <TableCell align="left">
        {row?.type ? row?.type?.name : EMPTY_DATA}
      </TableCell>
      <TableCell align="right">
        {row?.square_meters
          ? `${row?.square_meters} ${t("vacancies.m2")}`
          : EMPTY_DATA}
      </TableCell>
      <TableCell align="center">
        {row?.empty_space_since
          ? formatTimestamp(row?.empty_space_since)
          : EMPTY_DATA}
      </TableCell>
      <TableCell align="right">
        {row?.potential_rent_income ? (
          <>
            <NumericFormat
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              fixedDecimalScale
              decimalScale={DECIMAL_SCALE}
              displayType={"text"}
              value={row?.potential_rent_income}
            />{" "}
            {Measurement_Units.EURO}
          </>
        ) : (
          EMPTY_DATA
        )}
      </TableCell>
      <TableCell align="right">
        <EditIcon
          role={"editIconRole"}
          onClick={(): void => setRow(row)}
          size={18}
        />
      </TableCell>
    </>
  );

  return (
    <Grid container justifyContent="center" alignItems="stretch" spacing={4}>
      <Grid item xs={12} lg={10}>
        {exportingError?.text && (
          /* istanbul ignore next */ <FormAlert formMessage={exportingError} />
        )}
      </Grid>
      <Grid item xs={12} lg={10}>
        <FilterCard>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item xs={12} sm={8} sx={{ display: "flex", gap: 3 }}>
              <Grid item xs={6} sm={4}>
                <Dropdown
                  id="property-filters"
                  name="property-filters"
                  placeholder={t("counter.searchProperty")}
                  getOptions={getProperties}
                  onSelectOption={onPropertySelect}
                  optionLabel="object_name"
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Dropdown
                  id="property-area-autocomplete"
                  name="property_area_id"
                  placeholder={t("counter.searchArea")}
                  propertyId={propertyId}
                  isAreaSearch={true}
                  getOptions={(params) => getAreas(params)}
                  onSelectOption={onPropertyAreaSelect}
                  disabled={!Number(propertyId)}
                  dontLoad={!Number(propertyId)}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <MoreVerticalButton
                aria-haspopup="true"
                size={"small"}
                type="button"
                color="inherit"
                variant="text"
                disableElevation
                data-testid={"more-vertical-button"}
                onClick={handleClick(importExportTab)}
              >
                <MoreVertical />
              </MoreVerticalButton>
              <Menu
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                anchorEl={anchorDict.importExport}
                open={Boolean(anchorDict.importExport)}
                data-testid={"menu"}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={(): void => {
                    handleExportPDF();
                    handleClose();
                  }}
                  disableRipple
                >
                  {t("export.pdf")}
                </MenuItem>
                <MenuItem
                  onClick={(): void => {
                    handleExportXLS();
                    handleClose();
                  }}
                  disableRipple
                >
                  {t("export.excel")}
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </FilterCard>
      </Grid>
      <Grid item xs={12} md={8} lg={6.5}>
        <Card
          sx={{
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <CardHeader title={t("vacancies.areaVacancies")} />
          <Grid
            container
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            {isStatisticsLoading ? (
              <Loader />
            ) : (
              <Grid container justifyContent={"center"}>
                <InfoContainer item sm={6}>
                  <NumberContainer
                    value={statistics?.potential_rental_income}
                  />
                  <SmallLightText>
                    {t("vacancies.potentialRentalIncomings")}
                  </SmallLightText>
                </InfoContainer>
                <InfoContainer item sm={6}>
                  <Typography variant="h4">
                    {statistics?.not_rented_living_areas || EMPTY_DATA}
                  </Typography>
                  <SmallLightText>
                    {t("vacancies.netRentedLivingAreas")}
                  </SmallLightText>
                </InfoContainer>
                <InfoContainer item sm={6}>
                  <Typography variant="h4">
                    {statistics?.average_vacancy_period || EMPTY_DATA}
                  </Typography>
                  <SmallLightText>
                    {t("vacancies.averageVacancyPeriod")}
                  </SmallLightText>
                </InfoContainer>
                <InfoContainer item sm={6}>
                  <Typography variant="h4">
                    {statistics?.not_rented_commercial_areas || EMPTY_DATA}
                  </Typography>
                  <SmallLightText>
                    {t("vacancies.notRentedCommercialAreas")}
                  </SmallLightText>
                </InfoContainer>
              </Grid>
            )}
          </Grid>
          <Grid item />
        </Card>
      </Grid>
      <Grid item xs={12} md={4} lg={3.5} sx={{ minHeight: 360 }}>
        <StatisticsCard sx={{ height: "100%" }}>
          <CardHeader title={t("vacancies.areaByType")} />
          {isStatisticsLoading ? (
            <Loader />
          ) : (
            <>
              <ChartWrapper>
                <DoughnutInner>
                  <Typography variant="caption">
                    {t("vacancies.commercial")}
                  </Typography>
                  <Typography variant="h4">{`${commercial} ${Measurement_Units.PERCENTAGE}`}</Typography>
                </DoughnutInner>
                <Doughnut data={doughnutData} options={options} />
              </ChartWrapper>
              <Grid container spacing={1} sx={{ padding: 4 }}>
                <Grid item xs={12} className="info row-end">
                  <SmallLightText>{t("vacancies.notRented")}</SmallLightText>
                </Grid>
                <Grid item xs={12} className="info">
                  <Typography variant="caption">
                    {t("vacancies.living")}
                  </Typography>
                  <Typography variant="caption">
                    {statistics?.living_area ? (
                      <>
                        <NumericFormat
                          thousandSeparator={THOUSAND_SEPARATOR}
                          decimalSeparator={DECIMAL_SEPARATOR}
                          fixedDecimalScale
                          decimalScale={DECIMAL_SCALE}
                          displayType={"text"}
                          value={statistics?.living_area}
                        />
                        {` ${t("vacancies.m2")}`}
                      </>
                    ) : (
                      EMPTY_DATA
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} className="info">
                  <Typography variant="caption">
                    {t("vacancies.commercial")}
                  </Typography>
                  <Typography variant="caption">
                    {statistics?.commercial_area ? (
                      <>
                        <NumericFormat
                          thousandSeparator={THOUSAND_SEPARATOR}
                          decimalSeparator={DECIMAL_SEPARATOR}
                          fixedDecimalScale
                          decimalScale={DECIMAL_SCALE}
                          displayType={"text"}
                          value={statistics?.commercial_area}
                        />
                        {` ${t("vacancies.m2")}`}
                      </>
                    ) : (
                      EMPTY_DATA
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </StatisticsCard>
      </Grid>
      <Grid item xs={12} lg={10}>
        <Table
          data={data}
          total={total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={itemsListHeadCells}
          renderRow={renderRow}
          tableToolbar={TableToolbar}
          tableDataMaxHeight={"40vh"}
        />
      </Grid>
      {row && (
        <EditVacancyModal
          open={!!row}
          row={row}
          handleCreateModalClose={handleCreateModalClose}
          snackbar={snackbar}
          fetchList={fetchList}
        />
      )}
      {Snackbar}
    </Grid>
  );
};

export default Vacancies;
