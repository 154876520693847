import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import useAppSelector from "../../hooks/useAppSelector";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { setAsRead } from "../../api/news";

export const NewsModal = (): ReactElement => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { news } = useAppSelector((state) => state.news);
  const [gotIt, setGotIt] = useState(false);

  const handleClose = (): void => {
    news?.id && gotIt && setAsRead(news.id);
    setOpen(false);
  };

  useEffect(() => {
    news?.content && setOpen(true);
  }, [news]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {news && (
            <DialogContentText
              id="alert-dialog-description"
              dangerouslySetInnerHTML={{
                __html: news.content,
              }}
            ></DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ px: 5, pb: 5 }}
          >
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox onChange={(e) => setGotIt(e.target.checked)} />
                }
                label={t("gotIt")}
              />
            </Grid>
            <Grid item>
              <Button onClick={handleClose} data-testid="news-close-button">
                {t("close")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewsModal;
