import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { Form as FormikForm } from "formik";
import TextField from "../../../../ui/formsFields/textField";
import FormAlert from "../../../../ui/formAlert/FormAlert";
/* istanbul ignore next */
export const FileName = styled.a`
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display: block;
  color: ${(props): string => props.theme.palette.textColor.default};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const Form = styled(FormikForm)`
  display: flex;
  align-items: flex-start;
  transition: all 3s;
`;

export const TextFieldStyled = styled(TextField)`
  min-height: auto !important;
  > .MuiFormHelperText-root {
    position: absolute;
    top: 95%;
  }
`;

export const ErrorBox = styled(FormAlert)(spacing);

export const LabelText = styled.div`
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
`;
