import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Grid, TableCell, Tooltip } from "@mui/material";
import { route } from "../../../../utils/url";
import Table from "../../../../ui/table/Table";
import useTable from "../../../../ui/table/useTable";
import { itemsListHeadCells } from "./utils";
import {
  COMMENTABLE,
  EMPTY_DATA,
  PermissionsCreate,
  PermissionsDelete,
  PermissionsUpdate,
} from "../../../../constants";
import { Maintenance } from "../../../../types/be/maintenance";
import Button from "../../../../ui/button/Button";
import { StyledButton } from "../../../../ui/button/styled";
import { DescriptionWrapper } from "./styled";
import { DeleteIcon, EditIcon } from "../../../../ui/table/styled";
import { showCommentsModalInitState } from "../../../../ui/commentsPopUp/utils";
import CommentsPopup from "../../../../ui/commentsPopUp";
import { prepareQueryParams } from "../../../../utils/common";
import {
  deleteMaintenance,
  getMaintenanceList,
} from "../../../../api/maintenance";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { handleServerError } from "../../../../utils/http";
import MaintenanceTypeDialog from "../typeManagement";
import MaintenanceHistoryDialog from "../history";
import { getReleaseRoute } from "../../utils";
import PropertyAutocomplete from "../../components/propertyAutocomplete";
import useAccessControl from "../../../../hooks/useAccessControl";
import TabPageWrapper from "../../../../ui/tabPageWrapper";
import ExpandableComment from "../../../../ui/expandableComment/ExpandableComment";
import FileTableCell from "../../components/fileTableCell";

const MaintenanceList = (): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { can } = useAccessControl();
  const navigate = useNavigate();
  const [propertyId, setPropertyId] = useState(id);

  const [isShowCommentsModal, setIsShowCommentsModal] = useState(
    showCommentsModalInitState
  );
  const [showMaintenanceTypeDialog, setShowMaintenanceTypeDialog] =
    useState(false);
  const [showMaintenanceHistoryDialog, setShowMaintenanceHistoryDialog] =
    useState<number | null>(null);

  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleSortChange,
    handleSelectAllClick,
    setTotal,
    openConfirmToRemoveModal,
    rowToDelete,
    handleDeleteLastPageData,
    handleConfirmToRemoveModalClose,
  } = useTable<Maintenance>({ loading: true });

  const handleOpenComments = useCallback((row: Maintenance): void => {
    setIsShowCommentsModal({ isVisible: true, rowId: row?.id });
  }, []);

  const handleCloseCommentsModal = useCallback((): void => {
    setIsShowCommentsModal(showCommentsModalInitState);
  }, []);

  /* istanbul ignore next */
  const onPropertySelect = (propertyId?: string): void => {
    setPropertyId(propertyId);
  };

  const handleFetchList = useCallback(
    async (loading = true) => {
      setIsLoading(loading);
      const params = prepareQueryParams(queryParams, {
        property_id: propertyId,
      });
      const response = await getMaintenanceList(params);

      if (response.status !== HTTP_STATUS_CODES.OK) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        const json = await response.json();
        setData(json?.data);
        setTotal(json?.meta?.total);
      }

      setIsLoading(false);
    },
    [queryParams, propertyId]
  );

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteMaintenance(rowToDelete.id);
    handleDeleteLastPageData(handleFetchList);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteMaintenance,
    handleFetchList,
    handleConfirmToRemoveModalClose,
  ]);

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
  ];

  const TableToolbar = (
    <>
      <Grid container sx={{ pt: 5, mb: 2 }} spacing={2}>
        {!id && (
          <Grid item>
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item>
                <PropertyAutocomplete
                  onSelectProperty={onPropertySelect}
                  name="property-filters"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {can(PermissionsCreate.MAINTENANCE) && (
          <Grid item>
            <StyledButton
              component={NavLink}
              to={getReleaseRoute("maintenance.create", Number(id))}
              size="small"
              color="success"
              variant="contained"
            >
              {t("create")}
            </StyledButton>
          </Grid>
        )}
        <Grid item>
          <StyledButton
            size="small"
            color="success"
            variant="contained"
            onClick={() => setShowMaintenanceTypeDialog(true)}
          >
            {t("property.maintenance.typeManagement")}
          </StyledButton>
        </Grid>
      </Grid>
    </>
  );

  const renderRow = (row: Maintenance): ReactElement => (
    <>
      <TableCell align="left">
        {row?.contact ? row?.contact?.full_name : EMPTY_DATA}
      </TableCell>
      <TableCell align="left">
        {row?.description ? (
          <Tooltip title={row?.description} placement="top">
            <DescriptionWrapper>{row?.description}</DescriptionWrapper>
          </Tooltip>
        ) : (
          EMPTY_DATA
        )}
      </TableCell>
      <TableCell align="center">
        {row?.inspection + " " + t("insurance.month")}
      </TableCell>
      <TableCell align="left">
        <ExpandableComment
          seeAllComment={() => handleOpenComments(row)}
          commentData={row?.latest_comment}
        ></ExpandableComment>
      </TableCell>
      <FileTableCell file={row?.contract?.file} />
      <FileTableCell file={row?.report} />
      <FileTableCell file={row.order} />
      <TableCell align="center">
        <Button
          title={t("tenant.history.history")}
          variant="outlined"
          color="primary"
          testId="history-button"
          onClick={() => setShowMaintenanceHistoryDialog(row?.id)}
        />
      </TableCell>
      <TableCell align="center">
        <Button
          title={t("property.maintenance.units")}
          variant="outlined"
          color="primary"
          onClick={() =>
            navigate(getReleaseRoute("maintenance.units", Number(id), row?.id))
          }
          testId="maintenance-unit-button"
        />
      </TableCell>
      <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
        {can(PermissionsUpdate.MAINTENANCE) && (
          <EditIcon
            role="EditIconRole"
            size={18}
            onClick={() =>
              navigate(getReleaseRoute("maintenance.edit", Number(id), row?.id))
            }
          />
        )}
        {can(PermissionsDelete.MAINTENANCE) && (
          <DeleteIcon
            role="DeleteIconRole"
            size={18}
            onClick={() => openConfirmToRemoveModal(row)}
          />
        )}
      </TableCell>
    </>
  );

  useEffect(() => {
    if (!queryParams) return;
    handleFetchList();
  }, [propertyId, queryParams]);

  return (
    <TabPageWrapper
      title={t("property.maintenance.title")}
      breadcrumbs={breadcrumbs}
      wrap={!id}
    >
      <>
        <Table
          data={data}
          total={total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={itemsListHeadCells}
          renderRow={renderRow}
          tableToolbar={TableToolbar}
          onRowRemove={handleRowRemove}
        />
        {isShowCommentsModal.isVisible && (
          <CommentsPopup
            handleCloseDialog={handleCloseCommentsModal}
            id={String(isShowCommentsModal?.rowId)}
            relation={COMMENTABLE.MAINTENANCE}
          />
        )}
        {showMaintenanceTypeDialog ? (
          <MaintenanceTypeDialog
            open={showMaintenanceTypeDialog}
            onClose={() => setShowMaintenanceTypeDialog(false)}
          />
        ) : null}

        {showMaintenanceHistoryDialog ? (
          <MaintenanceHistoryDialog
            open={!!showMaintenanceHistoryDialog}
            onClose={() => setShowMaintenanceHistoryDialog(null)}
            maintenanceId={showMaintenanceHistoryDialog}
          />
        ) : null}
      </>
    </TabPageWrapper>
  );
};

export default MaintenanceList;
