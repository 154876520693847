/* istanbul ignore file */
import { HandleUpdateTenantProps, UpdateLeaseFormProps } from "./types";
import { FormikValues } from "formik";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../constants";
import { HTTP_STATUS_CODES } from "../../../../../types/server";
import { mapBackendValidationErrors } from "../../../../../utils/form";
import { replaceMomentByTimestamp } from "../../../../../utils/date";
import { updateTenant } from "../../../../../api/area";

export const updateLeaseInitState: UpdateLeaseFormProps = {
  rent_end: null,
};

export const touchedInitState = {
  rent_end: false,
};

export const handleUpdateTenantLease = ({
  area,
  setFormMessage,
  handleConfirmToRemoveModalClose,
  fetchAreasAfterLeaseUpdate,
}: HandleUpdateTenantProps) => {
  return async (
    values: UpdateLeaseFormProps,
    { setStatus }: FormikValues
  ): Promise<void> => {
    const data = new FormData();

    data.append("rent_end", String(replaceMomentByTimestamp(values.rent_end)));

    const response = await updateTenant(String(area.id), data);

    if (response.ok) {
      handleConfirmToRemoveModalClose();
      fetchAreasAfterLeaseUpdate();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      const errors = await response.json();
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(errors),
      });
    } else {
      setFormMessage({ text: SOMETHING_WENT_WRONG_ERROR, type: "error" });
    }
  };
};
