import React, {
  HTMLAttributes,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import Loader from "../../../../Loader";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { getJson } from "../../../../../utils/http";
import { Dropdown } from "../../../../../ui/autocomplete/styled";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import { Grid, TextField } from "@mui/material";
import debounce from "lodash/debounce";
import {
  NODE_ENVIRONMENTS,
  SEARCH_PARAM_MIN_CHARACTERS,
} from "../../../../../constants";
import { getUsers } from "../../../../../api/users";
import { User } from "../../../../../types/be/user";
import {
  AutocompletePropertyTenantEmail,
  AutocompletePropertyTenantName,
  AutocompleteListItem,
} from "./styled";
import { isNodeEnv } from "../../../../../utils/env";
import { DEBOUNCE_TIME } from "../../../../../utils/common";

const PropertyTenantAutocomplete = ({
  onPropertyTenantSelect,
}: {
  onPropertyTenantSelect: (propertyTenant: User | null) => void;
}): ReactElement => {
  const [isLoading, setLoading] = useState(false);
  const [propertyTenant, setPropertyTenant] = useState<User | null>(null);
  const [
    propertyTenantAutocompleteInputValue,
    setPropertyTenantAutocompleteInputValue,
  ] = useState("");
  const [predictions, setPredictions] = useState<User[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const val = propertyTenant
      ? `${propertyTenant.first_name} ${propertyTenant.last_name}`
      : "";
    setPropertyTenantAutocompleteInputValue(val);
    onPropertyTenantSelect(propertyTenant);
  }, [propertyTenant]);

  const handlePropertyTenantSelect = (propertyTenant: User): void => {
    setPropertyTenant(propertyTenant);
  };

  const handleAutocompleteOnChange = (
    _: SyntheticEvent,
    value: User | null
  ): void => {
    if (value) handlePropertyTenantSelect(value);
    else {
      setPropertyTenant(null);
    }
  };

  const onLocationSearchKeyDown = React.useCallback(
    debounce(
      async (search: string): Promise<void> => {
        if (search.length < SEARCH_PARAM_MIN_CHARACTERS) setPredictions([]);
        else {
          setLoading(true);
          getUsers(`?search=${search}&role_code=tenant`)
            .then(getJson)
            .then(({ data }) => {
              setPredictions(data);
            })
            .catch(() => {
              setPredictions([]);
            }).finally(() => {
              setLoading(false);
            });
        }
      },
      /* istanbul ignore next */ isNodeEnv(NODE_ENVIRONMENTS.TEST)
        ? 0
        : DEBOUNCE_TIME
    ),
    []
  );

  const handleRenderInput = (
    params: AutocompleteRenderInputParams
  ): ReactNode => (
    <TextField
      {...params}
      size={"small"}
      label={t("tenant.tenant")}
      onChange={(e) => {
        setPropertyTenantAutocompleteInputValue(e.target.value);
        onLocationSearchKeyDown(e.target.value);
      }}
      placeholder={t("search")}
    />
  );

  return (
    <Autocomplete
      role={"property-tenant-filter"}
      fullWidth
      loading={isLoading}
      loadingText={`${t("loading")}...`}
      inputValue={propertyTenantAutocompleteInputValue}
      popupIcon={isLoading ? <Loader size={25} /> : <ArrowDropDownIcon />}
      options={predictions!}
      isOptionEqualToValue={handleIsOptionEqualToValue}
      getOptionLabel={handleGetOptionLabel}
      filterOptions={handleFilterOptions}
      onChange={handleAutocompleteOnChange}
      renderOption={handleRenderOption}
      PaperComponent={autocompletePaperComponent}
      renderInput={handleRenderInput}
    />
  );
};

export default PropertyTenantAutocomplete;

const handleIsOptionEqualToValue = (option: User, value: User): boolean => {
  return option.id === value.id;
};

const handleGetOptionLabel = (propertyTenant: User): string => {
  return `${propertyTenant?.first_name} ${propertyTenant?.last_name}`;
};

const handleFilterOptions = (options: User[]): User[] => options;

const handleRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  propertyTenant: User
): ReactNode => {
  return (
    <AutocompleteListItem {...props} key={propertyTenant.id}>
      <Grid container direction={"column"}>
        <Grid item>
          <AutocompletePropertyTenantName>
            {propertyTenant.first_name} {propertyTenant.last_name}
          </AutocompletePropertyTenantName>
        </Grid>
        <Grid item>
          <AutocompletePropertyTenantEmail>
            {propertyTenant.email}
          </AutocompletePropertyTenantEmail>
        </Grid>
      </Grid>
    </AutocompleteListItem>
  );
};

const autocompletePaperComponent = ({
  children,
}: {
  children?: ReactNode;
}): ReactElement | null => <Dropdown>{children}</Dropdown>;
