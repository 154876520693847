import React, { ReactElement, useRef, useState, useEffect } from "react";
import { Button as MUButton, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FileRejection, useDropzone } from "react-dropzone";
import {
  DropZone,
  DropZoneHintText,
} from "../../../../../ui/fileUploader/styled";
import { Snackbar } from "../../../../../ui/snackbar1/useSnackbar";
import { File as BEFile, isImage } from "../../../../../types/be/file";
import {
  ALL_FILE_TYPE,
  IMAGE,
  IMAGE_EXTENSION,
  MAX_FILES_UPLOAD,
  MAX_PROPERTY_IMAGES_LIMIT,
  MAX_PROPERTY_VIDEO_LIMIT,
  MAX_VIDEO_FILE_SIZE,
  VIDEO_FILE_TYPE,
} from "../../../../../constants";
import SimpleImageViewer from "../../../../../ui/imageViewer/simple";
import Thumbnail from "../../../../../ui/fileUploader/components/thumbnail";
import InProgress from "../../../../../ui/fileUploader/components/inProgress";
import { deleteFile } from "../../../../../api/files";
import { getJson } from "../../../../../utils/http";
import { FILE_SIZE_100_MB, compareFileSize } from "../../../../../utils/common";

/* istanbul ignore next */
const Uploader = ({
  filesInitState,
  onFileChange,
  onFileDelete,
  accept = "*",
  isDisabled = false,
  uploadDisabled,
  refreshData,
  placeHolderText,
  uploadButtonText,
  progressPercentage,
  turnedOff = false,
  snackbar,
}: {
  placeHolderText: string;
  uploadButtonText: string;
  filesInitState?: BEFile[];
  onFileChange?: (filesToUpload: File[]) => void;
  accept?: string | string[];
  onFileDelete?: (files: File[]) => void;
  isDisabled?: boolean;
  uploadDisabled?: boolean;
  refreshData?: () => void;
  progressPercentage?: number;
  turnedOff?: boolean;
  snackbar?: Snackbar;
}): ReactElement => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [filesInProgress, setFilesInProgress] = useState<File[]>([]);
  const [isFileDeleting, setIsFileDeleting] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<BEFile[] | undefined>(
    filesInitState
  );
  const [isDragActive, setIsDragActive] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (filesInitState) {
      setUploadedFiles(filesInitState);
      setFilesToUpload([]);
      setFilesInProgress([]);
    }
  }, [filesInitState]);

  useEffect(() => {
    return () => {
      setFilesToUpload([]);
      setUploadedFiles([]);
      setIsDragActive(false);
    };
  }, []);

  const isFileSizesAcceptable = (file: File): boolean => {
    if (
      file.type.includes(VIDEO_FILE_TYPE) &&
      compareFileSize(file.size, MAX_VIDEO_FILE_SIZE)
    ) {
      snackbar && snackbar?.error(`${file.name} ${t(`fileSize100MB`)}`);
      return false;
    }
    return true;
  };

  const checkImagePixelSize = async (file: File): Promise<boolean> => {
    let flag = false;
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    img.src = objectUrl;

    let resolver: (value: unknown) => void;
    const promise = new Promise((resolve) => (resolver = resolve));

    img.onload = function () {
      const height = img.height;
      const width = img.width;
      if (height < 255 || width < 400) {
        snackbar && snackbar?.error(t("minImagePixelSize"));
        flag = true;
      }
      resolver(flag);
    };

    await promise;
    return flag;
  };

  const checkFileLimit = async (files: FileList): Promise<boolean> => {
    let uploadedImages = 0;
    let uploadedVideos = 0;
    let isImagePixelSizeExceed = false;

    uploadedFiles &&
      uploadedFiles.forEach((file) => {
        if (IMAGE_EXTENSION.includes(file?.extension)) {
          uploadedImages = uploadedImages + 1;
        }
        if (file?.extension?.includes(VIDEO_FILE_TYPE)) {
          uploadedVideos = uploadedVideos + 1;
        }
      });

    for (let i = 0; i < files.length; i++) {
      if (files[i].type.includes(IMAGE)) {
        const pixelSizeCheck = await checkImagePixelSize(files[i]);
        if (pixelSizeCheck) {
          isImagePixelSizeExceed = true;
          break;
        }
        uploadedImages = uploadedImages + 1;
      }
      if (files[i].type.includes(VIDEO_FILE_TYPE)) {
        uploadedVideos = uploadedVideos + 1;
      }
    }
    if (isImagePixelSizeExceed) {
      return true;
    }
    if (uploadedImages > MAX_PROPERTY_IMAGES_LIMIT) {
      snackbar && snackbar?.error(t(`fileLimit10Images`));
      return true;
    }
    if (uploadedVideos > MAX_PROPERTY_VIDEO_LIMIT) {
      snackbar && snackbar?.error(t(`fileLimit10Video`));
      return true;
    }
    return false;
  };

  const handleOnFileChange = async (): Promise<void> => {
    if (
      fileInput?.current?.files &&
      (await checkFileLimit(fileInput?.current?.files))
    ) {
      return;
    }

    const _filesToUpload: File[] = [];
    if (fileInput?.current?.files) {
      for (let i = 0; i < fileInput.current.files.length; i++) {
        if (
          isAcceptable(fileInput.current.files[i].type) &&
          isFileSizesAcceptable(fileInput.current.files[i])
        ) {
          _filesToUpload.push(fileInput.current.files[i]);
        }
      }
    }
    if (!filesInitState) {
      setFilesToUpload([...filesToUpload, ..._filesToUpload]);
      onFileChange && onFileChange([...filesToUpload, ..._filesToUpload]);
    } else {
      setFilesInProgress(_filesToUpload);
      onFileChange && onFileChange(_filesToUpload);
    }
  };

  const handleDeleteFileFromUploadList = (file: File): void => {
    setIsFileDeleting(true);
    const _files = filesToUpload?.filter((f) => f.name !== file.name);
    onFileDelete && onFileDelete(_files);
    setFilesToUpload(_files);
    setIsFileDeleting(false);
  };

  const handleDeleteUploadedFile = (
    file: BEFile,
    e?: React.MouseEvent<HTMLDivElement>
  ): void => {
    e && e.preventDefault();
    e && e.stopPropagation();
    setIsFileDeleting(true);
    deleteFile(String(file.id))
      .then(getJson)
      .then(() => {
        setUploadedFiles((_files) => {
          if (_files) return _files.filter((f) => f.id !== file.id);
          else return _files;
        });
        setIsFileDeleting(false);
        refreshData && refreshData();
      });
  };

  const isAcceptable = (type: string): boolean => {
    if (accept === ALL_FILE_TYPE) return true;
    else if (Array.isArray(accept)) {
      let acceptable = false;
      accept.forEach((item) => {
        if (type.includes(item)) {
          acceptable = true;
        }
      });
      return acceptable;
    } else {
      return type.includes(accept);
    }
  };

  const handleDownloadUploadedFile = (
    e: React.MouseEvent<HTMLDivElement>,
    downloadLink: string
  ): void => {
    e.stopPropagation();
    window.open(downloadLink);
  };

  const handleDrop = async <T extends File>(
    files: FileList | T[] | null,
    fileRejections: FileRejection[]
  ): Promise<void> => {
    const _filesToUpload: File[] = [];

    if (fileRejections.length) {
      snackbar &&
        snackbar?.error(
          fileRejections[0].errors[0].code === "file-too-large"
            ? `${t(`fileSize100MB`)}`
            : fileRejections[0].errors[0].message
        );
      return;
    }

    setIsDragActive(false);
    if (isDisabled) return;

    if (files && files[0] && (await checkFileLimit(files as FileList))) {
      return;
    }
    if (files && files[0]) {
      for (let i = 0; i < files.length; i++) {
        if (isAcceptable(files[i].type)) {
          _filesToUpload.push(files[i]);
        }
      }
    }
    if (!filesInitState) {
      setFilesToUpload([...filesToUpload, ..._filesToUpload]);
      onFileChange && onFileChange([...filesToUpload, ..._filesToUpload]);
    } else {
      setFilesInProgress(_filesToUpload);
      onFileChange && onFileChange(_filesToUpload);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: MAX_FILES_UPLOAD,
    accept: { "image/*": [".png", ".jpeg", ".jpg"], "video/*": [".mp4"] },
    onDrop: handleDrop,
    minSize: 0,
    maxSize: FILE_SIZE_100_MB,
    disabled: isDisabled || uploadDisabled,
  });

  return (
    <>
      <DropZone
        $isDragActive={isDragActive}
        {...getRootProps()}
        variant="outlined"
        sx={{
          width: "100%",
          minHeight: "200px",
          p: 2,
        }}
        data-testid="dropzone-container"
      >
        <input {...getInputProps()} />
        <Grid
          container
          height={"100%"}
          spacing={1}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {!turnedOff && (
            <>
              <Grid item lg={12}>
                <Grid container justifyContent={"center"}>
                  <Grid item>
                    <DropZoneHintText variant="body2">
                      {placeHolderText}
                    </DropZoneHintText>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12}>
                <Grid container justifyContent={"center"}>
                  <Grid item>
                    <MUButton
                      disabled={isDisabled || uploadDisabled}
                      variant="contained"
                      component="label"
                      size={"small"}
                    >
                      {uploadButtonText}
                      <input
                        ref={fileInput}
                        name="file[]"
                        hidden
                        accept={`${accept}/*`}
                        multiple
                        type="file"
                        onChange={() => handleOnFileChange()}
                      />
                    </MUButton>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item lg={12}>
            <Grid container spacing={2} justifyContent={"center"}>
              {!!uploadedFiles?.length &&
                uploadedFiles.map((file: BEFile) => (
                  <Grid item key={file.id}>
                    {isImage(file) ? (
                      <SimpleImageViewer
                        url={`${file.url}`}
                        downloadLink={file.url}
                        name={`${file.name}.${file.extension}`}
                        onDelete={() =>
                          !isFileDeleting && handleDeleteUploadedFile(file)
                        }
                        isDisabled={isDisabled}
                      />
                    ) : (
                      <Thumbnail
                        name={`${file.name}.${file.extension}`}
                        onDeleteClick={(e) =>
                          !isFileDeleting && handleDeleteUploadedFile(file, e)
                        }
                        onDownloadClick={(e) =>
                          handleDownloadUploadedFile(e, file.url)
                        }
                      />
                    )}
                  </Grid>
                ))}
              {!!filesToUpload.length &&
                filesToUpload.map((file, index) => (
                  <Grid item key={`${file.name}-${index}`}>
                    {file.type.includes("image") ? (
                      <SimpleImageViewer
                        url={URL.createObjectURL(file)}
                        name={`${file.name}`}
                        onDelete={() =>
                          !isFileDeleting &&
                          handleDeleteFileFromUploadList(file)
                        }
                        isDisabled={isDisabled}
                      />
                    ) : (
                      <Thumbnail
                        name={`${file.name}`}
                        onDeleteClick={() =>
                          !isFileDeleting &&
                          handleDeleteFileFromUploadList(file)
                        }
                      />
                    )}
                  </Grid>
                ))}
              {!!filesInProgress.length &&
                filesInProgress.map((file, index) => (
                  <Grid item key={`${file.name}-${index}`}>
                    <InProgress
                      url={URL.createObjectURL(file)}
                      progressPercentage={progressPercentage || null}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </DropZone>
    </>
  );
};

export default Uploader;
