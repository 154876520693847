import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import { createMaintenanceType } from "../../../../api/maintenance";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../constants";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { TableCell } from "../../../../types/table";
import { generalPropertyOption } from "../../../../utils/common";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { CreateMaintenanceType } from "./types";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.maintenance.type",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];

/* istanbul ignore next */
export const prepareDataBeforSubmit = (
  data: CreateMaintenanceType
): CreateMaintenanceType => {
  if (data.property_id === generalPropertyOption.id) {
    data.property_id = null;
  }
  return data;
};
export const handleCreateMaintenanceType = (
  t: TFunction<"translation", undefined, "translation">,
  snackbar: {
    success: (title: string) => void;
    error: (title: string) => void;
  },
  afterSuccess: () => void
) => {
  return async (
    values: CreateMaintenanceType,
    {
      setStatus,
      setSubmitting,
      resetForm,
    }: FormikHelpers<CreateMaintenanceType>
  ): Promise<void> => {
    const response = await createMaintenanceType(
      prepareDataBeforSubmit({ ...values })
    );
    const json = await response.json();

    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      resetForm();
      snackbar.success(t("property.maintenance.typeCreatedSuccessfully"));
      afterSuccess();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
    } else {
      snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
    }

    setSubmitting(false);
  };
};
