import { FinanceProfile } from "../types/models";
import http from "../utils/http";

export const createFinanceProfiles = async (
  financeProfile: FinanceProfile
): Promise<Response> => {
  return await http.post("/api/finance-profiles", financeProfile);
};

export const getFinanceProfilesByContactId = async (
  id: string,
  params: string
): Promise<Response> => {
  return await http.get(`/api/contacts/${id}/finance-profiles${params}`);
};
export const getFinanceProfile = async (id: number): Promise<Response> => {
  return await http.get(`/api/finance-profiles/${id}`);
};

export const editFinanceProfile = async (
  financeProfile: FinanceProfile
): Promise<Response> => {
  return await http.put(
    `/api/finance-profiles/${financeProfile.id}`,
    financeProfile
  );
};

/* istanbul ignore next */
export const deleteFinanceProfile = async (id: number): Promise<Response> => {
  return await http.delete(`/api/finance-profiles/${id}`);
};
