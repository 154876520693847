import React, { ReactElement, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Tooltip,
  Autocomplete,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import {
  AssetManagersAutocompleteTextField,
  Chip,
  SelectBox,
} from "../tabs/generalInformation/styled";
import {
  MAX_PRELOADED_OPTIONS,
  NODE_ENVIRONMENTS,
  SEARCH_PARAM_MIN_CHARACTERS,
} from "../../../../constants";
import { AssetManagersAutocompleteProps } from "../tabs/generalInformation/types";
import { removeDuplicatesFromUsers } from "../../areas/edit/tabs/areaForm/utils";
import debounce from "lodash/debounce";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { User } from "../../../../types/models";
import { isNodeEnv } from "../../../../utils/env";
import { handleOnChangeAutocompleteInputText } from "../utils";
import { StyledPaper } from "../../../contacts/autocomplete/styled";

const icon = <CheckBoxOutlineBlankIcon fontSize="inherit" />;
const checkedIcon = <CheckBoxIcon fontSize="inherit" />;

/* istanbul ignore next */
const AssetManagersAutocomplete = ({
  values,
  touched,
  errors,
  assetManagers,
  setFieldValue,
  handleBlur,
  size,
}: AssetManagersAutocompleteProps): ReactElement => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const preloadOptions: User[] = useMemo(() => {
    const { length = 0 } = values.asset_managers;
    return removeDuplicatesFromUsers([
      ...values.asset_managers,
      ...assetManagers.slice(
        0,
        length < MAX_PRELOADED_OPTIONS ? MAX_PRELOADED_OPTIONS - length : 0
      ),
    ]);
  }, [assetManagers, values.asset_managers, MAX_PRELOADED_OPTIONS]);

  const [options, setOptions] = useState<User[] | null>(preloadOptions);
  const [searchResult, setSearchResult] = useState<User[]>(preloadOptions);

  const onSearchFieldTextChange = useCallback(
    debounce(
      async (search: string): Promise<void> => {
        if (search.length < SEARCH_PARAM_MIN_CHARACTERS) {
          setOptions(preloadOptions);
          setSearchResult(preloadOptions);
        } else {
          setLoading(true);
          try {
            const response: User[] = await handleOnChangeAutocompleteInputText(
              search
            );
            setOptions(
              removeDuplicatesFromUsers([...values.asset_managers, ...response])
            );
            setSearchResult(removeDuplicatesFromUsers([...response]));
            setLoading(false);
          } catch (e) {
            /* istanbul ignore next */
            setOptions([]);
          }
        }
      },
      /* istanbul ignore next */ isNodeEnv(NODE_ENVIRONMENTS.TEST) ? 0 : 500
    ),

    [handleOnChangeAutocompleteInputText, preloadOptions, values.asset_managers]
  );

  const getOptionLabel = ({ id, first_name, last_name, email }: User): string =>
    `${first_name}${id}${last_name}${email}`;

  /* istanbul ignore next */
  const filterOptions = useCallback(
    (options: User[]): User[] =>
      options.filter(
        (option) =>
          !(
            !searchResult.some((el) => el.id === option.id) &&
            values.asset_managers.map((el) => el.id).includes(option.id)
          )
      ),
    [searchResult, values.asset_managers]
  );

  const onAutocompleteChange = (_: unknown, newUsers: User[]): void => {
    setFieldValue("asset_managers", newUsers);
  };

  const renderTags = (list: User[]): ReactElement => (
    <SelectBox>
      <Chip sx={{ maxHeight: 17 }} label={list[0].first_name} />
      {list.length > 1 && (
        <div className="more-info">{`${t(
          "tenant.selectedMoreThenOneTenantPartOne"
        )} ${list.length - 1} ${t(
          "tenant.selectedMoreThenOneTenantPartTwo"
        )} `}</div>
      )}
    </SelectBox>
  );

  const tooltipTitle =
    values.asset_managers.length === 0 || isMenuOpened
      ? null
      : values.asset_managers.map(
          ({ id, first_name, last_name }: User, index: number) => (
            <Typography key={id + " " + index}>
              {first_name + " " + last_name}
            </Typography>
          )
        );

  return (
    <Tooltip placement="top" title={tooltipTitle}>
      <Autocomplete
        id="asset_managers"
        ListboxProps={{ style: { maxHeight: "100vh" } }}
        loading={loading}
        inputValue={inputValue}
        size={size}
        multiple
        limitTags={1}
        onOpen={() => setIsMenuOpened(true)}
        onClose={() => {
          setIsMenuOpened(false);
        }}
        disableCloseOnSelect
        options={options || /* istanbul ignore next */ []}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value): boolean => {
          return option.id === value.id;
        }}
        value={values.asset_managers}
        onChange={onAutocompleteChange}
        renderTags={renderTags}
        PaperComponent={StyledPaper}
        renderOption={(props, option, { selected }): ReactElement => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            {`${option.first_name} ${option.last_name}`}
          </li>
        )}
        renderInput={(params) => (
          <>
            <AssetManagersAutocompleteTextField
              {...params}
              label={t("property.assetManager")}
              placeholder={t("property.assetManagers")}
              onBlur={handleBlur}
              onChange={(e) => {
                setInputValue(e.target.value);
                onSearchFieldTextChange(e.target.value);
              }}
              error={Boolean(touched.asset_managers && errors.asset_managers)}
              helperText={touched.asset_managers && errors.asset_managers}
              InputProps={{
                ...params.InputProps,
                className: `${params.InputProps.className} ${size ?? ""}`,
                endAdornment: (
                  <>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          </>
        )}
      />
    </Tooltip>
  );
};

export default AssetManagersAutocomplete;
