import styled from "styled-components/macro";
import { TextField as MuiTextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const DatePickerWrapper = styled.div`
  .react-datepicker {
    position: absolute !important;
    z-index: 1000000;

    background: ${(props): string => props.theme.palette.background.paper};

    &__header {
      background: ${(props): string => props.theme.palette.background.paper};

      * {
        color: ${(props): string => props.theme.palette.textColor.default};
      }
    }

    &__day {
      color: ${(props): string => props.theme.palette.textColor.default};

      &--in-range {
        background: ${(props): string => props.theme.palette.primary.main};
      }
    }
  }
`;

export const TextField = styled(MuiTextField)`
  min-width: ${(props): string => props.theme.spacing(53.3)};
`;

export const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
`;
