import React from "react";
import { FormMessageInterface } from "../../../types/form";
import { FormikValues } from "formik/dist/types";
import { EditTenantRequestInitState } from "./types";
import { updateTenantRequest } from "../../../api/tenantRequest";
import { mapBackendValidationErrors } from "../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { touchedInitState } from "../create/utils";

export const editTenantRequestInitState: EditTenantRequestInitState = {
  id: "",
  title: "",
  description: "",
  property_area_id: "",
  tenant_id: "",
  property_id: "",
  manager_id: "",
  file: [],
};

export type FormSubmitHandlerProps = {
  setFormMessage: React.Dispatch<React.SetStateAction<FormMessageInterface>>;
};

export const handleTenantRequestFormSubmit = ({
  setFormMessage,
}: FormSubmitHandlerProps) => {
  return async (
    values: EditTenantRequestInitState,
    { setStatus, setSubmitting, setTouched }: FormikValues
  ): Promise<void> => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("property_area_id", values.property_area_id);
    formData.append("tenant_id", values.tenant_id);
    formData.append("property_id", values.property_id);
    values.manager_id && formData.append("manager_id", values.manager_id);
    formData.append("_method", "PUT");
    const response = await updateTenantRequest(values.id, formData);
    const data = await response.json();
    if (response.status === 200) {
      setStatus({
        success: true,
        errors: {},
        data: data.data,
      });
      setTouched(touchedInitState);
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(data.errors),
      });
      setSubmitting(false);
    } else {
      setStatus({
        success: false,
        errors: SOMETHING_WENT_WRONG_ERROR,
      });
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
      setSubmitting(false);
    }
  };
};
