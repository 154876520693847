import { TableCell } from "../../../../../types/table";
import { NavigateFunction } from "react-router-dom";
import { objectGetParamsToString } from "../../../../../utils/common";
import { getFirstDayOfYear, getLastDayOfYear } from "../../../../../utils/date";
import { MonthDictProps } from "./types";
import {
  RELASE_BUTTONS_AM,
  RELASE_BUTTONS_PM,
  RELEASE_BUTTONS_FR,
  RELEASE_BUTTONS_USER,
} from "../../../utils";
import { route } from "../../../../../utils/url";

export const initInsuranceRange = [getFirstDayOfYear(), getLastDayOfYear()];

export const itemsListHeadCells: TableCell[] = [
  {
    id: "property_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertyName",
  },

  {
    id: "file_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "offers.fileName",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "offers.amount",
    align: "right",
  },
  {
    id: "cancellation_period",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "insurance.cancellationPeriod",
  },
  {
    id: "latest_comment",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "comment",
    align: "left",
  },
  {
    id: "created_by",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "uploadedBy",
    align: "left",
  },
  {
    id: "uploaded_at",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "offers.uploadedOn",
    align: "center",
  },
  {
    id: RELEASE_BUTTONS_FR,
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "property.navLinks.finalReleaser",
    align: "center",
  },
  {
    id: RELASE_BUTTONS_AM,
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "AM",
    align: "center",
  },
  {
    id: RELASE_BUTTONS_PM,
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "PM",
    align: "center",
  },
  {
    id: RELEASE_BUTTONS_USER,
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "User",
    align: "center",
  },
  {
    id: "contact",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "offers.contact",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "",
  },
];
export const navigateInsurancePage = (
  navigate: NavigateFunction,
  type_id: string | null,
  from: string | null,
  to: string | null,
  statusCode: string,
  id?: string
): true => {
  navigate(
    `${getReleaseRoute(
      `insurances.${statusCode}`,
      Number(id),
      type_id!
    )}?${objectGetParamsToString({
      from,
      to,
    })}`
  );
  return true;
};

export const monthDict: MonthDictProps = {
  1: "month",
  3: "months",
};
/* istanbul ignore next */
export const prepareQueryParamsForPropertySearch = (
  queryParams: string,
  obj: Record<string, string | undefined>
): string => {
  const params = new URLSearchParams(queryParams);
  Object.keys(obj)?.forEach((key) => {
    params.delete(key);
    obj[key] && params.append(key, encodeURIComponent(obj[key]!));
  });

  return `?${decodeURIComponent(params.toString())}`;
};

export const getReleaseRoute = (
  path: string,
  propertyId?: number,
  ...rest: string[]
): string => {
  const propertyPathArr = ["", "properties."];
  return route(
    `${propertyPathArr[Number(Boolean(propertyId))]}${path}`,
    ...[propertyId, ...rest].filter((el) => el)
  );
};

export const NEW_STATUS_CODE = "new";

/* istanbul ignore next */
export const mapFESortField = (field: string): string => {
  switch (field) {
    case "file_name":
      return "file.name";
    case "property_name":
      return "property.object_name";
    case "contact":
      return "contact.full_name";
    case "amount":
      return "amount";
    case "created_by":
      return "created_by.first_name";
    case "uploaded_at":
      return "uploaded_at";
    default:
      return field;
  }
};

export const historyModalInitState = {
  isVisible: false,
  historyId: 0,
};
