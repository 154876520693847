import React, { ReactElement, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useFetch } from "../../../../../hooks/useFetch";
import { Property } from "../../../../../types/be/property";
import { getProperty } from "../../../../../api/property";
import Form from "./Form";
import useValidation from "../../../../../hooks/useValidation";
import { handleChangeConfig } from "./utils";
import useSnackbar from "../../../../../ui/snackbar1/useSnackbar";
import { useTranslation } from "react-i18next";

const Config = (): ReactElement => {
  const { id } = useParams();
  const { data, run } = useFetch<Property>();
  const { company_number } = useValidation();
  const { Snackbar, snackbar } = useSnackbar();
  const { t } = useTranslation();
  const handleFetchProperty = (): void => {
    run(getProperty(id));
  };

  useEffect(() => {
    run(getProperty(id));
  }, [id]);

  const initialValues = {
    konto: data?.konto ?? "",
    id: id!,
  };

  return (
    <Grid container justifyContent="center" alignItems="stretch" spacing={4}>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <Card>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              konto: company_number,
            })}
            onSubmit={handleChangeConfig({
              snackbar,
              t,
              onSuccess: handleFetchProperty,
            })}
            enableReinitialize
          >
            {(props) => <Form {...props} />}
          </Formik>
          {Snackbar}
        </Card>
      </Grid>
    </Grid>
  );
};

export default Config;
