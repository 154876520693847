import React, { ReactElement, useCallback, useEffect, useState } from "react";
import Table from "../.././../../../../ui/table/Table";
import useTable from "../../../../../../ui/table/useTable";
import { Box, Grid, Tooltip } from "@mui/material";
import { EMPTY_DATA } from "../../../../../../constants";
import {
  DeleteIcon,
  Input,
  Search,
  SearchIconWrapper,
} from "../../../../../../ui/table/styled";
import { STRING_LENGTH, itemsListHeadCells } from "./utils";
import { handleServerError } from "../../../../../../utils/http";
import { StyledButton } from "../../../../../../ui/button/styled";
import { useTranslation } from "react-i18next";
import { prepareQueryParams } from "../../../../../../utils/common";
import { Search as SearchIcon } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import {
  bulkDelete,
  deleteDebtor,
  getDebtors,
} from "../../../../../../api/debtor";
import { Debtor as DebtorType } from "../../../../../../types/be/debtor";
import HZScrollController from "../../../../../../ui/HZScrollController";
import { FETabs, TABS } from "../../utils";
import { TableCell } from "../../../../../../styled";
import { FileName } from "../../../list/styled";
import DeleteConfirmationModal from "../../../../../../ui/table/DeleteConfirmationModal";
import UploadManuallyDialog from "../../Upload";
import { route } from "../../../../../../utils/url";

const Debtor = (): ReactElement => {
  const { t } = useTranslation();
  const [isDeleteAllModalOpen, setIsDeleteAllModalOpen] = useState(false);
  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleSortChange,
    handleSelectAllClick,
    rowToDelete,
    setTotal,
    handleConfirmToRemoveModalClose,
    handleDeleteLastPageData,
    openConfirmToRemoveModal,
    handleTableSearch,
  } = useTable<DebtorType>();

  const handleFetchList = useCallback(
    async (loading = true) => {
      setIsLoading(loading);
      const payload: Record<string, string | undefined> = {
        property_id: id,
      };
      const params = prepareQueryParams(queryParams, payload);
      const response = await getDebtors(params);

      if (response.ok) {
        const json = await response.json();
        setData(json?.data);
        setTotal(json?.meta?.total);
      } else {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      }
      setIsLoading(false);
    },
    [queryParams, id]
  );

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteDebtor(rowToDelete?.id);
    handleDeleteLastPageData(handleFetchList);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    handleFetchList,
    handleConfirmToRemoveModalClose,
    deleteDebtor,
  ]);

  const deleteAllDebtor = useCallback(async () => {
    const response = await bulkDelete();
    if (response.ok) {
      handleFetchList();
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }
    setIsDeleteAllModalOpen(false);
  }, [bulkDelete]);

  const onUploadSuccess = (): void => {
    setUploadModalVisible(false);
    handleFetchList();
  };

  const TableToolbar = (
    <Grid container sx={{ pt: 5, mb: 2 }}>
      <Grid
        item
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
        flexWrap={"wrap"}
      >
        <Grid item gap={2} display={"flex"} alignItems={"center"}>
          <StyledButton
            color="success"
            variant="contained"
            onClick={() =>
              navigate(
                route(
                  "properties.invoices.apidatev.tab.create",
                  id,
                  TABS.DEBTOR
                )
              )
            }
          >
            {t("create")}
          </StyledButton>
          <StyledButton
            color="success"
            variant="outlined"
            onClick={() => setUploadModalVisible(true)}
          >
            {t("documentRelease.invoice.apidatev.import")}
          </StyledButton>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems={"center"} gap={2}>
            <StyledButton
              color="error"
              variant="contained"
              onClick={() => setIsDeleteAllModalOpen(true)}
              sx={{ whiteSpace: "nowrap", minWidth: "auto", height: "32px" }}
            >
              {t("documentRelease.invoice.apidatev.deleteAll")}
            </StyledButton>
            <HZScrollController />
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t("search")} onChange={handleTableSearch} />
            </Search>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderRow = (row: DebtorType): ReactElement => (
    <>
      <TableCell>
        {row?.konto && row?.konto.length >= STRING_LENGTH ? (
          <Grid container>
            <Grid item>
              <Tooltip title={row?.konto} placement="top">
                <FileName>{row?.konto}</FileName>
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          row.konto || EMPTY_DATA
        )}
      </TableCell>
      <TableCell>{row?.company_name || EMPTY_DATA}</TableCell>
      <TableCell>{row?.addressee_type || EMPTY_DATA}</TableCell>
      <TableCell>{row?.abbreviation || EMPTY_DATA}</TableCell>
      <TableCell>{row?.address_type || EMPTY_DATA}</TableCell>
      <TableCell>{row?.street || EMPTY_DATA}</TableCell>
      <TableCell>{row?.postal_code || EMPTY_DATA}</TableCell>
      <TableCell>{row?.location || EMPTY_DATA}</TableCell>
      <TableCell>{row?.code_correspondence_address || EMPTY_DATA}</TableCell>
      <TableCell>{row?.address_valid_from || EMPTY_DATA}</TableCell>
      <TableCell align="center">
        {row?.user
          ? `${row?.user?.first_name} ${row?.user?.last_name}`
          : EMPTY_DATA}
      </TableCell>
      <TableCell align="right">
        <DeleteIcon
          role="deleteIconRole"
          size={22}
          onClick={() => openConfirmToRemoveModal(row)}
        />
      </TableCell>
    </>
  );

  useEffect(() => {
    queryParams && handleFetchList();
  }, [queryParams, id]);

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        onRowRemove={handleRowRemove}
        refId="hzScroll"
      />
      <DeleteConfirmationModal
        isConfirmToRemoveModalOpen={isDeleteAllModalOpen}
        handleConfirmToRemoveModalClose={() => setIsDeleteAllModalOpen(false)}
        onRowRemove={deleteAllDebtor}
        confirmationText={t(
          "documentRelease.invoice.apidatev.areYouSureYouWantToDeleteAllDebtor"
        )}
      />
      {uploadModalVisible ? (
        <UploadManuallyDialog
          onSuccess={onUploadSuccess}
          visible={uploadModalVisible}
          toggleModel={setUploadModalVisible}
          title={t("documentRelease.invoice.apidatev.importDebitor")}
          type={FETabs.DEBITOR}
        />
      ) : null}
    </>
  );
};

export default Debtor;
