import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import PageWrapper from "../../../ui/pageWrapper/PageWrapper";
import { route } from "../../../utils/url";
import { Formik } from "formik";
import { Grid } from "@mui/material";
import Form from "./form";
import {
  createCounterInitialValues,
  handleCreateMeterSubmit,
  initialSnackbarCredentials,
} from "./utils";
import { Paper } from "./styled";
import { useNavigate } from "react-router-dom";
import { SnackbarMessage } from "./types";
import Snackbar from "../../../ui/Snackbar";
import useValidation from "../../../hooks/useValidation";
import * as Yup from "yup";

const CreateMeter = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    property_id,
    property_area_id,
    number,
    readings: value,
    meterDate: date,
  } = useValidation();

  const [snackbarCredentials, setSnackbarCredentials] =
    useState<SnackbarMessage>(initialSnackbarCredentials);

  const handleCloseSnackbar = (): void =>
    setSnackbarCredentials(initialSnackbarCredentials);

  const breadcrumbsItems = [
    {
      to: route("meter"),
      name: t("meter.title"),
    },
  ];

  return (
    <>
      <PageWrapper
        title={t("counter.create.createMeter")}
        breadcrumbs={breadcrumbsItems}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Paper>
            <Formik
              initialValues={createCounterInitialValues}
              onSubmit={handleCreateMeterSubmit({
                setSnackbarCredentials,
                navigate,
              })}
              validationSchema={Yup.object().shape({
                property_id,
                property_area_id,
                type_id: Yup.string().when("counter_id", {
                  is: (counter_id: string) => !!counter_id,
                  then: () => Yup.string(),
                  otherwise: () => Yup.string().required(t("form.type_id")),
                }),
                number,
                value,
                date,
              })}
            >
              {(props) => <Form {...props} />}
            </Formik>
          </Paper>
        </Grid>
      </PageWrapper>
      <Snackbar
        data-testid={"credentials-snackbar"}
        message={t(snackbarCredentials.text)}
        color={snackbarCredentials.color}
        open={snackbarCredentials.visible}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

export default CreateMeter;
