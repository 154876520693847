import React, { ReactElement, useEffect, useState, useCallback } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { Bank, BankConnection } from "../../../../types/be/bankAccount";
import useSnackbar from "../../../../ui/snackbar1/useSnackbar";
import {
  bankConnectionImport,
  banksAutocomplete,
} from "../../../../api/bankAccount";
import { getJson } from "../../../../utils/http";
import { DEBOUNCE_TIME, prepareQueryParams } from "../../../../utils/common";
import debounce from "lodash/debounce";
import { SEARCH_PARAM_MIN_CHARACTERS } from "../../../../constants";
import { StyledPaper } from "../../../contacts/autocomplete/styled";
import DialogTitle from "../../../../ui/dialog/DialogTitle";

const BankConnectionModal = ({
  isOpen,
  onClose,
  isConnectButtonDisabled,
  handleOnBankSelect,
  onConnect,
}: {
  isOpen: boolean;
  onClose: () => void;
  isConnectButtonDisabled: boolean;
  handleOnBankSelect: (bankConnection: BankConnection) => void;
  onConnect: () => void;
}): ReactElement => {
  const [banks, setBanks] = useState<Bank[]>([]);
  const [loading, setLoading] = useState(false);
  const [bank, setBank] = useState<Bank | null>(null);
  const { Snackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleInputChange = useCallback(async (search: string) => {
    const params = prepareQueryParams("", {
      search,
    });
    const response = await banksAutocomplete(params);
    const { data } = await response.json();
    return data;
  }, []);

  const onSearchFieldTextChange = React.useCallback(
    debounce(async (search: string): Promise<void> => {
      if (search.length < SEARCH_PARAM_MIN_CHARACTERS) setBanks([]);
      else {
        setLoading(true);
        try {
          const response = await handleInputChange(search);
          setBanks([...response]);
          setLoading(false);
        } catch (e) {
          /* istanbul ignore next */
          setBanks([]);
        }
      }
    }, DEBOUNCE_TIME),
    [handleInputChange]
  );

  const handleAutocompleteValueChange = (
    _: React.SyntheticEvent,
    value: Bank | null
  ): void => setBank(value);

  useEffect(() => {
    if (bank) {
      const { id, name } = bank;
      bankConnectionImport({ bank_id: id, bank_connection_name: name })
        .then(getJson)
        .then(({ data }) => {
          handleOnBankSelect(data);
        });
    }
  }, [bank]);

  return (
    <Dialog
      role={"link-bank-account-modal"}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={false}
      PaperProps={{ sx: { maxWidth: "none" } }}
    >
      <>
        <DialogTitle title="bankAccount.chooseBank" onClose={onClose} />
        <DialogContent sx={{ width: "600px" }}>
          <Box sx={{ pt: 2 }}>
            <Autocomplete
              id="bank_id"
              fullWidth
              options={banks}
              loading={loading}
              getOptionLabel={(option: Bank) => `${option.name} ${option.blz}`}
              onChange={handleAutocompleteValueChange}
              PaperComponent={StyledPaper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size={"small"}
                  role="banks-autocomplete-input"
                  label={`${t("search")}...`}
                  onChange={(e): Promise<void> | undefined =>
                    onSearchFieldTextChange(e.target.value)
                  }
                />
              )}
            />
            {Snackbar}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button size="large" onClick={onClose}>
            {t("cancel")}
          </Button>
          <LoadingButton
            role="connect-button"
            onClick={onConnect}
            disabled={isConnectButtonDisabled}
            size="large"
            autoFocus
          >
            {t("connect")}
          </LoadingButton>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default BankConnectionModal;
