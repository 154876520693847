import {
  FIRST_NAME_MAX_CHARACTERS,
  FIRST_NAME_MIN_CHARACTERS,
  LAST_NAME_MIN_CHARACTERS,
  LAST_NAME_MAX_CHARACTERS,
  NOTES_MAX_CHARACTERS,
  OBJECT_NAME_MAX_CHARACTERS,
  OBJECT_NAME_MIN_CHARACTERS,
  PURCHASE_PRICE_MAX_CHARACTERS,
  PURCHASE_PRICE_MIN_CHARACTERS,
  MOBILE_MAX_CHARACTERS,
  ZIP_CODE_LENGTH,
  MINIMUM_YEAR,
  MOBILE_MIN_CHARACTERS,
} from "../constants";
import tenantRequest from "./tenantRequest/en";
import google from "./google/en";
import fileUploader from "../ui/fileUploader/langs/en";
import property from "./property/en";
import documentRelease from "./documentRelease/en";
import settings from "./settings/en";
import transactionManagement from "./transactionManagement/en";
import sidebar from "./sidebar/en";
import comments from "./comments/en";
import profile from "./profile/en";
import vacancies from "./vacancies/en";
import datev from "./datev/en";
import company from "./company/en";

export default {
  translation: {
    sidebar,
    tenantRequest,
    google,
    fileUploader,
    property,
    documentRelease,
    transactionManagement,
    settings,
    comments,
    vacancies,
    profile,
    datev,
    company,
    copied: "Copied",
    copyLink: "Copy link",
    edit: "Edit",
    loading: "Loading",
    deleteAll: "Delete all",
    createContact: {
      createNewContact: "Create New contact",
      searchContact: "Search contact",
      additionalInfo: "Additional Information",
      contactDetails: "Contact Details",
      contactAddress: "Contact Address",
      contactOrCompanyAddress: "Company/ Contact Address",
      finance: "Finance",
      searchProfile: "Search Profile",
      additionalInformation: "Additional information",
      businessPhone: "Business phone",
      businessPhoneOnly: "Business phone is required",
      createCompany: "Create company",
      title: "Title",
      firstName: "First name",
      lastName: "Last name",
      privatePhone: "Private phone",
      miscellaneousPhone: "Miscellaneous phone",
      city: "City",
      company: "Company",
      zipCode: "Zip code",
      street: "Street",
      houseNumber: "House number",
      country: "Country",
      state: "State",
      category: "Category",
      worldwideBranches: "Worldwide Branches",
      branches: "Branches",
      source: "Source",
      ranking: "Ranking",
      assetsInMillion: "Assets in million",
      competentArea: "Competent area",
      websiteUrl: "Website url",
      purchaseProfileUrl: "Purchase profile url",
      management: "Management",
      focus: "Focus",
      note: "Note",
      mobile: "Mobile",
      tags: "Tags",
      companyType: "Company type",
      financeProfiles: "Finance profiles",
      email: "Email",
      cc: "CC",
      bcc: "BCC",
      enterEmail: "Enter Email",
      salutationId: "Salutation",
      oneOfPhoneNumberIsRequired:
        "One of Business phone, Mobile, Private phone or Miscellaneous phone must be entered",
      contactType: "Contact type",
      contactWasSuccessfullyCreated: "Contact was successfully Created!",
      contactWasSuccessfullyUpdated: "Contact was successfully updated!",
      contactWasSuccessfullyDeleted: "Contact was successfully deleted!",
      searchProfileWasSuccessfullyUpdated:
        "Search profile was successfully saved!",
      financeProfileWasSuccessfullyUpdated:
        "Finance profile was successfully saved!",
      companyWasSuccessfullyCreated: "Company Was Successfully Created!",
      createContact: "Create Contact",
    },
    createCompany: {
      createCompany: "Create company",
      companyType: "Company type",
      companyTypeGeneral: "General",
      companyTypePropertyManagement: "Property Management",
    },
    searchProfile: {
      searchProfile: "Search Profile",
      title: "Title",
      expansion: "Expansion",
      typeId: "Type",
      qmFrom: "qm from",
      qmTo: "qm to",
      plotAreaFrom: "Plot area from",
      plotAreaTo: "Plot area to",
      saleAreaFrom: "Sales area from",
      saleAreaTo: "Sales area to",
      rentableAreaFrom: "Rentable area from",
      rentableAreaTo: "Rentable area to",
      officeAreaFrom: "Office area from",
      officeAreaTo: "Office area to",
      practiceAreaFrom: "Practice area from",
      practiceAreaTo: "Practice area to",
      commercialAreaFrom: "Commercial area from",
      commercialAreaTo: "Commercial area to",
      livingAreaFrom: "Living area from",
      livingAreaTo: "Living area to",
      otherAreaFrom: "Other area from",
      otherAreaTo: "Other area to",
      constructionYear: "Construction year",
      inhabitantsFrom: "Inhabitants from",
      inhabitantsTo: "Inhabitants to",
      renovation: "Renovation",
      zipCode: "Zip code",
      notes: "Notes",
      floors: "Floors",
      minSales: "Min sales",
      comment: "Comment",
      bnl: "BNL",
      confirmationTitle:
        "Do you really want to go to the next step without saving?",
      mainRoad: "Main road",
      isHolidayDestination: "Holiday destination",
      source: "Source",
      propertyTypeId: "Property type",
      usageId: "Usage",
      rankingId: "Ranking",
      searchProfileZipCode: `Should be ${ZIP_CODE_LENGTH} characters`,
      cancelEdit: "Cancel Edit",
    },
    financeProfile: {
      state: "State",
      title: "Finance profiles",
      addSearchProfile: "Add Search Profile",
      zipCode: "Zip Code",
      state_id: "State Id",
      financeVolumeFromValue: "Finance volume (min)",
      financeVolumeToValue: "Finance volume (max)",
      ltv: "LTV (max)",
      avgInterestRate: "AVG interest rate (%)",
      avgRepayment: "AVG Repayment",
    },
    form: {
      cancellationPeriod: "Cancellation Period is required",
      releaseAm: "Please select AM Recommendation",
      emailIsRequired: "E-Mail is required",
      insuranceType: "Insurance Type is required",
      firstNameIsRequired: "First name is required",
      firstNameMaxCharacters: `Max length should be no more then ${FIRST_NAME_MAX_CHARACTERS} characters`,
      firstNameMinCharacters: `Min length should be no less then ${FIRST_NAME_MIN_CHARACTERS} characters`,
      lastNameIsRequired: "Last name is required",
      lastNameMaxCharacters: `Min length should be no less then ${LAST_NAME_MAX_CHARACTERS} characters`,
      lastNameMinCharacters: `Max length should be no more then ${LAST_NAME_MIN_CHARACTERS} characters`,
      mobileNumberIsRequired: "Mobile number is required",
      mobileNumberError: `Mobile number should be more than ${MOBILE_MIN_CHARACTERS} characters`,
      mobileNumberMaxError: `Mobile number should be ${MOBILE_MAX_CHARACTERS} characters or less`,
      mustBeValidEmail: "E-Mail is not valid",
      passwordConfirmationIsRequired: "Confirm password",
      passwordIsRequired: "Password is required",
      passwordsMustMatch: "Password confirmation does not mutch",
      phoneNumberIsRequired: "Phone number is required",
      phoneNumberError: `Phone number should be more than ${MOBILE_MIN_CHARACTERS} characters`,
      phoneNumberMaxError: `Phone number should be ${MOBILE_MAX_CHARACTERS} characters or less`,
      salutationIsRequired: "Salutation form is required",
      roleIsRequired: "Please choose a role",
      notesMaxCharacters: `Max length should be no more then ${NOTES_MAX_CHARACTERS} characters`,
      notesIsRequired: "Notes is required",
      contactTypeIsRequired: "Contact type is required",
      contactAddressIsRequired:
        "Please enter the complete address ( city, street, house number).",
      objectNameMaxCharacters: `Max length should be no more then ${OBJECT_NAME_MAX_CHARACTERS} characters`,
      objectNameMinCharacters: `Min length should be no more then ${OBJECT_NAME_MIN_CHARACTERS} characters`,
      objectNameIsRequired: "Object name is required",
      purchasePriceMaxCharacters: `Max length should be no more then ${PURCHASE_PRICE_MAX_CHARACTERS} characters`,
      purchasePriceMinCharacters: `Min length should be no more then ${PURCHASE_PRICE_MIN_CHARACTERS} characters`,
      purchasePriceIsRequired: "Purchase price is required",
      zipCodeIsRequired: "Zip code is required",
      streetIsRequired: "Street is required",
      houseNumberIsRequired: "House number is required",
      stateIsRequired: "State is required",
      financeVolumeFromValueIsRequired: "Finance Volume From Value is required",
      financeVolumeToValueIsRequired: "Finance Volume To Value is required",
      ltvIsRequired: "LTV is required",
      avgInterestRateIsRequired: "avg interest rate is required",
      avgRepaymentIsRequired: "avg repayment is required",
      typeNumberOnly: "you must specify a number",
      propertyTypeIsRequired: "Property type is required",
      contactIsRequired: "Contact is required",
      companyNameIsRequired: "Company name is required",
      companyTypeIsRequired: "Company type is required",
      supervisorIsRequired: "Supervisor is required",
      expensesAreRequired: "Expenses are required",
      taxNumberIsRequired: "Tax number is required",
      vatIsRequired: "Vat is required",
      architectIsRequired: "Architect is required",
      titleIsRequired: "Title is required",
      propertyManagementCostIsRequired: "Property management cost is required",
      cityIsRequired: "City is Required",
      assetManagerIsRequired: "Asset manager is required",
      nameIsRequired: "Name is required",
      zipcodeLengthError: `The zip code must contain ${ZIP_CODE_LENGTH} numbers`,
      descriptionIsRequired: `Description is required`,
      select: "Select",
      placeIdIsRequired: "Place id is required",
      contactIdIsRequired: "Contact is required",
      countryIsRequired: "Country is required",
      propertyLocation: "Property location",
      type_id: "Please select type",
      number: "Number is required",
      bankNameIsRequired: "Bank name is required",
      propertyId: "Please select Property",
      property_id: "Please select Property",
      areaName: "Area name is required",
      area: "Area is required",
      netRentPerMonth: "Net rent per month is required",
      rentStart: "Rent start is required",
      rentEnd: "Rent end is required",
      otherIncomingsEur: "Additional costs is required",
      propertyAreaId: "Please select area",
      readings: "Readings is required",
      invoiceId: "Please select Invoice",
      oldPasswordIsRequired: "Old password is required",
      pleaseSelectYear: "Please select year",
      duplicateYearIsNotAllowed: "Duplicate year is not allowed",
      pleaseEnterHeadline: "Please enter headline",
      contactId: "Contact is required",
      costTypeIsRequired: "Cost type is required",
      contractExpiryDateIsRequired: "Contract expiry date is required",
      contractExpiryDateShouldNotBeGreaterThanContractStartDate:
        "Contract expiry date should not be greater than contract start date",
      kontoIsRequired: "Account is required",
      addresseeTypeCompanyIsRequired:
        "Name (addressee type company) is required",
      addresseeTypeIsRequired: "Addressee type is required",
      abbreviationIsRequired: "Abbreviation is required",
      addressTypeIsRequired: "Address type is required",
      streetRequired: "Street is required",
      locationIsRequired: "Location is required",
      postalCodeIsRequired: "Postal code is required",
      codeCorrespondenceAddressIsRequired:
        "Code Correspondence address is required",
      creditorIsRequired: "Creditor/Biller is required",
      dateOfPerformanceIsRequired: "Date of performance is required",
      bookingTextIsRequired: "Booking text is required",
      amountNettoIsRequired: "Amount netto is required",
      debitAccountIsRequired: "Debit account is required",
      atLeastOneFieldRequired:
        "Please select either of user, contact or enter email.",
      fileShouldBeLessThan5MB: "File should be less than 5MB",
    },
    stepper: {
      optional: "Optional",
    },
    contracts: {
      searchContract: "Search Contract",
      uploadContract: "Upload Contract",
      contract: "Contract",
      contractDetails: "Contract edit",
      objectName: "Property name",
      assetManager: "AM",
      contracts: "Contracts",
      fileName: "File name",
      contractName: "Contract name",
      contactName: "Contact person",
      amount: "Amount",
      createdBy: "Created by",
      startDate: "Start date",
      endDate: "End date",
      signingDate: "Signing date",
      cancellationUpTo: "Cancellation up to",
      canceledAt: "Canceled at",
      category: "Category",
      selectCategory: "Select category",
      oldContract: "Old contract",
      createContract: "Create Contract",
      cancellationDate: "Cancellation up to",
      uploadFile: "Upload Contract",
      editContract: "Edit Contract",
      contractSaveSuccessfully: "Contract saved successfully",
      error: {
        fileId: "Contract File is required",
        contactId: "Contact is required",
        contractCategoryId: "Contact category is required",
        pdfOnly: "Only PDF files are allowed",
      },
      closedAt: "Closed At",
      terminationAtTheLatest: "Termination at the latest",
    },
    optionalColumnsNames: {
      category: "Category",
      cancelled_at: "Canceled at",
      cancellation_date: "Cancellation up to",
      signing_date: "Signing date",
      end_date: "End date",
      release_date: "Release date",
      is_debited_automatically: "is debited automatically",
      is_apportionable: "is Apportionable",
      contract_file_name: "Contract File Name",
      offer_file_name: "Offer File Name",
      is_permanent: "Repeating invoice",
      is_active: "Activate invoice",
      number: "Invoice number",
      due_date: "Due date",
      property_name: "Property name",
      bic: "BIC",
      iban: "IBAN",
      invoice_amount: "Invoice amount",
      invoice_date: "Invoice date",
      invoice_number: "Invoice number",
    },
    table: {
      actions: "Actions",
      active: "Active",
      firstName: "First name",
      fullName: "Full name",
      id: "ID",
      inactive: "Inactive",
      lastName: "Last name",
      permissionToRelease: "Release",
      role: "Role",
      rowsPerPage: "Rows per page",
      status: "Status",
      noDataIsAvailable: "No data is available",
      noUsersAreAvailable: "No users are available",
      noDebitAccountAvailable: "No debit accounts available",
      noInvoicesAreAvailable: "No invoices are available",
      noInsurancesAreAvailable: "No insurances are available",
      noOffersAreAvailable: "No offers are available",
      noContractsAreAvailable: "No contracts are available",
      noRemindersAreAvailable: "No reminders are available",
      noActivitiesAreAvailable: "No activities are available",
      noCompanyOwnershipsAreAvailable: "No company ownerships are available",
      noEmployeeOverviewsAreAvailable: "No employee overviews are available",
      noVacationsAreAvailable: "No vacations are available",
      noVacationRequestsAreAvailable: "No vacation requests are available",
      noContactsAreAvailable: "No contacts are available",
      noPaymentsAreAvailable: "No payments are available",
      noLOIsAreAvailable: "No LOIs are available",
      noPropertiesAreAvailable: "No properties are available",
      noAreasAreAvailable: "No areas are available",
      noInventoryHistoryIsAvailable: "No inventory history is available",
      noLOIHistoryIsAvailable: "No LOI history is available",
      noTenantHistoryIsAvailable: "No tenant history is required",
      noRolesAreAvailable: "No roles are available",
      noTenantRequestsAreAvailable: "No tenant requests are available",
      noCreditorsAreAvailable: "No creditors are available",
      noContractHistoryIsAvailable: "No contract history is available",
      noInvoiceHistoryIsAvailable: "No invoice history is available",
      noInsuranceHistoryIsAvailable: "No insurance history is available",
      noOfferHistoryIsAvailable: "No offer history is available",
    },
    usersList: {
      actions: "Actions",
      active: "Active",
      firstName: "First name",
      id: "ID",
      Inactive: "Inactive",
      lastName: "Last name",
      role: "Role",
      status: "Status",
      permissionToRelease: "Permission to release",
      hasPermissionsToRelease: "Has the permission",
      allUsers: "All users",
      activeUsers: "Active users",
      notActiveUsers: "Not active users",
      createUserTitle: "Create User",
      deleteUserTitle: "Do you really want to delete the user {{userName}} ?",
    },
    propertiesList: {
      propertiesList: "PropertyList",
      create: "Create new property",
      objectName: "Property Name",
      state: "State",
      zip: "ZIP",
      street: "Street",
      propertyType: "Object type",
      rentedArea: "Rented in area",
      totalArea: "Total area",
      rentedOut: `Rented areas`,
      inPercentage: ` in %`,
      netRentPerYear: "Annual net",
      coldRent: "cold rent",
      potencialPerYear: "Potencial",
      perYear: "per year",
      wault: "WAULT",
    },
    insurance: {
      createTopic: "Create Topic",
      selectTopic: "Select insurance topic",
      editTopic: "Edit Topic Title",
      from: "From",
      to: "To",
      liability: "Liability",
      uploadfile: "Upload file",
      building: "Building",
      title: "Insurance",
      name: "Name",
      amount: "Amount",
      cancellationPeriod: "Cancellation Period",
      requestRelease: "Request release",
      amRecommendation: "AM Recommendation",
      createTitle: "Create Insurance",
      createSuccess: "Insurance created successfully",
      month: "month",
      on: "Yes",
      off: "No",
      editSuccess: "Insurance updated successfully",
      editTitle: "Edit Insurance",
      months: "Months",
      recommendation: "Recommendation",
      insurancesPerTable: "Insurances per table",
      insuranceTopicsPerPage: "Insurance Topics per page",
      date: "Insurance Date",
      createTopicTitle: "Create Insurance Topic",
      for: "For",
      period: "For the period",
      until: "to",
    },
    tenant: {
      tenant_type_id: "Type",
      tenant_type_name: "Type",
      contact_name: "Contact name",
      name: "Tenant",
      tenant_id: "Tenant name",
      area_name: "Area name",
      net_rent_per_month: "Net rent per month",
      area: "Area square",
      rent_start: "Start rent date",
      rent_end: "End rent date",
      is_cancelled: "Canceled / not renewed",
      comment: "Comment",
      cancellation_until: "Cancellation until",
      closing_tenant_contract: "Conclusion / rental contract",
      special_cancellation_right: "Special right of cancellation",
      deposit: "Deposit",
      reminder: "Reminder",
      new: "New",
      asset_managers: "Asset manager",
      options: "Options",
      remaining_term: "Remaining term",
      remaining_term_in_eur: "Remaining in EUR",
      usage_name: "Usage",
      tenant_category_name: "Category",
      index: "Indexing",
      net_rent_per_m2: "Net rent per m2",
      total_net_rent_per_year: "Net rent per year",
      total_net_rent_per_month: "Total net rent per month",
      tax: "Tax",
      total_gross_net_rent_per_month: "Total gross per month",
      total_gross_net_rent_per_year: "Total gross per year",
      others_incomings_eur: "Additional costs",
      empty_space: "Empty space",
      empty_space_in_euro: "Empty space in Euro",
      empty_space_since_date: "Empty space since",
      empty_space_at_purchase: "Empty space at purchase",
      addIndividualColumns: "Add individual columns",
      individualColumns: "individual column(s)",
      createArea: "Create Area",
      propertyTenantTypeIsRequired: "Tenant type is required",
      propertyTenantCategoryIsRequired: "Tenant category is required",
      propertyTenantWasSuccessfullyCreated: "Tenant was successfully created",
      pdf: "PDF",
      export: "Export",
      vacancyInSqm: "Vacancy in sqm",
      vacancyInEUR: "Vacancy EUR",
      url: "URL",
      immoscoutUpload: "Immoscout Upload",
      immoweltUpload: "Immowelt Upload",
      ebayUpload: "Ebay Upload",
      commissionedBroker: "Agent commissioned (non-exclusive)",
      unlimited: "Unlimited",
      option: "Options",
      firstTenancy: "Right of first tenancy",
      constOfConversion: "Cost of Conversion",
      rentFree: "Rent free in €",
      parkingLots: "No. of parking lots",
      miscellaneousEUR: "Miscellaneous EUR",
      rentEUR: "Rent EUR/pitch",
      rentalContact: "Rental Contract",
      terminated: "Terminated / After",
      dateBKA: "Date",
      amountBKA: "Amount",
      paymentDateBKA: "Payment Date",
      upload: "Upload",
      chooseAFile: "Choose a file",
      noBKA: "No BKA",
      warning: "warning",
      legalDunning: "Legal dunning",
      installment: "Installment/Cancellation",
      viewFile: "View File",
      wasSuccessfullyUpdated: "was successfully updated",
      tenantList: "Tenant list",
      type: "Type",
      types: "type(s)",
      areaName: "Area name",
      netRentPerMonth: "Net Rent/month",
      areaInM2: "Area in m2",
      startRentDate: "Start rent date",
      startRentDateNeedsToBeSelected: "Please select rent start date first",
      endRentDate: "End rent date",
      canceledOrNotRenewed: "Canceled / not renewed",
      cancellationUntil: "Cancellation until",
      conclusionOrRentalContract: "Conclusion / rental contract",
      specialRightOfCancellation: "Special right of cancellation",
      assetManager: "AM",
      remainingTerm: "Remaining term",
      remaining: "Remaining",
      tenantCategory: "Category",
      indexing: "Indexing",
      netRentPerM2: "Net Rent/m2",
      netRentPerYear: "Net Rent/year",
      totalNetRentPerMonth: "Total net rent per month",
      grossPerMonth: "Gross/month",
      grossPerYear: "Gross/year",
      additionalCosts: "Additional costs",
      additionalCostsPerYear: "Additional costs per year",
      emptySpace: "Empty space",
      emptySpaceInEuro: "Empty space in Euro",
      emptySpaceSince: "Empty space since",
      emptySpaceAtPurchase: "Empty space at purchase",
      ok: "✓",
      cancel: "X",
      error: "error",
      warningTextIsRequired: "Please enter warning text",
      legalTextIsRequired: "Please enter legal text",
      installmentTextIsRequired: "Please enter intstallment text",
      immoscoutRequired: "Please enter immoscout URL",
      immowelRequired: "Please enter immowel URL",
      ebayRequired: "Please enter ebay URL",
      agentCommissionedRequired: "Please enter agent comissioned URL",
      tenantUpdated: "Tenant was successfully updated",
      surface: "Surface",
      vacancyInfo: "Vacancy Info",
      vacancyAds: "Vacancy Ads",
      rentalContract: "Rental Contract",
      bka: "Operating costs statement",
      warnings: "Warnings",
      rentalAgreement: "Rental agreement",
      tenant: "Tenant",
      vacancy: "Empty space",
      tenantsList: "TenantsList",
      confirmation: "Do you want to leave without saving?",
      requests: "Requests",
      readings: "Readings",
      year: "Year",
      bkaTitle: "Utility bill",
      history: {
        history: "History",
        extend: "Extend contract",
        show: "Show edit",
        addFutureTenant: "Add future tenant",
        success: "Future tenant was successfully added",
        updateSuccess: "Future tenant updated successfully",
        futureTenant: "Future tenant",
        pastTenants: "Past tenants",
      },
      dateFuture: "Date is in the future",
      editTenant: "Edit tenant",
      counters: "Counters",
      selectedMoreThenOneTenantPartOne: "and",
      selectedMoreThenOneTenantPartTwo: "more",
      credentialsWasSent: "credentials was send",
      tenantUserForMobileApp: "Tenant users for mobile app",
      tenantContact: "Tenant contact",
      mobileUser: "Mobile User",
      inviteUser: "Invite User",
      inviteUserSuccessfully: "Invite user successfully",
      resendCredentialSuccessfully: "Credential resend successfully",
      invite: "Invite",
      BKAYear: "BKA Year",
      BKACreatedSuccessfully: "BKA created successfully",
      download: "Download",
      reminderSentDate: "Reminder sent date is required",
      tenantContactInformation: "Tenant contact information",
      revenueOverview: "Revenue overview",
      detailsAboutTheRentedArea: "Details about the rented area",
      furtherDetails: "Further details",
      inviteMobileUserLimit: "Limit of invitations has been reached",
      mobileUserText:
        "The mobile app for recording and processing tenant inquiries and providing meter reading information can be used by up to 3 users proposed by the tenants.",
      invoiceDate: "Invoice Date",
      costType: "Cost Type",
      dateOfPayment: "Date of Payment",
      BKAUpdatedSuccessfully: "BKA updated successfully",
      openingCost: "Opening Cost",
    },
    offers: {
      offer: "Offers",
      amount: "Amount",
      createOffer: "Create offer",
      uploadfile: "Upload offer file",
      selectTopic: "Select offer topic",
      uploadPicture: "Upload picture",
      offerSaveSuccessfully: "Offer saved successfully",
      updatedSuccessfully: "Offer updated successfully",
      offersPerTable: "Offers per table",
      topicsPerPage: "Topics per page",
      show: {
        createdBy: "Created by",
        contact: "Contact",
        file: "File",
        amount: "Amount",
        picture: "Picture",
        amStatus: "AM Status",
        frStatus: "FR Status",
        releaseDate: "Release Date",
        assetManager: "Asset Manager",
        finalReleaser: "Final Releaser",
      },
      contact: "Contact",
      fileName: "File name",
      topicId: "Topic ID",
      releaseDate: "Release",
      createdBy: "User",
      add: "Add",
      delete: "Delete",
      reset: "Reset",
      topic: "Topic",
      topicWasSuccessfullyCreated: "Topic was successfully created",
      error: {
        fileId: "Offer File is required",
        contactId: "Contact is required",
        amount: "Amount is required",
        topic: "Offer topic is required",
        pdfOnly: "Only PDF files are allowed",
      },
      date: "Offer date",
      create: "Create Offer Title",
      addOffers: "Add Offer",
      uploadedOn: "Uploaded On",
      providerOffers: {
        providerOffers: "Provider offers",
      },
    },
    type: "Type",
    active: "Active",
    activeInvoice: "Active",
    noDataIsAvailable: "No data is available",
    address: "Address",
    addContacts: "Add Contact",
    analyticsDashboard: "Analytics Dashboard",
    birthday: "Birthday",
    properties: "Properties",
    createRroperties: "Create Rroperties",
    list: "List",
    characters: "characters",
    close: "Close",
    state: "State",
    contactInformation: "Contact Information",
    passwordChange: "Password change",
    contacts: "Contacts",
    files: "Files",
    create: "Create",
    post: "Post",
    createUser: "Create user",
    dashboard: "Dashboard",
    disableFuture: "The date should be in the past",
    setMinYear: `You can specify ${MINIMUM_YEAR} year or later`,
    defaultDashboard: "Circle Dashboard",
    email: "Email",
    emailAddress: "Email Address",
    emailIsRequired: "Email is required",
    english: "English",
    enterYourEmailToResetPassword: "Please create your new password",
    enterYourEmailToResetYourPassword:
      "Enter your email to reset your password",
    errorSomethingWentWrong: "Something went wrong!",
    onlyOneFutureTenant: "There can only be one lease in the future",
    finish: "Finish",
    firstName: "First Name",
    forgotPassword: "Forgot Password",
    german: "German",
    invalidEmailAddress: "Invalid email address",
    lastName: "Last Name",
    mobileNumber: "Mobile number",
    mustBeValidEmail: "Email is invalid",
    name: "Name",
    createdAt: "Created at",
    addedAt: "Added at",
    comment: "Comment",
    uploadedBy: "Uploaded By",
    uploadedAt: "Uploaded at",
    assignTo: "Assign to",
    contact: "Contact",
    total: "Total",
    invoiceName: "Invoice name",
    history: "History",
    invoiceFileName: "Invoice file name",
    newPassword: "New password",
    nextStep: "Next",
    pages: "Pages",
    password: "Password",
    oldPassword: "Old Password",
    passwordConfirmation: "Password confirmation",
    confirmNewPassword: "Confirm new Password",
    passwordConfirmationIsRequired: "Password confirmation is required",
    passwordIsRequired: "Password is required",
    passwordMustBeAtLeast: "Password must be at least",
    passwordsMustMatch: "Passwords must match",
    personalInformation: "Personal Information",
    optionalColumns: "Optional columns",
    phoneNumber: "Phone number",
    profilePicture: "Profile Picture",
    crop: "Crop",
    updateImage: "Update Image",
    profilePictureHintFOrFormat: `For best results, use an image at least 128px by 128px in .jpg format`,
    salutation: {
      label: "Salutation",
      mr: "Mr",
      mrs: "Mrs",
      other: "Other",
    },
    prevStep: "Back",
    reset: "Reset all",
    resetPassword: "Reset Password",
    search: "Search",
    typeToSearch: "Type To Search",
    selected: "Selected",
    sendLink: "Send link",
    sendResetPasswordLink: "Send reset password link",
    signIn: "Sign in",
    signOut: "Sign out",
    somethingWentWrong: "Something went wrong",
    stepperFinishMessage: "You have finished all steps!",
    tag: "Tag",
    update: "Update",
    users: "Users",
    usersPerPage: "Users per page",
    user: {
      employees: "Employee Overview",
      management: "User Management",
      activity: "Activity",
      ownership: "Company Ownership",
      vacationRequests: "Vacation Requests",
    },
    userWasSuccessfullyCreated: "User was successfully created!",
    userWasSuccessfullyUpdated: "User was successfully updated!",
    userWasSuccessfullyAdded: "User was successfully added!",
    phoneNumberOrMobileNumberIsRequired:
      "Phone number or Mobile number is required",
    weHaveMissedYou: "We've missed you!",
    welcomeBack: "Welcome back!",
    weSentTheResetPasswordLinkOnYourEmail:
      "We sent the reset password link on your email",
    delete: "Delete",
    cancel: "Cancel",
    save: "Save",
    deleteConfirmation: "Do you really want to delete?",
    deleteOldUserActivity: "Do you really want to delete old activities?",
    objectDataSheet: {
      dataSheet: "Data sheet",
      title: "Object Data Sheet",
      generalInfo: "General Info",
      propertyManagement: "Property Management",
      bank: "Bank",
    },
    externalCompany: "External Company",
    sessionExpireTitle: "Your session expired",
    sessionExpireContent: "Your session expired, Please login again",
    greaterOrEqual: "must be greater than or equal to",
    smallerOrEqual: "must be less than or equal to",
    wrongPhoneNumberFormat:
      "Wrong number format. Example: +49152901820 or 0049152901820",
    yes: "Yes",
    no: "No",
    pages404: {
      noClient: "Missing Client Name",
      provideClientName: "Please provide a client name in URL",
      pageNotFound: "Page not found",
      pageWasRemoved: "The page you are looking for might have been removed",
      returnToWebsite: "Return to website",
    },
    pages403: {
      noRightsForRoute: "You don't have permissions to view this page",
      contactAdmin: "Contact your administrator for more information",
    },
    home: {
      seeMore: "See more",
      allInvoices: "All Invoices",
      expiringTenants: "Expiring Tenants",
      lockDashboard: "Lock Dashboard",
      allInsurances: "All Insurances",
      allOffers: "All Offers",
      allContracts: "All Contracts",
      bankAccounts: "Bank accounts",
    },
    widgets: {
      users: "Users",
      areaRented: "Area rented",
      netRent: "Net rent",
      potential: "Potential",
      contacts: "Contacts",
      expiringTenants: "Expiring tenants",
      invoices: "Invoices",
      insurances: "Insurances",
      offers: "Offers",
      tenantTickets: "Requests",
      contracts: "Contracts",
      properties: "Properties",
      total: "Total",
      comments: "Comments",
      bankAccountPayments: "Bank account payments",
      accounts: "Accounts",
      apiDATEvWidget: "API-DATEv",
    },
    provider: "Provider",
    date: "Date",
    dueDate: "Due date",
    propertyName: "Property name",
    amount: "Amount",
    netRentalIncome: "Potential net rental income per month",
    until: "Until",
    createdBy: "Created by",
    releaseDate: "Release date",
    debit: "Debit",
    apportionable: "Apportionable",
    permanent: "Permanent",
    contractFileName: "Contract file name",
    offerFileName: "Offer file name",
    startTyping: "Start typing to see options",
    searchUser: "Search User",
    send: "Send",
    areaRented: "Area rented",
    netRent: "Net rent",
    potential: "Potential",
    average: "Average",
    invoices: "Invoices",
    outgoingInvoice: "Outgoing invoices",
    noOptions: "No options",
    rename: "Rename",
    createFolder: "Create folder",
    moveTo: "Move to",
    uploadFile: "Upload file",
    download: "Download",
    remove: "Remove",
    gotIt: "Got it",
    notifications: {
      markAsUnread: "Mark as unread",
      markAsRead: "Mark as read",
      markAllAsRead: "Mark all as read",
      youHaveReadAllYourNotifications:
        "You've read all your notificationSettings",
    },
    fileManagement: {
      title: "File Management",
      folderWasSuccessfullyCreated: "Folder was successfully created",
      nameWasSuccessfullyUpdated: "Name was successfully updated",
      itemWasSuccessfullyDeleted: "Item was successfully deleted",
      itemWasSuccessfullyMoved: "Item was successfully moved",
      fileWasSuccessfullyUploaded: "File was successfully uploaded",
      startingFileDownload: "Starting file download",
      dropFilesHere: "Drop the files here ...",
      dragAndDropSomeFilesHere:
        "Drag 'n' drop some files here, or click to select files",
      fileTypeIsNotSupported: "File type is not supported",
      clickToDownloadArchive: "Click to download archive.",
      fileIsDownloading: "File is downloading...",
      list: {
        fileName: "File name",
        size: "Size",
        type: "Type",
        updatedAt: "Updated at",
        newFolder: "New folder",
        folder: "Folder",
      },
    },
    footer: {
      imprint: "Imprint",
      privacyTitle: "Data protection",
      privacy: "Privacy",
    },
    export: {
      pdf: "Export PDF",
      excel: "Export Excel",
    },
    from: "From",
    to: "To",
    counter: {
      counter: "Counter",
      heatingNumber: "Heating number",
      reading: "Reading",
      updatedBy: "Updated by",
      deleteConfirmation: "Are you sure you want to delete?",
      noReadings: "No readings are available",
      sidebarTitle: "Meter",
      meter: "Meter",
      penultimateReading: "Penultimate meter reading",
      penultimateReadingDate: "Date of penultimate meter reading",
      lastReading: "Last meter reading",
      lastReadingDate: "Date of last meter reading",
      unit: "Unit",
      searchCounter: "Search counter",
      searchProperty: "Search Property",
      searchArea: "Search Area",
      searchType: "Type",
      meterNumber: "Meter number",
      penultimateMeterReading: "Penultimate meter reading",
      create: {
        createCounter: "Create counter",
        numberOfHeating: "Number of heating",
        heatingCounter: "Heating Counter",
        dateOfReading: "Date of reading",
        heatingRequired: "Heating number is required",
        heatingValueRequired: "Heating value is required",
        createdSuccess: "Counter reading added successfully",
        number: "Number",
        sucessMessage: "Counter created successfully",
        createMeter: "Create meter",
        meterNumber: "Meter Number",
        searchMeterNumber: "Search Meter Number",
        meterReadings: "Meter Readings",
      },
      readings: {
        createdBy: "Created By",
        date: "Date",
        value: "Value",
        action: "Action",
        create: "Create reading",
      },
      images: "Images",
      counterId: "Counter ID",
      selectPropertyFirst: "Please select property",
    },
    requests: {
      title: "Title",
      description: "Description",
      status: "Status",
      all: "All",
      sendCredentialsSuccess: "Credentials are sent successfully",
      create: {
        createRequest: "Create request",
        createdSuccess: "Request added successfully",
        title: "Title",
        description: "Description",
      },
      edit: {
        editRequest: "Edit request",
        editdSuccess: "Request updated successfully",
      },
      history: {
        title: "Update History",
        name: "Name",
        updatedAt: "Updated At",
      },
    },
    employeeOverview: {
      newEntry: "New entry",
      locationOfficeManagement: "Location management",
      vacationHolidaysManagement: "Public holidays management",
      previous: "Previous",
      next: "Next",
      hide: "Hide",
      locationName: "Location name",
      createLocation: "Create location",
      editLocation: "Edit location",
      user: "User",
      hideEmployee: "Hide Employee",
      vacationHolidayWasSuccessfullyCreated:
        "Public holiday Was Successfully Added",
      newScheduleWasSuccessfullyCreated:
        "New schedule was successfully created",
      vacationHolidayUserSelectionValidation: "Please select user",
      allLocations: "All locations",
      errortheNameFieldIsRequired: "The name field is required.",
      employees: "Employees",
      description: "Description",
      locationOffice: "Location / Office",
      locationOfficeIsRequired: "Location / Office is required",
      homeOffice: "Home office",
      newEntryWasSuccessfullyCreated: "New entry was successfully created",
      scheduleWasSuccessfullyUpdated: "Schedule was successfully updated",
      fieldService: "Field service",
      createdAt: "Created at",
      roles: "Roles",
      allRoles: "All roles",
      vacationHolidayDatesRequired: "Please select date",
      federalState: "Federal state",
      year: "Year",
      noUsersAttachedToCurrentRole: "No users attached to current role",
    },
    companyOwnership: {
      title: "Company Ownership",
      brand: "Brand",
      type: "Type",
      licensePlateSerialNumber: "license plate / serial number",
      employeeName: "Employee name",
      receivedOnDate: "Received on date",
      whoAdded: "Who added",
      createdDate: "Created at",
      user: "User",
      operation: "Operation",
      inventoryHistory: "Inventory History",
      itemTypeIsRequired: "Item type is required",
      brandIsRequired: "Brand is required",
      departmentIsRequired: "Department is required",
      licensePlateSerialNumberIsRequired:
        "License plate or Serial Number is required",
      receivedOnDateIsRequired: "Received on date is required",
      inventoryItemWasSuccessfullyCreated:
        "Inventory item was successfully created",
      inventoryItemWasSuccessfullyUpdated:
        "Inventory item was successfully updated",
      department: "Department",
      addEmployee: "Add employee",
      employeeRemovedSuccessfully: "Employee removed successfully",
      createCompanyOwnership: "Add company item",
      continueCreatingInventory: "Do you want to create more inventory items?",
    },
    vacationRequests: {
      vacationList: "Vacation List",
      approvalProcess: "Approval Process",
      managingVacationLengths: "Managing Vacation Lengths",
      vacationDuration: "Vacation duration",
      daysRemaining: "Days remaining",
      accepted: "accepted",
      rejected: "rejected",
      statusWasUpdated: "Status was updated!",
      allStatuses: "All statuses",
      user: "User",
      vacationRequestsWasCreatedSuccessfully:
        "Vacation request was created successfully!",
      vacationRequestsWasUpdatedSuccessfully:
        "Vacation request was updated successfully!",
      youWillHave: "you will have",
      daysRemainingSmall: "days remaining",
      totalVacationDays: "Total vacation days",
      totalVacationDaysIsRequired: "Total vacation days is required",
      totalVacationWasChangedSuccessfully:
        "Total vacation was changed successfully",
    },
    bankAccount: {
      chooseBank: "Choose a bank",
      attachPropertyToBankAccount: "Attach property to bank account",
      assignPaymentToArea: "Assign payment to area",
      description: "Description",
      sender: "Sender",
      isIgnored: "Ignored",
      bankName: "Bank name",
      bankAccount: "Bank account",
      bookingDate: "Booking date",
      title: "Bank Accounts",
      allSales: "All sales",
      assignProperty: "Assign Property",
      searchProperty: "Search Property",
      propertyAssignedSuccessfully: "Property assigned successfully",
      linkNewBankAccount: "Link a new bank account",
      addAccountToAnExistingBank: "Add account to an existing bank",
      importSales: "Import sales",
      deleteBankAccount: "Delete bank account",
      proposals: "Proposals",
      notAssigned: "Not assigned",
      assigned: "Assigned",
      automaticallyAssigned: "Automatically assigned",
      ignored: "Ignored",
      downloadSalesExampleFile: "Download sales example file",
      assign: "Assign",
      paymentDescription: "Payment Description",
      paymentSender: "Payment Sender",
      checkboxForIgnoringPayment: "Checkbox For Ignoring Payment",
      amount: "Amount",
      assignPayment: "Assign Payment",
      assignPaymentListTitle: "Assign Area & Tenant",
      rentedArea: "Rented area",
      options: {
        assignProperty: "Assign Property",
        importSales: "Import Sales",
        downloadSalesSampleFile: "Download Sales Sample File",
        changeAccessData: "Change access data",
        editBankAccountName: "Edit Bank Account Name",
        delete: "Delete",
      },
      create: {
        manual: "Manual",
        createDialogTitle: "Link bank account",
        whatDoYouWantToDo: "What do you want to do?",
        LinkANewBankAccount: "Link a new bank account",
        AddAccountToAnExistingBank: "Add account to an existing bank",
        WhichBankIsYourAccountWith: "Which bank is your account with?",
        SelectBankAccountForWhichYouWantToAddAnAccount:
          "Select a bank account for which you want to add an account",
        myAccount: "My account",
        goBack: "Back",
      },
      accountBalance: "Account balance",
      paymentDate: "Payment date",
      assignAreaToPayment: "Assign area to payment",
      incomingPayment: "Incoming payment",
      payment: "Payment",
    },
    bankAccounts: "Bank Accounts",
    userActivity: {
      dateAndTime: "Date and time",
      allTypes: "All types",
      activity: "User Activity",
    },
    number: "Invoice number",
    swot: {
      swot: "SWOT",
      strength: "Strength",
      weakness: "Weakness",
      opportunities: "Opportunities",
      threads: "Threads",
    },
    location: {
      analysis: "Location analysis",
    },
    connect: "Connect",
    areas: "Areas",
    area: "Area",
    areaName: "Area name",
    typeError: "Please enter a valid number",
    minPercent: "Minimum at least 0",
    maxPercent: "Allowed maximum is 100",
    releaseMaxCharacter: "Allowed maximum character is 15",
    assign: "Assign",
    snackbar: {
      updated: "Updated",
      imported: "Imported",
      somethingWentWrong: "Something went wrong",
    },
    importContacts: "Import contacts",
    or: "or",
    browseFile: "Browse file",
    chooseAFile: "Choose a file",
    upload: "Upload",
    statusButtons: {
      invoices: {
        new: "New Invoices",
        pending: "Invoices under review",
        finalReleased: "Released Invoices",
        rejected: "Rejected Invoices",
        forwardedToReleaser: "Forwarded to releaser",
        forwarded: "Forwarded",
        history: "History",
      },
      contracts: {
        new: "New Contracts",
        pending: "Contracts under review",
        finalReleased: "Released Contracts",
        rejected: "Rejected Contracts",
        existingOldContracts: "Existing old contracts",
        forwardedToReleaser: "Forwarded to releaser",
        oldContracts: "Old Contracts",
        forwarded: "Forwarded",
        history: "History",
      },
      offers: {
        new: "New Offers",
        pending: "Offers under review",
        finalReleased: "Released Offers",
        rejected: "Rejected Offers",
        forwardedToReleaser: "Forwarded to releaser",
        forwarded: "Forwarded",
        history: "History",
      },
      insurances: {
        building: "Building",
        liability: "Liability",
        new: "New Insurances",
        pending: "Insurances under review",
        finalReleased: "Released Insurances",
        rejected: "Rejected Insurances",
        forwardedToReleaser: "Forwarded to releaser",
        forwarded: "Forwarded",
        history: "History",
      },
    },
    inbox: {
      title: "Inbox",
      complete: "Complete",
      incomplete: "Incomplete",
      addedBy: "Added by",
      forwardTitle: "Forward Inbox",
      forward: {
        forward: "Forward",
        title: "Forward Inbox",
        subject: "Subject",
        recipientEmail: "Recipient Email",
        text: "Text",
        includeComments: "Include Comments",
        success: "Inbox is forwarded successfully",
        validation: {
          subject: "Please enter subject",
          message: "Please enter text",
        },
      },
      create: {
        title: "Create",
        inboxCreatedSuccessfully: "Inbox created successfully!",
        fileRequired: "Please select file",
        pdfFileType: "The file must be pdf",
        remove: "Remove",
      },
      propertyRemovedSuccessfully: "Property removed successfully",
      dataType: "Data type",
      pleaseSelectProperty: "Please select property",
      pleaseMoveToProgress: 'To be able to edit, please move it to "Progress"',
      selectOrCreateInsuranceTopic: "Select or Create Insurance Topic",
      selectTopic: "Select Topic",
      orTextInCapitalLetters: "OR",
      all: "All",
      general: "General",
      providerWasAdded: "Provider was added successfully",
      invoiceAmount: "Invoice Amount",
      assignTitle: "Assign",
      reassignTitle: "Change",
    },
    meter: {
      title: "Meter",
      unit: "Unit",
      number: "Number",
      meterDateIsRequired: "Date is required",
    },
    openInNewTab: "Open in a new tab",
    tenantRequests: {
      title: "Tenant requests",
      userName: "User Name",
      new: "New",
      inProgress: "In progress",
      completed: "Completed",
      inCoordination: "In coordination",
      invitationSentOn: "Invitation sent on",
      assetManagerUserName: "Assigned Asset Manager",
      unassignedTenantRequest: "Unassigned Tenant Request",
      outdated: "Outdated",
    },
    hr: "HR",
    assignedUser: "Assigned User",
    manager: "Manager",
    resetPasswordEmailWasSend:
      "Reset password link was already sent to your email",
    forwardToAdmin: "Forward to admin",
    reminder: {
      title: "Reminders",
      create: {
        reminderCreatedSuccessfully: "Reminder created successfully",
        title: "Upload reminder",
      },
      update: {
        reminderUpdatedSuccessfully: "Reminder updated successfully",
      },
      relationIdRequired: "Invoice is required",
      status: {
        new: "New reminders",
        completed: "Completed reminders",
      },
      invoiceRemovedSuccessfully: "Invoice removed successfully",
      assignInvoice: "Assign Invoice",
      createReminder: "Upload Reminder",
      reminderSentDate: "Reminder Sent Date",
      reminderComplete: "Reminder Complete",
    },
    pdfViewer: {
      noPdfError: "NO PDF TO PREVIEW",
    },
    seeMore: "See more",
    seeLess: "See less",
    seeAllComments: "See All Comments",
    leaveComment: "Leave a comment...",
    message: "Message...",
    start: "Start",
    typing: "typing",
    fileFormateMustBe: "File Format must be",
    fileSizeMustBe: "File size must be",
    chats: "Chats",
    uploading: "Uploading...",
    invalidDate: "Please enter valid Date",
    invalidYear: "Please enter valid Year",
    lastRenovated: "Last renovated",
    floor: "Floor",
    scrollLeft: "Scroll left",
    scrollRight: "Scroll right",
    months: {
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
    },
    providerInfoTable: {
      name: "Name",
      companyName: "Company name",
      email: "E-mail",
      address: "Address",
      businessPhone: "Business phone",
    },
    forward: "Forward",
    fileSize100MB: "File size must be less than 100 MB",
    fileLimit10Images: "Maximum 10 images are allowed",
    fileLimit10Video: "Maximum 10 video is allowed",
    minImagePixelSize: "Minimum image size 400x255 is required",
    generalDocuments: {
      title: "General documents",
      generalDocumentStatusSuccessfullyUpdated:
        "General document status successfully updated",
      complete: "Complete",
      incomplete: "Incomplete",
      addedBy: "Added by",
      forwardTitle: "Forward general document",
      forward: {
        forward: "Forward",
        title: "Forward general document",
        subject: "Subject",
        recipientEmail: "Recipient Email",
        text: "Text",
        includeComments: "Include Comments",
        success: "General document is forwarded successfully",
        validation: {
          subject: "Please enter subject",
          message: "Please enter text",
        },
      },
      create: {
        title: "Create",
        generalDocumentCreatedSuccessfully:
          "General document created successfully!",
        fileRequired: "Please select file",
        pdfFileType: "The file must be pdf",
        remove: "Remove",
      },
      propertyRemovedSuccessfully: "Property removed successfully",
      propertyAssignedSuccessfully: "Property was assigned successfully",
      dataType: "Data type",
      pleaseSelectProperty: "Please select property",
      pleaseMoveToProgress: 'To be able to edit, please move it to "Progress"',
      selectOrCreateInsuranceTopic: "Select or Create Insurance Topic",
      selectTopic: "Select Topic",
      orTextInCapitalLetters: "OR",
      all: "All",
      general: "General",
      providerWasAdded: "Provider was added successfully",
      invoiceAmount: "Invoice Amount",
      assignTitle: "Assign",
      reassignTitle: "Re-Assign",
      assignUsers: "Assign users",
      assignedUsers: "Assigned users",
      userIsAmOfTheProperty: "User is AM of the property",
    },
  },
};
