import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageViewTypes } from "../../../components/properties/list/types";

export interface PropertyState {
  layout: PageViewTypes;
}

const initialState: PropertyState = {
  layout: PageViewTypes.LIST,
};

export const propertySlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changePropertyLayout: (
      state: PropertyState,
      action: PayloadAction<PageViewTypes>
    ): void => {
      state.layout = action.payload;
    },
  },
});

export const { changePropertyLayout } = propertySlice.actions;

export default propertySlice.reducer;
