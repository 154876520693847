import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Measurement_Units } from "../../../constants";
import {
  //Tenant,
  TenantActive } from "../../../types/models";

export interface TenantState {
  tenantTypeIds: number[];
  additionalFields: TenantAdditionalField[];
}

export interface TenantAdditionalField {
  name: keyof TenantActive;
  //name: keyof Tenant;
  visible: boolean;
  label: string;
  type: TenantAdditionalFieldType;
  readonly editable: boolean;
  unit?: Measurement_Units.EURO | Measurement_Units.SQUARE_METER;
  isDisablePast?: boolean;
  isNotRentedEditable?: boolean;
}

export type TenantAdditionalFieldType =
  | "text"
  | "date"
  | "checkbox"
  | "select"
  | "number";

export const additionalFieldsInitState: TenantAdditionalField[] = [
  {
    name: "cancellation_until",
    visible: false,
    label: "tenant.cancellation_until",
    type: "date",
    editable: true,
  },
  {
    name: "closing_tenant_contract",
    visible: false,
    label: "tenant.closing_tenant_contract",
    type: "date",
    editable: true,
  },
  {
    name: "special_cancellation_right",
    visible: false,
    label: "tenant.special_cancellation_right",
    type: "date",
    editable: true,
    isDisablePast: true,
  },
  {
    name: "deposit",
    visible: false,
    label: "tenant.deposit",
    type: "number",
    editable: true,
    unit: Measurement_Units.EURO,
  },
  {
    name: "reminder",
    visible: false,
    label: "tenant.reminder",
    type: "checkbox",
    editable: true,
  },
  {
    name: "new",
    visible: false,
    label: "tenant.new",
    type: "checkbox",
    editable: true,
  },
  {
    name: "asset_managers",
    visible: false,
    label: "tenant.asset_managers",
    type: "select",
    editable: true,
  },
  {
    name: "tenant_id",
    visible: false,
    label: "tenant.tenant_id",
    type: "select",
    editable: true,
  },
  {
    name: "options",
    visible: false,
    label: "tenant.options",
    type: "select",
    editable: true,
  },
  {
    name: "remaining_term",
    visible: false,
    label: "tenant.remaining_term",
    type: "text",
    editable: false,
  },
  {
    name: "remaining_term_in_eur",
    visible: false,
    label: "tenant.remaining_term_in_eur",
    type: "number",
    editable: false,
    unit: Measurement_Units.EURO,
  },
  {
    name: "usage_id",
    visible: false,
    label: "tenant.usage_name",
    type: "select",
    editable: true,
  },
  {
    name: "tenant_category_id",
    visible: false,
    label: "tenant.tenant_category_name",
    type: "select",
    editable: true,
  },
  {
    name: "index",
    visible: false,
    label: "tenant.index",
    type: "text",
    editable: true,
  },
  {
    name: "net_rent_per_m2",
    visible: false,
    label: "tenant.net_rent_per_m2",
    type: "number",
    editable: false,
    unit: Measurement_Units.EURO,
  },
  {
    name: "total_net_rent_per_year",
    visible: false,
    label: "tenant.total_net_rent_per_year",
    type: "number",
    editable: false,
    unit: Measurement_Units.EURO,
  },
  {
    name: "total_net_rent_per_month",
    visible: false,
    label: "tenant.total_net_rent_per_month",
    type: "number",
    editable: false,
    unit: Measurement_Units.EURO,
  },
  {
    name: "tax",
    visible: false,
    label: "tenant.tax",
    type: "number",
    editable: true,
    unit: Measurement_Units.EURO,
  },
  {
    name: "total_gross_net_rent_per_month",
    visible: false,
    label: "tenant.total_gross_net_rent_per_month",
    type: "number",
    editable: false,
    unit: Measurement_Units.EURO,
  },
  {
    name: "total_gross_net_rent_per_year",
    visible: false,
    label: "tenant.total_gross_net_rent_per_year",
    type: "number",
    editable: false,
    unit: Measurement_Units.EURO,
  },
  {
    name: "others_incomings_eur",
    visible: false,
    label: "tenant.others_incomings_eur",
    type: "number",
    editable: true,
    unit: Measurement_Units.EURO,
  },
  {
    name: "empty_space",
    visible: false,
    label: "tenant.empty_space",
    type: "number",
    editable: false,
    unit: Measurement_Units.SQUARE_METER,
  },
  {
    name: "empty_space_in_euro",
    visible: false,
    label: "tenant.emptySpaceInEuro",
    type: "number",
    editable: false,
    unit: Measurement_Units.EURO,
  },
  {
    name: "empty_space_since_date",
    visible: false,
    label: "tenant.empty_space_since_date",
    type: "date",
    editable: false,
  },
  {
    name: "empty_space_at_purchase",
    visible: false,
    label: "tenant.empty_space_at_purchase",
    type: "checkbox",
    editable: true,
  },
];

const initialState: TenantState = {
  tenantTypeIds: [],
  additionalFields: additionalFieldsInitState,
};

/* istanbul ignore next */
export const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    setTenantTypeIds: (
      state: TenantState,
      action: PayloadAction<number[]>
    ): void => {
      state.tenantTypeIds = action.payload;
    },
    setAdditionalFields: (
      state: TenantState,
      action: PayloadAction<TenantAdditionalField[]>
    ): void => {
      state.additionalFields = action.payload;
    },
  },
});

export const { setTenantTypeIds, setAdditionalFields } = tenantSlice.actions;

export default tenantSlice.reducer;
