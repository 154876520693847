import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import { Search as SearchIcon } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  bulkDelete,
  deleteCreditor,
  getCreditors,
} from "../../../../../../api/creditor";
import useTable from "../../../../../../ui/table/useTable";
import Table from "../../../../../../ui/table/Table";
import { HTTP_STATUS_CODES } from "../../../../../../types/server";
import { handleServerError } from "../../../../../../utils/http";
import { EMPTY_DATA } from "../../../../../../constants";
import {
  DeleteIcon,
  Input,
  Search,
  SearchIconWrapper,
} from "../../../../../../ui/table/styled";
import { DATE_TIME_FORMAT, STRING_LENGTH, itemsListHeadCells } from "./utils";
import { route } from "../../../../../../utils/url";
import { TextEllipsis } from "./styled";
import { prepareQueryParams } from "../../../../../../utils/common";
import { formatTimestamp } from "../../../../../../utils/date";
import { Creditor } from "../../../../../../types/be/creditor";
import HZScrollController from "../../../../../../ui/HZScrollController";

import { TableCell } from "./styled";
import DeleteConfirmationModal from "../../../../../../ui/table/DeleteConfirmationModal";
import { FETabs, TABS } from "../../utils";
import UploadManuallyDialog from "../../Upload";
import { StyledButton } from "../../../../../../ui/button/styled";

const CreditorList = (): ReactElement => {
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleDeleteLastPageData,
  } = useTable<Creditor>();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
  const [isDeleteAllModalOpen, setIsDeleteAllModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    queryParams && fetchCreditor();
  }, [queryParams]);

  const fetchCreditor = useCallback(async () => {
    setIsLoading(true);

    const params = prepareQueryParams(queryParams, {
      property_id: id,
    });

    const res = await getCreditors(params);
    if (res.status !== HTTP_STATUS_CODES.OK) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const resJson = await res.json();
      setData(resJson.data);
      setTotal(resJson.meta.total);
      setIsLoading(false);
    }
  }, [queryParams, setIsLoading, setTotal, setData, setIsLoading]);

  const deleteAllCreditor = useCallback(async () => {
    const response = await bulkDelete();

    if (response.ok) {
      fetchCreditor();
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }

    setIsDeleteAllModalOpen(false);
  }, [bulkDelete]);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteCreditor(rowToDelete.id);
    handleDeleteLastPageData(fetchCreditor);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteCreditor,
    fetchCreditor,
    handleConfirmToRemoveModalClose,
  ]);

  const onUploadSuccess = (): void => {
    setUploadModalVisible(false);
    fetchCreditor();
  };

  const renderRow = (row: Creditor): ReactElement => {
    return (
      <>
        <TableCell align="left" mw="130px">
          {row.user
            ? `${row.user?.first_name} ${row.user?.last_name}`
            : EMPTY_DATA}
        </TableCell>
        <TableCell align="left">
          {row?.konto && row?.konto.length >= STRING_LENGTH ? (
            <>
              <Grid container>
                <Grid item>
                  <Tooltip title={row?.konto} placement="top">
                    <TextEllipsis>{row?.konto}</TextEllipsis>
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          ) : (
            row.konto || EMPTY_DATA
          )}
        </TableCell>
        <TableCell align="left">
          {row?.company_name && row?.company_name.length >= STRING_LENGTH ? (
            <>
              <Grid container>
                <Grid item>
                  <Tooltip title={row?.company_name} placement="top">
                    <TextEllipsis>{row?.company_name}</TextEllipsis>
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          ) : (
            row?.company_name ?? EMPTY_DATA
          )}
        </TableCell>
        <TableCell align="left">{row.addressee_type ?? EMPTY_DATA}</TableCell>
        <TableCell align="left">{row.address_type ?? EMPTY_DATA}</TableCell>
        <TableCell align="left">
          {row?.abbreviation && row?.abbreviation.length >= STRING_LENGTH ? (
            <>
              <Grid container>
                <Grid item>
                  <Tooltip title={row?.abbreviation} placement="top">
                    <TextEllipsis>{row?.abbreviation}</TextEllipsis>
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          ) : (
            row.abbreviation ?? EMPTY_DATA
          )}
        </TableCell>
        <TableCell align="left">
          {row?.street && row?.street.length >= STRING_LENGTH ? (
            <>
              <Grid container>
                <Grid item>
                  <Tooltip title={row?.street} placement="top">
                    <TextEllipsis>{row?.street}</TextEllipsis>
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          ) : (
            row?.street ?? EMPTY_DATA
          )}
        </TableCell>
        <TableCell align="left">{row.postal_code ?? EMPTY_DATA}</TableCell>
        <TableCell align="left">
          {row?.location && row?.location.length >= STRING_LENGTH ? (
            <>
              <Grid container>
                <Grid item>
                  <Tooltip title={row?.location} placement="top">
                    <TextEllipsis>{row?.location}</TextEllipsis>
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          ) : (
            row?.location ?? EMPTY_DATA
          )}
        </TableCell>
        <TableCell align="left">
          {row.code_correspondence_address ?? EMPTY_DATA}
        </TableCell>
        <TableCell align="center">
          {row?.address_valid_from
            ? formatTimestamp(row.address_valid_from, DATE_TIME_FORMAT)
            : EMPTY_DATA}
        </TableCell>
        <TableCell
          align="center"
          onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
            handleCellClick(e)
          }
          style={{ whiteSpace: "nowrap" }}
        >
          <DeleteIcon
            role={"deleteIconRole"}
            onClick={(): void => openConfirmToRemoveModal(row)}
            size={22}
            data-testid="delete-icon"
          />
        </TableCell>
      </>
    );
  };

  const TableToolbar = (
    <Grid container sx={{ pt: 5, mb: 2 }}>
      <Grid
        item
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
        flexWrap={"wrap"}
      >
        <Grid item gap={2} display={"flex"} alignItems={"center"}>
          <StyledButton
            color="success"
            variant="contained"
            onClick={() =>
              navigate(
                route(
                  "properties.invoices.apidatev.tab.create",
                  id,
                  TABS.CREDITOR
                )
              )
            }
          >
            {t("create")}
          </StyledButton>
          <StyledButton
            color="success"
            variant="outlined"
            onClick={() => setUploadModalVisible(true)}
          >
            {t("documentRelease.invoice.apidatev.import")}
          </StyledButton>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems={"center"} gap={2}>
            <StyledButton
              color="error"
              variant="contained"
              sx={{ whiteSpace: "nowrap", minWidth: "auto", height: "32px" }}
              onClick={() => setIsDeleteAllModalOpen(true)}
            >
              {t("documentRelease.invoice.apidatev.deleteAll")}
            </StyledButton>
            <HZScrollController />
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t("search")} onChange={handleTableSearch} />
            </Search>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <React.Fragment>
        <Grid container spacing={0} columns={12}>
          <Table
            data={data}
            total={total}
            currentPage={currentPage}
            order={order}
            orderBy={orderBy}
            error={error}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
            onRowRemove={handleRowRemove}
            onSortChange={handleSortChange}
            onSelectAllClick={handleSelectAllClick}
            onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
            isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
            listHeadCells={itemsListHeadCells}
            renderRow={renderRow}
            tableToolbar={TableToolbar}
            noDataIsAvailablePlaceholder={"table.noCreditorsAreAvailable"}
            refId="hzScroll"
          />
          <DeleteConfirmationModal
            isConfirmToRemoveModalOpen={isDeleteAllModalOpen}
            handleConfirmToRemoveModalClose={() =>
              setIsDeleteAllModalOpen(false)
            }
            onRowRemove={deleteAllCreditor}
            confirmationText={t(`datev.creditor.deleteAllConfirmationMessage`)}
          />
        </Grid>
      </React.Fragment>
      {uploadModalVisible ? (
        <UploadManuallyDialog
          onSuccess={onUploadSuccess}
          visible={uploadModalVisible}
          toggleModel={setUploadModalVisible}
          title={t("documentRelease.invoice.apidatev.importCreditor")}
          type={FETabs.CREDITOR}
        />
      ) : null}
    </>
  );
};

export default CreditorList;
