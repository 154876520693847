import React, { ReactElement, useEffect, useRef, useState } from "react";
import Form from "../Form";
import { Formik, FormikProps } from "formik";
import { Grid, Paper } from "@mui/material";
import { handleContractFormSubmit, prepareContractBeforeForm } from "../utils";
import {
  FormMessageErrorState,
  FormMessageInItState,
} from "../../../../constants";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import * as Yup from "yup";
import useValidation from "../../../../hooks/useValidation";
import { useParams } from "react-router-dom";
import { getContract } from "../../../../api/contracts";
import { getContractCategory } from "../../../../api/contractCategories";
import { ErrorBox } from "../../invoices/styled";
import Loader from "../../../Loader";
import { useFetch } from "../../../../hooks/useFetch";
import FileTreeDialog from "../../../../ui/fileList/index";
import {
  Contract as BEContract,
  ContractCategory as BEContractCategory,
} from "../../../../types/be.interfaces";
import { route } from "../../../../utils/url";
import { useTranslation } from "react-i18next";
import { getReleaseRoute } from "../../utils";
import { Currencies } from "../../../../types/be/currencies";
import { getCurrencies } from "../../../../api/currencies";
import TabPageWrapper from "../../../../ui/tabPageWrapper";
import { ContractForm } from "../../../../types/fe.interfaces";
import PdfViewerWrapper from "../../components/PdfViewerWrapper";
import useUploadDropzone from "../../../../hooks/useUploadDropzone";

const ContractEdit = (): ReactElement => {
  const { t } = useTranslation();
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const { id, contractId } = useParams();
  const { file_id, contact_id, contract_category_id, property_id } =
    useValidation();

  const {
    data: contract,
    isLoading: isLoadingContract,
    isError: isErrorContract,
    run: runContract,
  } = useFetch<BEContract>();
  const {
    data: categories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    run: runCategories,
  } = useFetch<BEContractCategory>();
  const {
    isFileTreeVisible,
    handleOpenFileTree,
    handleCloseFileTree,
    fileName,
    setFileName,
    selectedFileId,
    setSelectedFileId,
  } = useUploadDropzone({ file: contract?.file });
  const formRef = useRef<FormikProps<ContractForm> | null>(null);
  const {
    data: currencies,
    isLoading: isCurrenciesLoading,
    isError: isCurrenciesError,
    run: runCurrencies,
  } = useFetch<Currencies[]>();

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("contracts", Number(id)),
      name: t("contracts.contracts"),
    },
  ];

  useEffect(() => {
    runContract(getContract(contractId));
    runCategories(getContractCategory());
    runCurrencies(getCurrencies());
  }, []);
  /* istanbul ignore next */
  const handleFileTreeClick = (file_id: string, file_name: string): void => {
    formRef?.current?.setFieldValue("file_id", parseInt(file_id));
    setFileName(file_name);
    setSelectedFileId(Number(file_id));
    handleCloseFileTree();
  };

  if (isErrorContract || isErrorCategories || isCurrenciesError)
    return <ErrorBox formMessage={FormMessageErrorState} />;

  if (isLoadingContract || isLoadingCategories || isCurrenciesLoading)
    return <Loader />;
  return (
    <>
      {contract && categories && currencies && (
        <TabPageWrapper
          title={contract?.file?.name}
          breadcrumbs={breadcrumbs}
          wrap={!id}
        >
          <Paper sx={{ padding: 6 }}>
            <Grid container justifyContent={"center"}>
              {selectedFileId && (
                <Grid item sm={6} xs={12}>
                  <PdfViewerWrapper data={selectedFileId} type="fileId" />
                </Grid>
              )}
              <Grid item xs={12} sm={6} sx={{ pl: 6 }}>
                {formMessage.text && (
                  <FormAlert
                    formMessage={formMessage}
                    sx={{ marginBottom: "10px" }}
                  />
                )}

                <Formik
                  initialValues={prepareContractBeforeForm(contract)}
                  validationSchema={Yup.object().shape({
                    file_id,
                    contact_id,
                    contract_category_id,
                    property_id,
                  })}
                  innerRef={formRef}
                  onSubmit={handleContractFormSubmit({
                    id,
                    setFormMessage,
                    edit: true,
                  })}
                >
                  {(props): React.ReactElement => (
                    <Form
                      {...props}
                      propertyId={id}
                      edit={true}
                      categories={categories}
                      currencies={currencies}
                      handleOpenDialog={handleOpenFileTree}
                      fileName={fileName}
                      setFileName={setFileName}
                      setSelectedFileId={setSelectedFileId}
                    />
                  )}
                </Formik>
                {
                  /* istanbul ignore next */ isFileTreeVisible && (
                    <FileTreeDialog
                      onClose={handleCloseFileTree}
                      handleFileTreeClick={handleFileTreeClick}
                      extensions={["pdf"]}
                    />
                  )
                }
              </Grid>
            </Grid>
          </Paper>
        </TabPageWrapper>
      )}
    </>
  );
};

export default ContractEdit;
