import { FormikValues } from "formik";
import omit from "lodash/omit";
import { Dispatch, SetStateAction } from "react";
import { multiUpdateConfiguration } from "../../../api/releaseFlow";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { ReleaseConfiguration as BEReleaseConfiguration } from "../../../types/be/releaseFlow";
import { Role as BERole } from "../../../types/be/role";
import { FormMessageInterface } from "../../../types/form";
import { mapBackendValidationErrors } from "../../../utils/form";
import { ReleaseConfigurationForm } from "./types";

/* istanbul ignore next */
export const handleReleasePermissionFormSubmit = ({
  setFormMessage,
}: {
  setFormMessage: Dispatch<SetStateAction<FormMessageInterface>>;
}) => {
  return async (
    values: ReleaseConfigurationForm[],
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const data = {
      configurations: values.map((el) => ({
        ...omit(el, ["module", "level", "roles"]),
        role_code: el.level.code !== "IV" ? el.roles.map((el) => el.code) : [],
      })),
    };

    const response = await multiUpdateConfiguration(data);
    const json = await response.json();

    if ([200, 201].includes(response.status)) {
      setStatus({
        success: true,
        errors: {},
      });
      setFormMessage({
        type: "success",
        text: "",
      });
    } else if (response.status === 422) {
      setSubmitting(false);
      setFormMessage({
        type: "error",
        text: JSON.stringify(mapBackendValidationErrors(json.errors)),
      });
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
      setSubmitting(false);
    }
  };
};

/* istanbul ignore next */
export const titleDict: Record<string, string> = {
  I: "settings.release.finalReleaser",
  II: "settings.release.firstReleaseSuggestion",
  III: "settings.release.secondReleaseSuggestionN",
  IV: "settings.release.userReleaseSuggestion",
};

/* istanbul ignore next */
export const omitRolesFromOtherLevels = (
  roles: BERole[],
  configuration: BEReleaseConfiguration[],
  currentId: number
): BERole[] =>
  roles.filter(
    ({ code }) =>
      !configuration
        .filter(({ id }: BEReleaseConfiguration) => id !== currentId)
        .map(({ roles }: BEReleaseConfiguration) =>
          roles.map(({ code }) => code)
        )
        .flat(1)
        .includes(code)
  );
/* istanbul ignore next */
export const removeDuplicatesFromRoles = (roles: BERole[]): BERole[] =>
  roles.filter(
    (value, index, self) =>
      index ===
      self.findIndex((t) => t.code === value.code && t.id === value.id)
  );
