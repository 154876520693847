import { TableCell } from "../../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "name",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "date",
  },
];
