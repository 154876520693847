import React, { ChangeEvent, ReactElement } from "react";
import { Grid, MenuItem } from "@mui/material";
import { Form, Formik, FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import { LogType } from "../../../../../types/be/userActivity";
import { LogTypeFilterProps } from "../../types";
import { TextField } from "../styled";

const LogTypeFilter = ({
  logTypes,
  setActiveTypeId,
  activeTypeId,
}: LogTypeFilterProps): ReactElement => {
  const { t } = useTranslation();
  const handleChange =
    ({ submitForm }: FormikValues) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const {
        target: { value },
      } = event;
      setActiveTypeId(parseInt(value));
      submitForm();
    };

  return (
    <Formik
      initialValues={{
        log_type_id: activeTypeId,
      }}
      enableReinitialize
      onSubmit={() => {}}
    >
      {({ values, submitForm }) => {
        return (
          <Form>
            <Grid container alignItems="center" columns={12}>
              <Grid item xs={12} sx={{ maxHeight: 36 }}>
                <TextField
                  name="log_type_id"
                  fullWidth
                  select
                  size="small"
                  value={values.log_type_id}
                  onChange={handleChange({ submitForm })}
                  inputProps={{
                    "aria-label": "log-type-select",
                  }}
                >
                  <MenuItem key={0} value={0}>
                    {t("userActivity.allTypes")}
                  </MenuItem>
                  {logTypes.map(({ id, name }: LogType) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LogTypeFilter;
