import styled from "styled-components/macro";
import CloseButtonCustom from "../../../../ui/dialog/CloseButton";
/* istanbul ignore next */
export const CloseButton = styled(CloseButtonCustom)`
  position: absolute;
  top: 0;
  right: 0;
  width: ${(props): string => props.theme.spacing(5)};
  height: ${(props): string => props.theme.spacing(5)};
`;
