import React, { ReactElement, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  TextField as MuiTextField,
  CircularProgress,
} from "@mui/material";
import {
  INPUT_MIN_HEIGHT,
  MAX_PRELOADED_OPTIONS,
  NODE_ENVIRONMENTS,
  PermissionsUpdate,
  SEARCH_PARAM_MIN_CHARACTERS,
} from "../../../../constants";
import debounce from "lodash/debounce";
import { isNodeEnv } from "../../../../utils/env";
import { Company } from "../../../../types/models";
import {
  handleCompanyAutocompleteInputChange,
  removeDuplicatesFromCompanies,
} from "../utils";
import { CompaniesAutocompleteProps } from "../tabs/propertyManagement/types";
import useAccessControl from "../../../../hooks/useAccessControl";
import { StyledPaper } from "../../../contacts/autocomplete/styled";

/* istanbul ignore next */
const CompaniesAutocomplete = ({
  values,
  setFieldValue,
  handleBlur,
  companies,
  touched,
  errors,
}: CompaniesAutocompleteProps): ReactElement => {
  const { t } = useTranslation();
  const { can } = useAccessControl();
  const [loading, setLoading] = useState(false);

  const preloadOptions = useMemo(() => {
    const auxCompanies = values.company
      ? [values.company, ...companies.slice(0, MAX_PRELOADED_OPTIONS - 1)]
      : [...companies.slice(0, MAX_PRELOADED_OPTIONS)];
    return removeDuplicatesFromCompanies(auxCompanies);
  }, [companies, values.company, MAX_PRELOADED_OPTIONS]);

  const [options, setOptions] = useState<Company[] | null>(preloadOptions);
  const [searchResult, setSearchResult] = useState<Company[]>(preloadOptions);

  /* istanbul ignore next */
  const filterOptions = useCallback(
    (options: Company[]): Company[] =>
      options.filter(
        (option) =>
          !(
            !searchResult.some((el) => el.id === option.id) &&
            values.company?.id === option.id
          )
      ),
    [searchResult, values.company?.id]
  );

  const getOptionLabel = (company: Company | null): string => {
    if (!company) return "";
    const { title } = company;

    return title;
  };

  const onAutocompleteChangeCompany = (
    _: unknown,
    newCompany: Company | null
  ): void => {
    setFieldValue("company", newCompany);
    setFieldValue("company_user_id", null);
  };

  const onSearchFieldTextChange = useCallback(
    debounce(
      async (search: string): Promise<void> => {
        if (search.length < SEARCH_PARAM_MIN_CHARACTERS) {
          setOptions(preloadOptions);
          setSearchResult(preloadOptions);
        } else {
          setLoading(true);
          try {
            const response: Company[] =
              await handleCompanyAutocompleteInputChange(search);
            const auxCompanies = values.company
              ? [values.company, ...response]
              : [...response];
            setOptions(removeDuplicatesFromCompanies(auxCompanies));
            setSearchResult(removeDuplicatesFromCompanies([...response]));
            setLoading(false);
          } catch (e) {
            /* istanbul ignore next */
            setOptions([]);
          }
        }
      },
      /* istanbul ignore next */ isNodeEnv(NODE_ENVIRONMENTS.TEST) ? 0 : 500
    ),

    [
      handleCompanyAutocompleteInputChange,
      removeDuplicatesFromCompanies,
      preloadOptions,
      values.company,
    ]
  );

  return (
    <Autocomplete
      id="company_id"
      disabled={!can(PermissionsUpdate.PROPERTY)}
      ListboxProps={{ style: { maxHeight: "100vh" } }}
      loading={loading}
      options={options || /* istanbul ignore next */ []}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value): boolean => option.id === value.id}
      value={values.company || null}
      onChange={onAutocompleteChangeCompany}
      renderOption={(props, option): ReactElement => (
        <li {...props}>{option.title}</li>
      )}
      PaperComponent={StyledPaper}
      renderInput={(params) => (
        <>
          <MuiTextField
            {...params}
            label={t("createContact.company")}
            placeholder={t("createContact.company")}
            onBlur={handleBlur}
            sx={{ minHeight: INPUT_MIN_HEIGHT }}
            onChange={(e) => {
              onSearchFieldTextChange(e.target.value);
            }}
            error={Boolean(touched.company && errors.company)}
            helperText={touched.company && errors.company}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default CompaniesAutocomplete;
