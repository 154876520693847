import http from "../utils/http";
import { InsuranceCreateTopic } from "../types/fe.interfaces";
import { InsuranceEditTopic } from "../types/be.interfaces";

export const getInsuranceTopics = async (params: string): Promise<Response> => {
  return await http.get(`/api/insurance-topics${params}`);
};

export const getInsuranceTopicById = async (id: string): Promise<Response> => {
  return await http.get(`/api/insurance-topics/${id}`);
};

export const getInsuranceTopicTypes = async (): Promise<Response> => {
  return await http.get("/api/insurance-topics/types");
};

export const requestReleaseInsuranceTopic = async (
  id: number
): Promise<Response> => {
  return await http.post(`/api/insurance-topics/${id}/final-releaser`, {});
};

export const deleteInsuranceTopic = async (id: number): Promise<Response> => {
  return await http.delete(`/api/insurance-topics/${id}`);
};

export const createInsuranceTopic = async (
  data: InsuranceCreateTopic
): Promise<Response> => {
  return await http.post("/api/insurance-topics", data);
};

export const editInsuranceTopic = async (
  id: number,
  data: InsuranceEditTopic
): Promise<Response> => {
  return await http.put(`/api/insurance-topics/${id}`, data);
};

/* istanbul ignore next */
export const getInsuranceTopicsSearch = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/insurance-topics/search${params}`);
};

/* istanbul ignore next */
export const assignUserToInsuranceTopic = async (
  id: number,
  assignedUserId: number
): Promise<Response> => {
  return await http.patch(
    `/api/insurance-topics/${id}/users/${assignedUserId}`,
    {
      assigned_user_id: assignedUserId,
    }
  );
};
