import { UpdateReminderProps } from "../components/properties/invoices/reminder/create/types";
import http from "../utils/http";

export const getReminderList = async (params: string): Promise<Response> => {
  return await http.get(`/api/reminders${params}`);
};
export const deleteReminder = async (id: number): Promise<Response> => {
  return await http.delete(`/api/reminders/${id}`);
};

export const updateReminder = async (
  reminderId: number,
  data: UpdateReminderProps
): Promise<Response> => {
  return await http.patch(`/api/reminders/${reminderId}`, data);
};

export const getReminderStatuses = async (): Promise<Response> => {
  return await http.get(`/api/reminders/statuses`);
};

export const createReminders = async (data: FormData): Promise<Response> => {
  return await http.post("/api/reminders", data);
};

/* istanbul ignore next */
export const getReminderById = async (id:string): Promise<Response> => {
  return await http.get(`/api/reminders/${id}`);
};