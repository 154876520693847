import React, { ReactElement } from "react";
import { Grid } from "@mui/material";
import { Form } from "formik";
import { FormikValues } from "formik/dist/types";
import { useTranslation } from "react-i18next";
import Button from "../../../ui/button/Button";
import DatePicker from "../../../ui/datePicker";
import { FormatNumber } from "../../../ui/formsFields/formatNumber";
import { FORMAT_NUMBER_DE, Measurement_Units } from "../../../constants";

const EditVacantForm = ({
  values,
  isSubmitting,
  status,
  row,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();

  return (
    <Form noValidate>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={6} md={6}>
          <DatePicker
            name="empty_space_since"
            label={t("tenant.emptySpaceSince")}
            status={status}
            size="small"
            disableFuture
            value={values.empty_space_since}
            minHeight={"50px"}
            sx={{ minHeight: "auto" }}
            disabled={Boolean(row?.empty_space_since)}
            dataTestId={"empty_space_since"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormatNumber
            required
            name="price"
            label={t("netRentalIncome")}
            value={values.price}
            status={status}
            size="small"
            inputProps={{
              "data-testid": "potential-rent-income",
              filter: FORMAT_NUMBER_DE,
              unit: Measurement_Units.EURO,
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={2}
        mt={2}
      >
        <Grid item mt={2}>
          <Button
            title={t("update")}
            type="submit"
            color="success"
            testId={"edit-vacancy-button"}
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default EditVacantForm;
