import React, { ReactElement, useEffect, useContext, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  MenuItem,
  CardHeader,
  Tooltip,
} from "@mui/material";
import { FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import { Edit } from "@mui/icons-material";
import {
  EMPTY_DATA,
  FORMAT_NUMBER_DE,
  Measurement_Units,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../../../constants";
import ConfirmationModal from "../../../../../../ui/confirmationModal";
import { useCallbackPrompt } from "../../../../../../hooks/useCallbackPrompt";
import { AreaTenants } from "../../../../../../types/be/tenant";
import SelectOrCreateContactsAutocompleteField from "../../../../../contacts/autocomplete";
import {
  getRentStartDisabledDates,
  getRentStartDisabledMonths,
  getRentStartDisabledYears,
  getRentEndDisabledDates,
  getRentEndDisabledMonths,
  getRentEndDisabledYears,
  handleDateDisable,
  handleDisableYear,
} from "../../utils";
import { RentalFormProps } from "./types";
import Loader from "../../../../../Loader";
import { AreaEditContext } from "../../AreaEditContext";
import { useFetch } from "../../../../../../hooks/useFetch";
import { getAreaRenters } from "../../../../../../api/area";
import { AreaRenter } from "../../../../../../types/be/area";
import NumberInput from "../../../../../../ui/formsFields/number";
import {
  EditInfoCard,
  RentedInfoContainer,
  SmallLightText,
  RentedInfoContainerForRentEndDataWithTooltip,
} from "./styled";
import TextField from "../../../../../../ui/formsFields/textField";
import SwitchWrapper from "../../../../../../ui/formsFields/switch";
import TenantsListModal from "../TenantsListModal";
import { sendCredentialsToUser } from "../../../../../../api/users";
import Button from "../../../../../../ui/button/Button";
import UploadContract from "./UploadContract";
import { formatTimestamp } from "../../../../../../utils/date";
import { ArtOptions, FirstTenancyOptions } from "./utils";
import MUIDatePicker from "../../../../../../ui/datePicker";
import { parseNumber } from "../../../../../../utils/common";
import NumberContainer from "./conponents/NumberContainer";
import { File as ViewableFile } from "../../../../../../types/be/file";
import { deleteFile } from "../../../../../../api/files";
import { FormHelperText } from "../../../../../../ui/formsFields/multiSelect/styled";
import { filterCurrentTenants, filterNullTenants } from "../../../../utils";

/* istanbul ignore next */
const RentalContractForm = ({
  values,
  setFieldValue,
  handleSubmit,
  status,
  isFutureTenant,
  isEditFutureTenant,
  initialValues,
  errors,
  touched,
  handleBlur,
  handleChange,
  dirty,
  setSaveOnlyTenantsButtonClick,
  saveOnlyTenantsButtonClick,
  isSubmitting,
  isEditInfo,
  setIsEditInfo,
  isEditTenant,
  setIsEditTenant,
  isUploadingRentalContract,
  setIsUploadingRentalContract,
  submitCallback,
  setFormMessage,
}: FormikValues & RentalFormProps): ReactElement => {
  const { t } = useTranslation();
  const { showPrompt, confirmNavigation, cancelNavigation } =
    useCallbackPrompt(dirty);
  const areaEditContext = useContext(AreaEditContext);
  const [isTenantsListModalOpen, setIsTenantsListModalOpen] = useState(false);
  const { data: renters, run: handleGetAreaRenters } = useFetch<AreaRenter[]>();
  const [tenantAutocompleteValue, setTenantAutocompleteValue] = useState<
    AreaTenants[]
  >([]);

  const [isDeletingRentalContract, setIsDeletingRentalContract] =
    useState<boolean>(false);

  const handleRemoveTenant = (
    value: AreaTenants,
    { values, setFieldValue }: FormikValues
  ): void => {
    setTenantAutocompleteValue(
      tenantAutocompleteValue.filter((i) => i.id !== value.id)
    );
    setFieldValue(
      "active_tenant.tenants",
      values.active_tenant.tenants.filter((i: number) => i !== value.id)
    );
  };

  useEffect(() => {
    if (areaEditContext?.area?.id) {
      handleGetAreaRenters(
        getAreaRenters(areaEditContext?.area?.id?.toString())
      );
    }
  }, [areaEditContext?.area?.id]);

  if (
    !areaEditContext ||
    !areaEditContext.area ||
    !areaEditContext.fetchArea ||
    !areaEditContext.areaCategories ||
    !renters
  ) {
    return <Loader />;
  }

  const filteredRenters = isEditFutureTenant
    ? filterCurrentTenants(renters, initialValues?.active_tenant?.id)
    : filterNullTenants(renters);

  const { area, fetchArea } = areaEditContext;

  const handleTenantsListModalClose = (): void => {
    setIsTenantsListModalOpen(false);
  };

  const handleSendCredentials = async (value: AreaTenants): Promise<void> => {
    await sendCredentialsToUser(value.id);
    fetchArea();
  };

  const handleDeleteRentalContract = async (
    file: ViewableFile
  ): Promise<void> => {
    setIsDeletingRentalContract(true);
    const response = await deleteFile(String(file.id));
    if (response.ok) {
      submitCallback();
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
    }

    setIsUploadingRentalContract(false);
  };

  const rentStartDate = new Date(values?.active_tenant?.rent_start),
    initialRentStartDate = new Date(initialValues?.active_tenant?.rent_start),
    initialRentEndDate = initialValues?.active_tenant?.rent_end
      ? new Date(initialValues?.active_tenant?.rent_end)
      : null;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={7.5} sx={{ py: 2 }}>
        <Grid item sm={12}>
          <EditInfoCard className="edit-card">
            <CardHeader title={`${t("tenant.tenantContactInformation")}`} />
            {isEditTenant ? (
              <>
                <Grid container>
                  <RentedInfoContainer item sm={12}>
                    <SelectOrCreateContactsAutocompleteField
                      {...{
                        errors,
                        touched,
                        values,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                      }}
                      required
                      label={t("tenant.tenantContact")}
                      placeholder={t("tenant.tenantContact")}
                      rentedForm={true}
                      sx={{ minHeight: "auto" }}
                    />
                  </RentedInfoContainer>
                  {!isFutureTenant ? (
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent={"flex-end"}
                      gap={4}
                      m={4}
                    >
                      <Button
                        title={t("cancel")}
                        variant="text"
                        color="primary"
                        size="large"
                        onClick={() => setIsEditTenant(false)}
                      />
                      <Button
                        title={t("save")}
                        color="success"
                        type="button"
                        size="large"
                        disabled={isSubmitting && saveOnlyTenantsButtonClick}
                        isLoading={isSubmitting && saveOnlyTenantsButtonClick}
                        onClick={() => {
                          setSaveOnlyTenantsButtonClick &&
                            setSaveOnlyTenantsButtonClick(true);
                          handleSubmit();
                        }}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </>
            ) : (
              <>
                <Edit
                  className="edit-icon"
                  onClick={() => setIsEditTenant(true)}
                />
                <Grid container>
                  <RentedInfoContainer item sm={12} className="align-start">
                    <Typography variant="h4">
                      {area?.active_tenant?.contact?.full_name
                        ? area?.active_tenant?.contact?.full_name
                        : EMPTY_DATA}
                    </Typography>
                    {area?.active_tenant?.contact?.email ? (
                      <SmallLightText>
                        {area?.active_tenant?.contact?.email}
                      </SmallLightText>
                    ) : null}
                    {area?.active_tenant?.contact?.address ? (
                      <SmallLightText>
                        {area?.active_tenant?.contact?.address}
                      </SmallLightText>
                    ) : null}
                  </RentedInfoContainer>
                </Grid>
              </>
            )}
          </EditInfoCard>
        </Grid>
        {!isFutureTenant ? (
          <Grid item sm={12}>
            <Card>
              <CardHeader title={`${t("tenant.revenueOverview")}`} />
              <Grid container>
                <RentedInfoContainer item sm={4} md={3}>
                  <NumberContainer
                    value={values?.active_tenant?.net_rent_per_m2}
                  />
                  <SmallLightText>{t("tenant.netRentPerM2")}</SmallLightText>
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4} md={3}>
                  <NumberContainer
                    value={values?.active_tenant?.net_rent_per_year}
                  />
                  <SmallLightText>{t("tenant.netRentPerYear")}</SmallLightText>
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4} md={3}>
                  <NumberContainer
                    value={values?.active_tenant?.remaining_term_in_eur}
                  />
                  <SmallLightText>{t("tenant.remaining")}</SmallLightText>
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4} md={3}>
                  <NumberContainer
                    value={
                      values?.active_tenant?.total_gross_net_rent_per_month
                    }
                  />
                  <SmallLightText>{t("tenant.grossPerMonth")}</SmallLightText>
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4} md={3}>
                  <NumberContainer
                    value={values?.active_tenant?.total_gross_net_rent_per_year}
                  />
                  <SmallLightText>{t("tenant.grossPerYear")}</SmallLightText>
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4} md={3}>
                  <Typography variant="h4" textAlign="center">
                    {values?.active_tenant?.remaining_term
                      ? values?.active_tenant?.remaining_term
                          ?.replace(" years", "y")
                          .replace(" months", "m")
                          .replace(" days", "d")
                      : EMPTY_DATA}
                  </Typography>
                  <SmallLightText>{t("tenant.remainingTerm")}</SmallLightText>
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4} md={3}>
                  <NumberContainer
                    value={values?.active_tenant?.additional_costs_per_year?.toString()}
                  />
                  <SmallLightText>
                    {t("tenant.additionalCostsPerYear")}
                  </SmallLightText>
                </RentedInfoContainer>
              </Grid>
            </Card>
          </Grid>
        ) : null}
        <Grid item sm={12}>
          <EditInfoCard className="edit-card">
            <CardHeader title={`${t("tenant.detailsAboutTheRentedArea")}`} />
            {isEditInfo ? (
              <>
                <Grid container>
                  <RentedInfoContainer item sm={4}>
                    <TextField
                      name="active_tenant.net_rent_per_month"
                      value={values?.active_tenant?.net_rent_per_month}
                      label={t("tenant.netRentPerMonth")}
                      inputProps={{
                        filter: FORMAT_NUMBER_DE,
                        unit: Measurement_Units.EURO,
                        "data-testid": "netRentPerMonth-input",
                      }}
                      required
                      status={status}
                      sx={{ minHeight: "auto" }}
                    />
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4}>
                    <TextField
                      name="active_tenant.tax"
                      value={values?.active_tenant?.tax}
                      label={t("tenant.tax")}
                      inputProps={{
                        filter: FORMAT_NUMBER_DE,
                        unit: Measurement_Units.EURO,
                        "data-testid": "tax-input",
                      }}
                      status={status}
                      sx={{ minHeight: "auto" }}
                      onChange={(event) => {
                        if (
                          parseNumber(event.target.value) >= 0 &&
                          parseNumber(event.target.value) <= 100
                        ) {
                          setFieldValue(
                            "active_tenant.tax",
                            event.target.value
                          );
                        } else {
                          setFieldValue(
                            "active_tenant.tax",
                            values?.active_tenant.tax
                          );
                        }
                      }}
                    />
                  </RentedInfoContainer>

                  <RentedInfoContainer item sm={4}>
                    <MUIDatePicker
                      name="active_tenant.rent_start"
                      label={t("tenant.startRentDate")}
                      status={status}
                      required
                      shouldDisableDate={getRentStartDisabledDates(
                        filteredRenters,
                        initialRentStartDate,
                        isFutureTenant
                      )}
                      shouldDisableYear={getRentStartDisabledYears(
                        filteredRenters,
                        initialRentStartDate,
                        isFutureTenant
                      )}
                      shouldDisableMonth={getRentStartDisabledMonths(
                        filteredRenters,
                        initialRentStartDate,
                        isFutureTenant
                      )}
                      minHeight={"50px"}
                      dataTestId={"rent-start"}
                    />
                  </RentedInfoContainer>
                  <RentedInfoContainerForRentEndDataWithTooltip item sm={4}>
                    <Tooltip
                      title={
                        !values.active_tenant.rent_start
                          ? t("tenant.startRentDateNeedsToBeSelected")
                          : null
                      }
                      placement="top"
                    >
                      <span>
                        <MUIDatePicker
                          name="active_tenant.rent_end"
                          label={t("tenant.endRentDate")}
                          status={status}
                          disabled={!values.active_tenant.rent_start}
                          shouldDisableDate={getRentEndDisabledDates(
                            filteredRenters,
                            rentStartDate,
                            initialRentEndDate,
                            isFutureTenant
                          )}
                          shouldDisableYear={getRentEndDisabledYears(
                            filteredRenters,
                            rentStartDate,
                            initialRentEndDate,
                            isFutureTenant
                          )}
                          shouldDisableMonth={getRentEndDisabledMonths(
                            filteredRenters,
                            rentStartDate,
                            initialRentEndDate,
                            isFutureTenant
                          )}
                          minHeight={"50px"}
                        />
                      </span>
                    </Tooltip>
                  </RentedInfoContainerForRentEndDataWithTooltip>
                  <RentedInfoContainer item sm={4}>
                    <MUIDatePicker
                      name="active_tenant.cancellation_until"
                      label={t("tenant.cancellationUntil")}
                      status={status}
                      shouldDisableDate={handleDateDisable(
                        values?.active_tenant?.rent_start ?? 0
                      )}
                      shouldDisableYear={handleDisableYear(
                        values?.active_tenant?.rent_start ?? 0
                      )}
                      minHeight={"50px"}
                    />
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4}>
                    <MUIDatePicker
                      name="active_tenant.closing_tenant_contract"
                      label={t("tenant.conclusionOrRentalContract")}
                      status={status}
                      shouldDisableDate={handleDateDisable(
                        values?.active_tenant?.rent_start ?? 0
                      )}
                      shouldDisableYear={handleDisableYear(
                        values?.active_tenant?.rent_start ?? 0
                      )}
                      minHeight={"50px"}
                    />
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4}>
                    <MUIDatePicker
                      name="active_tenant.special_cancellation_right"
                      label={t("tenant.specialRightOfCancellation")}
                      status={status}
                      shouldDisableDate={handleDateDisable(
                        values?.active_tenant?.rent_start ?? 0
                      )}
                      shouldDisableYear={handleDisableYear(
                        values?.active_tenant?.rent_start ?? 0
                      )}
                      minHeight={"50px"}
                    />
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4}>
                    <TextField
                      select
                      value={values?.active_tenant?.art}
                      name="active_tenant.art"
                      label={t("tenant.type")}
                      status={status}
                      sx={{ minHeight: "auto" }}
                    >
                      {ArtOptions.map((option) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4}>
                    <TextField
                      select
                      label={t("tenant.firstTenancy")}
                      name="active_tenant.right_of_first_tenancy"
                      value={
                        +(values?.active_tenant?.right_of_first_tenancy ?? 0)
                      }
                      status={status}
                      sx={{ minHeight: "auto" }}
                    >
                      {FirstTenancyOptions.map((option) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4}>
                    <TextField
                      name="active_tenant.deposit"
                      value={values?.active_tenant?.deposit}
                      label={t("tenant.deposit")}
                      inputProps={{
                        filter: FORMAT_NUMBER_DE,
                        unit: Measurement_Units.EURO,
                        "data-testid": "deposit-input",
                      }}
                      status={status}
                      sx={{ minHeight: "auto" }}
                    />
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4}>
                    <TextField
                      label={t("tenant.constOfConversion")}
                      inputProps={{
                        filter: FORMAT_NUMBER_DE,
                        unit: Measurement_Units.EURO,
                      }}
                      name="active_tenant.costs_for_modification"
                      value={values?.active_tenant?.costs_for_modification}
                      status={status}
                      sx={{ minHeight: "auto" }}
                    />
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4}>
                    <TextField
                      label={t("tenant.rentFree")}
                      inputProps={{
                        filter: FORMAT_NUMBER_DE,
                        unit: Measurement_Units.EURO,
                      }}
                      name="active_tenant.rent_free"
                      value={values?.active_tenant?.rent_free}
                      status={status}
                      sx={{ minHeight: "auto" }}
                    />
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4}>
                    <NumberInput
                      label={t("tenant.parkingLots")}
                      name="active_tenant.number_of_parking_spaces"
                      value={values?.active_tenant?.number_of_parking_spaces}
                      status={status}
                      type="number"
                    />
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4}>
                    <TextField
                      label={t("tenant.miscellaneousEUR")}
                      name="active_tenant.others_incomings_eur"
                      value={values?.active_tenant?.others_incomings_eur}
                      status={status}
                      inputProps={{
                        filter: FORMAT_NUMBER_DE,
                        unit: Measurement_Units.EURO,
                      }}
                      sx={{ minHeight: "auto" }}
                    />
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4}>
                    <TextField
                      label={t("tenant.rentEUR")}
                      name="active_tenant.rent_eur"
                      value={values?.active_tenant?.rent_eur}
                      status={status}
                      inputProps={{
                        filter: FORMAT_NUMBER_DE,
                        unit: Measurement_Units.EURO,
                      }}
                      sx={{ minHeight: "auto" }}
                    />
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4}>
                    <TextField
                      label={t("tenant.additionalCosts")}
                      name="active_tenant.additional_costs_per_month"
                      value={values?.active_tenant?.additional_costs_per_month}
                      status={status}
                      inputProps={{
                        filter: FORMAT_NUMBER_DE,
                        unit: Measurement_Units.EURO,
                      }}
                      sx={{ minHeight: "auto" }}
                    />
                  </RentedInfoContainer>
                  {!isFutureTenant ? (
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent={"flex-end"}
                      gap={4}
                      m={4}
                    >
                      <Button
                        title={t("cancel")}
                        variant="text"
                        color="primary"
                        size="large"
                        onClick={() => setIsEditInfo(false)}
                      />
                      <Button
                        title={t("save")}
                        color="success"
                        type="submit"
                        size="large"
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </>
            ) : (
              <>
                <Edit
                  className="edit-icon"
                  onClick={() => setIsEditInfo(true)}
                />
                <Grid container>
                  <RentedInfoContainer item sm={4} md={3}>
                    <NumberContainer
                      value={area?.active_tenant?.net_rent_per_month}
                    />
                    <SmallLightText>
                      {t("tenant.netRentPerMonth")}
                    </SmallLightText>
                  </RentedInfoContainer>

                  <RentedInfoContainer item sm={4} md={3}>
                    <NumberContainer
                      value={area?.active_tenant?.tax}
                      measurementUnits={Measurement_Units.PERCENTAGE}
                    />
                    <SmallLightText>{t("tenant.tax")}</SmallLightText>
                  </RentedInfoContainer>

                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {area?.active_tenant?.rent_start
                        ? formatTimestamp(area?.active_tenant?.rent_start)
                        : EMPTY_DATA}
                    </Typography>
                    <SmallLightText>{t("tenant.startRentDate")}</SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {area?.active_tenant?.rent_end
                        ? formatTimestamp(area?.active_tenant?.rent_end)
                        : EMPTY_DATA}
                    </Typography>
                    <SmallLightText>{t("tenant.endRentDate")}</SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {area?.active_tenant?.cancellation_until
                        ? formatTimestamp(
                            area?.active_tenant?.cancellation_until
                          )
                        : EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("tenant.cancellationUntil")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {area?.active_tenant?.closing_tenant_contract
                        ? formatTimestamp(
                            area?.active_tenant?.closing_tenant_contract
                          )
                        : EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("tenant.conclusionOrRentalContract")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {area?.active_tenant?.special_cancellation_right
                        ? formatTimestamp(
                            area?.active_tenant?.special_cancellation_right
                          )
                        : EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("tenant.specialRightOfCancellation")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {ArtOptions.find(
                        (item) => item?.id === Number(area?.active_tenant?.art)
                      )?.label || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>{t("tenant.type")}</SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {FirstTenancyOptions.find(
                        (item) =>
                          item?.id ===
                          Number(area?.active_tenant?.right_of_first_tenancy)
                      )?.label || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>{t("tenant.firstTenancy")}</SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <NumberContainer value={area?.active_tenant?.deposit} />
                    <SmallLightText>{t("tenant.deposit")}</SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <NumberContainer
                      value={area?.active_tenant?.costs_for_modification}
                    />
                    <SmallLightText>
                      {t("tenant.constOfConversion")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <NumberContainer value={area?.active_tenant?.rent_free} />
                    <SmallLightText>{t("tenant.rentFree")}</SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {area?.active_tenant?.number_of_parking_spaces ||
                        EMPTY_DATA}
                    </Typography>
                    <SmallLightText>{t("tenant.parkingLots")}</SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <NumberContainer
                      value={area?.active_tenant?.others_incomings_eur}
                    />
                    <SmallLightText>
                      {t("tenant.miscellaneousEUR")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <NumberContainer value={area?.active_tenant?.rent_eur} />
                    <SmallLightText>{t("tenant.rentEUR")}</SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <NumberContainer
                      value={area?.active_tenant?.additional_costs_per_month}
                    />
                    <SmallLightText>
                      {t("tenant.additionalCosts")}
                    </SmallLightText>
                  </RentedInfoContainer>
                </Grid>
              </>
            )}
          </EditInfoCard>
        </Grid>
        <Grid item sm={12}>
          <Card>
            <CardHeader title={`${t("tenant.furtherDetails")}`} />
            <Grid container>
              <RentedInfoContainer item sm={4}>
                <SwitchWrapper
                  name="active_tenant.new"
                  label={t("tenant.new")}
                  checked={Boolean(values?.active_tenant?.new)}
                  onChange={() => {
                    setFieldValue(
                      "active_tenant.new",
                      !values?.active_tenant?.new
                    );
                    !isFutureTenant && handleSubmit();
                  }}
                />
              </RentedInfoContainer>
              <RentedInfoContainer item sm={4}>
                <SwitchWrapper
                  name="active_tenant.is_terminated"
                  label={t("tenant.terminated")}
                  checked={Boolean(values?.active_tenant?.is_terminated)}
                  onChange={() => {
                    setFieldValue(
                      "active_tenant.is_terminated",
                      !values?.active_tenant?.is_terminated
                    );
                    !isFutureTenant && handleSubmit();
                  }}
                />
              </RentedInfoContainer>
              <RentedInfoContainer item sm={4}>
                <SwitchWrapper
                  name="active_tenant.is_unlimited"
                  label={t("tenant.unlimited")}
                  checked={Boolean(values?.active_tenant?.is_unlimited)}
                  onChange={() => {
                    setFieldValue(
                      "active_tenant.is_unlimited",
                      !values?.active_tenant?.is_unlimited
                    );
                    !isFutureTenant && handleSubmit();
                  }}
                />
              </RentedInfoContainer>
            </Grid>
          </Card>
        </Grid>
        <Grid item sm={12}>
          <Card>
            <CardHeader title={`${t("tenant.rentalContract")}`} />
            <RentedInfoContainer container>
              <RentedInfoContainer item sx={{ width: 400 }}>
                <UploadContract
                  file={values?.active_tenant?.rental_contract_file}
                  isLoading={isUploadingRentalContract}
                  handleDelete={handleDeleteRentalContract}
                  isDeletingRentalContract={isDeletingRentalContract}
                  onDrop={<T extends File>(
                    files: FileList | T[] | null
                  ): void => {
                    setIsUploadingRentalContract(!isFutureTenant);
                    setFieldValue("rental_contract_file", files?.[0] || "");
                    setFieldValue(
                      "active_tenant.rental_contract_file",
                      files?.[0] || ""
                    );
                    !isFutureTenant && handleSubmit();
                  }}
                  title={t("contracts.uploadFile")}
                />
                {status?.errors?.["active_tenant.rental_contract_file"] && (
                  <FormHelperText>
                    {status?.errors?.["active_tenant.rental_contract_file"]}
                  </FormHelperText>
                )}
              </RentedInfoContainer>
            </RentedInfoContainer>
          </Card>
        </Grid>
        {isFutureTenant ? (
          <Grid
            item
            sm={12}
            sx={{
              bottom: 0,
              position: "sticky",
              zIndex: 1,
            }}
          >
            <Card sx={{ p: 3 }}>
              <Grid container display={"flex"} justifyContent={"flex-end"}>
                <RentedInfoContainer>
                  <Button
                    title={t("save")}
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    color="success"
                  />
                </RentedInfoContainer>
              </Grid>
            </Card>
          </Grid>
        ) : null}
      </Grid>
      <TenantsListModal
        handleTenantsListModalClose={handleTenantsListModalClose}
        isTenantsListModalOpen={isTenantsListModalOpen}
        tenantAutocompleteValue={tenantAutocompleteValue}
        handleRemoveTenant={handleRemoveTenant}
        values={values}
        setFieldValue={setFieldValue}
        handleSendCredentials={handleSendCredentials}
      />
      <ConfirmationModal
        titleText={t("property.area.edit.leaveWithoutSaving")}
        visible={showPrompt}
        confirmText={t("yes")}
        cancelText={t("no")}
        setVisible={showPrompt}
        handleCancel={cancelNavigation}
        handleConfirm={confirmNavigation}
      />
    </form>
  );
};

export default RentalContractForm;
