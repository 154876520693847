/*istanbul ignore file*/

import styled from "styled-components";
import { Button as MuiButton } from "@mui/material";

export const StyledButton = styled(MuiButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  line-height: 20px;
  font-size: 14px;
  padding: ${(props): string => props.theme.spacing(0, 3.75, 0.5)};
  min-width: ${(props): string => props.theme.spacing(11.25)};
`;
