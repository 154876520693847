/* istanbul ignore file */

import React, { ReactElement, useState } from "react";
import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ImportDataProps } from "./types";
import {
  FormMessageErrorState,
  XLSX_FILE_TYPE,
} from "../../../../../../constants";
import { downloadFile } from "../../../../../../utils/common";
import { ErrorBox } from "../../styled";
import { HTTP_STATUS_CODES } from "../../../../../../types/server";
import { downloadExampleFile } from "../../../../../../api/area";

const AreaImportData = ({
  importCallback,
  handleClose,
}: ImportDataProps): ReactElement => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const handleDownloadLinkClick = async (): Promise<void> => {
    const res = await downloadExampleFile();
    if (res.status !== HTTP_STATUS_CODES.OK) {
      setIsError(true);
    } else {
      const blob = await res.blob();
      downloadFile(
        new Blob([blob]),
        t("propertiesList.propertiesList"),
        XLSX_FILE_TYPE
      );
    }
  };

  return (
    <>
      {isError && <ErrorBox formMessage={FormMessageErrorState} />}
      <MenuItem
        onClick={(): void => {
          handleDownloadLinkClick();
          handleClose();
        }}
        disableRipple
        data-testid={"downalod-sample-link"}
      >
        {t("property.downloadExample")}
      </MenuItem>
      <MenuItem
        onClick={(): void => {
          importCallback();
          handleClose();
        }}
        disableRipple
        data-testid={"upload-link"}
      >
        {t("property.import")}
      </MenuItem>
    </>
  );
};

export default AreaImportData;
