import { TableCell } from "../../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "asset_managers",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenant.asset_managers",
  },
  {
    id: "property_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "propertiesList.objectName",
  },
  {
    id: "contact_id",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenant.name",
  },
  {
    id: "rent_end",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenant.rent_end",
  },
  {
    id: "cancellation_until",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenant.cancellation_until",
  },
  {
    id: "area",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenant.area",
  },
  {
    id: "net_rent_per_month",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenant.net_rent_per_month",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "comment",
    align: "center",
  },
];
