import React, { ReactElement, useEffect, useState } from "react";
import {
  columnsFromBackend,
  COMPLETED_STATUS,
  COMPLETED_TITLE,
  IN_COORDINATION_STATUS,
  IN_COORDINATION_TITLE,
  IN_PROGRESS_STATUS,
  IN_PROGRESS_TITLE,
  PENDING_STATUS,
} from "./utils";
import { BoardColumn } from "./types";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import TaskCard from "./taskCard";
import {
  TenantRequest as TenantRequestType,
  TenantRequestStatusType,
} from "../../types/be/tenantRequest";
import {
  Container,
  TaskColumnStyles,
  TaskListWrapper,
  TaskList,
  Title,
} from "./styled";
import {
  getTenantRequests,
  updateTenantRequestStatus,
} from "../../api/tenantRequest";
import { getJson } from "../../utils/http";
import cloneDeep from "lodash/cloneDeep";
import { Button, Grid, Tooltip } from "@mui/material";
import { Property } from "../../types/be/property";
import PropertyAutocomplete from "./toolBar/filters/property";
import { prepareQueryParams } from "../../utils/common";
import ManagerAutocomplete from "./toolBar/filters/manager";
import { User } from "../../types/be/user";
import PropertyTenantAutocomplete from "./toolBar/filters/propertyTenant";
import { ToolBarWrapper } from "./toolBar/styled";
import camelCase from "lodash/camelCase";
import toUpper from "lodash/toUpper";
import { useTranslation } from "react-i18next";
import HourglassDisabledIcon from "@mui/icons-material/HourglassDisabled";
import HistoryIcon from "@mui/icons-material/History";
import { route } from "../../utils/url";
import { useNavigate } from "react-router-dom";
import useAccessControl from "../../hooks/useAccessControl";
import { StyledButton } from "../../ui/button/styled";
import { PermissionsCreate } from "../../constants";
import { LoadingBox } from "../contacts/styled";
import Loader from "../Loader";

const TenantRequest = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { can, isTenant } = useAccessControl();
  const [columns, setColumns] = useState<BoardColumn | null>(
    columnsFromBackend
  );
  const [property, setProperty] = useState<Property | null>(null);
  const [propertyTenant, setPropertyTenant] = useState<User | null>(null);
  const [manager, setManager] = useState<User | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchTenantRequests = (params: string): void => {
    setLoading(true);
    getTenantRequests(params)
      .then(getJson)
      .then(({ data }) => {
        const columns = cloneDeep(columnsFromBackend);
        if (!isTenant) {
          columns[IN_COORDINATION_STATUS] = {
            title: IN_COORDINATION_TITLE,
            items: [],
          };
        }
        columns[IN_PROGRESS_STATUS] = {
          title: IN_PROGRESS_TITLE,
          items: [],
        };
        columns[COMPLETED_STATUS] = {
          title: COMPLETED_TITLE,
          items: [],
        };
        data.forEach((item: TenantRequestType) => {
          if (isTenant && item.status.code === IN_COORDINATION_STATUS)
            columns[PENDING_STATUS].items.push(item);
          else columns[item.status.code].items.push(item);
        });
        setColumns(cloneDeep(columns));
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isTenant !== undefined) {
      const payload = {
        property_id: property?.id,
        tenant_id: propertyTenant?.id,
        manager_id: manager?.id,
      };
      const params = prepareQueryParams("", payload);
      fetchTenantRequests(params);
    }
  }, [property, propertyTenant, manager, isTenant]);

  /* istanbul ignore next */
  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;
    if (source.droppableId !== destination.droppableId) {
      if (!columns) return;
      const sourceColumn = columns[source.droppableId as string];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      if (!columns) return;
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
    updateTenantRequestStatus(
      +draggableId,
      destination.droppableId as TenantRequestStatusType
    )
      .then(getJson)
      .then(() => {});
  };

  const handleOnPropertySelect = (property: Property | null): void => {
    setProperty(property);
  };

  const handleOnPropertyTenantSelect = (propertyTenant: User | null): void => {
    setPropertyTenant(propertyTenant);
  };

  const handleOnManagerSelect = (manager: User | null): void => {
    setManager(manager);
  };

  const handleOnShowOutdatedTenantRequests = (): void => {
    navigate(route("tenant-requests.outdated"));
  };

  const handleOnCreateTenantRequestClick = (): void => {
    navigate(route("tenant-requests.create"));
  };

  return (
    <Grid container direction={"column"}>
      <ToolBarWrapper item>
        <Grid container justifyContent={"space-between"} sx={{ mb: 2 }}>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              direction: "column",
              alignItems: "center",
            }}
          >
            {can([PermissionsCreate.TENANT_REQUEST]) && (
              <StyledButton
                sx={{ display: "inline-block" }}
                variant="contained"
                size={"small"}
                color="success"
                onClick={() => handleOnCreateTenantRequestClick()}
              >
                {t("tenantRequest.create")}
              </StyledButton>
            )}
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
          >
            <Tooltip title={t("tenantRequests.outdated")}>
              <Button
                role="completed-outdated-button"
                variant="outlined"
                onClick={() => handleOnShowOutdatedTenantRequests()}
              >
                <HourglassDisabledIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t("tenant.history.history")}>
              <Button
                variant="outlined"
                color="warning"
                onClick={
                  /* istanbul ignore next */ () =>
                    navigate(route("tenant-requests.history"))
                }
                role="history-button"
              >
                <HistoryIcon />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <PropertyAutocomplete onPropertySelect={handleOnPropertySelect} />
          </Grid>
          <Grid item xs={3}>
            <PropertyTenantAutocomplete
              onPropertyTenantSelect={handleOnPropertyTenantSelect}
            />
          </Grid>
          <Grid item xs={3}>
            <ManagerAutocomplete onManagerSelect={handleOnManagerSelect} />
          </Grid>
        </Grid>
      </ToolBarWrapper>
      {isLoading ? (
        <div data-testid="loader">
          <LoadingBox>
            <Loader />
          </LoadingBox>
        </div>
      ) : (
        <>
          {columns && isTenant !== undefined && (
            <Grid item>
              <DragDropContext onDragEnd={onDragEnd}>
                <Container>
                  <TaskColumnStyles>
                    {Object.entries(columns).map(
                      ([columnId, column], index) => {
                        return (
                          <Droppable
                            key={`${columnId}-${index}`}
                            droppableId={columnId}
                          >
                            {(provided) => (
                              <TaskListWrapper
                                role={`${columnId}-list-wrapper`}
                              >
                                <TaskList
                                  elevation={0}
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  <Title>
                                    {toUpper(
                                      t(
                                        `tenantRequest.${camelCase(
                                          column.title
                                        )}`
                                      )
                                    )}
                                  </Title>
                                  {column.items.map((item, index) => (
                                    <div key={`key-${item.id}`}>
                                      <TaskCard item={item} index={index} />
                                    </div>
                                  ))}
                                  {provided.placeholder}
                                </TaskList>
                              </TaskListWrapper>
                            )}
                          </Droppable>
                        );
                      }
                    )}
                  </TaskColumnStyles>
                </Container>
              </DragDropContext>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default TenantRequest;
