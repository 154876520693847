import { sendCredentialsToUser } from "../../../../../../../api/users";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../../constants";
import { HTTP_STATUS_CODES } from "../../../../../../../types/server";
import { TableCell } from "../../../../../../../types/table";
import { resendCredentialProps } from "./types";

/* istanbul ignore next */
export const itemsListHeadCells: TableCell[] = [
  {
    id: "user_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequests.userName",
  },
  {
    id: "email_address",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "emailAddress",
  },
  {
    id: "invitation_sent_on",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "tenantRequests.invitationSentOn",
    align: "left",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
    align: "left",
  },
];

/* istanbul ignore next */
export const handelResendCredential = async ({
  row,
  snackbar,
  t,
  fetchMobileUser,
}: resendCredentialProps): Promise<void> => {
  const response = await sendCredentialsToUser(row.id);
  if (
    [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(response?.status)
  ) {
    fetchMobileUser();
    snackbar.success(t("tenant.resendCredentialSuccessfully"));
  } else {
    snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
  }
};
