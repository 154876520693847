import React, {
  ChangeEvent,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import Button from "../../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, Stack } from "@mui/material";
import {
  Company,
  ContactType,
  Ranking,
  Salutation,
} from "../../../../types/models";
import { Text as TextField } from "../../../../ui/formsFields/text";
import {
  INPUT_MIN_HEIGHT,
  SEARCH_PARAM_MIN_CHARACTERS,
} from "../../../../constants";
import Autocomplete from "@mui/material/Autocomplete";
import Loader from "../../../Loader";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  PlaceDetails,
  PlaceDetailsResponse,
  Prediction,
} from "../../../../types/be/place";
import { AutocompleteListItem } from "../../../properties/edit/general/styled";
import { Dropdown } from "../../../../ui/autocomplete/styled";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import { getPlaceDetails, getPlacePredictions } from "../../../../api/places";
import { getJson } from "../../../../utils/http";
import debounce from "lodash/debounce";
import {
  mapAddressComponentsToPlaceAddress,
  placeErrorText,
} from "../../../properties/utils";
import { FormSectionTitle } from "../styled";
import camelCase from "lodash/camelCase";
import Tag from "../../../../ui/tag";
import { FormikValues } from "formik/dist/types";
import Snackbar from "../../../../ui/Snackbar";
import { createCompanyFormInitState } from "../../createCompanyModal/utils";
import CreateCompanyModal from "../../createCompanyModal";
import CompanyAutocomplete from "../../companyAutocomplete";
import {
  findFiveDigitZipCode,
  prepareQueryParams,
} from "../../../../utils/common";
import { StyledPaper } from "../../autocomplete/styled";
import { route } from "../../../../utils/url";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

/* istanbul ignore next */
function Form({
  handleSubmit,
  status,
  isSubmitting,
  salutations,
  contactTypes,
  values,
  errors,
  handleBlur,
  setFieldValue,
  rankings,
  focuses,
  tags,
  handleChangeFocus,
  handleRemoveFocus,
  handleChangeTag,
  handleRemoveTag,
  propertyManagerId,
  setSelectedContactType,
  isModal,
}: FormikValues): React.ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [companyWasCreated, setCompanyWasCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPlaceValidationError, setIsPlaceValidationError] = useState(false);
  const [, setPlaceDetails] = useState<PlaceDetails | null>(null);
  const [placeInputValue, setPlaceInputValue] = useState("");
  const [placePredictions, setPlacePredictions] = useState<
    readonly Prediction[]
  >([]);
  const [placeValidationErrorText, setPlaceValidationErrorText] = useState("");
  const [isCreateCompanyModalOpen, setIsCreateCompanyModalOpen] =
    useState<boolean>(false);
  const [initialValues, setInitialValues] = useState(
    createCompanyFormInitState
  );
  const [companyAutocompleteValue, setCompanyAutocompleteValue] =
    useState<Company | null>(values.company.title ? values.company : null);
  const [placeAutocompleteFieldValue, setPlaceAutocompleteFieldValue] =
    useState("");

  const onLocationSearchKeyDown = React.useCallback(
    debounce(async (search: string): Promise<void> => {
      if (search.length < SEARCH_PARAM_MIN_CHARACTERS) setPlacePredictions([]);
      else {
        setLoading(true);
        const params = prepareQueryParams("", {
          input: search,
        });
        getPlacePredictions(params)
          .then(getJson)
          .then(({ data: { predictions } }) => {
            setPlacePredictions(predictions);
            setLoading(false);
          })
          .catch(() => {
            setPlacePredictions([]);
          });
      }
    }, 300),
    []
  );

  useEffect(() => {
    setPlaceInputValue(values?.location.full_address);
  }, []);

  const handlePlacePredictionSelect = ({ place_id }: Prediction): void => {
    const params = prepareQueryParams("", {
      place_id,
      language: "en",
    });
    getPlaceDetails(params)
      .then(getJson)
      .then(({ data: { result } }: PlaceDetailsResponse) => {
        setPlaceDetails(result);
        const { geometry } = result;
        const { address_components } = result;
        const { place_id } = result;
        const mapResult =
          mapAddressComponentsToPlaceAddress(address_components);
        setPlaceInputValue(result.formatted_address);
        setFieldValue("location", {
          full_address: result.formatted_address!,
          city: mapResult.city || "",
          country_code: mapResult.country_code!,
          state_code: mapResult.state_code || "",
          state: mapResult.state || "",
          house_number: mapResult.house_number || "",
          zip_code: mapResult.zip_code || "",
          street: mapResult.street || "",
          place_id: place_id!,
          lat: geometry.location.lat!,
          lng: geometry.location.lng!,
        });
      });
  };

  const handleAutocompleteOnChange = (
    _: SyntheticEvent,
    value: Prediction | null
  ): void => {
    if (value) handlePlacePredictionSelect(value);
    else {
      setPlaceDetails(null);
    }
  };

  const handleRenderInput = (
    params: AutocompleteRenderInputParams
  ): ReactNode => (
    <TextField
      {...params}
      label={t("createContact.contactOrCompanyAddress")}
      onChange={(e) => {
        setPlaceInputValue(e.target.value);
        onLocationSearchKeyDown(e.target.value);
        setPlaceAutocompleteFieldValue(e.target.value);
      }}
      placeholder={t("google.place.autocomplete.placeholder")}
      value={values.place_id}
      onBlur={handleBlur}
      error={isPlaceValidationError}
      helperText={isPlaceValidationError && placeValidationErrorText}
      status={status}
    />
  );

  useEffect(() => {
    const str = placeErrorText(errors.location);
    setIsPlaceValidationError(!!str.length);
    setPlaceValidationErrorText(
      t(`google.place.autocomplete.${camelCase(str)}`)
    );
  }, [errors.location]);

  const handleRankingChange = (value: string): void => {
    setFieldValue("ranking_id", value);
  };

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
    setCompanyWasCreated(companyWasCreated);
  }, [status, setCompanyWasCreated]);

  useEffect(() => {
    const newZipCode = findFiveDigitZipCode(placeAutocompleteFieldValue);
    newZipCode && setFieldValue("location.zip_code", newZipCode);
    newZipCode &&
      setFieldValue("location.full_address", placeAutocompleteFieldValue);
  }, [placeAutocompleteFieldValue]);

  const handleClose = (): void => {
    setIsSnackbarVisible(false);
    setCompanyWasCreated(false);
  };

  const isPropertyManagerRole = values.type_id === Number(propertyManagerId);

  const handleCreateCompanyModalClose = (): void => {
    setInitialValues(createCompanyFormInitState);
    setIsCreateCompanyModalOpen(false);
  };

  useEffect(() => {
    setSelectedContactType(values.type_id);
  }, [values.type_id]);

  useEffect(() => {
    companyAutocompleteValue !== null &&
      setFieldValue("company_id", companyAutocompleteValue?.id);
  }, [companyAutocompleteValue]);

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        {!isModal && (
          <FormSectionTitle>
            {t("createContact.contactDetails")}
          </FormSectionTitle>
        )}
        <Grid container spacing={6} columns={12} mb={6}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="salutation_id"
              select
              label={t("createContact.salutationId")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            >
              {salutations.map((salutation: Salutation) => (
                <MenuItem key={salutation.id} value={salutation.id}>
                  {salutation.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              name="first_name"
              label={t("createContact.firstName")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            />
            <TextField
              name="email"
              label={t("createContact.email")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            />
            <TextField
              name="mobile"
              label={t("createContact.mobile")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            />
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={8}>
                <CompanyAutocomplete
                  status={status}
                  name={"company_id"}
                  initialValue={companyAutocompleteValue}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    type="button"
                    testId="open-company-modal"
                    title={t("createContact.createCompany")}
                    onClick={() => setIsCreateCompanyModalOpen(true)}
                    sx={{ minHeight: "50px" }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="type_id"
              select
              label={t("createContact.contactType")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            >
              {contactTypes.map((contactType: ContactType) => (
                <MenuItem key={contactType.id} value={contactType.id}>
                  {contactType.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              name="last_name"
              label={t("createContact.lastName")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            />
            <TextField
              name="business_phone"
              label={t("createContact.businessPhone")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            />
            <TextField
              name="private_phone"
              label={t("createContact.privatePhone")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ mb: 3, mt: -5 }}>
            <Autocomplete
              fullWidth
              loading={loading}
              loadingText={`${t("loading")}...`}
              inputValue={placeInputValue ?? ""}
              popupIcon={loading ? <Loader size={25} /> : <ArrowDropDownIcon />}
              options={placePredictions!}
              isOptionEqualToValue={handleIsOptionEqualToValue}
              getOptionLabel={handleGetOptionLabel}
              filterOptions={handleFilterOptions}
              onChange={handleAutocompleteOnChange}
              renderOption={handleRenderOption}
              PaperComponent={autocompletePaperComponent}
              renderInput={handleRenderInput}
            />
          </Grid>
        </Grid>
        <FormSectionTitle>
          {t("createContact.additionalInformation")}
        </FormSectionTitle>
        <Grid container spacing={6} columns={12}>
          <Grid item xs={12} sm={4}>
            <TextField
              name="category"
              label={t("createContact.category")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "category-input" }}
            />
            <TextField
              name="worldwide_department_number"
              label={t("createContact.worldwideBranches")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "worldwide-branches-input" }}
            />
            <TextField
              name="department_number"
              label={t("createContact.branches")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "branches-input" }}
            />
            <TextField
              name="source"
              label={t("createContact.source")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "source-input" }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              name="assets"
              label={t("createContact.assetsInMillion")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "assets-in-million-input" }}
            />
            <TextField
              name="area"
              label={t("createContact.competentArea")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "competent-area-input" }}
            />
            <TextField
              name="website_link"
              label={t("createContact.websiteUrl")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "website-url-input" }}
            />
            <TextField
              name="purchase_link"
              label={t("createContact.purchaseProfileUrl")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "purchase-profile_url-input" }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="management"
              label={t("createContact.management")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "purchase-profile_url-input" }}
            />

            <TextField
              id="ranking_id"
              name="ranking_id"
              value={values.ranking_id ?? ""}
              select
              label={t("createContact.ranking")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleRankingChange(e.target.value)
              }
              placeholder={t("createContact.ranking")}
              data-testid="ranking-select"
            >
              {rankings.map((ranking: Ranking) => (
                <MenuItem key={ranking.id} value={ranking.id}>
                  {ranking.text}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              name="note"
              label={t("createContact.note")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "note-input" }}
            />
            <Autocomplete
              multiple
              freeSolo
              limitTags={1}
              id="tags-autocomplete"
              data-testid="tags-autocomplete"
              options={tags}
              value={tags}
              onChange={handleChangeTag}
              PaperComponent={StyledPaper}
              renderTags={(values) =>
                values.map((value) => (
                  <Tag
                    closeable
                    handleClose={() => handleRemoveTag(value)}
                    title={value.title}
                    key={value.title}
                  />
                ))
              }
              filterSelectedOptions
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="tags"
                    label={t("createContact.tags")}
                    placeholder={t("createContact.tags")}
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6} columns={12}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              freeSolo
              limitTags={1}
              id="focus-autocomplete"
              options={focuses}
              value={focuses}
              onChange={handleChangeFocus}
              PaperComponent={StyledPaper}
              renderTags={(values) =>
                values.map((value) => (
                  <Tag
                    closeable
                    handleClose={() => handleRemoveFocus(value)}
                    title={value.title}
                    key={value.title}
                  />
                ))
              }
              filterSelectedOptions
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="focus"
                    label={t("createContact.focus")}
                    placeholder={t("createContact.focus")}
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
        </Grid>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
          mt={3}
        >
          <Button
            type="button"
            color="primary"
            variant="text"
            title={t("cancel")}
            size="large"
            disabled={isSubmitting}
            onClick={() =>
              doesAnyHistoryEntryExist
                ? navigate(-1)
                : navigate(route("contacts"))
            }
          />
          <Button
            testId="contact-details-submit-button"
            title={t("update")}
            sx={{ ml: "1rem" }}
            color="success"
            type="submit"
            size="large"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
        </Stack>
        <Snackbar
          message={t(
            companyWasCreated
              ? "createContact.companyWasSuccessfullyCreated"
              : "createContact.contactWasSuccessfullyUpdated"
          )}
          color="success"
          open={isSnackbarVisible || companyWasCreated}
          handleClose={handleClose}
        />
      </form>
      <CreateCompanyModal
        isCreateCompanyModalOpen={isCreateCompanyModalOpen}
        handleCreateCompanyModalClose={handleCreateCompanyModalClose}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        isPropertyManagerRole={isPropertyManagerRole}
        setCompanyAutocompleteValue={setCompanyAutocompleteValue}
        setCompanyWasCreated={setCompanyWasCreated}
      />
    </>
  );
}

/* istanbul ignore next */
const handleRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  placePrediction: Prediction
): ReactNode => (
  <AutocompleteListItem {...props} key={placePrediction.description}>
    - {placePrediction.description}
  </AutocompleteListItem>
);

/* istanbul ignore next */
const handleIsOptionEqualToValue = (
  option: Prediction,
  value: Prediction
): boolean => option.place_id === value.place_id;
/* istanbul ignore next */
const handleGetOptionLabel = (placePrediction: Prediction): string =>
  placePrediction.description;
/* istanbul ignore next */
const handleFilterOptions = (options: Prediction[]): Prediction[] => options;

/* istanbul ignore next */
const autocompletePaperComponent = ({
  children,
}: {
  children?: ReactNode;
}): ReactElement | null => <Dropdown>{children}</Dropdown>;

export default Form;
