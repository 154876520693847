import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Typography, Dialog, DialogTitle, Box } from "@mui/material";
import { IdPropType, User } from "../../../../../../types/models";
import { Status as InvoiceStatus } from "../../../../../../types/be/status";
import { deleteInvoice, getInvoices } from "../../../../../../api/invoices";
import useTable from "../../../../../../ui/table/useTable";
import Table from "../../../../../../ui/table/Table";
import {
  prepareQueryParams,
  replaceNullOrUndefinedByEmptyString,
  getFilteredHeadCellsByProperty,
} from "../../../../../../utils/common";
import { handleServerError } from "../../../../../../utils/http";
import { useParams } from "react-router-dom";
import filter from "lodash/filter";
import { itemsListHeadCells } from "../../utils";
import useAppSelector from "../../../../../../hooks/useAppSelector";
import { Invoice } from "../../../../../../types/be.interfaces";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { setHiddenColumnIds } from "../../../../../../redux/slices/invoice";
import useAppDispatch from "../../../../../../hooks/useAppDispatch";
import useIsMounted from "../../../../../../hooks/useIsMounted";
import ForwardDocumentDialog from "../../../forwardDocument/ForwardDocumentDialog";
import Snackbar from "../../../../../../ui/Snackbar";
import {
  COMMENTABLE,
  FORM_SELECT_ALL_OPTION_ID,
} from "../../../../../../constants";
import CommentsPopup from "../../../../../../ui/commentsPopUp";
import { showCommentsModalInitState } from "../../../../../../ui/commentsPopUp/utils";
import PageWrapper from "../../../../../../ui/pageWrapper/PageWrapper";
import {
  ALL_STATUS,
  getItemsWithActiveReleasePermissions,
  getReleaseRoute,
  providerTableScrollerId,
} from "../../../../utils";
import {
  InvoiceFilterProps,
  ProviderInvoiceListFilter,
  ProviderInvoiceListProps,
} from "../../types";
import Loader from "../../../../../Loader";
import ContactShowDialog from "../../../../components/provider/contactShowDialog";
import InvoiceListRow from "../../InvoiceListRow";
import {
  DialogContainWrapper,
  DialogContent,
  DialogTitleContainer,
} from "../../../../../home/styled";
import CloseButton from "../../../../../../ui/dialog/CloseButton";
import ProviderInvoiceListToolbar from "./Toolbar";
import { childFilterInitValues } from "./utils";
import { PaidUnpaidFilter } from "../../../../types";
import { Contact } from "../../../../../contacts/edit/types";
import { useFetch } from "../../../../../../hooks/useFetch";
import { getContact } from "../../../../../../api/contacts";
import ProviderInfoTable from "../../../../components/provider/ProviderInfoTable";
import { ProviderInfoTableWrapper } from "../../../../components/provider/styled";
import HistoryPopup from "../../../../components/history/HistoryPopup";
import { HISTORY } from "../../../../components/history/types";

const historyModalInitState = {
  isVisible: false,
  historyId: 0,
};

/* istanbul ignore next */
const ProviderInvoiceList = ({
  onClose,
  parentFilter,
  configuration,
  moveToPaid,
  markAsBooked,
  statuses: statusesProp,
  releasePermission,
  handleForwardDocument,
  handleReleaseDocument,
  isDocumentReleaseLoading,
}: ProviderInvoiceListProps): ReactElement => {
  const [statuses, setStatuses] = useState<InvoiceStatus[]>([]);
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [childFilter, setChildFilter] = useState<ProviderInvoiceListFilter>(
    childFilterInitValues
  );
  const [showHistoryModal, setShowHistoryModal] = useState(
    historyModalInitState
  );
  const [forwardInvoiceId, setForwardInvoiceId] = useState<number | null>(null);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [isShowCommentsModal, setIsShowCommentsModal] = useState(
    showCommentsModalInitState
  );
  const { optionalColumnIds, selectedOptionalColumnsIds } = useAppSelector(
    (state) => state.invoice
  );

  const [contactShow, setContactShow] = useState<string | null>(null);

  const { data: contact, run: runContact } = useFetch<Contact>();

  useEffect(() => {
    parentFilter.contact_id &&
      runContact(getContact(String(parentFilter.contact_id)));
  }, []);

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleDeleteLastPageData,
  } = useTable<User>();

  useEffect(() => {
    if (!statusesProp) return;
    const newStatuses = [
      ALL_STATUS,
      ...statuses.filter((s) => s.code !== "new"),
    ];
    setStatuses(newStatuses);
  }, [statusesProp]);

  const fetchProviderInvoices = useCallback(
    async (loading = true) => {
      setIsLoading(loading);

      const params: InvoiceFilterProps = {};

      const { is_paid, fr_status_id, year, month } = childFilter;
      const { contact_id } = parentFilter;

      if (is_paid !== PaidUnpaidFilter.all) {
        params.is_paid = is_paid;
      }

      if (fr_status_id !== FORM_SELECT_ALL_OPTION_ID) {
        params.fr_status_id = fr_status_id;
      }

      if (year !== FORM_SELECT_ALL_OPTION_ID) {
        params.year = year;
      }

      if (month !== FORM_SELECT_ALL_OPTION_ID) {
        params.month = month;
      }

      if (id) {
        params.property_id = id;
      }

      if (contact_id) {
        params.contact_id = contact_id;
      }

      const response = await getInvoices(
        prepareQueryParams(
          queryParams,
          replaceNullOrUndefinedByEmptyString(params),
          true
        )
      );

      if (response.status !== 200) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        const resJson = await response.json();
        setTotal(resJson.meta.total);
        setData(resJson.data);
        setIsLoading(false);
      }
    },
    [
      queryParams,
      setIsLoading,
      setTotal,
      setData,
      setIsLoading,
      parentFilter,
      childFilter,
    ]
  );

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteInvoice(rowToDelete);
    handleDeleteLastPageData(fetchProviderInvoices);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteInvoice,
    fetchProviderInvoices,
    handleConfirmToRemoveModalClose,
  ]);

  const tableHeaderTitles = useMemo(() => {
    let filteredItems;
    filteredItems = itemsListHeadCells;
    filteredItems = filter(
      filteredItems,
      (o) =>
        !optionalColumnIds.includes(o.id) ||
        selectedOptionalColumnsIds.includes(o.id)
    );
    filteredItems = getFilteredHeadCellsByProperty(
      filteredItems,
      "property_name",
      id
    );

    return [
      ...getItemsWithActiveReleasePermissions(filteredItems, configuration),
    ];
  }, [selectedOptionalColumnsIds, configuration]);

  const handleEditClick = useCallback(
    ({ id: invoiceId }: IdPropType): void => {
      navigate(getReleaseRoute("invoices.edit", Number(id), invoiceId));
    },
    [id]
  );

  const handleOptionsSelectedChange = useCallback(
    (optionsSelected: string[]) => {
      dispatch(setHiddenColumnIds(optionsSelected));
    },
    []
  );

  const handleShowClick = useCallback(({ id: invoiceId }: IdPropType): void => {
    navigate(
      getReleaseRoute("invoices.show", id ? Number(id) : undefined, invoiceId)
    );
  }, []);

  const handleHistoryModal = ({ id: historyId }: IdPropType): void => {
    setShowHistoryModal({ isVisible: true, historyId });
  };

  const handleHistoryCloseModal = (): void => {
    setShowHistoryModal(historyModalInitState);
  };

  const handleForwardClick = useCallback(
    ({ id: invoiceId }: IdPropType): void => {
      setForwardInvoiceId(invoiceId);
    },
    []
  );

  const handleCloseForwardModal = useCallback(() => {
    setForwardInvoiceId(null);
  }, []);

  const handleCloseSnackbar = useCallback(() => {
    setSnackbarVisible(false);
  }, []);

  useEffect(() => {
    if (isMounted() && statuses.length) {
      fetchProviderInvoices();
    }
  }, [queryParams, childFilter, parentFilter, statuses.length]);

  const handleOpenComments = (row: Invoice): void => {
    setIsShowCommentsModal({ isVisible: true, rowId: row?.id });
  };

  const handleCloseCommentsModal = (): void => {
    setIsShowCommentsModal(showCommentsModalInitState);
    fetchProviderInvoices(false);
  };

  const onMoveToPaidSuccess = (): void => {
    fetchProviderInvoices();
  };

  const onMarkAsBookedSuccess = (): void => {
    fetchProviderInvoices();
  };

  const isMounted = useIsMounted();

  const TableToolbar = (
    <ProviderInvoiceListToolbar
      optionalColumnIds={optionalColumnIds}
      selectedOptionalColumnsIds={selectedOptionalColumnsIds}
      handleOptionsSelectedChange={handleOptionsSelectedChange}
      childFilter={childFilter}
      setChildFilter={setChildFilter}
      statuses={statuses}
    />
  );

  const renderRow = (row: Invoice): ReactElement => (
    <InvoiceListRow
      row={row}
      checkedDict={null}
      id={id}
      setContactShow={setContactShow}
      selectedOptionalColumnsIds={selectedOptionalColumnsIds}
      handleOpenComments={handleOpenComments}
      configuration={configuration}
      statuses={statuses}
      fetchInvoices={fetchProviderInvoices}
      moveToPaid={moveToPaid(onMoveToPaidSuccess)}
      markAsBooked={markAsBooked(onMarkAsBookedSuccess)}
      handleCellClick={handleCellClick}
      handleHistoryModal={handleHistoryModal}
      handleForwardClick={handleForwardClick}
      handleShowClick={handleShowClick}
      handleEditClick={handleEditClick}
      openConfirmToRemoveModal={openConfirmToRemoveModal}
      handleForwardDocument={handleForwardDocument}
      handleReleaseDocument={handleReleaseDocument}
      releasePermission={releasePermission}
      isDocumentReleaseLoading={isDocumentReleaseLoading}
    />
  );

  const breadcrumbs: { to: string; name: string }[] = [];

  const pageTitle = "";

  if (!statuses.length) {
    return <Loader />;
  }

  return (
    <Dialog
      role={"provider-invoices-list-modal"}
      fullWidth
      open={Boolean(parentFilter.contact_id)}
      onClose={onClose}
      maxWidth="xl"
    >
      <DialogTitle sx={{ pt: 0, pb: 0 }}>
        <DialogTitleContainer>
          <Typography
            variant="h6"
            component="div"
            justifyContent="space-around"
            width="100%"
          >
            Infos
          </Typography>
          <CloseButton onClick={onClose} />
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        <div>
          <DialogContainWrapper>
            <PageWrapper breadcrumbs={breadcrumbs} title={pageTitle}>
              <>
                {contact && (
                  <Box sx={{ mb: 6.5 }}>
                    <ProviderInfoTableWrapper>
                      <ProviderInfoTable contact={contact} />
                    </ProviderInfoTableWrapper>
                  </Box>
                )}
                <Table
                  data={data}
                  total={total}
                  currentPage={currentPage}
                  order={order}
                  orderBy={orderBy}
                  error={error}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  onPageChange={handleChangePage}
                  onRowRemove={handleRowRemove}
                  onSortChange={handleSortChange}
                  onSelectAllClick={handleSelectAllClick}
                  onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
                  rowsPerPage={rowsPerPage}
                  isLoading={isLoading}
                  isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
                  listHeadCells={tableHeaderTitles}
                  renderRow={renderRow}
                  tableToolbar={TableToolbar}
                  noDataIsAvailablePlaceholder={t(
                    "table.noInvoicesAreAvailable"
                  )}
                  confirmationText={t(
                    "documentRelease.invoice.deleteConfirmation"
                  )}
                  refId={`hzScroll${providerTableScrollerId}`}
                />
                {forwardInvoiceId ? (
                  <ForwardDocumentDialog
                    isOpen={true}
                    handleCloseForwardModal={handleCloseForwardModal}
                    entityId={forwardInvoiceId}
                    entityType="invoices"
                    setSnackbarVisible={setSnackbarVisible}
                    title={t("documentRelease.invoice.forwardInvoice")}
                  />
                ) : null}
                <Snackbar
                  message={t("documentRelease.invoice.invoiceForwardSuccess")}
                  color="success"
                  open={snackbarVisible}
                  handleClose={handleCloseSnackbar}
                  data-testid="snackbar-invoice-success"
                />
                {isShowCommentsModal.isVisible && (
                  <CommentsPopup
                    handleCloseDialog={handleCloseCommentsModal}
                    id={String(isShowCommentsModal?.rowId)}
                    relation={COMMENTABLE.INVOICE}
                  />
                )}
                {showHistoryModal.isVisible && (
                  <HistoryPopup
                    handleCloseDialog={handleHistoryCloseModal}
                    historyId={String(showHistoryModal?.historyId)}
                    documentRelease={HISTORY.INVOICE}
                  />
                )}
                {contactShow && (
                  <ContactShowDialog
                    id={contactShow}
                    onClose={() => setContactShow(null)}
                    open={true}
                  />
                )}
              </>
            </PageWrapper>
          </DialogContainWrapper>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProviderInvoiceList;
