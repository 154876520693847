import React, {
  ChangeEvent,
  Dispatch,
  ReactElement,
  useEffect,
  useRef,
} from "react";
import { Chat as BEChat } from "../../types/be/chat";
import Chat from "./Chat";
import { Box, Grid, Pagination } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import useAppSelector from "../../hooks/useAppSelector";
import CKEditor from "../ckeditor";
import useCKEditor from "../ckeditor/useCKEditor";
import Fancybox from "../fancybox";
import { ChatPopover } from "./styled";

/* istanbul ignore next */
const mentionStyle = (strings: TemplateStringsArray, color: string): string =>
  `background-color: ${color}; border-radius: 10px; color: white; padding: 1px 5px`;
/* istanbul ignore next */
const imageStyles =
  "width: 100%;max-width: 300px;height: auto; cursor: pointer";
/* istanbul ignore next */
const figureStyles = "display:flex; justify-content: center";

/* istanbul ignore next */
const Chats = ({
  chats,
  currentPage,
  totalPages,
  handlePaginationChange,
  editorContent,
  setEditorContent,
  handleSaveChat,
  handleChatRead,
  chatDisabled,
}: {
  chats: BEChat[];
  handlePaginationChange: (event: ChangeEvent<unknown>, page: number) => void;
  editorContent: string;
  totalPages: number;
  currentPage: number;
  setEditorContent: Dispatch<React.SetStateAction<string>>;
  handleSaveChat: () => Promise<void>;
  handleChatRead: (chat: BEChat) => Promise<void>;
  chatDisabled: boolean;
}): ReactElement => {
  const chatRef = useRef();
  const { usersFeed } = useCKEditor();
  const { _user } = useAppSelector((state) => state.user);
  useEffect(() => {
    const mentions = document.getElementsByClassName("mention");
    for (let i = 0; i < mentions.length; i++) {
      if (
        //@ts-ignore
        mentions[i].attributes["data-user-id"].value == _user?.id
      ) {
        //@ts-ignore
        mentions[i].style = mentionStyle`${blue[800]}`;
      } else {
        //@ts-ignore
        mentions[i].style = mentionStyle`${grey[600]}`;
      }
    }
  });

  useEffect(() => {
    if (chatRef && chatRef.current) {
      //@ts-ignore
      const figures: HTMLElement[] = chatRef.current?.getElementsByTagName("figure");
      figures.length && figures.forEach(figure => {
        figure.setAttribute("style", figureStyles);
      });
      //@ts-ignore
      const images: HTMLElement[] = chatRef.current?.getElementsByTagName("img");
      images.length && images.forEach(image => {
        image.setAttribute("style", imageStyles);
        image.setAttribute("data-fancybox", "gallery");
        image.setAttribute("data-src", image.getAttribute("src")!);
      });
      //@ts-ignore
      const links: HTMLElement[] = chatRef.current?.getElementsByTagName("a");
      links.length && links.forEach(link => {
        link.setAttribute("target", "_blank");
      });
    }
  }, [chats]);

  return (
    <>
      <ChatPopover>
      {!chatDisabled && (
          <CKEditor
            feeds={[usersFeed]}
            content={editorContent}
            onChange={(event: any, editor: any): void => {
              setEditorContent(editor.getData());
            }}
            handleSave={handleSaveChat}
            placeHolder="message"
          />
        )}
        {chats && (
          <Fancybox options={{ infinite: false }}>
            <Box sx={{ py: 3, pl: 1 }} ref={chatRef}>
              {chats.map((chat: BEChat) => (
                <Chat
                  chat={chat}
                  key={`chat-${chat.id}`}
                  handleChatRead={handleChatRead}
                />
              ))}
              {totalPages > 1 && (
                <Grid container justifyContent={"flex-end"}>
                  <Pagination
                    shape="rounded"
                    page={currentPage}
                    count={totalPages}
                    size="small"
                    onChange={handlePaginationChange}
                  />
                </Grid>
              )}
            </Box>
          </Fancybox>
        )}
      </ChatPopover>
    </>
  );
};

export default Chats;
