import React, { ReactElement, useEffect, useState } from "react";
import { Box, Button, Grid, Pagination } from "@mui/material";
import { Counter } from "../../../../types/be/counter";
import { getCounterList } from "../../../../api/reading";
import { LoadingBox, NoDataFoundContainer } from "./styled";
import Loader from "../../../Loader";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import {
  FormMessageInItState,
  NO_DATA_IS_AVAILABLE,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";

import { Trash2, Eye } from "react-feather";
import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Counter1 } from "./Counter1";
import CreateCounterDialog from "../create";
import {
  prepareQueryParams,
  replaceNullOrUndefinedByEmptyString,
} from "../../../../utils/common";
import Snackbar from "../../../../ui/Snackbar";

export const ShowIcon = styled(Eye)`
  margin-left: 10px;
  width: 15px;
  &:hover {
    color: ${(props): string => props.theme.palette.grey[400]};
    cursor: pointer;
  }
  transition: color 150ms;
`;

export const DeleteIcon = styled(Trash2)<{ ml?: string }>`
  color: ${(props): string => props.theme.palette.red[400]};
  margin-left: ${(props): string => props.ml ?? "10"}px;
  width: 15px;
  &:hover {
    color: ${(props): string => props.theme.palette.red.main};
    cursor: pointer;
  }
  transition: color 150ms;

  &.disabled {
    color: ${(props): string => props.theme.palette.red[400]};
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const CountersList = (): ReactElement => {
  const { t } = useTranslation();
  const { areaId } = useParams();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [counterList, setCounterList] = useState<Counter[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const limit = 9;

  const fetchCounters = async (): Promise<void> => {
    setIsLoading(true);
    const params = prepareQueryParams(
      "",
      replaceNullOrUndefinedByEmptyString({
        page: currentPage,
        limit,
        property_area_id: areaId,
      })
    );
    const response = await getCounterList(params);
    const result = await response.json();
    if ([200, 201].includes(response.status)) {
      setCounterList(result?.data);
      setLastPage(result?.meta?.last_page);
      setFormMessage(FormMessageInItState);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
    }
    setIsLoading(false);
  };

  const handlePaginationChange = (_: unknown, page: number): void => {
    setCurrentPage(page);
  };

  const afterCreateCounter = (): void => {
    setCurrentPage(1);
    setIsSnackbarVisible(true);
    fetchCounters();
  };

  useEffect(() => {
    fetchCounters();
  }, [currentPage]);

  return (
    <>
      <Grid container sx={{ pt: 5 }}>
        {formMessage.text ? <FormAlert formMessage={formMessage} /> : null}
        <Grid item>
          <Button
            data-testid={"dataItems-link"}
            size="small"
            color="success"
            variant="contained"
            onClick={() => setIsCreateModalVisible(true)}
          >
            {t("create")}
          </Button>
        </Grid>
        <Grid />
      </Grid>

      <Grid
        container
        sx={{ pt: 2 }}
        spacing={{ xs: 1 }}
        display={"flex"}
        flexWrap={"wrap"}
      >
        {isLoading ? (
          <LoadingBox>
            <Loader />
          </LoadingBox>
        ) : (
          <>
            {counterList?.length ? (
              counterList?.map((item: Counter, index) => (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={index}>
                  <Counter1
                    id={item?.id}
                    number={item?.number}
                    lastReading={item?.last_reading}
                    type={item?.type?.name}
                  />
                </Grid>
              ))
            ) : (
              <Grid xs={12} item justifyContent={"center"} alignItems="center">
                <NoDataFoundContainer>
                  <Box>{t(NO_DATA_IS_AVAILABLE)}</Box>
                </NoDataFoundContainer>
              </Grid>
            )}
            {counterList?.length && lastPage ? (
              <Grid container justifyContent={"center"} sx={{ margin: 2 }}>
                <Pagination
                  shape="rounded"
                  page={currentPage}
                  count={lastPage}
                  size="small"
                  onChange={handlePaginationChange}
                />
              </Grid>
            ) : null}
          </>
        )}
      </Grid>
      {isCreateModalVisible ? (
        <CreateCounterDialog
          isVisible={isCreateModalVisible}
          setIsVisible={setIsCreateModalVisible}
          onSuccess={afterCreateCounter}
        />
      ) : null}
      <Snackbar
        message={t("counter.create.sucessMessage")}
        color="success"
        open={isSnackbarVisible}
        handleClose={() => setIsSnackbarVisible(false)}
      />
    </>
  );
};

export default CountersList;
