import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Grid, Paper } from "@mui/material";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import Form from "./Form";
import Loader from "../../../Loader";
import { getInsurance } from "../../../../api/insurance";
import { useParams } from "react-router-dom";
import { Insurance } from "../../../../types/fe.interfaces";
import { handleUpdate } from "./utils";
import { useTranslation } from "react-i18next";
import { route } from "../../../../utils/url";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import Snackbar from "../../../../ui/Snackbar";
import FileTreeDialog from "../../../../ui/fileList/index";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import useValidation from "../../../../hooks/useValidation";
import useAccessControl from "../../../../hooks/useAccessControl";
import { getReleaseRoute } from "../../utils";
import TabPageWrapper from "../../../../ui/tabPageWrapper";
import useUploadDropzone from "../../../../hooks/useUploadDropzone";
import PdfViewerWrapper from "../../components/PdfViewerWrapper";

const InsuranceEdit = (): ReactElement => {
  const { t } = useTranslation();
  const { id, insuranceId } = useParams();
  const { isRole } = useAccessControl();

  const [insuranceData, setInsuranceData] = useState<Insurance | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const { contact_id, amount, cancellation_period } = useValidation();
  const formRef = useRef<FormikProps<Insurance> | null>(null);
  const {
    isFileTreeVisible,
    handleOpenFileTree,
    handleCloseFileTree,
    fileName,
    setFileName,
    selectedFileId,
    setSelectedFileId,
  } = useUploadDropzone({ file: insuranceData?.file });

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("insurances", Number(id), Number(insuranceId)),
      name: t("property.navLinks.insurances"),
    },
  ];

  const handleFetchInsurance = useCallback(async () => {
    setIsLoading(true);
    const response = await getInsurance(insuranceId!);
    if (response.status !== 200) {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
    } else {
      const { data } = await response.json();
      setInsuranceData(data);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleFetchInsurance();
  }, []);

  const handleClose = useCallback(() => {
    setIsSnackbarVisible(false);
  }, []);
  /* istanbul ignore next */
  const handleFileTreeClick = async (
    file_id: string,
    file_name: string
  ): Promise<void> => {
    formRef?.current?.setFieldValue("file_id", parseInt(file_id));
    setFileName(file_name);
    handleCloseFileTree();
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <TabPageWrapper
        title={t("insurance.editTitle")}
        breadcrumbs={breadcrumbs}
        wrap={!id}
      >
        {insuranceData ? (
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper sx={{ padding: 6 }}>
                <Grid container justifyContent={"center"}>
                  {
                    /* istanbul ignore next */ selectedFileId && (
                      <Grid item sm={6} xs={12}>
                        <PdfViewerWrapper data={selectedFileId} type="fileId" />
                      </Grid>
                    )
                  }
                  <Grid item xs={12} sm={6} sx={{ pl: 6 }}>
                    {formMessage?.text ? (
                      <FormAlert formMessage={formMessage} sx={{ mb: 4 }} />
                    ) : (
                      <></>
                    )}
                    <Formik
                      initialValues={{
                        ...insuranceData,
                        file_id: insuranceData.file.id,
                      }}
                      innerRef={formRef}
                      onSubmit={handleUpdate({
                        insuranceId: insuranceId!,
                        setIsSnackbarVisible,
                        setFormMessage,
                        isAssetManager: isRole("asset_manager"),
                      })}
                      validationSchema={Yup.object().shape({
                        contact_id,
                        amount,
                        cancellation_period,
                      })}
                    >
                      {(props) => (
                        <Form
                          {...props}
                          handleOpenDialog={handleOpenFileTree}
                          fileName={fileName}
                          setFileName={setFileName}
                          setSelectedFileId={setSelectedFileId}
                        />
                      )}
                    </Formik>
                    {
                      /* istanbul ignore next */ isFileTreeVisible && (
                        <FileTreeDialog
                          onClose={handleCloseFileTree}
                          handleFileTreeClick={handleFileTreeClick}
                          extensions={["pdf"]}
                        />
                      )
                    }
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        ) : formMessage?.text ? (
          <FormAlert formMessage={formMessage} />
        ) : (
          <></>
        )}
      </TabPageWrapper>
      <Snackbar
        message={t("insurance.editSuccess")}
        color="success"
        open={isSnackbarVisible}
        handleClose={handleClose}
        data-testid="insurance-edit-success"
      />
    </>
  );
};

export default InsuranceEdit;
