import React, { ReactElement } from "react";
import { NumericFormat } from "react-number-format";
import { TableCell, Tooltip, Typography } from "@mui/material";

import { AddressBox, ObjectLink } from "../styled";
import { route } from "../../../../utils/url";
import {
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  Measurement_Units,
  PermissionsDelete,
  THOUSAND_SEPARATOR,
} from "../../../../constants";
import { DeleteIcon } from "../../../../ui/table/styled";
import { ListViewProps } from "./types";
import useAccessControl from "../../../../hooks/useAccessControl";

/* istanbul ignore next */
const PropertyListView = ({
  row,
  isTenant,
  handleCellClick,
  openConfirmToRemoveModal,
}: ListViewProps): ReactElement => {
  const { can } = useAccessControl();

  return (
    <>
      <TableCell align="left">
        <ObjectLink
          to={
            !isTenant
              ? route("properties.edit", row.id, "general")
              : route("properties.areas", row.id)
          }
          key={row.id}
        >
          <Typography noWrap>{row.object_name}</Typography>
        </ObjectLink>
      </TableCell>
      <TableCell align="left">
        {row.location.full_address ? (
          <Tooltip title={`${row.location.full_address}`}>
            <AddressBox>{`${row.location.full_address}`}</AddressBox>
          </Tooltip>
        ) : (
          EMPTY_DATA
        )}
      </TableCell>
      <TableCell align="left">{row.property_type.name}</TableCell>
      {!isTenant && (
        <>
          <TableCell align="right">
            <NumericFormat
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              displayType={"text"}
              value={row.rented_area}
            />
            {row.rented_area !== null && Measurement_Units.SQUARE_METER}
          </TableCell>
          <TableCell align="right">
            <NumericFormat
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              displayType={"text"}
              value={row.total_area}
            />
            {row.total_area !== null && Measurement_Units.SQUARE_METER}
          </TableCell>
          <TableCell align="right">
            <NumericFormat
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              displayType={"text"}
              value={row.total_area_percentage}
            />
            {row.total_area_percentage !== null && Measurement_Units.PERCENTAGE}
          </TableCell>
          <TableCell align="right">
            <NumericFormat
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              fixedDecimalScale
              decimalScale={DECIMAL_SCALE}
              displayType={"text"}
              value={row.net_rent_per_year}
            />
            {row.net_rent_per_year !== null && Measurement_Units.EURO}
          </TableCell>
          <TableCell align="right">
            <NumericFormat
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              fixedDecimalScale
              decimalScale={DECIMAL_SCALE}
              displayType={"text"}
              value={row.potencial_per_year}
            />
            {row.potencial_per_year !== null && Measurement_Units.EURO}
          </TableCell>
          <TableCell align="right">
            <NumericFormat
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              displayType={"text"}
              value={row.wault}
            />
          </TableCell>
          <TableCell
            align="right"
            onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
              handleCellClick(e)
            }
            style={{ whiteSpace: "nowrap" }}
          >
            {can(PermissionsDelete.PROPERTY) && (
              <DeleteIcon
                onClick={() => openConfirmToRemoveModal(row)}
                size={20}
                data-testid="delete-icon"
              />
            )}
          </TableCell>
        </>
      )}
    </>
  );
};

export default PropertyListView;
