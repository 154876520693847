import {
  Badge,
  Card as MuiCard,
  Grid,
  TableCell as MUITableCell,
} from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

export const TableCell = styled(MUITableCell).attrs(
  (props: { mw?: string }) => ({
    mw: props.mw || "auto",
  })
)`
  min-width: ${(props): string => {
    return props.mw;
  }};
`;

export const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

export const Root = styled(Grid)`
  justify-content: center;
  align-items: center;
  display: flex;
  max-width: 1000px;
`;

/*istambul ignore next*/
export const MessageBadgeCounter = styled(Badge)`
  cursor: pointer;
`;
