export const propertiesHeadCells = [
  {
    id: "object_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "propertiesList.objectName",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "address",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "",
  },
];
