import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Grid, Checkbox, MenuItem, TextField, Tooltip } from "@mui/material";
import { IdPropType, User } from "../../../../types/models";
import { Status as InvoiceStatus } from "../../../../types/be/status";
import {
  deleteInvoice,
  getInvoices,
  getInvoiceStatuses,
  toggleBulkInvoicePaidArray,
  toogleInvoicePaid,
  updateBulkInvoiceStatus,
  releaseInvoice,
  updateInvoice,
} from "../../../../api/invoices";
import useTable from "../../../../ui/table/useTable";
import Table from "../../../../ui/table/Table";
import {
  FILTER_TYPE,
  prepareQueryParams,
  RELEASE_LEVEL,
  replaceNullOrUndefinedByEmptyString,
  getFilteredHeadCellsByProperty,
  generalPropertyOption,
} from "../../../../utils/common";
import { handleServerError } from "../../../../utils/http";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import filter from "lodash/filter";
import {
  getCheckboxItemListHeadCell,
  itemsListHeadCells,
  providerInvoiceListFilterInit,
  selectAllInit,
} from "./utils";
import useAppSelector from "../../../../hooks/useAppSelector";
import useAccessControl from "../../../../hooks/useAccessControl";
import { Invoice } from "../../../../types/be.interfaces";
import { useTranslation } from "react-i18next";
import { Input, Search, SearchIconWrapper } from "../../../../ui/table/styled";
import StatusButtons from "../../components/statusButtons";
import { route } from "../../../../utils/url";
import { useNavigate } from "react-router";
import MultiSelect from "../../components/multiSelect";
import { Search as SearchIcon } from "react-feather";
import { setHiddenColumnIds } from "../../../../redux/slices/invoice";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import find from "lodash/find";
import snakeCase from "lodash/snakeCase";
import useIsMounted from "../../../../hooks/useIsMounted";
import { CustomButton } from "./styled";
import ForwardDocumentDialog from "../forwardDocument/ForwardDocumentDialog";
import Snackbar from "../../../../ui/Snackbar";
import MuiFileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  COMMENTABLE,
  STARTING_PAGE,
  UserRoles,
  RELEASE_STATUS,
  FORWADED_STATUS,
  REQUEST_STATUS_IDS,
  FORWARDED_ROUTE,
  PermissionsCreate,
  HISTORY_STATUS,
  APP_ENVIRONMENTS,
} from "../../../../constants";
import CommentsPopup from "../../../../ui/commentsPopUp";
import { showCommentsModalInitState } from "../../../../ui/commentsPopUp/utils";
import { getProperties } from "../../../../api/property";
import {
  getItemsWithActiveReleasePermissions,
  getReleaseRoute,
  hasPermissionReleaseButtonTableCell,
  paidUnpaidFilter,
  RELEASE_BUTTONS_FR,
} from "../../utils";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  CheckedDict,
  InvoiceFilterProps,
  ProviderInvoiceListFilter,
} from "./types";
import last from "lodash/last";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import DotMenu from "../../components/releaseDotMenu";
import { downloadZipFile } from "../../../../api/files";
import Loader from "../../../Loader";
import { StyledButton } from "../../../../ui/button/styled";
import { DOCUMENT_RELEASE_MODULE } from "../../../../types/be/releaseFlow";
import HZScrollController from "../../../../ui/HZScrollController";
import Dropdown from "../../../../ui/dropdown";
import { usePropertySelect } from "../../../../hooks/usePropertySelect";
import ContactShowDialog from "../../components/provider/contactShowDialog";
import InvoiceListRow from "./InvoiceListRow";
import ProviderInvoiceList from "./providerInvoice/list";
import { PaidUnpaidFilter } from "../../types";
import useDocumentRelease from "../../components/documentRelease/useDocumentRelease";
import TabPageWrapper from "../../../../ui/tabPageWrapper";
import HistoryList from "../../components/history/HistoryList";
import { HISTORY } from "../../components/history/types";
import HistoryPopup from "../../components/history/HistoryPopup";
import { isAppEnv } from "../../../../utils/env";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const historyModalInitState = {
  isVisible: false,
  historyId: 0,
};

/* istanbul ignore next */
const InvoiceList = ({
  useOnWidget = false,
}: {
  useOnWidget?: boolean;
}): ReactElement => {
  const [statuses, setStatuses] = useState<InvoiceStatus[]>([]);
  const { t } = useTranslation();
  const { id, status } = useParams();
  const [searchParams] = useSearchParams();
  const {
    configuration,
    isConfigurationLoading,
    handleReleaseDocument,
    handleForwardDocument,
    releasePermission,
    snackbar,
    loading: isDocumentReleaseLoading,
  } = useDocumentRelease(DOCUMENT_RELEASE_MODULE.INVOICE);

  const dispatch = useAppDispatch();
  const { can, isRole } = useAccessControl();
  const navigate = useNavigate();
  const [activeStatusId, setActiveStatusId] = useState<number | null>(null);
  const [activeStatusCode, setActiveStatusCode] = useState<string | undefined>(
    undefined
  );
  const [showHistoryModal, setShowHistoryModal] = useState(
    historyModalInitState
  );
  const [forwardInvoiceId, setForwardInvoiceId] = useState<number | null>(null);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [isShowCommentsModal, setIsShowCommentsModal] = useState(
    showCommentsModalInitState
  );
  const [checkedDict, setCheckedDict] = useState<CheckedDict | null>(null);
  const [checkedIsPaid, setCheckedIsPaid] = useState<CheckedDict | null>(null);
  const [selectAll, setSelectAll] = useState(selectAllInit);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const { optionalColumnIds, selectedOptionalColumnsIds } = useAppSelector(
    (state) => state.invoice
  );

  const [isPaid, setIsPaid] = useState<string>(
    searchParams?.get("is_paid") || PaidUnpaidFilter.all
  );
  const [contactShow, setContactShow] = useState<string | null>(null);
  const [providerInvoiceListFilter, setProviderInvoiceListFilter] =
    useState<ProviderInvoiceListFilter>(providerInvoiceListFilterInit);
  const [loadingIds, setLoadingIds] = useState<number[]>([]);

  const { propertyId, property, isGeneral, onPropertySelect } =
    usePropertySelect(id);

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    setCurrentPage,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleDeleteLastPageData,
  } = useTable<User>({ loading: true });

  const userHasFinalReleasePermissions = useMemo(() => {
    return hasPermissionReleaseButtonTableCell(
      RELEASE_BUTTONS_FR,
      configuration,
      isRole
    );
  }, [configuration, isRole]);

  useEffect(() => {
    configuration && getStatuses();
  }, [configuration]);

  useEffect(() => {
    statuses.length && setActiveStatusByStatus();
  }, [status, statuses]);

  const resetCheckedDict = useCallback(
    (value = false): void => {
      /* istanbul ignore next */
      if (!data) return;
      const auxCheckedDict: CheckedDict = {};
      const auxCheckedIsPaid: CheckedDict = {};
      data.forEach((el: Invoice) => {
        auxCheckedDict[el.id] = value;
      });
      data.forEach((el: Invoice) => {
        auxCheckedIsPaid[el.id] = el.is_paid;
      });
      setCheckedDict(auxCheckedDict);
      setCheckedIsPaid(auxCheckedIsPaid);
    },
    [data]
  );

  const resetSelectAll = (): void => setSelectAll(selectAllInit);

  useEffect(() => {
    resetCheckedDict();
    resetSelectAll();
  }, [resetCheckedDict]);

  const getStatuses = useCallback(async () => {
    const historyStatus = isRole(UserRoles.ADMIN) ? [HISTORY_STATUS] : [];

    setStatuses([
      FORWADED_STATUS,
      ...(await (await getInvoiceStatuses()).json()).data,
      ...historyStatus,
    ]);
  }, []);

  const fetchInvoices = useCallback(
    async (loading = true) => {
      if (activeStatusId === HISTORY_STATUS.id) return;

      setIsLoading(loading);

      const params: InvoiceFilterProps = {
        fr_status_id: activeStatusId,
      };

      if (isGeneral === FILTER_TYPE.GENERAL || propertyId || id) {
        params.property_id = propertyId || id || "";
      }

      if (isPaid !== PaidUnpaidFilter.all) {
        params.is_paid = isPaid;
      }

      if (activeStatusId === -1) {
        params.is_was_forwarded = 1;
        params.fr_status_id = REQUEST_STATUS_IDS.NEW;
      } else {
        params.fr_status_id = activeStatusId;
      }

      const response = await getInvoices(
        prepareQueryParams(
          queryParams,
          replaceNullOrUndefinedByEmptyString(params),
          true
        )
      );

      if (response.status !== 200) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        const resJson = await response.json();
        setTotal(resJson.meta.total);
        setData(resJson.data);
        setIsLoading(false);
      }
    },
    [
      propertyId,
      activeStatusId,
      queryParams,
      setIsLoading,
      setTotal,
      setData,
      setIsLoading,
      status,
      isGeneral,
      isPaid,
    ]
  );

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteInvoice(rowToDelete);
    handleDeleteLastPageData(fetchInvoices);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteInvoice,
    fetchInvoices,
    handleConfirmToRemoveModalClose,
  ]);

  const tableHeaderTitles = useMemo(() => {
    const checkboxItemListHeadCell = getCheckboxItemListHeadCell(
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        checked={selectAll}
        data-testid={"select-all"}
        onChange={({
          target: { checked },
        }: React.ChangeEvent<HTMLInputElement>) => {
          setSelectAll(checked);
          resetCheckedDict(checked);
        }}
      />
    );

    let filteredItems;
    filteredItems = itemsListHeadCells;
    filteredItems = filter(
      filteredItems,
      (o) =>
        !optionalColumnIds.includes(o.id) ||
        selectedOptionalColumnsIds.includes(o.id)
    );
    filteredItems = getFilteredHeadCellsByProperty(
      filteredItems,
      "property_name",
      id
    );

    return [
      checkboxItemListHeadCell,
      ...getItemsWithActiveReleasePermissions(filteredItems, configuration),
    ];
  }, [selectedOptionalColumnsIds, resetCheckedDict, selectAll, configuration]);

  /* istanbul ignore next */
  const handleEditClick = useCallback(
    ({ id: invoiceId, has_datev, datev_invoice_id }: Invoice): void => {
      if (has_datev && datev_invoice_id) {
        navigate(
          getReleaseRoute("invoices.edit.datev", Number(id), datev_invoice_id)
        );
      } else {
        navigate(getReleaseRoute("invoices.edit", Number(id), invoiceId));
      }
    },
    []
  );

  const handleOptionsSelectedChange = useCallback(
    (optionsSelected: string[]) => {
      dispatch(setHiddenColumnIds(optionsSelected));
    },
    []
  );

  /* istanbul ignore next */
  const handleShowClick = useCallback(
    ({ id: invoiceId, has_datev, datev_invoice_id }: Invoice): void => {
      if (has_datev && datev_invoice_id) {
        navigate(
          getReleaseRoute(
            "invoices.show.datev",
            id ? Number(id) : undefined,
            datev_invoice_id
          )
        );
      } else {
        navigate(
          getReleaseRoute(
            "invoices.show",
            id ? Number(id) : undefined,
            invoiceId
          )
        );
      }
    },
    []
  );

  const handleReleaseButtonClick =
    (onSuccess: () => void) =>
    async (
      invoiceId: number | number[],
      status: string,
      level: RELEASE_LEVEL
    ): Promise<void> => {
      const beforeLoadingIds = [...loadingIds];
      if (Array.isArray(invoiceId)) {
        setLoadingIds([...loadingIds, ...invoiceId]);
      } else {
        setLoadingIds([...loadingIds, invoiceId]);
      }

      const statusObj = find(statuses, (o) => o.code === status);
      const params = prepareQueryParams("", {
        status_code: status,
      });
      statusObj &&
        (Array.isArray(invoiceId)
          ? await updateBulkInvoiceStatus(invoiceId, statusObj.id)
          : await releaseInvoice(String(invoiceId), level, params));
      onSuccess();
      setLoadingIds(beforeLoadingIds);
    };

  const onHandleReleaseButtonClickSuccess = (): void => {
    resetCheckedDict();
    resetSelectAll();
    handleDeleteLastPageData(fetchInvoices);
  };

  const handleHistoryModal = ({ id: historyId }: IdPropType): void => {
    setShowHistoryModal({ isVisible: true, historyId });
  };

  const handleHistoryCloseModal = (): void => {
    setShowHistoryModal(historyModalInitState);
  };

  const handleForwardClick = useCallback(
    ({ id: invoiceId }: IdPropType): void => {
      setForwardInvoiceId(invoiceId);
    },
    []
  );

  const handleCloseForwardModal = useCallback(() => {
    setForwardInvoiceId(null);
  }, []);

  const handleCloseSnackbar = useCallback(() => {
    setSnackbarVisible(false);
  }, []);

  useEffect(() => {
    if (isMounted() && statuses.length) {
      fetchInvoices();
    }
  }, [queryParams, activeStatusId, propertyId, isGeneral, isPaid]);

  const setActiveStatusByStatus = (): void => {
    if (status === "history") {
      setActiveStatusId(HISTORY_STATUS.id);
    } else {
      const activeStatus = find(statuses, (o) => o.code === snakeCase(status));

      setActiveStatusId(activeStatus?.id || FORWADED_STATUS.id);
      setActiveStatusCode(activeStatus?.code || FORWADED_STATUS.code);
    }
  };

  const handleOpenComments = (row: Invoice): void => {
    setIsShowCommentsModal({ isVisible: true, rowId: row?.id });
  };

  const handleCloseCommentsModal = (): void => {
    setIsShowCommentsModal(showCommentsModalInitState);
    fetchInvoices(false);
  };

  const handleStatusChange = (statusId: number, statusCode: string): void => {
    setCurrentPage(STARTING_PAGE);
    setActiveStatusId(statusId);
    setActiveStatusCode(statusCode);
  };
  const handleItemCheckbox =
    (id: number, is_paid: boolean) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setCheckedDict((state) => ({ ...state, [id]: event.target.checked }));
      setCheckedIsPaid((state) => ({
        ...state,
        [id]: event.target.checked && is_paid,
      }));
      resetSelectAll();
    };

  const getListInvoicesParamValues = useCallback((): string[] => {
    /* istanbul ignore next */
    if (!checkedDict) return [];
    const invoiceIdsToDownload = Object.keys(checkedDict)
      .filter((el) => checkedDict[el])
      .map((el) => Number(el));

    return data
      ?.filter(
        ({ id, file }: Invoice) =>
          invoiceIdsToDownload.includes(id) && file?.url
      )
      .map(({ file: { url } }: Invoice) => last(url.split("/")))
      .filter(
        (el: string, index: number, self: string[]) =>
          self.indexOf(el) === index
      );
  }, [checkedDict, data]);

  const handleDownload = async (): Promise<void> => {
    const listInvoicesParamValues = getListInvoicesParamValues();
    if (!listInvoicesParamValues.length) return;
    setIsArchiveLoading(true);
    const response = await downloadZipFile(listInvoicesParamValues);
    if (
      ![HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      const { errorMessage } = handleServerError(response);
      setIsArchiveLoading(false);
      return setError(errorMessage);
    }
    const blob = await response.blob();
    const href = window.URL.createObjectURL(blob);
    setIsArchiveLoading(false);
    const link = document.createElement("a");
    link.href = href;
    document.body.appendChild(link);
    link.click();
    link.href = "";
    resetCheckedDict();

    resetSelectAll();
  };

  const moveToPaid =
    (onSuccess: () => void) =>
    async (invoiceId: number | number[], isPaid: number): Promise<void> => {
      const response = Array.isArray(invoiceId)
        ? await toggleBulkInvoicePaidArray(invoiceId, !!isPaid)
        : await toogleInvoicePaid(invoiceId, isPaid);
      if (response.status !== 200) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        onSuccess();
      }
    };

  const onMoveToPaidSuccess = (): void => {
    resetCheckedDict();
    resetSelectAll();
    fetchInvoices();
  };

  const markAsBooked =
    (onSuccess: () => void) =>
    async (invoiceId: number, isBooked: boolean): Promise<void> => {
      const response = await updateInvoice({
        id: invoiceId,
        is_booked: !isBooked,
      });
      if (response.ok) {
        onSuccess();
      } else {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      }
    };

  const onMarkAsBookedSuccess = (): void => {
    fetchInvoices();
  };

  const isMounted = useIsMounted();

  const selected =
    checkedDict &&
    Object.keys(checkedDict)
      .filter((el) => checkedDict[el])
      .map((el) => Number(el));

  const TableToolbar = (
    <Grid
      container
      mt={1}
      justifyContent={id ? "space-between" : "flex-end"}
      alignItems={"center"}
      className="table-toolbar"
    >
      <Grid
        item
        xs={12}
        alignItems="flex-start"
        pt={4}
        pb={3}
        display="flex"
        justifyContent="flex-start"
        className="toolbar-container"
      >
        <StatusButtons
          subPath="invoices"
          tabPosition="left"
          statuses={statuses}
          handleStatusChange={handleStatusChange}
          navigateOnChange={!useOnWidget}
          activeStatusId={activeStatusId}
          useSpecialLabels={true}
          defaultStatus={FORWARDED_ROUTE}
        />
      </Grid>
      <Grid item xs={12} mt={5} className="filter-container">
        <Grid container display="flex" alignItems="center" rowSpacing={2}>
          {userHasFinalReleasePermissions && (
            <Grid item>
              <DotMenu
                checkedDict={checkedDict}
                checkedIsPaid={checkedIsPaid}
                handleReleaseButtonClick={handleReleaseButtonClick(
                  onHandleReleaseButtonClickSuccess
                )}
                moveToPaid={moveToPaid(onMoveToPaidSuccess)}
                status={activeStatusCode}
                tab={"invoice"}
              />
            </Grid>
          )}
          {!useOnWidget &&
            can(PermissionsCreate.INVOICE) &&
            activeStatusCode === RELEASE_STATUS.NEW && (
              <Grid item pr={2}>
                <StyledButton
                  data-testid={"dataItems-link"}
                  component={NavLink}
                  to={getReleaseRoute("invoices.create", Number(id))}
                  size="small"
                  color="success"
                  variant="contained"
                >
                  {t("documentRelease.invoice.uploadInvoice")}
                </StyledButton>
              </Grid>
            )}
          {!useOnWidget &&
            !isAppEnv(APP_ENVIRONMENTS.PROD) &&
            can(PermissionsCreate.DATEV_INVOICE) &&
            status === "new" && (
              <Grid item sx={{ mr: 5 }}>
                <StyledButton
                  component={NavLink}
                  to={getReleaseRoute("invoice.datev.create", Number(id))}
                  size="small"
                  color="success"
                  variant="contained"
                >
                  {t("documentRelease.invoice.dateVInvoice.upload")}
                </StyledButton>
              </Grid>
            )}
          <Grid item xs={"auto"}>
            <MultiSelect
              options={optionalColumnIds}
              selected={selectedOptionalColumnsIds}
              handleOptionsSelectedChange={handleOptionsSelectedChange}
            />
          </Grid>
          {!id && (
            <>
              <Grid item xs={12} sm={3} md={4} lg={2.5} pl={2}>
                <Dropdown
                  id="property-autocomplete"
                  name="property_id"
                  placeholder={t("documentRelease.invoice.searchProperty")}
                  getOptions={getProperties}
                  onSelectOption={onPropertySelect}
                  optionLabel="object_name"
                  size="small"
                  defaultValue={property}
                  defaultOption={[generalPropertyOption]}
                />
              </Grid>
            </>
          )}
          {snakeCase(status) === RELEASE_STATUS.FINAL_RELEASED ? (
            <Grid item xs={12} sm={6} md={2} lg={1} paddingLeft={2}>
              <TextField
                value={isPaid}
                onChange={(e) => {
                  setIsPaid(e.target.value);
                }}
                select
                fullWidth
                variant="outlined"
                size="small"
                label={`${t("documentRelease.invoice.paid")}/${t(
                  "documentRelease.invoice.unpaid"
                )}`}
              >
                {paidUnpaidFilter?.map(({ value, name }) => (
                  <MenuItem key={name} value={value}>
                    {t(name)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ) : null}
          <Grid item lg></Grid>
          <Grid
            item
            sx={{ display: "flex", alignItems: "center" }}
            gap={2}
            pr={2}
            pl={2}
          >
            <HZScrollController />
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t("search")} onChange={handleTableSearch} />
            </Search>
            <Tooltip
              title={
                selected?.length === 0
                  ? t(`documentRelease.outgoingInvoice.selectInvoiceToDownload`)
                  : t("tenant.download")
              }
            >
              <span>
                <CustomButton
                  onClick={handleDownload}
                  title={""}
                  type="submit"
                  disabled={selected?.length === 0 || isArchiveLoading}
                  isLoading={isArchiveLoading}
                  testId="download-invoices"
                  startIcon={
                    !isArchiveLoading ? <MuiFileDownloadIcon /> : undefined
                  }
                />
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderRow = (row: Invoice): ReactElement => (
    <InvoiceListRow
      row={row}
      handleItemCheckbox={handleItemCheckbox}
      checkedDict={checkedDict}
      id={id}
      setContactShow={setContactShow}
      setProviderInvoiceListFilter={setProviderInvoiceListFilter}
      selectedOptionalColumnsIds={selectedOptionalColumnsIds}
      handleOpenComments={handleOpenComments}
      configuration={configuration}
      statuses={statuses}
      fetchInvoices={fetchInvoices}
      moveToPaid={moveToPaid(onMoveToPaidSuccess)}
      markAsBooked={markAsBooked(onMarkAsBookedSuccess)}
      handleCellClick={handleCellClick}
      handleHistoryModal={handleHistoryModal}
      handleForwardClick={handleForwardClick}
      handleShowClick={handleShowClick}
      handleEditClick={handleEditClick}
      openConfirmToRemoveModal={openConfirmToRemoveModal}
      handleReleaseDocument={handleReleaseDocument}
      handleForwardDocument={handleForwardDocument}
      releasePermission={releasePermission}
      isDocumentReleaseLoading={isDocumentReleaseLoading}
    />
  );

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
  ];

  const pageTitle = useOnWidget ? "" : t("invoices");

  if (isConfigurationLoading || !statuses.length) {
    return <Loader />;
  }

  return (
    <TabPageWrapper
      breadcrumbs={breadcrumbs}
      title={pageTitle}
      wrap={!id && !useOnWidget}
    >
      <>
        {activeStatusId === HISTORY_STATUS.id ? (
          <HistoryList
            activeStatusId={activeStatusId}
            handleStatusChange={handleStatusChange}
            statuses={statuses}
            useOnWidget={useOnWidget}
            documentRelease={HISTORY.INVOICE}
            noDataMessage="table.noInvoiceHistoryIsAvailable"
          />
        ) : (
          <Table
            data={data}
            total={total}
            currentPage={currentPage}
            order={order}
            orderBy={orderBy}
            error={error}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
            onRowRemove={handleRowRemove}
            onSortChange={handleSortChange}
            onSelectAllClick={handleSelectAllClick}
            onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading || isConfigurationLoading}
            isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
            listHeadCells={tableHeaderTitles}
            renderRow={renderRow}
            tableToolbar={TableToolbar}
            noDataIsAvailablePlaceholder={t("table.noInvoicesAreAvailable")}
            tableDataMaxHeight={useOnWidget ? "50vh" : "75vh"}
            confirmationText={t("documentRelease.invoice.deleteConfirmation")}
            refId="hzScroll"
          />
        )}
        {forwardInvoiceId ? (
          <ForwardDocumentDialog
            isOpen={true}
            handleCloseForwardModal={handleCloseForwardModal}
            entityId={forwardInvoiceId}
            entityType="invoices"
            setSnackbarVisible={setSnackbarVisible}
            title={t("documentRelease.invoice.forwardInvoice")}
          />
        ) : null}
        <Snackbar
          message={t("documentRelease.invoice.invoiceForwardSuccess")}
          color="success"
          open={snackbarVisible}
          handleClose={handleCloseSnackbar}
          data-testid="snackbar-invoice-success"
        />
        {isShowCommentsModal.isVisible && (
          <CommentsPopup
            handleCloseDialog={handleCloseCommentsModal}
            id={String(isShowCommentsModal?.rowId)}
            relation={COMMENTABLE.INVOICE}
          />
        )}
        {showHistoryModal.isVisible && (
          <HistoryPopup
            handleCloseDialog={handleHistoryCloseModal}
            historyId={String(showHistoryModal?.historyId)}
            documentRelease={HISTORY.INVOICE}
          />
        )}
        {contactShow && (
          <ContactShowDialog
            id={contactShow}
            onClose={() => setContactShow(null)}
            open={true}
          />
        )}
        {providerInvoiceListFilter.contact_id && (
          <ProviderInvoiceList
            onClose={() =>
              setProviderInvoiceListFilter({
                contact_id: null,
              })
            }
            parentFilter={providerInvoiceListFilter}
            configuration={configuration}
            moveToPaid={moveToPaid}
            markAsBooked={markAsBooked}
            statuses={statuses}
            handleForwardDocument={handleForwardDocument}
            handleReleaseDocument={handleReleaseDocument}
            releasePermission={releasePermission}
            isDocumentReleaseLoading={isDocumentReleaseLoading}
          />
        )}
        {snackbar.Snackbar}
      </>
    </TabPageWrapper>
  );
};

export default InvoiceList;
