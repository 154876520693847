import React, { ReactElement, useEffect, useState } from "react";
import { Android12Switch } from "../../../ui/formsFields/switch/styled";
import Button from "../../../ui/button/Button";
import Snackbar from "../../../ui/Snackbar";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Stack,
} from "@mui/material";
import { FormikValues } from "formik/dist/types";
import { INPUT_MIN_HEIGHT } from "../../../constants";
import { DatePicker } from "@mui/x-date-pickers";
import { handleDatepicker } from "../../../utils/form";
import CreateCompanyModal from "../../contacts/createCompanyModal";
import TextField from "../../../ui/formsFields/text";
import { Company, Salutation } from "../../../types/models";
import { createCompanyFormInitState } from "../../contacts/createCompanyModal/utils";
import CompanyAutocomplete from "../../contacts/companyAutocomplete";
import { useNavigate } from "react-router-dom";
import { route } from "../../../utils/url";
import { useLocation } from "react-router";
import Dropdown from "../../../ui/dropdown";
import { getRoles } from "../../../api/roles";

function CreateUserForm({
  handleSubmit,
  handleChange,
  isSubmitting,
  values,
  status,
  setFieldValue,
  salutations,
  selectedInternalRole,
  setSelectedUserRoll,
  propertyManagerId,
  errors,
}: FormikValues): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const { t } = useTranslation();
  const active = t("active");
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [isCreateCompanyModalOpen, setIsCreateCompanyModalOpen] =
    useState<boolean>(false);
  const [companyWasCreated, setCompanyWasCreated] = useState(false);
  const [initialValues, setInitialValues] = useState(
    createCompanyFormInitState
  );
  const [companyAutocompleteValue, setCompanyAutocompleteValue] =
    useState<Company | null>(null);

  const isPropertyManagerRole = values.role_id === propertyManagerId;

  useEffect(() => {
    setSelectedUserRoll(values.role_id);
  }, [values.role_id]);

  const handleClose = (): void => {
    setIsSnackbarVisible(false);
    setCompanyWasCreated(false);
  };

  const handleCreateCompanyModalClose = (): void => {
    setInitialValues(createCompanyFormInitState);
    setIsCreateCompanyModalOpen(false);
  };

  useEffect(() => {
    companyAutocompleteValue !== null &&
      setFieldValue("company_id", companyAutocompleteValue?.id);
  }, [companyAutocompleteValue]);

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
    setCompanyWasCreated(companyWasCreated);
  }, [status, setCompanyWasCreated]);

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={6} columns={12}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="first_name"
              label={t("firstName")}
              value={values.first_name}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "first-name-input" }}
              status={status}
            />
            <TextField
              required
              name="last_name"
              label={t("lastName")}
              value={values.last_name}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "last-name-input" }}
              status={status}
            />
            <TextField
              required
              type="email"
              name="email"
              label={t("email")}
              value={values.email}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "email-input" }}
              status={status}
            />
            <TextField
              required
              type="password"
              name="password"
              label={t("password")}
              value={values.password}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "password-input" }}
              status={status}
            />
            <TextField
              required
              type="password"
              name="password_confirmation"
              label={t("passwordConfirmation")}
              value={values.password_confirmation}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "password-confirmation-input" }}
              status={status}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="phone_number"
              label={t("phoneNumber")}
              value={values.phone_number}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "phone-number-input" }}
              status={status}
            />
            <TextField
              name="mobile_number"
              label={t("mobileNumber")}
              value={values.mobile_number}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "mobile-number-input" }}
              status={status}
            />
            <TextField
              name="salutation_id"
              select
              label={t("salutation.label")}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              inputProps={{ "data-testid": "salutation-input" }}
              status={status}
            >
              {salutations.map((salutation: Salutation) => (
                <MenuItem key={salutation.id} value={salutation.id}>
                  {salutation.name}
                </MenuItem>
              ))}
            </TextField>

            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={2}
            >
              <Grid item xs={6}>
                <DatePicker
                  disableFuture
                  openTo="year"
                  views={["year", "month", "day"]}
                  inputFormat="dd/MM/yyyy"
                  value={values.birthday}
                  label={t("birthday")}
                  onChange={handleDatepicker({ setFieldValue }, "birthday")}
                  renderInput={(params): ReactElement => (
                    <TextField
                      data-testid="birthday-input"
                      id="birthday"
                      type="date"
                      value={values.birthday}
                      sx={{ minHeight: INPUT_MIN_HEIGHT }}
                      fullWidth
                      {...params}
                      status={status}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ marginBottom: "30px" }}
                >
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Android12Switch
                            checked={values.is_active}
                            onChange={handleChange}
                            name="is_active"
                          />
                        }
                        label={active}
                      />
                    </FormGroup>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item xs={6} sm={6}>
                <Dropdown
                  id="role-autocomplete"
                  name="role_id"
                  value={values.role_id || ""}
                  placeholder={t("search")}
                  label={t("usersList.role")}
                  getOptions={getRoles}
                  sx={{ minHeight: INPUT_MIN_HEIGHT }}
                  onSelectOption={(id?: string) => setFieldValue("role_id", id)}
                  defaultValue={values?.role || null}
                  enableReInitialization
                  optionLabel="name"
                  optionValue="id"
                  size="medium"
                  error={Boolean(status?.errors?.role_id || errors.role_id)}
                  helperText={errors?.role_id || status?.errors?.role_id}
                />
              </Grid>
            </Grid>

            {!selectedInternalRole && (
              <Grid
                container
                direction="row"
                spacing={2}
                data-testid={"external-user-block"}
              >
                <Grid item xs={12} sm={7}>
                  <CompanyAutocomplete
                    status={status}
                    name={"company_id"}
                    initialValue={companyAutocompleteValue}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Stack direction="row" ml={5}>
                    <Button
                      variant="contained"
                      type="button"
                      testId="open-company-modal"
                      title={t("createContact.createCompany")}
                      onClick={() => setIsCreateCompanyModalOpen(true)}
                      sx={{ minHeight: "50px" }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} sm={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            >
              <Button
                type="button"
                color="primary"
                variant="text"
                title={t("cancel")}
                disabled={isSubmitting}
                size="large"
                onClick={
                  /* istanbul ignore next */ () =>
                    doesAnyHistoryEntryExist
                      ? navigate(-1)
                      : navigate(route("users"))
                }
              />
              <Button
                color="success"
                testId="create-user-submit"
                title={t("createUser")}
                type="submit"
                size="large"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              />
            </Stack>
            <Snackbar
              message={t(
                companyWasCreated
                  ? "createContact.companyWasSuccessfullyCreated"
                  : "userWasSuccessfullyCreated"
              )}
              open={isSnackbarVisible || companyWasCreated}
              handleClose={handleClose}
            />
          </Grid>
        </Grid>
      </form>
      <CreateCompanyModal
        isCreateCompanyModalOpen={isCreateCompanyModalOpen}
        handleCreateCompanyModalClose={handleCreateCompanyModalClose}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        isPropertyManagerRole={isPropertyManagerRole}
        setCompanyAutocompleteValue={setCompanyAutocompleteValue}
        setCompanyWasCreated={setCompanyWasCreated}
      />
    </>
  );
}

export default CreateUserForm;
