import http from "../utils/http";
import { SearchProfile } from "../types/models";

export const createSearchProfiles = async (
  searchProfile: SearchProfile
): Promise<Response> => {
  return await http.post("/api/search-profiles", searchProfile);
};

export const getSearchProfile = async (id: number): Promise<Response> => {
  return await http.get(`/api/search-profiles/${id}`);
};

export const editSearchProfile = async (
  searchProfile: SearchProfile
): Promise<Response> => {
  return await http.put(
    `/api/search-profiles/${searchProfile.id}`,
    searchProfile
  );
};

export const deleteSearchProfile = async (id: number): Promise<Response> => {
  return await http.delete(`/api/search-profiles/${id}`);
};

export const getSearchProfilesTypes = async (): Promise<Response> => {
  return await http.get(`/api/search-profiles/types`);
};

export const getSearchProfilesByContactId = async (
  contact_id: string,
  params: string
): Promise<Response> => {
  return await http.get(`/api/contacts/${contact_id}/search-profiles${params}`);
};
