import { User } from "../../../../../../types/models";

/*istanbul ignore next*/
export const getUsersByIds = (usersArr: User[], userIds: number[]): User[] =>
  usersArr.filter(({ id }) => userIds.includes(id));
/*istanbul ignore next*/
export const removeDuplicatesFromUsers = (users: User[]): User[] =>
  users
    .filter((value) => value)
    .filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.first_name === value.first_name && t.id === value.id
        )
    );
