import http from "../utils/http";
import { IdPropType } from "../types/models";
import { OutgoingInvoice } from "../types/fe/outgoingInvoice";

export const getOutgoingInvoices = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/outgoing-invoices${params}`);
};
/* istanbul ignore next */
export const getOutgoingInvoice = async (id?: string): Promise<Response> => {
  return await http.get(`/api/outgoing-invoices/${id}`);
};

export const deleteOutgoingInvoice = async ({
  id,
}: IdPropType): Promise<Response> => {
  return await http.delete(`/api/outgoing-invoices/${id}`);
};
/* istanbul ignore next */
export const createOutgoingInvoice = async (
  data: OutgoingInvoice
): Promise<Response> => {
  return await http.post(`/api/outgoing-invoices`, data);
};
/* istanbul ignore next */
export const updateOutgoingInvoice = async (
  id: string,
  data: Partial<OutgoingInvoice>
): Promise<Response> => {
  return await http.put(`/api/outgoing-invoices/${id}`, data);
};
/* istanbul ignore next */
export const previewOutgoingInvoicePdf = async (
  data: Partial<OutgoingInvoice>
): Promise<Response> => {
  return await http.post(`/api/outgoing-invoices/preview-pdf`, data);
};
/* istanbul ignore next */
export const sendBulkOutgoingInvoice = async (
  invoiceId: number[]
): Promise<Response> => {
  return http.post(`/api/outgoing-invoices/bulk-send`, {
    ids: invoiceId,
  });
};
