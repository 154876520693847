import styled from "styled-components";

export const LoadingBox = styled.div<{ height?: number }>`
  width: 100%;
  height: ${(props) =>
    props.height ? props.theme.spacing(props.height) : "50vh"};
`;

export const NoDataFoundContainer = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
`;
