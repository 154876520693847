import React, { ReactElement, useEffect, useState } from "react";
import { Form } from "formik";
import TextField from "../../../ui/formsFields/text";
import FormTextfield from "../../../ui/formsFields/textField";
import Button from "../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import { Avatar, Grid, MenuItem, Stack } from "@mui/material";
import { FormikValues } from "formik/dist/types";
import Snackbar from "../../../ui/Snackbar";
import Switch from "../../../ui/formsFields/switch";
import { INPUT_MIN_HEIGHT } from "../../../constants";
import { DatePicker } from "@mui/x-date-pickers";
import { handleDatepicker } from "../../../utils/form";
import CreateCompanyModal from "../../contacts/createCompanyModal";
import { Company, Salutation } from "../../../types/models";
import { ProfilePhotoContainer } from "../create/styled";
import { createCompanyFormInitState } from "../../contacts/createCompanyModal/utils";
import CompanyAutocomplete from "../../contacts/companyAutocomplete";
import { route } from "../../../utils/url";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { getRoles } from "../../../api/roles";
import Dropdown from "../../../ui/dropdown";

function EditUserForm({
  handleSubmit,
  isSubmitting,
  values,
  status,
  errors,
  salutations,
  setFieldValue,
  selectedInternalRole,
  setSelectedUserRoll,
  propertyManagerId,
  setPasswordFieldValue,
}: FormikValues): React.ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [isCreateCompanyModalOpen, setIsCreateCompanyModalOpen] =
    useState<boolean>(false);
  const [companyWasCreated, setCompanyWasCreated] = useState(false);
  const [initialValues, setInitialValues] = useState(
    createCompanyFormInitState
  );
  const [companyAutocompleteValue, setCompanyAutocompleteValue] =
    useState<Company | null>(values.company.title ? values.company : null);

  const isPropertyManagerRole = values.role_id === propertyManagerId;

  useEffect(() => {
    setSelectedUserRoll(values.role_id);
  }, [values.role_id]);

  const handleClose = (): void => {
    setIsSnackbarVisible(false);
    setCompanyWasCreated(false);
  };

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
    setCompanyWasCreated(companyWasCreated);
  }, [status, setCompanyWasCreated]);

  const handleCreateCompanyModalClose = (): void => {
    setInitialValues(createCompanyFormInitState);
    setIsCreateCompanyModalOpen(false);
  };

  useEffect(() => {
    setPasswordFieldValue(values.password);
  }, [values.password]);

  useEffect(() => {
    companyAutocompleteValue !== null &&
      setFieldValue("company_id", companyAutocompleteValue?.id);
  }, [companyAutocompleteValue]);
  const profilePhoto = values?.avatar_file?.url;

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={12} columns={12}>
          <Grid item xs={12} sm={12} sx={{ pb: 10 }}>
            {!profilePhoto ? (
              <ProfilePhotoContainer>
                <Avatar
                  sx={{
                    width: 150,
                    height: 150,
                    bgcolor: "grey",
                    fontSize: 70,
                    textTransform: "uppercase",
                  }}
                >{`${values.first_name[0]}${values.last_name[0]}`}</Avatar>
              </ProfilePhotoContainer>
            ) : (
              /* istanbul ignore next */
              <ProfilePhotoContainer>
                <img src={profilePhoto} alt="profile-photo" />
              </ProfilePhotoContainer>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={6} columns={12}>
          <Grid item xs={12} sm={6}>
            <FormTextfield
              name="first_name"
              label={t("firstName")}
              inputProps={{ "data-testid": "first-name-input" }}
              status={status}
            />
            <FormTextfield
              name="last_name"
              label={t("lastName")}
              inputProps={{ "data-testid": "last-name-input" }}
              status={status}
            />
            <FormTextfield
              type="email"
              name="email"
              label={t("email")}
              inputProps={{ "data-testid": "email-input" }}
              status={status}
            />
            <FormTextfield
              type="password"
              name="password"
              label={t("password")}
              inputProps={{ "data-testid": "password-input" }}
              status={status}
            />
            <FormTextfield
              type="password"
              name="password_confirmation"
              label={t("passwordConfirmation")}
              inputProps={{ "data-testid": "password-confirmation-input" }}
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextfield
              name="phone_number"
              label={t("phoneNumber")}
              inputProps={{ "data-testid": "phone-number-input" }}
              status={status}
            />
            <FormTextfield
              name="mobile_number"
              label={t("mobileNumber")}
              inputProps={{ "data-testid": "mobile-number-input" }}
              status={status}
            />
            <FormTextfield
              name="salutation_id"
              select
              label={t("salutation.label")}
              inputProps={{ "data-testid": "salutation-input" }}
              status={status}
            >
              {salutations.map((salutation: Salutation) => (
                <MenuItem key={salutation.id} value={salutation.id}>
                  {salutation.name}
                </MenuItem>
              ))}
            </FormTextfield>

            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={2}
            >
              <Grid item xs={6}>
                <DatePicker
                  disableFuture
                  openTo="year"
                  views={["year", "month", "day"]}
                  inputFormat="dd.MM.yyyy"
                  value={values.birthday}
                  label={t("birthday")}
                  onChange={handleDatepicker({ setFieldValue }, "birthday")}
                  renderInput={(params): ReactElement => (
                    <TextField
                      data-testid="birthday-input"
                      id="birthday"
                      type="date"
                      value={values.birthday}
                      sx={{ minHeight: INPUT_MIN_HEIGHT }}
                      fullWidth
                      {...params}
                      status={status}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ marginBottom: "30px" }}
                >
                  <Switch
                    name="is_active"
                    label={t("active")}
                    checked={!!values.is_active}
                    data-testid="active-switch"
                  />
                </Stack>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item xs={6} sm={6}>
                <Dropdown
                  id="role-autocomplete"
                  name="role_id"
                  value={values.role_id}
                  placeholder={t("search")}
                  label={t("usersList.role")}
                  getOptions={getRoles}
                  sx={{ minHeight: INPUT_MIN_HEIGHT }}
                  onSelectOption={(id?: string) => setFieldValue("role_id", id)}
                  defaultValue={values?.role}
                  enableReInitialization
                  optionLabel="name"
                  optionValue="id"
                  size="medium"
                  error={Boolean(status?.errors?.role_id || errors.role_id)}
                  helperText={errors?.role_id || status?.errors?.role_id}
                />
              </Grid>
            </Grid>

            {!selectedInternalRole && (
              <Grid
                container
                direction="row"
                spacing={2}
                data-testid={"external-user-block"}
              >
                <Grid item xs={12} sm={7}>
                  <CompanyAutocomplete
                    status={status}
                    name={"company_id"}
                    initialValue={companyAutocompleteValue}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Stack direction="row" ml={5}>
                    <Button
                      variant="contained"
                      type="button"
                      testId="open-company-modal"
                      title={t("createContact.createCompany")}
                      onClick={() => setIsCreateCompanyModalOpen(true)}
                      sx={{ minHeight: "50px" }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} mt={10}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
          >
            <Button
              type="button"
              color="primary"
              variant="text"
              title={t("cancel")}
              disabled={isSubmitting}
              size="large"
              onClick={
                /* istanbul ignore next */ () =>
                  doesAnyHistoryEntryExist
                    ? navigate(-1)
                    : navigate(route("users"))
              }
            />
            <Button
              color="success"
              testId="edit-user-submit"
              title={t("update")}
              type="submit"
              size="large"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            />
          </Stack>
          <Snackbar
            color="success"
            message={t(
              companyWasCreated
                ? "createContact.companyWasSuccessfullyCreated"
                : "userWasSuccessfullyUpdated"
            )}
            open={isSnackbarVisible || companyWasCreated}
            handleClose={handleClose}
          />
        </Grid>
      </Form>
      <CreateCompanyModal
        isCreateCompanyModalOpen={isCreateCompanyModalOpen}
        handleCreateCompanyModalClose={handleCreateCompanyModalClose}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        isPropertyManagerRole={isPropertyManagerRole}
        setCompanyAutocompleteValue={setCompanyAutocompleteValue}
        setCompanyWasCreated={setCompanyWasCreated}
      />
    </>
  );
}

export default EditUserForm;
