import React, { ReactElement, useEffect, useState } from "react";
import { FormikValues } from "formik/dist/types";
import { Grid, Stack } from "@mui/material";
import TextField from "../../../ui/formsFields/text";
import { useTranslation } from "react-i18next";
import Button from "../../../ui/button/Button";
import FileUploader from "../../../ui/fileUploader";
import PropertyAreaAutocomplete from "../components/PropertyAreaAutocomplete";
import { Area, TenantUser } from "../../../types/be/area";
import useSnackbar from "../../../ui/snackbar1/useSnackbar";
import { route } from "../../../utils/url";
import { PropertyList } from "../../../types/be/property";
import PropertyAutocomplete from "../components/PropertyAutocomplete";
import { getAreaTenantUsers } from "../../../api/area";
import { getJson } from "../../../utils/http";
import {
  AutocompleteTenantEmail,
  AutocompleteTenantFullName,
  MenuItem,
} from "./styled";
import { touchedInitState } from "./utils";
import UsersAutocomplete from "../../users/autocomplete";
import { TENANT_REQUEST_READ_PERMISSION } from "../utils";
import { useFetch } from "../../../hooks/useFetch";
import { prepareQueryParams } from "../../../utils/common";
import { User } from "../../../types/be/user";
import { PAGE_LIMIT } from "../../../constants";
import { getUsers } from "../../../api/users";
import { useNavigate } from "react-router-dom";

const CreateTenantRequestForm = ({
  handleSubmit,
  touched,
  setTouched,
  status,
  errors,
  setFieldValue,
  isSubmitting,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Snackbar, snackbar, setLink } = useSnackbar();
  const [property, setProperty] = useState<PropertyList | null>(null);
  const [propertyArea, setPropertyArea] = useState<Area | null>(null);
  const [tenants, setTenants] = useState<TenantUser[]>([]);
  const [isTenantsLoading, setIsTenantsLoading] = useState<boolean>(false);
  const [areFilesSubmitted, setAreFilesSubmitted] = useState(false);
  const [propertyId, setPropertyId] = useState<number | null>(null);
  const [predictions, setPredictions] = useState<Area[]>([]);
  const [haveTenants, setHaveTenants] = useState(false);

  const { data: managerUsers, run: runManagerUsers } = useFetch<User[]>();

  useEffect(() => {
    const params = prepareQueryParams("", {
      limit: String(PAGE_LIMIT._10),
      "permission_code[]": TENANT_REQUEST_READ_PERMISSION,
    });
    runManagerUsers(getUsers(params));
  }, []);

  useEffect(() => {
    if (status && status.success) {
      setLink(
        `${window.location.origin}${route(
          "tenant-requests.edit",
          status.data.id
        )}`
      );
      setAreFilesSubmitted(true);
      snackbar.success(t("tenantRequest.created"));
    } else if (status && status.errors) {
      snackbar.error(t(status.errors));
    }
  }, [status]);

  const handleOnPropertyAreaSelect = (_area: Area | null): void => {
    setPropertyArea(_area);
    if (_area) {
      if (_area.id !== propertyArea?.id) {
        setFieldValue("tenant_id", "");
        setTenants([]);
      }
      touched.tenant_id && setTouched({ tenant_id: false });
      setFieldValue("property_area_id", _area.id);
      setIsTenantsLoading(true);
      getAreaTenantUsers(String(_area.id))
        .then(getJson)
        .then(({ data }) => {
          setIsTenantsLoading(false);
          setTenants(data);
          setHaveTenants(true);
        });
    } else {
      setFieldValue("property_area_id", "");
      setFieldValue("tenant_id", "");
      setTenants([]);
      setTouched(touchedInitState);
    }
  };

  const onPropertySelect = (_property: PropertyList | null): void => {
    setProperty(_property);
    if (_property) {
      if (_property.id !== propertyId) {
        setFieldValue("property_id", _property.id);
        setFieldValue("tenant_id", "");
        setFieldValue("property_area_id", "");
        setPropertyArea(null);
        setPredictions([]);
        setPropertyId(_property.id);
        setTenants([]);
      }
      setFieldValue("property_id", _property.id);
    } else {
      setProperty(null);
      setFieldValue("tenant_id", "");
      setFieldValue("property_area_id", "");
      setPropertyArea(null);
      setTenants([]);
      setPredictions([]);
    }
  };

  const handleOnFileChange = (files: File[]): void => {
    setAreFilesSubmitted(false);
    setFieldValue("file", [...files]);
  };

  const handleOnFileDelete = (files: File[]): void => {
    setFieldValue("file", [...files]);
  };

  const handleOnCancelClick = (): void => {
    navigate(route("tenant-requests"));
  };

  const tenantFieldLabel = !propertyArea
    ? `${t("tenantRequest.tenant")}* (${t(
        "tenantRequest.youNeedToSelectPropertyAreaFirst"
      )})`
    : `${t("tenantRequest.tenant")}*`;

  const areaFieldLabel = !property
    ? `${t("tenantRequest.area")}* (${t(
        "tenantRequest.youNeedToSelectPropertyFirst"
      )})`
    : `${t("tenantRequest.area")}*`;

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <TextField
                  required
                  disabled={isSubmitting}
                  name="title"
                  label={t("tenantRequest.title")}
                  placeholder={t("tenantRequest.title")}
                  error={Boolean(
                    touched.title && (status?.errors.title || errors.title)
                  )}
                  helperText={
                    touched.title && (status?.errors.title || errors.title)
                  }
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <PropertyAutocomplete
                  isDisabled={isSubmitting}
                  touched={touched}
                  status={status}
                  errors={errors}
                  onPropertySelect={onPropertySelect}
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <PropertyAreaAutocomplete
                  propertyAreaInitState={propertyArea}
                  isDisabled={isSubmitting}
                  label={areaFieldLabel}
                  touched={touched}
                  status={status}
                  errors={errors}
                  propertyId={propertyId ? propertyId : null}
                  predictions={predictions}
                  setPredictions={setPredictions}
                  onPropertyAreaSelect={handleOnPropertyAreaSelect}
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <TextField
                  name="tenant_id"
                  select
                  size={"small"}
                  label={tenantFieldLabel}
                  placeholder={tenantFieldLabel}
                  disabled={
                    (!property && !propertyArea) ||
                    isSubmitting ||
                    !tenants.length
                  }
                  error={
                    !isTenantsLoading &&
                    property &&
                    propertyArea &&
                    haveTenants &&
                    !tenants.length
                      ? /*istanbul ignore next */ true
                      : Boolean(
                          touched.tenant_id &&
                            (status?.errors.tenant_id || errors.tenant_id)
                        )
                  }
                  helperText={
                    !isTenantsLoading &&
                    property &&
                    propertyArea &&
                    haveTenants &&
                    !tenants.length
                      ? /*istanbul ignore next */ t(
                          "tenantRequest.selectedAreaDoesNotHaveTenants"
                        )
                      : touched.tenant_id &&
                        (status?.errors.tenant_id || errors.tenant_id)
                  }
                >
                  {tenants.map((tenant) => (
                    <MenuItem
                      key={`${tenant.id}-${tenant.email}`}
                      value={tenant.id}
                    >
                      <AutocompleteTenantFullName>{`${tenant.first_name} ${tenant.last_name}`}</AutocompleteTenantFullName>
                      <AutocompleteTenantEmail>
                        {`${tenant.email}`}
                      </AutocompleteTenantEmail>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <UsersAutocomplete
                  status={status}
                  permissionCodes={[TENANT_REQUEST_READ_PERMISSION]}
                  placeholder={"transactionManagement.loi.search"}
                  label={"tenantRequest.manager"}
                  user={null}
                  fieldName={"manager_id"}
                  optionLabel="full_name"
                  size={"small"}
                  sx={null}
                  preloadOptions={managerUsers}
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <TextField
                  required
                  name="description"
                  multiline
                  disabled={isSubmitting}
                  rows={4}
                  label={t("tenantRequest.description")}
                  placeholder={t("tenantRequest.description")}
                  error={Boolean(
                    touched.description &&
                      (status?.errors.description || errors.description)
                  )}
                  helperText={
                    touched.description &&
                    (status?.errors.description || errors.description)
                  }
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <FileUploader
                  accept="image"
                  isDisabled={isSubmitting}
                  onFileChange={handleOnFileChange}
                  onFileDelete={handleOnFileDelete}
                  isSubmitted={areFilesSubmitted}
                  placeHolderText={t("fileUploader.dragAndDropYourFilesHereOr")}
                  uploadButtonText={t("fileUploader.uploadFiles")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
        >
          <Button
            color={"primary"}
            variant="text"
            size="large"
            title={t("tenantRequest.cancel")}
            disabled={isSubmitting}
            onClick={() => handleOnCancelClick()}
          />
          <Button
            color="success"
            title={t("tenantRequest.create")}
            type="submit"
            size="large"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
        </Stack>
      </form>
      {Snackbar}
    </>
  );
};

export default CreateTenantRequestForm;
