import { Grid, Tooltip } from "@mui/material";
import { FormikValues } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getOfferTopicsSearch } from "../../../../api/offerTopic";
import { OfferTopic } from "../../../../types/be/offer";
import Dropdown from "../../../../ui/dropdown";
import { PlusIcon } from "../../../../ui/table/styled";
import { NEW_STATUS_CODE } from "../../insurances/list/topicList/utils";
import CreateTopicDialog from "../list/topicList/topicListToolbar/createTopicDialog";
/* istanbul ignore next */
const OfferTopicAutocomplete = ({
  setFieldValue,
  status,
  touched,
  errors,
  fieldName,
  defaultValue,
  propertyId,
  size,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState<OfferTopic | null>(null);

  useEffect(() => {
    defaultValue && setSelectedValue(defaultValue);
  }, [defaultValue]);

  const afterCreateTopic = (topic?: OfferTopic): void => {
    topic && setSelectedValue(topic);
    topic && setFieldValue(fieldName, topic.id);
    setOpenCreateDialog(false);
  };

  return (
    <>
      <Grid container alignItems={"center"}>
        <Grid item xs={10}>
          <Dropdown
            id="property-autocomplete"
            name={fieldName}
            label={t("offers.selectTopic")}
            placeholder={t("offers.selectTopic")}
            getOptions={getOfferTopicsSearch}
            onSelectOption={(id?: string) => setFieldValue(fieldName, id)}
            optionLabel="name"
            optionValue="id"
            property={propertyId}
            statusCode={NEW_STATUS_CODE}
            isAreaSearch={true}
            size={size}
            enableReInitialization={true}
            helperText={
              status?.errors[fieldName] ||
              (touched[fieldName] && errors[fieldName])
            }
            error={Boolean(
              status?.errors[fieldName] ||
                (touched[fieldName] && errors[fieldName])
            )}
            defaultValue={selectedValue}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip title={"Create contact"}>
            <PlusIcon
              role={"dataItems-create-contact"}
              onClick={() => {
                setOpenCreateDialog(true);
              }}
              size={18}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <CreateTopicDialog
        onClose={() => setOpenCreateDialog(false)}
        open={openCreateDialog}
        afterCreateTopic={afterCreateTopic}
      />
    </>
  );
};

export default OfferTopicAutocomplete;
