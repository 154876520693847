import styled from "styled-components/macro";
import { Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

export const AutocompletePropertyName = styled(Typography)``;

export const AutocompletePropertyAddress = styled(Typography)`
  font-size: 12px;
  color: ${(): string => blueGrey[200]};
`;
