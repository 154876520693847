import styled from "styled-components/macro";
import { Grid, Paper, Typography } from "@mui/material";

export const TaskInformation = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 15px;
  min-height: 106px;
  background: ${(props): string => props.theme.tenantRequests.background.bg1};
  transition: background ease 0.2s;
  margin-bottom: 5px;
  &:hover {
    background: ${(props): string => props.theme.tenantRequests.background.bg2};
  }
  &:active {
    background: ${(props): string => props.theme.tenantRequests.background.bg4};
  }
`;

export const CreatedAt = styled(Typography)`
  font-size: 11px;
  color: ${(props): string => props.theme.tenantRequests.color.createdAt};
`;

export const PropertyName = styled(Typography)`
  display: inline;
  color: ${(props): string => props.theme.tenantRequests.color.propertyName};
`;

export const PropertyNameWrapper = styled(Grid)`
  border-left: ${(props): string =>
    `2px solid ${props.theme.tenantRequests.border.propertyName}`};
  padding-left: ${(props): string => props.theme.spacing(2)};
  margin-left: ${(props): string => props.theme.spacing(2)};
`;

export const AreaName = styled(Typography)`
  display: inline;
  color: ${(props): string => props.theme.tenantRequests.color.areaName};
`;

export const AreaNameWrapper = styled(Grid)`
  border-left: ${(props): string =>
    `2px solid ${props.theme.tenantRequests.border.areaName}`};
  padding-left: ${(props): string => props.theme.spacing(2)};
  margin-left: ${(props): string => props.theme.spacing(2)};
`;

export const Title = styled(Typography)`
  font-size: 14px;
  margin-left: 5px;
  font-weight: bold;
  display: inline;
  color: ${(props): string => props.theme.tenantRequests.color.title};
`;

export const TicketNumberBadge = styled.div`
  display: inline;
  background: ${(props): string =>
    props.theme.tenantRequests.background.ticketNumberBadge};
  color: ${(props): string =>
    props.theme.tenantRequests.color.ticketNumberBadge};
  padding: 2px 3px;
  border-radius: 5px;
  font-weight: 600;
`;
