import en from "date-fns/locale/en-US";
import de from "date-fns/locale/de";
import { FormikValues } from "formik/dist/types";
import { replaceMomentByTimestamp } from "../../../../utils/date";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../constants";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { CreateEditVacationRequest, SubmitVacationRequestProps } from "./types";
import {
  createVacationRequest,
  editVacationRequest,
} from "../../../../api/vacationRequests";
import { HTTP_STATUS_CODES } from "../../../../types/server";

const touchedInitState = {
  user_id: false,
};

export const vacationRequestInitialState = {
  user_id: null,
  from: null,
  to: null,
};

export const langDict: Record<string, Locale> = {
  en,
  de,
};

export const initInsuranceRange = [new Date(), new Date()];

export const handleSubmitVacationRequest = ({
  editRowId,
  setFormMessage,
  handleSuccessVacationRequestSubmission,
  range,
  selectedUser,
  setSelectedUser,
  setRange,
  setIsSnackbarVisible,
}: SubmitVacationRequestProps) => {
  return async (
    values: CreateEditVacationRequest,
    { setStatus, setTouched, setSubmitting }: FormikValues
  ): Promise<void | Response> => {
    const [start, end] = range;

    const payload: CreateEditVacationRequest = {
      user_id: selectedUser && selectedUser.id,
      from: replaceMomentByTimestamp(start),
      to: replaceMomentByTimestamp(end),
    };
    const response = editRowId
      ? await editVacationRequest(editRowId, payload)
      : await createVacationRequest(payload);
    const json = await response.json();
    if ([201, 200].includes(response?.status)) {
      setStatus({
        success: true,
        errors: {},
      });
      !editRowId && setSelectedUser(null);
      !editRowId && setRange(initInsuranceRange);
      setTouched(touchedInitState);
      setFormMessage({
        type: "success",
        text: "",
      });
      handleSuccessVacationRequestSubmission();
      setIsSnackbarVisible(true);
      return response;
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json?.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
    }
  };
};
