import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const OPTIONAL_COLUMN_IDS = [
  "release_date",
  "is_debited_automatically",
  "is_apportionable",
  "contract_file_name",
  "offer_file_name",
  "is_permanent",
  "is_active",
  "due_date",
  "bic",
  "iban",
];

interface InitState {
  optionalColumnIds: string[];
  selectedOptionalColumnsIds: string[];
}

const initialState: InitState = {
  optionalColumnIds: OPTIONAL_COLUMN_IDS,
  selectedOptionalColumnsIds: [],
};

export const invoicesTableSlice = createSlice({
  name: "invoicesTable",
  initialState,
  reducers: {
    setHiddenColumnIds: (
      state: InitState,
      action: PayloadAction<string[]>
    ): void => {
      state.selectedOptionalColumnsIds = action.payload;
    },
  },
});

export const { setHiddenColumnIds } = invoicesTableSlice.actions;

export default invoicesTableSlice.reducer;
