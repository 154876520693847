import React, { useEffect, useCallback, ReactElement, useState } from "react";
import { ExpiringTenant } from "../../../../types/be.interfaces";
import useTable from "../../../../ui/table/useTable";
import Table from "../../../../ui/table/Table";
import { getExpiringTenants } from "../../../../api/dashboard";
import { handleServerError } from "../../../../utils/http";
import { itemsListHeadCells } from "./utils";
import { TableCell } from "../../../../styled";
import { formatTimestamp } from "../../../../utils/date";
import {
  COMMENTABLE,
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  THOUSAND_SEPARATOR,
  TIME_FORMAT,
} from "../../../../constants";
import CommentsPopup from "../../../../ui/commentsPopUp";
import { MessageCircle } from "react-feather";
import { NumericFormat } from "react-number-format";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, Typography } from "@mui/material";
import { ListDialogProps } from "../../types";
import { DialogContent, DialogTitleContainer } from "../../styled";
import CloseButton from "../../../../ui/dialog/CloseButton";
import { useTranslation } from "react-i18next";
import { MessageBadgeCounter } from "../../../properties/styled";
/* istanbul ignore next */
const ExpiringTenantsComponent = ({
  visible,
  toggleListModal,
}: ListDialogProps): ReactElement => {
  const { t } = useTranslation();
  const [commentsVisible, setCommentsVisible] = useState({
    rowId: 0,
    visible: false,
  });

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
  } = useTable<ExpiringTenant>();

  const handleFetchExpiringTenants = useCallback(async () => {
    setIsLoading(true);
    const response = await getExpiringTenants(queryParams);

    if (response.status !== 200) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const resJson = await response.json();
      setTotal(resJson?.meta?.total);
      setData(resJson.data);
    }

    setIsLoading(false);
  }, [queryParams]);

  const handleCloseCommentsModal = useCallback(() => {
    setCommentsVisible({
      rowId: 0,
      visible: false,
    });
    handleFetchExpiringTenants();
  }, []);

  const handleShowComments = useCallback((rowId: number) => {
    setCommentsVisible({
      rowId: rowId,
      visible: true,
    });
  }, []);

  useEffect(() => {
    handleFetchExpiringTenants();
  }, [queryParams]);

  const renderRow = (row: ExpiringTenant): ReactElement => {
    return (
      <>
        <TableCell mw="180px" align="left">
          {row.asset_manager?.full_name ?? "---"}
        </TableCell>
        <TableCell mw="180px" align="left">
          {row.property_name}
        </TableCell>
        <TableCell mw="180px" align="left">
          {row.contact?.full_name ?? "---"}
        </TableCell>
        <TableCell align="left">
          {row.rent_end ? formatTimestamp(row.rent_end, TIME_FORMAT) : "---"}
        </TableCell>
        <TableCell align={"left"}>
          {row.cancellation_until
            ? formatTimestamp(row.cancellation_until, TIME_FORMAT)
            : "---"}
        </TableCell>
        <TableCell align="left">
          <NumericFormat
            thousandSeparator={THOUSAND_SEPARATOR}
            decimalSeparator={DECIMAL_SEPARATOR}
            displayType={"text"}
            value={row.area}
          />
        </TableCell>
        <TableCell align="left">
          <NumericFormat
            thousandSeparator={THOUSAND_SEPARATOR}
            decimalSeparator={DECIMAL_SEPARATOR}
            fixedDecimalScale
            decimalScale={DECIMAL_SCALE}
            displayType={"text"}
            value={row.net_rent_per_month}
          />
        </TableCell>
        <TableCell align="center">
          <MessageBadgeCounter
            onClick={(): void => handleShowComments(row?.id)}
            badgeContent={row.comments_count}
            color="primary"
            overlap={"rectangular"}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MessageCircle role={"commentIconRole"} size={18} />
          </MessageBadgeCounter>
        </TableCell>
      </>
    );
  };

  return (
    <Dialog fullWidth open={visible} onClose={toggleListModal} maxWidth="xl">
      <DialogTitle>
        <DialogTitleContainer>
          <Typography
            variant="h6"
            component="div"
            justifyContent="space-around"
            width="100%"
          >
            {t("home.expiringTenants")}
          </Typography>
          <CloseButton onClick={toggleListModal} />
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        <Table
          data={data}
          total={total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={itemsListHeadCells}
          renderRow={renderRow}
          tableToolbar={<></>}
          tableDataMaxHeight={"50vh"}
        />
      </DialogContent>
      {commentsVisible.visible ? (
        <CommentsPopup
          handleCloseDialog={handleCloseCommentsModal}
          id={String(commentsVisible.rowId)}
          relation={COMMENTABLE.PROPERTY_TENANT}
        />
      ) : null}
    </Dialog>
  );
};

export default ExpiringTenantsComponent;
