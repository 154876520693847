import http from "../utils/http";
import {
  Insurance,
  InsuranceCreate,
  ReleaseProcessRoles,
} from "../types/fe.interfaces";
import { IdPropType } from "../types/models";
import { RELEASE_LEVEL } from "../utils/common";

export const createInsurance = async (
  data: InsuranceCreate
): Promise<Response> => {
  return await http.post("/api/insurances", data);
};

export const getInsurance = async (id: string): Promise<Response> => {
  return await http.get(`/api/insurances/${id}`);
};

export const updateInsurance = async (
  id: string,
  insurance: Partial<Insurance>
): Promise<Response> => {
  return await http.put(`/api/insurances/${id}`, insurance);
};

/* istanbul ignore next */
export const updateInsuranceStatus = async (
  insurance_id: number,
  status_id: number,
  who: ReleaseProcessRoles
): Promise<Response> => {
  return http.patch(
    `/api/insurances/${insurance_id}/statuses/${status_id}/${who}`,
    {}
  );
};

export const getInsuranceStatuses = async (): Promise<Response> => {
  return await http.get(`/api/insurances/statuses`);
};

export const deleteInsurance = async ({
  id,
}: IdPropType): Promise<Response> => {
  return await http.delete(`/api/insurances/${id}`);
};

export const getInsurances = async (params: string): Promise<Response> => {
  return await http.get(`/api/insurances${params}`);
};
/* istanbul ignore next */
export const getPropertyTopics = async (params: string): Promise<Response> => {
  return await http.get(`/api/property-topics${params}`);
};

/* istanbul ignore next */
export const forwardRequiredReleaseToAdmin = async (
  insuranceId: number
): Promise<Response> => {
  return await http.post(`/api/insurances/${insuranceId}/final-releaser`, {});
};

/* istanbul ignore next */
export const releaseInsurance = async (
  id: string,
  level: RELEASE_LEVEL,
  params: string
): Promise<Response> => {
  return await http.patch(`/api/insurances/${id}/${level}${params}`, {});
};
