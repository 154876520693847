import { FormikValues } from "formik";
import { Moment } from "moment";
import { EmployeeSchedule as FEEmployeeSchedule } from "../../types/fe.interfaces";
import { HTTP_STATUS_CODES } from "../../types/server";
import { TableCell } from "../../types/table";
import {
  adminCreateEmployeeSchedule,
  createEmployeeSchedule,
  deleteEmployeeSchedule,
  updateEmployeeSchedule,
} from "../../api/userSchedules";
import { HandleUpdateScheduleSubmitProps } from "./types";
import { FORM_SELECT_OPTION_ID } from "../../constants";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    sortable: true,
    label: "employeeOverview.user",
    align: "left",
  },
];

export const switchToNextWeek = (date: Moment): Moment =>
  date.clone().subtract(6, "days");

export const switchToPreviousWeek = (date: Moment): Moment =>
  date.clone().subtract(7, "days");

export const addTwoWeeks = (date: Moment): Moment =>
  date.clone().add(13, "days");

export const snackbarDict: Record<string, string> = {
  "new-entry": "newScheduleWasSuccessfullyCreated",
  "vacation-holiday": "vacationHolidayWasSuccessfullyCreated",
  "update-schedule": "scheduleWasSuccessfullyUpdated",
};

/* istanbul ignore next */
export const handleUpdateScheduleSubmit = ({
  id,
  isAdmin,
  formattedDate,
  userId,
  handleSuccessfulUpdateScheduleSubmission,
  setOptionId,
  setDescription,
  setScheduleId,
}: HandleUpdateScheduleSubmitProps) => {
  return async (
    schedule: Partial<FEEmployeeSchedule>,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void | Response> => {
    const response = !id
      ? isAdmin
        ? await adminCreateEmployeeSchedule({
            ...schedule,
            dates: [{ date_from: formattedDate, date_to: formattedDate }],
            user_id: [userId],
          })
        : await createEmployeeSchedule({
            ...schedule,
            dates: [{ date_from: formattedDate, date_to: formattedDate }],
          })
      : schedule.option_id === FORM_SELECT_OPTION_ID
      ? await deleteEmployeeSchedule(schedule)
      : await updateEmployeeSchedule(schedule);
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      setOptionId(
        schedule.option_id === FORM_SELECT_OPTION_ID
          ? undefined
          : schedule.option_id
      );
      if (isAdmin && response.status === HTTP_STATUS_CODES.CREATED) {
        const resJson = await response.json();
        setScheduleId(
          resJson.data[0].schedules[resJson.data[0].schedules.length - 1].id
        );
      } else {
        schedule.option_id === FORM_SELECT_OPTION_ID &&
          setScheduleId(undefined);
      }
      setDescription(String(schedule.description));
      handleSuccessfulUpdateScheduleSubmission();
      return response;
    } else {
      setStatus({
        success: false,
        errors: {
          option_id: " ",
        },
      });
    }
    setSubmitting(false);
  };
};

export const composeUniqueId = (
  id: number,
  formattedDate: string,
  optionId?: string
): string => `${optionId}-${id}-${formattedDate}`;

export const initSelectedValue = "";

export const limit = 10000;
export const staticParams = `?order=asc&limit=${limit}`;
export const paramsThatExcludesAdmins = "exclude_roles[]=admin";
export const permissionId = "permission_id[]";
