import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TableCell,
  Typography,
} from "@mui/material";
import { FormikValues } from "formik";
import React, { ChangeEvent, Dispatch, ReactElement } from "react";
import { PermissionsCategory as BEPermissionsCategory } from "../../../../../types/be/permissions";
import Table from "../../../../../ui/table/Table";
import useTable from "../../../../../ui/table/useTable";
import {
  allEqual,
  getCodesDict,
  getCrudDict,
  itemsListHeadCells,
} from "./utils";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { PermissionCodesDict } from "../../types";
import { INPUT_MIN_HEIGHT } from "../../../../../constants";
import TextField from "../../../../../ui/formsFields/textField";
import { useTranslation } from "react-i18next";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { CodesDict, Crud, PermissionCategory } from "./types";
/* istanbul ignore next */
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const indeterminateCheckBoxIcon = (
  <IndeterminateCheckBoxIcon fontSize="small" />
);
/* istanbul ignore next */
const PermissionCatergories = ({
  permissionCategories,
  permissionCodes,
  setPermissionCodes,
  values,
  status,
  handleChangeParent,
}: {
  permissionCategories: BEPermissionsCategory[];
  permissionCodes: PermissionCodesDict;
  setPermissionCodes: Dispatch<
    React.SetStateAction<PermissionCodesDict | null>
  >;
  handleChangeParent: (event: ChangeEvent<HTMLInputElement>) => void;
} & FormikValues): ReactElement => {
  const { t } = useTranslation();
  const {
    total,
    order,
    orderBy,
    isLoading,
    error,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
  } = useTable<PermissionCategory>();

  const preparedData = permissionCategories
    .map(({ id, code, groups, name }) =>
      groups.map((group) => ({
        id,
        code,
        name,
        group,
      }))
    )
    .flat(1);

  const dataForTable = preparedData.filter(({ code }) => code !== "other");
  const otherData = preparedData.find(({ code }) => code === "other");

  const booleans = Object.values(permissionCodes);

  const handleChange =
    (code: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      const {
        target: { checked },
      } = event;
      setPermissionCodes((prevState) => ({
        ...prevState,
        [code]: checked,
      }));
    };

  const tableToolbar = (
    <Grid container pt={4}>
      <Grid item xs={3}>
        <TextField
          required
          name="name"
          label={t("name")}
          value={values.name}
          sx={{ minHeight: INPUT_MIN_HEIGHT }}
          inputProps={{ "data-testid": "last-name-input" }}
          status={status}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component="h2" variant="h5" gutterBottom sx={{ pb: 2 }}>
          {t("settings.role.permissions")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} pt={4} pb={4}>
        <FormControlLabel
          label={t("settings.role.all")}
          key="test"
          control={
            <Checkbox
              indeterminateIcon={indeterminateCheckBoxIcon}
              icon={icon}
              checkedIcon={checkedIcon}
              checked={booleans.every((boolean) => boolean)}
              indeterminate={!allEqual(booleans)}
              onChange={handleChangeParent}
            />
          }
        />
      </Grid>
    </Grid>
  );

  const handleCodes = (checked: boolean, crudCodes: string[]): void => {
    const obj: Record<string, boolean> = {};
    crudCodes.forEach((code) => {
      if (code) {
        obj[code] = checked;
      }
    });
    setPermissionCodes((prevState) => ({
      ...prevState,
      ...obj,
    }));
  };

  const handleCrudChange =
    (crud: Crud, codes: CodesDict) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      const {
        target: { checked },
      } = event;
      const crudDict = getCrudDict(codes);
      handleCodes(checked, crudDict?.[crud]?.[Number(Boolean(checked))] || []);
    };

  const renderRow = (row: PermissionCategory): ReactElement => {
    const codes = getCodesDict(row);

    return (
      <>
        <TableCell>{row.name}</TableCell>
        <TableCell>
          {row.name === row.group.name ? "" : row.group.name}
        </TableCell>

        <TableCell>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={Boolean(permissionCodes[codes.read])}
            disabled={!codes.read}
            onChange={handleCrudChange("read", codes)}
          />
        </TableCell>

        <TableCell>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={Boolean(permissionCodes[codes.create])}
            disabled={!codes.create}
            onChange={handleCrudChange("create", codes)}
          />
        </TableCell>

        <TableCell>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={Boolean(permissionCodes[codes.update])}
            disabled={!codes.update}
            onChange={handleCrudChange("update", codes)}
          />
        </TableCell>

        <TableCell>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={Boolean(permissionCodes[codes.delete])}
            disabled={!codes.delete}
            onChange={handleCrudChange("delete", codes)}
          />
        </TableCell>
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Table
          data={dataForTable}
          total={total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={itemsListHeadCells}
          renderRow={renderRow}
          tableToolbar={tableToolbar}
          noDataIsAvailablePlaceholder={"table.noRolesAreAvailable"}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ px: 10, py: 5, mt: -2 }}>
          {otherData?.group.permissions.map((el) => (
            <FormControlLabel
              label={el.name}
              key={el.id}
              sx={{ pr: 4 }}
              labelPlacement="end"
              control={
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={Boolean(permissionCodes[el.code])}
                  onChange={handleChange(el.code)}
                />
              }
            />
          ))}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PermissionCatergories;
