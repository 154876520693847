import React, { ReactElement } from "react";
import { PropertyManagementShowProps } from "./types";
import {
  RentedInfoContainer,
  SmallLightText,
} from "../../../areas/edit/tabs/rentedForm/styled";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Edit } from "@mui/icons-material";
import {
  EMPTY_DATA,
  Measurement_Units,
  PermissionsUpdate,
} from "../../../../../constants";
import { formatTimestamp } from "../../../../../utils/date";
import NumberContainer from "../../../areas/edit/tabs/rentedForm/conponents/NumberContainer";
import { FormWrapper } from "./styled";
import useAccessControl from "../../../../../hooks/useAccessControl";

/* istanbul ignore next */
const PropertyManagementShow = ({
  property,
  setIsEdit,
}: PropertyManagementShowProps): ReactElement => {
  const { t } = useTranslation();
  const { can } = useAccessControl();

  return (
    <FormWrapper>
      {can(PermissionsUpdate.PROPERTY) && (
        <Edit
          className="edit-icon"
          data-testid="edit-icon"
          onClick={() => setIsEdit(true)}
        />
      )}
      <Grid container>
        <RentedInfoContainer item sm={4} md={3}>
          <Typography variant="h4">
            {property?.company?.title || EMPTY_DATA}
          </Typography>
          <SmallLightText>{t("createContact.company")}</SmallLightText>
        </RentedInfoContainer>
        <RentedInfoContainer item sm={4} md={3}>
          <Typography variant="h4">
            {property?.contract_start
              ? formatTimestamp(Number(property?.contract_start))
              : EMPTY_DATA}
          </Typography>
          <SmallLightText>{t("property.contractStartDate")}</SmallLightText>
        </RentedInfoContainer>
        <RentedInfoContainer item sm={4} md={3}>
          <Typography variant="h4">
            {property?.company_user
              ? `${property?.company_user?.first_name} ${property?.company_user?.last_name}`
              : EMPTY_DATA}
          </Typography>
          <SmallLightText>{t("property.companyUser")}</SmallLightText>
        </RentedInfoContainer>
        <RentedInfoContainer item sm={4} md={3}>
          <Typography variant="h4">
            {property?.contract_end
              ? formatTimestamp(Number(property?.contract_end))
              : EMPTY_DATA}
          </Typography>
          <SmallLightText>{t("property.contractExpiryDate")}</SmallLightText>
        </RentedInfoContainer>
        <RentedInfoContainer item sm={4} md={3}>
          <Typography variant="h4" textAlign="center">
            {property?.contract?.name || EMPTY_DATA}
          </Typography>
          <SmallLightText>{t("contracts.contractName")}</SmallLightText>
        </RentedInfoContainer>
        <RentedInfoContainer item sm={4} md={3}>
          <Typography variant="h4">
            {property?.caretaker
              ? `${property?.caretaker?.first_name} ${property?.caretaker?.last_name}`
              : EMPTY_DATA}
          </Typography>
          <SmallLightText>{t("property.caretaker")}</SmallLightText>
        </RentedInfoContainer>
        <RentedInfoContainer item sm={4} md={3}>
          <Typography variant="h4">
            {property?.contract_cancelled
              ? formatTimestamp(Number(property?.contract_cancelled))
              : EMPTY_DATA}
          </Typography>
          <SmallLightText>{t("property.contractCancelDate")}</SmallLightText>
        </RentedInfoContainer>
        <RentedInfoContainer item sm={4} md={3}>
          <NumberContainer value={property?.property_management_cost} />
          <SmallLightText>
            {t("property.propertyManagementCost")}
          </SmallLightText>
        </RentedInfoContainer>
        <RentedInfoContainer item sm={4} md={3}>
          <NumberContainer
            value={property?.percentage_net_rent}
            measurementUnits={Measurement_Units.PERCENTAGE}
          />
          <SmallLightText>{t("property.percentageOfNetRent")}</SmallLightText>
        </RentedInfoContainer>
      </Grid>
    </FormWrapper>
  );
};

export default PropertyManagementShow;
