import http from "../utils/http";

/* istanbul ignore next */
export const getAttachments = async (params = ""): Promise<Response> => {
  return await http.get(`/api/attachments${params}`);
};

/* istanbul ignore next */
export const deleteAttachments = async (id: string): Promise<Response> => {
  return await http.delete(`/api/attachments/${id}`);
};

/* istanbul ignore next */
export const archiveAttachments = async (id: string): Promise<Response> => {
  return await http.patch(`/api/attachments/${id}`, {});
};

/* istanbul ignore next */
export const uploadAttachment = async (data: FormData): Promise<Response> => {
  return await http.post(`/api/attachments`, data);
};

/* istanbul ignore next */
export const sendEmail = async (
  id: number,
  data: FormData
): Promise<Response> => {
  return await http.post(`/api/attachments/${id}/send`, data);
};
