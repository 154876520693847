import React, { ReactElement } from "react";
import { Button, CardHeader } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Card, CardContent } from "./styled";
import CommentsList from "./list/CommentsList";
import { OpenCommentsWidgetProps } from "./types";
import { CommentsStatusIds } from "../../../types/be/comment";

const OpenCommentsWidget = ({
  toggleOpenCommentsListModal,
}: OpenCommentsWidgetProps): ReactElement => {
  const { t } = useTranslation();

  const handleSeeMore = (): void => {
    toggleOpenCommentsListModal();
  };

  return (
    <Card mb={6} sx={{ height: "100%" }}>
      <>
        <CardHeader
          action={
            <Button
              aria-label="open-comments-more"
              size="small"
              aria-haspopup={true}
              onClick={handleSeeMore}
              color="primary"
            >
              {t("home.seeMore")}
            </Button>
          }
          title={`${t("comments.openComments")}`}
        />

        <CardContent>
          <CommentsList
            statusId={CommentsStatusIds.OPEN_COMMENTS}
            showToolbar={false}
          />
        </CardContent>
      </>
    </Card>
  );
};

export default OpenCommentsWidget;
