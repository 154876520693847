import { TableCell } from "../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "ticketNumber",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.ticketNumber",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.title",
    align: "center",
  },
  {
    id: "areaName",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.areaName",
    align: "center",
  },
  {
    id: "propertyName",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.propertyName",
    align: "center",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.status",
    align: "center",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];

export const OUTDATED_STATUS_ID = 3;
