import React, { ReactElement, useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
import { Grid, Button as IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/button/Button";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DoneIcon from "@mui/icons-material/Done";
import { Formik, FormikValues } from "formik";
import { handleSubmitSwotModalForm, swotFormInitState } from "./utils";
import { getSwots, getSwotTypes } from "../../../../api/swot";
import { handleServerError } from "../../../../utils/http";
import { useParams } from "react-router-dom";
import { SwotModalProps, SwotUpdateDto } from "./types";
import {
  EditButton,
  TextField,
  ButtonBox,
  SwotGrid,
  SwotLabel,
  SwotDialogContent,
} from "./styled";
import { ErrorBox } from "../../offers/list/styled";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import Snackbar from "../../../../ui/Snackbar";
import useIsMounted from "../../../../hooks/useIsMounted";
import { Swot } from "../../../../types/be/swot";
import { Status } from "../../../../types/be/status";

const SwotModal = ({
  toggleSwotModal,
  visible,
}: SwotModalProps): ReactElement => {
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [initialValues, setInitialValues] = useState<Swot>(swotFormInitState);
  const [swotTypesList, setSwotTypesList] = useState<Status[]>([]);
  const [swotList, setSwotsList] = useState<Swot[]>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState<{ [key: string]: boolean }>({
    strength: true,
    weakness: true,
    opportunities: true,
    threads: true,
  });
  const [isSnackbarVisible, setIsSnackbarVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const isMounted = useIsMounted();

  const handleCloseSnackbar = (): void => setIsSnackbarVisible(false);

  const handleDisabled = (code: string, value: boolean): boolean => {
    setIsDisabled({ ...isDisabled, [code]: value });
    return true;
  };
  /* istanbul ignore next */
  const handleCloseModal = (): void => {
    toggleSwotModal(false);
    setIsDisabled({
      strength: true,
      weakness: true,
      opportunities: true,
      threads: true,
    });
  };

  const setSwotTypes = (data: Status[]): void => {
    setSwotTypesList(data);
  };
  const setSwots = (data: Swot & Swot[]): void => {
    setInitialValues(data);
    setSwotsList(data);
  };

  const fetchSwotTypes = useCallback(async () => {
    setIsLoading(true);
    const res = await getSwotTypes();
    isMounted() && handleServerResponse(res, setSwotTypes);
  }, [isLoading, isMounted]);

  const fetchSwots = useCallback(async () => {
    setIsLoading(true);
    const res = await getSwots(`?property_id=${id}`);
    isMounted() && handleServerResponse(res, setSwots);
  }, [isLoading, isMounted]);

  const handleServerResponse = async (
    response: Response,
    callback: (data: Status[] & (Swot & Swot[])) => void
  ): Promise<void> => {
    /* istanbul ignore next */
    if (response.status !== 200) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
      setIsLoading(false);
    } else {
      const { data } = await response.json();
      callback(data);
      setIsLoading(false);
    }
  };

  const mappedSwotTypeList = swotTypesList.reduce(
    (acc: SwotUpdateDto[], item: Status) => {
      const itemFromIndex = swotList.find(
        (indexItem) => indexItem.type.id === item.id
      );

      if (itemFromIndex) {
        acc.push({
          ...itemFromIndex.type,
          description: itemFromIndex.description,
          swotId: itemFromIndex.id,
        });
      } else {
        acc.push({ ...item, description: "", swotId: null });
      }

      return acc;
    },
    []
  );

  useEffect(() => {
    fetchSwotTypes();
    fetchSwots();
  }, [isDisabled, visible]);

  /* istanbul ignore next */
  if (error) {
    return (
      <ErrorBox
        formMessage={{ type: "error", text: SOMETHING_WENT_WRONG_ERROR }}
      />
    );
  }

  return (
    <>
      <Dialog fullWidth open={visible} onClose={handleCloseModal} maxWidth="md">
        <DialogTitle title="SWOT" onClose={handleCloseModal} />
        <SwotDialogContent>
          <Grid
            container
            spacing={6}
            columns={12}
            justifyContent={"space-around"}
            ml={0}
            width={"100%"}
          >
            {mappedSwotTypeList &&
              mappedSwotTypeList.map((swot: SwotUpdateDto) => {
                return (
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmitSwotModalForm({
                      isCreate: swot.description?.length <= 0,
                      setFormMessage,
                      handleDisabled,
                      setIsSnackbarVisible,
                      code: swot.code,
                      update_type_id: swot.id,
                      swotId: swot.swotId,
                      id,
                    })}
                    key={swot.id}
                  >
                    {({ handleSubmit }: FormikValues): ReactElement => (
                      <form onSubmit={handleSubmit}>
                        <SwotGrid item position={"relative"}>
                          <TextField
                            $description={swot?.name}
                            name="description"
                            defaultValue={swot.description || ""}
                            multiline
                            rows={13}
                            fullWidth
                            disabled={isDisabled[swot.code]}
                            inputProps={{ maxLength: 450 }}
                          />
                          <SwotLabel
                            $swotDescription={
                              swot.description !== undefined &&
                              swot.description?.length > 0
                            }
                            $isFocused={!isDisabled[swot.code]}
                          >
                            {swot.name}
                          </SwotLabel>
                          <ButtonBox>
                            {isDisabled[swot.code] ? (
                              <EditButton
                                type="button"
                                onClick={() => handleDisabled(swot.code, false)}
                              >
                                <EditOutlinedIcon data-testid="swotEditBtn" />
                              </EditButton>
                            ) : swot.description?.length > 0 ? (
                              <IconButton type="submit">
                                <DoneIcon data-testid="doneIcon" />
                              </IconButton>
                            ) : (
                              <IconButton type="submit">
                                <DoneIcon data-testid="doneIcon" />
                              </IconButton>
                            )}
                          </ButtonBox>
                        </SwotGrid>
                      </form>
                    )}
                  </Formik>
                );
              })}
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
          >
            <Grid item>
              <Button
                onClick={handleCloseModal}
                title={t("cancel")}
                variant={"text"}
                size="large"
                type="button"
              />
            </Grid>
          </Grid>
        </SwotDialogContent>
      </Dialog>
      {!!formMessage.text.length && (
        <Snackbar
          message={t(formMessage?.text)}
          color="error"
          open={isSnackbarVisible}
          handleClose={handleCloseSnackbar}
          data-testid="snackbar-swot-error"
        />
      )}
    </>
  );
};

export default SwotModal;
