import React, { ReactElement, useEffect, useCallback } from "react";
import { itemsListHeadCells } from "./utils";
import { TableCell, Grid } from "@mui/material";
import { FinanceProfile } from "../../../../types/models";
import { FinanceProfilesTableProps } from "./types";
import Table from "../../../../ui/table/Table";
import useIsMounted from "../../../../hooks/useIsMounted";
import { Search as SearchIcon } from "react-feather";
import {
  DeleteIcon,
  EditIcon,
  Input,
  Search,
  SearchIconWrapper,
} from "../../../../ui/table/styled";
import { useTranslation } from "react-i18next";
import { handleServerError } from "../../../../utils/http";
import {
  deleteFinanceProfile,
  getFinanceProfilesByContactId,
} from "../../../../api/financeProfile";
import useTable from "../../../../ui/table/useTable";
import { NumericFormat } from "react-number-format";
import {
  THOUSAND_SEPARATOR,
  DECIMAL_SEPARATOR,
  DECIMAL_SCALE,
  Measurement_Units,
} from "../../../../constants";

const FinanceTable = ({
  id,
  handleEditClick,
  financeProfileUpdated,
  setFinanceProfileUpdate,
  setIsFinanceProfileData,
}: FinanceProfilesTableProps): ReactElement => {
  const { t } = useTranslation();
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleDeleteLastPageData,
  } = useTable<FinanceProfile>();
  const isMounted = useIsMounted();

  const handleServerResponse = async (res: Response): Promise<void> => {
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const resJson = await res.json();
      setTotal(resJson.meta.total);
      setData(resJson.data);
      setIsFinanceProfileData(Boolean(resJson.data.length));
      setIsLoading(false);
    }
  };

  const fetchFinanceProfileData = useCallback(async () => {
    setIsLoading(true);
    const res = await getFinanceProfilesByContactId(id, queryParams);
    isMounted() && handleServerResponse(res);
    setFinanceProfileUpdate(false);
  }, [queryParams, setTotal, setData, setIsLoading]);

  useEffect(() => {
    if (queryParams && isMounted()) fetchFinanceProfileData();
  }, [queryParams]);

  useEffect(() => {
    isMounted() && fetchFinanceProfileData();
    if (isMounted() && financeProfileUpdated) fetchFinanceProfileData();
  }, [queryParams, financeProfileUpdated]);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteFinanceProfile(rowToDelete.id);
    handleDeleteLastPageData(fetchFinanceProfileData);
    handleConfirmToRemoveModalClose();
  }, [rowToDelete, deleteFinanceProfile, handleConfirmToRemoveModalClose]);

  const renderRow = (row: FinanceProfile): ReactElement => (
    <>
      <TableCell align="left">{row.zip_code}</TableCell>
      <TableCell align="left">{row.state?.name}</TableCell>
      <TableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          fixedDecimalScale
          decimalScale={DECIMAL_SCALE}
          displayType={"text"}
          value={row.finance_volume_from_value}
        />{" "}
        {row.finance_volume_from_value !== null && Measurement_Units.EURO}
      </TableCell>
      <TableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          fixedDecimalScale
          decimalScale={DECIMAL_SCALE}
          displayType={"text"}
          value={row.finance_volume_to_value}
        />{" "}
        {row.finance_volume_to_value !== null && Measurement_Units.EURO}
      </TableCell>
      <TableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          fixedDecimalScale
          decimalScale={DECIMAL_SCALE}
          displayType={"text"}
          value={row.ltv}
        />{" "}
        {row.ltv !== null && Measurement_Units.EURO}
      </TableCell>
      <TableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.avg_interest_rate}
        />
      </TableCell>
      <TableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          fixedDecimalScale
          decimalScale={DECIMAL_SCALE}
          displayType={"text"}
          value={row.avg_repayment}
        />{" "}
        {row.avg_repayment !== null && Measurement_Units.EURO}
      </TableCell>
      <TableCell
        align="right"
        onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
          handleCellClick(e)
        }
        style={{ whiteSpace: "nowrap" }}
      >
        <EditIcon
          role={"editIconRole"}
          onClick={(): void => handleEditClick(row.id)}
          size={18}
        />
        <DeleteIcon
          onClick={(): void => openConfirmToRemoveModal(row)}
          size={20}
          data-testid="delete-icon"
        />
      </TableCell>
    </>
  );

  const TableToolbar = (
    <Grid container sx={{ pt: 5 }}>
      <Grid item xs />
      <Grid item>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Input placeholder={t("search")} onChange={handleTableSearch} />
        </Search>
      </Grid>
    </Grid>
  );

  return (
    <Table
      data={data}
      total={total}
      currentPage={currentPage}
      order={order}
      orderBy={orderBy}
      error={error}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={handleChangePage}
      onRowRemove={handleRowRemove}
      onSortChange={handleSortChange}
      onSelectAllClick={handleSelectAllClick}
      onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
      rowsPerPage={rowsPerPage}
      isLoading={isLoading}
      isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
      listHeadCells={itemsListHeadCells}
      renderRow={renderRow}
      tableToolbar={TableToolbar}
    />
  );
};

export default FinanceTable;
