import { FormikValues } from "formik";
import { Dispatch, SetStateAction } from "react";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { FormMessageInterface } from "../../../../types/form";
import { createCounter } from "../../../../api/reading";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { CreateCounterInitialValues } from "./types";

export const handleCreateCounter = (
  setFormMessage: Dispatch<SetStateAction<FormMessageInterface>>,
  onSuccess: () => void
) => {
  return async (
    values: CreateCounterInitialValues,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const formData = new FormData();
    formData.append("number", values.number);
    formData.append("type_id", values.type_id);
    formData.append("property_area_id", values.property_area_id);

    const response = await createCounter(formData);
    const result = await response?.json();

    if (response.status === 201) {
      setStatus({
        success: true,
      });
      setFormMessage(FormMessageInItState);
      onSuccess();
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(result?.errors),
      });
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
    }
    setSubmitting(false);
  };
};
