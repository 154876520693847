import React, { ReactElement } from "react";
import { MoreVerticalButton } from "../../list/styled";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import { AnchorDict, DotMenuProps } from "./types";
import { t } from "i18next";
import { RELEASE_STATUS } from "../../../../constants";
import capitalize from "lodash/capitalize";
import { RELEASE_LEVEL } from "../../../../utils/common";
import { MoreVertical } from "react-feather";
const DotMenu = ({
  checkedDict,
  checkedIsPaid,
  handleReleaseButtonClick,
  moveToPaid,
  status,
  tab,
}: DotMenuProps): ReactElement => {
  const [anchorDict, setAnchorDict] = React.useState<AnchorDict>({
    releaseAll: null,
  });

  const releaseAllTab = "releaseAll";

  const handleClick =
    (tab: string) => (event: React.MouseEvent<HTMLElement>) => {
      const currentTab = event.currentTarget;
      setAnchorDict((d) => ({ ...d, [tab]: currentTab }));
    };

  const handleClose = (): void => {
    setAnchorDict({ releaseAll: null });
  };

  const selected =
    checkedDict &&
    Object.keys(checkedDict)
      .filter((el) => checkedDict[el])
      .map((el) => Number(el));

  const paid =
    checkedIsPaid &&
    Object.keys(checkedIsPaid)
      .filter((el) => checkedIsPaid[el])
      .map((el) => Number(el));

  const hasPaid =
    selected && paid && selected.some((item: number) => paid.includes(item));

  return (
    <>
      <Tooltip
        title={
          selected?.length === 0
            ? t(`documentRelease.${tab}.notSelected${capitalize(tab)}s`)
            : null
        }
      >
        <span>
          <MoreVerticalButton
            aria-haspopup="true"
            size={"small"}
            type="button"
            variant="text"
            disableElevation
            disabled={selected?.length === 0}
            data-testid={"manually-menu"}
            onClick={handleClick(releaseAllTab)}
          >
            <MoreVertical />
          </MoreVerticalButton>
        </span>
      </Tooltip>
      <Menu
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorEl={anchorDict.releaseAll}
        open={Boolean(anchorDict.releaseAll)}
        data-testid={"menu"}
        onClose={handleClose}
      >
        <MenuItem
          onClick={(): void => {
            handleReleaseButtonClick(
              selected!,
              "final_released",
              RELEASE_LEVEL.FIRST_LEVEL_RELEASE
            );
            handleClose();
          }}
          disableRipple
          disabled={status === RELEASE_STATUS.FINAL_RELEASED}
          data-testid={"release-all-selected-items"}
        >
          {t(`documentRelease.${tab}.releaseAllSelected${capitalize(tab)}s`)}
        </MenuItem>
        <MenuItem
          onClick={(): void => {
            handleReleaseButtonClick(
              selected!,
              "pending",
              RELEASE_LEVEL.FIRST_LEVEL_RELEASE
            );
            handleClose();
          }}
          disableRipple
          disabled={status === RELEASE_STATUS.PENDING}
          data-testid={"review-all-selected-items"}
        >
          {t(`documentRelease.${tab}.reviewAllSelected${capitalize(tab)}s`)}
        </MenuItem>
        <MenuItem
          onClick={(): void => {
            handleReleaseButtonClick(
              selected!,
              "rejected",
              RELEASE_LEVEL.FIRST_LEVEL_RELEASE
            );
            handleClose();
          }}
          disabled={status === RELEASE_STATUS.REJECTED}
          disableRipple
          data-testid={"reject-all-selected-items"}
        >
          {t(`documentRelease.${tab}.rejectAllSelected${capitalize(tab)}s`)}
        </MenuItem>
        {moveToPaid && (
          <MenuItem
            onClick={(): void => {
              moveToPaid(selected!, hasPaid ? 0 : 1);
              handleClose();
            }}
            disableRipple
            data-testid={"unpaid-selected-invoices"}
          >
            {t("documentRelease.invoice.markAllSelectedInvoicesAsUnpaid")}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default DotMenu;
