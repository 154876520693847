import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Comment as BEComment } from "../../types/be/comment";
import {
  createComment,
  deleteComment,
  getComments,
  getMentionedUsers,
} from "../../api/comments";
import {
  objectGetParamsToString,
  prepareQueryParams,
} from "../../utils/common";
import { COMMENTABLE } from "../../constants";
import useCKEditor from "../ckeditor/useCKEditor";
import { FetchState, useFetch } from "../../hooks/useFetch";
import { User } from "../../types/models";

interface Props {
  relation: COMMENTABLE;
  relation_id: string;
}

interface ReturnType {
  fetchComments: () => Promise<void>;
  comments: BEComment[];
  currentPage: number;
  totalPages: number;
  handlePaginationChange: (page: number) => void;
  handleCommentDelete: (comment: BEComment) => Promise<void>;
  handleCommentSave: () => Promise<void>;
  content: string;
  setContent: Dispatch<SetStateAction<string>>;
  deleting: boolean;
  loading: boolean;
  mentionedUsers: User[] | null;
  isMentionedUsersLoading: boolean;
  setMentionedUsers: Dispatch<SetStateAction<FetchState<User[]>>>;
  onAvatarLoaded: () => void;
  avatarsLoaded: boolean;
}

/* istanbul ignore next */
const useComments = ({ relation, relation_id }: Props): ReturnType => {
  const { content, setContent } = useCKEditor();
  const [comments, setComments] = useState<BEComment[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [avatarsLoaded, setAvatarsLoaded] = useState(false);

  const {
    data: mentionedUsers,
    setState: setMentionedUsers,
    run: runMentionedUsers,
    isLoading: isMentionedUsersLoading,
  } = useFetch<User[]>();

  const fetchComments = async (refresh = false): Promise<void> => {
    setLoading(true);
    const response = await getComments(
      `?${objectGetParamsToString({
        relation_id,
        relation,
        page: currentPage,
        limit: 30,
      })}`
    );
    const dataJson = await response.json();
    setTotalPages(dataJson?.meta?.last_page);
    setComments((o) =>
      o && dataJson.data && !refresh ? [...o, ...dataJson.data] : dataJson.data
    );
    setLoading(false);
  };

  const handleCommentSave = async (): Promise<void> => {
    if (!content) return;

    const response = createComment({
      relation,
      relation_id,
      body: content,
      file_id: [],
    });
    const comment = (await (await response).json()).data;
    setContent("");
    setComments((comments) => [comment, ...comments]);
  };

  const handleCommentDelete = async (comment: BEComment): Promise<void> => {
    setDeleting(true);

    try {
      await deleteComment(comment.id);
      fetchComments(true);
    } catch (e) {
      console.log(e);
    } finally {
      setDeleting(false);
    }
  };

  const handlePaginationChange = (page: number): void => {
    setCurrentPage(page);
  };

  const after = (count: number, f: () => void): (() => void) => {
    let noOfCalls = 0;
    return () => {
      noOfCalls = noOfCalls + 1;
      if (count === noOfCalls) {
        f();
      }
    };
  };

  const onAvatarLoaded = after(
    (comments || []).filter((comment) => comment.user.avatar).length,
    () => setAvatarsLoaded(true)
  );

  useEffect(() => {
    if (relation && relation_id) {
      fetchComments();
    }
  }, [currentPage, relation, relation_id]);

  useEffect(() => {
    const params = prepareQueryParams("", {
      relation_id,
      relation,
    });
    runMentionedUsers(getMentionedUsers(params));
  }, []);

  return {
    fetchComments,
    comments,
    currentPage,
    totalPages,
    handlePaginationChange,
    handleCommentDelete,
    handleCommentSave,
    content,
    setContent,
    deleting,
    loading,
    mentionedUsers,
    isMentionedUsersLoading,
    setMentionedUsers,
    onAvatarLoaded,
    avatarsLoaded,
  };
};

export default useComments;
