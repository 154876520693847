import { ReactElement } from "react";
import { BreadcrumbItem } from "../../../ui/pageWrapper/PageWrapper";
import { Property } from "../../../types/be/property";

export enum PROPERTY_MODULE {
  QUICK_SHEET = "edit",
  OBJECT_DATA_SHEET = "object-data-sheet",
  MAINTENANCE = "maintenance",
}

export interface UsePropertyWrapperReturnType {
  getTitle: (module?: PROPERTY_MODULE) => string;
  getBreadcrumb: (module?: PROPERTY_MODULE) => BreadcrumbItem[];
  propertyBreadcrumbs: Property | null;
  getComponent: () => ReactElement;
}

export interface PropertyWrapperProps {
  isCompany?: boolean;
}
