import React, { ReactElement } from "react";

import { Box, Button, Grid } from "@mui/material";
import {
  AvatarBox,
  SubText,
  MainText,
  HoverBox,
  InfoBox,
} from "../../invoices/list/styled";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";
import { getFirstLettersFromFullName } from "../../../../utils/common";

interface ProviderHoverBox {
  fullName: string;
  companyName: string;
  city?: string;
  contactId: number;
  onContactShow: () => void;
  documentActionLabel?: string;
  onDocumentShow?: () => void;
}
/* istanbul ignore next */
const ProviderInfoHoverBox = ({
  fullName,
  companyName,
  city,
  documentActionLabel,
  onContactShow,
  onDocumentShow,
}: ProviderHoverBox): ReactElement => {
  const { t } = useTranslation();
  return (
    <HoverBox container data-testid="hoverProviderBox">
      <Grid item>
        <AvatarBox>{getFirstLettersFromFullName(fullName)}</AvatarBox>
      </Grid>
      <Grid item>
        {companyName && (
          <InfoBox>
            <BusinessOutlinedIcon fontSize="large" />
            <MainText>{companyName}</MainText>
          </InfoBox>
        )}
        <InfoBox>
          <PersonIcon fontSize="small" />
          <SubText>{fullName}</SubText>
        </InfoBox>
        {city && (
          <InfoBox>
            <LocationOnOutlinedIcon fontSize="small" />
            <SubText>{city}</SubText>
          </InfoBox>
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
          {onDocumentShow && documentActionLabel && (
            <Button onClick={onDocumentShow} variant="text" sx={{ mr: 2 }}>
              {t(documentActionLabel)}
            </Button>
          )}
          <Button onClick={onContactShow} variant="text">
            {t("documentRelease.invoice.showContact")}
          </Button>
        </Box>
      </Grid>
    </HoverBox>
  );
};

export default ProviderInfoHoverBox;
