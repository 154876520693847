/* istanbul ignore file */

import { FormikValues } from "formik/dist/types";
import { mapBackendValidationErrors } from "../../../../utils/form";
import React from "react";
import { updateProperty } from "../../../../api/property";
import { FormMessageInterface } from "../../../../types/form";
import { Property } from "../../../../types/be/property";
import { replaceNullOrUndefinedByEmptyString } from "../../../../utils/common";
import { PropertyUpdate } from "../../../../types/fe/property";

export const touchedInitState = {
  own_resources: false,
  bank_loan: false,
  interest_on_own_funds: false,
  interest_bank_loan: false,
  repayment_bank: false,
};

export const prepareForm = (property: Property): Property => ({
  ...property,
  ...replaceNullOrUndefinedByEmptyString(property),
});

export const prepareForm1 = (property: Property): Property => ({
  ...property,
  ...replaceNullOrUndefinedByEmptyString(property),
});

export type FormSubmitHandlerProps = {
  setFormMessage: React.Dispatch<React.SetStateAction<FormMessageInterface>>;
};

export const handleUpdatePropertyAdditionalCosts = (
  id: string,
  updateSuccessCallback: () => void,
  { setFormMessage }: FormSubmitHandlerProps
) => {
  return async (
    values: PropertyUpdate,
    { setStatus, setSubmitting, setTouched }: FormikValues
  ): Promise<void> => {
    const formData = { ...values };
    const response = await updateProperty(formData);
    const json = await response.json();
    if (response.status === 200) {
      setStatus({
        success: true,
        errors: {},
      });
      setTouched(touchedInitState);
      updateSuccessCallback();
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({ type: "error", text: "errorSomethingWentWrong" });
    }
  };
};
