import React, { ReactElement } from "react";
import { Button, Grid, Tooltip } from "@mui/material";
import { FileManagementViewButtonsProps, PageViewTypes } from "./types";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { useTranslation } from "react-i18next";
/* istanbul ignore next */
const FileManagementViewButtons = ({
  layout,
  setLayout,
}: FileManagementViewButtonsProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="center">
      <Grid item display="flex">
        <Tooltip title={t("property.list")} placement="top">
          <Button
            role="list-layout"
            data-testid="list-layout"
            fullWidth
            size={"small"}
            variant={layout === PageViewTypes.LIST ? "contained" : "outlined"}
            onClick={() => {
              setLayout(PageViewTypes.LIST);
            }}
            sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          >
            <FormatListBulletedOutlinedIcon
              color={layout === PageViewTypes.LIST ? "inherit" : "success"}
              fontSize="medium"
            />
          </Button>
        </Tooltip>
        <Tooltip title={t("property.grid")} placement="top">
          <Button
            role="grid-layout"
            data-testid="list-layout"
            fullWidth
            size="medium"
            variant={layout === PageViewTypes.ICONS ? "contained" : "outlined"}
            onClick={() => {
              setLayout(PageViewTypes.ICONS);
            }}
            sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          >
            <GridViewOutlinedIcon
              color={layout === PageViewTypes.ICONS ? "inherit" : "success"}
              fontSize="medium"
            />
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default FileManagementViewButtons;
