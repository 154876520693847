import React, { ReactElement } from "react";
import { Tooltip, Typography } from "@mui/material";
import { TableCell } from "../../styled";
import { HOVER_DELAY } from "../../../../constants";
import ProviderInfoHoverBox from "./ProviderHoverInfoBlock";
import { ContactShort } from "../../../../types/be/contact";

interface ProviderTableCellProps {
  contact: ContactShort;
  onContactShow: () => void;
  documentActionLabel?: string;
  onDocumentShow?: () => void;
}
/* istanbul ignore next */
const ProviderTableCell = ({
  contact,
  onContactShow,
  documentActionLabel,
  onDocumentShow,
}: ProviderTableCellProps): ReactElement => {
  return (
    <TableCell align="left">
      {contact ? (
        <Tooltip
          data-testid="hoverProvider"
          enterDelay={HOVER_DELAY}
          enterNextDelay={HOVER_DELAY}
          componentsProps={{
            tooltip: {
              style: {
                padding: 0,
                zIndex: 20,
              },
            },
          }}
          title={
            <ProviderInfoHoverBox
              fullName={contact.full_name}
              companyName={contact.company_name}
              city={contact.city}
              contactId={contact?.id}
              documentActionLabel={documentActionLabel}
              onContactShow={onContactShow}
              onDocumentShow={onDocumentShow}
            />
          }
        >
          <Typography color="primary" sx={{ cursor: "pointer" }}>
            {contact?.company_name || contact?.full_name}
          </Typography>
        </Tooltip>
      ) : (
        /* istanbul ignore next */
        "---"
      )}
    </TableCell>
  );
};

export default ProviderTableCell;
