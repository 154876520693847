import { Formik } from "formik";
import { Dialog, DialogContent, Stack } from "@mui/material";
import React, { ReactElement, useState, useEffect, createContext } from "react";
import {
  AdminUserSchedulesProps,
  DateFormat,
  VacationHolidayManagementContextType,
} from "./types";
import * as Yup from "yup";
import { FormMessageInItState, YEARS_PARAM } from "../../../constants";
import FormAlert from "../../../ui/formAlert/FormAlert";
import {
  createVacationHolidayManagementFormInitState,
  handleSubmitAdminUserSchedules,
  initSelectedYear,
} from "./utils";
import Loader from "../../Loader";
import useValidation from "../../../hooks/useValidation";
import DialogTitle from "../../../ui/dialog/DialogTitle";
import { User } from "../../../types/be.interfaces";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import DateObject from "react-date-object";
import moment from "moment";
import Form from "./Form";
import { prepareQueryParams } from "../../../utils/common";
import { PublicHoliday as BEPublicHoliday } from "../../../types/be/publicHolidays";
import { useFetch } from "../../../hooks/useFetch";
import { getPublicHolidays } from "../../../api/userSchedules";
import useRoles from "../../../hooks/useRoles";
import { getUsersSearch } from "../../../api/users";
import { Role } from "../../../types/be/role";

export const VacationHolidayManagementContext =
  createContext<VacationHolidayManagementContextType>(undefined!);

/* istanbul ignore next */
const VacationHolidayManagement = ({
  isVacationHolidayManagementModalOpen,
  handleVacationHolidayManagementModalClose,
  handleSuccessVacationHolidayManagementSubmission,
  isLoading,
  states,
  employeeOptionId,
}: AdminUserSchedulesProps): ReactElement => {
  const [initialValues] = useState(
    createVacationHolidayManagementFormInitState
  );
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [calendarValue, setCalendarValue] = useState<
    DateObject | DateObject[] | null
  >(null);
  const [options, setOptions] = useState<User[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [dates, setDates] = useState<DateFormat[]>([]);
  const [wasSubmittedWithError, setWasSubmittedWithError] = useState(false);

  const [selectedYear, setSelectedYear] = useState(initSelectedYear);

  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const [stateCode, setStateCode] = useState("");

  const { data: publicHolidays, run: runPublicHolidays } =
    useFetch<BEPublicHoliday[]>();

  const {
    data: users,
    run: runUsers,
    isLoading: isUsersLoading,
    setState: setUsers,
  } = useFetch<User[]>();

  const { roles, isLoading: isRolesLoading } = useRoles();

  const handleToggleSelectAll = (): void => {
    setSelectAll((prev) => {
      if (!prev) setOptions([...users!]);
      else setOptions([]);
      return !prev;
    });
  };

  const { vacation_holiday_user_selection, state_id } = useValidation();

  useEffect(() => {
    if (!selectedRoles.length) {
      setUsers((prevState) => ({ ...prevState, data: [] }));
      setOptions([]);
      return setSelectAll(false);
    }

    const data = { role_code: selectedRoles.map(({ code }) => code) };

    runUsers(getUsersSearch(data));
  }, [selectedRoles]);

  useEffect(() => {
    if (!stateCode) return;

    const params = prepareQueryParams("", {
      [YEARS_PARAM]: selectedYear,
      state_code: stateCode,
    });
    runPublicHolidays(getPublicHolidays(params));
  }, [selectedYear, stateCode]);

  useEffect(() => {
    if (!publicHolidays) return;

    const dates: DateObject[] = [];
    publicHolidays.forEach((el) => {
      dates.push(new DateObject(el.date));
    });
    setCalendarValue([...dates]);
  }, [publicHolidays]);

  useEffect(() => {
    if (calendarValue) {
      const arrayOfObjects = calendarValue
        .toString()
        .split(",")
        .map((item) => {
          const [day, month, year] = item.split(".");
          return {
            date_from: moment(new Date(+year, +month - 1, +day)).format(
              "YYYY-MM-DD"
            ),
            date_to: moment(new Date(+year, +month - 1, +day)).format(
              "YYYY-MM-DD"
            ),
          };
        });
      calendarValue && setDates(arrayOfObjects);
    }
  }, [calendarValue]);

  useEffect(() => {
    wasSubmittedWithError && calendarValue && setWasSubmittedWithError(false);
  }, [calendarValue]);

  const resetValues = (): void => {
    setCalendarValue(null);
    setOptions([]);
    setDates([]);
    setWasSubmittedWithError(false);
    setSelectedYear(initSelectedYear);
    handleVacationHolidayManagementModalClose();
  };

  return (
    <VacationHolidayManagementContext.Provider
      value={{
        options,
        setSelectAll,
        setOptions,
        handleToggleSelectAll,
        selectAll,
        states,
        calendarValue,
        wasSubmittedWithError,
        resetValues,
        setCalendarValue,
        setStateCode,
        publicHolidays,
        selectedYear,
        setSelectedYear,
        roles,
        users,
        isUsersLoading,
        isRolesLoading,
        selectedRoles,
        setSelectedRoles,
      }}
    >
      <Dialog
        maxWidth="xs"
        open={isVacationHolidayManagementModalOpen}
        onClose={resetValues}
        aria-labelledby="max-width-dialog-title"
        data-testid="create-vacation-holiday-dialog"
        fullWidth
      >
        <DialogTitle
          title="employeeOverview.vacationHolidaysManagement"
          onClose={resetValues}
        />
        <DialogContent>
          {isLoading ? (
            <Loader />
          ) : (
            <Stack>
              {formMessage.text && (
                <FormAlert
                  formMessage={formMessage}
                  sx={{ marginBottom: "10px" }}
                />
              )}
              <Formik
                enableReinitialize
                initialValues={{
                  ...initialValues,
                }}
                validationSchema={Yup.object().shape({
                  vacation_holiday_user_selection,
                  state_id,
                })}
                onSubmit={handleSubmitAdminUserSchedules({
                  setFormMessage,
                  handleSuccessVacationHolidayManagementSubmission,
                  dates,
                  resetValues,
                  setWasSubmittedWithError,
                  publicHolidays,
                  employeeOptionId,
                })}
              >
                {(props): ReactElement => <Form {...props} />}
              </Formik>
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </VacationHolidayManagementContext.Provider>
  );
};

export default VacationHolidayManagement;
