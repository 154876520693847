import { FormikValues } from "formik";
import { TFunction } from "i18next";
import { updateArea } from "../../../../../../../api/area";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../../constants";
import { Area } from "../../../../../../../types/be/area";
import { HTTP_STATUS_CODES } from "../../../../../../../types/server";
import { Snackbar } from "../../../../../../../ui/snackbar1/useSnackbar";
import {
  getModifiedKeys,
  parseNumber,
  replaceEntityById,
} from "../../../../../../../utils/common";
import { mapBackendValidationErrors } from "../../../../../../../utils/form";

const prepareDataBeforeSubmit = (data: Area): Area => {
  return {
    ...data,
    deposit: data?.deposit ? parseNumber(data?.deposit) : null,
    price: data?.price ? parseNumber(data?.price) : null,
    sales_square_meters: data?.sales_square_meters
      ? parseNumber(data?.sales_square_meters)
      : null,
    square_meters: data?.square_meters
      ? parseNumber(data?.square_meters)
      : null,
    last_modernization: data?.last_modernization
      ? new Date(String(data?.last_modernization))?.getFullYear()
      : null,
    built: data?.built ? new Date(String(data?.built))?.getFullYear() : null,
    ...replaceEntityById({
      store_type_id: data?.store_type,
      marketing_type_id: data?.marketing_type,
      state_id: data?.state,
      price_unit_id: data?.price_unit,
      subject_commission_id: data?.subject_commission,
    }),
  };
};

export const handleUpdateEmptyAreaListing = (
  initialValues: Area,
  t: TFunction<"translation", undefined, "translation">,
  onSuccessCallback: () => void,
  snackBar: Snackbar
) => {
  return async (
    values: Area,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const data = prepareDataBeforeSubmit(
      getModifiedKeys(initialValues, values, [
        "store_type",
        "marketing_type",
        "state",
        "contact_asset_manager_id",
        "deposit",
        "price",
        "price_unit",
        "subject_commission",
        "last_modernization",
        "built",
        "sales_square_meters",
        "square_meters",
      ])
    );
    const response = await updateArea(String(values?.id), data);
    const json = await response.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      snackBar.success(t("property.emptyAreaForm.listingUpdatedSuccessfully"));
      onSuccessCallback();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
    } else {
      snackBar.error(t(SOMETHING_WENT_WRONG_ERROR));
    }
    setSubmitting(false);
  };
};
