import { FormikValues } from "formik";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../ui/button/Button";
import { Form, TextFieldStyled as TextField } from "../../styled";
const CreateOfferTopicForm = ({
  isSubmitting,
  handleSubmit,
  status,
  setCreateOfferTopicFormMode,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();
  const submitBtnClassName = "submit-create-topic";
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void =>
    !event.relatedTarget?.className.includes(submitBtnClassName) &&
    setCreateOfferTopicFormMode(false);

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <TextField
        autoFocus
        name={"name"}
        status={status}
        label={t("offers.topic")}
        required
        onBlur={handleBlur}
        InputProps={{
          endAdornment: (
            <Button
              title={t("tenant.ok")}
              sx={{ whiteSpace: "no-wrap" }}
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              size="small"
              color="success"
              variant="contained"
              className={submitBtnClassName}
              testId="submit-create-topic"
            />
          ),
        }}
      />
    </Form>
  );
};

export default CreateOfferTopicForm;
