import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useTable from "../../../ui/table/useTable";
import { User } from "../../../types/models";
import { handleServerError } from "../../../utils/http";
import { Grid, TableCell } from "@mui/material";
import { Input, Search, SearchIconWrapper } from "../../../ui/table/styled";
import { Search as SearchIcon } from "react-feather";
import useIsMounted from "../../../hooks/useIsMounted";
import Table from "../../../ui/table/Table";
import { itemsListHeadCells } from "./utils";
import { getVacations } from "../../../api/vacationRequests";
import { Vacation } from "./types";
import TotalVacationTableCell from "./totalVacationTableCell";
import Snackbar from "../../../ui/Snackbar";
import { SnackbarMessage } from "../../inbox/types";
import { snackbarInitState } from "../../inbox/list/utils";
import { FormWrapper } from "./styled";

const ManagingVacationLengths = (): ReactElement => {
  const [snackbarData, setSnackbarData] =
    useState<SnackbarMessage>(snackbarInitState);
  const { t } = useTranslation();
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
  } = useTable<User>();

  useEffect(() => {
    if (isMounted()) fetchVacationList();
  }, [queryParams]);

  const fetchVacationList = useCallback(async () => {
    setIsLoading(true);
    const res = await getVacations(queryParams);
    if (!res.ok) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const resJson = await res.json();
      setTotal(resJson.meta.total);
      setData(resJson.data);
      setIsLoading(false);
    }
  }, [queryParams, setIsLoading, setTotal, setData, setIsLoading]);

  const handleCloseSnackbar = (): void => setSnackbarData(snackbarInitState);

  const renderRow = (row: Vacation): ReactElement => {
    return (
      <>
        <TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
        <TableCell>
          <TotalVacationTableCell
            userVacation={row}
            setSnackbarData={setSnackbarData}
          />
        </TableCell>
      </>
    );
  };

  const TableToolbar = (
    <Grid container sx={{ pt: 5, mb: 2 }}>
      <Grid item xs />
      <Grid item>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Input placeholder={t("search")} onChange={handleTableSearch} />
        </Search>
      </Grid>
    </Grid>
  );

  const isMounted = useIsMounted();

  return (
    <>
      <FormWrapper>
        <Table
          data={data}
          total={total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={itemsListHeadCells}
          renderRow={renderRow}
          tableToolbar={TableToolbar}
          noDataIsAvailablePlaceholder={"table.noVacationsAreAvailable"}
        />
      </FormWrapper>
      <Snackbar
        message={snackbarData.text}
        color={snackbarData.color}
        open={snackbarData.visible}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};
export default ManagingVacationLengths;
