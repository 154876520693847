import React, { ReactElement } from "react";
import { FileWrapper, HoverMask } from "./styled";
import {
  HoverMaskWrapper,
  ImageThumbnail,
} from "../../../imageViewer/simple/styled";
import { Box, LinearProgress, Typography } from "@mui/material";
/* istanbul ignore next */
const InProgress = ({
  url,
  progressPercentage,
}: {
  url: string;
  progressPercentage: number | null;
}): ReactElement => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    if(progressPercentage !== null) {
      return;
    }
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 90) {
          clearInterval(timer);
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 90);
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    setProgress(progressPercentage || 0)
  },[progressPercentage])

  return (
    <FileWrapper>
      <HoverMaskWrapper>
        <HoverMask $isHovered={true}>
          <Box sx={{ width: "100%", px: 2 }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                progress
              )}%`}
              </Typography>
            </Box>
        </HoverMask>
      </HoverMaskWrapper>
      <ImageThumbnail src={url} alt="" />
    </FileWrapper>
  );
};

export default InProgress;
