import React, { ReactElement, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import { AddEmployeeDialogProps } from "./types";
import Form from "./form";
import {
  addEmployeeInitialValues,
  handleEditInventoryItemFormSubmit,
} from "./utils";
import { FormMessageInterface } from "../../../types/form";
import FormAlert from "../../../ui/formAlert/FormAlert";
import { FormMessageInItState } from "../../../constants";
import * as Yup from "yup";
import useValidation from "../../../hooks/useValidation";
import DialogTitle from "../../../ui/dialog/DialogTitle";

const AddEmployeeDialog = ({
  open,
  onClose,
  id,
  afterAddEmployee,
}: AddEmployeeDialogProps): ReactElement => {
  const { user_id, received_date } = useValidation();
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="create-tenant-dialog"
    >
      <DialogTitle title="companyOwnership.addEmployee" onClose={onClose} />
      <DialogContent>
        {formMessage.text ? (
          <FormAlert formMessage={formMessage} sx={{ marginBottom: "10px" }} />
        ) : null}
        <Formik
          initialValues={addEmployeeInitialValues}
          onSubmit={handleEditInventoryItemFormSubmit(id, {
            setFormMessage,
            afterAddEmployee,
          })}
          validationSchema={Yup.object().shape({
            user_id,
            received_date: received_date.required,
          })}
        >
          {(props) => <Form {...props} onClose={onClose} />}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddEmployeeDialog;
