import { FormikValues } from "formik";
import { createSwot, updateSwot } from "../../../../api/swot";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { Swot } from "../../../../types/be/swot";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { SubmitSwotModalForm } from "./types";

export const swotFormInitState = {
  id: 1,
  type: {
    id: 1,
    code: "",
    name: "",
  },
  description: "",
};

export const handleSubmitSwotModalForm = ({
  isCreate,
  setFormMessage,
  handleDisabled,
  setIsSnackbarVisible,
  code,
  update_type_id,
  swotId,
  id,
}: SubmitSwotModalForm) => {
  return async (values: Swot, { setStatus }: FormikValues): Promise<void> => {
    const response = isCreate
      ? await createSwot({
          type_id: update_type_id,
          property_id: Number(id),
          description: values.description,
        })
      : await updateSwot(String(swotId), { description: values.description });

    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      handleDisabled(code, true);
      setFormMessage(FormMessageInItState);
      setStatus({
        success: true,
        errors: {},
      });
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setIsSnackbarVisible(true);
    }
  };
};
