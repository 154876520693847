import { FormikValues } from "formik";
import { updateTenant } from "../../../../../../../../../../api/propertyTenant";
import { HTTP_STATUS_CODES } from "../../../../../../../../../../types/server";
import { mapBackendValidationErrors } from "../../../../../../../../../../utils/form";
/*istanbul ignore next*/
export const handleUpdateFutureTenantContact = (
  onClose: () => void,
  refetchData: () => void
) => {
  return async (
    data: { id: number; contact_id: number | null },
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const { id, contact_id } = data;
    const response = await updateTenant({
      id,
      contact_id,
    });
    const json = await response.json();
    if (response.status === HTTP_STATUS_CODES.OK) {
      setStatus({
        success: true,
        errors: {},
      });
      refetchData();
      onClose();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      const { errors } = json;
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(errors),
      });
      setSubmitting(false);
    } else {
      setSubmitting(false);
    }
  };
};
