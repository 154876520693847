import AuthGuard from "../../components/guards/AuthGuard";
import AccessGuard from "../../components/guards/AccessGuard";
import {
  PermissionsCreate,
  PermissionsRead,
  PermissionsUpdate,
  UserPermissions,
  UserRoles,
} from "../../constants";
import DashboardLayout from "../../layouts/dashboard";
import UsersList from "../../components/users/list/UsersList";
import CreateUser from "../../components/users/create/CreateUser";
import EditUser from "../../components/users/edit/EditUser";
import UsersActivity from "../../components/activity/list";
import React from "react";

export default {
  path: "/:client/users",
  element: (
    <AuthGuard>
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "user-management",
      element: (
        <AccessGuard
          roles={[UserRoles.ADMIN]}
          permissions={[PermissionsRead.USER_MANAGEMENT]}
        >
          <UsersList />
        </AccessGuard>
      ),
    },
    {
      path: "create",
      element: (
        <AccessGuard
          roles={[UserRoles.ADMIN]}
          permissions={[PermissionsCreate.USER_MANAGEMENT]}
        >
          <CreateUser />
        </AccessGuard>
      ),
    },
    {
      path: "user-management/edit/:id",
      element: (
        <AccessGuard
          roles={[UserRoles.ADMIN]}
          permissions={[PermissionsUpdate.USER_MANAGEMENT]}
        >
          <EditUser />
        </AccessGuard>
      ),
    },
    {
      path: "activity",
      element: (
        <AccessGuard
          roles={[UserRoles.ADMIN]}
          permissions={[UserPermissions.USER_SCHEDULE_ACCESS]}
        >
          <UsersActivity />
        </AccessGuard>
      ),
    },
  ],
};
