import {
  VacationHolidayManagementFormData,
  SubmitAdminUserSchedulesProps,
  CustomTooltip,
  WeekendFilterTypes,
} from "./types";
import { grey } from "@mui/material/colors";
import { createAdminUserSchedules } from "../../../api/userSchedules";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { VacationHoliday } from "../../../types/fe.interfaces";
import { mapBackendValidationErrors } from "../../../utils/form";
import { FormikValues } from "formik/dist/types";
import { DateObject } from "react-multi-date-picker";
import moment from "moment";
import uniqBy from "lodash/uniqBy";

const touchedInitState = {
  vacation_holiday_user_selection: false,
};

export const initInsuranceRange = [new Date(), new Date()];
/* istanbul ignore next */
export const createVacationHolidayManagementFormInitState: VacationHolidayManagementFormData =
  {
    vacation_holiday_user_selection: [],
    description: "",
    option_id: "",
    is_blocked: 0,
    state_id: null,
  };
/* istanbul ignore next */
export const handleSubmitAdminUserSchedules = ({
  setFormMessage,
  handleSuccessVacationHolidayManagementSubmission,
  dates,
  resetValues,
  setWasSubmittedWithError,
  publicHolidays,
  employeeOptionId,
}: SubmitAdminUserSchedulesProps) => {
  return async (
    values: VacationHolidayManagementFormData,
    { setStatus, setTouched, setSubmitting }: FormikValues
  ): Promise<void | Response> => {
    const uniqDates = uniqBy(
      dates.map(({ date_from, date_to }) => [date_from, date_to]).flat(1),
      (year) => year
    );
    const holiday_type_id = publicHolidays
      ?.filter(
        (holiday) =>
          uniqDates.some((date) => moment(date).isSame(holiday.date)) &&
          holiday.state.id === values.state_id
      )
      .map((el) => el.id);
    const payload: VacationHoliday = {
      user_id: values.vacation_holiday_user_selection,
      dates,
      option_id: employeeOptionId.PUBLIC_HOLIDAY!,
      description: values.description,
      holiday_type_id,
    };
    const response = await createAdminUserSchedules(payload);
    const json = await response.json();
    /* istanbul ignore next */
    if ([201, 200].includes(response?.status)) {
      setStatus({
        success: true,
        errors: {},
      });
      resetValues();
      setTouched(touchedInitState);
      setFormMessage({
        type: "success",
        text: "",
      });
      handleSuccessVacationHolidayManagementSubmission();
      return response;
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json?.errors),
      });
      setWasSubmittedWithError(true);
      setSubmitting(false);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
    }
  };
};
/* istanbul ignore next */
export const filterWeekend = (
  date: DateObject
): WeekendFilterTypes | undefined => {
  const isWeekend = [0, 6].includes(date.weekDay.index);
  if (isWeekend)
    return {
      disabled: true,
      style: { color: grey[600] },
    };
};

export const initTooltipState: CustomTooltip = {
  top: 0,
  left: 0,
  visible: false,
  title: "",
};

export const initSelectedYear = new Date().getFullYear();
export const initSelectedRoleCode: string[] = [];

export const selectAllOptionId = -1;
