import { FormikValues } from "formik";
import { updateInbox } from "../../../../api/inbox";
import { HandleInboxAddContactProps, InboxAddContactData } from "./types";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../constants";
/* istanbul ignore next */
export const handleInboxUpdate = ({
  id,
  setSnackbarData,
  t,
}: HandleInboxAddContactProps) => {
  return async (
    data: InboxAddContactData,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const formData = new FormData();
    data?.contact_id && formData.append("contact_id", String(data?.contact_id));
    formData.append("_method", "PUT");
    const response = await updateInbox(id, formData);
    if (response.ok) {
      setStatus({
        success: true,
        errors: {},
      });
      setSnackbarData({
        visible: true,
        text: t("inbox.providerWasAdded"),
        color: "success",
      });
    } else {
      setSnackbarData({
        visible: true,
        text: t(SOMETHING_WENT_WRONG_ERROR),
        color: "error",
      });
      setSubmitting(false);
    }
  };
};
