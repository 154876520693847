import React, { ReactElement, useState } from "react";
import { DialogContent, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import { ListDialogProps } from "./types";
import CloseButton from "../../ui/dialog/CloseButton";
import { DialogTitleContainer, ErrorBox } from "./styled";
import { FormMessageErrorState } from "../../constants";
import BankAccountPage from "../bankAccount";
/* istanbul ignore next */
const BankAccountsListDialog = ({
  visible,
  toggleListModal,
}: ListDialogProps): ReactElement => {
  const { t } = useTranslation();
  const [error, setError] = useState<boolean>(false);

  if (error) return <ErrorBox formMessage={FormMessageErrorState} />;

  return (
    <Dialog fullWidth open={visible} onClose={toggleListModal} maxWidth="xl">
      <DialogTitle>
        <DialogTitleContainer>
          <Typography
            variant="h6"
            component="div"
            justifyContent="space-around"
            width="100%"
          >
            {t("home.bankAccounts")}
          </Typography>
          <CloseButton onClick={toggleListModal} />
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        <BankAccountPage useOnWidget={true} setError={setError} />
      </DialogContent>
    </Dialog>
  );
};

export default BankAccountsListDialog;
