import { CreateTenantRequestInitState } from "./types";
import React from "react";
import { FormMessageInterface } from "../../../types/form";
import { FormikValues } from "formik/dist/types";
import { createTenantRequest } from "../../../api/tenantRequest";
import { mapBackendValidationErrors } from "../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";

export const createTenantRequestInitState: CreateTenantRequestInitState = {
  title: "",
  description: "",
  property_area_id: "",
  tenant_id: "",
  property_id: "",
  manager_id: "",
  file: [],
};

export const touchedInitState = {
  title: false,
  description: false,
  property_area_id: false,
  tenant_id: false,
  property_id: false,
  manager_id: false,
  file: false,
};

export type FormSubmitHandlerProps = {
  setFormMessage: React.Dispatch<React.SetStateAction<FormMessageInterface>>;
};

export const handleTenantRequestFormSubmit = ({
  setFormMessage,
}: FormSubmitHandlerProps) => {
  return async (
    values: CreateTenantRequestInitState,
    { setStatus, setSubmitting, setTouched, setValues }: FormikValues
  ): Promise<void> => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("property_area_id", values.property_area_id);
    formData.append("tenant_id", values.tenant_id);
    formData.append("property_id", values.tenant_id);
    values.manager_id && formData.append("manager_id", values.manager_id);
    for (let i = 0; i < values.file.length; i++) {
      // @ts-ignore
      formData.append(`file[${i}]`, values.file[i]);
    }
    const response = await createTenantRequest(formData);
    const data = await response.json();
    if (response.status === 201) {
      setStatus({
        success: true,
        errors: {},
        data: data.data,
      });
      setValues(createTenantRequestInitState);
      setTouched(touchedInitState);
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(data.errors),
      });
      setSubmitting(false);
    } else {
      setStatus({
        success: false,
        errors: SOMETHING_WENT_WRONG_ERROR,
      });
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
      setSubmitting(false);
    }
  };
};
