import React, { ReactElement } from "react";
import FinApiWebForm from "../../../../ui/finApi/FinApiWebForm";
import { Dialog } from "@mui/material";

const FinApiWebFormModal = ({
  token,
  isOpen,
  onClose,
  onFail,
  onLoadError,
  onWebFormMounted,
  onAbort,
  onComplete,
}: {
  token: string;
  isOpen: boolean;
  onClose: () => void;
  onFail: () => void;
  onLoadError: () => void;
  onWebFormMounted: () => void;
  onAbort: () => void;
  onComplete: () => void;
}): ReactElement => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      PaperProps={{ sx: { width: "auto", height: "auto", maxWidth: "none" } }}
    >
      <FinApiWebForm
        token={token}
        onFail={onFail}
        onLoadError={onLoadError}
        onWebFormMounted={onWebFormMounted}
        onAbort={onAbort}
        onComplete={onComplete}
      />
    </Dialog>
  );
};

export default FinApiWebFormModal;
