import {
  Grid,
  Checkbox,
  Box,
  Paper,
  FormControlLabel,
  Divider,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import React, {
  ReactElement,
  useState,
  useRef,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../ui/button/Button";
import { FormikProps } from "formik/dist/types";
import {
  CustomTooltip,
  VacationHolidayManagementFormData,
  WeekendFilterTypes,
} from "./types";
import {
  MultiCalendarWrapper,
  TextField,
  Error,
  CalendarTooltip,
} from "./styled";
import { initTooltipState } from "./utils";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Form } from "../styled";
import { SelectBox } from "../../activity/list/toolbar/styled";
import { Dropdown } from "../../../ui/autocomplete/styled";
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import DateObject from "react-date-object";
import { grey } from "@mui/material/colors";
import moment from "moment";
import { VacationHolidayManagementContext } from ".";
import RolesAutocomplete from "../autocomplete/Roles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/* istanbul ignore next */
const VacationHolidayManagementForm = ({
  handleSubmit,
  isSubmitting,
  handleChange,
  handleBlur,
  touched,
  errors,
  status,
  setFieldValue,
  values,
}: FormikProps<VacationHolidayManagementFormData>): ReactElement => {
  const [tooltip, setTooltip] = useState<CustomTooltip>(initTooltipState);
  const ref = useRef<HTMLDivElement>();
  const { t } = useTranslation();
  const {
    options,
    setSelectAll,
    setOptions,
    handleToggleSelectAll,
    selectAll,
    states,
    calendarValue,
    wasSubmittedWithError,
    resetValues,
    setCalendarValue,
    setStateCode,
    publicHolidays,
    selectedYear,
    setSelectedYear,
    users,
    isUsersLoading,
    selectedRoles,
  } = useContext(VacationHolidayManagementContext);

  useEffect(() => {
    !selectedRoles.length &&
      setFieldValue("vacation_holiday_user_selection", []);
  }, [selectedRoles.length]);

  useEffect(() => {
    if (!values.state_id) return;
    const state = states?.find((el) => el.id === values.state_id);
    if (!state) return;
    const { abbreviation } = state;
    setStateCode(abbreviation);
  }, [values.state_id, states, setStateCode]);

  const onMouseOver =
    (title: string) =>
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
      if (!(e.target instanceof Element)) return;
      const spanRect = e.target.getBoundingClientRect();
      const calendarRect = ref?.current?.getBoundingClientRect();
      if (!calendarRect) return;
      setTooltip({
        ...tooltip,
        left: spanRect.left - calendarRect?.left,
        top: spanRect.top - calendarRect?.top,
        visible: true,
        title,
      });
    };

  const filterWeekday = ({
    year,
    day,
    month: { index: monthIndex },
  }: DateObject): WeekendFilterTypes | undefined => {
    const currentDate = new Date(year, monthIndex, day);
    const searchItem = publicHolidays?.find((el) =>
      moment(el.date).isSame(currentDate)
    );
    if (searchItem)
      return {
        onMouseOver: onMouseOver(searchItem.name),
        onMouseLeave: () => {
          setTooltip({
            ...tooltip,
            visible: false,
            title: searchItem.name,
          });
        },
      };
    return {
      disabled: true,
      style: { color: grey[600] },
    };
  };

  const years = useMemo(() => {
    const result: { value: number; title: number }[] = [];
    for (let i = selectedYear; i < selectedYear + 10; i++) {
      result.push({ value: i, title: i });
    }
    return result;
  }, []);

  const getPaperComponent = useCallback(
    (paperProps) => {
      const { children, ...restPaperProps } = paperProps;
      return (
        <Dropdown>
          <Paper {...restPaperProps}>
            {Boolean(users?.length) && (
              <Box onMouseDown={(e) => e.preventDefault()} pl={6.5} py={0.5}>
                <FormControlLabel
                  onClick={(e) => {
                    e.preventDefault();
                    handleToggleSelectAll();
                    setFieldValue(
                      "vacation_holiday_user_selection",
                      users?.map((x) => String(x.id))
                    );
                  }}
                  style={{ marginRight: 8 }}
                  label="Select all"
                  control={
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      id="select-all-checkbox"
                      checked={selectAll}
                    />
                  }
                />
              </Box>
            )}
            <Divider />
            {children}
          </Paper>
        </Dropdown>
      );
    },
    [users, handleToggleSelectAll, setFieldValue, selectAll]
  );

  const noUsersInSelectedRole = Boolean(
    touched?.roles && selectedRoles.length && !users?.length && !isUsersLoading
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={6} columns={12}>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <RolesAutocomplete handleBlur={handleBlur} touched={touched} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            id="vacation_holiday_user_selection"
            options={users || []}
            multiple
            disabled={
              isUsersLoading || !selectedRoles.length || noUsersInSelectedRole
            }
            loading={isUsersLoading}
            data-testid="search-user"
            disableCloseOnSelect
            size="small"
            getOptionLabel={(option) =>
              `${option.first_name}${option.id} ${option.last_name}`
            }
            value={options}
            onChange={(_e, value, reason) => {
              if (reason === "clear" || reason === "removeOption")
                setSelectAll(false);
              if (reason === "selectOption" && value.length === users?.length)
                setSelectAll(true);
              setOptions(value);
              setFieldValue(
                "vacation_holiday_user_selection",
                value.map(({ id }) => id)
              );
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.first_name} {option.last_name}
              </li>
            )}
            renderTags={(list) => {
              const displayList = list.map((item) => item.first_name);
              return (
                <SelectBox>
                  <div>{`${t("selected")} ${displayList.length} ${t(
                    "users"
                  )}`}</div>
                </SelectBox>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("users")}
                placeholder={t("search")}
                value={values?.vacation_holiday_user_selection}
                onBlur={handleBlur}
                error={Boolean(
                  noUsersInSelectedRole ||
                    (touched.vacation_holiday_user_selection &&
                      !!selectedRoles.length &&
                      (status?.errors.vacation_holiday_user_selection ||
                        errors.vacation_holiday_user_selection))
                )}
                helperText={
                  noUsersInSelectedRole
                    ? t("employeeOverview.noUsersAttachedToCurrentRole")
                    : touched.vacation_holiday_user_selection &&
                      !!selectedRoles.length &&
                      (status?.errors.vacation_holiday_user_selection ||
                        errors.vacation_holiday_user_selection)
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isUsersLoading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            PaperComponent={getPaperComponent}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t("employeeOverview.federalState")}
            value={values.state_id ?? ""}
            name="state_id"
            onChange={handleChange}
            select
            fullWidth
            variant="outlined"
            size="small"
            error={Boolean(
              touched.state_id && (status?.errors.state_id || errors.state_id)
            )}
            helperText={
              touched.state_id && (status?.errors.state_id || errors.state_id)
            }
          >
            {states
              ?.filter(({ abbreviation }) => abbreviation)
              ?.map(({ id, abbreviation, title }) => (
                <MenuItem
                  key={id}
                  value={id}
                  sx={{ justifyContent: "space-between" }}
                >
                  <span>{title}</span>
                  <span className="state-abbreviation">{abbreviation}</span>
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t("employeeOverview.year")}
            value={selectedYear ?? ""}
            name="year"
            onChange={(e) => {
              setSelectedYear(Number(e.target.value));
            }}
            select
            fullWidth
            variant="outlined"
            size="small"
          >
            {years.map(({ value, title }) => (
              <MenuItem key={value} value={value}>
                <span>{title}</span>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ marginTop: "auto" }}>
          <MultiCalendarWrapper>
            <Calendar
              multiple={true}
              value={calendarValue}
              ref={ref}
              format="DD.MM.YYYY"
              onChange={setCalendarValue}
              mapDays={({ date }) => filterWeekday(date)}
              plugins={[
                <DatePanel key={calendarValue?.toString()} sort="date" />,
              ]}
            >
              <CalendarTooltip
                $translateX={tooltip.left}
                $translateY={tooltip.top - 37}
                $visible={tooltip.visible}
              >
                {tooltip.title}
              </CalendarTooltip>
            </Calendar>
          </MultiCalendarWrapper>
          {wasSubmittedWithError &&
            (calendarValue === null ||
              calendarValue.toString().length === 0) && (
              <Error>
                {t("employeeOverview.vacationHolidayDatesRequired")}
              </Error>
            )}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <Grid item>
          <Button
            onClick={resetValues}
            title={t("cancel")}
            testId={"cancel-vacation-holiday-button"}
            variant={"text"}
            size="medium"
            type="button"
          />
        </Grid>
        <Grid item>
          <Button
            title={t("create")}
            type="submit"
            sx={{ ml: "1rem" }}
            color="success"
            size="medium"
            testId={"create-vacation-holiday-button"}
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default VacationHolidayManagementForm;
