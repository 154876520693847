import React, { ReactElement, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import * as Yup from "yup";
import { CreatePropertyAreaModalProps } from "./types";
import {
  createPropertyAreaFormInitState,
  handleSubmitCreateArea,
} from "./utils";
import useValidation from "../../../../hooks/useValidation";
import { useParams } from "react-router-dom";
import Form from "./Form";
import { useTranslation } from "react-i18next";
import DialogTitle from "../../../../ui/dialog/DialogTitle";

export const CreateAreaModal = ({
  isCreateModalOpen,
  handleCreateModalClose,
  handleCreateModalCancel,
  areaTypes,
  setSendCredentialsSnackbar: setSnackbarMessage,
}: CreatePropertyAreaModalProps): ReactElement => {
  const [initialValues] = useState(createPropertyAreaFormInitState);
  const { id } = useParams();
  const { name, type_id, square_meters } = useValidation();
  const { t } = useTranslation();
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isCreateModalOpen}
      onClose={handleCreateModalCancel}
      aria-labelledby="max-width-dialog-title"
      data-testid="create-area-dialog"
    >
      <DialogTitle
        title="property.area.createArea"
        onClose={handleCreateModalCancel}
      />
      <DialogContent>
        <>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              name,
              type_id,
              square_meters,
            })}
            onSubmit={handleSubmitCreateArea(parseInt(id!), {
              handleCreateModalClose,
              setSnackbarMessage,
              t,
            })}
          >
            {(props): ReactElement => (
              <Form
                {...props}
                areaTypes={areaTypes}
                handleCreateModalClose={handleCreateModalCancel}
              />
            )}
          </Formik>
        </>
      </DialogContent>
    </Dialog>
  );
};
