import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Insurance as BEInsurance } from "../../../../../../types/be/insurance";
import useTable from "../../../../../../ui/table/useTable";
import find from "lodash/find";
import snakeCase from "lodash/snakeCase";
import {
  deleteInsurance,
  getInsurances,
} from "../../../../../../api/insurance";
import {
  deleteInsuranceTopic,
  requestReleaseInsuranceTopic,
} from "../../../../../../api/insuranceTopics";
import { deleteOffer } from "../../../../../../api/offer";
import Table from "../../../../../../ui/table/Table";
import DeleteConfirmationModal from "../../../../../../ui/table/DeleteConfirmationModal";
import { Box, Grid } from "@mui/material";

import { handleServerError } from "../../../../../../utils/http";
import { useTranslation } from "react-i18next";
import filter from "lodash/filter";
import {
  FILTER_TYPE,
  generalPropertyOption,
  getPropertyHeadByWidget,
  prepareQueryParams,
  replaceNullOrUndefinedByEmptyString,
} from "../../../../../../utils/common";
import CommentsPopup from "../../../../../../ui/commentsPopUp";
import {
  COMMENTABLE,
  FORWARDED_ROUTE,
  FORWADED_STATUS,
  HISTORY_STATUS,
  REQUEST_STATUS_IDS,
  STARTING_PAGE,
} from "../../../../../../constants";
import { showCommentsModalInitState } from "../../../../../../ui/commentsPopUp/utils";
import InsuranceListRow from "./insuranceListRow";
import InsuranceListToolbar from "./InsuranceListToolbar";
import {
  historyModalInitState,
  itemsListHeadCells,
  mapFESortField,
} from "../utils";
import { EditPropsType, InsuranceListProps, ShowPropsType } from "../types";
import useIsMounted from "../../../../../../hooks/useIsMounted";
import {
  getItemsWithActiveReleasePermissions,
  getReleaseRoute,
} from "../../../../utils";
import useAppSelector from "../../../../../../hooks/useAppSelector";
import { getProperties } from "../../../../../../api/property";
import StatusButtons from "../../../../components/statusButtons";
import { InvoiceFilterProps } from "../../../../invoices/list/types";
import { Status } from "../../../../../../types/be/status";
import {
  Input,
  Search,
  SearchIconWrapper,
} from "../../../../../../ui/table/styled";
import { Search as SearchIcon } from "react-feather";
import _ from "lodash";
import Dropdown from "../../../../../../ui/dropdown";
import { usePropertySelect } from "../../../../../../hooks/usePropertySelect";
import { IdPropType } from "../../../../../../types/models";
import HistoryPopup from "../../../../components/history/HistoryPopup";
import { HISTORY } from "../../../../components/history/types";
import HistoryList from "../../../../components/history/HistoryList";

const InsuranceList = ({
  useOnWidget = false,
  insurances: insurancesData,
  statuses: statusesProp,
  fetchInsuranceTopics,
  insuranceTopic,
  propertyId,
  setError,
  configuration,
  handleForwardDocument,
  handleReleaseDocument,
  isConfigurationLoading,
  isDocumentReleaseLoading,
  releasePermission,
}: InsuranceListProps): ReactElement => {
  const [statuses, setStatuses] = useState<Status[]>([]);
  const { status, id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShowCommentsModal, setIsShowCommentsModal] = useState(
    showCommentsModalInitState
  );
  const { optionalColumnIds, selectedOptionalColumnsIds } = useAppSelector(
    (state) => state.insurances
  );
  const [showHistoryModal, setShowHistoryModal] = useState(
    historyModalInitState
  );
  const [activeStatusId, setActiveStatusId] = useState<number | null>(null);
  const {
    propertyId: selectedPropertyId,
    property,
    isGeneral,
    onPropertySelect,
  } = usePropertySelect(id);

  const {
    order,
    orderBy,
    isLoading,
    error,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleDeleteLastPageData,
    setIsLoading,
    queryParams,
    setTotal,
    setData,
    data,
    total,
    handleTableSearch,
    setCurrentPage,
  } = useTable<BEInsurance>();

  useEffect(() => {
    statuses.length && setActiveStatusByStatus();
  }, [statuses]);

  const getStatuses = useCallback(async () => {
    setStatuses([FORWADED_STATUS, ...statusesProp]);
  }, [statuses]);

  useEffect(() => {
    configuration && getStatuses();
  }, [configuration]);

  const dataPaginated = useMemo(
    () =>
      insurancesData &&
      _.orderBy(insurancesData, [mapFESortField(orderBy)], [order]).slice(
        currentPage * rowsPerPage,
        (currentPage + 1) * rowsPerPage
      ),
    [insurancesData, currentPage, order, orderBy]
  );
  useEffect(() => {
    isMounted() && !insurancesData && activeStatusId && fetchInsurances();
  }, [queryParams, isGeneral, selectedPropertyId, activeStatusId]);
  /* istanbul ignore next */
  const fetchInsurances = useCallback(
    async (loading = true) => {
      setIsLoading(loading);

      const params: InvoiceFilterProps = {
        fr_status_id: activeStatusId,
      };

      if (
        isGeneral === FILTER_TYPE.GENERAL ||
        propertyId ||
        selectedPropertyId
      ) {
        params.property_id = propertyId || selectedPropertyId || "";
      }

      if (activeStatusId === -1) {
        params.is_was_forwarded = 1;
        params.fr_status_id = REQUEST_STATUS_IDS.NEW;
      } else {
        params.fr_status_id = activeStatusId;
      }

      const response = await getInsurances(
        prepareQueryParams(
          queryParams,
          replaceNullOrUndefinedByEmptyString(params),
          true
        )
      );

      if (!response.ok) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        const resJson = await response.json();
        setTotal(resJson.meta.total);
        setData(resJson.data);
        setIsLoading(false);
      }
    },
    [
      queryParams,
      setIsLoading,
      setTotal,
      setData,
      setError,
      isGeneral,
      selectedPropertyId,
      activeStatusId,
      propertyId,
    ]
  );

  const setActiveStatusByStatus = (): void => {
    const activeStatus = find(statuses, (o) => o.code === snakeCase(status));
    setActiveStatusId(activeStatus?.id || FORWADED_STATUS.id);
  };

  const handleRowRemove = useCallback(async (): Promise<void> => {
    const response = await deleteInsurance(rowToDelete);
    if (response.ok) {
      fetchInsuranceTopics
        ? await fetchInsuranceTopics()
        : await fetchInsurances();
      isMounted() && handleConfirmToRemoveModalClose();
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }
  }, [
    rowToDelete,
    deleteOffer,
    fetchInsuranceTopics,
    handleConfirmToRemoveModalClose,
  ]);

  const handleEditClick = useCallback(
    ({ id: insurance_id, propertyId }: EditPropsType): void =>
      navigate(getReleaseRoute("insurances.edit", propertyId, insurance_id)),
    []
  );

  const handleShowClick = useCallback(
    ({ id: insurance_id, propertyId }: ShowPropsType): void =>
      navigate(getReleaseRoute("insurances.show", propertyId, insurance_id)),
    []
  );

  const handleConfirmToRemoveTopicModalClose = (): void =>
    setIsModalOpen(false);

  const handleTopicRemove = async (): Promise<void> => {
    const response = await deleteInsuranceTopic(insuranceTopic!.id!);
    if (response.ok) {
      handleConfirmToRemoveTopicModalClose();
      fetchInsuranceTopics && handleDeleteLastPageData(fetchInsuranceTopics);
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }
  };

  const handleTopicReleaseRequest = async (): Promise<void> => {
    const response = await requestReleaseInsuranceTopic(insuranceTopic!.id!);
    if (response.ok) {
      fetchInsuranceTopics && (await fetchInsuranceTopics());
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }
  };

  const openConfirmToRemoveTopicModal = (): void => setIsModalOpen(true);

  /* istanbul ignore next */
  const tableHeaderTitles = useMemo(() => {
    let filteredItems = itemsListHeadCells;

    filteredItems = filter(
      filteredItems,
      (o) =>
        !optionalColumnIds.includes(o.id) ||
        selectedOptionalColumnsIds.includes(o.id)
    );

    filteredItems = getPropertyHeadByWidget(
      filteredItems,
      "property_name",
      !useOnWidget
    );

    return getItemsWithActiveReleasePermissions(filteredItems, configuration);
  }, [selectedOptionalColumnsIds, itemsListHeadCells, configuration, id]);

  /* istanbul ignore next */
  const handleStatusChange = (status: number): void => {
    setCurrentPage(STARTING_PAGE);
    setActiveStatusId(status);
  };

  /* istanbul ignore next */
  const handleHistoryCloseModal = (): void => {
    setShowHistoryModal(historyModalInitState);
  };
  /* istanbul ignore next */
  const handleHistoryModal = ({ id: historyId }: IdPropType): void => {
    setShowHistoryModal({ isVisible: true, historyId });
  };
  /* istanbul ignore next */
  const TableToolbar = (
    <>
      {insuranceTopic ? (
        <InsuranceListToolbar
          id={propertyId}
          topicId={insuranceTopic!.id!}
          from={insuranceTopic!.from!}
          to={insuranceTopic!.to!}
          type={insuranceTopic!.type!}
          fetchInsuranceTopics={fetchInsuranceTopics!}
          handleTopicReleaseRequest={handleTopicReleaseRequest}
          openConfirmToRemoveTopicModal={openConfirmToRemoveTopicModal}
          propertyName={insuranceTopic?.property?.object_name}
          isSentFinalReleaserEmails={
            insuranceTopic.is_sent_final_releaser_emails
          }
          configuration={configuration}
          insuranceTopic={insuranceTopic}
          handleReleaseDocument={handleReleaseDocument}
          handleForwardDocument={handleForwardDocument}
          releasePermission={releasePermission}
          isDocumentReleaseLoading={isDocumentReleaseLoading}
        />
      ) : (
        <Grid container mb={2}>
          <Grid container>
            <Grid item>
              <StatusButtons
                subPath="insurances"
                statuses={statuses}
                handleStatusChange={handleStatusChange}
                navigateOnChange={false}
                activeStatusId={activeStatusId}
                useSpecialLabels={true}
                defaultStatus={FORWARDED_ROUTE}
              />
            </Grid>
          </Grid>
          <Grid
            container
            mt={5}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Dropdown
                  id="property-autocomplete"
                  name="property_id"
                  placeholder={t("documentRelease.invoice.searchProperty")}
                  getOptions={getProperties}
                  onSelectOption={onPropertySelect}
                  optionLabel="object_name"
                  size="small"
                  defaultValue={property}
                  defaultOption={[generalPropertyOption]}
                />
              </Grid>
              <Grid item xs />
              <Grid item>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <Input
                    placeholder={t("search")}
                    onChange={handleTableSearch}
                  />
                </Search>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );

  const handleOpenComments = (row: BEInsurance): void => {
    setIsShowCommentsModal({ isVisible: true, rowId: row?.id });
  };

  const handleCloseCommentsModal = (): void => {
    setIsShowCommentsModal(showCommentsModalInitState);
    /* istanbul ignore next */
    fetchInsuranceTopics ? fetchInsuranceTopics(false) : fetchInsurances(false);
  };

  const renderRow = (row: BEInsurance): ReactElement => (
    <>
      <InsuranceListRow
        useOnWidget={useOnWidget}
        row={row}
        topicStatusId={1}
        handleCellClick={handleCellClick}
        handleEditClick={handleEditClick}
        openConfirmToRemoveModal={openConfirmToRemoveModal}
        handleOpenComments={handleOpenComments}
        handleShowClick={handleShowClick}
        fetchInsuranceTopics={fetchInsuranceTopics || fetchInsurances}
        handleForwardDocument={handleForwardDocument}
        handleReleaseDocument={handleReleaseDocument}
        releasePermission={releasePermission}
        isDocumentReleaseLoading={isDocumentReleaseLoading}
        configuration={configuration}
        handleHistoryModal={handleHistoryModal}
      />
    </>
  );

  const isMounted = useIsMounted();

  return (
    <Box sx={{ mb: 1 }}>
      {activeStatusId === HISTORY_STATUS.id ? (
        /* istanbul ignore next */ <HistoryList
          documentRelease={HISTORY.OFFER}
          activeStatusId={activeStatusId}
          handleStatusChange={handleStatusChange}
          statuses={statuses}
          useOnWidget={useOnWidget}
          noDataMessage="table.noInsuranceHistoryIsAvailable"
        />
      ) : (
        <Table
          data={insurancesData ? dataPaginated : data}
          total={insurancesData ? insurancesData.length : total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onRowRemove={handleRowRemove}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading || isConfigurationLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={tableHeaderTitles}
          renderRow={renderRow}
          tableToolbar={TableToolbar}
          labelRowsPerPage={t("insurance.insurancesPerTable")}
          tableDataMaxHeight={insuranceTopic ? "75vh" : "55vh"}
          refId={`hzScroll${insuranceTopic?.id}`}
        />
      )}
      <DeleteConfirmationModal
        isConfirmToRemoveModalOpen={isModalOpen}
        handleConfirmToRemoveModalClose={handleConfirmToRemoveTopicModalClose}
        onRowRemove={handleTopicRemove}
      />
      {isShowCommentsModal.isVisible && (
        <CommentsPopup
          handleCloseDialog={handleCloseCommentsModal}
          id={String(isShowCommentsModal?.rowId)}
          relation={COMMENTABLE.INSURANCE}
        />
      )}
      {
        /* istanbul ignore next */ showHistoryModal.isVisible && (
          <HistoryPopup
            handleCloseDialog={handleHistoryCloseModal}
            historyId={String(showHistoryModal?.historyId)}
            documentRelease={HISTORY.INSURANCE}
          />
        )
      }
    </Box>
  );
};

export default InsuranceList;
