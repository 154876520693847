import { AreaField } from "../../../../types/fe/area";
import { TableCell } from "../../../../types/table";
import {
  HandleAreaIsRentedOptionChangeProps,
  HandleAreaTypeIdChangeProps,
} from "./components/toolbar/types";
import {
  AREA_TYPE_ID_PARAM,
  IS_RENTED_PARAM,
  Measurement_Units,
  PROPERTY_ID_PARAM,
} from "../../../../constants";
/* istanbul ignore next */
export const mainItemsListHeadCells: TableCell[] = [
  {
    id: "is_rented",
    numeric: false,
    disablePadding: true,
    sortable: false,
    align: "center",
    label: "",
  },
  {
    id: "future_tenant",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.area.nextTenantAvailable",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.area.typeName",
  },
  {
    id: "contact_full_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.contactName",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.name",
  },
  {
    id: "square_meters",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.squareMeters",
  },
  {
    id: "asset_managers",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.assetManager",
  },
  {
    id: "usage",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.area.usage",
  },
  {
    id: "comment",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.history.comment",
    align: "left",
  },
];
/* istanbul ignore next */
export const mainItemsListHeadCellsTenantUser: TableCell[] = [
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.area.typeName",
  },
  {
    id: "contact_full_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.contactName",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.area.name",
  },
  {
    id: "squareMeters",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.squareMeters",
  },
  {
    id: "net_rent_per_month",
    numeric: true,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.netRentPerMonth",
  },
  {
    id: "net_rent_per_m2",
    numeric: true,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.netRentPerM2",
  },
  {
    id: "rent_start",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.rentStart",
  },
  {
    id: "rent_end",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.rentEnd",
  },
  {
    id: "tax",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.tax",
  },
  {
    id: "others_incomings_eur",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.othersIncomingsEur",
  },
  {
    id: "comment",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.history.comment",
    align: "left",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];
/* istanbul ignore next */
export const additionalItemsListHeadCells: TableCell[] = [
  {
    id: "net_rent_per_month",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.netRentPerMonth",
  },
  {
    id: "rent_start",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.rentStart",
  },
  {
    id: "rent_end",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.rentEnd",
  },
  {
    id: "is_cancelled",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.isCancelled",
  },
  {
    id: "cancellation_until",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.cancellationUntil",
  },
  {
    id: "closing_tenant_contract",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.closingTenantContract",
  },
  {
    id: "special_cancellation_right",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.specialCancellationRight",
  },
  {
    id: "deposit",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.deposit",
  },
  {
    id: "reminder",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.reminder",
  },
  {
    id: "new",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.new",
  },
  {
    id: "tenants",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.tenantName",
  },
  {
    id: "options",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.options",
  },
  {
    id: "remaining_term",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.remainingTerm",
  },
  {
    id: "remaining_term_in_eur",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.remainingTermInEur",
  },
  {
    id: "net_rent_per_m2",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.netRentPerM2",
  },
  {
    id: "total_net_rent_per_year",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.totalNetRentPerYear",
  },
  {
    id: "total_net_rent_per_month",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.totalNetRentPerMonth",
  },
  {
    id: "tax_in_eur",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.tax",
  },
  {
    id: "total_gross_net_rent_per_month",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.totalGrossNetRentPerMonth",
  },
  {
    id: "total_gross_net_rent_per_year",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.totalGrossNetRentPerYear",
  },
  {
    id: "others_incomings_eur",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.othersIncomingsEur",
  },
  {
    id: "empty_space",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "property.area.emptySpace",
  },
  {
    id: "empty_space_at_purchase",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.area.emptySpaceAtPurchase",
  },
];
/* istanbul ignore next */
export const requiredItemsListHeadCells: TableCell[] = [
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];
/* istanbul ignore next */
export const mainFields: AreaField[] = [
  {
    name: "isFutureTenant",
    type: "checkbox",
    visible: true,
  },
  {
    name: "type_name",
    type: "text",
    visible: true,
  },
  {
    name: "contactFullName",
    type: "text",
    visible: true,
  },
  {
    name: "name",
    type: "text",
    visible: true,
  },
  {
    name: "square_meters",
    type: "number",
    visible: true,
    unit: Measurement_Units.SQUARE_METER,
  },
  {
    name: "asset_managers",
    type: "multi",
    visible: true,
  },
  {
    name: "usage_name",
    type: "text",
    visible: true,
  },
];

/* istanbul ignore next */
export const mainFieldsTenantUser: AreaField[] = [
  {
    name: "type_name",
    type: "text",
    visible: true,
  },
  {
    name: "contactFullName",
    type: "text",
    visible: true,
  },
  {
    name: "name",
    type: "text",
    visible: true,
  },
  {
    name: "square_meters",
    type: "number",
    visible: true,
  },
  {
    name: "net_rent_per_month",
    type: "number",
    visible: true,
  },
  {
    name: "net_rent_per_m2",
    type: "number",
    visible: true,
  },
  {
    name: "rent_start",
    type: "date",
    visible: true,
  },
  {
    name: "rent_end",
    type: "date",
    visible: true,
  },
  {
    name: "tax_in_eur",
    type: "number",
    visible: true,
  },
  {
    name: "others_incomings_eur",
    type: "number",
    visible: true,
  },
];

/* istanbul ignore next */
export const handleAreaTypeIdChange =
  ({
    id,
    dispatch,
    setQueryParams,
    setAreaTypeId,
    submitForm,
  }: HandleAreaTypeIdChangeProps) =>
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const {
      target: { value: typeId },
    } = event;
    typeof typeId !== "string" &&
      setQueryParams((queryParams: string) =>
        prepareQueryParams(queryParams, typeId, id!)
      );
    typeof typeId !== "string" && dispatch(setAreaTypeId(typeId));
    submitForm();
  };

/* istanbul ignore next */
export const handleAreaIsRentedOptionChange =
  ({
    dispatch,
    setQueryParams,
    setAreaIsRentedOption,
    submitForm,
  }: HandleAreaIsRentedOptionChangeProps) =>
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const {
      target: { value },
    } = event;
    const isRented = +value;
    setQueryParams((queryParams: string) => {
      const params = new URLSearchParams(queryParams);
      params.delete(IS_RENTED_PARAM);
      if (isRented !== -1) {
        params.append(IS_RENTED_PARAM, isRented.toString());
      }
      return `?${decodeURIComponent(params.toString())}`;
    });
    dispatch(setAreaIsRentedOption(isRented));
    submitForm();
  };

/* istanbul ignore next */
export const prepareQueryParams = (
  queryParams: string,
  areaTypeId: number,
  id: string
): string => {
  const params = new URLSearchParams(queryParams);
  !queryParams.includes(PROPERTY_ID_PARAM) &&
    params.append(PROPERTY_ID_PARAM, id);
  params.delete(AREA_TYPE_ID_PARAM);
  if (areaTypeId !== 0) {
    params.append(AREA_TYPE_ID_PARAM, areaTypeId.toString());
  }
  return `?${decodeURIComponent(params.toString())}`;
};
/* istanbul ignore next */
export const prepareIsRentedParam = (
  queryParams: string,
  isRentedOption: number
): string => {
  const param = new URLSearchParams(queryParams);
  param.delete(IS_RENTED_PARAM);
  if (isRentedOption !== -1) {
    param.append(IS_RENTED_PARAM, isRentedOption.toString());
  }
  return `?${decodeURIComponent(param.toString())}`;
};
