import React, { ReactElement, useCallback, useEffect } from "react";
import { Grid, TableCell } from "@mui/material";
import { EVENT_DATE_AND_TIME_FORMAT, itemsListHeadCells } from "./utils";
import { InventoryHistory } from "../../../types/be/companyOwnership";
import Table from "../../../ui/table/Table";
import useTable from "../../../ui/table/useTable";
import { prepareQueryParams } from "../../../utils/common";
import { useParams } from "react-router-dom";
import { getInventoryHistories } from "../../../api/companyOwnership";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { handleServerError } from "../../../utils/http";
import { formatTimestamp } from "../../../utils/date";
import { Input, Search, SearchIconWrapper } from "../../../ui/table/styled";
import { useTranslation } from "react-i18next";
import PageWrapper from "../../../ui/pageWrapper/PageWrapper";
import { route } from "../../../utils/url";
import { Search as SearchIcon } from "react-feather";
import { EMPTY_DATA } from "../../../constants";

const InventoryItemHistory = (): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    setTotal,
    handleTableSearch,
  } = useTable<InventoryHistory>();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const query = {
      inventory_id: id,
    };
    const params = prepareQueryParams(queryParams, query);
    const response = await getInventoryHistories(params);
    const json = await response.json();

    if (response.status === HTTP_STATUS_CODES.OK) {
      setData(json?.data);
      setTotal(json?.meta?.total);
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }

    setIsLoading(false);
  }, [queryParams]);

  const renderRow = (row: InventoryHistory): ReactElement => (
    <>
      <TableCell align="left">
        {row.user ? `${row.user.first_name} ${row.user.last_name}` : EMPTY_DATA}
      </TableCell>
      <TableCell align="left">{row.event.name}</TableCell>
      <TableCell align="left">{`${row.creator.first_name} ${row.creator.last_name}`}</TableCell>
      <TableCell align="left">
        {formatTimestamp(row.created_at, EVENT_DATE_AND_TIME_FORMAT)}
      </TableCell>
    </>
  );

  useEffect(() => {
    queryParams && fetchData();
  }, [queryParams]);

  const TableToolbar = (
    <Grid container sx={{ pt: 5 }} justifyContent="flex-end">
      <Grid item>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Input placeholder={t("search")} onChange={handleTableSearch} />
        </Search>
      </Grid>
    </Grid>
  );

  const breadcrumbs = [
    {
      to: route("users.company-ownership"),
      name: t("companyOwnership.title"),
    },
  ];

  return (
    <PageWrapper
      title={t("companyOwnership.inventoryHistory")}
      breadcrumbs={breadcrumbs}
    >
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        noDataIsAvailablePlaceholder={"table.noInventoryHistoryIsAvailable"}
      />
    </PageWrapper>
  );
};

export default InventoryItemHistory;
