import { useCallback, useEffect } from "react";
import { getReminderStatuses } from "../api/reminder";
import { Status } from "../types/be/status";
import { ReminderStatusCodes } from "../types/be/reminder";
import { useFetch } from "./useFetch";

interface UseReminderReturn {
  statusList: Status[] | null;
  isLoading: boolean;
  isError: boolean;
  findReminderStatus: (
    query: ReminderStatusCodes | number | string
  ) => Status | void;
}
/* istanbul ignore next */
const useReminderStatus = (): UseReminderReturn => {
  const { data, run, isLoading, isError } = useFetch<Status[]>();

  useEffect(() => {
    run(getReminderStatuses());
  }, []);

  const findReminderStatus = useCallback(
    (query: ReminderStatusCodes | number | string) =>
      data?.find((item) => [item.code, item.id].includes(query)),
    [data]
  );

  return {
    statusList: data,
    isLoading,
    isError,
    findReminderStatus,
  };
};

export default useReminderStatus;
