import React, {
  ReactElement,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import Button from "../../../ui/button/Button";
import FileInputField from "../../../ui/formsFields/fileInput";
import { useTranslation } from "react-i18next";
import { Grid, IconButton, MenuItem } from "@mui/material";
import { FormikValues } from "formik/dist/types";
import { Form, useFormikContext } from "formik";
import Snackbar from "../../../ui/Snackbar";
import OfferAutoCompleteField from "../offers/autocomplete";
import TextField from "../../../ui/formsFields/textField";
import SwitchWrapper from "../../../ui/formsFields/switch";
import { FormSection } from "../../profile/tabs/generalInformation/styled";
import { InvoiceFormProps } from "./types";
import SelectOrCreateContactsAutocompleteField from "../../contacts/autocomplete";
import { CURRENCIES, Measurement_Units } from "../../../constants";
import Text from "../../../ui/formsFields/text";
import { calculateDateDifference, calculatePeriodTotal } from "./utils";
import { getProperties } from "../../../api/property";
import Dropdown from "../../../ui/dropdown";
import {
  generalPropertyOption,
  prepareQueryParams,
} from "../../../utils/common";
import DatePicker from "../../../ui/datePicker";
import { getAreas } from "../../../api/area";
import { route } from "../../../utils/url";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { StyledGrid } from "./styled";

function InvoiceForm2({
  errors,
  status,
  handleBlur,
  handleChange,
  isSubmitting,
  handleSubmit,
  touched,
  values,
  propertyId,
  frStatusId,
  edit,
  invoicePeriod,
  handleOpenDialog,
  fileName,
  setFileName,
  setFormInitState,
  handleOsc,
}: FormikValues & InvoiceFormProps): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [isAreaSearch, setIsAreaSearch] = useState(false);

  const handleClose = (): void => setIsSnackbarVisible(false);
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);
  const buttonTitleOptions: Array<"create" | "update"> = ["create", "update"];

  const totalValue = useMemo(() => {
    const selectedPeriod = invoicePeriod?.find((item: { id: number }) => {
      if (item?.id === values?.repetition_period_id) return item;
    });

    return Number(
      selectedPeriod &&
        calculatePeriodTotal({
          date: values.repeat_from,
          until: values.repeat_to,
          monthly: selectedPeriod.period,
          amount: values.amount,
        })
    ).toFixed(2);
  }, [values]);

  const checkDateDifference = useMemo(() => {
    return Number(
      calculateDateDifference({
        from: values.repeat_from,
        to: values.repeat_to,
      })
    );
  }, [values]);

  const checkDisabledInvoicePeriod = useCallback(
    (period) => {
      const periodValue = invoicePeriod?.find((item: { name: string }) => {
        if (item?.name === period) return item;
      });
      return periodValue && checkDateDifference < periodValue?.period;
    },
    [values]
  );

  const defaultPropertyOptions = [generalPropertyOption];

  const getAreaOptions = useCallback(
    (defaultParams: string): Promise<Response> => {
      const params = prepareQueryParams(defaultParams, {
        property_id:
          !isAreaSearch && Number(values?.property_id)
            ? values?.property_id
            : undefined,
      });
      return getAreas(params);
    },
    [values?.property_id, propertyId, isAreaSearch]
  );

  useEffect(() => {
    setFieldValue("total", Number(totalValue));
  }, [totalValue]);

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <FormSection>
        <Grid container spacing={6}>
          {!propertyId ? (
            <Grid item sm={12} lg={6}>
              <Dropdown
                id="property-autocomplete"
                name="property_id"
                value={values.property_id}
                placeholder={t("documentRelease.invoice.searchProperty")}
                getOptions={getProperties}
                onSelectOption={(id?: string) => {
                  setIsAreaSearch(true);
                  setFieldValue("property_id", id);
                }}
                optionLabel="object_name"
                optionValue="id"
                size="medium"
                defaultValue={values?.property || generalPropertyOption}
                defaultOption={defaultPropertyOptions}
                error={Boolean(
                  status?.errors?.property_id || errors.property_id
                )}
                helperText={errors?.property_id || status?.errors?.property_id}
              />
            </Grid>
          ) : null}
          <Grid item sm={12} lg={6}>
            <Dropdown
              id="property-area-autocomplete"
              name="area_id"
              value={values.area_id || ""}
              label={t("counter.searchArea")}
              placeholder={t("counter.searchArea")}
              getOptions={getAreaOptions}
              onSelectOption={(id?: string) => setFieldValue("area_id", id)}
              size="medium"
              defaultValue={values?.area}
              error={Boolean(status?.errors?.area_id || errors.area_id)}
              helperText={errors?.area_id || status?.errors?.area_id}
              disabled={!Number(values?.property_id) && !propertyId}
              isAreaSearch={isAreaSearch}
              propertyId={values?.property_id}
            />
          </Grid>
          <Grid item sm={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={11}>
                <FileInputField
                  required
                  name="file_id"
                  label={t("documentRelease.invoice.uploadOneInvoice")}
                  error={Boolean(
                    touched.file_id &&
                      (status?.errors.file_id || errors.file_id)
                  )}
                  helperText={
                    touched.file_id &&
                    (status?.errors.file_id || errors.file_id)
                  }
                  foldername="Verträge"
                  propertyid={propertyId}
                  inputProps={{ "data-testid": "contract-file" }}
                  accept="application/pdf"
                  errorText={t("contracts.error.pdfOnly")}
                  outerFileName={fileName}
                  setOuterFileName={setFileName}
                  setFormInitState={setFormInitState}
                  handleOsc={handleOsc}
                />
              </Grid>
              <StyledGrid item xs={1} pt={3}>
                <IconButton
                  color="primary"
                  aria-label="uploadPdf"
                  component="span"
                  size="small"
                  onClick={handleOpenDialog}
                >
                  <InsertDriveFileIcon />
                </IconButton>
              </StyledGrid>
            </Grid>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Text
              label={t("documentRelease.invoice.number")}
              value={values.number}
              name="number"
              required
              size="medium"
              status={status}
              inputProps={{ "data-testid": "number" }}
            />
          </Grid>
          <Grid item sm={12} lg={6}>
            <DatePicker
              name="date"
              required
              disableFuture
              label={t("documentRelease.invoice.date")}
              status={status}
              dataTestId={"invoicedate-input"}
            />
          </Grid>
          <Grid item xl={6} sm={12} sx={{ position: "relative" }}>
            <SelectOrCreateContactsAutocompleteField
              {...{
                errors,
                values,
                touched,
                status,
                handleBlur,
                handleChange,
                setFieldValue,
              }}
              required={true}
              label={t("documentRelease.invoice.provider")}
              placeholder={t("documentRelease.invoice.provider")}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <DatePicker
              name="due_date"
              disablePast={!values.id}
              label={t("optionalColumnsNames.due_date")}
              status={status}
              dataTestId={"dueDate-input"}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
              id="currency"
              name="currency"
              select
              required
              label={t("documentRelease.invoice.currency")}
              value={values.currency_code}
              onChange={handleChange("currency_code")}
              error={Boolean(
                touched.currency_code &&
                  (status?.errors.currency_code || errors.currency_code)
              )}
              helperText={
                touched.currency_code &&
                (status?.errors.currency_code || errors.currency_code)
              }
              data-testid="property-type"
              fullWidth
            >
              {CURRENCIES?.map((currency: string) => (
                <MenuItem key={`${currency}`} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
              required
              name="amount"
              label={t("documentRelease.invoice.invoiceAmount")}
              value={values.amount}
              error={Boolean(
                touched.amount && (status?.errors.amount || errors.amount)
              )}
              allowNegative
              fullWidth
              inputProps={{
                "data-testid": "amount",
                filter: "formatNum",
                unit: Measurement_Units.EURO,
              }}
              status={status}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
              name="iban"
              label={t("documentRelease.invoice.iban")}
              value={values.iban}
              status={status}
              fullWidth
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
              name="bic"
              allowNegative
              label={t("documentRelease.invoice.bic")}
              value={values.bic}
              status={status}
              fullWidth
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <OfferAutoCompleteField
              {...{
                errors,
                values,
                touched,
                status,
                handleBlur,
                handleChange,
                setFieldValue,
                propertyId: values?.property_id,
                frStatusId,
                defaultValue: values?.offer ? values?.offer : null,
              }}
            />
          </Grid>
          <Grid item xl={6} sm={12}>
            <TextField
              name="comment"
              label={t("documentRelease.invoice.show.comment")}
              minRows={4}
              multiline
              inputProps={{ "data-testid": "comment" }}
              status={status}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection>
        <Grid container spacing={6}>
          <Grid item xl={4} md={6} sm={12}>
            <SwitchWrapper
              name="is_permanent"
              label={t("documentRelease.invoice.isPermanentInvoice")}
              checked={!!values.is_permanent}
              data-testid="is-permanent-invoice"
            />
          </Grid>
          <Grid item xl={4} md={6} sm={12}>
            <SwitchWrapper
              name="is_debited_automatically"
              label={t("documentRelease.invoice.isDebitedAutomatically")}
              checked={!!values.is_debited_automatically}
              data-testid="is-debited-automatically"
            />
          </Grid>
          <Grid item xl={4} md={6} sm={12}>
            <SwitchWrapper
              name="is_apportionable"
              label={t("documentRelease.invoice.isApportionable")}
              checked={!!values.is_apportionable}
              data-testid="is-apportionable"
            />
          </Grid>
          <Grid item xl={4} md={6} sm={12}>
            <SwitchWrapper
              name="is_confidential"
              label={t("documentRelease.invoice.isConfidentialInvoice")}
              checked={!!values.is_confidential}
              data-testid="is-confidential-invoice"
            />
          </Grid>
          <Grid item xl={4} md={6} sm={12}>
            <SwitchWrapper
              name="is_active"
              label={t("documentRelease.invoice.isActiveInvoice")}
              checked={!!values.is_active}
              data-testid="is-active-invoice"
            />
          </Grid>
          {values?.is_permanent && (
            <Grid item xl={4} md={6} sm={12}>
              <SwitchWrapper
                name="is_automatic_release_repeating_invoice"
                label={t(
                  "documentRelease.invoice.isAutomaticReleaseRepeatingInvoice"
                )}
                checked={!!values.is_automatic_release_repeating_invoice}
                data-testid="is_automatic_release_repeating_invoice"
              />
            </Grid>
          )}
        </Grid>
      </FormSection>
      <FormSection>
        <Grid container spacing={6}>
          {values.is_permanent && (
            <>
              <Grid item lg={4} sm={12}>
                <DatePicker
                  name="repeat_from"
                  label={t("documentRelease.invoice.repeatFrom")}
                  status={status}
                  minDate={new Date()}
                  dataTestId={"repeat-from-input"}
                />
              </Grid>
              <Grid item lg={4} sm={12}>
                <DatePicker
                  name="repeat_to"
                  label={t("documentRelease.invoice.repeatTo")}
                  status={status}
                  minDate={new Date()}
                  dataTestId={"repeat-to-input"}
                />
              </Grid>
              <Grid item lg={4} sm={12}>
                <TextField
                  label={t("documentRelease.invoice.repeatDay")}
                  value={values.repeat_day}
                  type="number"
                  name="repeat_day"
                  required
                  fullWidth
                  size="medium"
                  inputProps={{ "data-testid": "repeat_day" }}
                  status={status}
                />
              </Grid>
              <Grid item lg={4} sm={12}>
                <Text
                  name="repetition_period_id"
                  select
                  required
                  label={t("documentRelease.invoice.periodType")}
                  data-testid="period-type-input"
                  status={status}
                >
                  {invoicePeriod?.map(
                    (period: { id: number; name: string }) => (
                      <MenuItem
                        key={`${period.name}`}
                        value={period.id}
                        disabled={checkDisabledInvoicePeriod(period.name)}
                      >
                        {period.name}
                      </MenuItem>
                    )
                  )}
                </Text>
              </Grid>
              <Grid item lg={4} sm={12}>
                <TextField
                  name="total"
                  label={t("documentRelease.invoice.total")}
                  disabled
                  value={values.total}
                  inputProps={{
                    "data-testid": "total-input",
                    filter: "formatNum",
                    unit: Measurement_Units.EURO,
                  }}
                  status={status}
                />
              </Grid>
            </>
          )}
          {values.is_apportionable && (
            <Grid item lg={6} sm={12}>
              <TextField
                type="number"
                required={values.is_apportionable}
                name="apportionable_percent"
                label={t("documentRelease.invoice.apportionablePercent")}
                value={values.apportionable_percent}
                inputProps={{
                  "data-testid": "apportionable_percent",
                  max: 100,
                  min: 0,
                }}
                status={status}
              />
            </Grid>
          )}
        </Grid>
      </FormSection>
      <Grid item sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          type="button"
          color="primary"
          variant="text"
          title={t("cancel")}
          disabled={isSubmitting}
          size="large"
          onClick={
            /* istanbul ignore next */ () =>
              doesAnyHistoryEntryExist
                ? navigate(-1)
                : propertyId
                ? navigate(route("properties.invoices.new", propertyId))
                : navigate(route("invoices.new"))
          }
        />
        <Button
          testId="create-invoice"
          title={t(buttonTitleOptions[+Boolean(edit)])}
          type="submit"
          color="success"
          disabled={isSubmitting}
          isLoading={isSubmitting}
          size="large"
        />
      </Grid>
      <Snackbar
        message={t("documentRelease.invoice.invoiceSaveSuccessfully")}
        color="success"
        open={isSnackbarVisible}
        handleClose={handleClose}
        data-testid="snackbar-invoice-success"
      />
    </Form>
  );
}

export default InvoiceForm2;
