import React, { ReactElement, useCallback, useState } from "react";
import {
  Box,
  Grid,
  Tooltip,
  Typography,
  TableCell as MuiTableCell,
} from "@mui/material";
import { DeleteIcon, SendIcon, ShowIcon } from "../../../../ui/table/styled";
import { useTranslation } from "react-i18next";
import { DATE_TIME_FORMAT } from "../utils";
import { formatTimestamp } from "../../../../utils/date";
import { DoneAll, RemoveDone } from "@mui/icons-material";
import {
  EMPTY_DATA,
  PermissionsDelete,
  PermissionsUpdate,
} from "../../../../constants";
import { TableCell } from "../styled";
import Button from "../../../../ui/button/Button";
import Loader from "../../../Loader";
import CloseButton from "../../../../ui/dialog/CloseButton";
import { GeneralDocumentRowProps } from "../../types";
import useAccessControl from "../../../../hooks/useAccessControl";
import RedirectProperty from "../../../../ui/redirectProperty";
import {
  GeneralDocument,
  GeneralDocumentStatusCodes,
} from "../../../../types/be/generalDocument";
import GeneralDocumentContactAutocomplete from "../contactAutocomplete";
import ExpandableComment from "../../../../ui/expandableComment/ExpandableComment";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "../../../../ui/dialog/CloseButton/styled";
import { head } from "lodash";
import FileTableCell from "../../../properties/components/fileTableCell";
import { useParams } from "react-router-dom";

/* istanbul ignore next */
const GeneralDocumentRow = ({
  row,
  removingProperty,
  handleRemoveProperty,
  setGeneralDocumentId,
  setSnackbarData,
  prepareBeforeAssignedUsersDialog,
  handleOpenComments,
  handleChangeGeneralDocumentStatus,
  setSelectedGeneralDocumentId,
  handleShowClick,
  openConfirmToRemoveModal,
  preloadedContactsData,
}: GeneralDocumentRowProps): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { can } = useAccessControl();
  const [showTooltip, setShowTooltip] = useState<Record<string, boolean>>({});

  const getTooltipTitle = (row: GeneralDocument): ReactElement[] | null => {
    const assetManagers = (row.property?.asset_managers || []).map(
      ({ id, first_name, last_name }, index) => (
        <Typography key={id + " " + index} sx={{ fontSize: 11 }}>
          {first_name + " " + last_name} (AM)
        </Typography>
      )
    );
    const assignedUsers = row.assigned_users.map(
      ({ id, first_name, last_name }, index) => (
        <Typography key={id + " " + index} sx={{ fontSize: 11 }}>
          {first_name + " " + last_name}
        </Typography>
      )
    );

    return assetManagers.length + assignedUsers.length === 0
      ? null
      : [...assetManagers, ...assignedUsers];
  };

  const getAssignedUsersTitle = useCallback(
    ({ property, assigned_users }: GeneralDocument): ReactElement => {
      const totalUsers = [
        ...(property?.asset_managers || []),
        ...assigned_users,
      ];

      if (totalUsers.length === 1) {
        const user = head(totalUsers);
        return <>{`${user?.first_name} ${user?.last_name}`}</>;
      } else if (totalUsers.length > 1) {
        const user = head(totalUsers);
        return (
          <>
            {user?.first_name} {user?.last_name}
            <Box sx={{ fontSize: 12 }}>
              {` ${t("tenant.selectedMoreThenOneTenantPartOne")} ${
                totalUsers.length - 1
              } ${t("tenant.selectedMoreThenOneTenantPartTwo")}`}
            </Box>
          </>
        );
      } else {
        return <>EMPTY_DATA</>;
      }
    },
    []
  );

  return (
    <>
      {!id && (
        <TableCell align="left">
          {row?.property ? (
            <Grid display="flex" gap={2} alignItems="center">
              <RedirectProperty property={row?.property} />
              {removingProperty === String(row?.id) ? (
                <Loader size={15} />
              ) : (
                <Tooltip
                  title={
                    row.status.code === GeneralDocumentStatusCodes.COMPLETED
                      ? t("generalDocuments.pleaseMoveToProgress")
                      : null
                  }
                >
                  <span>
                    {can(PermissionsUpdate.GENERAL_DOCUMENT) && (
                      <CloseButton
                        disabled={
                          row.status.code ===
                          GeneralDocumentStatusCodes.COMPLETED
                        }
                        onClick={() => handleRemoveProperty(String(row?.id))}
                      />
                    )}
                  </span>
                </Tooltip>
              )}
            </Grid>
          ) : can(PermissionsUpdate.GENERAL_DOCUMENT) ? (
            <Tooltip
              title={
                row.status.code === GeneralDocumentStatusCodes.COMPLETED
                  ? t("generalDocuments.pleaseMoveToProgress")
                  : null
              }
            >
              <span>
                <Button
                  color="primary"
                  size="small"
                  variant="outlined"
                  disabled={
                    row.status.code === GeneralDocumentStatusCodes.COMPLETED
                  }
                  onClick={() => setGeneralDocumentId(String(row?.id))}
                  title={t("bankAccount.assignProperty")}
                />
              </span>
            </Tooltip>
          ) : (
            EMPTY_DATA
          )}
        </TableCell>
      )}
      <TableCell
        align="left"
        sx={{
          minWidth: 300,
        }}
      >
        <GeneralDocumentContactAutocomplete
          generalDocumentData={row}
          setSnackbarData={setSnackbarData}
          defaultPreloadedData={preloadedContactsData ?? []}
        />
      </TableCell>
      <MuiTableCell align="left" sx={{ maxWidth: "250px", minWidth: "150px" }}>
        {row?.assigned_users.length || row.property?.asset_managers.length ? (
          <Grid display="flex" gap={2} alignItems="center">
            <Tooltip
              title={getTooltipTitle(row)}
              placement="bottom"
              aria-label="add"
              open={Boolean(showTooltip[row.id])}
              onMouseEnter={() =>
                setShowTooltip((prev) => ({ ...prev, [row.id]: true }))
              }
              onMouseLeave={() =>
                setShowTooltip((prev) => ({ ...prev, [row.id]: false }))
              }
            >
              <Box>{getAssignedUsersTitle(row)}</Box>
            </Tooltip>
            <Tooltip
              title={
                row.status.code === GeneralDocumentStatusCodes.COMPLETED
                  ? t("generalDocuments.pleaseMoveToProgress")
                  : null
              }
            >
              <span>
                {can(PermissionsUpdate.GENERAL_DOCUMENT) && (
                  <>
                    <IconButton
                      aria-label="close"
                      data-testid="close-icon"
                      role="close-icon"
                      disabled={
                        row.status.code === GeneralDocumentStatusCodes.COMPLETED
                      }
                      onClick={() => prepareBeforeAssignedUsersDialog(row)}
                    >
                      <EditIcon />
                    </IconButton>
                  </>
                )}
              </span>
            </Tooltip>
          </Grid>
        ) : (
          <>
            {can(PermissionsUpdate.GENERAL_DOCUMENT) ? (
              <Tooltip
                title={
                  row.status.code === GeneralDocumentStatusCodes.COMPLETED
                    ? t("generalDocuments.pleaseMoveToProgress")
                    : null
                }
              >
                <span>
                  <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    disabled={
                      row.status.code === GeneralDocumentStatusCodes.COMPLETED
                    }
                    onClick={() => prepareBeforeAssignedUsersDialog(row)}
                    title={t("generalDocuments.assignUsers")}
                  />
                </span>
              </Tooltip>
            ) : (
              EMPTY_DATA
            )}
          </>
        )}
      </MuiTableCell>
      <TableCell align="left">
        <ExpandableComment
          seeAllComment={() => handleOpenComments(row)}
          commentData={row?.latest_comment}
        ></ExpandableComment>
      </TableCell>
      <FileTableCell file={row.file} />
      <TableCell align="left">{row?.status?.name}</TableCell>
      <TableCell align="left">
        {row.creator
          ? `${row?.creator?.first_name} ${row?.creator?.last_name}`
          : EMPTY_DATA}
      </TableCell>
      <TableCell align="left">
        {formatTimestamp(row.created_at, DATE_TIME_FORMAT)}
      </TableCell>
      <TableCell align="left" sx={{ minWidth: "135px" }}>
        <Grid container justifyContent="flex-end">
          {can(PermissionsUpdate.GENERAL_DOCUMENT) && (
            <span>
              {row.status.code !== GeneralDocumentStatusCodes.COMPLETED ? (
                <Grid item>
                  <Tooltip
                    title={t(`generalDocuments.complete`)}
                    placement="top"
                  >
                    <Grid container>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <DoneAll
                          color={
                            row.is_user_can_change_status
                              ? "success"
                              : "disabled"
                          }
                          width={22}
                          fontSize="small"
                          height={22}
                          onClick={() => {
                            handleChangeGeneralDocumentStatus(
                              row,
                              GeneralDocumentStatusCodes.COMPLETED
                            );
                          }}
                          data-testid="complete-icon"
                        />
                      </Grid>
                    </Grid>
                  </Tooltip>
                </Grid>
              ) : (
                <Grid item>
                  <Tooltip
                    title={t(`generalDocuments.incomplete`)}
                    placement="top"
                  >
                    <Grid container>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <RemoveDone
                          color={
                            row.is_user_can_change_status
                              ? "success"
                              : "disabled"
                          }
                          width={22}
                          height={22}
                          fontSize="small"
                          onClick={() => {
                            handleChangeGeneralDocumentStatus(
                              row,
                              GeneralDocumentStatusCodes.NEW
                            );
                          }}
                          data-testid="cancel-icon"
                        />
                      </Grid>
                    </Grid>
                  </Tooltip>
                </Grid>
              )}
            </span>
          )}
          <Grid item>
            <SendIcon
              role="ForwardIconRole"
              size={18}
              onClick={() => setSelectedGeneralDocumentId(row.id)}
            />
          </Grid>
          <ShowIcon
            role={"showIconRole"}
            onClick={(): void => handleShowClick(row)}
            size={18}
          />
          {can(PermissionsDelete.GENERAL_DOCUMENT) && (
            <Grid item>
              <DeleteIcon
                role="DeleteIconRole"
                size={18}
                onClick={() => openConfirmToRemoveModal(row)}
              />
            </Grid>
          )}
        </Grid>
      </TableCell>
    </>
  );
};

export default GeneralDocumentRow;
