import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Search, SearchIconWrapper, Input } from "../../../ui/table/styled";
import useTable from "../../../ui/table/useTable";
import Table from "../../../ui/table/Table";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { handleServerError } from "../../../utils/http";
import {
  FILTER_TYPE,
  generalPropertyOption, getFilteredHeadCellsByProperty,
  prepareQueryParams,
} from "../../../utils/common";
import { useTranslation } from "react-i18next";
import {
  initAssignedUsersData,
  itemsListHeadCells,
  snackbarInitState,
} from "./utils";
import {
  COMMENTABLE,
  PermissionsCreate,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../constants";
import { showCommentsModalInitState } from "../../../ui/commentsPopUp/utils";
import CommentsPopup from "../../../ui/commentsPopUp";
import useInboxStatus from "../../../hooks/useInboxStatus";
import { useParams, useNavigate } from "react-router-dom";
import { route } from "../../../utils/url";
import { NavButton } from "./styled";
import Snackbar from "../../../ui/Snackbar";
import Button from "../../../ui/button/Button";
import { AssignedUsers, SnackbarMessage } from "../types";
import { Search as SearchIcon } from "react-feather";
import { getProperties } from "../../../api/property";
import useAccessControl from "../../../hooks/useAccessControl";
import ForwardDocumentDialog from "../../properties/invoices/forwardDocument/ForwardDocumentDialog";
import Dropdown from "../../../ui/dropdown";
import { usePropertySelect } from "../../../hooks/usePropertySelect";
import TabPageWrapper from "../../../ui/tabPageWrapper";
import {
  GeneralDocument,
  GeneralDocumentStatusCodes,
} from "../../../types/be/generalDocument";
import {
  getGeneralDocuments,
  deleteGeneralDocument,
  updateGeneralDocumentStatus,
  assignPropertyToGeneralDocument,
} from "../../../api/generalDocuments";
import AssignPropertyDialog from "./assignPropertyDialog";
import { handleAssignProperty } from "./assignPropertyDialog/utils";
import AssignedUsersDialog from "./assignedUsersDialog";
import GeneralDocumentRow from "./row";
import { useFetch } from "../../../hooks/useFetch";
import { contactAutocomplete } from "../../../api/contacts";
import { Contact } from "../../../types/be/contact";

/* istanbul ignore next */
const GeneralDocumentList = (): ReactElement => {
  const { t } = useTranslation();
  const { status = GeneralDocumentStatusCodes.NEW, id } = useParams();
  const navigate = useNavigate();
  const { can } = useAccessControl();

  const [selectedGeneralDocumentId, setSelectedGeneralDocumentId] = useState<
    number | null
  >(null);
  const { propertyId, property, isGeneral, onPropertySelect } =
    usePropertySelect(id);
  const { data: preloadedContactsData, run: runPreloadedContactsList } =
    useFetch<Contact[]>();

  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleSortChange,
    handleSelectAllClick,
    setTotal,
    openConfirmToRemoveModal,
    rowToDelete,
    handleDeleteLastPageData,
    handleConfirmToRemoveModalClose,
    handleTableSearch,
  } = useTable<GeneralDocument>();
  const { findInboxStatus: findGeneralDocumentStatus, statusList } =
    useInboxStatus();

  const [isShowCommentsModal, setIsShowCommentsModal] = useState(
    showCommentsModalInitState
  );
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [removingProperty, setRemovingProperty] = useState<string | null>(null);
  const [snackbarData, setSnackbarData] =
    useState<SnackbarMessage>(snackbarInitState);
  const [generalDocumentId, setGeneralDocumentId] = useState<string | null>(
    null
  );

  const [assignedUsers, setAssignedUsers] = useState<AssignedUsers>(
    initAssignedUsersData
  );

  useEffect(() => {
    runPreloadedContactsList(contactAutocomplete(""));
  }, []);

  const handleCloseSnackbar = (): void => setSnackbarData(snackbarInitState);

  const handleOpenComments = useCallback((row: GeneralDocument): void => {
    setIsShowCommentsModal({ isVisible: true, rowId: row?.id });
  }, []);

  const handleFetchList = useCallback(async () => {
    const statusId = findGeneralDocumentStatus(status)?.id?.toString();
    const payload: Record<string, string | undefined> = {
      status_id: statusId,
    };

    if (isGeneral === FILTER_TYPE.GENERAL || propertyId || id) {
      payload.property_id = propertyId || id || "";
    }

    const params = prepareQueryParams(queryParams, payload, true);
    const response = await getGeneralDocuments(params);

    if (response.status !== HTTP_STATUS_CODES.OK) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const json = await response.json();
      setData(json?.data);
      setTotal(json?.meta?.total);
    }

    setIsLoading(false);
  }, [
    queryParams,
    propertyId,
    status,
    findGeneralDocumentStatus,
    id,
    isGeneral,
  ]);

  /* istanbul ignore next */
  const handleShowClick = useCallback(
    ({ id: documentId }: GeneralDocument): void => {
      if (id) {
        navigate(route("properties.general-documents.show", id, documentId));
      } else {
        navigate(route("general-documents.show", documentId));
      }
    },
    []
  );

  const handleCloseCommentsModal = useCallback((): void => {
    setIsShowCommentsModal(showCommentsModalInitState);
    handleFetchList();
  }, [handleFetchList]);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteGeneralDocument(rowToDelete.id);
    handleDeleteLastPageData(handleFetchList);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteGeneralDocument,
    handleFetchList,
    handleConfirmToRemoveModalClose,
  ]);

  const handleChangeGeneralDocumentStatus = useCallback(
    async (row: GeneralDocument, statusType: GeneralDocumentStatusCodes) => {
      if (!row.is_user_can_change_status) return;
      const status = findGeneralDocumentStatus(statusType);
      if (!status) return;

      const response = await updateGeneralDocumentStatus(row.id, status.id);
      console.log("response.status", response.status);
      if (response.status !== HTTP_STATUS_CODES.OK) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
        setSnackbarData({
          visible: true,
          text: t(SOMETHING_WENT_WRONG_ERROR),
          color: "error",
        });
      } else {
        await handleFetchList();
        setSnackbarData({
          visible: true,
          text: t("generalDocuments.generalDocumentStatusSuccessfullyUpdated"),
          color: "success",
        });
      }
    },
    [
      findGeneralDocumentStatus,
      status,
      handleFetchList,
      updateGeneralDocumentStatus,
    ]
  );

  const handleStatusChange = useCallback(
    (status: string) => {
      if (id) {
        navigate(route("properties.general-documents.tab", id, status));
      } else {
        navigate(route("general-documents.tab", status));
      }
    },
    [id]
  );

  const onCreateClick = useCallback(() => {
    if (id) {
      navigate(route("properties.general-documents.create", id));
    } else {
      navigate(route("general-documents.create"));
    }
  }, [id]);

  const handleRemoveProperty = useCallback(
    async (id: string): Promise<void> => {
      setRemovingProperty(id);
      const response = await assignPropertyToGeneralDocument(+id, {
        property_id: null,
      });
      if (response.status === HTTP_STATUS_CODES.OK) {
        await handleFetchList();
        setSnackbarData({
          visible: true,
          text: t("generalDocuments.propertyRemovedSuccessfully"),
          color: "success",
        });
      } else {
        setSnackbarData({
          visible: true,
          text: t(SOMETHING_WENT_WRONG_ERROR),
          color: "error",
        });
      }
      setRemovingProperty(null);
    },
    [assignPropertyToGeneralDocument, handleFetchList]
  );

  const afterAssignProperty = useCallback(async () => {
    await handleFetchList();
    setGeneralDocumentId(null);
    setSnackbarData({
      visible: true,
      text: t("generalDocuments.propertyAssignedSuccessfully"),
      color: "success",
    });
  }, [handleFetchList]);

  const afterAssignUsers = useCallback(async () => {
    await handleFetchList();
    setAssignedUsers(initAssignedUsersData);
    setSnackbarData({
      visible: true,
      text: t("generalDocuments.propertyAssignedSuccessfully"),
      color: "success",
    });
  }, [handleFetchList]);

  const tableHeaderTitles = getFilteredHeadCellsByProperty(
    itemsListHeadCells,
    "property_name",
    id
  );

  useEffect(() => {
    setIsLoading(true);
    queryParams && statusList && handleFetchList();
  }, [queryParams, status, statusList, id, propertyId, isGeneral]);

  const TableToolbar = (
    <Grid container mt={1} mb={2}>
      <Grid item xs={12}>
        <Grid container mt={1}>
          {statusList?.map((item) => (
            <Grid item key={`$status-option-${item.code}`} py={4} pr={4}>
              <NavButton
                size="small"
                disabled={status === item.code}
                key={item.code}
                onClick={() => handleStatusChange(item.code)}
                title={item?.name}
                variant="text"
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          mt={1}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Grid item xs={6} sx={{ pt: 2, gap: 2 }} display="flex">
            {can(PermissionsCreate.GENERAL_DOCUMENT) &&
            status === GeneralDocumentStatusCodes.NEW ? (
              <Grid item>
                <Button
                  color="success"
                  title={t("generalDocuments.create.title")}
                  onClick={onCreateClick}
                  sx={{ fontSize: 15 }}
                />
              </Grid>
            ) : null}
            <Grid item lg={4} xs={12} md={4} sm={6}>
              {!id ? (
                <Dropdown
                  id="property-autocomplete"
                  name="property_id"
                  placeholder={t("documentRelease.invoice.searchProperty")}
                  getOptions={getProperties}
                  onSelectOption={onPropertySelect}
                  optionLabel="object_name"
                  size="small"
                  defaultValue={property}
                  defaultOption={[generalPropertyOption]}
                />
              ) : null}
            </Grid>
          </Grid>

          <Grid item>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t("search")} onChange={handleTableSearch} />
            </Search>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const prepareBeforeAssignedUsersDialog = (row: GeneralDocument): void => {
    setAssignedUsers((prevState) => ({
      ...prevState,
      documentId: row.id,
      data: row.assigned_users,
      propertyId: row.property?.id ?? null,
      assetManagers: row.property?.asset_managers || [],
    }));
  };

  const renderRow = (row: GeneralDocument): ReactElement => {
    return (
      <GeneralDocumentRow
        row={row}
        removingProperty={removingProperty}
        handleRemoveProperty={handleRemoveProperty}
        setGeneralDocumentId={setGeneralDocumentId}
        setSnackbarData={setSnackbarData}
        prepareBeforeAssignedUsersDialog={prepareBeforeAssignedUsersDialog}
        handleOpenComments={handleOpenComments}
        handleChangeGeneralDocumentStatus={handleChangeGeneralDocumentStatus}
        setSelectedGeneralDocumentId={setSelectedGeneralDocumentId}
        handleShowClick={handleShowClick}
        openConfirmToRemoveModal={openConfirmToRemoveModal}
        preloadedContactsData={preloadedContactsData}
      />
    );
  };

  const handleCloseForwardModel = useCallback((): void => {
    setSelectedGeneralDocumentId(null);
  }, []);

  const hideSnackbar = useCallback((): void => {
    setSnackbarVisible(false);
  }, []);

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
  ];

  return (
    <TabPageWrapper
      title={t("generalDocuments.title")}
      breadcrumbs={breadcrumbs}
      wrap={!id}
    >
      <>
        <Table
          data={data}
          total={total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={tableHeaderTitles}
          renderRow={renderRow}
          tableToolbar={TableToolbar}
          onRowRemove={handleRowRemove}
          tableDataMaxHeight={"60vh"}
        />
        {isShowCommentsModal.isVisible && (
          <CommentsPopup
            handleCloseDialog={handleCloseCommentsModal}
            id={String(isShowCommentsModal?.rowId)}
            relation={COMMENTABLE.GENERAL_DOCUMENT}
          />
        )}
        {selectedGeneralDocumentId ? (
          <ForwardDocumentDialog
            isOpen={true}
            handleCloseForwardModal={handleCloseForwardModel}
            entityId={selectedGeneralDocumentId}
            entityType="general-documents"
            setSnackbarVisible={setSnackbarVisible}
            title={t("generalDocuments.forwardTitle")}
          />
        ) : null}
        {generalDocumentId ? (
          <AssignPropertyDialog
            id={generalDocumentId}
            onClose={() => setGeneralDocumentId(null)}
            open={true}
            afterAssignProperty={afterAssignProperty}
            handleAssignProperty={handleAssignProperty}
          />
        ) : null}

        {/* Assign users */}
        {assignedUsers.documentId && (
          <AssignedUsersDialog
            onClose={() =>
              setAssignedUsers((prevState) => ({
                ...prevState,
                documentId: null,
                data: [],
              }))
            }
            open={true}
            afterAssignUsers={afterAssignUsers}
            assignedUsers={assignedUsers}
            setAssignedUsers={setAssignedUsers}
          />
        )}
        <Snackbar
          message={t("generalDocuments.forward.success")}
          color="success"
          open={snackbarVisible}
          handleClose={hideSnackbar}
        />
        <Snackbar
          message={snackbarData.text}
          color={snackbarData.color}
          open={snackbarData.visible}
          handleClose={handleCloseSnackbar}
        />
      </>
    </TabPageWrapper>
  );
};

export default GeneralDocumentList;
