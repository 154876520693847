import React, { ReactElement } from "react";
import { Search, SearchIconWrapper, Input } from "../../../../ui/table/styled";
import { Button, Grid } from "@mui/material";
import { Search as SearchIcon } from "react-feather";
import { UserActivityToolbarProps } from "../types";
import { useTranslation } from "react-i18next";
import LogTypeFilter from "./filters/LogType";
import DateRangeFilter from "./filters/DateRange";
import UsersFilter from "./filters/UsersFilter";

const UserActivityToolbar = ({
  handleTableSearch,
  openCreateCompanyModal,
  setActiveTypeId,
  activeTypeId,
  logTypes,
  dateRange,
  setDateRange,
  users,
  setSelectedUsers,
}: UserActivityToolbarProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Grid container mt={5} mb={2} alignItems={"center"}>
      {users && (
        <Grid item xs={2} sx={{ mr: 4 }}>
          <UsersFilter users={users} setSelectedUsers={setSelectedUsers} />
        </Grid>
      )}
      {logTypes && (
        <Grid item xs={2} sx={{ mr: 4 }}>
          <LogTypeFilter
            logTypes={logTypes}
            setActiveTypeId={setActiveTypeId}
            activeTypeId={activeTypeId}
          />
        </Grid>
      )}
      <Grid item sx={{ mr: 4 }}>
        <DateRangeFilter dateRange={dateRange} setDateRange={setDateRange} />
      </Grid>
      <Grid item xs></Grid>
      <Grid item sx={{ mr: 4 }}>
        <Button
          sx={{ mr: 1 }}
          type="button"
          onClick={(): void => openCreateCompanyModal()}
          size="medium"
          color="error"
          variant="contained"
          data-testid={"dataItems-delete-old-activity"}
        >
          {t("delete")}
        </Button>
      </Grid>
      <Grid item>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Input placeholder={t("search")} onChange={handleTableSearch} />
        </Search>
      </Grid>
    </Grid>
  );
};

export default UserActivityToolbar;
