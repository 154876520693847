import styled from "styled-components";
import DialogTitleCustom from "../../../../../ui/dialog/DialogTitle";
import { Card as MuiCard } from "@mui/material";
/* istanbul ignore next */
export const DialogTitle = styled(DialogTitleCustom)`
  padding-left: ${(props) => props.theme.spacing(6)};
`;

export const Card = styled(MuiCard)`
  text-align: left;
  overflow: visible;
  .fixed-position {
    position: fixed;
    z-index: 999;
  }
`;
