import { CreateRoleFormValues } from "../../types";
import { FormikValues } from "formik";
import { mapBackendValidationErrors } from "../../../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../constants";
import { touchedInitState } from "../../create/utils";
import { HandleUpdateRoleFormSubmitProps } from "./types";
import { updateRole } from "../../../../../api/roles";
/* istanbul ignore next */
export const handleUpdateRoleFormSubmit = ({
  roleId,
  permissionCodes,
  setFormMessage,
}: HandleUpdateRoleFormSubmitProps) => {
  return async (
    values: CreateRoleFormValues,
    { setStatus, setSubmitting, setTouched }: FormikValues
  ): Promise<void> => {
    if (!permissionCodes || !roleId) return;

    const auxPermissionCodes = Object.keys(permissionCodes).filter((key) =>
      Boolean(permissionCodes[key])
    );

    const response = await updateRole(roleId, {
      name: values.name,
      permission_code: auxPermissionCodes,
    });
    const json = await response.json();

    if (response.ok) {
      setStatus({
        success: true,
        errors: {},
      });
      setTouched(touchedInitState);
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
      setSubmitting(false);
    }
  };
};
