import { Dispatch, SetStateAction } from "react";
import {
  Area,
  AreaCategory,
  AreaFloor,
  AreaType,
} from "../../../../../../../types/be/area";
import { User } from "../../../../../../../types/be/user";
import { FormMessageInterface } from "../../../../../../../types/form";
import {
  TenantHistory,
  TenantHistoryContact,
} from "../../../../../../../types/be/tenantHistory";
import {
  Advertisement,
  AreaTenants,
  BKA,
  DunningLetters,
  Usage,
} from "../../../../../../../types/be/tenant";
import { Tenant } from "../../../../../../../types/models";

export const SET_AREA = "SET_AREA";
export const SET_TENANT_HISTORY_DETAILS = "SET_TENANT_HISTORY_DETAILS";
export const SET_IS_SHOW_TENANT_HISTORY_DETAILS_BUTTON_DISABLED =
  "SET_IS_SHOW_TENANT_HISTORY_DETAILS_BUTTON_DISABLED";

export interface TenantHistoryTableData {
  id: number;
  name: string;
  rent_start: number;
  rent_end: number;
  net_rent_per_month: number;
  tax_in_eur: number;
  contact: TenantHistoryContact | null;
  is_extendable?: boolean;
}

export interface TenantHistoryProps {
  id: number;
  name: string;
  rent_start: number;
  rent_end: number;
  contact: TenantHistoryContact | null;
  is_extendable?: boolean;
  cancellation_until: number | null;
  special_cancellation_right: number | null;
  closing_tenant_contract: number | null;
  net_rent_per_month: number;
  net_rent_per_year: number;
  deposit: number;
  costs_for_modification: number;
  art: number;
  area: number;
  net_rent_per_m2: number;
  tax?: number;
  number_of_parking_spaces: null | number;
  rent_in_eur_per_parking_space: null | number;
  others_incomings_eur: number;
  is_cancelled: boolean;
  new: number;
  options: boolean;
  empty_space_at_purchase: number;
  tenants: AreaTenants[];
  usage: Usage;
  total_net_rent_per_year: number;
  total_net_rent_per_month: number;
  tax_in_eur: number;
  reminder: boolean;
  empty_space: number;
  remaining_term: string;
  remaining_term_in_eur: number;
  total_gross_net_rent_per_month: number;
  total_gross_net_rent_per_year: number;
  vacancy_in_sq: null;
  vacancy_in_eur: null;
  is_unlimited: boolean;
  assessment_date: null;
  is_opt_jn: boolean;
  is_idx_jn: boolean;
  right_of_first_tenancy: boolean;
  rent_free: number;
  rent_eur: number;
  is_terminated: boolean;
  no_bka: boolean;
  advertisement: Advertisement;
  dunning_letter: DunningLetters;
  bka: BKA[];
  contact_id?: number;
  additional_costs_per_month: number;
  additional_costs_per_year: number;
  rental_contract_file: File | null;
}

export interface payloadProps extends TenantHistoryProps {
  active_tenant: Tenant;
}

export interface FutureTenantDialogProps {
  initialValues: TenantHistoryProps;
  uploadFutureModal: boolean;
  handleCloseModal: () => void;
  isFutureTenant: boolean;
  submitCallback: () => void;
}

export type EditAreaContextType = {
  area: Area | null;
  fetchArea: (() => void) | null;
  isTenant: boolean;
  areaCategories: AreaCategory[] | null;
  isAreaCategoriesLoading: boolean;
  areaTypes: AreaType[] | null;
  isAreaTypesLoading: boolean;
  assetManagers: User[] | null;
  isAssetManagersLoading: boolean;
  tenantHistoryDetails: TenantHistory | null;
  isShowTenantHistoryDetailsButtonDisabled: boolean;
  openShowTenantHistoryDetailsModal: ((id: number) => void) | null;
  closeShowTenantHistoryDetailsModal: (() => void) | null;
  dispatch: Dispatch<
    | {
        type: typeof SET_AREA;
        payload: { area: Area };
      }
    | {
        type: typeof SET_TENANT_HISTORY_DETAILS;
        payload: { tenantHistoryDetails: TenantHistory | null };
      }
    | {
        type: typeof SET_IS_SHOW_TENANT_HISTORY_DETAILS_BUTTON_DISABLED;
        payload: { isShowTenantHistoryDetailsButtonDisabled: boolean };
      }
  > | null;
  areaFloors: AreaFloor[] | null;
  isAreaFloorsLoading: boolean;
};

export type EditAreaStateType = {
  area: Area | null;
  tenantHistoryDetails: TenantHistory | null;
  isShowTenantHistoryDetailsButtonDisabled: boolean;
};

export interface ExtendDialogProps {
  initialValues: TenantHistoryTableData;
  extendModal: boolean;
  handleCloseModal: (refresh?: boolean) => () => void;
}

export interface HandleSubmitExtendProps {
  setFormMessage: Dispatch<SetStateAction<FormMessageInterface>>;
  onSuccess: () => void;
}

export interface TenantHistoryTableRowProps {
  row: TenantHistoryProps;
  handleExtendClick: (row: TenantHistoryProps) => void;
  refetchData: () => void;
  openConfirmToRemoveModal: (row: TenantHistoryProps) => void;
  handleShowUpdateFutureTenantModal: (row: TenantHistoryProps) => void;
}

export interface SnackbarMessage {
  color: "success" | "error";
  text: string;
  visible: boolean;
}
