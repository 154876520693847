import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Grid, Paper } from "@mui/material";
import StatusButtons from "../../../../components/statusButtons";
import {
  Input,
  Search,
  SearchIconWrapper,
} from "../../../../../../ui/table/styled";
import { Search as SearchIcon } from "react-feather";
import { handleTopicCreateSubmit } from "../../utils";
import { Formik } from "formik";
import Form from "./Form";
import { CreateTopicForm, TopicListToolbarProps } from "../../types";
import { StyledButton } from "../../../../../../ui/button/styled";
import {
  PermissionsCreate,
  REQUEST_STATUS_IDS,
} from "../../../../../../constants";
import { generalPropertyOption } from "../../../../../../utils/common";
import { getProperties } from "../../../../../../api/property";
import useAccessControl from "../../../../../../hooks/useAccessControl";
import Dropdown from "../../../../../../ui/dropdown";

const TopicListToolbar = ({
  createOfferTopicFormMode,
  createTopicInitialValues,
  fetchOfferTopics,
  setCreateOfferTopicFormMode,
  setIsSuccess,
  name,
  handleCreateTopic,
  statuses,
  handleTableSearch,
  propertyId,
  activeStatusId,
  property,
  defaultStatus,
  onPropertySelect,
  handleStatusChange,
}: TopicListToolbarProps): ReactElement => {
  const { t } = useTranslation();
  const { can } = useAccessControl();

  return (
    <Paper sx={{ width: "100%", mb: 2, px: 5, py: 1, position: "relative" }}>
      <Grid container mt={5} alignItems="center">
        <Grid item xs={12} pb={4}>
          <Grid container alignItems="center">
            <Grid item>
              <StatusButtons
                subPath="offers"
                statuses={statuses}
                handleStatusChange={handleStatusChange}
                activeStatusId={activeStatusId}
                useSpecialLabels={true}
                defaultStatus={defaultStatus}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={5.5}>
          <Grid container alignItems="center">
            <Grid xs={6} sx={{ pt: 2 }} display="flex" item gap={2}>
              {can(PermissionsCreate.OFFER) &&
                activeStatusId === REQUEST_STATUS_IDS.NEW && (
                  <Grid item>
                    {createOfferTopicFormMode === CreateTopicForm.LIST ? (
                      <Formik
                        initialValues={createTopicInitialValues}
                        validationSchema={Yup.object().shape({
                          name,
                        })}
                        onSubmit={handleTopicCreateSubmit({
                          fetchOfferTopics,
                          setCreateOfferTopicFormMode,
                          setIsSuccess,
                        })}
                      >
                        {(props): React.ReactElement => (
                          <Form
                            {...props}
                            setCreateOfferTopicFormMode={
                              setCreateOfferTopicFormMode
                            }
                          />
                        )}
                      </Formik>
                    ) : (
                      <StyledButton
                        size="small"
                        color="success"
                        variant="contained"
                        onClick={handleCreateTopic}
                        data-testid="create-topic-button"
                      >
                        {t("offers.create")}
                      </StyledButton>
                    )}
                  </Grid>
                )}
              {!propertyId ? (
                <>
                  <Grid item lg={4} xs={12} md={4} sm={6}>
                    <Dropdown
                      id="property-autocomplete"
                      name="property_id"
                      placeholder={t("documentRelease.invoice.searchProperty")}
                      getOptions={getProperties}
                      onSelectOption={onPropertySelect}
                      optionLabel="object_name"
                      size="small"
                      defaultValue={property}
                      defaultOption={[generalPropertyOption]}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>

            <Grid item xs />
            <Grid item>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder={t("search")} onChange={handleTableSearch} />
              </Search>
            </Grid>
          </Grid>
        </Grid>
        {/*  */}
      </Grid>
    </Paper>
  );
};

export default TopicListToolbar;
