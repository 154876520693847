import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { NavLink, useParams } from "react-router-dom";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Grid,
  Link,
  Tooltip,
} from "@mui/material";
import { BreadcrumbsLinkText } from "./styled";
import { Property as BEProperty } from "../../types/be/property";
import PropertyData from "./PropertyData";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export interface BreadcrumbItem {
  to: string;
  name: string;
}

export interface PageWrapperProps {
  title: string;
  rightSideControls?: () => React.ReactElement;
  children: React.ReactElement;
  breadcrumbs: BreadcrumbItem[];
  property?: BEProperty | null;
}

export default ({
  title,
  breadcrumbs,
  property,
  rightSideControls,
  children,
}: PageWrapperProps): React.ReactElement => {
  const { id } = useParams();

  return (
    <>
      <Grid container flexDirection="column">
        <Grid item pb={2.5} className="bread-crumbs-container">
          <Breadcrumbs
            role={"breadcrumbs"}
            aria-label="Breadcrumb"
            sx={{ pl: 5, pt: 1 }}
          >
            {breadcrumbs.map((b, i) => {
              return b.name.length > 35 ? (
                /* istanbul ignore next */ <Tooltip
                  title={b.name}
                  key={`breadcrumb-${i}`}
                >
                  <Link component={NavLink} to={b.to}>
                    <BreadcrumbsLinkText>{b.name}</BreadcrumbsLinkText>
                  </Link>
                </Tooltip>
              ) : (
                <Link key={`breadcrumb-${i}`} component={NavLink} to={b.to}>
                  {b.name}
                </Link>
              );
            })}
            <BreadcrumbsLinkText>{title}</BreadcrumbsLinkText>
          </Breadcrumbs>
        </Grid>
        {property && (
          <Grid item xs={12} px={5}>
            <PropertyData property={property} />
          </Grid>
        )}
        <Grid item px={5}>
          {id && rightSideControls && rightSideControls()}
        </Grid>
      </Grid>
      {children}
    </>
  );
};
