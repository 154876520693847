import { useCallback, useEffect } from "react";
import { useFetch } from "./useFetch";
import { getEmployeeScheduleOptions as getEmployeeScheduleOption } from "../api/userSchedules";
import { staticParams } from "../components/employeeOverview/utils";
import { EmployeeScheduleOption } from "../types/be.interfaces";

interface UseEmployeeScheduleOptionsProps {
  employeeScheduleOptions: EmployeeScheduleOption[] | null;
  getEmployeeScheduleOptions: (
    query: string | number
  ) => EmployeeScheduleOption | void;
  isError: boolean;
  isLoading: boolean;
  run: (promise: Promise<Response>) => Promise<void>;
}

const useEmployeeScheduleOptions = (): UseEmployeeScheduleOptionsProps => {
  const {
    data: employeeScheduleOptions,
    isError,
    isLoading,
    run,
  } = useFetch<EmployeeScheduleOption[]>();

  useEffect(() => {
    run(getEmployeeScheduleOption(staticParams));
  }, []);

  const getEmployeeScheduleOptions = useCallback(
    (query: string | number): EmployeeScheduleOption | void =>
      employeeScheduleOptions?.find((employeeScheduleOption) =>
        [employeeScheduleOption.code, employeeScheduleOption.id].includes(query)
      ),
    [employeeScheduleOptions]
  );

  return {
    employeeScheduleOptions,
    getEmployeeScheduleOptions,
    isError,
    isLoading,
    run,
  };
};

export default useEmployeeScheduleOptions;
