import styled from "styled-components/macro";
import { TextField as MuiTextField } from "@mui/material";

export const TextField = styled(MuiTextField)`
  .MuiInputBase-root {
    color: ${(props): string => props.theme.palette.grey[600]};
  }
  .MuiSelect-select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;