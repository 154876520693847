import React, { ReactElement, useEffect, useState } from "react";
import useTable from "../../../../ui/table/useTable";
import { Property } from "../../../../types/be/property";
import Table from "../../../../ui/table/Table";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  TableCell,
  Typography,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getJson } from "../../../../utils/http";
import {
  BankAccount as BEBankAccount,
  Payment as BEPayment,
} from "../../../../types/be/bankAccount";
import {
  getAreasByBankAccountId,
  updatePayment,
} from "../../../../api/bankAccount";
import { useParams } from "react-router-dom";
import { areasHeadCells } from "./utils";
import { Area } from "../../../../types/be/area";
import AssignButton from "./AssignButton";
import useSnackbar from "../../../../ui/snackbar1/useSnackbar";
import { route } from "../../../../utils/url";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
/* istanbul ignore next */
const AttachAreaToPayment = ({
  payment,
  isModalOpen,
  onDoneClick,
  useOnWidget,
  selectedBankAccount,
}: {
  payment: BEPayment;
  isModalOpen: boolean;
  onDoneClick: () => void;
  useOnWidget: boolean;
  selectedBankAccount: BEBankAccount | undefined;
}): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { snackbar, Snackbar } = useSnackbar();
  const [attachedAreaId, setAttachedAreaId] = useState<number | null>(null);
  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
  } = useTable<Property>();

  useEffect(() => {
    if (payment?.area) setAttachedAreaId(payment.area.id);
  }, []);

  useEffect(() => {
    fetchAreas();
  }, []);

  const handleOnIsAssignedChange = (areaId: number): void => {
    setAttachedAreaId(areaId);
    attachArea(areaId);
  };

  const attachArea = (areaId: number): void => {
    const area_id = areaId;
    updatePayment(payment.id, {
      area_id,
    })
      .then(getJson)
      .then(({ data }) => {
        setAttachedAreaId(data.area.id);
        snackbar.success(t("snackbar.updated"));
      })
      .catch((err) => snackbar.error(err.toString()));
  };

  const fetchAreas = (): void => {
    setIsLoading(true);
    getAreasByBankAccountId(useOnWidget ? selectedBankAccount!.id : +id!)
      .then(getJson)
      .then(({ data }) => setData(data))
      .catch((err) => {
        setError(err.toString());
        snackbar.error(err.toString());
      })
      .finally(() => setIsLoading(false));
  };

  const handleOnPropertyNameClick = (id: number): void => {
    window.open(route("properties.edit", id, "general"), "_blank");
  };

  const handleOnAreaNameClick = (area: Area): void => {
    window.open(
      route("properties.areas.edit.tab", area.property.id, area.id, "area"),
      "_blank"
    );
  };

  const renderRow = (row: Area): ReactElement => {
    return (
      <>
        <TableCell align="left">
          <Link
            onClick={() => handleOnAreaNameClick(row)}
            sx={{ cursor: "pointer" }}
          >
            {row.name}
          </Link>
        </TableCell>
        <TableCell align="left">
          <Link
            onClick={() => handleOnPropertyNameClick(row.property.id)}
            sx={{ cursor: "pointer" }}
          >
            {row.property.object_name}
          </Link>
        </TableCell>
        <TableCell align="right">
          <AssignButton
            areaId={row.id}
            attachedAreaId={attachedAreaId}
            onClick={(areaId) => handleOnIsAssignedChange(areaId)}
          />
        </TableCell>
      </>
    );
  };

  const TableToolbar = (
    <>
      <Grid container mt={1}>
        <Grid item>
          <Typography variant="h6">
            {payment.amount > 0
              ? t("bankAccount.incomingPayment")
              : t("bankAccount.payment")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <Dialog
        open={isModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth={"md"}
        PaperProps={{ sx: { maxHeight: "80%" } }}
        role={"assign-payment-modal"}
        fullWidth
      >
        <DialogTitle
          title="bankAccount.assignAreaToPayment"
          onClose={onDoneClick}
        />
        <DialogContent>
          <Table
            data={data}
            total={total}
            currentPage={currentPage}
            order={order}
            orderBy={orderBy}
            error={error}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
            onSortChange={handleSortChange}
            onSelectAllClick={handleSelectAllClick}
            onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
            isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
            listHeadCells={areasHeadCells}
            renderRow={renderRow}
            tableToolbar={TableToolbar}
            tableDataMaxHeight={"50vh"}
          />
        </DialogContent>
        <DialogActions sx={{ pr: 11, pb: 5, pt: 5 }}>
          <Button variant="contained" onClick={() => onDoneClick()}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
      {Snackbar}
    </>
  );
};

export default AttachAreaToPayment;
