import { TableCell } from "../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "bankAccount.description",
    align: "left",
  },
  {
    id: "sender",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "bankAccount.sender",
    align: "center",
  },
  {
    id: "is_ignored",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "bankAccount.isIgnored",
    align: "center",
  },
  {
    id: "booking_date",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "bankAccount.paymentDate",
    align: "right",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "bankAccount.amount",
    align: "right",
  },
  {
    id: "area",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "area",
    align: "center",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
    align: "right",
  },
];
