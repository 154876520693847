/* istanbul ignore file */

import { DATE_TYPE, Measurement_Units } from "../../../../constants";
import { Property } from "../../../../types/be/property";
import { PropertyUpdate } from "../../../../types/fe/property";
import { mapBEPropertyToFEPropertyUpdate } from "../utils";

export enum EDITABLE_FIELDS_TYPE {
  FORMAT_NUMBER = "FormatNumber",
  NUMBER = "Number",
  TEXT = "Text",
  DATE = "Date",
  MULTILINE = "Multiline",
}
export const moreFields = [
  [
    {
      name: "broker_price",
      unit: Measurement_Units.EURO,
      disabled: false,
    },
    {
      name: "year_of_construction",
      unit: DATE_TYPE,
      disabled: false,
    },
    {
      name: "land_area",
      unit: Measurement_Units.SQUARE_METER,
      disabled: true,
    },
    {
      name: "parking_lots",
      unit: Measurement_Units.SQUARE_METER,
      disabled: false,
    },
    {
      name: "commercial_area_rented_in_m2",
      unit: Measurement_Units.SQUARE_METER,
      disabled: true,
    },
  ],
  [
    {
      name: "empty_commercial_area_in_m2",
      unit: Measurement_Units.SQUARE_METER,
      disabled: true,
    },
    {
      name: "rented_living_area_in_m2",
      unit: Measurement_Units.SQUARE_METER,
      disabled: true,
    },
    {
      name: "empty_living_area_in_m2",
      unit: Measurement_Units.SQUARE_METER,
      disabled: true,
    },
    {
      name: "standard_ground_value",
      unit: Measurement_Units.SQUARE_METER,
      disabled: false,
    },
    {
      name: "net_rent_actual",
      unit: Measurement_Units.PERCENTAGE,
      disabled: false,
    },
  ],
  [
    {
      name: "net_rent_target",
      unit: Measurement_Units.PERCENTAGE,
      disabled: false,
    },
    {
      name: "maintenance_non_reversible",
      unit: Measurement_Units.PERCENTAGE,
      disabled: false,
    },
    {
      name: "operating_costs_non_reversible",
      unit: Measurement_Units.PERCENTAGE,
      disabled: false,
    },
    {
      name: "non_circulating_property_management",
      unit: Measurement_Units.PERCENTAGE,
      disabled: false,
    },
    {
      name: "taxes",
      unit: Measurement_Units.PERCENTAGE,
      disabled: false,
    },
  ],
];

export const mapPropertyToUpdatePropertyGeneralData = (
  property: Property
): PropertyUpdate & { location: { full_address: string } } => {
  return {
    ...mapBEPropertyToFEPropertyUpdate(property),
    location: {
      ...property.location,
      full_address: property.location.full_address,
    },
  };
};
