import {
  DOCUMENT_RELEASE_LEVEL,
  ReleaseConfiguration,
} from "../../../../types/be/releaseFlow";

/* istanbul ignore next */
export const hasReleasePermission = (
  levelCode: DOCUMENT_RELEASE_LEVEL,
  configurations: ReleaseConfiguration[] | null,
  isRole: (role: string | string[]) => boolean
): boolean => {
  const configurationRole = configurations?.find(
    ({ level }) => level?.code === levelCode
  )?.roles;

  if (!configurationRole) return false;

  return isRole([...configurationRole.map(({ code }) => code)]);
};
