import { TableCell } from "../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "object_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.objectName",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "tenant.areaName",
  },
  {
    id: "area_type_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "tenant.type",
  },
  {
    id: "square_meters",
    numeric: true,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "vacancies.sizePerM2",
  },
  {
    id: "empty_space_since",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "center",
    label: "vacancies.emptySpace",
  },
  {
    id: "potential_rent_income",
    numeric: true,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "vacancies.potentialRentalIncomePerMonth",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];
