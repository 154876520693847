import { Grid, Paper } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { Search as SearchIcon } from "react-feather";
import {
  Input,
  Search,
  SearchIconWrapper,
} from "../../../../../../ui/table/styled";
import { useTranslation } from "react-i18next";
import { TopicListToolbarProps } from "../types";
import TopicDateRangeFilter from "../../../../components/dataRangeFilter";
import CreateTopic from "./topicCreate";
import StatusButtons from "../../../../components/statusButtons";
import { useNavigate, useParams } from "react-router-dom";
import { getReleaseRoute } from "../utils";
import kebabCase from "lodash/kebabCase";
import {
  PermissionsCreate,
  REQUEST_STATUS_IDS,
} from "../../../../../../constants";
import { generalPropertyOption } from "../../../../../../utils/common";
import { getProperties } from "../../../../../../api/property";
import Button from "../../../../../../ui/button/Button";
import useAccessControl from "../../../../../../hooks/useAccessControl";
import Dropdown from "../../../../../../ui/dropdown";

const TopicListToolbar = ({
  activeTypeId,
  setActiveTypeId,
  types,
  activeStatusId,
  handleStatusChange,
  statuses,
  range,
  setRange,
  handleTableSearch,
  fetchInsuranceTopics,
  propertyId,
  property,
  defaultStatus,
  onPropertySelect,
}: TopicListToolbarProps): ReactElement => {
  const [modalState, setModalState] = useState(false);
  const navigate = useNavigate();
  const { can } = useAccessControl();
  const { id, status } = useParams();
  const handleDialog = (): void => {
    setModalState((modalState) => !modalState);
  };
  const { t } = useTranslation();

  const handleTypeChange = (type: number | null, code: string): void => {
    setActiveTypeId(type);
    navigate(
      getReleaseRoute(`insurances.${kebabCase(code)}`, Number(id), status!)
    );
  };

  return (
    <>
      {modalState && (
        <CreateTopic
          handleDialog={handleDialog}
          fetchInsuranceTopics={fetchInsuranceTopics}
        />
      )}
      <Paper sx={{ width: "100%", mb: 2, px: 10, py: 1, position: "relative" }}>
        <Grid container mt={2.4} alignItems="center">
          <Grid item xs={12} pb={5.5}>
            <Grid container alignItems="flex-end">
              <Grid
                item
                sx={{
                  display: "flex",
                  gap: 3,
                  flexDirection: "column",
                }}
              >
                <StatusButtons
                  subPath="insurances"
                  statuses={types}
                  handleStatusChange={handleTypeChange}
                  activeStatusId={activeTypeId}
                  useSpecialLabels={true}
                  navigateOnChange={false}
                  tabPosition="flex-start"
                />
                <StatusButtons
                  subPath="insurances"
                  statuses={statuses}
                  handleStatusChange={handleStatusChange}
                  activeStatusId={activeStatusId}
                  useSpecialLabels={true}
                  navigateOnChange={false}
                  defaultStatus={defaultStatus}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} pb={5.5}>
            <Grid container>
              {/* istanbul ignore next */}
              <Grid xs={6} display="flex" item gap={2} alignItems={"center"}>
                {can(PermissionsCreate.INSURANCE) &&
                  activeStatusId === REQUEST_STATUS_IDS.NEW && (
                    <Grid item>
                      <Button
                        size="medium"
                        color="success"
                        sx={{ fontSize: 15, innerWidth: 150, outerWidth: 150 }}
                        testId="create-topic-button"
                        onClick={handleDialog}
                        title={t("insurance.createTopicTitle")}
                        fullWidth
                      />
                    </Grid>
                  )}
                {!propertyId ? (
                  <>
                    <Grid item lg={4} xs={12} md={4} sm={6}>
                      <Dropdown
                        id="property-autocomplete"
                        name="property_id"
                        placeholder={t(
                          "documentRelease.invoice.searchProperty"
                        )}
                        getOptions={getProperties}
                        onSelectOption={onPropertySelect}
                        optionLabel="object_name"
                        size="small"
                        defaultValue={property}
                        defaultOption={[generalPropertyOption]}
                      />
                    </Grid>
                  </>
                ) : null}

                <Grid item>
                  <TopicDateRangeFilter
                    range={range}
                    setRange={setRange}
                    navigateProp
                  />
                </Grid>
              </Grid>
              <Grid item xs />
              <Grid item>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <Input
                    placeholder={t("search")}
                    onChange={handleTableSearch}
                  />
                </Search>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/*  */}
      </Paper>
    </>
  );
};

export default TopicListToolbar;
