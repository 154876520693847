import React, { ReactElement, useCallback } from "react";
import { HeaderText, LabelText } from "./styled";
import { FormatNumber } from "../../../../ui/formsFields/formatNumber";
import {
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  Measurement_Units,
  MIN_YEAR_1850,
  THOUSAND_SEPARATOR,
} from "../../../../constants";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { handleFutureDisableYear } from "../../areas/edit/utils";
import TextField from "../../../../ui/formsFields/text";
import { EDITABLE_FIELDS_TYPE } from "./utils";
import { EditableFieldsProps } from "./types";
import { NumericFormat } from "react-number-format";
import { yearFromDate } from "../../../../utils/date";
import DatePicker from "../../../../ui/datePicker";

/* istanbul ignore next */
function EditableField({
  isEdit,
  gridSize,
  label,
  name,
  dataTestId,
  disabled,
  type,
  unit,
  value,
  formikProps,
}: EditableFieldsProps): ReactElement {
  const { t } = useTranslation();
  const status = formikProps.status;
  const NumberInputEuro = useCallback(
    () => (
      <FormatNumber
        label={t(label)}
        name={name}
        size={"small"}
        status={status}
        disabled={disabled}
        inputProps={{
          filter: "formatNum",
          unit: Measurement_Units.EURO,
          "data-testid": { dataTestId },
        }}
      />
    ),
    [type]
  );

  const MultiLine = useCallback(() => {
    return (
      <TextField
        id={name}
        name={name}
        label={t(label)}
        multiline
        rows={5}
        onBlur={formikProps.handleBlur}
        onChange={formikProps.handleChange}
        inputProps={{ "data-testid": dataTestId }}
        fullWidth
        status={formikProps.status}
      />
    );
  }, [type]);

  const TextFieldInput = useCallback(
    () => (
      <TextField
        label={t(label)}
        name={name}
        size="small"
        status={status}
        type={type}
        disabled={disabled}
        inputProps={{ "data-testid": dataTestId }}
      />
    ),
    [type]
  );

  const DatePickerField = useCallback(
    (): ReactElement => (
      <DatePicker
        name={name}
        views={["year"]}
        label={t(label)}
        inputFormat="yyyy"
        size={"small"}
        disabled={disabled}
        status={status}
        value={value || null}
        shouldDisableYear={handleFutureDisableYear()}
        minDate={new Date(MIN_YEAR_1850)}
      />
    ),
    [name, label, disabled, status, value]
  );

  const InputField = useCallback(() => {
    switch (type) {
      case EDITABLE_FIELDS_TYPE.TEXT:
      case EDITABLE_FIELDS_TYPE.NUMBER:
        return <TextFieldInput></TextFieldInput>;
      case EDITABLE_FIELDS_TYPE.MULTILINE:
        return <MultiLine></MultiLine>;
      case EDITABLE_FIELDS_TYPE.FORMAT_NUMBER:
        return <NumberInputEuro></NumberInputEuro>;
      default:
        return <></>;
    }
  }, [DatePickerField, type]);

  const DisplayValue = useCallback(() => {
    if (value === null || value === undefined) {
      return <HeaderText>{EMPTY_DATA}</HeaderText>;
    }
    switch (type) {
      case EDITABLE_FIELDS_TYPE.TEXT:
      case EDITABLE_FIELDS_TYPE.NUMBER:
      case EDITABLE_FIELDS_TYPE.MULTILINE:
        return <HeaderText>{`${value}${unit || ""}`}</HeaderText>;
      case EDITABLE_FIELDS_TYPE.FORMAT_NUMBER:
        return (
          <HeaderText>
            <NumericFormat
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              decimalScale={DECIMAL_SCALE}
              fixedDecimalScale
              displayType={"text"}
              value={value}
            />{" "}
            {unit || ""}
          </HeaderText>
        );
      case EDITABLE_FIELDS_TYPE.DATE:
        return <HeaderText>{`${yearFromDate(value)}`}</HeaderText>;
      default:
        return <></>;
    }
  }, [value]);
  return (
    <>
      <Grid item xs={Number(gridSize)}>
        {!isEdit ? (
          <>
            <DisplayValue />
            <LabelText>{t(label)}</LabelText>
          </>
        ) : (
          <Grid item p={2}>
            {type === EDITABLE_FIELDS_TYPE.DATE ? (
              <DatePickerField />
            ) : (
              InputField()
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default EditableField;
