/* istanbul ignore file */
import { Grid } from "@mui/material";
import styled from "styled-components";
export const ItemWrapper = styled(Grid)`
  border-top: ${(props): string => props.theme.spacing(0.25)} solid
    ${(props): string => props.theme.border.color};
  border-bottom: ${(props): string => props.theme.spacing(0.25)} solid
    ${(props): string => props.theme.border.color};
  margin-top: ${(props): string => props.theme.spacing(6)};
  margin-left: ${(props): string => props.theme.spacing(6)};
  padding-bottom: ${(props): string => props.theme.spacing(4)} !important;
`;
