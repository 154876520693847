export default {
  new: "New",
  search: "Search",
  inProgress: "In progress",
  inCoordination: "In coordination",
  completed: "Completed",
  areaName: "Area name",
  createdAt: "Created at",
  description: "Description",
  propertyName: "Property name",
  ticket: "Ticket",
  title: "Title",
  tenant: "Tenant",
  status: "Status",
  manager: "Manager",
  back: "Back",
  delete: "Delete",
  update: "Update",
  wantToDelete: "Want to delete",
  cancel: "Cancel",
  create: "Create",
  uploaded: "Uploaded",
  ticketNumber: "Ticket number",
  area: "Area",
  titleIsRequired: "Title is required",
  descriptionIsRequired: `Description is required`,
  dragAndDropYourFilesHereOr: "Drag and drop your files here or",
  uploadFile: "Upload a file",
  property: "Property",
  propertyIsRequired: "Property is required",
  tenantIsRequired: "Tenant is required",
  areaIsRequired: "Area is required",
  youNeedToSelectPropertyFirst: "You need to select a property first",
  youNeedToSelectPropertyAreaFirst: "You need to select a property area first",
  event: "Event",
  created: "Tenant request was created",
  eventData: {
    created: "created",
    updatedStatusOf: "updated status of",
    from: "from",
    to: "to",
    udpatedStatusOf: "updated status of",
    updatedDescriptionOf: "updated description of",
    updatedPropertyAreaOf: "updated property area of",
    udpateManagerOf: "updated manager of",
    udpateTenantOf: "updated tenant of",
    udpateTitleOf: "updated title of",
    udpatePropertyOf: "updated property of",
    udpateFilesOf: "updated files of",
  },
  selectedAreaDoesNotHaveTenants: "Selected area does not have tenants",
};
