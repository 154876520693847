import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ThemeContext } from "styled-components/macro";
import { Outlet, useParams } from "react-router-dom";
import { Hidden, CssBaseline } from "@mui/material";
import GlobalStyle from "../../components/GlobalStyle";
import Navbar from "../../components/navbar/Navbar";
import dashboardItems from "../../components/sidebar/dashboardItems";
import Sidebar from "../../components/sidebar/Sidebar";
import Footer from "../../components/Footer";
import SessionTimeoutDialog from "../../components/session/SessionTimeoutDialog";
import useAppSelector from "../../hooks/useAppSelector";
import { connectSocket, disconnectSocket } from "../../redux/slices/socket";
import useAppDispatch from "../../hooks/useAppDispatch";
import { getNotifications } from "../../api/notifications";
import {
  ChatNotification,
  Notification as BENotification,
} from "../../types/be/notification";
import { ServerPaginatedResponse } from "../../types/be/serverResponse";
import { prepareQueryParams } from "../../utils/common";
import { MAX_NOTIFICATIONS_LIMIT, NOTIFICATIONS } from "../../constants";
import { Root, Drawer, AppContent, MainContent } from "./styled";
import { drawerToggleCallback, prepareArchive } from "./utils";
import {
  setNotifications,
  pushNotification,
} from "../../redux/slices/notification";
import NewsModal from "./NewsModal";
import { setArchive } from "../../redux/slices/archive/archive";
import { ArchiveFolder } from "./archiveFolder";
import { setImportValidationNotification } from "../../redux/slices/importValidation/importValidation";
import ImportValidation from "./importValidation";
import { emitCustomEvent } from "react-custom-events";
import { SidebarItemsType } from "../../components/sidebar/types";

const Dashboard: React.FC = ({ children }) => {
  const dispatchStore = useAppDispatch();
  const { _user } = useAppSelector((state) => state.user);
  const { socket } = useAppSelector((state) => state.socket);
  const { client } = useParams();
  const [mobileOpen, setMobileOpen] = useState(false);
  const {
    sidebar: { width: drawerWidth },
  } = useContext(ThemeContext);
  const handleDrawerToggle = drawerToggleCallback(mobileOpen, setMobileOpen);

  const { _client } = useAppSelector((state) => state.client);

  const hideModules = useCallback(
    (arr: SidebarItemsType[]) => {
      const modifiedArr: SidebarItemsType[] = arr
        .filter(
          (page) =>
            !page.moduleName || _client.modules.includes(page.moduleName)
        )
        .map((page) => ({
          ...page,
          children: page?.children?.length
            ? hideModules(page.children)
            : page.children,
        }));

      return modifiedArr;
    },
    [_client]
  );

  const sidebarItems = useMemo(() => {
    return dashboardItems.map((item) => ({
      ...item,
      pages: hideModules(item.pages),
    }));
  }, [_client]);

  useEffect(() => {
    const params = prepareQueryParams("", {
      limit: String(MAX_NOTIFICATIONS_LIMIT),
    });
    getNotifications(params)
      .then((res) => res.json())
      .then(
        (
          notificationsServerResponse: ServerPaginatedResponse<BENotification[]>
        ) => {
          dispatchStore(setNotifications(notificationsServerResponse.data));
        }
      );
  }, []);

  const socketChannel = `user.${client}.${_user?.id}`;

  useEffect(() => {
    if (_user) {
      dispatchStore(
        connectSocket({
          query: {
            socket_channel: socketChannel,
          },
        })
      );
    } else dispatchStore(disconnectSocket());
    return () => {
      dispatchStore(disconnectSocket());
    };
  }, [_user]);

  /* istanbul ignore next */
  useEffect(() => {
    if (socket && _user) {
      socket.on(
        NOTIFICATIONS.CREATED,
        (...args: { data: BENotification }[]) => {
          dispatchStore(pushNotification(args[0].data));
        }
      );
      socket.on(
        NOTIFICATIONS.ARCHIVE_FOLDER,
        (...args: { data: BENotification }[]) => {
          dispatchStore(setArchive(prepareArchive(args[0].data)));
        }
      );
      socket.on(
        NOTIFICATIONS.IMPORT_VALIDATION,
        (...args: { data: BENotification }[]) => {
          dispatchStore(setImportValidationNotification(args[0].data));
        }
      );
      socket.on(
        NOTIFICATIONS.CHAT_MESSAGE_CREATED,
        (...args: { data: BENotification & ChatNotification }[]) => {
          emitCustomEvent(
            "TenentRequestChatMessageReceived",
            args[0].data.data.entity
          );
        }
      );
    }
  }, [socket, _user]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <SessionTimeoutDialog />
      <Drawer width={drawerWidth}>
        <Hidden lgUp implementation="js">
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={sidebarItems}
          />
        </Hidden>
        <Hidden mdDown implementation="css">
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={sidebarItems}
          />
        </Hidden>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent pl={1} pr={3} pb={1}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
        <NewsModal />
      </AppContent>
      <ArchiveFolder />
      <ImportValidation socketChannel={socketChannel} />
    </Root>
  );
};

export default Dashboard;
