import React, { ReactElement, useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconWrapper, LabelText } from "../../../../../invoices/show/styled";
import { CheckCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { formatTimestamp } from "../../../../../../../utils/date";
import Button from "../../../../../../../ui/button/Button";
import {
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  Measurement_Units,
  THOUSAND_SEPARATOR,
} from "../../../../../../../constants";
import { NumericFormat } from "react-number-format";
import { AreaEditContext } from "../../../AreaEditContext";
import { TenantHistory } from "../../../../../../../types/be/tenantHistory";
import Loader from "../../../../../../Loader";
/*istanbul ignore next*/
const ShowDetailsTable = (): ReactElement => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [tenantHistoryDetails, setTenantHistoryDetails] =
    useState<TenantHistory | null>(null);
  const areaEditContext = useContext(AreaEditContext);

  useEffect(() => {
    if (areaEditContext) {
      if (areaEditContext.tenantHistoryDetails) {
        setTenantHistoryDetails(areaEditContext.tenantHistoryDetails);
        setIsOpen(true);
      } else setIsOpen(false);
    } else {
      setIsOpen(false);
    }
  }, [areaEditContext?.tenantHistoryDetails]);

  if (!areaEditContext || !areaEditContext.closeShowTenantHistoryDetailsModal)
    return <Loader />;

  const { closeShowTenantHistoryDetailsModal } = areaEditContext;

  const handleClose = (): void => {
    closeShowTenantHistoryDetailsModal();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"lg"}
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="max-width-dialog-title"
      role="show-details-modal"
    >
      <DialogContent>
        <Grid container direction={"column"}>
          <Grid item>
            <Paper variant="outlined">
              <Grid container justifyContent={"center"}>
                <Grid item xs={12} md={4}>
                  {tenantHistoryDetails && (
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText role={"contact-label"}>
                              {t("contact")}
                            </LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                {tenantHistoryDetails.contact?.full_name ||
                                  EMPTY_DATA}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>{t("tenant.rent_start")}</LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                {formatTimestamp(
                                  Number(tenantHistoryDetails.rent_start)
                                ) || EMPTY_DATA}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>{t("tenant.rent_end")}</LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                {formatTimestamp(
                                  Number(tenantHistoryDetails.rent_end)
                                ) || EMPTY_DATA}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>
                              {t("tenant.others_incomings_eur")}
                            </LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <NumericFormat
                                  thousandSeparator={THOUSAND_SEPARATOR}
                                  decimalSeparator={DECIMAL_SEPARATOR}
                                  displayType={"text"}
                                  fixedDecimalScale
                                  value={
                                    tenantHistoryDetails.others_incomings_eur ||
                                    0
                                  }
                                />{" "}
                                {tenantHistoryDetails.others_incomings_eur !==
                                  0 && Measurement_Units.EURO}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        {/*<TableRow>*/}
                        {/*  <TableCell scope="row">*/}
                        {/*    <LabelText>*/}
                        {/*      {t("property.area.assetManager")}*/}
                        {/*    </LabelText>*/}
                        {/*  </TableCell>*/}
                        {/*  <TableCell align={"right"} scope="row">*/}
                        {/*    <Grid container justifyContent={"end"}>*/}
                        {/*      {" "}*/}
                        {/*      asset_manager??*/}
                        {/*      /!*<Grid item>*!/*/}
                        {/*      /!*  {tenantHistoryDetails.asset_manager || EMPTY_DATA}*!/*/}
                        {/*      /!*</Grid>*!/*/}
                        {/*    </Grid>*/}
                        {/*  </TableCell>*/}
                        {/*</TableRow>*/}
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>
                              {t("tenant.net_rent_per_month")}
                            </LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <NumericFormat
                                  thousandSeparator={THOUSAND_SEPARATOR}
                                  decimalSeparator={DECIMAL_SEPARATOR}
                                  displayType={"text"}
                                  fixedDecimalScale
                                  value={
                                    tenantHistoryDetails.net_rent_per_month
                                  }
                                />{" "}
                                {tenantHistoryDetails.net_rent_per_month !==
                                  null && Measurement_Units.EURO}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>{t("tenant.netRentPerM2")}</LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <NumericFormat
                                  thousandSeparator={THOUSAND_SEPARATOR}
                                  decimalSeparator={DECIMAL_SEPARATOR}
                                  displayType={"text"}
                                  fixedDecimalScale
                                  value={tenantHistoryDetails.net_rent_per_m2}
                                />{" "}
                                {tenantHistoryDetails.net_rent_per_m2 !==
                                  null && Measurement_Units.EURO}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>{t("tenant.new")}</LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <IconWrapper>
                                  {tenantHistoryDetails.is_ever_rented ? (
                                    <CheckCircleOutline
                                      color="success"
                                      fontSize="medium"
                                    />
                                  ) : (
                                    <RemoveCircleOutline
                                      color="error"
                                      fontSize="medium"
                                    />
                                  )}
                                </IconWrapper>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ borderBottom: "none" }}>
                          <TableCell scope="row">
                            <LabelText>{t("tenant.unlimited")}</LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <IconWrapper>
                                  {tenantHistoryDetails.is_unlimited ? (
                                    <CheckCircleOutline
                                      color="success"
                                      fontSize="medium"
                                    />
                                  ) : (
                                    <RemoveCircleOutline
                                      color="error"
                                      fontSize="medium"
                                    />
                                  )}
                                </IconWrapper>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>{t("tenant.options")}</LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <IconWrapper>
                                  {tenantHistoryDetails.is_opt_jn ? (
                                    <CheckCircleOutline
                                      color="success"
                                      fontSize="medium"
                                    />
                                  ) : (
                                    <RemoveCircleOutline
                                      color="error"
                                      fontSize="medium"
                                    />
                                  )}
                                </IconWrapper>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  {tenantHistoryDetails && (
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>{t("tenant.netRentPerYear")}</LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <NumericFormat
                                  thousandSeparator={THOUSAND_SEPARATOR}
                                  decimalSeparator={DECIMAL_SEPARATOR}
                                  displayType={"text"}
                                  fixedDecimalScale
                                  value={
                                    tenantHistoryDetails.net_rent_per_year || 0
                                  }
                                />{" "}
                                {tenantHistoryDetails.net_rent_per_year !== 0 &&
                                  Measurement_Units.EURO}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>{t("tenant.tax")}</LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <NumericFormat
                                  thousandSeparator={THOUSAND_SEPARATOR}
                                  decimalSeparator={DECIMAL_SEPARATOR}
                                  displayType={"text"}
                                  fixedDecimalScale
                                  value={tenantHistoryDetails.tax_in_eur}
                                />{" "}
                                {tenantHistoryDetails.net_rent_per_month !==
                                  0 && Measurement_Units.EURO}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>{t("tenant.netRentPerMonth")}</LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <NumericFormat
                                  thousandSeparator={THOUSAND_SEPARATOR}
                                  decimalSeparator={DECIMAL_SEPARATOR}
                                  displayType={"text"}
                                  fixedDecimalScale
                                  value={
                                    tenantHistoryDetails.total_gross_net_rent_per_month ||
                                    0
                                  }
                                />{" "}
                                {tenantHistoryDetails.total_gross_net_rent_per_month !==
                                  0 && Measurement_Units.EURO}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>
                              {t("tenant.total_gross_net_rent_per_year")}
                            </LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <NumericFormat
                                  thousandSeparator={THOUSAND_SEPARATOR}
                                  decimalSeparator={DECIMAL_SEPARATOR}
                                  displayType={"text"}
                                  fixedDecimalScale
                                  value={
                                    tenantHistoryDetails.total_gross_net_rent_per_year
                                  }
                                />{" "}
                                {tenantHistoryDetails.total_gross_net_rent_per_year !==
                                  0 && Measurement_Units.EURO}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>{t("tenant.remaining_term")}</LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                {tenantHistoryDetails.remaining_term ||
                                  EMPTY_DATA}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>
                              {t("tenant.remaining_term_in_eur")}
                            </LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <NumericFormat
                                  thousandSeparator={THOUSAND_SEPARATOR}
                                  decimalSeparator={DECIMAL_SEPARATOR}
                                  displayType={"text"}
                                  fixedDecimalScale
                                  value={
                                    tenantHistoryDetails.remaining_term_in_eur
                                  }
                                />{" "}
                                {tenantHistoryDetails.remaining_term_in_eur !==
                                  0 && Measurement_Units.EURO}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>
                              {t("tenant.cancellation_until")}
                            </LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                {formatTimestamp(
                                  Number(
                                    tenantHistoryDetails.cancellation_until
                                  )
                                ) || EMPTY_DATA}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>{t("tenant.index")}</LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <IconWrapper>
                                  {tenantHistoryDetails.is_idx_jn ? (
                                    <CheckCircleOutline
                                      color="success"
                                      fontSize="medium"
                                    />
                                  ) : (
                                    <RemoveCircleOutline
                                      color="error"
                                      fontSize="medium"
                                    />
                                  )}
                                </IconWrapper>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  {tenantHistoryDetails && (
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>
                              {t("tenant.special_cancellation_right")}
                            </LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                {formatTimestamp(
                                  Number(
                                    tenantHistoryDetails.special_cancellation_right
                                  )
                                ) || EMPTY_DATA}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>
                              {t("tenant.closing_tenant_contract")}
                            </LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                {formatTimestamp(
                                  Number(
                                    tenantHistoryDetails.closing_tenant_contract
                                  )
                                ) || EMPTY_DATA}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        {/*<TableRow>*/}
                        {/*  <TableCell scope="row">*/}
                        {/*    <LabelText>{t("tenant.tenantCategory")}</LabelText>*/}
                        {/*  </TableCell>*/}
                        {/*  <TableCell align={"right"} scope="row">*/}
                        {/*    <Grid container justifyContent={"end"}>*/}
                        {/*      <Grid item>*/}
                        {/*        tenant_category??*/}
                        {/*        /!*{tenantHistoryDetails.tenant_category || EMPTY_DATA}*!/*/}
                        {/*      </Grid>*/}
                        {/*    </Grid>*/}
                        {/*  </TableCell>*/}
                        {/*</TableRow>*/}
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>{t("tenant.deposit")}</LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <NumericFormat
                                  thousandSeparator={THOUSAND_SEPARATOR}
                                  decimalSeparator={DECIMAL_SEPARATOR}
                                  displayType={"text"}
                                  fixedDecimalScale
                                  value={tenantHistoryDetails.deposit}
                                />{" "}
                                {tenantHistoryDetails.deposit !== 0 &&
                                  Measurement_Units.EURO}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>
                              {t("property.area.costsForModification")}
                            </LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <NumericFormat
                                  thousandSeparator={THOUSAND_SEPARATOR}
                                  decimalSeparator={DECIMAL_SEPARATOR}
                                  displayType={"text"}
                                  fixedDecimalScale
                                  value={
                                    tenantHistoryDetails.costs_for_modification
                                  }
                                />{" "}
                                {tenantHistoryDetails.costs_for_modification !==
                                  0 && Measurement_Units.EURO}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>{t("property.area.art")}</LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                {tenantHistoryDetails.art || EMPTY_DATA}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>{t("tenant.parkingLots")}</LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                {tenantHistoryDetails.number_of_parking_spaces ||
                                  EMPTY_DATA}
                                <NumericFormat
                                  thousandSeparator={THOUSAND_SEPARATOR}
                                  decimalSeparator={DECIMAL_SEPARATOR}
                                  displayType={"text"}
                                  fixedDecimalScale
                                  value={
                                    tenantHistoryDetails.number_of_parking_spaces
                                  }
                                />
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>
                              {t("property.area.rentInEurPerParkingSpace")}
                            </LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <NumericFormat
                                  thousandSeparator={THOUSAND_SEPARATOR}
                                  decimalSeparator={DECIMAL_SEPARATOR}
                                  displayType={"text"}
                                  fixedDecimalScale
                                  value={
                                    tenantHistoryDetails.rent_in_eur_per_parking_space ||
                                    0
                                  }
                                />{" "}
                                {tenantHistoryDetails.rent_in_eur_per_parking_space !==
                                  null && Measurement_Units.EURO}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell scope="row">
                            <LabelText>{t("tenant.is_cancelled")}</LabelText>
                          </TableCell>
                          <TableCell align={"right"} scope="row">
                            <Grid container justifyContent={"end"}>
                              <Grid item>
                                <IconWrapper>
                                  {tenantHistoryDetails.is_cancelled ? (
                                    <CheckCircleOutline
                                      color="success"
                                      fontSize="medium"
                                    />
                                  ) : (
                                    <RemoveCircleOutline
                                      color="error"
                                      fontSize="medium"
                                    />
                                  )}
                                </IconWrapper>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item sx={{ display: "flex", justifyContent: "end" }} mt={3}>
            <Button
              //role={"close-details-modal-button"}
              testId="close-details-modal-button"
              title={t("close")}
              onClick={() => handleClose()}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ShowDetailsTable;
