import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Grid, TableCell, Tooltip } from "@mui/material";
import { route } from "../../../../utils/url";
import Table from "../../../../ui/table/Table";
import useTable from "../../../../ui/table/useTable";
import { itemsListHeadCells } from "./utils";
import { MaintenanceUnit } from "../../../../types/be/maintenanceUnit";
import Button from "../../../../ui/button/Button";
import { DescriptionWrapper } from "../list/styled";
import { DeleteIcon } from "../../../../ui/table/styled";
import { prepareQueryParams } from "../../../../utils/common";
import {
  deleteMaintenanceUnit,
  getMaintenanceUnits,
} from "../../../../api/maintenance";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { handleServerError } from "../../../../utils/http";
import Loader from "../../../Loader";
import { useFetch } from "../../../../hooks/useFetch";
import { Property } from "../../../../types/be/property";
import { getPropertyShort } from "../../../../api/property";
import CreateUnitDialog from "./createUnitDialog";
import useSnackbar from "../../../../ui/snackbar1/useSnackbar";
import { PermissionsCreate, PermissionsDelete } from "../../../../constants";
import useAccessControl from "../../../../hooks/useAccessControl";
import TabPageWrapper from "../../../../ui/tabPageWrapper";

const MaintenanceUnits = (): ReactElement => {
  const { t } = useTranslation();
  const { id, maintenanceId } = useParams();
  const { Snackbar, snackbar } = useSnackbar();
  const { can } = useAccessControl();

  const {
    run: getPropertyDetails,
    data: propertyData,
    isLoading: isPropertyLoading,
  } = useFetch<Property>();

  const [isCreateUnitDialogOpen, setIsCreateUnitDialogOpen] = useState(false);

  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleSortChange,
    handleSelectAllClick,
    setTotal,
    openConfirmToRemoveModal,
    rowToDelete,
    handleDeleteLastPageData,
    handleConfirmToRemoveModalClose,
  } = useTable<MaintenanceUnit>();

  const handleFetchList = useCallback(
    async (loading = true) => {
      setIsLoading(loading);
      const params = prepareQueryParams(queryParams, {
        maintenance_id: maintenanceId,
      });
      const response = await getMaintenanceUnits(params);

      if (response.status !== HTTP_STATUS_CODES.OK) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        const json = await response.json();
        setData(json?.data);
        setTotal(json?.meta?.total);
      }

      setIsLoading(false);
    },
    [queryParams, id]
  );

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteMaintenanceUnit(rowToDelete.id);
    handleDeleteLastPageData(handleFetchList);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteMaintenanceUnit,
    handleFetchList,
    handleConfirmToRemoveModalClose,
  ]);

  const afterCreateUnit = (): void => {
    setIsCreateUnitDialogOpen(false);
    handleFetchList();
  };

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: route("maintenance"),
      name: t("property.maintenance.title"),
    },
  ];

  const TableToolbar = (
    <>
      {can(PermissionsCreate.MAINTENANCE) && (
        <Grid container sx={{ pt: 5 }} spacing={2}>
          <Grid item>
            <Button
              title={t("create")}
              color="success"
              onClick={() => setIsCreateUnitDialogOpen(true)}
            />
          </Grid>
        </Grid>
      )}
    </>
  );

  const renderRow = (row: MaintenanceUnit): ReactElement => (
    <>
      <TableCell align="left">{row?.name}</TableCell>
      <TableCell align="left">
        <Tooltip title={row?.description} placement="top">
          <DescriptionWrapper>{row?.description}</DescriptionWrapper>
        </Tooltip>
      </TableCell>
      <TableCell align="left">{row?.resolved ? t("yes") : t("no")}</TableCell>
      <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
        {can(PermissionsDelete.MAINTENANCE) && (
          <DeleteIcon
            role="DeleteIconRole"
            size={18}
            onClick={() => openConfirmToRemoveModal(row)}
          />
        )}
      </TableCell>
    </>
  );

  useEffect(() => {
    id && queryParams && handleFetchList();
  }, [id, queryParams]);

  useEffect(() => {
    id && getPropertyDetails(getPropertyShort(id));
  }, [id]);

  if (isPropertyLoading && !propertyData) {
    return <Loader />;
  }

  return (
    <TabPageWrapper
      title={t("property.maintenance.units")}
      breadcrumbs={breadcrumbs}
      wrap={!id}
    >
      <>
        <Table
          data={data}
          total={total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={itemsListHeadCells}
          renderRow={renderRow}
          tableToolbar={TableToolbar}
          onRowRemove={handleRowRemove}
        />
        {isCreateUnitDialogOpen ? (
          <CreateUnitDialog
            open={isCreateUnitDialogOpen}
            onClose={() => setIsCreateUnitDialogOpen(false)}
            snackbar={snackbar}
            afterSuccess={afterCreateUnit}
          />
        ) : null}
        {Snackbar}
      </>
    </TabPageWrapper>
  );
};

export default MaintenanceUnits;
