import React, { ReactElement } from "react";
import { MobileUserListRowProps } from "./types";
import { TableCell } from "../../../../../styled";
import { formatTimestamp } from "../../../../../../../utils/date";
import { DeleteIcon, ResendIcon } from "../../../../../../../ui/table/styled";
import { handelResendCredential } from "./utils";
import { useTranslation } from "react-i18next";
import { EMPTY_DATA } from "../../../../../../../constants";

/* istanbul ignore next */
const MobileUserListRow = ({
  row,
  openConfirmToRemoveModal,
  snackbar,
  fetchMobileUser,
}: MobileUserListRowProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <TableCell align="left" mw="100px">
        {row.first_name ? `${row.first_name} ` : ""}{" "}
        {row.last_name ? `${row.last_name} ` : ""}
      </TableCell>
      <TableCell align="left">
        {row.email ? `${row.email} ` : EMPTY_DATA}
      </TableCell>
      <TableCell align="left" padding="none">
        {row?.send_credential_history
          ? formatTimestamp(row?.send_credential_history?.created_at)
          : EMPTY_DATA}
      </TableCell>
      <TableCell align="right" mw="70px">
        <ResendIcon
          size={20}
          onClick={() =>
            handelResendCredential({ row, snackbar, t, fetchMobileUser })
          }
        />
        <DeleteIcon
          onClick={(): void => openConfirmToRemoveModal(row)}
          size={20}
        />
      </TableCell>
    </>
  );
};

export default MobileUserListRow;
