import React, { ReactElement } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import Form from "./Form";
import { debtorInitState, handleCreateDebtor } from "./utils";
import useSnackbar from "../../../../../../ui/snackbar1/useSnackbar";
import { useNavigate, useParams } from "react-router-dom";
import useValidation from "../../../../../../hooks/useValidation";
import * as Yup from "yup";
import { Paper } from "../../creditor/create/styled";

const CreateDebtor = (): ReactElement => {
  const { t } = useTranslation();
  const { Snackbar, snackbar } = useSnackbar();
  const navigate = useNavigate();
  const { debtor } = useValidation();
  const { id } = useParams();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Paper sx={{ p: 5 }}>
          <Grid item>
            <Formik
              initialValues={{ ...debtorInitState, property_id: id! }}
              onSubmit={handleCreateDebtor({ snackbar, navigate, t, id })}
              validationSchema={Yup.object().shape(debtor)}
            >
              {(props) => <Form {...props} />}
            </Formik>
          </Grid>
        </Paper>
        {Snackbar}
      </Grid>
    </>
  );
};

export default CreateDebtor;
