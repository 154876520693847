import styled from "styled-components/macro";
import {
  Table as MuiTable,
  TableCell as MuiTableCell,
  Box as MuiBox,
} from "@mui/material";
/* istanbul ignore next */
export const InfoTable = styled(MuiTable)`
  border: ${(props): string => props.theme.spacing(0.25)} solid
    ${(props): string => props.theme.table.borderColor};
`;
/* istanbul ignore next */
export const TableHead = styled(MuiTableCell)`
  border-right: ${(props): string => props.theme.spacing(0.25)} solid
    ${(props): string => props.theme.table.borderColor};
  width: 25%;
`;
/* istanbul ignore next */
export const ProviderInfoTableWrapper = styled(MuiBox)`
  padding-right: ${(props): string => props.theme.spacing(5)};
  padding-left: ${(props): string => props.theme.spacing(5)};
  margin-bottom: ${(props): string => props.theme.spacing(0.5)};
`;
