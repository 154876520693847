import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button, Dialog, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useTable from "../../../../../../../ui/table/useTable";
import {
  SnackbarMessage,
  TenantHistoryProps,
  TenantHistoryTableData,
} from "./types";
import { fetchTenantHistory } from "../../../../../../../api/tenantHistory";
import { handleServerError } from "../../../../../../../utils/http";
import Table from "../../../../../../../ui/table/Table";
import {
  itemsListHeadCells,
  sendCredentialsSnackbarInit,
  tableContainerQuerySelector,
} from "./utils";
import ExtendDialog from "./Extend";
import TenantHistoryTableRow from "./row";
import RentalContract from "../../rentedForm";
import DialogTitle from "../../../../../../../ui/dialog/DialogTitle";
import Snackbar from "../../../../../../../ui/Snackbar";
import { HTTP_STATUS_CODES } from "../../../../../../../types/server";
import { AreaEditContext } from "../../../AreaEditContext";
import Loader from "../../../../../../Loader";
import ShowDetailsTable from "../showDetails";
import { DialogContent } from "./styled";
import { deletePropertyTenant } from "../../../../../../../api/propertyTenant";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../../constants";
import UpdateFutureDialog from "./FutureTenantUpdate.tsx";
/*istanbul ignore next*/
const TenantHistory = (): ReactElement => {
  const { t } = useTranslation();
  const { areaId } = useParams();
  const areaEditContext = useContext(AreaEditContext);
  const [extendModal, setExtendModal] = useState(false);
  const [updateFutureTenant, setUpdateFutureTenant] = useState(false);
  const [rowData, setRowData] = useState<TenantHistoryProps | null>(null);
  const [showFutureTenantModal, setShowFutureTenantModal] = useState(false);
  const [sendCredentialsSnackbar, setSendCredentialsSnackbar] =
    useState<SnackbarMessage>(sendCredentialsSnackbarInit);

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    rowToDelete,
    openConfirmToRemoveModal,
    handleDeleteLastPageData,
  } = useTable<TenantHistoryTableData>({ loading: true });

  const handleFetchTenantHistory = useCallback(async () => {
    setIsLoading(true);
    const response = await fetchTenantHistory(areaId!, `${queryParams}`);
    const { data, meta } = await response.json();
    if (response.status === HTTP_STATUS_CODES.OK) {
      setData(data);
      setTotal(meta?.total);
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }
    setIsLoading(false);
  }, [queryParams]);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    const response = await deletePropertyTenant(rowToDelete.id);
    if (response.status === HTTP_STATUS_CODES.OK) {
      handleDeleteLastPageData(handleFetchTenantHistory);
      handleConfirmToRemoveModalClose();
    } else {
      setSendCredentialsSnackbar({
        text: t(SOMETHING_WENT_WRONG_ERROR),
        visible: true,
        color: "error",
      });
    }
  }, [rowToDelete, deletePropertyTenant, handleConfirmToRemoveModalClose]);

  useEffect(() => {
    if (!queryParams) return;
    handleFetchTenantHistory();
  }, [queryParams]);

  useEffect(() => {
    areaEditContext && areaEditContext.fetchArea && areaEditContext.fetchArea();
  }, []);

  if (!areaEditContext || !areaEditContext.area || !areaEditContext.fetchArea)
    return <Loader />;

  const { area, fetchArea } = areaEditContext;

  const handleHideFutureTenantModal = (): void => {
    return setShowFutureTenantModal(false);
  };
  const handleShowFutureTenantModal = (): void => {
    return setShowFutureTenantModal(true);
  };

  const handleShowUpdateFutureTenantModal = (row: TenantHistoryProps): void => {
    setUpdateFutureTenant(true);
    setRowData(row);
  };
  const handleCloseUpdateFutureTenantModal = (): void => {
    setUpdateFutureTenant(false);
  };

  const handleCloseFutureTenantModal = (refresh: boolean): void => {
    refresh && fetchData();
    handleHideFutureTenantModal();
  };
  const handleCloseEditFutureTenantModal = (refresh: boolean): void => {
    refresh && fetchData();
    handleCloseUpdateFutureTenantModal();
  };

  const handleSubmitFutureTenant = (): void => {
    handleCloseFutureTenantModal(true);
    setSendCredentialsSnackbar({
      text: t("tenant.history.success"),
      visible: true,
      color: "success",
    });
  };

  const updateFutureTenantSuccessCallback = (): void => {
    handleCloseEditFutureTenantModal(true);
    setSendCredentialsSnackbar({
      text: t("tenant.history.updateSuccess"),
      visible: true,
      color: "success",
    });
  };
  const handleCloseSnackbar = (): void =>
    setSendCredentialsSnackbar(sendCredentialsSnackbarInit);

  const TableToolbar = (
    <Grid container mt={5} flexDirection={"column"}>
      {!area?.future_tenant && (
        <Grid item xs={12} lg={4} xl={4}>
          <Button
            role={"add-future-tenant-button"}
            onClick={handleShowFutureTenantModal}
            size="medium"
            color="primary"
            variant="contained"
          >
            {t("tenant.history.addFutureTenant")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
  const handleExtendClick = (row: any): void => {
    setExtendModal(true);
    setRowData(row);
  };

  const fetchData = (): void => {
    handleFetchTenantHistory();
    fetchArea();
  };

  const handleCloseModal =
    (refresh = false) =>
    (): void => {
      const tableContainer = document?.querySelector(
        tableContainerQuerySelector
      );
      tableContainer?.scrollTo && tableContainer.scrollTo(0, 0);
      refresh && fetchData();
      setExtendModal(false);
    };

  const renderRow = (row: TenantHistoryProps): ReactElement => (
    <TenantHistoryTableRow
      row={row}
      handleExtendClick={handleExtendClick}
      refetchData={fetchData}
      openConfirmToRemoveModal={openConfirmToRemoveModal}
      handleShowUpdateFutureTenantModal={handleShowUpdateFutureTenantModal}
    />
  );

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        onRowRemove={handleRowRemove}
        hasCustomRow
      />
      {area && (
        <>
          <Dialog
            open={showFutureTenantModal}
            aria-labelledby="max-width-dialog-title"
            role="add-future-tenant-modal"
            onClose={handleHideFutureTenantModal}
            fullWidth
            maxWidth="lg"
          >
            <DialogTitle
              title="tenant.history.addFutureTenant"
              onClose={handleHideFutureTenantModal}
            />
            <DialogContent>
              <RentalContract
                submitCallback={handleSubmitFutureTenant}
                isFutureTenant={true}
              />
            </DialogContent>
          </Dialog>
          {rowData && (
            <ExtendDialog
              initialValues={rowData}
              extendModal={extendModal}
              handleCloseModal={handleCloseModal}
            />
          )}
          {rowData && (
            <UpdateFutureDialog
              initialValues={rowData}
              uploadFutureModal={updateFutureTenant}
              handleCloseModal={handleCloseUpdateFutureTenantModal}
              isFutureTenant={true}
              submitCallback={updateFutureTenantSuccessCallback}
            />
          )}
          <ShowDetailsTable />
        </>
      )}
      <Snackbar
        data-testid={"send-credentials-snackbar"}
        message={sendCredentialsSnackbar.text}
        color={sendCredentialsSnackbar.color}
        open={sendCredentialsSnackbar.visible}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

export default TenantHistory;
