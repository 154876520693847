import { TableCell } from "../../types/table";

export enum VACATION_REQUESTS_TABS {
  VACATION_LIST = "vacation-list",
  APPROVAL_PROCESS = "approval-process",
  MANAGING_VACATION_LENGTHS = "managing-vacation-lengths",
}

export const itemsListHeadCells: TableCell[] = [
  {
    id: "full_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "companyOwnership.user",
  },
  {
    id: `from`,
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "from",
    align: "center",
  },
  {
    id: "to",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "to",
    align: "center",
  },
  {
    id: "vacation_request_status_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "requests.status",
  },
  {
    id: "days_remaining",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "vacationRequests.daysRemaining",
    align: "right",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];
