import { FormikValues } from "formik";
import { CreateBKADialogState, FormSubmitHandlerProps } from "./types";
import { HTTP_STATUS_CODES } from "../../../../../../../types/server";
import { createBKA, multiCostBKA } from "../../../../../../../api/bka";
import { mapBackendValidationErrors } from "../../../../../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../../constants";
import {
  constructFormData,
  parseNumber,
} from "../../../../../../../utils/common";
import { formatDateToTimespamp } from "../../../../../../../utils/date";

export const initialValues: CreateBKADialogState = {
  date: null,
  date_bka: null,
  amount: "",
  file: null,
  year: "",
  costs: [],
};

export const handleCreateCosts = async ({
  values,
  setStatus,
  afterCreateBKA,
  setFormMessage,
  setSubmitting,
}: FormikValues): Promise<void> => {
  const response = await multiCostBKA(values);
  const json = await response.json();

  if (
    [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(response.status)
  ) {
    setStatus({
      success: true,
      errors: {},
    });
    afterCreateBKA && (await afterCreateBKA());
  } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
    setStatus({
      success: false,
      errors: mapBackendValidationErrors(json.errors),
    });
  } else {
    setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
  }
  setSubmitting(false);
};

export const handleCreateBKA = ({
  setFormMessage,
  property_tenant_id,
  afterCreateBKA,
}: FormSubmitHandlerProps) => {
  return async (
    values: CreateBKADialogState,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const bkaData = {
      file: values?.file,
      amount: parseNumber(values?.amount),
      year: values?.year,
      property_tenant_id,
      date: values.date ? formatDateToTimespamp(values.date) : null,
      date_bka: values.date_bka ? formatDateToTimespamp(values.date_bka) : null,
    };
    const formData = constructFormData(bkaData);
    const response = await createBKA(formData);
    const json = await response.json();

    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      if (values?.costs?.length) {
        const costsData = {
          bka_id: json?.data?.id,
          costs: values?.costs?.map((cost) => ({
            type_id: cost?.type_id?.id,
            amount: parseNumber(cost?.amount),
          })),
        };
        await handleCreateCosts({
          values: costsData,
          setStatus,
          afterCreateBKA,
          setFormMessage,
          setSubmitting,
        });
      } else {
        setStatus({
          success: true,
          errors: {},
        });
        afterCreateBKA && (await afterCreateBKA());
      }
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
    }
    setSubmitting(false);
  };
};

export const getYearOption = (): number[] => {
  const year = new Date().getFullYear();
  const options = [];
  for (let i = year - 20; i <= year + 5; i++) {
    options.push(i);
  }
  return options;
};

export const onFileDrop = ({ setFieldValue }: FormikValues, name: string) => {
  return <T extends File>(files: FileList | T[] | null): void => {
    setFieldValue(name, files?.[0]);
  };
};
