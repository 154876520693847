import React, { ReactElement, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Form from "./index";
import { PropertyManagementFormWrapperProps } from "../types";
import useValidation from "../../../../../../hooks/useValidation";
import FormAlert from "../../../../../../ui/formAlert/FormAlert";
import { FormWrapper } from "../../../../../../ui/formWrapper/styled";

const PropertyManagementFormWrapper = ({
  edit,
  formMessage,
  initialValues,
  onSubmit,
  caretakers,
  companies,
  users,
  setShowSuccess,
  handleCloseDialog,
  onCancel,
}: PropertyManagementFormWrapperProps): ReactElement => {
  const [startContract, setStartContract] = useState("");
  const { company, property_management_cost, contract_end } = useValidation();

  return (
    <FormWrapper $fullwidth>
      {formMessage.text && <FormAlert formMessage={formMessage} />}
      {initialValues && (
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            company: company.required,
            property_management_cost,
            contract_end: startContract
              ? contract_end.required
              : contract_end.optional,
          })}
          onSubmit={onSubmit}
        >
          {(props): React.ReactElement => (
            <Form
              caretakers={caretakers}
              companies={companies}
              users={users}
              edit={Boolean(edit)}
              setShowSuccess={setShowSuccess}
              handleCloseDialog={handleCloseDialog}
              onCancel={onCancel}
              setStartContract={setStartContract}
              {...props}
            />
          )}
        </Formik>
      )}
    </FormWrapper>
  );
};

export default PropertyManagementFormWrapper;
