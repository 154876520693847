import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { FormikValues } from "formik/dist/types";
import { getOffers } from "../../../../api/offer";
import { offerSearchTypeProps } from "./types";
import { useTranslation } from "react-i18next";
import { Offer } from "../../../../types/be/offer";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import debounce from "lodash/debounce";
import { prepareQueryParams } from "../../../../utils/common";
import { PAGE_LIMIT, SEARCH_PARAM_MIN_CHARACTERS } from "../../../../constants";
import { StyledPaper } from "../../../contacts/autocomplete/styled";

/* istanbul ignore next */
const OfferAutoCompleteField = ({
  values,
  status,
  errors,
  setFieldValue,
  propertyId,
  frStatusId,
  defaultValue,
}: FormikValues & offerSearchTypeProps): ReactElement => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<Offer[]>([]);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<Offer[]>([]);
  const [optionLabel] = React.useState<string>("name");
  const [optionValue] = React.useState<string>("id");
  const [searchOptionsLoading, setSearchOptionsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Offer | null>(
    defaultValue ? defaultValue : null
  );

  const handleInputChange = useCallback(
    async (search: string) => {
      const params = {
        limit: PAGE_LIMIT._10,
        search: search ? search : undefined,
        fr_status_id: frStatusId ? frStatusId : undefined,
        property_id: propertyId === "0" ? undefined : propertyId,
      };
      const response = await getOffers(
        prepareQueryParams("", params, propertyId === "0")
      );
      const { data } = await response.json();
      const returnData = data.map((res: Offer) => {
        return {
          ...res,
          name: res?.file?.name,
        };
      });

      return returnData;
    },
    [propertyId, frStatusId]
  );

  useEffect(() => {
    handleInputChange("").then((res) => {
      setData(res);
    });
  }, [propertyId]);

  const resetOptions = useCallback((): void => {
    setOptions(data);
  }, [data]);

  useEffect(() => {
    if (!data) return;
    setOptions(data);
  }, [data]);

  useEffect(() => {
    if (!open) resetOptions();
  }, [open]);

  const onSearchFieldTextChange = React.useCallback(
    debounce(async (search: string): Promise<void> => {
      if (search.length < SEARCH_PARAM_MIN_CHARACTERS) return resetOptions();
      try {
        setSearchOptionsLoading(true);
        const data = await handleInputChange(search);
        setOptions(data);
        setSearchOptionsLoading(false);
      } catch (e) {
        resetOptions();
      }
    }, 0),
    [resetOptions, propertyId]
  );
  const isLoading = searchOptionsLoading;

  return (
    <Autocomplete
      open={open}
      value={selectedOption}
      loading={isLoading}
      options={options || []}
      onOpen={(): void => setOpen(true)}
      onClose={(): void => setOpen(false)}
      data-testid="search-offer"
      getOptionLabel={(option) => `${option?.[optionLabel as keyof Offer]}`}
      onChange={(_, value) => {
        setFieldValue("offer_id", value?.id);
        setSelectedOption(value);
      }}
      isOptionEqualToValue={(option, value) =>
        option?.[optionValue as keyof Offer] ===
        value?.[optionValue as keyof Offer]
      }
      renderOption={(props, option) => (
        <li {...props}>{option?.[optionLabel as keyof Offer]}</li>
      )}
      sx={{ minHeight: "0" }}
      componentsProps={{
        clearIndicator: {
          onClick: () => {
            setSelectedOption(null);
            setFieldValue("offer_id", null);
          },
        },
      }}
      PaperComponent={StyledPaper}
      renderInput={(params) => (
        <TextField
          {...params}
          data-testid="search-offer"
          name="offer_id"
          label={t("documentRelease.invoice.offer")}
          value={values?.offer_id || ""}
          placeholder={t("documentRelease.invoice.SelectOffer")}
          fullWidth
          error={Boolean(status?.errors?.offer_id || errors?.offer_id)}
          helperText={errors?.offer_id || status?.errors?.offer_id}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          onChange={(e): Promise<void> | undefined =>
            onSearchFieldTextChange(e.target.value)
          }
        />
      )}
    />
  );
};

export default OfferAutoCompleteField;
