import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import Form from "../Form";
import { Box, Grid, Paper } from "@mui/material";
import {
  CURRENCIES,
  FormMessageErrorState,
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
  SUBMIT_STATUS,
} from "../../../../constants";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import useValidation from "../../../../hooks/useValidation";
import * as Yup from "yup";
import {
  createOutgoingInvoiceInitState,
  handleCreateOrEditOutgoingInvoiceFormSubmit,
} from "../utils";
import { FormMessageInterface } from "../../../../types/form";
import { useTranslation } from "react-i18next";
import { route } from "../../../../utils/url";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../../hooks/useFetch";
import { ErrorBox } from "../../invoices/styled";
import Loader from "../../../Loader";
import { InvoicePeriod } from "../../../../types/be/invoice";
import { getInvoicePeriod } from "../../../../api/invoices";
import { getFileOCR } from "../../../../api/files";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { sendCredentialsSnackbarInitState } from "../../invoices/utils";
import { SnackbarMessage } from "../../invoices/types";
import { OutgoingInvoice } from "../../../../types/fe/outgoingInvoice";
import Snackbar from "../../../../ui/Snackbar";
import TabPageWrapper from "../../../../ui/tabPageWrapper";
import FileTreeDialog from "../../../../ui/fileList";
import PdfViewerWrapper from "../../components/PdfViewerWrapper";
import useUploadDropzone from "../../../../hooks/useUploadDropzone";
import { LoaderContainer } from "../../invoices/edit/styled";
import { handleServerError } from "../../../../utils/http";

/* istanbul ignore next */
const OutgoingInvoiceCreate = (): ReactElement => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<OutgoingInvoice> | null>(null);
  const pdfContainerRef = useRef<HTMLObjectElement | null>(null);
  const [submitAction, setSubmitAction] = useState<string | null>(null);
  const [prevImage, setPrevImage] = useState<string | null>(null);
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const [sendCredentialsSnackbar, setSendCredentialsSnackbar] =
    useState<SnackbarMessage>(sendCredentialsSnackbarInitState);
  useState<SnackbarMessage>(sendCredentialsSnackbarInitState);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [loaderContainerVisible, setLoaderContainerVisible] = useState(false);

  const {
    number,
    invoice_date: date,
    invoice_receiver,
    apportionable_percent,
    property_id,
    currency_code,
    repeat_from,
    repeat_to,
    repeat_day,
    repetition_period_id,
    services,
    serviceDateFrom,
    serviceDateTo,
    notice,
  } = useValidation();

  const {
    setIsOcrLoading,
    isFileTreeVisible,
    isOcrLoading,
    handleOpenFileTree,
    handleCloseFileTree,
    fileName,
    setFileName,
    selectedFileId,
    setSelectedFileId,
  } = useUploadDropzone();

  const {
    run: runInvoicePeriod,
    data: invoicePeriod,
    isLoading: isInvoicePeriodLoading,
    isError: isInvoicePeriodError,
  } = useFetch<InvoicePeriod[]>();

  useEffect(() => {
    runInvoicePeriod(getInvoicePeriod());
  }, []);

  useEffect(() => {
    setLoaderContainerVisible(isFileLoading || isOcrLoading);
  }, [isFileLoading, isOcrLoading]);

  const handleCloseSnackbar = (): void =>
    setSendCredentialsSnackbar(sendCredentialsSnackbarInitState);

  const handleOsc = async (fileId: number): Promise<void> => {
    setIsOcrLoading(true);
    try {
      const res = await getFileOCR(fileId);
      const data = await res.json();
      if (res.status === HTTP_STATUS_CODES.OK) {
        const {
          data: { invoice_number: number, date, currency_code },
        } = data;

        formRef?.current?.setValues({
          ...formRef?.current.values,
          number: number ? number : null,
          date: date ? new Date(date) : null,
          currency_code: CURRENCIES.includes(currency_code)
            ? currency_code
            : "",
        });
        setSelectedFileId(fileId);
      } else {
        const { errorMessage } = handleServerError(res);
        formRef?.current?.setFieldError("file_id", errorMessage);
        console.log(errorMessage);
      }
    } catch (error) {
      formRef?.current?.setFieldError("file_id", SOMETHING_WENT_WRONG_ERROR);
      console.log(error);
    } finally {
      setIsOcrLoading(false);
    }
  };

  const handleFileTreeClick = (file_id: string, file_name: string): void => {
    setPrevImage(null);
    setFileName(file_name);
    handleCloseFileTree();
    handleOsc(Number(file_id));
  };

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: route("outgoing-invoices"),
      name: t("property.navLinks.outgoingInvoices"),
    },
  ];

  if (isInvoicePeriodError)
    return <ErrorBox formMessage={FormMessageErrorState} />;

  if (isInvoicePeriodLoading) return <Loader />;

  return (
    <>
      {invoicePeriod && (
        <TabPageWrapper
          title={t("create")}
          breadcrumbs={breadcrumbs}
          wrap={!id}
        >
          <Paper sx={{ p: 5, position: "relative" }}>
            {loaderContainerVisible && (
              <LoaderContainer>
                <Box>
                  <Loader />
                </Box>
              </LoaderContainer>
            )}
            <Grid container justifyContent={"center"}>
              {selectedFileId && (
                <Grid item xs={6} ref={pdfContainerRef}>
                  <PdfViewerWrapper data={selectedFileId} type="fileId" />
                </Grid>
              )}
              {!selectedFileId && prevImage && (
                <Grid item xs={6} ref={pdfContainerRef}>
                  <PdfViewerWrapper data={prevImage} type="url" />
                </Grid>
              )}
              <Grid item xs={6} pl={6}>
                {formMessage.text && (
                  <FormAlert
                    formMessage={formMessage}
                    sx={{ marginBottom: "10px" }}
                  />
                )}
                <Formik
                  initialValues={{
                    ...createOutgoingInvoiceInitState,
                    property_id: id || "",
                  }}
                  validationSchema={
                    submitAction === SUBMIT_STATUS.PREVIEW
                      ? null
                      : Yup.object().shape({
                          number,
                          date,
                          contact_id: invoice_receiver,
                          repeat_from,
                          repeat_to,
                          repeat_day,
                          repetition_period_id,
                          apportionable_percent,
                          property_id,
                          currency_code,
                          services,
                          date_from: serviceDateFrom,
                          date_to: serviceDateTo,
                          notice,
                        })
                  }
                  innerRef={formRef}
                  onSubmit={handleCreateOrEditOutgoingInvoiceFormSubmit({
                    id,
                    submitAction,
                    setFormMessage,
                    setPrevImage,
                    setSelectedFileId,
                    navigate,
                  })}
                >
                  {(props): ReactElement => (
                    <Form
                      {...props}
                      propertyId={id}
                      submitAction={submitAction}
                      setSubmitAction={setSubmitAction}
                      invoicePeriod={invoicePeriod}
                      handleOpenDialog={handleOpenFileTree}
                      selectedFileId={selectedFileId}
                      fileName={fileName}
                      setFileName={setFileName}
                      handleOsc={handleOsc}
                      setIsFileLoading={setIsFileLoading}
                    />
                  )}
                </Formik>
                {isFileTreeVisible && (
                  <FileTreeDialog
                    onClose={handleCloseFileTree}
                    handleFileTreeClick={handleFileTreeClick}
                    extensions={["pdf"]}
                  />
                )}
              </Grid>
            </Grid>
            <Snackbar
              data-testid={"upload-file-snackbar"}
              message={sendCredentialsSnackbar.text}
              color={sendCredentialsSnackbar.color}
              open={sendCredentialsSnackbar.visible}
              handleClose={handleCloseSnackbar}
            />
          </Paper>
        </TabPageWrapper>
      )}
    </>
  );
};

export default OutgoingInvoiceCreate;
