import React, { ReactElement, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { handleCreate, reminderCreateInitialValues } from "./utils";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import useValidation from "../../../../../hooks/useValidation";
import { route } from "../../../../../utils/url";
import { Paper } from "../../../../inbox/styled";
import { getReleaseRoute } from "../../../utils";
import PdfViewer from "../../../../../ui/pdfViewer";
import Form from "./Form";
import useSnackbar from "../../../../../ui/snackbar1/useSnackbar";
import TabPageWrapper from "../../../../../ui/tabPageWrapper";

const ReminderCreate = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Snackbar, snackbar } = useSnackbar();
  const { pdfFile, property_id } = useValidation();
  const { id = "" } = useParams();
  const ref = useRef<HTMLObjectElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File>();

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("invoices", Number(id)),
      name: t("property.navLinks.invoices"),
    },
    {
      to: getReleaseRoute("invoices.reminders", Number(id)),
      name: t("property.navLinks.reminders"),
    },
  ];

  return (
    <TabPageWrapper title={t("create")} breadcrumbs={breadcrumbs} wrap={!id}>
      <Paper>
        <Grid container justifyContent={"center"}>
          <>
            <Grid item md={3.7} xs={12} ref={ref}>
              <PdfViewer
                file={{
                  url: selectedFile ? URL.createObjectURL(selectedFile) : "",
                }}
                containerRef={ref}
              />
            </Grid>

            <Grid item md={1} xs={12}></Grid>
          </>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Formik
              initialValues={{
                ...reminderCreateInitialValues,
                property_id: id,
              }}
              onSubmit={handleCreate({
                snackbar,
                t,
                navigate,
                property_id: id,
              })}
              validationSchema={Yup.object().shape({
                file: pdfFile,
                property_id,
              })}
            >
              {(props) => <Form {...props} setSelectedFile={setSelectedFile} />}
            </Formik>
          </Grid>
          {Snackbar}
        </Grid>
      </Paper>
    </TabPageWrapper>
  );
};

export default ReminderCreate;
