import React, { ReactElement } from "react";
import { DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import { ListDialogProps } from "./types";
import CloseButton from "../../ui/dialog/CloseButton";
import {
  DialogContainWrapper,
  DialogContent,
  DialogTitleContainer,
} from "./styled";
import InvoiceList from "../properties/invoices/list/InvoiceList";

const InvoiceListDialog = ({
  visible,
  toggleListModal,
}: ListDialogProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Dialog
      role={"invoices-list-modal"}
      fullWidth
      open={visible}
      onClose={toggleListModal}
      maxWidth="xl"
    >
      <DialogTitle sx={{ pt: 0, pb: 0 }}>
        <DialogTitleContainer>
          <Typography
            variant="h6"
            component="div"
            justifyContent="space-around"
            width="100%"
          >
            {t("home.allInvoices")}
          </Typography>
          <CloseButton onClick={toggleListModal} />
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        <div>
          <DialogContainWrapper>
            <InvoiceList useOnWidget={true} />
          </DialogContainWrapper>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InvoiceListDialog;
