import http from "../utils/http";
import { PostCommentBody } from "../types/fe.interfaces";
import { CommentsFormRestProps } from "../components/properties/comments/types";
import { COMMENTABLE } from "../constants";

/* istanbul ignore next */
export const getCommentMentions = async (params: string): Promise<Response> => {
  return await http.get(`/api/comments/users${params}`);
};

export const getComments = async (params: string): Promise<Response> => {
  return await http.get(`/api/comments${params}`);
};

export const getOpenCommentsCountPerUser = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/users/comments${params}`);
};
/* istanbul ignore next */
export const createComment = async (
  body: PostCommentBody
): Promise<Response> => {
  return await http.post(`/api/comments`, body);
};
/* istanbul ignore next */
export const deleteComment = async (id: number): Promise<Response> => {
  return await http.delete(`/api/comments/${id}`);
};

/* istanbul ignore next */
export const createComment1 = async (
  data: CommentsFormRestProps
): Promise<Response> => {
  return await http.post(`/api/comments`, { ...data, file_id: [data.file_id] });
};

export const updateCommentStatus = async (
  id: number,
  data: { status_id: 1 | 2 }
): Promise<Response> => {
  return await http.put(`/api/comments/${id}/statuses`, data);
};
/* istanbul ignore next */
export const getMentionedUsers = async (params: string): Promise<Response> => {
  return await http.get(`/api/mention-users${params}`);
};
/* istanbul ignore next */
export const createMentionedUsers = async (data: {
  relation_id: string;
  relation: COMMENTABLE;
  user_id: number[];
}): Promise<Response> => {
  return await http.post(`/api/mention-users`, data);
};
