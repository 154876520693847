/* istanbul ignore file */

import React, { ReactElement, useEffect, useState } from "react";
import { Property } from "../../../../types/be/property";
import { FormMessageInItState } from "../../../../constants";
import { Formik } from "formik";
import Form from "./Form";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import { FormWrapper } from "./styled";
import { handleUpdatePropertyFinanceStructure } from "./utils";
import { useParams } from "react-router-dom";
import useValidation from "../../../../hooks/useValidation";
import * as Yup from "yup";
import { PropertyExtraCalculationFields } from "../../../../types/fe/property";
import { mapBEPropertyToFEPropertyUpdate } from "../utils";

const FinanceStructure = ({
  property,
}: {
  property: Property;
}): ReactElement => {
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [initialValues, setInitialValues] = useState<
    Property & PropertyExtraCalculationFields
  >(property);
  const { id } = useParams();
  const {
    own_resources,
    bank_loan,
    interest_on_own_founds,
    interest_bank_loan,
    repayment_bank,
  } = useValidation();

  useEffect(() => {
    property && setCalculateFields();
  }, [property]);

  const setCalculateFields = (): void => {
    const ownResourcesInEur =
      (property.own_resources * property.purchase_price!) / 100;
    const bankLoanInEur = (property.bank_loan * property.purchase_price!) / 100;
    setInitialValues({
      ...property,
      own_resources_in_eur: ownResourcesInEur,
      bank_loan_in_eur: bankLoanInEur,
    });
  };

  return (
    <>
      <FormWrapper>
        {formMessage.text && (
          <FormAlert formMessage={formMessage} sx={{ mb: 2.5 }} />
        )}
        {property && (
          <Formik
            initialValues={mapBEPropertyToFEPropertyUpdate(initialValues)}
            onSubmit={handleUpdatePropertyFinanceStructure(id!, {
              setFormMessage,
            })}
            enableReinitialize
            validationSchema={Yup.object().shape({
              own_resources,
              bank_loan,
              interest_on_own_founds,
              interest_bank_loan,
              repayment_bank,
            })}
          >
            {(props): ReactElement => (
              <Form {...{ ...props, ...initialValues }} />
            )}
          </Formik>
        )}
      </FormWrapper>
    </>
  );
};

export default FinanceStructure;
