import React, { ReactElement, useEffect, useState } from "react";
import { Formik } from "formik";
import Form from "./Form";
import { Grid } from "@mui/material";
import {
  ContactTypes,
  FormMessageErrorState,
  FormMessageInItState,
} from "../../../constants";
import FormAlert from "../../../ui/formAlert/FormAlert";
import * as Yup from "yup";
import { FormWrapper, LoadingBox } from "../styled";
import { FormMessageInterface } from "../../../types/form";
import { useFetch } from "../../../hooks/useFetch";
import { getSalutations } from "../../../api/salutations";
import { handleCreateContactFormSubmit, initialState } from "./utils";
import useValidation from "../../../hooks/useValidation";
import Loader from "../../Loader";
import { Salutation } from "../../../types/be/salutation";
import { useNavigate } from "react-router-dom";
import { ErrorBox } from "../../properties/objectDataSheet/tabs/generalInformation/styled";
import useContactTypes from "../../../hooks/useContactTypes";
import { CreateContactProps } from "./types";
import PageWrapper from "../../../ui/pageWrapper/PageWrapper";
import { useTranslation } from "react-i18next";
import { route } from "../../../utils/url";

const CreateContact = ({
  handleCloseCreateContactModal,
  patchCreatedContact,
  modalWindow = false,
}: CreateContactProps): ReactElement => {
  const { t } = useTranslation();
  const [selectedContactType, setSelectedContactType] = useState("");
  const {
    contactTypes,
    isError: isContactTypesError,
    isLoading: isContactTypesLoading,
    getContactType,
  } = useContactTypes();
  const navigate = useNavigate();
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);

  const {
    data: salutations,
    isError: isSalutationsError,
    isLoading: isSalutationsLoading,
    run: runSalutations,
  } = useFetch<Salutation[]>();

  useEffect(() => {
    getData();
  }, []);

  const getData = (): void => {
    runSalutations(getSalutations());
  };

  const caretakerId = getContactType(ContactTypes.CARETAKER)?.id;
  const serviceProviderId = getContactType(ContactTypes.SERVICE_PROVIDER)?.id;
  const bankId = getContactType(ContactTypes.BANK)?.id;
  const propertyManagerId = getContactType(
    ContactTypes.PROPERTY_MANAGEMENT
  )?.id;
  const otherTypeId = getContactType(ContactTypes.OTHER)?.id;

  const requiredContactTypes = [
    propertyManagerId,
    caretakerId,
    serviceProviderId,
    bankId,
    otherTypeId,
  ];

  const {
    email,
    first_name,
    last_name,
    salutation_id,
    business_phone_only: business_phone,
    contact_type: type_id,
    company_id,
  } = useValidation();

  if (isSalutationsLoading || isContactTypesLoading)
    return (
      <div data-testid="loader">
        <LoadingBox>
          <Loader />
        </LoadingBox>
      </div>
    );

  if (isSalutationsError || isContactTypesError) {
    return <ErrorBox formMessage={FormMessageErrorState} />;
  }

  const breadcrumbs = [
    {
      to: route("contacts"),
      name: t("sidebar.contacts"),
    },
  ];

  return (
    <PageWrapper title={t("create")} breadcrumbs={breadcrumbs}>
      {contactTypes && salutations ? (
        <Grid
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          container
        >
          <Grid item sx={{ maxWidth: "md" }}>
            <FormWrapper data-testid={"form-wrapper"}>
              {formMessage.text && (
                <FormAlert
                  formMessage={formMessage}
                  sx={{ marginBottom: "10px" }}
                />
              )}
              <Formik
                initialValues={initialState}
                validationSchema={Yup.object().shape({
                  email,
                  first_name,
                  last_name,
                  salutation_id,
                  business_phone,
                  type_id,
                  company_id: requiredContactTypes.includes(
                    Number(selectedContactType)
                  )
                    ? company_id.required
                    : company_id.optional,
                })}
                onSubmit={handleCreateContactFormSubmit({
                  setFormMessage,
                  navigate,
                  modalWindow,
                  patchCreatedContact,
                  handleCloseCreateContactModal,
                })}
              >
                {(props): ReactElement => (
                  <Form
                    {...{
                      ...props,
                      salutations,
                      contactTypes,
                      propertyManagerId,
                      setSelectedContactType,
                      handleCloseCreateContactModal,
                      modalWindow,
                    }}
                  />
                )}
              </Formik>
            </FormWrapper>
          </Grid>
        </Grid>
      ) : (
        <Loader />
      )}
    </PageWrapper>
  );
};

export default CreateContact;
