import { FormFields as FormField } from "../components/contacts/edit/searchProfile/types";
import React, { ReactElement, useMemo, useState } from "react";
import FormTextField from "../ui/formsFields/textField";
import camelCase from "lodash/camelCase";
import kebabCase from "lodash/kebabCase";
import { InputAdornment, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useField } from "formik";

export const useFormTextField = (): ((
  formField: FormField,
  transPrefix: string,
  showTooltip?: boolean
) => ReactElement) => {
  return (
    formField: FormField,
    transPrefix: string,
    showTooltip?: boolean
  ): ReactElement => {
    return (
      <>
        <TextFieldWithToolTip
          formField={formField}
          transPrefix={transPrefix}
          showTooltip={showTooltip}
        ></TextFieldWithToolTip>
      </>
    );
  };
};

/* istanbul ignore next */
const TextFieldWithToolTip = ({
  formField,
  transPrefix,
  showTooltip,
}: {
  formField: FormField;
  transPrefix: string;
  showTooltip?: boolean;
}): ReactElement => {
  const [value] = useField(formField.name);
  const [focused, setFocused] = useState(false);
  const { t } = useTranslation();
  let inputProps = {
    "data-testid": `${kebabCase(formField.name)}-input`,
  };
  if (formField.inputProps) {
    inputProps = {
      ...inputProps,
      ...formField.inputProps,
    };
  }

  const inputField = useMemo(
    () => (
      <FormTextField
        select={formField.isSelect}
        key={formField.name}
        name={formField.name}
        status={formField.status}
        label={t(`${transPrefix}.${camelCase(formField.name)}`)}
        inputProps={{
          ...inputProps,
          onFocus: () => {
            setFocused(true);
          },
          onBlur: () => {
            setFocused(false);
          },
        }}
        InputLabelProps={{ shrink: formField.shrink }}
        InputProps={
          formField.adornment && {
            endAdornment: (
              <InputAdornment position={formField.adornment.position}>
                {formField.adornment.name}
              </InputAdornment>
            ),
          }
        }
      >
        {formField.render && formField.render()}
      </FormTextField>
    ),
    [formField]
  );
  return (
    <>
      {showTooltip ? (
        <Tooltip
          title={value.value}
          open={value.value.length > 50 && focused}
          arrow
          placement="top"
        >
          <div>{inputField}</div>
        </Tooltip>
      ) : (
        { inputField }
      )}
    </>
  );
};
