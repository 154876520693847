import { FormikValues } from "formik";
import {
  SOMETHING_WENT_WRONG_ERROR,
  TENANT_STATUS,
} from "../../../../../../../constants";
import { HTTP_STATUS_CODES } from "../../../../../../../types/server";
import { TableCell } from "../../../../../../../types/table";
import { mapBackendValidationErrors } from "../../../../../../../utils/form";
import {
  HandleSubmitExtendProps,
  SnackbarMessage,
  TenantHistoryProps,
  TenantHistoryTableData,
} from "./types";
import { TenantHistory as TenantHistoryInterface } from "../../../../../../../types/be/tenantHistory";
import { updateTenantRentEnd } from "../../../../../../../api/propertyTenant";
import {
  isDateFuture,
  isDatePast,
  replaceMomentByTimestamp,
} from "../../../../../../../utils/date";
import { convertTimestampToDate } from "../../../utils";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "contact",
  },
  {
    id: "rent_start",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenant.rent_start",
  },
  {
    id: "rent_end",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenant.rent_end",
  },
  {
    id: "net_rent_per_month",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenant.net_rent_per_month",
  },
  {
    id: "tax_in_eur",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenant.tax",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];
/*istanbul ignore next*/
export const mapTenantHistoryToTenantHistoryTableData = (
  data: TenantHistoryInterface[]
): TenantHistoryTableData[] => {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    rent_start: item.rent_start,
    rent_end: item.rent_end,
    net_rent_per_month: item.net_rent_per_month,
    tax_in_eur: item.tax_in_eur,
    contact: item.contact,
    is_extendable: item.is_extendable,
  }));
};
/*istanbul ignore next*/
export const handleSubmitExtend = ({
  setFormMessage,
  onSuccess,
}: HandleSubmitExtendProps) => {
  return async (
    values: TenantHistoryTableData,
    { setStatus }: FormikValues
  ): Promise<void | Response> => {
    const response = await updateTenantRentEnd({
      id: values.id,
      rent_end: replaceMomentByTimestamp(values.rent_end),
      rent_start: replaceMomentByTimestamp(values.rent_start),
    });
    if (response.status === HTTP_STATUS_CODES.OK) {
      onSuccess();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      const errors = await response.json();
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(errors),
      });
    } else {
      setFormMessage({ text: SOMETHING_WENT_WRONG_ERROR, type: "error" });
    }
  };
};

export const tableContainerQuerySelector = ".MuiTableContainer-root";

export const sendCredentialsSnackbarInit: SnackbarMessage = {
  text: "",
  visible: false,
  color: "success",
};

/* istanbul ignore next */
export const getTenantStatus = (value: TenantHistoryProps): TENANT_STATUS => {
  if (isDateFuture(value?.rent_start)) {
    return TENANT_STATUS.IS_FUTURE;
  } else if (
    isDatePast(value?.rent_start) &&
    (!value?.rent_end || isDateFuture(value?.rent_end))
  ) {
    return TENANT_STATUS.IS_CURRENT;
  } else {
    return TENANT_STATUS.IS_PAST;
  }
};

/*istanbul ignore next*/
export const getMinDateForExtentContract = (
  rentEnd: number,
  rentStart: number
): Date | undefined => {
  const today = new Date().getTime();
  const rentSTartTime = new Date(rentStart).getTime();
  if (rentEnd) {
    return convertTimestampToDate(rentEnd)?.toDate();
  }

  return today > rentSTartTime
    ? new Date(today)
    : new Date(
        new Date(
          new Date(rentSTartTime).setDate(new Date(rentSTartTime).getDate() + 1)
        )
      );
};
