import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Search as SearchIcon } from "react-feather";
import { Grid } from "@mui/material";
import { Dialog, HistoryDialogContent, LoaderRoot } from "./styled";
import Loader from "../../../Loader";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
import useTable from "../../../../ui/table/useTable";
import Table from "../../../../ui/table/Table";
import { Input, Search, SearchIconWrapper } from "../../../../ui/table/styled";
import { handleServerError } from "../../../../utils/http";
import {
  getFilteredHeadCellsByProperty,
  objectGetParamsToString,
} from "../../../../utils/common";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import useIsMounted from "../../../../hooks/useIsMounted";
import { getDocumentReleaseHistory } from "../../../../api/history";
import { DocumentRelaseHistory } from "../../../../types/be/history";
import { useParams } from "react-router-dom";
import { HistoryPopUpProps } from "./types";
import { itemsListHeadCells } from "./utils";
import HistoryRow from "./HistoryRow";

/* istanbul ignore next */
const HistoryPopup = ({
  handleCloseDialog,
  historyId,
  documentRelease,
}: HistoryPopUpProps): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
  } = useTable<DocumentRelaseHistory>();

  const fetchDocumentReleaseHistory = useCallback(async () => {
    setIsLoading(true);

    const params = {
      relation: documentRelease,
      relation_id: historyId,
    };

    const response = await getDocumentReleaseHistory(
      `${queryParams}&${objectGetParamsToString(params)}`
    );

    if (response.status !== HTTP_STATUS_CODES.OK) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const resJson = await response.json();
      setTotal(resJson.meta.total);
      setData(resJson.data);
      setIsLoading(false);
    }
  }, [historyId, queryParams, setIsLoading, setTotal, setData, setIsLoading]);

  useEffect(() => {
    if (isMounted()) {
      fetchDocumentReleaseHistory();
    }
  }, [queryParams, historyId, documentRelease]);

  const isMounted = useIsMounted();

  const tableHeaderTitles = useMemo(() => {
    return getFilteredHeadCellsByProperty(
      itemsListHeadCells,
      "property_name",
      id
    );
  }, []);

  const renderRow = (row: DocumentRelaseHistory): ReactElement => (
    <HistoryRow row={row} documentRelease={documentRelease} />
  );

  const TableToolbar = (
    <Grid
      container
      mt={1}
      justifyContent={
        historyId ? "space-between" : /* istanbul ignore next */ "flex-end"
      }
      alignItems={"center"}
    >
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs mb={2} />
          <Grid item>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t("search")} onChange={handleTableSearch} />
            </Search>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Dialog
        role="release-history"
        fullWidth
        maxWidth="xl"
        open={true}
        onClose={handleCloseDialog}
        aria-labelledby="max-width-dialog-title"
        data-testid="history-modal"
        disableEnforceFocus
        $loading={isLoading}
      >
        <DialogTitle
          title={t("tenant.history.history")}
          onClose={handleCloseDialog}
        ></DialogTitle>
        <HistoryDialogContent dividers>
          <Table
            data={data}
            total={total}
            currentPage={currentPage}
            order={order}
            orderBy={orderBy}
            error={error}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
            onSortChange={handleSortChange}
            onSelectAllClick={handleSelectAllClick}
            onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
            isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
            listHeadCells={tableHeaderTitles}
            renderRow={renderRow}
            tableToolbar={TableToolbar}
            noDataIsAvailablePlaceholder={"table.noContractHistoryIsAvailable"}
          />
        </HistoryDialogContent>
      </Dialog>
      {isLoading && (
        <LoaderRoot>
          <Loader />
        </LoaderRoot>
      )}
    </>
  );
};

export default HistoryPopup;
