import styled from "styled-components/macro";
import { CircularProgress, Paper } from "@mui/material";
/* istanbul ignore next */
export const TextFieldSpinner = styled(CircularProgress)`
  position: absolute;
  right: ${(props): string => props.theme.spacing(7.5)};
  top: ${(props): string => props.theme.spacing(29.5)};
`;

/* istanbul ignore next */
export const FormWrapper = styled(Paper)`
  padding: ${(props): string => props.theme.spacing(6)};
  position: relative;
  .edit-icon {
    position: absolute;
    right: ${(props): string => props.theme.spacing(5)};
    top: ${(props): string => props.theme.spacing(5)};
    cursor: pointer;
    color: ${(props): string => props.theme.palette.primary.main};
  }
  width: 100%;
  text-align: left;
`;
