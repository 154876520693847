import React, { ReactElement } from "react";
import { Button, Grid, Tooltip } from "@mui/material";
import {
  AddCard as AddCardIcon,
  AddLink as AddLinkIcon,
} from "@mui/icons-material";
import Loader from "../../../Loader";
import { BankAccount as BEBankAccount } from "../../../../types/be/bankAccount";
import BankAccountListItem from "../bankAccountListItem";
import { BankAccounts, ScrollableDiv } from "../../styled";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

const BankAccountList = ({
  onLinkBankAccountButtonClick,
  isCreateBankAccountButtonDisabled,
  onCreate,
  isBankAccountsLoading,
  bankAccounts,
  onBankAccountClick,
  useOnWidget,
  selectedBankAccount,
  onFetchMore,
  hasMore,
}: {
  onLinkBankAccountButtonClick: () => void;
  isCreateBankAccountButtonDisabled: boolean;
  onCreate: () => void;
  isBankAccountsLoading: boolean;
  bankAccounts: BEBankAccount[];
  onBankAccountClick: (bankAccount: BEBankAccount) => void;
  useOnWidget: boolean;
  selectedBankAccount?: BEBankAccount;
  onFetchMore: () => void;
  hasMore: boolean;
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <BankAccounts item md={3} lg={3} role={"bank-accounts-section"}>
      <Grid
        container
        spacing={1}
        justifyContent={"space-between"}
        sx={{ mb: 2, position: "sticky", top: 0 }}
      >
        <Grid item xs={6}>
          <Tooltip title={t("property.bank.linkNewBankAccount")}>
            <Button
              role="link-bank-account"
              fullWidth
              size={"small"}
              variant="contained"
              onClick={onLinkBankAccountButtonClick}
            >
              <AddLinkIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <Tooltip title={t("property.bank.addAccountToAnExistingBank")}>
            <Button
              role="add-account-button"
              disabled={isCreateBankAccountButtonDisabled}
              fullWidth
              size={"small"}
              variant="contained"
              onClick={() => onCreate()}
            >
              <AddCardIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      {isBankAccountsLoading ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems={"center"}
          sx={{ height: "93%" }}
        >
          <Grid item>
            <Loader />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          sx={{ height: "95%", maxHeight: "88vh", overflow: "auto" }}
        >
          <Grid item>
            <ScrollableDiv id="scrollableDiv">
              <InfiniteScroll
                dataLength={bankAccounts.length}
                next={onFetchMore}
                hasMore={hasMore}
                loader={false}
                scrollableTarget="scrollableDiv"
              >
                {bankAccounts.map((bankAccount: BEBankAccount) => (
                  <BankAccountListItem
                    key={`${bankAccount.id}`}
                    bankAccount={bankAccount}
                    onClick={() => onBankAccountClick(bankAccount)}
                    useOnWidget={useOnWidget}
                    selectedBankAccount={selectedBankAccount}
                  />
                ))}
              </InfiniteScroll>
            </ScrollableDiv>
          </Grid>
        </Grid>
      )}
    </BankAccounts>
  );
};

export default BankAccountList;
