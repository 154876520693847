import http from "../utils/http";
import { Focus, IdPropType } from "../types/models";
import { Contact } from "../components/contacts/edit/types";
import { CreateContactType } from "../components/contacts/create/types";

export const createContact = async (
  contact: CreateContactType
): Promise<Response> => {
  return await http.post("/api/contacts", contact);
};

export const updateContact = async (
  id: string,
  data: Contact
): Promise<Response> => {
  return await http.put(`/api/contacts/${id}`, data);
};

export const getContact = async (contactId: string): Promise<Response> => {
  return await http.get(`/api/contacts/${contactId}`);
};

export const getContacts = async (params: string): Promise<Response> => {
  return await http.get(`/api/contacts${params}`, true);
};

export const deleteContact = async ({ id }: IdPropType): Promise<Response> => {
  return await http.delete(`/api/contacts/${id}`);
};

export const addFocus = async (
  contactId: string | number,
  focus: Focus
): Promise<Response> => {
  return await http.post(`/api/contacts/${contactId}/focuses`, {
    title: focus,
  });
};

export const deleteFocus = async (
  contactId: string,
  id?: number | string
): Promise<Response> => {
  return await http.delete(`/api/contacts/${contactId}/focuses/${id}`);
};

export const contactAutocomplete = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/contacts${params}`, true);
};

export const getContactTypes = async (params: string): Promise<Response> => {
  return await http.get(`/api/contacts/types${params}`);
};

export const importContacts = async (data: FormData): Promise<Response> => {
  return await http.post("/api/imports/contact/xls", data);
};
