import React, { ReactElement, useEffect, useState, useMemo } from "react";
import useTable from "../../../../../ui/table/useTable";
import { Property } from "../../../../../types/be/property";
import { itemsListHeadCells, PROPERTY_CATEGORY_ID } from "./utils";
import Table from "../../../../../ui/table/Table";
import {
  getProperties,
  getPropertyCompanies,
} from "../../../../../api/property";
import { getJson } from "../../../../../utils/http";
import { Checkbox, Grid, TableCell, Tooltip, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddressBox, ObjectLink } from "../../../../properties/list/styled";
import { route } from "../../../../../utils/url";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  Input,
  Search,
  SearchIconWrapper,
} from "../../../../../ui/table/styled";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import useIsMounted from "../../../../../hooks/useIsMounted";
import map from "lodash/map";
import { UpdateRoleDto, getRole, syncProperty } from "../../../../../api/roles";
import { useParams } from "react-router-dom";
import { RoleDetails } from "../../../../../types/be/role";
import { LoaderWrapper } from "./styled";
import Loader from "../../../../Loader";
import { getCheckboxItemListHeadCell } from "../../../../properties/invoices/list/utils";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "../../../../../ui/button/styled";
import { PermissionsRead } from "../../../../../constants";
import { NavLink } from "react-router-dom";
import useAccessControl from "../../../../../hooks/useAccessControl";
import { Card } from "@mui/material";
import { PropertyAndCompanyProps } from "./types";
import { prepareQueryParams } from "../../../../../utils/common";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
/* istanbul ignore next */
const Properties = ({
  company = false,
}: PropertyAndCompanyProps): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSavingButtonLoading, setIsSavingButtonLoading] = useState(false);
  const [selectAllLoading, setSelectAllLoading] = useState(false);
  const [propertyIds, setPropertyIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
  } = useTable<Property>({ limit: 100 });

  const { can } = useAccessControl();
  const itemsFieldName = company ? "companies" : "properties";
  const itemFieldName = company ? "company" : "property";

  useEffect(() => {
    getRole(id!)
      .then(getJson)
      .then(({ data }: { data: RoleDetails }) => {
        setPropertyIds(map(data[itemsFieldName], "id"));
      });
  }, []);

  useEffect(() => {
    if (isMounted()) {
      company ? fetchCompanies() : fetchProperties();
    }
  }, [queryParams, company]);

  const handleOnSaveButtonClick = (): void => {
    setIsSavingButtonLoading(true);
    syncProperty(+id!, {
      property_category_code: itemFieldName,
      property_id: [...propertyIds],
    })
      .then(getJson)
      .then(({ data }: { data: RoleDetails }) => {
        setPropertyIds(map(data.properties, "id"));
        navigate(route("settings.roles"));
      })
      .finally(() => {
        setIsSavingButtonLoading(false);
        toggleDisabledBtn(false);
      });
  };

  const updatePropertyRole = (payload: Partial<UpdateRoleDto>): void => {
    syncProperty(+id!, payload)
      .then(getJson)
      .then(({ data }: { data: RoleDetails }) => {
        setPropertyIds(map(data[itemsFieldName], "id"));
      })
      .finally(() => setSelectAllLoading(false));
  };
  const handleSelectAllButtonClick = (checked: boolean): void => {
    setSelectAllLoading(true);
    setSelectAll(checked);
    const params = checked
      ? {
          all_properties: true,
          property_category_code: itemFieldName,
        }
      : {
          property_id: [],
          property_category_code: itemFieldName,
        };
    updatePropertyRole(params);
  };

  const fetchProperties = (): void => {
    setIsLoading(true);
    const params = prepareQueryParams(queryParams, {
      property_category_id: company
        ? PROPERTY_CATEGORY_ID.COMPANY
        : PROPERTY_CATEGORY_ID.PROPERTY,
    });
    getProperties(params)
      .then((res) => res.json())
      .then(({ data, meta: { total } }) => {
        setData(data);
        setTotal(total);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const fetchCompanies = (): void => {
    setIsLoading(true);
    const params = prepareQueryParams(queryParams, {});
    getPropertyCompanies(params)
      .then((res) => res.json())
      .then(({ data, meta: { total } }) => {
        setData(data);
        setTotal(total);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const handleRowCheckboxClick = (row: Property, checked: boolean): void => {
    if (checked) {
      setPropertyIds((ids) => {
        ids.push(row.id);
        return [...ids];
      });
    } else {
      setPropertyIds((ids) => ids.filter((id) => id !== row.id));
    }
  };

  const toggleDisabledBtn = (disableOnly = true): void => {
    if (disableOnly && !disabledBtn) return;
    setDisabledBtn((state) => !state);
  };

  const TableToolbar = (
    <Grid container justifyContent={"space-between"} sx={{ pt: 2, mb: 2 }}>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <LoadingButton
              onClick={() => handleOnSaveButtonClick()}
              disabled={disabledBtn}
              color={"success"}
              variant="contained"
              loading={isSavingButtonLoading}
            >
              {t("settings.role.save")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Input
            sx={{ width: "400px" }}
            placeholder={t("search")}
            onChange={handleTableSearch}
          />
        </Search>
      </Grid>
    </Grid>
  );

  const renderRow = (row: Property): ReactElement => {
    return (
      <>
        <TableCell align="left" padding={"none"}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            onChange={({
              target: { checked },
            }: React.ChangeEvent<HTMLInputElement>) => {
              handleRowCheckboxClick(row, checked);
              toggleDisabledBtn();
            }}
            checked={propertyIds.includes(row.id)}
          />
        </TableCell>
        <TableCell align="left">
          <ObjectLink
            to={route("properties.edit", row.id, "general")}
            key={row.id}
          >
            <Typography noWrap>{row.object_name}</Typography>
          </ObjectLink>
        </TableCell>
        <TableCell align="left">
          {row.location.full_address ? (
            <Tooltip title={`${row.location.full_address}`}>
              <AddressBox>{`${row.location.full_address}`}</AddressBox>
            </Tooltip>
          ) : (
            "---"
          )}
        </TableCell>
        <TableCell align="left">{row.property_type.name}</TableCell>
      </>
    );
  };

  const isMounted = useIsMounted();

  const tableHeaderTitles = useMemo(() => {
    const Element = selectAllLoading ? (
      <LoaderWrapper>
        <Loader size={17} />
      </LoaderWrapper>
    ) : (
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        checked={selectAll}
        data-testid={"select-all"}
        disabled={selectAllLoading}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleSelectAllButtonClick(event.target.checked);
          toggleDisabledBtn();
        }}
      />
    );
    const checkboxItemListHeadCell = getCheckboxItemListHeadCell(Element);
    return [checkboxItemListHeadCell, ...itemsListHeadCells];
  }, [selectAll, selectAllLoading, disabledBtn]);

  const isAllPropertiesSelected = (): boolean =>
    propertyIds?.length > 0 && total === propertyIds?.length;

  useEffect(() => {
    setSelectAll(isAllPropertiesSelected());
  }, [total, propertyIds]);

  return (
    <Grid>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={tableHeaderTitles}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        noDataIsAvailablePlaceholder={"table.noPropertiesAreAvailable"}
      />
      {can(PermissionsRead.SETTINGS_ROLE) && (
        <Card sx={{ mb: 1 }}>
          <Grid item sx={{ p: 2 }}>
            <StyledButton
              data-testid={"dataItems-link"}
              component={NavLink}
              to={route(`settings.roles`)}
              size="small"
              variant="contained"
              color="error"
            >
              {t("settings.role.abort")}
            </StyledButton>
          </Grid>
        </Card>
      )}
    </Grid>
  );
};

export default Properties;
