import * as React from "react";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(
  name: string,
  personName: string[],
  theme: Theme
): { fontWeight?: number | string } {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect({
  options,
  selected,
  handleOptionsSelectedChange,
  sx = { width: 200 },
}: {
  options: string[];
  selected: string[];
  handleOptionsSelectedChange: (optionsSelected: string[]) => void;
  sx?: SxProps;
}): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [optionsSelected, setOptionsSelected] = React.useState<string[]>([]);
  const handleChange = (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = event;
    setOptionsSelected(Array.from(value));
  };

  useEffect(() => {
    handleOptionsSelectedChange(optionsSelected);
  }, [optionsSelected]);

  useEffect(() => {
    setOptionsSelected(selected);
  }, []);
  return (
    <div>
      <FormControl sx={sx} size={"small"}>
        <InputLabel id="demo-multiple-name-label">
          {t("optionalColumns")}
        </InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={optionsSelected}
          onChange={handleChange}
          input={<OutlinedInput label="Optional columns" />}
          MenuProps={MenuProps}
        >
          {options.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, optionsSelected, theme)}
            >
              {t(`optionalColumnsNames.${name}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
