import React, { ReactElement, useContext } from "react";
import { DialogContent } from "@mui/material";
import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import { CreateMobileUserModalProps } from "./types";
import Form from "./Form";
import { createMobileUserInitState, handleInviteUser } from "./utils";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import DialogTitle from "../../../../../../../ui/dialog/DialogTitle";
import useValidation from "../../../../../../../hooks/useValidation";
import { AreaEditContext } from "../../../AreaEditContext";

/* istanbul ignore next */
export const InviteMobileUserModal = ({
  isCreateModalOpen,
  handleInviteModalClose,
  snackbar,
  fetchMobileUser,
}: CreateMobileUserModalProps): ReactElement => {
  const { first_name, last_name, email } = useValidation();
  const { t } = useTranslation();
  const areaEditContext = useContext(AreaEditContext);
  const activeTenantId = areaEditContext?.area?.active_tenant?.id;

  const onSuccess = (): void => {
    handleInviteModalClose();
    fetchMobileUser();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isCreateModalOpen}
      onClose={handleInviteModalClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="invite-mobile-user-dialog"
    >
      <DialogTitle
        title={t("tenant.inviteUser")}
        onClose={handleInviteModalClose}
      ></DialogTitle>
      <DialogContent>
        <>
          <Formik
            initialValues={createMobileUserInitState}
            validationSchema={Yup.object().shape({
              first_name,
              last_name,
              email,
            })}
            onSubmit={handleInviteUser({
              activeTenantId,
              t,
              snackbar,
              onSuccess,
            })}
          >
            {(props): ReactElement => (
              <Form
                {...props}
                handleInviteModalClose={handleInviteModalClose}
              />
            )}
          </Formik>
        </>
      </DialogContent>
    </Dialog>
  );
};
