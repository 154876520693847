import styled from "styled-components/macro";
import { Grid } from "@mui/material";

export const StatusLeftBy = styled.span`
  font-size: 10px;
  font-weight: bold;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StatusLeftByContainer = styled(Grid)`
  height: 0;
  top: 2px;
  position: relative;
`;

export const StatusLeftByItem = styled(Grid).attrs((props: { m?: string }) => ({
  m: props.m || "0",
}))`
  height: 0;
  position: relative;
  bottom: 4px;
  margin: ${(props): string => {
    return props.m;
  }};
`;
