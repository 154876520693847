import styled from "styled-components/macro";
import { Grid } from "@mui/material";

/* istanbul ignore next */
export const ActionButtonWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
