import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Checkbox } from "@mui/material";

const AssignCheckbox = ({
  ids,
  id,
  onChange,
}: {
  ids: number[];
  id: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}): ReactElement => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(ids.includes(id));
  }, []);
  /* istanbul ignore next */
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange(e);
    setValue(e.target.checked);
  };

  return (
    <Checkbox
      role={"assign-property-checkbox"}
      onChange={handleOnChange}
      value={value}
      checked={value}
    />
  );
};

export default AssignCheckbox;
