/* istanbul ignore file */

import { FormikValues } from "formik";
import { DateVInvoice as BEDateVInvoice } from "../../../../types/be/invoice";
import { DateVInvoice } from "../../../../types/fe/invoice";
import { HandleCreateInvoiceProps } from "./types";
import { prepareInvoiceBeforeSubmit } from "../createDateVInvoice/utils";
import { editDateVInvoice } from "../../../../api/invoices";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../constants";
import { formatTimestamp } from "../../../../utils/date";

export const prepareInitialValues = (invoice: BEDateVInvoice): DateVInvoice => {
  return {
    date: invoice?.invoice?.date
      ? formatTimestamp(invoice?.invoice?.date)
      : null,
    amount: invoice?.invoice?.amount,
    comment: invoice?.invoice?.comment,
    contact_id: invoice?.contact_id,
    file_id: invoice?.file?.id,
    is_confidential: Boolean(invoice?.invoice?.is_confidential),
    is_debited_automatically: Boolean(
      invoice?.invoice?.is_debited_automatically
    ),
    is_apportionable: Boolean(invoice?.invoice?.is_apportionable),
    booking_text: invoice?.booking_text,
    is_permanent: Boolean(invoice?.invoice?.is_permanent),
    is_active: Boolean(invoice?.invoice?.is_active),
    datev_creditor_id: invoice?.creditor_id,
    invoice_number: invoice?.invoice_number,
    bic: invoice?.invoice?.bic,
    iban: invoice?.invoice?.iban,
    currency_code: invoice?.invoice?.currency_code,
    due_date: invoice?.invoice?.due_date
      ? formatTimestamp(invoice?.invoice?.due_date)
      : null,
    performance_date: invoice?.performance_date
      ? formatTimestamp(invoice?.performance_date)
      : null,
    file_name: invoice?.file?.name,
    property: invoice?.property,
    property_id: invoice?.property?.id,
    contact: invoice?.invoice?.contact,
    contract_id: invoice?.invoice?.contract?.id,
    offer: invoice?.invoice?.offer
      ? {
          ...invoice?.invoice?.offer,
          name: invoice?.invoice?.offer?.file?.name,
        }
      : null,
    offer_id: invoice?.invoice?.offer?.id,
    total: invoice?.invoice?.total,
    repetition_period_id: invoice?.invoice?.repetition_period?.id,
    invoice_id: invoice?.invoice?.id,
    area_id: invoice?.invoice?.area?.id,
    apportionable_percent: invoice?.invoice?.apportionable_percent ?? "",
    datev_invoice_positions: invoice?.positions?.map((position) => ({
      datev_soll_konto: position?.soll_konto,
      datev_soll_konto_id: position?.soll_konto?.id?.toString(),
      vat: position?.vat?.toString(),
      amount_netto: position?.amount_netto,
      amount_brutto: position?.amount_brutto,
      booking_text: position?.booking_text,
    })),
    datev_creditor: invoice?.creditor,
    area: invoice?.invoice?.area,
    contract: invoice?.invoice?.contract
      ? {
          ...invoice?.invoice?.contract,
          name: invoice?.invoice?.contract?.file?.name,
        }
      : null,
    repeat_from: invoice?.invoice?.repeat_from
      ? formatTimestamp(invoice?.invoice?.repeat_from)
      : null,
    repeat_to: invoice?.invoice?.repeat_to
      ? formatTimestamp(invoice?.invoice?.repeat_to)
      : null,
    repeat_day: invoice?.invoice?.repeat_day ?? "",
  };
};

export const handleEditDateVInvoice = ({
  snackbar,
  t,
  afterSuccess,
  id,
}: HandleCreateInvoiceProps) => {
  return async (
    values: DateVInvoice,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    setStatus({
      success: true,
      errors: {},
    });
    const payload = prepareInvoiceBeforeSubmit(values, true);
    const response = await editDateVInvoice(id, payload);
    const result = await response.json();
    if (response?.ok) {
      setStatus({
        success: true,
        errors: {},
      });
      setSubmitting(false);
      afterSuccess();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(result?.errors),
      });
    } else {
      snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
    }
    setSubmitting(false);
  };
};
