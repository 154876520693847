import { FormikValues } from "formik";
import { updateBKA } from "../../../../../../../api/bka";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../../constants";
import { BKA, BKACost } from "../../../../../../../types/be/tenant";
import { HTTP_STATUS_CODES } from "../../../../../../../types/server";
import { parseNumber } from "../../../../../../../utils/common";
import { mapBackendValidationErrors } from "../../../../../../../utils/form";
import { FormSubmitHandlerProps } from "./types";
import { CreateBKADialogState } from "../create/types";
import { formatDateToTimespamp } from "../../../../../../../utils/date";
import { handleCreateCosts } from "../create/utils";

export const prepareInitialValues = (
  bka: BKA,
  costs: BKACost[]
): CreateBKADialogState => ({
  date: bka.date ? new Date(bka.date * 1000) : null,
  amount: String(bka.amount),
  date_bka: bka.date_bka ? new Date(bka.date_bka * 1000) : null,
  file: bka.file,
  year: bka.year?.toString(),
  costs: costs.map((cost) => ({
    amount: cost.amount.toString(),
    type_id: cost.type,
  })),
});

export const handleEditBKA = ({
  setFormMessage,
  afterEditBKA,
  id,
}: FormSubmitHandlerProps) => {
  return async (
    values: CreateBKADialogState,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const formData = new FormData();
    formData.append(
      "date",
      values.date ? formatDateToTimespamp(values.date)?.toString() : ""
    );
    formData.append("amount", parseNumber(values.amount)?.toString());
    formData.append(
      "date_bka",
      values.date_bka ? formatDateToTimespamp(values.date_bka)?.toString() : ""
    );
    formData.append("year", values.year);
    formData.append("_method", "PUT");
    const response = await updateBKA(formData, id);
    const json = await response.json();

    if (response?.ok) {
      const costsData = {
        bka_id: json?.data?.id,
        costs: values?.costs?.map((cost) => ({
          type_id: cost?.type_id?.id,
          amount: parseNumber(cost?.amount),
        })),
      };
      await handleCreateCosts({
        values: costsData,
        setStatus,
        afterCreateBKA: afterEditBKA,
        setFormMessage,
        setSubmitting,
      });
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
    }
    setSubmitting(false);
  };
};
