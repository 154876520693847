import { Formik } from "formik";
import { Grid, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/button/Button";
import { FormikValues } from "formik/dist/types";
import { CreateEditVacationRequestModalProps } from "./types";
import * as Yup from "yup";
import {
  DATEPICKER_DATE_FORMAT,
  FormMessageErrorState,
  FormMessageInItState,
  UserRoles,
} from "../../../../constants";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import { TextField } from "./styled";
import {
  vacationRequestInitialState,
  initInsuranceRange,
  langDict,
  handleSubmitVacationRequest,
} from "./utils";
import Loader from "../../../Loader";
import { ErrorBox } from "../../../home/styled";
import useValidation from "../../../../hooks/useValidation";
import { CloseIconStyled } from "../../../employeeOverview/vacationHolidayManagementModal/styled";
import DatePicker, { registerLocale } from "react-datepicker";
import { DatePickerWrapper } from "../../../../ui/datePicker/styled";
import { replaceMomentByTimestamp } from "../../../../utils/date";
import { Form } from "./styled";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
import {
  getCalculateVacationRemainder,
  getVacationRequest,
  searchUserInVacationRequest,
} from "../../../../api/vacationRequests";
import { useFetch } from "../../../../hooks/useFetch";
import {
  UserInVacationRequest,
  VacationRequest,
} from "../../../../types/be/vacationRequests";
import useAppSelector from "../../../../hooks/useAppSelector";
import { prepareQueryParams } from "../../../../utils/common";
import Dropdown from "../../../../ui/dropdown";
import { AutocompleteOption } from "../../../../ui/dropdown/types";

const currentLang = window.localStorage.getItem("lang") || "en";
currentLang && registerLocale("lang", langDict[currentLang]);

const VacationRequestModal = ({
  editRowId,
  isCreateEditModalOpen,
  handleCloseCreateEditModal,
  handleSuccessVacationRequestSubmission,
  setIsSnackbarVisible,
  selectedUser,
  setSelectedUser,
  range,
  setRange,
  rangeDifference,
  setRangeDifference,
}: CreateEditVacationRequestModalProps): ReactElement => {
  const { _user } = useAppSelector((state) => state.user);
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [
    editVacationRequestRangeDifference,
    setEditVacationRequestRangeDifference,
  ] = useState<number | null>(null);

  const { t } = useTranslation();
  const { vacation_holiday_user_selection } = useValidation();
  const [startDate, endDate] = range;

  const {
    data: vacationRequest,
    run: runVacationRequest,
    isLoading: isVacationRequestLoading,
    isError: isVacationRequestError,
  } = useFetch<VacationRequest>();

  const isAdmin = _user?.role.code === UserRoles.ADMIN;

  const handleClearRange = (event: React.MouseEvent<SVGSVGElement>): void => {
    event.stopPropagation();
    setRange(initInsuranceRange);
  };
  useEffect(() => {
    editRowId && getData();
  }, [editRowId]);

  useEffect(() => {
    editRowId && vacationRequest
      ? setSelectedUser(vacationRequest.user)
      : setSelectedUser(null);
    editRowId && vacationRequest
      ? setRange([
          new Date(vacationRequest?.from * 1000),
          new Date(vacationRequest?.to * 1000),
        ])
      : setRange(initInsuranceRange);
    editRowId &&
      vacationRequest &&
      setEditVacationRequestRangeDifference(vacationRequest.term);
    !editRowId && setEditVacationRequestRangeDifference(null);
  }, [editRowId, vacationRequest]);

  useEffect(() => {
    selectedUser && startDate && endDate && handleGetCountOfDaysLeft();
  }, [endDate, selectedUser]);

  const handleGetCountOfDaysLeft = useCallback(async () => {
    const params = prepareQueryParams("", {
      user_id: selectedUser?.id,
      from: replaceMomentByTimestamp(startDate),
      to: replaceMomentByTimestamp(endDate),
    });
    const response = await getCalculateVacationRemainder(params);
    const { data } = await response.json();
    return setRangeDifference(data.count);
  }, [selectedUser, startDate, endDate]);

  const getData = (): void => {
    runVacationRequest(getVacationRequest(editRowId!));
  };

  const onSelectUser = async (
    _?: unknown,
    event?: AutocompleteOption | null
  ): Promise<void> => {
    setSelectedUser(event as UserInVacationRequest);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={isCreateEditModalOpen}
      onClose={handleCloseCreateEditModal}
      aria-labelledby="max-width-dialog-title"
      data-testid="create-vacation-holiday-dialog"
      fullWidth
    >
      <DialogTitle
        title={editRowId ? "edit" : "create"}
        onClose={handleCloseCreateEditModal}
      />
      <DialogContent>
        {isVacationRequestLoading ? (
          <Loader />
        ) : (
          <>
            {isVacationRequestError ? (
              <ErrorBox formMessage={FormMessageErrorState} />
            ) : (
              <Stack>
                {formMessage.text && (
                  <FormAlert
                    formMessage={formMessage}
                    sx={{ marginBottom: "10px" }}
                  />
                )}
                <Formik
                  enableReinitialize
                  initialValues={vacationRequestInitialState}
                  validationSchema={Yup.object().shape({
                    vacation_holiday_user_selection,
                  })}
                  onSubmit={handleSubmitVacationRequest({
                    editRowId,
                    setFormMessage,
                    handleSuccessVacationRequestSubmission,
                    range,
                    selectedUser,
                    setRange,
                    setSelectedUser,
                    setIsSnackbarVisible,
                  })}
                >
                  {({
                    handleSubmit,
                    isSubmitting,
                    touched,
                    errors,
                    status,
                    handleBlur,
                  }: FormikValues): ReactElement => (
                    <Form onSubmit={handleSubmit}>
                      <Grid container spacing={6} columns={12}>
                        {isAdmin && (
                          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                            <Dropdown
                              id="user_id"
                              name="user_id"
                              size="medium"
                              placeholder={t("search")}
                              label={t("vacationRequests.user")}
                              getOptions={searchUserInVacationRequest}
                              onSelectOption={onSelectUser}
                              optionLabel="full_name"
                              optionValue="id"
                              defaultValue={selectedUser || null}
                              enableReInitialization
                              error={Boolean(
                                touched.user_id &&
                                  (status?.errors.user_id || errors.user_id)
                              )}
                              helperText={
                                touched.user_id &&
                                (status?.errors.user_id || errors.user_id)
                              }
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          sm={isAdmin ? 6 : 12}
                          sx={{ mt: 0.6 }}
                        >
                          <DatePickerWrapper>
                            <DatePicker
                              locale={currentLang}
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              onChange={
                                /* istanbul ignore next */ (update) => {
                                  setRange(update);
                                }
                              }
                              minDate={new Date()}
                              dateFormat={DATEPICKER_DATE_FORMAT}
                              customInput={
                                <TextField
                                  inputProps={{
                                    readOnly: true,
                                    "data-testid": "date-range-input",
                                  }}
                                  fullWidth
                                  onBlur={handleBlur}
                                  error={
                                    Boolean(
                                      touched.to &&
                                        (status?.errors.to || errors.to)
                                    ) ||
                                    Boolean(
                                      touched.from &&
                                        (status?.errors.from || errors.from)
                                    )
                                  }
                                  helperText={
                                    (touched.to &&
                                      (status?.errors.to || errors.to)) ||
                                    (touched.from &&
                                      (status?.errors.from || errors.from))
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <CloseIconStyled
                                        data-testid="remove-from-date-range"
                                        onClick={handleClearRange}
                                      />
                                    ),
                                  }}
                                />
                              }
                            />
                          </DatePickerWrapper>
                          {selectedUser && rangeDifference && (
                            <Typography
                              component="div"
                              sx={{
                                color:
                                  rangeDifference! <= 0
                                    ? "red.main"
                                    : "secondary.main",
                                ml: 4,
                              }}
                            >
                              {t("vacationRequests.youWillHave")}{" "}
                              {editRowId
                                ? rangeDifference +
                                  editVacationRequestRangeDifference!
                                : rangeDifference}{" "}
                              {t("vacationRequests.daysRemainingSmall")}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        gap={2}
                      >
                        <Grid item>
                          <Button
                            onClick={handleCloseCreateEditModal}
                            title={t("cancel")}
                            testId={"cancel-vacation-request-button"}
                            variant={"text"}
                            type="button"
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            title={editRowId ? t("update") : t("create")}
                            type="submit"
                            sx={{ ml: "1rem" }}
                            color="success"
                            testId={"create-vacation-request-button"}
                            disabled={isSubmitting}
                            isLoading={isSubmitting}
                          />
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Stack>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default VacationRequestModal;
