import styled from "styled-components/macro";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-height: ${(props): string => props.theme.spacing(80)};
  justify-content: space-between;
`;

export const TableContainer = styled.div`
  ${(props): string => props.theme.breakpoints.up("xxl")} {
    table {
      th:not(:nth-child(1)):not(:nth-child(2)) {
        width: 8.5%;
      }
    }
  }
`;
