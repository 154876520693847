import React, { ReactElement, useCallback, useContext } from "react";
import { Grid, Paper } from "@mui/material";
import FileTree from "../properties/components/fileTree";
import { StyledGrid } from "./styled";
import {
  extractItemDataFromNodeId,
  FILE_TREE_ITEM_SELECTOR,
} from "../properties/components/fileTree/utils";
import last from "lodash/last";
import { FileTreeEnum } from "../../constants";
import { findFolderById, prepareData } from "./utils";
import { FileManagementContext } from "./contexts/FileManagementContext";
import { useParams } from "react-router-dom";

const FileManagementFileTree = (): ReactElement => {
  const {
    cachedFolders: folders,
    expanded,
    selected,
    handleFolderClick,
    onRightClick,
    isFolderLoading,
  } = useContext(FileManagementContext);
  const { id } = useParams();

  /* istanbul ignore next */
  const onRightClickInFileTree = useCallback(
    (event: React.MouseEvent<HTMLUListElement, MouseEvent>): void => {
      if (!(event.target instanceof Element) || !folders) return;
      const liElementId =
        event.target.closest(FILE_TREE_ITEM_SELECTOR)?.id || "";
      const nodeId = last(liElementId.split(FileTreeEnum.SEPARATOR));

      if (!nodeId?.includes(FileTreeEnum.FOLDER)) return;
      const id = extractItemDataFromNodeId(nodeId, FileTreeEnum.FOLDER)[0];
      const obj = findFolderById(folders, +id);
      const { name, can_remove } = obj!;

      onRightClick(id.toString(), name, "folder", null, can_remove)(event);
    },
    [folders, onRightClick]
  );

  return (
    <Grid container>
      <StyledGrid item xs={12}>
        <Paper sx={{ p: 2, height: "100%" }}>
          {folders && (
            <FileTree
              data={[prepareData(folders, id)]}
              handleFolderClick={handleFolderClick}
              selected={selected}
              expanded={expanded}
              isLoading={isFolderLoading}
              onRightClick={onRightClickInFileTree}
              minWidth="auto"
              maxWidth="auto"
            />
          )}
        </Paper>
      </StyledGrid>
    </Grid>
  );
};

export default FileManagementFileTree;
