import React, { ReactElement, useState } from "react";
import { Stack } from "@mui/material";
import { FormWrapper } from "./styled";
import { FormMessageInterface } from "../../../types/form";
import { FormMessageInItState } from "../../../constants";
import FormAlert from "../../../ui/formAlert/FormAlert";
import {
  createTenantRequestInitState,
  handleTenantRequestFormSubmit,
} from "./utils";
import { Formik } from "formik";
import Form from "./Form";
import useValidation from "../../../hooks/useValidation";
import * as Yup from "yup";
import PageWrapper from "../../../ui/pageWrapper/PageWrapper";
import { useTranslation } from "react-i18next";
import { route } from "../../../utils/url";

const CreateTenantRequest = (): ReactElement => {
  const { t } = useTranslation();
  const { tenant_request } = useValidation();
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);

  const breadcrumbs = [
    {
      to: route("tenant-requests"),
      name: t("sidebar.tenantRequests"),
    },
  ];

  return (
    <PageWrapper title={t("create")} breadcrumbs={breadcrumbs}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        {formMessage.text && (
          <FormAlert formMessage={formMessage} sx={{ marginBottom: "10px" }} />
        )}
        <FormWrapper>
          <Formik
            initialValues={createTenantRequestInitState}
            validationSchema={Yup.object().shape(tenant_request)}
            onSubmit={handleTenantRequestFormSubmit({ setFormMessage })}
          >
            {(props): ReactElement => <Form {...props} />}
          </Formik>
        </FormWrapper>
      </Stack>
    </PageWrapper>
  );
};

export default CreateTenantRequest;
