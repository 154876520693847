/* istanbul ignore file */
import { FormikValues } from "formik";
import { SendLOIProps, SubmitLOIModalProps } from "./types";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { sendLOI } from "../../../../api/loi";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../constants";
import { LOI } from "../../../../types/be/loi";
import { Company } from "../../../../types/be/company";

export const sendLOIInitState = {
  subject_email: "",
  email: [],
  email_cc: [],
  email_bcc: [],
  message: "",
};

export const handelSendLOI = ({
  row,
  handleCreateModalClose,
  snackbar,
  t,
}: SubmitLOIModalProps) => {
  return async (
    values: SendLOIProps,
    { setStatus }: FormikValues
  ): Promise<void | Response> => {
    const formData = new FormData();
    formData.append("subject_email", values?.subject_email);
    values?.email.forEach((value) => {
      formData.append("email[]", value);
    });
    values?.email_bcc.forEach((value) => {
      formData.append("email_bcc[]", value);
    });
    values?.email_cc.forEach((value) => {
      formData.append("email_cc[]", value);
    });
    formData.append("message", values?.message);
    const response = await sendLOI(row.id, formData);
    const { errors } = await response.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response?.status
      )
    ) {
      handleCreateModalClose();
      snackbar.success(t("transactionManagement.loi.sendLOISuccessfully"));
      return response;
    } else if (response?.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(errors),
      });
    } else {
      snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
    }
  };
};

const createSubjectEmail = (row: Company, keys: (keyof Company)[]): string => {
  return keys
    .map((key) => {
      return row?.[key] ? ` ${row?.[key]}` : "";
    })
    .join("");
};

export const prepareLOISendBeforeForm = (row: LOI): SendLOIProps => {
  return {
    subject_email: `Kaufpreisangebot für das Objekt in${createSubjectEmail(
      row?.company,
      [
        "house_number",
        "street",
        "part_of_city",
        "city",
        "state",
        "country",
        "zip",
      ]
    )}`,
    email: [],
    email_cc: [],
    email_bcc: [],
    message: `${row?.first_line_of_email} \n \n ${row?.message}`,
  };
};
