import http from "../utils/http";

export const getDashboardMain = async (): Promise<Response> => {
  return await http.get("/api/dashboard/main");
};
/* istanbul ignore next */
export const getExpiringTenants = async (params: string): Promise<Response> => {
  return await http.get(`/api/dashboard/expiring-tenants${params}`);
};

export const getTenantRequests = async (): Promise<Response> => {
  return await http.get(`/api/dashboard/tenant-requests`);
};
