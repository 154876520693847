import React, { ReactElement, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  DialogActions,
} from "@mui/material";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
import { CreateCounterDialogProps, CreateCounterInitialValues } from "./types";
import { Form, Formik } from "formik";
import TextField from "../../../../ui/formsFields/textField";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/button/Button";
import { useFetch } from "../../../../hooks/useFetch";
import { getCounterTypes } from "../../../../api/reading";
import { CounterType } from "../../../../types/fe.interfaces";
import { useParams } from "react-router-dom";
import { handleCreateCounter } from "./utils";
import { FormMessageInItState } from "../../../../constants";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import * as Yup from "yup";
import useValidation from "../../../../hooks/useValidation";
import { LoadingBox } from "../list/styled";
import Loader from "../../../Loader";

const CreateCounterDialog = ({
  isVisible,
  setIsVisible,
  onSuccess,
}: CreateCounterDialogProps): ReactElement => {
  const { t } = useTranslation();
  const { data, run, isLoading } = useFetch<CounterType[]>();
  const { areaId } = useParams();
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const { number, type_id } = useValidation();

  const handleClose = (): void => {
    setIsVisible(false);
  };

  const initValues: CreateCounterInitialValues = {
    number: "",
    type_id: "",
    property_area_id: areaId!,
  };

  const afterCreateCounter = (): void => {
    onSuccess();
    handleClose();
  };

  useEffect(() => {
    run(getCounterTypes());
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        open={isVisible}
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle
          title="counter.create.createCounter"
          onClose={handleClose}
        />
        <DialogContent>
          {formMessage.text ? <FormAlert formMessage={formMessage} /> : null}
          {isLoading ? (
            <LoadingBox height={30}>
              <Loader />
            </LoadingBox>
          ) : (
            <Formik
              initialValues={initValues}
              onSubmit={handleCreateCounter(setFormMessage, afterCreateCounter)}
              validationSchema={Yup.object().shape({
                number,
                type_id,
              })}
            >
              {({ status, isSubmitting, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid
                    container
                    sx={{ marginTop: 2 }}
                    direction="column"
                    rowGap={3}
                  >
                    <Grid item xs={12}>
                      <TextField
                        required
                        size="small"
                        name="number"
                        label={t("counter.create.number")}
                        status={status}
                        minHeight="35px"
                        inputProps={{ "data-testid": "counter-number" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        select
                        size="small"
                        name="type_id"
                        label={t("tenant.type")}
                        status={status}
                        minHeight="35px"
                        inputProps={{ "data-testid": "select-counter-type" }}
                      >
                        {data?.map((type: CounterType) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button
                      title={t("create")}
                      isLoading={isSubmitting}
                      color="primary"
                      type="submit"
                      testId="create-counter-button"
                    />
                  </DialogActions>
                </Form>
              )}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateCounterDialog;
