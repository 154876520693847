import React, { ReactElement, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { Paper } from "../styled";
import { route } from "../../../../utils/url";
import { useTranslation } from "react-i18next";
import { getReleaseRoute } from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../../hooks/useFetch";
import Loader from "../../../Loader";
import useSnackbar from "../../../../ui/snackbar1/useSnackbar";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../constants";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import PdfViewer from "../../../../ui/pdfViewer";
import Form from "../createDateVInvoice/Form";
import { Formik } from "formik";
import useValidation from "../../../../hooks/useValidation";
import * as Yup from "yup";
import { DateVInvoice } from "../../../../types/be/invoice";
import { getDateVInvoiceById } from "../../../../api/invoices";
import { handleEditDateVInvoice, prepareInitialValues } from "./utils";
import TabPageWrapper from "../../../../ui/tabPageWrapper";

/* istanbul ignore next */
const EditDateVInvoice = (): ReactElement => {
  const { t } = useTranslation();
  const { id, invoiceId } = useParams();
  const { Snackbar, snackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    property_id,
    datev_creditor_id,
    datev_due_date,
    apportionable_percent,
    datev_invoice_positions,
    repeat_from,
    repeat_to,
    repeat_day,
    repetition_period_id,
    has_creditor_contact,
    dateVInvoice_date,
  } = useValidation();
  const ref = useRef<HTMLObjectElement | null>(null);
  const {
    data: dateVInvoice,
    isLoading,
    isError,
    run: fetchDateVInvoice,
  } = useFetch<DateVInvoice>();

  const afterSuccess = (): void => {
    navigate(getReleaseRoute("invoices", Number(id)));
  };

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("invoices", Number(id)),
      name: t("property.navLinks.invoices"),
    },
  ];

  useEffect(() => {
    if (invoiceId) {
      fetchDateVInvoice(getDateVInvoiceById(invoiceId));
    }
  }, [invoiceId]);

  if (isLoading || !dateVInvoice) {
    return <Loader />;
  }

  return (
    <TabPageWrapper
      title={t("documentRelease.invoice.dateVInvoice.upload")}
      breadcrumbs={breadcrumbs}
      wrap={!id}
    >
      <Grid container justifyContent="center" alignItems="stretch" spacing={4}>
        {isError && (
          <FormAlert
            formMessage={{
              text: SOMETHING_WENT_WRONG_ERROR,
              type: "error",
            }}
            sx={{ marginBottom: "10px" }}
          />
        )}
        <Paper sx={{ position: "relative" }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3.7} ref={ref}>
                  <PdfViewer
                    file={{ fileId: dateVInvoice?.file?.id }}
                    containerRef={ref}
                  />
                </Grid>
                <Grid item xs={8.3} pl={6}>
                  <Formik
                    initialValues={prepareInitialValues(dateVInvoice)}
                    onSubmit={handleEditDateVInvoice({
                      id: invoiceId!,
                      snackbar,
                      t,
                      afterSuccess,
                    })}
                    validationSchema={Yup.object().shape({
                      property_id,
                      datev_creditor_id,
                      due_date: datev_due_date,
                      apportionable_percent,
                      datev_invoice_positions,
                      repeat_from,
                      repeat_to,
                      repeat_day,
                      repetition_period_id,
                      has_creditor_contact,
                      date: dateVInvoice_date,
                    })}
                  >
                    {(props) => <Form {...props} snackbar={snackbar} isEdit />}
                  </Formik>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {Snackbar}
      </Grid>
    </TabPageWrapper>
  );
};

export default EditDateVInvoice;
