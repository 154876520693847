import { FormikValues } from "formik";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import {
  Employee as BEEmployee,
  EmployeeSchedule as BEEmployeeSchedule,
  EmployeeScheduleOption as BEEmployeeScheduleOption,
} from "../../types/be.interfaces";

export enum WeekAction {
  PREVIOUS = "previous",
  NEXT = "next",
}

export type EmployeeOverviewMode =
  | "idle"
  | "new-entry"
  | "vacation-holiday"
  | "update-schedule";

export interface Avatar {
  url: string;
}

export interface EmployeeOptionId {
  HOME_OFFICE: number | undefined;
  VACATION: number | undefined;
  OUT_OF_OFFICE: number | undefined;
  FIELD_SERVICE: number | undefined;
  PUBLIC_HOLIDAY: number | undefined;
}

export interface EmployeeOverviewRowProps {
  row: BEEmployee;
  weekRange: moment.Moment[];
  handleSuccessfulUpdateScheduleSubmission: () => void;
  options: BEEmployeeScheduleOption[] | null;
  employeeOptionId: EmployeeOptionId;
}

export interface EmployeeOverviewToolbarProps {
  openNewEntryModal: () => void;
  openVacationHolidayManagementModal: () => void;
  switchWeeks: (action: string) => () => void;
  handleTableSearch: () => void;
  toggleLocationListModal: () => void;
  openHideEmployeeModal: () => void;
}

export interface HandleUpdateScheduleSubmitProps {
  id?: number;
  isAdmin: boolean;
  formattedDate: string;
  userId: number;
  handleSuccessfulUpdateScheduleSubmission: () => void;
  setOptionId: Dispatch<SetStateAction<number | undefined>>;
  setDescription: Dispatch<SetStateAction<string>>;
  setScheduleId: Dispatch<SetStateAction<number | undefined>>;
}

export interface HandleOptionChangeProps extends FormikValues {
  formattedDate: string;
}

export interface DescriptionModalProps extends FormikValues {
  showDescriptionModal: boolean;
  handleCloseDescriptionModal: () => void;
}

export interface EmployeeOverviewCellProps {
  showDescriptionModal: boolean;
  handleCloseDescriptionModal: () => void;
  options: BEEmployeeScheduleOption[] | null;
  schedules: BEEmployeeSchedule[];
  day: moment.Moment;
  id: number;
  handleSuccessfulUpdateScheduleSubmission: () => void;
  handleOptionClick: (
    formattedDate: string
  ) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleOptionChange: ({
    formattedDate,
    handleChange,
    submitForm,
  }: HandleOptionChangeProps) => (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  isCurrentTableCellSelected: (formattedDate: string) => boolean;
  handleEditIconClick: (formattedDate: string) => () => void;
  ind: number;
  employeeOptionId: EmployeeOptionId;
}

export interface TableGetParams {
  currentPageForHideEmployeeModal: number;
  rowsPerPageForHideEmployeeModal: number;
}

export interface MappedTableGetParams {
  page?: number;
  limit?: number;
}

export interface EmployeeOptionId {
  HOME_OFFICE: number | undefined;
  VACATION: number | undefined;
  OUT_OF_OFFICE: number | undefined;
  FIELD_SERVICE: number | undefined;
  PUBLIC_HOLIDAY: number | undefined;
}
