import { FormikValues } from "formik";
import {
  HandleGeneralDocumentAddContactProps,
  GeneralDocumentAddContactData,
} from "./types";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../constants";
import { updateGeneralDocument } from "../../../../api/generalDocuments";
/* istanbul ignore next */
export const handleGeneralDocumentUpdate = ({
  id,
  setSnackbarData,
  t,
}: HandleGeneralDocumentAddContactProps) => {
  return async (
    data: GeneralDocumentAddContactData,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const formData = new FormData();
    data?.contact_id && formData.append("contact_id", String(data?.contact_id));
    formData.append("_method", "PUT");
    const response = await updateGeneralDocument(id, formData);
    if (response.ok) {
      setStatus({
        success: true,
        errors: {},
      });
      setSnackbarData({
        visible: true,
        text: t("generalDocuments.providerWasAdded"),
        color: "success",
      });
    } else {
      setSnackbarData({
        visible: true,
        text: t(SOMETHING_WENT_WRONG_ERROR),
        color: "error",
      });
      setSubmitting(false);
    }
  };
};
