import styled from "styled-components/macro";
import { grey } from "@mui/material/colors";
import { Link, Grid as MuiGrid } from "@mui/material";

export const StyledGrid = styled(MuiGrid)<{ $fullHeight?: boolean }>`
  width: 100%;
`;

export const TabLink = styled(Link).attrs((props: { disabled?: boolean }) => ({
  disabled: props.disabled || false,
}))`
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  color: ${(props) =>
    props.disabled ? grey[500] : props.theme.tabs.textColor};
  &:hover {
    border-bottom: 2px solid
      ${(props) =>
        props.disabled ? "transparent" : props.theme.tabs.textColor};
    background: transparent;
  }
  padding: 0;
  min-width: auto;
  font-size: 15px;
  border-bottom: 2px solid
    ${(props) => (props.disabled ? grey[500] : "transparent")};
  border-radius: 0;
  line-height: 26px;
  font-weight: bold;
`;
