import styled from "styled-components";
import { Grid, ToggleButtonGroup as MUIToggleButtonGroup } from "@mui/material";
import DialogTitleCustom from "../../../../ui/dialog/DialogTitle";

export const ToggleButtonGroup = styled(MUIToggleButtonGroup)`
  &.error {
    border: 1px solid #f44336;
  }
`;

export const StyledGrid = styled(Grid)`
  display: flex;
  justify-content: center;
`;

export const DialogTitle = styled(DialogTitleCustom)`
  padding-left: ${(props) => props.theme.spacing(9)};
`;

export const AboutTopic = styled.div<{ mb?: number }>`
  margin-bottom: ${(props) => props.theme.spacing(props.mb || 5)};
  font-size: ${(props) => props.theme.spacing(5)};
`;
