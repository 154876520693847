import React, { ReactElement, useEffect, useRef, useState } from "react";
import Form from "./Form";
import { Formik, FormikProps } from "formik";
import { createOfferFormInitState, handleOfferCreate } from "../utils";
import { FormMessageInItState } from "../../../../constants";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import * as Yup from "yup";
import useValidation from "../../../../hooks/useValidation";
import { useNavigate, useParams } from "react-router-dom";
import { route } from "../../../../utils/url";
import { useTranslation } from "react-i18next";
import { Grid, Paper } from "@mui/material";
import { OfferForm } from "../../../../types/fe.interfaces";
import PdfViewer from "../../../../ui/pdfViewer";
import { getReleaseRoute } from "../../utils";
import { useFetch } from "../../../../hooks/useFetch";
import Loader from "../../../Loader";
import { Offer } from "../../../../types/be/offer";
import { getOfferTopicsById } from "../../../../api/offerTopic";
import TabPageWrapper from "../../../../ui/tabPageWrapper";

const OfferCreate = (): ReactElement => {
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const { id, topicId } = useParams();
  const { file_id, contact_id, amount } = useValidation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const title = t("offers.createOffer");
  const formRef = useRef<FormikProps<OfferForm> | null>(null);
  const [selectedFileId, setSelectedFileId] = useState<number>();
  const pdfContainerRef = useRef<HTMLObjectElement | null>(null);
  const [fileName, setFileName] = useState("");
  const [offerData, setOfferData] = useState<OfferForm>(
    createOfferFormInitState
  );
  useEffect(() => {
    formRef.current?.values.file_id &&
      setSelectedFileId(Number(formRef.current?.values.file_id));
  }, [formRef.current?.values.file_id]);

  const {
    data: offersTopics,
    run: runOffers,
    isLoading: offerLoading,
  } = useFetch<Offer>();

  useEffect(() => {
    topicId && runOffers(getOfferTopicsById(Number(topicId)));
  }, []);

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("offers", Number(id)),
      name: t("property.navLinks.offers"),
    },
  ];

  if (offerLoading) {
    return <Loader />;
  }

  return (
    <>
      <TabPageWrapper title={title} breadcrumbs={breadcrumbs} wrap={!id}>
        <Grid container justifyContent={"center"}>
          <Grid item sm={6} xs={12} ref={pdfContainerRef}>
            <PdfViewer
              file={{ fileId: selectedFileId }}
              containerRef={pdfContainerRef}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ pl: 6 }}>
            <Paper sx={{ padding: 6 }}>
              {formMessage.text && (
                <FormAlert
                  formMessage={formMessage}
                  sx={{ marginBottom: "10px" }}
                />
              )}

              <Formik
                initialValues={{
                  ...offerData,
                  property_id:
                    id || offersTopics?.property?.id?.toString() || "",
                }}
                innerRef={formRef}
                validationSchema={Yup.object().shape({
                  file_id,
                  contact_id,
                  amount,
                })}
                onSubmit={handleOfferCreate(
                  id!,
                  topicId!,
                  { setFormMessage },
                  navigate
                )}
              >
                {(props): React.ReactElement => (
                  <Form
                    {...props}
                    propertyId={id}
                    topicId={topicId}
                    fileName={fileName}
                    setFileName={setFileName}
                    setFormInitState={setOfferData}
                    offersTopicsPropertyId={offersTopics?.property?.id}
                  />
                )}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      </TabPageWrapper>
    </>
  );
};

export default OfferCreate;
