import React, { ReactElement, useEffect } from "react";
import { Form, FormikValues } from "formik";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormAlert from "../../../../../ui/formAlert/FormAlert";
import ToggleButtons from "../../../../../ui/toggleButtons";
import TopicDateRangeFilter from "../../../../properties/components/dataRangeFilter";
import Dropdown from "../../../../../ui/dropdown";
import { getProperties } from "../../../../../api/property";
import { generalPropertyOption } from "../../../../../utils/common";
import UIButton from "../../../../../ui/button/Button";
/* istanbul ignore next */
const CreateForm = ({
  status,
  values,
  isSubmitting,
  setFieldValue,
  errors,
  touched,
  inboxData,
  setRange,
  formMessage,
  handleSubmit,
  range,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();

  useEffect(() => {
    inboxData &&
      inboxData.property &&
      setFieldValue("property_id", inboxData.property.id);
    setFieldValue("type", true);
  }, []);

  const options = [
    {
      name: t("insurance.building"),
      value: true,
    },
    {
      name: t("insurance.liability"),
      value: false,
    },
  ];

  return (
    <Form noValidate style={{ width: "400px" }}>
      {formMessage.text && (
        <FormAlert formMessage={formMessage} sx={{ mb: "10px" }} />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ToggleButtons
            value={values.type}
            options={options}
            helperText={
              (touched.type && status?.errors.type) || errors.type
            }
            error={Boolean(
              (touched.type && status?.errors.type) || errors.type
            )}
            onChange={(
              e: React.MouseEvent<HTMLElement>,
              value: boolean | null
            ): void => {
              value !== null && setFieldValue("type", value);
            }}
          />
        </Grid>
        <Grid item sm={6}>
          <TopicDateRangeFilter
            range={range}
            setRange={setRange}
            calenderContainerClass={"fixed-position"}
          />
        </Grid>
        <Grid item xs={12} sm={12} mt={4}>
          <Dropdown
            id="property-autocomplete"
            name="property_id"
            label={t("form.propertyId")}
            placeholder={t("form.propertyId")}
            getOptions={getProperties}
            onSelectOption={(id?: string) => setFieldValue("property_id", id)}
            optionLabel="object_name"
            optionValue="id"
            size="small"
            helperText={
              status?.errors.property_id ||
              (touched.property_id && errors.property_id)
            }
            error={Boolean(
              status?.errors.property_id ||
                (touched.property_id && errors.property_id)
            )}
            defaultOption={[generalPropertyOption]}
            defaultValue={inboxData ? inboxData?.property : null}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={1} sx={{ mt: 4 }}>
          <UIButton
            title={t("save")}
            type="button"
            onClick={handleSubmit}
            fullWidth
            color="success"
            isLoading={isSubmitting}
            disabled={isSubmitting}
            testId="create-topic-save"
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default CreateForm;
