/* istanbul ignore file */

import styled from "styled-components/macro";
import { Link } from "@mui/material";
import { grey } from "@mui/material/colors";

export const Input = styled("input")({
  display: "none",
});

export const TabLink = styled(Link).attrs((props: { disabled?: boolean }) => ({
  disabled: props.disabled || false,
}))`
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  color: ${(props) =>
    props.disabled ? grey[500] : props.theme.tabs.textColor};
  };
  &:hover {
    text-decoration: ${(props) => (props.disabled ? "none" : "underline")};
  }
`;
