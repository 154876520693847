import React, { ReactElement, useCallback } from "react";
import { Status } from "../../../../../../users/list/styled";
import { Tooltip, Box } from "@mui/material";
import { NumericFormat } from "react-number-format";
import {
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  Measurement_Units,
  THOUSAND_SEPARATOR,
} from "../../../../../../../constants";
import {
  AreaTableCellProps,
  TableCellNumberProps,
  TypeDict,
} from "../../../types";
import { TableCell } from "../styled";
import { FormikValues } from "formik";
import { formatTimestamp } from "../../../../../../../utils/date";
import head from "lodash/head";
import { User } from "../../../../../../../types/models";
import { useTranslation } from "react-i18next";

const AreaTableCell = <T,>({
  type,
  children,
  unit,
  sx,
  isActive,
}: FormikValues & AreaTableCellProps<T>): ReactElement => {
  const { t } = useTranslation();

  const MultiTableCellTitle = useCallback(() => {
    if (children.length === 0) {
      return <>{EMPTY_DATA}</>;
    } else if (children.length === 1) {
      const user = head<User>(children);
      return <>{`${user?.first_name} ${user?.last_name}`}</>;
    } else {
      const user = head<User>(children);
      return (
        <Tooltip
          title={children.map(({ id, first_name, last_name }: User) => (
            <Box key={id}>{`${first_name} ${last_name}`}</Box>
          ))}
          placement="top-start"
        >
          <span>
            {user?.first_name} {user?.last_name}{" "}
            <span>
              {t("tenant.selectedMoreThenOneTenantPartOne")}{" "}
              {children.length - 1}{" "}
              {t("tenant.selectedMoreThenOneTenantPartTwo")}
            </span>
          </span>
        </Tooltip>
      );
    }
  }, [children]);

  const typeDict: TypeDict = {
    number: (
      <TableCellNumber unit={unit} sx={{ ...sx }} isActive={isActive}>
        {children}
      </TableCellNumber>
    ),
    date: (
      <TableCell align={"center"} sx={{ ...sx }} $isActive={isActive}>
        {children ? formatTimestamp(children) : EMPTY_DATA}
      </TableCell>
    ),
    checkbox: (
      <TableCell sx={{ ...sx }} $isActive={isActive}>
        {<Status type={children ? "success" : "error"} />}
      </TableCell>
    ),
    multi: (
      <TableCell $isActive={isActive}>
        {children && Array.isArray(children) && <MultiTableCellTitle />}
      </TableCell>
    ),
    text: (
      <TableCell $isActive={isActive}>
        {children ? children : EMPTY_DATA}
      </TableCell>
    ),
    answer: (
      <TableCell $isActive={isActive}>{children ? "Yes" : "No"}</TableCell>
    ),
  };
  return typeDict[type];
};

export const TableCellNumber = ({
  unit,
  children = 0,
  sx,
  isActive,
}: TableCellNumberProps): ReactElement => {
  return (
    <TableCell align={"right"} sx={{ ...sx }} $isActive={isActive}>
      <NumericFormat
        thousandSeparator={THOUSAND_SEPARATOR}
        decimalSeparator={DECIMAL_SEPARATOR}
        displayType={"text"}
        value={children}
      />{" "}
      {children !== undefined && children !== null
        ? unit || Measurement_Units.EURO
        : /*istanbul ignore next*/ EMPTY_DATA}
    </TableCell>
  );
};

export default AreaTableCell;
