import React, { ReactElement } from "react";
import { Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { User } from "../../../../../types/models";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { UsersFilterProps } from "../../types";
import { TextField } from "@mui/material";
import { SelectBox } from "./../styled";
import { StyledPaper } from "../../../../contacts/autocomplete/styled";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const UsersFilter = ({
  users,
  setSelectedUsers,
}: UsersFilterProps): ReactElement => {
  const { t } = useTranslation();

  const handleChange = (value: User[]): void => {
    const usersId = value.map(({ id }: User) => id);
    setSelectedUsers(usersId);
  };

  return (
    <Autocomplete
      id="activity_user_selection"
      options={users!}
      multiple
      data-testid="search-user"
      disableCloseOnSelect
      getOptionLabel={(option: User) =>
        `${option.first_name} ${option.last_name}`
      }
      PaperComponent={StyledPaper}
      onChange={(_, value: User[]) => {
        handleChange(value);
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.first_name} {option.last_name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={t("users")}
          placeholder={t("search")}
        />
      )}
      renderTags={(list) => {
        const displayList = list.map((item) => item.first_name);
        return (
          <SelectBox>
            <div>{`${t("selected")} ${displayList.length} ${t("users")}`}</div>
          </SelectBox>
        );
      }}
    />
  );
};

export default UsersFilter;
