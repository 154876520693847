import React, { ReactElement, useEffect, useState } from "react";
import { Formik } from "formik";
import { getPermissionCategories } from "../../../../api/permissions";
import { useFetch } from "../../../../hooks/useFetch";
import { PermissionsCategory as BEPermissionsCategory } from "../../../../types/be/permissions";
import { Grid } from "@mui/material";
import { FormMessageInItState } from "../../../../constants";
import { createRoleFormInitState, handleCreateRoleFormSubmit } from "./utils";
import Loader from "../../../Loader";
import { PermissionCodesDict } from "../types";
import { FormMessageInterface } from "../../../../types/form";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import useValidation from "../../../../hooks/useValidation";
import * as Yup from "yup";
import Form from "./Form";
import PostCreateModal from "./postCreateModal";
import { RoleDetails } from "../../../../types/be/role";

/* istanbul ignore next */
const CreateRole = (): ReactElement => {
  const [isPostCreateModalOpen, setIsPostCreateModalOpen] = useState(false);
  const [createdRole, setCreatedRole] = useState<RoleDetails | null>(null);
  const { name } = useValidation();
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const [permissionCodes, setPermissionCodes] =
    useState<PermissionCodesDict | null>(null);

  const {
    data: permissionCategories,
    run: runPermissionCategories,
    isLoading: isPermissionCategoriesLoading,
  } = useFetch<BEPermissionsCategory[]>();

  useEffect(() => {
    runPermissionCategories(getPermissionCategories());
  }, []);

  useEffect(() => {
    if (!permissionCategories) return;
    const auxPermissionCodes: PermissionCodesDict = {};
    const mapped = permissionCategories
      .map((category) => {
        const firstLevel = category.groups.map((group) => {
          const secondLevel = group.permissions.map((el) => el.code);
          return secondLevel;
        });
        return firstLevel.flat(1);
      })
      .flat(1)
      .filter((item, pos, self) => self.indexOf(item) == pos);

    mapped.forEach((code) => {
      auxPermissionCodes[code] = false;
    });

    setPermissionCodes(auxPermissionCodes);
  }, [permissionCategories]);

  const handlePostCreateModalClose = (): void => {
    setIsPostCreateModalOpen(false);
    setCreatedRole(null);
  };

  const postCreateCallback = (data: RoleDetails): void => {
    setCreatedRole(data);
    setIsPostCreateModalOpen(true);
  };

  if (isPermissionCategoriesLoading) return <Loader />;

  return (
    <Grid container>
      <Grid item xs={12}>
        <>
          {formMessage.text && (
            <FormAlert
              formMessage={formMessage}
              sx={{ marginBottom: "10px" }}
            />
          )}
          {permissionCategories && permissionCodes && (
            <Formik
              initialValues={createRoleFormInitState}
              onSubmit={handleCreateRoleFormSubmit({
                setFormMessage,
                postCreateCallback,
                permissionCodes,
              })}
              validationSchema={Yup.object().shape({
                name,
              })}
              enableReinitialize
            >
              {(props): React.ReactElement => (
                <Form
                  {...props}
                  //setIsSnackbarVisible={setIsSnackbarVisible}
                  permissionCategories={permissionCategories}
                  permissionCodes={permissionCodes}
                  setPermissionCodes={setPermissionCodes}
                />
              )}
            </Formik>
          )}
        </>
      </Grid>
      <PostCreateModal
        createdRole={createdRole}
        isOpen={isPostCreateModalOpen}
        onClose={handlePostCreateModalClose}
        setIsPostCreateModalOpen={setIsPostCreateModalOpen}
      />
    </Grid>
  );
};

export default CreateRole;
