import React, { ReactElement, useContext } from "react";
import { getFolder, moveFolder } from "../../api/folders";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, Grid } from "@mui/material";
import head from "lodash/head";
import FileTree from "../properties/components/fileTree";
import { extractItemDataFromNodeId } from "../properties/components/fileTree/utils";
import { Formik, Form } from "formik";
import TextField from "../../ui/formsFields/textField";
import * as Yup from "yup";
import {
  findFolderById,
  handleItemRenameOrCreateSubmit,
  handleServerResponse,
  langDict,
  mutateFolder,
} from "./utils";
import Button from "../../ui/button/Button";
import useValidation from "../../hooks/useValidation";
import DialogTitle from "../../ui/dialog/DialogTitle";
import { FileTreeFolder } from "../../types/be.interfaces";
import { moveFile } from "../../api/files";
import { FileManagementContext } from "./contexts/FileManagementContext";
const FileManagementDialog = (): ReactElement => {
  const { t } = useTranslation();

  const {
    cachedFolders: folders,
    runFolder,
    setFolders,
    selected,
    setIsSuccess,
    actionItemId,
    actionItemType,
    dialogVisible,
    setDialogVisible,
    handleCloseDialog,
    mode,
    actionItemName,
    setActionItemName,
    runFolderSortedByDate,
  } = useContext(FileManagementContext);

  const {
    companyCreate: { name },
  } = useValidation();

  const handleMoveToFolderClick = async (id: string): Promise<void> => {
    let response;
    if (actionItemType === "folder") {
      response = await moveFolder(id, actionItemId!);
    } else {
      response = await moveFile({ file_id: [+actionItemId!], folder_id: +id });
    }
    await handleServerResponse(response, setIsSuccess);
    handleParentFolderUpdateResponse()(id);

    await runFolderSortedByDate(head(extractItemDataFromNodeId(selected)));
    setDialogVisible(false);
  };

  const handleParentFolderUpdateResponse =
    (forceUpdate = true) =>
    async (id: string): Promise<void> => {
      const response = await getFolder(id);
      const json = await response.json();
      [200, 201].includes(response.status) &&
        handleParentFolderUpdate(id, json, forceUpdate);
    };

  const handleParentFolderUpdate = (
    id: string,
    json: { data: FileTreeFolder },
    forceUpdate: boolean
  ): void => {
    const auxFolders: FileTreeFolder[] = JSON.parse(JSON.stringify(folders));
    const {
      data: { children, files },
    } = json;
    const folder = findFolderById(auxFolders, +id);
    const hasAlreadyChildren = folder?.has_children;
    if (!forceUpdate && hasAlreadyChildren) return;
    folder && mutateFolder({ folder, children, files });
    setFolders((prevState) => ({ ...prevState, data: auxFolders }));
  };

  return (
    <Dialog
      maxWidth={"lg"}
      open={dialogVisible}
      onClose={handleCloseDialog()}
      aria-labelledby="max-width-dialog-title"
      data-testid="file-management-dialog"
    >
      <DialogTitle title={t(langDict[mode])} onClose={handleCloseDialog()} />
      <DialogContent>
        {["rename", "create"].includes(mode) && (
          <Formik
            initialValues={{
              id: actionItemId!,
              name: actionItemName,
            }}
            validationSchema={Yup.object().shape({ name })}
            onSubmit={handleItemRenameOrCreateSubmit({
              selected,
              type: actionItemType,
              mode,
              handleCloseDialog,
              setFolders,
              setIsSuccess,
              runFolder,
            })}
            enableReinitialize
          >
            {({ status, isSubmitting }): React.ReactElement => (
              <Form>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                >
                  <Grid item mb={4}>
                    <TextField
                      autoFocus
                      name="name"
                      status={status}
                      size="small"
                      onChange={(e) => {
                        setActionItemName(e.target.value);
                      }}
                      sx={{ "min-width": "250px" }}
                      inputProps={{
                        "data-testid": "create-rename-input",
                      }}
                      required
                    />
                  </Grid>
                  <Grid
                    container
                    display="flex"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      title={t("cancel")}
                      onClick={handleCloseDialog()}
                      type="button"
                      testId="cancel-button"
                      variant="text"
                    />
                    <Button
                      color="success"
                      testId="edit-user-submit"
                      title={t(mode === "rename" ? "update" : "create")}
                      type="submit"
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
        {mode === "move" && folders && (
          <FileTree
            data={[
              {
                children: folders,
                files: [],
                id: 0,
                name: "",
                can_remove: true,
              },
            ]}
            iconClickExpandOnly={true}
            onIconClick={handleParentFolderUpdateResponse(false)}
            handleFolderClick={handleMoveToFolderClick}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FileManagementDialog;
