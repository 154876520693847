import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";
/* istanbul ignore next */
export const ObjectLink = styled(Link)`
  color: ${(props): string => props.theme.palette.secondary.main};
  text-decoration: none;
`;
/* istanbul ignore next */
export const AddressBox = styled(Box)`
  min-width: ${(props): string => props.theme.spacing(50)};
`;
/* istanbul ignore next */
export const MoreVerticalButton = styled(Button)`
  min-width: ${(props): string => props.theme.spacing(5)};
  max-width: ${(props): string => props.theme.spacing(10)};
`;
