import React from "react";
import AuthGuard from "../../components/guards/AuthGuard";
import DashboardLayout from "../../layouts/dashboard";
import PropertiesList from "../../components/properties/list/PropertyList";
import CreateProperty from "../../components/properties/create/CreateProperty";
import CreateReading from "../../components/properties/counters/readings/create";
import RequestShow from "../../components/properties/requests/show/RequestShow";
import EditRequest from "../../components/properties/requests/edit";
import CreateRequest from "../../components/properties/requests/create";
import maintenance from "./maintenance";
import {
  PermissionsCreate,
  PermissionsRead,
  PermissionsUpdate,
} from "../../constants";
import AccessGuard from "../../components/guards/AccessGuard";
import PropertyWrapper from "../../components/properties/propertyWrapper";
import AreaEdit from "../../components/properties/areas/edit";
import { AreaEditProvider } from "../../components/properties/areas/edit/AreaEditContext";

export default {
  path: "/:client/properties",

  element: (
    <AuthGuard clientModuleName="properties">
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: (
        <AccessGuard permissions={[PermissionsRead.PROPERTY]}>
          <PropertiesList />
        </AccessGuard>
      ),
    },
    {
      path: "create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.PROPERTY]}>
          <CreateProperty />
        </AccessGuard>
      ),
    },
    {
      path: ":id/edit/:tab",
      element: (
        <AccessGuard permissions={[PermissionsRead.PROPERTY]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/tenants/:tenantId/edit/:tab/:counterId/readings/create",
      element: <CreateReading />,
    },
    {
      path: ":id/object-data-sheet/:tab",
      element: (
        <AccessGuard permissions={[PermissionsRead.PROPERTY]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/contracts",
      element: (
        <AccessGuard permissions={[PermissionsRead.CONTRACT]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/contracts/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.CONTRACT]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/contracts/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.CONTRACT]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/contracts/:contractId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.CONTRACT]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/contracts/:contractId/show",
      element: (
        <AccessGuard permissions={[PermissionsRead.CONTRACT]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/offers",
      element: (
        <AccessGuard permissions={[PermissionsRead.OFFER]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/offers/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.OFFER]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/offers/:topicId/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.OFFER]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/offers/:offerId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.OFFER]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/offers/:offerId/show",
      element: (
        <AccessGuard permissions={[PermissionsRead.OFFER]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/insurances",
      element: (
        <AccessGuard permissions={[PermissionsRead.INSURANCE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/insurances/:type/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.INSURANCE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/insurances/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.INSURANCE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/insurances/:insuranceId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.INSURANCE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/insurances/:insuranceId/show",
      element: (
        <AccessGuard permissions={[PermissionsRead.INSURANCE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/insurances/:topicId/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INSURANCE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/tenants/:areaId/requests/:requestId/show",
      element: <RequestShow />,
    },
    {
      path: ":id/tenants/:areaId/requests/:requestId/edit",
      element: <EditRequest />,
    },
    {
      path: ":id/tenants/:areaId/requests/create",
      element: <CreateRequest />,
    },
    {
      path: ":id/inbox",
      element: (
        <AccessGuard permissions={[PermissionsRead.INBOX]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/inbox/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.INBOX]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/inbox/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INBOX]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    // General documents
    {
      path: ":id/general-documents",
      element: (
        <AccessGuard permissions={[PermissionsRead.GENERAL_DOCUMENT]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/general-documents/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.GENERAL_DOCUMENT]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/general-documents/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.GENERAL_DOCUMENT]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/general-documents/:documentId/show",
      element: (
        <AccessGuard permissions={[PermissionsRead.GENERAL_DOCUMENT]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/file-management",
      element: (
        <AccessGuard permissions={[PermissionsRead.FILE_MANAGEMENT]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/api-datev/:tab",
      element: (
        <AccessGuard permissions={[PermissionsCreate.PROPERTY]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/api-datev/creditor/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.PROPERTY]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/api-datev/debtor/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.PROPERTY]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/api-datev/debit-account/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.PROPERTY]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    ...maintenance,
    {
      path: ":id/invoices",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INVOICE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/:invoiceId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.INVOICE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/:invoiceId/show",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/reminders/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INVOICE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/reminders",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/reminders/:status",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/datev/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.DATEV_INVOICE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/datev/:invoiceId/show",
      element: (
        <AccessGuard permissions={[PermissionsRead.DATEV_INVOICE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/invoices/datev/:invoiceId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.DATEV_INVOICE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/outgoing-invoices",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/outgoing-invoices/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.INVOICE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/outgoing-invoices/:invoiceId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.INVOICE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/outgoing-invoices/:invoiceId/show",
      element: (
        <AccessGuard permissions={[PermissionsRead.INVOICE]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/areas",
      element: (
        <AccessGuard permissions={[PermissionsRead.AREA]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/areas/:areaId/edit/:tab",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.AREA]}>
          <PropertyWrapper />
        </AccessGuard>
      ),
    },
    {
      path: ":id/areas/:areaId/edit/:tab/:counterId/readings",
      element: (
        <AreaEditProvider>
          <AccessGuard permissions={[PermissionsUpdate.AREA]}>
            <AreaEdit />
          </AccessGuard>
        </AreaEditProvider>
      ),
    },
    {
      path: ":id/areas/:areaId/edit/:tab/:counterId/readings/create",
      element: <CreateReading />,
    },
  ],
};
