/* istanbul ignore file */

import React, { ReactElement, useState } from "react";
import { Property } from "../../../../types/be/property";
import { FormWrapper } from "../general/styled";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import { Formik } from "formik";
import {
  handleUpdatePropertyFormSubmit,
  mapBEPropertyToFEPropertyUpdate,
} from "../utils";
import { FormMessageInItState } from "../../../../constants";
import Form from "./Form";
import useValidation from "../../../../hooks/useValidation";
import * as Yup from "yup";

const ExitAndRepaymentBond = ({
  property,
}: {
  property: Property;
}): ReactElement => {
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const { property_value_development } = useValidation();

  return (
    <FormWrapper>
      {formMessage.text && (
        <FormAlert formMessage={formMessage} sx={{ mb: 2.5 }} />
      )}
      {property && (
        <Formik
          initialValues={mapBEPropertyToFEPropertyUpdate(property)}
          validationSchema={Yup.object().shape({
            property_value_development,
          })}
          onSubmit={handleUpdatePropertyFormSubmit({
            setFormMessage,
          })}
          enableReinitialize
        >
          {(props): ReactElement => (
            <Form
              {...{ ...props, ...mapBEPropertyToFEPropertyUpdate(property) }}
            />
          )}
        </Formik>
      )}
    </FormWrapper>
  );
};

export default ExitAndRepaymentBond;
