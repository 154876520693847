import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import { FormikValues } from "formik/dist/types";
import { Form, useFormikContext } from "formik";
import OfferAutoCompleteField from "../offers/autocomplete";
import TextFieldCustom from "../../../ui/formsFields/textField";
import SwitchWrapper from "../../../ui/formsFields/switch";
import { FormSection } from "../../profile/tabs/generalInformation/styled";
import { InvoiceFormProps } from "./types";
import SelectOrCreateContactsAutocompleteField from "../../contacts/autocomplete";
import {
  CURRENCIES,
  Measurement_Units,
  FORMAT_NUMBER_DE,
  INPUT_MIN_HEIGHT,
} from "../../../constants";
import Text from "../../../ui/formsFields/text";
import {
  calculatePeriodTotal,
  calculateDateDifference,
  prepareFormKey,
} from "./utils";
import { getProperties } from "../../../api/property";
import Dropdown from "../../../ui/dropdown";
import { generalPropertyOption } from "../../../utils/common";
import DatePicker from "../../../ui/datePicker";
import { getAreas } from "../../../api/area";
import { AutocompleteOption } from "../../../ui/dropdown/types";

function InvoiceForm2({
  errors,
  status,
  handleBlur,
  handleChange,
  handleSubmit,
  touched,
  setTouched,
  values,
  propertyId,
  frStatusId,
  setOuterState,
  toggleSubmit,
  setPreparedDict,
  setContactIdDict,
  contactIdDict,
  inboxData,
  invoicePeriod,
}: FormikValues & InvoiceFormProps): ReactElement {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setPreparedDict(Boolean(!Object.keys(errors).length));
  }, [toggleSubmit, errors, values]);

  useEffect(() => {
    setTouched({ contact_id: true });
  }, [toggleSubmit, errors, values]);

  const totalValue = useMemo(() => {
    const selectedPeriod = invoicePeriod?.find((item: { id: number }) => {
      if (item?.id === values?.repetition_period_id) return item;
    });

    return Number(
      selectedPeriod &&
        calculatePeriodTotal({
          date: values.repeat_from,
          until: values.repeat_to,
          monthly: selectedPeriod.period,
          amount: values.amount,
        })
    ).toFixed(2);
  }, [values]);

  const checkDateDifference = useMemo(() => {
    return Number(
      calculateDateDifference({
        from: values.repeat_from,
        to: values.repeat_to,
      })
    );
  }, [values]);

  const checkDisabledInvoicePeriod = useCallback(
    (period) => {
      const periodValue = invoicePeriod?.find((item: { name: string }) => {
        if (item?.name === period) return item;
      });
      return periodValue && checkDateDifference < periodValue?.period;
    },
    [values]
  );

  const changeOuterState = (field: string, value: unknown): void => {
    setOuterState && setOuterState(field, value);
  };

  useEffect(() => {
    inboxData &&
      inboxData.property &&
      changeOuterState("property_id", inboxData.property.id);
  }, []);

  useEffect(() => {
    setFieldValue("total", Number(totalValue));
    changeOuterState("total", Number(totalValue));
  }, [totalValue]);

  useEffect(() => {
    changeOuterState("contact_id", inboxData?.contact?.id || values.contact_id);
  }, [values.contact_id]);

  useEffect(() => {
    changeOuterState("offer_id", values.offer_id);
  }, [values.offer_id]);

  useEffect(() => {
    changeOuterState("amount", values.amount);
  }, [values.amount]);

  useEffect(() => {
    changeOuterState("bic", values.bic);
  }, [values.bic]);

  useEffect(() => {
    changeOuterState("iban", values.iban);
  }, [values.iban]);

  const defaultPropertyOptions = [generalPropertyOption];

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <FormSection>
        <Grid container spacing={6}>
          {!propertyId ? (
            <Grid item sm={12} lg={6}>
              <Dropdown
                id="property-autocomplete"
                name="property_id"
                placeholder={t("documentRelease.invoice.searchProperty")}
                getOptions={getProperties}
                onSelectOption={(
                  id?: string,
                  values?: AutocompleteOption | null
                ) => {
                  changeOuterState("property_id", id);
                  changeOuterState("property", values);
                }}
                optionLabel="object_name"
                size="medium"
                defaultValue={values?.property || null}
                defaultOption={defaultPropertyOptions}
                error={Boolean(
                  status?.errors?.property_id || errors.property_id
                )}
                helperText={errors?.property_id || status?.errors?.property_id}
                enableReInitialization
              />
            </Grid>
          ) : null}
          <Grid item sm={12} lg={6}>
            <Dropdown
              id="property-area-autocomplete"
              name="area_id"
              value={values?.area_id || ""}
              label={t("counter.searchArea")}
              placeholder={t("counter.searchArea")}
              getOptions={getAreas}
              onSelectOption={(id?: string) => {
                changeOuterState("area_id", id);
                setFieldValue("area_id", id);
              }}
              size="medium"
              defaultValue={values?.area}
              error={Boolean(status?.errors?.area_id || errors?.area_id)}
              helperText={errors?.area_id || status?.errors?.area_id}
              disabled={!Number(values?.property_id) && !propertyId}
              isAreaSearch={true}
              propertyId={values?.property_id || propertyId}
              isShowAllOption={true}
            />
          </Grid>
          <Grid item sm={12} lg={6}>
            <TextField
              label={t("documentRelease.invoice.number")}
              value={values.number || ""}
              name="number"
              required
              fullWidth
              size="medium"
              inputProps={{ "data-testid": "number" }}
              onChange={(e) => {
                changeOuterState("number", e.target.value);
              }}
              error={Boolean(status?.errors.number || errors.number)}
              helperText={status?.errors.number || errors.number}
            />
          </Grid>
          <Grid item sm={12} lg={6}>
            <DatePicker
              name="date"
              label={t("documentRelease.invoice.date")}
              status={status}
              onChangeValue={(date) => {
                changeOuterState("date", date);
              }}
              required
              disableFuture
              dataTestId={"invoicedate-input"}
              touchedValue={true}
            />
          </Grid>
          <Grid item xl={6} sm={12} sx={{ position: "relative" }}>
            <SelectOrCreateContactsAutocompleteField
              {...{
                errors,
                values: {
                  ...values,
                  contact: inboxData?.contact
                    ? inboxData.contact
                    : contactIdDict[prepareFormKey(values.file_id)],
                },
                touched,
                status,
                handleBlur,
                handleChange,
                setFieldValue,
                setOutState: setContactIdDict,
              }}
              required={true}
              label={t("documentRelease.invoice.provider")}
              placeholder={t("documentRelease.invoice.provider")}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <DatePicker
              name="due_date"
              label={t("optionalColumnsNames.due_date")}
              status={status}
              onChangeValue={(date) => {
                changeOuterState("due_date", date);
              }}
              dataTestId={"dueDate-input"}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
              id="currency"
              name="currency"
              select
              required
              label={t("documentRelease.invoice.currency")}
              value={values.currency_code}
              onChange={(e) => {
                changeOuterState("currency_code", e.target.value);
              }}
              error={Boolean(
                status?.errors.currency_code || errors.currency_code
              )}
              helperText={status?.errors.currency_code || errors.currency_code}
              data-testid="property-type"
              fullWidth
            >
              {CURRENCIES?.map((currency: string) => (
                <MenuItem key={`${currency}`} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={12}>
            <TextFieldCustom
              required
              name="amount"
              allowNegative
              label={t("documentRelease.invoice.invoiceAmount")}
              value={values.amount ?? ""}
              error={Boolean(status?.errors.amount || errors.amount)}
              helperText={status?.errors.amount || errors.amount}
              fullWidth
              inputProps={{
                "data-testid": "amount",
                filter: "formatNum",
                unit: Measurement_Units.EURO,
              }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextFieldCustom
              name="iban"
              label={t("documentRelease.invoice.iban")}
              value={values.iban}
              fullWidth
              status={status}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextFieldCustom
              name="bic"
              allowNegative
              label={t("documentRelease.invoice.bic")}
              value={values.bic}
              status={status}
              fullWidth
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <Text
              name="file_name"
              label={t("contracts.fileName")}
              value={values.file_name}
              fullWidth
              inputProps={{
                "data-testid": "file_name",
              }}
              sx={{ minHeight: INPUT_MIN_HEIGHT }}
              status={status}
              onChange={(e) => {
                changeOuterState("file_name", e.target.value);
              }}
              disabled={!!values.dynamic_name}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <Box sx={{ pt: 2 }}>
              <SwitchWrapper
                name="dynamic_name"
                label={t("documentRelease.invoice.dynamicFileName")}
                checked={!!values.dynamic_name}
                data-testid="dynamic-name-invoice"
                onChange={(
                  e: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  > & {
                    target: { checked: boolean };
                  }
                ): void => {
                  const { checked } = e.target;
                  changeOuterState("dynamic_name", checked);
                }}
              />
            </Box>
          </Grid>
          <Grid item md={6} sm={12}>
            <OfferAutoCompleteField
              {...{
                errors,
                values,
                touched,
                status,
                handleBlur,
                handleChange,
                setFieldValue,
                propertyId: values?.property_id,
                frStatusId,
              }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
              name="comment"
              label={t("documentRelease.invoice.show.comment")}
              minRows={4}
              multiline
              fullWidth
              inputProps={{ "data-testid": "comment" }}
              onChange={(e) => {
                changeOuterState("comment", e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection>
        <Grid container spacing={6}>
          <Grid item xl={4} md={6} sm={12}>
            <SwitchWrapper
              name="is_permanent"
              label={t("documentRelease.invoice.isPermanentInvoice")}
              checked={!!values.is_permanent}
              data-testid="is-permanent-invoice"
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> & {
                  target: { checked: boolean };
                }
              ): void => {
                const { checked } = e.target;
                changeOuterState("is_permanent", checked);
                if (!checked) {
                  changeOuterState(
                    "is_automatic_release_repeating_invoice",
                    false
                  );
                }
              }}
            />
          </Grid>
          <Grid item xl={4} md={6} sm={12}>
            <SwitchWrapper
              name="is_debited_automatically"
              label={t("documentRelease.invoice.isDebitedAutomatically")}
              checked={!!values.is_debited_automatically}
              data-testid="is-debited-automatically"
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> & {
                  target: { checked: boolean };
                }
              ): void => {
                const { checked } = e.target;
                changeOuterState("is_debited_automatically", checked);
              }}
            />
          </Grid>
          <Grid item xl={4} md={6} sm={12}>
            <SwitchWrapper
              name="is_apportionable"
              label={t("documentRelease.invoice.isApportionable")}
              checked={!!values.is_apportionable}
              data-testid="is-apportionable"
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> & {
                  target: { checked: boolean };
                }
              ): void => {
                const { checked } = e.target;
                changeOuterState("is_apportionable", checked);
              }}
            />
          </Grid>
          <Grid item xl={4} md={6} sm={12}>
            <SwitchWrapper
              name="is_confidential"
              label={t("documentRelease.invoice.isConfidentialInvoice")}
              checked={!!values.is_confidential}
              data-testid="is-confidential-invoice"
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> & {
                  target: { checked: boolean };
                }
              ): void => {
                const { checked } = e.target;
                changeOuterState("is_confidential", checked);
              }}
            />
          </Grid>
          <Grid item xl={4} md={6} sm={12}>
            <SwitchWrapper
              name="is_active"
              label={t("documentRelease.invoice.isActiveInvoice")}
              checked={!!values.is_active}
              data-testid="is-active-invoice"
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> & {
                  target: { checked: boolean };
                }
              ): void => {
                const { checked } = e.target;
                changeOuterState("is_active", checked);
              }}
            />
          </Grid>
          {values.is_permanent && (
            <Grid item xl={4} md={6} sm={12}>
              <SwitchWrapper
                name="is_automatic_release_repeating_invoice"
                label={t(
                  "documentRelease.invoice.isAutomaticReleaseRepeatingInvoice"
                )}
                checked={!!values.is_automatic_release_repeating_invoice}
                data-testid="is_automatic_release_repeating_invoice"
                onChange={(
                  e: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  > & {
                    target: { checked: boolean };
                  }
                ): void => {
                  const { checked } = e.target;
                  changeOuterState(
                    "is_automatic_release_repeating_invoice",
                    checked
                  );
                }}
              />
            </Grid>
          )}
        </Grid>
      </FormSection>
      <FormSection>
        <Grid container spacing={6}>
          {values.is_permanent && (
            <>
              <Grid item lg={4} sm={12}>
                <DatePicker
                  name="repeat_from"
                  label={t("documentRelease.invoice.repeatFrom")}
                  status={status}
                  onChangeValue={(date) => {
                    changeOuterState("repeat_from", date);
                  }}
                  minDate={new Date()}
                  required
                  dataTestId={"repeat-from-input"}
                />
              </Grid>
              <Grid item lg={4} sm={12}>
                <DatePicker
                  name="repeat_to"
                  label={t("documentRelease.invoice.repeatTo")}
                  status={status}
                  onChangeValue={(date) => {
                    changeOuterState("repeat_to", date);
                  }}
                  minDate={new Date()}
                  dataTestId={"repeat-to-input"}
                />
              </Grid>
              <Grid item lg={4} sm={12}>
                <TextFieldCustom
                  label={t("documentRelease.invoice.repeatDay")}
                  value={values.repeat_day || ""}
                  name="repeat_day"
                  type="number"
                  required
                  fullWidth
                  size="medium"
                  inputProps={{ "data-testid": "repeat_day" }}
                  onChange={(e) => {
                    changeOuterState("repeat_day", e.target.value);
                  }}
                  status={status}
                />
              </Grid>
              <Grid item lg={4} sm={12}>
                <Text
                  name="repetition_period_id"
                  select
                  required
                  label={t("documentRelease.invoice.periodType")}
                  data-testid="period-type-input"
                  status={status}
                  onChange={(e) => {
                    changeOuterState("repetition_period_id", e.target.value);
                  }}
                >
                  {invoicePeriod?.map(
                    (period: { id: number; name: string }) => (
                      <MenuItem
                        key={`${period?.name}`}
                        value={period?.id}
                        disabled={checkDisabledInvoicePeriod(period.name)}
                      >
                        {period.name}
                      </MenuItem>
                    )
                  )}
                </Text>
              </Grid>
              <Grid item lg={4} sm={12}>
                <TextFieldCustom
                  name="total"
                  label={t("documentRelease.invoice.total")}
                  disabled
                  value={values.total}
                  inputProps={{
                    "data-testid": "total-input",
                    filter: FORMAT_NUMBER_DE,
                    unit: Measurement_Units.EURO,
                  }}
                />
              </Grid>
            </>
          )}
          {values.is_apportionable && (
            <Grid item lg={6} sm={12}>
              <TextField
                fullWidth
                type="number"
                required={values.is_apportionable}
                name="apportionable_percent"
                label={t("documentRelease.invoice.apportionablePercent")}
                value={values.apportionable_percent}
                inputProps={{
                  "data-testid": "apportionable_percent",
                  max: 100,
                  min: 0,
                }}
                onChange={(e) => {
                  changeOuterState("apportionable_percent", e.target.value);
                }}
                error={Boolean(
                  status?.errors.apportionable_percent ||
                    errors.apportionable_percent
                )}
                helperText={
                  status?.errors.apportionable_percent ||
                  errors.apportionable_percent
                }
              />
            </Grid>
          )}
        </Grid>
      </FormSection>
    </Form>
  );
}

export default InvoiceForm2;
