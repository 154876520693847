import Stats from "./stats/Stats";
import React, { ReactElement, useCallback, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Measurement_Units } from "../../constants";
import DoughnutChart from "./invoicesWidget/DoughnutChart";
import InsuranceDoughnutChart from "./insurancesWidget/DoughnutChart";
import OfferDoughnutChart from "./offersWidget/DoughnutChart";
import TenantTicketDoughnutChart from "./tenantTicketsWidget/DoughnutChart";
import ContractDoughnutChart from "./contractsWidget/DoughnutChart";
import PropertiesMapWidget from "./properties";

import InvoiceListDialog from "./invoiceListDialog";
import ExpiringTenants from "./expiringTenants/list/ExpiringTenants";
import { Layout } from "react-grid-layout";
import WidgetsIcon from "@mui/icons-material/Widgets";
import map from "lodash/map";
import {
  dashboardLockInitState,
  dashboardWidgetsInitData,
  deleteWidgetIconStyle,
  layoutInitState,
  permissionsToRead,
  responsiveLayoutInitSettings,
  responsiveLayoutInitState,
  WIDGET_IDS,
  WIDGET_IDS_MODULE,
} from "./utils";
import { Checkbox, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  DashboardLockSection,
  WidgetsList,
  WidgetsSideBarButton,
} from "./styled";
import kebabCase from "lodash/kebabCase";
import InsuranceListDialog from "./insuranceListDialog";
import OfferListDialog from "./offerListDialog";
import ContractListDialog from "./contractListDialog";
import OpenCommentsWidget from "./openCommentsWidget";
import OpenCommentsListDialog from "./openCommentsWidget/Dialog";
import { OpenCommentsListWidget } from "../../types/be/comment";
import {
  ContractDoughnutChartData,
  InsuranceDoughnutChartData,
  InvoiceDoughnutChartData,
  OfferDoughnutChartData,
} from "../../types/be/dashboard";
import TenantRequestsList from "./tenantRequestsDialog";
import BankAccountPayments from "./bankAccountPaymentsWidget";
import useAccessControl from "../../hooks/useAccessControl";
import useAppSelector from "../../hooks/useAppSelector";
import { useNavigate } from "react-router-dom";
import { route } from "../../utils/url";
import BankAccountsListDialog from "./bankAccountsListDialog";
import { updateUserConfiguration } from "../../api/users";
import { dashboardConfigurationsProps } from "./types";
import ApiDATEvModal from "./apiDATEvWidget";

const useWidgets = ({
  localStorageKey,
}: {
  localStorageKey: string;
}): UseWidgetsReturnType => {
  const { t } = useTranslation();
  const { can } = useAccessControl();
  const navigate = useNavigate();

  const { _client } = useAppSelector((state) => state.client);
  const [isDragging, setIsDragging] = useState(false);

  const [isWidgetsSidebarActive, setIsWidgetsSidebarActive] = useState(true);
  const [isDashboardLock, setIsDashboardLock] = useState(
    dashboardLockInitState(localStorageKey)
  );

  const [activeBreakpoint, setActiveBreakpoint] =
    useState<BreakpointType | null>("lg");
  const [invoiceListVisible, setInvoiceListVisible] = useState(false);
  const [contractListVisible, setContractListVisible] = useState(false);

  const [insuranceListVisible, setInsuranceListVisible] = useState(false);
  const [offerListVisible, setOfferListVisible] = useState(false);
  const [expiringTenantsVisible, setExpiringTenants] = useState(false);
  const [apiDATEvVisible, setApiDATEvVisible] = useState(false);
  const [propertyCount, setPropertyCount] = useState(0);
  const [openCommentsListVisible, setOpenCommentsListVisible] = useState(false);
  const [tenantRequestListVisible, setTenantRequestListVisible] =
    useState(false);
  const [bankAccountsListVisible, setBankAccountsListVisible] = useState(false);

  const [isDashboardWidgetDataLoading, setIsDashboardWidgetDataLoading] =
    useState(true);

  const [usersWidgetData, setUsersWidgetData] = useState(
    dashboardWidgetsInitData.users
  );
  const [areaRentedWidgetData, setAreaRentedWidgetData] = useState(
    dashboardWidgetsInitData.areaRented
  );
  const [netRentWidgetData, setNetRentWidgetData] = useState(
    dashboardWidgetsInitData.netRent
  );
  const [potentialWidgetData, setPotentialWidgetData] = useState(
    dashboardWidgetsInitData.potential
  );
  const [contactsWidgetData, setContactsWidgetData] = useState(
    dashboardWidgetsInitData.contacts
  );
  const [expiringTenantCountWidgetData, setExpiringTenantCountWidgetData] =
    useState(dashboardWidgetsInitData.expiringTenantCount);
  const [invoicesWidgetData, setInvoicesWidgetData] =
    useState<InvoiceDoughnutChartData>(dashboardWidgetsInitData.invoice);
  const [insurancesWidgetData, setInsurancesWidgetData] =
    useState<InsuranceDoughnutChartData>(dashboardWidgetsInitData.insurance);
  const [offersWidgetData, setOffersWidgetData] =
    useState<OfferDoughnutChartData>(dashboardWidgetsInitData.offer);

  const [contractsWidgetData, setContractsWidgetData] =
    useState<ContractDoughnutChartData>(dashboardWidgetsInitData.contracts);

  const [openCommentsWidgetData, setOpenCommentsWidgetData] = useState<
    OpenCommentsListWidget[]
  >(dashboardWidgetsInitData.comments);
  const [openCommentsLoading, setOpenCommentsLoading] = useState(true);

  const [hoveredWidget, setHoveredWidget] = useState("");
  const [layout, setLayout] = useState<Layout[]>(
    layoutInitState(localStorageKey)
  );

  const [responsiveLayout] = useState<Record<string, Layout[]>>(
    responsiveLayoutInitState(localStorageKey)
  );

  const toggleExpiringTenantsModal = useCallback(() => {
    setExpiringTenants((visible) => !visible);
  }, []);

  /* istanbul ignore next */
  const toggleApiDATEvModal = useCallback(() => {
    setApiDATEvVisible((visible) => !visible);
  }, []);

  const toggleInvoiceListModal = useCallback(() => {
    setInvoiceListVisible((visible) => !visible);
  }, []);

  const toggleContractsListModal = useCallback(() => {
    setContractListVisible((visible) => !visible);
  }, []);

  const toggleInsuranceListModal = useCallback(() => {
    setInsuranceListVisible((visible) => !visible);
  }, []);

  const toggleOfferListModal = useCallback(() => {
    setOfferListVisible((visible) => !visible);
  }, []);
  /* istanbul ignore next */
  const toggleOpenCommentsListModal = useCallback(() => {
    setOpenCommentsListVisible((visible) => !visible);
  }, []);

  const toggleTenantRequestModal = useCallback(() => {
    setTenantRequestListVisible((visible) => !visible);
  }, []);
  /* istanbul ignore next */
  const toggleBankAccountsModal = useCallback(() => {
    setBankAccountsListVisible((visible) => !visible);
  }, []);

  const isDocumentReleaseEnabled = _client.modules.includes("document-release");
  const isMobileAppEnabled = _client.modules.includes("mobile-app");
  const isPropertiesEnabled = _client.modules.includes("properties");

  const onWidgetClick = (click: () => void) => () => {
    !isDragging && click();
  };

  const showCloseIcon = (name: string): ReactElement => {
    if (hoveredWidget === name && !isDashboardLock)
      return (
        <CloseIcon
          sx={deleteWidgetIconStyle}
          onClick={() => handleCloseWidget(name)}
          data-testid="widget-close"
        />
      );
    else return <></>;
  };

  const usersWidget = (): ReactElement => (
    <Stats
      title={WIDGET_IDS.USERS}
      amount={Math.round(usersWidgetData).toString()}
      chip="widgets.total"
      isLoading={isDashboardWidgetDataLoading}
      onClick={onWidgetClick(() => navigate(route("users")))}
    />
  );

  const areaRentedWidget = (): ReactElement => (
    <Stats
      title={WIDGET_IDS.AREA_RENTED}
      amount={Math.round(areaRentedWidgetData).toString()}
      unit={Measurement_Units.PERCENTAGE}
      isLoading={isDashboardWidgetDataLoading}
    />
  );

  const netRentWidget = (): ReactElement => (
    <Stats
      title={WIDGET_IDS.NET_RENT}
      amount={Math.round(netRentWidgetData).toString()}
      chip="property.tenants.calculationResult.pa"
      unit={Measurement_Units.EURO}
      isLoading={isDashboardWidgetDataLoading}
    />
  );

  const potentialWidget = (): ReactElement => (
    <Stats
      title={WIDGET_IDS.POTENTIAL}
      amount={Math.round(potentialWidgetData).toString()}
      chip="property.tenants.calculationResult.pa"
      unit={Measurement_Units.EURO}
      isLoading={isDashboardWidgetDataLoading}
    />
  );

  const contactsWidget = (): ReactElement => (
    <Stats
      title={WIDGET_IDS.CONTACTS}
      amount={Math.round(contactsWidgetData).toString()}
      chip="widgets.total"
      isLoading={isDashboardWidgetDataLoading}
      onClick={onWidgetClick(() => navigate(route("contacts")))}
    />
  );

  const expiringTenantsWidget = (): ReactElement => (
    <Stats
      title={WIDGET_IDS.EXPIRING_TENANTS}
      amount={Math.round(expiringTenantCountWidgetData).toString()}
      onClick={onWidgetClick(toggleExpiringTenantsModal)}
      isLoading={isDashboardWidgetDataLoading}
    />
  );

  const apiDATEvWidget = (): ReactElement => (
    <Stats
      title={WIDGET_IDS.API_DATEv_WIDGET}
      amount={Math.round(propertyCount).toString()}
      onClick={onWidgetClick(toggleApiDATEvModal)}
      isLoading={isDashboardWidgetDataLoading}
    />
  );

  const propertiesMapWidget = (): ReactElement => <PropertiesMapWidget />;

  const invoicesWidget = (): ReactElement => (
    <DoughnutChart
      data={invoicesWidgetData}
      toggleInvoiceListModal={toggleInvoiceListModal}
      isLoading={isDashboardWidgetDataLoading}
    />
  );

  const insurancesWidget = (): ReactElement => (
    <InsuranceDoughnutChart
      data={insurancesWidgetData}
      toggleInsuranceListModal={toggleInsuranceListModal}
      isLoading={isDashboardWidgetDataLoading}
    />
  );

  const offersWidget = (): ReactElement => (
    <OfferDoughnutChart
      data={offersWidgetData}
      toggleOfferListModal={toggleOfferListModal}
      isLoading={isDashboardWidgetDataLoading}
    />
  );

  const tenantRequestWidget = (): ReactElement => (
    <TenantTicketDoughnutChart
      toggleTenantRequestsModal={toggleTenantRequestModal}
    />
  );

  const bankAccountPaymentsWidget = (): ReactElement => (
    <BankAccountPayments toggleBankAccountModal={toggleBankAccountsModal} />
  );

  const invoicesListDialog = (): ReactElement =>
    invoiceListVisible ? (
      <InvoiceListDialog
        visible={invoiceListVisible}
        toggleListModal={toggleInvoiceListModal}
      />
    ) : (
      <></>
    );

  const insurancesListDialog = (): ReactElement =>
    insuranceListVisible ? (
      <InsuranceListDialog
        visible={insuranceListVisible}
        toggleListModal={toggleInsuranceListModal}
      />
    ) : (
      <></>
    );

  const offersListDialog = (): ReactElement =>
    offerListVisible ? (
      <OfferListDialog
        visible={offerListVisible}
        toggleListModal={toggleOfferListModal}
      />
    ) : (
      <></>
    );

  const contractsListDialog = (): ReactElement =>
    contractListVisible ? (
      <ContractListDialog
        visible={contractListVisible}
        toggleListModal={toggleContractsListModal}
      />
    ) : (
      <></>
    );
  /* istanbul ignore next */
  const openCommentsListDialog = (): ReactElement =>
    openCommentsListVisible ? (
      <OpenCommentsListDialog
        visible={openCommentsListVisible}
        toggleListModal={toggleOpenCommentsListModal}
      />
    ) : (
      <></>
    );

  const expiringTenants = (): ReactElement =>
    expiringTenantsVisible ? (
      <ExpiringTenants
        visible={expiringTenantsVisible}
        toggleListModal={toggleExpiringTenantsModal}
      />
    ) : (
      <></>
    );

  /* istanbul ignore next */
  const apiDATEv = (): ReactElement =>
    apiDATEvVisible ? (
      <ApiDATEvModal
        visible={apiDATEvVisible}
        toggleListModal={toggleApiDATEvModal}
      />
    ) : (
      <></>
    );

  const contractWidget = (): ReactElement => (
    <ContractDoughnutChart
      data={contractsWidgetData}
      toggleInvoiceListModal={toggleContractsListModal}
      isLoading={isDashboardWidgetDataLoading}
    />
  );

  const openCommentsWidget = (): ReactElement => (
    <OpenCommentsWidget
      data={openCommentsWidgetData}
      toggleOpenCommentsListModal={toggleOpenCommentsListModal}
      isLoading={openCommentsLoading}
    />
  );

  const requestListModal = (): ReactElement => (
    <TenantRequestsList
      toggleListModal={toggleTenantRequestModal}
      visible={tenantRequestListVisible}
    />
  );
  /* istanbul ignore next */
  const bankAccountsListModal = (): ReactElement =>
    bankAccountsListVisible ? (
      <BankAccountsListDialog
        toggleListModal={toggleBankAccountsModal}
        visible={bankAccountsListVisible}
      />
    ) : (
      <></>
    );

  const widgetIdToComponentMap = {
    users: can(permissionsToRead[WIDGET_IDS.USERS]) ? (
      <div
        key={WIDGET_IDS.USERS}
        onMouseEnter={() => setHoveredWidget(WIDGET_IDS.USERS)}
        onMouseLeave={() => setHoveredWidget("")}
        data-testid="widget-users"
        data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
          (el) => el.i === WIDGET_IDS.USERS
        )}
      >
        {usersWidget()}
        {showCloseIcon(WIDGET_IDS.USERS)}
      </div>
    ) : (
      /* istanbul ignore next */
      <></>
    ),
    areaRented: can(permissionsToRead[WIDGET_IDS.AREA_RENTED]) ? (
      <div
        key={WIDGET_IDS.AREA_RENTED}
        onMouseEnter={() => setHoveredWidget(WIDGET_IDS.AREA_RENTED)}
        onMouseLeave={() => setHoveredWidget("")}
        data-testid="widget-area-rented"
        data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
          (el) => el.i === WIDGET_IDS.AREA_RENTED
        )}
      >
        {areaRentedWidget()}
        {showCloseIcon(WIDGET_IDS.AREA_RENTED)}
      </div>
    ) : (
      /* istanbul ignore next */
      <></>
    ),
    netRent: can(permissionsToRead[WIDGET_IDS.NET_RENT]) ? (
      <div
        key={WIDGET_IDS.NET_RENT}
        onMouseEnter={() => setHoveredWidget(WIDGET_IDS.NET_RENT)}
        onMouseLeave={() => setHoveredWidget("")}
        data-testid="widget-net-rent"
        data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
          (el) => el.i === WIDGET_IDS.NET_RENT
        )}
      >
        {netRentWidget()}
        {showCloseIcon(WIDGET_IDS.NET_RENT)}
      </div>
    ) : (
      /* istanbul ignore next */
      <></>
    ),
    potential: can(permissionsToRead[WIDGET_IDS.POTENTIAL]) ? (
      <div
        key={WIDGET_IDS.POTENTIAL}
        onMouseEnter={() => setHoveredWidget(WIDGET_IDS.POTENTIAL)}
        onMouseLeave={() => setHoveredWidget("")}
        data-testid="widget-potential"
        data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
          (el) => el.i === WIDGET_IDS.POTENTIAL
        )}
      >
        {potentialWidget()}
        {showCloseIcon(WIDGET_IDS.POTENTIAL)}
      </div>
    ) : (
      /* istanbul ignore next */
      <></>
    ),
    contacts: can(permissionsToRead[WIDGET_IDS.CONTACTS]) ? (
      <div
        key={WIDGET_IDS.CONTACTS}
        onMouseEnter={() => setHoveredWidget(WIDGET_IDS.CONTACTS)}
        onMouseLeave={() => setHoveredWidget("")}
        data-testid="widget-contacts"
        data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
          (el) => el.i === WIDGET_IDS.CONTACTS
        )}
      >
        {contactsWidget()}
        {showCloseIcon(WIDGET_IDS.CONTACTS)}
      </div>
    ) : (
      /* istanbul ignore next */
      <></>
    ),
    expiringTenants: can(permissionsToRead[WIDGET_IDS.EXPIRING_TENANTS]) ? (
      <div
        key={WIDGET_IDS.EXPIRING_TENANTS}
        onMouseEnter={() => setHoveredWidget(WIDGET_IDS.EXPIRING_TENANTS)}
        onMouseLeave={() => setHoveredWidget("")}
        data-testid="widget-expiring-tenants"
        data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
          (el) => el.i === WIDGET_IDS.EXPIRING_TENANTS
        )}
      >
        {expiringTenantsWidget()}
        {showCloseIcon(WIDGET_IDS.EXPIRING_TENANTS)}
      </div>
    ) : (
      /* istanbul ignore next */
      <></>
    ),

    apiDATEvWidget: /* istanbul ignore next */ can(
      permissionsToRead[WIDGET_IDS.API_DATEv_WIDGET]
    ) ? (
      <div
        key={WIDGET_IDS.API_DATEv_WIDGET}
        onMouseEnter={() => setHoveredWidget(WIDGET_IDS.API_DATEv_WIDGET)}
        onMouseLeave={() => setHoveredWidget("")}
        data-testid="widget-apiDATEv"
        data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
          (el) => el.i === WIDGET_IDS.API_DATEv_WIDGET
        )}
      >
        {apiDATEvWidget()}
        {showCloseIcon(WIDGET_IDS.API_DATEv_WIDGET)}
      </div>
    ) : (
      <></>
    ),
    invoices:
      can(permissionsToRead[WIDGET_IDS.INVOICES]) &&
      isDocumentReleaseEnabled ? (
        <div
          key={WIDGET_IDS.INVOICES}
          onMouseEnter={() => setHoveredWidget(WIDGET_IDS.INVOICES)}
          onMouseLeave={() => setHoveredWidget("")}
          data-testid="widget-invoices"
          data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
            (el) => el.i === WIDGET_IDS.INVOICES
          )}
        >
          {invoicesWidget()}
          {showCloseIcon(WIDGET_IDS.INVOICES)}
        </div>
      ) : (
        /* istanbul ignore next */
        <></>
      ),
    insurances:
      can(permissionsToRead[WIDGET_IDS.INSURANCES]) &&
      isDocumentReleaseEnabled ? (
        <div
          key={WIDGET_IDS.INSURANCES}
          onMouseEnter={() => setHoveredWidget(WIDGET_IDS.INSURANCES)}
          onMouseLeave={() => setHoveredWidget("")}
          data-testid="widget-insurances"
          data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
            (el) => el.i === WIDGET_IDS.INSURANCES
          )}
        >
          {insurancesWidget()}
          {showCloseIcon(WIDGET_IDS.INSURANCES)}
        </div>
      ) : (
        /* istanbul ignore next */
        <></>
      ),
    tenantTickets:
      can(permissionsToRead[WIDGET_IDS.TENANT_TICKETS]) &&
      isMobileAppEnabled ? (
        <div
          key={WIDGET_IDS.TENANT_TICKETS}
          onMouseEnter={() => setHoveredWidget(WIDGET_IDS.TENANT_TICKETS)}
          onMouseLeave={() => setHoveredWidget("")}
          data-testid="widget-tenant-tickets"
          data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
            (el) => el.i === WIDGET_IDS.TENANT_TICKETS
          )}
        >
          {tenantRequestWidget()}
          {showCloseIcon(WIDGET_IDS.TENANT_TICKETS)}
        </div>
      ) : (
        /* istanbul ignore next */
        <></>
      ),
    offers:
      can(permissionsToRead[WIDGET_IDS.OFFERS]) && isDocumentReleaseEnabled ? (
        <div
          key={WIDGET_IDS.OFFERS}
          onMouseEnter={() => setHoveredWidget(WIDGET_IDS.OFFERS)}
          onMouseLeave={() => setHoveredWidget("")}
          data-testid="widget-offers"
          data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
            (el) => el.i === WIDGET_IDS.OFFERS
          )}
        >
          {offersWidget()}
          {showCloseIcon(WIDGET_IDS.OFFERS)}
        </div>
      ) : (
        /* istanbul ignore next */
        <></>
      ),
    contracts:
      can(permissionsToRead[WIDGET_IDS.CONTRACTS]) &&
      isDocumentReleaseEnabled ? (
        <div
          key={WIDGET_IDS.CONTRACTS}
          onMouseEnter={() => setHoveredWidget(WIDGET_IDS.CONTRACTS)}
          onMouseLeave={() => setHoveredWidget("")}
          data-testid="widget-contracts"
          data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
            (el) => el.i === WIDGET_IDS.CONTRACTS
          )}
        >
          {contractWidget()}
          {showCloseIcon(WIDGET_IDS.CONTRACTS)}
        </div>
      ) : (
        /* istanbul ignore next */
        <></>
      ),
    comments: can(permissionsToRead[WIDGET_IDS.COMMENTS]) ? (
      <div
        key={WIDGET_IDS.COMMENTS}
        onMouseEnter={
          /* istanbul ignore next */ () => {
            setHoveredWidget(WIDGET_IDS.COMMENTS);
          }
        }
        onMouseLeave={
          /* istanbul ignore next */ () => {
            setHoveredWidget("");
          }
        }
        data-testid="widget-comments"
        data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
          (el) => el.i === WIDGET_IDS.COMMENTS
        )}
      >
        {openCommentsWidget()}
        {showCloseIcon(WIDGET_IDS.COMMENTS)}
      </div>
    ) : (
      /* istanbul ignore next */
      <></>
    ),
    properties:
      can(permissionsToRead[WIDGET_IDS.USERS]) && isPropertiesEnabled ? (
        <div
          key={WIDGET_IDS.PROPERTIES}
          onMouseEnter={
            /* istanbul ignore next */ () =>
              setHoveredWidget(WIDGET_IDS.PROPERTIES)
          }
          onMouseLeave={/* istanbul ignore next */ () => setHoveredWidget("")}
          data-testid="widget-properties"
          data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
            (el) => el.i === WIDGET_IDS.PROPERTIES
          )}
        >
          {propertiesMapWidget()}
          {showCloseIcon(WIDGET_IDS.PROPERTIES)}
        </div>
      ) : (
        /* istanbul ignore next */
        <></>
      ),
    bankAccountPayments: can(
      permissionsToRead[WIDGET_IDS.BANK_ACCOUNT_PAYMENTS]
    ) ? (
      <div
        key={WIDGET_IDS.BANK_ACCOUNT_PAYMENTS}
        onMouseEnter={
          /* istanbul ignore next */ () =>
            setHoveredWidget(WIDGET_IDS.BANK_ACCOUNT_PAYMENTS)
        }
        onMouseLeave={/* istanbul ignore next */ () => setHoveredWidget("")}
        data-testid="widget-bank-account"
        data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
          (el) => el.i === WIDGET_IDS.BANK_ACCOUNT_PAYMENTS
        )}
      >
        {bankAccountPaymentsWidget()}
        {showCloseIcon(WIDGET_IDS.BANK_ACCOUNT_PAYMENTS)}
      </div>
    ) : (
      /* istanbul ignore next */
      <></>
    ),
  };

  /* istanbul ignore next */
  const handleOnBreakpointChange = (newBreakpoint: string): void => {
    setActiveBreakpoint(newBreakpoint as BreakpointType);
  };

  const toggleWidget = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: string
  ): void => {
    if (event.target.checked) {
      const layoutInit = responsiveLayoutInitSettings[activeBreakpoint!];
      const itemToAdd = layoutInit.find((el) => el.i === item);
      setLayout((layout) => {
        layout.push(itemToAdd!);
        return [...layout];
      });
    } else {
      setLayout((layout) => [...layout.filter((l: Layout) => l.i !== item)]);
    }
  };

  const onLayoutChange = async (
    layout: Layout[],
    responsiveLayout: Record<string, Layout[]>
  ): Promise<void> => {
    /* istanbul ignore else */
    if (localStorage) {
      const settings = localStorage.getItem(localStorageKey)
        ? JSON.parse(localStorage.getItem(localStorageKey)!)
        : {};
      settings.layouts = responsiveLayout;
      localStorage.setItem(localStorageKey, JSON.stringify(settings));
      const payload: dashboardConfigurationsProps = {
        data: { dashboard: { layouts: responsiveLayout } },
      };
      await updateUserConfiguration(payload);
    } else {
      setLayout(layout);
    }
  };

  const handleCloseWidget = (name: string): void => {
    setLayout((layout) => layout.filter((l: Layout) => l.i !== name));
  };

  const handleOnWidgetsSidebarButtonClick = (): void => {
    setIsWidgetsSidebarActive((val) => !val);
  };

  const handleLock = (): void => {
    /* istanbul ignore else */
    if (localStorage) {
      const settings = JSON.parse(localStorage.getItem(localStorageKey)!);
      settings.isLocked = !isDashboardLock;
      localStorage.setItem(localStorageKey, JSON.stringify(settings));
    }
    setIsDashboardLock((val) => !val);
  };

  const widgetsSideBar = (): ReactElement => {
    const filtered = Object.values(WIDGET_IDS).filter(
      /* istanbul ignore next */ (widget) => {
        if (can(permissionsToRead[widget])) {
          if (WIDGET_IDS_MODULE[widget] !== "") {
            return _client.modules.includes(WIDGET_IDS_MODULE[widget]);
          }

          return true;
        }

        return false;
      }
    );

    return (
      <>
        <WidgetsList $isHidden={isWidgetsSidebarActive} elevation={2}>
          <div>
            <WidgetsSideBarButton
              $isHidden={isWidgetsSidebarActive}
              startIcon={<WidgetsIcon />}
              variant="outlined"
              onClick={() => handleOnWidgetsSidebarButtonClick()}
              data-testid="widgets-sidebar-button"
            >
              Widgets
            </WidgetsSideBarButton>
          </div>
          {lockWidget()}
          <div>
            <FormGroup sx={{ px: 2 }}>
              {filtered.map((item: string) => (
                <FormControlLabel
                  key={`id-${item}`}
                  control={
                    <Checkbox
                      checked={map(layout, "i").includes(item)}
                      onChange={(e) => toggleWidget(e, item)}
                      data-testid={`${kebabCase(item)}-checkbox`}
                    />
                  }
                  label={t(`widgets.${item}`)}
                />
              ))}
            </FormGroup>
          </div>
        </WidgetsList>
      </>
    );
  };

  const lockWidget = (): ReactElement => (
    <DashboardLockSection>
      <FormControlLabel
        control={
          <Switch
            checked={isDashboardLock}
            onChange={() => handleLock()}
            data-testid="widgets-sidebar-lock-button"
          />
        }
        label={t("home.lockDashboard")}
      />
    </DashboardLockSection>
  );
  return {
    layout,
    responsiveLayout,
    handleCloseWidget,
    expiringTenantsVisible,
    apiDATEvVisible,
    setPropertyCount,
    invoiceListVisible,
    widgetIdToComponentMap,
    setActiveBreakpoint,
    handleOnBreakpointChange,
    widgetsSideBar,
    invoicesListDialog,
    expiringTenants,
    apiDATEv,
    onLayoutChange,
    setUsersWidgetData,
    setAreaRentedWidgetData,
    setNetRentWidgetData,
    propertiesMapWidget,
    setPotentialWidgetData,
    setContactsWidgetData,
    setExpiringTenantCountWidgetData,
    setInvoicesWidgetData,
    isDashboardLock,
    setInsurancesWidgetData,
    insurancesListDialog,
    setOffersWidgetData,
    offersListDialog,
    contractsListDialog,
    openCommentsListDialog,
    setContractsWidgetData,
    setOpenCommentsWidgetData,
    requestListModal,
    bankAccountsListModal,
    setIsDashboardWidgetDataLoading,
    setOpenCommentsLoading,
    isDragging,
    setIsDragging,
  };
};

export default useWidgets;

export type BreakpointType = "lg" | "md" | "sm" | "xs" | "xxs";

interface UseWidgetsReturnType {
  layout: Layout[];
  setActiveBreakpoint: React.Dispatch<
    React.SetStateAction<BreakpointType | null>
  >;
  responsiveLayout: Record<string, Layout[]>;
  handleCloseWidget: (name: string) => void;
  expiringTenantsVisible: boolean;
  apiDATEvVisible: boolean;
  setPropertyCount: React.Dispatch<React.SetStateAction<number>>;
  invoiceListVisible: boolean;
  handleOnBreakpointChange: (newBreakpoint: string) => void;
  widgetsSideBar: () => ReactElement;
  widgetIdToComponentMap: Record<string, ReactElement>;
  invoicesListDialog: () => ReactElement;
  propertiesMapWidget: () => ReactElement;
  expiringTenants: () => ReactElement;
  apiDATEv: () => ReactElement;
  onLayoutChange: (layout: Layout[], layouts: Record<string, Layout[]>) => void;
  setUsersWidgetData: React.Dispatch<React.SetStateAction<number>>;
  setAreaRentedWidgetData: React.Dispatch<React.SetStateAction<number>>;
  setNetRentWidgetData: React.Dispatch<React.SetStateAction<number>>;
  setPotentialWidgetData: React.Dispatch<React.SetStateAction<number>>;
  setContactsWidgetData: React.Dispatch<React.SetStateAction<number>>;
  setExpiringTenantCountWidgetData: React.Dispatch<
    React.SetStateAction<number>
  >;
  setInvoicesWidgetData: React.Dispatch<
    React.SetStateAction<InvoiceDoughnutChartData>
  >;
  setInsurancesWidgetData: React.Dispatch<
    React.SetStateAction<InsuranceDoughnutChartData>
  >;
  isDashboardLock: boolean;
  insurancesListDialog: () => ReactElement;
  setOffersWidgetData: React.Dispatch<
    React.SetStateAction<OfferDoughnutChartData>
  >;
  offersListDialog: () => ReactElement;
  contractsListDialog: () => ReactElement;
  openCommentsListDialog: () => ReactElement;
  setContractsWidgetData: React.Dispatch<
    React.SetStateAction<ContractDoughnutChartData>
  >;
  setOpenCommentsWidgetData: React.Dispatch<
    React.SetStateAction<OpenCommentsListWidget[]>
  >;
  requestListModal: () => ReactElement;
  bankAccountsListModal: () => ReactElement;
  setIsDashboardWidgetDataLoading: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setOpenCommentsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isDragging: boolean;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
}
