import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { DialogContent, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import { ListDialogProps } from "./types";
import CloseButton from "../../ui/dialog/CloseButton";
import { DialogTitleContainer, ErrorBox } from "./styled";
import { Status as OfferStatus } from "../../types/be/status";
import { getOfferStatuses } from "../../api/offer";
import OfferList from "../properties/offers/list/topicList/offerList";
import { FormMessageErrorState } from "../../constants";
import useDocumentRelease from "../properties/components/documentRelease/useDocumentRelease";
import { DOCUMENT_RELEASE_MODULE } from "../../types/be/releaseFlow";

const OfferListDialog = ({
  visible,
  toggleListModal,
}: ListDialogProps): ReactElement => {
  const { t } = useTranslation();
  const [statuses, setStatuses] = useState<OfferStatus[]>([]);
  const [error, setError] = useState<string | null>(null);
  const getStatuses = useCallback(async () => {
    setStatuses((await (await getOfferStatuses()).json()).data);
  }, []);

  const {
    configuration,
    isConfigurationLoading,
    handleReleaseDocument,
    handleForwardDocument,
    releasePermission,
    snackbar,
    loading: isDocumentReleaseLoading,
  } = useDocumentRelease(DOCUMENT_RELEASE_MODULE.OFFER);

  useEffect(() => {
    getStatuses();
  }, []);
  /* istanbul ignore next */
  if (error) return <ErrorBox formMessage={FormMessageErrorState} />;

  return (
    <Dialog fullWidth open={visible} onClose={toggleListModal} maxWidth="xl">
      <DialogTitle sx={{ pt: 0, pb: 0 }}>
        <DialogTitleContainer>
          <Typography
            variant="h6"
            component="div"
            justifyContent="space-around"
            width="100%"
          >
            {t("home.allOffers")}
          </Typography>
          <CloseButton onClick={toggleListModal} />
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        <OfferList
          setError={setError}
          statuses={statuses}
          useOnWidget={true}
          configuration={configuration}
          handleForwardDocument={handleForwardDocument}
          handleReleaseDocument={handleReleaseDocument}
          isConfigurationLoading={isConfigurationLoading}
          releasePermission={releasePermission}
          isDocumentReleaseLoading={isDocumentReleaseLoading}
        />
        {snackbar.Snackbar}
      </DialogContent>
    </Dialog>
  );
};

export default OfferListDialog;
