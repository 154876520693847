export default {
  creditor: {
    account: "Account",
    user: "User",
    property: "Property",
    companyName: "Company name",
    addresseeType: "Addressee type",
    abbreviation: "Abbreviation",
    addressType: "Address type",
    street: "Street",
    postalCode: "Postal code",
    location: "Location",
    codeCorrespondenceAddress: "Code correspondence address",
    addressValidFrom: "Address valid from",
    creditorRequestsWasCreatedSuccessfully:
      "Creditor request was created successfully!",
    creditorRequestsWasUpdatedSuccessfully:
      "Creditor request was updated successfully!",
    apidatev: "API-DATEv",
    creditor: "Creditor",
    debtor: "Debtor",
    sollkonto: "Soll-Konto",
    create: "Create",
    deleteAll: "Delete All",

    creditorWasSuccessfullyCreated: "Creditor Was Successfully Created!",
    deleteAllConfirmationMessage:
      "Are you sure you want to delete all Creditor?",

    formFields: {
      konto: "Account",
      propertyId: "Property Id",
      companyName: "Company name",
      addresseeType: "Addressee type",
      abbreviation: "Abbreviation",
      addresstype: "Address type",
      street: "Street",
      postalcode: "Postal code",
      location: "Location",
      codeCorrespondenceAddress: "Code correspondence address",
      addressValidFrom: "Address valid from",
    },

    error: {
      konto: "Account is required",
      propertyId: "Property id is required",
      companyName: "Company name is required",
      addresseeType: "Addressee type is required",
      abbreviation: "Abbreviation is required",
      addressType: "Address type is required",
      street: "Street is required",
      postalCode: "Postal code is required",
      location: "Location is required",
      codeCorrespondenceAddress: "Code correspondence address is required",
      addressValidFrom: "Address valid from date is required",
    },
  },
};
