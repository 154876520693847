import React from "react";
import { Property } from "../../../types/be/property";
import { PropertyUpdate } from "../../../types/fe/property";
import { FormMessageInterface } from "../../../types/form";

export type FormSubmitHandlerProps = {
  setFormMessage: React.Dispatch<React.SetStateAction<FormMessageInterface>>;
  initialValues?: PropertyUpdate & { location: { full_address: string } };
};

export enum TABS {
  GENERAL = "general",
  COSTS = "costs",
  FINANCE = "finance",
  OPERATION = "operation",
  REPAYMENT = "repayment",
}

export interface CostPropsTypes {
  property: Property;
  updateSuccessCallback: () => void;
}

export interface PropertyUpdateDto {
  id: number;
  object_name: string;
  street: string;
  house_number: string;
  address: string;
  zip_code: string;
  city: string;
  state: {
    id: 9;
    abbreviation: string;
    name: string;
    title: string;
  };
  country: string;
  property_type: {
    id: 4;
    code: string;
    name: string;
    title: string;
  };
  rented_area: 0;
  total_area: 0;
  net_rent_per_year: 0;
  potencial_per_year: 0;
  wault: 0;
  total_area_percentage: 0;
  lat: 52.988454;
  lng: 10.1063031;
  search_place_id: string;
  company_user: string;
  images: [];
  contact: string;
  takeover: 1669702226;
  supervisor: string;
  caretaker: string;
  company: string;
  expenses: string;
  portfolio: string;
  phone_number: string;
  tax_number: string;
  vat: string;
  architect: string;
  architect_updated_at: string;
  property_management_cost: string;
  percentage_net_rent: 0;
  purchase_price: string;
  contract_start: string;
  contract_end: string;
  contract_cancelled: string;
  notes: string;
  bank_accounts: [];
  asset_managers: [];
  property_managers: [];
  property_users: [];
  tenants: [];
  asset_managers_history: [];
  total_not_rented_area: 0;
  total_rented_area: 0;
  full_area: 0;
  living_avg_per_year: 0;
  commercial_avg_per_year: 0;
  shortest_remaining_term: 0;
  living_area_percentage: 0;
  commercial_area_percentage: 0;
  immo24_report_file: "";
  commercial_area_rented_in_m2: 0;
  empty_commercial_area_in_m2: 0;
  rented_living_area_in_m2: 0;
  empty_living_area_in_m2: 0;
  property_value_development: 0;
  depreciation: 0;
  repayment_bank: 0;
  interest_bank_loan: 0;
  interest_on_own_funds: 0;
  bank_loan: 0;
  own_resources: 0;
  maintenance_non_apportionable: 0;
  operating_costs_non_revenueable: 0;
  property_management_non_revenueable: 0;
  land_in_eur: 737230;
  land_in_percents: 100;
  building_in_eur: 0;
  building_in_percents: 0;
  buffer: 0;
  other: 0;
  buffer_in_eur: 0;
  other_in_eur: 0;
  property_transfer_tax: 0;
  real_estate_agent: 0;
  notary: 0;
  due_dilligence: 0;
  taxes: 0;
  broker_price: 0;
  year_of_construction: 0;
  parking_lots: 0;
  standart_ground_value: 0;
  net_rent_actual: 0;
  net_rent_target: 0;
  maintenance_non_reversible: 0;
  operating_costs_non_reversible: 0;
  non_circulating_property_management: 0;
  commercial_net_rent_per_month: 0;
  commercial_net_rent_per_year: 0;
  commercial_net_rent_percentage: 0;
  living_net_rent_per_month: 0;
  living_net_rent_per_year: 0;
  living_net_rent_percentage: 0;
  total_net_rent_per_month: 0;
  total_net_rent_per_year: 0;
  commercial_valued_free_space_per_month: 0;
  commercial_valued_free_space_per_year: 0;
  living_valued_free_space_per_month: 0;
  living_valued_free_space_per_year: 0;
  total_valued_free_space_per_month: 0;
  total_valued_free_space_per_year: 0;
  commercial_area: 0;
  living_area: 0;
  commercial_avg_per_month: 0;
  living_avg_per_month: number;
  remaining_in_eur: number;
  commercial_not_rented_area: number;
  commercial_rented_area: number;
  living_not_rented_area: number;
  living_rented_area: number;
  property_type_id: number;
  country_code: string;
  state_code: string;
  place_id: string;
}
