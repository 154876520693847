import styled from "styled-components/macro";
import MuiButton from "@mui/material/Button";
import { blue } from "@mui/material/colors";
import { Grid as MuiTabBarGrid } from "@mui/material";

export const TabBar = styled(MuiTabBarGrid)`
  padding: 0;
  display: "flex";
  flex-wrap: "wrap";
  @media screen and (min-width: 1400px) {
    background: ${blue[700]};
  }
`;

export const TabButton = styled(MuiButton)<{ disabled?: boolean }>`
  font-size: ${(props): string => props.theme.typography.fontSize};
  line-height: 26px;
  cursor: pointer;
  background: ${blue[700]};
  font-weight: 500;
  color: ${(props) => props.theme.palette.primary.contrastText} !important;
  padding: ${(props): string => props.theme.spacing(1.25, 3)};
  border-radius: 0;
  border: 0;
  box-shadow: ${(props) =>
    props.disabled
      ? `rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;`
      : "none"};
  font-size: ${(props) => props.theme.spacing(3.25)};
  &:hover {
    background: ${blue[700]};
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }
`;
