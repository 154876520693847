import { TableCell } from "../../../../types/table";
import { FormikValues } from "formik";
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { FormMessageInterface } from "../../../../types/form";
import { createReading } from "../../../../api/reading";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { route } from "../../../../utils/url";
import { CreateReadingInitialValues } from "./types";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "createdBy",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "counter.readings.createdBy",
  },
  {
    id: "value",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "counter.readings.value",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "counter.readings.date",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];

export const MAX_FILES = 5;

export const handleSubmit = (
  setFormMessage: Dispatch<SetStateAction<FormMessageInterface>>,
  navigate: NavigateFunction,
  id: string,
  tenantId: string,
  counterId: string
) => {
  return async (
    values: CreateReadingInitialValues,
    { setStatus }: FormikValues
  ): Promise<void> => {
    const formData = new FormData();
    formData.append("value", values.value);
    formData.append("counter_id", values.counter_id);

    values.file.forEach((item, index) => {
      item.file && formData.append(`file[${index}]`, item.file);
    });

    const response = await createReading(formData);
    const result = await response?.json();

    if (response.status === 201) {
      setStatus({
        success: true,
      });
      setFormMessage(FormMessageInItState);
      navigate(
        route("properties.areas.edit.counter.reading", id, tenantId, counterId)
      );
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(result?.errors),
      });
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
    }
  };
};
