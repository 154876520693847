import React, { ReactElement, useState } from "react";
import {
  DeleteIcon,
  EditIcon,
  ShowIcon,
} from "../../../../../../ui/table/styled";
import { TableCell } from "../../../../styled";
import { OfferListRowProps } from "../../types";
import { Typography } from "@mui/material";
import { NumericFormat } from "react-number-format";
import {
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  Measurement_Units,
  PermissionsDelete,
  PermissionsUpdate,
  THOUSAND_SEPARATOR,
  UserRoles,
} from "../../../../../../constants";
import { formatTimestamp } from "../../../../../../utils/date";
import ExpandableComment from "../../../../../../ui/expandableComment/ExpandableComment";
import {
  ToggleButton,
  ToggleButtonGroup,
} from "../../../../insurances/list/topicList/insuranceList/insuranceListRow/styled";
import { releaseOfferAM } from "../../../../../../api/offer";
import useAccessControl from "../../../../../../hooks/useAccessControl";
import { useTranslation } from "react-i18next";
import { RELEASE_LEVEL } from "../../../../../../utils/common";
import RedirectProperty from "../../../../../../ui/redirectProperty";
import ProviderTableCell from "../../../../components/provider/ProviderTableCell";
import DocumentRelease from "../../../../components/documentRelease";
import {
  DOCUMENT_RELEASE_LEVEL,
  DOCUMENT_RELEASE_MODULE,
} from "../../../../../../types/be/releaseFlow";
import {
  RELASE_BUTTONS_AM,
  RELASE_BUTTONS_PM,
  RELEASE_BUTTONS_FR,
  RELEASE_BUTTONS_USER,
  isAvailableReleaseButtonTableCell,
} from "../../../../utils";
import { DocumentReleaseHistoryIcon } from "../../../../components/history/styled";
import FileTableCell from "../../../../components/fileTableCell";
/* istanbul ignore next */
const OfferListRow = ({
  row,
  handleCellClick,
  handleEditClick,
  handleShowClick,
  openConfirmToRemoveModal,
  handleOpenComments,
  fetchOfferTopics,
  useOnWidget,
  setContactShowId,
  setProviderOfferListFilter,
  handleReleaseDocument,
  handleForwardDocument,
  releasePermission,
  isDocumentReleaseLoading,
  configuration,
  handleHistoryModal,
}: OfferListRowProps): ReactElement => {
  const [value, setValue] = useState(() => Number(row.release_am));
  const [error, setError] = useState(false);
  const { can, isRole } = useAccessControl();
  const { t } = useTranslation();
  /* istanbul ignore next */
  const handleChange = async (_: unknown, value: number): Promise<void> => {
    if (value === null) return;
    const response = await releaseOfferAM(row.id.toString(), value);
    response.status === 200 ? setValue(value) : setError(true);
  };

  const onDocumentShow = setProviderOfferListFilter
    ? () => {
        const contact_id = row?.contact?.id ?? null;
        setProviderOfferListFilter({
          contact_id,
        });
      }
    : undefined;

  return (
    <>
      {useOnWidget ? (
        <TableCell align="left">
          <RedirectProperty property={row?.property} />
        </TableCell>
      ) : null}
      <FileTableCell file={row.file} />
      <ProviderTableCell
        contact={row.contact}
        onContactShow={() => setContactShowId(String(row?.contact?.id))}
        onDocumentShow={onDocumentShow}
        documentActionLabel="offers.offer"
      />
      <TableCell align="right">
        <Typography noWrap>
          <NumericFormat
            thousandSeparator={THOUSAND_SEPARATOR}
            decimalSeparator={DECIMAL_SEPARATOR}
            decimalScale={DECIMAL_SCALE}
            fixedDecimalScale
            displayType={"text"}
            value={row.amount}
          />{" "}
          {row.amount !== null && Measurement_Units.EURO}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <ExpandableComment
          seeAllComment={() => handleOpenComments(row)}
          commentData={row?.latest_comment}
        ></ExpandableComment>
      </TableCell>
      <TableCell>
        {row.created_by
          ? `${row.created_by.first_name} ${row.created_by.last_name}`
          : /* istanbul ignore next */ "---"}
      </TableCell>
      <TableCell align="center">
        {row.uploaded_at ? formatTimestamp(row.uploaded_at) : EMPTY_DATA}
      </TableCell>
      <TableCell align="center">{formatTimestamp(row.created_at)}</TableCell>
      {
        //Hide till api complete the task
        /* istanbul ignore next */
        false && (
          <TableCell
            align="left"
            padding={
              !can(["property.release"])
                ? /* istanbul ignore next */ "none"
                : "normal"
            }
          >
            <ToggleButtonGroup
              color="primary"
              $error={error}
              value={value}
              exclusive
              onChange={handleChange}
              size="small"
              disabled={!isRole(["asset_manager"])}
            >
              <ToggleButton value={1}>{t("insurance.on")}</ToggleButton>
              <ToggleButton value={0}>{t("insurance.off")}</ToggleButton>
            </ToggleButtonGroup>
          </TableCell>
        )
      }
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELEASE_BUTTONS_FR,
          configuration
        ) ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.fr}
              releaseStatus={row?.fr_status}
              forwardedAt={row?.fr_forwarded_at}
              forwardedBy={row?.fr_forwarded_by}
              overdue={row?.fr_overdue}
              afterSuccess={fetchOfferTopics}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              isButtonVisible={{
                forwardButton: false,
              }}
              level={RELEASE_LEVEL.FIRST_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
                  row?.assigned_user
                )
              }
              hasReleasePermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL]
              }
              relation={DOCUMENT_RELEASE_MODULE.OFFER}
              loading={isDocumentReleaseLoading}
            />
          </TableCell>
        ) : null
      }
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELASE_BUTTONS_AM,
          configuration
        ) ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.am}
              releaseStatus={row?.am_status}
              forwardedAt={row?.am_forwarded_at}
              forwardedBy={row?.am_forwarded_by}
              overdue={row?.am_overdue}
              afterSuccess={fetchOfferTopics}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              level={RELEASE_LEVEL.SECOND_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
                  row?.assigned_user
                )
              }
              hasReleasePermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL]
              }
              relation={DOCUMENT_RELEASE_MODULE.OFFER}
              loading={isDocumentReleaseLoading}
              isButtonVisible={{
                forwardButton: false,
              }}
            />
          </TableCell>
        ) : null
      }
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELASE_BUTTONS_PM,
          configuration
        ) ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.pm}
              releaseStatus={row?.pm_status}
              forwardedAt={row?.pm_forwarded_at}
              forwardedBy={row?.pm_forwarded_by}
              overdue={row?.pm_overdue}
              afterSuccess={fetchOfferTopics}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              level={RELEASE_LEVEL.THIRD_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
                  row?.assigned_user
                )
              }
              hasReleasePermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL]
              }
              relation={DOCUMENT_RELEASE_MODULE.OFFER}
              loading={isDocumentReleaseLoading}
              isButtonVisible={{
                forwardButton: false,
              }}
            />
          </TableCell>
        ) : null
      }
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELEASE_BUTTONS_USER,
          configuration
        ) ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.au}
              releaseStatus={row?.au_status}
              forwardedAt={row?.au_forwarded_at}
              forwardedBy={row?.au_forwarded_by}
              overdue={row?.au_overdue}
              afterSuccess={fetchOfferTopics}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              level={RELEASE_LEVEL.FOURTH_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL]
              }
              hasReleasePermission={releasePermission[
                DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
              ](row?.assigned_user)}
              relation={DOCUMENT_RELEASE_MODULE.OFFER}
              assignedUser={row?.assigned_user}
              loading={isDocumentReleaseLoading}
              isButtonVisible={{
                forwardButton: false,
                assignUserButton: false,
              }}
              hasAssignUserPermission={can(PermissionsUpdate.OFFER)}
            />
          </TableCell>
        ) : null
      }
      <TableCell
        align="right"
        mw="100px"
        onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
          handleCellClick(e)
        }
        style={{ whiteSpace: "nowrap" }}
      >
        {isRole(UserRoles.ADMIN) && (
          <DocumentReleaseHistoryIcon
            role={"showHistoryRole"}
            onClick={() => {
              handleHistoryModal(row);
            }}
          />
        )}
        <ShowIcon
          role={"showIconRole"}
          onClick={(): void => handleShowClick({ id: row.id })}
          size={18}
        />
        {can(PermissionsUpdate.OFFER) && (
          <EditIcon
            role={"editIconRole"}
            onClick={(): void => handleEditClick({ id: row.id })}
            size={18}
          />
        )}
        {can(PermissionsDelete.OFFER) && (
          <DeleteIcon
            onClick={(): void => openConfirmToRemoveModal(row)}
            size={20}
            role={"deleteIconRole"}
          />
        )}
      </TableCell>
    </>
  );
};

export default OfferListRow;
