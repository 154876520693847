import React, { ReactElement, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PageWrapper from "../../../ui/pageWrapper/PageWrapper";
import { route } from "../../../utils/url";
import Table from "../../../ui/table/Table";
import useTable from "../../../ui/table/useTable";
import { TableCell } from "@mui/material";
import { getActionText, itemsListHeadCells } from "./utils";
import { handleServerError } from "../../../utils/http";
import { getTenantRequestHistory } from "../../../api/tenantRequest";
import { TenantRequestHistory as History } from "../../../types/be/tenantRequestsHistory";
import { formatTimestamp } from "../../../utils/date";

const TenantRequestHistory = (): ReactElement => {
  const { t } = useTranslation();

  const breadcrumbs = [
    {
      to: route("tenant-requests"),
      name: t("tenantRequests.title"),
    },
  ];

  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleSortChange,
    handleSelectAllClick,
    setTotal,
    handleConfirmToRemoveModalClose,
  } = useTable<History>();

  const handleFetchList = useCallback(async () => {
    setIsLoading(true);
    const response = await getTenantRequestHistory(queryParams);

    if (!response?.ok) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const json = await response.json();
      setData(json?.data);
      setTotal(json?.meta?.total);
    }

    setIsLoading(false);
  }, [queryParams, getTenantRequestHistory]);

  const TableToolbar = <></>;

  const renderRow = (row: History): ReactElement => {
    return (
      <>
        <TableCell>{getActionText(row, t)}</TableCell>
        <TableCell>{row?.description}</TableCell>
        <TableCell>
          {formatTimestamp(Number(row?.created_at), "DD.MM.yyyy")}
        </TableCell>
      </>
    );
  };

  useEffect(() => {
    handleFetchList();
  }, [queryParams]);

  return (
    <PageWrapper title={t("tenant.history.history")} breadcrumbs={breadcrumbs}>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        noDataIsAvailablePlaceholder={"table.noTenantHistoryIsAvailable"}
      />
    </PageWrapper>
  );
};

export default TenantRequestHistory;
