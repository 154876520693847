import { FileTreeFolder as FEFileTreeFolder } from "../../types/fe.interfaces";
import {
  createFolder,
  getFolder,
  updateFile,
  updateFolder,
} from "../../api/folders";
import { extractItemDataFromNodeId } from "../properties/components/fileTree/utils";
import pick from "lodash/pick";
import { FileTreeFile, FileTreeFolder } from "../../types/be.interfaces";
import {
  ContextMenuProperties,
  FileManagementFolderContainerContextType,
  FileManagementListViewItem,
  HandleItemRenameOrCreateSubmitProps,
  SET_IS_FILE_LOADING,
  SET_PREVIEW_FILE,
} from "./types";
import { handleServerError } from "../../utils/http";
import { Dispatch, SetStateAction } from "react";
import { HTTP_STATUS_CODES } from "../../types/server";
import { isNodeEnv } from "../../utils/env";
import { NODE_ENVIRONMENTS } from "../../constants";
import { isImage, isPdf } from "../../types/be/file";
import { getFile } from "../../api/files";
import orderBy from "lodash/orderBy";
import { Many, uniqBy } from "lodash";

export const DEFAULT_PAGE = 1;

export const handleItemRenameOrCreateSubmit = ({
  selected,
  type,
  mode,
  handleCloseDialog,
  setIsSuccess,
}: HandleItemRenameOrCreateSubmitProps) => {
  return async (item: Partial<FEFileTreeFolder>): Promise<void> => {
    const [parent_id] = extractItemDataFromNodeId(selected);
    let response: Response;

    if (mode === "create") {
      const preparedData = {
        ...pick(item, ["name"]),
        parent_id: parseInt(parent_id),
        can_remove: 1,
      };
      response = await createFolder(preparedData);
    } else if (type === "folder") {
      response = await updateFolder(item);
    } else {
      response = await updateFile(item, parent_id);
    }
    await handleServerResponse(
      response,
      setIsSuccess,
      mode === "create" ? HTTP_STATUS_CODES.CREATED : HTTP_STATUS_CODES.OK
    );
    await handleCloseDialog(true)();
  };
};

export const handleServerResponse = async (
  response: Response,
  setIsSuccess: Dispatch<SetStateAction<boolean>>,
  status = HTTP_STATUS_CODES.OK
): Promise<void> => {
  if (response.status === status) {
    setIsSuccess(true);
  } else {
    const { errorMessage } = handleServerError(response);
    /* istanbul ignore next */
    if (!isNodeEnv(NODE_ENVIRONMENTS.TEST)) console.log(errorMessage);
  }
};

export const handleOpenContextMenu = (
  event: React.MouseEvent<Element, MouseEvent>,
  setContextMenuProperties: Dispatch<
    SetStateAction<{
      contextMenuVisibility: boolean;
      contextMenuTop: number;
      contextMenuLeft: number;
    }>
  >
): void => {
  setContextMenuProperties({
    contextMenuVisibility: true,
    contextMenuTop: event.clientY,
    contextMenuLeft:
      event.clientX + contextMenuWidth > window.innerWidth
        ? window.innerWidth - contextMenuWidth
        : event.clientX,
  });
};

export const contextMenuWidth = 320;

export const langDict: Record<string, string> = {
  rename: "rename",
  move: "moveTo",
  create: "createFolder",
};

export const snackbarDict: Record<string, string> = {
  rename: "nameWasSuccessfullyUpdated",
  move: "itemWasSuccessfullyMoved",
  create: "folderWasSuccessfullyCreated",
  delete: "itemWasSuccessfullyDeleted",
  upload: "fileWasSuccessfullyUploaded",
  download: "startingFileDownload",
};

export const initialContextMenuProperties: ContextMenuProperties = {
  contextMenuVisibility: false,
  contextMenuTop: 0,
  contextMenuLeft: 0,
};

export const findFolderById = (
  arr: FileTreeFolder[],
  id: number
): FileTreeFolder | undefined => {
  if (!arr || !arr.length) return;
  let elem;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].id == id) return arr[i];
    const index = arr[i].children?.findIndex((el) => {
      return el.id == id;
    });
    if (index !== -1 && index !== undefined) return arr[i]?.children?.[index];
    elem = findFolderById(arr[i].children, id);
    if (!elem) continue;
    return elem;
  }
  return elem;
};

export const mutateFolder = ({
  folder,
  children,
  files,
}: {
  folder: FileTreeFolder;
  children: FileTreeFolder[];
  files: FileTreeFile[];
}): void => {
  folder.children = uniqBy(children, ({ id }) => id);
  folder.files = uniqBy(files, ({ id }) => id);
};

/*istanbul ignore next*/
export const removeDuplicatesFromFolders = <
  T extends Pick<FileTreeFolder, "id">
>(
  folders: T[]
): T[] =>
  folders.filter(
    (value, index, self) => index === self.findIndex((t) => t.id === value.id)
  );
/* istanbul ignore next */
export const generateUrlToPreview = (url: string): string => `${url}/show`;
/* istanbul ignore next */
export const handleFileDoubleClick =
  ({
    file,
    dispatch,
    handleOpenDialog,
  }: {
    file: FileTreeFile;
    dispatch: FileManagementFolderContainerContextType["dispatch"];
    handleOpenDialog: () => void;
  }) =>
  async (): Promise<void> => {
    dispatch({
      type: SET_IS_FILE_LOADING,
      payload: true,
    });

    if (isPdf(file)) {
      const response = await getFile(file.id);
      const json = await response.json();
      if (response.status === HTTP_STATUS_CODES.OK) {
        const {
          data: { file: previewUrl },
        } = json;
        dispatch({
          type: SET_PREVIEW_FILE,
          payload: { ...file, previewUrl },
        });
      }
    } else if (isImage(file)) {
      dispatch({
        type: SET_PREVIEW_FILE,
        payload: {
          ...file,
          previewUrl: generateUrlToPreview(file.url),
        },
      });
    } else {
      return dispatch({
        type: SET_IS_FILE_LOADING,
        payload: false,
      });
    }
    dispatch({
      type: SET_IS_FILE_LOADING,
      payload: false,
    });
    handleOpenDialog();
  };
/* istanbul ignore next */
export const getSortedAndOrderedFileTreeItems = (
  folders: FileTreeFolder[],
  files: FileTreeFile[],
  sortBy: string,
  order: Many<boolean | "asc" | "desc"> | undefined
): FileManagementListViewItem[] => {
  return sortBy === "extension"
    ? orderBy([...folders, ...files], sortBy, order)
    : [...orderBy(folders, sortBy, order), ...orderBy(files, sortBy, order)];
};
/* istanbul ignore next */
export const mutateFileTree = async (
  expanded: string[],
  folders: FileTreeFolder[]
): Promise<void> => {
  for await (const nodeId of expanded) {
    const [id] = extractItemDataFromNodeId(nodeId);
    const response = await getFolder(id);
    const json = await response.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      const { data } = json;
      const obj = findFolderById(folders || [], data.id);
      obj &&
        mutateFolder({
          folder: obj,
          children: data?.children,
          files: data?.files,
        });
    } else {
      const { errorMessage } = handleServerError(response);
      /* istanbul ignore next */
      if (!isNodeEnv(NODE_ENVIRONMENTS.TEST)) console.log(errorMessage);
    }
  }
};

/* istanbul ignore next */
export const prepareData = (
  folders: FileTreeFolder[],
  id?: string
): FileTreeFolder => {
  return !id
    ? {
        children: folders,
        files: [],
        id: 0,
        name: "",
        can_remove: false,
      }
    : {
        children: folders[0]?.children || [],
        files: folders[0]?.files || [],
        id: 0,
        name: "",
        can_remove: false,
      };
};
