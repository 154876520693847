import styled from "styled-components/macro";
import CloseIcon from "@mui/icons-material/Close";
import { TextField as MuiTextField } from "@mui/material";
import { grey } from "@mui/material/colors";

export const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
  z-index: 1000111;
`;
/* istanbul ignore next */
export const TextField = styled(MuiTextField)`
  .state-abbreviation {
    display: none;
  }
`;
/* istanbul ignore next */
export const MultiCalendarWrapper = styled.div`
  position: relative;
  margin-bottom: ${(props): string => props.theme.spacing(7)};
  .rmdp-shadow {
    box-shadow: none;
  }
  .rmdp-calendar,
  .rmdp-panel {
    border: ${(props): string => props.theme.spacing(0.1)} solid ${grey[600]};
  }
  .rmdp-calendar,
  .rmdp-week,
  .rmdp-header,
  .rmdp-month-picker,
  .rmdp-year-picker {
    background-color: ${(props): string =>
      props.theme.palette.background.paper};

    * {
      color: ${(props): string => props.theme.palette.textColor.default};
    }
  }

  .rmdp-calendar {
    .rmdp-day,
    .rmdp-disabled {
      color: ${grey[600]};
    }
    .rmdp-day {
      color: ${(props): string => props.theme.palette.textColor.default};

      &.rmdp-today {
        &:not(.rmdp-selected) span {
          background: ${grey[600]};
        }
      }
    }
  }
  .rmdp-panel {
    background-color: ${(props): string =>
      props.theme.palette.background.paper};

    * {
      color: ${(props): string => props.theme.palette.textColor.default};
    }
    span {
      color: ${(props): string => props.theme.palette.primary.contrastText};
    }
    button {
      color: black;
    }
  }
`;
/* istanbul ignore next */
export const Error = styled.div`
  margin-top: ${(props) => props.theme.spacing(3)};
  color: ${(props): string => props.theme.palette.textColor.errorColor};
  font-size: ${(props): string => props.theme.spacing(3)};
`;
/* istanbul ignore next */
export const CalendarTooltip = styled.span<{
  $translateX: number;
  $translateY: number;
  $visible?: boolean;
}>`
  position: absolute;
  left: ${(props): string => props.theme.spacing(0)};
  top: ${(props): string => props.theme.spacing(0)};
  background-color: ${(props): string => props.theme.palette.grey[600]};
  border-radius: ${(props): string => props.theme.spacing(1.25)};
  opacity: 0.9;
  padding: ${(props): string => props.theme.spacing(0.75, 1.25)};
  color: white;
  transform: ${(props): string =>
    `translate(${props.$translateX}px, ${props.$translateY}px)`};
  visibility: ${(props): string => (props.$visible ? "visible" : "hidden")};
`;
