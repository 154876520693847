import React, { ReactElement, useCallback, useState } from "react";
import FormAlert from "../../../../../ui/formAlert/FormAlert";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Dropzone from "../../../../../ui/dropzone";
import Button from "../../../../..//ui/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { FileInfo } from "../../../../inbox/styled";
import { getProperties } from "../../../../../api/property";
import Dropdown from "../../../../../ui/dropdown";
import {
  compareFileSize,
  generalPropertyOption,
  prepareQueryParams,
} from "../../../../../utils/common";
import { getInvoices } from "../../../../../api/invoices";
import { FormikValues } from "formik";
import { route } from "../../../../../utils/url";
import { useLocation } from "react-router";
import { MAX_PDF_FILE_SIZE } from "../../../../../constants";

const Form = ({
  values,
  errors,
  touched,
  status,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  setSelectedFile,
  setFieldError,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";

  const [isDropzoneVisible, setIsDropzoneVisible] = useState(true);

  const getInvoiceOptions = useCallback(
    async (search: string) => {
      const newParams = prepareQueryParams(search, {
        fr_status_id: "1",
        property_id: values?.property_id,
      });
      return await getInvoices(newParams);
    },
    [values?.property_id]
  );

  return (
    <Grid
      container
      data-testid="dropzone-container"
      sx={{
        position: "relative",
        justifyContent: "center",
      }}
      onDragOver={() => {
        setIsDropzoneVisible(true);
      }}
      spacing={4}
    >
      <Grid item sm={12}>
        {errors?.file && (
          <FormAlert
            formMessage={{
              type: "error",
              text: errors?.file,
            }}
            sx={{ marginBottom: "10px" }}
          />
        )}
      </Grid>

      {!id && (
        <Grid item sm={12} mt={2}>
          <Dropdown
            id="property-autocomplete"
            name="property_id"
            label={t("form.propertyId")}
            placeholder={t("form.propertyId")}
            getOptions={getProperties}
            onSelectOption={(id?: string) => setFieldValue("property_id", id)}
            optionLabel="object_name"
            optionValue="id"
            size="medium"
            helperText={
              (touched.property_id && errors.property_id) ||
              status?.errors?.property_id
            }
            error={Boolean(
              (touched.property_id && errors.property_id) ||
                status?.errors?.property_id
            )}
            defaultOption={[generalPropertyOption]}
          />
        </Grid>
      )}

      <Grid item sm={12} mt={2}>
        <Dropdown
          id="invoice-autocomplete"
          name="relation_id"
          label={t("form.invoiceId")}
          placeholder={t("form.invoiceId")}
          getOptions={getInvoiceOptions}
          onSelectOption={(id?: string) => setFieldValue("relation_id", id)}
          optionLabel="name"
          optionValue="id"
          size="medium"
          helperText={
            (touched.relation_id && errors.relation_id) ||
            status?.errors?.relation_id
          }
          error={Boolean(
            (touched.relation_id && errors.relation_id) ||
              status?.errors?.relation_id
          )}
        />
      </Grid>

      <Grid item sm={12}>
        <Dropzone
          open={isDropzoneVisible}
          onDrop={async <T extends File>(
            files: FileList | T[] | null
          ): Promise<void> => {
            const file = files?.[0];

            /* istanbul ignore next */
            if (file && compareFileSize(file.size, MAX_PDF_FILE_SIZE)) {
              setFieldError("file", t("fileSize100MB"));
            } else {
              setFieldValue("file", files?.[0]);
              setSelectedFile(files?.[0]);
              setFieldError("file", "");
            }
          }}
        />
      </Grid>
      {values?.file && !errors?.file ? (
        <Grid item xs={7}>
          <FileInfo>
            <span className="file-name">{values?.file?.name}</span>
            <span
              onClick={() => setFieldValue("file", null)}
              className="remove-btn"
            >
              {t("inbox.create.remove")}
            </span>
          </FileInfo>
        </Grid>
      ) : null}

      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          type="button"
          color="primary"
          variant="text"
          title={t("cancel")}
          disabled={isSubmitting}
          size="large"
          onClick={
            /* istanbul ignore next */ () =>
              doesAnyHistoryEntryExist
                ? navigate(-1)
                : id
                ? navigate(route("properties.invoices.reminders", id))
                : navigate(route("invoices.reminders"))
          }
        />
        <Button
          title={t("create")}
          testId="createBtn"
          color="success"
          size="large"
          onClick={handleSubmit}
          isLoading={isSubmitting}
        />
      </Grid>
    </Grid>
  );
};

export default Form;
