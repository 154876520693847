import objectDataSheet from "./objectDataSheet/de";
import maintenance from "./maintenance/de";
import { IBAN_CHARACTERS_NUMBER } from "../../constants";

export default {
  objectDataSheet,
  maintenance,
  property: "Objekt",
  objectName: "Objektname",
  street: "Strasse",
  houseNumber: "Hausnummer",
  zipCode: "PLZ",
  purchasePrice: "Einkaufspreis",
  notes: "Notizen",
  brokerPrice: "Maklerpreis",
  yearOfConstruction: "Baujahr",
  fullArea: "Grundstücksfläche",
  parkingLots: "Stellplätze",
  commercialAreaRentedInM2: "Gewerbefläche vermietet in m²",
  emptyCommercialAreaInM2: "Gewerbefläche Leerstand in m²",
  rentedLivingAreaInM2: "Wohnfläche vermietet in m²",
  emptyLivingAreaInM2: "Wohnfläche Leerstand in m²",
  standardGroundValue: "Bodenrichtwert in €/m²",
  netRentActual: "Netto Mieteinnahmen",
  netRentTarget: "Netto Mieterhöhung pro Jahr in %",
  maintenanceNonReversible: "Nicht umlegbare Instandhaltungskosten",
  operatingCostsNonReversible:
    "Nicht umlegbare Betriebskosten Erhöhung p.a. in %",
  nonCirculatingPropertyManagement:
    "Nicht umlegbare Objektverwaltungskosten Erhöhung p.a. in %",
  taxes: "Steuern in %",
  state: "Bundesland",
  propertyType: "Objekttyp",
  contactType: "Kontakttyp",
  createProperty: "Objekt erstellen",
  createNewObject: "Neues Objekt anlegen",
  propertyCreated: "Eigentum erstellt",
  createPropertyManagement: "Immobilienverwaltung erstellen",
  updateProperty: "Objekt aktualisieren",
  propertyWasSuccessfullyCreated: "Objekt wurde erfolgreich erstellt",
  propertyWasSuccessfullyUpdated: "Objekt wurde erfolgreich aktualisiert",
  financesWasSuccessfullyUpdated: "Finanzen wurde erfolgreich aktualisiert",
  propertyManagementWasSuccessfullyCreated:
    "Immobilienverwaltung wurde erfolgreich erstellt",
  propertyManagementWasSuccessfullyUpdated:
    "Immobilienverwaltung wurde erfolgreich aktualisiert",
  anchorTenant: "Ankermieter",
  rentStartDate: "Mietbeginn",
  rentedArea: "Vermietete Fläche",
  rentEnd: "Mietende",
  rentalIncome: "Anteil Mieteinnahmen zum Objekt in %",
  netRentPerYear: "Nettokaltmiete pro Jahr",
  assetManager: "Asset Manager",
  assetManagers: "AM",
  totalArea: "Gesamtfläche",
  potencialPerYear: "Potenzieller Anteil pro Jahr",
  assetManagersHistoryBtn: "AM-Verlauf",
  assetManagersHistoryTitle: "AM-Verlauf",
  dateOfTakingOver: "Übernahme des Objekts",
  takeover: "Übernahme des Objekts",
  supervisor: "Bauleiter",
  caretaker: "Hausmeister",
  expenses: "Schwierigkeitsgrad des Objektes",
  expensesHelperText: "*1 = einfach, 6 = sehr schwierig",
  wault: "Wault",
  portfolio: "Portfolio",
  taxNumber: "Steuernummer",
  vat: "Umsatzsteuer-ID",
  architect: "Architekt",
  architectHelperText: "aktualisiert",
  city: "Ort",
  contractStartDate: "Vertrag geschlossen am",
  contractExpiryDate: "Vertrag läuft aus am",
  contractCancelDate: "Vertrag gekündigt am",
  propertyManagementCost: "Hausverwaltungskosten pro Monat in €",
  percentageOfNetRent: "Prozent von Nettomiete",
  companyUser: "Ansprechpartner Hausverwaltung",
  propertyValueDevelopment: "Wertenwicklung Immobilie in %",
  commertialType: "Gewerbefläche",
  livingType: "Wohnfläche",
  registrationNumber: "Registergericht-Nr./Handelsregisternummer",
  outgoingAddress: "Ausgehende adresse",
  managingDirector: "Geschäftsführerin",
  propertyEmail: "E-Mail",
  taxId : "Steuer-ID",
  logo: "Logo",
  uploadLogo: "Logo hochladen",
  tabs: {
    general: "Allgemeine Objektinformationen",
    costs: "Nebenkosten",
    finance: "Finanzierungsstruktur",
    operation: "Betrieb und Verwendung",
    repayment: "Exit / Rückzahlung Anleihe",
  },
  manualImport: "Manuell importieren",
  downloadExample: "Importliste runterladen",
  upload: "Hochladen",
  fileIsRequired: "Datei ist erforderlich",
  excelOnly: "Excel hochladen",
  csvOnly: "Hochladen CSV",
  importTitle: "Eigenschaften importieren",
  import: "Importieren",
  importProperties: "Objektliste importieren",
  importTenants: "Mieter importieren",
  tenantsSampleDownload: "Beispieldatei herunterladen",
  bank: {
    rentAccount: "Mietkonto",
    loanAccount: "Darlehenskonto",
    contactPerson: "Ansprechpartner",
    iban: "IBAN",
    bankName: "Bankname",
    bic: "BIC",
    accountNumber: "Kontonummer",
    checkIban: "IBAN prüfen",
    ibanErrorMessage: "IBAN ist nicht vorhanden",
    ibanCharactersNumber: `Muss ${IBAN_CHARACTERS_NUMBER} Zeichen betragen`,
    bankWasSuccessfullyUpdated: "Bank wurde erfolgreich aktualisiert",
    ibanIsRequired: "IBAN ist erforderlich",
    assignProperty: "Eigentum zuweisen",
    searchProperty: "Immobilien suchen",
    propertyAssignedSuccessfully: "Eigentum erfolgreich zugewiesen",
    linkNewBankAccount: "Verknüpfen Sie ein neues Bankkonto",
    addAccountToAnExistingBank: "Konto zu einer bestehenden Bank hinzufügen",
    importSales: "Verkäufe importieren",
    deleteBankAccount: "Bankkonto löschen",
    syncBankAccount: "Bankkonto synchronisieren",
    reloadBankAccountData: "Bankkontodaten neu laden",
    proposals: "Vorschläge",
    notAssigned: "Nicht zugeordnet",
    automaticallyAssigned: "Automatisch zugewiesen",
    ignored: "ignoriert",
    downloadSalesExampleFile: "Verkaufsbeispieldatei herunterladen",
  },
  finance: {
    ownResources: "Eigenmittel in %",
    bankLoan: "Bankkredit in %",
    interestOnOwnFunds: "Eigenmittelverzinsung in %",
    interestBankLoan: "Zins Bankdarlehen in %",
    repaymentBank: "Tilgung Bank in %",
    bankLoanInEur: "Bankkredit in €",
    ownResourcesInEur: "Eigenmittel in €",
  },
  noFileTree: "Kein Dateibaum für diese Eigenschaft",
  selectAnyFileToLink: "Wählen Sie eine beliebige Datei zum Verknüpfen.",
  operationAndUse: {
    nonApportionableAncillaryCosts: "Nichtumlagefähige Nebenkosten",
    maintenanceNonApportionable: "Instandhaltung nicht umlagefähig in %",
    operatingCostsNonRevenueable: "Betriebskosten nicht umlagefähig in %",
    propertyManagementNonRevenueable: "Objektverwaltung nicht umlagefähig in %",
    depreciation: "Abschreibung in %",
  },
  area: {
    activeTenant: "Active tenant",
    futureTenant: "Future tenant",
    areaList: "AreaList",
    name: "Flächenbezeichnung",
    squareMeters: "Fläche in m²",
    emptySpaceEuro: "Potenzielle monatliche Mieteinnahmen",
    emptySpaceSince: "Leerstand seit",
    emptySpaceAtPurchase: "Leerstand bei Ankauf",
    isRented: "Vermietet",
    isNotRented: "Leerstand",
    isActive: "Is active",
    typeName: "Flächentyp",
    categoryName: "Category name",
    usage: "Nutzungsart",
    contactName: "Kontaktname",
    netRentPerMonth: "IST-Netto pm",
    rentStart: "Mietbeginn",
    rentEnd: "Mietende",
    isCancelled: "gekün. / n.verl",
    tenantName: "Mietername",
    comment: "Kommentar",
    cancellationUntil: "Kündigung bis zum",
    closingTenantContract: "Abschluss MV",
    specialCancellationRight: "Sonderkündigunsrecht",
    deposit: "Kaution",
    rentInEurPerParkingSpace: "Miete pro Stellplatz",
    reminder: "Mahnung",
    new: "Neu",
    assetManager: "Asset Manager",
    costsForModification: "Kosten für Umbau",
    art: "Art",
    options: "Optionen",
    remainingTerm: "Restlaufzeit",
    remainingTermInEur: "Restlaufzeit, €",
    usageName: "Nutzung",
    index: "Indexierung",
    netRentPerM2: "Miete/m²",
    totalNetRentPerYear: "IST-Nettokaltmiete p.a.",
    totalNetRentPerMonth: "Gesamt Netto p.m.",
    tax: "19% MwSt.",
    totalGrossNetRentPerMonth: "Gesamt Brutto p.m.",
    totalGrossNetRentPerYear: "Gesamt Brutto p.a.",
    othersIncomingsEur: "NK netto p.m.",
    emptySpace: "Leerstand in m² ",
    squareMetersRequired: "Flächenquadrat in m2 ist erforderlich",
    commercialLiving: "Gewerbe/Wohnen",
    areaName: "Flächenbezeichnung",
    areaSqureInM2: "Fläche in m²",
    createArea: "Fläche Erstellen",
    createAreaButton: "Fläche Hinzufügen",
    areaWasSuccessfullyCreated: "Bereich erfolgreich erstellt",
    createTenant: "Mieter erstellen",
    addTenant: "Mieter hinzufügen",
    tenantWasSuccessfullyCreated: "Mieter erfolgreich angelegt",
    saveHistory: "Geschichte des Gebiets bewahren",
    extendContract: "Vertrag verlängern",
    updateTenant: "Mieter aktualisieren",
    importTitle: "Bereiche importieren",
    edit: {
      tabs: {
        area: "Flächeninformationen",
        requests: "Anfragen",
        requestsBoard: "Fordert vorstand",
        counters: "Zähler",
        tenant: "Mieter",
        history: "Mieterverlauf",
        "empty-area": "Leerstandsinformationen",
      },
      success: "Die Mietfläche wurde erfolgreich aktualisiert",
      deactivate: "Deaktivieren",
      activate: "aktivieren Sie",
      deactivateConfirm:
        "Durch die Deaktivierung werden alle Werte dieser Fläche bei den Berechnungen nicht mehr berücksichtigt.\n\nMöchten Sie die Fläche jetzt deaktivieren?",
      activateConfirm:
        "Durch die Aktivierung dieser Fläche, werden alle Werte in den Kalkulationen berücksichtigt. .\n\nMöchten Sie die Fläche jetzt aktivieren??",
      editArea: "Fläche und Mieter bearbeiten",
      leaveWithoutSaving:
        "Ihre Änderungen sind nicht gespeichert.\n\nMöchten Sie trotzdem fortfahren?",
      emptySpaceEurRequired: "Die Fläche ist nicht vermietet, geben Sie bitte eine potenzielle Nettokaltmiete an",
    },
    searchArea: "Suchbereich",
    submit: "Einreichen",
    cancelTheLeaseOn: "Kündigen Sie den Mietvertrag am",
    updateTenantLease: "Mietvertrag aktualisieren",
    nextTenantAvailable: "Nachmieter vorhanden",
    selectAll: "Alle auswählen",
  },
  tenants: {
    areaDesignation: "Flächenbezeichnung",
    chooseType: "Wählen Sie Typ",
    rentalAreaInM2: "Mietfläche in m²",
    use: "Nutzung",
    indexing: "Indexierung",
    calculationResult: {
      business: "Gewerbe",
      living: "Wohnen",
      livingSqm:
        "Aufteilung der Objektfläche nach Vermietung und Leerstand in m²",
      vacancy: "Leerstand",
      rented: "Vermietet",
      reside: "Wohnen",
      total: "Summe",
      pa: "jährlich",
      pm: "monatlich",
      averageNetRentPerSquareMeter:
        "Durchschnittliche Nettokaltmiete in € pro m²",
      calculatedRemainingTerm: "Nettokaltmiete",
      remainingTermTotal: "Restlaufzeit gesamt in €",
      commercialAndLivingArea: "Gewerbe- und Wohnfläche in m²",
      landAllocation: "Flächenaufteilung",
      objectTotal: "Objekt insgesamt",
      businessInSqm: "Gewerbefläche",
      livingInSqm: "Wohnfläche",
      rentableArea: "Anmietbare Fläche",
      totalArea: "Gesamtfläche",
      shareOfISTNME: "Anteil an Nettokaltmiete in %",
      subdivisionOfRentedAndVacantSpace:
        "Aufteilung von vermieteten und leerstehenden Flächen",
      vacancyRate: "Leerstandsquote",
      vacancyValued: "Potenzial von Leerstandsflächen in €",
    },
    selectContact: "Wählen Sie einen Kontakt",
    createContact: "Kontakt erstellen",
  },
  navLinks: {
    assetManager: "Asset management",
    transactionManager: "Transaction management",
    release: "Freigabe",
    dataSheet: "Objektdatenblatt",
    tenantList: "Mieterliste / Flächenliste",
    quickSheet: "Quick sheet",
    invoices: "Rechnungen",
    contracts: "Verträge",
    new: "Neu",
    pending: "Pending",
    released: "Freigeben",
    finalReleased: "Endgültig freigegeben",
    rejected: "Ablehnen",
    existingOldContracts: "Bestehende Altverträge",
    finalReleaser: "Finale Freigabe",
    offers: "Angebote",
    insurances: "Versicherungen",
    building: "Gebäudeversicherung",
    liability: "Haftpflichtversicherung",
    edit: "QuickSheet",
    reminders: "Mahnungen",
    outgoingInvoices: "Ausgangsrechnungen",
  },
  cost: {
    purchasePrice: "Kaufpreis",
    buildingInPercents: "Gebäude in %",
    buildingInEuro: "Gebäude in €",
    landInPercents: "Gründstück in %",
    landInEuro: "Gründstück in €",
    propertyTransferTax: "Grunderwerbssteuer in %",
    realEstateAgent: "Makler in %",
    notary: "Notar Grundbuch in %",
    dueDilligence: "Due Dilligence in %",
    other: "Sonstiges in %",
    buffer: "Puffer in %",
    otherEuro: "Sonstiges in €",
    bufferEuro: "Puffer in €",
    validation: {
      buildingInPercentsIsRequired: "Der Aufbau in % ist erforderlich",
      buildingInEuroIsRequired: "Gebäude in Euro ist erforderlich",
      landInPercentsIsRequired: "Land in % ist erforderlich",
      propertyTransferTaxIsRequired: "Grunderwerbsteuer ist erforderlich",
      realEstateAgentIsRequired: "Immobilienmakler in % ist erforderlich",
      notaryIsRequired: "Notar in % ist erforderlich",
      dueDilligenceIsRequired: "Sorgfalt ist erforderlich",
      otherInPercentsIsRequired: "Sonstiges in % ist erforderlich",
      bufferInPercentsIsRequired: "Puffer in % erforderlich",
    },
  },
  general: {
    fileUploadLimit: "Es können maximal 10 Bilder hochgeladen werden",
  },
  noPictures: "Keine Bilder verfügbar",
  more: "Mehr",
  filterBy: "Filtern nach",
  from: "von",
  list: "Liste",
  grid: "Netz",
  carouselFilesUpload: "Bilder/Videos hochladen",
  imageUploadPlaceHolder: "Ziehen Sie Ihre Bilder per Drag & Drop hierher oder",
  carouselImageUploadPlaceHolder:
    "Ziehen Sie Ihre Bilder/Videos hierher oder legen Sie sie dort ab",
  uploadFiles: "Bilder hochladen",
  rented: "Vermietet",
  rentalIncomePerYear: "Mieteinnahmen pro Jahr",
  emptyAreaForm: {
    information: "Informationen",
    listing: "Anzeige",
    exposeHeadline: "Exposéüberschrift",
    street: "Straße",
    houseNumber: "Hausnummer",
    zipCode: "PLZ",
    city: "Ort",
    publishPropertyAddress: "Objektadresse veröffentlichen?",
    contactPerson: "Kontaktperson",
    storeType: "Ladentyp",
    deposit: "Kaution",
    propertyState: "Objektzustand",
    constructionYear: "Baujahr",
    numberOfParkingLots: "Anzahl der Parkplätze",
    lastModernization: "Letzte Modernisierung",
    marketingType: "Vermarktungsart",
    price: "Preis",
    validPriceForAreaOrPeriod: "Preis gilt für Fläche / Zeitraum",
    salesArea: "Verkaufsfläche",
    totalArea: "Gesamtfläche",
    subjectToCommission: "Provisionspflichtig",
    propertyDescription: "Objektbeschreibung",
    location: "Lage",
    equipment: "Austattung",
    otherInformation: "Sonstige Angaben",
    informationUpdatedSuccessfully: "Informationen erfolgreich aktualisiert",
    listingUpdatedSuccessfully: "Anzeige erfolgreich aktualisiert",
  },
  confirmRemoveText:
    "Möchten Sie das Objekt und damit auch alle zum Objekt gehörenden Inhalte wirklich löschen ?",
};
