import React, { ReactElement, useCallback, useEffect } from "react";
import { Grid } from "@mui/material";
import { FormikProps } from "formik";
import Text from "../../../../../../ui/formsFields/text";
import { initialValues } from "./utils";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../../../../../ui/autocomplete/Autocomplete";
import { getUsers, searchUser } from "../../../../../../api/users";
import { User } from "../../../../../../types/models";
import Button from "../../../../../../ui/button/Button";
import { DEBOUNCE_TIME } from "../../../../../../utils/common";
import { AutocompleteOption } from "../../../../../../ui/autocomplete/types";
import { useFetch } from "../../../../../../hooks/useFetch";

/* istanbul ignore next */
const Form = ({
  values,
  status,
  errors,
  touched,
  isSubmitting,
  handleSubmit,
  setValues,
}: FormikProps<typeof initialValues>): ReactElement => {
  const { t } = useTranslation();

  const { data: preloadedUserData, run: runPreloadedUserList } =
    useFetch<AutocompleteOption[]>();

  const handleInputChange = useCallback(async (search: string) => {
    const response = await searchUser(search);
    const { data } = await response.json();

    return data;
  }, []);

  const onSelectUser = useCallback(
    async (event: User): Promise<void> => {
      setValues({
        ...values,
        user_id: event?.id?.toString() || "",
        email: event?.email || "",
      });
    },
    [values]
  );

  useEffect(() => {
    runPreloadedUserList(getUsers());
  }, []);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={4} sx={{ mt: 0.5 }}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={5.6}>
              <Text
                name="email"
                value={values.email}
                label={t("inbox.forward.recipientEmail")}
                placeholder={t("inbox.forward.recipientEmail")}
                type="email"
                status={status}
                disabled={!!values.user_id}
              />
            </Grid>
            <Grid item xs={0.8} justifyContent="center" display={"flex"}>
              {t("or")}
            </Grid>
            <Grid item xs={5.6}>
              <Autocomplete
                id="user-autocomplete"
                name="email"
                handleOnChangeInputText={handleInputChange}
                handleOptionSelect={onSelectUser}
                placeholder={t("searchUser")}
                value={values.email || ""}
                autocompleteValue={
                  values?.user_id
                    ? { id: values?.user_id, email: values?.email }
                    : null
                }
                optionValue="id"
                optionLabel="email"
                error={Boolean(
                  touched && (status?.errors?.email || errors.email)
                )}
                helperText={touched && (errors?.email || status?.errors?.email)}
                debounceTime={DEBOUNCE_TIME}
                sx={{ minHeight: 1, mb: 0 }}
                preloadOptions={preloadedUserData}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Text
            name="text"
            value={values.text}
            label={t("inbox.forward.text")}
            placeholder={t("inbox.forward.text")}
            rows={5}
            multiline
            status={status}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            title={t("send")}
            color="success"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            size="large"
            type="submit"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
