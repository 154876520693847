import AuthGuard from "../../components/guards/AuthGuard";
import DashboardLayout from "../../layouts/dashboard";
import React from "react";
import MaintenanceList from "../../components/properties/maintenance/list";
import CreateMaintenance from "../../components/properties/maintenance/create";
import EditMaintenance from "../../components/properties/maintenance/edit";
import MaintenanceUnits from "../../components/properties/maintenance/MaintenanceUnits";
import {
  PermissionsCreate,
  PermissionsRead,
  PermissionsUpdate,
} from "../../constants";
import AccessGuard from "../../components/guards/AccessGuard";

export default {
  path: "/:client/maintenance",
  element: (
    <AuthGuard clientModuleName="maintenance">
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: (
        <AccessGuard permissions={[PermissionsRead.MAINTENANCE]}>
          <MaintenanceList />
        </AccessGuard>
      ),
    },
    {
      path: "create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.MAINTENANCE]}>
          <CreateMaintenance />
        </AccessGuard>
      ),
    },
    {
      path: ":maintenanceId/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.MAINTENANCE]}>
          <EditMaintenance />
        </AccessGuard>
      ),
    },
    {
      path: ":maintenanceId/units",
      element: (
        <AccessGuard permissions={[PermissionsRead.MAINTENANCE]}>
          <MaintenanceUnits />
        </AccessGuard>
      ),
    },
  ],
};
