import {
  FormInitialValues,
  HandleCounterCreateProps,
  HandleMeterCreateProps,
  SnackbarMessage,
  ViewableFile,
} from "./types";
import { FormikValues } from "formik";
import {
  createCounter,
  createReading,
  getCounterList,
} from "../../../api/reading";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { route } from "../../../utils/url";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { mapBackendValidationErrors } from "../../../utils/form";
import { prepareQueryParams } from "../../../utils/common";
import { formationDateForBackEnd } from "../../../utils/date";

export const createCounterInitialValues: FormInitialValues = {
  property_id: "",
  number: "",
  type_id: "",
  property_area_id: "",
  value: "",
  file: [],
  counter_id: "",
  date: new Date(),
};

export const handleCreateMeterSubmit = ({
  navigate,
  setSnackbarCredentials,
}: HandleMeterCreateProps) => {
  return async (
    data: FormInitialValues,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    setStatus({
      success: true,
      errors: {},
    });

    const formData = new FormData();
    formData.append("value", String(data?.value));
    data?.file?.forEach((file: ViewableFile, index: number) => {
      file.file && formData.append(`file[${index}]`, file.file);
    });
    formData.append("date", String(formationDateForBackEnd(data?.date)));

    if (data?.counter_id) {
      formData.append("counter_id", String(data?.counter_id));
    } else {
      const counter_id = await handleCreateCounter({
        values: data,
        setSnackbarCredentials,
        setStatus,
        setSubmitting,
      });
      if (counter_id) {
        formData.append("counter_id", String(counter_id));
      } else {
        return;
      }
    }

    const response = await createReading(formData);
    const result = await response?.json();
    if (
      [HTTP_STATUS_CODES.CREATED, HTTP_STATUS_CODES.OK].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      setSubmitting(false);
      navigate(route("meter"));
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(result?.errors),
      });
    } else {
      setSnackbarCredentials({
        visible: true,
        text: SOMETHING_WENT_WRONG_ERROR,
        color: "error",
      });
      setSubmitting(false);
    }
  };
};

export const initialSnackbarCredentials: SnackbarMessage = {
  text: "",
  visible: false,
  color: "success",
};

export const handleCreateCounter = async ({
  values,
  setSnackbarCredentials,
  setStatus,
  setSubmitting,
}: HandleCounterCreateProps): Promise<number | undefined> => {
  const formData = new FormData();
  formData.append("number", values.number);
  formData.append("type_id", values.type_id);
  formData.append("property_area_id", values.property_area_id);

  const response = await createCounter(formData);
  const result = await response?.json();

  if (
    [HTTP_STATUS_CODES.CREATED, HTTP_STATUS_CODES.OK].includes(response.status)
  ) {
    setStatus({
      success: true,
      errors: {},
    });
    return result?.data?.id;
  } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
    const params = prepareQueryParams("", {
      search: values.number,
    });
    const response = await getCounterList(params);
    const { data } = await response.json();
    return data?.[0]?.id;
  } else {
    setSnackbarCredentials({
      visible: true,
      text: SOMETHING_WENT_WRONG_ERROR,
      color: "error",
    });
    setSubmitting(false);
    return;
  }
};

export const MAX_FILES = 5;
