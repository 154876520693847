/* istanbul ignore file */

import {
  MenuItem as MuiMenuItem,
  TextField as MuiTextField,
} from "@mui/material";
import { Box } from "@mui/system";
import styled from "styled-components/macro";
import MultiSelectCustom from "../../../../../../ui/formsFields/multiSelect";

/* istanbul ignore next */
export const SelectBox = styled(Box)`
  gap: ${(props): string => props.theme.spacing(0.5)};
  display: flex;
  flex-wrap: wrap;
`;

export const SelectDefault = styled.div`
  color: ${(props): string => props.theme.palette.grey[600]};
  padding-left: ${(props): string => props.theme.spacing(2.75)};
`;

export const MultiSelect = styled(MultiSelectCustom)`
  padding-top: ${(props): string => props.theme.spacing(0.5)};
  padding-bottom: ${(props): string => props.theme.spacing(0.5)};
`;

export const MenuItem = styled(MuiMenuItem)`
  padding-top: ${(props): string => props.theme.spacing(0)};
  padding-bottom: ${(props): string => props.theme.spacing(0)};
  .MuiCheckbox-root {
    padding: ${(props): string => props.theme.spacing(1.5)};
  }
`;

export const TextField = styled(MuiTextField)`
  .MuiInputBase-root {
    color: ${(props): string => props.theme.palette.grey[600]};
  }
  .MuiSelect-select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;
