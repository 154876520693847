import { CreateTopicValuesType, FormSubmitHandlerProps } from "./types";
import { FormikValues } from "formik";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../../constants";
import { HTTP_STATUS_CODES } from "../../../../../../../types/server";
import { mapBackendValidationErrors } from "../../../../../../../utils/form";
import { createOfferTopic } from "../../../../../../../api/offerTopic";

export const createTopicInitialValues: CreateTopicValuesType = {
  property_id: "",
  name: "",
};

export const handleCreateOfferTopic = ({
  setFormMessage,
  afterCreateTopic,
}: FormSubmitHandlerProps) => {
  return async (
    values: CreateTopicValuesType,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const data = { ...values };
    if (!Number(data?.property_id)) {
      data.property_id = "";
    }
    const response = await createOfferTopic(data);
    const json = await response.json();

    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      afterCreateTopic && afterCreateTopic(json.data);
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
    }
    setSubmitting(false);
  };
};
