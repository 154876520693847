import styled from "styled-components";
import { Paper as MUIPaper } from "@mui/material";

export const Paper = styled(MUIPaper)`
  padding: ${(props) => props.theme.spacing(5)};
`;

export const TenantNameLabel = styled.span`
  padding: ${(props) => `${props.theme.spacing(2)} ${props.theme.spacing(6)}`};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

export const Image = styled.img`
  width: ${(props) => props.theme.spacing(25)};
  height: ${(props) => props.theme.spacing(25)};
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 50vh;
`;

export const DialogTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ShowHistoryButton = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;
