import { useCallback, useEffect } from "react";
import { useFetch } from "./useFetch";
import { ContactType } from "../types/models";
import { getContactTypes } from "../api/contacts";
import { prepareQueryParams } from "../utils/common";

interface UseTenantTypesProps {
  contactTypes: ContactType[] | null;
  getContactType: (query: string | number) => ContactType | void;
  isError: boolean;
  isLoading: boolean;
}

const useContactTypes = (): UseTenantTypesProps => {
  const {
    data: contactTypes,
    isError,
    isLoading,
    run,
  } = useFetch<ContactType[]>();

  useEffect(() => {
    const params = prepareQueryParams("", {
      order: "asc",
    });
    run(getContactTypes(params));
  }, []);

  const getContactType = useCallback(
    (query: string | number): ContactType | void =>
      contactTypes?.find((contactType) =>
        [contactType.code, contactType.id].includes(query)
      ),
    [contactTypes]
  );

  return {
    contactTypes,
    getContactType,
    isError,
    isLoading,
  };
};

export default useContactTypes;
