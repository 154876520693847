import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Grid, TableCell, Tooltip } from "@mui/material";
import {
  DeleteIcon,
  SendIcon,
  Search,
  SearchIconWrapper,
  Input,
} from "../../../ui/table/styled";
import { Inbox, InboxStatusCodes } from "../../../types/be/inbox";
import useTable from "../../../ui/table/useTable";
import Table from "../../../ui/table/Table";
import {
  deleteInbox,
  getInboxList,
  patchInboxStatus,
  unassignPropertyToInbox,
  updateInbox,
} from "../../../api/inbox";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { handleServerError } from "../../../utils/http";
import {
  FILTER_TYPE,
  generalPropertyOption,
  getFilteredHeadCellsByProperty,
  prepareQueryParams,
} from "../../../utils/common";
import { useTranslation } from "react-i18next";
import {
  DATE_TIME_FORMAT,
  itemsListHeadCells,
  snackbarInitState,
} from "./utils";
import {
  formatTimestamp,
  replaceTimestampByMomentDatepicker,
} from "../../../utils/date";
import { DoneAll, RemoveDone } from "@mui/icons-material";
import {
  COMMENTABLE,
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  Measurement_Units,
  PermissionsCreate,
  PermissionsDelete,
  PermissionsUpdate,
  SOMETHING_WENT_WRONG_ERROR,
  THOUSAND_SEPARATOR,
} from "../../../constants";
import { showCommentsModalInitState } from "../../../ui/commentsPopUp/utils";
import CommentsPopup from "../../../ui/commentsPopUp";
import useInboxStatus from "../../../hooks/useInboxStatus";
import { useParams, useNavigate } from "react-router-dom";
import { route } from "../../../utils/url";
import { NavButton } from "./styled";
import Snackbar from "../../../ui/Snackbar";
import Button from "../../../ui/button/Button";
import Loader from "../../Loader";
import CloseButton from "../../../ui/dialog/CloseButton";
import { SnackbarMessage } from "../types";
import AssignPropertyDialog from "../assignPropertyDialog";
import { Search as SearchIcon } from "react-feather";
import { handleAssignProperty } from "../assignPropertyDialog/utils";
import InboxTypesSelect from "./inboxTypesSelect";
import { INBOX_STATUS } from "../utils";
import useInboxTypes from "../../../hooks/useInboxTypes";
import { getProperties } from "../../../api/property";

import InboxContactAutocomplete from "./inboxContactAutocomplete";
import useAccessControl from "../../../hooks/useAccessControl";
import { TableEditableText } from "../../../ui/editableText";
import { NumericFormat } from "react-number-format";
import { EDITABLE_FIELDS_TYPE } from "../../properties/edit/general/utils";
import RedirectProperty from "../../../ui/redirectProperty";
import ForwardDocumentDialog from "../../properties/invoices/forwardDocument/ForwardDocumentDialog";
import Dropdown from "../../../ui/dropdown";
import { usePropertySelect } from "../../../hooks/usePropertySelect";
import TabPageWrapper from "../../../ui/tabPageWrapper";
import useAppSelector from "../../../hooks/useAppSelector";
import MultipleSelect from "../../properties/components/multiSelect";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { setHiddenColumnIds } from "../../../redux/slices/inbox";
import { filter } from "lodash";
import ExpandableComment from "../../../ui/expandableComment/ExpandableComment";
import { useFetch } from "../../../hooks/useFetch";
import { contactAutocomplete } from "../../../api/contacts";
import { Contact } from "../../../types/be/contact";
import FileTableCell from "../../properties/components/fileTableCell";

/* istanbul ignore next */
const InboxList = (): ReactElement => {
  const { t } = useTranslation();
  const { status = InboxStatusCodes.new, id } = useParams();
  const navigate = useNavigate();
  const { can } = useAccessControl();
  const dispatch = useAppDispatch();

  const { optionalColumnIds, selectedOptionalColumnsIds } = useAppSelector(
    (state) => state.inbox
  );

  const [selectedInboxId, setSelectedInboxId] = useState<number | null>(null);

  const { propertyId, property, isGeneral, onPropertySelect } =
    usePropertySelect(id);
  const { data: preloadedContactsData, run: runPreloadedContactsList } =
    useFetch<Contact[]>();

  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleSortChange,
    handleSelectAllClick,
    setTotal,
    openConfirmToRemoveModal,
    rowToDelete,
    handleDeleteLastPageData,
    handleConfirmToRemoveModalClose,
    handleTableSearch,
  } = useTable<Inbox>();
  const { findInboxStatus, statusList } = useInboxStatus();

  const [isShowCommentsModal, setIsShowCommentsModal] = useState(
    showCommentsModalInitState
  );
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [removingProperty, setRemovingProperty] = useState<string | null>(null);
  const [snackbarData, setSnackbarData] =
    useState<SnackbarMessage>(snackbarInitState);
  const [inboxId, setInboxId] = useState<string | null>(null);
  const { inboxTypes, getInboxType } = useInboxTypes();
  const [showAssginModal, setShowAssginModal] = useState(false);
  const [selectedInboxData, setSelectedInboxData] = useState<Inbox | null>(
    null
  );

  const handleCloseSnackbar = (): void => setSnackbarData(snackbarInitState);

  const handleOpenComments = useCallback((row: Inbox): void => {
    setIsShowCommentsModal({ isVisible: true, rowId: row?.id });
  }, []);

  const handleFetchList = useCallback(async () => {
    const statusId = findInboxStatus(status)?.id?.toString();
    const payload: Record<string, string | undefined> = {
      status_id: statusId,
    };

    if (isGeneral === FILTER_TYPE.GENERAL || propertyId || id) {
      payload.property_id = propertyId || id || "";
    }

    const params = prepareQueryParams(queryParams, payload, true);
    const response = await getInboxList(params);

    if (response.status !== HTTP_STATUS_CODES.OK) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const json = await response.json();
      setData(json?.data);
      setTotal(json?.meta?.total);
    }

    setIsLoading(false);
  }, [queryParams, propertyId, status, findInboxStatus, id, isGeneral]);

  const handleCloseCommentsModal = useCallback((): void => {
    setIsShowCommentsModal(showCommentsModalInitState);
    handleFetchList();
  }, [handleFetchList]);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteInbox(rowToDelete.id);
    handleDeleteLastPageData(handleFetchList);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteInbox,
    handleFetchList,
    handleConfirmToRemoveModalClose,
  ]);

  const handleChangeInboxStatus = useCallback(
    async (row: Inbox, statusType: InboxStatusCodes) => {
      const status = findInboxStatus(statusType);
      const statusId = status?.id;

      const response = await patchInboxStatus(row.id, statusId!);
      if (response.status !== HTTP_STATUS_CODES.OK) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        handleFetchList();
      }
    },
    [findInboxStatus, status, handleFetchList, patchInboxStatus]
  );

  const handleStatusChange = useCallback(
    (status: string) => {
      if (id) {
        navigate(route("properties.inbox.tab", id, status));
      } else {
        navigate(route("inbox.tab", status));
      }
    },
    [id]
  );

  const onCreateClick = useCallback(() => {
    if (id) {
      navigate(route("properties.inbox.create", id));
    } else {
      navigate(route("inbox.create"));
    }
  }, [id]);

  const handleRemoveProperty = useCallback(
    async (id: string): Promise<void> => {
      setRemovingProperty(id);
      const response = await unassignPropertyToInbox(id);
      if (response.status === HTTP_STATUS_CODES.OK) {
        await handleFetchList();
        setSnackbarData({
          visible: true,
          text: t("inbox.propertyRemovedSuccessfully"),
          color: "success",
        });
      } else {
        setSnackbarData({
          visible: true,
          text: t(SOMETHING_WENT_WRONG_ERROR),
          color: "error",
        });
      }
      setRemovingProperty(null);
    },
    [unassignPropertyToInbox, handleFetchList]
  );

  const afterAssignProperty = useCallback(async () => {
    await handleFetchList();
    setInboxId(null);
  }, [handleFetchList]);

  useEffect(() => {
    setIsLoading(true);
    queryParams && statusList && handleFetchList();
  }, [queryParams, status, statusList, id, propertyId, isGeneral]);

  useEffect(() => {
    runPreloadedContactsList(contactAutocomplete(""));
  }, []);

  const handleChange = useCallback(
    async (id: number, key: string, value: string): Promise<void> => {
      const formData = new FormData();
      formData.append(key, value);
      formData.append("_method", "PUT");
      const response = await updateInbox(id, formData);
      if (response.status === HTTP_STATUS_CODES.OK) {
        await handleFetchList();
      } else {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      }
    },
    [
      updateInbox,
      queryParams,
      propertyId,
      status,
      findInboxStatus,
      id,
      isGeneral,
    ]
  );

  /* istanbul ignore next */
  const onAssignClick = (data: Inbox): void => {
    setSelectedInboxData(data);
    setShowAssginModal(true);
  };

  /* istanbul ignore next */
  const hideSelectDialog = (): void => {
    setSelectedInboxData(null);
    setShowAssginModal(false);
    handleFetchList();
  };

  const handleOptionsSelectedChange = useCallback(
    (optionsSelected: string[]) => {
      dispatch(setHiddenColumnIds(optionsSelected));
    },
    []
  );

  const tableHeaderTitles = useMemo(() => {
    let filteredItems = filter(
      itemsListHeadCells,
      (o) =>
        !optionalColumnIds.includes(o.id) ||
        selectedOptionalColumnsIds.includes(o.id)
    );
    filteredItems = getFilteredHeadCellsByProperty(
      filteredItems,
      "property_name",
      id
    );
    return filteredItems;
  }, [selectedOptionalColumnsIds, optionalColumnIds]);

  const TableToolbar = (
    <Grid container mt={1} mb={2}>
      <Grid item xs={12}>
        <Grid container mt={1}>
          {statusList?.map((item) => (
            <Grid item key={`$status-option-${item.code}`} py={4} pr={4}>
              <NavButton
                size="small"
                disabled={status === item.code}
                key={item.code}
                onClick={() => handleStatusChange(item.code)}
                title={item?.name}
                variant="text"
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          mt={1}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Grid item xs={6} sx={{ pt: 2, gap: 2 }} display="flex">
            {can(PermissionsCreate.INBOX) && status === InboxStatusCodes.new ? (
              <Grid item>
                <Button
                  color="success"
                  title={t("inbox.create.title")}
                  onClick={onCreateClick}
                  sx={{ fontSize: 15 }}
                />
              </Grid>
            ) : null}
            <Grid item xs={"auto"}>
              <MultipleSelect
                options={optionalColumnIds}
                selected={selectedOptionalColumnsIds}
                handleOptionsSelectedChange={handleOptionsSelectedChange}
              />
            </Grid>
            <Grid item lg={4} xs={12} md={4} sm={6}>
              {!id ? (
                <Dropdown
                  id="property-autocomplete"
                  name="property_id"
                  placeholder={t("documentRelease.invoice.searchProperty")}
                  getOptions={getProperties}
                  onSelectOption={onPropertySelect}
                  optionLabel="object_name"
                  size="small"
                  defaultValue={property}
                  defaultOption={[generalPropertyOption]}
                />
              ) : null}
            </Grid>
          </Grid>

          <Grid item>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t("search")} onChange={handleTableSearch} />
            </Search>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderRow = (row: Inbox): ReactElement => {
    return (
      <>
        {!id && (
          <TableCell align="left">
            {row?.property ? (
              <Grid display="flex" gap={2} alignItems="center">
                <RedirectProperty property={row?.property} />
                {removingProperty === String(row?.id) ? (
                  <Loader size={15} />
                ) : (
                  <Tooltip
                    title={
                      row.status.code === INBOX_STATUS.COMPLETED
                        ? t("inbox.pleaseMoveToProgress")
                        : null
                    }
                  >
                    <span>
                      {can(PermissionsUpdate.INBOX) && (
                        <CloseButton
                          disabled={row.status.code === INBOX_STATUS.COMPLETED}
                          onClick={() => handleRemoveProperty(String(row?.id))}
                        />
                      )}
                    </span>
                  </Tooltip>
                )}
              </Grid>
            ) : can(PermissionsUpdate.INBOX) ? (
              <Tooltip
                title={
                  row.status.code === INBOX_STATUS.COMPLETED
                    ? t("inbox.`pleaseMoveToProgress`")
                    : null
                }
              >
                <span>
                  <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    disabled={row.status.code === INBOX_STATUS.COMPLETED}
                    onClick={() => setInboxId(String(row?.id))}
                    title={t("bankAccount.assignProperty")}
                  />
                </span>
              </Tooltip>
            ) : (
              EMPTY_DATA
            )}
          </TableCell>
        )}
        <TableCell
          align="left"
          sx={{
            minWidth: 300,
          }}
        >
          <InboxContactAutocomplete
            inboxData={row}
            setSnackbarData={setSnackbarData}
            defaultPreloadedData={preloadedContactsData ?? []}
          />
        </TableCell>
        {selectedOptionalColumnsIds.includes("invoice_amount") && (
          <TableCell align="left">
            <TableEditableText
              isDisabled={row.status.code === INBOX_STATUS.COMPLETED}
              name="invoice_amount"
              permission={PermissionsUpdate.INBOX}
              label={
                <>
                  {row?.invoice_amount !== null || row.invoice ? (
                    <>
                      <NumericFormat
                        thousandSeparator={THOUSAND_SEPARATOR}
                        decimalSeparator={DECIMAL_SEPARATOR}
                        decimalScale={DECIMAL_SCALE}
                        fixedDecimalScale
                        displayType={"text"}
                        value={row.invoice?.amount || row.invoice_amount}
                      />{" "}
                      {Measurement_Units.EURO}
                    </>
                  ) : (
                    <>{EMPTY_DATA}</>
                  )}
                </>
              }
              initialValues={{ invoice_amount: row.invoice_amount ?? "" }}
              onSave={async (key, value) => {
                await handleChange(row.id, key, value);
              }}
              type={EDITABLE_FIELDS_TYPE.FORMAT_NUMBER}
            />
          </TableCell>
        )}
        {selectedOptionalColumnsIds.includes("invoice_date") && (
          <TableCell align="left">
            <TableEditableText
              isDisabled={row.status.code === INBOX_STATUS.COMPLETED}
              name="invoice_date"
              permission={PermissionsUpdate.INBOX}
              label={
                <>
                  {row.invoice
                    ? formatTimestamp(row.invoice.date)
                    : row.invoice_date
                    ? formatTimestamp(row.invoice_date)
                    : EMPTY_DATA}
                </>
              }
              initialValues={{
                invoice_date: replaceTimestampByMomentDatepicker(
                  row.invoice_date
                ),
              }}
              onSave={async (key, value) => {
                await handleChange(row.id, key, value);
              }}
              type={EDITABLE_FIELDS_TYPE.DATE}
            />
          </TableCell>
        )}
        {selectedOptionalColumnsIds.includes("invoice_number") && (
          <TableCell align="left">
            <TableEditableText
              permission={PermissionsUpdate.INBOX}
              isDisabled={row.status.code === INBOX_STATUS.COMPLETED}
              name="invoice_number"
              label={
                <>{row.invoice?.number || row.invoice_number || EMPTY_DATA}</>
              }
              initialValues={{ invoice_number: row.invoice_number ?? "" }}
              onSave={async (key, value) => {
                await handleChange(row.id, key, value);
              }}
              type={EDITABLE_FIELDS_TYPE.TEXT}
            />
          </TableCell>
        )}
        <TableCell align="left">
          <ExpandableComment
            seeAllComment={() => handleOpenComments(row)}
            commentData={row?.latest_comment}
          ></ExpandableComment>
        </TableCell>
        <FileTableCell file={row.file} />
        <TableCell align="left">{row?.status?.name}</TableCell>
        <TableCell align="left">
          {row.manager
            ? `${row?.manager?.first_name} ${row?.manager?.last_name}`
            : EMPTY_DATA}
        </TableCell>
        <TableCell align="left">
          {formatTimestamp(row.created_at, DATE_TIME_FORMAT)}
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "135px" }}>
          {can(PermissionsUpdate.INBOX) && (
            <Button
              color="primary"
              title={t(
                row.status.code !== InboxStatusCodes.completed
                  ? "inbox.assignTitle"
                  : "inbox.reassignTitle"
              )}
              onClick={() => {
                /* istanbul ignore next */
                onAssignClick(row);
              }}
            />
          )}
          <div>
            {row.status.code === InboxStatusCodes.completed
              ? row.type?.name
              : ""}
          </div>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "135px" }}>
          <Grid container justifyContent="flex-end">
            {can(PermissionsUpdate.INBOX) && (
              <span>
                {row.status.code !== InboxStatusCodes.completed ? (
                  <Grid item>
                    <Tooltip title={t(`inbox.complete`)} placement="top">
                      <Grid container>
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <DoneAll
                            color="success"
                            width={22}
                            fontSize="small"
                            height={22}
                            onClick={() =>
                              handleChangeInboxStatus(
                                row,
                                InboxStatusCodes.completed
                              )
                            }
                            data-testid="complete-icon"
                          />
                        </Grid>
                      </Grid>
                    </Tooltip>
                  </Grid>
                ) : (
                  <Grid item>
                    <Tooltip title={t(`inbox.incomplete`)} placement="top">
                      <Grid container>
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <RemoveDone
                            color="success"
                            width={22}
                            height={22}
                            fontSize="small"
                            onClick={() =>
                              handleChangeInboxStatus(row, InboxStatusCodes.new)
                            }
                            data-testid="cancel-icon"
                          />
                        </Grid>
                      </Grid>
                    </Tooltip>
                  </Grid>
                )}
              </span>
            )}
            <Grid item>
              <SendIcon
                role="ForwardIconRole"
                size={18}
                onClick={() => setSelectedInboxId(row.id)}
              />
            </Grid>
            {can(PermissionsDelete.INBOX) && (
              <Grid item>
                <DeleteIcon
                  role="DeleteIconRole"
                  size={18}
                  onClick={() => openConfirmToRemoveModal(row)}
                />
              </Grid>
            )}
          </Grid>
        </TableCell>
      </>
    );
  };

  const handleCloseForwardModel = useCallback((): void => {
    setSelectedInboxId(null);
  }, []);

  const hideSnackbar = useCallback((): void => {
    setSnackbarVisible(false);
  }, []);

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
  ];

  return (
    <TabPageWrapper
      title={t("inbox.title")}
      breadcrumbs={breadcrumbs}
      wrap={!id}
    >
      <>
        <Table
          data={data}
          total={total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={tableHeaderTitles}
          renderRow={renderRow}
          tableToolbar={TableToolbar}
          onRowRemove={handleRowRemove}
          tableDataMaxHeight={"60vh"}
        />
        {isShowCommentsModal.isVisible && (
          <CommentsPopup
            handleCloseDialog={handleCloseCommentsModal}
            id={String(isShowCommentsModal?.rowId)}
            relation={COMMENTABLE.INBOX}
          />
        )}
        {selectedInboxId ? (
          <ForwardDocumentDialog
            isOpen={true}
            handleCloseForwardModal={handleCloseForwardModel}
            entityId={selectedInboxId}
            entityType="inboxes"
            setSnackbarVisible={setSnackbarVisible}
            title={t("inbox.forwardTitle")}
          />
        ) : null}
        {inboxId ? (
          <AssignPropertyDialog
            id={inboxId}
            onClose={() => setInboxId(null)}
            open={true}
            afterAssignProperty={afterAssignProperty}
            handleAssignProperty={handleAssignProperty}
          />
        ) : null}
        <Snackbar
          message={t("inbox.forward.success")}
          color="success"
          open={snackbarVisible}
          handleClose={hideSnackbar}
        />
        <Snackbar
          message={snackbarData.text}
          color={snackbarData.color}
          open={snackbarData.visible}
          handleClose={handleCloseSnackbar}
        />
        {
          /* istanbul ignore next */
          inboxTypes && showAssginModal && (
            <InboxTypesSelect
              inboxTypes={inboxTypes}
              inboxData={selectedInboxData!}
              getInboxType={getInboxType}
              setSnackbarData={setSnackbarData}
              handleFetchList={handleFetchList}
              handleClose={hideSelectDialog}
            />
          )
        }
      </>
    </TabPageWrapper>
  );
};

export default InboxList;
