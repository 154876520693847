import React, { ReactElement, useCallback } from "react";
import { FormikProps } from "formik";
import { Grid } from "@mui/material";
import { AssignInvoiceData, AssignInvoiceProps } from "./types";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/button/Button";
import Dropdown from "../../../../ui/dropdown";
import { prepareQueryParams } from "../../../../utils/common";
import { getInvoices } from "../../../../api/invoices";
import { AutocompleteOption } from "../../../../ui/dropdown/types";
import { REQUEST_STATUS_IDS } from "../../../../constants";

const Form = ({
  handleSubmit,
  setFieldValue,
  touched,
  errors,
  status,
  isSubmitting,
  propertyId,
}: FormikProps<AssignInvoiceData> & AssignInvoiceProps): ReactElement => {
  const { t } = useTranslation();

  const onSelectOption = (
    id?: string,
    value?: AutocompleteOption | null
  ): void => {
    setFieldValue("invoice_id", id);
    setFieldValue("property_id", value?.property?.id || "");
  };

  const getInvoiceOptions = useCallback(
    async (search: string) => {
      const newParams = prepareQueryParams(search, {
        fr_status_id: REQUEST_STATUS_IDS.NEW,
        property_id: propertyId,
      });
      return await getInvoices(newParams);
    },
    [propertyId]
  );

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <Dropdown
            id="invoice-autocomplete"
            name="invoice_id"
            label={t("form.invoiceId")}
            placeholder={t("form.invoiceId")}
            getOptions={getInvoiceOptions}
            onSelectOption={onSelectOption}
            optionLabel="name"
            optionValue="id"
            size="medium"
            helperText={
              (touched.invoice_id && errors.invoice_id) ||
              status?.errors?.invoice_id
            }
            error={Boolean(
              (touched.invoice_id && errors.invoice_id) ||
                status?.errors?.invoice_id
            )}
            otherOptions={["property"]}
          />
        </Grid>
        <Grid
          item
          sx={{ display: "flex", justifyContent: "flex-end" }}
          xs={12}
          lg={12}
          sm={12}
          md={12}
        >
          <Button
            title={t("save")}
            color="success"
            type="submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
