import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { Grid, Paper, Link } from "@mui/material";
import { useFetch } from "../../../hooks/useFetch";
import {
  COMMENTABLE,
  EMPTY_DATA,
  FormMessageErrorState,
} from "../../../constants";
import { LoadingBox } from "../../contacts/styled";
import Loader from "../../Loader";
import { route } from "../../../utils/url";
import { useTranslation } from "react-i18next";
import Comments from "../../../ui/сomments";
import useComments from "../../../ui/сomments/useComments";
import TabPageWrapper from "../../../ui/tabPageWrapper";
import PdfViewer from "../../../ui/pdfViewer";
import { ErrorBox } from "../../properties/objectDataSheet/tabs/generalInformation/styled";
import { getGeneralDocument } from "../../../api/generalDocuments";
import { GeneralDocument } from "../../../types/be/generalDocument";
import { HeaderText } from "../../properties/edit/general/styled";
import { LabelText } from "../../properties/invoices/show/styled";
import { formatTimestamp } from "../../../utils/date";

/* istanbul ignore next */
const GeneralDocumentShow = (): ReactElement => {
  const { t } = useTranslation();
  const { id, documentId } = useParams();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const pdfContainerRef = useRef<HTMLObjectElement | null>(null);
  const [fileId, setFileId] = useState<number | undefined>();
  const {
    data: generalDocument,
    isLoading,
    isError,
    run,
  } = useFetch<GeneralDocument>();

  const {
    comments,
    content,
    setContent,
    totalPages,
    handleCommentSave,
    handleCommentDelete,
    handlePaginationChange,
    currentPage,
    deleting,
    loading,
  } = useComments({
    relation: COMMENTABLE.GENERAL_DOCUMENT,
    relation_id: documentId!,
  });

  const getData = async (): Promise<void> => {
    await run(getGeneralDocument(documentId!));
    setIsDataLoaded(true);
  };

  useEffect(() => {
    generalDocument?.file?.id && setFileId(generalDocument.file.id);
  }, [generalDocument]);

  useEffect(() => {
    getData();
  }, [id, documentId]);

  const generalDocumentFile = useMemo(() => {
    return { fileId: fileId };
  }, [fileId]);

  if (isError) {
    return <ErrorBox formMessage={FormMessageErrorState} />;
  }

  if (!isDataLoaded && (isLoading || !generalDocument)) {
    return (
      <div data-testid="loader">
        <LoadingBox>
          <Loader />
        </LoadingBox>
      </div>
    );
  }
  /* istanbul ignore next */
  if (!generalDocument) {
    return <></>;
  }

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: id
        ? route("properties.general-documents", id)
        : route("general-documents"),
      name: t("sidebar.generalDocuments"),
    },
  ];

  return (
    <TabPageWrapper
      title={generalDocument.id.toString()}
      breadcrumbs={breadcrumbs}
      wrap={!id}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} xl={12}>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12} p={4}>
              <Grid container spacing={4}>
                <Grid item xs={12} ref={pdfContainerRef}>
                  <PdfViewer
                    file={generalDocumentFile}
                    containerRef={pdfContainerRef}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Paper variant="outlined" sx={{ p: 4, textAlign: "center" }}>
                    <Grid
                      container
                      justifyContent={"center"}
                      spacing={4}
                      sx={{ textAlign: "left" }}
                    >
                      <Grid item xs={12} sm={4}>
                        <HeaderText> {generalDocument?.id} </HeaderText>
                        <LabelText>
                          <Link
                            href={generalDocument?.file?.url}
                            target="_blank"
                            sx={{
                              wordBreak: "break-all",
                              fontSize: "11px",
                            }}
                          >
                            {generalDocument?.file?.name}
                          </Link>{" "}
                          | {formatTimestamp(generalDocument.created_at)}
                        </LabelText>
                      </Grid>
                      <Grid item xs={12} sm={4}></Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: 2,
                        }}
                      ></Grid>
                    </Grid>
                    <Grid container justifyContent={"center"} mt={6}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {generalDocument.contact ? (
                            <Link
                              href={route(
                                "contacts.edit.first-tab",
                                generalDocument.contact.id
                              )}
                              target="_blank"
                            >
                              {`${generalDocument.contact.full_name}`}
                            </Link>
                          ) : (
                            EMPTY_DATA
                          )}
                        </HeaderText>
                        <LabelText>{t("contact")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {generalDocument.creator
                            ? `${generalDocument.creator?.first_name} ${generalDocument.creator?.last_name}`
                            : EMPTY_DATA}
                        </HeaderText>
                        <LabelText>{t("createdBy")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {formatTimestamp(generalDocument.created_at)}
                        </HeaderText>
                        <LabelText>
                          {t("documentRelease.invoice.show.createdAt")}
                        </LabelText>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent={"center"} mt={6}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {generalDocument.property ? (
                            <Link
                              href={route(
                                "properties.edit.main",
                                generalDocument.property.id
                              )}
                              target="_blank"
                            >
                              {generalDocument.property.object_name}
                            </Link>
                          ) : (
                            EMPTY_DATA
                          )}
                        </HeaderText>
                        <LabelText>
                          {t("documentRelease.invoice.show.propertyName")}
                        </LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>{generalDocument.status.name}</HeaderText>
                        <LabelText>{t("table.status")}</LabelText>
                      </Grid>
                      <Grid item xs />
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} pt={4}>
                  <Comments
                    editorContent={content}
                    setEditorContent={setContent}
                    handleSaveComment={handleCommentSave}
                    comments={comments}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handleDeleteComment={handleCommentDelete}
                    handlePaginationChange={handlePaginationChange}
                    deleting={deleting}
                    loading={loading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TabPageWrapper>
  );
};

export default GeneralDocumentShow;
