import { useEffect, useState } from "react";
import { useFetch } from "../../../../hooks/useFetch";
import {
  DOCUMENT_RELEASE_LEVEL,
  DOCUMENT_RELEASE_MODULE,
  ReleaseConfiguration,
} from "../../../../types/be/releaseFlow";
import {
  forwardDocument,
  getConfiguration,
  updateReleaseLevel,
} from "../../../../api/releaseFlow";
import { prepareQueryParams } from "../../../../utils/common";
import {
  Loading,
  ReleasePermission,
  handleForwardDocumentProps,
  handleReleaseDocumentProps,
  useDocumentReleaserReturnType,
} from "./types";
import { hasReleasePermission } from "./utils";
import useAccessControl from "../../../../hooks/useAccessControl";
import useSnackbar from "../../../../ui/snackbar1/useSnackbar";
import { useTranslation } from "react-i18next";
import {
  RELEASE_STATUS,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import useAppSelector from "../../../../hooks/useAppSelector";
import { RootState } from "../../../../redux/store";

/* istanbul ignore next */
const useDocumentRelease = (
  moduleCodeForReleaser: DOCUMENT_RELEASE_MODULE,
  moduleCodeForForwarder?: DOCUMENT_RELEASE_MODULE
): useDocumentReleaserReturnType => {
  const { _user } = useAppSelector((state: RootState) => state.user);
  const { t } = useTranslation();
  const { isRole } = useAccessControl();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<Loading | null>(null);
  const {
    data: configuration,
    isLoading: isConfigurationLoading,
    run: runConfiguration,
  } = useFetch<ReleaseConfiguration[]>();

  const releasePermission: ReleasePermission = {
    [DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL]: hasReleasePermission(
      DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL,
      configuration,
      isRole
    ),
    [DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL]: hasReleasePermission(
      DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL,
      configuration,
      isRole
    ),
    [DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL]: hasReleasePermission(
      DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL,
      configuration,
      isRole
    ),
    [DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL]: (user) => user?.id === _user?.id,
  };

  const handleForwardDocument = async ({
    id,
    level,
    hasPermission,
    afterSuccess,
    successMessage,
    errorMessage = t(SOMETHING_WENT_WRONG_ERROR),
  }: handleForwardDocumentProps): Promise<void> => {
    if (!hasPermission) {
      return;
    }
    setLoading({
      id,
      level,
      status: RELEASE_STATUS.FORWARD_TO_ADMIN,
    });
    const payload = {
      relation: moduleCodeForForwarder ?? moduleCodeForReleaser,
      relation_id: id,
    };
    const response = await forwardDocument(level, payload);

    if (response?.ok) {
      successMessage && snackbar.snackbar.success(successMessage);
      afterSuccess && afterSuccess();
    } else {
      errorMessage && snackbar.snackbar.error(errorMessage);
    }
    setLoading(null);
  };

  const handleReleaseDocument = async ({
    id,
    level,
    status,
    hasPermission,
    afterSuccess,
    successMessage,
    errorMessage = t(SOMETHING_WENT_WRONG_ERROR),
  }: handleReleaseDocumentProps): Promise<void> => {
    if (!hasPermission) {
      return;
    }
    setLoading({
      id,
      level,
      status,
    });
    const params = prepareQueryParams("", {
      status_code: status,
    });

    const response = await updateReleaseLevel(
      moduleCodeForReleaser,
      id,
      level,
      params
    );
    if (response?.ok) {
      successMessage && snackbar.snackbar.success(successMessage);
      afterSuccess && afterSuccess();
    } else {
      errorMessage && snackbar.snackbar.error(errorMessage);
    }
    setLoading(null);
  };

  const handleGetConfiguration = async (): Promise<void> => {
    const params = {
      module_code: moduleCodeForReleaser,
    };
    runConfiguration(getConfiguration(prepareQueryParams("", params)));
  };

  useEffect(() => {
    handleGetConfiguration();
  }, []);

  return {
    configuration,
    isConfigurationLoading,
    handleGetConfiguration,
    releasePermission,
    handleReleaseDocument,
    handleForwardDocument,
    snackbar,
    loading,
  };
};

export default useDocumentRelease;
