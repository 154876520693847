import { Dialog, DialogContent } from "@mui/material";
import React, { ReactElement, useState, useContext } from "react";
import DialogTitle from "../../../../../../../ui/dialog/DialogTitle";
import { CreateBKADialogProps } from "./types";
import { Formik } from "formik";
import Form from "./Form";
import { handleCreateBKA, initialValues } from "./utils";
import { FormMessageInItState } from "../../../../../../../constants";
import { FormMessageInterface } from "../../../../../../../types/form";
import FormAlert from "../../../../../../../ui/formAlert/FormAlert";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useValidation from "../../../../../../../hooks/useValidation";
import { AreaEditContext } from "../../../AreaEditContext";

const CreateBKADialog = ({
  open,
  onClose,
  afterCreateBKA,
  property_tenant_id,
}: CreateBKADialogProps): ReactElement => {
  const { bkaYear, file, amount, date, BKACosts } = useValidation();
  const { t } = useTranslation();
  const areaEditContext = useContext(AreaEditContext);
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="create-bka-dialog"
    >
      <DialogTitle title={t("create")} onClose={onClose} />
      <DialogContent sx={{ marginX: 5 }}>
        {formMessage.text ? (
          <FormAlert formMessage={formMessage} sx={{ mb: 2 }} />
        ) : null}
        <Formik
          initialValues={initialValues}
          onSubmit={handleCreateBKA({
            setFormMessage,
            afterCreateBKA,
            property_tenant_id,
          })}
          validationSchema={Yup.object().shape({
            year: bkaYear(areaEditContext?.area?.active_tenant?.bka),
            file,
            amount,
            date,
            date_bka: date,
            costs: BKACosts,
          })}
        >
          {(props) => <Form {...props} onClose={onClose} />}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CreateBKADialog;
