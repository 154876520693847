import { LocationOn } from "@mui/icons-material";
import styled from "styled-components/macro";

export const LocationOnIcon = styled(LocationOn)`
  transition: transform 0.05s ease-in;
  will-change: transform;
  &:hover {
    cursor: pointer;
  }
`;
