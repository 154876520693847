import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Grid, Paper, styled } from "@mui/material";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Text } from "../../../../ui/formsFields/text";
import PageWrapper from "../../../../ui/pageWrapper/PageWrapper";
import { handleSubmit, initialValues, MAX_FILES } from "./utils";
import { Add, Close } from "@mui/icons-material";
import {
  AddImageButton,
  ImageItem,
  ImagesContainer,
  ReadingImage,
} from "./styled";
import {
  DEFAULT_FILE_TYPES,
  FormMessageInItState,
} from "../../../../constants";
import { ViewableFile } from "./types";
import Button from "../../../../ui/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import useAccessControl from "../../../../hooks/useAccessControl";
import { route } from "../../../../utils/url";
import useValidation from "../../../../hooks/useValidation";
import ImageViewer from "../../../../ui/imageViewer/reactImageGallery";

const Input = styled("input")({
  display: "none",
});

const CreateRequest = (): ReactElement => {
  const { t } = useTranslation();
  const { id, areaId } = useParams();
  const { _user } = useAccessControl();
  const navigate = useNavigate();
  const { title, description } = useValidation();

  const [initValues, setInitValues] = useState(initialValues);
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [showImageViewer, setShowImageViewer] = useState<number | null>(null);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInitValues(initValues);
  }, []);

  const handleFileChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      files: ViewableFile[],
      setFieldValue: (field: string, value: ViewableFile[]) => void
    ) => {
      const file = e?.target?.files?.[0];

      const newFiles: ViewableFile[] = [
        ...files,
        {
          file,
          url: file && URL.createObjectURL(file),
          id: Date.now(),
        },
      ];
      setFieldValue("file", newFiles);
      ref.current!.value = "";
    },
    []
  );

  const removeFile = useCallback(
    (
      id: number,
      files: ViewableFile[],
      setFieldValue: (field: string, value: ViewableFile[]) => void
    ) => {
      const newFiles = files.filter((item) => item.id !== id);
      setFieldValue("file", newFiles);
    },
    []
  );

  const openImageViewer = useCallback((index: number) => {
    setShowImageViewer(index);
  }, []);

  const closeImageViewer = useCallback(() => {
    setShowImageViewer(null);
  }, []);

  const breadcrumbs = [
    {
      to: route("properties.areas.edit", id, areaId),
      name: t("tenant.editTenant"),
    },
  ];

  return (
    <PageWrapper
      title={t("requests.create.createRequest")}
      breadcrumbs={breadcrumbs}
    >
      <Grid container justifyContent={"center"} spacing={2}>
        <Grid item xs={12} lg={8} xl={5}>
          <Paper sx={{ padding: 6 }}>
            <Grid item xs={12}>
              {formMessage.text ? (
                <FormAlert formMessage={formMessage} />
              ) : null}
            </Grid>
            <Formik
              initialValues={initValues}
              onSubmit={handleSubmit(
                setFormMessage,
                navigate,
                id!,
                areaId!,
                String(_user?.id)
              )}
              validationSchema={Yup.object().shape({
                title,
                description,
              })}
              enableReinitialize
            >
              {({
                values,
                setFieldValue,
                status,
                isSubmitting,
                handleSubmit,
              }) => (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  data-testid="request-form"
                >
                  <Grid item xs={12} mb={5}>
                    <Text
                      required
                      name="title"
                      label={t("requests.create.title")}
                      fullWidth
                      status={status}
                    />
                  </Grid>
                  <Grid item xs={12} mb={5}>
                    <Text
                      required
                      name="description"
                      label={t("requests.create.description")}
                      fullWidth
                      status={status}
                      multiline
                      rows={8}
                    />
                  </Grid>
                  <ImagesContainer>
                    {values.file?.map((item, index) => (
                      <ImageItem
                        key={index}
                        onClick={() => openImageViewer(index)}
                      >
                        <ReadingImage
                          src={item.url}
                          data-testid="upload-image"
                        />
                        <Close
                          className="removeIcon"
                          color="action"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeFile(item.id, values.file, setFieldValue);
                          }}
                          data-testid="remove-icon"
                        />
                      </ImageItem>
                    ))}
                    {values.file?.length < MAX_FILES ? (
                      <AddImageButton htmlFor="file">
                        <Add fontSize="large" />
                        <Input
                          accept={DEFAULT_FILE_TYPES}
                          type="file"
                          name="file"
                          id="file"
                          ref={ref}
                          onChange={(e) =>
                            handleFileChange(e, values.file, setFieldValue)
                          }
                          data-testid="select-file"
                        />
                      </AddImageButton>
                    ) : null}
                  </ImagesContainer>
                  {showImageViewer !== null && values?.file?.length ? (
                    <ImageViewer
                      images={values?.file?.map((item) => ({
                        original: item.url ?? "",
                        thumbnail: item.url ?? "",
                      }))}
                      handleClose={closeImageViewer}
                      startIndex={showImageViewer}
                    />
                  ) : null}
                  <Grid
                    item
                    xs={12}
                    sx={{ marginTop: 2 }}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      title={t("create")}
                      type="submit"
                      color="success"
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      size="medium"
                      testId="create-request"
                    />
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default CreateRequest;
