import { TawkWidgetType } from "./types";
/**
 * @param {Object} - Tawk widget required properties
 */
/* istanbul ignore next */
export const loadScript = ({
  propertyId,
  widgetId,
  basePath,
}: TawkWidgetType): void => {
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://embed.${basePath}/${propertyId}/${widgetId}`;
  script.charset = "UTF-8";
  script.setAttribute("crossorigin", "*");

  document.head.appendChild(script);
};
