import styled from "styled-components";
import { Card as MuiCard, Grid } from "@mui/material";
import { spacing } from "@mui/system";
import FormAlert from "../../../../ui/formAlert/FormAlert";

export const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

export const LabelText = styled.div`
  font-weight: 600;
  font-size: 11px;
  white-space: nowrap;
`;

export const Root = styled(Grid)`
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const LoadingBox = styled.div`
  width: 100%;
  height: 50vh;
`;

export const OfferImage = styled.img`
  max-width: 100%;
`;

export const ErrorBox = styled(FormAlert)(spacing);
