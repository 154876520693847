import styled from "styled-components/macro";
import { ReactComponent as Logo } from "../../../vendor/logo.svg";
import {
  Alert as MuiAlert,
  Box,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
/* istanbul ignore next */
export const Brand = styled(Logo)`
  fill: ${(props): string => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

export const FormMessageWrapper = styled(Box)`
  min-height: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AutocompleteListItem = styled.li`
  font-size: 11px;
  font-weight: 500;
`;

export const Alert = styled(MuiAlert)(spacing);
export const TextField = styled(MuiTextField)<{ mb?: number }>(spacing);
