export default {
  attachments: "Anhänge",
  fileName: "Dateiname",
  uploadedBy: "Hochgeladen von",
  uploadedAt: "Hochgeladen am",
  files: "Anhänge",
  archived: "Archivierte Anhänge",
  archive: "Archivieren",
  unArchive: "Archivierung aufheben",
  attachmentUploadedSuccessfully: "Anhang erfolgreich hochgeladen",
  attachmentCreatedSuccessfully: "Anlage erfolgreich erstellt",
  sendEmail: "E-Mail senden",
  mailSendSuccessfully: "E-Mail erfolgreich gesendet",
  uploadFile: "Datei hochladen",
  fileArchivedSuccessfully: "Datei erfolgreich archiviert",
  fileUnArchivedSuccessfully: "Datei erfolgreich archiviert",
};
