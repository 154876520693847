import React, { ReactElement, useState, useEffect } from "react";
import Button from "../../../ui/button/Button";
import FileInputField from "../../../ui/formsFields/fileInput";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, IconButton } from "@mui/material";
import { FormikValues } from "formik/dist/types";
import { Form } from "formik";
import Snackbar from "../../../ui/Snackbar";
import TextField from "../../../ui/formsFields/textField";
import SwitchWrapper from "../../../ui/formsFields/switch";
import { FormSection } from "../../profile/tabs/generalInformation/styled";
import { ContractFormProps } from "./types";
import SelectOrCreateContactsAutocompleteField from "../../contacts/autocomplete";
import { ContractCategory } from "../../../types/be.interfaces";
import { FOLDER_NAMES, Measurement_Units } from "../../../constants";
import { StyledGrid } from "./styled";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Currencies } from "../../../types/be/currencies";
import Dropdown from "../../../ui/dropdown";
import { getProperties } from "../../../api/property";
import { generalPropertyOption } from "../../../utils/common";
import DatePicker from "../../../ui/datePicker";
import { getAreas } from "../../../api/area";
import { route } from "../../../utils/url";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

function ContractForm({
  errors,
  status,
  handleBlur,
  handleChange,
  isSubmitting,
  handleSubmit,
  touched,
  values,
  propertyId,
  setFieldValue,
  edit,
  categories,
  fileName,
  setFileName,
  setFormInitState,
  handleOpenDialog,
  currencies,
  inboxData,
  setSelectedFileId,
}: FormikValues & ContractFormProps): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const handleClose = (): void => setIsSnackbarVisible(false);
  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);
  /* istanbul ignore next */
  useEffect(() => {
    if (inboxData) {
      setFieldValue("file_id", inboxData.file.id);
      inboxData.property && setFieldValue("property_id", inboxData.property.id);
      inboxData.contact && setFieldValue("contact_id", inboxData.contact.id);
    }
  }, []);

  useEffect(() => {
    setSelectedFileId?.(values.file_id);
  }, [values.file_id]);

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <FormSection>
        <Grid container spacing={6}>
          {!propertyId && (
            <Grid item lg={6} sm={12}>
              <Dropdown
                id="property-autocomplete"
                name="property_id"
                label={t("form.propertyId")}
                placeholder={t("form.propertyId")}
                getOptions={getProperties}
                onSelectOption={(id?: string) =>
                  setFieldValue("property_id", id)
                }
                optionLabel="object_name"
                optionValue="id"
                size="medium"
                helperText={
                  status?.errors.property_id ||
                  (touched.property_id && errors.property_id)
                }
                error={Boolean(
                  status?.errors.property_id ||
                    (touched.property_id && errors.property_id)
                )}
                defaultOption={[generalPropertyOption]}
                defaultValue={
                  inboxData
                    ? /* istanbul ignore next */ inboxData?.property
                    : values.property
                }
              />
            </Grid>
          )}
          <Grid item sm={6} md={6}>
            <Dropdown
              id="property-area-autocomplete"
              name="area_id"
              value={values?.area_id || ""}
              label={t("counter.searchArea")}
              placeholder={t("counter.searchArea")}
              getOptions={getAreas}
              onSelectOption={(id?: string) => {
                setFieldValue("area_id", id);
              }}
              size="medium"
              defaultValue={values?.area}
              error={Boolean(status?.errors?.area_id || errors?.area_id)}
              helperText={errors?.area_id || status?.errors?.area_id}
              disabled={!Number(values?.property_id) && !propertyId}
              isAreaSearch={true}
              enableReInitialization
              propertyId={values?.property_id || propertyId}
              isShowAllOption={true}
            />
          </Grid>
          {!inboxData && (
            <Grid item sm={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={11}>
                  <FileInputField
                    required
                    name="file_id"
                    label={t("contracts.uploadFile")}
                    error={Boolean(
                      touched.file_id &&
                        (status?.errors.file_id || errors.file_id)
                    )}
                    helperText={
                      touched.file_id &&
                      (status?.errors.file_id || errors.file_id)
                    }
                    foldername={FOLDER_NAMES.CONTRACT}
                    propertyid={propertyId}
                    inputProps={{ "data-testid": "contract-file" }}
                    accept="application/pdf"
                    errorText={t("contracts.error.pdfOnly")}
                    outerFileName={fileName}
                    setOuterFileName={setFileName}
                    setFormInitState={setFormInitState}
                  />
                </Grid>
                <StyledGrid item xs={1} pt={3}>
                  <IconButton
                    color="primary"
                    aria-label="uploadPdf"
                    component="span"
                    size="small"
                    onClick={handleOpenDialog}
                  >
                    <InsertDriveFileIcon />
                  </IconButton>
                </StyledGrid>
              </Grid>
            </Grid>
          )}
          <Grid item xl={6} sm={12}>
            <SelectOrCreateContactsAutocompleteField
              {...{
                errors,
                values: {
                  ...values,
                  contact: inboxData?.contact
                    ? /* istanbul ignore next */ inboxData.contact
                    : values.contact,
                },
                touched,
                status,
                handleBlur,
                handleChange,
                setFieldValue,
              }}
              label={t("createContact.searchContact")}
              placeholder={t("createContact.searchContact")}
            />
          </Grid>
          <Grid item lg={6} sm={12}>
            <TextField
              name="contract_category_id"
              select
              required
              label={t("contracts.category")}
              inputProps={{ "data-testid": "contract_category-input" }}
            >
              {categories.map((category: ContractCategory) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={6} sm={12}>
            <DatePicker
              name="start_date"
              label={t("contracts.startDate")}
              status={status}
              dataTestId={"contract-start-date-input"}
            />
          </Grid>
          <Grid item lg={6} sm={12}>
            <DatePicker
              name="end_date"
              label={t("contracts.endDate")}
              minDate={values.start_date}
              status={status}
              dataTestId={"contract-end-date-input"}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <DatePicker
              name="signing_date"
              label={t("contracts.signingDate")}
              status={status}
              dataTestId={"contract-signing-date-input"}
            />
          </Grid>
          <Grid item lg={6} sm={12}>
            <DatePicker
              name="cancellation_date"
              label={t("contracts.cancellationDate")}
              status={status}
              dataTestId={"contract-cancellation-date-input"}
            />
          </Grid>
          <Grid item lg={6} sm={12}>
            <DatePicker
              name="cancelled_at"
              label={t("contracts.canceledAt")}
              status={status}
              dataTestId={"contract-cancelled-at-date-input"}
            />
          </Grid>
          <Grid item lg={6} sm={12}>
            <TextField
              name="amount"
              label={t("contracts.amount")}
              value={values.amount}
              error={Boolean(
                touched.amount && (status?.errors.amount || errors.amount)
              )}
              fullWidth
              allowNegative
              inputProps={{
                "data-testid": "amount",
                filter: "formatNum",
                unit: Measurement_Units.EURO,
              }}
              status={status}
            />
          </Grid>
          <Grid item lg={6} sm={12}>
            <TextField
              name="currency_code"
              select
              disabled={edit}
              value={values.currency_code || ""}
              label={t("documentRelease.invoice.currency")}
              inputProps={{ "data-testid": "currencies_code-input" }}
            >
              {currencies.map((currency: Currencies) => (
                <MenuItem key={currency.code} value={currency.code}>
                  {currency.code}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection>
        <Grid container spacing={6}>
          <Grid item sm={12}>
            <SwitchWrapper
              name="is_old_contract"
              label={t("contracts.oldContract")}
              checked={values.is_old_contract}
              data-testid="is-old-contract"
            />
          </Grid>
        </Grid>
      </FormSection>
      <Grid container display="flex" justifyContent="space-between">
        <Grid item>
          <Button
            type="button"
            color="primary"
            variant="text"
            title={t("cancel")}
            disabled={isSubmitting}
            size="large"
            onClick={
              /* istanbul ignore next */ () =>
                doesAnyHistoryEntryExist
                  ? navigate(-1)
                  : propertyId
                  ? navigate(route("properties.contracts.new", propertyId))
                  : navigate(route("contracts.new"))
            }
          />
        </Grid>
        <Grid item>
          <Button
            testId="create-contract"
            title={t(edit ? "update" : "create")}
            type="submit"
            color="success"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            size="large"
          />
        </Grid>
      </Grid>
      <Snackbar
        message={t("contracts.contractSaveSuccessfully")}
        color="success"
        open={isSnackbarVisible}
        handleClose={handleClose}
        data-testid="snackbar-contract-success"
      />
    </Form>
  );
}

export default ContractForm;
