import React from "react";
import { NavLink } from "react-router-dom";
import { SidebarItemsType } from "./types";
import SidebarHeader from "./SidebarHeader";
import SidebarNav from "./SidebarNav";
import { Drawer, Brand, ClientLogo } from "./styled";
import { route } from "../../utils/url";
import useAppSelector from "../../hooks/useAppSelector";

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
};

const Sidebar: React.FC<SidebarProps> = ({ items, ...rest }) => {
  const { _client } = useAppSelector((state) => state.client);

  return (
    <Drawer variant="permanent" {...rest}>
      <SidebarHeader />
      <SidebarNav items={items} />
      <Brand component={NavLink} to={route("root")}>
        {_client.images?.logo && (
          <ClientLogo role={"client-logo"} src={_client.images?.logo} />
        )}
      </Brand>
    </Drawer>
  );
};

export default Sidebar;
