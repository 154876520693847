import { Box, Dialog as MuiDialog } from "@mui/material";
import styled from "styled-components/macro";
import CloseIconMui from "@mui/icons-material/Close";

/* istanbul ignore next */
export const Dialog = styled(MuiDialog)<{ $loading?: boolean }>`
  .MuiPaper-root {
    visibility: ${(props): string => (props.$loading ? "hidden" : "unset")};
    opacity: ${(props): number => (props.$loading ? 0 : 1000)};
    overflow-x: hidden;
  }
`;
/* istanbul ignore next */
export const MentionedUserItem = styled(Box)`
  display: flex;
  justify-content: space-between;
  border: 0.5px solid ${(props): string => props.theme.border.color};
  border-bottom-width: 0;
  align-items: center;
  padding-left: ${(props): string => props.theme.spacing(3)};
  padding-right: ${(props): string => props.theme.spacing(1)};
`;
/* istanbul ignore next */
export const MentionedUsersItems = styled(Box)`
  margin-top: ${(props): string => props.theme.spacing(4)};
  & :last-child {
    border-bottom-width: 0.5px;
  }
`;
/* istanbul ignore next */
export const MentionedUserName = styled(Box)`
  padding-top: ${(props): string => props.theme.spacing(1.5)};
  padding-bottom: ${(props): string => props.theme.spacing(1.5)};
`;
/* istanbul ignore next */
export const MentionedUserDeleteIcon = styled(CloseIconMui)`
  cursor: pointer;
`;
/* istanbul ignore next */
export const LoaderRoot = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`;
