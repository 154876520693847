import React, {
  HTMLAttributes,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import Loader from "../../../Loader";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import {
  AutocompleteListItem,
  AutocompletePropertyAddress,
  AutocompletePropertyName,
} from "./styled";
import { Grid, TextField } from "@mui/material";
import { Dropdown } from "../../../../ui/autocomplete/styled";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import debounce from "lodash/debounce";
import {
  NODE_ENVIRONMENTS,
  SEARCH_PARAM_MIN_CHARACTERS,
} from "../../../../constants";
import { getJson } from "../../../../utils/http";
import { FormikValues } from "formik";
import { PropertyList } from "../../../../types/be/property";
import { getProperties } from "../../../../api/property";
import cloneDeep from "lodash/cloneDeep";
import { isNodeEnv } from "../../../../utils/env";
import { DEBOUNCE_TIME } from "../../../../utils/common";
import { useFetch } from "../../../../hooks/useFetch";
/* istanbul ignore next */
const PropertyAutocomplete = ({
  onPropertySelect,
  touched,
  errors,
  status,
  propertyInitState = null,
  isDisabled = false,
}: FormikValues & {
  propertyInitState?: PropertyList | null;
  onPropertySelect: (property: PropertyList | null) => void;
  isDisabled?: boolean;
}): ReactElement => {
  const [isLoading, setLoading] = useState(false);
  const [property, setProperty] = useState<PropertyList | null>(
    propertyInitState
  );
  const { data, run, isLoading: startingOptionsLoading } = useFetch<any[]>();
  const { t } = useTranslation();
  const [propertyAutocompleteInputValue, setPropertyAutocompleteInputValue] =
    useState("");
  const [predictions, setPredictions] = useState<PropertyList[]>([]);

  useEffect(() => {
    setProperty(propertyInitState);
  }, [propertyInitState]);

  useEffect(() => {
    if (status && status.success) reset();
  }, [status]);

  const reset = (): void => {
    setPropertyAutocompleteInputValue("");
    resetOptions();
    setProperty(null);
  };

  useEffect(() => {
    setPropertyAutocompleteInputValue(
      property ? `${property?.object_name}` : ""
    );
    onPropertySelect(property);
  }, [property]);

  const handlePropertySelect = (property: PropertyList): void => {
    setPropertyAutocompleteInputValue(
      property ? `${property?.object_name}` : ""
    );
    onPropertySelect(property);
    setProperty(cloneDeep(property));
  };

  const handleAutocompleteOnChange = (
    _: SyntheticEvent,
    value: PropertyList | null
  ): void => {
    if (value) handlePropertySelect(value);
    else {
      setProperty(null);
    }
  };

  const memoOptions = useMemo(() => {
    return data ?? [];
  }, [data]);

  useEffect(() => {
    if (!memoOptions) return;
    setPredictions(memoOptions);
  }, [memoOptions]);

  useEffect(() => {
    run(getProperties());
  }, []);

  const resetOptions = useCallback((): void => {
    setPredictions(memoOptions);
    setPropertyAutocompleteInputValue("");
  }, [memoOptions]);

  const handleRenderInput = (
    params: AutocompleteRenderInputParams
  ): ReactNode => (
    <TextField
      {...params}
      data-testid="property-autocomplete"
      size={"small"}
      disabled={isDisabled}
      name="property_id"
      label={`${t("tenantRequest.property")}*`}
      onChange={(e) => {
        setPropertyAutocompleteInputValue(e.target.value);
        onSearchKeyDown(e.target.value);
      }}
      placeholder={t("tenantRequest.search")}
      error={Boolean(
        touched.property_id &&
          (status?.errors.property_id || errors.property_id)
      )}
      helperText={
        touched.property_id &&
        (status?.errors.property_id || errors.property_id)
      }
    />
  );

  const onSearchKeyDown = useCallback(
    debounce(
      async (search: string): Promise<void> => {
        if (search.length < SEARCH_PARAM_MIN_CHARACTERS) resetOptions();
        else {
          setLoading(true);
          getProperties(`?search=${search}&limit=10&order=asc`)
            .then(getJson)
            .then(({ data }) => {
              setPredictions(data);
            })
            .catch(() => {
              resetOptions();
            })
            .finally(() => {
              setLoading(false);
            });
        }
      },
      isNodeEnv(NODE_ENVIRONMENTS.TEST) ? 0 : DEBOUNCE_TIME
    ),
    []
  );

  return (
    <Autocomplete
      loading={isLoading || startingOptionsLoading}
      loadingText={`${t("loading")}...`}
      inputValue={propertyAutocompleteInputValue}
      popupIcon={isLoading ? <Loader size={25} /> : <ArrowDropDownIcon />}
      options={predictions!}
      isOptionEqualToValue={handleIsOptionEqualToValue}
      getOptionLabel={handleGetOptionLabel}
      filterOptions={handleFilterOptions}
      onChange={handleAutocompleteOnChange}
      renderOption={handleRenderOption}
      PaperComponent={autocompletePaperComponent}
      renderInput={handleRenderInput}
      disabled={isDisabled}
    />
  );
};

export default PropertyAutocomplete;
/* istanbul ignore next */
const handleIsOptionEqualToValue = (
  option: PropertyList,
  value: PropertyList
): boolean => {
  return option.id === value.id;
};
/* istanbul ignore next */
const handleGetOptionLabel = (property: PropertyList): string => {
  return `${property?.object_name}, ${property?.location.full_address}`;
};
/* istanbul ignore next */
const handleFilterOptions = (options: PropertyList[]): PropertyList[] =>
  options;
/* istanbul ignore next */
const handleRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  property: PropertyList
): ReactNode => {
  return (
    <AutocompleteListItem {...props} key={property.id}>
      <Grid container direction={"column"}>
        <Grid item>
          <AutocompletePropertyName>{`${property?.object_name}`}</AutocompletePropertyName>
        </Grid>
        <Grid item>
          <AutocompletePropertyAddress>{`${property?.location.full_address}`}</AutocompletePropertyAddress>
        </Grid>
      </Grid>
    </AutocompleteListItem>
  );
};
/* istanbul ignore next */
const autocompletePaperComponent = ({
  children,
}: {
  children?: ReactNode;
}): ReactElement | null => <Dropdown>{children}</Dropdown>;
