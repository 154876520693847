import React, { ReactElement, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const AssignButton = ({
  onClick,
  areaId,
  attachedAreaId,
}: {
  areaId: number;
  attachedAreaId: number | null;
  onClick: (areaId: number) => void;
}): ReactElement => {
  const { t } = useTranslation();
  const [value, setValue] = useState(false);
  /* istanbul ignore next */
  const handleOnChange = (): void => {
    onClick(areaId);
    setValue(true);
  };

  useEffect(() => {
    setValue(areaId === attachedAreaId);
  }, [attachedAreaId]);

  return (
    <Button
      variant="outlined"
      disabled={value}
      size={"small"}
      onClick={/* istanbul ignore next */ () => handleOnChange()}
    >
      {t("assign")}
    </Button>
  );
};

export default AssignButton;
