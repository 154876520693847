import { Grid, Link, Paper, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { DeleteIcon, ShowIcon } from "./CounterList";
import { useDeleteConfirmation } from "../../../../hooks/useDeleteConfirmation";
import { DeleteConfirmationPopover } from "../../../../ui/deleteConfirmationPopover";
import { useNavigate, useParams } from "react-router-dom";
import { route } from "../../../../utils/url";
import { Reading } from "../../../../types/be/readings";
import { formatTimestamp } from "../../../../utils/date";

/* istanbul ignore next */
export const Counter1 = ({
  id,
  number,
  type,
  lastReading,
  handleDelete,
}: {
  id: number;
  number: string;
  type?: string;
  lastReading: Reading | null;
  updatedBy?: string;
  updatedAt?: string;
  handleDelete?: () => void;
}): ReactElement => {
  const navigate = useNavigate();
  const { id: propertyId, areaId } = useParams();
  const { confirmDeleteState, showConfirmationPopover, handleClose } =
    useDeleteConfirmation();
  return (
    <>
      <Paper
        elevation={2}
        sx={{
          minWidth: 235,
          maxHeight: 128,
          px: 2,
          py: 1,
        }}
      >
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="subtitle2" gutterBottom color="primary">
              {number}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" gutterBottom color="primary">
              {type}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ py: 2 }} direction={"column"}>
          <Grid item display={"flex"}>
            <Typography
              variant="caption"
              gutterBottom
              sx={{ mr: 1, lineHeight: 1.8 }}
            >
              Last reading:
            </Typography>
            <Typography variant="subtitle2" gutterBottom sx={{ mr: 1 }}>
              {lastReading?.value || "---"}
            </Typography>
            {lastReading?.updated_at ? (
              <Typography
                variant="caption"
                gutterBottom
                sx={{ fontStyle: "italic", fontSize: "9px", lineHeight: 2.6 }}
              >
                ({formatTimestamp(lastReading?.updated_at)})
              </Typography>
            ) : null}
          </Grid>
          <Grid item display={"flex"}>
            <Typography variant="caption" sx={{ mr: 1 }}>
              Updated by:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {lastReading?.created_by ? (
                <Link
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open("https://google.com", "_blank");
                  }}
                >
                  {`${lastReading?.created_by?.first_name} ${lastReading?.created_by?.last_name}`}
                </Link>
              ) : (
                "---"
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent={"end"}>
          <Grid item>
            <ShowIcon
              onClick={() =>
                navigate(
                  route(
                    "properties.areas.edit.counter.reading",
                    propertyId,
                    areaId,
                    id
                  )
                )
              }
              data-testid="show-readings-icon"
            />
          </Grid>
          <Grid item>
            <DeleteIcon onClick={(e) => showConfirmationPopover(e)} />
          </Grid>
        </Grid>
      </Paper>
      <DeleteConfirmationPopover
        state={confirmDeleteState}
        handleClose={handleClose}
        handleDeleteConfirmation={() => {
          handleClose();
          handleDelete && handleDelete();
        }}
      />
    </>
  );
};
