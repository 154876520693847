import http from "../utils/http";
import { IdPropType } from "../types/models";
import { InventoryItem } from "../types/fe/companyOwnership";

export const getInventoryList = async (params: string): Promise<Response> => {
  return await http.get(`/api/inventories${params}`);
};

export const deleteInventoryItem = async ({
  id,
}: IdPropType): Promise<Response> => {
  return await http.delete(`/api/inventories/${id}`);
};

export const createInventoryItem = async (
  values: InventoryItem
): Promise<Response> => {
  return await http.post("/api/inventories", values);
};

export const getTypesOfInventoryItems = async (): Promise<Response> => {
  return await http.get("/api/inventories/types");
};

export const getInventoryHistories = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/inventory-ownership-histories${params}`);
};

export const getInventoryItemById = async (id: string): Promise<Response> => {
  return await http.get(`/api/inventories/${id}`);
};

export const updateInventoryItem = async (
  id: string,
  values: Partial<InventoryItem>
): Promise<Response> => {
  return await http.put(`/api/inventories/${id}`, values);
};
