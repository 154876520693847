import attachments from "./attachments/en";

export default {
  attachments: attachments,
  dataSheet: "Data sheet",
  title: "Object Data Sheet",
  generalInfo: "General Info",
  propertyManagement: "Property Management",
  bank: "Bank",
  createCompany: "Create company",
  top3Tenant: "Top 3 Tenant",
};
