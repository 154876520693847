import styled from "styled-components/macro";
import { Box, Paper as MuiPaper } from "@mui/material";
/* istanbul ignore next */
export const LoaderContainer = styled(Box)`
  position: absolute;
  top: ${(props): string => props.theme.spacing(0)};
  right: ${(props): string => props.theme.spacing(0)};
  left: ${(props): string => props.theme.spacing(0)};
  bottom: ${(props): string => props.theme.spacing(0)};
  z-index: 11111;
  background-color: rgba(0, 0, 0, 0.35);
  & > div {
    height: 100vh;
  }
`;
/* istanbul ignore next */
export const Paper = styled(MuiPaper)`
  padding: ${(props): string => props.theme.spacing(5)};
  position: relative;
`;
