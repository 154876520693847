/* istanbul ignore file */
import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButton as MuiToggleButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ToggleButtonGroupProps } from "../../types";

export const ToggleButtonGroup = styled(
  MuiToggleButtonGroup
)<ToggleButtonGroupProps>`
  border: 1px solid
    ${(props): string => (props.$error ? "#f44336" : "transparent")};
`;
export const ToggleButton = styled(MuiToggleButton)`
  height: ${(props): string => props.theme.spacing(7.6)};
  padding: ${(props): string =>
    `${props.theme.spacing(1.75)} ${props.theme.spacing(2.25)}`};
`;
