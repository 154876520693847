import http from "../utils/http";

export const getVacanciesList = async (params: string): Promise<Response> => {
  return await http.get(`/api/areas/vacant${params}`);
};

export const getVacanciesStatistics = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/areas/vacant/statistics${params}`);
};

export const exportXLS = async (params: string): Promise<Response> => {
  return await http.post(`/api/areas/vacant/export/xls${params}`, {});
};

export const exportPDF = async (params: string): Promise<Response> => {
  return await http.post(`/api/areas/vacant/export/pdf${params}`, {});
};
