import { SnackbarMessage } from "../../../types/fe/companyOwnership";
import { TableCell } from "../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "full_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "companyOwnership.employeeName",
  },
  {
    id: `type_name_${window.localStorage.getItem("lang")}`,
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "companyOwnership.type",
  },
  {
    id: "brand",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "companyOwnership.brand",
  },
  {
    id: "number",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "companyOwnership.licensePlateSerialNumber",
    align: "right",
  },
  {
    id: "received_date",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "companyOwnership.receivedOnDate",
    align: "center",
  },
  {
    id: "creator",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "companyOwnership.whoAdded",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "companyOwnership.createdDate",
    align: "center",
  },
  {
    id: "department",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "companyOwnership.department",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];

export const snackbarInitState: SnackbarMessage = {
  text: "",
  visible: false,
  color: "success",
};
