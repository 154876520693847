import React, { ReactElement, useCallback } from "react";
import { PaidByContainer, PaidByItem, PaidBy as PaidByStyled } from "./styled";
import useAppSelector from "../../../../../hooks/useAppSelector";
import { Theme, Tooltip } from "@mui/material";
import { SxProps } from "@mui/system";
import { formatTimestamp } from "../../../../../utils/date";
import { useTranslation } from "react-i18next";
/* istanbul ignore next */
const PaidBy = ({
  first_name,
  last_name,
  when_paid,
  align = "start",
  is_debited_automatically,
  sx
}: {
  first_name?: string;
  last_name?: string;
  when_paid?: number | null;
  align?: string;
  is_debited_automatically: boolean;
  sx?: SxProps<Theme>
}): ReactElement => {
  const { t } = useTranslation();
  const { _user } = useAppSelector((state) => state.user);

  const getStatusLeft = useCallback(
    (initials = false): string => {
      if (is_debited_automatically)
        return t("documentRelease.invoice.directDebit");
      if (
        _user &&
        _user.first_name === first_name &&
        _user.last_name === last_name
      )
        return "You";
      return initials
        ? `${first_name?.charAt(0)}. ${last_name?.charAt(0)}.`
        : `${first_name} ${last_name}`;
    },
    [first_name, last_name]
  );
  return (
    <PaidByContainer container justifyContent={align} sx={sx}>
      <PaidByItem item>
        <Tooltip
          title={getStatusLeft() === "You" ? "" : getStatusLeft()}
          placement="top"
        >
          <PaidByStyled>
            {getStatusLeft(true)}{" "}
            {when_paid && `- ${formatTimestamp(when_paid)}`}
          </PaidByStyled>
        </Tooltip>
      </PaidByItem>
    </PaidByContainer>
  );
};

export default PaidBy;
