import React, { ReactElement, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
} from "@mui/material";
import { Form } from "formik";
import { useTranslation } from "react-i18next";
import { ReleaseConfigurationForm, ReleasePermissionFormProps } from "../types";
import { Android12Switch } from "../../../../ui/formsFields/switch/styled";
import Button from "../../../../ui/button/Button";
import Snackbar from "../../../../ui/Snackbar";
import TextField from "../../../../ui/formsFields/textField";
import { titleDict } from "../utils";
import useAccessControl from "../../../../hooks/useAccessControl";
import RolesAutocomplete from "./RolesAutocomplete";
import { PermissionsCreate, PermissionsUpdate } from "../../../../constants";

/* istanbul ignore next */
const ReleasePermissionForm = ({
  values: configurationValues,
  status,
  isSubmitting,
  handleSubmit,
  roles,
  handleBlur,
  setConfiguration,
}: ReleasePermissionFormProps): ReactElement => {
  const { t } = useTranslation();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState<boolean>(false);
  const { can } = useAccessControl();

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  const handleClose = (): void => setIsSnackbarVisible(false);

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container pt={4} pb={6}>
            <Grid item data-testid="gog5" xs={12}>
              <Grid container>
                {configurationValues.map(
                  (values: ReleaseConfigurationForm, index: number) => (
                    <Grid item xs={12} pb={4} key={values.id}>
                      <Grid container spacing={4} alignItems="center">
                        <Grid item xs={2} textAlign="center">
                          <Box sx={{ flexGrow: 1 }}>
                            {t(titleDict[values.level.code])}
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            name="name"
                            label={t("name")}
                            value={values.name}
                            disabled={
                              !can([
                                PermissionsUpdate.SETTINGS_RELEASE,
                                PermissionsCreate.SETTINGS_RELEASE,
                              ])
                            }
                            sx={{ minHeight: "51.69px", flexGrow: 1 }}
                            inputProps={{ "data-testid": "last-name-input" }}
                            onChange={(e) => {
                              setConfiguration((prevState) => {
                                if (!prevState.data) return prevState;
                                const clone = [...prevState.data];
                                clone[index].name = e.target.value;
                                return {
                                  ...prevState,
                                  data: [...clone],
                                };
                              });
                            }}
                            status={status}
                          />
                        </Grid>
                        <Grid item xs={4.5}>
                          {values.level.code !== "IV" && (
                            <RolesAutocomplete
                              values={values}
                              index={index}
                              roles={roles}
                              setConfiguration={setConfiguration}
                              handleBlur={handleBlur}
                              configuration={configurationValues}
                              disabled={
                                !can([
                                  PermissionsUpdate.SETTINGS_RELEASE,
                                  PermissionsCreate.SETTINGS_RELEASE,
                                ])
                              }
                            />
                          )}
                        </Grid>

                        <Grid item>
                          <Box sx={{ flexGrow: 1 }}>
                            {values.level.code !== "I" && (
                              <FormControl
                                component="fieldset"
                                variant="standard"
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Android12Switch
                                        checked={values.is_active}
                                        disabled={
                                          !can([
                                            PermissionsUpdate.SETTINGS_RELEASE,
                                            PermissionsCreate.SETTINGS_RELEASE,
                                          ])
                                        }
                                        onChange={(e) => {
                                          setConfiguration((prevState) => {
                                            if (!prevState.data)
                                              return prevState;
                                            const clone = [...prevState.data];
                                            clone[index].is_active =
                                              e.target.checked;
                                            return {
                                              ...prevState,
                                              data: [...clone],
                                            };
                                          });
                                        }}
                                        name="is_active"
                                      />
                                    }
                                    label={t("active")}
                                  />
                                </FormGroup>
                              </FormControl>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={0}
              >
                {can([
                  PermissionsUpdate.SETTINGS_RELEASE,
                  PermissionsCreate.SETTINGS_RELEASE,
                ]) && (
                  <Button
                    color="success"
                    testId="create-role-submit"
                    title={t("settings.release.submit")}
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          message={t("settings.release.configurationWasSuccessfullyUpdated")}
          color="success"
          open={isSnackbarVisible}
          handleClose={handleClose}
          data-testid="snackbar-invoice-success"
        />
      </Grid>
    </Form>
  );
};

export default ReleasePermissionForm;
