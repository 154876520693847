import http from "../utils/http";
import {
  EmployeeSchedule as FEEmployeeSchedule,
  VacationHoliday,
} from "../types/fe.interfaces";
import { IdPropType } from "../types/models";
import { LocationListData } from "../components/employeeOverview/locationModal/lacationList/types";

export const getEmployees = async (params: string): Promise<Response> => {
  return await http.get(`/api/user-schedules${params}`);
};
/* istanbul ignore next */
export const createEmployeeSchedule = async (
  schedule: FEEmployeeSchedule
): Promise<Response> => {
  return await http.post(`/api/user-schedules`, schedule);
};

export const adminCreateEmployeeSchedule = async (
  schedule: FEEmployeeSchedule
): Promise<Response> => {
  return await http.post(`/api/admin/user-schedules`, schedule);
};

/* istanbul ignore next */
export const updateEmployeeSchedule = async (
  schedule: Partial<FEEmployeeSchedule>
): Promise<Response> => {
  return await http.put(`/api/user-schedules/${schedule.id}`, schedule);
};
/* istanbul ignore next */
export const deleteEmployeeSchedule = async (
  schedule: Partial<FEEmployeeSchedule>
): Promise<Response> => {
  return await http.delete(`/api/admin/user-schedules/${schedule.id}`);
};

export const getEmployeeScheduleOptions = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/user-schedule-options${params}`);
};
/* istanbul ignore next */
export const createAdminUserSchedules = async (
  body: VacationHoliday
): Promise<Response> => {
  return await http.post(`/api/admin/user-schedules`, body);
};

export const deleteLocation = async ({ id }: IdPropType): Promise<Response> => {
  return await http.delete(`/api/user-schedule-options/${id}`);
};

export const updateLocation = async (
  id: string,
  data: Partial<LocationListData>
): Promise<Response> => {
  return await http.put(`/api/user-schedule-options/${id}`, data);
};

export const createLocation = async (
  data: Partial<LocationListData>
): Promise<Response> => {
  return await http.post(`/api/user-schedule-options`, data);
};
/* istanbul ignore next */
export const getPublicHolidays = async (params = ""): Promise<Response> => {
  return await http.get(`/api/public-holidays${params}`);
};
