import { Offer } from "../../../../types/be/offer";
import { updateOffer } from "../../../../api/offer";
import { FormikValues } from "formik/dist/types";
import { mapBackendValidationErrors } from "../../../../utils/form";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { OfferUpdateDto, SubmitHandlerProps } from "./types";
import { parseNumber } from "../../../../utils/common";

export const prepareForm = (
  values: Offer
): Pick<
  OfferUpdateDto,
  "amount" | "contact_id" | "contact" | "area" | "area_id" | "file_id"
> => ({
  amount: values.amount,
  contact_id: values.contact.id,
  contact: values.contact,
  area_id: values?.area?.id,
  area: values?.area,
  file_id: values.file?.id,
});

export const handleSubmit = ({
  offer,
  setShowSnackbar,
  setFormMessage,
}: SubmitHandlerProps) => {
  return async (
    values: Pick<
      OfferUpdateDto,
      "amount" | "contact_id" | "area_id" | "file_id"
    >,
    { setStatus }: FormikValues
  ): Promise<void> => {
    const payload = {
      area_id: values.area_id,
      contact_id: values.contact_id,
      topic_id: offer.topic_id,
      file_id: values.file_id,
      amount: parseNumber(values.amount, true),
    };
    const response = await updateOffer(payload, String(offer.id));

    if (response.status === 200) {
      setShowSnackbar(true);
      setFormMessage(FormMessageInItState);
      setStatus({
        success: true,
        errors: {},
      });
    } else if (response.status === 422) {
      const { errors } = await response.json();

      setStatus({
        success: false,
        errors: mapBackendValidationErrors(errors),
      });
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
    }
  };
};
