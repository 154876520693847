import http from "../utils/http";
/* istanbul ignore next */
export const getPlacePredictions = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/place/autocomplete${params}`);
};
/* istanbul ignore next */
export const getPlaceDetails = async (params: string): Promise<Response> => {
  return await http.get(`/api/place/details${params}`);
};
