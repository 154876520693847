import React, {
  HTMLAttributes,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import TextField from "../../../ui/formsFields/text";
import Button from "../../../ui/button/Button";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { Grid, Stack } from "@mui/material";
import Snackbar from "../../../ui/snackbar1";
import { Form } from "formik";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AutocompleteListItem } from "./styled";
import { Property } from "../../../types/be/property";
import { SEARCH_PARAM_MIN_CHARACTERS } from "../../../constants";
import { getPlaceDetails, getPlacePredictions } from "../../../api/places";
import { Dropdown } from "../../../ui/autocomplete/styled";
import {
  Location,
  PlaceDetails,
  PlaceDetailsResponse,
  Prediction,
} from "../../../types/be/place";
import Loader from "../../Loader";
import { getJson } from "../../../utils/http";
import { REACT_APP_GOOGLE_API_KEY } from "../../../config";
import GoogleMapReact from "google-map-react";
import PinMarker from "../../../ui/map/marker/default";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import { FilterOptionsState } from "@mui/base/useAutocomplete";
import {
  DEFAULT_MAP_LAT,
  DEFAULT_MAP_LNG,
  DEFAULT_MAP_ZOOM,
} from "../../../ui/map/utils";
import { CreateCompanyFormProps } from "./types";
import { route } from "../../../utils/url";
import debounce from "lodash/debounce";
import camelCase from "lodash/camelCase";
import {
  mapAddressComponentsToPlaceAddress,
  placeErrorText,
} from "../../properties/utils";
import { isNodeEnv } from "../../../utils/env";
import {
  findFiveDigitZipCode,
  prepareQueryParams,
} from "../../../utils/common";

/* istanbul ignore next */
function CreateCompanyForm({
  errors,
  isSubmitting,
  values,
  status,
  setFieldValue,
  handleSubmit,
}: CreateCompanyFormProps): ReactElement {
  const [loading, setLoading] = useState(false);
  const [placePredictions, setPlacePredictions] = useState<
    readonly Prediction[]
  >([]);
  const { t } = useTranslation();
  const [zoom, setZoom] = useState<number>(8);
  const [, setPlaceDetails] = useState<PlaceDetails | null>(null);
  const [placeLocation, setPlaceLocation] = useState<Location | undefined>(
    undefined
  );
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [isPlaceValidationError, setIsPlaceValidationError] = useState(false);
  const [placeValidationErrorText, setPlaceValidationErrorText] = useState("");
  const [placeInputValue, setPlaceInputValue] = useState("");
  const [createdCompany, setCreatedCompany] = useState<Property | null>(null);
  const [placeAutocompleteFieldValue, setPlaceAutocompleteFieldValue] =
    useState("");

  const handleSnackbarClose = (): void => setIsSnackbarVisible(false);

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
    setCreatedCompany(status?.company);

    if (status?.success) {
      resetForm();
    }
  }, [status]);

  const onLocationSearchKeyDown = React.useCallback(
    debounce(async (search: string): Promise<void> => {
      if (search.length < SEARCH_PARAM_MIN_CHARACTERS) setPlacePredictions([]);
      else {
        setLoading(true);
        const params = prepareQueryParams("", {
          input: search,
        });
        getPlacePredictions(params)
          .then(getJson)
          .then(({ data: { predictions } }) => {
            setPlacePredictions(predictions);
            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setPlacePredictions([]);
          });
      }
    }, 300),
    []
  );

  const handlePlacePredictionSelect = ({ place_id }: Prediction): void => {
    const params = prepareQueryParams("", {
      place_id,
      language: "en",
    });
    getPlaceDetails(params)
      .then(getJson)
      .then(({ data: { result } }: PlaceDetailsResponse) => {
        setPlaceDetails(result);
        setPlaceLocation(result.geometry.location);
        setZoom(12);
        const { address_components } = result;
        const { geometry } = result;
        const { place_id } = result;
        const mapResult =
          mapAddressComponentsToPlaceAddress(address_components);
        setPlaceInputValue(result.formatted_address);
        setFieldValue("location", {
          full_address: result.formatted_address!,
          city: mapResult.city!,
          country_code: mapResult.country_code!,
          state_code: mapResult.state_code!,
          house_number: mapResult.house_number!,
          zip_code: mapResult.zip_code!,
          street: mapResult.street!,
          place_id: place_id!,
          lat: geometry.location.lat!,
          lng: geometry.location.lng!,
        });
      });
  };
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const handleAutocompleteOnChange = (
    _: SyntheticEvent,
    value: Prediction | null
  ): void => {
    if (value) handlePlacePredictionSelect(value);
    else {
      setPlaceDetails(null);
    }
  };

  useEffect(() => {
    const str = placeErrorText(errors.location);
    setIsPlaceValidationError(!!str.length);
    setPlaceValidationErrorText(
      t(`google.place.autocomplete.${camelCase(str)}`)
    );
  }, [errors]);

  const resetForm = (): void => {
    setPlaceInputValue("");
    setPlacePredictions([]);
    setPlaceLocation(undefined);
    setZoom(8);
    setIsPlaceValidationError(false);
    setPlaceValidationErrorText("");
  };

  useEffect(() => {
    const newZipCode = findFiveDigitZipCode(placeAutocompleteFieldValue);
    newZipCode && setFieldValue("location.zip_code", newZipCode);
    newZipCode &&
      setFieldValue("location.full_address", placeAutocompleteFieldValue);
  }, [placeAutocompleteFieldValue]);

  const handleRenderInput = (
    params: AutocompleteRenderInputParams
  ): ReactNode => (
    <TextField
      {...params}
      label={t("form.propertyLocation")}
      onChange={(e) => {
        setPlaceInputValue(e.target.value);
        onLocationSearchKeyDown(e.target.value);
        setPlaceAutocompleteFieldValue(e.target.value);
      }}
      placeholder={t("google.place.autocomplete.placeholder")}
      value={values.location.place_id}
      error={isPlaceValidationError}
      helperText={isPlaceValidationError && placeValidationErrorText}
    />
  );

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Grid container sx={{ mb: 3 }}>
        <Grid item xs={12} sm={12} md={12} sx={{ mb: 3 }}>
          <Autocomplete
            fullWidth
            loading={loading}
            loadingText={`${t("loading")}...`}
            inputValue={placeInputValue}
            popupIcon={loading ? <Loader size={25} /> : <ArrowDropDownIcon />}
            options={placePredictions!}
            isOptionEqualToValue={handleIsOptionEqualToValue}
            getOptionLabel={handleGetOptionLabel}
            filterOptions={handleFilterOptions}
            onChange={handleAutocompleteOnChange}
            renderOption={handleRenderOption}
            PaperComponent={autocompletePaperComponent}
            renderInput={handleRenderInput}
          />
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <Grid container direction={"column"} spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  required
                  name="object_name"
                  label={t("property.objectName")}
                  inputProps={{ "data-testid": "object-name" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="notes"
                  name="notes"
                  label={t("property.notes")}
                  multiline
                  rows={5}
                  inputProps={{ "data-testid": "notes" }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6}>
            {!isNodeEnv("test") && (
              <GoogleMapReact
                bootstrapURLKeys={{ key: REACT_APP_GOOGLE_API_KEY! }}
                defaultCenter={{ lat: DEFAULT_MAP_LAT, lng: DEFAULT_MAP_LNG }}
                defaultZoom={DEFAULT_MAP_ZOOM}
                center={placeLocation}
                zoom={zoom}
              >
                {placeLocation && (
                  <PinMarker lat={placeLocation.lat} lng={placeLocation.lng} />
                )}
              </GoogleMapReact>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={0}
      >
        <Button
          color="success"
          title={t("create")}
          type="submit"
          disabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Stack>
      <Snackbar
        open={isSnackbarVisible}
        title={t("company.companyCreated")}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
        link={`${window.location.origin}${route(
          "company.edit",
          createdCompany?.id,
          "general"
        )}`}
      />
    </Form>
  );
}
/* istanbul ignore next */
const handleRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  placePrediction: Prediction
): ReactNode => (
  <AutocompleteListItem {...props} key={placePrediction.description}>
    - {placePrediction.description}
  </AutocompleteListItem>
);
/* istanbul ignore next */
const handleIsOptionEqualToValue = (
  option: Prediction,
  value: Prediction
): boolean => option.place_id === value.place_id;
/* istanbul ignore next */
const handleGetOptionLabel = (placePrediction: Prediction): string =>
  placePrediction.description;

/* eslint-disable @typescript-eslint/no-unused-vars */ /* istanbul ignore next */
const handleFilterOptions = (
  options: Prediction[],
  _: FilterOptionsState<Prediction>
): Prediction[] => options;
/* istanbul ignore next */
const autocompletePaperComponent = ({
  children,
}: {
  children?: any;
}): ReactElement<any, any> | null => <Dropdown>{children}</Dropdown>;

export default CreateCompanyForm;
