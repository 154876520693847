import React, { useEffect, useRef, useState, MouseEvent } from "react";
import {
  styled,
  IconButton,
  TextFieldProps,
  Grid,
  Tooltip,
} from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import DownloadIcon from "@mui/icons-material/Download";
import { FormikValues, useField, useFormikContext } from "formik";
import { uploadFile } from "../../../api/files";
import { handleServerError } from "../../../utils/http";
import { CircularProgress } from "@mui/material";
import { UploadFileType } from "../../../types/fe.interfaces";
import { DEFAULT_FILE_TYPES } from "../../../constants";
import TextField from "../textField";
import { useTranslation } from "react-i18next";

const Input = styled("input")({
  display: "none",
});

type FileInputProps<T> = Partial<TextFieldProps> & {
  value?: null | string | UploadFileType;
  foldername?: string;
  propertyid?: string;
  testId?: string;
  directUpload?: boolean;
  name: string;
  accept?: string;
  errorText?: string;
  status?: { errors?: Record<string, string> };
  outerFileName?: string;
  setOuterFileName?: React.Dispatch<string>;
  setFormInitState?: React.Dispatch<React.SetStateAction<T>>;
  downloadUrl?: string;
  handleOsc?: (fileId: number, fileName: string) => Promise<void>;
  setLoader?: React.Dispatch<React.SetStateAction<boolean>>;
} & FormikValues;

/* istanbul ignore next */
const FileInput = <T,>({
  testId,
  directUpload,
  accept = DEFAULT_FILE_TYPES,
  errorText,
  status,
  values,
  outerFileName,
  setOuterFileName,
  setFormInitState,
  downloadUrl,
  handleOsc,
  setLoader,
  ...props
}: FileInputProps<T>): React.ReactElement => {
  const { t } = useTranslation();
  const [field, meta] = useField(props.name);
  const { setFieldValue, setFieldError } = useFormikContext();
  const [fileName, setFileName] = useState(
    (typeof field.value === "string" ? field.value : field.value?.name) ??
      (String(outerFileName) || "")
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (outerFileName || outerFileName === "") {
      resetFileName();
    }
  }, [outerFileName]);

  const resetFileName = (): void => {
    ref.current!.value = "";
    outerFileName && setFileName(outerFileName);
  };

  const ref = useRef<HTMLInputElement>(null);

  const handleFile = async (file: FileList | null): Promise<void> => {
    const allowedTypes = accept.replace(/\s/g, "").split(",");
    if (!allowedTypes.includes(String(file?.[0]?.type))) {
      setFieldError(props.name, errorText);
      return;
    }
    if (directUpload === false) {
      /* istanbul ignore next */
      setFileName(file?.[0]?.name ?? "");
      /* istanbul ignore next */
      setFieldValue(props.name, file?.[0]);
    } else {
      const data = new FormData();
      const fileField = "file_id";
      file && data.append("file", file[0]);
      /* istanbul ignore next */
      data.append("property_id", props.propertyid || "");
      data.append("folder", props.foldername!);
      setLoading(true);
      setLoader && setLoader(true);
      const res = await uploadFile(data);
      if (res.status !== 201) {
        const { errorMessage } = handleServerError(res);
        setFieldError(props.name, errorMessage);
        console.log(errorMessage);
      } else {
        const resJson = await res.json();
        setOuterFileName && setOuterFileName("");
        setFileName(resJson.data.name);
        /* istanbul ignore next */
        setFieldValue(props.name || fileField, resJson.data.id);
        setFormInitState &&
          setFormInitState({
            ...values,
            [props.name]: resJson.data.id,
          });
        handleOsc?.(resJson.data.id, resJson.data.name);
      }
      setLoading(false);
      setLoader && setLoader(false);
    }
  };

  const downloadFile = (event: MouseEvent<HTMLSpanElement>): void => {
    event.stopPropagation();
    window.open(downloadUrl, "_blank");
  };

  return (
    <TextField
      label="url"
      sx={{ pr: 0 }}
      {...props}
      disabled
      value={fileName}
      error={!!meta.error && meta.touched}
      helperText={meta.error && meta.touched}
      status={status}
      InputProps={{
        endAdornment: (
          <label htmlFor={props.id}>
            <Input
              accept={accept}
              type="file"
              ref={ref}
              data-testid={testId ?? "file-upload"}
              name={props.name}
              id={props.id}
              onChange={(e) => {
                handleFile(e?.target?.files);
              }}
              disabled={props.disabled}
            />
            <Grid display="flex">
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Tooltip placement="top" title={t("uploadFile")}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size="small"
                    disabled={props.disabled}
                  >
                    <PublishIcon />
                  </IconButton>
                </Tooltip>
              )}
              {downloadUrl && (
                <Tooltip title={t("download")} placement="top">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size="small"
                    onClick={downloadFile}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </label>
        ),
      }}
    />
  );
};

export default FileInput;
