export default {
  place: {
    autocomplete: {
      validationError:
        "Country, city, street and house number must be specified",
      placeholder: "City, street, house number",
      countryIsRequired: "Country is required",
      houseNumberIsRequired: "House number is required",
      streetIsRequired: "Street is required",
      countryHouseNumberAreRequired: "Country house number are required",
      houseNumberStreetAreRequired: "House number street are required",
      streetCountryHouseNumberAreRequired:
        "Street, country, house number are required",
      streetHouseNumberAreRequired: "Street, house number are required",
      cityStreetHouseNumberCountryAreRequired:
        "City, street, house number, country are required",
      countryCodeIsRequired: "Country is required",
    },
  },
};
