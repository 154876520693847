import React, { ReactElement, useCallback, useEffect } from "react";
import { itemsListHeadCells } from "./utils";
import { useTranslation } from "react-i18next";
import { MoreVerticalButton } from "../../properties/list/styled";
import { Input, Search, SearchIconWrapper } from "../../../ui/table/styled";
import { Search as SearchIcon } from "react-feather";
import Table from "../../../ui/table/Table";
import useIsMounted from "../../../hooks/useIsMounted";
import { handleServerError } from "../../../utils/http";
import { Box, Grid, Menu, MenuItem, Tooltip, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import { route } from "../../../utils/url";
import useTable from "../../../ui/table/useTable";
import { Property } from "../../../types/be/property";
import {
  downloadSampleFile,
  exportExcelPropertyList,
  exportPdfPropertyList,
  getProperties,
} from "../../../api/property";
import {
  XLSX_FILE_TYPE,
  PermissionsCreate,
  COMPANY_FILTER,
} from "../../../constants";
import { downloadFile } from "../../../utils/common";
import useAccessControl from "../../../hooks/useAccessControl";
import { AnchorDict, PageViewTypes } from "../../properties/list/types";
import UploadManuallyDialog from "../../properties/list/upload";
import { MoreVertical } from "react-feather";
import { StyledButton } from "../../../ui/button/styled";
import CompanyListView from "./CompanyListView";
import PropertyGridView from "../../properties/list/PropertyGridView";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { changePropertyLayout } from "../../../redux/slices/property";
import { useDispatch } from "react-redux";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import HZScrollController from "../../../ui/HZScrollController";
import { handleDeleteClick } from "../../properties/list/utils";
/* istanbul ignore next */
const CompanyList = (): ReactElement => {
  const { t } = useTranslation();
  const { can } = useAccessControl();
  const dispatch: AppDispatch = useDispatch();
  const { layout } = useSelector((state: RootState) => state?.property);

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleDeleteLastPageData,
  } = useTable<Property>({
    limit: layout === PageViewTypes.LIST ? undefined : 12,
    loading: true,
  });

  const [uploadModelVisible, setUploadModalVisible] = React.useState(false);
  const importExportTab = "importExport";
  const [anchorDict, setAnchorDict] = React.useState<AnchorDict>({
    importExport: null,
  });

  useEffect(() => {
    if (isMounted()) {
      fetchProperty();
    }
  }, [queryParams]);

  const fetchProperty = useCallback(async () => {
    setIsLoading(true);
    const res = await getProperties(queryParams + `${COMPANY_FILTER}`);
    if (!res.ok) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const resJson = await res.json();
      setTotal(resJson.meta.total);
      setData(resJson.data);
      setIsLoading(false);
    }
  }, [queryParams, setIsLoading, setTotal, setData, setIsLoading]);

  const handlePropertyExportPDF = useCallback(async () => {
    const res = await exportPdfPropertyList();
    if (!res.ok) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const blob = await res.blob();
      downloadFile(new Blob([blob]), t("propertiesList.propertiesList"), "pdf");
    }
  }, []);

  const handlePropertyExportExcel = useCallback(async () => {
    const res = await exportExcelPropertyList();
    if (!res.ok) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const blob = await res.blob();
      downloadFile(
        new Blob([blob]),
        t("propertiesList.propertiesList"),
        XLSX_FILE_TYPE
      );
    }
  }, []);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await handleDeleteClick(rowToDelete);
    handleDeleteLastPageData(fetchProperty);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    handleDeleteClick,
    fetchProperty,
    handleConfirmToRemoveModalClose,
  ]);

  const handleDownloadLinkClick = async (): Promise<void> => {
    const res = await downloadSampleFile();
    if (!res.ok) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const blob = await res.blob();
      downloadFile(
        new Blob([blob]),
        t("propertiesList.propertiesList"),
        XLSX_FILE_TYPE
      );
    }
  };

  const handleImportLinkClick = async (): Promise<void> => {
    setUploadModalVisible(true);
  };

  const handleClick =
    (tab: string) => (event: React.MouseEvent<HTMLElement>) => {
      const currentTab = event.currentTarget;
      setAnchorDict((d) => ({ ...d, [tab]: currentTab }));
    };

  const handleClose = (): void => {
    setAnchorDict({ importExport: null });
  };

  const onUploadSuccess = (): void => {
    setUploadModalVisible(false);
    fetchProperty();
  };

  const TableToolbar = (
    <Grid container sx={{ pt: 2, pb: 2 }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {can(PermissionsCreate.PROPERTY) && (
          <Grid item>
            <StyledButton
              data-testid={"dataItems-link"}
              component={NavLink}
              to={route(`companies.create`)}
              size="small"
              variant="contained"
              color="success"
            >
              {t("company.createNewCompany")}
            </StyledButton>
          </Grid>
        )}
        <Grid item>
          <Grid item xs={6} display="flex">
            <Tooltip title={t("property.list")} placement="top">
              <Button
                role="list-layout"
                fullWidth
                size={"small"}
                variant={
                  layout === PageViewTypes.LIST ? "contained" : "outlined"
                }
                onClick={() => {
                  setIsLoading(true);
                  dispatch(changePropertyLayout(PageViewTypes.LIST));
                }}
                sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              >
                <FormatListBulletedOutlinedIcon
                  color={layout === PageViewTypes.LIST ? "inherit" : "success"}
                  fontSize="medium"
                />
              </Button>
            </Tooltip>
            <Tooltip title={t("property.grid")} placement="top">
              <Button
                role="grid-layout"
                fullWidth
                size="medium"
                variant={
                  layout === PageViewTypes.GRID ? "contained" : "outlined"
                }
                onClick={() => {
                  setIsLoading(true);
                  dispatch(changePropertyLayout(PageViewTypes.GRID));
                }}
                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              >
                <GridViewOutlinedIcon
                  color={layout === PageViewTypes.GRID ? "inherit" : "success"}
                  fontSize="medium"
                />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item>
          <Box display="flex">
            <HZScrollController />
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t("search")} onChange={handleTableSearch} />
            </Search>
            <>
              <MoreVerticalButton
                aria-haspopup="true"
                size={"small"}
                type="button"
                color="inherit"
                variant="text"
                disableElevation
                data-testid={"upload-manually-menu"}
                onClick={handleClick(importExportTab)}
              >
                <MoreVertical />
              </MoreVerticalButton>
              <Menu
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                anchorEl={anchorDict.importExport}
                open={Boolean(anchorDict.importExport)}
                data-testid={"menu"}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={(): void => {
                    handleDownloadLinkClick();
                    handleClose();
                  }}
                  disableRipple
                  data-testid={"download-sample-link"}
                >
                  {t("property.downloadExample")}
                </MenuItem>
                <MenuItem
                  onClick={(): void => {
                    handleImportLinkClick();
                    handleClose();
                  }}
                  disableRipple
                  data-testid={"upload-link"}
                >
                  {t("property.importProperties")}
                </MenuItem>
                <MenuItem
                  onClick={(): void => {
                    handlePropertyExportPDF();
                    handleClose();
                  }}
                  disableRipple
                >
                  {t("export.pdf")}
                </MenuItem>
                <MenuItem
                  onClick={(): void => {
                    handlePropertyExportExcel();
                    handleClose();
                  }}
                  disableRipple
                >
                  {t("export.excel")}
                </MenuItem>
              </Menu>
            </>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderRow = (row: Property): ReactElement => (
    <>
      <CompanyListView
        row={row}
        handleCellClick={handleCellClick}
        openConfirmToRemoveModal={openConfirmToRemoveModal}
      />
    </>
  );

  const isMounted = useIsMounted();

  return (
    <>
      {layout === PageViewTypes.LIST ? (
        <Table
          data={data}
          total={total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onRowRemove={handleRowRemove}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={itemsListHeadCells}
          renderRow={renderRow}
          tableToolbar={TableToolbar}
          noDataIsAvailablePlaceholder={"table.noPropertiesAreAvailable"}
          confirmationText={t("property.confirmRemoveText")}
          refId="hzScroll"
        />
      ) : (
        <PropertyGridView
          data={data}
          tableToolbar={TableToolbar}
          isTenant={false}
          total={total}
          rowsPerPage={12}
          isLoading={isLoading}
          currentPage={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          noDataIsAvailablePlaceholder={"table.noPropertiesAreAvailable"}
        />
      )}

      <UploadManuallyDialog
        onSuccess={onUploadSuccess}
        visible={uploadModelVisible}
        toggleModel={setUploadModalVisible}
      />
    </>
  );
};

export default CompanyList;
