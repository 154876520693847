import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import useTable from "../../../../../../ui/table/useTable";
import Table from "../../../../../../ui/table/Table";
import { User } from "../../../../../../types/be/user";
import { deleteUser } from "../../../../../../api/users";
import { handleServerError } from "../../../../../../utils/http";
import MobileUserListRow from "./MobileUserListRow";
import { InviteMobileUserModal } from "./CreateMobileUserModal";
import { itemsListHeadCells } from "./MobileUserListRow/utils";
import useSnackbar from "../../../../../../ui/snackbar1/useSnackbar";
import { useTranslation } from "react-i18next";
import { HTTP_STATUS_CODES } from "../../../../../../types/server";
import { Button, Grid, Typography } from "@mui/material";
import { getAreaTenantUsers } from "../../../../../../api/area";
import { useParams } from "react-router-dom";
import { MobileTenantUser } from "../../../../../../types/be/area";
import { AreaEditContext } from "../../AreaEditContext";
import { DefaultImage, TenantInfoContainer } from "./styled";
import { Paper } from "@mui/material";
import { HALF_TABLE_MIN_WIDTH } from "../../../../../../constants";

/* istanbul ignore next */
const MobileUser = (): React.ReactElement => {
  const { areaId } = useParams();
  const areaEditContext = useContext(AreaEditContext);
  const activeTenantId = areaEditContext?.area?.active_tenant?.id;
  const activeTenantContact = areaEditContext?.area?.active_tenant?.contact;

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { Snackbar, snackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleDeleteLastPageData,
  } = useTable<User>();

  const handleInviteModalOpen = (): void => {
    if (data.length >= 3) {
      snackbar.error(t("tenant.inviteMobileUserLimit"));
    } else {
      setIsCreateModalOpen(true);
    }
  };
  const handleInviteModalClose = (): void => {
    setIsCreateModalOpen(false);
  };

  const fetchMobileUser = useCallback(async () => {
    setIsLoading(true);
    const response = await getAreaTenantUsers(String(areaId));
    if (response.status !== HTTP_STATUS_CODES.OK) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const resJson = await response.json();
      setTotal(resJson?.meta?.total);
      setData(resJson.data);
    }
    setIsLoading(false);
  }, [queryParams, setIsLoading, setTotal, setData]);

  useEffect(() => {
    fetchMobileUser();
  }, [queryParams]);

  const renderRow = (row: MobileTenantUser): ReactElement => (
    <>
      <MobileUserListRow
        row={row}
        openConfirmToRemoveModal={openConfirmToRemoveModal}
        snackbar={snackbar}
        fetchMobileUser={fetchMobileUser}
      />
    </>
  );

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteUser({ id: rowToDelete.id });
    handleDeleteLastPageData(fetchMobileUser);
    handleConfirmToRemoveModalClose();
  }, [rowToDelete, fetchMobileUser, handleConfirmToRemoveModalClose]);

  const tableToolbar = (
    <>
      <Grid item flexDirection="row" md={6} mt={6} mb={6}>
        {activeTenantId && (
          <Button
            sx={{ mr: 1 }}
            type="button"
            onClick={handleInviteModalOpen}
            size="medium"
            color="success"
            variant="contained"
          >
            {t("tenant.inviteUser")}
          </Button>
        )}
      </Grid>
    </>
  );
  return (
    <>
      <Grid container spacing={2} sx={{ p: 3 }} justifyContent="center">
        <Grid item sm={6} md={4} xl={5} alignSelf="center">
          <DefaultImage src={`/static/img/files/mobileapp.png`} alt="" />
        </Grid>
        <Grid direction={"row"} item sm={6} md={8} xl={7}>
          <Paper>
            <TenantInfoContainer>
              {activeTenantContact?.company_name ? (
                <Typography variant="h3">
                  {activeTenantContact?.company_name}
                </Typography>
              ) : null}
              {activeTenantContact?.full_name ? (
                <Typography variant="h5">
                  {activeTenantContact?.full_name}
                </Typography>
              ) : null}
              {activeTenantContact?.address ? (
                <Typography variant="h5">
                  {activeTenantContact?.address}
                </Typography>
              ) : null}
              {activeTenantContact?.city ? (
                <Typography variant="h5">
                  {activeTenantContact?.city}
                </Typography>
              ) : null}
              <Typography variant="h6" mt={5}>
                {t("tenant.mobileUserText")}
              </Typography>
            </TenantInfoContainer>
          </Paper>
          <Paper sx={{ mt: 4 }}>
            <Table
              data={data}
              total={total}
              currentPage={currentPage}
              order={order}
              orderBy={orderBy}
              error={error}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangePage}
              onRowRemove={handleRowRemove}
              onSortChange={handleSortChange}
              onSelectAllClick={handleSelectAllClick}
              onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
              rowsPerPage={rowsPerPage}
              isLoading={isLoading}
              isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
              listHeadCells={itemsListHeadCells}
              renderRow={renderRow}
              tableToolbar={tableToolbar}
              noDataIsAvailablePlaceholder={"table.noUsersAreAvailable"}
              minWidth={HALF_TABLE_MIN_WIDTH}
            />
          </Paper>
        </Grid>
      </Grid>

      {isCreateModalOpen && (
        <InviteMobileUserModal
          isCreateModalOpen={true}
          handleInviteModalClose={handleInviteModalClose}
          snackbar={snackbar}
          fetchMobileUser={fetchMobileUser}
        />
      )}
      {Snackbar}
    </>
  );
};

export default MobileUser;
