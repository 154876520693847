import React, { ReactElement, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import { AddInvoiceDialogProps } from "./types";
import Form from "./form";
import { assignInvoiceInitialValues } from "./utils";
import * as Yup from "yup";
import useValidation from "../../../../hooks/useValidation";
import { FormMessageInterface } from "../../../../types/form";
import { FormMessageInItState } from "../../../../constants";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
import FormAlert from "../../../../ui/formAlert/FormAlert";

const AssignInvoiceDialog = ({
  open,
  onClose,
  id,
  afterAssignInvoice,
  handleAssignInvoice,
  propertyId,
}: AddInvoiceDialogProps): ReactElement => {
  const { invoice_id } = useValidation();
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="assign-invoice-dialog"
    >
      <DialogTitle title="reminder.assignInvoice" onClose={onClose} />
      <DialogContent>
        {formMessage.text ? (
          <FormAlert formMessage={formMessage} sx={{ marginBottom: "10px" }} />
        ) : null}
        <Formik
          initialValues={{
            ...assignInvoiceInitialValues,
            property_id: propertyId || "",
          }}
          onSubmit={handleAssignInvoice(id, {
            setFormMessage,
            afterAssignInvoice,
          })}
          validationSchema={Yup.object().shape({
            invoice_id,
          })}
        >
          {(props) => <Form {...props} propertyId={propertyId} />}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AssignInvoiceDialog;
