import * as React from "react";
import { ReactElement } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { route } from "../../../../utils/url";
import { objectGetParamsToString } from "../../../../utils/common";
import useAccessControl from "../../../../hooks/useAccessControl";
import { PermissionsRead } from "../../../../constants";
import { ObjectDataSheetTabs } from "../../objectDataSheet/types";
import { TabBar, TabButton } from "./styled";
import { TABS } from "../../edit/types";
import { PROPERTY_MODULE } from "../../propertyWrapper/types";
import { LinkMenuProps } from "./types";

export const LabelText = styled.label`
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  margin: 0;
`;

export const SubMenuItem = styled(MenuItem)`
  margin-left: 20px;
`;

/* istanbul ignore next */
const LinkMenu = ({ isCompany = false }: LinkMenuProps): ReactElement => {
  const { id, tab } = useParams();
  const { t } = useTranslation();
  const { can, isRole, atLeastOnePermission } = useAccessControl();
  const isTenant = isRole("tenant");
  const { pathname } = useLocation();

  const getRoutePath = (routeName: string, queryParams?: string): string => {
    let path = route(
      isCompany ? routeName.replace("properties", "companies") : routeName,
      id
    );
    if (queryParams) {
      path = `${path}?${queryParams}`;
    }
    return path;
  };

  const isInvoiceDisabled =
    pathname.includes("invoices") &&
    !pathname.includes("reminders") &&
    !pathname.includes("outgoing-invoices");

  const isReminderDisabled = pathname.includes("reminders");

  const isContractDisabled = pathname.includes("contracts");
  const isOfferDisabled = pathname.includes("offers");

  const isInsuranceDisabled = pathname.includes("insurances");
  const isInboxDisabled = pathname.includes("inbox");
  const isGeneralDocumentsDisabled = pathname.includes("general-documents");
  const isFileManagementDisabled = pathname.includes("file-management");

  const isObjectDataSheetDisabled =
    route("properties.object-data-sheet.tab", id, tab) === pathname;

  const isQuickSheetDisabled =
    route("properties.edit", id, TABS.GENERAL) === pathname;

  const isAreasDisabled = pathname.includes("areas");

  const isMaintenanceDisabled = pathname.includes(PROPERTY_MODULE.MAINTENANCE);

  const isOutgoingInvoiceDisabled = pathname.includes("outgoing-invoices");

  const isApiDATEvDisabled =
    route("properties.invoices.apidatev.tab", id, tab) === pathname;

  if (isTenant) {
    return <></>;
  } else {
    return (
      <TabBar item py={2} mb={2}>
        {can([PermissionsRead.PROPERTY]) && (
          <Link to={getRoutePath("properties.edit.main")}>
            <TabButton
              size="small"
              disableRipple
              disabled={isQuickSheetDisabled}
              data-testid={"objectDataSheet-link"}
            >
              {t("property.navLinks.quickSheet")}
            </TabButton>
          </Link>
        )}
        {can([PermissionsRead.PROPERTY]) && (
          <Link
            to={route(
              "properties.object-data-sheet.tab",
              id,
              ObjectDataSheetTabs.GENERAL_INFO
            )}
          >
            <TabButton
              size="small"
              disableRipple
              disabled={isObjectDataSheetDisabled}
              data-testid={"objectDataSheet-link"}
            >
              {t("property.navLinks.dataSheet")}
            </TabButton>
          </Link>
        )}
        {can([PermissionsRead.AREA]) && (
          <Link to={route("properties.areas", id)}>
            <TabButton
              size="small"
              disableRipple
              disabled={isAreasDisabled}
              data-testid={"tenantList-link"}
            >
              {t("property.navLinks.tenantList")}
            </TabButton>
          </Link>
        )}
        {can([PermissionsRead.MAINTENANCE]) && (
          <Link to={route("properties.maintenance", id)}>
            <TabButton
              size="small"
              disableRipple
              disabled={isMaintenanceDisabled}
              data-testid={"maintenance-link"}
            >
              {t("property.maintenance.title")}
            </TabButton>
          </Link>
        )}
        {atLeastOnePermission([
          PermissionsRead.INVOICE,
          PermissionsRead.CONTRACT,
          PermissionsRead.INSURANCE,
          PermissionsRead.OFFER,
        ]) && (
          <>
            {can([PermissionsRead.INVOICE]) && (
              <Link to={getRoutePath("properties.invoices")}>
                <TabButton
                  size="small"
                  disableRipple
                  disabled={isInvoiceDisabled}
                  data-testid={"invoices-link"}
                >
                  {t("property.navLinks.invoices")}
                </TabButton>
              </Link>
            )}
            {can([PermissionsRead.INVOICE]) && (
              <Link to={getRoutePath("properties.invoices.reminders")}>
                <TabButton
                  size="small"
                  disableRipple
                  disabled={isReminderDisabled}
                  data-testid={"reminders-link"}
                >
                  {t("property.navLinks.reminders")}
                </TabButton>
              </Link>
            )}
            {can([PermissionsRead.OUTGOING_INVOICE]) && (
              <Link to={getRoutePath("properties.outgoing-invoices")}>
                <TabButton
                  size="small"
                  disableRipple
                  disabled={isOutgoingInvoiceDisabled}
                  data-testid={"outgoing-invoices-link"}
                >
                  {t("property.navLinks.outgoingInvoices")}
                </TabButton>
              </Link>
            )}
          </>
        )}
        {can([PermissionsRead.DATEV_INVOICE]) && (
          <Link to={getRoutePath("properties.invoices.apidatev")}>
            <TabButton
              size="small"
              disableRipple
              disabled={isApiDATEvDisabled}
              data-testid={"apidatev-link"}
            >
              API-DATEv
            </TabButton>
          </Link>
        )}
        {can([PermissionsRead.CONTRACT]) && (
          <Link to={getRoutePath("properties.contracts")}>
            <TabButton
              size="small"
              disableRipple
              disabled={isContractDisabled}
              data-testid={"contracts-link"}
            >
              {t("property.navLinks.contracts")}
            </TabButton>
          </Link>
        )}
        {can([PermissionsRead.OFFER]) && (
          <Link to={getRoutePath("properties.offers")}>
            <TabButton
              size="small"
              disableRipple
              disabled={isOfferDisabled}
              data-testid={"offers-link"}
            >
              {t("property.navLinks.offers")}
            </TabButton>
          </Link>
        )}
        {can([PermissionsRead.INSURANCE]) && (
          <Link
            to={getRoutePath(
              "properties.insurances",
              objectGetParamsToString({ type_id: 1 })
            )}
          >
            <TabButton
              size="small"
              disableRipple
              disabled={isInsuranceDisabled}
              data-testid={"insurances-link"}
            >
              {t("property.navLinks.insurances")}
            </TabButton>
          </Link>
        )}
        {can([PermissionsRead.INSURANCE]) && (
          <Link to={getRoutePath("properties.inbox")}>
            <TabButton
              size="small"
              disableRipple
              disabled={isInboxDisabled}
              data-testid={"inbox-link"}
            >
              {t("inbox.title")}
            </TabButton>
          </Link>
        )}
        {can([PermissionsRead.GENERAL_DOCUMENT]) && (
          <Link to={getRoutePath("properties.general-documents")}>
            <TabButton
              size="small"
              disableRipple
              disabled={isGeneralDocumentsDisabled}
              data-testid={"general-documents-link"}
            >
              {t("sidebar.generalDocuments")}
            </TabButton>
          </Link>
        )}
        {can([PermissionsRead.FILE_MANAGEMENT]) && (
          <Link to={getRoutePath("properties.file-management")}>
            <TabButton
              size="small"
              disableRipple
              disabled={isFileManagementDisabled}
              data-testid={"file-management-link"}
            >
              {t("sidebar.fileManagement")}
            </TabButton>
          </Link>
        )}
      </TabBar>
    );
  }
};
export default React.memo(LinkMenu);
