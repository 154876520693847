import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  createInvoiceFormInitState,
  prepareFormKey,
  sendCredentialsSnackbarInitState,
} from "../utils";
import {
  CURRENCIES,
  FOLDER_NAMES,
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import { useLocation, useParams } from "react-router-dom";
import { route } from "../../../../utils/url";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid } from "@mui/material";
import Dropzone from "../../../../ui/dropzone";
import Snackbar from "../../../../ui/Snackbar";
import { getFile, getFileOCR, multiUploadFiles } from "../../../../api/files";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { Paper } from "../styled";
import { useRef } from "react";
import { FormDict, PagesDict, SnackbarMessage } from "../types";
import { getReleaseRoute } from "../../utils";
import PdfViewer from "../../../../ui/pdfViewer";
import { Base64Dict } from "../types";
import FormikForm from "./Formik";
import { InboxLocationState } from "../../../inbox/types";
import { Inbox } from "../../../../types/fe/inbox";
import { useFetch } from "../../../../hooks/useFetch";
import Loader from "../../../Loader";
import { replaceTimestampByMomentDatepicker } from "../../../../utils/date";
import { getInvoicePeriod } from "../../../../api/invoices";
import { InvoicePeriod } from "../../../../types/be.interfaces";
import useUploadDropzone from "../../../../hooks/useUploadDropzone";
import TabPageWrapper from "../../../../ui/tabPageWrapper";

const InvoiceCreate = (): ReactElement => {
  const { state } = useLocation() as InboxLocationState;
  const { t } = useTranslation();
  const { id = "" } = useParams();
  const {
    dropzone,
    setDropzone,
    setIsOcrLoading,
    handleOpenDropzone,
    handleCloseDropzone,
    isFileTreeVisible,
    isOcrLoading,
    handleOpenFileTree,
    handleCloseFileTree,
  } = useUploadDropzone();

  const [base64Dict, setBase64Dict] = useState<Base64Dict>({});
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const inboxData = state ? (state.inboxData as Inbox) : null;

  const [sendCredentialsSnackbar, setSendCredentialsSnackbar] =
    useState<SnackbarMessage>(sendCredentialsSnackbarInitState);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [page, setPage] = useState(0);
  const [forms, setForms] = useState<FormDict>({});
  const [pagesDict, setPagesDict] = useState<PagesDict>({});
  const [rowsPerPage, setRowsPerPage] = useState(1);
  const ref = useRef<HTMLObjectElement | null>(null);

  const {
    run: runInvoicePeriod,
    data: invoicePeriod,
    isLoading: isInvoicePeriodLoading,
  } = useFetch<InvoicePeriod[]>();

  const fetchInvoicePeriod = useCallback(() => {
    runInvoicePeriod(getInvoicePeriod());
  }, []);

  useEffect(() => {
    fetchInvoicePeriod();
  }, [id]);

  useEffect(() => {
    if (inboxData) {
      handleCloseDropzone();
      handleOsc(inboxData.file.id, inboxData.file.name, inboxData);
    }
  }, []);

  useEffect(() => {
    const auxPagesDict: PagesDict = {};
    Object.values(forms)
      .map(({ file_id }) => file_id)
      .forEach((el, i) => {
        auxPagesDict[i] = String(el);
      });
    setPagesDict({ ...auxPagesDict });
  }, [forms]);

  useEffect(() => {
    setNumberOfPages(Object.keys(forms).length);
  }, [Object.keys(forms).length]);

  useEffect(() => {
    if (numberOfPages) return;
    handleOpenDropzone();
    setDropzone((prevState) => ({ ...prevState, loading: false }));
  }, [numberOfPages, sendCredentialsSnackbar]);

  const handleError = ({ message }: { message: string }): void => {
    setSendCredentialsSnackbar({
      visible: true,
      text: message || t(SOMETHING_WENT_WRONG_ERROR),
      color: "error",
    });
  };
  /* istanbul ignore next */
  const handleFileTreeClick = async (
    file_id: string,
    file_name: string
  ): Promise<void> => {
    setIsOcrLoading(true);
    try {
      await handleOsc(+file_id, file_name);
    } catch (error) {
      console.log(error);
    } finally {
      setIsOcrLoading(false);
    }
  };

  const handleFile = useCallback(
    async <T extends File>(file: FileList | T[] | null): Promise<void> => {
      if (!file?.length)
        return handleError({
          message: t("fileManagement.fileTypeIsNotSupported"),
        });
      setNumberOfPages(file?.length);
      setDropzone((prevState) => ({ ...prevState, loading: true }));
      const data = new FormData();
      Array.isArray(file) &&
        file.forEach((el) => {
          data.append("file[]", el);
        });
      id && data.append("property_id", id);
      data.append("folder", FOLDER_NAMES.INVOICE);
      const response = await multiUploadFiles(data);
      const uploadFileJson = await response.json();
      if (response.status !== HTTP_STATUS_CODES.CREATED)
        return handleError(uploadFileJson);
      const { data: filesData } = uploadFileJson;
      Array.isArray(filesData) &&
        filesData.forEach(async ({ id: file_id, name: file_name }) => {
          handleOsc(file_id, file_name);
        });
    },
    []
  );

  const handleOsc = async (
    file_id: number,
    file_name: string,
    inboxData?: Inbox
  ): Promise<void> => {
    const responseGetFileOCR = await getFileOCR(file_id);
    const jsonGetFileOCR = await responseGetFileOCR.json();
    if (responseGetFileOCR.status === HTTP_STATUS_CODES.OK) {
      const {
        data: {
          total: amount,
          invoice_number: number,
          date,
          due_date,
          currency_code,
          vendor: { iban, bank_swift },
        },
      } = jsonGetFileOCR;
      setForms((prevState) => ({
        ...prevState,
        [prepareFormKey(file_id)]: {
          ...createInvoiceFormInitState(),
          property_id: inboxData?.property ? inboxData.property.id : id || "",
          property: inboxData?.property || null,
          area_id: inboxData?.area?.id || "",
          amount: inboxData?.invoice_amount || amount,
          number: inboxData?.invoice_number || number,
          date: inboxData?.invoice_date
            ? replaceTimestampByMomentDatepicker(inboxData?.invoice_date)
            : date
            ? new Date(date)
            : null,
          file_id,
          due_date,
          currency_code: CURRENCIES.includes(currency_code)
            ? currency_code
            : "",
          file_name,
          iban,
          bic: bank_swift,
        },
      }));
      handleCloseDropzone();
      handleCloseFileTree();
    } else {
      handleError(jsonGetFileOCR);
    }
    const responseGetFile = await getFile(file_id);
    const jsonGetFile = await responseGetFile.json();
    if (responseGetFile.status === HTTP_STATUS_CODES.OK) {
      const {
        data: { file },
      } = jsonGetFile;
      setBase64Dict((prevState) => ({
        ...prevState,
        [prepareFormKey(file_id)]: file,
      }));
    } else {
      handleError(jsonGetFile);
    }
  };

  const handleCloseSnackbar = (): void =>
    setSendCredentialsSnackbar(sendCredentialsSnackbarInitState);

  const pageFormKey = prepareFormKey(Number(pagesDict[page]));

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("invoices", Number(id)),
      name: t("property.navLinks.invoices"),
    },
  ];

  if (isInvoicePeriodLoading) {
    return <Loader />;
  }

  return (
    <TabPageWrapper title={t("create")} breadcrumbs={breadcrumbs} wrap={!id}>
      <>
        <Grid container justifyContent={"center"}>
          {formMessage.text && (
            <FormAlert
              formMessage={formMessage}
              sx={{ marginBottom: "10px" }}
            />
          )}
          {dropzone.visible && !inboxData ? (
            <Paper sx={{ position: "relative" }}>
              <Grid
                container
                data-testid="dropzone-container"
                sx={{
                  height: "100%",
                  position: "relative",
                  justifyContent: "center",
                }}
                onDragOver={handleOpenDropzone}
              >
                <Grid item sm={6} xs={12}>
                  <Dropzone
                    open={dropzone.visible}
                    onDrop={handleFile}
                    isLoading={dropzone.loading}
                    accept={{ "application/pdf": [".pdf"] }}
                    maxFiles={20}
                    isFileTreeVisible={isFileTreeVisible}
                    handleOpenFileTree={handleOpenFileTree}
                    handleCloseFileTree={handleCloseFileTree}
                    handleFileTreeClick={handleFileTreeClick}
                    isOcrLoading={isOcrLoading}
                    fileTreeExtensions={["pdf"]}
                  />
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <>
              {numberOfPages > 0 && forms[pageFormKey] ? (
                <Paper sx={{ position: "relative" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6} ref={ref}>
                          <PdfViewer
                            file={{
                              base64: base64Dict[pageFormKey],
                            }}
                            containerRef={ref}
                          />
                        </Grid>
                        <Grid item xs={6} pl={6}>
                          <FormikForm
                            forms={forms}
                            setForms={setForms}
                            page={page}
                            id={id}
                            setFormMessage={setFormMessage}
                            rowsPerPage={rowsPerPage}
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                            numberOfPages={numberOfPages}
                            setSendCredentialsSnackbar={
                              setSendCredentialsSnackbar
                            }
                            setNumberOfPages={setNumberOfPages}
                            inboxData={inboxData}
                            invoicePeriod={invoicePeriod}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              ) : (
                <Grid
                  container
                  sx={{
                    height: 300,
                    position: "relative",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <CircularProgress size={25} color="inherit" role="loader" />
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Snackbar
          data-testid={"send-credentials-snackbar"}
          message={sendCredentialsSnackbar.text}
          color={sendCredentialsSnackbar.color}
          open={sendCredentialsSnackbar.visible}
          handleClose={handleCloseSnackbar}
        />
      </>
    </TabPageWrapper>
  );
};

export default InvoiceCreate;
