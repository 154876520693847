import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Button, Grid, TextField } from "@mui/material";
import {
  Delete as DeleteIcon,
  Download as DownloadIcon,
  UploadFile as UploadFileIcon,
  HomeWork as HomeWorkIcon,
  Sync,
  Refresh,
} from "../../../../ui/button/icon/styled";
import { BankAccount as FEBankAccount } from "../../../../types/fe/bankAccount";
import { BankAccount as BEBankAccount } from "../../../../types/be/bankAccount";
import { useDeleteConfirmation } from "../../../../hooks/useDeleteConfirmation";
import { DeleteConfirmationPopover } from "../../../../ui/deleteConfirmationPopover";
import {
  downloadSales,
  importSales,
  syncOneBankAccount,
} from "../../../../api/bankAccount";
import { Tooltip } from "@mui/material";
import { GridStyledWrapper, IconButton, Input } from "./styled";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { handleServerError } from "../../../../utils/http";

const ToolBar = ({
  bankAccount,
  onDelete,
  onSave,
  onManagePropertiesButtonClick,
  fetchBankPayments,
  setErrorTableOnSync,
  useOnWidget,
}: {
  bankAccount: BEBankAccount;
  onDelete: () => void;
  onSave: (bankAccount: FEBankAccount) => void;
  onManagePropertiesButtonClick: () => void;
  fetchBankPayments: () => void;
  setErrorTableOnSync: React.Dispatch<React.SetStateAction<string>>;
  useOnWidget: boolean;
}): ReactElement => {
  const { id } = useParams();
  const { confirmDeleteState, showConfirmationPopover, handleClose } =
    useDeleteConfirmation();
  const [bankAccountName, setBankAccountName] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isFinishSyncing, setIsFinishSyncing] = useState(false);
  const [bankAccountNameWasChanged, setBankAccountNameWasChanged] =
    useState(false);
  const { t } = useTranslation();
  /* istanbul ignore next */
  const onImportSalesFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.length) {
      const fd = new FormData();
      fd.append("file", e.target.files[0]);
      fd.append("bank_account_id", id!);
      const object = {};
      fd.forEach(function (value, key) {
        // @ts-ignore
        object[key] = value;
      });
      importSales(fd)
        .then((res) => res.json())
        .then((json) => console.log(json))
        .catch((err) => console.error(err));
    }
  };
  /* istanbul ignore next */
  const syncBankAccount = useCallback(async () => {
    setIsSyncing(true);
    const res = await syncOneBankAccount(
      useOnWidget ? bankAccount.id : Number(id)
    );
    if (res.status !== HTTP_STATUS_CODES.OK) {
      const { errorMessage } = handleServerError(res);
      setErrorTableOnSync(errorMessage);
    } else {
      setIsSyncing(false);
      setIsFinishSyncing(true);
    }
  }, [setIsSyncing, setIsFinishSyncing]);

  useEffect(() => {
    setBankAccountName(bankAccount.bank_name);
    setBankAccountNameWasChanged(false);
  }, [bankAccount]);

  const handleDeleteConfirmation = (): void => {
    onDelete();
    handleClose();
  };

  const handleOnSaveClick = (): void => {
    onSave({
      id: bankAccount.id,
      date: bankAccount.date,
      booking_date: bankAccount.booking_date,
      description: bankAccount.description,
      sender: bankAccount.sender,
      iban: bankAccount.iban,
      bank_name: bankAccountName,
      amount: bankAccount.amount,
      property_id: bankAccount.properties.map((o) => o.id),
    });
  };
  /* istanbul ignore next */
  const handleDownload = (): void => {
    if (isDownloading) return;
    setIsDownloading(true);
    downloadSales()
      .then((res) => res.blob())
      .then((data) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = `sales`;
        a.click();
      })
      .finally(() => setIsDownloading(false));
  };
  /* istanbul ignore next */
  const handleOnCancelClick = (): void => {
    setBankAccountName(bankAccount.bank_name);
  };

  const validate = (): boolean => {
    return (
      bankAccountNameWasChanged &&
      bankAccount.bank_name !== bankAccountName &&
      bankAccountName.length > 1 &&
      bankAccountName.length < 100
    );
  };

  return (
    <>
      <GridStyledWrapper
        role="bank-account-tool-bar"
        container
        justifyContent={"space-between"}
        sx={{ py: 2, pl: 2, pr: 6 }}
        columnSpacing={2}
      >
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Grid container spacing={6}>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title={t("bankAccount.attachPropertyToBankAccount")}>
                    <span
                      onClick={onManagePropertiesButtonClick}
                      role="attach-property-button"
                    >
                      <HomeWorkIcon />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <TextField
                    inputProps={{
                      role: "bank-account-name-input",
                    }}
                    hiddenLabel
                    value={bankAccountName}
                    onChange={(e) => {
                      setBankAccountNameWasChanged(true);
                      setBankAccountName(e.target.value);
                    }}
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
            {validate() && (
              <Grid item>
                <Button
                  role={"save-bank-account-name-button"}
                  variant="contained"
                  onClick={() => handleOnSaveClick()}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
            {bankAccountNameWasChanged &&
              bankAccount.bank_name !== bankAccountName && (
                <Grid item>
                  <Button
                    role={"cancel-bank-account-name-button"}
                    color={"warning"}
                    variant="outlined"
                    onClick={
                      /* istanbul ignore next */ () => handleOnCancelClick()
                    }
                  >
                    {t("cancel")}
                  </Button>
                </Grid>
              )}
          </Grid>
        </Grid>
        <Grid item sx={{ display: "flex" }}>
          <Grid
            container
            sx={{ height: "100%", ml: 2 }}
            justifyItems={"center"}
            justifyContent={"center"}
            direction="column"
          >
            <Grid item>
              {!isFinishSyncing ? (
                <Tooltip title={t("property.bank.syncBankAccount")}>
                  <IconButton
                    onClick={/* istanbul ignore next */ () => syncBankAccount()}
                    disabled={isSyncing}
                    disableRipple={true}
                    role={"sync-bank-account-icon"}
                  >
                    <Sync />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={t("property.bank.reloadBankAccountData")}>
                  <Refresh
                    onClick={
                      /* istanbul ignore next */ () => {
                        fetchBankPayments();
                        setIsFinishSyncing(false);
                      }
                    }
                    role={"refresh-bank-account-icon"}
                  />
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ height: "100%", ml: 2 }}
            justifyItems={"center"}
            justifyContent={"center"}
            direction="column"
          >
            <Grid item>
              <label htmlFor="icon-button-file">
                <Input
                  onChange={
                    /* istanbul ignore next */ (e) => onImportSalesFileChange(e)
                  }
                  accept=".xls,.xlsx"
                  id="icon-button-file"
                  type="file"
                />
                <Tooltip title={t("property.bank.importSales")}>
                  <UploadFileIcon />
                </Tooltip>
              </label>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ height: "100%", ml: 2 }}
            justifyItems={"center"}
            justifyContent={"center"}
            direction="column"
          >
            <Grid item>
              <Tooltip title={t("property.bank.downloadSalesExampleFile")}>
                <DownloadIcon
                  onClick={/* istanbul ignore next */ () => handleDownload()}
                />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ height: "100%", ml: 2, mr: 5 }}
            justifyItems={"center"}
            justifyContent={"center"}
            direction="column"
          >
            <Grid item>
              <Tooltip title={t("property.bank.deleteBankAccount")}>
                <DeleteIcon
                  onClick={(e): void => {
                    showConfirmationPopover(e);
                  }}
                  size={20}
                  role={"delete-bank-account-icon"}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </GridStyledWrapper>
      <DeleteConfirmationPopover
        state={confirmDeleteState}
        handleClose={handleClose}
        handleDeleteConfirmation={handleDeleteConfirmation}
      />
    </>
  );
};

export default ToolBar;
