import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { CardHeader, Grid, TableCell } from "@mui/material";
import Loader from "../../../../Loader";
import { ErrorBox, FormWrapper } from "./styled";
import {
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  FormMessageErrorState,
  FormMessageInItState,
  Measurement_Units,
  THOUSAND_SEPARATOR,
  TIME_FORMAT,
} from "../../../../../constants";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  handleUpdateGeneralInformationFormSubmit,
  itemsListHeadCells,
} from "./utils";
import Form from "./form";
import useValidation from "../../../../../hooks/useValidation";
import Table from "../../../../../ui/table/Table";
import FormAlert from "../../../../../ui/formAlert/FormAlert";
import { TabPanelItemProps } from "../../types";
import {
  getProperty,
  getPropertyTopTenants,
} from "../../../../../api/property";
import { preparePropertyBeforeForm } from "../../utils";
import useTable from "../../../../../ui/table/useTable";
import { handleServerError } from "../../../../../utils/http";
import useIsMounted from "../../../../../hooks/useIsMounted";
import { LoadingBox, StyledStack } from "../../styled";
import { TopTenant } from "../../../../../types/be.interfaces";
import { NumericFormat } from "react-number-format";
import { formatTimestamp } from "../../../../../utils/date";
import { useTranslation } from "react-i18next";
import { ObjectDataSheetContext } from "../../ObjectDataSheetContexts";

const GeneralInformation = ({
  id,
  setDisabled,
}: TabPanelItemProps): ReactElement => {
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [isUploadingPropertyLogo, setIsUploadingPropertyLogo] = useState(false);
  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
  } = useTable<TopTenant>();

  const {
    useFetchProperty: {
      data: property,
      isError: isPropertyError,
      setState: setProperty,
      run: FetchProperty,
    },
    useFetchCaretakers: { data: caretakers, error: isCaretakersError },
    useFetchAllAssetManagers: { data: allAssetManagers },
    handleFetchPropertyBreadcrumbs,
  } = useContext(ObjectDataSheetContext);

  const {
    supervisor_id,
    expenses,
    phone_number,
    tax_number,
    vat,
    asset_managers,
    optionalEmail,
  } = useValidation();

  const isSuccess = property && caretakers && allAssetManagers;
  const isError = isPropertyError || isCaretakersError;

  useEffect(() => {
    const isLoadingFinished = isError || isSuccess;
    setDisabled(Boolean(!isLoadingFinished));
  }, [isError, isSuccess]);

  const handleServerResponse = async (res: Response): Promise<void> => {
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const resJson = await res.json();
      setData(resJson.data);
      setIsLoading(false);
    }
  };

  const fetchTopTenants = useCallback(async () => {
    setIsLoading(true);
    const res = await getPropertyTopTenants(id);
    isMounted() && handleServerResponse(res);
  }, [setData, setIsLoading]);

  /* istanbul ignore next */
  const onSuccess = (): void => {
    id && FetchProperty(getProperty(id));
    handleFetchPropertyBreadcrumbs();
  };

  useEffect(() => {
    isMounted() && fetchTopTenants();
  }, []);

  const renderRow = (row: TopTenant): ReactElement => (
    <>
      <TableCell>
        {row.contact
          ? `${row.contact.first_name} ${row.contact.last_name}`
          : EMPTY_DATA}
      </TableCell>
      <TableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.area}
        />
        {row.area !== null && Measurement_Units.SQUARE_METER}
      </TableCell>
      <TableCell align="right">
        {row.rent_start ? formatTimestamp(row.rent_start, TIME_FORMAT) : "---"}
      </TableCell>
      <TableCell align="right">
        {row.rent_end ? formatTimestamp(row.rent_end, TIME_FORMAT) : "---"}
      </TableCell>
      <TableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.net_rent_per_year}
        />
        {row.net_rent_per_year !== null && Measurement_Units.EURO}
      </TableCell>
      <TableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.rental_income}
        />
        {row.rental_income !== null && Measurement_Units.PERCENTAGE}
      </TableCell>
    </>
  );

  const TableToolbar = (
    <Grid container justifyContent="flex-start">
      <CardHeader
        title={t("property.objectDataSheet.top3Tenant")}
        sx={{ pt: 0, pl: 1 }}
      />
    </Grid>
  );

  if (isError) {
    return <ErrorBox formMessage={FormMessageErrorState} />;
  }

  return (
    <>
      {isSuccess ? (
        <StyledStack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          className={"general-information-wrap"}
        >
          {formMessage.text && (
            /* istanbul ignore next */ <FormAlert formMessage={formMessage} />
          )}
          <Grid container spacing={7.5} sx={{ py: 2 }}>
            <Grid item sm={12}>
              <FormWrapper $fullwidth>
                <Formik
                  initialValues={preparePropertyBeforeForm(property)}
                  validationSchema={Yup.object().shape({
                    supervisor_id,
                    expenses,
                    phone_number,
                    tax_number,
                    vat,
                    asset_managers,
                    email: optionalEmail,
                  })}
                  onSubmit={handleUpdateGeneralInformationFormSubmit({
                    setFormMessage,
                    setProperty,
                    setIsUploadingPropertyLogo,
                    onSuccess,
                  })}
                >
                  {(props): React.ReactElement => (
                    <Form
                      assetManagers={allAssetManagers}
                      caretakers={caretakers}
                      setFormMessage={setFormMessage}
                      isUploadingPropertyLogo={isUploadingPropertyLogo}
                      setIsUploadingPropertyLogo={setIsUploadingPropertyLogo}
                      onSuccess={onSuccess}
                      {...props}
                    />
                  )}
                </Formik>
              </FormWrapper>
            </Grid>
            <Grid item sm={12}>
              <FormWrapper $fullwidth>
                <Table
                  data={data}
                  total={total}
                  currentPage={currentPage}
                  order={order}
                  orderBy={orderBy}
                  error={error}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  onPageChange={handleChangePage}
                  onSortChange={handleSortChange}
                  onSelectAllClick={handleSelectAllClick}
                  onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
                  rowsPerPage={rowsPerPage}
                  isLoading={isLoading}
                  isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
                  listHeadCells={itemsListHeadCells}
                  renderRow={renderRow}
                  tableToolbar={TableToolbar}
                />
              </FormWrapper>
            </Grid>
          </Grid>
        </StyledStack>
      ) : (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      )}
    </>
  );
};

export default GeneralInformation;
