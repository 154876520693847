import { FormikValues } from "formik";
import { createRole } from "../../../../api/permissions";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../constants";
import { mapBackendValidationErrors } from "../../../../utils/form";
import {
  CreateRoleFormValues,
  HandleCreateRoleFormSubmitProps,
  PermissionCodes,
} from "../types";

/* istanbul ignore next */
export const objectFilter = (
  obj: PermissionCodes,
  predicate: (value: boolean) => boolean
): PermissionCodes =>
  Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce<PermissionCodes>((res, key) => ((res[key] = obj[key]), res), {});
/* istanbul ignore next */
export const handleCreateRoleFormSubmit = ({
  setFormMessage,
  postCreateCallback,
  permissionCodes,
}: HandleCreateRoleFormSubmitProps) => {
  return async (
    values: CreateRoleFormValues,
    { setStatus, setSubmitting, setValues, setTouched }: FormikValues
  ): Promise<void> => {
    const fd = new FormData();
    fd.append("name", values.name);
    Object.keys(permissionCodes).forEach((key) => {
      if (permissionCodes[key]) fd.append("permission_code[]", key);
    });

    const response = await createRole(fd);
    const json = await response.json();

    if ([200, 201].includes(response.status)) {
      setStatus({
        success: true,
        errors: {},
      });
      setValues(createRoleFormInitState);
      setTouched(touchedInitState);
      const { data } = json;
      postCreateCallback(data);
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
      setSubmitting(false);
    }
  };
};
/* istanbul ignore next */
export const createRoleFormInitState: CreateRoleFormValues = {
  name: "",
};
/* istanbul ignore next */
export const touchedInitState = {
  name: false,
};
/* istanbul ignore next */
export const langDict = {
  snackbar: {
    create: "roleWasSuccessfullyCreated",
    edit: "updated",
  },
  submitButton: {
    create: "createRole",
    edit: "update",
  },
};
