/* istanbul ignore file */

import React, { ReactElement } from "react";
import NetColdRent from "./NetColdRent";
import { Grid } from "@mui/material";
import ValuedFreeSpace from "./ValuedFreeSpace";
import FloorPlanSubdivision from "./FloorPlanSubdivision";
import AverageNetRentPerSquareMeter from "./AverageNetRentPerSquareMeter";
import CalculatedRemainingTerm from "./CalculatedRemainingTerm";
import SubdivisionOfRentedAndVacantSpace from "./SubdivsisionOfRentedAndVacantSpace";
import VacancyRate from "./VacancyRate";
import {
  extractAverageNetRentPerSquareMeterFromProperty,
  extractCalculatedRemainingTermFromProperty,
  extractFloorPlanSubdivisionFromProperty,
  extractNetColdRentFromProperty,
  extractSubdivisionOfRentedAndVacantSpaceFromProperty,
  extractVacancyRateFromProperty,
  extractValuedFreeSpaceFromProperty,
} from "./utils";
import { CalculationProps } from "./types";

const CalculationResult = ({ property }: CalculationProps): ReactElement => {
  return (
    <>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ px: 1 }}
      >
        {property && (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <FloorPlanSubdivision
                      {...extractFloorPlanSubdivisionFromProperty(property)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SubdivisionOfRentedAndVacantSpace
                      {...extractSubdivisionOfRentedAndVacantSpaceFromProperty(
                        property
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={6} xl={6}>
                    <NetColdRent
                      {...extractNetColdRentFromProperty(property)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} xl={6}>
                    <ValuedFreeSpace
                      {...extractValuedFreeSpaceFromProperty(property)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <AverageNetRentPerSquareMeter
                      {...extractAverageNetRentPerSquareMeterFromProperty(
                        property
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CalculatedRemainingTerm
                      {...extractCalculatedRemainingTermFromProperty(property)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <VacancyRate {...extractVacancyRateFromProperty(property)} />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default CalculationResult;
