import styled from "styled-components/macro";
import { Box, Paper, TextField as MuiTextField } from "@mui/material";
import { spacing } from "@mui/system";

/* istanbul ignore next */
export const FormWrapper = styled(Paper)`
  padding: ${(props): string => props.theme.spacing(6)};
  ${(props): string => props.theme.breakpoints.up("lg")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 80%;
  }
  ${(props): string => props.theme.breakpoints.up("xl")} {
    &.popup-wrapper {
      max-width: 90%;
    }
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 60%;
  }
  ${(props): string => props.theme.breakpoints.up("xs")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 100%;
  }
`;

export const LoadingBox = styled.div`
  width: 100%;
  height: 50vh;
`;

export const TextField = styled(MuiTextField)<{ mb?: number }>(spacing);

export const FormSectionTitle = styled(Box)`
  font-size: 18px;
  padding-bottom: 10px;
`;
