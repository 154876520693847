import { FormikValues } from "formik/dist/types";
import { mapBackendValidationErrors } from "../../../utils/form";
import { CreateUserFormInitState } from "./types";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { createUser } from "../../../api/users";
import { FormMessageInterface } from "../../../types/form";
import React from "react";
import { route } from "../../../utils/url";
import { NavigateFunction } from "react-router-dom";
import { formationDateForBackEnd } from "../../../utils/date";

export const createUserFormInitState: CreateUserFormInitState = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "+49",
  mobile_number: "+49",
  salutation_id: "",
  role_id: "",
  is_active: true,
  password: "",
  password_confirmation: "",
  birthday: null,
  company_id: "",
};

export const touchedInitState = {
  first_name: false,
  last_name: false,
  email: false,
  password: false,
  password_confirmation: false,
  phone_number: false,
  mobile_number: false,
  salutation: false,
  birthday: false,
};

export type FormSubmitHandlerProps = {
  setFormMessage: React.Dispatch<React.SetStateAction<FormMessageInterface>>;
  navigate: NavigateFunction;
  selectedInternalRole: boolean;
};

export const handleCreateUserFormSubmit = ({
  setFormMessage,
  navigate,
  selectedInternalRole,
}: FormSubmitHandlerProps) => {
  return async (
    values: CreateUserFormInitState,
    { setStatus, setSubmitting, setValues, setTouched }: FormikValues
  ): Promise<void> => {
    const response = await createUser({
      ...values,
      birthday: formationDateForBackEnd(values.birthday),
      company_id: !selectedInternalRole ? values.company_id : null,
    });
    const json = await response.json();
    if (response.status === 201) {
      setStatus({
        success: true,
        errors: {},
      });
      setValues(createUserFormInitState);
      setTouched(touchedInitState);
      navigate(route("users"));
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
      setSubmitting(false);
    }
  };
};
