import http from "../utils/http";
import { FileTreeFolder as FEFileTreeFolder } from "../types/fe.interfaces";

/* istanbul ignore next */
export const getFolders = async (params = ""): Promise<Response> => {
  return await http.get(`/api/folders${params}`);
};

export const getFolder = async (
  id?: string,
  params = ""
): Promise<Response> => {
  return await http.get(`/api/folders/${id}${params}`);
};
/* istanbul ignore next */
export const getFolderByPropertyId = async (
  id?: string,
  params = ""
): Promise<Response> => {
  return await http.get(`/api/properties/${id}/folders${params}`);
};

export const deleteFolder = async (id?: string): Promise<Response> => {
  return await http.delete(`/api/folders/${id}`);
};

export const updateFolder = async (
  data: Partial<FEFileTreeFolder>
): Promise<Response> => {
  return await http.put(`/api/folders/${data.id}`, data);
};

export const createFolder = async (
  data: Partial<FEFileTreeFolder>
): Promise<Response> => {
  return await http.post("/api/folders/", data);
};

export const moveFolder = async (
  id?: string,
  item_id?: string
): Promise<Response> => {
  return await http.patch(`/api/folders/${id}/move/${item_id}`, {});
};

export const updateFile = async (
  data: Partial<FEFileTreeFolder>,
  id_folder?: string
): Promise<Response> => {
  return await http.put(`/api/folders/${id_folder}/files/${data.id}`, data);
};

export const uploadFileToFolder = async (
  formData: FormData,
  id?: string
): Promise<Response> => {
  return await http.post(`/api/folders/${id}/files`, formData);
};

export const initFolderArchiving = async (
  id_folder: string
): Promise<Response> => {
  return await http.post(`/api/folders/export/zip/init/${id_folder}`, {});
};
