import * as React from "react";
import { withTheme } from "styled-components/macro";
import { NavbarProps } from "./types";
import { Menu as MenuIcon } from "@mui/icons-material";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";
import { Grid, Hidden, Toolbar } from "@mui/material";
import { AppBar, IconButton, ThemeSwitch } from "./styled";
import { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { BE_THEMES, THEMES } from "../../constants";
import NavbarSearch from "./NavbarSearch";
import NotificationBell from "../../ui/notification/notificationBell";
import { updateTheme } from "../../api/users";
import useAppSelector from "../../hooks/useAppSelector";

const Navbar: React.FC<NavbarProps> = ({
  onDrawerToggle,
}): React.ReactElement => {
  const { setTheme, theme } = useContext(ThemeContext);
  const { _user } = useAppSelector((state) => state.user);

  const handleThemeSwitch = async (): Promise<void> => {
    const newTheme = theme === THEMES.DARK ? THEMES.DEFAULT : THEMES.DARK;
    setTheme(newTheme);
    _user?.id &&
      (await updateTheme(
        _user?.id,
        newTheme === THEMES.DEFAULT ? BE_THEMES.LIGHT : BE_THEMES.DARK
      ));
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp smDown>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              <NavbarSearch />
            </Grid>
            <Grid item xs />
            <Grid item>
              <Grid container>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <ThemeSwitch
                    data-testid="theme-switch-toggle"
                    onClick={handleThemeSwitch}
                    checked={theme === THEMES.DARK}
                  />
                </Grid>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <NotificationBell />
                </Grid>
                <Grid item>
                  <NavbarLanguagesDropdown />
                </Grid>
                <Grid item>
                  <NavbarUserDropdown />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
