/* istanbul ignore file */

import React, { ReactElement } from "react";
import { AverageNetRentPerSquareMeterProps } from "./types";
import { CalculationTableHead, CalculationTableTitle } from "./styled";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import {
  THOUSAND_SEPARATOR,
  DECIMAL_SEPARATOR,
  DECIMAL_SCALE,
  Measurement_Units,
} from "../../../../../../constants";

const AverageNetRentPerSquareMeter = ({
  commercial_avg_per_month,
  living_avg_per_month,
}: AverageNetRentPerSquareMeterProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <CalculationTableHead>
        <CalculationTableTitle>
          {t("property.tenants.calculationResult.averageNetRentPerSquareMeter")}
        </CalculationTableTitle>
      </CalculationTableHead>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" size="small">
              {t("property.tenants.calculationResult.business")}
            </TableCell>
            <TableCell align="right" size="small">
              {t("property.tenants.calculationResult.living")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell align="left" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                decimalScale={DECIMAL_SCALE}
                fixedDecimalScale
                displayType={"text"}
                value={commercial_avg_per_month}
              />{" "}
              {Measurement_Units.EURO}/{Measurement_Units.SQUARE_METER}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                decimalScale={DECIMAL_SCALE}
                fixedDecimalScale
                displayType={"text"}
                value={living_avg_per_month}
              />{" "}
              {Measurement_Units.EURO}/{Measurement_Units.SQUARE_METER}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AverageNetRentPerSquareMeter;
