import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Measurement_Units } from "../../../constants";
import { AreaActiveTenantField } from "../../../types/fe/area";

export interface AreaState {
  areaTypeId: number;
  additionalFields: AreaActiveTenantField[];
  areaIsRentedOption: number;
}

export const additionalFieldsInitState: AreaActiveTenantField[] = [
  {
    name: "net_rent_per_month",
    visible: false,
    label: "property.area.netRentPerMonth",
    type: "number",
    editable: true,
  },
  {
    name: "rent_start",
    visible: false,
    label: "property.area.rentStart",
    type: "date",
    editable: true,
  },
  {
    name: "rent_end",
    visible: false,
    label: "property.area.rentEnd",
    type: "date",
    editable: true,
  },
  {
    name: "is_cancelled",
    visible: false,
    label: "property.area.isCancelled",
    type: "checkbox",
    editable: true,
  },
  {
    name: "cancellation_until",
    visible: false,
    label: "property.area.cancellationUntil",
    type: "date",
    editable: true,
  },
  {
    name: "closing_tenant_contract",
    visible: false,
    label: "property.area.closingTenantContract",
    type: "date",
    editable: true,
  },
  {
    name: "special_cancellation_right",
    visible: false,
    label: "property.area.specialCancellationRight",
    type: "date",
    editable: true,
    isDisablePast: true,
  },
  {
    name: "deposit",
    visible: false,
    label: "property.area.deposit",
    type: "number",
    editable: true,
    unit: Measurement_Units.EURO,
  },
  {
    name: "reminder",
    visible: false,
    label: "property.area.reminder",
    type: "checkbox",
    editable: true,
  },
  {
    name: "new",
    visible: false,
    label: "property.area.new",
    type: "checkbox",
    editable: true,
  },
  {
    name: "tenants",
    visible: false,
    label: "property.area.tenantName",
    type: "multi",
    editable: true,
  },
  {
    name: "options",
    visible: false,
    label: "property.area.options",
    type: "answer",
    editable: true,
  },
  {
    name: "remaining_term",
    visible: false,
    label: "property.area.remainingTerm",
    type: "text",
    editable: false,
  },
  {
    name: "remaining_term_in_eur",
    visible: false,
    label: "property.area.remainingTermInEur",
    type: "number",
    editable: false,
    unit: Measurement_Units.EURO,
  },
  {
    name: "net_rent_per_m2",
    visible: false,
    label: "property.area.netRentPerM2",
    type: "number",
    editable: false,
    unit: Measurement_Units.EURO,
  },
  {
    name: "total_net_rent_per_year",
    visible: false,
    label: "property.area.totalNetRentPerYear",
    type: "number",
    editable: false,
    unit: Measurement_Units.EURO,
  },
  {
    name: "total_net_rent_per_month",
    visible: false,
    label: "property.area.totalNetRentPerMonth",
    type: "number",
    editable: false,
    unit: Measurement_Units.EURO,
  },
  {
    name: "tax_in_eur",
    visible: false,
    label: "property.area.tax",
    type: "number",
    editable: true,
    unit: Measurement_Units.EURO,
  },
  {
    name: "total_gross_net_rent_per_month",
    visible: false,
    label: "property.area.totalGrossNetRentPerMonth",
    type: "number",
    editable: false,
    unit: Measurement_Units.EURO,
  },
  {
    name: "total_gross_net_rent_per_year",
    visible: false,
    label: "property.area.totalGrossNetRentPerYear",
    type: "number",
    editable: false,
    unit: Measurement_Units.EURO,
  },
  {
    name: "others_incomings_eur",
    visible: false,
    label: "property.area.othersIncomingsEur",
    type: "number",
    editable: true,
    unit: Measurement_Units.EURO,
  },
  {
    name: "empty_space",
    visible: false,
    label: "property.area.emptySpace",
    type: "number",
    editable: false,
    unit: Measurement_Units.SQUARE_METER,
  },
  {
    name: "empty_space_at_purchase",
    visible: false,
    label: "property.area.emptySpaceAtPurchase",
    type: "checkbox",
    editable: true,
  },
];

const initialState: AreaState = {
  additionalFields: additionalFieldsInitState,
  areaTypeId: 0,
  areaIsRentedOption: -1,
};

/* istanbul ignore next */
export const areaSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    setAreaTypeId: (state: AreaState, action: PayloadAction<number>): void => {
      state.areaTypeId = action.payload;
    },
    setAreaIsRentedOption: (
      state: AreaState,
      action: PayloadAction<number>
    ): void => {
      state.areaIsRentedOption = action.payload;
    },
    setAdditionalFields: (
      state: AreaState,
      action: PayloadAction<AreaActiveTenantField[]>
    ): void => {
      state.additionalFields = action.payload;
    },
  },
});

export const { setAdditionalFields, setAreaTypeId, setAreaIsRentedOption } =
  areaSlice.actions;

export default areaSlice.reducer;
