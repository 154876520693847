/* istanbul ignore file */

import { FormikValues } from "formik";
import { ForwardDocumentPayload, SubmitForwardDocumentProps } from "./types";
import { prepareQueryParams } from "../../../../utils/common";
import { forwardDocument } from "../../../../api/invoices";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { mapBackendValidationErrors } from "../../../../utils/form";

export const forwardDocumentInitialValues: ForwardDocumentPayload = {
  user_id: [],
  subject: "",
  contact_id: "",
  email: "",
  comment: "",
  is_add_comment: false,
};

export const handleForwardDocument = ({
  entityId,
  entityType,
  setSnackbarVisible,
  setFormMessage,
  handleCloseForwardModal,
}: SubmitForwardDocumentProps) => {
  return async (
    values: ForwardDocumentPayload,
    { setStatus }: FormikValues
  ): Promise<void> => {
    try {
      const userIdParams = values.user_id.reduce(
        (acc, cur) => `${acc}user_id[]=${cur}&`,
        ""
      );

      const params = prepareQueryParams(userIdParams, {
        subject: values.subject,
        "contact_id[]": values.contact_id,
        email: values.email,
        comment: values.comment,
        is_add_comment: String(+values.is_add_comment),
      });

      const response = await forwardDocument(entityId, entityType, params);

      if (response.status === 200) {
        setSnackbarVisible(true);
        setFormMessage(FormMessageInItState);
        setStatus({
          success: true,
          errors: {},
        });
        handleCloseForwardModal();
      } else if (response.status === 422) {
        const { errors } = await response.json();
        setStatus({
          success: false,
          errors: mapBackendValidationErrors(errors),
        });
      } else {
        setFormMessage({
          type: "error",
          text: SOMETHING_WENT_WRONG_ERROR,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};
