import styled from "styled-components/macro";

export const LabelText = styled.div`
  font-weight: 600;
  font-size: 11px;
  white-space: break-spaces;
  color: ${(props): string => props.theme.comments.textColor};
`;

export const IconWrapper = styled.div`
  height: 20px;
  position: relative;
  left: 5px;
  bottom: 2px;
`;
