import { MAX_REPEAT_DAY, MIN_REPEAT_DAY } from "../../../constants";

export default {
  uploadInvoice: "Rechnung hochladen",
  number: "Rechnungsnummer",
  name: "Dateiname",
  fileUpload: "Rechnung hochladen",
  provider: "Rechnungssteller",
  CreateInvoice: "Rechnung erstellen",
  editInvoice: "Rechnung bearbeiten",
  label: "Rechnung hochladen",
  invoiceAmount: "Rechnungsbetrag",
  releaseDate: "Freigabedatum",
  apportionable: "Umlagefähig",
  debitedAutomatically: "Lastschrift",
  date: "Rechnungsdatum",
  invalidDate: "Bitte gültiges Datum eingeben",
  apportionable_percent: "Umlageprozent",
  isDebitedAutomatically: "Wird automatisch abgebucht",
  isApportionable: "Umlagefähig",
  confidential: "Vertraulich",
  permanent: "Dauerrechnung",
  isConfidentialInvoice: "Vertrauliche Rechnung",
  isActiveInvoice: "Rechnung aktivieren",
  SelectOffer: "Wählen Sie Angebot aus",
  invoiceSaveSuccessfully: "Rechnung erfolgreich gespeichert",
  isPermanentInvoice: "Dauerrechnung",
  dynamicFileName: "Dynamischer Dateiname",
  forwardInvoice: "Rechnung weiterleiten",
  invoiceForwardSuccess: "Rechnung erfolgreich weitergeleitet",
  apportionablePercent: "Umlagefähig in %",
  currency: "Währung",
  error: {
    amountType: "Der Betrag sollte eine Zahl sein",
    fileId: "Rechnungsdatei ist erforderlich",
    contactId: "Kontakt ist erforderlich",
    amount: "Betrag ist erforderlich",
    until: "Bis Datum ist erforderlich",
    total: "Total ist erforderlich",
    monthly: "Monatlich ist erforderlich",
    contractId: "Vertrag ist erforderlich",
    offerId: "Angebot ist erforderlich",
    date: "Rechnungsdatum ist erforderlich",
    comment: "Kommentar erforderlich",
    pdfOnly: "Es sind nur PDF-Dateien erlaubt",
    userId: "Nutzer ist erforderlich",
    number: "Rechnungsnummer ist erforderlich",
    currency: "Währung ist erforderlich",
    currencyCode: "Währungscode ist erforderlich",
    dueDateMin: "Das Fälligkeitsdatum muss nach dem Rechnungsdatum liegen",
    invoiceDate: "Das Rechnungsdatum sollte nicht in der Zukunft liegen",
    apportionablePercent: "Es ist ein anteiliger Prozentsatz erforderlich",
    invoiceProvider: "Rechnungssteller ist erforderlich",
    invoiceAmountType: "Der Rechnungsbetrag sollte eine Zahl sein",
    invoiceAmount: "Rechnungsbetrag ist erforderlich",
    repeatFrom: "Wiederholen Sie ab ist erforderlich",
    repeatFromMinDay: "Wiederholung von kann nicht in der Vergangenheit sein",
    repeatTo: "Wiederholen Sie bis ist erforderlich",
    repeatToMinDay:
      "Wiederholen bis kann nicht grauer sein als Wiederholen von",
    repeatDay: "Wiederholen Sie den Tag",
    repeatDayMin: `Wiederholungstag Mindestwert sollte ${MIN_REPEAT_DAY} sein`,
    repeatDayMax: `Wiederholungstag Maximalwert kann nicht größer als ${MAX_REPEAT_DAY} sein`,
    repetitionPeriodId: "Wiederholungszeitraum ist erforderlich",
    pleaseConnectCreditorWithProvide:
      "Bitte verbinden Sie den Gläubiger mit dem Anbieter",
  },
  show: {
    show: "Rechnung anzeigen",
    propertyName: "Objektname",
    file: "Datei",
    total: "Insgesamt",
    monthly: "Monatlich",
    createdAt: "Erstellt am",
    createdBy: "Erstellt von",
    comment: "Kommentar",
    amount: "Betrag",
    contact: "Kontakt",
  },
  release: {
    title: "Mitteilungen",
    commentRequired: "Nachricht ist erforderlich",
  },
  markAsPaid: "Als bezahlt markieren",
  markAsBooked: "Als gebucht markieren",
  paid: "Bezahlt",
  unpaid: "unbezahlt",
  bankTransfer: "Überweisung",
  booked: "gebucht",
  notBooked: "nicht gebucht",
  all: "Alle",
  monthly: "Monatlich",
  halfYearly: "Halbjährlich",
  twoMonths: "Alle 2 Monate",
  quarterly: "Vierteljährlich",
  annual: "Jährlich",
  periodType: "Zeitintervall",
  releaseAllSelectedInvoices: "Ausgewählte Rechnung(en) Freigeben",
  reviewAllSelectedInvoices: "Ausgewählte Rechnung(en) Prüfen",
  rejectAllSelectedInvoices: "Ausgewählte Rechnung(en) Ablehnen",
  markAllSelectedInvoicesAsUnpaid:
    "Ausgewählte Rechnung(en) als (un)bezahlt markieren",
  notSelectedInvoice: "Bitte wählen Sie eine Rechnung",
  history: {
    title: "Verlauf",
    userName: "Nutzername",
    clickedButton: "Angeklickte Schaltfläche",
    fileName: "Dateiname",
    invoiceNumber: "Rechnungsnummer",
    dateOfUpdate: "Datum der Aktualisierung",
    currentInvoiceStatus: "Aktueller Rechnungsstatus",
    comment: "Kommentar",
  },
  notSelectedInvoices: "Bitte wählen Sie eine Rechnung",
  invoicesPerPage: "Rechnungen pro Seite",
  showContact: "Kontakt anzeigen",
  createAll: "Rechnungen Hinzufügen",
  delete: "Löschen",
  iban: "IBAN",
  bic: "BIC",
  directDebit: "Lastschrifteinzug",
  deleteConfirmation: "Möchten Sie die Rechnung wirklich löschen ?",
  searchProperty: "Objekt suchen",
  total: "Gesamtbetrag",
  until: "Rechung wiederholen bis zum",
  isAutomaticReleaseRepeatingInvoice:
    "Nach Erstfreigabe weitere Rechnungen automatisch freigeben",
  offer: "Angebot auswählen",
  apidatev: {
    creditor: "Creditor",
    debtor: "Debitor",
    debitAccount: "Soll-Konto",
    konto: "Konto",
    addresseeTypeCompany: "Name (Adressattyp Unternehmen)",
    addresseeType: "Adressattyp",
    abbreviation: "Kurzbezeichnung",
    addressType: "Adressart",
    street: "Straße",
    postalCode: "Postleitzahl",
    location: "Ort",
    codeCorrespondenceAddress: "Kennz. Korrespondenzadresse",
    addressValidFrom: "Adresse Gültig von",
    users: "User",
    date: "Datum",
    deleteAll: "Alles löschen",
    import: "Importieren",
    importCreditor: "Gläubiger importieren",
    importDebitor: "Importschuldner",
    importDebitAccount: "Soll-konto importieren",
    areYouSureYouWantToDeleteAllDebtor:
      "Möchten Sie wirklich alle Debitor löschen?",
    config: "Konfig",
    companyNumber: "Unternehmensnummer",
    companyNumberUpdatedSuccessfully:
      "Unternehmensnummer erfolgreich aktualisiert",
    companyNumberIsRequired: "Unternehmensnummer ist erforderlich",
  },
  repeatFrom: "Wiederholen Sie ab",
  repeatTo: "Wiederholen Sie bis",
  repeatDay: "Tag wiederholen",
  createInvoice: "Rechnung erstellen",
  dateVInvoice: {
    upload: "Laden Sie die DATEv-Rechnung hoch",
    dateOfPerformance: "Leistungsdatum",
    bookingText: "Buchungstext",
    creditor: "Kreditor/Rechnungssteller",
    postingItems: "Buchungspositionen",
    debitAccount: "Soll-Konto",
    vatIncl: "Ust. Inkl.",
    amountGross: "Betrag brutto",
    netAmount: "Betrag netto",
    invoiceAmountNet: "Rechnungsbetrag netto",
    grossInvoiceAmount: "Rechnungsbetrag brutto",
    biller: "Rechnungssteller",
    contactAssignedSuccessfully: "Kontakt erfolgreich zugewiesen",
  },
  noLogoAvailable: "Kein Logo verfügbar",
  providerInvoices: {
    all: "Alle",
    pending: "Pending",
    released: "Freigeben",
    finalReleased: "Endgültig freigegeben",
    rejected: "Ablehnen",
    month: "Monat",
    providerInvoices: "Provider invoices",
  },
  uploadOneInvoice: "Rechnung hochladen",
};
