import React, { ReactElement, useCallback, useEffect, useState } from "react";
import useTable from "../../../../../ui/table/useTable";
import Table from "../../../../../ui/table/Table";
import { DebitAccountProps } from "../../../../../types/be.interfaces";
import { handleServerError } from "../../../../../utils/http";
import { HTTP_STATUS_CODES } from "../../../../../types/server";
import {
  prepareQueryParams,
  replaceNullOrUndefinedByEmptyString,
} from "../../../../../utils/common";
import {
  EMPTY_DATA,
  HALF_TABLE_MIN_WIDTH,
  PAGE_LIMIT,
  STRING_LENGTH,
} from "../../../../../constants";
import { TableCell } from "../../../styled";
import { itemsListHeadCells } from "./utils";
import { formatTimestamp } from "../../../../../utils/date";
import { Box, Grid, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  DeleteIcon,
  Input,
  Search,
  SearchIconWrapper,
} from "../../../../../ui/table/styled";
import { Search as SearchIcon } from "react-feather";
import {
  deleteAllDebitAccount,
  deleteDebitAccount,
  getDebitAccount,
} from "../../../../../api/sollconto";
import { StyledButton } from "../../../../../ui/button/styled";
import { useNavigate, useParams } from "react-router-dom";
import { FETabs, TABS } from "../utils";
import DeleteConfirmationModal from "../../../../../ui/table/DeleteConfirmationModal";
import { FileName } from "../../list/styled";
import UploadManuallyDialog from "../Upload";
import { route } from "../../../../../utils/url";
import HZScrollController from "../../../../../ui/HZScrollController";

const DebitAccount = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isConfirmToDeleteModalOpen, setIsConfirmToDeleteModalOpen] =
    useState(false);
  const [deleteAllDebitAccountError, setDeleteAllDebitAccountError] =
    useState("");
  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleDeleteLastPageData,
  } = useTable<DebitAccountProps>();

  const fetchDebitAccount = useCallback(async () => {
    setIsLoading(true);
    const param = prepareQueryParams(
      queryParams,
      replaceNullOrUndefinedByEmptyString({
        property_id: id,
        limit: String(PAGE_LIMIT._10),
      })
    );
    const response = await getDebitAccount(param);
    if (response.status !== HTTP_STATUS_CODES.OK) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const resJson = await response.json();
      setTotal(resJson?.meta?.total);
      setData(resJson.data);
    }
    setIsLoading(false);
  }, [queryParams, setIsLoading, setTotal, setData, id]);

  useEffect(() => {
    fetchDebitAccount();
  }, [queryParams, id]);

  const onConfirmToRemoveAllModalClose = (): void => {
    setIsConfirmToDeleteModalOpen(false);
  };
  const onConfirmToRemoveAllModalOpen = (): void => {
    setIsConfirmToDeleteModalOpen(true);
  };

  const handleRemoveAllDebitAccount = useCallback(async (): Promise<void> => {
    const response = await deleteAllDebitAccount();
    if (response.status === HTTP_STATUS_CODES.OK) {
      await fetchDebitAccount();
      onConfirmToRemoveAllModalClose();
    } else {
      const { errorMessage } = handleServerError(response);
      setDeleteAllDebitAccountError(errorMessage);
    }
  }, [onConfirmToRemoveAllModalClose]);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    const response = await deleteDebitAccount({ id: rowToDelete.id });
    if (response.status === HTTP_STATUS_CODES.OK) {
      handleDeleteLastPageData(fetchDebitAccount);
      handleConfirmToRemoveModalClose();
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }
  }, [rowToDelete, fetchDebitAccount, handleConfirmToRemoveModalClose]);

  const onUploadSuccess = (): void => {
    setUploadModalVisible(false);
    fetchDebitAccount();
  };

  const renderRow = (row: DebitAccountProps): ReactElement => (
    <>
      <TableCell align="left">{row.konto ? row.konto : EMPTY_DATA}</TableCell>
      <TableCell align="left">
        {row?.account_caption &&
        row?.account_caption.length >= STRING_LENGTH ? (
          <Grid container>
            <Grid item>
              <Tooltip title={row?.account_caption} placement="top">
                <FileName>{row?.account_caption}</FileName>
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          row.account_caption ?? EMPTY_DATA
        )}
      </TableCell>
      <TableCell align="left">
        {row.language_id ? row.language_id : EMPTY_DATA}
      </TableCell>
      <TableCell align="left">
        {row?.account_label_long &&
        row?.account_label_long.length >= STRING_LENGTH ? (
          <Grid container>
            <Grid item>
              <Tooltip title={row?.account_label_long} placement="top">
                <FileName>{row?.account_label_long}</FileName>
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          row.account_label_long ?? EMPTY_DATA
        )}
      </TableCell>
      <TableCell align="left">
        {row.user ? `${row.user.first_name} ${row.user.last_name}` : EMPTY_DATA}
      </TableCell>
      <TableCell align="center">
        {row?.created_at ? formatTimestamp(row?.created_at) : EMPTY_DATA}
      </TableCell>

      <TableCell align="right">
        <DeleteIcon
          onClick={(): void => openConfirmToRemoveModal(row)}
          size={22}
          role="deleteIconRole"
        />
      </TableCell>
    </>
  );

  const TableToolbar = (
    <Grid container sx={{ pt: 5, mb: 2 }}>
      <Grid
        item
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
        flexWrap={"wrap"}
      >
        <Grid item gap={2} display={"flex"} alignItems={"center"}>
          <StyledButton
            color="success"
            variant="contained"
            onClick={() =>
              navigate(
                route(
                  `properties.invoices.apidatev.tab.create`,
                  id,
                  TABS.DEBITACCOUNT
                )
              )
            }
          >
            {t("create")}
          </StyledButton>
          <StyledButton
            color="success"
            variant="outlined"
            onClick={() => setUploadModalVisible(true)}
          >
            {t("documentRelease.invoice.apidatev.import")}
          </StyledButton>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems={"center"} gap={2}>
            <StyledButton
              color="error"
              variant="contained"
              onClick={onConfirmToRemoveAllModalOpen}
              sx={{ whiteSpace: "nowrap", minWidth: "auto", height: "32px" }}
            >
              {t("documentRelease.invoice.apidatev.deleteAll")}
            </StyledButton>
            <HZScrollController />
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t("search")} onChange={handleTableSearch} />
            </Search>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onRowRemove={handleRowRemove}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        noDataIsAvailablePlaceholder={"table.noDebitAccountAvailable"}
        minWidth={HALF_TABLE_MIN_WIDTH}
        refId="hzScroll"
      />
      <DeleteConfirmationModal
        isConfirmToRemoveModalOpen={isConfirmToDeleteModalOpen}
        handleConfirmToRemoveModalClose={onConfirmToRemoveAllModalClose}
        onRowRemove={handleRemoveAllDebitAccount}
        confirmationText={t(
          "documentRelease.sollkonto.debitAccountDeleteAllMessage"
        )}
        deletingError={deleteAllDebitAccountError}
      />

      {uploadModalVisible ? (
        <UploadManuallyDialog
          onSuccess={onUploadSuccess}
          visible={uploadModalVisible}
          toggleModel={setUploadModalVisible}
          title={t("documentRelease.invoice.apidatev.importDebitAccount")}
          type={FETabs.SOLLKONTO}
        />
      ) : null}
    </>
  );
};

export default DebitAccount;
