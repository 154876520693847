/* istanbul ignore file */

import React, {
  HTMLAttributes,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import TextField from "../../../../ui/formsFields/text";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { MenuItem, Grid, Typography, useTheme, Paper } from "@mui/material";
import Snackbar from "../../../../ui/Snackbar";
import { Form, FormikValues } from "formik";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  AutocompleteListItem,
  ChartWrapper,
  DoughnutInner,
  EditableSection,
  EditSectionButton,
  HeaderText,
  LocationText,
  EmptyEditableSection,
  LabelText,
} from "./styled";
import debounce from "lodash/debounce";
import { PropertyType } from "../../../../types/be/property";
import {
  CAROUSEL_MAX_FILE_LIMIT,
  EMPTY_DATA,
  Measurement_Units,
  PermissionsUpdate,
  SEARCH_PARAM_MIN_CHARACTERS,
  VIDEO_FILE_TYPE,
} from "../../../../constants";
import { getPlaceDetails, getPlacePredictions } from "../../../../api/places";
import { Dropdown } from "../../../../ui/autocomplete/styled";
import {
  Location,
  PlaceDetails,
  PlaceDetailsResponse,
  Prediction,
} from "../../../../types/be/place";
import Loader from "../../../Loader";
import { getJson } from "../../../../utils/http";
import { REACT_APP_GOOGLE_API_KEY } from "../../../../config";
import GoogleMapReact, { Maps } from "google-map-react";
import PinMarker from "../../../../ui/map/marker/default";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import {
  DEFAULT_MAP_LAT,
  DEFAULT_MAP_LNG,
  DEFAULT_MAP_ZOOM,
} from "../../../../ui/map/utils";
import { useFetch } from "../../../../hooks/useFetch";
import { getPropertyTypes } from "../../../../api/propertyTypes";
import {
  mapAddressComponentsToPlaceAddress,
  placeErrorText,
} from "../../utils";
import camelCase from "lodash/camelCase";
import { EDITABLE_FIELDS_TYPE } from "./utils";
import { isNodeEnv } from "../../../../utils/env";
import {
  findFiveDigitZipCode,
  prepareQueryParams,
} from "../../../../utils/common";
import { useParams } from "react-router-dom";
import { updatePropertyWithFormData } from "../../../../api/property";
import useSnackbar from "../../../../ui/snackbar1/useSnackbar";
import Carousel from "../../../../ui/carousel";
import { Doughnut } from "react-chartjs-2";
import { red } from "@mui/material/colors";
import EditableField from "./EditableField";
import useAccessControl from "../../../../hooks/useAccessControl";
import Button from "../../../../ui/button/Button";
import Uploader from "./FileUploader";
function CreatePropertyForm({
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  values,
  status,
  setFieldValue,
  getPropertyData,
  images,
  videos,
}: any & FormikValues): ReactElement {
  const { data: propertyTypes, run: runPropertyTypes } =
    useFetch<PropertyType[]>();
  const [loading, setLoading] = useState(false);
  const [placePredictions, setPlacePredictions] = useState<
    readonly Prediction[]
  >([]);
  const { t } = useTranslation();
  const { id = "" } = useParams();
  const { Snackbar: SnackbarMsg, snackbar } = useSnackbar();
  const { can } = useAccessControl();

  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [zoom, setZoom] = useState<number>(8);
  const [, setPlaceDetails] = useState<PlaceDetails | null>(null);
  const [placeLocation, setPlaceLocation] = useState<Location>();
  const [isPlaceValidationError, setIsPlaceValidationError] = useState(false);
  const [placeValidationErrorText, setPlaceValidationErrorText] = useState("");
  const [placeInputValue, setPlaceInputValue] = useState("");
  const [editMainSection, setEditMainSection] = useState(false);
  const [editMoreFieldSection, setEditMoreFieldSection] = useState(false);
  const [editNoteSection, setEditNoteSection] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0.01);
  const [placeAutocompleteFieldValue, setPlaceAutocompleteFieldValue] =
    useState("");
  const theme = useTheme();
  const editableDefaultProps = {
    formikProps: {
      values,
      status,
      setFieldValue,
      handleBlur,
      handleChange,
    },
  };
  useEffect(() => {
    const { location } = values;
    setPlaceLocation({ lat: location.lat, lng: location.lng });
  }, [values]);

  useEffect(() => {
    runPropertyTypes(getPropertyTypes());
    setPlaceInputValue(values?.location.full_address);
  }, []);

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  useEffect(() => {
    const str = placeErrorText(errors.location);
    setIsPlaceValidationError(!!str.length);
    setPlaceValidationErrorText(
      t(`google.place.autocomplete.${camelCase(str)}`)
    );
  }, [errors.location]);

  useEffect(() => {
    const str = placeErrorText(errors.location);
    setIsPlaceValidationError(!!str.length);
    setPlaceValidationErrorText(
      t(`google.place.autocomplete.${camelCase(str)}`)
    );
  }, [errors]);

  useEffect(() => {
    const newZipCode = findFiveDigitZipCode(placeAutocompleteFieldValue);
    newZipCode && setFieldValue("location.zip_code", newZipCode);
    newZipCode &&
      setFieldValue("location.full_address", placeAutocompleteFieldValue);
  }, [placeAutocompleteFieldValue]);

  const onLocationSearchKeyDown = React.useCallback(
    debounce(async (search: string): Promise<void> => {
      /* istanbul ignore next */
      if (search.length < SEARCH_PARAM_MIN_CHARACTERS) setPlacePredictions([]);
      else {
        setLoading(true);
        const params = prepareQueryParams("", {
          input: search,
        });
        getPlacePredictions(params)
          .then(getJson)
          .then(({ data: { predictions } }) => {
            setPlacePredictions(predictions);
            setLoading(false);
          })
          .catch(() => {
            setPlacePredictions([]);
          });
      }
    }, 300),
    []
  );
  /* istanbul ignore next */
  const getMapOptions = (maps: Maps): any => {
    return {
      fullscreenControl: true,
      mapTypeId: maps.MapTypeId.HYBRID,
      scaleControl: true,
      scrollwheel: false,
    };
  };

  const handlePlacePredictionSelect = ({ place_id }: Prediction): void => {
    const params = prepareQueryParams("", {
      place_id,
      language: "en",
    });
    getPlaceDetails(params)
      .then(getJson)
      .then(({ data: { result } }: PlaceDetailsResponse) => {
        setPlaceDetails(result);
        setPlaceLocation(result.geometry.location);
        setZoom(15);
        const { geometry } = result;
        const { address_components } = result;
        const { place_id } = result;
        const mapResult =
          mapAddressComponentsToPlaceAddress(address_components);
        setPlaceInputValue(result.formatted_address);
        setFieldValue("location", {
          full_address: result.formatted_address!,
          city: mapResult.city || "",
          country_code: mapResult.country_code!,
          state_code: mapResult.state_code || "",
          state: mapResult.state || "",
          house_number: mapResult.house_number || "",
          zip_code: mapResult.zip_code || "",
          street: mapResult.street || "",
          place_id: place_id!,
          lat: geometry.location.lat!,
          lng: geometry.location.lng!,
        });
      });
  };

  /* istanbul ignore next */
  const handleAutocompleteOnChange = (
    _: SyntheticEvent,
    value: Prediction | null
  ): void => {
    if (value) handlePlacePredictionSelect(value);
    else {
      setPlaceDetails(null);
    }
  };

  const handleSnackbarClose = (): void => setIsSnackbarVisible(false);

  const validatePlaceLocation = (
    placeLocation: Location | undefined
  ): boolean => !!(placeLocation && placeLocation.lat && placeLocation.lng);

  const handleRenderInput = (
    params: AutocompleteRenderInputParams
  ): ReactNode => (
    <TextField
      {...params}
      label={t("form.propertyLocation")}
      size="small"
      onChange={(e) => {
        setPlaceInputValue(e.target.value);
        onLocationSearchKeyDown(e.target.value);
        setPlaceAutocompleteFieldValue(e.target.value);
      }}
      placeholder={t("google.place.autocomplete.placeholder")}
      value={values.place_id}
      onBlur={handleBlur}
      error={isPlaceValidationError}
      helperText={isPlaceValidationError && placeValidationErrorText}
      status={status}
    />
  );

  const handleOnFileChange = async (filesToUpload: File[]): Promise<void> => {
    if (filesToUpload.length) {
      const formData = new FormData();
      for (let i = 0; filesToUpload.length > i; i++) {
        if (filesToUpload[i].type === "video/mp4") {
          formData.append(`videos[]`, filesToUpload[i]);
        } else {
          formData.append(`images[]`, filesToUpload[i]);
        }
      }

      formData.append("purchase_price", values.purchase_price);
      formData.append(`_method`, "PUT");

      updatePropertyWithFormData(id, formData, (event) => {
        const percentage = (event.loaded * 100) / event.total;
        setUploadProgress(percentage);
      })
        .then(() => {
          snackbar.success(t("tenantRequest.uploaded"));
          getPropertyData();
        })
        .catch((err) => snackbar.error(err.toString()));
    }
  };

  const handleOnFileDelete = (filesToUpload: File[]): void => {
    setFieldValue("images", [...filesToUpload]);
  };

  const doughnutDataForCommercial = {
    labels: ["Commercial rented", "Commercial not rented"],
    datasets: [
      {
        data: [
          values.commercial_rented_area_percentage || 0,
          values.commercial_area_percentage || 0,
        ],
        backgroundColor: [theme.palette.secondary.main, red[500]],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const doughnutDataForLiving = {
    labels: ["Living rented", "Living  not rented"],
    datasets: [
      {
        data: [
          values.living_rented_area_percentage || 0,
          values.living_area_percentage || 0,
        ],
        backgroundColor: [theme.palette.secondary.main, red[500]],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: "80%",
  };

  const propertyTypeName = useMemo(() => {
    return propertyTypes?.find(
      (type) => String(type.id) === String(values.property_type_id)
    );
  }, [values.property_type_id, propertyTypes]);

  const uploadedFiles = useMemo(() => {
    const imageFiles = images || values.images;
    const videosFiles = videos || values.videos;
    return [...imageFiles, ...videosFiles];
  }, [values.images, images]);

  const resetEditableField = (): void => {
    setEditMainSection(false);
    setEditNoteSection(false);
    setEditMoreFieldSection(false);
  };

  useEffect(() => {
    if (!isSubmitting && Object.keys(errors).length === 0) {
      resetEditableField();
    }
  }, [isSubmitting]);

  return (
    <>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <EmptyEditableSection />
        <EditableSection sx={{ p: 2, mb: 4 }}>
          <Grid container sx={{ mb: 3, mt: 2 }} justifyItems="center">
            <Grid
              item
              xs={12}
              sm={6}
              alignItems="center"
              sx={{ display: "flex" }}
            >
              {!editMainSection ? (
                <Carousel
                  images={uploadedFiles}
                  defaultImagePath="/static/img/files/propertyDefault.png"
                />
              ) : (
                <Uploader
                  accept={["image", VIDEO_FILE_TYPE]}
                  isDisabled={isSubmitting}
                  onFileChange={handleOnFileChange}
                  onFileDelete={handleOnFileDelete}
                  refreshData={getPropertyData}
                  filesInitState={uploadedFiles}
                  uploadDisabled={
                    isSubmitting ||
                    images.length + videos.length >= CAROUSEL_MAX_FILE_LIMIT
                  }
                  placeHolderText={t("property.carouselImageUploadPlaceHolder")}
                  uploadButtonText={t("property.carouselFilesUpload")}
                  progressPercentage={uploadProgress}
                  snackbar={snackbar}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} p={4}>
              <Grid container justifyItems={"space-between"} height="inherit">
                {!editMainSection ? (
                  <>
                    <Grid item xs={12}>
                      <HeaderText>{values.object_name}</HeaderText>
                      <LocationText>{`${placeInputValue} | ${
                        propertyTypeName?.name || ""
                      }`}</LocationText>
                    </Grid>
                  </>
                ) : (
                  <>
                    <EditableField
                      {...{
                        ...editableDefaultProps,
                        name: "object_name",
                        label: "property.objectName",
                        isEdit: editMainSection,
                        type: EDITABLE_FIELDS_TYPE.TEXT,
                        gridSize: 12,
                        dataTestId: "object-name",
                        value: values.object_name,
                      }}
                    ></EditableField>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{ mb: 3 }}
                      padding={2}
                    >
                      <Autocomplete
                        fullWidth
                        loading={loading}
                        loadingText={`${t("loading")}...`}
                        inputValue={placeInputValue ?? ""}
                        popupIcon={
                          loading ? <Loader size={25} /> : <ArrowDropDownIcon />
                        }
                        options={placePredictions!}
                        isOptionEqualToValue={handleIsOptionEqualToValue}
                        getOptionLabel={handleGetOptionLabel}
                        filterOptions={handleFilterOptions}
                        onChange={handleAutocompleteOnChange}
                        renderOption={handleRenderOption}
                        PaperComponent={autocompletePaperComponent}
                        renderInput={handleRenderInput}
                      />
                    </Grid>
                    {propertyTypes?.length ? (
                      <Grid item xs={12} p={2}>
                        <TextField
                          id="property_type_id"
                          name="property_type_id"
                          select
                          required
                          size={"small"}
                          label={t("property.propertyType")}
                          value={values.property_type_id}
                          onChange={handleChange("property_type_id")}
                          data-testid="property-type"
                          fullWidth
                          status={status}
                        >
                          {propertyTypes?.map((propertyType) => (
                            <MenuItem
                              key={`${propertyType.name}-${propertyType.id}`}
                              value={propertyType.id}
                            >
                              {propertyType.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    ) : null}
                  </>
                )}

                <Grid item xs={12} mt={10}>
                  <Grid container>
                    <EditableField
                      {...{
                        ...editableDefaultProps,
                        name: "purchase_price",
                        label: "property.purchasePrice",
                        isEdit: editMainSection,
                        type: EDITABLE_FIELDS_TYPE.FORMAT_NUMBER,
                        gridSize: 5,
                        dataTestId: "purchase-price",
                        unit: Measurement_Units.EURO,
                        value: values.purchase_price,
                      }}
                    ></EditableField>
                    <EditableField
                      {...{
                        ...editableDefaultProps,
                        name: "land_area",
                        label: "property.fullArea",
                        isEdit: editMainSection,
                        type: EDITABLE_FIELDS_TYPE.FORMAT_NUMBER,
                        gridSize: 4,
                        dataTestId: "full-area",
                        unit: Measurement_Units.SQUARE_METER,
                        value: values.land_area,
                      }}
                    ></EditableField>
                    <EditableField
                      {...{
                        ...editableDefaultProps,
                        name: "parking_lots",
                        label: "property.parkingLots",
                        isEdit: editMainSection,
                        type: EDITABLE_FIELDS_TYPE.NUMBER,
                        gridSize: 3,
                        dataTestId: "parking-lots",
                        value: values.parking_lots,
                      }}
                    ></EditableField>
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={10}>
                  <Grid container>
                    <EditableField
                      {...{
                        ...editableDefaultProps,
                        name: "net_rent_actual",
                        label: "property.netRentActual",
                        isEdit: editMainSection,
                        type: EDITABLE_FIELDS_TYPE.FORMAT_NUMBER,
                        gridSize: 5,
                        dataTestId: "net-rent-actual",
                        unit: Measurement_Units.EURO,
                        value: values.net_rent_actual,
                      }}
                    ></EditableField>
                    <EditableField
                      {...{
                        ...editableDefaultProps,
                        name: "year_of_construction",
                        label: "property.yearOfConstruction",
                        isEdit: editMainSection,
                        type: EDITABLE_FIELDS_TYPE.DATE,
                        gridSize: 4,
                        dataTestId: "year-of-construction",
                        value: values.year_of_construction,
                      }}
                    ></EditableField>
                    <EditableField
                      {...{
                        ...editableDefaultProps,
                        name: "taxes",
                        label: "property.taxes",
                        isEdit: editMainSection,
                        type: EDITABLE_FIELDS_TYPE.NUMBER,
                        gridSize: 3,
                        dataTestId: "taxes",
                        unit: Measurement_Units.PERCENTAGE,
                        value: values.taxes,
                      }}
                    ></EditableField>
                  </Grid>
                </Grid>
                {editMainSection ? (
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent={"flex-end"}
                    gap={4}
                  >
                    <Button
                      title={t("cancel")}
                      variant="text"
                      color="primary"
                      size="large"
                      onClick={() => setEditMainSection(!editMainSection)}
                    />
                    <Button
                      title={t("save")}
                      color="success"
                      type="submit"
                      size="large"
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          {can([PermissionsUpdate.PROPERTY]) &&
            (!editMainSection ? (
              <EditSectionButton
                onClick={() => setEditMainSection(!editMainSection)}
              ></EditSectionButton>
            ) : null)}
        </EditableSection>
        <Paper sx={{ p: 2, mb: 4 }}>
          <Grid container sx={{ mb: 3, mt: 2 }}>
            <Grid item xs={12} height={200}>
              {!isNodeEnv("test") && (
                /* istanbul ignore next */
                <GoogleMapReact
                  options={getMapOptions}
                  bootstrapURLKeys={{ key: REACT_APP_GOOGLE_API_KEY! }}
                  defaultCenter={{ lat: DEFAULT_MAP_LAT, lng: DEFAULT_MAP_LNG }}
                  defaultZoom={DEFAULT_MAP_ZOOM}
                  center={
                    validatePlaceLocation(placeLocation)
                      ? placeLocation
                      : {
                          lat: DEFAULT_MAP_LAT,
                          lng: DEFAULT_MAP_LNG,
                        }
                  }
                  zoom={zoom}
                >
                  {
                    /* istanbul ignore next */ placeLocation &&
                      placeLocation.lat &&
                      placeLocation.lng && (
                        <PinMarker
                          lat={placeLocation.lat}
                          lng={placeLocation.lng}
                        />
                      )
                  }
                </GoogleMapReact>
              )}
            </Grid>
          </Grid>
        </Paper>
        <Grid container>
          <Grid item xs={12} sm={6} pr={2}>
            <Paper sx={{ p: 2, mb: 4 }}>
              <HeaderText>{t("property.commertialType")}</HeaderText>
              <ChartWrapper>
                <DoughnutInner>
                  <Typography variant="caption">
                    {t("property.rented")}
                  </Typography>
                  <Typography variant="h4">
                    {values.commercial_rented_area_percentage}%
                  </Typography>
                </DoughnutInner>
                <Doughnut data={doughnutDataForCommercial} options={options} />
              </ChartWrapper>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} pl={2}>
            <Paper sx={{ p: 2, mb: 4 }}>
              <HeaderText>{t("property.livingType")}</HeaderText>
              <ChartWrapper>
                <DoughnutInner>
                  <Typography variant="caption">
                    {t("property.rented")}
                  </Typography>
                  <Typography variant="h4">
                    {values.living_rented_area_percentage}%
                  </Typography>
                </DoughnutInner>
                <Doughnut data={doughnutDataForLiving} options={options} />
              </ChartWrapper>
            </Paper>
          </Grid>
        </Grid>
        <EditableSection sx={{ p: 4, mb: 4 }}>
          <Grid container justifyItems={"space-between"} height="inherit">
            <Grid item xs={12} mt={4}>
              <Grid container>
                <EditableField
                  {...{
                    ...editableDefaultProps,
                    name: "broker_price",
                    label: "property.brokerPrice",
                    isEdit: editMoreFieldSection,
                    type: EDITABLE_FIELDS_TYPE.FORMAT_NUMBER,
                    gridSize: 6,
                    dataTestId: "broker-price",
                    unit: Measurement_Units.EURO,
                    value: values.broker_price,
                  }}
                ></EditableField>
                <EditableField
                  {...{
                    ...editableDefaultProps,
                    name: "maintenance_non_reversible",
                    label: "property.maintenanceNonReversible",
                    isEdit: editMoreFieldSection,
                    type: EDITABLE_FIELDS_TYPE.FORMAT_NUMBER,
                    gridSize: 6,
                    dataTestId: "maintenance-non-reversible",
                    unit: Measurement_Units.EURO,
                    value: values.maintenance_non_reversible,
                  }}
                ></EditableField>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={4}>
              <Grid container>
                <EditableField
                  {...{
                    ...editableDefaultProps,
                    name: "standard_ground_value",
                    label: "property.standardGroundValue",
                    isEdit: editMoreFieldSection,
                    type: EDITABLE_FIELDS_TYPE.FORMAT_NUMBER,
                    gridSize: 6,
                    dataTestId: "standard-ground-value",
                    unit: Measurement_Units.SQUARE_METER,
                    value: values.standard_ground_value,
                  }}
                ></EditableField>
                <EditableField
                  {...{
                    ...editableDefaultProps,
                    name: "operating_costs_non_reversible",
                    label: "property.operatingCostsNonReversible",
                    isEdit: editMoreFieldSection,
                    type: EDITABLE_FIELDS_TYPE.NUMBER,
                    gridSize: 6,
                    dataTestId: "operating-costs-non-reversible",
                    unit: Measurement_Units.PERCENTAGE,
                    value: values.operating_costs_non_reversible,
                  }}
                ></EditableField>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={4} mb={4}>
              <Grid container>
                <EditableField
                  {...{
                    ...editableDefaultProps,
                    name: "net_rent_target",
                    label: "property.netRentTarget",
                    isEdit: editMoreFieldSection,
                    type: EDITABLE_FIELDS_TYPE.NUMBER,
                    gridSize: 6,
                    dataTestId: "net-rent-target",
                    unit: Measurement_Units.PERCENTAGE,
                    value: values.net_rent_target,
                  }}
                ></EditableField>
                <EditableField
                  {...{
                    ...editableDefaultProps,
                    name: "non_circulating_property_management",
                    label: "property.nonCirculatingPropertyManagement",
                    isEdit: editMoreFieldSection,
                    type: EDITABLE_FIELDS_TYPE.NUMBER,
                    gridSize: 6,
                    dataTestId: "net-rent-target",
                    unit: Measurement_Units.PERCENTAGE,
                    value: values.non_circulating_property_management,
                  }}
                ></EditableField>
              </Grid>
            </Grid>
            {editMoreFieldSection ? (
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent={"flex-end"}
                gap={4}
              >
                <Button
                  title={t("cancel")}
                  variant="text"
                  color="primary"
                  size="large"
                  onClick={() => setEditMoreFieldSection(!editMoreFieldSection)}
                />
                <Button
                  title={t("save")}
                  color="success"
                  type="submit"
                  size="large"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                />
              </Grid>
            ) : null}
          </Grid>
          {can([PermissionsUpdate.PROPERTY]) &&
            (!editMoreFieldSection ? (
              <EditSectionButton
                onClick={() => setEditMoreFieldSection(!editMoreFieldSection)}
              ></EditSectionButton>
            ) : null)}
        </EditableSection>
        <EditableSection sx={{ p: 4, mb: 4 }}>
          <Grid container mt={4} mb={4}>
            {editNoteSection ? (
              <EditableField
                {...{
                  ...editableDefaultProps,
                  name: "notes",
                  label: "property.notes",
                  isEdit: editNoteSection,
                  type: EDITABLE_FIELDS_TYPE.MULTILINE,
                  gridSize: 12,
                  dataTestId: "notes",
                  value: values.notes,
                }}
              ></EditableField>
            ) : (
              <Grid item xs={12}>
                <LabelText>{t("property.notes")}</LabelText>
                <HeaderText>{values.notes || EMPTY_DATA}</HeaderText>
              </Grid>
            )}
            {editNoteSection ? (
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent={"flex-end"}
                gap={4}
              >
                <Button
                  title={t("cancel")}
                  variant="text"
                  color="primary"
                  size="large"
                  onClick={() => setEditNoteSection(!editNoteSection)}
                />
                <Button
                  title={t("save")}
                  color="success"
                  type="submit"
                  size="large"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                />
              </Grid>
            ) : null}
          </Grid>
          {can([PermissionsUpdate.PROPERTY]) &&
            (!editNoteSection ? (
              <EditSectionButton
                onClick={() => setEditNoteSection(!editNoteSection)}
              ></EditSectionButton>
            ) : null)}
        </EditableSection>
      </Form>
      {SnackbarMsg}
      <Snackbar
        open={isSnackbarVisible}
        message={t("property.propertyWasSuccessfullyUpdated")}
        color="success"
        handleClose={handleSnackbarClose}
      />
    </>
  );
}

const handleRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  placePrediction: Prediction
): ReactNode => (
  <AutocompleteListItem {...props} key={placePrediction.description}>
    - {placePrediction.description}
  </AutocompleteListItem>
);
/* istanbul ignore next */
const handleIsOptionEqualToValue = (
  option: Prediction,
  value: Prediction
): boolean => option.place_id === value.place_id;

const handleGetOptionLabel = (placePrediction: Prediction): string =>
  placePrediction.description;

const handleFilterOptions = (options: Prediction[]): Prediction[] => options;

const autocompletePaperComponent = ({
  children,
}: {
  children?: ReactNode;
}): ReactElement | null => <Dropdown>{children}</Dropdown>;

export default CreatePropertyForm;
