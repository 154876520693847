import http from "../utils/http";
import { TenantRequestStatusType } from "../types/be/tenantRequest";
import { CreateMobileUser } from "../components/properties/areas/edit/tabs/MobileUsers/CreateMobileUserModal/types";

/* istanbul ignore next */
export const getTenantRequests = async (params = ""): Promise<Response> => {
  return await http.get(`/api/tenant-requests${params}`);
};
/* istanbul ignore next */
export const getOutdatedTenantRequests = async (
  params = ""
): Promise<Response> => {
  return await http.get(`/api/tenant-requests/outdated${params}`);
};
/* istanbul ignore next */
export const getTenantRequest = async (id: number): Promise<Response> => {
  return await http.get(`/api/tenant-requests/${id}`);
};
/* istanbul ignore next */
export const deleteTenantRequest = async (id: number): Promise<Response> => {
  return await http.delete(`/api/tenant-requests/${id}`);
};
/* istanbul ignore next */
export const createTenantRequest = async (
  data: FormData
): Promise<Response> => {
  return await http.post(`/api/tenant-requests`, data);
};
/* istanbul ignore next */
export const updateTenantRequest = async (
  id: string,
  data: FormData
): Promise<Response> => {
  return await http.post(`/api/tenant-requests/${id}`, data);
};
/* istanbul ignore next */
export const updateTenantRequestStatus = async (
  id: number,
  status: TenantRequestStatusType
): Promise<Response> => {
  return await http.patch(
    `/api/tenant-requests/${id}/status?code=${status}`,
    {}
  );
};

/* istanbul ignore next */
export const getTenantRequestHistory = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/tenant-requests/history${params}`);
};

/* istanbul ignore next */
export const inviteTenantMobileUser = async (
  data: CreateMobileUser
): Promise<Response> => {
  return await http.post("/api/property-tenants/invite-tenant", data);
};
/* istanbul ignore next */
export const getChats = async (
  id: number,
  params: string
): Promise<Response> => {
  return await http.get(`/api/tenant-requests/${id}/chats${params}`);
};
/* istanbul ignore next */
export const createChats = async (
  id: number,
  message: string
): Promise<Response> => {
  return await http.post(`/api/tenant-requests/${id}/chats`, {
    body: message,
  });
};
/* istanbul ignore next */
export const deleteChat = async (
  id: number,
  chatId: number
): Promise<Response> => {
  return await http.delete(`/api/tenant-requests/${id}/chats/${chatId}`);
};
/* istanbul ignore next */
export const readChat = async (
  id: number,
  chatId: number
): Promise<Response> => {
  return await http.patch(`/api/tenant-requests/${id}/chats/${chatId}`, {});
};
