import React from "react";
import VacationRequests from "../../components/vacationRequests";
import AccessGuard from "../../components/guards/AccessGuard";
import { PermissionsRead } from "../../constants";

export default {
  path: "vacation-requests/:tab",
  element: (
    <AccessGuard permissions={[PermissionsRead.VACATION_REQUEST]}>
      <VacationRequests />
    </AccessGuard>
  ),
};
