import React, { createContext, ReactElement, useMemo } from "react";
import { FileManagementContextType } from "../types";
import { useCallback, useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import {
  deleteFolder,
  getFolder,
  getFolderByPropertyId,
  getFolders,
  initFolderArchiving,
  uploadFileToFolder,
} from "../../../api/folders";
import { FileTreeFolder } from "../../../types/be.interfaces";
import head from "lodash/head";
import {
  composeNodeId,
  extractItemDataFromNodeId,
} from "../../properties/components/fileTree/utils";
import {
  FileManagementItem,
  FileManagementMode,
  HandleExpandedTypes,
  PageViewTypes,
} from ".././types";
import {
  findFolderById,
  handleOpenContextMenu,
  handleServerResponse,
  initialContextMenuProperties,
  mutateFileTree,
  mutateFolder,
  prepareData,
  removeDuplicatesFromFolders,
} from ".././utils";
import { HTTP_STATUS_CODES } from "../../../types/server";
import useAppSelector from "../../../hooks/useAppSelector";
import {
  setGlobalSearchFileLastFolderId,
  setGlobalSearchFilePath,
  setGlobalSearchSelectedFolder,
} from "../../../redux/slices/globalSearch";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { addArchive } from "../../../redux/slices/archive/archive";
import { handleServerError } from "../../../utils/http";
import { isNodeEnv } from "../../../utils/env";
import { NODE_ENVIRONMENTS } from "../../../constants";
import { deleteFile } from "../../../api/files";
import { useParams } from "react-router-dom";
import { prepareQueryParams } from "../../../utils/common";

/* istanbul ignore next */
const FileManagementContext = createContext<FileManagementContextType>(
  undefined!
);

/* istanbul ignore next */
function FileManagementProvider({
  children,
}: {
  children: ReactElement;
}): React.ReactElement {
  const dispatchStore = useAppDispatch();
  const { path, selectedFolder, fileLastFolderId } = useAppSelector(
    (state) => state.globalSearch
  );
  const { id } = useParams();
  const [selected, setSelected] = useState("");
  const [expanded, setExpanded] = useState<string[]>([]);
  const [contextMenuProperties, setContextMenuProperties] = useState(
    initialContextMenuProperties
  );
  const [actionItemId, setActionItemId] = useState<string | null>(null);
  const [actionItemType, setActionItemType] =
    useState<FileManagementItem>("folder");
  const [actionItemName, setActionItemName] = useState("");
  const [actionItemUrl, setActionItemUrl] = useState<string | null>(null);
  const [disabledDelete, setDisabledDelete] = useState(false);
  const [hasRelation, setHasRelation] = useState(false);
  const [isConfirmToRemoveModalOpen, setIsConfirmToRemoveModalOpen] =
    useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [mode, setMode] = useState<FileManagementMode>("delete");
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState(() => false);
  const [contextMenuClickedOnItem, setContextMenuClickedOnItem] =
    useState(false);
  const [isDropzoneVisible, setIsDropzoneVisible] = useState(false);
  const [isDropzoneLoading, setIsDropzoneLoading] = useState(false);
  const [fileRejected, setFileRejected] = useState(false);
  const {
    data: folder,
    setState: setFolder,
    run: runFolder,
    isLoading: isFolderLoading,
  } = useFetch<FileTreeFolder>();
  const [layout, setLayout] = useState(
    /* istanbul ignore next */ isNodeEnv(NODE_ENVIRONMENTS.TEST)
      ? PageViewTypes.ICONS
      : PageViewTypes.LIST
  );
  const handleEndConcert = (): void => {
    dispatchStore(setGlobalSearchFilePath([]));
    dispatchStore(setGlobalSearchSelectedFolder(""));
    dispatchStore(setGlobalSearchFileLastFolderId(""));
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleEndConcert);
    return () => {
      handleEndConcert();
    };
  }, []);

  const {
    data: folders,
    run: runFolders,
    isLoading: isFoldersLoading,
    setState: setFolders,
  } = useFetch<FileTreeFolder[]>();
  useEffect(() => {
    setIsSnackbarVisible(isSuccess);
  }, [isSuccess]);
  useEffect(() => {
    const params = prepareQueryParams("", {
      sort: "name",
      order: "asc",
    });
    runFolders(!id ? getFolders(params) : getFolderByPropertyId(id, params));
  }, [id]);
  useEffect(() => {
    setActionItemId(head(extractItemDataFromNodeId(selected))!);
  }, [selected]);

  useEffect(() => {
    if (!folders) return;

    const data = prepareData(folders, id);
    setFolder((prevState) => ({ ...prevState, data }));
  }, [folders]);

  const runFolderSortedByDate = async (folderId?: string): Promise<void> => {
    const params = prepareQueryParams("", {
      sort: "updated_at",
      order: "desc",
    });
    runFolder(getFolder(folderId, params));
  };

  const onClose = useCallback(() => {
    handleCloseContextMenu();
    setDisabledDelete(false);
    mode !== "move" && setActionItemId(null);
  }, [setContextMenuProperties, setActionItemId, setDisabledDelete, mode]);

  const handleCloseContextMenu = (): void => {
    setContextMenuProperties(initialContextMenuProperties);
  };
  const handleCloseSnackbar = (): void => setIsSuccess(false);
  const handleCloseDropzone = (): void => setIsDropzoneVisible(false);

  const onRightClick = useCallback(
    (
        id: string,
        name: string,
        itemType: FileManagementItem,
        url: string | null,
        canRemove,
        hasRelation = false
      ) =>
      (event: React.MouseEvent<Element, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
        setContextMenuClickedOnItem(true);
        !canRemove && setDisabledDelete(true);
        setHasRelation(hasRelation);
        setActionItemType(itemType);
        setActionItemId(id);
        setActionItemName(name);
        setActionItemUrl(url);
        handleOpenContextMenu(event, setContextMenuProperties);
      },
    [setContextMenuProperties, setDisabledDelete]
  );
  /* istanbul ignore next */
  const getFolderByNodeId = (nodeId: string): FileTreeFolder | undefined => {
    const id = Number(head(extractItemDataFromNodeId(nodeId)));
    return findFolderById(folders || [], id);
  };
  /* istanbul ignore next */
  const isChildOf = (childNodeId: string, parentId: string): boolean => {
    const folder = getFolderByNodeId(childNodeId);
    if (!folder) return false;

    const { parent_id = 0 } = folder;
    return parent_id === +parentId;
  };
  /* istanbul ignore next */
  const handleExpanded = (id: string, mode: HandleExpandedTypes): void => {
    const nodeId = composeNodeId(id);
    setExpanded((expanded) => {
      switch (mode) {
        case HandleExpandedTypes.INCLUDE_CURRENT:
          return [...expanded, nodeId];
        case HandleExpandedTypes.EXCLUDE_CURRENT_AND_CHILDREN:
          return [
            ...expanded.filter((st) => !isChildOf(st, id) && st !== nodeId),
          ];
        case HandleExpandedTypes.EXCLUDE_CHILDREN:
          return [...expanded.filter((st) => !isChildOf(st, id))];
      }
    });
  };
  /* istanbul ignore next */
  const handleFolderClick = async (
    folderId: string,
    isIconClicked: string
  ): Promise<void> => {
    const iconClicked = Boolean(+isIconClicked);
    const nodeId = composeNodeId(folderId);
    setSelected(nodeId);

    if (expanded.includes(nodeId)) {
      const preventTreeSnapping = folder?.id !== +folderId;
      if (
        isNodeEnv(NODE_ENVIRONMENTS.TEST) &&
        (iconClicked || !preventTreeSnapping)
      ) {
        return handleExpanded(
          folderId,
          HandleExpandedTypes.EXCLUDE_CURRENT_AND_CHILDREN
        );
      }

      if (iconClicked) {
        return handleExpanded(
          folderId,
          HandleExpandedTypes.EXCLUDE_CURRENT_AND_CHILDREN
        );
      }

      if (!preventTreeSnapping) {
        const parentId = folder?.parent_id;
        const rootFolderId = id ? folders?.[0].id : null;

        if (parentId && +parentId !== rootFolderId) {
          runFolderSortedByDate(parentId);
        } else {
          const data = folders ? prepareData(folders, id) : null;
          setFolder((prevState) => ({ ...prevState, data }));
        }

        return handleExpanded(
          folderId,
          HandleExpandedTypes.EXCLUDE_CURRENT_AND_CHILDREN
        );
      }

      handleExpanded(folderId, HandleExpandedTypes.EXCLUDE_CHILDREN);

      return runFolderSortedByDate(folderId);
    }
    handleExpanded(folderId, HandleExpandedTypes.INCLUDE_CURRENT);

    runFolderSortedByDate(folderId);
  };

  const handleDoubleClick = useCallback(
    (id: string) => () => {
      runFolderSortedByDate(id);
      const nodeId = composeNodeId(id);
      setSelected(nodeId);
      setExpanded((expanded) => [...expanded, nodeId]);
    },
    [setSelected, setExpanded, expanded]
  );

  const handleConfirmToRemoveModalClose = (): void => {
    setActionItemId(null);
    setIsConfirmToRemoveModalOpen(false);
    onClose();
  };

  const handleItemRemove = useCallback(async (): Promise<void> => {
    const [id] = extractItemDataFromNodeId(selected);
    const response =
      actionItemType === "folder"
        ? await deleteFolder(actionItemId!)
        : await deleteFile(actionItemId!);
    await handleServerResponse(response, setIsSuccess);

    await runFolderSortedByDate(id);
    handleConfirmToRemoveModalClose();
  }, [
    runFolderSortedByDate,
    handleConfirmToRemoveModalClose,
    handleServerResponse,
  ]);

  const handleContextMenuDeleteClick = (): void => {
    setMode("delete");
    setIsConfirmToRemoveModalOpen(true);
    handleCloseContextMenu();
  };

  const handleContextMenuRenameClick = (): void => {
    setMode("rename");
    setDialogVisible(true);
    handleCloseContextMenu();
  };

  const handleContextMenuCreateFolderClick = (): void => {
    setActionItemName("");
    setMode("create");
    setDialogVisible(true);
    handleCloseContextMenu();
  };

  const handleContextMenuDownloadClick = async (): Promise<void> => {
    handleCloseContextMenu();

    if (actionItemType === "file") {
      setMode("download");
      actionItemUrl && window.open(actionItemUrl, "_blank");
      setIsSuccess(true);
    } else {
      const response = await initFolderArchiving(actionItemId!);
      if (response.status === HTTP_STATUS_CODES.CREATED) {
        const {
          data: { id },
        } = await response.json();
        const archive = { id, name: actionItemName };
        dispatchStore(addArchive(archive));
      } else {
        const { errorMessage } = handleServerError(response);
        /* istanbul ignore next */
        if (!isNodeEnv(NODE_ENVIRONMENTS.TEST)) console.log(errorMessage);
      }
    }
  };

  const handleCloseDialog =
    (refresh = false) =>
    async () => {
      const [id] = extractItemDataFromNodeId(selected);

      refresh && (await runFolderSortedByDate(id));
      setDialogVisible(false);
      setActionItemName("");
      setActionItemId(null);
      setDisabledDelete(false);
      setHasRelation(false);
    };

  const handleContextMenuMoveToClick = (): void => {
    setMode("move");
    setDialogVisible(true);
  };

  const handlePageContextMenu = (
    event: React.MouseEvent<Element, MouseEvent>
  ): void => {
    event.preventDefault();
    setActionItemId(null);
    setContextMenuClickedOnItem(false);
    selected && handleOpenContextMenu(event, setContextMenuProperties);
  };

  const handleFile = useCallback(
    async <T extends File>(file: FileList | T[] | null): Promise<void> => {
      setMode("upload");
      if (!file?.length) {
        setFileRejected(true);
        setIsSuccess(true);
        handleCloseDropzone();
      } else {
        setFileRejected(false);
        setIsDropzoneLoading(true);
        const data = new FormData();
        data.append("file", file![0]);
        onClose();
        const [id] = extractItemDataFromNodeId(selected);
        const response = await uploadFileToFolder(data, id);
        await handleServerResponse(
          response,
          setIsSuccess,
          HTTP_STATUS_CODES.CREATED
        );
        setIsDropzoneLoading(false);
        handleCloseDropzone();

        await runFolderSortedByDate(id);
      }
    },
    [selected]
  );

  const handleContextMenuUploadFileClick = (): void => {
    setMode("upload");
    setIsDropzoneVisible(true);
    handleCloseContextMenu();
    document?.getElementById("root")?.scrollTo(0, 0);
  };

  const showErrorSnackbar = fileRejected && mode === "upload";

  const { contextMenuTop, contextMenuLeft, contextMenuVisibility } =
    contextMenuProperties;

  const memoedFolder = useMemo((): FileTreeFolder | null => {
    if (!folder) return null;

    const { children, files } = folder;
    return {
      ...folder,
      children: removeDuplicatesFromFolders(children),
      files: removeDuplicatesFromFolders(files),
    };
  }, [folder]);

  const cachedFolders = useMemo(() => {
    const id = memoedFolder?.id;
    if (id && folders) {
      const obj = findFolderById(folders, +id);
      obj &&
        mutateFolder({
          folder: obj,
          children: memoedFolder?.children,
          files: memoedFolder?.files,
        });
    }

    return folders;
  }, [folders, memoedFolder, selected]);

  useEffect(() => {
    const handleAsync = async (): Promise<void> => {
      if (!path || !cachedFolders) return;
      await mutateFileTree(path, cachedFolders);
      setExpanded(path);
    };
    handleAsync();

    selectedFolder && setSelected(selectedFolder);

    fileLastFolderId && runFolderSortedByDate(fileLastFolderId);
  }, [path, selectedFolder, fileLastFolderId, cachedFolders]);

  const fileManagementContext: FileManagementContextType = useMemo(
    () => ({
      runFolder,
      setFolders,
      isFolderLoading,
      runFolderSortedByDate,
      layout,
      setLayout,
      expanded,
      setExpanded,
      selected,
      setIsSuccess,
      setSelected,
      isFoldersLoading,
      cachedFolders,
      actionItemId,
      setActionItemId,
      actionItemType,
      setActionItemType,
      setActionItemUrl,
      memoedFolder,
      isDropzoneVisible,
      isDropzoneLoading,
      setIsDropzoneVisible,
      contextMenuTop,
      contextMenuLeft,
      contextMenuVisibility,
      isConfirmToRemoveModalOpen,
      dialogVisible,
      setDialogVisible,
      handleCloseDialog,
      handleConfirmToRemoveModalClose,
      onClose,
      contextMenuClickedOnItem,
      disabledDelete,
      hasRelation,
      mode,
      actionItemName,
      setActionItemName,
      isSnackbarVisible,
      showErrorSnackbar,
      handleCloseSnackbar,
      handleCloseDropzone,
      handlePageContextMenu,
      handleFolderClick,
      onRightClick,
      handleDoubleClick,
      handleFile,
      handleContextMenuDeleteClick,
      handleContextMenuRenameClick,
      handleContextMenuMoveToClick,
      handleContextMenuCreateFolderClick,
      handleContextMenuDownloadClick,
      handleContextMenuUploadFileClick,
      handleItemRemove,
    }),
    [
      runFolder,
      setFolders,
      isFolderLoading,
      layout,
      runFolderSortedByDate,
      setLayout,
      expanded,
      setExpanded,
      selected,
      setIsSuccess,
      setSelected,
      isFoldersLoading,
      cachedFolders,
      actionItemId,
      setActionItemId,
      actionItemType,
      setActionItemType,
      setActionItemUrl,
      memoedFolder,
      isDropzoneVisible,
      isDropzoneLoading,
      setIsDropzoneVisible,
      contextMenuTop,
      contextMenuLeft,
      contextMenuVisibility,
      isConfirmToRemoveModalOpen,
      dialogVisible,
      setDialogVisible,
      handleCloseDialog,
      handleConfirmToRemoveModalClose,
      onClose,
      contextMenuClickedOnItem,
      disabledDelete,
      hasRelation,
      mode,
      actionItemName,
      setActionItemName,
      isSnackbarVisible,
      showErrorSnackbar,
      handleCloseSnackbar,
      handleCloseDropzone,
      handlePageContextMenu,
      handleFolderClick,
      onRightClick,
      handleDoubleClick,
      handleFile,
      handleContextMenuDeleteClick,
      handleContextMenuRenameClick,
      handleContextMenuMoveToClick,
      handleContextMenuCreateFolderClick,
      handleContextMenuDownloadClick,
      handleContextMenuUploadFileClick,
      handleItemRemove,
    ]
  );

  return (
    <FileManagementContext.Provider value={fileManagementContext}>
      {children}
    </FileManagementContext.Provider>
  );
}

export { FileManagementProvider, FileManagementContext };
