import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  DESCRIPTION_MAX_CHARACTERS,
  FIRST_NAME_MAX_CHARACTERS,
  FIRST_NAME_MIN_CHARACTERS,
  IBAN_CHARACTERS_NUMBER,
  LAST_NAME_MAX_CHARACTERS,
  LAST_NAME_MIN_CHARACTERS,
  MAX_RELEASE_INPUT,
  MAX_REPEAT_DAY,
  MIME_TYPES,
  MIN_REPEAT_DAY,
  MOBILE_MAX_CHARACTERS,
  MOBILE_MIN_CHARACTERS,
  NOTES_MAX_CHARACTERS,
  OBJECT_NAME_MAX_CHARACTERS,
  OBJECT_NAME_MIN_CHARACTERS,
  PASSWORD_MAX_CHARACTERS,
  PASSWORD_MIN_CHARACTERS,
  PERCENTAGE_MAX_VALUE,
  PERCENTAGE_MIN_VALUE,
  PURCHASE_PRICE_MAX_CHARACTERS,
  PURCHASE_PRICE_MIN_CHARACTERS,
  ZIP_CODE_LENGTH,
} from "../constants";
import { parseNumber } from "../utils/common";
import { AnyObject, StringSchema, MixedOptions } from "yup";
import { BKA } from "../types/be/tenant";
import moment from "moment";
/* istanbul ignore next */
const useValidation = (): any => {
  const { t } = useTranslation();
  const passwordMinCharactersErrorMessage = `${t(
    "passwordMustBeAtLeast"
  )} ${PASSWORD_MIN_CHARACTERS} ${t("characters")}`;

  const oneOfPhoneNumberIsRequired = `${t(
    "createContact.oneOfPhoneNumberIsRequired"
  )}`;

  const phoneNumberOrMobileNumberIsRequired = `${t(
    "phoneNumberOrMobileNumberIsRequired"
  )}`;

  const compareFormattedFields =
    (valueFrom: string) =>
    (
      valueTo: string | undefined,
      testContext: Yup.TestContext<AnyObject>
    ): boolean => {
      return valueTo
        ? parseNumber(valueTo) >= parseNumber(testContext.parent[valueFrom])
        : true;
    };

  const checkTenantType = (message: string): Yup.StringSchema => {
    return Yup.string().when("tenant_type_id", {
      is: (value: number) => value === 1 || value === 2,
      then: () => Yup.string().required(t(message)),
    });
  };

  const validateBKAYear = (
    bka: BKA[]
  ): StringSchema<string | null | undefined, AnyObject> =>
    Yup.string()
      .required(t("form.pleaseSelectYear"))
      .nullable()
      .notOneOf(
        bka?.map((item) => item?.year?.toString()),
        t("form.duplicateYearIsNotAllowed")
      );

  return {
    first_name: Yup.string()
      .max(FIRST_NAME_MAX_CHARACTERS, t("form.firstNameMaxCharacters"))
      .min(FIRST_NAME_MIN_CHARACTERS, t("form.firstNameMinCharacters"))
      .required(t("form.firstNameIsRequired")),
    last_name: Yup.string()
      .max(LAST_NAME_MAX_CHARACTERS, t("form.lastNameMaxCharacters"))
      .min(LAST_NAME_MIN_CHARACTERS, t("form.lastNameMinCharacters"))
      .required(t("form.lastNameIsRequired")),
    email: Yup.string()
      .required(t("form.emailIsRequired"))
      .email(t("form.mustBeValidEmail")),
    entered_value: Yup.boolean(),
    phone_number: Yup.string()
      .test(
        "oneOfRequired",
        phoneNumberOrMobileNumberIsRequired,
        (item, testContext) => {
          return (
            testContext.parent.phone_number || testContext.parent.mobile_number
          );
        }
      )
      .when({
        is: (value: string) => value && value.length !== 0,
        then: () =>
          Yup.string()
            .matches(/^[+]*[-\s.0-9]*$/i, t("wrongPhoneNumberFormat"))
            .min(MOBILE_MIN_CHARACTERS, t("form.phoneNumberError"))
            .max(MOBILE_MAX_CHARACTERS, t("form.phoneNumberMaxError")),
      }),
    mobile_number: Yup.string()
      .test(
        "oneOfRequired",
        phoneNumberOrMobileNumberIsRequired,
        (item, testContext) => {
          return (
            testContext.parent.phone_number || testContext.parent.mobile_number
          );
        }
      )
      .when({
        is: (value: string) => value && value.length !== 0,
        then: () =>
          Yup.string()
            .matches(/^[+]*[-\s.0-9]*$/i, t("wrongPhoneNumberFormat"))
            .min(MOBILE_MIN_CHARACTERS, t("form.mobileNumberError"))
            .max(MOBILE_MAX_CHARACTERS, t("form.mobileNumberMaxError")),
      }),
    business_phone_only: Yup.string().required(
      t("createContact.businessPhoneOnly")
    ),
    business_phone: Yup.string().test(
      "oneOfRequired",
      oneOfPhoneNumberIsRequired,
      (item, testContext) => {
        return (
          testContext.parent.mobile ||
          testContext.parent.private_phone ||
          testContext.parent.business_phone
        );
      }
    ),
    mobile: Yup.string().test(
      "oneOfRequired",
      oneOfPhoneNumberIsRequired,
      (item, testContext) => {
        return (
          testContext.parent.mobile ||
          testContext.parent.private_phone ||
          testContext.parent.business_phone
        );
      }
    ),
    private_phone: Yup.string().test(
      "oneOfRequired",
      oneOfPhoneNumberIsRequired,
      (item, testContext) => {
        return (
          testContext.parent.mobile ||
          testContext.parent.private_phone ||
          testContext.parent.business_phone
        );
      }
    ),
    salutation_id: Yup.string().required(t("form.salutationIsRequired")),
    role: Yup.string().required(t("form.roleIsRequired")),
    contact_type: Yup.string().required(t("form.contactTypeIsRequired")),
    password: {
      required: Yup.string()
        .max(PASSWORD_MAX_CHARACTERS)
        .min(PASSWORD_MIN_CHARACTERS, passwordMinCharactersErrorMessage)
        .required(t("form.passwordIsRequired")),
      optional: Yup.string()
        .max(PASSWORD_MAX_CHARACTERS)
        .min(PASSWORD_MIN_CHARACTERS, passwordMinCharactersErrorMessage),
    },
    password_confirmation: {
      required: Yup.string()
        .max(PASSWORD_MAX_CHARACTERS)
        .min(PASSWORD_MIN_CHARACTERS, passwordMinCharactersErrorMessage)
        .required(t("form.passwordConfirmationIsRequired"))
        .oneOf([Yup.ref("password")])
        .nullable(),
      optional: Yup.string()
        .max(PASSWORD_MAX_CHARACTERS)
        .min(PASSWORD_MIN_CHARACTERS, passwordMinCharactersErrorMessage)
        .oneOf([Yup.ref("password")], t("form.passwordsMustMatch"))
        .nullable(),
    },
    profile: {
      old_password: Yup.string().when(["password", "password_confirmation"], {
        is: (password: string, password_confirmation: string) =>
          !!password || !!password_confirmation,
        then: () => Yup.string().required(t("form.oldPasswordIsRequired")),
        otherwise: () => Yup.string(),
      }),
      password: Yup.string().when(["password_confirmation", "old_password"], {
        is: (password_confirmation: string, old_password: string) =>
          !!password_confirmation || !!old_password,
        then: () =>
          Yup.string()
            .max(PASSWORD_MAX_CHARACTERS)
            .min(PASSWORD_MIN_CHARACTERS, passwordMinCharactersErrorMessage)
            .required(t("form.passwordIsRequired")),
        otherwise: () =>
          Yup.string()
            .max(PASSWORD_MAX_CHARACTERS)
            .min(PASSWORD_MIN_CHARACTERS, passwordMinCharactersErrorMessage),
      }),
      password_confirmation: Yup.string().when(["password", "old_password"], {
        is: (password: string, old_password: string) =>
          !!password || !!old_password,
        then: () =>
          Yup.string()
            .max(PASSWORD_MAX_CHARACTERS)
            .min(PASSWORD_MIN_CHARACTERS, passwordMinCharactersErrorMessage)
            .required(t("form.passwordConfirmationIsRequired"))
            .oneOf([Yup.ref("password")], t("form.passwordsMustMatch"))
            .nullable(),
        otherwise: () =>
          Yup.string()
            .max(PASSWORD_MAX_CHARACTERS)
            .min(PASSWORD_MIN_CHARACTERS, passwordMinCharactersErrorMessage)
            .oneOf([Yup.ref("password")], t("form.passwordsMustMatch"))
            .nullable(),
      }),
    },
    purchase_price: Yup.string()
      .max(PURCHASE_PRICE_MAX_CHARACTERS, t("form.purchasePriceMaxCharacters"))
      .min(PURCHASE_PRICE_MIN_CHARACTERS, t("form.purchasePriceMinCharacters"))
      .required(t("form.purchasePriceIsRequired")),
    company_name: Yup.string().required(t("form.companyNameIsRequired")),
    company_id: {
      required: Yup.string().required(t("form.companyNameIsRequired")),
      optional: Yup.string().optional().nullable(),
    },
    company: {
      required: Yup.object()
        .required(t("form.companyNameIsRequired"))
        .nullable(),
      optional: Yup.object().optional().nullable(),
    },
    notes: Yup.string().max(NOTES_MAX_CHARACTERS, t("form.notesMaxCharacters")),
    object_name: Yup.string()
      .max(OBJECT_NAME_MAX_CHARACTERS, t("form.objectNameMaxCharacters"))
      .min(OBJECT_NAME_MIN_CHARACTERS, t("form.objectNameMinCharacters"))
      .required(t("form.objectNameIsRequired")),
    place_id: Yup.string().required(t("form.placeIdIsRequired")),
    country_code: Yup.string().required(t("form.countryIsRequired")),
    state_code: Yup.string().required(t("form.stateIsRequired")),
    company_type_id: Yup.string()
      .required(t("form.companyTypeIsRequired"))
      .nullable(),
    city: Yup.string().required(t("form.cityIsRequired")).nullable(),

    zip_code: Yup.string()
      .required(t("form.zipCodeIsRequired"))
      .min(ZIP_CODE_LENGTH, t("form.zipcodeLengthError"))
      .max(ZIP_CODE_LENGTH, t("form.zipcodeLengthError")),
    street: Yup.string().required(t("form.streetIsRequired")),
    house_number: Yup.string().required(t("form.houseNumberIsRequired")),
    country: Yup.string().required(t("form.countryIsRequired")),
    state: Yup.string().required(t("form.stateIsRequired")).nullable(),

    location: Yup.object().shape({
      // city: Yup.string().required(t("form.cityIsRequired")),
      // zip_code: Yup.string().required(t("form.zipCodeIsRequired")),
      // street: Yup.string().required(t("form.streetIsRequired")),
      // house_number: Yup.string().required(t("form.houseNumberIsRequired")),
      country_code: Yup.string().required(t("form.countryIsRequired")),
      //state_code: Yup.string().required(t("form.stateIsRequired")).nullable(),
    }),

    finance_volume: Yup.string().required(t("form.financeVolumeIsRequired")),
    finance_volume_from_value: Yup.string().required(
      t("form.financeVolumeFromValueIsRequired")
    ),
    finance_volume_to_value: Yup.string().required(
      t("form.financeVolumeToValueIsRequired")
    ),
    circle: Yup.string().required(t("form.circleIsRequired")),
    ltv: Yup.string().required(t("form.ltvIsRequired")),
    avg_interest_rate: Yup.number()
      .typeError(t("form.typeNumberOnly"))
      .required(t("form.avgInterestRateIsRequired")),
    avg_amortisation: Yup.string().required(t("form.avgAmortisation")),
    avg_repayment: Yup.string().required(t("form.avgRepaymentIsRequired")),
    state_id: Yup.string().required(t("form.stateIsRequired")),
    property_type_id: Yup.string().required(t("form.propertyTypeIsRequired")),
    contactIsRequired: Yup.string().required(t("form.contactIsRequired")),
    companyCreate: {
      title: Yup.string().required(t("form.titleIsRequired")),
      city: Yup.string().required(t("form.cityIsRequired")),
    },
    supervisor_id: Yup.number(),
    expenses: Yup.string(),
    tax_number: Yup.number()
      .typeError(t("typeError"))
      .required(t("form.taxNumberIsRequired")),
    vat: Yup.string().required(t("form.vatIsRequired")),
    architect: Yup.string().required(t("form.architectIsRequired")),
    searchProfileTitle: Yup.string()
      .max(NOTES_MAX_CHARACTERS)
      .required(t("form.titleIsRequired")),
    source: Yup.string().max(NOTES_MAX_CHARACTERS),
    searchProfileZipCode: Yup.string()
      .min(ZIP_CODE_LENGTH, t("searchProfile.searchProfileZipCode"))
      .max(ZIP_CODE_LENGTH, t("searchProfile.searchProfileZipCode")),
    construction_year: Yup.string()
      .test("construction_year", t("invalidYear"), function (value) {
        if (value) {
          const minDate = new Date("1850");
          const addedDate = new Date(value);
          return addedDate > minDate && addedDate <= new Date();
        }
        return true;
      })
      .nullable(),
    renovation: Yup.string()
      .test("renovation", t("invalidYear"), function (value) {
        if (value) {
          const minDate = new Date("1850");
          const addedDate = new Date(value);
          return addedDate > minDate && addedDate <= new Date();
        }
        return true;
      })
      .nullable(),
    sale_area_to: Yup.string().test(
      "sale_area_compare",
      `${t("searchProfile.saleAreaTo")} ${t("greaterOrEqual")} ${t(
        "searchProfile.saleAreaFrom"
      )}`,
      compareFormattedFields("sale_area_from")
    ),

    rentable_area_to: Yup.string().test(
      "rentable_area_compare",
      `${t("searchProfile.rentableAreaTo")} ${t("greaterOrEqual")} ${t(
        "searchProfile.rentableAreaFrom"
      )}`,
      compareFormattedFields("rentable_area_from")
    ),

    office_area_to: Yup.string().test(
      "office_area_compare",
      `${t("searchProfile.officeAreaTo")} ${t("greaterOrEqual")} ${t(
        "searchProfile.officeAreaFrom"
      )}`,
      compareFormattedFields("office_area_from")
    ),

    practice_area_to: Yup.string().test(
      "practice_area_compare",
      `${t("searchProfile.practiceAreaTo")} ${t("greaterOrEqual")} ${t(
        "searchProfile.practiceAreaFrom"
      )}`,
      compareFormattedFields("practice_area_from")
    ),

    commercial_area_to: Yup.string().test(
      "commercial_area_compare",
      `${t("searchProfile.commercialAreaTo")} ${t("greaterOrEqual")} ${t(
        "searchProfile.commercialAreaFrom"
      )}`,
      compareFormattedFields("commercial_area_from")
    ),

    living_area_to: Yup.string().test(
      "living_area_compare",
      `${t("searchProfile.livingAreaTo")} ${t("greaterOrEqual")} ${t(
        "searchProfile.livingAreaFrom"
      )}`,
      compareFormattedFields("living_area_from")
    ),

    other_area_to: Yup.string().test(
      "other_area_compare",
      `${t("searchProfile.livingAreaTo")} ${t("greaterOrEqual")} ${t(
        "searchProfile.livingAreaFrom"
      )}`,
      compareFormattedFields("other_area_from")
    ),

    inhabitants_to: Yup.string().test(
      "inhabitants_compare",
      `${t("searchProfile.inhabitantsTo")} ${t("greaterOrEqual")} ${t(
        "searchProfile.inhabitantsFrom"
      )}`,
      compareFormattedFields("inhabitants_from")
    ),

    plot_area_to: Yup.string().test(
      "plot_area_compare",
      `${t("searchProfile.plotAreaTo")} ${t("greaterOrEqual")} ${t(
        "searchProfile.plotAreaFrom"
      )}`,
      compareFormattedFields("plot_area_from")
    ),

    iban: Yup.string()
      .transform((v) => v.replace(/ /g, ""))
      .min(IBAN_CHARACTERS_NUMBER, t("property.bank.ibanCharactersNumber"))
      .max(IBAN_CHARACTERS_NUMBER, t("property.bank.ibanCharactersNumber"))
      .required(t("property.bank.ibanIsRequired")),
    property_management_cost: Yup.string()
      .required(t("form.propertyManagementCostIsRequired"))
      .nullable(),
    asset_managers: Yup.array().min(1, t("form.assetManagerIsRequired")),
    tenant_type_id: Yup.number().required(
      t("tenant.propertyTenantTypeIsRequired")
    ),
    tenant_category_id: Yup.number().required(
      t("tenant.propertyTenantCategoryIsRequired")
    ),
    immoscout_url: Yup.string()
      .when("is_immoscout_enabled", {
        is: true,
        then: () => Yup.string().required(t("tenant.immoscoutRequired")),
      })
      ?.nullable(),

    immowelt_url: Yup.string()
      .when("is_immowelt_enabled", {
        is: true,
        then: () => Yup.string().required(t("tenant.immowelRequired")),
      })
      .nullable(),

    ebay_url: Yup.string()
      .when("is_ebay_enabled", {
        is: true,
        then: () => Yup.string().required(t("tenant.ebayRequired")),
      })
      .nullable(),

    agent_commissioned_url: Yup.string()
      .when("is_agent_commissioned_enabled", {
        is: true,
        then: () =>
          Yup.string().required(t("tenant.agentCommissionedRequired")),
      })
      .nullable(),

    warning_text: Yup.string()
      .when("is_warning_enabled", {
        is: true,
        then: () => Yup.string().required(t("tenant.warningTextIsRequired")),
      })
      .nullable(),

    legal_dunning_text: Yup.string()
      .when("is_legal_dunning_enabled", {
        is: true,
        then: () => Yup.string().required(t("tenant.legalTextIsRequired")),
      })
      .nullable(),

    installment_text: Yup.string()
      .when("is_installment_enabled", {
        is: true,
        then: () =>
          Yup.string().required(t("tenant.installmentTextIsRequired")),
      })
      .nullable(),
    file_id: Yup.string().required(t("documentRelease.invoice.error.fileId")),
    contact_id: Yup.string().required(
      t("documentRelease.invoice.error.contactId")
    ),
    currency_code: Yup.string().required(
      t("documentRelease.invoice.error.currencyCode")
    ),
    amount: Yup.string()
      .required(t("documentRelease.invoice.error.amount"))
      .typeError(t("documentRelease.invoice.error.amountType")),
    is_permanent: Yup.boolean(),
    is_apportionable: Yup.boolean(),
    comment: Yup.string().required(t("documentRelease.invoice.error.comment")),
    date: Yup.date()
      .required(t("documentRelease.invoice.error.date"))
      .nullable()
      .typeError(t("documentRelease.invoice.invalidDate")),
    until: Yup.date().when("is_permanent", {
      is: true,
      then: () =>
        Yup.date().required(t("invalidDate")).typeError(t("invalidDate")),
    }),

    total: Yup.string().when("is_permanent", {
      is: true,
      then: () =>
        Yup.string().required(t("documentRelease.invoice.error.total")),
    }),
    cancellation_period: Yup.string().required(t("form.cancellationPeriod")),
    release_am: Yup.string().required(t("form.releaseAm")).nullable(),
    monthly: Yup.string().when("is_permanent", {
      is: true,
      then: () =>
        Yup.string().required(t("documentRelease.invoice.error.monthly")),
    }),
    contract_id: Yup.string().required(
      t("documentRelease.invoice.error.contractId")
    ),
    offer_id: Yup.string().required(t("documentRelease.invoice.error.offerId")),
    apportionable_percent: Yup.number().when("is_apportionable", {
      is: true,
      then: () =>
        Yup.number()
          .max(PERCENTAGE_MAX_VALUE, t("maxPercent"))
          .min(PERCENTAGE_MIN_VALUE, t("minPercent"))
          .required(t("documentRelease.invoice.error.apportionablePercent")),
    }),
    name: Yup.string().required(t("form.nameIsRequired")),
    contract_category_id: Yup.string().required(
      t("contracts.error.contractCategoryId")
    ),
    type: Yup.string().required(t("form.insuranceType")),
    topic_id: Yup.string().required(t("offers.error.topic")),
    user_id: Yup.string().required(t("documentRelease.invoice.error.userId")),
    releaserComment: Yup.string().required(
      t("documentRelease.invoice.release.commentRequired")
    ),
    location_name: Yup.string().required(t("employeeOverview.locationName")),
    heatingNumber: Yup.string().required(t("counter.create.heatingRequired")),
    heatingValue: Yup.string().required(
      t("counter.create.heatingValueRequired")
    ),
    vacation_holiday_user_selection: Yup.array().min(
      1,
      t("employeeOverview.vacationHolidayUserSelectionValidation")
    ),
    option_id: Yup.number()
      .required(t("employeeOverview.locationOfficeIsRequired"))
      .nullable(),
    title: Yup.string().required(t("form.titleIsRequired")),
    description: Yup.string().required(t("form.descriptionIsRequired")),
    description_max: Yup.string()
      .required(t("form.descriptionIsRequired"))
      .max(
        DESCRIPTION_MAX_CHARACTERS,
        t("property.maintenance.descriptionLengthError")
      ),
    type_id: Yup.string().required(t("form.type_id")),
    number: Yup.string().required(t("form.number")).nullable(),
    bank_name: Yup.string().required(t("form.bankNameIsRequired")),
    area_name: Yup.string().required(t("form.areaName")),
    area: Yup.string().required(t("form.propertyTenant")),
    net_rent_per_month: Yup.string().required(t("form.netRentPerMonth")),
    active_tenant: Yup.object().shape({
      rent_start: Yup.date()
        .required(t("form.rentStart"))
        .typeError(t("form.rentStart")),
      net_rent_per_month: Yup.string().required(t("form.netRentPerMonth")),
    }),
    rent_start: Yup.date()
      .required(t("form.rentStart"))
      .typeError(t("invalidDate")),
    rent_end: Yup.date().nullable().typeError(t("invalidDate")),
    property: {
      area: {
        tenant: {
          rent_start: Yup.string()
            .nullable()
            .required(t("form.rentStart"))
            .typeError(t("form.rentStart")),
          rent_end: Yup.string().nullable(),
          net_rent_per_month: Yup.string().required(t("form.netRentPerMonth")),
          contact_id: Yup.string()
            .required(t("form.contactId"))
            .typeError(t("form.contactId")),
        },
      },
    },
    others_incomings_eur: checkTenantType("form.otherIncomingsEur"),
    optional_contact_id: checkTenantType(
      "documentRelease.invoice.error.contactId"
    ),
    property_id: Yup.string().required(t("form.propertyId")),
    square_meters: Yup.string().required(
      t("property.area.squareMetersRequired")
    ),
    contact_address: {
      required: Yup.string().required(t("form.contactAddressIsRequired")),
      optional: Yup.string().optional().nullable(),
    },
    file: Yup.string().required(t("property.fileIsRequired")),
    building_in_percents: Yup.number()
      .max(PERCENTAGE_MAX_VALUE, t("maxPercent"))
      .min(PERCENTAGE_MIN_VALUE, t("minPercent"))
      .required(t("property.cost.validation.buildingInPercentsIsRequired")),
    building_in_eur: Yup.string().required(
      t("property.cost.validation.buildingInEuroIsRequired")
    ),
    land_in_percents: Yup.number()
      .max(PERCENTAGE_MAX_VALUE, t("maxPercent"))
      .min(PERCENTAGE_MIN_VALUE, t("minPercent"))
      .required(t("property.cost.validation.landInPercentsIsRequired")),
    property_transfer_tax: Yup.number()
      .max(PERCENTAGE_MAX_VALUE, t("maxPercent"))
      .min(PERCENTAGE_MIN_VALUE, t("minPercent"))
      .required(t("property.cost.validation.propertyTransferTaxIsRequired")),
    real_estate_agent: Yup.number()
      .max(PERCENTAGE_MAX_VALUE, t("maxPercent"))
      .min(PERCENTAGE_MIN_VALUE, t("minPercent"))
      .required(t("property.cost.validation.realEstateAgentIsRequired")),
    notary: Yup.number()
      .max(PERCENTAGE_MAX_VALUE, t("maxPercent"))
      .min(PERCENTAGE_MIN_VALUE, t("minPercent"))
      .required(t("property.cost.validation.notaryIsRequired")),
    due_dilligence: Yup.number()
      .max(PERCENTAGE_MAX_VALUE, t("maxPercent"))
      .min(PERCENTAGE_MIN_VALUE, t("minPercent"))
      .required(t("property.cost.validation.dueDilligenceIsRequired")),
    other: Yup.number()
      .max(PERCENTAGE_MAX_VALUE, t("maxPercent"))
      .min(PERCENTAGE_MIN_VALUE, t("minPercent"))
      .required(t("property.cost.validation.otherInPercentsIsRequired")),
    buffer: Yup.number()
      .max(PERCENTAGE_MAX_VALUE, t("maxPercent"))
      .min(PERCENTAGE_MIN_VALUE, t("minPercent"))
      .required(t("property.cost.validation.bufferInPercentsIsRequired")),
    maintenance_non_apportionable: Yup.number()
      .typeError(t("typeError"))
      .min(0, t("minPercent"))
      .max(100, t("maxPercent")),
    operating_costs_non_revenueable: Yup.number()
      .typeError(t("typeError"))
      .min(0, t("minPercent"))
      .max(100, t("maxPercent")),
    property_management_non_revenueable: Yup.number()
      .typeError(t("typeError"))
      .min(0, t("minPercent"))
      .max(100, t("maxPercent")),
    depreciation: Yup.number()
      .typeError(t("typeError"))
      .min(0, t("minPercent"))
      .max(100, t("maxPercent")),
    property_value_development: Yup.number()
      .typeError(t("typeError"))
      .min(0, t("minPercent"))
      .max(100, t("maxPercent")),
    own_resources: Yup.number()
      .typeError(t("typeError"))
      .min(0, t("minPercent"))
      .max(100, t("maxPercent")),
    bank_loan: Yup.number()
      .typeError(t("typeError"))
      .min(0, t("minPercent"))
      .max(100, t("maxPercent")),
    interest_on_own_founds: Yup.number()
      .typeError(t("typeError"))
      .min(0, t("minPercent"))
      .max(100, t("maxPercent")),
    interest_bank_loan: Yup.number()
      .typeError(t("typeError"))
      .min(0, t("minPercent"))
      .max(100, t("maxPercent")),
    repayment_bank: Yup.number()
      .typeError(t("typeError"))
      .min(0, t("minPercent"))
      .max(100, t("maxPercent")),
    inventory_type_id: Yup.string().required(
      t("companyOwnership.itemTypeIsRequired")
    ),
    brand: Yup.string().required(t("companyOwnership.brandIsRequired")),
    department: Yup.string().required(
      t("companyOwnership.departmentIsRequired")
    ),
    serial_number: Yup.string().required(
      t("companyOwnership.licensePlateSerialNumberIsRequired")
    ),
    subject: Yup.string().required(t("inbox.forward.validation.subject")),
    message: Yup.string().required(t("inbox.forward.validation.message")),
    received_date: {
      required: Yup.mixed().required(
        t("companyOwnership.receivedOnDateIsRequired")
      ),
      optional: Yup.mixed().optional().nullable(),
    },
    pdfFile: Yup.mixed()
      .required(t("inbox.create.fileRequired"))
      .test(
        "fileType",
        t("inbox.create.pdfFileType"),
        (file: MixedOptions<File>) => file?.type === MIME_TYPES.PDF
      ),
    property_area_id: Yup.string().required(t("form.propertyAreaId")),
    readings: Yup.string().required(t("form.readings")).nullable(),
    tenant_request: {
      title: Yup.string().required(t("tenantRequest.titleIsRequired")),
      description: Yup.string().required(
        t("tenantRequest.descriptionIsRequired")
      ),
      tenant_id: Yup.string().required(t("tenantRequest.tenantIsRequired")),
      property_id: Yup.string().required(t("tenantRequest.propertyIsRequired")),
      property_area_id: Yup.string().required(
        t("tenantRequest.areaIsRequired")
      ),
    },
    due_date: Yup.date()
      .when(
        "date",
        (date, schema) =>
          date &&
          schema.min(date, t("documentRelease.invoice.error.dueDateMin"))
      )
      .optional()
      .nullable()
      .typeError(t("invalidDate")),
    invoice_id: Yup.string().required(t("reminder.relationIdRequired")),
    maintenance_type_id: Yup.string().required(
      t("property.maintenance.pleaseSelectMaintenanceType")
    ),
    inspection: Yup.string().required(
      t("property.maintenance.pleaseSelectInspection")
    ),
    title_transaction_manager: Yup.string().required(
      t("transactionManagement.loi.titleOfTransactionManagerIsRequired")
    ),
    transactionManager: Yup.string().required(
      t("transactionManagement.loi.transactionManagerIsRequired")
    ),
    send_date: Yup.date()
      .required(t("form.rentStart"))
      .typeError(t("invalidDate")),
    recipient_street: Yup.string()
      .required(t("transactionManagement.loi.recipientStreetIsRequired"))
      .nullable(),
    recipient_city: Yup.string()
      .required(t("transactionManagement.loi.recipientCityIsRequired"))
      .nullable(),
    lease_cancel_date: Yup.date()
      .required(t("form.rentEnd"))
      .typeError(t("form.rentEnd")),
    optional_percentage: Yup.number()
      .max(PERCENTAGE_MAX_VALUE, t("maxPercent"))
      .min(PERCENTAGE_MIN_VALUE, t("minPercent"))
      .optional(),
    bkaYear: validateBKAYear,
    optional_array_of_email: Yup.array()
      .of(Yup.string().email(t("form.mustBeValidEmail")))
      .optional(),
    totalVacationLengths: Yup.number()
      .typeError(t("typeError"))
      .required(t("vacationRequests.totalVacationDaysIsRequired")),
    release_name_length: Yup.string().max(
      MAX_RELEASE_INPUT,
      t("releaseMaxCharacter")
    ),
    headline: Yup.string().required(t("form.pleaseEnterHeadline")).nullable(),
    tenantReminderSentDate: Yup.date()
      .required(t("tenant.reminderSentDate"))
      .typeError(t("tenant.reminderSentDate")),
    meterDate: Yup.date()
      .required(t("meter.meterDateIsRequired"))
      .nullable()
      .typeError(t("invalidDate")),
    required_array_of_email: Yup.array()
      .of(Yup.string().email(t("form.mustBeValidEmail")))
      .min(1, t("form.emailIsRequired")),
    empty_space_eur: Yup.string()
      .required(t("property.area.edit.emptySpaceEurRequired"))
      .typeError(t("property.area.edit.emptySpaceEurRequired")),
    BKACosts: Yup.array().of(
      Yup.object().shape({
        type_id: Yup.object().required(t("form.costTypeIsRequired")).nullable(),
        amount: Yup.string().required(
          t("documentRelease.invoice.error.amount")
        ),
      })
    ),
    invoice_date: Yup.date()
      .required(t("documentRelease.invoice.error.date"))
      .max(moment(), t("documentRelease.invoice.error.invoiceDate"))
      .typeError(t("documentRelease.invoice.invalidDate")),
    contract_end: {
      required: Yup.date()
        .required(t("form.contractExpiryDateIsRequired"))
        .min(
          Yup.ref("contract_start"),
          t("form.contractExpiryDateShouldNotBeGreaterThanContractStartDate")
        ),
      optional: Yup.string().nullable(),
    },
    invoice_provider: Yup.string().required(
      t("documentRelease.invoice.error.invoiceProvider")
    ),
    invoiceAmount: Yup.string()
      .required(t("documentRelease.invoice.error.invoiceAmount"))
      .typeError(t("documentRelease.invoice.error.invoiceAmountType")),
    repeat_from: Yup.string().when("is_permanent", {
      is: (is_permanent: boolean) => is_permanent,
      then: () =>
        Yup.date()
          .required(t("documentRelease.invoice.error.repeatFrom"))
          .min(moment(), t("documentRelease.invoice.error.repeatFromMinDay"))
          .typeError(t("documentRelease.invoice.error.invoiceAmountType")),
      otherwise: (schema) => schema.notRequired(),
    }),
    repeat_to: Yup.string().when("is_permanent", {
      is: (is_permanent: boolean) => is_permanent,
      then: () =>
        Yup.date()
          .required(t("documentRelease.invoice.error.repeatTo"))
          .min(
            Yup.ref("repeat_from"),
            t("documentRelease.invoice.error.repeatToMinDay")
          )
          .typeError(t("documentRelease.invoice.error.invoiceAmountType")),
      otherwise: (schema) => schema.notRequired(),
    }),
    repeat_day: Yup.string().when("is_permanent", {
      is: (is_permanent: boolean) => is_permanent,
      then: () =>
        Yup.number()
          .required(t("documentRelease.invoice.error.repeatDay"))
          .min(MIN_REPEAT_DAY, t("documentRelease.invoice.error.repeatDayMin"))
          .max(MAX_REPEAT_DAY, t("documentRelease.invoice.error.repeatDayMax")),
      otherwise: (schema) => schema.notRequired(),
    }),
    repetition_period_id: Yup.string().when("is_permanent", {
      is: (is_permanent: boolean) => is_permanent,
      then: () =>
        Yup.string().required(
          t("documentRelease.invoice.error.repetitionPeriodId")
        ),
      otherwise: (schema) => schema.notRequired(),
    }),
    creditor: {
      konto: Yup.string().required(t("datev.creditor.error.konto")),
    },
    debtor: {
      konto: Yup.string().required(t("form.kontoIsRequired")),
    },
    datev_creditor_id: Yup.string().required(t("form.creditorIsRequired")),
    performance_date: Yup.date().required(
      t("form.dateOfPerformanceIsRequired")
    ),
    booking_text: Yup.string().required(t("form.bookingTextIsRequired")),
    datev_invoice_positions: Yup.array().of(
      Yup.object().shape({
        amount_netto: Yup.string().required(t("form.amountNettoIsRequired")),
        booking_text: Yup.string().required(t("form.bookingTextIsRequired")),
        datev_soll_konto_id: Yup.string().required(
          t("form.debitAccountIsRequired")
        ),
        vat: Yup.string().required(t("form.vatIsRequired")),
      })
    ),
    has_creditor_contact: Yup.boolean().when("datev_creditor_id", {
      is: (datev_creditor_id: string) => Boolean(datev_creditor_id),
      then: () =>
        Yup.boolean().isTrue(
          t("documentRelease.invoice.error.pleaseConnectCreditorWithProvide")
        ),
      otherwise: () => Yup.boolean(),
    }),
    datev_due_date: Yup.date().when("date", {
      is: (date: Date | null) => !!date,
      then: () =>
        Yup.date()
          .min(Yup.ref("date"), t("documentRelease.invoice.error.dueDateMin"))
          .nullable(),
      otherwise: () => Yup.date().nullable(),
    }),
    dateVInvoice_date: Yup.date()
      .required(t("documentRelease.invoice.error.date"))
      .typeError(t("documentRelease.invoice.invalidDate")),
    forwardDocument: {
      contact_id: Yup.string()
        .ensure()
        .when(["email", "user_id"], {
          is: (email: string, user_id: string[]) =>
            email?.length === 0 && user_id?.length === 0,
          then: (schema) => schema.required(t("form.atLeastOneFieldRequired")),
          otherwise: (schema) => schema.notRequired(),
        }),
      email: Yup.string()
        .email()
        .ensure()
        .when(["contact_id", "user_id"], {
          is: (contact_id: string, user_id: string[]) =>
            contact_id?.length === 0 && user_id?.length === 0,
          then: (schema) => schema.required(t("form.atLeastOneFieldRequired")),
          otherwise: (schema) => schema.notRequired(),
        }),
      user_id: Yup.array()
        .ensure()
        .when(["email", "contact_id"], {
          is: (email: string, contact_id: string) =>
            email?.length === 0 && contact_id?.length === 0,
          then: (schema) => schema.min(1, t("form.atLeastOneFieldRequired")),
          otherwise: (schema) => schema.min(0),
        }),
    },
    assigned_user_id: Yup.string().required(
      t("documentRelease.invoice.error.userId")
    ),
    invoice_receiver: Yup.string().required(
      t("documentRelease.outgoingInvoice.error.receiverIsRequired")
    ),
    services: Yup.array().of(
      Yup.object().shape({
        tax: Yup.string().required(
          t("documentRelease.outgoingInvoice.error.taxIsRequired")
        ),
        price: Yup.string().required(
          t("documentRelease.outgoingInvoice.error.priceIsRequired")
        ),
        description: Yup.string().required(
          t("documentRelease.outgoingInvoice.error.descriptionIsRequired")
        ),
        quantity: Yup.number()
          .required(
            t("documentRelease.outgoingInvoice.error.quantityIsRequired")
          )
          .typeError(
            t(
              "documentRelease.outgoingInvoice.error.quantityNeedsToBePositiveNumber"
            )
          )
          .min(
            1,
            t(
              "documentRelease.outgoingInvoice.error.quantityNeedsToBePositiveNumber"
            )
          ),
      })
    ),
    serviceDateFrom: Yup.date()
      .required(t("documentRelease.outgoingInvoice.error.serviceDateFrom"))
      .typeError(t("documentRelease.invoice.invalidDate")),
    serviceDateTo: Yup.date()
      .required(t("documentRelease.outgoingInvoice.error.serviceDateTo"))
      .typeError(t("documentRelease.invoice.invalidDate"))
      .min(
        Yup.ref("date_from"),
        t(
          "documentRelease.outgoingInvoice.error.dateToDateShouldNotBeGreaterThanDateFrom"
        )
      ),
    notice: Yup.string().required(
      t("documentRelease.outgoingInvoice.error.noticeIsRequired")
    ),
    company_number: Yup.string().required(
      t("documentRelease.invoice.apidatev.companyNumberIsRequired")
    ),
    optionalEmail: Yup.string().email(t("form.mustBeValidEmail")),
  };
};
export default useValidation;
