import FormAlert from "../../../../ui/formAlert/FormAlert";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import { Mail } from "react-feather";

export const ErrorBox = styled(FormAlert)(spacing);

export const LabelText = styled.div`
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  margin: 5px 0 0 0;
`;
/* istanbul ignore next */
export const MailIcon = styled(Mail)`
  margin-right: ${(props) => props.theme.spacing(2.5)};
  &:hover {
    color: ${(props): string => props.theme.palette.grey[400]};
    cursor: pointer;
  }
  transition: color 150ms;
`;
