import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dialog, DialogContent, Grid } from "@mui/material";
import { AssignFormInitProps, InboxTypesSelectProps } from "./types";
import SelectOrCreateTopic from "./selectOrCreateInsuranceTopicModal";
import CreateReminderModal from "./createReminderModal";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
import PdfViewer from "../../../../ui/pdfViewer";
import { Formik } from "formik";
import * as Yup from "yup";
import InboxAssignForm from "./Form";
import {
  handleAssignFormSubmit,
  handleOsc,
  prepareFormIntialValue,
  setContractData,
  setInsuranceData,
  setInvoiceData,
  setOfferData,
  setReminderData,
} from "./utils";
import useValidation from "../../../../hooks/useValidation";
import { FormMessageInItState } from "../../../../constants";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import { INBOX_TYPES } from "../../utils";
import { replaceTimestampByMomentDatepicker } from "../../../../utils/date";
import Loader from "../../../Loader";
/* istanbul ignore next */
const InboxTypesSelect = ({
  inboxTypes,
  inboxData,
  setSnackbarData,
  handleFetchList,
  handleClose,
  getInboxType,
}: InboxTypesSelectProps): ReactElement => {
  const pdfContainerRef = useRef<HTMLObjectElement | null>(null);
  const [openCreateInsuranceModal, setOpenCreateInsuranceModal] =
    useState(false);
  const [openCreateReminderModal, setOpenCreateReminderModal] = useState(false);
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [dataLoading, sestDataLoading] = useState(true);
  const [selectedCode, setSelectedCode] = useState("");
  const [inboxFileId, setInboxFileId] = useState<number>();
  const [formData, setFormData] = useState<AssignFormInitProps>(inboxData);
  const handleDialog = (): void => {
    setOpenCreateInsuranceModal((modalState) => !modalState);
  };

  const handleDialogCreateReminder = (): void => {
    setOpenCreateReminderModal((modalState) => !modalState);
  };

  useEffect(() => {
    if (inboxData) {
      if (!inboxData.type?.code || inboxData.type.code === INBOX_TYPES.INBOX) {
        handleOsc(inboxData.file.id).then(async (responseGetFileOCR) => {
          const jsonGetFileOCR = await responseGetFileOCR.json();
          const { total, invoice_number, date, currency_code } =
            jsonGetFileOCR.data;

          setFormData({
            ...inboxData,
            invoice_number: inboxData.invoice_amount ?? invoice_number,
            invoice_amount: inboxData.invoice_amount ?? total,
            date: inboxData.invoice_date
              ? replaceTimestampByMomentDatepicker(inboxData.invoice_date)
              : date,
            currency_code,
          });
          sestDataLoading(false);
        });
      } else if (inboxData.forwarded?.id) {
        getInboxTypeData().then(() => {
          sestDataLoading(false);
        });
      } else {
        sestDataLoading(false);
      }
      setInboxFileId(inboxData.file.id);
    }
  }, [inboxData]);

  const getInboxTypeData = async (): Promise<void> => {
    if (inboxData.forwarded?.id) {
      if (inboxData.type?.code === INBOX_TYPES.INVOICE) {
        await setInvoiceData(inboxData.forwarded.id, formData, setFormData);
      } else if (inboxData.type?.code === INBOX_TYPES.CONTRACT) {
        await setContractData(inboxData.forwarded.id, formData, setFormData);
      } else if (inboxData.type?.code === INBOX_TYPES.INSURANCE) {
        await setInsuranceData(inboxData.forwarded.id, formData, setFormData);
      } else if (inboxData.type?.code === INBOX_TYPES.OFFER) {
        await setOfferData(inboxData.forwarded.id, formData, setFormData);
      } else if (inboxData.type?.code === INBOX_TYPES.REMINDER) {
        await setReminderData(inboxData.forwarded.id, formData, setFormData);
      }
    }
  };
  const {
    number,
    invoice_date,
    contact_id,
    invoiceAmount,
    property_id,
    currency_code,
    type_id,
    assigned_user_id,
    contract_category_id,
    topic_id,
    amount,
    cancellation_period,
    invoice_id,
  } = useValidation();

  const validationSchema = useMemo(() => {
    const defaultValidation = {
      property_id,
      type_id,
    };
    if (selectedCode === INBOX_TYPES.DATEV_INVOICE) {
      return Yup.object().shape({
        ...defaultValidation,
        contact_id,
        assigned_user_id,
      });
    }

    if (selectedCode === INBOX_TYPES.INVOICE) {
      return Yup.object().shape({
        ...defaultValidation,
        invoice_number: number,
        invoice_amount: invoiceAmount,
        contact_id,
        currency_code,
        date: invoice_date,
        assigned_user_id,
      });
    }
    if (selectedCode === INBOX_TYPES.CONTRACT) {
      return Yup.object().shape({
        ...defaultValidation,
        contact_id,
        contract_category_id,
        assigned_user_id,
      });
    }

    if (selectedCode === INBOX_TYPES.OFFER) {
      return Yup.object().shape({
        ...defaultValidation,
        contact_id,
        topic_id,
        amount,
        assigned_user_id,
      });
    }

    if (selectedCode === INBOX_TYPES.INSURANCE) {
      return Yup.object().shape({
        ...defaultValidation,
        contact_id,
        topic_id,
        amount,
        cancellation_period,
        assigned_user_id,
      });
    }

    if (selectedCode === INBOX_TYPES.REMINDER) {
      return Yup.object().shape({
        ...defaultValidation,
        invoice_id,
      });
    }

    return Yup.object().shape({ ...defaultValidation });
  }, [selectedCode]);

  const initFormData = useMemo(() => {
    return prepareFormIntialValue(formData);
  }, [formData]);

  const pdfFile = useMemo(() => {
    return { fileId: inboxFileId };
  }, [inboxFileId]);
  return (
    <>
      {inboxData && (
        <>
          <Dialog
            open={true}
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="max-width-dialog-title"
            data-testid="assign-inbox-modal"
          >
            <DialogTitle title="inbox.assignTitle" onClose={handleClose} />
            <DialogContent>
              {formMessage.text && (
                <FormAlert
                  formMessage={formMessage}
                  sx={{ marginBottom: "10px" }}
                />
              )}
              <Grid container justifyContent="space-between" spacing={6}>
                <Grid item xs={12} sm={5} ref={pdfContainerRef}>
                  <PdfViewer file={pdfFile} containerRef={pdfContainerRef} />
                </Grid>
                {dataLoading ? (
                  <Grid item xs={12} sm={6}>
                    <Loader />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6}>
                    <Formik
                      initialValues={initFormData}
                      enableReinitialize
                      validationSchema={validationSchema}
                      onSubmit={handleAssignFormSubmit({
                        id: inboxData.id,
                        setFormMessage,
                        edit: false,
                        inboxData,
                        onClose: handleClose,
                      })}
                    >
                      {(props): React.ReactElement => (
                        <InboxAssignForm
                          {...props}
                          inboxTypes={inboxTypes}
                          inboxData={inboxData}
                          getInboxType={getInboxType}
                          setSelectedCode={setSelectedCode}
                        />
                      )}
                    </Formik>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
          </Dialog>

          {openCreateInsuranceModal && (
            <SelectOrCreateTopic
              handleDialog={handleDialog}
              inboxData={inboxData}
            />
          )}
          {openCreateReminderModal && (
            <CreateReminderModal
              handleDialogCreateReminder={handleDialogCreateReminder}
              inboxData={inboxData}
              setSnackbarData={setSnackbarData}
              handleFetchList={handleFetchList}
            />
          )}
        </>
      )}
    </>
  );
};

export default InboxTypesSelect;
