import React from "react";
import CompanyOwnership from "../../components/companyOwnership/list";
import AccessGuard from "../../components/guards/AccessGuard";
import EditInventoryItem from "../../components/companyOwnership/edit";
import CreateInventoryItem from "../../components/companyOwnership/create";
import InventoryItemHistory from "../../components/companyOwnership/history";
import {
  PermissionsCreate,
  PermissionsRead,
  PermissionsUpdate,
} from "../../constants";

export default {
  path: "company-ownership",
  children: [
    {
      path: "",
      element: (
        <AccessGuard permissions={[PermissionsRead.COMPANY_OWNERSHIP]}>
          <CompanyOwnership />
        </AccessGuard>
      ),
    },
    {
      path: "create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.COMPANY_OWNERSHIP]}>
          <CreateInventoryItem />
        </AccessGuard>
      ),
    },
    {
      path: ":id/history",
      element: (
        <AccessGuard permissions={[PermissionsRead.COMPANY_OWNERSHIP]}>
          <InventoryItemHistory />
        </AccessGuard>
      ),
    },
    {
      path: ":id/edit",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.COMPANY_OWNERSHIP]}>
          <EditInventoryItem />
        </AccessGuard>
      ),
    },
  ],
};
