import React, { ReactElement, useEffect, useRef, useState } from "react";
import Form from "../Form";
import { Grid, Paper } from "@mui/material";
import { Formik, FormikProps } from "formik";
import {
  createContractFormInitState,
  handleContractFormSubmit,
} from "../utils";
import {
  FormMessageErrorState,
  FormMessageInItState,
} from "../../../../constants";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import * as Yup from "yup";
import useValidation from "../../../../hooks/useValidation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { route } from "../../../../utils/url";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFetch";
import { ContractCategory as BEContractCategory } from "../../../../types/be.interfaces";
import { ErrorBox } from "../../invoices/styled";
import Loader from "../../../Loader";
import { getContractCategory } from "../../../../api/contractCategories";
import { getReleaseRoute } from "../../utils";
import { ContractForm } from "../../../../types/fe.interfaces";
import { Currencies } from "../../../../types/be/currencies";
import { getCurrencies } from "../../../../api/currencies";
import { InboxLocationState } from "../../../inbox/types";
import { Inbox } from "../../../../types/fe/inbox";
import { CreateContractFormProps } from "../types";
import FileTreeDialog from "../../../../ui/fileList/index";
import PdfViewerWrapper from "../../components/PdfViewerWrapper";
import TabPageWrapper from "../../../../ui/tabPageWrapper";

const ContractCreate = ({
  onClose,
  isModal = false,
  setContractModalValue,
}: CreateContractFormProps): ReactElement => {
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [createContractInitState, setCreateContractInitState] = useState(
    createContractFormInitState
  );
  const [fileName, setFileName] = useState("");
  const { id } = useParams();
  const { state } = useLocation() as InboxLocationState;
  /* istanbul ignore next */
  const inboxData = state ? (state.inboxData as Inbox) : null;

  const { file_id, contact_id, contract_category_id, property_id } =
    useValidation();

  const {
    data: currencies,
    isLoading: isCurrenciesLoading,
    isError: isCurrenciesError,
    run: runCurrencies,
  } = useFetch<Currencies[]>();
  const {
    data: contractCategories,
    isLoading: isContractCategoriesLoading,
    isError: isContractCategoriesError,
    run: runContractCategories,
  } = useFetch<BEContractCategory[]>();

  const formRef = useRef<FormikProps<ContractForm> | null>(null);

  const [selectedFileId, setSelectedFileId] = useState<number>();

  const navigate = useNavigate();
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const handleCloseDialog = (): void => setIsDialogVisible(false);
  const handleOpenDialog = (): void => setIsDialogVisible(true);

  useEffect(() => {
    runCurrencies(getCurrencies());
    runContractCategories(getContractCategory());
  }, []);

  useEffect(() => {
    formRef.current?.values.file_id &&
      setSelectedFileId(Number(formRef.current?.values.file_id));
  }, [formRef.current?.values.file_id]);
  const { t } = useTranslation();

  const breadcrumbs = isModal
    ? []
    : [
        {
          to: route("root"),
          name: "Dashboard",
        },
        {
          to: getReleaseRoute("contracts", Number(id)),
          name: t("property.navLinks.contracts"),
        },
      ];

  const handleFileTreeClick = (file_id: string, file_name: string): void => {
    formRef?.current?.setFieldValue("file_id", parseInt(file_id));
    setFileName(file_name);
    setSelectedFileId(Number(file_id));
    handleCloseDialog();
  };

  if (isContractCategoriesError || isCurrenciesError)
    return <ErrorBox formMessage={FormMessageErrorState} />;

  if (isContractCategoriesLoading || isCurrenciesLoading) return <Loader />;

  return (
    <TabPageWrapper
      title={isModal ? "" : t("create")}
      breadcrumbs={breadcrumbs}
      wrap={!id}
    >
      <Paper sx={{ p: 5 }}>
        {contractCategories && currencies && (
          <Grid container justifyContent={"center"}>
            {selectedFileId && (
              <Grid item sm={6} xs={12}>
                <PdfViewerWrapper data={selectedFileId} type="fileId" />
              </Grid>
            )}

            <Grid item xs={12} sm={isModal ? 12 : 6} sx={{ pl: 6 }}>
              {formMessage.text && (
                <FormAlert
                  formMessage={formMessage}
                  sx={{ marginBottom: "10px" }}
                />
              )}
              <Formik
                initialValues={{
                  ...createContractInitState,
                  property_id: id || "",
                }}
                validationSchema={Yup.object().shape({
                  file_id,
                  contact_id,
                  contract_category_id,
                  property_id,
                })}
                innerRef={formRef}
                onSubmit={handleContractFormSubmit({
                  id,
                  setFormMessage,
                  edit: false,
                  navigate,
                  inboxData,
                  onClose,
                  setContractModalValue,
                })}
              >
                {(props): React.ReactElement => (
                  <Form
                    {...props}
                    propertyId={id}
                    categories={contractCategories}
                    handleOpenDialog={handleOpenDialog}
                    fileName={fileName}
                    setFileName={setFileName}
                    setFormInitState={setCreateContractInitState}
                    currencies={currencies}
                    inboxData={inboxData}
                  />
                )}
              </Formik>
              {isDialogVisible && (
                <FileTreeDialog
                  onClose={handleCloseDialog}
                  handleFileTreeClick={handleFileTreeClick}
                  extensions={["pdf"]}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Paper>
    </TabPageWrapper>
  );
};

export default ContractCreate;
