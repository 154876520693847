import {
  createAsyncThunk,
  createSlice,
  ActionReducerMapBuilder,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Client } from "../../../types/models";
import http from "../../../utils/http";

export interface ClientState {
  _client: Client;
  status: "idle" | "pending" | "resolved" | "rejected";
}

interface FetchPayload {
  client: string | undefined;
  callback?: () => void;
}

const initialState: ClientState = {
  _client: {
    name: "",
    images: null,
    slug: "",
    modules: [],
  },
  status: "idle",
};

export const fetchClientBySlug = createAsyncThunk(
  "client/fetchClientBySlug",
  async ({ client }: FetchPayload) => {
    const response = await http.get(`/api/tenants/${client}`);
    const { data } = await response.json();
    data.slug = client;
    return data;
  }
);

export const fetchClientBySlugFulfilledCallback = (
  state: ClientState,
  action: PayloadAction<
    Client,
    string,
    {
      arg: FetchPayload;
      requestId: string;
      requestStatus: "fulfilled";
    },
    never
  >
): void => {
  state._client = action.payload;
  state.status = "resolved";
  setTimeout(() => {
    action?.meta?.arg?.callback?.();
  }, 0);
};

export const fetchClientBySlugPendingCallback = (state: ClientState): void => {
  state.status = "pending";
};

export const extraReducers = (
  builder: ActionReducerMapBuilder<ClientState>
): void => {
  builder
    .addCase(fetchClientBySlug.pending, fetchClientBySlugPendingCallback)
    .addCase(fetchClientBySlug.fulfilled, fetchClientBySlugFulfilledCallback);
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {},
  extraReducers,
});

export default clientSlice.reducer;
