import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { route } from "../../../../utils/url";
import { useNavigate, useParams } from "react-router-dom";
import { Paper } from "./styled";
import { handleCreateMaintenanceSubmit, initialValues } from "./utils";
import Form from "./form";
import useValidation from "../../../../hooks/useValidation";
import useSnackbar from "../../../../ui/snackbar1/useSnackbar";
import { getReleaseRoute } from "../../utils";
import TabPageWrapper from "../../../../ui/tabPageWrapper";

const CreateMaintenance = (): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { Snackbar, snackbar } = useSnackbar();
  const {
    description_max: description,
    contact_id,
    file,
    maintenance_type_id,
    contract_id,
    inspection,
  } = useValidation();
  const afterCreateMaintenance = (): void => {
    navigate(getReleaseRoute("maintenance", Number(id)));
  };

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("maintenance", Number(id)),
      name: t("property.maintenance.title"),
    },
  ];

  return (
    <TabPageWrapper title={t("create")} breadcrumbs={breadcrumbs} wrap={!id}>
      <Grid container justifyContent="center" alignItems="center">
        <Paper>
          <Formik
            initialValues={initialValues}
            onSubmit={handleCreateMaintenanceSubmit({
              snackbar,
              t,
              afterCreateMaintenance,
            })}
            validationSchema={Yup.object().shape({
              description,
              contact_id,
              report_id: file,
              order_id: file,
              maintenance_type_id,
              contract_id,
              inspection,
            })}
          >
            {(props) => <Form {...props} />}
          </Formik>
          {Snackbar}
        </Paper>
      </Grid>
    </TabPageWrapper>
  );
};

export default CreateMaintenance;
