import { useCallback, useEffect, useMemo } from "react";
import { useFetch } from "./useFetch";
import { AreaTypeCodes } from "../constants";
import { AreaType as BEAreaType } from "../types/be/area";
import { getTypes } from "../api/area";

export type AreaTypesIdDict = Record<AreaTypeCodes, number | undefined>;

interface UseAreaTypesProps {
  areaTypes: BEAreaType[] | null;
  areaTypesIdDict: AreaTypesIdDict;
  getAreaType: (query: AreaTypeCodes | number) => BEAreaType | void;
  isLoading: boolean;
  isError: boolean;
}

const initAreaTypesIdDict: AreaTypesIdDict = {
  commercial: undefined,
  living: undefined,
};
/* istanbul ignore next */
const useAreaTypes = (): UseAreaTypesProps => {
  const { data: areaTypes, isError, isLoading, run } = useFetch<BEAreaType[]>();

  useEffect(() => {
    run(getTypes());
  }, []);

  const getAreaType = useCallback(
    (query: AreaTypeCodes | number): BEAreaType | void =>
      areaTypes?.find((areaType) =>
        [areaType.code, areaType.id].includes(query)
      ),
    [areaTypes]
  );

  const areaTypesIdDict: AreaTypesIdDict = useMemo(
    () => ({
      ...Object.values(AreaTypeCodes).reduce((dict, value) => {
        return {
          ...dict,
          [value]: getAreaType(value)?.id,
        };
      }, initAreaTypesIdDict),
    }),
    [areaTypes]
  );

  return {
    areaTypes,
    areaTypesIdDict,
    getAreaType,
    isLoading,
    isError,
  };
};

export default useAreaTypes;
