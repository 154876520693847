import FormAlert from "../../../ui/formAlert/FormAlert";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import { Grid, Paper as MuiPaper } from "@mui/material";

export const ErrorBox = styled(FormAlert)(spacing);

export const StyledGrid = styled(Grid)`
  display: flex;
  justify-content: center;
`;
/* istanbul ignore next */
export const Paper = styled(MuiPaper)`
  padding: ${(props): string => props.theme.spacing(6)};
  min-width: 400px;
  .drop-box {
    position: static;
    height: 40vh;
    margin: ${(props): string => props.theme.spacing(0)};
    .MuiBox-root {
      margin: ${(props): string => props.theme.spacing(0)};
    }
  }
`;
