import React, { ReactElement, useEffect } from "react";
import { Form, FormikValues } from "formik";
import { Grid, MenuItem, IconButton } from "@mui/material";
import TextField from "../../../../ui/formsFields/textField";
import Button from "../../../../ui/button/Button";
import FileInputField from "../../../../ui/formsFields/fileInput";
import { useTranslation } from "react-i18next";
import { StyledGrid } from "./styled";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { FormatNumber } from "../../../../ui/formsFields/formatNumber";
import { FORMAT_NUMBER_DE, Measurement_Units } from "../../../../constants";
import SelectOrCreateContactsAutocompleteField from "../../../contacts/autocomplete";
import { route } from "../../../../utils/url";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

const CreateForm = ({
  status,
  values,
  isSubmitting,
  propertyId,
  setFieldValue,
  handleOpenDialog,
  setFormInitState,
  fileName,
  setFileName,
  errors,
  touched,
  inboxData,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";

  /* istanbul ignore next */
  useEffect(() => {
    if (inboxData) {
      setFieldValue("file_id", inboxData.file.id);
      inboxData.contact && setFieldValue("contact_id", inboxData.contact.id);
    }
  }, []);

  return (
    <Form noValidate>
      <Grid container spacing={6}>
        {!inboxData && (
          <Grid item md={6} sm={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={11}>
                <FileInputField
                  required
                  name="file_id"
                  label={t("insurance.uploadfile")}
                  error={Boolean(
                    touched?.file_id &&
                      (status?.errors?.file_id || errors?.file_id)
                  )}
                  helperText={
                    touched?.file_id &&
                    (status?.errors?.file_id || errors?.file_id)
                  }
                  foldername="Verträge"
                  propertyid={propertyId}
                  inputProps={{ "data-testid": "insurance-file" }}
                  accept="application/pdf"
                  errorText={t("contracts.error.pdfOnly")}
                  outerFileName={fileName}
                  setOuterFileName={setFileName}
                  setFormInitState={setFormInitState}
                />
              </Grid>
              <StyledGrid item xs={1} pt={3}>
                <IconButton
                  color="primary"
                  aria-label="uploadPdf"
                  component="span"
                  size="small"
                  onClick={handleOpenDialog}
                >
                  <InsertDriveFileIcon />
                </IconButton>
              </StyledGrid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={6}>
          <SelectOrCreateContactsAutocompleteField
            {...{
              errors,
              values: {
                ...values,
                contact: inboxData?.contact
                  ? /* istanbul ignore next */ inboxData.contact
                  : values.contact,
              },
              touched,
              setFieldValue,
            }}
            required
            label={t("tenant.contact_name")}
            placeholder={t("tenant.contact_name")}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <FormatNumber
            name="amount"
            allowNegative
            label={t("insurance.amount")}
            required
            size="medium"
            status={status}
            fullWidth
            inputProps={{
              "data-testid": "property-management-cost-input",
              filter: FORMAT_NUMBER_DE,
              unit: Measurement_Units.EURO,
            }}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <TextField
            name="cancellation_period"
            select
            label={t("insurance.cancellationPeriod")}
            fullWidth
            status={status}
            required
            data-testid="cancellation-period-select"
          >
            {[1, 3].map((item) => (
              <MenuItem
                value={item}
                key={item}
                data-testid={`cancellation-period-${item}`}
              >
                {item} {t("insurance.month")}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid
          container
          display="flex"
          justifyContent="space-between"
          sx={{ mt: 3, ml: 3 }}
        >
          <Grid item>
            <Button
              type="button"
              color="primary"
              variant="text"
              title={t("cancel")}
              disabled={isSubmitting}
              size="large"
              onClick={
                /* istanbul ignore next */ () =>
                  doesAnyHistoryEntryExist
                    ? navigate(-1)
                    : propertyId
                    ? navigate(route("properties.insurances.new", propertyId))
                    : navigate(route("insurances.new"))
              }
            />
          </Grid>
          <Grid item>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="success"
              size="large"
              title={t("create")}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default CreateForm;
