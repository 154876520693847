/* istanbul ignore file */
import http from "../utils/http";
import { Advertisement } from "../types/be/tenant";

/* istanbul ignore next */
export const getPropertyTenant = async (id: number): Promise<Response> => {
  return await http.get(`/api/property-tenants/${id}`);
};

/* istanbul ignore next */
export const patchContactOnTenant = async (
  id: number,
  contactId: number | null
): Promise<Response> => {
  return await http.patch(`/api/property-tenants/${id}/contacts`, {
    contact_id: contactId,
  });
};

/* istanbul ignore next */
export const deletePropertyTenant = async (id: number): Promise<Response> => {
  return await http.delete(`/api/property-tenants/${id}`);
};

/* istanbul ignore next */
export const savePropertyAdvertisement = async (
  payload: Advertisement,
  tenantId: string
): Promise<Response> => {
  return await http.post(
    `/api/property-tenants/${tenantId}/advertisements`,
    payload
  );
};
/* istanbul ignore next */
export const updatePropertyAdvertisement = async (
  payload: Advertisement,
  tenantId: string
): Promise<Response> => {
  return await http.put(
    `/api/property-tenants/${tenantId}/advertisements/${payload.id}`,
    payload
  );
};

/* istanbul ignore next */
export const storeDunning = async (
  tenantId: string,
  payload: FormData
): Promise<Response> => {
  return await http.post(
    `/api/property-tenants/${tenantId}/dunning-letters`,
    payload
  );
};

/* istanbul ignore next */
export const updateDunning = async (
  tenantId: string,
  dunningId: number,
  payload: FormData
): Promise<Response> => {
  return await http.post(
    `/api/property-tenants/${tenantId}/dunning-letters/${dunningId}`,
    payload
  );
};

export const getTenantType = async (): Promise<Response> => {
  return await http.get("/api/property-tenants/types");
};

/* istanbul ignore next */
export const updateTenant = async (tenant: {
  id: number;
  contact_id: number | null;
}): Promise<Response> =>
  await http.put(`/api/property-tenants/${tenant.id}`, tenant);

/* istanbul ignore next */
export const updateTenantRentEnd = async (tenant: { rent_start: number; rent_end: number; id: number }): Promise<Response> =>
  await http.put(`/api/property-tenants/${tenant.id}`, tenant);

/* istanbul ignore next */
export const downloadTenantSampleFile = async (): Promise<Response> => {
  return await http.get(`/api/imports/area-tenant-contact/xls/example`);
};

/* istanbul ignore next */
export const importTenants = async (payload: FormData): Promise<Response> => {
  return await http.post(`/api/property-tenants/import/xls`, payload);
};
