import {
  AssignUsersToGeneralDocumentData,
  FormSubmitHandlerProps,
} from "./types";
import { FormikValues } from "formik";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { mapBackendValidationErrors } from "../../../../utils/form";
import {
  PermissionsRead,
  PERMISSION_CODE_PARAM,
  PROPERTY_ID_PARAM,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { editGeneralDocument } from "../../../../api/generalDocuments";
import { prepareQueryParams } from "../../../../utils/common";
import { getUsers } from "../../../../api/users";
import { User } from "../../../../types/models";

export const assignUsersInitialValues: AssignUsersToGeneralDocumentData = {
  assigned_users: [],
};
/* istanbul ignore next */
export const handleAssignUsers = (
  id: number,
  { setFormMessage, afterAssignUsers }: FormSubmitHandlerProps
) => {
  return async (
    values: AssignUsersToGeneralDocumentData,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const payload = {
      assigned_user_id: values.assigned_users.map((el) => el.id),
    };

    const response = await editGeneralDocument(+id, payload);
    const json = await response.json();

    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      afterAssignUsers && afterAssignUsers();
      setStatus({
        success: true,
        errors: {},
      });
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
    }
    setSubmitting(false);
  };
};
/* istanbul ignore next */
export const getSearchQueryForUsersToBeAssigned = (
  propertyId?: number | null
): Record<string, string | number | undefined> => ({
  [PERMISSION_CODE_PARAM]: PermissionsRead.GENERAL_DOCUMENT,
  [PROPERTY_ID_PARAM]: propertyId ?? undefined,
});

/* istanbul ignore next */
export const handleOnChangeAutocompleteInputText = async (
  search: string,
  propertyId?: number | null
): Promise<User[]> => {
  const params = prepareQueryParams("", {
    search,
    ...getSearchQueryForUsersToBeAssigned(propertyId),
  });
  const response = await getUsers(params);

  const { data } = await response.json();

  return data;
};
