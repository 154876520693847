import React from "react";
import AuthGuard from "../../components/guards/AuthGuard";
import MeterTable from "../../components/meter/list";
import CreateMeter from "../../components/meter/create";
import DashboardLayout from "../../layouts/dashboard";
import MeterShow from "../../components/meter/show";
import { PermissionsCreate, PermissionsRead } from "../../constants";
import AccessGuard from "../../components/guards/AccessGuard";

export default {
  path: "/:client/meter",
  element: (
    <AuthGuard clientModuleName="mobile-app">
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: (
        <AccessGuard permissions={[PermissionsRead.METER]}>
          <MeterTable />
        </AccessGuard>
      ),
    },
    {
      path: "create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.METER]}>
          <CreateMeter />
        </AccessGuard>
      ),
    },
    {
      path: ":id",
      element: (
        <AccessGuard permissions={[PermissionsRead.METER]}>
          <MeterShow />
        </AccessGuard>
      ),
    },
  ],
};
