import React, { ReactElement, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import { CreateAreaTenantModalProps } from "./types";
import { createTenantFormInitState, handleSubmitCreateArea } from "./utils";
import useValidation from "../../../../../../hooks/useValidation";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import AddTenantForm from "./Form";
import { useTranslation } from "react-i18next";
import DialogTitle from "../../../../../../ui/dialog/DialogTitle";

export const CreateTenantModal = ({
  isCreateTenantModalOpen,
  handleCreateTenantModalClose,
  handleCreateTenantModalCancel,
  area,
  setRow,
  setSnackbarMessage,
}: CreateAreaTenantModalProps): ReactElement => {
  const [initialValues] = useState(createTenantFormInitState);
  const { id: propertyId } = useParams();
  const { t } = useTranslation();
  const {
    property: {
      area: { tenant: addTenantValidation },
    },
  } = useValidation();

  const { net_rent_per_month, rent_start, rent_end, contact_id } =
    addTenantValidation;
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isCreateTenantModalOpen}
      onClose={handleCreateTenantModalCancel}
      aria-labelledby="max-width-dialog-title"
      data-testid="create-tenant-dialog"
    >
      <DialogTitle
        title="property.area.createTenant"
        onClose={handleCreateTenantModalCancel}
      />
      <DialogContent>
        <>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              net_rent_per_month,
              rent_start,
              rent_end,
              contact_id,
            })}
            onSubmit={handleSubmitCreateArea(parseInt(propertyId!), area.id, {
              handleCreateTenantModalClose,
              setRow,
              setSnackbarMessage,
              t,
            })}
          >
            {(props): ReactElement => (
              <AddTenantForm
                {...props}
                area={area}
                handleCreateTenantModalCancel={handleCreateTenantModalCancel}
              />
            )}
          </Formik>
        </>
      </DialogContent>
    </Dialog>
  );
};
