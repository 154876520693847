import styled from "styled-components/macro";
import { Grid } from "@mui/material";

export const ToolBarWrapper = styled(Grid)`
  background: ${(props): string => props.theme.tenantRequests.background.bg1};
  border-bottom: ${(props): string =>
    `5px solid ${props.theme.palette.background.default}`};
  margin-right: ${(props): string => props.theme.spacing(1.5)};
  padding-top: ${(props): string => props.theme.spacing(2)};
  padding-bottom: ${(props): string => props.theme.spacing(2)};
  padding-right: ${(props): string => props.theme.spacing(3)};
  padding-left: ${(props): string => props.theme.spacing(3)};
`;
