import styled from "styled-components/macro";
import {
  Alert as MuiAlert,
  Box,
  Paper,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

export const FormWrapper = styled(Paper)`
  padding: ${(props): string => props.theme.spacing(6)};
  margin-top: 30px;
  ${(props): string => props.theme.breakpoints.up("lg")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 80%;
  }
  ${(props): string => props.theme.breakpoints.up("xl")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 60%;
  }
  ${(props): string => props.theme.breakpoints.down("xs")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 100%;
  }
`;

export const FormMessageWrapper = styled(Box)`
  min-height: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Alert = styled(MuiAlert)(spacing);
export const TextField = styled(MuiTextField)<{ mb?: number }>(spacing);

export const LoadingBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;
