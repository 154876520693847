import React, {
  ReactElement,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  TextField as MuiTextField,
  CircularProgress,
} from "@mui/material";
import {
  MAX_PRELOADED_OPTIONS,
  NODE_ENVIRONMENTS,
  SEARCH_PARAM_MIN_CHARACTERS,
} from "../../../../constants";
import { uniqBy } from "lodash";
import debounce from "lodash/debounce";
import { isNodeEnv } from "../../../../utils/env";
import {
  FILTER_TYPE,
  prepareQueryParams,
  replaceNullOrUndefinedByEmptyString,
} from "../../../../utils/common";
import { getInvoices } from "../../../../api/invoices";
import { useFetch } from "../../../../hooks/useFetch";
import { getContracts } from "../../../../api/contracts";
import {
  DocumentActions,
  DocumentsAutocompleteFilter,
  DocumentsAutocompleteProps,
} from "./types";
import { getOffers } from "../../../../api/offer";
import { getInsurances } from "../../../../api/insurance";
import { useParams } from "react-router-dom";

/* istanbul ignore next */
const DocumentsAutocomplete = <T extends { id: number; name: string }>({
  document,
  onDocumentSelect,
  documentType,
  propertyId,
  isGeneral,
  size = "small",
}: DocumentsAutocompleteProps<T>): ReactElement => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const {
    data: invoices,
    run: runInvoices,
    isLoading: invoicesAreLoading,
  } = useFetch<T[]>();

  const {
    data: contracts,
    run: runContracts,
    isLoading: contractsAreLoading,
  } = useFetch<T[]>();

  const {
    data: offers,
    run: runOffers,
    isLoading: offersAreLoading,
  } = useFetch<T[]>();

  const {
    data: insurances,
    run: runInsurances,
    isLoading: insurancesAreLoading,
  } = useFetch<T[]>();

  const [options, setOptions] = useState<T[] | null>([]);

  const documentActions: DocumentActions = useMemo(
    () => ({
      invoice: {
        run: runInvoices,
        get: getInvoices,
      },
      contract: {
        run: runContracts,
        get: getContracts,
      },
      offer: {
        run: runOffers,
        get: getOffers,
      },
      insurance: {
        run: runInsurances,
        get: getInsurances,
      },
    }),
    [
      runInvoices,
      getInvoices,
      runContracts,
      getContracts,
      runOffers,
      getOffers,
      runInsurances,
      getInsurances,
    ]
  );

  useEffect(() => {
    if (!documentActions?.[documentType]) return;

    const params: DocumentsAutocompleteFilter = {
      limit: MAX_PRELOADED_OPTIONS,
    };

    if (isGeneral === FILTER_TYPE.GENERAL || propertyId || id) {
      params.property_id = propertyId || id || "";
    }

    documentActions[documentType].run(
      documentActions[documentType].get(
        prepareQueryParams(
          "",
          replaceNullOrUndefinedByEmptyString(params),
          true
        )
      )
    );
  }, [propertyId, isGeneral, documentActions, documentType]);

  useEffect(() => {
    invoices && setOptions(invoices);
    contracts && setOptions(contracts);
    offers && setOptions(offers);
    insurances && setOptions(insurances);
  }, [invoices, contracts, offers, insurances]);

  const getOptionLabel = (relation: T | null): string => {
    if (!relation) return "";
    const { id, name } = relation;

    return `${id ? id : ""}${name ? " " + name : ""}`;
  };

  const onAutocompleteChangeCompany = (
    _: unknown,
    newRelation: T | null
  ): void => {
    onDocumentSelect(newRelation);
  };

  const handleAutocompleteInputChange = async (
    search: string
  ): Promise<T[]> => {
    const params: DocumentsAutocompleteFilter = {
      search,
    };

    if (isGeneral === FILTER_TYPE.GENERAL || propertyId || id) {
      params.property_id = propertyId || id || "";
    }

    const response = await documentActions[documentType].get(
      prepareQueryParams("", replaceNullOrUndefinedByEmptyString(params), true)
    );
    const { data } = await response.json();
    return data;
  };

  const onSearchFieldTextChange = useCallback(
    debounce(
      async (search: string): Promise<void> => {
        if (search.length < SEARCH_PARAM_MIN_CHARACTERS) {
          setOptions(options);
        } else {
          setLoading(true);
          try {
            const response: T[] = await handleAutocompleteInputChange(search);
            const uniqResult = uniqBy(response, (id) => id);
            setOptions([...uniqResult]);
            setLoading(false);
          } catch (e) {
            setOptions([]);
          }
        }
      },
      isNodeEnv(NODE_ENVIRONMENTS.TEST) ? 0 : 500
    ),

    [handleAutocompleteInputChange, options]
  );

  const isLoading =
    loading ||
    invoicesAreLoading ||
    contractsAreLoading ||
    offersAreLoading ||
    insurancesAreLoading;

  return (
    <Autocomplete
      id="document_id"
      size={size}
      loading={isLoading}
      options={options || []}
      filterOptions={(options) => options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value): boolean => option.id === value.id}
      value={document || null}
      onChange={onAutocompleteChangeCompany}
      renderOption={(props, option): ReactElement => (
        <li {...props}>{option.name || option.id}</li>
      )}
      sx={{ minHeight: "0" }}
      renderInput={(params) => (
        <>
          <MuiTextField
            {...params}
            label={t(`documentRelease.history.${documentType}`)}
            placeholder={t(`documentRelease.history.${documentType}`)}
            onChange={(e) => {
              onSearchFieldTextChange(e.target.value);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default DocumentsAutocomplete;
