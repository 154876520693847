import { FormikValues } from "formik";
import { Grid } from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Text from "../../../../../../../ui/formsFields/text";
import Button from "../../../../../../../ui/button/Button";

/* istanbul ignore next */
const CreateMobileUserForm = ({
  values,
  handleSubmit,
  isSubmitting,
  status,
  handleInviteModalClose,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Text
            name="first_name"
            label={t("createContact.firstName")}
            value={values?.first_name}
            size="small"
            status={status}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Text
            name="last_name"
            label={t("createContact.lastName")}
            value={values?.last_name}
            size="small"
            status={status}
          />
        </Grid>
      </Grid>
      <Grid container mt={4}>
        <Grid item xs={12} sm={6} md={6}>
          <Text
            name="email"
            label={t("emailAddress")}
            value={values?.email}
            size="small"
            status={status}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mt={3}
      >
        <Grid item>
          <Button
            onClick={handleInviteModalClose}
            title={t("cancel")}
            testId={"cancel-button"}
            variant={"text"}
            type="button"
            size="large"
          />
        </Grid>
        <Grid item>
          <Button
            title={t("tenant.invite")}
            type="submit"
            color="success"
            size="medium"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateMobileUserForm;
