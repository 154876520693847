import React, { ReactElement, SyntheticEvent, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  StyledGridTabs,
  StyledGridTabsContent,
  StyledTabContent,
} from "../../../../objectDataSheet/styled";
import { Tabs, Tab } from "@mui/material";
import TabPanel from "../../../../../../ui/tabs/tabPanel";
import { emptyAreaFormTabs, EmptyAreaFormTabs } from "./utils";
import Information from "./information";
import Listing from "./listing";
import { EmptyAreaFormProps } from "./types";
import { isAppEnv } from "../../../../../../utils/env";
import { APP_ENVIRONMENTS } from "../../../../../../constants";

const EmptyArea = (props: EmptyAreaFormProps): ReactElement => {
  const { t } = useTranslation();
  const [formType, setFormType] = useState<EmptyAreaFormTabs>(
    EmptyAreaFormTabs.INFORMATION
  );

  const handleTabChange = (value: EmptyAreaFormTabs): void => {
    setFormType(value);
  };
  /* istanbul ignore next */
  const filteredTabsForProd = isAppEnv(APP_ENVIRONMENTS.PROD)
    ? emptyAreaFormTabs.filter((i) => i.value === EmptyAreaFormTabs.INFORMATION)
    : emptyAreaFormTabs;

  return (
    <>
      <Grid item sm={12}>
        <StyledGridTabs item xs={12} sm={12}>
          <Tabs
            value={formType}
            onChange={(_: SyntheticEvent, value: EmptyAreaFormTabs) =>
              handleTabChange(value)
            }
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            {filteredTabsForProd.map(({ label, value }) => (
              <Tab value={value} label={t(label)} key={value} />
            ))}
          </Tabs>
        </StyledGridTabs>
        <StyledGridTabsContent item xs={12} sm={12}>
          <StyledTabContent>
            <>
              <TabPanel
                key={EmptyAreaFormTabs.INFORMATION}
                value={formType}
                index={EmptyAreaFormTabs.INFORMATION}
              >
                <Information {...props} />
              </TabPanel>
              <TabPanel
                key={EmptyAreaFormTabs.LISTING}
                value={formType}
                index={EmptyAreaFormTabs.LISTING}
              >
                <Listing {...props} />
              </TabPanel>
            </>
          </StyledTabContent>
        </StyledGridTabsContent>
      </Grid>
    </>
  );
};

export default EmptyArea;
