import { FormikValues } from "formik";
import moment from "moment";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { EmployeeSchedule as FEEmployeeSchedule } from "../../../types/fe.interfaces";
import { HTTP_STATUS_CODES } from "../../../types/server";
import {
  adminCreateEmployeeSchedule,
  createEmployeeSchedule,
} from "../../../api/userSchedules";
import { replaceMomentByFormatedString } from "../../../utils/date";
import { mapBackendValidationErrors } from "../../../utils/form";
import { HandleSubmitNewEntryModalFormProps } from "./types";
import de from "date-fns/locale/de";
import en from "date-fns/locale/en-US";

export const initEmployeeScheduleRange = [new Date(), new Date()];

const touchedInitState = {
  option_id: false,
  date_from: false,
  date_to: false,
};

export const handleSubmitNewEntryModalForm = ({
  setFormMessage,
  handleSuccessfulNewEntrySubmission,
  handleNewEntryModalClose,
  isAdmin,
  id,
}: HandleSubmitNewEntryModalFormProps) => {
  return async (
    schedule: FEEmployeeSchedule,
    { setStatus, setTouched, setSubmitting }: FormikValues
  ): Promise<void | Response> => {
    const auxSchedule: FEEmployeeSchedule = {
      option_id: schedule.option_id,
      dates: [
        {
          date_from: replaceMomentByFormatedString(
            moment(schedule.dates[0].date_from)
          ),
          date_to: replaceMomentByFormatedString(
            moment(schedule.dates[0].date_to)
          ),
        },
      ],
    };

    const response = isAdmin
      ? await adminCreateEmployeeSchedule({ ...auxSchedule, user_id: [id!] })
      : /* istanbul ignore next */
        await createEmployeeSchedule(auxSchedule);

    const json = await response.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      setTouched(touchedInitState);
      setFormMessage({
        type: "success",
        text: "",
      });
      handleNewEntryModalClose();
      handleSuccessfulNewEntrySubmission();
      return response;
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json?.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setSubmitting(false);
    }
  };
};

export const langDict: Record<string, Locale> = {
  en,
  de,
};
