import React, { ReactElement, useRef, useState } from "react";
import { Formik } from "formik";
import Form from "../Form";
import { Grid, Paper } from "@mui/material";
import { FormMessageInItState, SUBMIT_STATUS } from "../../../../constants";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import useValidation from "../../../../hooks/useValidation";
import * as Yup from "yup";
import { createLOIInitState, handleCreateOrEditLOIFormSubmit } from "../utils";
import { FormMessageInterface } from "../../../../types/form";
import PdfViewer from "../../../../ui/pdfViewer";
import PageWrapper from "../../../../ui/pageWrapper/PageWrapper";
import { useTranslation } from "react-i18next";
import { route } from "../../../../utils/url";
import { useNavigate } from "react-router-dom";
/* istanbul ignore next */
const CreateLOI = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ref = useRef<HTMLObjectElement | null>(null);
  const [submitAction, setSubmitAction] = useState<string | null>(null);
  const [prevImage, setPrevImage] = useState<string | null>(null);
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const [initialValues] = useState(createLOIInitState);
  const {
    send_date: date,
    purchase_price,
    company_id,
    object_name,
    contact_id,
    recipient_street,
    recipient_city,
    city,
    title_transaction_manager,
    transactionManager: user_id,
  } = useValidation();

  const breadcrumbsItems = [
    {
      to: route("loi"),
      name: t("transactionManagement.loi.loi"),
    },
  ];

  return (
    <PageWrapper title={t("create")} breadcrumbs={breadcrumbsItems}>
      <Paper sx={{ p: 5 }}>
        <Grid container justifyContent={"center"}>
          {prevImage && (
            <Grid item xs={3.7} ref={ref}>
              <PdfViewer file={{ url: prevImage }} containerRef={ref} />
            </Grid>
          )}
          <Grid item xs={8.3} pl={6}>
            {formMessage.text && (
              <FormAlert
                formMessage={formMessage}
                sx={{ marginBottom: "10px" }}
              />
            )}
            <Formik
              initialValues={initialValues}
              validationSchema={
                submitAction === SUBMIT_STATUS.PREVIEW
                  ? null
                  : Yup.object().shape({
                      date,
                      purchase_price,
                      company_id: company_id.required,
                      object_name,
                      contact_id,
                      recipient_street,
                      recipient_city,
                      city,
                      user_id,
                      title_transaction_manager,
                    })
              }
              onSubmit={handleCreateOrEditLOIFormSubmit({
                submitAction,
                setFormMessage,
                setPrevImage,
                navigate,
              })}
            >
              {(props): ReactElement => (
                <Form
                  {...props}
                  submitAction={submitAction}
                  setSubmitAction={setSubmitAction}
                />
              )}
            </Formik>
          </Grid>
        </Grid>
      </Paper>
    </PageWrapper>
  );
};

export default CreateLOI;
