import React, { ReactElement } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableContainer,
  Grid,
  TableCell,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TenantHistoryContact } from "../../../../../../../../../../types/be/tenantHistory";
/*istanbul ignore next*/
export const ContactInfo = ({
  full_name,
  company_name,
  address,
  email,
}: TenantHistoryContact): ReactElement => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} mb={5}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell>{t("table.fullName")}</TableCell>
              <TableCell>{full_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("company.companyName")}</TableCell>
              <TableCell>{company_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("address")}</TableCell>
              <TableCell>{address}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("email")}</TableCell>
              <TableCell>{email}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default ContactInfo;
