/* istanbul ignore file */
import React, { ReactElement } from "react";
import { DialogContent } from "@mui/material";
import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import { SendLOIModalProps } from "./types";
import Form from "./Form";
import { handelSendLOI, prepareLOISendBeforeForm } from "./utils";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import useValidation from "../../../../hooks/useValidation";
import DialogTitle from "../../../../ui/dialog/DialogTitle";

export const SendLOIModal = ({
  open,
  row,
  handleCreateModalClose,
  snackbar,
}: SendLOIModalProps): ReactElement => {
  const { t } = useTranslation();
  const { required_array_of_email, optional_array_of_email, subject, message } =
    useValidation();

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleCreateModalClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="send-LOI-dialog"
    >
      <DialogTitle
        title={t("transactionManagement.loi.sendLOI")}
        onClose={handleCreateModalClose}
      />
      <DialogContent>
        <>
          <Formik
            initialValues={prepareLOISendBeforeForm(row)}
            validationSchema={Yup.object().shape({
              email: required_array_of_email,
              subject_email: subject,
              message,
              email_cc: optional_array_of_email,
              email_bcc: optional_array_of_email,
            })}
            onSubmit={handelSendLOI({
              row,
              handleCreateModalClose,
              snackbar,
              t,
            })}
          >
            {(props): ReactElement => (
              <Form
                {...props}
                handleCreateModalClose={handleCreateModalClose}
              />
            )}
          </Formik>
        </>
      </DialogContent>
    </Dialog>
  );
};
