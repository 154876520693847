import React, { ReactElement, useContext } from "react";
import { TreeItemRoot } from "./styled";
import { FileTreeItemProps } from "./types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ThemeContext } from "styled-components";
import { blue } from "@mui/material/colors";
import { CircularProgress } from "@mui/material";
import { FileTreeContext } from "./utils";
const FileTreeItem = ({
  color = blue["A400"],
  labelIcon: LabelIcon,
  labelInfo,
  labelText,
  ...other
}: FileTreeItemProps): ReactElement => {
  const {
    fileManagement: { background },
  } = useContext(ThemeContext);
  const fileTreeContext = useContext(FileTreeContext);

  return (
    <TreeItemRoot
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{
              fontWeight: "inherit",
              flexGrow: 1,
              wordBreak: "break-word",
              maxWidth: "calc(90% - 18px)",
              width: "calc(90% - 18px)",
            }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>

          {fileTreeContext?.selected === other.nodeId &&
            fileTreeContext?.isLoading && (
              <Box
                sx={{
                  height: "18px",
                  width: "18px",
                }}
              >
                <CircularProgress size={18} color="inherit" />
              </Box>
            )}
        </Box>
      }
      sx={{
        "--tree-view-color": color,
        "--tree-view-bg-color": background,
      }}
      {...other}
    />
  );
};

export default FileTreeItem;
