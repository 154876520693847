import { uniqBy } from "lodash";
import { User } from "../../types/models";
/* istanbul ignore next */
export const showCommentsModalInitState = {
  isVisible: false,
  rowId: 0,
};
/* istanbul ignore next */
export const getUniqUsers = (
  mentionedUsers: User[],
  data: User[] | null
): User[] => uniqBy([...mentionedUsers, ...(data || [])], ({ id }) => id);
