import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Grid } from "@mui/material";
import { Typography, Dialog, DialogTitle } from "@mui/material";
import { User } from "../../../../../../types/models";
import { Status as ContractStatus } from "../../../../../../types/be/status";
import { Contract } from "../../../../../../types/be/contract";
import useTable from "../../../../../../ui/table/useTable";
import Table from "../../../../../../ui/table/Table";
import { itemsListHeadCells } from "../../utils";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  prepareQueryParams,
  RELEASE_LEVEL,
  replaceNullOrUndefinedByEmptyString,
  getFilteredHeadCellsByProperty,
} from "../../../../../../utils/common";
import { formatTimestamp } from "../../../../../../utils/date";
import {
  DeleteIcon,
  EditIcon,
  ShowIcon,
} from "../../../../../../ui/table/styled";
import { deleteContract, getContracts } from "../../../../../../api/contracts";
import useAccessControl from "../../../../../../hooks/useAccessControl";
import { TableCell } from "../../../../styled";
import useAppSelector from "../../../../../../hooks/useAppSelector";
import { useNavigate } from "react-router";
import { handleServerError } from "../../../../../../utils/http";
import useIsMounted from "../../../../../../hooks/useIsMounted";
import filter from "lodash/filter";
import { NumericFormat } from "react-number-format";
import {
  DECIMAL_SEPARATOR,
  DECIMAL_SCALE,
  Measurement_Units,
  THOUSAND_SEPARATOR,
  COMMENTABLE,
  CURRENCIES,
  EMPTY_DATA,
  PermissionsUpdate,
  PermissionsDelete,
  UserRoles,
} from "../../../../../../constants";
import CommentsPopup from "../../../../../../ui/commentsPopUp";
import { showCommentsModalInitState } from "../../../../../../ui/commentsPopUp/utils";
import PageWrapper from "../../../../../../ui/pageWrapper/PageWrapper";
import {
  ALL_STATUS,
  getItemsWithActiveReleasePermissions,
  getReleaseRoute,
  providerTableScrollerId,
  statusCodesToExclude,
} from "../../../../utils";
import Loader from "../../../../../Loader";
import { ContractFilterProps, ProviderContractListProps } from "../../../types";
import ExpandableComment from "../../../../../../ui/expandableComment/ExpandableComment";
import RedirectProperty from "../../../../../../ui/redirectProperty";
import ContactShowDialog from "../../../../components/provider/contactShowDialog";
import ProviderTableCell from "../../../../components/provider/ProviderTableCell";

import {
  DialogContainWrapper,
  DialogContent,
  DialogTitleContainer,
} from "../../../../../home/styled";
import CloseButton from "../../../../../../ui/dialog/CloseButton";
import { ProviderInfoTableWrapper } from "../../../../components/provider/styled";
import ProviderInfoTable from "../../../../components/provider/ProviderInfoTable";
import { getContact } from "../../../../../../api/contacts";
import { useFetch } from "../../../../../../hooks/useFetch";
import { Contact } from "../../../../../contacts/edit/types";
import HZScrollController from "../../../../../../ui/HZScrollController";
import DocumentRelease from "../../../../components/documentRelease";
import {
  DOCUMENT_RELEASE_LEVEL,
  DOCUMENT_RELEASE_MODULE,
} from "../../../../../../types/be/releaseFlow";
import { DocumentReleaseHistoryIcon } from "../../../../components/history/styled";
import FileTableCell from "../../../../components/fileTableCell";

/* istanbul ignore next */
const ProviderContractList = ({
  onClose,
  parentFilter,
  configuration,
  statuses: statusesProp,
  handleForwardDocument,
  handleReleaseDocument,
  releasePermission,
  isDocumentReleaseLoading,
  handleHistoryModal,
}: ProviderContractListProps): ReactElement => {
  const { t } = useTranslation();
  const { id, status } = useParams();
  const [statuses, setStatuses] = useState<ContractStatus[]>([]);

  const [isShowCommentsModal, setIsShowCommentsModal] = useState(
    showCommentsModalInitState
  );

  const [contactShowId, setContactShowId] = useState<string | null>(null);

  const navigate = useNavigate();
  const { can, isRole } = useAccessControl();

  const { optionalColumnIds, selectedOptionalColumnsIds } = useAppSelector(
    (state) => state.contract
  );

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleDeleteLastPageData,
  } = useTable<User>();

  const {
    data: contact,
    run: runContact,
    isSuccess: isContactSuccess,
  } = useFetch<Contact>();

  useEffect(() => {
    parentFilter.contact_id &&
      runContact(getContact(String(parentFilter.contact_id)));
  }, []);

  /* istanbul ignore next */

  useEffect(() => {
    if (!statusesProp) return;
    const newStatuses = [
      ALL_STATUS,
      ...statusesProp.filter((s) => !statusCodesToExclude.includes(s.code)),
    ];
    setStatuses(newStatuses);
  }, [statusesProp]);

  const tableHeaderTitles = useMemo(() => {
    let filteredItems = itemsListHeadCells;
    filteredItems = filter(
      filteredItems,
      (o) =>
        selectedOptionalColumnsIds.includes(o.id) ||
        !optionalColumnIds.includes(o.id)
    );

    filteredItems = getFilteredHeadCellsByProperty(
      filteredItems,
      "property_name",
      id
    );

    return [
      ...getItemsWithActiveReleasePermissions(filteredItems, configuration),
    ];
  }, [selectedOptionalColumnsIds]);

  /* istanbul ignore next */
  const fetchContracts = useCallback(
    async (loading = true) => {
      setIsLoading(loading);

      const params: ContractFilterProps = {};

      const { contact_id } = parentFilter;

      if (id) {
        params.property_id = id;
      }

      if (contact_id) {
        params.contact_id = contact_id;
      }

      const response = await getContracts(
        prepareQueryParams(
          queryParams,
          replaceNullOrUndefinedByEmptyString(params),
          true
        )
      );
      if (response.status !== 200) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        const resJson = await response.json();
        setTotal(resJson.meta.total);
        setData(resJson.data);
        setIsLoading(false);
      }
    },
    [
      queryParams,
      setIsLoading,
      setTotal,
      setData,
      setIsLoading,
      status,
      parentFilter,
    ]
  );

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteContract(rowToDelete);
    handleDeleteLastPageData(fetchContracts);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteContract,
    fetchContracts,
    handleConfirmToRemoveModalClose,
  ]);

  const handleEditClick = useCallback(
    (contract: Contract): void =>
      navigate(
        getReleaseRoute(
          "contracts.edit",
          id ? Number(id) : undefined,
          contract.id
        )
      ),
    []
  );

  const handleShowClick = useCallback(
    (contract: Contract): void =>
      navigate(
        getReleaseRoute(
          "contracts.show",
          id ? Number(id) : undefined,
          contract.id
        )
      ),
    []
  );
  useEffect(() => {
    if (isMounted() && statuses.length) {
      fetchContracts();
    }
  }, [queryParams, parentFilter, statuses.length]);

  const isMounted = useIsMounted();

  const TableToolbar = isContactSuccess ? (
    <Grid container pt={4}>
      <Grid item>
        <HZScrollController scrollerId={providerTableScrollerId} />
      </Grid>
    </Grid>
  ) : (
    <></>
  );

  const handleOpenComments = (row: Contract): void => {
    setIsShowCommentsModal({ isVisible: true, rowId: row?.id });
  };

  const handleCloseCommentsModal = (): void => {
    setIsShowCommentsModal(showCommentsModalInitState);

    fetchContracts(false);
  };

  /* istanbul ignore next */
  const renderRow = (row: Contract): ReactElement => (
    <>
      {!id ? (
        <TableCell align="left" mw="100px">
          <RedirectProperty property={row?.property} />
        </TableCell>
      ) : null}
      <FileTableCell file={row.file} />
      <ProviderTableCell
        contact={row.contact}
        onContactShow={() => setContactShowId(String(row?.contact?.id))}
      />
      <TableCell align="right" mw={"150px"}>
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          decimalScale={DECIMAL_SCALE}
          fixedDecimalScale
          displayType={"text"}
          value={row.amount}
        />{" "}
        {row.amount !== null
          ? row.currency_code === CURRENCIES[1]
            ? Measurement_Units.DOLLAR
            : Measurement_Units.EURO
          : EMPTY_DATA}
      </TableCell>

      <TableCell align="center" mw="130px">
        {formatTimestamp(row.start_date)}
      </TableCell>
      <TableCell align="center" mw="130px">
        {formatTimestamp(row.end_date)}
      </TableCell>
      <TableCell align="center" mw="130px">
        {formatTimestamp(row.signing_date)}
      </TableCell>
      <TableCell align="center" mw="130px">
        {formatTimestamp(row.cancellation_date)}
      </TableCell>
      <TableCell align="center" mw="130px">
        {formatTimestamp(row.cancelled_at)}
      </TableCell>
      <TableCell align="left">
        <ExpandableComment
          seeAllComment={() => handleOpenComments(row)}
          commentData={row?.latest_comment}
        ></ExpandableComment>
      </TableCell>
      <TableCell align="left" mw="130px">
        {row.created_by?.first_name} {row.created_by?.last_name}
      </TableCell>
      <TableCell align="center">
        {row.uploaded_at ? formatTimestamp(row.uploaded_at) : EMPTY_DATA}
      </TableCell>
      <TableCell align="left" mw="100px">
        {row.category?.name || EMPTY_DATA}
      </TableCell>
      <TableCell align="center">
        <DocumentRelease
          id={row?.id}
          releaser={row?.fr}
          releaseStatus={row?.fr_status}
          forwardedAt={row?.fr_forwarded_at}
          forwardedBy={row?.fr_forwarded_by}
          overdue={row?.fr_overdue}
          afterSuccess={fetchContracts}
          handleReleaseDocument={handleReleaseDocument}
          handleForwardDocument={handleForwardDocument}
          level={RELEASE_LEVEL.FIRST_LEVEL_RELEASE}
          hasForwardPermission={
            releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
            releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL] ||
            releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
              row?.assigned_user
            )
          }
          hasReleasePermission={
            releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL]
          }
          relation={DOCUMENT_RELEASE_MODULE.CONTRACT}
          loading={isDocumentReleaseLoading}
        />
      </TableCell>
      <TableCell align="center">
        <DocumentRelease
          id={row?.id}
          releaser={row?.am}
          releaseStatus={row?.am_status}
          forwardedAt={row?.am_forwarded_at}
          forwardedBy={row?.am_forwarded_by}
          overdue={row?.am_overdue}
          afterSuccess={fetchContracts}
          handleReleaseDocument={handleReleaseDocument}
          handleForwardDocument={handleForwardDocument}
          level={RELEASE_LEVEL.SECOND_LEVEL_RELEASE}
          hasForwardPermission={
            releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
            releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL] ||
            releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
              row?.assigned_user
            )
          }
          hasReleasePermission={
            releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL]
          }
          relation={DOCUMENT_RELEASE_MODULE.CONTRACT}
          loading={isDocumentReleaseLoading}
        />
      </TableCell>

      <TableCell align="center">
        <DocumentRelease
          id={row?.id}
          releaser={row?.pm}
          releaseStatus={row?.pm_status}
          forwardedAt={row?.pm_forwarded_at}
          forwardedBy={row?.pm_forwarded_by}
          overdue={row?.pm_overdue}
          afterSuccess={fetchContracts}
          handleReleaseDocument={handleReleaseDocument}
          handleForwardDocument={handleForwardDocument}
          level={RELEASE_LEVEL.THIRD_LEVEL_RELEASE}
          hasForwardPermission={
            releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
            releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
            releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
              row?.assigned_user
            )
          }
          hasReleasePermission={
            releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL]
          }
          relation={DOCUMENT_RELEASE_MODULE.CONTRACT}
          loading={isDocumentReleaseLoading}
        />
      </TableCell>
      <TableCell align="center">
        <DocumentRelease
          id={row?.id}
          releaser={row?.au}
          releaseStatus={row?.au_status}
          forwardedAt={row?.au_forwarded_at}
          forwardedBy={row?.au_forwarded_by}
          overdue={row?.au_overdue}
          afterSuccess={fetchContracts}
          handleReleaseDocument={handleReleaseDocument}
          handleForwardDocument={handleForwardDocument}
          level={RELEASE_LEVEL.FOURTH_LEVEL_RELEASE}
          hasForwardPermission={
            releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
            releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
            releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL]
          }
          hasReleasePermission={releasePermission[
            DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
          ](row?.assigned_user)}
          relation={DOCUMENT_RELEASE_MODULE.CONTRACT}
          assignedUser={row?.assigned_user}
          loading={isDocumentReleaseLoading}
          hasAssignUserPermission={can(PermissionsUpdate.CONTRACT)}
        />
      </TableCell>

      {selectedOptionalColumnsIds.includes("end_date") && (
        /* istanbul ignore next */
        <TableCell align="right" mw="130px">
          {formatTimestamp(row.end_date)}
        </TableCell>
      )}

      {selectedOptionalColumnsIds.includes("property_name") && (
        /* istanbul ignore next */
        <TableCell align="left" mw="100px">
          {row.property ? row.property.object_name : "---"}
        </TableCell>
      )}
      <TableCell
        align="right"
        mw="100px"
        onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
          handleCellClick(e)
        }
        style={{ whiteSpace: "nowrap" }}
      >
        {isRole(UserRoles.ADMIN) && (
          <DocumentReleaseHistoryIcon
            role={"showHistoryRole"}
            onClick={() => {
              handleHistoryModal(row);
            }}
          />
        )}
        <ShowIcon
          role={"showIconRole"}
          onClick={(): void => handleShowClick(row)}
          size={18}
        />
        {can(PermissionsUpdate.CONTRACT) && (
          <EditIcon
            role={"editIconRole"}
            onClick={(): void => handleEditClick(row)}
            size={18}
          />
        )}
        {can(PermissionsDelete.CONTRACT) && (
          <DeleteIcon
            onClick={(): void => openConfirmToRemoveModal(row)}
            size={20}
            role={"deleteIconRole"}
          />
        )}
      </TableCell>
    </>
  );

  const breadcrumbs: { to: string; name: string }[] = [];

  const pageTitle = "";

  if (!statuses.length) {
    return <Loader />;
  }

  return (
    <Dialog
      role={"provider-invoices-list-modal"}
      fullWidth
      open={Boolean(parentFilter.contact_id)}
      onClose={onClose}
      maxWidth="xl"
    >
      <DialogTitle sx={{ pt: 0, pb: 0 }}>
        <DialogTitleContainer>
          <Typography
            variant="h6"
            component="div"
            justifyContent="space-around"
            width="100%"
          >
            Infos
          </Typography>
          <CloseButton onClick={onClose} />
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        <div>
          <DialogContainWrapper>
            <PageWrapper breadcrumbs={breadcrumbs} title={pageTitle}>
              <>
                {contact && (
                  <ProviderInfoTableWrapper>
                    <ProviderInfoTable contact={contact} />
                  </ProviderInfoTableWrapper>
                )}
                <Table
                  data={data}
                  total={total}
                  currentPage={currentPage}
                  order={order}
                  orderBy={orderBy}
                  error={error}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  onPageChange={handleChangePage}
                  onRowRemove={handleRowRemove}
                  onSortChange={handleSortChange}
                  onSelectAllClick={handleSelectAllClick}
                  onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
                  rowsPerPage={rowsPerPage}
                  isLoading={isLoading}
                  isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
                  listHeadCells={tableHeaderTitles}
                  renderRow={renderRow}
                  tableToolbar={TableToolbar}
                  noDataIsAvailablePlaceholder={t(
                    "table.noContractsAreAvailable"
                  )}
                  tableDataMaxHeight={"65vh"}
                  refId={`hzScroll${providerTableScrollerId}`}
                />
                {isShowCommentsModal.isVisible && (
                  <CommentsPopup
                    handleCloseDialog={handleCloseCommentsModal}
                    id={String(isShowCommentsModal?.rowId)}
                    relation={COMMENTABLE.CONTRACT}
                  />
                )}
                {contactShowId && (
                  <ContactShowDialog
                    id={contactShowId}
                    onClose={() => setContactShowId(null)}
                    open={true}
                  />
                )}
              </>
            </PageWrapper>
          </DialogContainWrapper>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProviderContractList;
