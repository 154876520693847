import React, { ReactElement } from "react";
import {
  Link,
  Tooltip,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { TableCell } from "../../styled";
import { RELEASE_LEVEL } from "../../../../utils/common";
import { formatTimestamp } from "../../../../utils/date";
import useAccessControl from "../../../../hooks/useAccessControl";
import { CheckCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  DeleteIcon,
  EditIcon,
  SendIcon,
  ShowIcon,
} from "../../../../ui/table/styled";
import { TextEllipsis, IsActiveIcon } from "../../../../styled";
import { NumericFormat } from "react-number-format";
import {
  DECIMAL_SEPARATOR,
  DECIMAL_SCALE,
  Measurement_Units,
  THOUSAND_SEPARATOR,
  UserRoles,
  EMPTY_DATA,
  PermissionsDelete,
  PermissionsUpdate,
} from "../../../../constants";
import { Android12Switch } from "../../../../ui/formsFields/switch/styled";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { InvoiceListRowProps } from "./types";
import PaidBy from "./paidBy";
import ExpandableComment from "../../../../ui/expandableComment/ExpandableComment";
import RedirectProperty from "../../../../ui/redirectProperty";
import ProviderTableCell from "../../components/provider/ProviderTableCell";
import DocumentRelease from "../../components/documentRelease";
import {
  DOCUMENT_RELEASE_LEVEL,
  DOCUMENT_RELEASE_MODULE,
} from "../../../../types/be/releaseFlow";
import FileTableCell from "../../components/fileTableCell";
import {
  RELASE_BUTTONS_AM,
  RELASE_BUTTONS_PM,
  RELEASE_BUTTONS_FR,
  RELEASE_BUTTONS_USER,
  isAvailableReleaseButtonTableCell,
} from "../../utils";
import { DocumentReleaseHistoryIcon } from "../../components/history/styled";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
/* istanbul ignore next */
const InvoiceListRow = ({
  row,
  handleItemCheckbox,
  checkedDict,
  id,
  setContactShow,
  setProviderInvoiceListFilter,
  selectedOptionalColumnsIds,
  handleOpenComments,
  fetchInvoices,
  moveToPaid,
  markAsBooked,
  handleCellClick,
  handleHistoryModal,
  handleForwardClick,
  handleShowClick,
  handleEditClick,
  openConfirmToRemoveModal,
  handleReleaseDocument,
  handleForwardDocument,
  releasePermission,
  isDocumentReleaseLoading,
  configuration,
}: InvoiceListRowProps): ReactElement => {
  const { can, isRole } = useAccessControl();
  const { t } = useTranslation();

  const onDocumentShow = setProviderInvoiceListFilter
    ? () => {
        const contact_id = row?.contact?.id ?? null;
        setProviderInvoiceListFilter({
          contact_id,
        });
      }
    : undefined;

  return (
    <>
      {handleItemCheckbox && (
        <TableCell padding="none">
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            onChange={handleItemCheckbox(row.id, row.is_paid)}
            checked={checkedDict?.[row.id]}
            data-testid={`checkbox-${row.id}`}
          />
        </TableCell>
      )}
      {!id ? (
        <TableCell align="left">
          <RedirectProperty property={row?.property} />
        </TableCell>
      ) : null}
      <FileTableCell file={row.file} showDatevIcon={row.has_datev} />
      <TableCell align="left">
        <Typography noWrap>{row.number}</Typography>
      </TableCell>
      <ProviderTableCell
        contact={row.contact}
        onContactShow={() => setContactShow(String(row?.contact?.id))}
        onDocumentShow={onDocumentShow}
        documentActionLabel="invoices"
      />
      <TableCell align="center">{formatTimestamp(row.date)}</TableCell>
      <TableCell align="right">
        <Typography noWrap>
          <NumericFormat
            thousandSeparator={THOUSAND_SEPARATOR}
            decimalSeparator={DECIMAL_SEPARATOR}
            decimalScale={DECIMAL_SCALE}
            fixedDecimalScale
            displayType={"text"}
            value={row.amount}
          />{" "}
          {row.amount !== null && Measurement_Units.EURO}
        </Typography>
      </TableCell>
      <TableCell align="center">
        {row.uploaded_at ? formatTimestamp(row.uploaded_at) : EMPTY_DATA}
      </TableCell>
      <TableCell align="left">
        <ExpandableComment
          seeAllComment={() => handleOpenComments(row)}
          commentData={row?.latest_comment}
        ></ExpandableComment>
      </TableCell>
      <TableCell align="left">
        <Typography noWrap>
          {row.created_by
            ? `${row.created_by?.first_name} ${row.created_by?.last_name}`
            : EMPTY_DATA}
        </Typography>
      </TableCell>
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELEASE_BUTTONS_FR,
          configuration
        ) ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.fr}
              releaseStatus={row?.fr_status}
              forwardedAt={row?.fr_forwarded_at}
              forwardedBy={row?.fr_forwarded_by}
              overdue={row?.fr_overdue}
              afterSuccess={fetchInvoices}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              level={RELEASE_LEVEL.FIRST_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
                  row?.assigned_user
                )
              }
              hasReleasePermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL]
              }
              relation={DOCUMENT_RELEASE_MODULE.INVOICE}
              loading={isDocumentReleaseLoading}
            />
          </TableCell>
        ) : null
      }
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELASE_BUTTONS_AM,
          configuration
        ) ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.am}
              releaseStatus={row?.am_status}
              forwardedAt={row?.am_forwarded_at}
              forwardedBy={row?.am_forwarded_by}
              overdue={row?.am_overdue}
              afterSuccess={fetchInvoices}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              level={RELEASE_LEVEL.SECOND_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
                  row?.assigned_user
                )
              }
              hasReleasePermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL]
              }
              relation={DOCUMENT_RELEASE_MODULE.INVOICE}
              loading={isDocumentReleaseLoading}
            />
          </TableCell>
        ) : null
      }
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELASE_BUTTONS_PM,
          configuration
        ) ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.pm}
              releaseStatus={row?.pm_status}
              forwardedAt={row?.pm_forwarded_at}
              forwardedBy={row?.pm_forwarded_by}
              overdue={row?.pm_overdue}
              afterSuccess={fetchInvoices}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              level={RELEASE_LEVEL.THIRD_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
                  row?.assigned_user
                )
              }
              hasReleasePermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL]
              }
              relation={DOCUMENT_RELEASE_MODULE.INVOICE}
              loading={isDocumentReleaseLoading}
            />
          </TableCell>
        ) : null
      }
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELEASE_BUTTONS_USER,
          configuration
        ) ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.au}
              releaseStatus={row?.au_status}
              forwardedAt={row?.au_forwarded_at}
              forwardedBy={row?.au_forwarded_by}
              overdue={row?.au_overdue}
              afterSuccess={fetchInvoices}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              level={RELEASE_LEVEL.FOURTH_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL]
              }
              hasReleasePermission={releasePermission[
                DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
              ](row?.assigned_user)}
              relation={DOCUMENT_RELEASE_MODULE.INVOICE}
              assignedUser={row?.assigned_user}
              loading={isDocumentReleaseLoading}
              propertyId={row?.property?.id.toString()}
              hasAssignUserPermission={can(PermissionsUpdate.INVOICE)}
            />
          </TableCell>
        ) : null
      }
      {selectedOptionalColumnsIds.includes("bic") && (
        <TableCell>{row.bic || "---"}</TableCell>
      )}
      {selectedOptionalColumnsIds.includes("iban") && (
        <TableCell>{row.iban || "---"}</TableCell>
      )}
      {selectedOptionalColumnsIds.includes("release_date") && (
        <TableCell align="right">
          {row.release_date ? formatTimestamp(row.release_date) : "---"}
        </TableCell>
      )}
      {selectedOptionalColumnsIds.includes("is_debited_automatically") && (
        <TableCell>
          {row.is_debited_automatically ? (
            <CheckCircleOutline color="success" fontSize="medium" />
          ) : (
            <RemoveCircleOutline color="error" fontSize="medium" />
          )}
        </TableCell>
      )}
      {selectedOptionalColumnsIds.includes("is_apportionable") && (
        <TableCell>
          {row.is_apportionable ? (
            <CheckCircleOutline color="success" fontSize="medium" />
          ) : (
            <RemoveCircleOutline color="error" fontSize="medium" />
          )}
        </TableCell>
      )}
      {selectedOptionalColumnsIds.includes("is_active") && (
        <TableCell>
          <div>
            {<IsActiveIcon type={row.is_active ? "success" : "error"} />}
          </div>
        </TableCell>
      )}
      {selectedOptionalColumnsIds.includes("is_permanent") && (
        <TableCell>
          {row.is_permanent ? (
            <CheckCircleOutline color="success" fontSize="medium" />
          ) : (
            <RemoveCircleOutline color="error" fontSize="medium" />
          )}
        </TableCell>
      )}
      {selectedOptionalColumnsIds.includes("contract_file_name") && (
        <TableCell>
          <Tooltip
            title={row.contract?.file ? row.contract?.file.name : ""}
            placement="top"
          >
            <TextEllipsis w="100px">
              {row.contract && row.contract.file ? (
                <Link href={row.contract?.file.url} target="_blank">
                  {row.contract?.file?.name}
                </Link>
              ) : (
                "---"
              )}
            </TextEllipsis>
          </Tooltip>
        </TableCell>
      )}
      {selectedOptionalColumnsIds.includes("offer_file_name") && (
        <TableCell>
          <Tooltip
            title={row.offer?.file ? row.offer.file.name : ""}
            placement="top"
          >
            <TextEllipsis w="100px">
              {row.offer && row.offer.file ? (
                <Link href={row.offer?.file.url} target="_blank">
                  {row.offer?.file.name}
                </Link>
              ) : (
                "---"
              )}
            </TextEllipsis>
          </Tooltip>
        </TableCell>
      )}
      {selectedOptionalColumnsIds.includes("due_date") && (
        <TableCell align={row.due_date ? "right" : "center"}>
          {row.due_date ? formatTimestamp(row.due_date) : "---"}
        </TableCell>
      )}
      <TableCell style={{ whiteSpace: "nowrap" }}>
        <>
          <FormControlLabel
            control={
              <Android12Switch
                checked={row.is_paid || Boolean(row?.is_debited_automatically)}
                onChange={() =>
                  moveToPaid(
                    row.id,
                    row.is_paid || Boolean(row?.is_debited_automatically)
                      ? 0
                      : 1
                  )
                }
                data-testid="toggle-invoice-paid"
                disabled={!can("invoice.accountant")}
              />
            }
            label={t("documentRelease.invoice.paid")}
          />
          {(row.who_paid || Boolean(row?.is_debited_automatically)) && (
            <PaidBy
              {...row.who_paid}
              when_paid={row.when_paid}
              is_debited_automatically={Boolean(row?.is_debited_automatically)}
            />
          )}
        </>
      </TableCell>
      <TableCell style={{ whiteSpace: "nowrap" }}>
        <>
          <FormControlLabel
            control={
              <Android12Switch
                checked={row.is_booked}
                onChange={() => markAsBooked(row.id, row.is_booked)}
                disabled={!can("invoice.accountant")}
              />
            }
            label={
              row.is_booked
                ? t("documentRelease.invoice.booked")
                : t("documentRelease.invoice.notBooked")
            }
          />
        </>
      </TableCell>
      <TableCell
        align="right"
        onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
          handleCellClick(e)
        }
        style={{ whiteSpace: "nowrap" }}
      >
        {isRole(UserRoles.ADMIN) && (
          <DocumentReleaseHistoryIcon
            role={"showHistoryRole"}
            onClick={() => {
              handleHistoryModal(row);
            }}
          />
        )}
        <SendIcon
          role={"sendIconRole"}
          onClick={() => handleForwardClick(row)}
          size={18}
        />
        <ShowIcon
          role={"showIconRole"}
          onClick={(): void => handleShowClick(row)}
          size={18}
        />
        {((can(PermissionsUpdate.INVOICE) && !row?.has_datev) ||
          (can(PermissionsUpdate?.DATEV_INVOICE) && row?.has_datev)) && (
          <EditIcon
            role={"editIconRole"}
            onClick={(): void => handleEditClick(row)}
            size={18}
          />
        )}
        {((can(PermissionsDelete.INVOICE) && !row?.has_datev) ||
          (can(PermissionsDelete?.DATEV_INVOICE) && row?.has_datev)) && (
          <DeleteIcon
            onClick={(): void => openConfirmToRemoveModal(row)}
            size={20}
            role={"deleteIconRole"}
          />
        )}
      </TableCell>
    </>
  );
};

export default InvoiceListRow;
