import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Grid,
  Link,
  Tooltip,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { TableCell } from "../../styled";
import { IdPropType, User } from "../../../../types/models";
import useTable from "../../../../ui/table/useTable";
import Table from "../../../../ui/table/Table";
import {
  FILTER_TYPE,
  prepareQueryParams,
  replaceNullOrUndefinedByEmptyString,
  getFilteredHeadCellsByProperty,
} from "../../../../utils/common";
import { formatTimestamp } from "../../../../utils/date";
import { handleServerError } from "../../../../utils/http";
import { NavLink, useParams } from "react-router-dom";
import {
  getCheckboxItemListHeadCell,
  selectAllInit,
} from "../../invoices/list/utils";
import useAccessControl from "../../../../hooks/useAccessControl";
import { CheckCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  DeleteIcon,
  EditIcon,
  Input,
  Search,
  SearchIconWrapper,
  SendIcon,
  ShowIcon,
} from "../../../../ui/table/styled";
import { route } from "../../../../utils/url";
import { useNavigate } from "react-router";
import { Search as SearchIcon } from "react-feather";
import useIsMounted from "../../../../hooks/useIsMounted";
import { CustomButton } from "../../invoices/list/styled";
import { TextEllipsis, IsActiveIcon } from "../../../../styled";
import ForwardDocumentDialog from "../../invoices/forwardDocument/ForwardDocumentDialog";
import Snackbar from "../../../../ui/Snackbar";
import { NumericFormat } from "react-number-format";
import {
  DECIMAL_SEPARATOR,
  DECIMAL_SCALE,
  Measurement_Units,
  THOUSAND_SEPARATOR,
  COMMENTABLE,
  PermissionsCreate,
  PermissionsDelete,
  PermissionsUpdate,
  EMPTY_DATA,
  CURRENCIES,
} from "../../../../constants";
import CommentsPopup from "../../../../ui/commentsPopUp";
import { showCommentsModalInitState } from "../../../../ui/commentsPopUp/utils";
import { Android12Switch } from "../../../../ui/formsFields/switch/styled";
import { useFetch } from "../../../../hooks/useFetch";
import { getProperties } from "../../../../api/property";
import { getItemsWithActiveReleasePermissions } from "../../utils";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { CheckedDict, InvoiceFilterProps } from "../../invoices/list/types";
import Loader from "../../../Loader";
import { StyledButton } from "../../../../ui/button/styled";
import ExpandableComment from "../../../../ui/expandableComment/ExpandableComment";
import { ReleaseConfiguration as BEReleaseConfiguration } from "../../../../types/be/releaseFlow";
import { getConfiguration } from "../../../../api/releaseFlow";
import HZScrollController from "../../../../ui/HZScrollController";
import {
  deleteOutgoingInvoice,
  getOutgoingInvoices,
  sendBulkOutgoingInvoice,
  updateOutgoingInvoice,
} from "../../../../api/outgoingInvoice";
import { itemsListHeadCells } from "./utils";
import { OutgoingInvoice } from "../../../../types/be/outgoingInvoices";
import RedirectProperty from "../../../../ui/redirectProperty";
import Dropdown from "../../../../ui/dropdown";
import { usePropertySelect } from "../../../../hooks/usePropertySelect";
import ConfirmationModal from "../../../../ui/confirmationModal";
import { StyledSendButton } from "./styled";
import MuiFileDownloadIcon from "@mui/icons-material/FileDownload";
import { downloadZipFile } from "../../../../api/files";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { Invoice } from "../../../../types/be.interfaces";
import last from "lodash/last";
import TabPageWrapper from "../../../../ui/tabPageWrapper";
import FileTableCell from "../../components/fileTableCell";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
/* istanbul ignore next */
const OutgoingInvoiceList = (): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { can } = useAccessControl();
  const navigate = useNavigate();
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [isShowCommentsModal, setIsShowCommentsModal] = useState(
    showCommentsModalInitState
  );
  const [checkedDict, setCheckedDict] = useState<CheckedDict | null>(null);
  const [selectAll, setSelectAll] = useState(selectAllInit);
  const [forwardInvoiceId, setForwardInvoiceId] = useState<number | null>(null);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const { propertyId, property, isGeneral, onPropertySelect } =
    usePropertySelect(id);

  const [isArchiveLoading, setIsArchiveLoading] = useState(false);

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleDeleteLastPageData,
  } = useTable<User>();

  const {
    data: configuration,
    isLoading: isConfigurationLoading,
    run: runConfiguration,
  } = useFetch<BEReleaseConfiguration[]>();

  useEffect(() => {
    runConfiguration(getConfiguration(`?module_code=invoice`));
  }, []);

  const resetCheckedDict = useCallback(
    (value = false): void => {
      /* istanbul ignore next */
      if (!data) return;
      const auxCheckedDict: CheckedDict = {};
      data.forEach((el: OutgoingInvoice) => {
        auxCheckedDict[el.id] = value;
      });
      setCheckedDict(auxCheckedDict);
    },
    [data]
  );

  const selected =
    checkedDict &&
    Object.keys(checkedDict)
      .filter((el) => checkedDict[el])
      .map((el) => Number(el));

  const updateStatus = async (): Promise<void> => {
    setConfirmationVisible(false);
    setUpdateLoading(true);
    const response = await sendBulkOutgoingInvoice(selected!);
    if (!response.ok) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      resetCheckedDict();
      resetSelectAll();
      handleDeleteLastPageData(fetchOutgoingInvoices);
    }
    setUpdateLoading(false);
  };

  const resetSelectAll = (): void => setSelectAll(selectAllInit);

  useEffect(() => {
    resetCheckedDict();
    resetSelectAll();
  }, [resetCheckedDict]);

  const fetchOutgoingInvoices = useCallback(
    async (loading = true) => {
      setIsLoading(loading);

      const params: InvoiceFilterProps = {};

      if (isGeneral === FILTER_TYPE.GENERAL || propertyId || id) {
        params.property_id = propertyId || id || "";
      }

      const response = await getOutgoingInvoices(
        prepareQueryParams(
          queryParams,
          replaceNullOrUndefinedByEmptyString(params),
          true
        )
      );

      if (response.status !== 200) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        const resJson = await response.json();
        setTotal(resJson.meta.total);
        setData(resJson.data);
        setIsLoading(false);
      }
    },
    [
      propertyId,
      queryParams,
      setIsLoading,
      setTotal,
      setData,
      setIsLoading,
      isGeneral,
    ]
  );

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteOutgoingInvoice(rowToDelete);
    handleDeleteLastPageData(fetchOutgoingInvoices);
    handleConfirmToRemoveModalClose();
  }, [rowToDelete, fetchOutgoingInvoices, handleConfirmToRemoveModalClose]);

  const tableHeaderTitles = useMemo(() => {
    const checkboxItemListHeadCell = getCheckboxItemListHeadCell(
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={selectAll}
        data-testid={"select-all"}
        onChange={({
          target: { checked },
        }: React.ChangeEvent<HTMLInputElement>) => {
          setSelectAll(checked);
          resetCheckedDict(checked);
        }}
      />
    );

    const filteredItems = getFilteredHeadCellsByProperty(
      itemsListHeadCells,
      "property_name",
      id
    );

    return [
      checkboxItemListHeadCell,
      ...getItemsWithActiveReleasePermissions(filteredItems, configuration),
    ];
  }, [resetCheckedDict, selectAll, configuration]);

  const handleEditClick = useCallback(
    ({ id: invoiceId }: IdPropType): void => {
      navigate(`${invoiceId}/edit`);
    },
    [id]
  );

  const handleShowClick = useCallback(
    ({ id: invoiceId }: IdPropType): void => {
      navigate(`${invoiceId}/show`);
    },
    [id]
  );

  const handleForwardClick = useCallback(
    ({ id: invoiceId }: IdPropType): void => {
      setForwardInvoiceId(invoiceId);
    },
    []
  );

  const handleCloseForwardModal = useCallback(() => {
    setForwardInvoiceId(null);
  }, []);

  const handleCloseSnackbar = useCallback(() => {
    setSnackbarVisible(false);
  }, []);

  useEffect(() => {
    isMounted() && fetchOutgoingInvoices();
  }, [queryParams, propertyId, isGeneral]);

  const handleOpenComments = (row: OutgoingInvoice): void => {
    setIsShowCommentsModal({ isVisible: true, rowId: row?.id });
  };

  const handleCloseCommentsModal = (): void => {
    setIsShowCommentsModal(showCommentsModalInitState);
    fetchOutgoingInvoices(false);
  };

  const handleItemCheckbox =
    (id: number) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setCheckedDict((state) => ({
        ...state,
        [id]: event.target.checked,
      }));
      resetSelectAll();
    };

  const markAsBooked = async (invoice: OutgoingInvoice): Promise<void> => {
    const response = await updateOutgoingInvoice(String(invoice.id), {
      is_booked: !invoice.is_booked,
      services: { ...invoice.services },
    });
    if (response.ok) {
      await fetchOutgoingInvoices();
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }
  };

  const isMounted = useIsMounted();

  const getListInvoicesParamValues = useCallback((): string[] => {
    if (!checkedDict) return [];
    const invoiceIdsToDownload = Object.keys(checkedDict)
      .filter((el) => checkedDict[el])
      .map((el) => Number(el));

    return data
      ?.filter(
        ({ id, file }: Invoice) =>
          invoiceIdsToDownload.includes(id) && file?.url
      )
      .map(({ file: { url } }: Invoice) => last(url.split("/")))
      .filter(
        (el: string, index: number, self: string[]) =>
          self.indexOf(el) === index
      );
  }, [checkedDict, data]);

  const handleDownload = async (): Promise<void> => {
    const listInvoicesParamValues = getListInvoicesParamValues();
    if (!listInvoicesParamValues.length) return;
    setIsArchiveLoading(true);
    const response = await downloadZipFile(listInvoicesParamValues);
    if (
      ![HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      const { errorMessage } = handleServerError(response);
      setIsArchiveLoading(false);
      return setError(errorMessage);
    }
    const blob = await response.blob();
    const href = window.URL.createObjectURL(blob);
    setIsArchiveLoading(false);
    const link = document.createElement("a");
    link.href = href;
    document.body.appendChild(link);
    link.click();
    link.href = "";
    resetCheckedDict();

    resetSelectAll();
  };

  // <Grid item xs={9}>
  //           <Grid container alignItems={"center"} spacing={2}>
  //             {!id && (
  //               <>
  //                 <Grid item xs={12} sm={6} md={4} lg={3}>
  //                   <Dropdown
  //                     id="property-autocomplete"
  //                     name="property_id"
  //                     placeholder={t("documentRelease.invoice.searchProperty")}
  //                     getOptions={getProperties}
  //                     onSelectOption={onPropertySelect}
  //                     optionLabel="object_name"
  //                     size="small"
  //                     defaultValue={property}
  //                     defaultOption={[generalPropertyOption]}
  //                   />
  //                 </Grid>
  //               </>
  //             )}
  //           </Grid>
  //         </Grid>
  //         <Grid item xs />
  //         <Grid item sx={{ display: "flex", alignItems: "center" }} gap={2}></Grid>

  const TableToolbar = (
    <Grid
      container
      mt={3}
      justifyContent={id ? "space-between" : "flex-end"}
      alignItems={"center"}
    >
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          {can(PermissionsCreate.INVOICE) && (
            <>
              <Grid item>
                <StyledButton
                  data-testid={"dataItems-link"}
                  component={NavLink}
                  to={
                    id
                      ? route("properties.outgoing-invoices.create", id)
                      : route("outgoing-invoices.create")
                  }
                  size="small"
                  color="success"
                  variant="contained"
                >
                  {t("documentRelease.invoice.createInvoice")}
                </StyledButton>
              </Grid>
            </>
          )}
          <>
            <Grid item>
              <Tooltip
                title={
                  selected?.length === 0
                    ? t(`documentRelease.outgoingInvoice.selectInvoiceToSend`)
                    : null
                }
              >
                <span>
                  <StyledSendButton
                    testId="send-button"
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setConfirmationVisible(true);
                    }}
                    disabled={selected?.length === 0 || updateLoading}
                    isLoading={updateLoading}
                    title={t("documentRelease.outgoingInvoice.sendInvoice")}
                  />
                </span>
              </Tooltip>
            </Grid>
          </>
          {!id && (
            <>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Dropdown
                  id="property-autocomplete"
                  name="property_id"
                  placeholder={t("documentRelease.invoice.searchProperty")}
                  getOptions={getProperties}
                  onSelectOption={onPropertySelect}
                  optionLabel="object_name"
                  size="small"
                  defaultValue={property}
                />
              </Grid>
            </>
          )}
          <Grid item lg />
          <Grid
            item
            sx={{ display: "flex", alignItems: "center" }}
            gap={2}
            pr={2}
            pl={2}
          >
            <HZScrollController />
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t("search")} onChange={handleTableSearch} />
            </Search>
            <Tooltip
              title={
                selected?.length === 0
                  ? t(`documentRelease.outgoingInvoice.selectInvoiceToDownload`)
                  : t("tenant.download")
              }
            >
              <span>
                <CustomButton
                  onClick={handleDownload}
                  title={""}
                  type="submit"
                  disabled={selected?.length === 0 || isArchiveLoading}
                  isLoading={isArchiveLoading}
                  testId="download-invoices"
                  startIcon={
                    !isArchiveLoading ? <MuiFileDownloadIcon /> : undefined
                  }
                />
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderRow = (row: OutgoingInvoice): ReactElement => (
    <>
      <TableCell padding="none">
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          onChange={handleItemCheckbox(row.id)}
          checked={checkedDict?.[row.id]}
          data-testid={`checkbox-${row.id}`}
        />
      </TableCell>
      {!id ? (
        <TableCell align="left">
          <RedirectProperty property={row?.property} />
        </TableCell>
      ) : null}
      <FileTableCell file={row?.file} />
      <TableCell align="left">
        <Typography noWrap>{row.number}</Typography>
      </TableCell>
      <TableCell align="center">
        {row.send_date ? formatTimestamp(row.send_date) : EMPTY_DATA}
      </TableCell>
      <TableCell align="center">{formatTimestamp(row.date)}</TableCell>
      <TableCell align="right">
        <Typography noWrap>
          <NumericFormat
            thousandSeparator={THOUSAND_SEPARATOR}
            decimalSeparator={DECIMAL_SEPARATOR}
            decimalScale={DECIMAL_SCALE}
            fixedDecimalScale
            displayType={"text"}
            value={row.amount}
          />{" "}
          {row.amount !== null && row.currency_code === CURRENCIES[0]
            ? Measurement_Units.EURO
            : Measurement_Units.DOLLAR}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <ExpandableComment
          seeAllComment={() => handleOpenComments(row)}
          commentData={row?.latest_comment}
        />
      </TableCell>
      <TableCell>
        <div>{<IsActiveIcon type={row.is_active ? "success" : "error"} />}</div>
      </TableCell>
      <TableCell>
        {row.is_permanent ? (
          <CheckCircleOutline color="success" fontSize="medium" />
        ) : (
          <RemoveCircleOutline color="error" fontSize="medium" />
        )}
      </TableCell>
      <TableCell>
        <Tooltip
          title={row.contract?.file ? row.contract?.file.name : ""}
          placement="top"
        >
          <TextEllipsis w="100px">
            {row.contract && row.contract.file ? (
              <Link href={row.contract?.file.url} target="_blank">
                {row.contract?.file?.name}
              </Link>
            ) : (
              EMPTY_DATA
            )}
          </TextEllipsis>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          title={row.offer?.file ? row.offer.file.name : ""}
          placement="top"
        >
          <TextEllipsis w="100px">
            {row.offer && row.offer.file ? (
              <Link href={row.offer?.file.url} target="_blank">
                {row.offer?.file.name}
              </Link>
            ) : (
              EMPTY_DATA
            )}
          </TextEllipsis>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Typography noWrap>
          {row.creator
            ? `${row.creator?.first_name} ${row.creator?.last_name}`
            : EMPTY_DATA}
        </Typography>
      </TableCell>
      <TableCell align="left">
        {row.created_at ? formatTimestamp(row.created_at) : EMPTY_DATA}
      </TableCell>
      <TableCell padding="none" style={{ whiteSpace: "nowrap" }}>
        <>
          <FormControlLabel
            control={
              <Android12Switch
                checked={row.is_booked}
                onChange={() => markAsBooked(row)}
                disabled={!can("invoice.accountant")}
              />
            }
            label={
              row.is_booked
                ? t("documentRelease.invoice.booked")
                : t("documentRelease.invoice.notBooked")
            }
          />
        </>
      </TableCell>
      <TableCell
        align="right"
        onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
          handleCellClick(e)
        }
        style={{ whiteSpace: "nowrap" }}
      >
        <SendIcon
          role={"sendIconRole"}
          onClick={() => handleForwardClick(row)}
          size={18}
        />
        <ShowIcon
          role={"showIconRole"}
          onClick={(): void => handleShowClick(row)}
          size={18}
        />
        {can(PermissionsUpdate.INVOICE) && (
          <EditIcon
            role={"editIconRole"}
            onClick={(): void => handleEditClick(row)}
            size={18}
          />
        )}
        {can(PermissionsDelete.INVOICE) && (
          <DeleteIcon
            onClick={(): void => openConfirmToRemoveModal(row)}
            size={20}
            role={"deleteIconRole"}
          />
        )}
      </TableCell>
    </>
  );

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
  ];

  if (isConfigurationLoading) {
    return <Loader />;
  }

  return (
    <>
      <TabPageWrapper
        breadcrumbs={breadcrumbs}
        title={t("outgoingInvoice")}
        wrap={!id}
      >
        <>
          <Table
            data={data}
            total={total}
            currentPage={currentPage}
            order={order}
            orderBy={orderBy}
            error={error}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
            onRowRemove={handleRowRemove}
            onSortChange={handleSortChange}
            onSelectAllClick={handleSelectAllClick}
            onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading || isConfigurationLoading}
            isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
            listHeadCells={tableHeaderTitles}
            renderRow={renderRow}
            tableToolbar={TableToolbar}
            noDataIsAvailablePlaceholder={t("table.noInvoicesAreAvailable")}
            tableDataMaxHeight={"75vh"}
            confirmationText={t("documentRelease.invoice.deleteConfirmation")}
            refId="hzScroll"
          />
          {forwardInvoiceId ? (
            <ForwardDocumentDialog
              isOpen={true}
              handleCloseForwardModal={handleCloseForwardModal}
              entityId={forwardInvoiceId}
              entityType="outgoing-invoices"
              setSnackbarVisible={setSnackbarVisible}
              title={t("documentRelease.invoice.forwardInvoice")}
            />
          ) : null}
          <Snackbar
            message={t("documentRelease.invoice.invoiceForwardSuccess")}
            color="success"
            open={snackbarVisible}
            handleClose={handleCloseSnackbar}
            data-testid="snackbar-invoice-success"
          />
          <ConfirmationModal
            titleText={t("documentRelease.outgoingInvoice.sendConformation")}
            visible={confirmationVisible}
            confirmText={t("yes")}
            cancelText={t("no")}
            setVisible={setConfirmationVisible}
            handleCancel={() => setConfirmationVisible(false)}
            handleConfirm={updateStatus}
          />
          {isShowCommentsModal.isVisible && (
            <CommentsPopup
              handleCloseDialog={handleCloseCommentsModal}
              id={String(isShowCommentsModal?.rowId)}
              relation={COMMENTABLE.OUTGOING_INVOICES}
            />
          )}
        </>
      </TabPageWrapper>
    </>
  );
};

export default OutgoingInvoiceList;
