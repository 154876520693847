import React, { ReactElement } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "../dialog/DialogTitle";
import FileTree from "../../components/properties/components/fileTree";
import Button from "../button/Button";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import useFileManagement from "../../hooks/useFileManagement";

interface FileListProps {
  onClose?: () => void;
  handleFileTreeClick?: (id: string, name: string) => void;
  isOcrLoading?: boolean;
  extensions?: string[];
}
/* istanbul ignore next */
const FileList = ({
  onClose,
  handleFileTreeClick,
  isOcrLoading,
  extensions,
}: FileListProps): ReactElement => {
  const { t } = useTranslation();

  const {
    data: folders,
    selected,
    isFolderLoading,
    isFoldersLoading,
    handleFolderClick,
  } = useFileManagement({ extensions });

  return (
    <Dialog
      maxWidth={"lg"}
      open={true}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle
        renderElement={isOcrLoading ? <Loader size={25} /> : undefined}
        title="property.selectAnyFileToLink"
        onClose={onClose}
      />
      <DialogContent
        dividers
        sx={{
          height: isFoldersLoading ? "100vh" : "auto",
          overflowX: "hidden",
        }}
      >
        {isFoldersLoading && <Loader />}
        {folders && (
          <FileTree
            data={folders}
            handleFolderClick={handleFolderClick}
            isLoading={isFolderLoading}
            handleFileClick={handleFileTreeClick}
            selected={selected}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button title={t("cancel")} onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

export default FileList;
