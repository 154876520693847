import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Grid, Link, TableCell, Tooltip } from "@mui/material";
import {
  DeleteIcon,
  Search,
  SearchIconWrapper,
  Input,
} from "../../../../../ui/table/styled";
import {
  Reminder,
  ReminderStatusCodes,
} from "../../../../../types/be/reminder";
import useTable from "../../../../../ui/table/useTable";
import Table from "../../../../../ui/table/Table";
import {
  deleteReminder,
  getReminderList,
  updateReminder,
} from "../../../../../api/reminder";
import { HTTP_STATUS_CODES } from "../../../../../types/server";
import { handleServerError } from "../../../../../utils/http";
import {
  FILTER_TYPE,
  prepareQueryParams,
  replaceNullOrUndefinedByEmptyString,
  getFilteredHeadCellsByProperty,
  generalPropertyOption,
} from "../../../../../utils/common";
import { useTranslation } from "react-i18next";
import {
  handleAssignInvoice,
  handleAssignProperty,
  itemsListHeadCells,
} from "./utils";
import { formatTimestamp } from "../../../../../utils/date";
import { DoneAll, RemoveDone } from "@mui/icons-material";
import {
  COMMENTABLE,
  EMPTY_DATA,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../../constants";
import { showCommentsModalInitState } from "../../../../../ui/commentsPopUp/utils";
import CommentsPopup from "../../../../../ui/commentsPopUp";
import { useParams, useNavigate } from "react-router-dom";
import { route } from "../../../../../utils/url";
import { TabButton } from "./styled";
import Snackbar from "../../../../../ui/Snackbar";
import Button from "../../../../..//ui/button/Button";
import { Search as SearchIcon } from "react-feather";
import useReminderStatus from "../../../../../hooks/useReminderStatus";
import { getReleaseRoute } from "../../../utils";
import { getProperties } from "../../../../../api/property";
import Loader from "../../../../Loader";
import CloseButton from "../../../../../ui/dialog/CloseButton";
import { snackbarInitState } from "../../../../inbox/list/utils";
import { SnackbarMessage } from "../../types";
import AssignInvoiceDialog from "../../assignInvoiceDialog";
import AssignPropertyDialog from "../../../../inbox/assignPropertyDialog";
import { StyledButton } from "../../../../../ui/button/styled";
import RedirectProperty from "../../../../../ui/redirectProperty";
import Dropdown from "../../../../../ui/dropdown";
import { usePropertySelect } from "../../../../../hooks/usePropertySelect";
import ExpandableComment from "../../../../../ui/expandableComment/ExpandableComment";
import TabPageWrapper from "../../../../../ui/tabPageWrapper";

const ReminderList = (): ReactElement => {
  const { t } = useTranslation();
  const { status = ReminderStatusCodes.new, id } = useParams();
  const navigate = useNavigate();

  const { propertyId, property, isGeneral, onPropertySelect } =
    usePropertySelect(id);

  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleSortChange,
    handleSelectAllClick,
    setTotal,
    openConfirmToRemoveModal,
    rowToDelete,
    handleDeleteLastPageData,
    handleConfirmToRemoveModalClose,
    handleTableSearch,
  } = useTable<Reminder>();
  const { findReminderStatus, statusList } = useReminderStatus();
  const [removingInvoice, setRemovingInvoice] = useState<string | null>(null);
  const [isShowCommentsModal, setIsShowCommentsModal] = useState(
    showCommentsModalInitState
  );

  const [snackbarData, setSnackbarData] =
    useState<SnackbarMessage>(snackbarInitState);
  const [selectedReminder, setSelectedReminder] = useState<Reminder | null>(
    null
  );
  const [showAssignProperty, setShowAssignProperty] = useState<boolean>(false);
  const [showAssignInvoice, setShowAssignInvoice] = useState<boolean>(false);
  const [removingProperty, setRemovingProperty] = useState<string | null>(null);

  const handleCloseSnackbar = (): void => setSnackbarData(snackbarInitState);

  const handleOpenComments = useCallback((row: Reminder): void => {
    setIsShowCommentsModal({ isVisible: true, rowId: row?.id });
  }, []);

  const handleFetchList = useCallback(async () => {
    const statusId = findReminderStatus(status)?.id?.toString();

    const payload: Record<string, string | undefined> = {
      status_id: statusId,
    };

    /* istanbul ignore next */
    if (isGeneral === FILTER_TYPE.GENERAL || propertyId || id) {
      payload.property_id = propertyId || id || "";
    }

    const params = prepareQueryParams(
      queryParams,
      replaceNullOrUndefinedByEmptyString(payload),
      true
    );

    const response = await getReminderList(params);

    if (response.status !== HTTP_STATUS_CODES.OK) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const json = await response.json();
      setData(json?.data);
      setTotal(json?.meta?.total);
    }

    setIsLoading(false);
  }, [queryParams, id, status, findReminderStatus, propertyId, isGeneral]);

  const handleCloseCommentsModal = useCallback((): void => {
    setIsShowCommentsModal(showCommentsModalInitState);
    handleFetchList();
  }, [handleFetchList]);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteReminder(rowToDelete.id);
    handleDeleteLastPageData(handleFetchList);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteReminder,
    handleFetchList,
    handleConfirmToRemoveModalClose,
  ]);

  const updateReminderStatus = useCallback(
    async (row: Reminder, newStatus) => {
      const status = findReminderStatus(newStatus);
      const statusId = status?.id;
      const updateData = {
        status_id: statusId!,
      };
      const response = await updateReminder(row.id, updateData);
      if (response.status !== HTTP_STATUS_CODES.OK) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        handleFetchList();
      }
    },
    [findReminderStatus, status, handleFetchList, updateReminder]
  );

  const navigateToCreate = (): void => {
    if (id) {
      navigate(route("properties.invoice.reminders.create", id));
    } else {
      navigate(route("invoice.reminders.create"));
    }
  };

  useEffect(() => {
    setIsLoading(true);
    queryParams && statusList && handleFetchList();
  }, [queryParams, id, status, statusList, propertyId, isGeneral]);

  const handleRemoveInvoice = useCallback(
    async (id: string, showMessage = true): Promise<void> => {
      setRemovingInvoice(id);
      const response = await updateReminder(Number(id), { relation_id: null });
      if (response.status === HTTP_STATUS_CODES.OK) {
        await handleFetchList();
        showMessage &&
          setSnackbarData({
            visible: true,
            text: t("reminder.invoiceRemovedSuccessfully"),
            color: "success",
          });
      } else {
        setSnackbarData({
          visible: true,
          text: t(SOMETHING_WENT_WRONG_ERROR),
          color: "error",
        });
      }
      setRemovingInvoice(null);
    },
    [updateReminder, handleFetchList]
  );

  const afterAssignInvoice = useCallback(async () => {
    await handleFetchList();
    setSelectedReminder(null);
    setShowAssignProperty(false);
    setShowAssignInvoice(false);
  }, [handleFetchList]);

  const afterAssignProperty = useCallback(
    async (assignedPropertyId) => {
      if (
        selectedReminder?.entity &&
        selectedReminder?.entity?.property?.id != assignedPropertyId
      ) {
        await handleRemoveInvoice(String(selectedReminder.id), false);
      }
      await handleFetchList();
      setSelectedReminder(null);
      setShowAssignProperty(false);
      setShowAssignInvoice(false);
    },
    [handleFetchList, handleRemoveInvoice, selectedReminder]
  );

  const handleRemoveProperty = useCallback(
    async (id: string): Promise<void> => {
      setRemovingProperty(id);
      const response = await updateReminder(Number(id), { property_id: null });
      if (response.status === HTTP_STATUS_CODES.OK) {
        await handleFetchList();
        setSnackbarData({
          visible: true,
          text: t("inbox.propertyRemovedSuccessfully"),
          color: "success",
        });
      } else {
        setSnackbarData({
          visible: true,
          text: t(SOMETHING_WENT_WRONG_ERROR),
          color: "error",
        });
      }
      setRemovingProperty(null);
    },
    [updateReminder, handleFetchList]
  );

  const TableToolbar = (
    <Grid container mt={1} mb={2} spacing={2}>
      <Grid item xs={12}>
        <Grid container mt={1}>
          {statusList?.map((item) => (
            <Grid item key={`$status-option-${item.code}`} py={3} pr={4}>
              <TabButton
                size="small"
                disabled={status === item.code}
                key={item.code}
                onClick={() =>
                  navigate(
                    id
                      ? route("properties.invoice.reminders.tab", id, item.code)
                      : route("invoice.reminders.tab", item.code)
                  )
                }
                title={t(`reminder.status.${item?.code}`)}
                variant="text"
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          mt={1}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Grid item xs={6} display="flex" alignItems={"center"} gap={2}>
            {status === ReminderStatusCodes.new ? (
              <Grid item>
                <StyledButton
                  size="small"
                  color="success"
                  variant="contained"
                  onClick={() => navigateToCreate()}
                  data-testid="create-topic-button"
                >
                  {t("reminder.create.title")}
                </StyledButton>
              </Grid>
            ) : null}
            {!id ? (
              <>
                <Grid item lg={4} xs={12} md={4} sm={6}>
                  <Dropdown
                    id="property-autocomplete"
                    name="property_id"
                    placeholder={t("documentRelease.invoice.searchProperty")}
                    getOptions={getProperties}
                    onSelectOption={onPropertySelect}
                    optionLabel="object_name"
                    size="small"
                    defaultValue={property}
                    defaultOption={[generalPropertyOption]}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
          <Grid item>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t("search")} onChange={handleTableSearch} />
            </Search>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderRow = (row: Reminder): ReactElement => {
    return (
      <>
        <TableCell align="left">
          {row.user ? `${row.user.first_name} ${row.user.last_name}` : "---"}
        </TableCell>
        <TableCell align="left">
          <Link href={row.file.url} target="_blank">
            {row.file.name}
          </Link>
        </TableCell>
        {!id ? (
          <TableCell align="left" padding="none" sx={{ maxWidth: "250px" }}>
            {row.status.code === ReminderStatusCodes.new ? (
              row?.property ? (
                <Grid display="flex" gap={2} alignItems="center">
                  <span>
                    <RedirectProperty property={row?.property} />
                  </span>
                  {!id &&
                    (removingProperty === String(row?.id) ? (
                      <Loader size={15} />
                    ) : (
                      <CloseButton
                        onClick={() => handleRemoveProperty(String(row?.id))}
                        data-testid="remove-property-button"
                      />
                    ))}
                </Grid>
              ) : (
                <Button
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setSelectedReminder(row);
                    setShowAssignProperty(true);
                  }}
                  title={t("bankAccount.assignProperty")}
                />
              )
            ) : (
              <span>
                <RedirectProperty property={row?.property} />
              </span>
            )}
          </TableCell>
        ) : null}
        <TableCell align="left">
          <ExpandableComment
            seeAllComment={() => handleOpenComments(row)}
            commentData={row?.latest_comment}
          ></ExpandableComment>
        </TableCell>
        <TableCell align="center">{formatTimestamp(row.created_at)}</TableCell>
        <TableCell align="left">
          {row.status.code === ReminderStatusCodes.new ? (
            row?.entity ? (
              <Grid display="flex" gap={2} alignItems="center">
                <span>{row.entity.number}</span>
                {removingInvoice === String(row?.id) ? (
                  <Loader size={15} />
                ) : (
                  <CloseButton
                    onClick={() => handleRemoveInvoice(String(row?.id))}
                    data-testid="remove-invoice-button"
                  />
                )}
              </Grid>
            ) : (
              <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => {
                  setSelectedReminder(row);
                  setShowAssignInvoice(true);
                }}
                title={t("reminder.assignInvoice")}
              />
            )
          ) : (
            <span>{row.entity?.number || EMPTY_DATA}</span>
          )}
        </TableCell>
        <TableCell align="left">
          <Grid container justifyContent="flex-end" flexWrap={"nowrap"}>
            {row.status.code !== ReminderStatusCodes.completed ? (
              <Grid item>
                <Tooltip title={t(`inbox.complete`)} placement="top">
                  <Grid container>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <DoneAll
                        color="success"
                        width={22}
                        height={22}
                        onClick={() =>
                          updateReminderStatus(
                            row,
                            ReminderStatusCodes.completed
                          )
                        }
                        data-testid="complete-icon"
                      />
                    </Grid>
                  </Grid>
                </Tooltip>
              </Grid>
            ) : (
              <Grid item>
                <Tooltip title={t(`inbox.incomplete`)} placement="top">
                  <Grid container>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <RemoveDone
                        color="success"
                        width={22}
                        height={22}
                        fontSize="small"
                        onClick={() =>
                          updateReminderStatus(row, ReminderStatusCodes.new)
                        }
                        data-testid="cancel-icon"
                      />
                    </Grid>
                  </Grid>
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              <DeleteIcon
                role="DeleteIconRole"
                size={20}
                onClick={() => openConfirmToRemoveModal(row)}
              />
            </Grid>
          </Grid>
        </TableCell>
      </>
    );
  };

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("invoices", Number(id)),
      name: t("property.navLinks.invoices"),
    },
  ];

  const tableHeaderTitles = getFilteredHeadCellsByProperty(
    itemsListHeadCells,
    "propertyName",
    id
  );

  return (
    <TabPageWrapper
      breadcrumbs={breadcrumbs}
      title={t(`reminder.title`)}
      wrap={!id}
    >
      <>
        <Table
          data={data}
          total={total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={tableHeaderTitles}
          renderRow={renderRow}
          tableToolbar={TableToolbar}
          onRowRemove={handleRowRemove}
          noDataIsAvailablePlaceholder={"table.noRemindersAreAvailable"}
        />
        {isShowCommentsModal.isVisible && (
          <CommentsPopup
            handleCloseDialog={handleCloseCommentsModal}
            id={String(isShowCommentsModal?.rowId)}
            relation={COMMENTABLE.REMINDER}
          />
        )}
        <Snackbar
          message={snackbarData.text}
          color={snackbarData.color}
          open={snackbarData.visible}
          handleClose={handleCloseSnackbar}
        />
        {selectedReminder?.id && showAssignInvoice && (
          <AssignInvoiceDialog
            id={String(selectedReminder?.id)}
            onClose={() => {
              setSelectedReminder(null);
              setShowAssignInvoice(false);
            }}
            open={true}
            afterAssignInvoice={afterAssignInvoice}
            handleAssignInvoice={handleAssignInvoice}
            propertyId={String(id || selectedReminder.property?.id || "")}
          />
        )}

        {selectedReminder?.id && showAssignProperty && (
          <AssignPropertyDialog
            id={String(selectedReminder?.id)}
            onClose={() => {
              setSelectedReminder(null);
              setShowAssignProperty(false);
            }}
            open={true}
            afterAssignProperty={afterAssignProperty}
            handleAssignProperty={handleAssignProperty}
          />
        )}
      </>
    </TabPageWrapper>
  );
};

export default ReminderList;
