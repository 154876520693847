import styled from "styled-components/macro";
import { Alert, Box } from "@mui/material";
import CheckCircleTwoToneIconMui from "@mui/icons-material/CheckCircleTwoTone";
import HourglassTopTwoToneIconMui from "@mui/icons-material/HourglassTopTwoTone";

export const CheckCircleTwoToneIcon = styled(CheckCircleTwoToneIconMui)`
  margin-left: ${(props): string => props.theme.spacing(1.5)};
  margin-top: -${(props): string => props.theme.spacing(0.5)};
`;

export const HourglassTopTwoToneIcon = styled(HourglassTopTwoToneIconMui)`
  margin-left: ${(props): string => props.theme.spacing(1.5)};
  margin-top: -${(props): string => props.theme.spacing(0.5)};
`;

export const CircularProgressWithLabel = styled(Box)`
  position: relative;
  display: inline-flex;
`;

export const Label = styled(Box)`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArchiveFolderAlert = styled(Alert)`
  width: 100%;
`;
