import React, { useEffect } from "react";
import Button from "../../../../ui/button/Button";
import { Form, FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import { Grid, Stack } from "@mui/material";
import { TextFieldCustom } from "../styled";

const LocationForm = ({
  handleSubmit,
  isSubmitting,
  values,
  status,
  isCreate,
  touched,
  row,
  errors,
  setLocationItemId,
  setLocationItemName,
}: FormikValues): React.ReactElement => {
  const { t } = useTranslation();

  useEffect(() => {
    if (row) {
      setLocationItemName(row.name);
      setLocationItemId(row.id);
    }
  }, [row]);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setLocationItemName(e.target.value);
  };

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={6}>
        <Grid item>
          <TextFieldCustom
            value={values.name || ""}
            name="name"
            label={t(
              isCreate
                ? "employeeOverview.createLocation"
                : "employeeOverview.editLocation"
            )}
            inputProps={{ "data-testid": "location-input" }}
            status={status}
            error={Boolean(
              touched.name && (status?.errors.name || errors.name)
            )}
            helperText={touched.name && (status?.errors.name || errors.name)}
            onChange={handleOnChange}
            size="small"
          />
        </Grid>
        <Grid item>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={0}
            mt={1.5}
          >
            <Button
              color="success"
              testId="create-user-submit"
              title={t(isCreate ? "create" : "update")}
              type="submit"
              size="medium"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            />
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
};

export default LocationForm;
