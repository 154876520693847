import FolderIcon from "@mui/icons-material/Folder";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import styled from "styled-components/macro";
import { grey } from "@mui/material/colors";
import MuiFileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Dialog as MuiDialog,
  CircularProgress as MuiCircularProgress,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
} from "@mui/material";
import {
  InputBase,
  Tooltip as MuiTooltip,
  Grid as MuiGrid,
  Grid,
  Box as MuiBox,
  Button as MuiButton,
} from "@mui/material";
import { TooltipProps } from "./types";
import { blue } from "@mui/material/colors";
import { darken } from "polished";

export const FolderIconStyled = styled(FolderIcon)`
  color: ${grey[600]};
  transition: all 0.2s;
`;

export const UploadFileIconStyled = styled(UploadFileIcon)`
  color: ${grey[600]};
  transition: all 0.2s;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const Tooltip = styled(MuiTooltip)<TooltipProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 6px;
  &:hover {
    border: 1px solid ${(props): string => props.theme.palette.background.paper};
    & svg {
      color: ${blue[900]};
    }
  }
`;

export const GridWithPadding = styled(MuiGrid)`
  padding: ${(props): string => props.theme.spacing(2)};
  padding-bottom: ${(props): string => props.theme.spacing(6)};
`;

export const Label = styled.label`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

export const StyledGrid = styled(MuiGrid)`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - ${(props): string => props.theme.spacing(42)});
  background: ${(props): string => props.theme.palette.background.paper};
`;

export const Search = styled.div`
  border-radius: ${(props): string => props.theme.spacing(2)};
  position: relative;
  width: 100%;
  display: block;
  background-color: ${(props): string =>
    darken(0.05, props.theme.header.background)};
`;

export const SearchIconWrapper = styled.div`
  width: ${(props): string => props.theme.spacing(12)};
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: ${(props): string => props.theme.spacing(6)};
    height: ${(props): string => props.theme.spacing(6)};
  }
`;

export const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props): string => props.theme.header.search.color};
    padding-top: ${(props): string => props.theme.spacing(2.5)};
    padding-right: ${(props): string => props.theme.spacing(2.5)};
    padding-bottom: ${(props): string => props.theme.spacing(2.5)};
    padding-left: ${(props): string => props.theme.spacing(12)};
  }
`;

export const DropzoneContainer = styled(Grid)`
  .drop-box {
    top: 0;
    height: ${(props): string => props.theme.spacing(100)};
  }
`;

export const FolderContainer = styled(Grid)`
  overflow-y: auto;
  height: calc(100vh - ${(props): string => props.theme.spacing(42)});
`;
/* istanbul ignore next */
export const PreviewImageDialog = styled(MuiDialog)<{ $visibility: string }>`
  .MuiPaper-root {
    visibility: ${(props): string => props.$visibility};
    overflow-x: hidden;
  }
`;
/* istanbul ignore next */
export const PreviewDialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: ${(props): string => props.theme.spacing(3)};
`;
/* istanbul ignore next */
export const PreviewDialogContent = styled(MuiDialogContent)`
  padding: ${(props): string => props.theme.spacing(0)};
`;
/* istanbul ignore next */
export const PreviewPdfDialog = styled(MuiDialog)`
  .MuiDialogContent-root {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .MuiDialogContent-root::-webkit-scrollbar {
    display: none;
  }
`;

export const FileIconContainer = styled.div`
  position: relative;
  margin-top: ${(props): string => props.theme.spacing(1)};
`;
/* istanbul ignore next */
export const FileIconLoader = styled(MuiCircularProgress)`
  position: absolute;
  top: ${(props): string => props.theme.spacing(0.25)};
  left: ${(props): string => props.theme.spacing(0.25)};
`;

export const PreviewLoaderRoot = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`;
/* istanbul ignore next */
export const PreviewImage = styled.img<{ $optimized?: boolean }>`
  display: block;
  max-width: ${(props): string => (!props.$optimized ? "100%" : "none")};
  height: ${(props): string =>
    !props.$optimized ? props.theme.spacing(100) : "auto"};
  width: ${(props): string => (!props.$optimized ? "auto" : "100%")};
`;
/* istanbul ignore next */
export const FileDownloadIcon = styled(MuiFileDownloadIcon)`
  margin-left: ${(props): string => props.theme.spacing(2.5)};
  width: ${(props): string => props.theme.spacing(5.5)};
  height: ${(props): string => props.theme.spacing(5.5)};
  margin-right: -${(props): string => props.theme.spacing(0.5)};
  &:hover {
    color: ${(props): string => props.theme.palette.grey[400]};
    cursor: pointer;
  }
  transition: color 150ms;
`;
/* istanbul ignore next */
export const ListViewItem = styled(MuiBox)`
  display: flex;
  align-items: center;
  cursor: pointer;
  div {
    margin-left: ${(props): string => props.theme.spacing(2)};
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &:hover {
    div {
      color: ${(props): string => props.theme.palette.grey[400]};
    }
  }
`;
/* istanbul ignore next */
export const ListViewItemName = styled(MuiBox)`
  width: ${(props): string => props.theme.spacing(62.5)};
  word-break: break-word;
`;
/* istanbul ignore next */
export const StyledButton = styled(MuiButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  line-height: ${(props): string => props.theme.spacing(5.5)};
  font-size: 15px;
  padding: ${(props): string => props.theme.spacing(1.25, 2)};
`;
