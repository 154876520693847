import React, { ReactElement } from "react";
import { DescriptionModalProps } from "../../types";
import { Dialog, DialogContent, Grid } from "@mui/material";
import Button from "../../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import { DescriptionTextField } from "../styled";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
/* istanbul ignore next */
const DescriptionModal = ({
  showDescriptionModal,
  handleCloseDescriptionModal,
  resetForm,
  isSubmitting,
  submitForm,
  status,
}: DescriptionModalProps): ReactElement => {
  const { t } = useTranslation();

  const handleClick =
    (update = false) =>
    (): void => {
      update ? submitForm() : resetForm();
      handleCloseDescriptionModal();
    };

  return (
    <Dialog
      open={showDescriptionModal}
      aria-labelledby="max-width-dialog-title"
      data-testid="description-input-modal"
      onClose={handleCloseDescriptionModal}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle
        title="employeeOverview.fieldService"
        onClose={handleClick()}
      />
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <DescriptionTextField
              name={"description"}
              status={status}
              size="small"
              label={t("employeeOverview.description")}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
        >
          <Grid item>
            <Button
              onClick={handleClick()}
              title={t("cancel")}
              variant={"text"}
              type="button"
            />
          </Grid>
          <Grid item>
            <Button
              title={t("update")}
              sx={{ ml: "1rem" }}
              color="success"
              testId={"update-description-button"}
              disabled={isSubmitting}
              isLoading={isSubmitting}
              onClick={handleClick(true)}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DescriptionModal;
