import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Counter } from "../../../types/be/counter";
import useTable from "../../../ui/table/useTable";
import Table from "../../../ui/table/Table";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { handleServerError } from "../../../utils/http";
import { getCounterList, getCounterTypes } from "../../../api/reading";
import { itemsListHeadCells } from "./utilts";
import { Grid, TableCell, Typography } from "@mui/material";
import { EMPTY_DATA, PermissionsCreate } from "../../../constants";
import { useTranslation } from "react-i18next";
import { Input, Search, SearchIconWrapper } from "../../../ui/table/styled";
import { Search as SearchIcon } from "react-feather";
import { prepareQueryParams } from "../../../utils/common";
import Dropdown from "../../../ui/dropdown";
import { getProperties } from "../../../api/property";
import { route } from "../../../utils/url";
import { useNavigate } from "react-router-dom";
import { ObjectLink } from "../../properties/list/styled";
import { StyledButton } from "../../../ui/button/styled";
import { getAreas } from "../../../api/area";
import useAccessControl from "../../../hooks/useAccessControl";
import RedirectProperty from "../../../ui/redirectProperty";

const MeterTable = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { can } = useAccessControl();

  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleSortChange,
    handleSelectAllClick,
    setTotal,
    handleConfirmToRemoveModalClose,
    handleTableSearch,
  } = useTable<Counter>({ loading: true });
  const [propertyId, setPropertyId] = useState<string>();
  const [propertyAreaId, setPropertyAreaId] = useState<string>();
  const [typeId, setTypeId] = useState<string>();

  const onPropertyAreaSelect = useCallback(
    async (id?: string): Promise<void> => {
      setPropertyAreaId(id);
    },
    []
  );

  const onCounterTypeSelect = useCallback((id?: string) => {
    setTypeId(id);
  }, []);

  const fetchList = useCallback(async () => {
    setIsLoading(true);

    const response = await getCounterList(
      prepareQueryParams(queryParams, {
        property_id: propertyId,
        property_area_id: propertyAreaId,
        type_id: typeId,
      })
    );

    if (response.status !== HTTP_STATUS_CODES.OK) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const resJson = await response.json();
      setTotal(resJson?.meta?.total);
      setData(resJson.data);
    }

    setIsLoading(false);
  }, [queryParams, propertyId, propertyAreaId, typeId]);

  const onPropertySelect = useCallback((id?: string) => {
    setPropertyId(id);
    setPropertyAreaId("");
  }, []);

  const renderRow = (row: Counter): ReactElement => (
    <>
      <TableCell align="left">
        <RedirectProperty property={row?.property_area?.property} />
      </TableCell>
      <TableCell sx={{ cursor: "pointer" }} align="left">
        <ObjectLink to={route("meter.edit", row?.id)} key={row.id}>
          <Typography noWrap>{row.number}</Typography>
        </ObjectLink>
      </TableCell>
      <TableCell align="center">
        {row?.penultimate_reading?.date
          ? row?.penultimate_reading?.date
          : EMPTY_DATA}
      </TableCell>
      <TableCell align="center">
        {row?.penultimate_reading?.value
          ? row?.penultimate_reading?.value
          : EMPTY_DATA}
      </TableCell>
      <TableCell align="center">
        {row?.last_reading?.date ? row?.last_reading?.date : EMPTY_DATA}
      </TableCell>
      <TableCell align="right">{row?.last_reading?.value}</TableCell>
      <TableCell>{row?.type?.unit}</TableCell>
    </>
  );

  const TableToolbar = (
    <>
      <Grid container sx={{ pt: 5 }} justifyContent="space-between">
        <Grid item>
          {can(PermissionsCreate.METER) && (
            <StyledButton
              color="success"
              onClick={() => navigate(route("createMeter"))}
              variant="contained"
            >
              {t("counter.create.createMeter")}
            </StyledButton>
          )}
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder={t("search")} onChange={handleTableSearch} />
              </Search>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{ pt: 2, mb: 2 }} spacing={2} wrap="wrap">
        <Grid item lg={2} xs={12} md={3} sm={4}>
          <Dropdown
            id="property-filters"
            name="property-filters"
            placeholder={t("counter.searchProperty")}
            getOptions={getProperties}
            onSelectOption={onPropertySelect}
            optionLabel="object_name"
          />
        </Grid>
        <Grid item lg={2} xs={12} md={3} sm={4}>
          <Dropdown
            id="property-area-autocomplete"
            name="property_area_id"
            placeholder={t("counter.searchArea")}
            propertyId={propertyId}
            isAreaSearch={true}
            getOptions={(params) => getAreas(params)}
            onSelectOption={onPropertyAreaSelect}
          />
        </Grid>
        <Grid item lg={2} xs={12} md={3} sm={4}>
          <Dropdown
            id="counter-type-autocomplete"
            name="counter_type_id"
            getOptions={getCounterTypes}
            placeholder={t("counter.searchType")}
            onSelectOption={onCounterTypeSelect}
          />
        </Grid>
      </Grid>
    </>
  );

  useEffect(() => {
    queryParams && fetchList();
  }, [queryParams, propertyId, propertyAreaId, typeId]);

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
      />
    </>
  );
};

export default MeterTable;
