export default {
  dateFrom: "Service from",
  dateTo: "Service to",
  description: "Description",
  taxPrice: "Tax price",
  price: "Price",
  tax: "Tax",
  quantity: "Quantity",
  totalPrice: "Total price",
  fullInvoicePrice: "Full invoice price",
  fullInvoicePriceWithTax: "Full invoice price with tax",
  fullInvoiceTax: "Full invoice tax",
  services: "Services",
  addServices: "Add services",
  deleteServices: "Delete services",
  searchReceiver: "Search receiver",
  debitor: "Debitor",
  debitorSearch: "Search debitor",
  notice: "Comment",
  sentDate: "E-mail was sent on date",
  sendInvoice: "Send invoice",
  selectInvoiceToSend: "To send, please select invoice",
  selectInvoiceToDownload: "To download, please select invoice",
  sendConformation: "Are you sure you want to send selected invoice?",
  upload: "Upload",
  chooseFile: "Choose file",
  error: {
    receiverIsRequired: "Receiver is required",
    priceIsRequired: "Price is required",
    descriptionIsRequired: "Description is required",
    taxIsRequired: "Tax is required",
    serviceDateFrom: "Service date from is required",
    serviceDateTo: "Service date to is required",
    dateToDateShouldNotBeGreaterThanDateFrom:
      "Service date to should not be greater than service date from",
    quantityIsRequired: "Quantity is required",
    quantityNeedsToBePositiveNumber: "Quantity needs to be positive number",
    noticeIsRequired: "Comment is required",
  },
};
