import React, { ReactElement } from "react";
import { Dialog, DialogContent, Grid } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
import { CreateUnitDialogProps, CreateUnitForm } from "./types";
import Text from "../../../../ui/formsFields/text";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/button/Button";
import useValidation from "../../../../hooks/useValidation";
import { useParams } from "react-router";
import { handleCreateUnit } from "./utils";

const CreateUnitDialog = ({
  open,
  onClose,
  snackbar,
  afterSuccess,
}: CreateUnitDialogProps): ReactElement => {
  const { t } = useTranslation();
  const { name, description } = useValidation();
  const { maintenanceId } = useParams();

  const initialValues: CreateUnitForm = {
    name: "",
    description: "",
    maintenance_id: maintenanceId!,
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      data-testid="maintenance-unit-dialog"
      onClose={onClose}
    >
      <DialogTitle title="property.maintenance.createUnit" onClose={onClose} />
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleCreateUnit(t, snackbar, afterSuccess)}
          validationSchema={Yup.object().shape({
            name,
            description,
          })}
        >
          {({
            values,
            status,
            isSubmitting,
            touched,
            errors,
            handleSubmit,
          }) => (
            <form noValidate onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2} sx={{ py: 2 }}>
                <Grid item xs={12}>
                  <Text
                    required
                    name="name"
                    value={values?.name}
                    label={t("name")}
                    placeholder={t("name")}
                    type="text"
                    status={status}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Text
                    required
                    name="description"
                    multiline
                    disabled={isSubmitting}
                    rows={4}
                    label={t("property.maintenance.description")}
                    inputProps={{ "data-testid": "description" }}
                    error={Boolean(
                      touched.description &&
                        (status?.errors.description || errors.description)
                    )}
                    helperText={
                      touched.description &&
                      (status?.errors.description || errors.description)
                    }
                  />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Button
                    type={"submit"}
                    isLoading={isSubmitting}
                    title={t("create")}
                    color="success"
                    disabled={isSubmitting}
                    testId="createBtn"
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CreateUnitDialog;
