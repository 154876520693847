import * as React from "react";
import { Tooltip, Typography } from "@mui/material";
import { EMPTY_DATA } from "../../constants";
/* istanbul ignore next */
export const TextWithTooltip = ({
  value,
  maxChar = 30,
}: {
  value?: string | number | null;
  maxChar?: number;
}): JSX.Element => {
  const textWithEllipsis = React.useMemo(() => {
    const newVal = `${value ?? ''}`
    if (newVal && newVal.length > maxChar) {
      return `${newVal.substring(0, maxChar - 1)}...`;
    }
    return value;
  }, []);
  return (
    <Tooltip title={value}>
      <Typography>{textWithEllipsis ?? EMPTY_DATA}</Typography>
    </Tooltip>
  );
};
