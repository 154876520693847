import styled from "styled-components";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import { spacing } from "@mui/system";
import { Grid } from "@mui/material";

export const LoadingBox = styled.div`
  width: 100%;
  height: 50vh;
`;

export const ErrorBox = styled(FormAlert)(spacing);
/*istambul ignore next*/
export const StyledGrid = styled(Grid)`
  display: flex;
  justify-content: center;
`;
