import { FormikHelpers, FormikValues } from "formik";
import { Dispatch, SetStateAction } from "react";
import { sendEmail } from "../../../../../../api/attachment";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../../../constants";
import { FormMessageInterface } from "../../../../../../types/form";
import { HTTP_STATUS_CODES } from "../../../../../../types/server";
import { mapBackendValidationErrors } from "../../../../../../utils/form";

/* istanbul ignore next */
export const initialValues = {
  email: "",
  text: "",
  user_id: "",
};

/* istanbul ignore next */
export const handleSendEmail = (
  id: number,
  showSnackbar: () => void,
  handleCloseSendEmailModal: () => void,
  setFormMessage: Dispatch<SetStateAction<FormMessageInterface>>
) => {
  return async (
    values: FormikValues,
    { setStatus, setSubmitting }: FormikHelpers<typeof initialValues>
  ): Promise<void> => {
    const formData = new FormData();
    values?.user_id
      ? formData.append("user_id", values.user_id)
      : formData.append("email", values.email);

    formData.append("text", values.text);

    const response = await sendEmail(id, formData);
    const json = await response.json();

    if (response.status === HTTP_STATUS_CODES.OK) {
      setStatus({
        success: true,
        errors: {},
      });
      setFormMessage(FormMessageInItState);
      showSnackbar();
      handleCloseSendEmailModal();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
    }
    setSubmitting(false);
  };
};
