export const futureTenantInitialValue = {
  contact_id: "",
  tenants: [],
  active_tenant: {
    rent_start: null,
    rent_end: null,
    net_rent_per_month: "",
    area: "",
    total_net_rent_per_month: "",
    tax: "",
    total_gross_net_rent_per_month: "",
    total_gross_net_rent_per_year: "",
    remaining_term: "",
    remaining_term_in_eur: "",
    cancellation_until: null,
    closing_tenant_contract: null,
    special_cancellation_right: null,
    art: 0,
    is_opt_jn: 0,
    is_idx_jn: 0,
    right_of_first_tenancy: 0,
    deposit: "",
    costs_for_modification: "",
    rent_free: "",
    number_of_parking_spaces: "",
    others_incomings_eur: "",
    rent_eur: "",
    rental_contract_file_id: "",
    new: false,
    is_terminated: false,
    is_unlimited: false,
  },
};

export const FirstTenancyOptions = [
  {
    id: 0,
    label: "V",
  },
  {
    id: 1,
    label: "L",
  },
];

export const ArtOptions = [
  {
    id: 0,
    label: "VL",
  },
  {
    id: 1,
    label: "MV",
  },
];
