import user from "./user";
import property from "./property";
import profile from "./profile";
import home from "./home";
import auth from "./auth";
import bankAccounts from "./bankAccount";
import imprint from "./imprint";
import privacy from "./privacy";
import fallback from "./fallback";
import fileManagement from "./fileManagement";
import contact from "./contact";
import generalRelease from "./generalRelease";
import humanResources from "./humanResources";
import inbox from "./inbox";
import meter from "./meter";
import tenantRequest from "./tenantRequest";
import settings from "./settings";
import transactionManagement from "./transactionManagement";
import maintenance from "./maintenance";
import vacancies from "./vacancies";
import company from "./company";
import generalDocuments from "./generalDocuments";

const routes = [
  home,
  property,
  company,
  user,
  profile,
  contact,
  auth,
  fileManagement,
  bankAccounts,
  imprint,
  privacy,
  fallback,
  generalRelease,
  inbox,
  tenantRequest,
  humanResources,
  meter,
  transactionManagement,
  settings,
  vacancies,
  generalDocuments,
  maintenance,
];

export default routes;
