import React, { ReactElement, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PageWrapper from "../../../ui/pageWrapper/PageWrapper";
import { route } from "../../../utils/url";
import Table from "../../../ui/table/Table";
import useTable from "../../../ui/table/useTable";
import { itemsListHeadCells } from "./utils";
import { Counter } from "../../../types/be/counter";
import { TableCell, Grid, Paper, Tooltip, Link } from "@mui/material";
import { EMPTY_DATA } from "../../../constants";
import { ExternalLink, Search as SearchIcon } from "react-feather";
import { SearchIconWrapper, Search, Input } from "../../../ui/table/styled";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { handleServerError } from "../../../utils/http";
import { prepareQueryParams } from "../../../utils/common";
import { getCounterDetails, getReadingsList } from "../../../api/reading";
import { Reading } from "../../../types/be/readings";
import { FileName, GridItem } from "./styled";
import { useFetch } from "../../../hooks/useFetch";
import Loader from "../../Loader";
import { File } from "../../../types/be/file";

const MeterShow = (): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      to: route("meter"),
      name: t("counter.meter"),
    },
  ];

  const {
    data: counterDetails,
    run: fetchCounterDetails,
    isLoading: isCounterDetailsLoading,
  } = useFetch<Counter>();

  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleSortChange,
    handleSelectAllClick,
    setTotal,
    handleConfirmToRemoveModalClose,
    handleTableSearch,
  } = useTable<Reading>();

  const fetchList = useCallback(async () => {
    setIsLoading(true);

    const response = await getReadingsList(
      prepareQueryParams(queryParams?.substring(1), {
        counter_id: id,
      })
    );

    if (response.status !== HTTP_STATUS_CODES.OK) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const resJson = await response.json();
      setTotal(resJson?.meta?.total);
      setData(resJson.data);
    }

    setIsLoading(false);
  }, [queryParams, id]);

  const renderRow = (row: Reading): ReactElement => (
    <>
      <TableCell align="left">{row?.date ? row?.date : EMPTY_DATA}</TableCell>
      <TableCell align="left">{row?.value}</TableCell>
      <TableCell align="left">{counterDetails?.type?.unit}</TableCell>
      <TableCell align="left">
        {row?.created_by
          ? `${row?.created_by?.first_name} ${row?.created_by?.last_name}`
          : EMPTY_DATA}
      </TableCell>
      <TableCell align="left">
        {row?.files?.length
          ? row?.files?.map((item: File) => (
              <Grid display={"flex"} key={item?.id}>
                <Tooltip title={item.name} placement="top">
                  <Link href={item?.url} target="_blank">
                    <FileName>{item.name}</FileName>
                  </Link>
                </Tooltip>
                <Link target="_blank" rel="noreferrer" href={item?.url}>
                  <ExternalLink size="15" />
                </Link>
              </Grid>
            ))
          : EMPTY_DATA}
      </TableCell>
    </>
  );

  const TableToolbar = (
    <>
      <Grid container sx={{ pt: 5 }} justifyContent="flex-end">
        <Grid item>
          <Grid container>
            <Grid item>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder={t("search")} onChange={handleTableSearch} />
              </Search>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  useEffect(() => {
    queryParams && fetchList();
  }, [queryParams]);

  useEffect(() => {
    fetchCounterDetails(getCounterDetails(id!));
  }, [id]);

  if (isCounterDetailsLoading) return <Loader />;

  return (
    <PageWrapper title={id!} breadcrumbs={breadcrumbs}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Paper>
            <Grid container>
              <GridItem item xs={12}>
                <div className="left-item">{t("contracts.objectName")}</div>
                <div className="right-item">
                  {counterDetails?.property_area?.property?.object_name}
                </div>
              </GridItem>
              <GridItem item xs={12}>
                <div className="left-item">{t("areaName")}</div>
                <div className="right-item">
                  {counterDetails?.property_area?.name}
                </div>
              </GridItem>
              <GridItem item xs={12}>
                <div className="left-item">{t("counter.counterId")}</div>
                <div className="right-item">{counterDetails?.id}</div>
              </GridItem>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} lg={12} md={12}>
          <Table
            data={data}
            total={total}
            currentPage={currentPage}
            order={order}
            orderBy={orderBy}
            error={error}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
            onSortChange={handleSortChange}
            onSelectAllClick={handleSelectAllClick}
            onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
            isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
            listHeadCells={itemsListHeadCells}
            renderRow={renderRow}
            tableToolbar={TableToolbar}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default MeterShow;
