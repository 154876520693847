import { SelectChangeEvent } from "@mui/material";
import { FormikValues } from "formik";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../constants";
import { additionalFieldsInitState } from "../../../../../../redux/slices/area/area";
import {
  setDownloadLink,
  setFileName,
} from "../../../../../../redux/slices/importValidation/importValidation";
import { HTTP_STATUS_CODES } from "../../../../../../types/server";
import { mapBackendValidationErrors } from "../../../../../../utils/form";
import {
  HandleAdditionalFieldsChangeProps,
  handleManullyPropertyUploadProps,
  UploadFileType,
} from "./types";
import { importAreaTenantContact } from "../../../../../../api/area";

export const handleAdditionalFieldsChange =
  ({ dispatch, setAdditionalFields }: HandleAdditionalFieldsChangeProps) =>
  (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value: labels },
    } = event;
    const arr = [...additionalFieldsInitState];

    typeof labels !== "string" &&
      labels.forEach((label) => {
        if (label === "all") {
          const allVisible = arr.every((el) => el.visible);
          arr.forEach(function (el) {
            const ind = arr.indexOf(el);
            arr[ind] = {
              ...el,
              visible: !allVisible,
            };
          });
        } else {
          const ind = arr.findIndex((el) => el.label === label);
          arr[ind] = { ...arr[ind], visible: true };
        }
      });
    dispatch(setAdditionalFields([...arr]));
  };

export const uploadDialogInitState: UploadFileType = {
  file: "",
};

export const handleManullyPropertyUpload = ({
  setFormMessage,
  onSuccess,
  setUploadInprogress,
  dispatchStore,
}: handleManullyPropertyUploadProps) => {
  return async (
    data: UploadFileType,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const reqData = new FormData();
    reqData.append("file", data.file!);
    const response = await importAreaTenantContact(reqData);
    setUploadInprogress(true);
    const json = await response.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      const {
        data: {
          file: { name, download_link },
        },
      } = json;
      dispatchStore(setFileName(name));
      dispatchStore(setDownloadLink(download_link));
      setStatus({
        success: true,
        errors: {},
      });
      setFormMessage({ type: undefined, text: "" });
      onSuccess();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setSubmitting(false);
    }
    setUploadInprogress(false);
  };
};

export const isRentedOptions = [
  { id: 1, name: "property.area.isRented" },
  { id: 0, name: "property.area.isNotRented" },
];
