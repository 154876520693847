import * as React from "react";
import { AppDispatch } from "../../../redux/store";
import { Property } from "../../../types/be/property";
import { FormMessageInterface } from "../../../types/form";

export type Order = "asc" | "desc";

export interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

export interface EnhancedTableToolbarProps {
  numSelected: number;
}

export interface AnchorDict {
  importExport: null | HTMLElement;
}

export interface ManuallyUploaDialogProps {
  toggleModel: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
  onSuccess: () => void;
}

export interface handleManullyPropertyUploadProps {
  setFormMessage: React.Dispatch<React.SetStateAction<FormMessageInterface>>;
  onSuccess: () => void;
  dispatchStore: AppDispatch;
}

export interface PropertyGridViewProps {
  data: Property[];
  tableToolbar: React.ReactElement;
  isTenant: boolean;
  total: number;
  rowsPerPage: number;
  labelRowsPerPage?: string;
  isLoading: boolean;
  currentPage: number;
  onPageChange: () => void;
  onRowsPerPageChange: () => void;
  noDataIsAvailablePlaceholder?: string;
}

export enum PageViewTypes {
  LIST = "list",
  GRID = "grid",
}
