import { Formik } from "formik";
import { Dialog, DialogContent, Stack } from "@mui/material";
import React, { ReactElement, useState } from "react";
import * as Yup from "yup";
import { FormMessageInItState } from "../../../constants";
import FormAlert from "../../../ui/formAlert/FormAlert";
import { handleCreateCompanyFormSubmit } from "./utils";
import { CreateCompanyComponentProps } from "./types";
import DialogTitle from "../../../ui/dialog/DialogTitle";
import Form from "./Form";
import useValidation from "../../../hooks/useValidation";

const CreateCompanyModal = ({
  isCreateCompanyModalOpen,
  handleCreateCompanyModalClose,
  initialValues,
  setInitialValues,
  isPropertyManagerRole = false,
  setCompanyAutocompleteValue,
  setCompanyWasCreated,
}: CreateCompanyComponentProps): ReactElement => {
  const [formMessage, setFormMessage] = useState(FormMessageInItState);

  const { title } = useValidation();

  return (
    <Dialog
      maxWidth="sm"
      open={isCreateCompanyModalOpen}
      onClose={handleCreateCompanyModalClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="create-vacation-holiday-dialog"
      fullWidth
    >
      <DialogTitle
        title="createContact.createCompany"
        onClose={handleCreateCompanyModalClose}
      />
      <DialogContent>
        <Stack>
          {formMessage.text && (
            <FormAlert
              formMessage={formMessage}
              sx={{ marginBottom: "10px" }}
            />
          )}
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              title,
            })}
            onSubmit={handleCreateCompanyFormSubmit({
              setFormMessage,
              setInitialValues,
              handleCreateCompanyModalClose,
              setCompanyAutocompleteValue,
              setCompanyWasCreated,
            })}
          >
            {(props): ReactElement => (
              <Form
                {...{
                  ...props,
                  setInitialValues,
                  handleCreateCompanyModalClose,
                  isPropertyManagerRole,
                }}
              />
            )}
          </Formik>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CreateCompanyModal;
