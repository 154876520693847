import React, { useCallback, useEffect, useState } from "react";
import { ReactElement } from "react";
import { Switch } from "@mui/material";
import { updatePayment } from "../../../api/bankAccount";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { IsIgnoredSwitchProps } from "./types";
import { handleServerError } from "../../../utils/http";

const label = { inputProps: { "aria-label": "isPaymentIgnoredSwitch" } };

const IsIgnoredSwitch = ({
  payment,
  fetchBankPayments,
  setError,
}: IsIgnoredSwitchProps): ReactElement => {
  const [value, setValue] = useState<boolean>(false);

  useEffect(() => {
    setValue(payment.is_ignored);
  }, [payment]);
  /* istanbul ignore next */
  const handleChangeInboxStatus = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const response = await updatePayment(payment.id, {
        is_ignored: event.target.checked,
      });
      if (response.status !== HTTP_STATUS_CODES.OK) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        fetchBankPayments(false);
      }
    },
    [fetchBankPayments, updatePayment]
  );

  return (
    <Switch
      role="is-ignored-switch"
      onChange={/* istanbul ignore next */ (e) => handleChangeInboxStatus(e)}
      size={"small"}
      {...label}
      checked={value}
    />
  );
};

export default IsIgnoredSwitch;
