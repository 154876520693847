/* istanbul ignore file*/

import styled from "styled-components/macro";
import { Box } from "@mui/material";
import { graphite } from "../../../../theme/variants";

export const PropertyImages = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: ${(props) => props.theme.spacing(1.5)};
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: ${(props): string => props.theme.card.cardShadow};

  img {
    width: 100%;
    min-height: ${(props) => props.theme.spacing(50)};
    object-position: center;
    cursor: pointer;
    float: left;
  }

  .image-gallery {
    &-icon {
      height: 100%;
      svg {
        height: ${(props) => props.theme.spacing(5)};
        width: auto;
      }
      color: ${graphite[500]};
    }

    img {
      object-fit: cover !important;
    }
  }
`;

export const PropertyDetails = styled(Box)`
  cursor: pointer;
  padding: ${(props) => props.theme.spacing(5)};
  font-size: 14px;
  text-align: center;
  background-color: ${(props): string => props.theme.footer.background};
  color: ${(props): string => props.theme.footer.color};
  box-shadow: ${(props): string => props.theme.card.cardShadow};
  border-radius: ${(props) => props.theme.spacing(1.5)};
  min-height: ${(props) => props.theme.spacing(51.5)};
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  position: relative;
`;

export const PropertyName = styled(Box)`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: ${(props) => props.theme.spacing(2.5)};
  color: ${(props): string => props.theme.palette.textColor};

  width: 100%;
  min-height: ${(props) => props.theme.spacing(10)};
  text-overflow: ellipsis;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const PropertyType = styled(Box)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const PropertySpace = styled(Box)`
  &.red {
    color: ${(props): string => props.theme.palette.red[400]};
  }
  &.gray {
    color: ${(props): string => props.theme.palette.grey[400]};
  }
  &.yellow {
    color: ${(props): string => props.theme.palette.orange[400]};
  }
`;

export const PropertyRent = styled(Box)`
  margin-bottom: ${(props) => props.theme.spacing(2.5)};
`;

export const Pagination = styled.div`
  width: 100%;
  background: ${(props): string => props.theme.footer.background};
  margin-top: ${(props) => props.theme.spacing(2.5)};
`;

export const DefaultImage = styled.img`
  width: 100%;
  height: ${(props) => props.theme.spacing(55)};
  object-fit: contain;
`;
