import { FormikValues } from "formik/dist/types";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "../../../ui/formsFields/text";
import { TenantRequestStatusType } from "../../../types/be/tenantRequest";
import { getUser, getUsers } from "../../../api/users";
import { getJson } from "../../../utils/http";
import {
  getTenantRequest,
  updateTenantRequestStatus,
} from "../../../api/tenantRequest";
import { useLocation, useParams } from "react-router-dom";
import { Form } from "formik";
import {
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import Button from "../../../ui/button/Button";
import useAccessControl from "../../../hooks/useAccessControl";
import {
  COMPLETED_STATUS,
  COMPLETED_TITLE,
  IN_COORDINATION_STATUS,
  IN_COORDINATION_TITLE,
  IN_PROGRESS_STATUS,
  IN_PROGRESS_TITLE,
  PENDING_STATUS,
  PENDING_TITLE,
  statusBackground,
  TENANT_REQUEST_READ_PERMISSION,
} from "../utils";
import { grey } from "@mui/material/colors";
import toUpper from "lodash/toUpper";
import camelCase from "lodash/camelCase";
import PropertyAreaAutocomplete from "../components/PropertyAreaAutocomplete";
import { Area, TenantUser } from "../../../types/be/area";
import { PropertyList } from "../../../types/be/property";
import { getProperty } from "../../../api/property";
import PropertyAutocomplete from "../components/PropertyAutocomplete";
import Loader from "../../Loader";
import { getArea, getAreaTenantUsers } from "../../../api/area";
import FileUploader from "../../../ui/fileUploader";
import {
  AutocompleteTenantEmail,
  AutocompleteTenantFullName,
} from "../create/styled";
import { formatTimestamp } from "../../../utils/date";
import { touchedInitState } from "../create/utils";
import { uploadAndAttachFiles } from "../../../api/files";
import useSnackbar from "../../../ui/snackbar1/useSnackbar";
import UsersAutocomplete from "../../users/autocomplete";
import { useFetch } from "../../../hooks/useFetch";
import { User } from "../../../types/be/user";
import { prepareQueryParams } from "../../../utils/common";
import { EMPTY_DATA, PAGE_LIMIT, PermissionsUpdate } from "../../../constants";
import { EditTenantRequestFormTypes } from "./types";

const EditTenantRequestForm = ({
  handleSubmit,
  touched,
  setTouched,
  status,
  errors,
  setFieldValue,
  isSubmitting,
  values,
  fetchTenantRequest,
  tenantRequest,
  setTenantRequest,
  statusCode,
}: FormikValues & EditTenantRequestFormTypes): ReactElement => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { id } = useParams();
  const { can, isTenant } = useAccessControl();
  const isShowPage = search?.includes("isShow=true");
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [property, setProperty] = useState<PropertyList | null>(null);
  const [propertyArea, setPropertyArea] = useState<Area | null>(null);
  const [tenants, setTenants] = useState<TenantUser[]>([]);
  const [isTenantsLoading, setIsTenantsLoading] = useState(false);
  const [refreshForm, setRefreshForm] = useState(true);
  const { Snackbar, snackbar } = useSnackbar();
  const [propertyId, setPropertyId] = useState<number | null>(null);
  const [predictions, setPredictions] = useState<Area[]>([]);
  const [haveTenants, setHaveTenants] = useState(false);

  useEffect(() => {
    if (status && status.success) {
      fetchTenantRequest();
    }
  }, [status]);

  const { data: managerUsers, run: runManagerUsers } = useFetch<User[]>();

  useEffect(() => {
    const params = prepareQueryParams("", {
      limit: String(PAGE_LIMIT._10),
      "permission_code[]": TENANT_REQUEST_READ_PERMISSION,
    });
    runManagerUsers(getUsers(params));
  }, []);

  useEffect(() => {
    if (tenantRequest && refreshForm) {
      setIsLoadingData(true);
      const { title, description, tenant, id, property } = tenantRequest;
      const promises = [
        getUser(String(tenant?.id)),
        getArea(String(tenantRequest?.area?.id)),
        getAreaTenantUsers(String(tenantRequest?.area?.id)),
      ];
      property && promises.push(getProperty(String(property.id)));
      const promiseAll = Promise.all(promises);
      promiseAll.then((responses) => {
        const results = responses.map((res) => res.json());
        results[0].then(({ data }) => {
          setFieldValue("tenant_id", data?.id);
        });
        results[1].then(({ data }) => {
          setFieldValue("property_area_id", data?.id);
          setPropertyArea(data);
        });
        results[2].then(({ data }) => {
          setTenants(data);
        });
        property &&
          results[3].then(({ data }) => {
            setPropertyId(data.id);
            setProperty(data);
            setFieldValue("property_id", data.id);
          });
        setFieldValue("id", id);
        setFieldValue("title", title);
        setFieldValue("description", description);
        setIsLoadingData(false);
      });
    }
  }, [tenantRequest]);

  const handleOnPropertyAreaSelect = (_area: Area | null): void => {
    setPropertyArea(_area);
    if (_area) {
      if (_area.id !== propertyArea?.id) {
        setFieldValue("tenant_id", "");
        setTenants([]);
      }
      setFieldValue("property_area_id", _area.id);
      setTouched({ tenant_id: false });
      setIsTenantsLoading(true);
      getAreaTenantUsers(String(_area.id))
        .then(getJson)
        .then(({ data }) => {
          setTenants(data);
          setIsTenantsLoading(false);
          setHaveTenants(true);
        });
    } else {
      setFieldValue("tenant_id", "");
      setFieldValue("property_area_id", "");
      setTenants([]);
      setTouched(touchedInitState);
    }
  };

  const handleOnPropertySelect = (_property: PropertyList | null): void => {
    if (_property) {
      if (_property.id !== property?.id || _property.id !== propertyId) {
        setFieldValue("property_id", _property.id);
        setFieldValue("tenant_id", "");
        setFieldValue("property_area_id", "");
        setPredictions([]);
        setPropertyId(_property.id);
        setProperty(_property);
        setPropertyArea(null);
        setTenants([]);
      }
      setFieldValue("property_id", _property.id);
    } else {
      setProperty(null);
      setFieldValue("tenant_id", "");
      setFieldValue("property_area_id", "");
      setPropertyArea(null);
      setTenants([]);
    }
  };

  const handleOnCancelClick = (): void => {
    history.back();
  };

  const handleOnStatusChange = (e: any): void => {
    tenantRequest &&
      updateTenantRequestStatus(
        tenantRequest?.id,
        e.target.value as TenantRequestStatusType
      )
        .then(getJson)
        .then(() => fetchTenantRequest());
  };

  const handleOnFileChange = (filesToUpload: File[]): void => {
    const formData = new FormData();
    for (let i = 0; filesToUpload.length > i; i++)
      formData.append(`file[${i}]`, filesToUpload[i]);
    formData.append("relation", "tenant_request");
    formData.append("relation_id", String(tenantRequest?.id));
    uploadAndAttachFiles(formData)
      .then(getJson)
      .then(() => {
        snackbar.success(t("tenantRequest.uploaded"));
        getTenantRequest(+id!)
          .then(getJson)
          .then(({ data }) => {
            setTenantRequest(data);
            setRefreshForm(true);
          });
      })
      .catch((err) => {
        snackbar.error(err?.toString());
      })
      .finally(() => {
        setRefreshForm(false);
      });
  };

  const tenantFieldLabel = !propertyArea
    ? `${t("tenantRequest.tenant")}* (${t(
        "tenantRequest.youNeedToSelectPropertyAreaFirst"
      )})`
    : `${t("tenantRequest.tenant")}*`;

  const areaFieldLabel = !property
    ? `${t("tenantRequest.area")}* (${t(
        "tenantRequest.youNeedToSelectPropertyFirst"
      )})`
    : `${t("tenantRequest.area")}*`;

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        {isLoadingData ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Loader />
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid item>
                    <Grid container spacing={2} alignItems={"center"}>
                      <Grid item>
                        <Chip
                          sx={{
                            color: "white",
                            fontWeight: 700,
                            background: grey[500],
                          }}
                          label={tenantRequest?.ticket_number}
                        />
                      </Grid>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          direction: "column",
                          fontSize: "12px",
                          alignItems: "end",
                        }}
                      >
                        <span>
                          {formatTimestamp(tenantRequest?.created_at)}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <FormControl
                      sx={{
                        m: 1,
                        minWidth: 120,
                        borderWidth: "2px",
                        background:
                          tenantRequest?.status &&
                          statusBackground(tenantRequest?.status?.code),
                      }}
                      size="small"
                    >
                      <Select
                        sx={{ color: "white", fontWeight: 700 }}
                        id="demo-select-small"
                        value={statusCode}
                        disabled={
                          !!isShowPage ||
                          !can([PermissionsUpdate.TENANT_REQUEST])
                        }
                        onChange={(e) => handleOnStatusChange(e)}
                      >
                        <MenuItem value={PENDING_STATUS}>
                          {toUpper(
                            t(`tenantRequest.${camelCase(PENDING_TITLE)}`)
                          )}
                        </MenuItem>
                        {!isTenant && (
                          <MenuItem value={IN_COORDINATION_STATUS}>
                            {toUpper(
                              t(
                                `tenantRequest.${camelCase(
                                  IN_COORDINATION_TITLE
                                )}`
                              )
                            )}
                          </MenuItem>
                        )}
                        <MenuItem value={IN_PROGRESS_STATUS}>
                          {toUpper(
                            t(`tenantRequest.${camelCase(IN_PROGRESS_TITLE)}`)
                          )}
                        </MenuItem>
                        <MenuItem value={COMPLETED_STATUS}>
                          {toUpper(
                            t(`tenantRequest.${camelCase(COMPLETED_TITLE)}`)
                          )}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  name="title"
                  label={t("tenantRequest.title")}
                  disabled={
                    !!isShowPage || !can([PermissionsUpdate.TENANT_REQUEST])
                  }
                  error={Boolean(
                    touched.title && (status?.errors.title || errors.title)
                  )}
                  helperText={
                    touched.title && (status?.errors.title || errors.title)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <PropertyAutocomplete
                  propertyInitState={property}
                  touched={touched}
                  status={status}
                  errors={errors}
                  onPropertySelect={handleOnPropertySelect}
                  isDisabled={
                    !!isShowPage || !can([PermissionsUpdate.TENANT_REQUEST])
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <PropertyAreaAutocomplete
                  propertyAreaInitState={propertyArea}
                  label={areaFieldLabel}
                  propertyId={propertyId}
                  predictions={predictions}
                  setPredictions={setPredictions}
                  touched={touched}
                  status={status}
                  errors={errors}
                  onPropertyAreaSelect={handleOnPropertyAreaSelect}
                  isDisabled={
                    !!isShowPage || !can([PermissionsUpdate.TENANT_REQUEST])
                  }
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <TextField
                  name="tenant_id"
                  select
                  size={"small"}
                  label={tenantFieldLabel}
                  disabled={
                    (!property && !propertyArea) ||
                    isSubmitting ||
                    !tenants.length ||
                    isShowPage ||
                    !can([PermissionsUpdate.TENANT_REQUEST])
                  }
                  error={
                    !isTenantsLoading &&
                    property &&
                    propertyArea &&
                    haveTenants &&
                    !tenants.length
                      ? /*istanbul ignore next */ true
                      : Boolean(
                          touched.tenant_id &&
                            (status?.errors.tenant_id || errors.tenant_id)
                        )
                  }
                  helperText={
                    !isTenantsLoading &&
                    property &&
                    propertyArea &&
                    haveTenants &&
                    !tenants.length
                      ? /*istanbul ignore next */ t(
                          "tenantRequest.selectedAreaDoesNotHaveTenants"
                        )
                      : touched.tenant_id &&
                        (status?.errors.tenant_id || errors.tenant_id)
                  }
                >
                  {tenants.map((tenant) => (
                    <MenuItem
                      selected={values.tenant_id == tenant.id}
                      key={`${tenant.id}-${tenant.email}`}
                      value={tenant.id}
                    >
                      <AutocompleteTenantFullName>{`${tenant.first_name} ${tenant.last_name}`}</AutocompleteTenantFullName>
                      <AutocompleteTenantEmail>
                        {`${tenant.email}`}
                      </AutocompleteTenantEmail>
                    </MenuItem>
                  ))}
                  {!tenants.length && (
                    <MenuItem
                      sx={{ p: 0 }}
                      disabled={true}
                      value={tenantRequest?.tenant?.id}
                    >
                      {tenantRequest?.tenant?.full_name || EMPTY_DATA}
                    </MenuItem>
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <UsersAutocomplete
                  status={status}
                  permissionCodes={[TENANT_REQUEST_READ_PERMISSION]}
                  placeholder={"transactionManagement.loi.search"}
                  label={"tenantRequest.manager"}
                  user={tenantRequest?.manager || null}
                  fieldName={"manager_id"}
                  optionLabel="full_name"
                  size={"small"}
                  sx={null}
                  preloadOptions={managerUsers}
                  disabled={
                    !!isShowPage || !can([PermissionsUpdate.TENANT_REQUEST])
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  name="description"
                  multiline
                  rows={4}
                  disabled={
                    !!isShowPage || !can([PermissionsUpdate.TENANT_REQUEST])
                  }
                  label={t("tenantRequest.description")}
                  error={Boolean(
                    touched.description &&
                      (status?.errors.description || errors.description)
                  )}
                  helperText={
                    touched.description &&
                    (status?.errors.description || errors.description)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FileUploader
                  accept="image"
                  filesInitState={tenantRequest?.files}
                  onFileChange={handleOnFileChange}
                  isSubmitted={false}
                  placeHolderText={t("fileUploader.dragAndDropYourFilesHereOr")}
                  uploadButtonText={t("fileUploader.uploadFiles")}
                  isDisabled={
                    !!isShowPage || !can([PermissionsUpdate.TENANT_REQUEST])
                  }
                  turnedOff={
                    !!isShowPage || !can([PermissionsUpdate.TENANT_REQUEST])
                  }
                />
              </Grid>
            </Grid>
            <Stack
              sx={{ mt: 5 }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            >
              <Button
                color={"primary"}
                variant="text"
                title={t("tenantRequest.cancel")}
                disabled={isSubmitting}
                size="large"
                onClick={() => handleOnCancelClick()}
              />
              {!!isShowPage ||
                (can([PermissionsUpdate.TENANT_REQUEST]) && (
                  <Button
                    color="success"
                    title={t("tenantRequest.update")}
                    type="submit"
                    size="large"
                    disabled={isSubmitting || isLoadingData}
                    isLoading={isSubmitting}
                  />
                ))}
            </Stack>
          </>
        )}
      </Form>
      {Snackbar}
    </>
  );
};

export default EditTenantRequestForm;
