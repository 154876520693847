export default {
  roleWasSuccessfullyCreated: "Role was successfully created",
  create: "Create",
  list: "List",
  createRole: "Create role",
  roles: "Roles",
  permissions: "Permissions",
  all: "All",
  properties: "Properties",
  companies: "Companies",
  save: "Save",
  attachAll: "Attach all",
  resetAll: "Reset all",
  preCreateMessage:
    "Your role was successfully created. Do you want to continue with assigning properties to the recently created role ?",
  yes: "Yes",
  no: "No",
  update: "Update",
  updated: "Updated",
  category: "Category",
  function: "Function",
  read: "Read",
  delete: "Delete",
  createNewRole: "Create New Role",
  abort: "Abort",
};
