import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { route } from "../../../../utils/url";
import { useFetch } from "../../../../hooks/useFetch";
import { Offer } from "../../../../types/be/offer";
import Form from "./Form";
import { getOffer } from "../../../../api/offer";
import { handleSubmit, prepareForm } from "./utils";
import { FormMessageInterface } from "../../../../types/form";
import FileTreeDialog from "../../../../ui/fileList/index";
import {
  FormMessageErrorState,
  FormMessageInItState,
} from "../../../../constants";
import Snackbar from "../../../../ui/Snackbar";
import Loader from "../../../Loader";
import { ErrorBox, LoadingBox } from "./styled";
import { Grid, Paper } from "@mui/material";
import { getReleaseRoute } from "../../utils";
import TabPageWrapper from "../../../../ui/tabPageWrapper";
import PdfViewerWrapper from "../../components/PdfViewerWrapper";
import useUploadDropzone from "../../../../hooks/useUploadDropzone";
import { OfferUpdateDto } from "./types";

const OfferEdit = (): ReactElement => {
  const { id, offerId } = useParams();
  const { t } = useTranslation();
  const {
    data: offer,
    run: runOffers,
    isLoading: offerLoading,
    isError: offerError,
  } = useFetch<Offer>();
  const formRef = useRef<FormikProps<
    Pick<
      OfferUpdateDto,
      "amount" | "contact_id" | "contact" | "area" | "area_id"
    >
  > | null>(null);
  const {
    isFileTreeVisible,
    handleOpenFileTree,
    handleCloseFileTree,
    fileName,
    setFileName,
    selectedFileId,
    setSelectedFileId,
  } = useUploadDropzone({ file: offer?.file });

  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    runOffers(getOffer(offerId!));
  }, []);
  /* istanbul ignore next */
  const handleClose = useCallback(() => {
    setShowSnackbar(false);
  }, []);
  /* istanbul ignore next */
  const handleFileTreeClick = async (
    file_id: string,
    file_name: string
  ): Promise<void> => {
    formRef?.current?.setFieldValue("file_id", parseInt(file_id));
    setFileName(file_name);
    setSelectedFileId(parseInt(file_id));
    handleCloseFileTree();
  };

  if (offerLoading)
    return (
      <LoadingBox>
        <Loader />
      </LoadingBox>
    );

  if (offerError || !offer)
    return <ErrorBox formMessage={FormMessageErrorState} />;

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("offers", Number(offerId)),
      name: t("offers.offer"),
    },
  ];

  return (
    <TabPageWrapper title={t("edit")} breadcrumbs={breadcrumbs} wrap={!id}>
      <Grid container justifyContent={"center"}>
        {
          /* istanbul ignore next */ selectedFileId && (
            <Grid item sm={6} xs={12}>
              <PdfViewerWrapper data={selectedFileId} type="fileId" />
            </Grid>
          )
        }
        <Grid item xs={12} sm={6} sx={{ pl: 6 }}>
          <Paper sx={{ padding: 6 }}>
            <Formik
              initialValues={prepareForm(offer)}
              onSubmit={handleSubmit({
                offer,
                setFormMessage,
                setShowSnackbar,
              })}
            >
              {(props) => (
                <Form
                  {...props}
                  formMessage={formMessage}
                  offer={offer}
                  handleOpenDialog={handleOpenFileTree}
                  fileName={fileName}
                  setFileName={setFileName}
                  setSelectedFileId={setSelectedFileId}
                />
              )}
            </Formik>
            {
              /* istanbul ignore next */ isFileTreeVisible && (
                <FileTreeDialog
                  onClose={handleCloseFileTree}
                  handleFileTreeClick={handleFileTreeClick}
                  extensions={["pdf"]}
                />
              )
            }
            <Snackbar
              message={t("offers.updatedSuccessfully")}
              color="success"
              open={showSnackbar}
              handleClose={handleClose}
              data-testid="snackbar-offer-success"
            />
          </Paper>
        </Grid>
      </Grid>
    </TabPageWrapper>
  );
};

export default OfferEdit;
