/* istanbul ignore file */

import styled from "styled-components/macro";
import { THEMES } from "../../../../../../constants";

export const CalculationTableTitle = styled.div`
  color: ${(props): string => props.theme.palette.primary.contrastText};
  color: ${(props): string => {
    if (props.theme.name === THEMES.DARK)
      return props.theme.palette.primary.contrastText;
    else return props.theme.palette.grey[900];
  }};
  font-weight: 700;
`;

export const CalculationTableHead = styled.div`
  background: ${(props): string => {
    if (props.theme.name === THEMES.DARK)
      return props.theme.palette.purple[900];
    else return props.theme.palette.grey[300];
  }};
  padding-left: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

export const VacancyRateWrapper = styled.div`
  border: ${(props): string => `1px solid ${props.theme.palette.purple[100]}`};
  border: ${(props): string => {
    if (props.theme.name === THEMES.DARK)
      return `1px solid ${props.theme.palette.purple[100]}`;
    else return `1px solid ${props.theme.palette.grey[500]}`;
  }};
  border-radius: 3px;
`;
