import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { SidebarItemsType } from "./types";
import reduceChildRoutes from "./reduceChildRoutes";
import { useTranslation } from "react-i18next";
import useAccessControl from "../../hooks/useAccessControl";
import Skeleton from "@mui/material/Skeleton";
import { SkeletonContainer } from "./styled";
import useAppSelector from "../../hooks/useAppSelector";

type SidebarNavListProps = {
  depth: number;
  pages: SidebarItemsType[];
};

/* istanbul ignore next */
const SkeletonLoader = (): ReactElement => {
  return (
    <>
      {new Array(10)?.fill(0)?.map((_, index) => (
        <SkeletonContainer key={index}>
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton variant="text" width="80%" height={20} />
        </SkeletonContainer>
      ))}
    </>
  );
};

const SidebarNavList: React.FC<SidebarNavListProps> = (props) => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const { t } = useTranslation();
  const { isRole, can, atLeastOnePermission } = useAccessControl();
  const { status } = useAppSelector((state) => state.client);

  const childRoutes = pages.reduce(
    (items, page) =>
      reduceChildRoutes({
        items,
        page,
        currentRoute,
        depth,
        isRole,
        can,
        atLeastOnePermission,
        t,
      }),
    [] as JSX.Element[]
  );

  return (
    <React.Fragment>
      {
        /* istanbul ignore next */ status === "pending" ? (
          <SkeletonLoader />
        ) : (
          childRoutes
        )
      }
    </React.Fragment>
  );
};

export default SidebarNavList;
