import { FormikValues } from "formik";
import { FormMessageInItState } from "../../../../../constants";
import { FormSubmitHandlerProps } from "../../../../../types/form";
import { updateProperty } from "../../../../../api/property";
import { mapBackendValidationErrors } from "../../../../../utils/form";
import { Property } from "../../../../../types/be/property";
import { mapBEPropertyToFEPropertyUpdate } from "../../../edit/utils";
import pick from "lodash/pick";
import { HTTP_STATUS_CODES } from "../../../../../types/server";
import { Dispatch, SetStateAction } from "react";
import { FetchState } from "../../../../../hooks/useFetch";

/* istanbul ignore next */
export const handleUpdatePropertyManagementFormSubmit = ({
  setFormMessage,
  afterSubmit,
  setProperty,
}: FormSubmitHandlerProps & {
  setProperty: Dispatch<SetStateAction<FetchState<Property>>>;
}) => {
  return async (
    data: Property,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const preparedProperty = mapBEPropertyToFEPropertyUpdate(data);
    const fields = [
      "id",
      "company_user_id",
      "caretaker_id",
      "property_management_cost",
      "contract_start",
      "contract_end",
      "contract_cancelled",
      "purchase_price",
    ];

    if (typeof preparedProperty.contract_id !== "string") {
      fields.push("contract_id");
    }

    const updatedFields = pick(preparedProperty, fields);
    const response = await updateProperty({
      ...updatedFields,
      company_id: data.company?.id.toString(),
    });
    const json = await response.json();

    if (response.ok) {
      const { data } = json;
      setProperty((prevState) => ({ ...prevState, data }));
      setStatus({
        success: true,
        errors: {},
      });
      setFormMessage(FormMessageInItState);
      afterSubmit && (await afterSubmit());
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({ type: "error", text: "errorSomethingWentWrong" });
      setSubmitting(false);
    }
  };
};
