import React, { ReactElement } from "react";
import { Grid } from "@mui/material";
import { Search as SearchIcon } from "react-feather";
import { Input, Search, SearchIconWrapper } from "../../../ui/table/styled";
import { useTranslation } from "react-i18next";
import { EmployeeOverviewToolbarProps, WeekAction } from "../types";
import useAccessControl from "../../../hooks/useAccessControl";
import { StyledButton } from "../../../ui/button/styled";
import { PermissionsUpdate, UserPermissions } from "../../../constants";

const EmployeeOverviewToolbar = ({
  openNewEntryModal,
  openVacationHolidayManagementModal,
  openHideEmployeeModal,
  handleTableSearch,
  switchWeeks,
  toggleLocationListModal,
}: EmployeeOverviewToolbarProps): ReactElement => {
  const { t } = useTranslation();
  const { can, isRole } = useAccessControl();

  return (
    <Grid container sx={{ pt: 5, mb: 2 }}>
      <Grid item>
        {can(UserPermissions.USER_SCHEDULE_ACCESS) && (
          <StyledButton
            sx={{ mr: 2 }}
            size="small"
            variant="contained"
            onClick={openNewEntryModal}
            color="success"
          >
            {t("create")}
          </StyledButton>
        )}
        {can(PermissionsUpdate.EMPLOYEE_OVERVIEW) && (
          <StyledButton
            sx={{ mr: 2 }}
            type="button"
            size="small"
            color="success"
            variant="outlined"
            onClick={toggleLocationListModal}
          >
            {t("employeeOverview.locationOfficeManagement")}
          </StyledButton>
        )}
        {isRole("admin") && (
          <StyledButton
            sx={{ mr: 2 }}
            type="button"
            size="small"
            color="success"
            data-testid="vacation-holiday-modal"
            onClick={openVacationHolidayManagementModal}
            variant="outlined"
          >
            {t("employeeOverview.vacationHolidaysManagement")}
          </StyledButton>
        )}
      </Grid>
      <Grid item xs />
      <Grid item>
        {Object.values(WeekAction).map((action) => (
          <StyledButton
            key={action}
            sx={{ mr: 2 }}
            type="button"
            size="small"
            variant="outlined"
            onClick={switchWeeks(action)}
          >
            {t(`employeeOverview.${action}`)}
          </StyledButton>
        ))}
        {
          /* istanbul ignore next */ isRole("admin") && (
            <StyledButton
              sx={{ mr: 2 }}
              type="button"
              size="small"
              color="error"
              variant="outlined"
              onClick={openHideEmployeeModal}
              data-testid="hide-employee-modal"
            >
              {t("employeeOverview.hide")}
            </StyledButton>
          )
        }
      </Grid>
      <Grid item>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Input placeholder={t("search")} onChange={handleTableSearch} />
        </Search>
      </Grid>
    </Grid>
  );
};

export default EmployeeOverviewToolbar;
