import styled from "styled-components/macro";
import Button from "../button/Button";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { blue, red } from "@mui/material/colors";
/* istanbul ignore next */
export const EditableSection = styled("div")`
  position: relative;
  width: 170px;
  p {
    word-break: break-all;
  }
`;
/* istanbul ignore next */
export const EditSectionButton = styled(EditIcon)`
  color: ${(): string => blue[500]};
  transition: 0.75s;
  cursor: pointer;
  margin-left: ${(props): string => props.theme.spacing(2)};
`;
/* istanbul ignore next */
export const SaveSectionButton = styled(Button)`
  transition: 0.75s;
  cursor: pointer;
  min-width: 0;
  margin-left: -5px;
  span {
    margin: 0;
  }
  svg {
    font-size: 22px !important;
  }
`;
/* istanbul ignore next */
export const CancelSectionButton = styled(CloseIcon)`
  color: ${(): string => red[600]};
  transition: 0.75s;
  cursor: pointer;
`;
/* istanbul ignore next */
export const LabelContainer = styled("div")`
  position: relative;
  display: flex;
  align-items: center;
`;

/* istanbul ignore next */
export const EditFieldContainer = styled("div")`
  position: relative;
  display: flex;
`;
