import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import Form from "../Form";
import { Box, Grid, Paper } from "@mui/material";
import {
  CURRENCIES,
  FormMessageErrorState,
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
  SUBMIT_STATUS,
} from "../../../../constants";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import useValidation from "../../../../hooks/useValidation";
import * as Yup from "yup";
import {
  handleCreateOrEditOutgoingInvoiceFormSubmit,
  prepareOutgoingInvoiceBeforeForm,
} from "../utils";
import { FormMessageInterface } from "../../../../types/form";
import PdfViewer from "../../../../ui/pdfViewer";
import { useTranslation } from "react-i18next";
import { route } from "../../../../utils/url";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../../hooks/useFetch";
import { ErrorBox } from "../../invoices/styled";
import Loader from "../../../Loader";
import { OutgoingInvoice } from "../../../../types/be/outgoingInvoices";
import { OutgoingInvoice as FEOutgoingInvoice } from "../../../../types/fe/outgoingInvoice";
import { getOutgoingInvoice } from "../../../../api/outgoingInvoice";
import { getInvoicePeriod } from "../../../../api/invoices";
import { InvoicePeriod } from "../../../../types/be/invoice";
import TabPageWrapper from "../../../../ui/tabPageWrapper";
import PdfViewerWrapper from "../../components/PdfViewerWrapper";
import useUploadDropzone from "../../../../hooks/useUploadDropzone";
import { getFileOCR } from "../../../../api/files";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { handleServerError } from "../../../../utils/http";
import FileTreeDialog from "../../../../ui/fileList";
import { LoaderContainer } from "../../invoices/edit/styled";
/* istanbul ignore next */
const OutgoingInvoiceEdit = (): ReactElement => {
  const { id, invoiceId } = useParams();
  const { t } = useTranslation();
  const ref = useRef<HTMLObjectElement | null>(null);
  const [submitAction, setSubmitAction] = useState<string | null>(null);
  const [prevImage, setPrevImage] = useState<string | null>(null);
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [loaderContainerVisible, setLoaderContainerVisible] = useState(false);
  const [isDynamicFile, setIsDynamicFile] = useState(true);
  const {
    number,
    invoice_date: date,
    invoice_receiver,
    apportionable_percent,
    property_id,
    currency_code,
    repeat_from,
    repeat_to,
    repeat_day,
    repetition_period_id,
    services,
    serviceDateFrom,
    serviceDateTo,
    notice,
  } = useValidation();
  const formRef = useRef<FormikProps<FEOutgoingInvoice> | null>(null);
  const {
    data: invoiceData,
    isLoading: isInvoiceLoading,
    isError: isInvoiceError,
    run: runInvoice,
  } = useFetch<OutgoingInvoice>();

  const {
    run: runInvoicePeriod,
    data: invoicePeriod,
    isLoading: isInvoicePeriodLoading,
    isError: isInvoicePeriodError,
  } = useFetch<InvoicePeriod[]>();

  const {
    setIsOcrLoading,
    isFileTreeVisible,
    isOcrLoading,
    handleOpenFileTree,
    handleCloseFileTree,
    fileName,
    setFileName,
    selectedFileId,
    setSelectedFileId,
  } = useUploadDropzone({ file: invoiceData?.file });

  useEffect(() => {
    runInvoice(getOutgoingInvoice(invoiceId));
    runInvoicePeriod(getInvoicePeriod());
  }, []);

  useEffect(() => {
    setLoaderContainerVisible(isFileLoading || isOcrLoading);
  }, [isFileLoading, isOcrLoading]);

  const handleFileTreeClick = (file_id: string, file_name: string): void => {
    setPrevImage(null);
    setFileName(file_name);
    handleCloseFileTree();
    handleOsc(Number(file_id));
  };

  const handleOsc = async (fileId: number): Promise<void> => {
    setIsOcrLoading(true);
    try {
      const res = await getFileOCR(fileId);
      const data = await res.json();
      if (res.status === HTTP_STATUS_CODES.OK) {
        const {
          data: { invoice_number: number, date, currency_code },
        } = data;

        formRef?.current?.setValues({
          ...formRef?.current.values,
          number: number ? number : null,
          date: date ? new Date(date) : null,
          currency_code: CURRENCIES.includes(currency_code)
            ? currency_code
            : "",
        });
        setSelectedFileId(fileId);
      } else {
        const { errorMessage } = handleServerError(res);
        formRef?.current?.setFieldError("file_id", errorMessage);
        console.log(errorMessage);
      }
    } catch (error) {
      formRef?.current?.setFieldError("file_id", SOMETHING_WENT_WRONG_ERROR);
      console.log(error);
    } finally {
      setPrevImage(null);
      setIsOcrLoading(false);
    }
  };

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: route("outgoing-invoices"),
      name: t("property.navLinks.outgoingInvoices"),
    },
  ];

  if (isInvoiceError || isInvoicePeriodError)
    return <ErrorBox formMessage={FormMessageErrorState} />;

  if (isInvoiceLoading || isInvoicePeriodLoading) return <Loader />;

  return (
    <>
      {invoiceData && (
        <TabPageWrapper
          title={t("update")}
          breadcrumbs={breadcrumbs}
          wrap={!id}
        >
          <Paper sx={{ p: 5, position: "relative" }}>
            {loaderContainerVisible && (
              <LoaderContainer>
                <Box>
                  <Loader />
                </Box>
              </LoaderContainer>
            )}
            <Grid container justifyContent={"center"}>
              {selectedFileId && !prevImage && (
                <Grid item xs={6} ref={ref}>
                  <PdfViewerWrapper data={selectedFileId} type="fileId" />
                </Grid>
              )}
              {isDynamicFile && prevImage && (
                <Grid item xs={6} ref={ref}>
                  <PdfViewer file={{ url: prevImage }} containerRef={ref} />
                </Grid>
              )}
              <Grid item xs={6} pl={6}>
                {formMessage.text && (
                  <FormAlert
                    formMessage={formMessage}
                    sx={{ marginBottom: "10px" }}
                  />
                )}
                <Formik
                  initialValues={prepareOutgoingInvoiceBeforeForm(invoiceData)}
                  validationSchema={
                    submitAction === SUBMIT_STATUS.PREVIEW
                      ? null
                      : Yup.object().shape({
                          number,
                          date,
                          contact_id: invoice_receiver,
                          repeat_from,
                          repeat_to,
                          repeat_day,
                          repetition_period_id,
                          apportionable_percent,
                          property_id,
                          currency_code,
                          services,
                          date_from: serviceDateFrom,
                          date_to: serviceDateTo,
                          notice,
                        })
                  }
                  onSubmit={handleCreateOrEditOutgoingInvoiceFormSubmit({
                    id,
                    submitAction,
                    setFormMessage,
                    setPrevImage,
                    invoiceId,
                  })}
                  innerRef={formRef}
                >
                  {(props): ReactElement => (
                    <Form
                      {...props}
                      propertyId={id}
                      submitAction={submitAction}
                      setSubmitAction={setSubmitAction}
                      invoicePeriod={invoicePeriod}
                      edit
                      handleOpenDialog={handleOpenFileTree}
                      fileName={fileName}
                      setFileName={setFileName}
                      handleOsc={handleOsc}
                      setIsFileLoading={setIsFileLoading}
                      selectedFileId={selectedFileId}
                      setIsDynamicFile={setIsDynamicFile}
                    />
                  )}
                </Formik>
                {isFileTreeVisible && (
                  <FileTreeDialog
                    onClose={handleCloseFileTree}
                    handleFileTreeClick={handleFileTreeClick}
                    extensions={["pdf"]}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </TabPageWrapper>
      )}
    </>
  );
};
export default OutgoingInvoiceEdit;
