import React, { useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { Card } from "./styled";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog, Autocomplete, Divider } from "@mui/material";
import * as Yup from "yup";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { FormMessageInterface } from "../../../../../types/form";
import {
  FormMessageInItState,
  SEARCH_PARAM_MIN_CHARACTERS,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../../constants";
import {
  createTopicFormInitData,
  handleCreateTopic,
  initCreateTopicRange,
} from "../../../../properties/insurances/list/topicList/topicListToolbar/topicCreate/utils";
import useValidation from "../../../../../hooks/useValidation";
import { DialogTitle } from "./styled";
import debounce from "lodash/debounce";
import { HTTP_STATUS_CODES } from "../../../../../types/server";
import { InsuranceTopic } from "../../../../../types/be/insurance";
import { route } from "../../../../../utils/url";
import { SelectOrCreateTopicProps } from "./types";
import { isNodeEnv } from "../../../../../utils/env";
import { getPropertyTopics } from "../../../../../api/insurance";
import Form from "./Form";
import { StyledPaper } from "../../../../contacts/autocomplete/styled";

/* istanbul ignore next */
const SelectOrCreateTopic = ({
  handleDialog,
  inboxData,
}: SelectOrCreateTopicProps): React.ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { type, property_id } = useValidation();
  const { id } = useParams();
  const [searchOptions, setSearchOptions] = useState<InsuranceTopic[]>([]);
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState<(Date | null)[]>(initCreateTopicRange);
  const [formMessage, setFormMessage] =
    React.useState<FormMessageInterface>(FormMessageInItState);

  const onTextChange = React.useCallback(
    debounce(
      async (search: string): Promise<void> => {
        if (search.length < SEARCH_PARAM_MIN_CHARACTERS) setSearchOptions([]);
        else {
          setLoading(true);
          const response = await getPropertyTopics(`?search=${search}`);
          if (response.status === HTTP_STATUS_CODES.OK) {
            const json = await response.json();
            setSearchOptions(json.data);
          } else {
            setFormMessage({
              type: "error",
              text: SOMETHING_WENT_WRONG_ERROR,
            });
          }
        }
        setLoading(false);
      },
      isNodeEnv("test") ? 0 : 300
    ),
    []
  );

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="xs"
      aria-labelledby="max-width-dialog-title"
      data-testid="create-topic-modal"
    >
      <DialogTitle
        title="inbox.selectOrCreateInsuranceTopic"
        onClose={handleDialog}
      />
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item md={12}>
              <Typography variant="subtitle1" component="div" mb={3}>
                {t("form.select")}
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Autocomplete
                options={searchOptions}
                data-testid="search-insurance-topic"
                getOptionLabel={(option) => `${option.property?.object_name}`}
                onChange={(_, value: InsuranceTopic | null) =>
                  inboxData &&
                  navigate(route("insurances.create", value?.id), {
                    state: { inboxData },
                  })
                }
                loading={loading}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.property?.object_name}
                  </li>
                )}
                PaperComponent={StyledPaper}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size={"small"}
                    label={t("inbox.selectTopic")}
                    InputLabelProps={{ shrink: true }}
                    placeholder={t("search")}
                    onChange={(e) => onTextChange(e.target.value)}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={3} mt={5} mb={5}>
            <Grid item xs>
              <Divider />
            </Grid>
            <Grid item>{t("inbox.orTextInCapitalLetters")}</Grid>
            <Grid item xs>
              <Divider />
            </Grid>
          </Grid>
          <Card>
            <Typography variant="subtitle1" component="div" mb={3}>
              {t("create")}
            </Typography>
            <Formik
              initialValues={{
                ...createTopicFormInitData,
                property_id: String(id || ""),
              }}
              enableReinitialize
              validationSchema={Yup.object().shape({ type, property_id })}
              onSubmit={handleCreateTopic({
                setFormMessage,
                handleDialog,
                range,
                inboxData,
                navigate,
              })}
            >
              {(props): React.ReactElement => (
                <Form
                  formMessage={formMessage}
                  range={range}
                  setRange={setRange}
                  inboxData={inboxData}
                  {...props}
                />
              )}
            </Formik>
          </Card>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SelectOrCreateTopic;
