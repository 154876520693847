import {
  Dialog,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
import { LabelText } from "../../../properties/invoices/show/styled";
import { ActivityModalProps } from "../types";
import { ACTIVITY_DATE_AND_TIME_FORMAT } from "../utils";
import moment from "moment";

const ShowModal = ({
  showActivityModal,
  data,
  handleCloseActivityModal,
}: ActivityModalProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={showActivityModal}
      aria-labelledby="max-width-dialog-title"
      data-testid="show-activity-modal"
      onClose={handleCloseActivityModal}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle
        title="userActivity.activity"
        onClose={handleCloseActivityModal}
      />
      <DialogContent>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={12}>
            <Table aria-label="invoice-show">
              <TableBody>
                <TableRow>
                  <TableCell scope="row">
                    <LabelText>{t("type")}</LabelText>
                  </TableCell>
                  <TableCell align={"right"} scope="row">
                    {data?.type?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">
                    <LabelText>{t("employeeOverview.user")}</LabelText>
                  </TableCell>
                  <TableCell align={"right"} scope="row">
                    {`${data?.user?.first_name} ${data?.user?.last_name}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">
                    <LabelText>{t("email")}</LabelText>
                  </TableCell>
                  <TableCell align={"right"} scope="row">
                    {data?.user?.email}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">
                    <LabelText>{t("userActivity.dateAndTime")}</LabelText>
                  </TableCell>
                  <TableCell align={"right"} scope="row">
                    {moment
                      .unix(Number(data?.created_at))
                      .format(ACTIVITY_DATE_AND_TIME_FORMAT)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ShowModal;
