import { Area as BEArea } from "../../../../../../types/be/area";
import { Area as FEArea } from "../../../../../../types/fe/area";
import omit from "lodash/omit";

export const prepareRowForTable = (row: BEArea): FEArea => ({
  ...omit(row, "type", "usage", "category"),
  asset_managers: row.asset_managers,
  type_name: row.type?.name,
  usage_name: row.usage?.name,
  category_id: row.category?.id,
  contact_id: row.active_tenant?.contact?.id,
  isFutureTenant: Boolean(row.future_tenant),
  contactFullName: row.active_tenant?.contact?.full_name,
  comments_count: row.comments_count,
  active_tenant: row.active_tenant
    ? {
        ...row.active_tenant,
        empty_space_at_purchase: Boolean(
          row.active_tenant.empty_space_at_purchase
        ),
        options: Number(row.active_tenant.options),
        new: Boolean(row.active_tenant.new),
        tenant_id: row.active_tenant.tenants.map((el) => el.id),
        tenants: row.active_tenant.tenants,
      }
    : null,
});
