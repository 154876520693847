import { Maintenance } from "../../../../types/be/maintenance";
import { MaintenanceForm } from "../create/types";

export const prepareInitialValues = (data: Maintenance): MaintenanceForm => {
  return {
    ...data,
    contact_id: data.contact.id?.toString(),
    report_id: data.report.id?.toString(),
    inspection: data.inspection,
    order_id: data.order.id?.toString(),
    maintenance_type_id: data.maintenance_type.id?.toString(),
    contract_id: data.contract?.id?.toString(),
  };
};
