import { TableCell } from "../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "employeeOverview.user",
    align: "left",
  },
  {
    id: `role_name`,
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "table.role",
  },
  {
    id: `created_at`,
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "employeeOverview.createdAt",
  },
];

export const ALL_ROLES = "all";
