import http from "../utils/http";

export const getCounterTypes = async (): Promise<Response> => {
  return await http.get("/api/counters/types");
};
/* istanbul ignore next */
export const deleteReading = async (id: number): Promise<Response> => {
  return await http.delete(`/api/readings/${id}`);
};

export const createReading = async (payload: FormData): Promise<Response> => {
  return await http.post(`/api/readings`, payload);
};

export const createCounter = async (payload: FormData): Promise<Response> => {
  return await http.post("/api/counters", payload);
};

export const getCounterList = async (params: string): Promise<Response> => {
  return await http.get(`/api/counters${params}`);
};

export const getCounterDetails = async (id: string): Promise<Response> => {
  return await http.get(`/api/counters/${id}`);
};

export const getReadingsList = async (params: string): Promise<Response> => {
  return await http.get(`/api/readings${params}`);
};
