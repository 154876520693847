import { Contact, ContactType, FormSubmitHandlerProps } from "../types";
import { FormikValues } from "formik/dist/types";
import { updateContact } from "../../../../api/contacts";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../constants";
import { replaceNullOrUndefinedByEmptyString } from "../../../../utils/common";
import { Company, Salutation } from "../../../../types/models";
import { touchedInitState } from "../utils";

/* istanbul ignore next */
const prepareContactBeforeSubmit = (data: Contact): Contact => {
  return {
    ...data,
    street: data?.location?.street ?? null,
    place_id: data?.location?.place_id ?? null,
    city: data?.location?.city ?? null,
    house_number: data?.location?.house_number ?? null,
    zip_code: data?.location?.zip_code ?? null,
    country: data.location?.country_code ?? null,
    state: data.location?.state ?? null,
  };
};

export const handleUpdateContactFormSubmit = (
  id: string,
  { setFormMessage }: FormSubmitHandlerProps
) => {
  return async (
    values: Contact,
    { setStatus, setSubmitting, setTouched }: FormikValues
  ): Promise<void> => {
    const response = await updateContact(
      id,
      prepareContactBeforeSubmit(values)
    );
    const json = await response.json();
    if (response.status === 200) {
      setStatus({
        success: true,
        errors: {},
      });
      setTouched(touchedInitState);
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
      setSubmitting(false);
    }
  };
};

export const setContactDetailsBeforeForm = (data: Contact): Contact => ({
  ...data,
  ...replaceNullOrUndefinedByEmptyString(data),
  salutation_id: data.salutation && String((data.salutation as Salutation)?.id),
  type_id: data.type && String((data?.type as ContactType)?.id),
  ranking_id: data.ranking?.id,
  company_id: data.company && String((data.company as Company)?.id),
});
