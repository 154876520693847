import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  FormMessageErrorState,
  FORWADED_STATUS,
  HISTORY_STATUS,
  REQUEST_STATUS_IDS,
  STARTING_PAGE,
  TOPICS_PER_PAGE,
  UserRoles,
} from "../../../../../constants";
import { useFetch } from "../../../../../hooks/useFetch";
import useIsMounted from "../../../../../hooks/useIsMounted";
import {
  InsuranceTopic as BEInsuranceTopic,
  InsuranceTopicType as BEInsuranceTopicType,
} from "../../../../../types/be/insurance";
import { ReleaseStatus, Status } from "../../../../../types/be/status";
import useTable from "../../../../../ui/table/useTable";
import find from "lodash/find";
import {
  FILTER_TYPE,
  prepareQueryParams,
  replaceNullOrUndefinedByEmptyString,
} from "../../../../../utils/common";
import { formatDateToTimespamp } from "../../../../../utils/date";
import {
  getInsuranceTopicTypes,
  getInsuranceTopics,
} from "../../../../../api/insuranceTopics";
import { getInsuranceStatuses } from "../../../../../api/insurance";
import { handleServerError } from "../../../../../utils/http";
import { ErrorBox } from "../../../offers/list/styled";
import Loader from "../../../../Loader";
import InsuranceList from "./insuranceList";
import TopicListToolbar from "./topicListToolbar";
import { getReleaseRoute, initInsuranceRange } from "./utils";
import { Box, Paper, TablePagination } from "@mui/material";
import { ROWS_PER_PAGE_OPTIONS } from "../../../../../ui/table/utils";
import { useTranslation } from "react-i18next";
import { epochToTimeStamp } from "../../../../../utils/date";
import { route } from "../../../../../utils/url";
import { camelCase } from "lodash";
import useAccessControl from "../../../../../hooks/useAccessControl";
import { DOCUMENT_RELEASE_MODULE } from "../../../../../types/be/releaseFlow";
import kebabCase from "lodash/kebabCase";
import { FORWARDED_ROUTE } from "../../../../../constants";
import { usePropertySelect } from "../../../../../hooks/usePropertySelect";
import TabPageWrapper from "../../../../../ui/tabPageWrapper";
import HistoryList from "../../../components/history/HistoryList";
import { HISTORY } from "../../../components/history/types";
import useDocumentRelease from "../../../components/documentRelease/useDocumentRelease";

const InsuranceTopicList = (): ReactElement => {
  const { id, type, status } = useParams();
  const navigate = useNavigate();
  const [activeTypeId, setActiveTypeId] = useState<number | null>(null);
  const [activeStatusId, setActiveStatusId] = useState<number | null>(null);
  const [range, setRange] = useState<(Date | null)[]>(initInsuranceRange);
  const isMounted = useIsMounted();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const [insuranceStatusWithReleaser, setInsuranceStatusWithReleaser] =
    useState<Status[]>([]);
  const { propertyId, property, isGeneral, onPropertySelect } =
    usePropertySelect(id);
  const { isRole } = useAccessControl();

  const {
    configuration,
    isConfigurationLoading,
    handleReleaseDocument,
    handleForwardDocument,
    releasePermission,
    snackbar,
    loading: isDocumentReleaseLoading,
  } = useDocumentRelease(
    DOCUMENT_RELEASE_MODULE.INSURANCE,
    DOCUMENT_RELEASE_MODULE.INSURANCE_TOPIC
  );
  const {
    data: statuses,
    run: runStatuses,
    isLoading: isStatusesLoading,
    isError: isStatusesError,
  } = useFetch<ReleaseStatus[]>();

  const {
    data: types,
    run: runTypes,
    isLoading: isTypesLoading,
    isError: isTypesError,
  } = useFetch<BEInsuranceTopicType[]>();

  const {
    data: insuranceTopics,
    setData,
    total,
    setTotal,
    isLoading: isInsuranceTopicsLoading,
    setIsLoading,
    error,
    setError,
    setRowsPerPage,
    setCurrentPage,
    queryParams,
    handleTableSearch,
    rowsPerPage,
    currentPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTable<BEInsuranceTopic>();

  useEffect(() => {
    runTypes(getInsuranceTopicTypes());
    runStatuses(getInsuranceStatuses());
    setRowsPerPage(TOPICS_PER_PAGE);
  }, []);

  useEffect(() => {
    /* istanbul ignore next */
    if (!type && configuration && status !== "history") {
      if (id) {
        navigate(route("properties.insurances.building", id, "forwarded"));
      } else {
        navigate(route("insurances.building", "forwarded"));
      }
    }
  }, [type, configuration, id]);

  /* istanbul ignore next */
  useEffect(() => {
    /* istanbul ignore next */
    if (status && type == "undefined") {
      navigate(
        getReleaseRoute(
          `insurances.${kebabCase(status)}`,
          Number(id),
          "building"
        )
      );
    }
  }, [type, status]);

  /* istanbul ignore next */
  useEffect(() => {
    statuses && setInsuranceStatusWithReleaser([FORWADED_STATUS, ...statuses]);

    const historyStatus = isRole(UserRoles.ADMIN) ? [HISTORY_STATUS] : [];

    statuses &&
      setInsuranceStatusWithReleaser([
        FORWADED_STATUS,
        ...statuses,
        ...historyStatus,
      ]);
  }, [statuses, configuration, status, id]);

  useEffect(() => {
    if (!configuration) return;

    const activeType = find(
      types,
      ({ code }) => code?.toLowerCase() === type?.toLowerCase()
    );
    activeType && setActiveTypeId(activeType?.id);
    const activeStatus = find(
      insuranceStatusWithReleaser,
      ({ code }) => camelCase(code) === camelCase(status)
    );
    /* istanbul ignore next */
    activeStatus && setActiveStatusId(activeStatus?.id);
    setCurrentPage(STARTING_PAGE);
  }, [types, insuranceStatusWithReleaser, type, status, configuration]);

  useEffect(() => {
    const startDate = searchParams.get("from");
    const endDate = searchParams.get("to");
    /* istanbul ignore next */
    startDate &&
      endDate &&
      setRange([
        new Date(epochToTimeStamp(Number(startDate))),
        new Date(epochToTimeStamp(Number(endDate))),
      ]);
  }, []);

  const [startDate, endDate] = range;
  const dateValidation = !startDate || (startDate && startDate !== endDate);

  /* istanbul ignore next */
  useEffect(() => {
    status === undefined && setActiveStatusId(-1);
  }, [status]);

  useEffect(() => {
    isMounted() &&
      activeTypeId &&
      activeStatusId &&
      dateValidation &&
      fetchInsuranceTopics();
  }, [
    queryParams,
    types?.length,
    activeTypeId,
    range,
    propertyId,
    activeStatusId,
    insuranceStatusWithReleaser?.length,
    isGeneral,
  ]);

  /* istanbul ignore next */
  const fetchInsuranceTopics = useCallback(
    async (loading = true) => {
      if (activeStatusId === HISTORY_STATUS.id) return;
      setIsLoading(loading);
      const params: Record<string, string | number | undefined> = {
        type_id: activeTypeId!,
        from: formatDateToTimespamp(startDate!),
        to: formatDateToTimespamp(endDate!),
      };

      if (isGeneral === FILTER_TYPE.GENERAL || propertyId || id) {
        params.property_id = propertyId || id || "";
      }

      if (activeStatusId === -1) {
        params.is_was_forwarded = 1;
        params.fr_status_id = REQUEST_STATUS_IDS.NEW;
      } else if (activeStatusId) {
        params.fr_status_id = activeStatusId;
      }

      const response = await getInsuranceTopics(
        prepareQueryParams(
          queryParams,
          replaceNullOrUndefinedByEmptyString(params),
          true
        )
      );

      if (response.status !== 200) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        const resJson = await response.json();
        setTotal(resJson?.meta?.total);
        setData(resJson.data);
      }
      setIsLoading(false);
    },
    [
      activeTypeId,
      queryParams,
      setIsLoading,
      setTotal,
      setData,
      setIsLoading,
      id,
      range,
      propertyId,
      activeStatusId,
      status,
      isGeneral,
    ]
  );
  /* istanbul ignore next */
  const redirectInsuranceHistory = (): string => {
    if (id) {
      return route("properties.insurances.history", id);
    } else {
      return route("insurances.history");
    }
  };
  /* istanbul ignore next */
  const handleStatusChange = useCallback(
    (status: number | null, code: string) => {
      if (status === HISTORY_STATUS.id) {
        navigate(redirectInsuranceHistory());
      } else {
        setActiveStatusId(status);
        navigate(
          getReleaseRoute(
            `insurances.${kebabCase(code)}`,
            Number(id),
            type || "building"
          )
        );
      }
    },
    [setActiveStatusId, type, redirectInsuranceHistory]
  );

  if (isTypesError || isStatusesError || error)
    return <ErrorBox formMessage={FormMessageErrorState} />;

  if (isTypesLoading || isStatusesLoading || isConfigurationLoading) {
    return <Loader />;
  }

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
  ];

  return (
    <TabPageWrapper
      breadcrumbs={breadcrumbs}
      title={t("property.navLinks.insurances")}
      wrap={!id}
    >
      <>
        <Box>
          {
            /* istanbul ignore next */ status === "history" ? (
              <HistoryList
                documentRelease={HISTORY.INSURANCE}
                activeStatusId={activeStatusId}
                handleStatusChange={handleStatusChange}
                statuses={insuranceStatusWithReleaser || []}
                useOnWidget={false}
                noDataMessage="table.noInsuranceHistoryIsAvailable"
              />
            ) : (
              <>
                {insuranceStatusWithReleaser && types && (
                  <TopicListToolbar
                    setActiveTypeId={setActiveTypeId}
                    types={types}
                    activeTypeId={activeTypeId}
                    setActiveStatusId={setActiveStatusId}
                    statuses={insuranceStatusWithReleaser}
                    activeStatusId={activeStatusId}
                    handleStatusChange={handleStatusChange}
                    range={range}
                    setRange={setRange}
                    handleTableSearch={handleTableSearch}
                    fetchInsuranceTopics={fetchInsuranceTopics}
                    propertyId={id}
                    setCurrentPage={setCurrentPage}
                    property={property}
                    onPropertySelect={onPropertySelect}
                    defaultStatus={FORWARDED_ROUTE}
                  />
                )}
                {isInsuranceTopicsLoading ? (
                  <Loader />
                ) : (
                  <>
                    {insuranceTopics.length ? (
                      insuranceTopics.map(
                        (insuranceTopic: BEInsuranceTopic) => (
                          <InsuranceList
                            key={insuranceTopic.id}
                            insurances={insuranceTopic.insurances}
                            propertyId={id}
                            setError={setError}
                            fetchInsuranceTopics={fetchInsuranceTopics}
                            insuranceTopic={insuranceTopic}
                            statuses={statuses!}
                            configuration={configuration}
                            handleForwardDocument={handleForwardDocument}
                            handleReleaseDocument={handleReleaseDocument}
                            isConfigurationLoading={isConfigurationLoading}
                            releasePermission={releasePermission}
                            isDocumentReleaseLoading={isDocumentReleaseLoading}
                          />
                        )
                      )
                    ) : (
                      <Box>{t("table.noInsurancesAreAvailable")}</Box>
                    )}
                    {insuranceTopics.length ? (
                      <Paper sx={{ width: "100%", mb: 10, px: 10, py: 1 }}>
                        <TablePagination
                          data-testid={"table-pagination"}
                          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                          component="div"
                          count={total}
                          rowsPerPage={rowsPerPage}
                          labelRowsPerPage={t(
                            "insurance.insuranceTopicsPerPage"
                          )}
                          page={currentPage}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : null}
                  </>
                )}
              </>
            )
          }
        </Box>
        {snackbar.Snackbar}
      </>
    </TabPageWrapper>
  );
};

export default InsuranceTopicList;
