import { EditInventoryItemType, FormSubmitHandlerProps } from "./types";
import { FormikValues } from "formik";
import { replaceMomentByTimestamp } from "../../../utils/date";
import { updateInventoryItem } from "../../../api/companyOwnership";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { mapBackendValidationErrors } from "../../../utils/form";

export const addEmployeeInitialValues: Partial<EditInventoryItemType> = {
  received_date: null,
  user_id: "",
};

export const handleEditInventoryItemFormSubmit = (
  id: string,
  { setFormMessage, afterAddEmployee }: FormSubmitHandlerProps
) => {
  return async (
    values: Partial<EditInventoryItemType>,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const response = await updateInventoryItem(id, {
      ...values,
      received_date: values?.received_date
        ? replaceMomentByTimestamp(values.received_date)
        : null,
    });
    const json = await response.json();

    if (response.status === HTTP_STATUS_CODES.OK) {
      setStatus({
        success: true,
        errors: {},
      });
      afterAddEmployee && afterAddEmployee();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
    }
    setSubmitting(false);
  };
};
