import DashboardLayout from "../../layouts/dashboard";
import React from "react";
import TenantRequest from "../../components/tenantRequests";
import TenantRequestDetails from "../../components/tenantRequests/edit";
import OutdatedTenantRequests from "../../components/tenantRequests/outdated";
import CreateTenantRequest from "../../components/tenantRequests/create";
import TenantRequestHistory from "../../components/tenantRequests/history";
import AccessGuard from "../../components/guards/AccessGuard";
import { PermissionsCreate, PermissionsRead } from "../../constants";
import AuthGuard from "../../components/guards/AuthGuard";

export default {
  path: "/:client/tenant-requests",
  element: (
    <AuthGuard clientModuleName="mobile-app">
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: (
        <AccessGuard permissions={[PermissionsRead.TENANT_REQUEST]}>
          <TenantRequest />
        </AccessGuard>
      ),
    },
    {
      path: "create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.TENANT_REQUEST]}>
          <CreateTenantRequest />
        </AccessGuard>
      ),
    },
    {
      path: "outdated",
      element: (
        <AccessGuard permissions={[PermissionsRead.TENANT_REQUEST]}>
          <OutdatedTenantRequests />
        </AccessGuard>
      ),
    },
    {
      path: ":id",
      element: (
        <AccessGuard permissions={[PermissionsRead.TENANT_REQUEST]}>
          <TenantRequestDetails />
        </AccessGuard>
      ),
    },
    {
      path: "history",
      element: (
        <AccessGuard permissions={[PermissionsRead.TENANT_REQUEST]}>
          <TenantRequestHistory />
        </AccessGuard>
      ),
    },
  ],
};
