import React, {
  ReactElement,
  useState,
  useCallback,
  useEffect,
  SyntheticEvent,
} from "react";
import { contactAutocomplete } from "../../../api/contacts";
import { useTranslation } from "react-i18next";
import { ContactListData } from "../list/types";
import { FormikValues } from "formik/dist/types";
import { PlusIcon } from "../../../ui/table/styled";
import CreateContactPopup from "./CreateContactPopup";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import MUIAutocomplete from "@mui/material/Autocomplete";
import debounce from "lodash/debounce";
import { EMPTY_DATA, SEARCH_PARAM_MIN_CHARACTERS } from "../../../constants";
import { StyledPaper } from "./styled";
import {
  prepareQueryParams,
  replaceNullOrUndefinedByEmptyString,
} from "../../../utils/common";
import { Contact } from "../edit/types";
import Snackbar from "../../../ui/Snackbar";
import { AutocompleteOption } from "../../../ui/autocomplete/types";
import { useFetch } from "../../../hooks/useFetch";

const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: ContactListData
): ReactElement => (
  <Box key={`${option.id}-${option.email}`} component="li" {...props}>
    <Grid>
      <Grid>
        <Typography style={{ fontWeight: "600" }}>
          {option.full_name}
        </Typography>{" "}
        <Typography style={{ fontSize: "11px" }}>{option.email}</Typography>
      </Grid>
      <Grid style={{ fontSize: "11px", fontStyle: "italic" }}>
        {option.company_name}
      </Grid>
    </Grid>
  </Box>
);
/* istanbul ignore next */
const SelectOrCreateContactsAutocompleteField = ({
  errors,
  status,
  touched,
  values,
  setFieldValue,
  required = false,
  label,
  placeholder,
  setOutState,
  textFieldParams,
  size = "medium",
  sx = { minHeight: "74px", mb: 1 },
  addIconSx = { display: "flex", justifyContent: "center" },
  variant = "outlined",
  disableUnderline = false,
  submitForm,
  disabled = false,
  table = false,
  rentedForm = false,
  disabledTooltipText = "",
  showContactFullTooltip = false,
  displayOnlyFullName = false,
  editPermissions = true,
  fileId,
  defaultPreloadedData = null,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = React.useState<
    readonly ContactListData[] | null
  >(null);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const {
    data: preloadedContactsData,
    run: runPreloadedContactsList,
    setState: setPreloadedContactData,
  } = useFetch<AutocompleteOption[]>();

  const [contactListData, setContactListData] =
    useState<ContactListData | null>(null);
  const [isCreateModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const handleInputChange = useCallback(async (search: string) => {
    const params = prepareQueryParams("", {
      search,
      limit: "10",
    });
    const response = await contactAutocomplete(params);
    const { data } = await response.json();
    return data;
  }, []);

  const onSelectContact = async (
    _: SyntheticEvent,
    contact: ContactListData | null
  ): Promise<void> => {
    setOutState && setOutState(contact);
    setContactListData && setContactListData(contact);
    setFieldValue("contact_id", contact?.id);
  };

  const onSearchFieldTextChange = React.useCallback(
    debounce(async (search: string): Promise<void> => {
      if (search.length < SEARCH_PARAM_MIN_CHARACTERS)
        setOptions(
          preloadedContactsData
            ? (preloadedContactsData as ContactListData[])
            : null
        );
      else {
        setLoading(true);

        try {
          const response = await handleInputChange(search);
          setOptions([...response]);
          setLoading(false);
        } catch (e) {
          setOptions([]);
        }
      }
    }, 0),
    [handleInputChange, preloadedContactsData]
  );

  const patchCreatedContact = (data: Contact | null): void => {
    data && setContactListDataFromContact(data);
    setIsSnackbarVisible(true);
  };

  const setContactListDataFromContact = (data: Contact): void => {
    const name = data.full_name
      ? data.full_name
      : `${data.first_name} ${data.last_name}`;

    const contactListData = {
      id: parseInt(data.id),
      full_name: name,
      company_name: data.company_name ? data.company_name : "",
      address: "",
      email: data.email,
      name: name,
      telephone: parseInt(data.business_phone),
      tags: [
        {
          id: 0,
          title: "",
          code: "",
        },
      ],
      has_relation: data?.has_relation,
      location: {
        place_id: "",
        lat: null,
        lng: null,
        full_address: "",
        zip_code: "",
        street: "",
        city: "",
        house_number: "",
        country_code: "",
        state_code: "",
        state: "",
      },
    };

    replaceNullOrUndefinedByEmptyString(contactListData);
    setContactListData(contactListData);
    setFieldValue("contact_id", data.id);
  };

  useEffect(() => {
    if (table && values.contact_id && values.contact.id !== values.contact_id) {
      submitForm();
    }
  }, [values.contact_id]);

  const openCreateContactPopup = (): void => {
    setCreateModalOpen(true);
  };

  const handleCloseCreateContactModal = (): void => {
    setCreateModalOpen(false);
  };

  useEffect(() => {
    if (!open && !preloadedContactsData) setOptions(null);
  }, [open, preloadedContactsData]);

  useEffect(() => {
    if (preloadedContactsData)
      setOptions(preloadedContactsData as ContactListData[]);
  }, [preloadedContactsData]);

  useEffect(() => {
    if (defaultPreloadedData) {
      setPreloadedContactData((prev) => ({
        ...prev,
        data: defaultPreloadedData,
      }));
    } else {
      runPreloadedContactsList(contactAutocomplete(""));
    }
  }, [defaultPreloadedData]);

  useEffect(() => {
    if (!values.contact) return setContactListData(null);
    !setOutState
      ? setContactListDataFromContact(values.contact)
      : setContactListData(values.contact);
  }, [values.contact]);

  useEffect(() => {
    setIsSnackbarVisible(isSnackbarVisible);
  }, [setIsSnackbarVisible]);

  const handleCloseSnackbar = (): void => {
    setIsSnackbarVisible(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={rentedForm ? 11 : 10}>
          <MUIAutocomplete
            id="contact-autocomplete"
            open={open}
            options={options ?? []}
            value={contactListData || null}
            loading={loading}
            disabled={!editPermissions || disabled}
            onOpen={(): void => setOpen(true)}
            onClose={(): void => setOpen(false)}
            onChange={onSelectContact}
            renderOption={renderOption}
            getOptionLabel={(option): string =>
              displayOnlyFullName
                ? option.full_name
                : `${option.full_name} ${option.email} ${
                    option.company_name ? option.company_name : ""
                  }`
            }
            isOptionEqualToValue={(option, value): boolean =>
              option.email === value.email
            }
            noOptionsText={!options ? t("startTyping") : undefined}
            PaperComponent={StyledPaper}
            renderInput={(params): ReactElement => (
              <Tooltip
                placement="bottom-start"
                aria-label="add"
                open={!open && showTooltip}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                title={
                  disabled ? (
                    editPermissions ? (
                      t(disabledTooltipText)
                    ) : null
                  ) : showContactFullTooltip && values.contact ? (
                    <>
                      {values.contact.full_name && (
                        <>
                          <span>{values.contact.full_name}</span>
                          <br />
                        </>
                      )}
                      {values.contact.street && values.contact.house_number && (
                        <>
                          <span>
                            {values.contact.street}{" "}
                            {values.contact.house_number}
                          </span>
                          <br />
                        </>
                      )}
                      {values.contact.zip_code && values.contact.city && (
                        <>
                          <span>
                            {values.contact.zip_code} {values.contact.city}
                          </span>
                          <br />
                        </>
                      )}
                    </>
                  ) : null
                }
              >
                <TextField
                  {...params}
                  variant={variant}
                  required={required}
                  name={"contact_id"}
                  sx={sx}
                  label={label}
                  size={size}
                  placeholder={editPermissions ? placeholder : EMPTY_DATA}
                  fullWidth
                  disabled={disabled}
                  value={values.contact_id || ""}
                  error={Boolean(
                    touched.contact_id &&
                      (status?.errors.contact_id || errors.contact_id)
                  )}
                  helperText={
                    touched.contact_id &&
                    (status?.errors.contact_id || errors.contact_id)
                  }
                  onChange={(e): Promise<void> | undefined =>
                    onSearchFieldTextChange(e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    ...(variant === "standard" && {
                      disableUnderline: disableUnderline,
                    }),
                    endAdornment: (
                      <>
                        {loading && (
                          <CircularProgress color="inherit" size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  {...textFieldParams}
                />
              </Tooltip>
            )}
          />
        </Grid>
        {editPermissions && !disabled && (
          <Grid
            item
            xs={rentedForm ? 1 : 2}
            pb={size === "small" ? 3 : 0}
            pt={size === "small" ? 0 : 3}
            sx={addIconSx}
          >
            <Tooltip title={"Create contact"}>
              <PlusIcon
                role={"dataItems-create-contact"}
                onClick={openCreateContactPopup}
                size={18}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <CreateContactPopup
        {...{
          isModalOpen: isCreateModalOpen,
          handleCloseCreateContactModal,
          patchCreatedContact,
          fileId,
        }}
      />
      <Snackbar
        message={t("createContact.contactWasSuccessfullyCreated")}
        color="success"
        open={isSnackbarVisible}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};
export default SelectOrCreateContactsAutocompleteField;
