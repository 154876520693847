import AuthGuard from "../../components/guards/AuthGuard";
import DashboardLayout from "../../layouts/dashboard";
import employeeOverview from "../employeeOverview";
import vacationRequests from "../vacationRequests";
import React from "react";
import companyOwnership from "../companyOwnership";

export default {
  path: "/:client/human-resources",
  element: (
    <AuthGuard clientModuleName="human-resources">
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [employeeOverview, vacationRequests, companyOwnership],
};
