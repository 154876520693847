import { TableCell } from "../../../../types/table";
import { FormikValues } from "formik/dist/types";
import { FinanceProfile } from "../../../../types/models";
import {
  createFinanceProfiles,
  editFinanceProfile,
} from "../../../../api/financeProfile";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../constants";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { replaceStringByParsedNumber } from "../../../../utils/common";
import { FinanceProfileSubmitHandlerProps } from "./types";

export const financeProfileFormInitState: FinanceProfile = {
  id: 0,
  zip_code: "",
  state: null,
  state_id: "",
  finance_volume_from_value: "",
  finance_volume_to_value: "",
  avg_repayment: "",
  ltv: "",
  avg_interest_rate: "",
};

export const itemsListHeadCells: TableCell[] = [
  {
    id: "zip_code",
    numeric: true,
    disablePadding: false,
    sortable: false,
    label: "financeProfile.zipCode",
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "financeProfile.state",
  },
  {
    id: "finance_volume_from_value",
    numeric: true,
    disablePadding: false,
    sortable: false,
    label: "financeProfile.financeVolumeFromValue",
    align: "right",
  },
  {
    id: "finance_volume_to_value",
    numeric: true,
    disablePadding: false,
    sortable: false,
    label: "financeProfile.financeVolumeToValue",
    align: "right",
  },
  {
    id: "ltv",
    numeric: true,
    disablePadding: false,
    sortable: false,
    label: "financeProfile.ltv",
    align: "right",
  },
  {
    id: "avg_interest_rate",
    numeric: true,
    disablePadding: false,
    sortable: false,
    label: "financeProfile.avgInterestRate",
    align: "right",
  },
  {
    id: "avg_repayment",
    numeric: true,
    disablePadding: false,
    sortable: false,
    label: "financeProfile.avgRepayment",
    align: "right",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];

export const handleFinanceFormSubmit = ({
  id,
  setFinanceProfileUpdated,
  setIsFinanceProfileData,
  setFormMessage,
}: FinanceProfileSubmitHandlerProps): ((
  values: FinanceProfile,
  { setStatus, setSubmitting, setValues, setTouched }: FormikValues
) => Promise<Response | void>) => {
  return async (
    values: FinanceProfile,
    { setStatus, setSubmitting, setValues, setTouched }: FormikValues
  ): Promise<Response | void> => {
    const payload = {
      ...values,
      contact_id: id,
      zip_code: `${values.zip_code}`,
      ...replaceStringByParsedNumber({
        finance_volume_from_value: values.finance_volume_from_value,
        finance_volume_to_value: values.finance_volume_to_value,
        ltv: values.ltv,
        avg_repayment: values.avg_repayment,
      }),
    };
    let response;
    values.id !== 0
      ? (response = await editFinanceProfile(payload))
      : (response = await createFinanceProfiles(payload));
    if ([200, 201].includes(response.status)) {
      setStatus({
        success: true,
        errors: {},
      });
      setValues(financeProfileFormInitState);
      setTouched(financeProfileFormInitState);
      setFinanceProfileUpdated(true);
      setIsFinanceProfileData(true);
      return response;
    } else if (response.status === 422) {
      const res = await response.json();
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(res.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setSubmitting(false);
    }
  };
};
