import React, { ReactElement, useCallback, useEffect } from "react";
import { Grid, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";
import { deleteRoles, getRoles } from "../../../../api/roles";
import useIsMounted from "../../../../hooks/useIsMounted";
import { Role as BERole } from "../../../../types/be/role";
import {
  DeleteIcon,
  EditIcon,
  Input,
  Search,
  SearchIconWrapper,
} from "../../../../ui/table/styled";
import Table from "../../../../ui/table/Table";
import useTable from "../../../../ui/table/useTable";
import { handleServerError } from "../../../../utils/http";
import { itemsListHeadCells } from "./utils";
import { Search as SearchIcon } from "react-feather";
import { route } from "../../../../utils/url";
import { useNavigate } from "react-router-dom";
import useAccessControl from "../../../../hooks/useAccessControl";
import { StyledButton } from "../../../../ui/button/styled";
import { PermissionsCreate } from "../../../../constants";
import { NavLink } from "react-router-dom";
/* istanbul ignore next */
const RolesList = (): ReactElement => {
  const navigate = useNavigate();
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleCellClick,
    handleDeleteLastPageData,
    rowToDelete,
    openConfirmToRemoveModal,
  } = useTable<BERole>({ loading: true });
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const fetchRoles = useCallback(async () => {
    setIsLoading(true);
    const res = await getRoles(queryParams);
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const resJson = await res.json();
      setTotal(resJson.meta.total);
      setData(resJson.data);
      setIsLoading(false);
    }
  }, [queryParams, setIsLoading, setTotal, setData, setIsLoading]);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteRoles(rowToDelete.id);
    handleDeleteLastPageData(fetchRoles);
    handleConfirmToRemoveModalClose();
  }, [rowToDelete, deleteRoles, fetchRoles, handleConfirmToRemoveModalClose]);

  useEffect(() => {
    if (isMounted() && queryParams) fetchRoles();
  }, [queryParams]);

  const handleOnEditIconClick = ({ id }: { id: number }): void => {
    navigate(route("settings.roles.permissions", id));
  };

  const { can } = useAccessControl();

  const renderRow = (row: BERole): ReactElement => (
    <>
      <TableCell>{row?.name}</TableCell>
      <TableCell
        align="right"
        onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
          handleCellClick(e)
        }
        style={{ whiteSpace: "nowrap" }}
      >
        {can("role.update") && (
          <EditIcon size={18} onClick={() => handleOnEditIconClick(row)} />
        )}
        {can("role.delete") && (
          <DeleteIcon
            onClick={() => openConfirmToRemoveModal(row)}
            size={18}
            data-testid="delete-icon"
          />
        )}
      </TableCell>
    </>
  );

  const tableToolbar = (
    <Grid container sx={{ pt: 5, mb: 2 }}>
      <Grid item xs>
        {can(PermissionsCreate.SETTINGS_ROLE) && (
          <Grid item>
            <StyledButton
              data-testid={"dataItems-link"}
              component={NavLink}
              to={route(`settings.roles.create`)}
              size="small"
              variant="contained"
              color="success"
            >
              {t("settings.role.createNewRole")}
            </StyledButton>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Input placeholder={t("search")} onChange={handleTableSearch} />
        </Search>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onRowRemove={handleRowRemove}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={tableToolbar}
        noDataIsAvailablePlaceholder={"table.noRolesAreAvailable"}
      />
    </>
  );
};

export default RolesList;
