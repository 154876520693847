import React, { ReactElement } from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import MultiSelect from "../../../../components/multiSelect";
import { camelCase } from "lodash";
import { ProviderInvoiceListToolbarProps } from "../../types";
import { FORM_SELECT_ALL_OPTION_ID } from "../../../../../../constants";
import {
  getFilterByMonthOptions,
  getFilterByYearOptions,
  paidUnpaidFilter,
  providerTableScrollerId,
} from "../../../../utils";
import HZScrollController from "../../../../../../ui/HZScrollController";
/* istanbul ignore next */
const ProviderInvoiceListToolbar = ({
  optionalColumnIds,
  selectedOptionalColumnsIds,
  handleOptionsSelectedChange,
  childFilter,
  setChildFilter,
  statuses,
}: ProviderInvoiceListToolbarProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Grid container mt={1} alignItems={"center"} className="table-toolbar">
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} sm={6} md={2} lg={1.5}>
            <TextField
              label={t("employeeOverview.year")}
              value={childFilter.year}
              name="year"
              onChange={(e) => {
                const year = +e.target.value;
                setChildFilter((prevState) => ({ ...prevState, year }));
              }}
              select
              fullWidth
              variant="outlined"
              size="small"
            >
              <MenuItem
                key={FORM_SELECT_ALL_OPTION_ID}
                value={FORM_SELECT_ALL_OPTION_ID}
              >
                <span>{t("documentRelease.invoice.providerInvoices.all")}</span>
              </MenuItem>
              {getFilterByYearOptions().map(({ value, name }) => (
                <MenuItem key={value} value={value}>
                  <span>{name}</span>
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={1.5}>
            <TextField
              label={t("documentRelease.invoice.providerInvoices.month")}
              value={childFilter.month}
              name="month"
              onChange={(e) => {
                const month = +e.target.value;
                setChildFilter((prevState) => ({ ...prevState, month }));
              }}
              select
              fullWidth
              variant="outlined"
              size="small"
            >
              <MenuItem
                key={FORM_SELECT_ALL_OPTION_ID}
                value={FORM_SELECT_ALL_OPTION_ID}
              >
                <span>{t("documentRelease.invoice.providerInvoices.all")}</span>
              </MenuItem>
              {getFilterByMonthOptions().map(({ value, name }) => (
                <MenuItem key={value} value={value}>
                  <span>{t(`months.${name}`)}</span>
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={1.5}>
            <TextField
              value={childFilter.is_paid}
              onChange={(e) => {
                const is_paid = e.target.value;
                setChildFilter((prevState) => ({ ...prevState, is_paid }));
              }}
              select
              fullWidth
              variant="outlined"
              size="small"
              label={t("documentRelease.invoice.bankTransfer")}
            >
              {paidUnpaidFilter?.map(({ value, name }) => (
                <MenuItem key={name} value={value}>
                  {t(name)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={1.5}>
            <TextField
              value={childFilter.fr_status_id}
              onChange={(e) => {
                const fr_status_id = +e.target.value;
                setChildFilter((prevState) => ({ ...prevState, fr_status_id }));
              }}
              select
              fullWidth
              variant="outlined"
              size="small"
              label={"Status"}
            >
              {statuses?.map(({ id, code }) => (
                <MenuItem key={id} value={id}>
                  {t(
                    `documentRelease.invoice.providerInvoices.${camelCase(
                      code
                    )}`
                  )}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={"auto"}>
            <MultiSelect
              options={optionalColumnIds}
              selected={selectedOptionalColumnsIds}
              handleOptionsSelectedChange={handleOptionsSelectedChange}
            />
          </Grid>
          <Grid item>
            <HZScrollController scrollerId={providerTableScrollerId} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProviderInvoiceListToolbar;
