import React, { ReactElement, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import { ManuallyUploaDialogProps } from "./types";
import { Form, Formik } from "formik";
import FileInputField from "../../../../../../ui/formsFields/fileInput";
import { handleManullyPropertyUpload, uploadDialogInitState } from "./utils";
import * as Yup from "yup";
import useValidation from "../../../../../../hooks/useValidation";
import { FormMessageInItState } from "../../../../../../constants";
import FormAlert from "../../../../../../ui/formAlert/FormAlert";
import Button from "../../../../../../ui/button/Button";
import CloseButton from "../../../../../../ui/dialog/CloseButton";
import { DialogContent } from "../../../../../home/styled";
import useAppDispatch from "../../../../../../hooks/useAppDispatch";
import { StyledDialogTitle } from "../../../../../../ui/dialog/DialogTitle/styled";
const UploadManuallyDialog = ({
  toggleModel,
  visible,
  onSuccess,
}: ManuallyUploaDialogProps): ReactElement => {
  const { t } = useTranslation();
  const [uploadInprogress, setUploadInprogress] = useState(false);
  const { file } = useValidation();
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const dispatchStore = useAppDispatch();
  return (
    <Dialog open={visible} maxWidth="xl">
      <StyledDialogTitle>
        <Typography variant="h6" component="div">
          {t("property.area.importTitle")}
        </Typography>
        <CloseButton
          onClick={() => {
            toggleModel(false);
          }}
        />
      </StyledDialogTitle>
      <DialogContent>
        <div>
          {formMessage.text && (
            <FormAlert
              formMessage={formMessage}
              sx={{ marginBottom: "10px" }}
            />
          )}
          <Formik
            initialValues={uploadDialogInitState}
            enableReinitialize
            onSubmit={handleManullyPropertyUpload({
              setFormMessage,
              onSuccess,
              setUploadInprogress,
              dispatchStore,
            })}
            validationSchema={Yup.object().shape({
              file,
            })}
          >
            {(props): React.ReactElement => (
              <Form noValidate onSubmit={props.handleSubmit}>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item xs={11}>
                      <FileInputField
                        sx={{ marginTop: 2 }}
                        required
                        name="file"
                        directUpload={false}
                        label={t("property.upload")}
                        error={Boolean(
                          props.touched.file &&
                            (props.status?.errors.file_id || props.errors.file)
                        )}
                        helperText={
                          props.touched?.file &&
                          (props.status?.errors.file_id || props.errors.file)
                        }
                        inputProps={{ "data-testid": "upload-file" }}
                        status={props.status}
                        values={props.values}
                        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        errorText={t("property.excelOnly")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    testId="import-data"
                    title={t("property.import")}
                    type="submit"
                    color="success"
                    size="small"
                    sx={{ marginTop: "10px" }}
                    isLoading={uploadInprogress}
                  />
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UploadManuallyDialog;
