import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Contact } from "../../../contacts/edit/types";
import { LabelText } from "../../offers/list/styled";
import { InfoTable, TableHead } from "./styled";
/* istanbul ignore next */
const ProviderInfoTable = ({ contact }: { contact: Contact }): ReactElement => {
  const { t } = useTranslation();
  const { zip_code, city, street, house_number, first_name, last_name } =
    contact;

  const fullAddress = [zip_code, city, street, house_number]
    .filter((el) => el)
    .join(" ");

  const fullName = [first_name, last_name].filter((el) => el).join(" ");

  return (
    <InfoTable>
      <TableBody>
        <TableRow>
          <TableHead scope="row">
            <LabelText>{t("providerInfoTable.name")}</LabelText>
          </TableHead>
          <TableCell align={"left"} scope="row">
            {fullName}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableHead scope="row">
            <LabelText>{t("providerInfoTable.companyName")}</LabelText>
          </TableHead>
          <TableCell align={"left"} scope="row">
            {contact.company_name ||
              (typeof contact?.company !== "string" && contact?.company?.title)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableHead scope="row">
            <LabelText>{t("providerInfoTable.email")}</LabelText>
          </TableHead>
          <TableCell align={"left"} scope="row">
            {contact.email}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableHead scope="row">
            <LabelText>{t("providerInfoTable.address")}</LabelText>
          </TableHead>
          <TableCell align={"left"} scope="row">
            {fullAddress}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableHead scope="row">
            <LabelText>{t("providerInfoTable.businessPhone")}</LabelText>
          </TableHead>
          <TableCell align={"left"} scope="row">
            {contact.business_phone}
          </TableCell>
        </TableRow>
      </TableBody>
    </InfoTable>
  );
};

export default ProviderInfoTable;
