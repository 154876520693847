import { Status } from "../../../../types/be/status";
import { Insurance as BEInsurance } from "../../../../types/be/insurance";
import { Offer as BEOffer } from "../../../../types/be/offer";
import { Invoice as BEInvoice } from "../../../../types/be.interfaces";
import { Contract as BEContract } from "../../../../types/be/contract";

export interface DocumentReleaseHistoryFilterProps {
  relation?: HISTORY;
  relation_id: number | undefined;
  property_id?: string | number;
}

export interface HistoryListProps {
  documentRelease: HISTORY;
  statuses: Status[];
  handleStatusChange: (statusId: number, statusCode: string) => void;
  useOnWidget?: boolean;
  activeStatusId: number | null;
  noDataMessage: string;
}

export enum HISTORY {
  INVOICE = "invoice",
  CONTRACT = "contract",
  OFFER = "offer",
  INSURANCE = "insurance",
}

export interface RelationTypes {
  invoice: BEInvoice | null;
  contract: BEContract | null;
  offer: BEOffer | null;
  insurance: BEInsurance | null;
}

export interface HistoryPopUpProps {
  handleCloseDialog: () => void;
  historyId: string;
  documentRelease: HISTORY;
}
