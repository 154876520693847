import React, { useState, useContext } from "react";
import { RentalContractFormProps } from "../../types";
import { Formik } from "formik";
import { FormMessageInterface } from "../../../../../../types/form";
import { FormMessageInItState } from "../../../../../../constants";
import {
  handleUpdateTenant,
  handleCreateFutureTenant,
  prepareForm,
} from "../../utils";
import * as Yup from "yup";
import useValidation from "../../../../../../hooks/useValidation";
import { AreaEditContext } from "../../AreaEditContext";
import Loader from "../../../../../Loader";
import { futureTenantInitialValue } from "./utils";
import { useParams } from "react-router-dom";
import RentalContractForm from "./Form";

/* istanbul ignore next */
const RentalContract = ({
  submitCallback,
  isFutureTenant,
}: RentalContractFormProps): React.ReactElement => {
  const { id: propertyId, areaId } = useParams();
  const areaEditContext = useContext(AreaEditContext);
  const { contact_id, active_tenant } = useValidation();
  const [isEditInfo, setIsEditInfo] = useState(Boolean(isFutureTenant));
  const [isEditTenant, setIsEditTenant] = useState(Boolean(isFutureTenant));
  const [saveOnlyTenantsButtonClick, setSaveOnlyTenantsButtonClick] =
    useState(false);
  const [isUploadingRentalContract, setIsUploadingRentalContract] =
    useState(false);
  const [formMessage, setFormMessage] =
    React.useState<FormMessageInterface>(FormMessageInItState);

  if (!areaEditContext || !areaEditContext.area) return <Loader />;
  const { area } = areaEditContext;

  return (
    <Formik
      initialValues={
        isFutureTenant
          ? futureTenantInitialValue
          : prepareForm(areaEditContext.area)
      }
      enableReinitialize
      validationSchema={
        isFutureTenant
          ? Yup.object().shape({ contact_id, active_tenant })
          : Yup.object().shape({ active_tenant })
      }
      onSubmit={
        isFutureTenant
          ? handleCreateFutureTenant(
              futureTenantInitialValue,
              setFormMessage,
              submitCallback,
              propertyId,
              areaId
            )
          : handleUpdateTenant({
              initialValues: area,
              setFormMessage,
              onSuccessCallback: submitCallback,
              saveOnlyTenantsButtonClick,
              setSaveOnlyTenantsButtonClick,
              setIsEditInfo,
              setIsEditTenant,
              setIsUploadingRentalContract,
            })
      }
    >
      {(props): React.ReactElement => (
        <RentalContractForm
          formMessage={formMessage}
          saveOnlyTenantsButtonClick={saveOnlyTenantsButtonClick}
          setSaveOnlyTenantsButtonClick={setSaveOnlyTenantsButtonClick}
          isFutureTenant={isFutureTenant}
          isEditInfo={isEditInfo}
          setIsEditInfo={setIsEditInfo}
          isEditTenant={isEditTenant}
          setIsEditTenant={setIsEditTenant}
          isUploadingRentalContract={isUploadingRentalContract}
          setIsUploadingRentalContract={setIsUploadingRentalContract}
          submitCallback={submitCallback}
          setFormMessage={setFormMessage}
          {...props}
        />
      )}
    </Formik>
  );
};

export default RentalContract;
