import styled from "styled-components/macro";
import { Grid, Paper } from "@mui/material";
import { Status } from "../../../users/list/styled";

export const FormWrapper = styled(Paper)`
  padding: ${(props): string => props.theme.spacing(6)};
  ${(props): string => props.theme.breakpoints.up("lg")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 100%;
  }
  ${(props): string => props.theme.breakpoints.up("xl")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 100%;
  }
  ${(props): string => props.theme.breakpoints.down("xs")} {
    padding: ${(props): string => props.theme.spacing(10)};
    max-width: 100%;
  }
`;

export const SearchProfilesTableWrapper = styled(Grid)`
  width: 1400px;
`;

export const StatusWrapper = styled(Status)`
  margin: 0 auto;
`;
