import { FormikValues } from "formik";
import { CreateDebtorProps, FEDebtor } from "./types";
import { HTTP_STATUS_CODES } from "../../../../../../types/server";
import { mapBackendValidationErrors } from "../../../../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../constants";
import { createDebtor } from "../../../../../../api/debtor";
import { TABS } from "../../utils";
import { route } from "../../../../../../utils/url";
import { getModifiedKeys } from "../../../../../../utils/common";

export const debtorInitState: FEDebtor = {
  property_id: "",
  konto: "",
  company_name: "",
  addressee_type: "",
  abbreviation: "",
  address_type: "",
  street: "",
  location: "",
  postal_code: "",
  code_correspondence_address: "",
};

export const prepareCreditorItemBeforeSubmission = (
  reqData: FEDebtor
): FEDebtor => {
  const newObj = { ...reqData };
  return newObj;
};

export const handleCreateDebtor = ({
  snackbar,
  navigate,
  t,
  id,
}: CreateDebtorProps) => {
  return async (
    values: FEDebtor,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    setStatus({
      success: true,
      errors: {},
    });
    const response = await createDebtor(
      getModifiedKeys(
        debtorInitState,
        prepareCreditorItemBeforeSubmission(values)
      )
    );
    const result = await response?.json();
    if (
      [HTTP_STATUS_CODES.CREATED, HTTP_STATUS_CODES.OK].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      navigate(route("properties.invoices.apidatev.tab", id, TABS.DEBTOR));
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(result?.errors),
      });
    } else {
      snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
    }
    setSubmitting(false);
  };
};
