import React, {
  ReactElement,
  SyntheticEvent,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AreaForm from "./tabs/areaForm";
import EmptyAreaForm from "./tabs/emptyAreaForm";
import Loader from "../../../Loader";
import { COMMENTABLE, PermissionsUpdate } from "../../../../constants";
import { simpleTabProps } from "./utils";
import { TABS } from "./types";
import {
  StyledGridTabs,
  StyledGridTabsContent,
  StyledTabContent,
} from "../../objectDataSheet/styled";
import TabPanel from "../../../../ui/tabs/tabPanel";
import { FormikProps } from "formik";
import { route } from "../../../../utils/url";
import { Area } from "../../../../types/be/area";
import TenantForm from "./tabs/TenantForm";
import RequestsList from "../../requests/list/RequestsList";
import Readings from "../../counters/readings/list";
import CountersList from "../../counters/list/CounterList";
import Comments from "../../../../ui/сomments";
import useComments from "../../../../ui/сomments/useComments";
import TenantHistory from "./tabs/history/list";
import { AreaEditContext } from "./AreaEditContext";
import useSnackbar from "../../../../ui/snackbar1/useSnackbar";
import useAccessControl from "../../../../hooks/useAccessControl";
/* istanbul ignore next */
const AreaEdit = (): ReactElement => {
  const areaEditContext = useContext(AreaEditContext);
  const { t } = useTranslation();
  const location = useLocation();
  const { areaId, tab, id: propertyId } = useParams();
  const navigate = useNavigate();
  const { can } = useAccessControl();
  const { Snackbar, snackbar } = useSnackbar();
  const formikRef = useRef<FormikProps<Area>>(null);

  const {
    comments,
    content,
    setContent,
    currentPage,
    totalPages,
    handleCommentSave,
    handleCommentDelete,
    handlePaginationChange,
    deleting,
    loading,
  } = useComments({
    relation: COMMENTABLE.AREA,
    relation_id: areaId!,
  });

  useEffect(() => {
    const isAreaActive =
      areaEditContext &&
      areaEditContext.area &&
      !areaEditContext.area.is_active;
    const isTabNameValid =
      tab &&
      [
        String(TABS.REQUESTS),
        String(TABS.COUNTERS),
        String(TABS.HISTORY),
        String(TABS.TENANT),
      ].includes(tab);

    if (isAreaActive && isTabNameValid) {
      navigate(
        route("properties.areas.edit.tab", propertyId, areaId, TABS.AREA)
      );
    }
  }, [areaEditContext?.area]);

  const onSubmitSuccess = (): void => {
    snackbar.success(t("property.area.edit.success"));
    areaEditContext && areaEditContext.fetchArea && areaEditContext.fetchArea();
  };
  useEffect(() => {
    areaEditContext && areaEditContext.fetchArea && areaEditContext.fetchArea();
  }, [propertyId, areaId]);

  const handleTabChange = (newValue: TABS): void => {
    navigate(route("properties.areas.edit.tab", propertyId, areaId, newValue));
  };

  if (
    !areaEditContext ||
    !areaEditContext.area ||
    areaEditContext.isAreaCategoriesLoading ||
    areaEditContext.isAreaTypesLoading
  )
    return <Loader />;

  const { area, isTenant } = areaEditContext;

  const tabsLabels = (): string[] => {
    if (!area.is_active) return [TABS.AREA, TABS.HISTORY];
    if (isTenant)
      return [
        //TABS.REQUESTS,
        //TABS.COUNTERS
      ];
    const tabs = [
      TABS.AREA,
      TABS.EMPTY_AREA,
      //TABS.REQUESTS,
      //TABS.COUNTERS,
      TABS.HISTORY,
    ];
    area.active_tenant &&
      can(PermissionsUpdate.TENANT) &&
      tabs.push(TABS.TENANT);
    //tabs.push(TABS.TENANT);
    return tabs;
  };

  return (
    <>
      <Grid container>
        <Grid item sm={8}>
          <StyledGridTabs item xs={12} sm={12}>
            <Tabs
              value={tab}
              onChange={(e: SyntheticEvent, value: TABS) =>
                handleTabChange(value)
              }
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              {tabsLabels().map((tab, index) => (
                <Tab
                  value={tab}
                  label={t(`property.area.edit.tabs.${tab}`)}
                  {...simpleTabProps(index)}
                  key={tab}
                />
              ))}
            </Tabs>
          </StyledGridTabs>
          <StyledGridTabsContent item xs={12} sm={12}>
            <StyledTabContent>
              <>
                <TabPanel key={"area"} value={tab!} index={TABS.AREA}>
                  <AreaForm ref={formikRef} submitCallback={onSubmitSuccess} />
                </TabPanel>
                <TabPanel
                  key={"emptyArea"}
                  value={tab!}
                  index={TABS.EMPTY_AREA}
                >
                  <EmptyAreaForm
                    area={areaEditContext.area}
                    fetchArea={areaEditContext?.fetchArea}
                  />
                </TabPanel>
                <TabPanel key={"tenant"} value={tab!} index={TABS.TENANT}>
                  <TenantForm submitCallback={onSubmitSuccess} />
                </TabPanel>
                <TabPanel key="requests" value={tab!} index={TABS.REQUESTS}>
                  <RequestsList />
                </TabPanel>
                <TabPanel key="counters" value={tab!} index={TABS.COUNTERS}>
                  {location?.pathname?.includes("readings") ? (
                    <Readings />
                  ) : (
                    <CountersList />
                  )}
                </TabPanel>
                <TabPanel key="history" value={tab!} index={TABS.HISTORY}>
                  <TenantHistory />
                </TabPanel>
              </>
            </StyledTabContent>
          </StyledGridTabsContent>
        </Grid>
        <Grid item sm={4} sx={{ pt: 12, pl: 5 }}>
          <Comments
            editorContent={content}
            setEditorContent={setContent}
            handleSaveComment={handleCommentSave}
            comments={comments}
            currentPage={currentPage}
            totalPages={totalPages}
            handleDeleteComment={handleCommentDelete}
            handlePaginationChange={handlePaginationChange}
            deleting={deleting}
            loading={loading}
          />
        </Grid>
      </Grid>
      {Snackbar}
    </>
  );
};

export default AreaEdit;
