import moment, { Moment } from "moment";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getEmployees,
  getEmployeeScheduleOptions,
} from "../../api/userSchedules";
import { PAGE_LIMIT, UserPermissions } from "../../constants";
import { EMPLOYEE_OPTION } from "../../constants";
import { useFetch } from "../../hooks/useFetch";
import useIsMounted from "../../hooks/useIsMounted";
import { Employee } from "../../types/be.interfaces";
import { TableCell as EmployeeTableCell } from "../../types/table";
import Snackbar from "../../ui/Snackbar";
import Table from "../../ui/table/Table";
import useTable from "../../ui/table/useTable";
import {
  objectGetParamsToString,
  prepareQueryParams,
} from "../../utils/common";
import { isWeekend, replaceMomentByFormatedString } from "../../utils/date";
import { handleServerError } from "../../utils/http";
import EmployeeOverviewRow from "./employeeOverviewRow";
import EmployeeOverviewToolbar from "./employeeOverviewToolbar";
import HideEmployeeModal from "./hideEmployeeModal";
import LocationModal from "./locationModal/LocationModal";
import NewEntryModal from "./newEntryModal";
import { TableContainer } from "./styled";
import { EmployeeOptionId, EmployeeOverviewMode, WeekAction } from "./types";
import {
  addTwoWeeks,
  itemsListHeadCells,
  permissionId,
  snackbarDict,
  staticParams,
  switchToNextWeek,
  switchToPreviousWeek,
} from "./utils";
import VacationHolidayManagement from "./vacationHolidayManagementModal";
import usePermissions from "../../hooks/usePermissions";
import { State as BEState } from "../../types/be/country";
import { fetchGermanStates } from "../../api/countryStates";
import useEmployeeScheduleOptions from "../../hooks/useEmployeeScheduleOptions";
import Loader from "../Loader";

const EmployeeOverview = (): ReactElement => {
  const [locationListVisible, setLocationListVisible] = useState(false);
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const {
    data: states,
    run: runStates,
    isLoading: isStatesLoading,
  } = useFetch<BEState[]>();

  const {
    data,
    setData,
    total,
    order,
    setOrder,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleTableSearch,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    setTotal,
  } = useTable<Employee>();
  const toggleLocationListModal = (): void => {
    setLocationListVisible(!locationListVisible);
  };

  const startWith = moment().startOf("week");
  const endWith = addTwoWeeks(moment().startOf("week"));

  const [startDate, setStartDate] = useState(startWith);
  const [endDate, setEndDate] = useState(endWith);
  const {
    employeeScheduleOptions,
    isLoading: isOptionsLoading,
    isError: isOptionsError,
    getEmployeeScheduleOptions: getEmployeeScheduleOption,
    run: runOptions,
  } = useEmployeeScheduleOptions();
  const [weekRange, setWeekRange] = useState<Moment[]>([]);
  const [
    isVacationHolidayManagementModalOpen,
    setIsVacationHolidayManagementModalOpen,
  ] = useState(false);
  const [isNewEntryModalOpen, setIsNewEntryModalOpen] = useState(false);
  const [isHideModalOpen, setHideModalOpen] = useState(false);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [mode, setMode] = useState<EmployeeOverviewMode>("idle");

  const { getPermission } = usePermissions();
  const userScheduleAccessPermissionsId = getPermission(
    UserPermissions.USER_SCHEDULE_ACCESS
  )?.id;

  useEffect(() => {
    setData([{}]);
  }, []);

  useEffect(() => {
    runOptions(getEmployeeScheduleOptions(staticParams));
    generateDays(startDate, endDate);

    const params = prepareQueryParams("", {
      country_code: "DE",
      limit: PAGE_LIMIT._20,
      order: "asc",
    });
    runStates(fetchGermanStates(params));
  }, []);

  useEffect(() => {
    if (isMounted() && queryParams && userScheduleAccessPermissionsId) {
      fetchEmployees();
    }
  }, [queryParams, startDate, endDate, userScheduleAccessPermissionsId]);

  const fetchEmployees = useCallback(async () => {
    setIsLoading(true);
    const params = {
      from: replaceMomentByFormatedString(startDate),
      to: replaceMomentByFormatedString(endDate),
    };
    const response = await getEmployees(
      `${queryParams}&${objectGetParamsToString(
        params
      )}&${permissionId}=${userScheduleAccessPermissionsId}`
    );
    isMounted() && handleServerResponse(response);
  }, [
    queryParams,
    setIsLoading,
    setTotal,
    startDate,
    endDate,
    userScheduleAccessPermissionsId,
  ]);

  const handleServerResponse = async (res: Response): Promise<void> => {
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      !orderBy && setOrder(undefined);
      if ((!orderBy && order === undefined) || orderBy) {
        const resJson = await res.json();
        setTotal(resJson.meta.total);
        setData(resJson.data);
        setIsLoading(false);
      }
    }
  };
  /* istanbul ignore next */
  const handleVacationHolidayManagementModalClose = (): void =>
    setIsVacationHolidayManagementModalOpen(false);
  const handleNewEntryModalClose = (): void => setIsNewEntryModalOpen(false);
  /* istanbul ignore next */
  const openVacationHolidayManagementModal = (): void =>
    setIsVacationHolidayManagementModalOpen(true);
  const openNewEntryModal = (): void => setIsNewEntryModalOpen(true);
  /* istanbul ignore next */
  const handleClose = (): void => setIsSnackbarVisible(false);

  const generateDays = (startDate: Moment, endDate: Moment): void => {
    const now = startDate.clone();
    const dates = [];
    while (now.isSameOrBefore(endDate)) {
      dates.push(now.clone());
      now.add(1, "days");
    }
    setWeekRange([...dates]);
  };

  const switchWeeks = (action: string) => () => {
    const auxStart =
      action === WeekAction.NEXT
        ? switchToNextWeek(endDate)
        : switchToPreviousWeek(startDate);
    setDateRange(auxStart);
  };

  const setDateRange = (startDate: Moment): void => {
    const auxStart = startDate.clone();
    const auxEnd = addTwoWeeks(auxStart);
    setStartDate(auxStart.clone());
    setEndDate(auxEnd.clone());
    generateDays(auxStart, auxEnd);
  };
  /* istanbul ignore next */
  const handleSuccessfulUpdateScheduleSubmission = (): void => {
    setMode("update-schedule");
    setIsSnackbarVisible(true);
  };

  const handleSuccessfulNewEntrySubmission = (): void => {
    setMode("new-entry");
    setIsSnackbarVisible(true);
    fetchEmployees();
  };

  const handleSuccessfulNewLocation = (): void => {
    runOptions(getEmployeeScheduleOptions(staticParams));
  };

  const employeeOptionId: EmployeeOptionId = {
    HOME_OFFICE: getEmployeeScheduleOption(EMPLOYEE_OPTION.HOME_OFFICE)?.id,
    VACATION: getEmployeeScheduleOption(EMPLOYEE_OPTION.VACATION)?.id,
    OUT_OF_OFFICE: getEmployeeScheduleOption(EMPLOYEE_OPTION.OUT_OF_OFFICE)?.id,
    FIELD_SERVICE: getEmployeeScheduleOption(EMPLOYEE_OPTION.FIELD_SERVICE)?.id,
    PUBLIC_HOLIDAY: getEmployeeScheduleOption(EMPLOYEE_OPTION.PUBLIC_HOLIDAY)
      ?.id,
  };

  const renderRow = useCallback(
    (row: Employee): ReactElement => (
      <EmployeeOverviewRow
        row={row}
        weekRange={weekRange}
        handleSuccessfulUpdateScheduleSubmission={
          handleSuccessfulUpdateScheduleSubmission
        }
        options={employeeScheduleOptions}
        employeeOptionId={employeeOptionId}
      />
    ),
    [weekRange, employeeScheduleOptions]
  );
  /* istanbul ignore next */
  const handleSuccessVacationHolidayManagementSubmission = (): void => {
    setMode("vacation-holiday");
    setIsVacationHolidayManagementModalOpen(false);
    setIsSnackbarVisible(true);
    fetchEmployees();
  };
  /* istanbul ignore next */
  const handleHideEmployeeModal = (hasChanged?: boolean): void => {
    isHideModalOpen && hasChanged && fetchEmployees();
    setHideModalOpen(!isHideModalOpen);
  };

  const composeItemsListHeadCells = useCallback(
    (weekRange: Moment[]): EmployeeTableCell[] => [
      ...itemsListHeadCells,
      ...weekRange
        .filter((day) => isWeekend(day))
        .map(
          (day): EmployeeTableCell => ({
            id: replaceMomentByFormatedString(day),
            numeric: false,
            disablePadding: false,
            sortable: false,
            label: replaceMomentByFormatedString(day, "DD.MM.YYYY"),
            align: "left",
          })
        ),
    ],
    [weekRange]
  );

  if (!userScheduleAccessPermissionsId) {
    return <Loader />;
  }

  return (
    <>
      {userScheduleAccessPermissionsId && (
        <TableContainer>
          <Table
            data={data}
            total={total}
            currentPage={currentPage}
            order={order}
            orderBy={orderBy}
            error={error}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
            onSortChange={handleSortChange}
            onSelectAllClick={handleSelectAllClick}
            onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
            isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
            listHeadCells={composeItemsListHeadCells(weekRange)}
            renderRow={renderRow}
            tableToolbar={
              <EmployeeOverviewToolbar
                openNewEntryModal={openNewEntryModal}
                openVacationHolidayManagementModal={
                  openVacationHolidayManagementModal
                }
                openHideEmployeeModal={handleHideEmployeeModal}
                switchWeeks={switchWeeks}
                handleTableSearch={handleTableSearch}
                toggleLocationListModal={toggleLocationListModal}
              />
            }
            noDataIsAvailablePlaceholder={
              "table.noEmployeeOverviewsAreAvailable"
            }
          />
        </TableContainer>
      )}

      {userScheduleAccessPermissionsId && (
        <VacationHolidayManagement
          isVacationHolidayManagementModalOpen={
            isVacationHolidayManagementModalOpen
          }
          handleVacationHolidayManagementModalClose={
            handleVacationHolidayManagementModalClose
          }
          handleSuccessVacationHolidayManagementSubmission={
            handleSuccessVacationHolidayManagementSubmission
          }
          isLoading={isStatesLoading}
          states={states}
          employeeOptionId={employeeOptionId}
        />
      )}
      <NewEntryModal
        isNewEntryModalOpen={isNewEntryModalOpen}
        handleNewEntryModalClose={handleNewEntryModalClose}
        options={employeeScheduleOptions}
        isLoading={isOptionsLoading}
        isError={isOptionsError}
        handleSuccessfulNewEntrySubmission={handleSuccessfulNewEntrySubmission}
      />
      <LocationModal
        visible={locationListVisible}
        toggleLocationListModal={toggleLocationListModal}
        handleSuccessfulNewLocation={handleSuccessfulNewLocation}
      />
      {userScheduleAccessPermissionsId && (
        <HideEmployeeModal
          isHideEmployeeModalOpen={isHideModalOpen}
          handleHideEmployeeModalClose={handleHideEmployeeModal}
          userScheduleAccessPermissionsId={userScheduleAccessPermissionsId}
        />
      )}
      <Snackbar
        data-testid={"vacation-holiday-was-successfully-created"}
        message={t(`employeeOverview.${snackbarDict[mode]}`)}
        color="success"
        open={isSnackbarVisible}
        handleClose={handleClose}
      />
    </>
  );
};

export default EmployeeOverview;
