import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useTable from "../../../ui/table/useTable";
import { User } from "../../../types/models";
import { handleServerError } from "../../../utils/http";
import { TableCell } from "@mui/material";
import { formatTimestamp } from "../../../utils/date";
import { AcceptIcon, RejectIcon } from "../../../ui/table/styled";
import useIsMounted from "../../../hooks/useIsMounted";
import Table from "../../../ui/table/Table";
import { itemsListHeadCells } from "../utils";
import {
  getVacationList,
  patchVacationStatus,
} from "../../../api/vacationRequests";
import { VacationRequest } from "../../../types/be/vacationRequests";
import Snackbar from "../../../ui/snackbar1";

const ApprovalProcess = (): ReactElement => {
  const [updatedCell, setUpdatedCell] = useState(false);
  const [updatedCellId, setUpdatedCellId] = useState<number | null>(null);
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const { t } = useTranslation();
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
  } = useTable<User>();

  useEffect(() => {
    if (isMounted()) fetchVacationList();
  }, [queryParams]);

  const handleSnackbarClose = (): void => setIsSnackbarVisible(false);

  const fetchVacationList = useCallback(async () => {
    setIsLoading(true);
    const res = await getVacationList(queryParams);
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const resJson = await res.json();
      setTotal(resJson.meta.total);
      setData(resJson.data);
      setIsLoading(false);
    }
  }, [queryParams, setIsLoading, setTotal, setData, setIsLoading]);

  const handleStatusChangeClick = useCallback(
    async (row: VacationRequest, accept?: boolean): Promise<void> => {
      const res = await patchVacationStatus(row.id, { approve: !!accept });
      if (res.status !== 200) {
        const { errorMessage } = handleServerError(res);
        setError(errorMessage);
      } else {
        setUpdatedCell(true);
        setUpdatedCellId(row.id);
        setUpdatedStatus(
          accept
            ? t("vacationRequests.accepted")
            : t("vacationRequests.rejected")
        );
        setIsSnackbarVisible(true);
      }
    },
    []
  );

  const renderRow = (row: VacationRequest): ReactElement => {
    return (
      <>
        <TableCell>{`${row.user.first_name} ${row.user.last_name}`}</TableCell>
        <TableCell align="center">{formatTimestamp(row.from)}</TableCell>
        <TableCell align="center">{formatTimestamp(row.to)}</TableCell>
        <TableCell align="left">
          {updatedCellId === row.id && updatedCell
            ? (row.vacation_request_status.name = updatedStatus)
            : row.vacation_request_status.name}
        </TableCell>
        <TableCell align="right">{row.user.current_year_vacations}</TableCell>
        <TableCell
          align="right"
          onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
            handleCellClick(e)
          }
          style={{ whiteSpace: "nowrap" }}
        >
          <AcceptIcon
            role={"acceptIconRole"}
            onClick={(): Promise<void> => handleStatusChangeClick(row, true)}
            size={20}
          />
          <RejectIcon
            role={"rejectIconRole"}
            onClick={(): Promise<void> => handleStatusChangeClick(row)}
            size={22}
            data-testid="delete-icon"
          />
        </TableCell>
      </>
    );
  };

  const TableToolbar = <></>;

  const isMounted = useIsMounted();

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        noDataIsAvailablePlaceholder={"table.noVacationRequestsAreAvailable"}
      />
      <Snackbar
        open={isSnackbarVisible}
        title={t("vacationRequests.statusWasUpdated")}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
      />
    </>
  );
};
export default ApprovalProcess;
