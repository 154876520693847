import { FormikValues } from "formik";
import {
  createMaintenance,
  editMaintenance,
} from "../../../../api/maintenance";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../constants";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { HandleMaintenanceCreateProps, MaintenanceForm } from "./types";

export const initialValues: MaintenanceForm = {
  description: "",
  contact_id: "",
  report_id: "",
  inspection: "",
  order_id: "",
  maintenance_type_id: "",
  contract_id: "",
};

export const INSPECTION_MENU_ITEMS = ["3", "6", "12", "24", "36"];

export const RELEASED_CONTRACTS_FR_STATUS_ID = 3;
export const OLD_CONTRACTS_FR_STATUS_ID = 5;
export const FR_STATUS_ID = "fr_status_id[]";

export const handleCreateMaintenanceSubmit = ({
  afterCreateMaintenance,
  snackbar,
  t,
  maintenanceId,
}: HandleMaintenanceCreateProps) => {
  return async (
    data: MaintenanceForm,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    setStatus({
      success: true,
      errors: {},
    });

    const response = maintenanceId
      ? await editMaintenance(maintenanceId, data)
      : await createMaintenance(data);
    const result = await response?.json();
    if (
      [HTTP_STATUS_CODES.CREATED, HTTP_STATUS_CODES.OK].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      setSubmitting(false);
      afterCreateMaintenance();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(result?.errors),
      });
    } else {
      snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
      setSubmitting(false);
    }
  };
};
