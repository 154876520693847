import { FormikValues } from "formik/dist/types";
import { TableCell } from "../../../../types/table";
import {
  headSellSettings,
  mapBackendValidationErrors,
} from "../../../../utils/form";
import {
  SOMETHING_WENT_WRONG_ERROR,
  YEAR_LENGTH,
  ZIP_CODE_LENGTH,
} from "../../../../constants";
import { AdornmentPositionType } from "../types";
import {
  FormFields,
  FormFieldsProps,
  SearchProfileSubmitHandlerProps,
} from "./types";
import {
  PropertyType,
  Ranking,
  SearchProfile as SearchProfileInterface,
  Type,
  Usages,
} from "../../../../types/models";
import {
  createSearchProfiles,
  editSearchProfile,
} from "../../../../api/searchProfile";
import moment from "moment";
import React, { ReactElement } from "react";
import { MenuItem } from "@mui/material";
import { replaceStringByParsedNumber } from "../../../../utils/common";
import { yearFromDate } from "../../../../utils/date";

export const searchProfileFormInitState: SearchProfileInterface = {
  id: 0,
  title: "",
  type_id: "",
  source: "",
  floors: "",
  expansion: false,
  is_holiday_destination: false,
  main_road: false,
  ranking_id: "",
  ranking: null,
  usage_id: "",
  type: null,
  property_type_id: "",
  property_type: null,
  usage: null,
  plot_area_from: "",
  plot_area_to: "",
  sale_area_from: "",
  sale_area_to: "",
  rentable_area_from: "",
  rentable_area_to: "",
  office_area_from: "",
  office_area_to: "",
  practice_area_from: "",
  practice_area_to: "",
  commercial_area_from: "",
  commercial_area_to: "",
  living_area_from: "",
  living_area_to: "",
  other_area_from: "",
  other_area_to: "",
  construction_year: null,
  inhabitants_from: "",
  inhabitants_to: "",
  renovation: null,
  zip_code: "",
  notes: "",
  bnl: "",
};

export const formFields = ({
  types,
  propertyTypes,
  usages,
  rankings,
  status,
}: FormFieldsProps): Array<
  FormFields[] | FormFields | Array<FormFields[] | FormFields>
> => [
  [
    {
      name: "title",
      status,
    },
    {
      name: "renovation",
      inputProps: { type: "string", maxLength: YEAR_LENGTH },
      status,
    },
    {
      name: "construction_year",
      inputProps: { type: "string", maxLength: YEAR_LENGTH },
      status,
    },
    {
      name: "expansion",
      type: "checkbox",
      status,
    },
  ],
  [
    {
      name: "type_id",
      isSelect: true,
      status,
      render: (): ReactElement[] =>
        types!.map((types: Type) => (
          <MenuItem key={types.id} value={types.id}>
            {types.name}
          </MenuItem>
        )),
    },
    {
      name: "zip_code",
      inputProps: { type: "string", maxLength: ZIP_CODE_LENGTH },
      status,
    },
    {
      name: "floors",
      status,
      type: "number"
    },
    {
      name: "is_holiday_destination",
      type: "checkbox",
      status,
    },
  ],
  [
    {
      name: "property_type_id",
      isSelect: true,
      status,
      render: (): ReactElement[] =>
        propertyTypes.map((propertyType: PropertyType) => (
          <MenuItem key={propertyType.id} value={propertyType.id}>
            {propertyType.name}
          </MenuItem>
        )),
    },
    {
      name: "usage_id",
      isSelect: true,
      status,
      render: (): ReactElement[] =>
        usages.map((usage: Usages) => (
          <MenuItem key={usage.id} value={usage.id}>
            {usage.name}
          </MenuItem>
        )),
    },
    {
      name: "ranking_id",
      isSelect: true,
      status,
      render: (): ReactElement[] =>
        rankings.map((ranking: Ranking) => (
          <MenuItem key={ranking.id} value={ranking.id}>
            {ranking.text}
          </MenuItem>
        )),
    },
    {
      name: "main_road",
      type: "checkbox",
      status,
    },
  ],
  [
    {
      name: "source",
      status,
    },
    [
      {
        name: "sale_area_from",
        inputProps: { filter: "formatNum", min: "0" },
        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
      {
        name: "sale_area_to",
        inputProps: { filter: "formatNum", min: "0" },
        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
    ],
    [
      {
        name: "rentable_area_from",
        inputProps: { filter: "formatNum", min: "0" },

        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
      {
        name: "rentable_area_to",
        inputProps: { filter: "formatNum", min: "0" },
        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
    ],
    [
      {
        name: "office_area_from",
        inputProps: { filter: "formatNum", min: "0" },
        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
      {
        name: "office_area_to",
        inputProps: { filter: "formatNum", min: "0" },
        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
    ],
  ],
  [
    {
      name: "bnl",
      inputProps: { type: "date" },
      status,
    },
    [
      {
        name: "practice_area_from",
        inputProps: { filter: "formatNum", min: "0" },
        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
      {
        name: "practice_area_to",
        inputProps: { filter: "formatNum", min: "0" },
        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
    ],
    [
      {
        name: "commercial_area_from",
        inputProps: { filter: "formatNum", min: "0" },
        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
      {
        name: "commercial_area_to",
        inputProps: { filter: "formatNum", min: "0" },
        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
    ],
    [
      {
        name: "living_area_from",
        inputProps: { filter: "formatNum", min: "0" },
        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
      {
        name: "living_area_to",
        inputProps: { filter: "formatNum", min: "0" },
        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
    ],
  ],
  [
    {
      name: "notes",
      status,
    },
    [
      {
        name: "other_area_from",
        inputProps: { filter: "formatNum", min: "0" },
        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
      {
        name: "other_area_to",
        inputProps: { filter: "formatNum", min: "0" },
        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
    ],
    [
      {
        name: "plot_area_from",
        inputProps: { filter: "formatNum", min: "0" },
        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
      {
        name: "plot_area_to",
        inputProps: { filter: "formatNum", min: "0" },
        adornment: {
          name: "m2",
          position: "end" as AdornmentPositionType,
        },
        shrink: true,
        status,
      },
    ],
    [
      {
        name: "inhabitants_from",
        inputProps: { filter: "formatNum", min: "0" },
        shrink: true,
        status,
      },
      {
        name: "inhabitants_to",
        inputProps: { filter: "formatNum", min: "0" },
        shrink: true,
        status,
      },
    ],
  ],
];

export const itemsListHeadCells: TableCell[] = [
  {
    ...headSellSettings("title", "searchProfile"),
  },
  {
    ...headSellSettings("type_id", "searchProfile"),
  },
  {
    ...headSellSettings("property_type_id", "searchProfile"),
  },
  {
    ...headSellSettings("usage_id", "searchProfile"),
  },
  {
    ...headSellSettings("source", "searchProfile"),
  },
  {
    ...headSellSettings("zip_code", "searchProfile"),
  },
  {
    ...headSellSettings("floors", "searchProfile"),
  },
  {
    ...headSellSettings("renovation", "searchProfile"),
  },
  {
    ...headSellSettings("ranking_id", "searchProfile"),
  },
  {
    ...headSellSettings("expansion", "searchProfile"),
  },
  {
    ...headSellSettings("is_holiday_destination", "searchProfile"),
  },
  {
    ...headSellSettings("main_road", "searchProfile"),
  },
  {
    ...headSellSettings("bnl", "searchProfile"),
  },
  {
    ...headSellSettings("plot_area_from", "searchProfile"),
  },
  {
    ...headSellSettings("plot_area_to", "searchProfile"),
  },
  {
    ...headSellSettings("sale_area_from", "searchProfile"),
  },
  {
    ...headSellSettings("sale_area_to", "searchProfile"),
  },
  {
    ...headSellSettings("rentable_area_from", "searchProfile"),
  },
  {
    ...headSellSettings("rentable_area_to", "searchProfile"),
  },
  {
    ...headSellSettings("office_area_from", "searchProfile"),
  },
  {
    ...headSellSettings("office_area_to", "searchProfile"),
  },
  {
    ...headSellSettings("practice_area_from", "searchProfile"),
  },
  {
    ...headSellSettings("practice_area_to", "searchProfile"),
  },
  {
    ...headSellSettings("commercial_area_from", "searchProfile"),
  },
  {
    ...headSellSettings("commercial_area_to", "searchProfile"),
  },
  {
    ...headSellSettings("living_area_from", "searchProfile"),
  },
  {
    ...headSellSettings("living_area_to", "searchProfile"),
  },
  {
    ...headSellSettings("other_area_from", "searchProfile"),
  },
  {
    ...headSellSettings("other_area_to", "searchProfile"),
  },
  {
    ...headSellSettings("construction_year", "searchProfile"),
  },
  {
    ...headSellSettings("inhabitants_from", "searchProfile"),
  },
  {
    ...headSellSettings("inhabitants_to", "searchProfile"),
  },
  {
    ...headSellSettings("notes", "searchProfile"),
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];

export const handleSearchProfileFormSubmit = ({
  setInitialValues,
  setFormMessage,
  id,
  setSearchProfileUpdated,
  setIsSearchProfileData,
}: SearchProfileSubmitHandlerProps): ((
  values: SearchProfileInterface,
  { setStatus, setSubmitting, setValues, setTouched }: FormikValues
) => Promise<Response | void>) => {
  return async (
    values: SearchProfileInterface,
    { setStatus, setSubmitting, setTouched, setValues }: FormikValues
  ): Promise<Response | void> => {
    const payload = {
      ...values,
      contact_id: id,
      construction_year: yearFromDate(String(values.construction_year ?? "")),
      renovation: yearFromDate(String(values.renovation ?? "")),
      bnl: parseInt(moment(values.bnl).format("X")),
      ...replaceStringByParsedNumber({
        sale_area_from: values.sale_area_from,
        sale_area_to: values.sale_area_to,
        rentable_area_from: values.rentable_area_from,
        rentable_area_to: values.rentable_area_to,
        office_area_from: values.office_area_from,
        office_area_to: values.office_area_to,
        practice_area_from: values.practice_area_from,
        practice_area_to: values.practice_area_to,
        commercial_area_from: values.commercial_area_from,
        commercial_area_to: values.commercial_area_to,
        living_area_from: values.living_area_from,
        living_area_to: values.living_area_to,
        other_area_from: values.other_area_from,
        other_area_to: values.other_area_to,
        inhabitants_from: values.inhabitants_from,
        inhabitants_to: values.inhabitants_to,
        plot_area_from: values.plot_area_from,
        plot_area_to: values.plot_area_to,
      }),
    };

    let response;

    values?.id !== 0
      ? ((response = await editSearchProfile(payload)),
        setInitialValues(searchProfileFormInitState))
      : (response = await createSearchProfiles(payload));

    if ([200, 201].includes(response.status)) {
      setStatus({
        success: true,
        errors: {},
      });
      setValues(searchProfileFormInitState);
      setTouched(searchProfileFormInitState);
      setSearchProfileUpdated(true);
      setIsSearchProfileData(true);
      return response;
    } else if (response.status === 422) {
      const res = await response.json();
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(res.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setSubmitting(false);
    }
  };
};
