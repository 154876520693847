export default {
  sizePerM2: "Größe in m2",
  potentialRentalIncomings: "Potenzielle Mieteinnahmen",
  netRentedLivingAreas: "Anzahl nicht vermieteter Wohnflächen",
  averageVacancyPeriod: "Durchschnittliche Leerstandszeit",
  notRentedCommercialAreas: "Nicht vermietete Gewerbeflächen",
  notRented: "Nicht vermietet",
  living: "Wohnen",
  commercial: "Gewerbe",
  m2: "m²",
  areaByType: "Fläche nach Typ",
  areaVacancies: "Leerstandsflächen",
  emptySpace: "Leerstand seit",
  amount: "Potenzielle Mieteinnahmen pro Monat",
  editVacancy: "Leerstandsinformationen",
  potentialRentalIncomePerMonth: "Potenzielle Mieteinnahmen pro Monat",
};
