import { FormikValues } from "formik";
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { FormMessageInterface } from "../../../../types/form";
import { createRequest } from "../../../../api/requests";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { route } from "../../../../utils/url";
import { CreateRequestInitialValues } from "./types";

export const MAX_FILES = 5;

export const initialValues: CreateRequestInitialValues = {
  title: "",
  description: "",
  file: [],
  tenant_id: "",
  property_area_id: "",
};

export const handleSubmit = (
  setFormMessage: Dispatch<SetStateAction<FormMessageInterface>>,
  navigate: NavigateFunction,
  propertyId: string,
  areaId: string,
  userId: string
) => {
  return async (
    values: CreateRequestInitialValues,
    { setStatus }: FormikValues
  ): Promise<void> => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("property_area_id", areaId);
    formData.append("tenant_id", userId);

    values.file.forEach((item, index) => {
      item.file && formData.append(`file[${index}]`, item.file);
    });

    const response = await createRequest(formData);
    const result = await response?.json();

    if (response.status === 201) {
      setStatus({
        success: true,
      });
      setFormMessage(FormMessageInItState);
      navigate(route("properties.areas.edit", propertyId, areaId));
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(result?.errors),
      });
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
    }
  };
};
