import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, Typography } from "@mui/material";
import OfferAutoCompleteField from "../../offers/autocomplete";
import SwitchWrapper from "../../../../ui/formsFields/switch";
import { FormSection } from "../../../profile/tabs/generalInformation/styled";

import {
  CURRENCIES,
  Measurement_Units,
  INVOICE_PERIOD_TYPES,
  FORMAT_NUMBER_DE,
  FINAL_RELEASER_STATUS_ID,
} from "../../../../constants";
import Text from "../../../../ui/formsFields/text";
import { calculateDateDifference, calculatePeriodTotal } from "../utils";
import { getProperties } from "../../../../api/property";
import Dropdown from "../../../../ui/dropdown";
import DatePicker from "../../../../ui/datePicker";
import { getAreas } from "../../../../api/area";
import { useNavigate, useParams } from "react-router-dom";
import { parseNumber, prepareQueryParams } from "../../../../utils/common";
import TextField from "../../../../ui/formsFields/textField";
import { FormikValues } from "formik";
import { PlusIcon } from "../../../../ui/table/styled";
import { ItemWrapper } from "./styled";
import { getDebitAccount } from "../../../../api/sollconto";
import { VAT_INCL_OPTIONS } from "./utils";
import { MinusCircle } from "react-feather";
import Button from "../../../../ui/button/Button";
import AssignContactModal from "./AssignContactModal";
import { getReleasedAndOldContract } from "../../../../api/contracts";
import { DateVInvoicePosition } from "../../../../types/fe/invoice";
import { getCreditors } from "../../../../api/creditor";
import { AutocompleteOption } from "../../../../ui/dropdown/types";
import { route } from "../../../../utils/url";
import { useLocation } from "react-router";

/* istanbul ignore next */
function Form({
  errors,
  status,
  handleBlur,
  handleChange,
  handleSubmit,
  touched,
  values,
  setFieldValue,
  isSubmitting,
  snackbar,
  setValues,
  isEdit,
}: FormikValues): ReactElement {
  const { t } = useTranslation();
  const { id, invoiceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";

  const [isAssignContactModalOpen, setIsAssignContactModalOpen] =
    useState(false);

  const checkDateDifference = useMemo(() => {
    return Number(
      calculateDateDifference({
        from: values?.date,
        to: values?.until,
      })
    );
  }, [values]);

  const handleAddDateVPositionItem = (): void => {
    setFieldValue("datev_invoice_positions", [
      ...values.datev_invoice_positions,
      {
        datev_soll_konto_id: "",
        vat: "",
        amount_netto: "",
        amount_brutto: "",
        booking_text: "",
      },
    ]);
  };

  const handleRemoveDateVPositionItem = (index: number): void => {
    setFieldValue(
      "datev_invoice_positions",
      values.datev_invoice_positions.filter(
        (_: unknown, i: number) => i !== index
      )
    );
  };
  const getContractOptions = (search: string): Promise<Response> => {
    const params = prepareQueryParams(search, {
      property_id: id,
    });
    return getReleasedAndOldContract(params);
  };

  const handleChangeVat = (index: number, value: string): void => {
    setFieldValue(`datev_invoice_positions.${index}.vat`, value);
    const amountNetto = parseNumber(
      values?.datev_invoice_positions?.[index]?.amount_netto
    );
    const amountBrutto = amountNetto * (Number(value) / 100);
    setFieldValue(
      `datev_invoice_positions.${index}.amount_brutto`,
      amountBrutto + amountNetto
    );
  };

  const handleChangeAmountNetto = (index: number, value: string): void => {
    const amountNetto = parseNumber(value);
    const amountBrutto =
      amountNetto *
      (Number(values?.datev_invoice_positions?.[index]?.vat) / 100);
    setFieldValue(
      `datev_invoice_positions.${index}.amount_brutto`,
      amountBrutto + amountNetto
    );
    setFieldValue(`datev_invoice_positions.${index}.amount_netto`, value);
  };

  const grossInvoiceAmount = values?.datev_invoice_positions?.reduce(
    (old: number, item: DateVInvoicePosition): number =>
      old + item?.amount_brutto,
    0
  );
  const totalValue = useMemo(() => {
    return Number(
      calculatePeriodTotal({
        date: values?.date,
        until: values?.repeat_to,
        monthly: values?.monthly,
        amount: grossInvoiceAmount,
      })
    ).toFixed(2);
  }, [values]);

  const handleAfterSuccessAssignContactModal = (): void => {
    setFieldValue("has_creditor_contact", true);
    setIsAssignContactModalOpen(false);
    snackbar.success(
      t("documentRelease.invoice.dateVInvoice.contactAssignedSuccessfully")
    );
  };

  useEffect(() => {
    setFieldValue("total", totalValue);
  }, [totalValue]);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <FormSection>
        <Grid container spacing={6}>
          {!id ? (
            <Grid item sm={6} md={6}>
              <Dropdown
                id="property-autocomplete"
                name="property_id"
                placeholder={t("documentRelease.invoice.searchProperty")}
                getOptions={getProperties}
                onSelectOption={(id?: string) =>
                  setFieldValue("property_id", id)
                }
                optionLabel="object_name"
                size="medium"
                defaultValue={values?.property || null}
                error={Boolean(
                  status?.errors?.property_id || errors?.property_id
                )}
                helperText={errors?.property_id || status?.errors?.property_id}
                enableReInitialization
              />
            </Grid>
          ) : null}
          <Grid item sm={6} md={6}>
            <Dropdown
              id="property-area-autocomplete"
              name="area_id"
              value={values?.area_id || ""}
              label={t("counter.searchArea")}
              placeholder={t("counter.searchArea")}
              getOptions={getAreas}
              onSelectOption={(id?: string) => setFieldValue("area_id", id)}
              size="medium"
              enableReInitialization={isEdit}
              defaultValue={values?.area}
              error={Boolean(status?.errors?.area_id || errors?.area_id)}
              helperText={errors?.area_id || status?.errors?.area_id}
              disabled={!Number(values?.property_id) && !id}
              isAreaSearch={true}
              propertyId={values?.property_id || id}
              isShowAllOption={true}
            />
          </Grid>
          <Grid item sm={6}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs>
                <Dropdown
                  id="creditor-autocomplete"
                  name="datev_creditor_id"
                  value={values?.datev_creditor_id || ""}
                  label={t("documentRelease.invoice.dateVInvoice.creditor")}
                  placeholder={t(
                    "documentRelease.invoice.dateVInvoice.creditor"
                  )}
                  getOptions={getCreditors}
                  onSelectOption={(
                    id?: string,
                    value?: AutocompleteOption | null
                  ) => {
                    setValues({
                      ...values,
                      datev_creditor_id: id,
                      has_creditor_contact: !!value?.contact,
                      contact_id: value?.contact.id ?? "",
                    });
                  }}
                  optionLabel="konto"
                  size="medium"
                  defaultValue={values?.datev_creditor}
                  error={Boolean(
                    errors?.datev_creditor_id ||
                      errors?.has_creditor_contact ||
                      status?.errors?.datev_creditor_id
                  )}
                  helperText={
                    errors?.datev_creditor_id ||
                    errors?.has_creditor_contact ||
                    status?.errors?.datev_creditor_id
                  }
                  otherOptions={["contact"]}
                />
              </Grid>
              {values?.datev_creditor_id &&
              !values?.has_creditor_contact &&
              !values?.datev_creditor?.contact ? (
                <Grid item sm={2} md={2}>
                  <Button
                    title={t("connect")}
                    fullWidth
                    size="large"
                    onClick={() => setIsAssignContactModalOpen(true)}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item sm={6} md={6}>
            <TextField
              label={t("documentRelease.invoice.number")}
              value={values?.invoice_number || ""}
              name="invoice_number"
              size="medium"
              status={status}
              sx={{ minHeight: "auto" }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <DatePicker
              name="date"
              label={t("documentRelease.invoice.date")}
              status={status}
              required
              disableFuture
              touchedValue
              sx={{ minHeight: "auto" }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <DatePicker
              name="due_date"
              label={t("optionalColumnsNames.due_date")}
              status={status}
              sx={{ minHeight: "auto" }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <DatePicker
              name="performance_date"
              label={t(
                "documentRelease.invoice.dateVInvoice.dateOfPerformance"
              )}
              status={status}
              sx={{ minHeight: "auto" }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
              id="currency"
              name="currency_code"
              select
              required
              label={t("documentRelease.invoice.currency")}
              value={values?.currency_code}
              status={status}
              sx={{ minHeight: "auto" }}
            >
              {CURRENCIES?.map((currency: string) => (
                <MenuItem key={`${currency}`} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
              name="iban"
              label={t("documentRelease.invoice.iban")}
              value={values?.iban}
              fullWidth
              status={status}
              sx={{ minHeight: "auto" }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
              name="bic"
              allowNegative
              label={t("documentRelease.invoice.bic")}
              value={values?.bic}
              status={status}
              sx={{ minHeight: "auto" }}
              fullWidth
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
              name="booking_text"
              label={t("documentRelease.invoice.dateVInvoice.bookingText")}
              value={values?.booking_text}
              status={status}
              sx={{ minHeight: "auto" }}
              fullWidth
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <Dropdown
              id="contract-autocomplete"
              name="contract_id"
              value={values?.contract_id}
              placeholder={t("contracts.searchContract")}
              getOptions={getContractOptions}
              onSelectOption={(id?: string) => setFieldValue("contract_id", id)}
              defaultValue={values?.contract}
              enableReInitialization
              optionLabel="name"
              optionValue="id"
              size="medium"
              error={Boolean(
                status?.errors?.contract_id || errors?.contract_id
              )}
              helperText={errors?.contract_id || status?.errors?.contract_id}
            />
          </Grid>
          <ItemWrapper container>
            <Grid item xs={12} paddingY={4}>
              <Typography variant="h4">
                {t("documentRelease.invoice.dateVInvoice.postingItems")}
              </Typography>
            </Grid>
            {values?.datev_invoice_positions?.map(
              (item: any, index: number) => (
                <Grid container key={index} spacing={4} paddingY={4}>
                  <Grid item sm={5.5}>
                    <Dropdown
                      id="soll-konto-autocomplete"
                      name={`datev_invoice_positions.${index}.datev_soll_konto_id`}
                      value={item?.datev_soll_konto_id || ""}
                      label={t(
                        "documentRelease.invoice.dateVInvoice.debitAccount"
                      )}
                      placeholder={t(
                        "documentRelease.invoice.dateVInvoice.debitAccount"
                      )}
                      optionLabel="konto"
                      getOptions={getDebitAccount}
                      onSelectOption={(id?: string) =>
                        setFieldValue(
                          `datev_invoice_positions.${index}.datev_soll_konto_id`,
                          id
                        )
                      }
                      size="medium"
                      defaultValue={item?.datev_soll_konto}
                      error={Boolean(
                        status?.[
                          `datev_invoice_positions.${index}.datev_soll_konto_id`
                        ] ||
                          errors?.datev_invoice_positions?.[index]
                            ?.datev_soll_konto_id
                      )}
                      helperText={
                        errors?.datev_invoice_positions?.[index]
                          ?.datev_soll_konto_id ||
                        status?.[
                          `datev_invoice_positions.${index}.datev_soll_konto_id`
                        ]
                      }
                    />
                  </Grid>
                  <Grid item sm={5.5}>
                    <TextField
                      name={`datev_invoice_positions.${index}.booking_text`}
                      label={t(
                        "documentRelease.invoice.dateVInvoice.bookingText"
                      )}
                      value={item?.booking_text}
                      fullWidth
                      status={status}
                      sx={{ minHeight: "auto" }}
                    />
                  </Grid>
                  <Grid item sm={1} md={1}>
                    {index ? (
                      <MinusCircle
                        color="red"
                        onClick={() => handleRemoveDateVPositionItem(index)}
                      />
                    ) : (
                      <PlusIcon
                        color="green"
                        onClick={handleAddDateVPositionItem}
                      />
                    )}
                  </Grid>
                  <Grid item sm={5.5}>
                    <TextField
                      name={`datev_invoice_positions.${index}.vat`}
                      select
                      required
                      value={item?.vat}
                      label={t("documentRelease.invoice.dateVInvoice.vatIncl")}
                      status={status}
                      onChange={(e) => handleChangeVat(index, e.target.value)}
                      sx={{ minHeight: "auto" }}
                    >
                      {VAT_INCL_OPTIONS?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {`${item} %`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item sm={5.5}>
                    <TextField
                      required
                      name={`datev_invoice_positions.${index}.amount_brutto`}
                      label={t(
                        "documentRelease.invoice.dateVInvoice.amountGross"
                      )}
                      disabled
                      value={item?.amount_brutto}
                      status={status}
                      inputProps={{
                        "data-testid": "amount_brutto",
                        filter: "formatNum",
                        unit: Measurement_Units.EURO,
                      }}
                      sx={{ minHeight: "auto" }}
                    />
                  </Grid>
                  <Grid item sm={5.5}>
                    <TextField
                      required
                      name={`datev_invoice_positions.${index}.amount_netto`}
                      label={t(
                        "documentRelease.invoice.dateVInvoice.netAmount"
                      )}
                      value={item?.amount_netto ?? ""}
                      status={status}
                      onChange={(e) =>
                        handleChangeAmountNetto(index, e.target.value)
                      }
                      inputProps={{
                        "data-testid": "amount_netto",
                        filter: "formatNum",
                        unit: Measurement_Units.EURO,
                      }}
                      sx={{ minHeight: "auto" }}
                    />
                  </Grid>
                </Grid>
              )
            )}
            <Grid container spacing={4} paddingY={4}>
              <Grid item sm={5.5}>
                <TextField
                  required
                  name="invoice_amount_net"
                  label={t(
                    "documentRelease.invoice.dateVInvoice.invoiceAmountNet"
                  )}
                  value={
                    values?.datev_invoice_positions?.reduce(
                      (old: number, item: DateVInvoicePosition): number =>
                        old + parseNumber(item?.amount_netto),
                      0
                    ) ?? ""
                  }
                  status={status}
                  disabled
                  inputProps={{
                    "data-testid": "invoiceAmountNet",
                    filter: "formatNum",
                    unit: Measurement_Units.EURO,
                  }}
                  sx={{ minHeight: "auto" }}
                />
              </Grid>
              <Grid item sm={5.5}>
                <TextField
                  required
                  name="gross_invoice_amount"
                  disabled
                  label={t(
                    "documentRelease.invoice.dateVInvoice.grossInvoiceAmount"
                  )}
                  value={grossInvoiceAmount}
                  status={status}
                  inputProps={{
                    "data-testid": "grossInvoiceAmount",
                    filter: "formatNum",
                    unit: Measurement_Units.EURO,
                  }}
                  sx={{ minHeight: "auto" }}
                />
              </Grid>
            </Grid>
          </ItemWrapper>
          <Grid item md={6} sm={12}>
            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item sm={8} md={6} xl={8}>
                <Text
                  name="file_name"
                  label={t("contracts.fileName")}
                  value={values?.file_name}
                  fullWidth
                  inputProps={{
                    "data-testid": "file_name",
                  }}
                  status={status}
                  disabled={!!values?.dynamic_name}
                />
              </Grid>
              <Grid item sm={4} md={6} xl={4}>
                <SwitchWrapper
                  name="dynamic_name"
                  label={t("documentRelease.invoice.dynamicFileName")}
                  checked={!!values?.dynamic_name}
                  data-testid="dynamic-name-invoice"
                  onChange={(
                    e: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    > & {
                      target: { checked: boolean };
                    }
                  ): void => {
                    const { checked } = e.target;
                    setFieldValue("dynamic_name", checked);
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12}>
                <OfferAutoCompleteField
                  {...{
                    errors,
                    values,
                    touched,
                    status,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    frStatusId: FINAL_RELEASER_STATUS_ID,
                    defaultValue: values?.offer,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
              name="comment"
              label={t("documentRelease.invoice.show.comment")}
              minRows={4}
              multiline
              status={status}
              fullWidth
              inputProps={{ "data-testid": "comment" }}
              onChange={(e) => {
                setFieldValue("comment", e.target.value);
              }}
              sx={{ minHeight: "auto" }}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection>
        <Grid container spacing={6}>
          <Grid item md={4} sm={12}>
            <SwitchWrapper
              name="is_permanent"
              label={t("documentRelease.invoice.isPermanentInvoice")}
              checked={!!values?.is_permanent}
              data-testid="is-permanent-invoice"
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> & {
                  target: { checked: boolean };
                }
              ): void => {
                const { checked } = e.target;
                setFieldValue("is_permanent", checked);
                if (!checked) {
                  setFieldValue(
                    "is_automatic_release_repeating_invoice",
                    false
                  );
                }
              }}
            />
          </Grid>
          <Grid item md={4} sm={12}>
            <SwitchWrapper
              name="is_debited_automatically"
              label={t("documentRelease.invoice.isDebitedAutomatically")}
              checked={!!values?.is_debited_automatically}
              data-testid="is-debited-automatically"
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> & {
                  target: { checked: boolean };
                }
              ): void => {
                const { checked } = e.target;
                setFieldValue("is_debited_automatically", checked);
              }}
            />
          </Grid>
          <Grid item md={4} sm={12}>
            <SwitchWrapper
              name="is_apportionable"
              label={t("documentRelease.invoice.isApportionable")}
              checked={!!values?.is_apportionable}
              data-testid="is-apportionable"
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> & {
                  target: { checked: boolean };
                }
              ): void => {
                const { checked } = e.target;
                setFieldValue("is_apportionable", checked);
              }}
            />
          </Grid>
          <Grid item md={4} sm={12}>
            <SwitchWrapper
              name="is_confidential"
              label={t("documentRelease.invoice.isConfidentialInvoice")}
              checked={!!values?.is_confidential}
              data-testid="is-confidential-invoice"
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> & {
                  target: { checked: boolean };
                }
              ): void => {
                const { checked } = e.target;
                setFieldValue("is_confidential", checked);
              }}
            />
          </Grid>
          <Grid item md={4} sm={12}>
            <SwitchWrapper
              name="is_active"
              label={t("documentRelease.invoice.isActiveInvoice")}
              checked={!!values?.is_active}
              data-testid="is-active-invoice"
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> & {
                  target: { checked: boolean };
                }
              ): void => {
                const { checked } = e.target;
                setFieldValue("is_active", checked);
              }}
            />
          </Grid>
          {values?.is_permanent && (
            <Grid item md={4} sm={12}>
              <SwitchWrapper
                name="is_automatic_release_repeating_invoice"
                label={t(
                  "documentRelease.invoice.isAutomaticReleaseRepeatingInvoice"
                )}
                checked={!!values?.is_automatic_release_repeating_invoice}
                data-testid="is_automatic_release_repeating_invoice"
                onChange={(
                  e: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  > & {
                    target: { checked: boolean };
                  }
                ): void => {
                  const { checked } = e.target;
                  setFieldValue(
                    "is_automatic_release_repeating_invoice",
                    checked
                  );
                }}
              />
            </Grid>
          )}
        </Grid>
      </FormSection>
      <FormSection>
        <Grid container spacing={6}>
          {values?.is_permanent && (
            <>
              <Grid item md={4} sm={12}>
                <DatePicker
                  name="repeat_from"
                  label={t("documentRelease.invoice.repeatFrom")}
                  status={status}
                  minDate={new Date()}
                  required={values?.is_permanent}
                  dataTestId={"untildate-input"}
                  sx={{ minHeight: "auto" }}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <DatePicker
                  name="repeat_to"
                  label={t("documentRelease.invoice.repeatTo")}
                  status={status}
                  minDate={new Date()}
                  required={values?.is_permanent}
                  dataTestId={"untildate-input"}
                  sx={{ minHeight: "auto" }}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <TextField
                  name="repeat_day"
                  required
                  type="number"
                  label={t("documentRelease.invoice.repeatDay")}
                  fullWidth
                  sx={{ minHeight: "auto" }}
                  status={status}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <TextField
                  name="repetition_period_id"
                  select
                  required
                  label={t("documentRelease.invoice.periodType")}
                  status={status}
                  onChange={(e) => {
                    setFieldValue("repetition_period_id", e.target.value);
                  }}
                  sx={{ minHeight: "auto" }}
                >
                  {INVOICE_PERIOD_TYPES?.map(
                    (period: { label: string; value: number }) => (
                      <MenuItem
                        key={`${period.value}`}
                        value={period.value}
                        disabled={checkDateDifference < period.value}
                      >
                        {t(period.label)}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
              <Grid item md={4} sm={12}>
                <TextField
                  name="total"
                  label={t("documentRelease.invoice.total")}
                  disabled
                  value={values?.total || ""}
                  status={status}
                  inputProps={{
                    "data-testid": "total-input",
                    filter: FORMAT_NUMBER_DE,
                    unit: Measurement_Units.EURO,
                  }}
                  sx={{ minHeight: "auto" }}
                />
              </Grid>
            </>
          )}
          {values?.is_apportionable && (
            <Grid item md={6} sm={12}>
              <TextField
                fullWidth
                type="number"
                required={values?.is_apportionable}
                status={status}
                name="apportionable_percent"
                label={t("documentRelease.invoice.apportionablePercent")}
                value={values?.apportionable_percent}
                inputProps={{
                  "data-testid": "apportionable_percent",
                  max: 100,
                  min: 0,
                }}
                sx={{ minHeight: "auto" }}
              />
            </Grid>
          )}
        </Grid>
      </FormSection>
      <Grid container display="flex" justifyContent="space-between">
        <Grid item>
          <Button
            type="button"
            color="primary"
            variant="text"
            disabled={isSubmitting}
            title={t("cancel")}
            size="large"
            onClick={() =>
              doesAnyHistoryEntryExist
                ? navigate(-1)
                : navigate(route("properties.invoices.new", id))
            }
          />
        </Grid>
        <Grid item>
          <Button
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
            size="large"
            title={invoiceId ? t("update") : t("create")}
            color="success"
          />
        </Grid>
      </Grid>
      <AssignContactModal
        isOpen={isAssignContactModalOpen}
        setIsOpen={setIsAssignContactModalOpen}
        id={values?.datev_creditor_id}
        afterSuccess={handleAfterSuccessAssignContactModal}
      />
    </form>
  );
}

export default Form;
