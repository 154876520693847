import React, {
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useContext,
  Fragment,
} from "react";
import { FECosts, BKADialogFormProps } from "./types";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
  Typography,
} from "@mui/material";
import { getYearOption, onFileDrop } from "./utils";
import TextField from "../../../../../../../ui/formsFields/textField";
import { useTranslation } from "react-i18next";
import {
  FORMAT_NUMBER_DE,
  Measurement_Units,
} from "../../../../../../../constants";
import MUIDatePicker from "../../../../../../../ui/datePicker";
import UploadFile from "../../rentedForm/UploadContract";
import Button from "../../../../../../../ui/button/Button";
import { Plus } from "react-feather";
import { useFetch } from "../../../../../../../hooks/useFetch";
import { Status } from "../../../../../../../types/be/status";
import { getBKACostTypes } from "../../../../../../../api/bka";
import { DeleteIcon } from "../../../../../../../ui/table/styled";
import { AreaEditContext } from "../../../AreaEditContext";
import { FormContainer } from "./styled";
import Loader from "../../../../../../Loader";
import { StyledPaper } from "../../../../../../contacts/autocomplete/styled";

const Form = ({
  values,
  setFieldValue,
  handleSubmit,
  handleBlur,
  status,
  isSubmitting,
  isEdit,
  errors,
  touched,
  initialValues,
  onClose,
}: BKADialogFormProps): ReactElement => {
  const { t } = useTranslation();
  const options = getYearOption();
  const { data: costTypes, run: getCostTypes } = useFetch<Status[]>();
  const areaEditContext = useContext(AreaEditContext);

  const getOptionDisabled = (option: number): boolean => {
    const findBKA = areaEditContext?.area?.active_tenant?.bka?.some(
      (b) => Number(b.year) === option && option !== Number(initialValues.year)
    );
    return findBKA!;
  };

  const handleBKAYearChange = (
    _: SyntheticEvent,
    value: number | null
  ): void => {
    setFieldValue("year", value);
  };

  const handleDeleteCostType = (index: number): void => {
    setFieldValue(
      "costs",
      values.costs.filter((_: unknown, i) => i !== index)
    );
  };

  useEffect(() => {
    getCostTypes(getBKACostTypes());
  }, []);

  if (!costTypes) return <Loader />;

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <FormContainer container spacing={5}>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={options}
            value={values?.year ? Number(values?.year) : null}
            getOptionLabel={(year: number): string => String(year)}
            onChange={handleBKAYearChange}
            PaperComponent={StyledPaper}
            renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
              <TextField
                {...params}
                name="year"
                label={t("tenant.BKAYear")}
                placeholder={t("tenant.BKAYear")}
                value={values.year}
                onBlur={handleBlur}
                status={status}
                sx={{ minHeight: "auto" }}
              />
            )}
            getOptionDisabled={getOptionDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <MUIDatePicker
            name="date_bka"
            label={t("tenant.invoiceDate")}
            status={status}
            required
            sx={{ minHeight: "auto" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="amount"
            value={values?.amount}
            label={t("tenant.amountBKA")}
            inputProps={{
              filter: FORMAT_NUMBER_DE,
              unit: Measurement_Units.EURO,
            }}
            required
            status={status}
            sx={{ minHeight: "auto" }}
            placeholder={t("tenant.amountBKA")}
          />
        </Grid>
        <Grid item xs={6}>
          <MUIDatePicker
            name="date"
            label={t("tenant.dateOfPayment")}
            status={status}
            required
            dataTestId={"rent-start"}
            sx={{ minHeight: "auto" }}
          />
        </Grid>
        <Grid item xs={12} data-testid="dropzone-container">
          <UploadFile
            file={values?.file}
            isLoading={false}
            onDrop={onFileDrop({ setFieldValue }, "file")}
            title={t("uploadFile")}
            disabled={isEdit}
          />
          {(errors?.file && touched?.file) || status?.errors?.file ? (
            <Typography variant="subtitle2" color="error">
              {errors?.file || status?.errors?.file}
            </Typography>
          ) : null}
        </Grid>
        {values?.costs.map((cost: FECosts, index) => (
          <Fragment key={index}>
            <Grid item xs={5}>
              <Autocomplete
                fullWidth
                options={costTypes}
                value={cost.type_id}
                getOptionLabel={(option: Status): string => option.name}
                onChange={(_: SyntheticEvent, value: Status | null): void => {
                  setFieldValue(`costs[${index}].type_id`, value);
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                getOptionDisabled={(option: Status): boolean =>
                  values?.costs?.some(
                    (cost) => cost?.type_id?.id === option?.id
                  )
                }
                PaperComponent={StyledPaper}
                renderInput={(
                  params: AutocompleteRenderInputParams
                ): ReactNode => (
                  <TextField
                    {...params}
                    name={`costs[${index}].type_id`}
                    label={t("tenant.costType")}
                    placeholder={t("tenant.costType")}
                    value={cost.type_id}
                    onBlur={handleBlur}
                    status={status}
                    sx={{ minHeight: "auto" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                name={`costs[${index}].amount`}
                value={cost.amount}
                label={t("tenant.amountBKA")}
                placeholder={t("tenant.amountBKA")}
                inputProps={{
                  filter: FORMAT_NUMBER_DE,
                  unit: Measurement_Units.EURO,
                }}
                required
                status={status}
                sx={{ minHeight: "auto" }}
              />
            </Grid>
            <Grid
              item
              xs={2}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <DeleteIcon
                size={20}
                role={"deleteIconRole"}
                onClick={() => handleDeleteCostType(index)}
              />
            </Grid>
          </Fragment>
        ))}
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", mb: 5 }}
        >
          <Button
            variant="outlined"
            color="info"
            size="large"
            startIcon={<Plus />}
            title={t("tenant.openingCost")}
            sx={{ borderRadius: 20 }}
            type="button"
            onClick={() =>
              setFieldValue("costs", [
                ...values.costs,
                { type_id: null, amount: "" },
              ])
            }
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Grid item>
            <Button
              onClick={onClose}
              title={t("cancel")}
              testId={"cancel-button"}
              variant={"text"}
              type="button"
              size="large"
            />
          </Grid>
          <Grid item>
            <Button
              color="success"
              size="large"
              title={t(isEdit ? "update" : "create")}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            />
          </Grid>
        </Grid>
      </FormContainer>
    </form>
  );
};

export default Form;
