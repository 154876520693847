import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { patchContactOnTenant } from "../../../../../../../../../api/propertyTenant";
import { handleServerError } from "../../../../../../../../../utils/http";
import { Box } from "../../../../../../../../sidebar/styled";
import { TableCell } from "../../../../../../../styled";
import { Chip } from "../../../../../../list/components/row/styled";
import { SelectOrCreateContactTableCellProps } from "./types";
import SelectOrCreateContactModal from "./dialog";
import useSnackbar from "../../../../../../../../../ui/snackbar1/useSnackbar";
/*istanbul ignore next*/
export const SelectOrCreateContactTableCell = ({
  tenantHistory,
  refetchData,
}: SelectOrCreateContactTableCellProps): ReactElement => {
  const { t } = useTranslation();
  const { snackbar, Snackbar } = useSnackbar();
  const [isContactModalOpened, setIsContactModalOpened] = useState(false);

  const handleOnDeleteContact = async (): Promise<void> => {
    const response = await patchContactOnTenant(tenantHistory.id, null);
    if (response.status !== 200) {
      const { errorMessage } = handleServerError(response);
      snackbar.error(errorMessage);
    } else {
      refetchData();
      snackbar.success(t("createContact.contactWasSuccessfullyDeleted"));
    }
  };

  const showSelectContactModal = (): void => {
    setIsContactModalOpened(true);
  };

  return (
    <TableCell>
      <Box component="div" mt={1}>
        {tenantHistory.contact ? (
          <Chip
            role={"editContactRole"}
            label={`${tenantHistory.contact?.full_name}`}
            variant="outlined"
            color="primary"
            onClick={(): void => showSelectContactModal()}
            onDelete={(): Promise<void> => handleOnDeleteContact()}
          />
        ) : (
          <Chip
            role={"selectContactRole"}
            label={t("property.tenants.selectContact")}
            variant="outlined"
            onClick={(): void => showSelectContactModal()}
          />
        )}
      </Box>
      <SelectOrCreateContactModal
        setIsContactModalOpened={setIsContactModalOpened}
        tenantHistory={tenantHistory}
        isContactModalOpened={isContactModalOpened}
        refetchData={refetchData}
      />
      {Snackbar}
    </TableCell>
  );
};

export default SelectOrCreateContactTableCell;
