import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { News } from "../../../types/be/news";

export interface NewsState {
  news: News | null;
}

const initialState: NewsState = {
  news: null,
};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setNews: (state: NewsState, action: PayloadAction<News | null>): void => {
      state.news = action.payload;
    },
  },
});

export const { setNews } = newsSlice.actions;

export default newsSlice.reducer;
