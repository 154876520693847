import { FormikValues } from "formik/dist/types";
import { mapBackendValidationErrors } from "../../../utils/form";
import { FormSubmitHandlerProps } from "../../../types/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { createOffer } from "../../../api/offer";
import { OfferForm } from "../../../types/fe.interfaces";
import { NavigateFunction } from "react-router-dom";
import { parseNumber } from "../../../utils/common";
import { getReleaseRoute } from "../utils";

export const createOfferFormInitState: OfferForm = {
  property_id: "",
  file_id: "",
  contact_id: "",
  amount: null,
  topic_id: "",
  picture_id: "",
  area_id: "",
};

export const touchedInitState = {
  file_id: false,
  contact_id: false,
  amount: false,
  topic_id: false,
  picture_id: false,
};

export const handleOfferCreate = (
  id: string,
  topicId: string,
  { setFormMessage }: FormSubmitHandlerProps,
  navigate: NavigateFunction
) => {
  return async (
    data: OfferForm,
    { setStatus, setSubmitting, setTouched }: FormikValues
  ): Promise<void> => {
    const reqData = {
      ...data,
      topic_id: topicId,
      amount: parseNumber(data.amount, true),
    };
    !reqData.picture_id && delete reqData.picture_id;
    const response = await createOffer(reqData);
    const json = await response.json();
    if (response.status === 201) {
      setStatus({
        success: true,
        errors: {},
      });
      setTouched(touchedInitState);
      setFormMessage({ type: undefined, text: "" });
      navigate(getReleaseRoute("offers", Number(id)));
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setSubmitting(false);
    }
  };
};
