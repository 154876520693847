import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
} from "@mui/material";
import { FormikValues } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ContactAutoCompleteField from "../../../../components/contacts/autocomplete";
import FileInputField from "../../../../ui/formsFields/fileInput";
import Text from "../../../../ui/formsFields/text";
import { StyledGrid } from "./styled";
import Button from "../../../../ui/button/Button";
import Autocomplete from "../../../../ui/autocomplete/Autocomplete";
import { prepareQueryParams } from "../../../../utils/common";
import { getReleasedAndOldContract } from "../../../../api/contracts";
import { MaintenanceType } from "../../../../types/be/maintenance";
import { getMaintenanceType } from "../../../../api/maintenance";
import { FileDialogType } from "./types";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
import Loader from "../../../Loader";
import FileTree from "../../components/fileTree";
import { Contract } from "../../../../types/be.interfaces";
import Dropdown from "../../../../ui/dropdown";
import { pick } from "lodash";
import { PlusIcon } from "../../../../ui/table/styled";
import CreateContractModal from "./createContractModal";
import useFileManagement from "../../../../hooks/useFileManagement";
import { INSPECTION_MENU_ITEMS } from "./utils";
import { useFetch } from "../../../../hooks/useFetch";
import { route } from "../../../../utils/url";
import { useLocation } from "react-router";

const Form = ({
  values,
  errors,
  touched,
  status,
  handleBlur,
  setFieldValue,
  handleChange,
  handleSubmit,
  setFormInitState,
  isSubmitting,
  isEdit,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: preloadedMaintenanceTypes, run: runPreloadedMaintenanceTypes } =
    useFetch<MaintenanceType[]>();

  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const [maintenanceType, setMaintenanceType] =
    useState<MaintenanceType | null>(values?.maintenance_type || null);

  const { data, isFoldersLoading, isFolderLoading, handleFolderClick } =
    useFileManagement();

  const [isDialogVisible, setIsDialogVisible] = useState<FileDialogType | null>(
    null
  );

  const [reportFileName, setReportFileName] = useState(
    values?.report?.name || ""
  );
  const [orderFileName, setOrderFileName] = useState(values?.order?.name || "");
  const [contractModalOpen, setContractModalOpen] = useState(false);

  const handleContractModalOpen = (): void => {
    setContractModalOpen(true);
  };

  const handleContractModalClose = (): void => {
    setContractModalOpen(false);
  };

  const handleCloseDialog = (): void => setIsDialogVisible(null);
  const handleOpenDialog = (type: FileDialogType): void =>
    setIsDialogVisible(type);

  const handleFileTreeClick = (file_id: string, file_name: string): void => {
    if (isDialogVisible === FileDialogType.REPORT) {
      setFieldValue("report_id", parseInt(file_id));
      setReportFileName(file_name);
    } else {
      setFieldValue("order_id", parseInt(file_id));
      setOrderFileName(file_name);
    }
    handleCloseDialog();
  };

  const handleMaintenanceTypeInputChange = useCallback(
    async (search: string) => {
      const params = prepareQueryParams("", {
        search,
        property_id: id,
      });
      const response = await getMaintenanceType(params);
      const { data } = await response.json();
      return data;
    },
    [getMaintenanceType]
  );

  const onMaintenanceTypeSelect = useCallback(
    async (event: MaintenanceType): Promise<void> => {
      setFieldValue("maintenance_type_id", event?.id?.toString());
      setMaintenanceType(event);
    },
    [values]
  );

  const getContractOptions = (search: string): Promise<Response> => {
    const params = prepareQueryParams(search, {
      property_id: id,
    });

    return getReleasedAndOldContract(params);
  };

  /* istanbul ignore next */
  const setContractModalValue = (contract: Contract): void => {
    setFieldValue("contract", contract);
    setFieldValue("contract_id", contract?.id);
  };

  useEffect(() => {
    const params = prepareQueryParams("", {
      property_id: id,
    });
    runPreloadedMaintenanceTypes(getMaintenanceType(params));
  }, [id]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container columnSpacing={5} rowSpacing={7}>
        <Grid item sm={12} md={6}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={11}>
              <Dropdown
                id="contract-autocomplete"
                name="contract_id"
                value={values.contract_id || values?.contract?.id || ""}
                placeholder={t("contracts.searchContract")}
                getOptions={getContractOptions}
                onSelectOption={(id?: string) =>
                  setFieldValue("contract_id", id)
                }
                defaultValue={
                  values?.contract
                    ? pick(values?.contract, ["id", "name"])
                    : null
                }
                enableReInitialization
                optionLabel="name"
                optionValue="id"
                size="medium"
                error={Boolean(
                  status?.errors?.contract_id || errors.contract_id
                )}
                helperText={errors?.contract_id || status?.errors?.contract_id}
              />
            </Grid>
            <Grid item xs={1} pt={3}>
              <Tooltip title={t("contracts.createContract")}>
                <PlusIcon
                  role={"dataItems-create-contract"}
                  onClick={handleContractModalOpen}
                  size={18}
                  style={{ cursor: "pointer" }}
                  type="button"
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6}>
          <ContactAutoCompleteField
            {...{
              errors,
              values,
              touched,
              status,
              handleBlur,
              handleChange,
              setFieldValue,
            }}
            required={true}
            placeholder={t("createContact.searchContact")}
            textFieldParams={{ sx: { minHeight: 0, mb: 0 } }}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={11}>
              <FileInputField
                required
                name="report_id"
                label={t("property.maintenance.report")}
                error={Boolean(
                  touched?.report_id &&
                    (status?.errors?.report_id || errors?.report_id)
                )}
                directUpload
                helperText={
                  touched?.report_id &&
                  (status?.errors?.report_id || errors?.report_id)
                }
                foldername="Verträge"
                propertyid={id}
                inputProps={{ "data-testid": "insurance-file" }}
                accept="application/pdf"
                errorText={t("contracts.error.pdfOnly")}
                outerFileName={reportFileName}
                setOuterFileName={setReportFileName}
                setFormInitState={setFormInitState}
              />
            </Grid>
            <StyledGrid item xs={1} pt={3}>
              <IconButton
                color="primary"
                aria-label="uploadPdf"
                component="span"
                size="small"
                onClick={() => handleOpenDialog(FileDialogType.REPORT)}
                data-testid="upload-report"
              >
                <InsertDriveFileIcon />
              </IconButton>
            </StyledGrid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={11}>
              <FileInputField
                required
                name="order_id"
                label={t("property.maintenance.order")}
                error={Boolean(
                  touched?.order_id &&
                    (status?.errors?.order_id || errors?.order_id)
                )}
                directUpload
                helperText={
                  touched?.order_id &&
                  (status?.errors?.order_id || errors?.order_id)
                }
                foldername="Verträge"
                propertyid={id}
                inputProps={{ "data-testid": "insurance-file" }}
                accept="application/pdf"
                errorText={t("contracts.error.pdfOnly")}
                outerFileName={orderFileName}
                setOuterFileName={setOrderFileName}
                setFormInitState={setFormInitState}
              />
            </Grid>
            <StyledGrid item xs={1} pt={3}>
              <IconButton
                color="primary"
                aria-label="uploadPdf"
                component="span"
                size="small"
                onClick={() => handleOpenDialog(FileDialogType.ORDER)}
                data-testid="upload-order"
              >
                <InsertDriveFileIcon />
              </IconButton>
            </StyledGrid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6}>
          <Autocomplete
            id="maintenance-type-autocomplete"
            name="maintenance_type_id"
            handleOnChangeInputText={handleMaintenanceTypeInputChange}
            handleOptionSelect={onMaintenanceTypeSelect}
            placeholder={t("property.maintenance.maintenanceType")}
            value={values.maintenance_type_id || ""}
            autocompleteValue={maintenanceType}
            error={Boolean(
              (touched?.maintenance_type_id || touched?.maintenance_type_id) &&
                (status?.errors?.maintenance_type_id ||
                  errors.maintenance_type_id ||
                  errors?.maintenance_type_id)
            )}
            helperText={
              (touched?.maintenance_type_id || touched?.maintenance_type_id) &&
              (status?.errors?.maintenance_type_id ||
                errors.maintenance_type_id ||
                errors?.maintenance_type_id)
            }
            debounceTime={0}
            sx={{ minHeight: 1, mb: 0 }}
            optionLabel="name"
            optionValue="id"
            preloadOptions={preloadedMaintenanceTypes}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Text
            name="inspection"
            select
            required
            label={t("property.maintenance.inspectionAndMaintenanceInterval")}
            status={status}
          >
            {INSPECTION_MENU_ITEMS?.map((item) => (
              <MenuItem key={item} value={item}>
                {`${item} ${t("insurance.months")}`}
              </MenuItem>
            ))}
          </Text>
        </Grid>
        <Grid item sm={12}>
          <Text
            required
            name="description"
            multiline
            disabled={isSubmitting}
            rows={4}
            label={t("property.maintenance.description")}
            inputProps={{ "data-testid": "description" }}
            error={Boolean(
              touched.description &&
                (status?.errors.description || errors.description)
            )}
            helperText={
              touched.description &&
              (status?.errors.description || errors.description)
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            type="button"
            color="primary"
            variant="text"
            title={t("cancel")}
            size="large"
            disabled={isSubmitting}
            onClick={
              /* istanbul ignore next */ () =>
                doesAnyHistoryEntryExist
                  ? navigate(-1)
                  : id
                  ? navigate(route("properties.maintenance", id))
                  : navigate(route("maintenance"))
            }
          />
          <Button
            type={"submit"}
            isLoading={isSubmitting}
            title={isEdit ? t("update") : t("create")}
            color="success"
            size="large"
            disabled={isSubmitting}
            testId="createBtn"
          />
        </Grid>
      </Grid>
      <Dialog
        maxWidth={"lg"}
        open={!!isDialogVisible}
        onClose={handleCloseDialog}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle
          title="property.selectAnyFileToLink"
          onClose={handleCloseDialog}
        />
        <DialogContent dividers>
          {isFoldersLoading && <Loader />}
          {data && (
            <FileTree
              data={data}
              handleFolderClick={handleFolderClick}
              isLoading={isFolderLoading}
              handleFileClick={handleFileTreeClick}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button title={t("cancel")} size="medium" onClick={handleCloseDialog} />
        </DialogActions>
      </Dialog>
      <CreateContractModal
        open={contractModalOpen}
        onClose={handleContractModalClose}
        setContractModalValue={setContractModalValue}
      />
    </form>
  );
};

export default Form;
