import { TFunction } from "i18next";
import {
  TenantRequestEvent,
  TenantRequestHistory,
} from "../../../types/be/tenantRequestsHistory";
import { TableCell } from "../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "event",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.event",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.description",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequest.createdAt",
  },
];

export const getActionText = (
  row: TenantRequestHistory,
  t: TFunction<"translation", undefined, "translation">
): string | undefined => {
  switch (row.event) {
    case TenantRequestEvent.CREATED:
      return `${row.causer.first_name} ${row.causer.last_name} ${t(
        "tenantRequest.eventData.created"
      )} ${row.properties.attributes?.title}`;
    case TenantRequestEvent.UPDATED:
      if (
        row.properties.old?.status?.id !== row.properties.attributes?.status?.id
      ) {
        return `${row.causer.first_name} ${row.causer.last_name} ${t(
          "tenantRequest.eventData.udpatedStatusOf"
        )}
        )} ${row.properties.attributes?.title} ${t(
          "tenantRequest.eventData.from"
        )}} ${row.properties.old?.status?.name} ${t(
          "tenantRequest.eventData.to"
        )} ${row.properties.attributes?.status?.name}`;
      } else if (
        row.properties.old?.description !==
        row.properties.attributes?.description
      ) {
        return `${row.causer.first_name} ${row.causer.last_name} ${t(
          "tenantRequest.eventData.updatedDescriptionOf"
        )} ${row.properties.attributes?.title} ${t(
          "tenantRequest.eventData.from"
        )} ${row.properties.old?.description} ${t(
          "tenantRequest.eventData.to"
        )} ${row.properties.attributes?.description}`;
      } else if (
        row.properties.old?.area?.id !== row.properties.attributes?.area?.id
      ) {
        return `${row.causer.first_name} ${row.causer.last_name} ${t(
          "tenantRequest.eventData.updatedPropertyAreaOf"
        )} ${row.properties.attributes?.title} ${t(
          "tenantRequest.eventData.from"
        )} ${row.properties.old?.area?.name} ${t(
          "tenantRequest.eventData.to"
        )} ${row.properties.attributes?.area?.name}`;
      } else if (
        row.properties.old?.manager?.id !==
        row.properties.attributes?.manager?.id
      ) {
        return `${row.causer.first_name} ${row.causer.last_name} ${t(
          "tenantRequest.eventData.udpateManagerOf"
        )} ${row.properties.attributes?.title} ${t(
          "tenantRequest.eventData.from"
        )} ${row.properties.old?.manager?.first_name} ${
          row.properties.old?.manager?.last_name
        } ${t("tenantRequest.eventData.to")} ${
          row.properties.attributes?.manager?.first_name
        } ${row.properties.attributes?.manager?.last_name}`;
      } else if (
        row.properties.old?.tenant?.id !== row.properties.attributes?.tenant?.id
      ) {
        return `${row.causer.first_name} ${row.causer.last_name} ${t(
          "tenantRequest.eventData.udpateTenantOf"
        )} ${row.properties.attributes?.title} ${t(
          "tenantRequest.eventData.from"
        )} ${row.properties.old?.tenant?.full_name} ${t(
          "tenantRequest.eventData.to"
        )} ${row.properties.attributes?.tenant?.full_name}`;
      } else if (
        row.properties.old?.title !== row.properties.attributes?.title
      ) {
        return `${row.causer.first_name} ${row.causer.last_name} ${t(
          "tenantRequest.eventData.udpateTitleOf"
        )} ${row.properties.attributes?.title} ${t(
          "tenantRequest.eventData.from"
        )} ${row.properties.old?.title} ${t("tenantRequest.eventData.to")} ${
          row.properties.attributes?.title
        }`;
      } else if (
        row.properties.old?.property?.id !==
        row.properties.attributes?.property?.id
      ) {
        return `${row.causer.first_name} ${row.causer.last_name} ${t(
          "tenantRequest.eventData.udpatePropertyOf"
        )} ${row.properties.attributes?.title} ${t(
          "tenantRequest.eventData.from"
        )} ${row.properties.old?.property?.name} ${t(
          "tenantRequest.eventData.to"
        )} ${row.properties.attributes?.property?.name}`;
      } else if (
        row.properties.old?.files?.length !==
        row.properties.attributes?.files?.length
      ) {
        return `${row.causer.first_name} ${row.causer.last_name} ${t(
          "tenantRequest.eventData.udpateFilesOf"
        )} ${row.properties.attributes?.title}`;
      }
  }
};
