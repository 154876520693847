import styled from "styled-components/macro";
import { Paper } from "@mui/material";
import { teal } from "@mui/material/colors";

export const Container = styled.div`
  display: flex;
`;

export const TaskList = styled(Paper)`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 5px;
  background: ${(props): string => props.theme.tenantRequests.background.bg1};
  border: none;
`;

export const TaskListWrapper = styled.div`
  width: 25%;
`;

export const TaskColumnStyles = styled.div`
  display: flex;
  width: 100%;
`;

export const Title = styled.span`
  color: ${(): string => teal[500]};
  padding: 0 10px 5px 10px;
  border-radius: 5px;
  align-self: flex-start;
  font-weight: 700;
`;
