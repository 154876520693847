import { FormikHelpers } from "formik";
import { ChangeConfigProps, Config } from "./types";
import { updateProperty } from "../../../../../api/property";
import { HTTP_STATUS_CODES } from "../../../../../types/server";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../constants";
import { mapBackendValidationErrors } from "../../../../../utils/form";

export const handleChangeConfig = ({
  snackbar,
  t,
  onSuccess,
}: ChangeConfigProps) => {
  return async (
    values: Config,
    { setStatus, setSubmitting }: FormikHelpers<Config>
  ): Promise<void> => {
    setStatus({
      success: true,
      errors: {},
    });
    const payload = {
      id: String(values?.id),
      konto: values?.konto,
    };
    const response = await updateProperty(payload);
    const result = await response?.json();
    if (
      [HTTP_STATUS_CODES.CREATED, HTTP_STATUS_CODES.OK].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      onSuccess();
      snackbar.success(
        t("documentRelease.invoice.apidatev.companyNumberUpdatedSuccessfully")
      );
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(result?.errors),
      });
    } else {
      snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
    }
    setSubmitting(false);
  };
};
