import styled from "styled-components/macro";
import { Badge, Grid } from "@mui/material";

/* istanbul ignore next */
export const ChatWrapper = styled(Grid)`
  padding: ${(props): string => props.theme.spacing(1)};
  margin-bottom: ${(props): string => props.theme.spacing(3)};
  background : ${(props): string => props.theme.palette.background.active};
  max-width: 80%;
  &.other-chat {
    float:right;
  }
`;

/* istanbul ignore next */
export const ChatBodyWrapper = styled(Grid)`
  padding-left: ${(props): string => props.theme.spacing(2)};
  * {
    margin-top: 0;
    margin-bottom: 0;
    word-break: break-all;
  }
`;

/* istanbul ignore next */
export const ChatLeftBy = styled.span.attrs(() => ({}))`
  font-weight: 700;
  color: ${(props): string => props.theme.comments.textColor};
  cursor: pointer;
  font-size: 10px;
`;

/* istanbul ignore next */
export const ChatLeftAt = styled.span.attrs(() => ({}))`
  font-weight: 500;
  font-size: 10px;
  color: ${(props): string => props.theme.comments.textColor};
  margin-left: ${(props): string => props.theme.spacing(2)};
`;

/* istanbul ignore next */
export const MessageBadgeCounter = styled(Badge)`
  bottom: 80px;
  right: ${(props): string => props.theme.spacing(4)};
  cursor: pointer;
  position: fixed;
`;

/* istanbul ignore next */
export const ChatPopover = styled('div')`
  cursor: pointer;
  padding: 10px;
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  background : ${(props): string => props.theme.palette.background.hover};
  box-shadow: 0px 0px 30px ${(props): string => props.theme.palette.background.default};
  margin-bottom: ${(props): string => props.theme.spacing(4)};
`;