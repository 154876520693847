import React, { ReactElement, useState, useEffect } from "react";
import Button from "../../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, Stack } from "@mui/material";
import { FormikValues } from "formik/dist/types";
import { Form } from "formik";
import Snackbar from "../../../../ui/Snackbar";
import {
  CURRENCIES,
  INPUT_MIN_HEIGHT,
  Measurement_Units,
  PAGE_LIMIT,
} from "../../../../constants";
import Dropdown from "../../../../ui/dropdown";
import TextField from "../../../../ui/formsFields/textField";
import { InboxType } from "../../../../types/be/inbox";
import { INBOX_TYPES } from "../../utils";
import SwitchWrapper from "../../../../ui/formsFields/switch";
import { Box } from "@mui/system";
import { getProperties } from "../../../../api/property";
import {
  generalPropertyOption,
  prepareQueryParams,
} from "../../../../utils/common";
import DatePicker from "../../../../ui/datePicker";
import SelectOrCreateContactsAutocompleteField from "../../../contacts/autocomplete";
import UsersAutocomplete from "../../../users/autocomplete";
import { useFetch } from "../../../../hooks/useFetch";
import {
  ContractCategory,
  Invoice,
  User,
} from "../../../../types/be.interfaces";
import { getUsers } from "../../../../api/users";
import { getContractCategory } from "../../../../api/contractCategories";
import OfferTopicAutocomplete from "../../../properties/offers/topicAutocomplete";
import InsuranceTopicAutocomplete from "../../../properties/insurances/topicAutocomplete";
import { AutocompleteOption } from "../../../../ui/dropdown/types";
import DocumentsAutocomplete from "../../../properties/components/documentsAutocomplete";
import { HISTORY } from "../../../properties/components/history/types";
/* istanbul ignore next */
function InboxAssignForm({
  errors,
  status,
  isSubmitting,
  handleSubmit,
  values,
  setFieldValue,
  inboxTypes,
  inboxData,
  touched,
  handleBlur,
  handleChange,
  getInboxType,
  setSelectedCode,
}: FormikValues): ReactElement {
  const { t } = useTranslation();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const handleClose = (): void => setIsSnackbarVisible(false);
  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);
  const { data: managerUsers, run: runManagerUsers } = useFetch<User[]>();
  const { data: contractCategories, run: runContractCategories } =
    useFetch<ContractCategory[]>();

  useEffect(() => {
    const params = prepareQueryParams("", {
      limit: String(PAGE_LIMIT._10),
    });
    runManagerUsers(getUsers(params));
    runContractCategories(getContractCategory());
  }, []);
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={6} mt={4}>
        <Grid item sm={6} md={6}>
          <TextField
            name="type_id"
            label={t("inbox.dataType")}
            fullWidth
            select
            onChange={(event) => {
              setFieldValue("type_id", event.target.value);
              setFieldValue(
                "type_code",
                getInboxType(event.target.value)?.code
              );
              setSelectedCode(getInboxType(event.target.value)?.code);
            }}
          >
            {inboxTypes.map(
              ({ id, name, code }: InboxType) =>
                code !== INBOX_TYPES.INBOX && (
                  <MenuItem
                    disabled={
                      ![
                        `${INBOX_TYPES.INVOICE}`,
                        `${INBOX_TYPES.CONTRACT}`,
                        `${INBOX_TYPES.OFFER}`,
                        `${INBOX_TYPES.INSURANCE}`,
                        `${INBOX_TYPES.REMINDER}`,
                        `${INBOX_TYPES.ATTACHMENT}`,
                        `${INBOX_TYPES.DATEV_INVOICE}`,
                      ].includes(code)
                    }
                    key={id}
                    value={id}
                  >
                    <span>{name}</span>
                  </MenuItem>
                )
            )}
          </TextField>
        </Grid>
        {values.type_code !== INBOX_TYPES.ATTACHMENT &&
          values.type_code !== INBOX_TYPES.REMINDER && (
            <Grid item xs={6} sx={{ mb: 4 }}>
              <UsersAutocomplete
                status={status}
                placeholder={"transactionManagement.loi.search"}
                label={"employeeOverview.user"}
                user={values.assigned_user ?? null}
                fieldName={"assigned_user_id"}
                optionLabel="full_name"
                sx={null}
                preloadOptions={managerUsers}
              />
            </Grid>
          )}

        <Grid item sm={12} md={12}>
          <Dropdown
            id="property-autocomplete"
            name="property_id"
            placeholder={t("documentRelease.invoice.searchProperty")}
            getOptions={getProperties}
            onSelectOption={(
              id?: string,
              values?: AutocompleteOption | null
            ) => {
              setFieldValue("property_id", id);
              setFieldValue("property", values);
            }}
            optionLabel="object_name"
            size="medium"
            defaultValue={values?.property || null}
            defaultOption={[generalPropertyOption]}
            error={Boolean(status?.errors?.property_id || errors.property_id)}
            helperText={errors?.property_id || status?.errors?.property_id}
            enableReInitialization
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <TextField
            name="file_name"
            label={t("contracts.fileName")}
            value={values.file.name}
            fullWidth
            inputProps={{
              "data-testid": "file_name",
            }}
            sx={{ minHeight: INPUT_MIN_HEIGHT }}
            status={status}
            disabled={!!values.dynamic_name}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <Box sx={{ pt: 2 }}>
            <SwitchWrapper
              name="dynamic_name"
              label={t("documentRelease.invoice.dynamicFileName")}
              checked={!!values.dynamic_name}
              data-testid="dynamic-name-invoice"
            />
          </Box>
        </Grid>
        {values.type_code === INBOX_TYPES.INVOICE && (
          <>
            <Grid item sm={12} lg={6}>
              <TextField
                label={t("documentRelease.invoice.number")}
                value={values.invoice_number || ""}
                name="invoice_number"
                required
                fullWidth
                size="medium"
                inputProps={{ "data-testid": "number" }}
                status={status}
                helperText={status?.errors.number || errors.number}
              />
            </Grid>

            <Grid item md={6} sm={12}>
              <TextField
                id="currency"
                name="currency_code"
                select
                required
                label={t("documentRelease.invoice.currency")}
                value={values.currency_code}
                status={status}
                helperText={
                  status?.errors.currency_code || errors.currency_code
                }
                data-testid="property-type"
                fullWidth
              >
                {CURRENCIES?.map((currency: string) => (
                  <MenuItem key={`${currency}`} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} sm={12}>
              <TextField
                required
                name="invoice_amount"
                allowNegative
                label={t("documentRelease.invoice.invoiceAmount")}
                value={values.invoice_amount ?? ""}
                status={status}
                helperText={status?.errors.amount || errors.amount}
                fullWidth
                inputProps={{
                  "data-testid": "amount",
                  filter: "formatNum",
                  unit: Measurement_Units.EURO,
                }}
              />
            </Grid>
            <Grid item sm={12} lg={6}>
              <DatePicker
                name="date"
                label={t("documentRelease.invoice.date")}
                status={status}
                required
                disableFuture
                dataTestId={"invoicedate-input"}
                touchedValue={true}
              />
            </Grid>
          </>
        )}
        {values.type_code === INBOX_TYPES.CONTRACT &&
          contractCategories?.length && (
            <Grid item lg={6} sm={12}>
              <TextField
                name="contract_category_id"
                select
                required
                label={t("contracts.category")}
                inputProps={{ "data-testid": "contract_category-input" }}
              >
                {contractCategories?.map((category: ContractCategory) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        {values.type_code !== INBOX_TYPES.REMINDER &&
          values.type_code !== INBOX_TYPES.ATTACHMENT && (
            <Grid item xl={6} sm={12} sx={{ position: "relative" }}>
              <SelectOrCreateContactsAutocompleteField
                {...{
                  errors,
                  values: {
                    ...values,
                    contact: inboxData.contact,
                  },
                  touched,
                  status,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  fileId: inboxData.file.id,
                }}
                required={true}
                label={t(
                  values.type_code === INBOX_TYPES.INVOICE
                    ? "documentRelease.invoice.provider"
                    : "createContact.searchContact"
                )}
                placeholder={t(
                  values.type_code === INBOX_TYPES.INVOICE
                    ? "documentRelease.invoice.provider"
                    : "createContact.searchContact"
                )}
              />
            </Grid>
          )}
        {values.type_code === INBOX_TYPES.OFFER && (
          <>
            <Grid item md={6} sm={12}>
              <TextField
                required
                name="amount"
                allowNegative
                label={t("documentRelease.invoice.invoiceAmount")}
                value={values.amount ?? ""}
                error={Boolean(status?.errors.amount || errors.amount)}
                helperText={status?.errors.amount || errors.amount}
                fullWidth
                inputProps={{
                  "data-testid": "amount",
                  filter: "formatNum",
                  unit: Measurement_Units.EURO,
                }}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <OfferTopicAutocomplete
                errors={errors}
                setFieldValue={setFieldValue}
                status={status}
                touched={touched}
                fieldName={"topic_id"}
                defaultValue={values.topic}
              ></OfferTopicAutocomplete>
            </Grid>
          </>
        )}

        {values.type_code === INBOX_TYPES.INSURANCE && (
          <>
            <Grid item md={6} sm={12}>
              <TextField
                required
                name="amount"
                allowNegative
                label={t("documentRelease.invoice.invoiceAmount")}
                value={values.amount ?? ""}
                error={Boolean(status?.errors.amount || errors.amount)}
                helperText={status?.errors.amount || errors.amount}
                fullWidth
                inputProps={{
                  "data-testid": "amount",
                  filter: "formatNum",
                  unit: Measurement_Units.EURO,
                }}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <TextField
                name="cancellation_period"
                select
                label={t("insurance.cancellationPeriod")}
                fullWidth
                status={status}
                required
                data-testid="cancellation-period-select"
              >
                {[1, 3].map((item) => (
                  <MenuItem
                    value={item}
                    key={item}
                    data-testid={`cancellation-period-${item}`}
                  >
                    {item} {t("insurance.month")}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} sm={12}>
              <InsuranceTopicAutocomplete
                errors={errors}
                setFieldValue={setFieldValue}
                status={status}
                touched={touched}
                fieldName={"topic_id"}
                propertyId={values.property_id}
                defaultValue={values.topic}
              ></InsuranceTopicAutocomplete>
            </Grid>
          </>
        )}
        {values.type_code === INBOX_TYPES.REMINDER && (
          <Grid item md={6} sm={12}>
            <DocumentsAutocomplete
              document={values.reminderInvoice}
              onDocumentSelect={(document: Invoice | null): void => {
                setFieldValue("reminderInvoice", document);
                setFieldValue("invoice_id", document?.id);
              }}
              documentType={HISTORY.INVOICE}
              propertyId={values.property_id}
              isGeneral={"isGeneral"}
              size="medium"
            />
          </Grid>
        )}

        <Grid item md={6} sm={12}>
          <TextField
            name="comment"
            label={t("documentRelease.invoice.show.comment")}
            minRows={4}
            multiline
            fullWidth
            inputProps={{ "data-testid": "comment" }}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={"end"}>
        <Grid item xs={1}>
          <Stack justifyContent="flex-end" sx={{ mt: 6 }}>
            <Button
              testId="create-offer"
              title={t(values.forwarded?.id ? "update" : "create")}
              type="submit"
              color="success"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              size="medium"
            />
          </Stack>
        </Grid>
      </Grid>
      <Snackbar
        message={t("offers.offerSaveSuccessfully")}
        color="success"
        open={isSnackbarVisible}
        handleClose={handleClose}
        data-testid="snackbar-offer-success"
      />
    </Form>
  );
}

export default InboxAssignForm;
