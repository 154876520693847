import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const OPTIONAL_COLUMN_IDS = [];

interface InitState {
  optionalColumnIds: string[];
  selectedOptionalColumnsIds: string[];
}

const initialState: InitState = {
  optionalColumnIds: OPTIONAL_COLUMN_IDS,
  selectedOptionalColumnsIds: [],
};
/* istanbul ignore next */
export const offersTableSlice = createSlice({
  name: "offersTable",
  initialState,
  reducers: {
    setHiddenColumnIds: (
      state: InitState,
      action: PayloadAction<string[]>
    ): void => {
      state.selectedOptionalColumnsIds = action.payload;
    },
  },
});

export const { setHiddenColumnIds } = offersTableSlice.actions;

export default offersTableSlice.reducer;
