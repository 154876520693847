import { TableCell } from "../../../../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "konto",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.apidatev.konto",
  },
  {
    id: "company_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.apidatev.addresseeTypeCompany",
  },
  {
    id: "addressee_type",
    numeric: true,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.apidatev.addresseeType",
  },
  {
    id: "abbreviation",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.apidatev.abbreviation",
  },
  {
    id: "address_type",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.apidatev.addressType",
  },
  {
    id: "street",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.apidatev.street",
  },
  {
    id: "postal_code",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.apidatev.postalCode",
    align: "center",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.apidatev.location",
  },
  {
    id: "code_correspondence_address",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.apidatev.codeCorrespondenceAddress",
  },
  {
    id: "address_valid_from",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.apidatev.addressValidFrom",
    align: "center",
  },
  {
    id: "user",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.apidatev.users",
    align: "center",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];

export const STRING_LENGTH = 10;
