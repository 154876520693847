import React, { ReactElement, useState, useEffect, useMemo } from "react";
import { PropertyAutocompleteProps } from "../../invoices/list/types";
import { useTranslation } from "react-i18next";
import { prepareQueryParams } from "../../../../utils/common";
import { getProperties } from "../../../../api/property";
import { Autocomplete, CircularProgress, Grid, TextField } from "@mui/material";
import { PropertyOption } from "./types";
import { PAGE_LIMIT, SEARCH_PARAM_MIN_CHARACTERS } from "../../../../constants";
import debounce from "lodash/debounce";
import { isNodeEnv } from "../../../../utils/env";
import { useFetch } from "../../../../hooks/useFetch";
import {
  AutocompleteListItem,
  AutocompletePropertyAddress,
  AutocompletePropertyName,
} from "./styled";
import { StyledPaper } from "../../../contacts/autocomplete/styled";
/* istanbul ignore next */
const PropertyAutocomplete = ({
  onSelectProperty,
}: PropertyAutocompleteProps): ReactElement => {
  const { t } = useTranslation();
  const [searchOptionsLoading, setSearchOptionsLoading] = useState(false);
  const [options, setOptions] = useState<PropertyOption[]>([]);
  const [open, setOpen] = React.useState(false);

  const {
    data: preloadedData,
    run,
    isLoading: ispreloadedDataLoading,
  } = useFetch<PropertyOption[]>();

  useEffect(() => {
    const params = prepareQueryParams("", {
      limit: String(PAGE_LIMIT._10),
    });
    run(getProperties(params));
  }, []);

  useEffect(() => {
    preloadedData && setOptions(preloadedData);
  }, [preloadedData]);

  useEffect(() => {
    if (!open) resetOptions();
  }, [open]);

  const resetOptions = (): void => {
    setOptions(options);
  };

  const memoOptions = useMemo(() => {
    return options;
  }, [options]);
  /* istanbul ignore next */
  const onSelect = async (event: PropertyOption | null): Promise<void> => {
    const id = !event?.id ? "" : String(event?.id);
    onSelectProperty(id);
  };
  /* istanbul ignore next */
  const onSearchFieldTextChange = React.useCallback(
    debounce(
      async (search: string): Promise<void> => {
        if (!isNodeEnv("test") && search.length < SEARCH_PARAM_MIN_CHARACTERS)
          return resetOptions();
        setSearchOptionsLoading(true);
        try {
          const params = prepareQueryParams("", { search });
          const response = await getProperties(params);
          const { data } = await response.json();
          setOptions([...data]);
          setSearchOptionsLoading(false);
        } catch (e) {
          resetOptions();
        }
      },
      isNodeEnv("test") ? 0 : 300
    ),
    [resetOptions]
  );

  const isLoading = searchOptionsLoading || ispreloadedDataLoading;

  return (
    <Autocomplete
      size="small"
      id="property-autocomplete"
      open={open}
      loading={isLoading}
      options={memoOptions}
      onOpen={(): void => setOpen(true)}
      onClose={(): void => setOpen(false)}
      data-testid="search-user"
      getOptionLabel={(option) => `${option.object_name}`}
      onChange={(_, value) => {
        onSelect(value);
      }}
      isOptionEqualToValue={(option, value) =>
        option?.object_name === value?.object_name
      }
      renderOption={(props, option) => (
        <AutocompleteListItem {...props} key={option.id}>
          <Grid container direction={"column"}>
            <Grid item>
              <AutocompletePropertyName>{`${option?.object_name}`}</AutocompletePropertyName>
            </Grid>
            <Grid item>
              <AutocompletePropertyAddress>{`${option?.location.full_address}`}</AutocompletePropertyAddress>
            </Grid>
          </Grid>
        </AutocompleteListItem>
      )}
      sx={{ minHeight: "0", pr: 2, minWidth: 300 }}
      PaperComponent={StyledPaper}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("property.bank.searchProperty")}
          placeholder={t("property.bank.searchProperty")}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          onChange={(e): Promise<void> | undefined =>
            onSearchFieldTextChange(e.target.value)
          }
        />
      )}
    />
  );
};

export default PropertyAutocomplete;
