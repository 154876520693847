export default {
  transactionManager: "Transaction Manager",
  objectName: "Objekt",
  date: "Datum",
  sent: "Versendet",
  pdf: "PDF",
  transactionManagerEmail: "E.Mail Transactionmanager",
  recipientCompanyName: "Empfänger: Firmenname",
  recipientContactPerson: "Empfänger: Ansprechpartner",
  recipientStreet: "Empfänger: strasse",
  recipientCity: "Empfänger: Ort",
  city: "Ort",
  purchasePrice: "Kaufpreis",
  purchasePriceInWords: "Kaufpreis in Worten",
  firstLineOfEmail: "Erste Zeile der E-Mail",
  htmlMessage: "Nachricht",
  titleOfTransactionManager: "Titel des Transaktionsmanagers",
  selectTransactionManagerForDB: "Select transaction manager for DB",
  titleOfTransactionManagerIsRequired:
    "Titel des Transaktionsmanagers ist erforderlich",
  transactionManagerIsRequired: "Transaktionsmanager ist erforderlich",
  search: "Suche",
  preview: "Vorschau",
  loi: "LOI",
  recipientStreetIsRequired: "Straße des Empfängers ist erforderlich",
  recipientCityIsRequired: "Empfängerstadt ist erforderlich",
  sendLOI: "LOI senden",
  sendLOISuccessfully: "LOI erfolgreich senden",
};
