import React, {
  ReactElement,
  SyntheticEvent,
  useContext,
  useState,
} from "react";
import { TenantFormProps } from "../types";
import { TenantForms, tenantFormTabs } from "../utils";
import RentalContract from "./rentedForm";
// import BKA from "./BKAForm";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../../../../Loader";
import { AreaEditContext } from "../AreaEditContext";
import {
  StyledGridTabs,
  StyledGridTabsContent,
  StyledTabContent,
} from "../../../objectDataSheet/styled";
import { Tabs, Tab } from "@mui/material";
import TabPanel from "../../../../../ui/tabs/tabPanel";
import MobileUser from "./MobileUsers";
import TenantReminder from "./TenantReminder";
import BKAList from "./BKA/list";

/* istanbul ignore next */
const TenantForm = ({ submitCallback }: TenantFormProps): ReactElement => {
  const { t } = useTranslation();
  const areaEditContext = useContext(AreaEditContext);
  const [formType, setFormType] = useState<TenantForms>(TenantForms.Renter);

  const handleTabChange = (value: TenantForms): void => {
    setFormType(value);
  };

  if (!areaEditContext || !areaEditContext.area) return <Loader />;

  const { area } = areaEditContext;

  return (
    <>
      <Grid item sm={12}>
        <StyledGridTabs item xs={12} sm={12}>
          <Tabs
            value={formType}
            onChange={(e: SyntheticEvent, value: TenantForms) =>
              handleTabChange(value)
            }
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            {tenantFormTabs.map(({ label, value }) => (
              <Tab value={value} label={t(label)} key={value} />
            ))}
          </Tabs>
        </StyledGridTabs>
        <StyledGridTabsContent item xs={12} sm={12}>
          <StyledTabContent>
            <>
              <TabPanel
                key={"renter"}
                value={formType}
                index={TenantForms.Renter}
              >
                <RentalContract
                  submitCallback={submitCallback}
                  isFutureTenant={false}
                />
              </TabPanel>
              <TabPanel
                key={"dunningLetters"}
                value={formType}
                index={TenantForms.DunningLetters}
              >
                <TenantReminder />
              </TabPanel>
              <TabPanel key={"bka"} value={formType} index={TenantForms.Bka}>
                <BKAList initialValues={area} submitCallback={submitCallback} />
              </TabPanel>
              <TabPanel
                key={"mobileUser"}
                value={formType}
                index={TenantForms.MobileUser}
              >
                <MobileUser />
              </TabPanel>
            </>
          </StyledTabContent>
        </StyledGridTabsContent>
      </Grid>
    </>
  );
};

export default TenantForm;
