import { getFile } from "../../api/files";
import { MIME_TYPES } from "../../constants";
import { HTTP_STATUS_CODES } from "../../types/server";

/* istanbul ignore file */
export const base64ToUrl =  (base64: string): string => {
    const dataURI = `data:${MIME_TYPES.PDF};base64,${base64}`;
    let byteString = "";
    byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ia], { type: mimeString });
    return URL.createObjectURL(blob);
}

export const getFileFromId = async (id: number): Promise<string> => {
    const responseGetFile = await getFile(id);
    const jsonGetFile = await responseGetFile.json();
    if (responseGetFile.status === HTTP_STATUS_CODES.OK) {
      const {
        data: { file },
      } = jsonGetFile;
      return base64ToUrl(file);
    }
    return "";
}