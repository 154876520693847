/* istanbul ignore file */

import { FormikValues } from "formik/dist/types";
import { updateProperty } from "../../../api/property";
import { mapBackendValidationErrors } from "../../../utils/form";
import { FormSubmitHandlerProps } from "./types";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { Property } from "../../../types/be/property";
import { PropertyUpdate } from "../../../types/fe/property";
import {
  getModifiedKeys,
  parseNumber,
  replaceNullOrUndefinedByEmptyString,
} from "../../../utils/common";
import { moreFields } from "./general/utils";
import { DATE_TYPE } from "../../../constants";
import { replaceMomentByTimestamp } from "../../../utils/date";

export const touchedInitState = {
  property_type_id: false,
  state: false,
  object_name: false,
  street_id: false,
  city: false,
  house_number: false,
  zip_code: false,
  purchase_price: false,
};

export const mapBEPropertyToFEPropertyUpdate = (
  property: Property
): PropertyUpdate => {
  const mappedData = {
    id: String(property.id),
    property_type_id: String(property.property_type.id),
    company_id: String(property.company_id || property.company?.id || ""),
    company_user_id: String(property.company_user_id || ""),
    caretaker_id: property.caretaker_id || property.caretaker?.id,
    contract_id: property.contract_id ? Number(property.contract_id) : null,
    supervisor_id: property.supervisor_id || property.supervisor?.id,
    expenses: property.expenses ?? "",
    vat: property.vat ?? "",
    architect: property.architect ?? "",
    phone_number: property.phone_number ?? "",
    tax_number: String(property.tax_number) ?? "",
    contract_start: replaceMomentByTimestamp(property.contract_start),
    contract_cancelled: replaceMomentByTimestamp(property.contract_cancelled),
    contract_end: replaceMomentByTimestamp(property.contract_end),
    potencial_per_year: String(property.potencial_per_year) ?? "",
    net_rent_per_year: String(property.net_rent_per_year) ?? "",
    empty_commercial_area_in_m2: property?.empty_commercial_area_in_m2,
    property_management_cost:
      String(parseNumber(property.property_management_cost)) ?? "",
    object_name: property.object_name ?? "",
    portfolio: property.portfolio ?? "",
    purchase_price: parseNumber(property.purchase_price),
    total_area: String(property.total_area) ?? "",
    rented_area: property.rented_area,
    notes: property.notes ?? "",
    wault: property.wault ?? "",
    asset_managers: property.asset_managers || [],
    location: {
      place_id: String(property.location.place_id),
      lat: property.location.lat,
      lng: property.location.lng,
      zip_code: String(property.location.lng),
      street: String(property.location.lng),
      city: String(property.location.lng),
      house_number: String(property.location.lng),
      country_code: String(property.location.lng),
      state_code: String(property.location.lng),
    },
    images: property.images,
    videos: property.videos,
    living_rented_area_percentage: property.living_rented_area_percentage,
    commercial_rented_area_percentage:
      property.commercial_rented_area_percentage,
    living_area_percentage: property.living_area_percentage,
    commercial_area_percentage: property.commercial_area_percentage,
    registration_number: property.registration_number,
    tax_id: property.tax_id,
    outgoing_address: property.outgoing_address,
    managing_director: property.managing_director,
    logo: property.logo,
    email: property.email,
  };

  moreFields.forEach((grid) => {
    grid.forEach((field) => {
      const fieldKey = <keyof typeof mappedData>field.name;
      if (property[fieldKey]) {
        if (field.unit === DATE_TYPE) {
          mappedData[fieldKey] = <never>new Date(`${property[fieldKey]}`);
        } else {
          mappedData[fieldKey] = <never>parseNumber(property[fieldKey]);
        }
      }
    });
  });
  replaceNullOrUndefinedByEmptyString(mappedData);
  return mappedData;
};

export const handleUpdatePropertyFormSubmit = ({
  setFormMessage,
  initialValues,
}: FormSubmitHandlerProps) => {
  return async (
    values: PropertyUpdate,
    { setStatus, setSubmitting, setTouched, setErrors }: FormikValues
  ): Promise<void> => {
    const payload = getModifiedKeys(initialValues!, values, [
      "id",
      "location",
      "purchase_price",
    ]);
    moreFields.forEach((grid) => {
      grid.forEach((field) => {
        const fieldKey = <keyof PropertyUpdate>field.name;
        if (payload[fieldKey]) {
          if (field.unit === DATE_TYPE) {
            payload[fieldKey] = <never>(
              new Date(`${payload[fieldKey]}`).getFullYear()
            );
          } else {
            payload[fieldKey] = <never>parseNumber(payload[fieldKey]);
          }
        }
      });
    });

    const response = await updateProperty({
      ...payload,
      purchase_price: parseNumber(payload.purchase_price),
    });
    const json = await response.json();
    if (response.status === HTTP_STATUS_CODES.OK) {
      setStatus({
        success: true,
        errors: {},
      });
      setTouched(touchedInitState);
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setErrors(mapBackendValidationErrors(json.errors));
      setSubmitting(false);
    } else {
      setFormMessage({ type: "error", text: "errorSomethingWentWrong" });
    }
  };
};
