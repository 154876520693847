import { TableCell } from "../../../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "account",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.sollkonto.account",
  },
  {
    id: "account_caption",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.sollkonto.accountCaption",
    align: "left",
  },
  {
    id: "language_id",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.sollkonto.languageId",
    align: "left",
  },
  {
    id: "account_label_long",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.sollkonto.accountLabelLong",
    align: "left",
  },
  {
    id: "user",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.sollkonto.user",
    align: "left",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.sollkonto.date",
    align: "center",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
    align: "right",
  },
];
