import { Box, Grid, TableCell, Tooltip } from "@mui/material";
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { DeleteIcon, EditIcon, SendIcon } from "../../../ui/table/styled";
import moment from "moment";
import Table from "../../../ui/table/Table";
import useTable from "../../../ui/table/useTable";
import { FileManagementFolderContainerContext } from "../contexts/FileManagementFolderContainerContext";
import {
  FileDownloadIcon,
  FolderIconStyled,
  ListViewItem,
  ListViewItemName,
  StyledButton,
  UploadFileIconStyled,
} from "../styled";
import {
  disableDelete,
  disableRenameAndMove,
  displayItemSize,
  isFolder,
  itemsListHeadCells,
  tablePaperSx,
} from "./utils";
import { ArrowLeft } from "react-feather";
import useAccessControl from "../../../hooks/useAccessControl";
import { FileManagementListViewItem, ListViewProps } from "../types";
import { useTranslation } from "react-i18next";
import { getSortedAndOrderedFileTreeItems } from "../utils";
import { FileManagementContext } from ".././contexts/FileManagementContext";
import { FileTreeFile } from "../../../types/be.interfaces";
import { useParams } from "react-router-dom";

/* istanbul ignore next */
const ListView = ({
  handleFileDoubleClick,
  handleOpenDialog,
}: ListViewProps): ReactElement => {
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    error,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
  } = useTable<FileManagementListViewItem>({ limit: 25 });
  const { id } = useParams();
  const {
    memoedFolder: folder,
    setActionItemId,
    setActionItemType,
    setActionItemUrl,
    handleFolderClick,
    handleDoubleClick,
    handleContextMenuDeleteClick,
    handleContextMenuRenameClick,
    handleContextMenuMoveToClick,
    handleContextMenuCreateFolderClick,
    handleContextMenuDownloadClick,
    handleContextMenuUploadFileClick,
  } = useContext(FileManagementContext);

  const { dispatch } = useContext(FileManagementFolderContainerContext);
  const { can } = useAccessControl();
  const { t } = useTranslation();

  useEffect(() => {
    if (!folder) return;
    const { children, files } = folder;

    const data = [
      ...getSortedAndOrderedFileTreeItems(children, files, orderBy, order),
    ];
    setData(data);
    setTotal(data.length);
  }, [folder, order, orderBy]);

  const renderRow = useCallback(
    (row: FileManagementListViewItem): ReactElement => {
      const itemIsFolder = isFolder(row);
      const deleteIsDisabled = disableDelete(row, can);
      const renameAndMoveDisabled = disableRenameAndMove(row, can);
      const itemSize = displayItemSize(row);

      return (
        <>
          <TableCell align="left">
            <Box sx={{ width: "250px" }}>
              {itemIsFolder ? (
                <ListViewItem
                  onClick={handleDoubleClick(row.id.toString(), row.name)}
                >
                  <FolderIconStyled fontSize="medium" />
                  <ListViewItemName>{row.name}</ListViewItemName>
                </ListViewItem>
              ) : (
                <ListViewItem
                  onClick={handleFileDoubleClick({
                    file: row as FileTreeFile,
                    dispatch,
                    handleOpenDialog,
                  })}
                >
                  <UploadFileIconStyled fontSize="medium" />
                  <ListViewItemName>{row.name}</ListViewItemName>
                </ListViewItem>
              )}
            </Box>
          </TableCell>
          <TableCell align="left">{itemSize}</TableCell>
          <TableCell align="left">
            {itemIsFolder ? t("fileManagement.list.folder") : row?.extension}
          </TableCell>
          <TableCell align="left">
            {moment.unix(row.updated_at!).format("DD.MM.yyyy h:mm:ss")}
          </TableCell>
          <TableCell
            align="right"
            padding="none"
            style={{ whiteSpace: "nowrap" }}
            onMouseEnter={() => {
              setActionItemId(row.id.toString());
              setActionItemType(itemIsFolder ? "folder" : "file");
              !itemIsFolder && setActionItemUrl(row.url!);
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip title={t("rename")} placement="top">
                <EditIcon
                  className={renameAndMoveDisabled ? "disabled" : ""}
                  onClick={
                    !renameAndMoveDisabled
                      ? handleContextMenuRenameClick
                      : undefined
                  }
                  size={18}
                />
              </Tooltip>
              <Tooltip title={t("moveTo")} placement="top">
                <SendIcon
                  className={renameAndMoveDisabled ? "disabled" : ""}
                  role={"sendIconRole"}
                  onClick={
                    !renameAndMoveDisabled
                      ? handleContextMenuMoveToClick
                      : undefined
                  }
                  size={18}
                />
              </Tooltip>
              <Tooltip title={t("download")} placement="top">
                <FileDownloadIcon onClick={handleContextMenuDownloadClick} />
              </Tooltip>
              <Tooltip title={t("delete")} placement="top">
                <DeleteIcon
                  className={deleteIsDisabled ? "disabled" : ""}
                  onClick={
                    !deleteIsDisabled ? handleContextMenuDeleteClick : undefined
                  }
                  size={20}
                />
              </Tooltip>
            </Box>
          </TableCell>
        </>
      );
    },
    [handleContextMenuDownloadClick]
  );

  const tableToolbar = (
    <Grid container pt={2} pb={2}>
      <Grid item xs={12}>
        <Grid container display="flex" alignItems="center" spacing={2}>
          <Grid item>
            <StyledButton
              type="button"
              size="small"
              variant="outlined"
              disabled={!folder?.parent_id && (!id ? !folder?.id : true)}
              onClick={() => {
                handleFolderClick(folder?.parent_id || String(folder?.id), "");
              }}
            >
              <ArrowLeft />
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              type="button"
              size="small"
              variant="outlined"
              onClick={handleContextMenuCreateFolderClick}
            >
              <Box>{t("fileManagement.list.newFolder")}</Box>
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              color="success"
              size="small"
              variant="outlined"
              onClick={handleContextMenuUploadFileClick}
            >
              <Box>{t("uploadFile")}</Box>
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const dataPaginated = useMemo(
    () =>
      data &&
      data.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage),
    [data, currentPage, rowsPerPage]
  );

  return (
    <Table
      data={dataPaginated}
      total={total}
      currentPage={currentPage}
      order={order}
      orderBy={orderBy}
      error={error}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={handleChangePage}
      onSortChange={handleSortChange}
      onSelectAllClick={handleSelectAllClick}
      onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
      rowsPerPage={rowsPerPage}
      isLoading={isLoading}
      isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
      listHeadCells={itemsListHeadCells}
      renderRow={renderRow}
      tableToolbar={tableToolbar}
      noDataIsAvailablePlaceholder={"table.noActivitiesAreAvailable"}
      paperSx={tablePaperSx}
    />
  );
};

export default ListView;
