import { Grid, Tooltip } from "@mui/material";
import { FormikValues } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getInsuranceTopicsSearch } from "../../../../api/insuranceTopics";
import { InsuranceTopic } from "../../../../types/be/insurance";
import Dropdown from "../../../../ui/dropdown";
import { PlusIcon } from "../../../../ui/table/styled";
import { formatTimestamp } from "../../../../utils/date";
import CreateTopic from "../list/topicList/topicListToolbar/topicCreate";
import { NEW_STATUS_CODE } from "../list/topicList/utils";
/* istanbul ignore next */
const InsuranceTopicAutocomplete = ({
  setFieldValue,
  status,
  touched,
  errors,
  fieldName,
  defaultValue,
  size,
  propertyId,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState<InsuranceTopic | null>(
    null
  );

  useEffect(() => {
    defaultValue && setSelectedValue(defaultValue);
  }, [defaultValue]);

  const afterCreateTopic = (topic?: InsuranceTopic): void => {
    topic && setSelectedValue(topic);
    topic && setFieldValue(fieldName, topic.id);
    setOpenCreateDialog(false);
  };

  const renderOptionLabel = (option: unknown): string => {
    const renderOption = option as InsuranceTopic;
    return `${renderOption.property?.object_name ?? ""} ${formatTimestamp(
      renderOption.from
    )} - ${formatTimestamp(renderOption.to)}`;
  };

  return (
    <>
      <Grid container alignItems={"center"}>
        <Grid item xs={10}>
          <Dropdown
            id="property-autocomplete"
            name={fieldName}
            label={t("insurance.selectTopic")}
            placeholder={t("insurance.selectTopic")}
            getOptions={getInsuranceTopicsSearch}
            onSelectOption={(id?: string) => setFieldValue(fieldName, id)}
            optionLabel="name"
            renderOptionLabel={renderOptionLabel}
            optionValue="id"
            propertyId={propertyId}
            statusCode={NEW_STATUS_CODE}
            isAreaSearch={true}
            size={size}
            otherOptions={["to", "from", "property"]}
            enableReInitialization={true}
            helperText={
              status?.errors[fieldName] ||
              (touched[fieldName] && errors[fieldName])
            }
            error={Boolean(
              status?.errors[fieldName] ||
                (touched[fieldName] && errors[fieldName])
            )}
            defaultValue={selectedValue}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip title={"Create contact"}>
            <PlusIcon
              role={"dataItems-create-contact"}
              onClick={() => {
                setOpenCreateDialog(true);
              }}
              size={18}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
        </Grid>
      </Grid>
      {openCreateDialog && (
        <CreateTopic
          handleDialog={() => setOpenCreateDialog(false)}
          afterCreateTopic={afterCreateTopic}
        />
      )}
    </>
  );
};

export default InsuranceTopicAutocomplete;
