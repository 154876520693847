import { TableCell } from "../../../../../../../types/table";

/* istanbul ignore next */
export const itemsListHeadCells: TableCell[] = [
  {
    id: "reminder_file_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "offers.fileName",
  },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequests.userName",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "createdAt",
    align: "left",
  },
  {
    id: "reminder_date",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "reminder.reminderSentDate",
  },
  {
    id: "reminder_complete_switch",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "reminder.reminderComplete",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
    align: "left",
  },
];
