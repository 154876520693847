import React, { ReactElement, useState } from "react";
import { FormikValues } from "formik";
import { Grid } from "@mui/material";
import TextField from "../../../../ui/formsFields/text";
import { AcceptIcon, RejectIcon } from "../../../../ui/table/styled";

const TotalVacationTableCellForm = ({
  handleSubmit,
  status,
  values,
  submitForm,
  setFieldValue,
  userVacation,
}: FormikValues): ReactElement => {
  const [totalWasChanged, setTotalWasChanged] = useState(false);

  const handleTotalChange = (value: string): void => {
    setFieldValue("total", value);
    setTotalWasChanged(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3} columns={12}>
        <Grid item xs={3} sm={3} mr={20}>
          <TextField
            required
            name="total"
            variant="standard"
            value={values.total}
            onChange={(e) => handleTotalChange(e.target.value)}
            inputProps={{ "data-testid": "total-input" }}
            status={status}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <>
                  <AcceptIcon
                    role={"submitIconRole"}
                    size={40}
                    height={20}
                    onClick={() => {
                      submitForm();
                      setTotalWasChanged(false);
                    }}
                    className={!totalWasChanged ? "disabled" : ""}
                  />
                  <RejectIcon
                    role={"resetValueIconRole"}
                    size={40}
                    height={20}
                    onClick={() => {
                      setFieldValue("total", userVacation.total);
                      setTotalWasChanged(false);
                    }}
                    className={!totalWasChanged ? "disabled" : ""}
                  />
                </>
              ),
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default TotalVacationTableCellForm;
