import React, { ReactElement, useEffect, useState } from "react";
import { REACT_APP_GOOGLE_API_KEY } from "../../../config";
import { DEFAULT_MAP_ZOOM } from "../../../ui/map/utils";
import PinMarker from "../../../ui/map/marker/pinWithPopover";
import GoogleMapReact from "google-map-react";
import { Location } from "../../../types/be/place";
import { Card as MuiCard, CardContent, Skeleton } from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { getProperties } from "../../../api/property";
import { getJson } from "../../../utils/http";
import { Property } from "../../../types/be/property";
import MarkerPopover from "./markerPopover";
import { isNodeEnv } from "../../../utils/env";

const Card = styled(MuiCard)(spacing);
/* istanbul ignore next */
const PropertiesMap = (): ReactElement => {
  const [placeLocation] = useState<Location>();
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  /* istanbul ignore next */
  const getMapOptions = (): any => {
    return {
      fullscreenControl: true,
      scaleControl: true,
      scrollwheel: false,
    };
  };

  useEffect(() => {
    setIsLoading(true);
    getProperties()
      .then(getJson)
      .then(({ data }) => {
        setProperties(data);
        setIsLoading(false);
      });
  }, []);
  /* istanbul ignore next */
  const markers = properties.map(
    (item: Property) =>
      item.location &&
      item.location.lat &&
      item.location.lng && (
        <PinMarker
          key={`${item.object_name}-${item.id}`}
          lat={item.location.lat}
          lng={item.location.lng}
          Popover={() => (
            <MarkerPopover
              property={item}
              handleOnHover={() => {}}
              handleOnBlur={() => {}}
            />
          )}
        />
      )
  );

  return (
    <Card mb={6} sx={{ height: "100%" }}>
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height="100%" />
      ) : (
        <CardContent sx={{ height: "100%" }}>
          {!isNodeEnv("test") && (
            <GoogleMapReact
              options={getMapOptions}
              bootstrapURLKeys={{ key: REACT_APP_GOOGLE_API_KEY! }}
              defaultCenter={{ lat: 51.1657, lng: 10.4515 }}
              defaultZoom={DEFAULT_MAP_ZOOM}
              center={placeLocation}
              zoom={6}
            >
              {markers}
            </GoogleMapReact>
          )}
        </CardContent>
      )}
    </Card>
  );
};

export default PropertiesMap;
