import React, { ReactElement, useState } from "react";
import { MarkerProps } from "./types";
import { LocationOnIcon, PopoverWrapper } from "./styled";

/* eslint-disable @typescript-eslint/no-unused-vars */ /* istanbul ignore next */
const PinWithPopover = ({
  lat,
  lng,
  onClick,
  Popover,
}: MarkerProps): ReactElement => {
  const [isHovered, setIsHovered] = useState(false);
  const handleOnHover = (): void => {
    setIsHovered(true);
  };
  const handleOnBlur = (): void => {
    setIsHovered(false);
  };

  return (
    <div>
      <PopoverWrapper onMouseEnter={handleOnHover} onMouseLeave={handleOnBlur}>
        {isHovered && <Popover />}
      </PopoverWrapper>
      <LocationOnIcon
        $hovered={isHovered}
        onMouseEnter={handleOnHover}
        onMouseLeave={handleOnBlur}
        onClick={onClick}
        sx={{ position: "absolute", transform: "translate(-50%, -50%)" }}
        color={"error"}
        fontSize={"large"}
      />
    </div>
  );
};

export default PinWithPopover;
