import { FECreditor } from "../types/fe/creditor";
import http from "../utils/http";

export const createCreditor = async (
  creditor: FECreditor
): Promise<Response> => {
  return await http.post("/api/datev/creditors", creditor);
};

export const getCreditors = async (params: string): Promise<Response> => {
  return await http.get(`/api/datev/creditors${params}`);
};

export const deleteCreditor = async (id: string): Promise<Response> => {
  return await http.delete(`/api/datev/creditors/${id}`);
};

export const bulkDelete = async (): Promise<Response> => {
  return await http.delete("/api/datev/creditors");
};

/* istanbul ignore next */
export const editCreditor = async (
  id: string,
  creditor: Partial<FECreditor>
): Promise<Response> => {
  return await http.put(`/api/datev/creditors/${id}`, creditor);
};

export const uploadCSVFile = async (data: FormData): Promise<Response> => {
  return await http.post("/api/datev/import", data);
};
