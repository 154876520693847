/*istanbul ignore file*/

import {
  AreaListContextType,
  AreaListStateType,
  SET_AREA,
  SET_AREA_CATEGORIES,
  SET_IS_AREA_CATEGORIES_LOADING,
  SET_IS_TENANT_USERS_LOADING,
  SET_TENANT_USERS,
} from "./types";
import { Area, AreaCategory } from "../../../../types/be/area";
import React, { createContext, useEffect, useReducer } from "react";
import { ActionMap } from "../../../../types/fe/context";
import { useFetch } from "../../../../hooks/useFetch";
import { getArea, getAreaCategories } from "../../../../api/area";
import { getJson } from "../../../../utils/http";
import { User } from "../../../../types/be/user";
import { fetchUsersByRoleId } from "../../../../api/roles";
import { UserRoles } from "../../../../constants";

export type AreaListActionTypes = {
  [SET_AREA]: {
    area: Area;
  };
  [SET_AREA_CATEGORIES]: {
    areaCategories: AreaCategory[];
  };
  [SET_TENANT_USERS]: {
    tenantUsers: User[];
  };
  [SET_IS_TENANT_USERS_LOADING]: {
    isTenantUsersLoading: boolean;
  };
  [SET_IS_AREA_CATEGORIES_LOADING]: {
    isAreaCategoriesLoading: boolean;
  };
};

const AreaListContext = createContext<AreaListContextType | null>({
  area: null,
  areaFormData: null,
  areaCategories: [],
  tenantUsers: [],
  isTenantUsersLoading: false,
  isAreaCategoriesLoading: false,
  setActiveArea: () => {},
});

const reducer = (
  state: AreaListStateType,
  action: ActionMap<AreaListActionTypes>[keyof ActionMap<AreaListActionTypes>]
): AreaListStateType => {
  switch (action.type) {
    case SET_AREA:
      return {
        ...state,
        area: action.payload.area,
      };
    case SET_AREA_CATEGORIES:
      return {
        ...state,
        areaCategories: action.payload.areaCategories,
      };
    case SET_IS_AREA_CATEGORIES_LOADING:
      return {
        ...state,
        isAreaCategoriesLoading: action.payload.isAreaCategoriesLoading,
      };
    case SET_TENANT_USERS:
      return {
        ...state,
        tenantUsers: action.payload.tenantUsers,
      };
    case SET_IS_TENANT_USERS_LOADING:
      return {
        ...state,
        isTenantUsersLoading: action.payload.isTenantUsersLoading,
      };
    default:
      return {
        ...state,
      };
  }
};

function AreaListProvider({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement {
  const [state, dispatch] = useReducer(reducer, {
    area: null,
    areaCategories: [],
    tenantUsers: [],
    isTenantUsersLoading: false,
    isAreaCategoriesLoading: false,
    areaFormData: null,
  });

  const {
    data: areaCategories,
    run: runAreaCategories,
    isLoading: isAreaCategoriesLoading,
  } = useFetch<AreaCategory[]>();

  const {
    data: tenantUsers,
    isLoading: isTenantUsersLoading,
    run: runTenantUsers,
  } = useFetch<User[]>();

  useEffect(() => {
    runAreaCategories(getAreaCategories());
    runTenantUsers(fetchUsersByRoleId(UserRoles.TENANT));
  }, []);

  useEffect(() => {
    dispatch({
      type: SET_AREA_CATEGORIES,
      payload: { areaCategories: areaCategories || [] },
    });
  }, [areaCategories]);

  useEffect(() => {
    dispatch({
      type: SET_TENANT_USERS,
      payload: { tenantUsers: tenantUsers || [] },
    });
  }, [tenantUsers]);

  useEffect(() => {
    dispatch({
      type: SET_IS_TENANT_USERS_LOADING,
      payload: { isTenantUsersLoading: isTenantUsersLoading },
    });
  }, [isTenantUsersLoading]);

  useEffect(() => {
    dispatch({
      type: SET_IS_AREA_CATEGORIES_LOADING,
      payload: { isAreaCategoriesLoading: isAreaCategoriesLoading },
    });
  }, [isAreaCategoriesLoading]);

  const setActiveArea = (id: number): void => {
    getArea(id.toString())
      .then(getJson)
      .then(() => {
        dispatch({
          type: SET_IS_AREA_CATEGORIES_LOADING,
          payload: { isAreaCategoriesLoading: isAreaCategoriesLoading },
        });
      });
  };

  return (
    <AreaListContext.Provider
      value={{
        ...state,
        setActiveArea,
      }}
    >
      {children}
    </AreaListContext.Provider>
  );
}

export { AreaListProvider, AreaListContext };
