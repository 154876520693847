import React, { ReactElement, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Box, Grid } from "@mui/material";
import format from "date-fns/format";
import { CloseIconStyled, TextField, DatePickerWrapper } from "./styled";
import { TopicDateRangeFilterProps } from "./types";
import {
  initInsuranceRange,
  navigateInsurancePage,
} from "../../insurances/list/topicList/utils";
import { useNavigate, useParams } from "react-router-dom";
import { formatDateToTimespamp } from "../../../../utils/date";
import { FormikValues } from "formik";

import "react-datepicker/dist/react-datepicker.css";
import { DATEPICKER_DATE_FORMAT } from "../../../../constants";

const TopicDateRangeFilter = ({
  range,
  setRange,
  status,
  navigateProp,
  calenderContainerClass
}: FormikValues & TopicDateRangeFilterProps): ReactElement => {
  const [startDate, endDate] = range;

  const { id, type, status: statusCode } = useParams();
  const navigate = useNavigate();
  const refOne = useRef<HTMLDivElement | null>(null);

  const [open, setOpen] = useState(false);
  const [tempStartDate, setTempStartDate] = useState<Date | null>(startDate);
  const [tempEndDate, setTempEndDate] = useState<Date | null>(endDate);

  useEffect(() => {
    document.addEventListener("click", hideOnClickOutside, true);

    return () => {
      document.removeEventListener("click", hideOnClickOutside, true);
    };
  }, []);

  const hideOnClickOutside = (event: MouseEvent): void => {
    event.target instanceof Element &&
      !refOne?.current?.contains(event.target) &&
      setOpen(false);
  };

  const handleRemoveDateRangeClick = (
    event: React.MouseEvent<SVGSVGElement>
  ): void => {
    event.stopPropagation();
    const [start, end] = initInsuranceRange;
    navigateProp &&
      navigateInsurancePage(
        navigate,
        type!,
        formatDateToTimespamp(start).toString(),
        formatDateToTimespamp(end).toString(),
        statusCode!,
        id,
      );
    setRange(initInsuranceRange);
    setTempStartDate(start);
    setTempEndDate(end);
  };

  const handleChange = (date: (Date | null)[]): void => {
    const [start, end] = date;

    setTempStartDate(start);
    setTempEndDate(end);

    start && end && setRange([start, end]);
    start &&
      end &&
      navigateProp &&
      navigateInsurancePage(
        navigate,
        type!,
        formatDateToTimespamp(start).toString(),
        formatDateToTimespamp(end).toString(),
        statusCode!,
        id
      );
  };

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12}>
        <Box
          className="calendarWrap"
          sx={{ display: "inline-block", position: "relative" }}
        >
          <Box onClick={() => setOpen((open) => !open)}>
            <TextField
              fullWidth
              name="from"
              value={`${
                startDate && format(startDate, DATEPICKER_DATE_FORMAT)
              } - ${endDate && format(endDate, DATEPICKER_DATE_FORMAT)}`}
              inputProps={{ readOnly: true, "data-testid": "date-range-input" }}
              size="small"
              InputProps={{
                endAdornment: (
                  <CloseIconStyled
                    data-testid="remove-from-date-range"
                    onClick={handleRemoveDateRangeClick}
                  />
                ),
              }}
              error={Boolean(status?.errors?.from || status?.errors?.to)}
              helperText={status?.errors?.from || status?.errors?.to}
            />
          </Box>
          <Box ref={refOne} className={calenderContainerClass}>
            {open ? (
              <DatePickerWrapper>
                <DatePicker
                  selected={tempStartDate}
                  startDate={tempStartDate}
                  endDate={tempEndDate}
                  onChange={handleChange}
                  selectsRange
                  dateFormat={DATEPICKER_DATE_FORMAT}
                  inline
                />
              </DatePickerWrapper>
            ) : null}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TopicDateRangeFilter;
