/* istanbul ignore file */
import styled from "styled-components";
import { Grid } from "@mui/material";

export const TenantInfoContainer = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${(props): string => `${props.theme.spacing(1)}`};
  flex-direction: column;
  padding: ${(props): string => `${props.theme.spacing(4)}`};
`;

export const DefaultImage = styled.img`
  height: ${(props) => props.theme.spacing(125)};
  width: 100%;
  object-fit: contain;
`;
