import React, { ReactElement, useState } from "react";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { File as BEFile } from "../../types/be/file";
import {
  CarouselContainer,
  NoPicturesContainer,
  SliderNavigationContainer,
} from "./styled";
import { Box } from "@mui/material";
import Video from "../video";
import { VIDEO_FILE_TYPE } from "../../constants";
const AutoPlaySwipeableViews = SwipeableViews;
import { DefaultImage } from "../../components/properties/list/PropertyGridView/styled";
import { useTranslation } from "react-i18next";
import {
  DialogTitleStyled,
  PreviewImage,
  PreviewImageDialog,
} from "../imageViewer/simple/styled";
/* istanbul ignore next */
const Carousel = ({
  images,
  defaultImagePath,
}: {
  images: BEFile[];
  defaultImagePath?: string;
}): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);

  const [isPreviewZoomed, setIsPreviewZoomed] = useState(false);
  const [previewImage, setPreviewImage] = useState<BEFile | null>(null);
  const maxSteps = images.length;

  const handleNext = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.stopPropagation();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.stopPropagation();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number): void => {
    setActiveStep(step);
  };

  const handlePreviewClose = (): void => {
    setPreviewImage(null);
    setIsPreviewZoomed(false);
  };

  const handlePreviewImageClick = (): void => {
    setIsPreviewZoomed((val) => !val);
  };

  return (
    <>
      {images && images.length > 0 ? (
        <CarouselContainer sx={{ maxWidth: 400, flexGrow: 1 }}>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {images.map((step, index) => (
              <div key={step.id}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <>
                    {step.extension === VIDEO_FILE_TYPE ? (
                      <Video
                        video={step}
                        maxWidth={400}
                        height={255}
                        controls={false}
                      />
                    ) : (
                      <Box
                        component="img"
                        sx={{
                          height: 255,
                          display: "block",
                          maxWidth: 400,
                          overflow: "hidden",
                          width: "100%",
                          borderRadius: "15px",
                          objectFit: "contain",
                        }}
                        onClick={() => {
                          setPreviewImage(step);
                        }}
                        src={`${step.url}?width=400&height=255`}
                        alt={step.name}
                      />
                    )}
                  </>
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <SliderNavigationContainer
            className="hover-visible"
            onClick={(e) => e.stopPropagation()}
          >
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </SliderNavigationContainer>
        </CarouselContainer>
      ) : defaultImagePath ? (
        <NoPicturesContainer>
          <DefaultImage src={defaultImagePath} alt="" />
          <p>{t("property.noPictures")}</p>
        </NoPicturesContainer>
      ) : null}
      {previewImage && (
        <PreviewImageDialog
          $isZoomed={isPreviewZoomed}
          onClose={handlePreviewClose}
          open={!!previewImage}
        >
          <DialogTitleStyled id="responsive-dialog-title">
            {previewImage?.name}
          </DialogTitleStyled>
          <PreviewImage
            $isZoomed={isPreviewZoomed}
            src={previewImage?.url}
            alt=""
            onClick={handlePreviewImageClick}
          />
        </PreviewImageDialog>
      )}
    </>
  );
};

export default Carousel;
