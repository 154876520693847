import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Table from "../../../../../../../ui/table/Table";
import useTable from "../../../../../../../ui/table/useTable";
import { BKA } from "../../../../../../../types/be/tenant";
import { Grid, TableCell } from "@mui/material";
import Button from "../../../../../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import {
  COMMENTABLE,
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  Measurement_Units,
  THOUSAND_SEPARATOR,
} from "../../../../../../../constants";
import { formatTimestamp } from "../../../../../../../utils/date";
import { BKAFormProps } from "../../../types";
import { itemsListHeadCells } from "./utils";
import { DeleteIcon, EditIcon } from "../../../../../../../ui/table/styled";
import CreateBKADialog from "../create";
import { deleteBKA, getBKAList } from "../../../../../../../api/bka";
import { handleServerError } from "../../../../../../../utils/http";
import { prepareQueryParams } from "../../../../../../../utils/common";
import useSnackbar from "../../../../../../../ui/snackbar1/useSnackbar";
import EditBKADialog from "../edit";
import { AreaEditContext } from "../../../AreaEditContext";
import { NumericFormat } from "react-number-format";
import { showCommentsModalInitState } from "../../../../../../../ui/commentsPopUp/utils";
import CommentsPopup from "../../../../../../../ui/commentsPopUp";
import ExpandableComment from "../../../../../../../ui/expandableComment/ExpandableComment";
import FileTableCell from "../../../../../components/fileTableCell";

const BKAList = (props: BKAFormProps): ReactElement => {
  const { t } = useTranslation();
  const { snackbar, Snackbar } = useSnackbar();
  const areaEditContext = useContext(AreaEditContext);
  const [isCreateBKADialogOpen, setIsCreateBKADialogOpen] = useState(false);
  const [editBKAData, setEditBKAData] = useState<BKA | null>(null);
  const [isShowCommentsModal, setIsShowCommentsModal] = useState(
    showCommentsModalInitState
  );

  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleSortChange,
    handleSelectAllClick,
    setTotal,
    openConfirmToRemoveModal,
    rowToDelete,
    handleDeleteLastPageData,
    handleConfirmToRemoveModalClose,
  } = useTable<BKA>();

  const afterCreateBKA = async (): Promise<void> => {
    setIsCreateBKADialogOpen(false);
    snackbar.success(t("tenant.BKACreatedSuccessfully"));
    await fetchList();
  };

  const afterEditBKA = async (): Promise<void> => {
    setEditBKAData(null);
    snackbar.success(t("tenant.BKAUpdatedSuccessfully"));
    await fetchList();
  };

  const handleOpenComments = useCallback((row: BKA): void => {
    setIsShowCommentsModal({ isVisible: true, rowId: row?.id });
  }, []);

  const fetchList = useCallback(async () => {
    setIsLoading(true);
    areaEditContext?.fetchArea && areaEditContext?.fetchArea();
    const response = await getBKAList(
      prepareQueryParams(queryParams, {
        property_tenant_id: props?.initialValues?.active_tenant?.id,
      })
    );

    if (response.ok) {
      const resJson = await response.json();
      setTotal(resJson?.meta?.total);
      setData(resJson.data);
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }

    setIsLoading(false);
  }, [queryParams]);

  const handleCloseCommentsModal = useCallback((): void => {
    setIsShowCommentsModal(showCommentsModalInitState);
    fetchList();
  }, [fetchList]);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteBKA(rowToDelete?.id);
    handleDeleteLastPageData(fetchList);
    handleConfirmToRemoveModalClose();
  }, [rowToDelete, fetchList, handleConfirmToRemoveModalClose, deleteBKA]);

  useEffect(() => {
    fetchList();
  }, [queryParams]);

  const TableToolbar = (
    <>
      <Grid container sx={{ pt: 3 }}>
        <Grid item>
          <Button
            title={t("create")}
            color="success"
            variant="contained"
            size="medium"
            onClick={() => setIsCreateBKADialogOpen(true)}
          />
        </Grid>
      </Grid>
    </>
  );

  const renderRow = (row: BKA): ReactElement => (
    <>
      <FileTableCell file={row.file} />
      <TableCell>{row?.year}</TableCell>
      <TableCell>
        {row?.date_bka ? formatTimestamp(row.date_bka) : EMPTY_DATA}
      </TableCell>
      <TableCell align="left">
        <ExpandableComment
          seeAllComment={() => handleOpenComments(row)}
          commentData={row?.latest_comment}
        ></ExpandableComment>
      </TableCell>
      <TableCell>
        {row?.date ? formatTimestamp(row.date) : EMPTY_DATA}
      </TableCell>
      <TableCell>
        {row?.amount ? (
          <>
            <NumericFormat
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              fixedDecimalScale
              decimalScale={DECIMAL_SCALE}
              displayType={"text"}
              value={row?.amount}
            />{" "}
            {Measurement_Units.EURO}
          </>
        ) : (
          EMPTY_DATA
        )}
      </TableCell>
      <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
        <EditIcon
          role={"editIconRole"}
          size={18}
          onClick={() => setEditBKAData(row)}
        />
        <DeleteIcon
          size={20}
          role={"deleteIconRole"}
          onClick={() => openConfirmToRemoveModal(row)}
        />
      </TableCell>
    </>
  );

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        onRowRemove={handleRowRemove}
      />
      {isCreateBKADialogOpen ? (
        <CreateBKADialog
          open={isCreateBKADialogOpen}
          onClose={() => setIsCreateBKADialogOpen(false)}
          afterCreateBKA={afterCreateBKA}
          property_tenant_id={props?.initialValues?.active_tenant?.id}
        />
      ) : null}
      {editBKAData ? (
        <EditBKADialog
          data={editBKAData}
          afterEditBKA={afterEditBKA}
          onClose={() => setEditBKAData(null)}
          open={Boolean(editBKAData)}
        />
      ) : null}
      {isShowCommentsModal.isVisible && (
        <CommentsPopup
          handleCloseDialog={handleCloseCommentsModal}
          id={String(isShowCommentsModal?.rowId)}
          relation={COMMENTABLE.BKA}
        />
      )}
      {Snackbar}
    </>
  );
};

export default BKAList;
