import React, { ReactElement } from "react";
import { Grid, Stack } from "@mui/material";
import { FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";

import TextField from "../../../../../../ui/formsFields/text";
import { handleDatepicker } from "../../../../../../utils/form";
import Button from "../../../../../../ui/button/Button";
import { route } from "../../../../../../utils/url";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router";

const CreateCreditor = ({
  handleSubmit,
  status,
  values,
  setFieldValue,
  isSubmitting,
}: FormikValues): React.ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={4} columns={12}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="konto"
              required
              inputProps={{ "data-testid": "konto-input" }}
              label={t("datev.creditor.formFields.konto")}
              status={status}
              placeholder={t("datev.creditor.formFields.konto")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="company_name"
              required
              inputProps={{ "data-testid": "companyname-input" }}
              label={t("datev.creditor.formFields.companyName")}
              placeholder={t("datev.creditor.formFields.companyName")}
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="addressee_type"
              inputProps={{ "data-testid": "addresseetype-input" }}
              label={t("datev.creditor.formFields.addresseeType")}
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="abbreviation"
              inputProps={{ "data-testid": "abbreviation-input" }}
              label={t("datev.creditor.formFields.abbreviation")}
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="address_type"
              inputProps={{ "data-testid": "addresstype-input" }}
              label={t("datev.creditor.formFields.addresstype")}
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="street"
              inputProps={{ "data-testid": "street-input" }}
              label={t("datev.creditor.formFields.street")}
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="postal_code"
              inputProps={{ "data-testid": "postalcode-input" }}
              label={t("datev.creditor.formFields.postalcode")}
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="location"
              inputProps={{ "data-testid": "location-input" }}
              label={t("datev.creditor.formFields.location")}
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="code_correspondence_address"
              inputProps={{ "data-testid": "codeCorrespondenceAddress-input" }}
              label={t("datev.creditor.formFields.codeCorrespondenceAddress")}
              status={status}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              openTo="year"
              views={["year", "month", "day"]}
              inputFormat="dd/MM/yyyy"
              value={values.address_valid_from}
              label={t("datev.creditor.formFields.addressValidFrom")}
              onChange={handleDatepicker(
                { setFieldValue },
                "address_valid_from"
              )}
              renderInput={(params): ReactElement => (
                <TextField
                  name="address_valid_from"
                  type="date"
                  status={status}
                  value={values.address_valid_from}
                  fullWidth
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
        >
          <Button
            type="button"
            color="primary"
            variant="text"
            sx={{ mt: "1rem" }}
            size="large"
            title={t("cancel")}
            disabled={isSubmitting}
            onClick={
              /* istanbul ignore next */ () =>
                doesAnyHistoryEntryExist
                  ? navigate(-1)
                  : navigate(route("properties.invoices.apidatev", id))
            }
          />
          <Button
            title={t("create")}
            sx={{ ml: "1rem", mt: "1rem" }}
            color="success"
            size="large"
            type="submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
        </Stack>
      </form>
    </>
  );
};

export default CreateCreditor;
