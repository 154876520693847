import React, { ReactElement, useState } from "react";
import moment from "moment";
import { EmployeeOverviewCellProps } from "../../types";
import { Box, Grid } from "@mui/material";
import { replaceMomentByFormatedString } from "../../../../utils/date";
import { handleUpdateScheduleSubmit } from "../../utils";
import useAccessControl from "../../../../hooks/useAccessControl";
import { Formik } from "formik";
import {
  InfoBox,
  StyledGrid,
  StyledMenuItem,
  TableCell,
  TextField,
  EditIcon,
  InnerStyledGrid,
  PublicHolidayStyledGrid,
} from "../styled";
import DescriptionModal from "./DescriptionModal";
import { FORM_SELECT_OPTION_ID } from "../../../../constants";
import { useTranslation } from "react-i18next";
import { Home, MapPin, Moon, Navigation, Sun, Sunset, X } from "react-feather";
import { amber, green, grey, indigo } from "@mui/material/colors";
/* istanbul ignore next */
const EmployeeOverviewCell = ({
  showDescriptionModal,
  handleCloseDescriptionModal,
  options,
  schedules,
  day,
  id,
  handleSuccessfulUpdateScheduleSubmission,
  handleOptionClick,
  handleOptionChange,
  isCurrentTableCellSelected,
  handleEditIconClick,
  ind,
  employeeOptionId,
}: EmployeeOverviewCellProps): ReactElement => {
  const { isRole, _user } = useAccessControl();
  const formattedDate = replaceMomentByFormatedString(day);
  const schedule = schedules?.find(({ date }) => date === formattedDate);
  const isLoggedUserRow = _user?.id === id;
  const isAdmin = isRole("admin");
  const [optionId, setOptionId] = useState(schedule?.option.id);
  const [scheduleId, setScheduleId] = useState(schedule?.id);
  const { t } = useTranslation();
  const [description, setDescription] = useState(schedule?.description || "");
  const isPublicHolidaySelected = optionId === employeeOptionId.PUBLIC_HOLIDAY;
  const showPublicHolidayTitle =
    isPublicHolidaySelected && Boolean(schedule?.holiday_type);
  const isFieldServiceSelected = optionId === employeeOptionId.FIELD_SERVICE;

  const is_blocked = !!(schedule && schedule.is_blocked === 1);

  const statusDict: Record<string, React.ReactElement> = {
    [Number(employeeOptionId.HOME_OFFICE)]: <Home size={15} />,
    [Number(employeeOptionId.VACATION)]: <Sunset size={15} />,
    [Number(employeeOptionId.OUT_OF_OFFICE)]: <Moon size={15} />,
    [Number(employeeOptionId.FIELD_SERVICE)]: <Navigation size={15} />,
    [Number(employeeOptionId.PUBLIC_HOLIDAY)]: <Sun size={15} />,
  };
  const getBackgroundColorByOptionId = (id?: number): string => {
    switch (id) {
      case employeeOptionId.HOME_OFFICE:
        return amber["900"];
      case employeeOptionId.VACATION:
        return grey["500"];
      case employeeOptionId.OUT_OF_OFFICE:
        return grey["500"];
      case employeeOptionId.PUBLIC_HOLIDAY:
        return indigo["400"];
      case undefined:
        return "transparent";
      default:
        return green["500"];
    }
  };

  return (
    <TableCell align="left" key={formattedDate} $showBorderRight={ind === 5}>
      {(isLoggedUserRow &&
        moment().isSameOrBefore(day, "day") &&
        !is_blocked) ||
      isAdmin ? (
        options && (
          <Formik
            initialValues={{
              id: scheduleId,
              option_id: optionId,
              description,
            }}
            enableReinitialize={true}
            onSubmit={handleUpdateScheduleSubmit({
              id: scheduleId,
              isAdmin,
              formattedDate,
              userId: id,
              handleSuccessfulUpdateScheduleSubmission,
              setOptionId,
              setDescription,
              setScheduleId,
            })}
          >
            {({
              status,
              handleChange,
              submitForm,
              values,
              isSubmitting,
              resetForm,
            }): React.ReactElement => (
              <Grid container sx={{ position: "relative" }}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    name="option_id"
                    select
                    size="small"
                    value={values.option_id || ""}
                    status={status}
                    onClick={handleOptionClick(formattedDate)}
                    onChange={handleOptionChange({
                      formattedDate,
                      handleChange,
                      submitForm,
                    })}
                    inputProps={{
                      "aria-label": `${id}-${formattedDate}`,
                    }}
                    $getBackgroundColorByOptionId={getBackgroundColorByOptionId}
                    $optionId={values.option_id}
                    $isFieldServiceSelected={isFieldServiceSelected}
                    $showPublicHolidayTitle={showPublicHolidayTitle}
                  >
                    {values.option_id && (
                      <StyledMenuItem
                        key={FORM_SELECT_OPTION_ID}
                        value={FORM_SELECT_OPTION_ID}
                      >
                        <X size={14} />
                        <span> {t("form.select")}</span>
                      </StyledMenuItem>
                    )}
                    {options.map((option) => (
                      <StyledMenuItem
                        sx={
                          !isAdmin &&
                          option.id === employeeOptionId.PUBLIC_HOLIDAY
                            ? { display: "none" }
                            : { display: "flex" }
                        }
                        key={option.id}
                        value={option.id}
                      >
                        {statusDict[option.id] ? (
                          statusDict[option.id]
                        ) : (
                          <MapPin size={14} />
                        )}
                        <span>{option.name}</span>
                      </StyledMenuItem>
                    ))}
                  </TextField>
                </Grid>
                {!isCurrentTableCellSelected(formattedDate) &&
                  isFieldServiceSelected && (
                    <StyledGrid item>
                      <InnerStyledGrid>
                        <Box
                          component="div"
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {values.description}
                        </Box>
                      </InnerStyledGrid>
                      <EditIcon
                        size={13}
                        onClick={handleEditIconClick(formattedDate)}
                        data-testid="edit-icon"
                      />
                    </StyledGrid>
                  )}
                {!isCurrentTableCellSelected(formattedDate) &&
                  showPublicHolidayTitle && (
                    <PublicHolidayStyledGrid item>
                      <InnerStyledGrid>
                        <Box
                          component="div"
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {schedule?.holiday_type?.name}
                        </Box>
                      </InnerStyledGrid>
                    </PublicHolidayStyledGrid>
                  )}
                {isCurrentTableCellSelected(formattedDate) && (
                  <DescriptionModal
                    showDescriptionModal={showDescriptionModal}
                    handleCloseDescriptionModal={handleCloseDescriptionModal}
                    resetForm={resetForm}
                    isSubmitting={isSubmitting}
                    submitForm={submitForm}
                    status={status}
                    values={values}
                  />
                )}
              </Grid>
            )}
          </Formik>
        )
      ) : (
        <InfoBox
          $getBackgroundColorByOptionId={getBackgroundColorByOptionId}
          $optionId={schedule?.option.id}
        >
          {schedule?.option.name}
        </InfoBox>
      )}
    </TableCell>
  );
};

export default EmployeeOverviewCell;
