import { PermissionsDelete } from "../../../constants";
import { TableCell } from "../../../types/table";
import { convertNumberToFileSize } from "../../../utils/common";
import { FileManagementListViewItem } from "../types";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "fileManagement.list.fileName",
  },
  {
    id: "size",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "fileManagement.list.size",
  },
  {
    id: "extension",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "fileManagement.list.type",
  },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "fileManagement.list.updatedAt",
  },

  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "",
  },
];

/* istanbul ignore next */
export const disableDelete = (
  item: FileManagementListViewItem,
  can: (can: string | string[]) => boolean
): boolean =>
  Boolean(
    !can([PermissionsDelete.FILE_MANAGEMENT]) ||
      (isFolder(item) ? !item.can_remove : item.has_relation)
  );
/* istanbul ignore next */
export const disableRenameAndMove = (
  item: FileManagementListViewItem,
  can: (can: string | string[]) => boolean
): boolean =>
  Boolean(
    !can([PermissionsDelete.FILE_MANAGEMENT]) ||
      (isFolder(item) && !item.can_remove)
  );

/* istanbul ignore next */
export const isFolder = (item: FileManagementListViewItem): boolean =>
  !("extension" in item);

/* istanbul ignore next */
export const displayItemSize = (item: FileManagementListViewItem): string => {
  if (isFolder(item)) return "";
  return convertNumberToFileSize(Number(item.size ?? 0));
};

export const tablePaperSx = {
  width: "100%",
  mb: 2,
  px: 10,
  py: 1,
  height: "100%",
  background: "transparent",
};
