import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Grid, Link, Paper } from "@mui/material";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoadingBox, ErrorBox, LabelText, OfferImage } from "./styled";
import { useFetch } from "../../../../hooks/useFetch";
import { Offer } from "../../../../types/be/offer";
import Loader from "../../../Loader";
import {
  COMMENTABLE,
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  FormMessageErrorState,
  Measurement_Units,
  PermissionsUpdate,
  THOUSAND_SEPARATOR,
} from "../../../../constants";
import { getOffer } from "../../../../api/offer";
import { route } from "../../../../utils/url";
import Comments from "../../../../ui/сomments";
import useComments from "../../../../ui/сomments/useComments";
import { NumericFormat } from "react-number-format";
import { RELEASE_LEVEL } from "../../../../utils/common";
import {
  findReleaseLevelName,
  getReleaseRoute,
  isAvailableReleaseButtonTableCell,
  RELASE_BUTTONS_AM,
  RELASE_BUTTONS_PM,
  RELEASE_BUTTONS_FR,
  RELEASE_BUTTONS_USER,
} from "../../utils";
import {
  DOCUMENT_RELEASE_LEVEL,
  DOCUMENT_RELEASE_MODULE,
} from "../../../../types/be/releaseFlow";
import PdfViewer from "../../../../ui/pdfViewer";
import { HeaderText } from "../../edit/general/styled";
import useDocumentRelease from "../../components/documentRelease/useDocumentRelease";
import DocumentRelease from "../../components/documentRelease";
import TabPageWrapper from "../../../../ui/tabPageWrapper";
import useAccessControl from "../../../../hooks/useAccessControl";

const OfferShow = (): ReactElement => {
  const { t } = useTranslation();
  const { id, offerId } = useParams();
  const pdfContainerRef = useRef<HTMLObjectElement | null>(null);
  const [offerFildId, setOfferFileId] = useState<number | undefined>();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const {
    configuration,
    isConfigurationLoading,
    handleReleaseDocument,
    handleForwardDocument,
    releasePermission,
    snackbar,
    loading: isDocumentReleaseLoading,
  } = useDocumentRelease(DOCUMENT_RELEASE_MODULE.OFFER);
  const {
    comments,
    content,
    setContent,
    currentPage,
    totalPages,
    handleCommentSave,
    handleCommentDelete,
    handlePaginationChange,
    deleting,
    loading,
  } = useComments({
    relation: COMMENTABLE.OFFER,
    relation_id: offerId!,
  });
  const {
    data: offer,
    run: runOffers,
    isLoading: offerLoading,
    isError: offerError,
  } = useFetch<Offer>();
  const { can } = useAccessControl();

  const getData = async (): Promise<void> => {
    await runOffers(getOffer(offerId!));
    setIsDataLoaded(true);
  };
  /* istanbul ignore next */
  useEffect(() => {
    offer && offer?.file.id && setOfferFileId(offer?.file?.id);
  }, [offer]);

  useEffect(() => {
    getData();
  }, []);

  const offerFile = useMemo(() => {
    return { fileId: offerFildId };
  }, [offerFildId]);

  if (!isDataLoaded && (offerLoading || isConfigurationLoading))
    return (
      <LoadingBox>
        <Loader />
      </LoadingBox>
    );

  if (offerError || !offer)
    return <ErrorBox formMessage={FormMessageErrorState} />;

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("offers", Number(offerId)),
      name: t("offers.offer"),
    },
  ];

  return (
    <TabPageWrapper
      title={offer.file.name}
      breadcrumbs={breadcrumbs}
      wrap={!id}
    >
      <Grid container justifyContent={"flex-end"} spacing={3}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12} p={4}>
              <Grid container spacing={4}>
                <Grid item xs={12} ref={pdfContainerRef}>
                  <PdfViewer file={offerFile} containerRef={pdfContainerRef} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Paper variant="outlined" sx={{ p: 4, textAlign: "center" }}>
                    <Grid
                      container
                      justifyContent={"center"}
                      spacing={4}
                      sx={{ textAlign: "left" }}
                    >
                      <Grid item xs={12}>
                        <HeaderText>
                          <Link href={offer?.file?.url} target="_blank">
                            {offer?.file?.name}
                          </Link>
                        </HeaderText>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent={"center"} mt={6}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {offer?.created_by
                            ? `${offer.created_by.first_name} ${offer.created_by.last_name}`
                            : /* istanbul ignore next */ EMPTY_DATA}
                        </HeaderText>
                        <LabelText>{t("offers.show.createdBy")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          <NumericFormat
                            thousandSeparator={THOUSAND_SEPARATOR}
                            decimalSeparator={DECIMAL_SEPARATOR}
                            decimalScale={DECIMAL_SCALE}
                            fixedDecimalScale
                            displayType={"text"}
                            value={offer.amount}
                          />{" "}
                          {offer.amount !== null && Measurement_Units.EURO}
                        </HeaderText>
                        <LabelText>{t("offers.show.amount")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText> {offer?.fr_status?.name} </HeaderText>
                        <LabelText>{t("offers.show.frStatus")}</LabelText>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent={"center"} mt={6}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {`${offer?.contact?.first_name} ${offer?.contact?.last_name}`}
                        </HeaderText>
                        <LabelText>{t("offers.show.contact")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {offer.release_date
                            ? moment
                                .unix(offer.release_date)
                                .format("MM/DD/YYYY")
                            : EMPTY_DATA}
                        </HeaderText>
                        <LabelText>{t("offers.show.releaseDate")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>{offer?.am_status?.name}</HeaderText>
                        <LabelText>{t("offers.show.amStatus")}</LabelText>
                      </Grid>
                    </Grid>
                    <Grid container mt={6}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {offer.fr
                            ? `${offer.fr?.first_name} ${offer.fr?.last_name}`
                            : /* istanbul ignore next */ EMPTY_DATA}
                        </HeaderText>
                        <LabelText>{t("offers.show.finalReleaser")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {offer.am
                            ? `${offer.am?.first_name} ${offer.am?.last_name}`
                            : EMPTY_DATA}
                        </HeaderText>
                        <LabelText>{t("offers.show.assetManager")}</LabelText>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper
                    variant="outlined"
                    sx={{ p: 4, textAlign: "left", mt: 4 }}
                  >
                    <Grid container spacing={4}>
                      <Grid
                        item
                        xs={6}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={4}
                      >
                        {
                          /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                            RELASE_BUTTONS_PM,
                            configuration
                          ) ? (
                            <Grid container>
                              <Grid item xs>
                                <LabelText>
                                  {findReleaseLevelName(
                                    configuration,
                                    RELASE_BUTTONS_PM
                                  )}
                                </LabelText>
                              </Grid>
                              <Grid item xs>
                                <DocumentRelease
                                  id={offer?.id}
                                  releaser={offer?.pm}
                                  releaseStatus={offer?.pm_status}
                                  forwardedAt={offer?.pm_forwarded_at}
                                  forwardedBy={offer?.pm_forwarded_by}
                                  overdue={offer?.pm_overdue}
                                  afterSuccess={getData}
                                  handleReleaseDocument={handleReleaseDocument}
                                  handleForwardDocument={handleForwardDocument}
                                  level={RELEASE_LEVEL.THIRD_LEVEL_RELEASE}
                                  hasForwardPermission={
                                    /* istanbul ignore next */
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                    ](offer?.assigned_user)
                                  }
                                  hasReleasePermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                    ]
                                  }
                                  relation={DOCUMENT_RELEASE_MODULE.OFFER}
                                  loading={isDocumentReleaseLoading}
                                  isButtonVisible={{
                                    forwardButton: false,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ) : null
                        }
                        {
                          /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                            RELEASE_BUTTONS_USER,
                            configuration
                          ) ? (
                            <Grid container>
                              <Grid item xs>
                                <LabelText>
                                  {findReleaseLevelName(
                                    configuration,
                                    RELEASE_BUTTONS_USER
                                  )}
                                </LabelText>
                              </Grid>
                              <Grid item xs>
                                <DocumentRelease
                                  id={offer?.id}
                                  releaser={offer?.au}
                                  releaseStatus={offer?.au_status}
                                  forwardedAt={offer?.au_forwarded_at}
                                  forwardedBy={offer?.au_forwarded_by}
                                  overdue={offer?.au_overdue}
                                  afterSuccess={getData}
                                  handleReleaseDocument={handleReleaseDocument}
                                  handleForwardDocument={handleForwardDocument}
                                  level={RELEASE_LEVEL.FOURTH_LEVEL_RELEASE}
                                  hasForwardPermission={
                                    /* istanbul ignore next */
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                    ]
                                  }
                                  hasReleasePermission={releasePermission[
                                    DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                  ](offer?.assigned_user)}
                                  relation={DOCUMENT_RELEASE_MODULE.OFFER}
                                  assignedUser={offer?.assigned_user}
                                  loading={isDocumentReleaseLoading}
                                  isButtonVisible={{
                                    forwardButton: false,
                                  }}
                                  hasAssignUserPermission={can(
                                    PermissionsUpdate.OFFER
                                  )}
                                />
                              </Grid>
                            </Grid>
                          ) : null
                        }
                        {
                          /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                            RELEASE_BUTTONS_FR,
                            configuration
                          ) ? (
                            <Grid container>
                              <Grid item xs>
                                <LabelText>
                                  {findReleaseLevelName(
                                    configuration,
                                    RELEASE_BUTTONS_FR
                                  )}
                                </LabelText>
                              </Grid>
                              <Grid item xs>
                                <DocumentRelease
                                  id={offer?.id}
                                  releaser={offer?.fr}
                                  releaseStatus={offer?.fr_status}
                                  forwardedAt={offer?.fr_forwarded_at}
                                  forwardedBy={offer?.fr_forwarded_by}
                                  overdue={offer?.fr_overdue}
                                  afterSuccess={getData}
                                  handleReleaseDocument={handleReleaseDocument}
                                  handleForwardDocument={handleForwardDocument}
                                  level={RELEASE_LEVEL.FIRST_LEVEL_RELEASE}
                                  hasForwardPermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                    ](offer?.assigned_user)
                                  }
                                  hasReleasePermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                    ]
                                  }
                                  relation={DOCUMENT_RELEASE_MODULE.OFFER}
                                  loading={isDocumentReleaseLoading}
                                  isButtonVisible={{
                                    forwardButton: false,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ) : null
                        }
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={4}
                      >
                        {
                          /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                            RELASE_BUTTONS_AM,
                            configuration
                          ) ? (
                            <Grid container mt={3}>
                              <Grid item xs={5}>
                                <LabelText>
                                  {findReleaseLevelName(
                                    configuration,
                                    RELASE_BUTTONS_AM
                                  )}
                                </LabelText>
                              </Grid>
                              <Grid item xs={7}>
                                <DocumentRelease
                                  id={offer?.id}
                                  releaser={offer?.am}
                                  releaseStatus={offer?.am_status}
                                  forwardedAt={offer?.am_forwarded_at}
                                  forwardedBy={offer?.am_forwarded_by}
                                  overdue={offer?.am_overdue}
                                  afterSuccess={getData}
                                  handleReleaseDocument={handleReleaseDocument}
                                  handleForwardDocument={handleForwardDocument}
                                  level={RELEASE_LEVEL.SECOND_LEVEL_RELEASE}
                                  hasForwardPermission={
                                    /* istanbul ignore next */
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                    ](offer?.assigned_user)
                                  }
                                  hasReleasePermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                    ]
                                  }
                                  relation={DOCUMENT_RELEASE_MODULE.OFFER}
                                  loading={isDocumentReleaseLoading}
                                  isButtonVisible={{
                                    forwardButton: false,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ) : null
                        }
                        <Grid container mt={3}>
                          <Grid item xs={5}>
                            <LabelText>{t("offers.show.picture")}</LabelText>
                          </Grid>
                          <Grid item xs={7}>
                            {offer?.picture?.name && (
                              <Link href={offer?.picture?.url} target="_blank">
                                <OfferImage
                                  src={offer?.picture?.url}
                                  alt={offer?.picture?.url}
                                ></OfferImage>
                              </Link>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} pt={4}>
                  <Comments
                    editorContent={content}
                    setEditorContent={setContent}
                    handleSaveComment={handleCommentSave}
                    comments={comments}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handleDeleteComment={handleCommentDelete}
                    handlePaginationChange={handlePaginationChange}
                    deleting={deleting}
                    loading={loading}
                  />
                </Grid>
              </Grid>
            </Grid>
            {snackbar.Snackbar}
          </Grid>
        </Grid>
      </Grid>
    </TabPageWrapper>
  );
};

export default OfferShow;
