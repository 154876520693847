import React, { ReactElement, useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  DialogContent,
  DialogActions,
  Dialog,
} from "@mui/material";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import Form from "./Form";
import Loader from "../../../Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InsuranceCreate } from "../../../../types/fe.interfaces";
import { useTranslation } from "react-i18next";
import { route } from "../../../../utils/url";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
import { FormMessageInItState } from "../../../../constants";
import useValidation from "../../../../hooks/useValidation";
import { handleSubmit, initialData } from "./utils";
import FileTree from "../../components/fileTree";
import Button from "../../../../ui/button/Button";
import useAccessControl from "../../../../hooks/useAccessControl";
import { getReleaseRoute } from "../../utils";
import PdfViewer from "../../../../ui/pdfViewer";
import { InboxLocationState } from "../../../inbox/types";
import { Inbox } from "../../../../types/fe/inbox";
import useFileManagement from "../../../../hooks/useFileManagement";
import { useFetch } from "../../../../hooks/useFetch";
import { InsuranceTopic } from "../../../../types/be/insurance";
import { getInsuranceTopicById } from "../../../../api/insuranceTopics";
import { AboutTopic } from "./styled";
import { formatTimestamp } from "../../../../utils/date";
import TabPageWrapper from "../../../../ui/tabPageWrapper";

const CreateInsurance = (): ReactElement => {
  const { state } = useLocation() as InboxLocationState;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, topicId } = useParams();
  const { isRole } = useAccessControl();
  const [insuranceData, setInsuranceData] =
    useState<InsuranceCreate>(initialData);
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const { contact_id, amount, cancellation_period, file_id } = useValidation();
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [fileName, setFileName] = useState("");
  const handleCloseDialog = (): void => setIsDialogVisible(false);
  const handleOpenDialog = (): void => setIsDialogVisible(true);
  const pdfContainerRef = useRef<HTMLObjectElement | null>(null);
  const [selectedFileId, setSelectedFileId] = useState<number>();
  /* istanbul ignore next */
  const inboxData = state ? (state.inboxData as Inbox) : null;
  const formRef = useRef<FormikProps<InsuranceCreate> | null>(null);

  const { data, isFoldersLoading, isFolderLoading, handleFolderClick } =
    useFileManagement();

  const {
    data: topicData,
    isLoading: topicDataLoading,
    run: runInsuranceTopic,
  } = useFetch<InsuranceTopic>();

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("insurances", Number(id)),
      name: t("insurance.title"),
    },
  ];

  useEffect(() => {
    formRef.current?.values.file_id &&
      setSelectedFileId(Number(formRef.current?.values.file_id));
  }, [formRef.current?.values.file_id]);

  useEffect(() => {
    runInsuranceTopic(getInsuranceTopicById(topicId!));
  }, []);

  const handleFileTreeClick = (file_id: string, file_name: string): void => {
    formRef?.current?.setFieldValue("file_id", parseInt(file_id));
    setFileName(file_name);
    setSelectedFileId(Number(file_id));
    handleCloseDialog();
  };

  if (topicDataLoading || isFoldersLoading) {
    return <Loader />;
  }

  return (
    <>
      <TabPageWrapper
        title={t("insurance.createTitle")}
        breadcrumbs={breadcrumbs}
        wrap={!id}
      >
        <Paper sx={{ padding: 6 }}>
          <Grid container justifyContent={"center"}>
            {selectedFileId && (
              <Grid item sm={6} xs={12} ref={pdfContainerRef}>
                <PdfViewer
                  file={{ fileId: selectedFileId }}
                  containerRef={pdfContainerRef}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} sx={{ pl: 6 }}>
              <AboutTopic>{`${topicData?.type?.name} ${t("insurance.for")} ${
                topicData?.property?.object_name
              }`}</AboutTopic>

              <AboutTopic mb={10}>{`${t("insurance.period")}: ${formatTimestamp(
                topicData?.from
              )} ${t("insurance.until")} ${formatTimestamp(
                topicData?.to
              )}`}</AboutTopic>

              {formMessage?.text ? (
                <FormAlert formMessage={formMessage} sx={{ mb: 4 }} />
              ) : (
                <></>
              )}
              <Formik
                initialValues={insuranceData}
                innerRef={formRef}
                onSubmit={handleSubmit({
                  insuranceId: +id!,
                  topicId: +topicId!,
                  setFormMessage,
                  isAssetManager: isRole("asset_manager"),
                  navigate,
                  inboxData,
                })}
                validationSchema={Yup.object().shape({
                  contact_id,
                  amount,
                  cancellation_period,
                  file_id,
                })}
              >
                {(props) => (
                  <Form
                    {...props}
                    propertyId={id}
                    handleOpenDialog={handleOpenDialog}
                    fileName={fileName}
                    setFileName={setFileName}
                    setFormInitState={setInsuranceData}
                    inboxData={inboxData}
                  />
                )}
              </Formik>

              <Dialog
                maxWidth={"lg"}
                open={isDialogVisible}
                onClose={handleCloseDialog}
                aria-labelledby="max-width-dialog-title"
              >
                <DialogTitle
                  title="property.selectAnyFileToLink"
                  onClose={handleCloseDialog}
                />
                <DialogContent dividers>
                  {data && (
                    <FileTree
                      data={data}
                      handleFolderClick={handleFolderClick}
                      isLoading={isFolderLoading}
                      handleFileClick={handleFileTreeClick}
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <Button title={t("cancel")} onClick={handleCloseDialog} />
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </Paper>
      </TabPageWrapper>
    </>
  );
};

export default CreateInsurance;
