export default {
  releaseAllSelectedContracts: "Release all selected contracts",
  reviewAllSelectedContracts: "Review all selected contracts",
  rejectAllSelectedContracts: "Reject all selected contracts",
  notSelectedContracts: "Please select contract",
  providerContracts: {
    providerContracts: "Provider contracts",
  },
  selectContractToDownload: "To download, please select contract",
};
