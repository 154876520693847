import React, { ReactElement, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { GeneralDocumentTypesContactAutocompleteProps } from "./types";
import { handleGeneralDocumentUpdate } from "./utils";
import { FormikValues } from "formik/dist/types";
import SelectOrCreateContactsAutocompleteField from "../../../contacts/autocomplete";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { PermissionsUpdate } from "../../../../constants";
import useAccessControl from "../../../../hooks/useAccessControl";
import { GeneralDocumentStatusCodes } from "../../../../types/be/generalDocument";

/* istanbul ignore next */
const GeneralDocumentContactAutocomplete = ({
  generalDocumentData,
  setSnackbarData,
  defaultPreloadedData,
}: GeneralDocumentTypesContactAutocompleteProps): ReactElement => {
  const { t } = useTranslation();
  const { can } = useAccessControl();
  const [outState, setOutState] = useState();

  useEffect(() => {
    outState && (generalDocumentData.contact = outState);
  }, [outState]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          contact_id: generalDocumentData.contact
            ? generalDocumentData.contact.id
            : "",
          contact: generalDocumentData.contact
            ? generalDocumentData.contact
            : "",
        }}
        onSubmit={handleGeneralDocumentUpdate({
          id: generalDocumentData.id,
          setSnackbarData,
          t,
        })}
      >
        {({
          handleSubmit,
          handleChange,
          isSubmitting,
          handleBlur,
          touched,
          errors,
          status,
          setFieldValue,
          values,
          submitForm,
        }: FormikValues): ReactElement => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={6} columns={12}>
              <Grid item xs={12} sm={12} mr={20}>
                <SelectOrCreateContactsAutocompleteField
                  {...{
                    errors,
                    values,
                    touched,
                    status,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    isSubmitting,
                    submitForm,
                  }}
                  size="small"
                  sx={{ mt: 4 }}
                  variant={"standard"}
                  label={null}
                  disableUnderline={true}
                  table={true}
                  displayOnlyFullName={true}
                  placeholder={t("createContact.searchContact")}
                  disabled={
                    generalDocumentData.status.code ===
                    GeneralDocumentStatusCodes.COMPLETED
                  }
                  editPermissions={can(PermissionsUpdate.GENERAL_DOCUMENT)}
                  disabledTooltipText={"generalDocuments.pleaseMoveToProgress"}
                  showContactFullTooltip={true}
                  addIconSx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 4,
                  }}
                  setOutState={setOutState}
                  defaultPreloadedData={defaultPreloadedData}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default GeneralDocumentContactAutocomplete;
