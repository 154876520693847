import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { route } from "../../../../../utils/url";
import ConfirmationModal from "../../../../../ui/confirmationModal";
import { postCreateModalProps } from "./types";
/* istanbul ignore next */
const PostCreateModal = ({
  createdRole,
  isOpen,
  onClose,
  setIsPostCreateModalOpen,
}: postCreateModalProps): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNoClick = (): void => {
    navigate(route("settings.roles"));
    onClose();
  };

  const handleYesClick = (): void => {
    if (createdRole)
      navigate(route("settings.roles.properties", createdRole.id));
    onClose();
  };

  return (
    <ConfirmationModal
      visible={isOpen}
      confirmText={t("settings.role.yes")}
      cancelText={t("settings.role.no")}
      titleText={t("settings.role.preCreateMessage")}
      setVisible={setIsPostCreateModalOpen}
      handleCancel={handleNoClick}
      handleConfirm={handleYesClick}
    />
  );
};

export default PostCreateModal;
