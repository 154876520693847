import { FormikValues } from "formik";
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";
import { editRequest } from "../../../../api/requests";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { TenantRequest } from "../../../../types/be/tenantRequest";
import { FormMessageInterface } from "../../../../types/form";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { route } from "../../../../utils/url";
import { CreateRequestInitialValues } from "./types";

export const MAX_FILES = 5;

export const prepareForm = (
  values: TenantRequest
): CreateRequestInitialValues => {
  return {
    title: values.title,
    description: values.description,
    file: values.files,
    tenant_id: values.tenant.id.toString(),
    property_area_id: "",
  };
};

export const initialValues: CreateRequestInitialValues = {
  title: "",
  description: "",
  file: [],
  tenant_id: "",
  property_area_id: "",
};

export const handleSubmit = (
  setFormMessage: Dispatch<SetStateAction<FormMessageInterface>>,
  navigate: NavigateFunction,
  propertyId: string,
  areaId: string,
  requestId: string,
  setShowSnackbar: Dispatch<SetStateAction<boolean>>
) => {
  return async (
    values: CreateRequestInitialValues,
    { setStatus }: FormikValues
  ): Promise<void> => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);

    values.file.forEach((item, index) => {
      item.file && formData.append(`file[${index}]`, item.file);
    });

    const response = await editRequest(requestId, formData);
    const result = await response?.json();
    if (response.status === 200) {
      setShowSnackbar(true);
      setStatus({
        success: true,
      });
      setFormMessage(FormMessageInItState);
      navigate(route("properties.areas.edit", propertyId, areaId));
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(result?.errors),
      });
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
    }
  };
};
