import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { route } from "../../../../utils/url";
import { useNavigate, useParams } from "react-router-dom";
import { Paper } from "../create/styled";
import { handleCreateMaintenanceSubmit } from "../create/utils";
import Form from "../create/form";
import useValidation from "../../../../hooks/useValidation";
import { useFetch } from "../../../../hooks/useFetch";
import { getMaintenanceDetails } from "../../../../api/maintenance";
import Loader from "../../../Loader";
import { Maintenance } from "../../../../types/be/maintenance";
import { prepareInitialValues } from "./utils";
import useSnackbar from "../../../../ui/snackbar1/useSnackbar";
import { getReleaseRoute } from "../../utils";
import TabPageWrapper from "../../../../ui/tabPageWrapper";

const EditMaintenance = (): ReactElement => {
  const { t } = useTranslation();
  const { id, maintenanceId } = useParams();
  const navigate = useNavigate();
  const { run, data, isLoading } = useFetch<Maintenance>();
  const { Snackbar, snackbar } = useSnackbar();
  const {
    description,
    contact_id,
    file,
    maintenance_type_id,
    contract_id,
    inspection,
  } = useValidation();
  const afterCreateMaintenance = (): void => {
    navigate(getReleaseRoute("maintenance", Number(id)));
  };

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("maintenance", Number(id), Number(maintenanceId)),
      name: t("property.maintenance.title"),
    },
  ];

  useEffect(() => {
    run(getMaintenanceDetails(maintenanceId!));
  }, [maintenanceId]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <TabPageWrapper title={maintenanceId!} breadcrumbs={breadcrumbs} wrap={!id}>
      <Grid container justifyContent="center" alignItems="center">
        <Paper>
          {data ? (
            <Formik
              initialValues={prepareInitialValues(data)}
              onSubmit={handleCreateMaintenanceSubmit({
                snackbar,
                t,
                afterCreateMaintenance,
                maintenanceId,
              })}
              enableReinitialize
              validationSchema={Yup.object().shape({
                description: description.max(
                  255,
                  t("property.maintenance.descriptionLengthError")
                ),
                contact_id,
                report_id: file,
                order_id: file,
                maintenance_type_id,
                contract_id,
                inspection,
              })}
            >
              {(props) => <Form {...props} isEdit />}
            </Formik>
          ) : null}
          {Snackbar}
        </Paper>
      </Grid>
    </TabPageWrapper>
  );
};

export default EditMaintenance;
