import { FormikValues } from "formik";
import { createInbox } from "../../api/inbox";
import { SOMETHING_WENT_WRONG_ERROR } from "../../constants";
import { HTTP_STATUS_CODES } from "../../types/server";
import { route } from "../../utils/url";
import {
  HandleInboxCreateProps,
  InboxCreateInitialValuesType,
  SnackbarMessage,
} from "./types";

export const inboxCreateInitialValues = {
  file: null,
};

export const handleInboxCreate = ({
  setSendCredentialsSnackbar,
  t,
  navigate,
  property_id,
}: HandleInboxCreateProps) => {
  return async (
    data: InboxCreateInitialValuesType,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const formData = new FormData();
    data?.file && formData.append("file", data?.file);
    property_id && formData.append("property_id", property_id);
    const response = await createInbox(formData);
    if (
      [HTTP_STATUS_CODES.CREATED, HTTP_STATUS_CODES.OK].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      navigate(
        property_id ? route("properties.inbox", property_id) : route("inbox")
      );
      setSendCredentialsSnackbar({
        visible: true,
        text: t("inbox.create.inboxCreatedSuccessfully"),
        color: "success",
      });
    } else {
      setSendCredentialsSnackbar({
        visible: true,
        text: t(SOMETHING_WENT_WRONG_ERROR),
        color: "error",
      });
      setSubmitting(false);
    }
  };
};

export const sendCredentialsSnackbarInitState: SnackbarMessage = {
  text: "",
  visible: false,
  color: "success",
};

export enum INBOX_STATUS {
  NEW = "new",
  COMPLETED = "completed",
}

export enum INBOX_TYPES {
  INBOX = "inbox",
  INVOICE = "invoice",
  REMINDER = "reminder",
  INSURANCE = "insurance",
  CONTRACT = "contract",
  ATTACHMENT = "attachment",
  DATEV_INVOICE = "datev_invoice",
  OFFER = "offer",
  GENERAL_DOCUMENT = "general_document",
}
