import { Status } from "./status";
import { Property } from "./property";
import { User } from "./user";
import { File } from "./file";
import { Contact } from "./contact";
import { Area } from "./area";
import { Invoice } from "../be.interfaces";
import { Comment } from "./comment";

export interface Inbox {
  id: number;
  is_automatically: boolean;
  type: InboxType | null;
  status: Status;
  managed: number;
  property: Pick<Property, "id" | "object_name"> | null;
  manager: Pick<User, "id" | "first_name" | "last_name">;
  contact: Contact;
  file: File;
  created_at: number;
  comments_count: number;
  area: Pick<Area, "name" | "id">;
  invoice?: Pick<Invoice, "amount" | "date" | "number" | "id">;
  invoice_number: string | null;
  invoice_date: string | null;
  invoice_amount: string | null;
  forwarded?: {
    id: string;
  };
  latest_comment: Comment | null;
}

export enum InboxStatusCodes {
  new = "new",
  completed = "completed",
}

export interface InboxType {
  id: number;
  code: string;
  name: string;
}
