export default {
  accountCaption: "Kontoüberschrift",
  account: "Konto",
  languageId: "Sprach-ID",
  accountLabelLong: "Kontoetikett lang",
  user: "Benutzer",
  date: "Datum",
  action: "Aktion",
  newDebitAccount: "Neues Debitorenkonto",
  error: {
    konto: "Konto ist erforderlich",
  },
  debitAccountCreatedSuccessfully: "Debitorenkonto erfolgreich erstellt",
  debitAccountDeleteAllMessage:
    "Sind Sie sicher, dass Sie alle Sollkonten löschen möchten?",
};
