import React, { useEffect, useCallback, ReactElement } from "react";
import useTable from "../../../ui/table/useTable";
import Table from "../../../ui/table/Table";
import { handleServerError } from "../../../utils/http";
import { itemsListHeadCells } from "./utils";
import { TableCell } from "../../../styled";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, Grid, Typography } from "@mui/material";
import { ListDialogProps } from "../types";
import { DialogContent, DialogTitleContainer } from "../styled";
import CloseButton from "../../../ui/dialog/CloseButton";
import { useTranslation } from "react-i18next";
import { Property } from "../../../types/be/property";
import { getProperties } from "../../../api/property";
import { EMPTY_DATA } from "../../../constants";
import { ObjectLink } from "../../properties/list/styled";
import { route } from "../../../utils/url";
import { Input, Search, SearchIconWrapper } from "../../../ui/table/styled";
import { Search as SearchIcon } from "react-feather";

/* istanbul ignore next */
const ApiDATEvModal = ({
  visible,
  toggleListModal,
}: ListDialogProps): ReactElement => {
  const { t } = useTranslation();

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
  } = useTable<Property>();

  const handleFetchProperties = useCallback(async () => {
    setIsLoading(true);
    const response = await getProperties(queryParams);

    if (response.status !== 200) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const resJson = await response.json();
      setTotal(resJson?.meta?.total);
      setData(resJson.data);
    }

    setIsLoading(false);
  }, [queryParams]);

  useEffect(() => {
    handleFetchProperties();
  }, [queryParams]);

  const renderRow = (row: Property): ReactElement => {
    return (
      <>
        <TableCell>
          <ObjectLink
            to={route("properties.invoices.apidatev", row?.id)}
            key={row?.id}
          >
            <Typography noWrap>{row?.object_name}</Typography>
          </ObjectLink>
        </TableCell>
        <TableCell>{row.konto || EMPTY_DATA}</TableCell>
      </>
    );
  };

  const TableToolbar = (
    <Grid container>
      <Grid
        item
        display={"flex"}
        justifyContent={"flex-end"}
        sx={{ width: "100%" }}
        flexWrap={"wrap"}
      >
        <Grid item>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input placeholder={t("search")} onChange={handleTableSearch} />
          </Search>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Dialog fullWidth open={visible} onClose={toggleListModal} maxWidth="xl">
      <DialogTitle>
        <DialogTitleContainer>
          <Typography
            variant="h6"
            component="div"
            justifyContent="space-around"
            width="100%"
          >
            {t("widgets.apiDATEvWidget")}
          </Typography>
          <CloseButton onClick={toggleListModal} />
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        <Table
          data={data}
          total={total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={itemsListHeadCells}
          renderRow={renderRow}
          tableToolbar={TableToolbar}
          tableDataMaxHeight={"50vh"}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ApiDATEvModal;
