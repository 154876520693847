import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Checkbox, Grid, Tooltip } from "@mui/material";
import MuiFileDownloadIcon from "@mui/icons-material/FileDownload";
import { IdPropType, User } from "../../../../types/models";
import { Status as ContractStatus } from "../../../../types/be/status";
import { Contract } from "../../../../types/be/contract";
import StatusButtons from "../../components/statusButtons";
import useTable from "../../../../ui/table/useTable";
import Table from "../../../../ui/table/Table";
import { historyModalInitState, itemsListHeadCells } from "./utils";
import { NavLink, useParams } from "react-router-dom";
import { route } from "../../../../utils/url";
import { useTranslation } from "react-i18next";
import {
  FILTER_TYPE,
  prepareQueryParams,
  RELEASE_LEVEL,
  replaceNullOrUndefinedByEmptyString,
  getFilteredHeadCellsByProperty,
  generalPropertyOption,
} from "../../../../utils/common";
import { formatTimestamp } from "../../../../utils/date";
import {
  DeleteIcon,
  EditIcon,
  ShowIcon,
  Input,
  Search,
  SearchIconWrapper,
} from "../../../../ui/table/styled";
import find from "lodash/find";
import snakeCase from "lodash/snakeCase";
import {
  deleteContract,
  getContracts,
  getContractStatuses,
  updateBulkContractsStatus,
  forwardRequiredReleaseToAdmin,
  releaseContract,
} from "../../../../api/contracts";
import useAccessControl from "../../../../hooks/useAccessControl";
import { TableCell } from "../../styled";
import useAppSelector from "../../../../hooks/useAppSelector";
import { useNavigate } from "react-router";
import { handleServerError } from "../../../../utils/http";
import { Search as SearchIcon } from "react-feather";
import useIsMounted from "../../../../hooks/useIsMounted";
import filter from "lodash/filter";
import { NumericFormat } from "react-number-format";
import {
  DECIMAL_SEPARATOR,
  DECIMAL_SCALE,
  Measurement_Units,
  THOUSAND_SEPARATOR,
  FormMessageInItState,
  COMMENTABLE,
  CURRENCIES,
  RELEASE_STATUS,
  EMPTY_DATA,
  FORWADED_STATUS,
  REQUEST_STATUS_IDS,
  PermissionsCreate,
  PermissionsUpdate,
  PermissionsDelete,
  UserRoles,
  HISTORY_STATUS,
  OLD_CONTRACT,
  FORWARDED_ROUTE,
} from "../../../../constants";
import { FormMessageInterface } from "../../../../types/form";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import CommentsPopup from "../../../../ui/commentsPopUp";
import { showCommentsModalInitState } from "../../../../ui/commentsPopUp/utils";
import { getProperties } from "../../../../api/property";
import {
  getItemsWithActiveReleasePermissions,
  getReleaseRoute,
  hasPermissionReleaseButtonTableCell,
  isAvailableReleaseButtonTableCell,
  RELASE_BUTTONS_AM,
  RELASE_BUTTONS_PM,
  RELEASE_BUTTONS_FR,
  RELEASE_BUTTONS_USER,
} from "../../utils";
import { CustomButton } from "../../invoices/list/styled";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  getCheckboxItemListHeadCell,
  providerInvoiceListFilterInit,
  selectAllInit,
} from "../../invoices/list/utils";
import { CheckedDict } from "../../invoices/list/types";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import last from "lodash/last";
import { downloadZipFile } from "../../../../api/files";
import DotMenu from "../../components/releaseDotMenu";
import Loader from "../../../Loader";
import { StyledButton } from "../../../../ui/button/styled";
import { ContractFilterProps, ProviderContractListFilter } from "../types";
import ExpandableComment from "../../../../ui/expandableComment/ExpandableComment";
import {
  DOCUMENT_RELEASE_LEVEL,
  DOCUMENT_RELEASE_MODULE,
} from "../../../../types/be/releaseFlow";
import HZScrollController from "../../../../ui/HZScrollController";
import RedirectProperty from "../../../../ui/redirectProperty";
import Dropdown from "../../../../ui/dropdown";
import { usePropertySelect } from "../../../../hooks/usePropertySelect";
import ContactShowDialog from "../../components/provider/contactShowDialog";
import ProviderTableCell from "../../components/provider/ProviderTableCell";
import ProviderContractList from "./providerContracts/list";
import useDocumentRelease from "../../components/documentRelease/useDocumentRelease";
import DocumentRelease from "../../components/documentRelease";
import HistoryPopup from "../../components/history/HistoryPopup";
import { HISTORY } from "../../components/history/types";
import HistoryList from "../../components/history/HistoryList";
import { DocumentReleaseHistoryIcon } from "../../components/history/styled";
import TabPageWrapper from "../../../../ui/tabPageWrapper";
import FileTableCell from "../../components/fileTableCell";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ContractList = ({
  useOnWidget = false,
}: {
  useOnWidget?: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  const { id, status } = useParams();
  const [statuses, setStatuses] = useState<ContractStatus[]>([]);
  const [activeStatusId, setActiveStatusId] = useState<number | null>(null);
  const [activeStatusCode, setActiveStatusCode] = useState<string | undefined>(
    undefined
  );
  const [showHistoryModal, setShowHistoryModal] = useState(
    historyModalInitState
  );
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const [isShowCommentsModal, setIsShowCommentsModal] = useState(
    showCommentsModalInitState
  );
  const [checkedDict, setCheckedDict] = useState<CheckedDict | null>(null);
  const [selectAll, setSelectAll] = useState(selectAllInit);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [loadingIds, setLoadingIds] = useState<number[]>([]);
  const [contactShowId, setContactShowId] = useState<string | null>(null);
  const [providerContractListFilter, setProviderContractListFilter] =
    useState<ProviderContractListFilter>(providerInvoiceListFilterInit);

  const navigate = useNavigate();
  const { can, isRole } = useAccessControl();

  const { optionalColumnIds, selectedOptionalColumnsIds } = useAppSelector(
    (state) => state.contract
  );

  const {
    configuration,
    isConfigurationLoading,
    handleReleaseDocument,
    handleForwardDocument,
    releasePermission,
    snackbar,
    loading: isDocumentReleaseLoading,
  } = useDocumentRelease(DOCUMENT_RELEASE_MODULE.CONTRACT);

  const { propertyId, property, isGeneral, onPropertySelect } =
    usePropertySelect(id);

  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleDeleteLastPageData,
  } = useTable<User>({ loading: true });

  const resetCheckedDict = useCallback(
    (value = false): void => {
      /* istanbul ignore next */
      if (!data) return;
      const auxCheckedDict: CheckedDict = {};
      data.forEach((el: Contract) => {
        auxCheckedDict[el.id] = value;
      });
      setCheckedDict(auxCheckedDict);
    },
    [data]
  );

  const resetSelectAll = (): void => setSelectAll(selectAllInit);

  const userHasFinalReleasePermissions = useMemo(() => {
    return hasPermissionReleaseButtonTableCell(
      RELEASE_BUTTONS_FR,
      configuration,
      isRole
    );
  }, [configuration, isRole]);

  useEffect(() => {
    configuration && getStatuses();
  }, [configuration]);

  useEffect(() => {
    /* istanbul ignore next */
    statuses.length && configuration && setActiveStatusByStatus();
  }, [status, statuses]);

  useEffect(() => {
    resetCheckedDict();
    resetSelectAll();
  }, [resetCheckedDict]);

  /* istanbul ignore next */
  const setActiveStatusByStatus = (): void => {
    if (status === "old-contracts") {
      setActiveStatusId(OLD_CONTRACT.id);
    } else if (status === "history") {
      setActiveStatusId(HISTORY_STATUS.id);
    } else {
      const activeStatus = find(statuses, (o) => o.code === snakeCase(status));

      setActiveStatusId(activeStatus?.id || FORWADED_STATUS.id);
      setActiveStatusCode(activeStatus?.code || FORWADED_STATUS.code);
    }
  };

  const getStatuses = useCallback(async () => {
    /* istanbul ignore next */
    const oldContractStatus = isRole(UserRoles.ADMIN) ? [OLD_CONTRACT] : [];

    const historyStatus = isRole(UserRoles.ADMIN) ? [HISTORY_STATUS] : [];

    setStatuses([
      FORWADED_STATUS,
      ...(await (await getContractStatuses()).json()).data,
      ...oldContractStatus,
      ...historyStatus,
    ]);
  }, []);

  const tableHeaderTitles = useMemo(() => {
    const checkboxItemListHeadCell = getCheckboxItemListHeadCell(
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={selectAll}
        data-testid={"select-all"}
        onChange={({
          target: { checked },
        }: React.ChangeEvent<HTMLInputElement>) => {
          setSelectAll(checked);
          resetCheckedDict(checked);
        }}
      />
    );

    let filteredItems = itemsListHeadCells;
    /* istanbul ignore next */
    if (activeStatusId === OLD_CONTRACT.id) {
      filteredItems = filter(
        filteredItems,
        (o) =>
          ![
            RELEASE_BUTTONS_FR,
            RELASE_BUTTONS_AM,
            RELASE_BUTTONS_PM,
            RELEASE_BUTTONS_USER,
          ].includes(o.id)
      );
    }
    filteredItems = filter(
      filteredItems,
      (o) =>
        selectedOptionalColumnsIds.includes(o.id) ||
        !optionalColumnIds.includes(o.id)
    );

    filteredItems = getFilteredHeadCellsByProperty(
      filteredItems,
      "property_name",
      id
    );

    return [
      checkboxItemListHeadCell,
      ...getItemsWithActiveReleasePermissions(filteredItems, configuration),
    ];
  }, [selectedOptionalColumnsIds, resetCheckedDict, selectAll]);

  /* istanbul ignore next */
  const fetchContracts = useCallback(
    async (loading = true) => {
      if (activeStatusId === HISTORY_STATUS.id) return;
      setIsLoading(loading);
      const params: ContractFilterProps = {
        fr_status_id: activeStatusId,
      };

      if (isGeneral === FILTER_TYPE.GENERAL || propertyId || id) {
        params.property_id = propertyId || id || "";
      }

      if (activeStatusId === -1) {
        params.is_was_forwarded = 1;
        params.fr_status_id = REQUEST_STATUS_IDS.NEW;
      } else if (activeStatusId === -3) {
        params.is_old_contract = 1;
        delete params.fr_status_id;
      } else {
        params.fr_status_id = activeStatusId;
      }

      const response = await getContracts(
        prepareQueryParams(
          queryParams,
          replaceNullOrUndefinedByEmptyString(params),
          true
        )
      );
      if (response.status !== 200) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        const resJson = await response.json();
        setTotal(resJson.meta.total);
        setData(resJson.data);
        setIsLoading(false);
      }
    },
    [
      propertyId,
      activeStatusId,
      queryParams,
      setIsLoading,
      setTotal,
      setData,
      setIsLoading,
      status,
      isGeneral,
    ]
  );

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteContract(rowToDelete);
    handleDeleteLastPageData(fetchContracts);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteContract,
    fetchContracts,
    handleConfirmToRemoveModalClose,
  ]);

  const handleEditClick = useCallback(
    (contract: Contract): void =>
      navigate(
        getReleaseRoute(
          "contracts.edit",
          id ? Number(id) : undefined,
          contract.id
        )
      ),
    []
  );

  const handleShowClick = useCallback(
    (contract: Contract): void =>
      navigate(
        getReleaseRoute(
          "contracts.show",
          id ? Number(id) : undefined,
          contract.id
        )
      ),
    []
  );
  useEffect(() => {
    if (isMounted() && statuses.length && activeStatusId !== null) {
      fetchContracts();
    }
  }, [queryParams, activeStatusId, propertyId, isGeneral]);

  const handleDownload = async (): Promise<void> => {
    const listContractsParamValues = getListContractsParamValues();
    setIsArchiveLoading(true);
    const response = await downloadZipFile(listContractsParamValues);
    if (
      ![HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      const { errorMessage } = handleServerError(response);
      setIsArchiveLoading(false);
      return setError(errorMessage);
    }
    const blob = await response.blob();
    const href = window.URL.createObjectURL(blob);
    setIsArchiveLoading(false);
    const link = document.createElement("a");
    link.href = href;
    document.body.appendChild(link);
    link.click();
    link.href = "";
    resetCheckedDict();
    resetSelectAll();
  };

  const isMounted = useIsMounted();

  /* istanbul ignore next */
  const handleReleaseButtonClick =
    (onSuccess: () => void) =>
    async (
      contract_id: number | number[],
      status: string,
      level: RELEASE_LEVEL,
      comment?: string
    ): Promise<void> => {
      const statusObj = find(statuses, (o) => o.code === status);
      const beforeLoadingIds = [...loadingIds];
      if (Array.isArray(contract_id)) {
        setLoadingIds([...loadingIds, ...contract_id]);
      } else {
        setLoadingIds([...loadingIds, contract_id]);
      }
      let response;
      /* istanbul ignore next */
      if (status === RELEASE_STATUS.FORWARD_TO_ADMIN) {
        response = await forwardRequiredReleaseToAdmin(contract_id, comment);
      } else {
        const params = prepareQueryParams("", {
          status_code: status,
        });
        response =
          statusObj &&
          (Array.isArray(contract_id)
            ? await updateBulkContractsStatus(contract_id, statusObj.id)
            : await releaseContract(String(contract_id), level, params));
      }
      if (response && response.status !== 200) {
        const { errorMessage } = handleServerError(response);
        setFormMessage({ text: errorMessage, type: "error" });
      } else {
        setFormMessage(FormMessageInItState);
        onSuccess();
      }
      setLoadingIds(beforeLoadingIds);
    };

  /* istanbul ignore next */
  const onHandleReleaseButtonClickSuccess = async (): Promise<void> => {
    await fetchContracts();
    resetCheckedDict();
    resetSelectAll();
    handleDeleteLastPageData(fetchContracts);
  };
  /* istanbul ignore next */
  const handleStatusChange = (statusId: number, statusCode: string): void => {
    setActiveStatusId(statusId);
    setActiveStatusCode(statusCode);
  };

  const selected =
    checkedDict &&
    Object.keys(checkedDict)
      .filter((el) => checkedDict[el])
      .map((el) => Number(el));

  const TableToolbar = (
    <Grid
      container
      mt={7}
      mb={2}
      justifyContent={"space-between"}
      alignItems={"center"}
      className="table-toolbar"
    >
      {formMessage.text && (
        <Grid item xs={12} sx={{ mb: 2 }}>
          <FormAlert formMessage={formMessage} />
        </Grid>
      )}
      <Grid item xs={12} pb={5.5} className="status-button-container">
        <Grid container alignItems="center">
          <Grid item>
            <StatusButtons
              subPath="contracts"
              useSpecialLabels={true}
              statuses={statuses}
              navigateOnChange={!useOnWidget}
              activeStatusId={activeStatusId}
              handleStatusChange={handleStatusChange}
              tabPosition="flex-start"
              defaultStatus={FORWARDED_ROUTE}
            />
          </Grid>
          <Grid item xs />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          {/* istanbul ignore next */}
          <Grid xs={6} sx={{ pt: 2 }} display="flex" item gap={2}>
            {!useOnWidget &&
              can(PermissionsCreate.CONTRACT) &&
              activeStatusCode === RELEASE_STATUS.NEW && (
                <Grid item>
                  <StyledButton
                    data-testid={"dataItems-link"}
                    component={NavLink}
                    to={getReleaseRoute("contracts.create", Number(id))}
                    size="small"
                    color="success"
                    variant="contained"
                  >
                    {t("contracts.uploadContract")}
                  </StyledButton>
                </Grid>
              )}
            {!id ? (
              <Grid item lg={4} xs={12} md={4} sm={6}>
                <Dropdown
                  id="property-autocomplete"
                  name="property_id"
                  placeholder={t("documentRelease.invoice.searchProperty")}
                  getOptions={getProperties}
                  onSelectOption={onPropertySelect}
                  optionLabel="object_name"
                  size="small"
                  defaultValue={property}
                  defaultOption={[generalPropertyOption]}
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs />
          <Grid item sx={{ display: "flex" }} gap={2}>
            <HZScrollController />
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t("search")} onChange={handleTableSearch} />
            </Search>
          </Grid>
          {userHasFinalReleasePermissions && (
            <Grid item>
              <DotMenu
                checkedDict={checkedDict}
                handleReleaseButtonClick={handleReleaseButtonClick(
                  onHandleReleaseButtonClickSuccess
                )}
                status={activeStatusCode}
                tab={"contract"}
              />
            </Grid>
          )}
          <Grid item pl={2}>
            <Tooltip
              title={
                selected?.length === 0
                  ? t(`documentRelease.contract.selectContractToDownload`)
                  : t("tenant.download")
              }
            >
              <span>
                <CustomButton
                  onClick={handleDownload}
                  title={""}
                  type="submit"
                  testId="download-contracts"
                  disabled={selected?.length === 0 || isArchiveLoading}
                  isLoading={isArchiveLoading}
                  startIcon={
                    !isArchiveLoading ? <MuiFileDownloadIcon /> : undefined
                  }
                />
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const handleOpenComments = (row: Contract): void => {
    setIsShowCommentsModal({ isVisible: true, rowId: row?.id });
  };

  const handleCloseCommentsModal = (): void => {
    setIsShowCommentsModal(showCommentsModalInitState);
    fetchContracts(false);
  };

  const handleItemCheckbox =
    (id: number) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setCheckedDict((state) => ({ ...state, [id]: event.target.checked }));
      resetSelectAll();
    };

  const getListContractsParamValues = useCallback((): string[] => {
    /* istanbul ignore next */
    if (!checkedDict) return [];
    const contractIdsToDownload = Object.keys(checkedDict)
      .filter((el) => checkedDict[el])
      .map((el) => Number(el));

    return data
      ?.filter(
        ({ id, file }: Contract) =>
          contractIdsToDownload.includes(id) && file?.url
      )
      .map(({ file: { url } }: Contract) => last(url.split("/")))
      .filter(
        (el: string, index: number, self: string[]) =>
          self.indexOf(el) === index
      );
  }, [checkedDict, data]);
  /* istanbul ignore next */
  const handleHistoryCloseModal = (): void => {
    setShowHistoryModal(historyModalInitState);
  };
  /* istanbul ignore next */
  const handleHistoryModal = ({ id: historyId }: IdPropType): void => {
    setShowHistoryModal({ isVisible: true, historyId });
  };

  /* istanbul ignore next */
  const renderRow = (row: Contract): ReactElement => (
    <>
      <TableCell padding="none">
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          onChange={handleItemCheckbox(row.id)}
          data-testid={`checkbox-${row.id}`}
          checked={checkedDict?.[row.id]}
        />
      </TableCell>
      {!id ? (
        <TableCell align="left" mw="100px">
          <RedirectProperty property={row?.property} />
        </TableCell>
      ) : null}
      <FileTableCell file={row.file} />
      <ProviderTableCell
        contact={row.contact}
        onContactShow={() => setContactShowId(String(row?.contact?.id))}
        onDocumentShow={() => {
          const contact_id = row?.contact?.id ?? null;
          setProviderContractListFilter({
            contact_id,
          });
        }}
        documentActionLabel="contracts.contracts"
      />
      <TableCell align="right" mw={"150px"}>
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          decimalScale={DECIMAL_SCALE}
          fixedDecimalScale
          displayType={"text"}
          value={row.amount}
        />{" "}
        {row.amount !== null
          ? row.currency_code === CURRENCIES[1]
            ? Measurement_Units.DOLLAR
            : Measurement_Units.EURO
          : EMPTY_DATA}
      </TableCell>

      <TableCell align="center" mw="130px">
        {row?.start_date ? formatTimestamp(row?.start_date) : EMPTY_DATA}
      </TableCell>
      <TableCell align="center" mw="130px">
        {row?.end_date ? formatTimestamp(row?.end_date) : EMPTY_DATA}
      </TableCell>
      <TableCell align="center" mw="130px">
        {row?.signing_date ? formatTimestamp(row?.signing_date) : EMPTY_DATA}
      </TableCell>
      <TableCell align="center" mw="130px">
        {row?.cancellation_date
          ? formatTimestamp(row?.cancellation_date)
          : EMPTY_DATA}
      </TableCell>
      <TableCell align="center" mw="130px">
        {row?.cancelled_at ? formatTimestamp(row?.cancelled_at) : EMPTY_DATA}
      </TableCell>
      <TableCell align="left">
        <ExpandableComment
          seeAllComment={() => handleOpenComments(row)}
          commentData={row?.latest_comment}
        ></ExpandableComment>
      </TableCell>
      <TableCell align="left" mw="130px">
        {row?.created_by
          ? `${row?.created_by?.first_name} ${row?.created_by?.last_name}`
          : EMPTY_DATA}
      </TableCell>
      <TableCell align="center">
        {row?.uploaded_at ? formatTimestamp(row.uploaded_at) : EMPTY_DATA}
      </TableCell>
      <TableCell align="left" mw="100px">
        {row?.category?.name || EMPTY_DATA}
      </TableCell>
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELEASE_BUTTONS_FR,
          configuration
        ) && activeStatusId !== OLD_CONTRACT.id ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.fr}
              releaseStatus={row?.fr_status}
              forwardedAt={row?.fr_forwarded_at}
              forwardedBy={row?.fr_forwarded_by}
              overdue={row?.fr_overdue}
              afterSuccess={fetchContracts}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              level={RELEASE_LEVEL.FIRST_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
                  row?.assigned_user
                )
              }
              hasReleasePermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL]
              }
              relation={DOCUMENT_RELEASE_MODULE.CONTRACT}
              loading={isDocumentReleaseLoading}
            />
          </TableCell>
        ) : null
      }
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELASE_BUTTONS_AM,
          configuration
        ) && activeStatusId !== OLD_CONTRACT.id ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.am}
              releaseStatus={row?.am_status}
              forwardedAt={row?.am_forwarded_at}
              forwardedBy={row?.am_forwarded_by}
              overdue={row?.am_overdue}
              afterSuccess={fetchContracts}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              level={RELEASE_LEVEL.SECOND_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
                  row?.assigned_user
                )
              }
              hasReleasePermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL]
              }
              relation={DOCUMENT_RELEASE_MODULE.CONTRACT}
              loading={isDocumentReleaseLoading}
            />
          </TableCell>
        ) : null
      }
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELASE_BUTTONS_PM,
          configuration
        ) && activeStatusId !== OLD_CONTRACT.id ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.pm}
              releaseStatus={row?.pm_status}
              forwardedAt={row?.pm_forwarded_at}
              forwardedBy={row?.pm_forwarded_by}
              overdue={row?.pm_overdue}
              afterSuccess={fetchContracts}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              level={RELEASE_LEVEL.THIRD_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
                  row?.assigned_user
                )
              }
              hasReleasePermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL]
              }
              relation={DOCUMENT_RELEASE_MODULE.CONTRACT}
              loading={isDocumentReleaseLoading}
            />
          </TableCell>
        ) : null
      }
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELEASE_BUTTONS_USER,
          configuration
        ) && activeStatusId !== OLD_CONTRACT.id ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.au}
              releaseStatus={row?.au_status}
              forwardedAt={row?.au_forwarded_at}
              forwardedBy={row?.au_forwarded_by}
              overdue={row?.au_overdue}
              afterSuccess={fetchContracts}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              level={RELEASE_LEVEL.FOURTH_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL]
              }
              hasReleasePermission={releasePermission[
                DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
              ](row?.assigned_user)}
              relation={DOCUMENT_RELEASE_MODULE.CONTRACT}
              assignedUser={row?.assigned_user}
              loading={isDocumentReleaseLoading}
              hasAssignUserPermission={can(PermissionsUpdate.CONTRACT)}
            />
          </TableCell>
        ) : null
      }

      {selectedOptionalColumnsIds.includes("end_date") && (
        /* istanbul ignore next */
        <TableCell align="right" mw="130px">
          {formatTimestamp(row.end_date)}
        </TableCell>
      )}

      {selectedOptionalColumnsIds.includes("property_name") && (
        /* istanbul ignore next */
        <TableCell align="left" mw="100px">
          {row.property ? row.property.object_name : "---"}
        </TableCell>
      )}
      <TableCell
        align="right"
        mw="100px"
        onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
          handleCellClick(e)
        }
        style={{ whiteSpace: "nowrap" }}
      >
        {isRole(UserRoles.ADMIN) && (
          <DocumentReleaseHistoryIcon
            role={"showHistoryRole"}
            onClick={() => {
              handleHistoryModal(row);
            }}
          />
        )}
        <ShowIcon
          role={"showIconRole"}
          onClick={(): void => handleShowClick(row)}
          size={18}
        />
        {can(PermissionsUpdate.CONTRACT) && (
          <EditIcon
            role={"editIconRole"}
            onClick={(): void => handleEditClick(row)}
            size={18}
          />
        )}
        {can(PermissionsDelete.CONTRACT) && (
          <DeleteIcon
            onClick={(): void => openConfirmToRemoveModal(row)}
            size={20}
            role={"deleteIconRole"}
          />
        )}
      </TableCell>
    </>
  );

  const breadcrumbs = useOnWidget
    ? []
    : [
        {
          to: route("root"),
          name: "Dashboard",
        },
      ];

  const pageTitle = useOnWidget ? "" : t("contracts.contracts");

  if (isConfigurationLoading || !statuses.length) {
    return <Loader />;
  }

  return (
    <TabPageWrapper
      breadcrumbs={breadcrumbs}
      title={pageTitle}
      wrap={!useOnWidget && !id}
    >
      <>
        {
          /* istanbul ignore next */ activeStatusId === HISTORY_STATUS.id ? (
            <HistoryList
              activeStatusId={activeStatusId}
              handleStatusChange={handleStatusChange}
              statuses={statuses}
              useOnWidget={useOnWidget}
              documentRelease={HISTORY.CONTRACT}
              noDataMessage="table.noContractHistoryIsAvailable"
            />
          ) : (
            <Table
              data={data}
              total={total}
              currentPage={currentPage}
              order={order}
              orderBy={orderBy}
              error={error}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangePage}
              onRowRemove={handleRowRemove}
              onSortChange={handleSortChange}
              onSelectAllClick={handleSelectAllClick}
              onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
              rowsPerPage={rowsPerPage}
              isLoading={isLoading || isConfigurationLoading}
              isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
              listHeadCells={tableHeaderTitles}
              renderRow={renderRow}
              tableToolbar={TableToolbar}
              noDataIsAvailablePlaceholder={t("table.noContractsAreAvailable")}
              tableDataMaxHeight={useOnWidget ? "50vh" : "75vh"}
              refId="hzScroll"
            />
          )
        }

        {isShowCommentsModal.isVisible && (
          <CommentsPopup
            handleCloseDialog={handleCloseCommentsModal}
            id={String(isShowCommentsModal?.rowId)}
            relation={COMMENTABLE.CONTRACT}
          />
        )}

        {
          /* istanbul ignore next */ contactShowId && (
            <ContactShowDialog
              id={contactShowId}
              onClose={() => setContactShowId(null)}
              open={true}
            />
          )
        }
        {
          /* istanbul ignore next */ providerContractListFilter.contact_id && (
            <ProviderContractList
              onClose={() =>
                setProviderContractListFilter({
                  contact_id: null,
                })
              }
              parentFilter={providerContractListFilter}
              configuration={configuration}
              statuses={statuses}
              handleForwardDocument={handleForwardDocument}
              handleReleaseDocument={handleReleaseDocument}
              releasePermission={releasePermission}
              isDocumentReleaseLoading={isDocumentReleaseLoading}
              handleHistoryModal={handleHistoryModal}
            />
          )
        }
        {
          /* istanbul ignore next */ showHistoryModal.isVisible && (
            <HistoryPopup
              handleCloseDialog={handleHistoryCloseModal}
              historyId={String(showHistoryModal?.historyId)}
              documentRelease={HISTORY.CONTRACT}
            />
          )
        }
        {snackbar.Snackbar}
      </>
    </TabPageWrapper>
  );
};

export default ContractList;
