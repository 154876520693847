import { Paper, Typography } from "@mui/material";
import styled from "styled-components/macro";

export const Tenant = styled(Typography)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const Manager = styled(Typography)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
/* istanbul ignore next */
export const FormWrapper = styled(Paper)`
  padding: ${(props): string => props.theme.spacing(6)};
  ${(props): string => props.theme.breakpoints.up("lg")} {
    padding: ${(props): string => props.theme.spacing(10)};
  }
  ${(props): string => props.theme.breakpoints.up("xl")} {
    padding: ${(props): string => props.theme.spacing(10)};
  }
  ${(props): string => props.theme.breakpoints.down("xs")} {
    padding: ${(props): string => props.theme.spacing(10)};
  }
`;
