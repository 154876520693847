import styled from "styled-components/macro";
import MUIDialogContent from "@mui/material/DialogContent";

export const DialogContent = styled(MUIDialogContent)`
  & > .MuiBox-root {
    max-height: 80vh;
    width: 100%;
    > .MuiPaper-root {
      padding: 0;
    }
    .MuiTableContainer-root {
      overflow: auto;
      height: calc(80vh - ${(props): string => props.theme.spacing(49)});
    }
    .MuiTable-root {
      min-width: unset;
    }
  }
`;
