import React, { ReactElement, useContext } from "react";
import { Box, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ProlongIcon,
  ShowIcon,
  DeleteIcon,
  EditIcon,
} from "../../../../../../../../ui/table/styled";
import {
  formatTimestamp,
  isDateFuture,
} from "../../../../../../../../utils/date";
import { TableCell } from "../../../../../../styled";
import { TenantHistoryTableRowProps } from "../types";
import { NumericFormat } from "react-number-format";
import {
  AreaTypeCodes,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  Measurement_Units,
  THOUSAND_SEPARATOR,
} from "../../../../../../../../constants";
import SelectOrCreateContactTableCell from "./contact/cell";
import { AreaEditContext } from "../../../../AreaEditContext";
import { TableRow } from "../styled";
import { getTenantStatus } from "../utils";
/*istanbul ignore next*/
const TenantHistoryTableRow = ({
  row,
  handleExtendClick,
  refetchData,
  openConfirmToRemoveModal,
  handleShowUpdateFutureTenantModal,
}: TenantHistoryTableRowProps): ReactElement => {
  const areaEditContext = useContext(AreaEditContext);
  const { t } = useTranslation();

  if (
    !areaEditContext ||
    !areaEditContext.area ||
    !areaEditContext.openShowTenantHistoryDetailsModal ||
    !areaEditContext.fetchArea
  )
    return <></>;

  const {
    area,
    isShowTenantHistoryDetailsButtonDisabled,
    openShowTenantHistoryDetailsModal,
  } = areaEditContext;

  const handleShowDetails = (row: any): void => {
    if (!isShowTenantHistoryDetailsButtonDisabled)
      openShowTenantHistoryDetailsModal(row);
  };

  return (
    <TableRow $tenantStatus={getTenantStatus(row)}>
      {row.rent_start && isDateFuture(row.rent_start) ? (
        <SelectOrCreateContactTableCell
          tenantHistory={row}
          refetchData={refetchData}
        />
      ) : (
        <TableCell mw="120px" align="left">
          {row.contact?.full_name}
        </TableCell>
      )}
      <TableCell mw="100px" align="left">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {formatTimestamp(Number(row.rent_start))}
        </Box>
      </TableCell>
      <TableCell mw="100px" align="left">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {row.rent_end ? formatTimestamp(Number(row.rent_end)) : ""}
        </Box>
      </TableCell>
      <TableCell mw="120px" align="left">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          fixedDecimalScale
          value={row.net_rent_per_month}
        />{" "}
        {row.net_rent_per_month !== null && Measurement_Units.EURO}
      </TableCell>
      <TableCell mw="120px" align="left">
        {area.type?.code === AreaTypeCodes.COMMERCIAL ? (
          <>
            <NumericFormat
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              displayType={"text"}
              fixedDecimalScale
              value={row.tax_in_eur}
            />{" "}
            {row.tax_in_eur !== null ? Measurement_Units.EURO : EMPTY_DATA}
          </>
        ) : (
          EMPTY_DATA
        )}
      </TableCell>
      <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
        {row.is_extendable && (
          <Tooltip title={t("tenant.history.extend")} placement="top">
            <ProlongIcon
              role={"prolongIconRole"}
              size={18}
              onClick={() => handleExtendClick(row)}
            />
          </Tooltip>
        )}
        {isDateFuture(row?.rent_start) && (
          <EditIcon
            role={"editIconRole"}
            onClick={(): void => handleShowUpdateFutureTenantModal(row)}
            size={18}
          />
        )}
        <Tooltip title={t("tenant.history.show")} placement="top">
          <ShowIcon
            role={"showIconRole"}
            size={18}
            onClick={() => handleShowDetails(row)}
          />
        </Tooltip>
        {isDateFuture(row.rent_start) && (
          <DeleteIcon
            role={"deleteIconRole"}
            size={18}
            onClick={() => {
              openConfirmToRemoveModal(row);
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default TenantHistoryTableRow;
