import styled from "styled-components";
import { TableCell as MUITableCell } from "@mui/material";

export const TableCell = styled(MUITableCell).attrs(
  (props: { mw?: string }) => ({
    mw: props.mw || "auto",
  })
)`
  min-width: ${(props): string => {
    return props.mw;
  }};
`;

export const TextEllipsis = styled.div`
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;
