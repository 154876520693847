import { FormikValues } from "formik/dist/types";
import { mapBackendValidationErrors } from "../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { createInventoryItem } from "../../../api/companyOwnership";
import { InventoryItem } from "../../../types/fe/companyOwnership";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { FormSubmitHandlerProps } from "../../../types/form";

export const createInventoryItemInitState = {
  type_id: "",
  brand: "",
  number: "",
  department: "",
};

export const touchedInitState = {
  user_id: false,
  type_id: false,
  brand: false,
  number: false,
  received_date: false,
  department: false,
};

export const handleCreateInventoryItemFormSubmit = ({
  setFormMessage,
  afterSubmit,
}: FormSubmitHandlerProps) => {
  return async (
    values: InventoryItem,
    { setStatus, setSubmitting, setValues, setTouched }: FormikValues
  ): Promise<void> => {
    const response = await createInventoryItem(values);
    const json = await response.json();
    if (response.status === HTTP_STATUS_CODES.CREATED) {
      setStatus({
        success: true,
        errors: {},
      });
      setValues(createInventoryItemInitState);
      setTouched(touchedInitState);
      afterSubmit && afterSubmit();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
      setSubmitting(false);
    }
  };
};
