/* istanbul ignore file */

import React, { ReactElement, useState } from "react";
import { Formik } from "formik";
import Form from "./Form";
import { FormWrapper } from "./styled";
import { FormMessageInItState } from "../../../../constants";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import { handleUpdatePropertyFormSubmit } from "../utils";
import * as Yup from "yup";
import useValidation from "../../../../hooks/useValidation";
import { Property } from "../../../../types/be/property";
import { mapPropertyToUpdatePropertyGeneralData } from "./utils";

const General = ({
  property,
  getPropertyData,
}: {
  property: Property;
  getPropertyData: () => void;
}): ReactElement => {
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const {
    location,
    property_type_id,
    purchase_price,
    object_name,
    notes,
    optional_percentage,
  } = useValidation();

  const initialValues = mapPropertyToUpdatePropertyGeneralData(property);

  return (
    <FormWrapper>
      {formMessage.text && (
        <FormAlert formMessage={formMessage} sx={{ mb: 2.5 }} />
      )}
      {property && (
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            location,
            property_type_id,
            purchase_price,
            object_name,
            notes,
            taxes: optional_percentage,
            non_circulating_property_management: optional_percentage,
            operating_costs_non_reversible: optional_percentage,
            net_rent_target: optional_percentage,
          })}
          onSubmit={handleUpdatePropertyFormSubmit({
            setFormMessage,
            initialValues,
          })}
          enableReinitialize
        >
          {(props): ReactElement => (
            <Form
              {...{
                ...props,
                ...mapPropertyToUpdatePropertyGeneralData(property),
                getPropertyData,
              }}
            />
          )}
        </Formik>
      )}
    </FormWrapper>
  );
};

export default General;
