import { Dialog, DialogContent } from "@mui/material";
import { Formik } from "formik";
import React, { ReactElement, useState } from "react";
import RentalContractForm from "../../../rentedForm/Form";
import DialogTitle from "../../../../../../../../ui/dialog/DialogTitle";
import {
  handleUpdateFutureTenant,
  prepareFormForUpdateFutureTenant,
} from "../../../../utils";
import { FutureTenantDialogProps, SnackbarMessage } from "../types";
import { FormMessageInterface } from "../../../../../../../../types/form";
import { FormMessageInItState } from "../../../../../../../../constants";
import { sendCredentialsSnackbarInit } from "../utils";
/*istanbul ignore next*/
const UpdateFutureDialog = ({
  initialValues,
  uploadFutureModal,
  handleCloseModal,
  isFutureTenant,
  submitCallback,
}: FutureTenantDialogProps): ReactElement => {
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const [isEditInfo, setIsEditInfo] = useState(Boolean(isFutureTenant));
  const [isEditTenant, setIsEditTenant] = useState(Boolean(isFutureTenant));
  useState<SnackbarMessage>(sendCredentialsSnackbarInit);

  const [isUploadingRentalContract, setIsUploadingRentalContract] =
    useState(false);

  return (
    <>
      <Dialog
        open={uploadFutureModal}
        aria-labelledby="max-width-dialog-title"
        data-testid="update-tenant-modal"
        onClose={handleCloseModal}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          title="property.area.updateTenant"
          onClose={handleCloseModal}
        />
        <DialogContent sx={{ padding: "0px 24px 0px 24px" }}>
          <Formik
            enableReinitialize
            initialValues={prepareFormForUpdateFutureTenant(initialValues)}
            onSubmit={handleUpdateFutureTenant({
              initialValues,
              onSuccessCallback: submitCallback,
              setFormMessage,
            })}
          >
            {(props): React.ReactElement => (
              <RentalContractForm
                formMessage={formMessage}
                isEditFutureTenant={true}
                isFutureTenant={true}
                isEditInfo={isEditInfo}
                setIsEditInfo={setIsEditInfo}
                isEditTenant={isEditTenant}
                setIsEditTenant={setIsEditTenant}
                isUploadingRentalContract={isUploadingRentalContract}
                setIsUploadingRentalContract={setIsUploadingRentalContract}
                submitCallback={submitCallback}
                setFormMessage={setFormMessage}
                {...props}
              />
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateFutureDialog;
