/* istanbul ignore next */
import styled from "styled-components/macro";
import { Grid, Stack } from "@mui/material";
export const StyledTabContent = styled.div`
  padding: ${(props): string => props.theme.spacing(0)};
  background: transparent;
  width: 100%;
  ${(props): string => props.theme.breakpoints.up("md")} {
    padding: ${(props): string => props.theme.spacing(0)};
    padding-top: ${(props): string => props.theme.spacing(0)};
  }
`;

export const StyledGridTabs = styled(Grid)`
  margin-top: ${(props): string => props.theme.spacing(0)};
  background: transparent;
`;

export const StyledGridTabsContent = styled(Grid)`
  padding: ${(props): string => props.theme.spacing(0)};
`;

export const StyledStack = styled(Stack)`
  &.general-information-wrap .MuiPaper-root {
    padding: ${(props): string => props.theme.spacing(3)};
  }
`;

export const LoadingBox = styled.div`
  position: absolute;
  left: 55%;
`;