export enum EmptyAreaFormTabs {
  INFORMATION = "information",
  LISTING = "listing",
}

export const emptyAreaFormTabs = [
  {
    label: "property.emptyAreaForm.information",
    value: EmptyAreaFormTabs.INFORMATION,
  },
  {
    label: "property.emptyAreaForm.listing",
    value: EmptyAreaFormTabs.LISTING,
  },
];
