import { TableCell } from "../../../types/table";
import { getFirstDayOfYear, getLastDayOfYear } from "../../../utils/date";
import de from "date-fns/locale/de";
import en from "date-fns/locale/en-US";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "employeeOverview.user",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "email",
  },
  {
    id: `created_at`,
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "userActivity.dateAndTime",
    align: "center",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenant.tenant_type_name",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "",
  },
];

export const ACTIVITY_DATE_AND_TIME_FORMAT = "DD.MM.yyyy HH:mm:ss";

export const initUserActivityRange = [getFirstDayOfYear(), getLastDayOfYear()];

export const langDict: Record<string, Locale> = {
  en,
  de,
};

export const LOCALE_KEY = "lang";
