import React, { ReactElement, useEffect, useCallback } from "react";
import { Dialog, TableCell } from "@mui/material";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
import { MaintenanceHistoryDialogProps } from "./types";
import { DialogContent } from "../typeManagement/styled";
import Table from "../../../../ui/table/Table";
import useTable from "../../../../ui/table/useTable";
import { itemsListHeadCells } from "./utils";
import { prepareQueryParams } from "../../../../utils/common";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { handleServerError } from "../../../../utils/http";
import { getMaintenanceHistory } from "../../../../api/maintenance";
import { MaintenanceHistory } from "../../../../types/be/maintenanceHistory";
import { formatTimestamp } from "../../../../utils/date";

const MaintenanceHistoryDialog = ({
  open,
  onClose,
  maintenanceId,
}: MaintenanceHistoryDialogProps): ReactElement => {
  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleSortChange,
    handleSelectAllClick,
    setTotal,
    handleConfirmToRemoveModalClose,
  } = useTable<MaintenanceHistory>();

  const handleFetchList = useCallback(
    async (loading = true) => {
      setIsLoading(loading);
      const params = prepareQueryParams(queryParams, {
        maintenance_id: maintenanceId,
      });
      const response = await getMaintenanceHistory(params);

      if (response.status !== HTTP_STATUS_CODES.OK) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        const json = await response.json();
        setData(json?.data);
        setTotal(json?.meta?.total);
      }

      setIsLoading(false);
    },
    [queryParams, maintenanceId]
  );

  const TableToolbar = <></>;

  const renderRow = (row: MaintenanceHistory): ReactElement => (
    <>
      <TableCell>{`${row?.user?.first_name} ${row?.user?.last_name}`}</TableCell>
      <TableCell>{formatTimestamp(row?.date)}</TableCell>
    </>
  );

  useEffect(() => {
    if (!queryParams) return;
    handleFetchList();
  }, [maintenanceId, queryParams]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      data-testid="maintenance-history-dialog"
      onClose={onClose}
    >
      <DialogTitle title="tenant.history.history" onClose={onClose} />
      <DialogContent>
        <Table
          data={data}
          total={total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={itemsListHeadCells}
          renderRow={renderRow}
          tableToolbar={TableToolbar}
        />
      </DialogContent>
    </Dialog>
  );
};

export default MaintenanceHistoryDialog;
