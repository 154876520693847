export default {
  accountCaption: "Account caption",
  account: "Account",
  languageId: "language-id",
  accountLabelLong: "Account lable long",
  user: "User",
  date: "Date",
  action: "Action",
  newDebitAccount: "New debit account",
  error: {
    konto: "Account is required",
  },
  debitAccountCreatedSuccessfully: "Debit account created successfully",
  debitAccountDeleteAllMessage:
    "Are you sure you want to delete all debit accounts?",
};
