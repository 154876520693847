import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import { TableCell, Grid } from "@mui/material";
import moment from "moment";
import { AssetManagerNote } from "../../../../../../types/models";
import { Search as SearchIcon } from "react-feather";
import {
  Input,
  Search,
  SearchIconWrapper,
} from "../../../../../../ui/table/styled";
import { useTranslation } from "react-i18next";
import { GeneralInformationDialogProps } from "../types";
import Button from "../../../../../../ui/button/Button";
import useTable from "../../../../../../ui/table/useTable";
import Table from "../../../../../../ui/table/Table";
import useIsMounted from "../../../../../../hooks/useIsMounted";
import { getAssetManagersHistory } from "../../../../../../api/property";
import { itemsListHeadCellsForAMHistory as itemsListHeadCells } from "../utils";
import FormAlert from "../../../../../../ui/formAlert/FormAlert";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../../../constants";
import DialogTitle from "../../../../../../ui/dialog/DialogTitle";
const GeneralInformationDialog = ({
  handleCloseDialog,
  id,
  ...props
}: GeneralInformationDialogProps): ReactElement => {
  const { t } = useTranslation();
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
  } = useTable<AssetManagerNote>();
  const isMounted = useIsMounted();

  const handleServerResponse = async (response: Response): Promise<void> => {
    const responseData = await response.json();
    if (response.status === 200) {
      setData(responseData.data);
      setTotal(responseData?.meta?.total);
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
    }
    setIsLoading(false);
  };

  const fetchAssetManagersHistoryData = useCallback(async () => {
    setIsLoading(true);
    const res = await getAssetManagersHistory(id, queryParams);
    isMounted() && handleServerResponse(res);
  }, [queryParams, setTotal, setData, setIsLoading]);

  useEffect(() => {
    if (id && queryParams && isMounted()) fetchAssetManagersHistoryData();
  }, [id, queryParams]);
  const TableToolbar = (
    <Grid container sx={{ pt: 5 }}>
      <Grid item xs />
      <Grid item>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Input placeholder={t("search")} onChange={handleTableSearch} />
        </Search>
      </Grid>
    </Grid>
  );

  const renderRow = (row: AssetManagerNote): ReactElement => (
    <>
      <TableCell align="left">{row.note}</TableCell>
      <TableCell align="right">
        {moment.unix(row.created_at).format("DD.MM.YYYY")}
      </TableCell>
    </>
  );

  return (
    <Dialog
      maxWidth={"lg"}
      open={true}
      onClose={handleCloseDialog}
      aria-labelledby="max-width-dialog-title"
      {...props}
    >
      <DialogTitle
        title="property.assetManagersHistoryTitle"
        onClose={handleCloseDialog}
      />
      <DialogContent dividers>
        <>
          {formMessage.text && <FormAlert formMessage={formMessage} />}
          <Table
            data={data}
            total={total}
            currentPage={currentPage}
            order={order}
            orderBy={orderBy}
            error={error}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
            onSortChange={handleSortChange}
            onSelectAllClick={handleSelectAllClick}
            onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
            isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
            listHeadCells={itemsListHeadCells}
            renderRow={renderRow}
            tableToolbar={TableToolbar}
            tableDataMaxHeight={"50vh"}
          />
        </>
      </DialogContent>
      <DialogActions>
        <Button
          title={t("cancel")}
          onClick={handleCloseDialog}
          testId="close-history-modal"
        />
      </DialogActions>
    </Dialog>
  );
};

export default GeneralInformationDialog;
