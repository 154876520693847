import React, { ReactElement } from "react";
import { Box, InfoBox, StyledCloseButton } from "./styled";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { MAX_FILES_UPLOAD } from "../../constants";
import { useTranslation } from "react-i18next";
import { DropzoneProps } from "./types";
import { getAcceptedTypesObject } from "./utils";
import CloudUpload from "@mui/icons-material/CloudUpload";
import Button from "../../ui/button/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileUpload from "@mui/icons-material/FileUpload";
import { Button as MUIButton } from "@mui/material";
import FileList from "../fileList";

const Dropzone = ({
  onDrop,
  open,
  handleClose,
  isLoading,
  isFileTreeVisible,
  handleOpenFileTree,
  handleCloseFileTree,
  handleFileTreeClick,
  isOcrLoading,
  fileTreeExtensions,
  maxFiles = MAX_FILES_UPLOAD,
  accept = getAcceptedTypesObject(),
  sx = {},
}: DropzoneProps): ReactElement => {
  const { t } = useTranslation();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept,
    maxFiles,
  });
  const additionalStyle = isDragAccept
    ? "accept"
    : isDragReject
    ? "reject"
    : "";

  const handleCloseButtonClick = (
    event: React.MouseEvent<Element, MouseEvent>
  ): void => {
    event.stopPropagation();
    handleClose && handleClose();
  };

  return (
    <>
      {open && (
        <>
          <Grid
            container
            sx={{
              ...sx,
              position: "relative",
              zIndex: 11,
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                data-testid="drop-box"
                additionalstyle={additionalStyle}
                className="drop-box"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <InfoBox>
                  {isDragActive ? (
                    <Typography variant="h3">
                      {t("fileManagement.dropFilesHere")}
                    </Typography>
                  ) : isLoading ? (
                    <CircularProgress size={25} color="inherit" role="loader" />
                  ) : (
                    <>
                      <CloudUpload sx={{ fontSize: "80px" }} />
                      <Typography variant="h4">
                        {t("fileManagement.dragAndDropSomeFilesHere")}
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{
                          marginTop: 5,
                          marginBottom: 5,
                          textTransform: "uppercase",
                        }}
                      >
                        {t("or")}
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid
                          item
                          xs={handleOpenFileTree ? 6 : 12}
                          display="flex"
                          justifyContent={
                            handleOpenFileTree ? "flex-end" : "center"
                          }
                        >
                          <Button
                            testId="browse-file"
                            title={t("upload")}
                            size="medium"
                            sx={{
                              textTransform: "uppercase",
                              letterSpacing: 0.8,
                            }}
                            startIcon={<FileUpload />}
                          />
                        </Grid>
                        {handleOpenFileTree && (
                          <Grid
                            item
                            xs={6}
                            display="flex"
                            justifyContent="flex-start"
                          >
                            <MUIButton
                              color="primary"
                              variant="contained"
                              sx={{
                                textTransform: "uppercase",
                                letterSpacing: 0.8,
                              }}
                              size="medium"
                              startIcon={<CloudUploadIcon />}
                              onClick={
                                /* istanbul ignore next */ (e) => {
                                  e.stopPropagation();
                                  handleOpenFileTree?.();
                                }
                              }
                            >
                              {t("chooseAFile")}
                            </MUIButton>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                </InfoBox>
                {handleClose && (
                  <StyledCloseButton onClick={handleCloseButtonClick} />
                )}
              </Box>
            </Grid>
          </Grid>

          {
            /* istanbul ignore next */ isFileTreeVisible && (
              <FileList
                onClose={handleCloseFileTree}
                isOcrLoading={isOcrLoading}
                handleFileTreeClick={handleFileTreeClick}
                extensions={fileTreeExtensions}
              />
            )
          }
        </>
      )}
    </>
  );
};

export default Dropzone;
