/* istanbul ignore file */

import React, { ReactElement } from "react";
import { ValuedFreeSpaceProps } from "./types";
import { CalculationTableHead, CalculationTableTitle } from "./styled";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import {
  THOUSAND_SEPARATOR,
  DECIMAL_SEPARATOR,
  DECIMAL_SCALE,
  Measurement_Units,
} from "../../../../../../constants";

const ValuedFreeSpace = ({
  commercial_valued_free_space_per_month,
  commercial_valued_free_space_per_year,
  living_valued_free_space_per_month,
  living_valued_free_space_per_year,
  total_valued_free_space_per_month,
  total_valued_free_space_per_year,
}: ValuedFreeSpaceProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <CalculationTableHead>
        <CalculationTableTitle>
          {t("property.tenants.calculationResult.vacancyValued")}
        </CalculationTableTitle>
      </CalculationTableHead>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" size="small">
              {" "}
            </TableCell>
            <TableCell align="right" size="small">
              {t("property.tenants.calculationResult.pm")}
            </TableCell>
            <TableCell align="right" size="small">
              {t("property.tenants.calculationResult.pa")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left" size="small">
              {t("property.tenants.calculationResult.business")}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                decimalScale={DECIMAL_SCALE}
                fixedDecimalScale
                displayType={"text"}
                value={commercial_valued_free_space_per_month}
              />{" "}
              {commercial_valued_free_space_per_month !== null &&
                Measurement_Units.EURO}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                decimalScale={DECIMAL_SCALE}
                fixedDecimalScale
                displayType={"text"}
                value={commercial_valued_free_space_per_year}
              />{" "}
              {commercial_valued_free_space_per_year !== null &&
                Measurement_Units.EURO}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" size="small">
              {t("property.tenants.calculationResult.reside")}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                decimalScale={DECIMAL_SCALE}
                fixedDecimalScale
                displayType={"text"}
                value={living_valued_free_space_per_month}
              />{" "}
              {living_valued_free_space_per_month !== null &&
                Measurement_Units.EURO}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                decimalScale={DECIMAL_SCALE}
                fixedDecimalScale
                displayType={"text"}
                value={living_valued_free_space_per_year}
              />{" "}
              {living_valued_free_space_per_year !== null &&
                Measurement_Units.EURO}
            </TableCell>
          </TableRow>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell align="left" size="small">
              {" "}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                decimalScale={DECIMAL_SCALE}
                fixedDecimalScale
                displayType={"text"}
                value={total_valued_free_space_per_month || "0"}
              />{" "}
              {total_valued_free_space_per_month !== null &&
                Measurement_Units.EURO}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                decimalScale={DECIMAL_SCALE}
                fixedDecimalScale
                displayType={"text"}
                value={total_valued_free_space_per_year || "0"}
              />{" "}
              {total_valued_free_space_per_year !== null &&
                Measurement_Units.EURO}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ValuedFreeSpace;
