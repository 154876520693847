import { CreatePropertyArea } from "../../../../types/be/area";
import { SubmitCreatePropertyAreaProps } from "./types";
import { FormikHelpers } from "formik";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { parseNumber } from "../../../../utils/common";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { createArea } from "../../../../api/area";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../constants";

export const createPropertyAreaFormInitState: CreatePropertyArea = {
  name: "",
  type_id: "",
  square_meters: "",
};

export const handleSubmitCreateArea = (
  id: number,
  {
    handleCreateModalClose,
    setSnackbarMessage,
    t,
  }: SubmitCreatePropertyAreaProps
) => {
  return async (
    values: CreatePropertyArea,
    { setStatus }: FormikHelpers<CreatePropertyArea>
  ): Promise<void | Response> => {
    const data = {
      ...values,
      square_meters: parseNumber(values.square_meters),
      property_id: id,
    };
    const response = await createArea(data);
    const { errors } = await response.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response?.status
      )
    ) {
      handleCreateModalClose();
      setSnackbarMessage({
        visible: true,
        text: t("property.area.areaWasSuccessfullyCreated"),
        color: "success",
      });
      return response;
    } else if (response?.status == HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(errors),
      });
    } else {
      setSnackbarMessage({
        visible: true,
        text: t(SOMETHING_WENT_WRONG_ERROR),
        color: "error",
      });
    }
  };
};
