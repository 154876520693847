import React, { ReactElement, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import { CreateTopicDialogProps } from "./types";
import Form from "./form";
import { createTopicInitialValues, handleCreateOfferTopic } from "./utils";
import { FormMessageInterface } from "../../../../../../../types/form";
import FormAlert from "../../../../../../../ui/formAlert/FormAlert";
import { FormMessageInItState } from "../../../../../../../constants";
import * as Yup from "yup";
import useValidation from "../../../../../../../hooks/useValidation";
import DialogTitle from "../../../../../../../ui/dialog/DialogTitle";

const CreateTopicDialog = ({
  open,
  onClose,
  afterCreateTopic,
}: CreateTopicDialogProps): ReactElement => {
  const { name, property_id } = useValidation();
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="create-offer-dialog"
    >
      <DialogTitle title="offers.create" onClose={onClose} />
      <DialogContent>
        {formMessage.text ? (
          <FormAlert formMessage={formMessage} sx={{ marginBottom: "10px" }} />
        ) : null}
        <Formik
          initialValues={createTopicInitialValues}
          onSubmit={handleCreateOfferTopic({
            setFormMessage,
            afterCreateTopic,
          })}
          validationSchema={Yup.object().shape({
            name,
            property_id,
          })}
        >
          {(props) => <Form {...props} />}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CreateTopicDialog;
