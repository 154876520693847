import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Grid, Paper, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { route } from "../../../../utils/url";
import { ErrorBox, LabelText, LoadingBox } from "./styled";
import { NumericFormat } from "react-number-format";
import {
  COMMENTABLE,
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  FormMessageErrorState,
  Measurement_Units,
  PermissionsUpdate,
  THOUSAND_SEPARATOR,
} from "../../../../constants";
import Comments from "../../../../ui/сomments";
import { useFetch } from "../../../../hooks/useFetch";
import { getInsurance } from "../../../../api/insurance";
import Loader from "../../../Loader";
import useComments from "../../../../ui/сomments/useComments";
import { monthDict } from "../list/topicList/utils";
import { Insurance } from "../../../../types/be/insurance";
import { RELEASE_LEVEL } from "../../../../utils/common";
import {
  findReleaseLevelName,
  isAvailableReleaseButtonTableCell,
  RELASE_BUTTONS_AM,
  RELASE_BUTTONS_PM,
  RELEASE_BUTTONS_FR,
  RELEASE_BUTTONS_USER,
} from "../../utils";
import {
  DOCUMENT_RELEASE_LEVEL,
  DOCUMENT_RELEASE_MODULE,
} from "../../../../types/be/releaseFlow";
import PdfViewer from "../../../../ui/pdfViewer";
import { HeaderText } from "../../edit/general/styled";
import useDocumentRelease from "../../components/documentRelease/useDocumentRelease";
import DocumentRelease from "../../components/documentRelease";
import TabPageWrapper from "../../../../ui/tabPageWrapper";
import useAccessControl from "../../../../hooks/useAccessControl";

/* istanbul ignore next */
const InsuranceDetails = (): ReactElement => {
  const { can } = useAccessControl();
  const { id, insuranceId } = useParams();
  const { t } = useTranslation();
  const pdfContainerRef = useRef<HTMLObjectElement | null>(null);
  const [insuranceFildId, setInsuranceFileId] = useState<number | undefined>();
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const {
    configuration,
    isConfigurationLoading,
    handleReleaseDocument,
    handleForwardDocument,
    releasePermission,
    snackbar,
    loading: isDocumentReleaseLoading,
  } = useDocumentRelease(DOCUMENT_RELEASE_MODULE.INSURANCE);

  const {
    data: insuranceData,
    run: getInsuranceDetails,
    isLoading: isInsuranceLoading,
    error: insuranceError,
    isError: isInsuranceError,
  } = useFetch<Insurance>();

  const {
    comments,
    content,
    setContent,
    currentPage,
    totalPages,
    handleCommentSave,
    handleCommentDelete,
    handlePaginationChange,
    deleting,
    loading,
  } = useComments({
    relation: COMMENTABLE.INSURANCE,
    relation_id: insuranceId!,
  });

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: route("insurances"),
      name: t("property.navLinks.insurances"),
    },
  ];

  const getData = (): void => {
    getInsuranceDetails(getInsurance(insuranceId!));
  };

  useEffect(() => {
    getData();
  }, [insuranceId]);

  const invoiceFile = useMemo(() => {
    return { fileId: insuranceFildId };
  }, [insuranceFildId]);

  useEffect(() => {
    insuranceData &&
      insuranceData?.file?.id &&
      setInsuranceFileId(insuranceData?.file?.id);
    insuranceData && setIsDataLoaded(true);
  }, [insuranceData]);

  if (!isDataLoaded && (isInsuranceLoading || isConfigurationLoading))
    return (
      <LoadingBox>
        <Loader />
      </LoadingBox>
    );

  if (isInsuranceError || !insuranceData || insuranceError)
    return <ErrorBox formMessage={FormMessageErrorState} />;

  return (
    <TabPageWrapper
      title={insuranceData?.file?.name}
      breadcrumbs={breadcrumbs}
      wrap={!id}
    >
      <Grid container justifyContent={"flex-end"} spacing={4}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12} p={4}>
              <Grid container spacing={4}>
                <Grid item xs={12} ref={pdfContainerRef}>
                  <PdfViewer
                    file={invoiceFile}
                    containerRef={pdfContainerRef}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Paper variant="outlined" sx={{ textAlign: "center", p: 4 }}>
                    <Grid container spacing={4} sx={{ textAlign: "left" }}>
                      <Grid item xs={12}>
                        <HeaderText>
                          <Link
                            href={insuranceData.file.url}
                            target="_blank"
                            sx={{
                              wordBreak: "break-all",
                              fontSize: "11px",
                            }}
                          >
                            {insuranceData.file.name}
                          </Link>
                        </HeaderText>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent={"center"} mt={6}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {insuranceData?.contact?.first_name}{" "}
                          {insuranceData?.contact?.last_name}
                        </HeaderText>
                        <LabelText>{t("offers.contact")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {insuranceData?.property
                            ? insuranceData?.property?.object_name
                            : EMPTY_DATA}
                        </HeaderText>
                        <LabelText>{t("propertyName")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          <NumericFormat
                            thousandSeparator={THOUSAND_SEPARATOR}
                            decimalSeparator={DECIMAL_SEPARATOR}
                            decimalScale={DECIMAL_SCALE}
                            fixedDecimalScale
                            displayType={"text"}
                            value={insuranceData?.amount}
                          />{" "}
                          {insuranceData?.amount !== null &&
                            Measurement_Units.EURO}
                        </HeaderText>
                        <LabelText>{t("offers.amount")}</LabelText>
                      </Grid>
                    </Grid>
                    <Grid container mt={6}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {insuranceData?.fr_status?.name}
                        </HeaderText>
                        <LabelText>{t("offers.show.frStatus")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {`${insuranceData?.cancellation_period} ${t(
                            `insurance.${
                              monthDict[insuranceData?.cancellation_period]
                            }`
                          )}`}
                        </HeaderText>
                        <LabelText>
                          {t("insurance.cancellationPeriod")}
                        </LabelText>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper variant="outlined" sx={{ p: 4, mt: 4 }}>
                    <Grid container spacing={4}>
                      <Grid
                        item
                        xs={6}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={4}
                      >
                        {
                          /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                            RELASE_BUTTONS_PM,
                            configuration
                          ) ? (
                            <Grid container>
                              <Grid item xs>
                                <LabelText>
                                  {findReleaseLevelName(
                                    configuration,
                                    RELASE_BUTTONS_PM
                                  )}
                                </LabelText>
                              </Grid>
                              <Grid item xs>
                                <DocumentRelease
                                  id={insuranceData?.id}
                                  releaser={insuranceData?.pm}
                                  releaseStatus={insuranceData?.pm_status}
                                  forwardedAt={insuranceData?.pm_forwarded_at}
                                  forwardedBy={insuranceData?.pm_forwarded_by}
                                  overdue={insuranceData?.pm_overdue}
                                  afterSuccess={getData}
                                  handleReleaseDocument={handleReleaseDocument}
                                  handleForwardDocument={handleForwardDocument}
                                  level={RELEASE_LEVEL.THIRD_LEVEL_RELEASE}
                                  hasForwardPermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                    ](insuranceData?.assigned_user)
                                  }
                                  hasReleasePermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                    ]
                                  }
                                  relation={DOCUMENT_RELEASE_MODULE.INSURANCE}
                                  loading={isDocumentReleaseLoading}
                                  isButtonVisible={{
                                    forwardButton: false,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ) : null
                        }
                        {
                          /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                            RELEASE_BUTTONS_FR,
                            configuration
                          ) ? (
                            <Grid container>
                              <Grid item xs>
                                <LabelText>
                                  {findReleaseLevelName(
                                    configuration,
                                    RELEASE_BUTTONS_FR
                                  )}
                                </LabelText>
                              </Grid>
                              <Grid item xs>
                                <DocumentRelease
                                  id={insuranceData?.id}
                                  releaser={insuranceData?.fr}
                                  releaseStatus={insuranceData?.fr_status}
                                  forwardedAt={insuranceData?.fr_forwarded_at}
                                  forwardedBy={insuranceData?.fr_forwarded_by}
                                  overdue={insuranceData?.fr_overdue}
                                  afterSuccess={getData}
                                  handleReleaseDocument={handleReleaseDocument}
                                  handleForwardDocument={handleForwardDocument}
                                  level={RELEASE_LEVEL.FIRST_LEVEL_RELEASE}
                                  hasForwardPermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                    ](insuranceData?.assigned_user)
                                  }
                                  hasReleasePermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                    ]
                                  }
                                  relation={DOCUMENT_RELEASE_MODULE.INSURANCE}
                                  loading={isDocumentReleaseLoading}
                                  isButtonVisible={{
                                    forwardButton: false,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ) : null
                        }
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={4}
                      >
                        {
                          /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                            RELASE_BUTTONS_AM,
                            configuration
                          ) ? (
                            <Grid container>
                              <Grid item xs>
                                <LabelText>
                                  {findReleaseLevelName(
                                    configuration,
                                    RELASE_BUTTONS_AM
                                  )}
                                </LabelText>
                              </Grid>
                              <Grid item xs>
                                <DocumentRelease
                                  id={insuranceData?.id}
                                  releaser={insuranceData?.am}
                                  releaseStatus={insuranceData?.am_status}
                                  forwardedAt={insuranceData?.am_forwarded_at}
                                  forwardedBy={insuranceData?.am_forwarded_by}
                                  overdue={insuranceData?.am_overdue}
                                  afterSuccess={getData}
                                  handleReleaseDocument={handleReleaseDocument}
                                  handleForwardDocument={handleForwardDocument}
                                  level={RELEASE_LEVEL.SECOND_LEVEL_RELEASE}
                                  hasForwardPermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                    ](insuranceData?.assigned_user)
                                  }
                                  hasReleasePermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                    ]
                                  }
                                  relation={DOCUMENT_RELEASE_MODULE.INSURANCE}
                                  loading={isDocumentReleaseLoading}
                                  isButtonVisible={{
                                    forwardButton: false,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ) : null
                        }
                        {
                          /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                            RELEASE_BUTTONS_USER,
                            configuration
                          ) ? (
                            <Grid container>
                              <Grid item xs>
                                <LabelText>
                                  {findReleaseLevelName(
                                    configuration,
                                    RELEASE_BUTTONS_USER
                                  )}
                                </LabelText>
                              </Grid>
                              <Grid item xs>
                                <DocumentRelease
                                  id={insuranceData?.topic?.id}
                                  releaser={insuranceData?.au}
                                  releaseStatus={insuranceData?.au_status}
                                  forwardedAt={insuranceData?.au_forwarded_at}
                                  forwardedBy={insuranceData?.au_forwarded_by}
                                  overdue={insuranceData?.au_overdue}
                                  afterSuccess={getData}
                                  handleReleaseDocument={handleReleaseDocument}
                                  handleForwardDocument={handleForwardDocument}
                                  level={RELEASE_LEVEL.FOURTH_LEVEL_RELEASE}
                                  hasForwardPermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                    ]
                                  }
                                  hasReleasePermission={releasePermission[
                                    DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                  ](insuranceData?.assigned_user)}
                                  relation={DOCUMENT_RELEASE_MODULE.INSURANCE}
                                  assignedUser={insuranceData?.assigned_user}
                                  loading={isDocumentReleaseLoading}
                                  isButtonVisible={{
                                    forwardButton: false,
                                  }}
                                  hasAssignUserPermission={can(
                                    PermissionsUpdate.INSURANCE
                                  )}
                                />
                              </Grid>
                            </Grid>
                          ) : null
                        }
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} pt={4}>
                  <Comments
                    editorContent={content}
                    setEditorContent={setContent}
                    handleSaveComment={handleCommentSave}
                    comments={comments}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handleDeleteComment={handleCommentDelete}
                    handlePaginationChange={handlePaginationChange}
                    deleting={deleting}
                    loading={loading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {snackbar.Snackbar}
        </Grid>
      </Grid>
    </TabPageWrapper>
  );
};

export default InsuranceDetails;
