import React, { ReactElement, useState, useEffect } from "react";
import { Form } from "formik";
import Button from "../../../../../../ui/button/Button";
import { useTranslation } from "react-i18next";
import {
  MenuItem,
  Grid,
  CardHeader,
  Typography,
  Tooltip,
  Card,
  FormHelperText,
} from "@mui/material";
import { FormikValues } from "formik/dist/types";
import Snackbar from "../../../../../../ui/Snackbar";
import {
  AMHistoryButton,
  SelectBox,
  EditInfoCard,
  RentedInfoContainer,
  SmallLightText,
  LogoContainer,
} from "../styled";
import {
  ADMINISTRATIVE_EXPENSES,
  EMPTY_DATA,
} from "../../../../../../constants";
import { GeneralInformationFormRestProps } from "../types";
import TextField from "../../../../../../ui/formsFields/textField";
import Dialog from "./Dialog";
import { Edit } from "@mui/icons-material";
import SupervisorAutocomplete from "../../../autocomplete/Supervisor";
import { User } from "../../../../../../types/models";
import AssetManagersAutocomplete from "../../../autocomplete/AssetManagers";
import UploadLogo from "../UploadLogo";
import { ErrorCode, FileRejection } from "react-dropzone";

/* istanbul ignore next */
const GeneralInformationForm = ({
  errors,
  handleSubmit,
  isSubmitting,
  touched,
  values,
  status,
  assetManagers,
  caretakers,
  handleBlur,
  setFieldValue,
  isUploadingPropertyLogo,
}: FormikValues & GeneralInformationFormRestProps): ReactElement => {
  const { t } = useTranslation();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isGeneralAllEditable, setIsGeneralAllEditable] = useState(false);
  const [isMenuOpened] = useState(false);
  const [fileBlob, setFileBlob] = useState<Blob | null>(null);
  const [logoError, setLogoError] = useState<string>();

  const tooltipTitle =
    values.asset_managers.length === 0 || isMenuOpened
      ? null
      : values.asset_managers.map(
          ({ id, first_name, last_name }: User, index: number) => (
            <Typography key={id + " " + index}>
              {first_name + " " + last_name}
            </Typography>
          )
        );

  /* istanbul ignore next */
  const handleCloseSnackbar = (): void => setIsSnackbarVisible(false);
  const handleCloseDialog = (): void => setIsDialogVisible(false);
  const handleOpenDialog = (): void => setIsDialogVisible(true);

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  const showSupervisor = (): string => {
    if (values.supervisor) {
      return `${
        values.supervisor?.first_name && values.supervisor?.first_name
      } ${values.supervisor?.last_name && values.supervisor?.last_name}`;
    }
    return "---";
  };
  const showCareTacker = (): string => {
    const caretaker = caretakers.find(
      (caretaker) => caretaker.id === values.caretaker_id
    );
    if (caretaker) {
      return `${caretaker?.first_name && caretaker?.first_name} ${
        caretaker?.last_name && caretaker?.last_name
      }`;
    }
    return "---";
  };

  const showAssetManagers = (): ReactElement => {
    const selectedAssetManager = [...values.asset_managers];
    return (
      <SelectBox>
        <Typography variant="h4" textAlign="center">
          {selectedAssetManager.length > 0 ? (
            <>
              {selectedAssetManager[0]?.first_name}{" "}
              {selectedAssetManager[0]?.last_name}
              {selectedAssetManager.length > 1 && (
                <div>{`${t("tenant.selectedMoreThenOneTenantPartOne")} ${
                  selectedAssetManager.length - 1
                } ${t("tenant.selectedMoreThenOneTenantPartTwo")} `}</div>
              )}
            </>
          ) : (
            "---"
          )}
        </Typography>
      </SelectBox>
    );
  };

  const handleDeleteRentalContract = (): void => {
    setFileBlob(null);
    setFieldValue("logo", "");
  };

  return (
    <React.Fragment>
      <Form noValidate onSubmit={handleSubmit}>
        <Grid container>
          <Grid item sm={12}>
            <EditInfoCard className="edit-card">
              <CardHeader title={t("property.objectDataSheet.generalInfo")} />
              {isGeneralAllEditable ? (
                <Grid item sx={{ px: 4 }}>
                  <Grid container spacing={6} columns={12} sx={{ pt: 2 }}>
                    <Grid item xs={12} sm={3}>
                      <AssetManagersAutocomplete
                        values={values}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        assetManagers={assetManagers}
                        touched={touched}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="takeover"
                        label={t("property.takeover")}
                        value={values.takeover}
                        inputProps={{
                          readOnly: true,
                        }}
                        helperText={
                          <AMHistoryButton onClick={handleOpenDialog}>
                            {t("property.assetManagersHistoryBtn")}
                          </AMHistoryButton>
                        }
                        status={status}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SupervisorAutocomplete
                        values={values}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        assetManagers={assetManagers}
                        touched={touched}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="caretaker_id"
                        select
                        label={t("property.caretaker")}
                        value={values.caretaker_id || ""}
                        defaultValue=""
                        status={status}
                      >
                        {caretakers.map((caretaker) => (
                          <MenuItem key={caretaker.id} value={caretaker.id}>
                            {`${caretaker.first_name} ${caretaker.last_name}`}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="expenses"
                        select
                        label={t("property.expenses")}
                        value={values.expenses}
                        defaultValue=""
                        status={status}
                      >
                        {ADMINISTRATIVE_EXPENSES.map((expense: number) => (
                          <MenuItem key={expense} value={expense.toString()}>
                            {expense}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="company_title"
                        label={t("company.companyName")}
                        value={values.company?.title ?? ""}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="portfolio"
                        label={t("property.portfolio")}
                        value={values.portfolio}
                        status={status}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="wault"
                        label={t("property.wault")}
                        value={values.wault}
                        inputProps={{ readOnly: true }}
                        variant="filled"
                        status={status}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="phone_number"
                        label={t("phoneNumber")}
                        value={values.phone_number}
                        status={status}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="tax_number"
                        label={t("property.taxNumber")}
                        value={values.tax_number}
                        status={status}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="vat"
                        label={t("property.vat")}
                        value={values.vat}
                        status={status}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="architect"
                        label={t("property.architect")}
                        value={values.architect}
                        helperText={
                          values.architect_updated_at
                            ? `${t("property.architectHelperText")}: ${
                                values.architect_updated_at
                              }`
                            : ""
                        }
                        status={status}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="registration_number"
                        label={t("property.registrationNumber")}
                        value={values.registration_number}
                        status={status}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="tax_id"
                        label={t("property.taxId")}
                        value={values.tax_id}
                        status={status}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="outgoing_address"
                        label={t("property.outgoingAddress")}
                        value={values.outgoing_address}
                        status={status}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="managing_director"
                        label={t("property.managingDirector")}
                        value={values.managing_director}
                        status={status}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="email"
                        label={t("property.propertyEmail")}
                        value={values.email}
                        status={status}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Edit
                    className="edit-icon"
                    onClick={() => setIsGeneralAllEditable(true)}
                  />
                  <Grid container>
                    <Tooltip placement="top" title={tooltipTitle}>
                      <RentedInfoContainer item sm={4} md={3}>
                        {showAssetManagers()}
                        <SmallLightText>
                          {t("property.assetManager")}
                        </SmallLightText>
                      </RentedInfoContainer>
                    </Tooltip>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {values?.takeover ? values.takeover : EMPTY_DATA}
                      </Typography>
                      <SmallLightText>{t("property.takeover")}</SmallLightText>
                      <AMHistoryButton onClick={handleOpenDialog}>
                        {t("property.assetManagersHistoryBtn")}
                      </AMHistoryButton>
                    </RentedInfoContainer>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {showSupervisor()}
                      </Typography>
                      <SmallLightText>
                        {t("property.supervisor")}
                      </SmallLightText>
                    </RentedInfoContainer>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {showCareTacker()}
                      </Typography>
                      <SmallLightText>{t("property.caretaker")}</SmallLightText>
                    </RentedInfoContainer>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {values.expenses ? values.expenses : EMPTY_DATA}
                      </Typography>
                      <SmallLightText>{t("property.expenses")}</SmallLightText>
                    </RentedInfoContainer>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {values?.company?.title
                          ? values?.company?.title
                          : EMPTY_DATA}
                      </Typography>
                      <SmallLightText>
                        {t("company.companyName")}
                      </SmallLightText>
                    </RentedInfoContainer>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {values.portfolio ? values.portfolio : EMPTY_DATA}
                      </Typography>
                      <SmallLightText>{t("property.portfolio")}</SmallLightText>
                    </RentedInfoContainer>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {values?.wault ? values?.wault : EMPTY_DATA}
                      </Typography>
                      <SmallLightText>{t("property.wault")}</SmallLightText>
                    </RentedInfoContainer>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {values?.phone_number
                          ? values?.phone_number
                          : EMPTY_DATA}
                      </Typography>
                      <SmallLightText>{t("phoneNumber")}</SmallLightText>
                    </RentedInfoContainer>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {values?.tax_number ? values?.tax_number : EMPTY_DATA}
                      </Typography>
                      <SmallLightText>{t("property.taxNumber")}</SmallLightText>
                    </RentedInfoContainer>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {values?.vat ? values?.vat : EMPTY_DATA}
                      </Typography>
                      <SmallLightText>{t("property.vat")}</SmallLightText>
                    </RentedInfoContainer>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {values?.architect ? values?.architect : EMPTY_DATA}
                      </Typography>
                      <SmallLightText>{t("property.architect")}</SmallLightText>
                    </RentedInfoContainer>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {values?.registration_number
                          ? values?.registration_number
                          : EMPTY_DATA}
                      </Typography>
                      <SmallLightText>
                        {t("property.registrationNumber")}
                      </SmallLightText>
                    </RentedInfoContainer>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {values?.tax_id ? values?.tax_id : EMPTY_DATA}
                      </Typography>
                      <SmallLightText>{t("property.taxId")}</SmallLightText>
                    </RentedInfoContainer>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {values?.outgoing_address
                          ? values?.outgoing_address
                          : EMPTY_DATA}
                      </Typography>
                      <SmallLightText>
                        {t("property.outgoingAddress")}
                      </SmallLightText>
                    </RentedInfoContainer>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {values?.managing_director
                          ? values?.managing_director
                          : EMPTY_DATA}
                      </Typography>
                      <SmallLightText>
                        {t("property.managingDirector")}
                      </SmallLightText>
                    </RentedInfoContainer>
                    <RentedInfoContainer item sm={4} md={3}>
                      <Typography variant="h4" textAlign="center">
                        {values?.email ? values?.email : EMPTY_DATA}
                      </Typography>
                      <SmallLightText>
                        {t("property.propertyEmail")}
                      </SmallLightText>
                    </RentedInfoContainer>
                  </Grid>
                </>
              )}
            </EditInfoCard>
          </Grid>
          <Grid item sm={12}>
            <Card>
              <LogoContainer container>
                <LogoContainer item sx={{ width: 400 }}>
                  <UploadLogo
                    file={values?.logo}
                    fileBlob={fileBlob}
                    isLoading={isUploadingPropertyLogo}
                    handleDelete={handleDeleteRentalContract}
                    onDrop={<T extends File>(
                      files: FileList | T[] | null,
                      fileRejections: FileRejection[]
                    ): void => {
                      setFileBlob((files?.[0] as Blob) || null);
                      setFieldValue("logo", files?.[0] || "");
                      setLogoError(
                        fileRejections?.[0]?.errors?.[0]?.code ===
                          ErrorCode.FileTooLarge
                          ? t("form.fileShouldBeLessThan5MB")
                          : ""
                      );
                    }}
                    title={t("property.uploadLogo")}
                    isGeneralAllEditable={isGeneralAllEditable}
                  />
                  {logoError || status?.errors?.logo ? (
                    <FormHelperText error>
                      {logoError || status?.errors?.["logo"]}
                    </FormHelperText>
                  ) : null}
                </LogoContainer>
                <SmallLightText>{t("property.logo")}</SmallLightText>
              </LogoContainer>
            </Card>
          </Grid>
          {isGeneralAllEditable ? (
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent={"flex-end"}
              gap={4}
              m={4}
            >
              <Button
                title={t("cancel")}
                variant="text"
                size="large"
                color="primary"
                onClick={() => {
                  setLogoError("");
                  setIsGeneralAllEditable(false);
                }}
              />
              <Button
                title={t("save")}
                color="success"
                size="large"
                type="button"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isSubmitting}
                isLoading={isSubmitting}
              />
            </Grid>
          ) : null}
        </Grid>
      </Form>
      <Snackbar
        message={t("property.propertyWasSuccessfullyUpdated")}
        color="success"
        open={isSnackbarVisible}
        handleClose={handleCloseSnackbar}
      />
      {isDialogVisible && (
        <Dialog handleCloseDialog={handleCloseDialog} id={values.id} />
      )}
    </React.Fragment>
  );
};

export default GeneralInformationForm;
