import { TableCell } from "../../../../../types/table";
import { CodesDict, PermissionCategory } from "./types";
/* istanbul ignore next */
export const itemsListHeadCells: TableCell[] = [
  {
    id: "roles.category",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "settings.role.category",
  },
  {
    id: "roles.function",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "settings.role.function",
  },
  {
    id: "roles.read",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "settings.role.read",
  },
  {
    id: "roles.create",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "settings.role.create",
  },
  {
    id: "roles.update",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "settings.role.update",
  },
  {
    id: "roles.delete",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "settings.role.delete",
  },
];
/* istanbul ignore next */
export const getCodesDict = (row: PermissionCategory): CodesDict => {
  const codes: CodesDict = {};
  row.group.permissions.forEach(({ code }) => {
    const splited = code.split(".");
    const crudType = splited[splited.length - 1];
    codes[crudType] = code;
  });
  return codes;
};
/* istanbul ignore next */
export const getCrudDict = (
  codes: CodesDict
): {
  read: string[][];
  create: string[][];
  update: string[][];
  delete: string[][];
} => ({
  read: [[codes.read, codes.create, codes.update, codes.delete], [codes.read]],
  create: [
    [codes.create, codes.update, codes.delete],
    [codes.read, codes.create],
  ],
  update: [
    [codes.update, codes.delete],
    [codes.read, codes.create, codes.update],
  ],
  delete: [
    [codes.delete],
    [codes.read, codes.create, codes.update, codes.delete],
  ],
});
/* istanbul ignore next */
export const allEqual = (arr: boolean[]): boolean =>
  arr.every((v: boolean) => v === arr[0]);
