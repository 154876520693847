import React, { ReactElement } from "react";
import { TenantReminderListRowProps } from "./types";
import { HTTP_STATUS_CODES } from "../../../../../../../types/server";
import { updateReminder } from "../../../../../../../api/reminder";
import { TableCell } from "../../../../../styled";
import {
  EMPTY_DATA,
  REMINDER_STATUS,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../../../../constants";
import { formatTimestamp } from "../../../../../../../utils/date";
import { t } from "i18next";
import { DeleteIcon } from "../../../../../../../ui/table/styled";
import { Android12Switch } from "../../../../../../../ui/formsFields/switch/styled";
import FileTableCell from "../../../../../components/fileTableCell";

/* istanbul ignore next */
const TenantReminderListRow = ({
  row,
  snackbar,
  openConfirmToRemoveModal,
  fetchReminder,
}: TenantReminderListRowProps): ReactElement => {
  const handleReminderCompleteStatusButton = async (): Promise<void> => {
    const payload = {
      status_id: row?.status?.code === REMINDER_STATUS.NEW ? 2 : 1,
    };
    const response = await updateReminder(row.id, payload);
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response?.status
      )
    ) {
      await fetchReminder();
      snackbar.success(t("reminder.update.reminderUpdatedSuccessfully"));
    } else {
      snackbar.error(SOMETHING_WENT_WRONG_ERROR);
    }
  };
  return (
    <>
      <FileTableCell file={row.file} />
      <TableCell align="left">
        {row.user ? `${row.user.first_name} ${row.user.last_name}` : EMPTY_DATA}
      </TableCell>
      <TableCell align="left">{formatTimestamp(row.created_at)}</TableCell>
      <TableCell align="left">
        {row.date ? formatTimestamp(row.date) : EMPTY_DATA}
      </TableCell>
      <TableCell align="left">
        <Android12Switch
          checked={row.status.code !== REMINDER_STATUS.NEW}
          onChange={handleReminderCompleteStatusButton}
          data-testid="toggle-reminder-complete"
        />
      </TableCell>
      <TableCell align="right">
        <DeleteIcon
          onClick={(): void => openConfirmToRemoveModal(row)}
          size={20}
          role={"deleteIconRole"}
        />
      </TableCell>
    </>
  );
};

export default TenantReminderListRow;
