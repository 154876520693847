import styled from "styled-components";
import { Card as MuiCard } from "@mui/material";

export const Card = styled(MuiCard)`
  text-align: left;
  overflow: visible;
  .fixed-position {
    position:fixed;
    z-index: 999
  }
`;
