/* istanbul ignore next */
import { PermissionsRead } from "../../constants";
import { DOCUMENT_RELEASE_MODULE } from "../../types/be/releaseFlow";
import { AutocompleteOption } from "./types";

export const DROPDOWN_LIMIT = "10";

export const getOptionLabel = (
  option: AutocompleteOption,
  label: string
): string => {
  if (label === "full_name") {
    return option?.full_name || `${option?.first_name} ${option?.last_name}`;
  }

  return option?.[label];
};

export const DOCUMENT_RELATION = {
  [DOCUMENT_RELEASE_MODULE.INVOICE]: PermissionsRead.INVOICE,
  [DOCUMENT_RELEASE_MODULE.OFFER]: PermissionsRead.OFFER,
  [DOCUMENT_RELEASE_MODULE.OFFER_TOPIC]: PermissionsRead.OFFER,
  [DOCUMENT_RELEASE_MODULE.CONTRACT]: PermissionsRead.CONTRACT,
  [DOCUMENT_RELEASE_MODULE.INSURANCE]: PermissionsRead.INSURANCE,
  [DOCUMENT_RELEASE_MODULE.INSURANCE_TOPIC]: PermissionsRead.INSURANCE,
};
