export default {
  roleWasSuccessfullyCreated: "Rolle wurde erfolgreich erstellt",
  create: "Erstellen",
  list: "Liste",
  createRole: "Rolle erstellen",
  roles: "Rollen",
  permissions: "Berechtigungen",
  all: "Alle",
  properties: "Objekte",
  companies: "Unternehmen",
  save: "Speichern",
  attachAll: "Attach all",
  resetAll: "Reset all",
  preCreateMessage:
    "Die benutzerdefinierte Rolle wurde erfolgreich erstellt. Möchten Sie mit der Objektzuweisung für die kürzlich erstellte Rolle fortfahren?",
  yes: "Ja",
  no: "Nein",
  update: "Aktualisieren",
  updated: "Aktualisiert",
  category: "Kategorie",
  function: "Funktion",
  read: "Lesen",
  delete: "Löschen",
  createNewRole: "Neue Rolle erstellen",
  abort: "Abbrechen",
};
