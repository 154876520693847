import { useEffect } from "react";
import { useFetch } from "./useFetch";
import { Property as BEProperty } from "../types/be/property";
import { getPropertyShort } from "../api/property";
import { useParams } from "react-router-dom";

export interface UseBreadcrumbsReturnType {
  propertyBreadcrumbs: BEProperty | null;
  isPropertyBreadcrumbsLoading: boolean;
  propertyBreadcrumbsError: boolean | null;
  handleFetchPropertyBreadcrumbs: () => Promise<void>;
}
/* istanbul ignore next */
const useBreadcrumbs = (id?: string): UseBreadcrumbsReturnType => {
  const { id: paramsId } = useParams();
  const propertyId = id || paramsId;

  const {
    data: propertyBreadcrumbs,
    isLoading: isPropertyBreadcrumbsLoading,
    error: propertyBreadcrumbsError,
    run: runPropertyBreadcrumbs,
  } = useFetch<BEProperty>();

  const handleFetchPropertyBreadcrumbs = async (): Promise<void> => {
    propertyId && (await runPropertyBreadcrumbs(getPropertyShort(propertyId)));
  };

  useEffect(() => {
    handleFetchPropertyBreadcrumbs();
  }, [propertyId]);

  return {
    propertyBreadcrumbs,
    isPropertyBreadcrumbsLoading,
    propertyBreadcrumbsError,
    handleFetchPropertyBreadcrumbs,
  };
};

export default useBreadcrumbs;
