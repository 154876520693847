import { Status } from "./status";
import { File } from "./file";
import { Comment } from "./comment";

export interface Reminder {
  id: number;
  relation: string;
  relation_id: number;
  user: {
    id: number;
    first_name: string;
    last_name: string;
  } | null;
  file: File;
  status: Status;
  created_at: number;
  property: {
    id: number;
    object_name: string;
  } | null;
  entity: {
    file: File;
    id: number;
    name: string;
    number: string;
    date: number;
    amount: number;
    property?: {
      id: string;
      object_name: string;
    };
  } | null;
  comments_count: number;
  date: number;
  latest_comment: Comment | null;
}

export enum ReminderStatusCodes {
  new = "new",
  completed = "completed",
}
