import React, { ReactElement, useState } from "react";
import { FormikProps } from "formik";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../../ui/button/Button";
import { generalPropertyOption } from "../../../../../../../utils/common";
import { getProperties } from "../../../../../../../api/property";
import { Property } from "../../../../../../../types/be/property";
import Text from "../../../../../../../ui/formsFields/text";
import { CreateTopicValuesType } from "./types";
import Dropdown from "../../../../../../../ui/dropdown";
import { AutocompleteOption } from "../../../../../../../ui/dropdown/types";

const Form = ({
  handleSubmit,
  setFieldValue,
  touched,
  errors,
  status,
  isSubmitting,
}: FormikProps<CreateTopicValuesType>): ReactElement => {
  const { t } = useTranslation();
  const [property, setProperty] = useState<Property | null>(null);

  const onPropertySelect = async (
    id?: string,
    event?: AutocompleteOption | null
  ): Promise<void> => {
    setProperty(event as Property);
    setFieldValue("property_id", id);
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={6} lg={6} sm={6} md={6}>
          <Dropdown
            id="property-autocomplete"
            name="property_id"
            size="medium"
            label={t("property.property")}
            placeholder={t("counter.searchProperty")}
            getOptions={getProperties}
            onSelectOption={onPropertySelect}
            optionLabel="object_name"
            optionValue="id"
            defaultValue={property || null}
            enableReInitialization
            error={Boolean(
              touched?.property_id &&
                (status?.errors?.property_id || errors.property_id)
            )}
            helperText={
              touched?.property_id &&
              (errors?.property_id || status?.errors?.property_id)
            }
            defaultOption={[generalPropertyOption]}
          />
        </Grid>
        <Grid item xs={6} lg={6} sm={6} md={6}>
          <Text
            label={t("offers.topic")}
            name="name"
            required
            size="medium"
            status={status}
            inputProps={{
              "data-testid": "topic-name",
            }}
          />
        </Grid>
        <Grid
          item
          sx={{ display: "flex", justifyContent: "flex-end" }}
          xs={12}
          lg={12}
          sm={12}
          md={12}
        >
          <Button
            title={t("offers.create")}
            color="success"
            type="submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            testId="createTopicBtn"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
