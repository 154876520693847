/* istanbul ignore file */
import React, { ReactElement } from "react";
import { Grid } from "@mui/material";
import { Form } from "formik";
import { FormikValues } from "formik/dist/types";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/button/Button";
import { Text } from "../../../../ui/formsFields/text";
import TagAutocomplete from "./TagAutocomplete";

const SendLOIForm = ({
  values,
  isSubmitting,
  setFieldValue,
  touched,
  errors,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();

  const handleReset = (): void => {
    setFieldValue("subject_email", "");
    setFieldValue("message", "");
  };

  return (
    <Form noValidate>
      <Grid item xs={12} sm={6} md={6}>
        <TagAutocomplete
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          field={"email"}
          label={"createContact.email"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TagAutocomplete
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          field={"email_cc"}
          label={"createContact.cc"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} mb={2}>
        <TagAutocomplete
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          field={"email_bcc"}
          label={"createContact.bcc"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} mb={4}>
        <Text
          type="string"
          name="subject_email"
          size="small"
          label={t("inbox.forward.subject")}
          value={values.subject_email}
          fullWidth
          inputProps={{ "data-testid": "email-subject-input" }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Text
          name="message"
          size="small"
          multiline
          rows={6}
          label={t("transactionManagement.loi.htmlMessage")}
          value={values.message}
          fullWidth
          inputProps={{ "data-testid": "message-input" }}
        />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={2}
        mt={2}
      >
        <Grid item mt={2}>
          <Button
            title={t("offers.reset")}
            color="warning"
            size="large"
            testId={"LOI-reset-button"}
            onClick={handleReset}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={2}
        mt={2}
      >
        <Grid item mt={2}>
          <Button
            title={t("send")}
            type="submit"
            color="success"
            size="large"
            testId={"LOI-send-button"}
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default SendLOIForm;
