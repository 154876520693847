import { MultiCosts } from "../components/properties/areas/edit/tabs/BKA/create/types";
import http from "../utils/http";

export const updateBKA = async (
  payload: FormData,
  bkaId: number
): Promise<Response> => {
  return await http.post(`/api/bka/${bkaId}`, payload);
};

export const createBKA = async (payload: FormData): Promise<Response> => {
  return await http.post("/api/bka", payload);
};

export const getBKAList = async (params: string): Promise<Response> => {
  return await http.get(`/api/bka${params}`);
};

export const getBKACostTypes = async (): Promise<Response> => {
  return await http.get("/api/bka/costs/types");
};

export const deleteBKA = async (bkaId: number): Promise<Response> => {
  return await http.delete(`/api/bka/${bkaId}`);
};

export const multiCostBKA = async (payload: MultiCosts): Promise<Response> => {
  return await http.post("/api/bka/costs/multi", payload);
};

export const getCostsById = async (params: string): Promise<Response> => {
  return await http.get(`/api/bka/costs${params}`);
};
