import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  PAYMENTS_TABS,
  paymentsQueryParams,
  TRANSLATION_PREFIX,
} from "../utils";
import Table from "../../../ui/table/Table";
import useTable from "../../../ui/table/useTable";
import {
  BankAccount as BEBankAccount,
  BankAccountProperty,
  Payment,
} from "../../../types/be/bankAccount";
import {
  Button,
  Grid,
  Typography,
  TableCell,
  Link,
  Tooltip,
} from "@mui/material";
import { AreaAddIcon } from "../../../ui/button/icon/styled";
import camelCase from "lodash/camelCase";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { route } from "../../../utils/url";
import kebabCase from "lodash/kebabCase";
import { getBankAccount, getBankPayments } from "../../../api/bankAccount";
import { handleServerError } from "../../../utils/http";
import { itemsListHeadCells } from "./utils";
import { TableCell as TableCellType } from "../../../types/table";
import { Area } from "../../../types/be/area";
import IsIgnoredSwitch from "./IsIgnoredSwitch";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";
import AttachAreaToPayment from "./areas/AttachAreaToPayment";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { NumericFormat } from "react-number-format";
import {
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  Measurement_Units,
  THOUSAND_SEPARATOR,
} from "../../../constants";
import { Input, Search, SearchIconWrapper } from "../../../ui/table/styled";
import { Search as SearchIcon } from "react-feather";
import { useFetch } from "../../../hooks/useFetch";
import { BankAccount } from "../../../types/fe/bankAccount";
import { formatTimestamp } from "../../../utils/date";
/* istanbul ignore next */
const Payments = ({
  properties,
  setFetchBankPayments,
  errorTableOnSync,
  useOnWidget = false,
  selectedBankAccount,
}: {
  properties: BankAccountProperty[];
  setFetchBankPayments: React.Dispatch<React.SetStateAction<() => void>>;
  errorTableOnSync: string;
  useOnWidget: boolean;
  selectedBankAccount: BEBankAccount | undefined;
}): ReactElement => {
  const { tab, id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [headCells, setHeadCells] =
    useState<TableCellType[]>(itemsListHeadCells);
  const [payment, setPayment] = useState<Payment | null>(null);
  const [isAttachAreaModalOpen, setIsAttachAreaModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("all-sales");

  const handleOnAttachAreaIconClick = (payment: Payment): void => {
    setIsAttachAreaModalOpen(true);
    setPayment(payment);
  };
  const {
    data,
    setData,
    setTotal,
    total,
    order,
    orderBy,
    isLoading,
    setError,
    error,
    rowsPerPage,
    queryParams,
    setIsLoading,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
  } = useTable<Payment>();
  /* istanbul ignore next */
  useCustomEventListener("AttachEntityModalCloseEvent", async () => {
    fetchBankPayments();
  });

  const {
    data: bankAccount,
    isLoading: isBankAccountLoading,
    run: runBankAccount,
  } = useFetch<BankAccount>();

  useEffect(() => {
    setActiveTab("all-sales");
    runBankAccount(
      getBankAccount(useOnWidget ? String(selectedBankAccount!.id) : id)
    );
  }, [id, selectedBankAccount]);

  useEffect(() => {
    if (properties.length) setHeadCells(itemsListHeadCells);
    else
      setHeadCells(itemsListHeadCells.filter((cell) => cell.id !== "actions"));
  }, [properties]);

  const TableToolbar =
    id || selectedBankAccount?.id ? (
      <>
        <Grid
          container
          spacing={3}
          sx={{ pt: 2, pb: 2 }}
          role="payments-table-toolbar"
        >
          <Grid item xs />
          <Grid item>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t("search")} onChange={handleTableSearch} />
            </Search>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{ pt: 2, pb: 2 }}
          role="payments-table-toolbar"
        >
          {Object.values(PAYMENTS_TABS).map((item: string) => (
            <Grid item key={item}>
              <Button
                variant={"outlined"}
                disabled={
                  useOnWidget
                    ? item === `${TRANSLATION_PREFIX}${camelCase(activeTab)}`
                    : item === `${TRANSLATION_PREFIX}${camelCase(tab)}`
                }
                size="small"
                onClick={
                  /* istanbul ignore next */ () => handleOnTabClick(item)
                }
              >
                {t(item)}
              </Button>
            </Grid>
          ))}
          <Grid item xs />
          <Grid item>
            {!isBankAccountLoading && (
              <Typography variant="h4" mt={2}>
                {`${t("bankAccount.accountBalance")}: `}
                <NumericFormat
                  thousandSeparator={THOUSAND_SEPARATOR}
                  decimalSeparator={DECIMAL_SEPARATOR}
                  decimalScale={DECIMAL_SCALE}
                  fixedDecimalScale
                  displayType={"text"}
                  value={bankAccount?.amount}
                />{" "}
                {bankAccount?.amount !== null && Measurement_Units.EURO}
              </Typography>
            )}
          </Grid>
        </Grid>
      </>
    ) : (
      /* istanbul ignore next */ <></>
    );
  /* istanbul ignore next */
  const handleOnAreaNameClick = (area: Area): void => {
    window.open(
      route("properties.areas.edit.tab", area.property.id, area.id, "area"),
      "_blank"
    );
  };
  /* istanbul ignore next */
  const handleOnAttachAreaModalClose = (): void => {
    setPayment(null);
    emitCustomEvent("AttachEntityModalCloseEvent");
    setIsAttachAreaModalOpen(false);
  };

  const renderRow = (row: Payment): ReactElement => (
    <Fragment key={row.id}>
      <TableCell align={"left"}>
        <Typography noWrap>{row.description ?? "---"}</Typography>
      </TableCell>
      <TableCell align={"center"}>
        <Typography noWrap>{row.payment_sender}</Typography>
        <Typography variant="caption">{row.iban}</Typography>
      </TableCell>
      <TableCell align={"center"}>
        <IsIgnoredSwitch
          payment={row}
          fetchBankPayments={fetchBankPayments}
          setError={setError}
        />
      </TableCell>
      <TableCell align="right">
        {row.booking_date ? formatTimestamp(row.booking_date) : "---"}
      </TableCell>
      <TableCell align={"right"}>
        <Typography noWrap>
          <NumericFormat
            thousandSeparator={THOUSAND_SEPARATOR}
            decimalSeparator={DECIMAL_SEPARATOR}
            decimalScale={DECIMAL_SCALE}
            fixedDecimalScale
            displayType={"text"}
            value={row.amount}
          />{" "}
          {row.amount !== null && Measurement_Units.EURO}
        </Typography>
      </TableCell>
      <TableCell align={"center"}>
        {row.area ? (
          <Link
            onClick={() => handleOnAreaNameClick(row.area)}
            sx={{ cursor: "pointer" }}
          >
            {row.area.name}
          </Link>
        ) : (
          "---"
        )}
      </TableCell>
      {!!properties.length && (
        <TableCell align={"right"}>
          {!row.is_ignored ? (
            <Tooltip title={t("bankAccount.assignPaymentToArea")}>
              <AreaAddIcon
                data-testid="assign-payment-button"
                onClick={() => handleOnAttachAreaIconClick(row)}
              />
            </Tooltip>
          ) : (
            EMPTY_DATA
          )}
        </TableCell>
      )}
    </Fragment>
  );

  const handleOnTabClick = (navigateTab: string): void => {
    useOnWidget && setActiveTab(navigateTab.split(TRANSLATION_PREFIX)[1]);
    !useOnWidget &&
      navigate(
        route(
          `bank-account.${kebabCase(
            kebabCase(navigateTab.split(TRANSLATION_PREFIX)[1])
          )}`,
          id
        )
      );
  };

  const fetchBankPayments = useCallback(
    async (load = true) => {
      load && setIsLoading(true);
      const params = tab
        ? paymentsQueryParams[tab ?? ""]
        : paymentsQueryParams[kebabCase(activeTab) ?? ""];
      const bankId = useOnWidget ? selectedBankAccount?.id : id;
      const res = await getBankPayments(
        `${
          queryParams ? queryParams + "&" : "?"
        }bank_account_id=${bankId}&${params}`
      );
      if (res.status !== HTTP_STATUS_CODES.OK) {
        const { errorMessage } = handleServerError(res);
        setError(errorMessage);
      } else {
        const resJson = await res.json();
        setTotal(resJson.meta.total);
        setData(resJson.data);
        setIsLoading(false);
      }
    },
    [
      queryParams,
      setTotal,
      setData,
      setIsLoading,
      id,
      tab,
      selectedBankAccount,
      activeTab,
    ]
  );

  useEffect(() => {
    if ((!useOnWidget && !id) || (!useOnWidget && !queryParams)) return;
    setFetchBankPayments(() => fetchBankPayments);
    fetchBankPayments(true);
  }, [id, tab, queryParams, selectedBankAccount, activeTab]);

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error || errorTableOnSync}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={headCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        paperSx={{ width: "100%", mb: 2, pl: 2, pr: 6, py: 1 }}
        noDataIsAvailablePlaceholder={"table.noPaymentsAreAvailable"}
        tableDataMaxHeight={"65vh"}
      />
      {payment && (
        <AttachAreaToPayment
          onDoneClick={() => handleOnAttachAreaModalClose()}
          payment={payment}
          isModalOpen={isAttachAreaModalOpen}
          selectedBankAccount={selectedBankAccount}
          useOnWidget={useOnWidget}
        />
      )}
    </>
  );
};

export default Payments;
