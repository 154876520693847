import styled from "styled-components/macro";
import { Grid } from "@mui/material";

export const BankAccounts = styled(Grid)`
  padding: ${(props): string => `${props.theme.spacing(2)}`};
  border-right: ${(props) =>
    `3px solid ${props.theme.palette.background.default}`};};
`;
/* istanbul ignore next */
export const BankAccountListItem = styled(Grid).attrs(
  ({
    $isSelected,
    $isDisabled,
  }: {
    $isSelected: boolean;
    $isDisabled: boolean;
  }) => ({
    $isSelected: $isSelected || false,
    $isDisabled: $isDisabled || false,
  })
)`
  cursor: ${(props): string => (props.$isDisabled ? "default" : "pointer")};
  border-radius: 3px;
  min-height: 45px;
  padding: ${(props): string => `0 ${props.theme.spacing(2)}`};
  transition: all 0.1s ease;
  background: ${(props): string =>
    props.$isSelected ? props.theme.palette.background.active : ""};
  &:hover {
    background: ${(props): string => {
      const { hover, active } = props.theme.palette.background;
      return props.$isSelected ? active : hover;
    }};
  }
  &:active {
    background: ${(props): string => props.theme.palette.background.active};
  }
`;

export const BankAccountsPayments = styled(Grid)`
  max-height: 88vh;
  overflow: auto;
`;

export const ScrollableDiv = styled.div`
  height: 76vh;
  overflow: auto;
`;
