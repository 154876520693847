import { FormikValues } from "formik";
import { getFileOCR } from "../../../../api/files";
import { assignInbox, reAssignInbox } from "../../../../api/inbox";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { handleServerError } from "../../../../utils/http";
import {
  AssignFormInitProps,
  FormRequestProps,
  HandleFormSubmitProps,
} from "./types";
import {
  replaceMomentByTimestamp,
  replaceTimestampByMomentDatepicker,
} from "../../../../utils/date";
import { generalPropertyOption, parseNumber } from "../../../../utils/common";
import { INBOX_TYPES } from "../../utils";
import { getInvoiceById } from "../../../../api/invoices";
import { Contract, Invoice } from "../../../../types/be.interfaces";
import { pick } from "lodash";
import { getContractById } from "../../../../api/contracts";
import { getOffer } from "../../../../api/offer";
import { Offer } from "../../../../types/be/offer";
import { getInsurance } from "../../../../api/insurance";
import { Insurance } from "../../../../types/be/insurance";
import { getReminderById } from "../../../../api/reminder";
import { Reminder } from "../../../../types/be/reminder";
/* istanbul ignore next */
export const handleOsc = async (file_id: number): Promise<Response> => {
  const responseGetFileOCR = await getFileOCR(file_id);
  if (responseGetFileOCR.status !== HTTP_STATUS_CODES.OK) {
    const jsonGetFileOCR = await responseGetFileOCR.json();
    handleServerError(jsonGetFileOCR);
  }
  return responseGetFileOCR;
};

/* istanbul ignore next */
export const getAssignedData = async <T>(
  response: Response
): Promise<T | void> => {
  if (response) {
    if (response.status !== HTTP_STATUS_CODES.OK) {
      const jsonGetFileOCR = await response.json();
      handleServerError(jsonGetFileOCR);
    } else {
      const data = await response.json();
      return data.data;
    }
  }
};

/* istanbul ignore next */
export const setSelectedTypeData = (
  data: AssignFormInitProps,
  formData: AssignFormInitProps
): AssignFormInitProps => {
  return {
    ...formData,
    property: data?.property ?? formData.property,
  };
};

/* istanbul ignore next */
export const prepareFormIntialValue = (
  data: AssignFormInitProps
): AssignFormInitProps => {
  return {
    ...data,
    property_id: String(data.property?.id ?? ""),
    type_id:
      data.type?.code !== INBOX_TYPES.INBOX ? String(data.type?.id || "") : "",
    currency_code: data.currency_code ?? "",
    type_code: data.type?.code ?? "",
    contact_id: String(data.contact?.id ?? ""),
    assigned_user_id: data.assigned_user_id ?? "",
    contract_category_id: data.contract_category_id ?? "",
    amount: data.invoice_amount ?? "",
    topic_id: data.topic_id ?? "",
    cancellation_period: data.cancellation_period ?? "",
    invoice: data.invoice,
    invoice_id: data.invoice_id,
  };
};

/* istanbul ignore next */
export const setInvoiceData = async (
  id: string,
  formData: AssignFormInitProps,
  setFormData: (value: React.SetStateAction<AssignFormInitProps>) => void
): Promise<void> => {
  const response = await getInvoiceById(id);
  const invoiceData = await getAssignedData<Invoice>(response);
  invoiceData &&
    setFormData({
      ...formData,
      invoice_number: String(invoiceData.number),
      invoice_amount: String(invoiceData.amount),
      date: replaceTimestampByMomentDatepicker(invoiceData.date),
      currency_code: String(invoiceData.currency_code),
      property: invoiceData.property,
      property_id: String(invoiceData.property?.id ?? ""),
      file_name: invoiceData.file.name,
      assigned_user: invoiceData.assigned_user
        ? pick(invoiceData.assigned_user, ["id", "first_name", "last_name"])
        : undefined,
      assigned_user_id: String(invoiceData.assigned_user?.id ?? ""),
    });
};

/* istanbul ignore next */
export const setReminderData = async (
  id: string,
  formData: AssignFormInitProps,
  setFormData: (value: React.SetStateAction<AssignFormInitProps>) => void
): Promise<void> => {
  const response = await getReminderById(id);
  const reminderData = await getAssignedData<Reminder>(response);
  reminderData &&
    setFormData({
      ...formData,
      reminderInvoice: reminderData?.entity
        ? pick(reminderData?.entity, ["id", "number", "amount", "file","name"])
        : undefined,
      invoice_id: String(reminderData.relation_id),
      property: reminderData.property,
      property_id: String(reminderData.property?.id ?? ""),
      file_name: reminderData.file.name,
    });
};

/* istanbul ignore next */
export const setContractData = async (
  id: string,
  formData: AssignFormInitProps,
  setFormData: (value: React.SetStateAction<AssignFormInitProps>) => void
): Promise<void> => {
  const response = await getContractById(id);
  const contractData = await getAssignedData<Contract>(response);
  contractData &&
    setFormData({
      ...formData,
      currency_code: String(contractData.currency_code),
      property: contractData.property,
      property_id: String(contractData.property?.id ?? ""),
      contact_id: String(contractData.contact.id),
      file_name: contractData.file.name,
      assigned_user: contractData.assigned_user
        ? pick(contractData.assigned_user, ["id", "first_name", "last_name"])
        : undefined,
      assigned_user_id: String(contractData.assigned_user?.id ?? ""),
      contract_category: contractData.category,
      contract_category_id: String(contractData.category.id),
    });
};

/* istanbul ignore next */
export const setInsuranceData = async (
  id: string,
  formData: AssignFormInitProps,
  setFormData: (value: React.SetStateAction<AssignFormInitProps>) => void
): Promise<void> => {
  const response = await getInsurance(id);
  const insuranceData = await getAssignedData<Insurance>(response);
  insuranceData &&
    setFormData({
      ...formData,
      amount: String(insuranceData.amount ?? ""),
      property: insuranceData.property,
      property_id: String(insuranceData.property?.id ?? ""),
      contact_id: String(insuranceData.contact?.id ?? ""),
      file_name: insuranceData.file.name,
      assigned_user: insuranceData.assigned_user
        ? pick(insuranceData.assigned_user, ["id", "first_name", "last_name"])
        : undefined,
      assigned_user_id: String(insuranceData.assigned_user?.id ?? ""),
      topic_id: String(insuranceData.topic?.id ?? ""),
      topic: insuranceData.topic,
      cancellation_period: String(insuranceData.cancellation_period),
    });
};

/* istanbul ignore next */
export const setOfferData = async (
  id: string,
  formData: AssignFormInitProps,
  setFormData: (value: React.SetStateAction<AssignFormInitProps>) => void
): Promise<void> => {
  const response = await getOffer(id);
  const offerData = await getAssignedData<Offer>(response);
  offerData &&
    setFormData({
      ...formData,
      amount: String(offerData.amount ?? ""),
      property: offerData.property,
      property_id: String(offerData.property?.id ?? ""),
      contact_id: String(offerData.contact.id),
      file_name: offerData.file.name,
      assigned_user: offerData.assigned_user
        ? pick(offerData.assigned_user, ["id", "first_name", "last_name"])
        : undefined,
      assigned_user_id: String(offerData.assigned_user?.id ?? ""),
      topic_id: String(offerData.topic_id),
      topic: offerData.topic,
    });
};

/* istanbul ignore next */
export const prepareFromData = (
  data: AssignFormInitProps
): FormRequestProps => {
  const defaultValue = {
    property_id:
      data.property_id === generalPropertyOption.id ? null : Number(data.property_id ?? ''),
    file_name: data.file_name,
    dynamic_name: data.dynamic_name,
  };
  return {
    invoice:
      data.type_code === INBOX_TYPES.INVOICE
        ? {
            ...defaultValue,
            number: String(data.invoice_number ?? '' ),
            date: replaceMomentByTimestamp(data.date!),
            amount: parseNumber(data.invoice_amount!, true),
            is_permanent: false,
            is_active: true,
            is_apportionable: false,
            is_confidential: false,
            is_debited_automatically: false,
            comment: data.comment,
            contact_id: data.contact_id,
            assigned_user_id: data.assigned_user_id,
          }
        : undefined,
    contract:
      data.type_code === INBOX_TYPES.CONTRACT
        ? {
            ...defaultValue,
            contract_category_id: data.contract_category_id!,
            comment: data.comment,
            contact_id: data.contact_id!,
            assigned_user_id: data.assigned_user_id,
          }
        : undefined,
    offer:
      data.type_code === INBOX_TYPES.OFFER
        ? {
            ...defaultValue,
            amount: parseNumber(data.amount!, true),
            topic_id: data.topic_id!,
            comment: data.comment,
            contact_id: data.contact_id!,
            assigned_user_id: data.assigned_user_id,
          }
        : undefined,
    insurance:
      data.type_code === INBOX_TYPES.INSURANCE
        ? {
            ...defaultValue,
            amount: parseNumber(data.amount!, true),
            topic_id: data.topic_id!,
            comment: data.comment,
            contact_id: data.contact_id!,
            cancellation_period: data.cancellation_period!,
            assigned_user_id: data.assigned_user_id,
          }
        : undefined,
    reminder:
      data.type_code === INBOX_TYPES.REMINDER
        ? {
            ...defaultValue,
            comment: data.comment,
            relation_id: data.invoice_id!,
            relation: "invoice",
          }
        : undefined,
    attachment:
      data.type_code === INBOX_TYPES.ATTACHMENT
        ? {
            ...defaultValue,
            comment: data.comment,
          }
        : undefined,
        datev_invoice: data.type_code === INBOX_TYPES.DATEV_INVOICE
        ? {
            ...defaultValue,
            contact_id: data.contact_id!,
            assigned_user_id: data.assigned_user_id,
          }
        : undefined,
  };
};

/* istanbul ignore next */
export const handleAssignFormSubmit = ({
  id,
  setFormMessage,
  edit,
  onClose,
}: HandleFormSubmitProps) => {
  return async (
    data: AssignFormInitProps,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const reqData = prepareFromData(data);
    const response = edit
      ? await assignInbox(id, reqData)
      : await reAssignInbox(Number(id), reqData);
    const json = await response.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      setFormMessage(FormMessageInItState);
      onClose?.();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setSubmitting(false);
    }
  };
};
