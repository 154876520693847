import { FormMessageInterface } from "../../../../types/form";
import { AreaTenants, BKA } from "../../../../types/be/tenant";
import { Dispatch, SetStateAction } from "react";
import { Area } from "../../../../types/be/area";
import { FormikValues } from "formik";
import { TenantHistoryProps } from "./tabs/history/list/types";

export interface TabAreaControls {
  id: string;
  "aria-controls": string;
}

export type AreaProps = {
  submitCallback: () => void;
};

export enum TABS {
  AREA = "area",
  EMPTY_AREA = "empty-area",
  REQUESTS = "requests",
  COUNTERS = "counters",
  HISTORY = "history",
  TENANT = "tenant",
}

export interface TenantFormProps {
  submitCallback: () => void;
}

export interface RentalContractFormProps {
  submitCallback: () => void;
  isFutureTenant: boolean;
}
export interface DunningLettersFormProps {
  initialValues: Area;
  submitCallback: () => void;
}
export interface BKAFormProps {
  initialValues: Area;
  submitCallback: () => void;
}
export interface BKAItemProps {
  initialValues: BKA;
  submitCallback: () => void;
  setFormMessage: Dispatch<SetStateAction<FormMessageInterface>>;
}

export interface TenantsListModalProps {
  isTenantsListModalOpen: boolean;
  handleTenantsListModalClose: () => void;
  tenantAutocompleteValue: AreaTenants[] | undefined;
  handleRemoveTenant: (
    value: AreaTenants,
    { values, setFieldValue }: FormikValues
  ) => void;
  values: FormikValues;
  setFieldValue: FormikValues;
  handleSendCredentials: (value: AreaTenants) => void;
}

export interface HandleUpdateTenantProps {
  initialValues: Area;
  setFormMessage: Dispatch<SetStateAction<FormMessageInterface>>;
  onSuccessCallback: () => void;
  saveOnlyTenantsButtonClick: boolean;
  setSaveOnlyTenantsButtonClick: Dispatch<SetStateAction<boolean>>;
  setIsEditInfo: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditTenant: React.Dispatch<React.SetStateAction<boolean>>;
  setIsUploadingRentalContract: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface handleUpdateFutureTenantProps {
  initialValues: TenantHistoryProps | null;
  onSuccessCallback: () => void;
  setFormMessage: Dispatch<SetStateAction<FormMessageInterface>>;
}

export interface AreaFormTypes {
  formMessage: FormMessageInterface;
}
