import React, { useState, ReactElement } from "react";
import { Grid } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { Paper } from "./styled";
import Form from "./Form";
import {
  createCreditorInitState,
  handleCreateCreditorFormSubmit,
} from "./utils";
import useValidation from "../../../../../../hooks/useValidation";
import { FormMessageInterface } from "../../../../../../types/form";
import { FormMessageInItState } from "../../../../../../constants";
import FormAlert from "../../../../../../ui/formAlert/FormAlert";

const CreateCreditorRequest = (): ReactElement => {
  const [submitAction, setSubmitAction] = useState<string | null>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);

  const { creditor, company_name, property_id } = useValidation();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Paper sx={{ p: 5 }}>
          <Grid item>
            {formMessage.text && (
              <FormAlert
                formMessage={formMessage}
                sx={{ marginBottom: "10px" }}
              />
            )}
            <Formik
              initialValues={{
                ...createCreditorInitState,
                property_id: Number(id),
              }}
              validationSchema={Yup.object().shape({
                ...creditor,
                company_name,
                property_id,
              })}
              onSubmit={handleCreateCreditorFormSubmit({
                submitAction,
                setFormMessage,
                navigate,
                id,
              })}
            >
              {(props): ReactElement => (
                <Form
                  {...props}
                  submitAction={submitAction}
                  setSubmitAction={setSubmitAction}
                />
              )}
            </Formik>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default CreateCreditorRequest;
