export default {
  releaseAllSelectedContracts: "Alle ausgewählten Verträge freigeben",
  reviewAllSelectedContracts: "Überprüfung aller ausgewählten Verträge",
  rejectAllSelectedContracts: "Alle ausgewählten Verträge ablehnen",
  notSelectedContracts: "Bitte Vertrag auswählen",
  providerContracts: {
    providerContracts: "Provider contracts",
  },
  selectContractToDownload: "Zum Herunterladen bitte Vertrag auswählen",
};
