/* istanbul ignore file */

import React, { ReactElement } from "react";
import { VacancyRateProps } from "./types";
import { VacancyRateWrapper } from "./styled";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import {
  THOUSAND_SEPARATOR,
  DECIMAL_SEPARATOR,
  Measurement_Units,
} from "../../../../../../constants";

const VacancyRate = ({
  total_area_percentage,
}: VacancyRateProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <VacancyRateWrapper>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left" size="small" sx={{ fontWeight: 800 }}>
                {t("property.tenants.calculationResult.vacancyRate")}
              </TableCell>
              <TableCell align="right" size="small">
                <NumericFormat
                  thousandSeparator={THOUSAND_SEPARATOR}
                  decimalSeparator={DECIMAL_SEPARATOR}
                  displayType={"text"}
                  value={total_area_percentage}
                />
                {Measurement_Units.PERCENTAGE}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </VacancyRateWrapper>
  );
};

export default VacancyRate;
