import { TableCell } from "../../../../../types/table";
/* istanbul ignore next */
export const itemsListHeadCells: TableCell[] = [
  {
    id: "object_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertiesList.objectName",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "address",
  },
  {
    id: "property_type_title",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertiesList.propertyType",
  },
];

export const PROPERTY_CATEGORY_ID = {
  PROPERTY: 1,
  COMPANY: 2,
};
