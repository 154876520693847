import React, { ReactElement } from "react";
import { Grid, Link, Tooltip } from "@mui/material";
import { ExternalLink } from "react-feather";
import { EMPTY_DATA } from "../../../../constants";
import { File } from "../../../../types/be/file";
import { TableCell } from "../../styled";
import { DATEvImage, FileName } from "./styled";

interface FileTableCellProps {
  file?: File | null;
  showDatevIcon?: boolean;
}

const FileTableCell = ({
  file,
  showDatevIcon,
}: FileTableCellProps): ReactElement => {
  return (
    <TableCell align="left">
      {file ? (
        <Grid container>
          <Grid item>
            <Tooltip title={file.name} placement="top">
              <Link href={file?.url} target="_blank">
                <FileName>
                  {file.name}
                  {showDatevIcon && (
                    <DATEvImage src="/static/img/files/datev.png" />
                  )}
                </FileName>
              </Link>
            </Tooltip>
          </Grid>
          <Grid item>
            <Link target="_blank" rel="noreferrer" href={file?.url + "/show"}>
              <ExternalLink size="15" />
            </Link>
          </Grid>
        </Grid>
      ) : (
        EMPTY_DATA
      )}
    </TableCell>
  );
};

export default FileTableCell;
