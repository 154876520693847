import React, { ReactElement, useCallback, useContext, useRef } from "react";
import {
  PreviewPdfDialog,
  PreviewImage,
  PreviewImageDialog,
  PreviewDialogContent,
  PreviewDialogTitle,
  PreviewLoaderRoot,
} from "../styled";
import { Download as DownloadIcon } from "react-feather";
import { Box, Grid, IconButton } from "@mui/material";
import {
  FileManagementPreviewProps,
  PreviewDialogVisibility,
  SET_OPTIMIZED,
  SET_VISIBILITY,
} from "../types";
import PdfViewer from "../../../ui/pdfViewer";
import { isImage, isPdf } from "../../../types/be/file";
import Loader from "../../Loader";
import CloseButton from "../../../ui/dialog/CloseButton";
import { FileManagementFolderContainerContext } from "../contexts/FileManagementFolderContainerContext";
/* istanbul ignore next */
const FileManagementPreview = ({
  handleCloseDialog,
  handleDownloadFromPreview,
}: FileManagementPreviewProps): ReactElement => {
  const ref = useRef<HTMLObjectElement | null>(null);
  const {
    state: { isDialogVisible, previewFile, visibility, optimized, preloading },
    dispatch,
  } = useContext(FileManagementFolderContainerContext);

  const onLoad = useCallback(
    (e) => {
      if (e.target.clientWidth / e.target.clientHeight > 2) {
        dispatch({
          type: SET_OPTIMIZED,
          payload: true,
        });
      }
      dispatch({
        type: SET_VISIBILITY,
        payload: PreviewDialogVisibility.UNSET,
      });
    },
    [dispatch]
  );

  if (!previewFile) return <></>;

  return (
    <>
      {isImage(previewFile) && (
        <PreviewImageDialog
          fullWidth={optimized}
          maxWidth={!optimized ? false : "md"}
          open={isDialogVisible}
          onClose={handleCloseDialog}
          aria-labelledby="max-width-dialog-title"
          $visibility={visibility}
        >
          <PreviewDialogTitle>
            <Box>{previewFile?.name}</Box>
            <Box>
              <IconButton
                aria-label="close"
                data-testid="close-icon"
                role="close-icon"
                onClick={handleDownloadFromPreview}
              >
                <DownloadIcon size={20} />
              </IconButton>
              <CloseButton onClick={handleCloseDialog} color="action" />
            </Box>
          </PreviewDialogTitle>

          <PreviewDialogContent>
            <PreviewImage
              onLoad={onLoad}
              src={previewFile?.previewUrl}
              $optimized={optimized}
            />
          </PreviewDialogContent>
        </PreviewImageDialog>
      )}
      {isPdf(previewFile) && (
        <PreviewPdfDialog
          fullWidth
          maxWidth="xs"
          open={isDialogVisible}
          onClose={handleCloseDialog}
          aria-labelledby="max-width-dialog-title"
        >
          <PreviewDialogTitle>
            <Box>{previewFile?.name}</Box>
            <Box>
              <IconButton
                aria-label="close"
                data-testid="close-icon"
                role="close-icon"
                onClick={handleDownloadFromPreview}
              >
                <DownloadIcon size={20} />
              </IconButton>
              <CloseButton onClick={handleCloseDialog} color="action" />
            </Box>
          </PreviewDialogTitle>
          <PreviewDialogContent>
            <Grid container>
              <Grid
                item
                ref={ref}
                sx={{
                  width: "100%",
                }}
              >
                <PdfViewer
                  file={{
                    base64: previewFile.previewUrl,
                  }}
                  containerRef={ref}
                />
              </Grid>
            </Grid>
          </PreviewDialogContent>
        </PreviewPdfDialog>
      )}
      {preloading && (
        <PreviewLoaderRoot>
          <Loader />
        </PreviewLoaderRoot>
      )}
    </>
  );
};

export default FileManagementPreview;
