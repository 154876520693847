import { FormikValues } from "formik/dist/types";
import { mapBackendValidationErrors } from "../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { createContract, editContract } from "../../../api/contracts";
import { ContractForm as FEContract } from "../../../types/fe.interfaces";
import {
  replaceEntityById,
  replaceNullOrUndefinedByEmptyString,
  parseNumber,
  generalPropertyOption,
} from "../../../utils/common";
import {
  replaceMomentByTimestamp,
  replaceTimestampByMomentDateOrNull,
} from "../../../utils/date";
import {
  HandleContractsFormSubmitProps,
  ProviderContractListFilter,
} from "./types";
import { Contract as BEContract } from "../../../types/be.interfaces";
import pick from "lodash/pick";
import omit from "lodash/omit";
import { getReleaseRoute } from "../utils";
import { createContractFromInbox } from "../../../api/inbox";
import { OLD_CONTRACTS_FR_STATUS_ID } from "../maintenance/create/utils";

export const createContractFormInitState: FEContract = {
  property_id: "",
  file_id: "",
  contact_id: "",
  amount: "",
  currency_code: "",
  contract_category_id: "",
  is_old_contract: false,
  start_date: null,
  end_date: null,
  signing_date: null,
  cancellation_date: null,
  cancelled_at: null,
  area_id: "",
};

export const touchedInitState = {
  file_id: false,
  contact_id: false,
  amount: false,
  currency_code: false,
  contract_category_id: false,
  is_old_contract: false,
  start_date: false,
  end_date: false,
  signing_date: false,
  cancellation_date: false,
  cancelled_at: false,
};

/* istanbul ignore next */
export const prepareContractBeforeForm = (data: BEContract): FEContract => ({
  ...pick(
    {
      ...replaceNullOrUndefinedByEmptyString(
        omit(data, [
          "cancellation_date",
          "cancelled_at",
          "end_date",
          "signing_date",
          "start_date",
        ])
      ),
    },
    ["amount", "id"]
  ),
  contact: {
    ...pick({ ...replaceNullOrUndefinedByEmptyString(data.contact) }, [
      "id",
      "first_name",
      "last_name",
      "email",
    ]),
  },
  ...replaceTimestampByMomentDateOrNull({
    cancellation_date: data.cancellation_date,
    cancelled_at: data.cancelled_at,
    end_date: data.end_date,
    signing_date: data.signing_date,
    start_date: data.start_date,
  }),
  ...replaceEntityById({
    contact_id: data.contact,
    contract_category_id: data.category,
    file_id: data.file,
  }),
  is_old_contract: Boolean(data.is_old_contract),
  currency_code: data.currency_code,
  property: data.property || generalPropertyOption,
  property_id: data.property?.id || generalPropertyOption.id,
  area: data?.area,
  area_id: data?.area?.id?.toString() || "",
});

/* istanbul ignore next */
export const prepareContractForFrom = (reqData: FEContract): FEContract => {
  const newObj = { ...reqData };
  //Change date to timestamp
  newObj.start_date = replaceMomentByTimestamp(newObj.start_date);
  newObj.end_date = replaceMomentByTimestamp(newObj.end_date);
  newObj.signing_date = replaceMomentByTimestamp(newObj.signing_date);
  newObj.cancellation_date = replaceMomentByTimestamp(newObj.cancellation_date);
  newObj.cancelled_at = replaceMomentByTimestamp(newObj.cancelled_at);
  newObj.amount = parseNumber(newObj.amount, true);
  newObj.property_id = Number(newObj.property_id) || "";

  return newObj;
};
/* istanbul ignore next */
export const handleContractFormSubmit = ({
  id,
  setFormMessage,
  edit,
  navigate,
  inboxData,
  onClose,
  setContractModalValue,
}: HandleContractsFormSubmitProps) => {
  return async (
    data: FEContract,
    { setStatus, setSubmitting, setTouched }: FormikValues
  ): Promise<void> => {
    const reqData = prepareContractForFrom(data);
    const response = edit
      ? await editContract(reqData)
      : inboxData
      ? await createContractFromInbox(inboxData.id, reqData)
      : await createContract(reqData);
    const json = await response.json();
    /* istanbul ignore else */
    if ([200, 201].includes(response.status)) {
      setStatus({
        success: true,
        errors: {},
      });
      setTouched(touchedInitState);
      setFormMessage({ type: undefined, text: "" });
      !onClose &&
        !edit &&
        !inboxData &&
        navigate &&
        navigate(getReleaseRoute("contracts", Number(id)));
      inboxData && window.history.back();
      if (
        json?.data?.fr_status?.id === OLD_CONTRACTS_FR_STATUS_ID &&
        setContractModalValue
      ) {
        setContractModalValue(json?.data);
      }
      onClose && onClose();
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setSubmitting(false);
    }
  };
};
/* istanbul ignore next */
export const providerContractListFilterInit: ProviderContractListFilter = {
  contact_id: null,
};
