import styled from "styled-components/macro";
import React from "react";
import { spacing } from "@mui/system";

import {
  Box as MuiBox,
  Drawer as MuiDrawer,
  Grid,
  ListItemButton,
} from "@mui/material";

export const BrandLogo = styled.img`
  width: 100%;
  border-radius: 2px;
`;

export const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props): string => props.theme.typography.h5.fontSize};
  font-weight: ${(props): string => props.theme.typography.fontWeightMedium};
  color: ${(props): string => props.theme.sidebar.header.color};
  background-color: ${(props): string => props.theme.sidebar.header.background};
  font-family: ${(props): string => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props): string => props.theme.spacing(6)};
  padding-right: ${(props): string => props.theme.spacing(6)};
  flex-shrink: 0;
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props): string => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props): string =>
      props.theme.sidebar.header.background};
  }
`;

export const Box = styled(MuiBox)(spacing);

export const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

export const ClientLogo = styled.img`
  width: 100%;
`;

export const LoggedUser = styled(Grid)`
  color: ${(props): string => props.theme.sidebar.header.color};
`;

export const Footer = styled.div`
  background-color: ${(props): string =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props): string => props.theme.spacing(2.75)}
    ${(props): string => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  height: 70px;
`;

/* istanbul ignore next */
export const SkeletonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props): string => props.theme.spacing(2)};
  padding-top: ${(props): string => props.theme.spacing(3)};
  padding-right: ${(props): string => props.theme.spacing(7)};
  padding-bottom: ${(props): string => props.theme.spacing(3)};
  padding-left: ${(props): string => props.theme.spacing(8)};
`;
