export default {
  description: "Description",
  contactName: "Contact Name",
  report: "Report",
  inspectionAndMaintenanceInterval:
    "Inspection and maintenance intervals (legal)",
  contract: "Contract",
  order: "Order",
  units: "Units",
  lastMaintenance: "Last Maintenance",
  title: "Maintenance",
  maintenanceType: "Maintenance Type",
  type: "Type",
  typeManagement: "Type Management",
  typeCreatedSuccessfully: "Type created successfully",
  pleaseSelectMaintenanceType: "Please select maintenance type",
  pleaseSelectInspection:
    "Please select Inspection and maintenance intervals (legal)",
  descriptionLengthError: "description must be at most 255 characters",
  createUnit: "Create Unit",
  unitCreatedSuccessfully: "Unit created successfully",
  resolved: "Resolved",
};
