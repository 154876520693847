import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Grid, Tooltip, TablePagination } from "@mui/material";

import {
  Pagination,
  PropertyDetails,
  PropertyImages,
  PropertyName,
  PropertyRent,
  PropertySpace,
  PropertyType,
} from "./styled";
import { route } from "../../../../utils/url";
import "react-image-gallery/styles/css/image-gallery.css";
import { PropertyGridViewProps } from "../types";
import { TablePaginationActions } from "../../../../ui/table/TablePaginationActions";
import {
  Measurement_Units,
  THOUSAND_SEPARATOR,
  DECIMAL_SEPARATOR,
  DECIMAL_SCALE,
  EMPTY_DATA,
  NO_DATA_IS_AVAILABLE,
} from "../../../../constants";
import { NumericFormat } from "react-number-format";
import Loader from "../../../Loader";
import { useNavigate } from "react-router-dom";
import { ROWS_PER_PAGE_OPTIONS } from "./utils";
import Carousel from "../../../../ui/carousel";

/* istanbul ignore next */
const PropertyGridView = ({
  data,
  tableToolbar,
  isTenant,
  total,
  rowsPerPage,
  labelRowsPerPage = "table.rowsPerPage",
  isLoading,
  currentPage,
  onPageChange,
  onRowsPerPageChange,
  noDataIsAvailablePlaceholder = NO_DATA_IS_AVAILABLE,
}: PropertyGridViewProps): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
        role="table"
      >
        <Paper sx={{ width: "100%", mb: 2, px: 5, py: 1, height: "100%" }}>
          <Grid
            container
            height={"100%"}
            direction={"column"}
            justifyContent={"space-between"}
          >
            <Grid item width={"100%"} position="relative">
              <div role="table-toolbar">{tableToolbar}</div>
            </Grid>
          </Grid>
        </Paper>

        {isLoading ? (
          <Loader />
        ) : (
          <Grid container sx={{ pt: 5 }}>
            <Grid container>
              <Grid container display="flex" flexWrap="wrap" spacing={5}>
                {data?.map((item: any) => {
                  return (
                    <Grid
                      item
                      key={item?.id}
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                      data-attr={item?.id}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent="center"
                    >
                      <Grid
                        onClick={() =>
                          navigate(
                            !isTenant
                              ? route("properties.edit", item.id, "general")
                              : route("properties.areas", item.id)
                          )
                        }
                      >
                        <PropertyImages>
                          <Carousel
                            images={[
                              ...(item?.images || []),
                              ...(item?.videos || []),
                            ]}
                            defaultImagePath="/static/img/files/propertyDefault.png"
                          />
                        </PropertyImages>
                        <PropertyDetails>
                          <Tooltip title={item.object_name} placement="top">
                            <PropertyName>{item.object_name}</PropertyName>
                          </Tooltip>
                          {item.property_type.name ? (
                            <Tooltip
                              title={item.property_type.name}
                              placement="top"
                            >
                              <PropertyType>
                                {item.property_type.name}
                              </PropertyType>
                            </Tooltip>
                          ) : (
                            EMPTY_DATA
                          )}
                          <PropertySpace
                            className={`${
                              item.rented_area === item.total_area
                                ? "gray"
                                : item.rented_area === 0
                                ? "red"
                                : "yellow"
                            }`}
                          >
                            {t("property.rented")}:{" "}
                            <NumericFormat
                              thousandSeparator={THOUSAND_SEPARATOR}
                              decimalSeparator={DECIMAL_SEPARATOR}
                              displayType={"text"}
                              value={item.rented_area}
                            />{" "}
                            {t("property.from")}{" "}
                            <NumericFormat
                              thousandSeparator={THOUSAND_SEPARATOR}
                              decimalSeparator={DECIMAL_SEPARATOR}
                              displayType={"text"}
                              value={item.total_area}
                            />
                            {Measurement_Units.SQUARE_METER}
                          </PropertySpace>
                          <PropertyRent>
                            {t("property.rentalIncomePerYear")}:{" "}
                            <NumericFormat
                              thousandSeparator={THOUSAND_SEPARATOR}
                              decimalSeparator={DECIMAL_SEPARATOR}
                              fixedDecimalScale
                              decimalScale={DECIMAL_SCALE}
                              displayType={"text"}
                              value={item.net_rent_per_year}
                            />
                            {item.net_rent_per_year !== null &&
                              Measurement_Units.EURO}
                          </PropertyRent>
                        </PropertyDetails>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>

              <Grid container justifyContent={"center"}>
                <Grid item>
                  {isLoading && <Loader />}
                  {!data?.length && !isLoading && (
                    <Box>{t(noDataIsAvailablePlaceholder)}</Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Pagination>
              {data && data.length > 0 && !isLoading && !!total && (
                <TablePagination
                  data-testid={"table-pagination"}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  component="div"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={t(labelRowsPerPage)}
                  page={currentPage}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  ActionsComponent={TablePaginationActions}
                />
              )}
              {!data?.length && <Box sx={{ height: "50px" }} />}
            </Pagination>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default PropertyGridView;
