/* istanbul ignore next */
import styled from "styled-components/macro";
import { Paper } from "@mui/material";
import { grey, orange } from "@mui/material/colors";
/* istanbul ignore next */
export const FileWrapper = styled(Paper).attrs(
  ({ $height, $width }: { $height: string; $width: string }) => ({
    $height: $height || "120px",
    $width: $width || "120px",
  })
)`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  object-fit: cover;
  img {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
  }
`;

export const Button = styled.div`
  position: relative;
  top: 2px;
  right: 2px;
  width: 22px;
  height: 18px;
  border-radius: 2px;
  margin-left: 3px;
  background: ${orange[500]};
  border: 1px solid ${grey[200]};
  z-index: 5;
  opacity: 0.9;

  &:hover {
    cursor: pointer;
    background: ${orange[700]};
  }
  &:active {
    background: ${orange[800]};
  }
`;

export const FileNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 0;
  opacity: 1;
  position: relative;
  z-index: 11;
  padding: 0 5px;
`;

export const FileName = styled.div`
  top: 25px;
  position: relative;
  color: white;
  z-index: 10;
  font-size: 10px;
  border-radius: 3px;
  line-height: 0.9;
  background: rgba(0, 0, 0, 0.59);
  height: fit-content;
  padding: 3px;
`;
