import styled from "styled-components";
import {
  DialogContent as MUIDialogContent,
  TableRow as MUITableRow,
} from "@mui/material";
import { TENANT_STATUS } from "../../../../../../../constants";

/*istanbul ignore next*/
export const DialogContent = styled(MUIDialogContent)`
  margin-bottom: 0;
  padding-bottom: 0;
  .MuiPaper-rounded {
    background-color: ${(props): string =>
      props.theme.palette.background.default};
  }
`;

/*istanbul ignore next*/
export const TableRow = styled(MUITableRow)<{
  $tenantStatus: TENANT_STATUS;
}>`
  background-color: ${(props): string =>
    props?.$tenantStatus === TENANT_STATUS.IS_FUTURE
      ? props.theme.palette.lightBlue
      : props?.$tenantStatus === TENANT_STATUS.IS_CURRENT
      ? props.theme.palette.lightGreen
      : props.theme.palette.lightGrey};
  .MuiTableCell-body {
    color: black !important;
  }
`;
