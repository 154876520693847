import http from "../utils/http";
import { IdPropType } from "../types/models";
import {
  ReleaseProcessRoles,
  Invoice as FEInvoice,
} from "../types/fe.interfaces";
import { DateVInvoice, Invoice } from "../types/fe/invoice";
import { RELEASE_LEVEL } from "../utils/common";

export const getInvoices = async (params: string): Promise<Response> => {
  return await http.get(`/api/invoices${params}`);
};

export const getInvoice = async (id?: string): Promise<Response> => {
  return await http.get(`/api/invoices/${id}`);
};

export const getInvoiceById = async (invoiceId: string): Promise<Response> => {
  return await http.get(`/api/invoices/${invoiceId}`);
};

export const deleteInvoice = async ({ id }: IdPropType): Promise<Response> => {
  return await http.delete(`/api/invoices/${id}`);
};
/* istanbul ignore next */
export const createInvoice = async (data: FEInvoice): Promise<Response> => {
  return await http.post(`/api/invoices`, data);
};
/* istanbul ignore next */
export const createMultipleInvoices = async (data: {
  invoice: FEInvoice[];
}): Promise<Response> => {
  return await http.post(`/api/invoices/multi-upload`, data);
};

export const updateInvoice = async (
  data: Partial<Invoice>
): Promise<Response> => {
  return await http.put(`/api/invoices/${data.id}`, data);
};

/*
 *  Invoice statuses
 */
export const getInvoiceStatuses = async (): Promise<Response> => {
  return await http.get(`/api/invoices/statuses`);
};

/* istanbul ignore next */
export const updateInvoiceStatus = async (
  invoiceId: number,
  status_id: number,
  who: ReleaseProcessRoles
): Promise<Response> => {
  return http.patch(
    `/api/invoices/${invoiceId}/statuses/${status_id}/${who}`,
    {}
  );
};

export const updateBulkInvoiceStatus = async (
  invoiceId: number[],
  status_id: number
): Promise<Response> => {
  return http.post(`/api/bulk-invoices/${status_id}/statuses`, {
    ids: invoiceId,
  });
};

export const forwardDocument = async (
  entityId: number,
  entityType: string,
  params: string
): Promise<Response> => {
  return await http.get(`/api/${entityType}/${entityId}/send${params}`);
};

export const toogleInvoicePaid = async (
  invoiceId: number,
  status: number
): Promise<Response> => {
  return await http.patch(`/api/invoices/${invoiceId}/is-paid`, {
    is_paid: status,
  });
};

export const toggleBulkInvoicePaidArray = async (
  invoiceId: number[],
  status: boolean
): Promise<Response> => {
  return await http.post(`/api/bulk-invoices/is-paid`, {
    is_paid: status,
    ids: invoiceId,
  });
};

/* istanbul ignore next */
export const getInvoicesHistory = async (params: string): Promise<Response> => {
  return await http.get(`/api/invoice-histories${params}`);
};

/* istanbul ignore next */
export const forwardRequiredReleaseToAdmin = async (
  invoiceId: number | number[],
  text?: string
): Promise<Response> => {
  return await http.post(`/api/invoices/${invoiceId}/final-releaser`, {
    text,
  });
};

/* istanbul ignore next */
export const releaseInvoice = async (
  id: string,
  level: RELEASE_LEVEL,
  params: string
): Promise<Response> => {
  return await http.patch(`/api/invoices/${id}/${level}${params}`, {});
};

/* istanbul ignore next */
export const createDateVInvoice = async (
  payload: DateVInvoice
): Promise<Response> => {
  return await http.post(`/api/datev/invoices`, payload);
};

/* istanbul ignore next */
export const getDateVInvoiceById = async (id: string): Promise<Response> => {
  return await http.get(`/api/datev/invoices/${id}`);
};

/* istanbul ignore next */
export const editDateVInvoice = async (
  id: string,
  payload: DateVInvoice
): Promise<Response> => {
  return await http.put(`/api/datev/invoices/${id}`, payload);
};

export const getInvoicePeriod = async (): Promise<Response> => {
  return await http.get(`/api/repetition-periods`);
};
