import { TableCell } from "../../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "property_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "propertyName",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "invoiceName",
  },
  {
    id: "number",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "number",
  },
  //this next will be sent date not ready on BE
  {
    id: "sent_date",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.outgoingInvoice.sentDate",
    align: "center",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.date",
    align: "center",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "amount",
    align: "right",
  },
  {
    id: "latest_comment",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "comment",
    align: "left",
  },
  {
    id: "is_active",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "active",
  },
  {
    id: "is_permanent",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "permanent",
  },
  {
    id: "contract_file_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "contractFileName",
  },
  {
    id: "offer_file_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "offerFileName",
  },
  {
    id: "uploadedBy",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "uploadedBy",
    align: "left",
  },
  {
    id: "uploaded_on",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "offers.uploadedOn",
    align: "left",
  },
  {
    id: "is_booked",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "documentRelease.invoice.markAsBooked",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "",
  },
];
