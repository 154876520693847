import {
  FIRST_NAME_MAX_CHARACTERS,
  FIRST_NAME_MIN_CHARACTERS,
  LAST_NAME_MIN_CHARACTERS,
  LAST_NAME_MAX_CHARACTERS,
  NOTES_MAX_CHARACTERS,
  OBJECT_NAME_MAX_CHARACTERS,
  OBJECT_NAME_MIN_CHARACTERS,
  PURCHASE_PRICE_MAX_CHARACTERS,
  PURCHASE_PRICE_MIN_CHARACTERS,
  MOBILE_MAX_CHARACTERS,
  ZIP_CODE_LENGTH,
  MINIMUM_YEAR,
  MOBILE_MIN_CHARACTERS,
} from "../constants";
import tenantRequest from "./tenantRequest/de";
import google from "./google/de";
import fileUploader from "../ui/fileUploader/langs/de";
import property from "./property/de";
import documentRelease from "./documentRelease/de";
import transactionManagement from "./transactionManagement/de";
import settings from "./settings/de";
import sidebar from "./sidebar/de";
import comments from "./comments/de";
import profile from "./profile/de";
import vacancies from "./vacancies/de";
import datev from "./datev/de";
import company from "./company/de";

export default {
  translation: {
    sidebar,
    tenantRequest,
    google,
    fileUploader,
    property,
    documentRelease,
    settings,
    comments,
    transactionManagement,
    profile,
    vacancies,
    datev,
    company,
    copied: "Kopiert",
    copyLink: "Link kopieren",
    edit: "Bearbeiten",
    loading: "Wird geladen",
    deleteAll: "Alles löschen",
    createContact: {
      createNewContact: "Neuen Kontakt erstellen",
      searchContact: "Kontakt suchen",
      additionalInfo: "Zusätzliche Informationen",
      contactDetails: "Kontaktdetails",
      contactAddress: "Adresse",
      contactOrCompanyAddress: "Unternehmen/ Kontaktadresse",
      finance: "Finanzen",
      searchProfile: "Suchprofile",
      additionalInformation: "Zusätzliche Information",
      businessPhone: "Telefon geschäflich",
      businessPhoneOnly: "Geschäftliches Telefon ist erforderlich",
      createCompany: "Unternehmen erstellen",
      title: "Titel",
      firstName: "Vorname",
      lastName: "Nachname",
      privatePhone: "Privates Telefon",
      miscellaneousPhone: "Verschiedenes Telefon",
      city: "Stadt",
      company: "Firma",
      zipCode: "Postleitzahl",
      street: "Straße",
      houseNumber: "Hausnummer",
      country: "Land",
      state: "State",
      mobile: "Mobiltelefon",
      category: "Kategorie",
      worldwideBranches: "Weltweite niederlassungen",
      branches: "Branche",
      source: "Quelle",
      ranking: "Rangfolge",
      assetsInMillion: "Vermögen in Mio",
      competentArea: "kompetenter Bereich",
      websiteUrl: "Webadresse",
      purchaseProfileUrl: "Kaufprofil-URL",
      management: "Verwaltung",
      focus: "Fokus",
      note: "Notiz",
      tags: "Stichworte",
      financeProfiles: "Finanzprofile",
      email: "Email",
      cc: "CC",
      bcc: "BCC",
      enterEmail: "Email eingeben",
      salutationId: "Anrede",
      oneOfPhoneNumberIsRequired:
        "Eines der Telefon geschäflich, Mobiltelefon, Privates Telefon or Verschiedenes Telefon muss eingegeben werden",
      contactType: "Kontaktart",
      contactWasSuccessfullyCreated: "Kontakt wurde erfolgreich erstellt!",
      contactWasSuccessfullyUpdated:
        "Der Kontakt wurde erfolgreich aktualisiert!",
      contactWasSuccessfullyDeleted: "Kontakt wurde erfolgreich gelöscht!",
      searchProfileWasSuccessfullyUpdated:
        "Das Suchprofil wurde erfolgreich gespeichert!",
      financeProfileWasSuccessfullyUpdated:
        "Das Finanzprofil wurde erfolgreich gespeichert!",
      companyWasSuccessfullyCreated:
        "Das Unternehmen wurde erfolgreich gegründet!",
      createContact: "Kontakt erstellen",
    },
    createCompany: {
      createCompany: "Unternehmen erstellen",
      companyType: "Firmentyp",
      companyTypeGeneral: "Allgemein",
      companyTypePropertyManagement: "Immobilienverwaltung",
    },
    searchProfile: {
      searchProfile: "Suchprofil",
      title: "Titel",
      expansion: "Erweiterung",
      typeId: "Typ",
      plotAreaFrom: "Grundstücksfläche von",
      plotAreaTo: "Grundstücksfläche bis",
      saleAreaFrom: "Verkaufsfläche von",
      saleAreaTo: "Verkaufsfläche bis",
      rentableAreaFrom: "Vermietbare Fläche von",
      rentableAreaTo: "Vermietbare Fläche bis",
      officeAreaFrom: "Bürofläche von",
      officeAreaTo: "Bürofläche bis",
      practiceAreaFrom: "Praxisfläche von",
      practiceAreaTo: "Praxisfläche bis",
      commercialAreaFrom: "Gewerbefläche von",
      commercialAreaTo: "Gewerbefläche bis",
      livingAreaFrom: "Wohnfläche von",
      livingAreaTo: "Wohnfläche bis",
      otherAreaFrom: "Sonstige Flächen von",
      otherAreaTo: "Sonstige Flächen bis",
      constructionYear: "Baujahr",
      inhabitantsFrom: "Einwohner von",
      inhabitantsTo: "Einwohner bis",
      renovation: "Letzte Renovierung",
      zipCode: "Postleitzahl",
      notes: "Anmerkungen",
      floors: "Etagen",
      minSales: "Mindestumsatz",
      bnl: "BNL",
      confirmationTitle:
        "Möchten Sie wirklich ohne Speichern zum nächsten Schritt gehen?",
      mainRoad: "Hauptverkehrsstr",
      isHolidayDestination: "Urlaubsort",
      source: "Quelle",
      propertyTypeId: "Objekttyp",
      usageId: "Nutzung",
      rankingId: "Rangfolge",
      searchProfileZipCode: `Sollte ${ZIP_CODE_LENGTH} Zeichen lang sein`,
      cancelEdit: "Bearbeiten abbrechen",
    },
    financeProfile: {
      state: "Bundesland",
      title: "Finanzprofile",
      addSearchProfile: "Suchprofil hinzufügen",
      zipCode: "Postleitzahl",
      state_id: "Zustands-ID",
      financeVolumeFromValue: "Finanzvolumen (min)",
      financeVolumeToValue: "Finanzvolumen (max)",
      ltv: "LTV (max)",
      avgInterestRate: "Durchschnittlicher Zinssatz (%)",
      avgRepayment: "AVG-Rückzahlung",
    },
    form: {
      cancellationPeriod: "Cancellation Period is required",
      releaseAm: "Bitte AM-Empfehlung auswählen",
      emailIsRequired: "E-Mail ist erforderlich",
      insuranceType: "Versicherungstyp ist erforderlich",
      firstNameIsRequired: "Vorname ist erforderlich",
      firstNameMaxCharacters: `Der Vorname sollte weniger als ${FIRST_NAME_MAX_CHARACTERS} Zeichen lang sein`,
      firstNameMinCharacters: `Der Vorname sollte mindestens ${FIRST_NAME_MIN_CHARACTERS} Zeichen lang sein`,
      lastNameIsRequired: "Nachname ist erforderlich",
      lastNameMaxCharacters: `Der Nachname sollte weniger als ${LAST_NAME_MAX_CHARACTERS} Zeichen lang sein`,
      lastNameMinCharacters: `Der Nachname sollte mindestens ${LAST_NAME_MIN_CHARACTERS} Zeichen lang sein`,
      mobileNumberIsRequired: "Handynummer ist erforderlich",
      mobileNumberMustStartsWith: "Handynummer muss mit +49 beginnen",
      mobileNumberError: `Handynummer sollte mehr als ${MOBILE_MIN_CHARACTERS} Zeichen haben`,
      mobileNumberMaxError: `Die Handynummer sollte höchstens ${MOBILE_MAX_CHARACTERS} Zeichen lang sein.`,
      mustBeValidEmail: "Muss eine gültige E-Mail sein",
      passwordConfirmationIsRequired: "Passwortbestätigung ist erforderlich",
      passwordIsRequired: "Passwort ist erforderlich",
      passwordsMustMatch: "Passwörter müssen übereinstimmen",
      phoneNumberIsRequired: "Telefonnummer ist erforderlich",
      phoneNumberMustStartsWith: "Telefonnummer muss mit +49 beginnen",
      phoneNumberError: `Telefonnummer sollte mehr als ${MOBILE_MIN_CHARACTERS} Zeichen haben`,
      phoneNumberMaxError: `Die Telefonnummer sollte höchstens ${MOBILE_MAX_CHARACTERS}  Zeichen lang sein.`,
      salutationIsRequired: "Anrede ist erforderlich",
      roleIsRequired: "Bitte wählen Sie eine Rolle aus",
      notesMaxCharacters: `Notizen sollte weniger als ${NOTES_MAX_CHARACTERS} Zeichen lang sein`,
      contactTypeIsRequired: "Kontaktart ist erforderlich",
      contactAddressIsRequired:
        "Bitte geben Sie die vollständige Adresse (Ort, Straße, Hausnummer) an.",
      notesIsRequired: "Notizen ist erforderlich",
      objectNameMaxCharacters: `Objekt name sollte weniger als ${OBJECT_NAME_MAX_CHARACTERS} Zeichen lang sein`,
      objectNameMinCharacters: `Objekt name sollte mindestens ${OBJECT_NAME_MIN_CHARACTERS} Zeichen lang sein`,
      objectNameIsRequired: "Objektname ist erforderlich",
      purchasePriceMaxCharacters: `Einkaufspreis sollte weniger als ${PURCHASE_PRICE_MAX_CHARACTERS} Zeichen lang sein`,
      purchasePriceMinCharacters: `Einkaufspreis sollte mindestens ${PURCHASE_PRICE_MIN_CHARACTERS} Zeichen lang sein`,
      purchasePriceIsRequired: "Einkaufspreis ist erforderlich",
      zipCodeIsRequired: "Postleitzahl ist erforderlich",
      streetIsRequired: "Strasse ist erforderlich",
      houseNumberIsRequired: "Hausnummer ist erforderlich",
      stateIsRequired: "Bundesland ist erforderlich",
      financeVolumeFromValueIsRequired:
        "Finanzierungsvolumen aus Wert ist erforderlich",
      financeVolumeToValueIsRequired:
        "Finanzierungsvolumen zum Wert ist erforderlich",
      ltvIsRequired: "ltv ist erforderlich",
      avgInterestRateIsRequired: "durchschnittlicher Zinssatz ist erforderlich",
      avgRepaymentIsRequired: "durchschnittliche Rückzahlung ist erforderlich",
      typeNumberOnly: "Sie müssen eine Zahl angeben",
      propertyTypeIsRequired: "Objekttyp ist erforderlich",
      contactIsRequired: "Kontakttyp ist erforderlich",
      companyNameIsRequired: "Firmenname ist erforderlich",
      companyTypeIsRequired: "Unternehmenstyp ist erforderlich",
      supervisorIsRequired: "Bauleiter ist erforderlich",
      expensesAreRequired: "Schwierigkeitsgrad d. Objektes ist erforderlich",
      taxNumberIsRequired: "Steuernummer ist erforderlich",
      vatIsRequired: "Umsatzsteuer-ID is required",
      architectIsRequired: "Architekt ist erforderlich",
      propertyManagementCostIsRequired:
        "Kosten für die Immobilienverwaltung sind erforderlich",
      cityIsRequired: "Ort ist erforderlich",
      assetManagerIsRequired: "Asset Manager ist erforderlich",
      titleIsRequired: "Titel ist erforderlich",
      nameIsRequired: "Name ist erforderlich",
      zipcodeLengthError: `Die PLZ muss ${ZIP_CODE_LENGTH} Zahlen enthalten`,
      descriptionIsRequired: `Beschreibung ist erforderlich`,
      select: "Auswählen",
      type_id: "Bitte Typ auswählen",
      number: "Bitte Nummer eingeben",
      bankNameIsRequired: "Bankname ist erforderlich",
      property_id: "Bitte Objekt auswählen",
      areaName: "Name des Gebiets ist erforderlich",
      area: "Bereich ist erforderlich",
      netRentPerMonth: "Nettomiete pro Monat ist erforderlich",
      rentStart: "Mietbeginn ist erforderlich",
      rentEnd: "Mietende ist erforderlich",
      otherIncomingsEur: "Zusätzliche Kosten sind erforderlich",
      propertyId: "Bitte Objekt auswählen",
      placeIdIsRequired: "Orts-ID ist erforderlich",
      contactIdIsRequired: "Kontakt is st erforderlich",
      propertyLocation: "Standort der Immobilie",
      countryIsRequired: "Land ist erforderlich",
      propertyAreaId: "Bitte Gebiet auswählen",
      readings: "Messungen sind erforderlich",
      invoiceId: "Bitte wählen Sie Rechnung",
      oldPasswordIsRequired: "Altes Passwort ist erforderlich",
      pleaseSelectYear: "Bitte wählen Sie Jahr",
      duplicateYearIsNotAllowed: "Duplikat Jahr ist nicht erlaubt",
      pleaseEnterHeadline: "Bitte geben Sie Überschrift ein",
      contactId: "Kontakt ist erforderlich",
      costTypeIsRequired: "Kostentyp ist erforderlich",
      contractExpiryDateIsRequired: "Vertragsablaufdatum ist erforderlich",
      contractExpiryDateShouldNotBeGreaterThanContractStartDate:
        "Vertragsablaufdatum darf nicht größer sein als Vertragsbeginndatum",
      kontoIsRequired: "Konto ist erforderlich",
      addresseeTypeCompanyIsRequired:
        "Name (Adressattyp Unternehmen) ist erforderlich",
      addresseeTypeIsRequired: "Adressattyp ist erforderlich",
      abbreviationIsRequired: "Kurzbezeichnung ist erforderlich",
      addressTypeIsRequired: "Adressart ist erforderlich",
      streetRequired: "Straße ist erforderlich",
      locationIsRequired: "Ort ist erforderlich",
      postalCodeIsRequired: "Postleitzahl ist erforderlich",
      codeCorrespondenceAddressIsRequired:
        "Kennz. Korrespondenzadresse ist erforderlich",
      creditorIsRequired: "Kreditor/Rechnungssteller ist erforderlich",
      dateOfPerformanceIsRequired: "Leistungsdatum ist erforderlich",
      bookingTextIsRequired: "Buchungstext ist erforderlich",
      amountNettoIsRequired: "Betrag netto ist erforderlich",
      debitAccountIsRequired: "Sollkonto ist erforderlich",
      atLeastOneFieldRequired:
        "Bitte wählen Sie entweder Benutzer, Kontakt oder E-Mail-Adresse aus.",
      fileShouldBeLessThan5MB: "Datei sollte kleiner als 5 MB sein",
    },
    stepper: {
      optional: "Optional",
    },
    contracts: {
      searchContract: "Vertrag suchen",
      uploadContract: "Vertrag hochladen",
      contract: "Vertrag",
      contractDetails: "Vertragsdetails",
      objectName: "Objektname",
      assetManager: "AM",
      contracts: "Verträge",
      fileName: "Dateiname",
      contractName: "Vertragsname",
      contactName: "Kontaktname",
      amount: "Betrag",
      createdBy: "Erstellt von",
      startDate: "Startdatum",
      endDate: "Endtermin",
      signingDate: "Unterzeichnungsdatum",
      cancellationUpTo: "Kündigung bis zum",
      canceledAt: "Gekündigt zum",
      category: "Kategorie",
      selectCategory: "Kategorie wählen",
      oldContract: "Altvertrag",
      createContract: "Vertrag erstellen",
      cancellationDate: "Kündigungsdatum",
      uploadFile: "Vertrag hochladen",
      editContract: "Vertrag bearbeiten",
      contractSaveSuccessfully: "Vertrag erfolgreich gespeichert",
      error: {
        fileId: "Vertrag ist erforderlich",
        contactId: "Kontakt ist erforderlich",
        contractCategoryId: "Kontaktkategorie ist erforderlich",
        pdfOnly: "Es sind nur PDF-Dateien erlaubt",
      },
      closedAt: "Unterzeichnet am",
      terminationAtTheLatest: "Kündigung bis spätestens",
    },
    optionalColumnsNames: {
      category: "Kategorie",
      cancelled_at: "Gekündigt zum",
      cancellation_date: "Kündigungsdatum",
      signing_date: "Unterzeichnungsdatum",
      end_date: "Endtermin",
      release_date: "Freigabedatum",
      is_debited_automatically: "Lastschrift",
      is_apportionable: "ist umlagefähig",
      is_permanent: "Dauerrechnung",
      is_active: "ist aktiv",
      offer_file_name: "Angebot",
      contract_file_name: "Vertrag",
      number: "Rechnungsnummer",
      due_date: "Fälligkeitsdatum",
      property_name: "Objektname",
      iban: "IBAN",
      bic: "BIC",
      invoice_amount: "Rechnungsbetrag",
      invoice_date: "Rechnungsdatum",
      invoice_number: "Rechnungsnummer",
    },
    table: {
      actions: "Aktionen",
      active: "Aktiv",
      firstName: "Name",
      fullName: "Name",
      id: "ID",
      inactive: "Inaktiv",
      lastName: "Nachname",
      permissionToRelease: "Freigabe",
      role: "Rolle",
      rowsPerPage: "Zeilen pro Seite",
      status: "Status",
      noDataIsAvailable: "Es sind keine Daten verfügbar",
      noUsersAreAvailable: "Keine Nutzer vorhanden",
      noDebitAccountAvailable: "Keine Debitkonten verfügbar",
      noInvoicesAreAvailable: "Keine Rechnungen vorhanden",
      noInsurancesAreAvailable: "Keine Versicherungen vorhanden",
      noOffersAreAvailable: "Keine Angebote vorhanden",
      noContractsAreAvailable: "Keine Verträge vorhanden",
      noRemindersAreAvailable: "Keine Mahnungen vorhanden",
      noActivitiesAreAvailable: "Keine Aktivitäten vorhanden",
      noCompanyOwnershipsAreAvailable: "Kein Firmeneigentum vorhanden",
      noEmployeeOverviewsAreAvailable: "Keine Mitarbeiter vorhanden",
      noVacationsAreAvailable: "Keine Urlaubsanträge vorhanden",
      noVacationRequestsAreAvailable: "Keine Urlaubsanträge vorhanden",
      noContactsAreAvailable: "Keine Kontakte vorhanden",
      noPaymentsAreAvailable: "Keine Zahlungen vorhanden",
      noLOIsAreAvailable: "Keine LOIs vorhanden",
      noPropertiesAreAvailable: "Keine Objekte vorhanden",
      noAreasAreAvailable: "Keine Flächen vorhanden",
      noInvoiceHistoryIsAvailable: "Es ist keine Rechnungshistorie verfügbar",
      noInventoryHistoryIsAvailable: "Der Inventar Verlauf ist leer",
      noLOIHistoryIsAvailable: "Der LOI Verlauf ist leer",
      noTenantHistoryIsAvailable: "Der Mieterverlauf ist leer",
      noRolesAreAvailable: "Es sind keine Rollen vorhanden",
      noTenantRequestsAreAvailable: "Es sind keine Mieteranfragen vorhanden",
      noCreditorsAreAvailable: "Es sind keine Gläubiger vorhanden",
      noContractHistoryIsAvailable: "Der Verträge Verlauf ist leer",
      noInsuranceHistoryIsAvailable: "Es liegt keine Versicherungshistorie vor",
      noOfferHistoryIsAvailable: "Es ist keine Angebotshistorie verfügbar",
    },
    usersList: {
      actions: "Aktionen",
      active: "Aktiv",
      firstName: "Vornamen",
      id: "ID",
      Inactive: "Inaktiv",
      lastName: "Nachname",
      role: "Rolle",
      status: "Status",
      permissionToRelease: "Erlaubnis zur Freigabe",
      hasPermissionsToRelease: "Hat die Erlaubnis",
      allUsers: "Alle Nutzer",
      activeUsers: "Aktive Nutzer",
      notActiveUsers: "Inaktive Nutzer",
      createUserTitle: "Benutzer erstellen",
      deleteUserTitle:
        "Möchten Sie den Benutzer {{userName}} wirklich Löschen ?",
    },
    propertiesList: {
      propertiesList: "Objektliste",
      create: "Neues Objekt anlegen",
      objectName: "Objektname",
      state: "Bundesland",
      zip: "PLZ",
      street: "Strasse",
      propertyType: "Objekttyp",
      rentedArea: "Vermietete Fläche",
      totalArea: "Gesamtfläche",
      rentedOut: "Vermietete Fläche",
      inPercentage: ` in %`,
      netRentPerYear: "Nettokaltmiete ",
      coldRent: "pro Jahr",
      potencialPerYear: "Potenzial ",
      perYear: "pro Jahr",
      wault: "WAULT",
    },
    insurance: {
      createTopic: "Thema erstellen",
      selectTopic: "Versicherungsthema auswählen",
      editTopic: "Thementitel bearbeiten",
      from: "aus",
      to: "zu",
      liability: "Haftpflichtversicherung",
      uploadfile: "Datei hochladen",
      building: "Gebäudeversicherung",
      title: "Versicherung",
      name: "Mieter",
      amount: "Betrag",
      cancellationPeriod: "Kündigungsfrist",
      requestRelease: "Freigabe beantragen",
      amRecommendation: "Empfehlung",
      createTitle: "Versicherung erstellen",
      createSuccess: "Versicherung erfolgreich erstellt",
      month: "monat",
      on: "Ja",
      off: "Nein",
      editSuccess: "Versicherung erfolgreich aktualisiert",
      editTitle: "Versicherung bearbeiten",
      months: "Monate",
      recommendation: "Empfehlung",
      insurancesPerTable: "Versicherungen pro Tisch",
      insuranceTopicsPerPage: "Versicherungsthemen pro Seite",
      date: "Versicherungsdatum",
      createTopicTitle: "Erstellen Sie ein Versicherungsthema",
      for: "für",
      period: "Für den Zeitraum",
      until: "bis",
    },
    tenant: {
      tenant_type_id: "Typ",
      tenant_type_name: "Typ",
      contact_name: "Kontaktname",
      name: "Mieter",
      tenant_id: "Mietername",
      area_name: "Flächenbezeichnung",
      net_rent_per_month: "IST-Netto pm",
      area: "Fläche",
      rent_start: "Mietbeginn",
      startRentDateNeedsToBeSelected:
        "Bitte wählen Sie zuerst das Datum des Mietbeginns",
      rent_end: "Mietende",
      is_cancelled: "gekün. / n.verl",
      comment: "Kommentar",
      uploadedBy: "Hochgeladen durch",
      cancellation_until: "Kündigung bis zum",
      closing_tenant_contract: "Abschluss MV",
      special_cancellation_right: "Sonderkündigunsrecht",
      deposit: "Kaution",
      reminder: "Mahnung",
      new: "Neu",
      asset_managers: "Asset Manager",
      options: "Optionen",
      remaining_term: "Restlaufzeit",
      remaining_term_in_eur: "Restlaufzeit in EUR",
      usage_name: "Nutzung",
      tenant_category_name: "Kategorie",
      index: "Indexierung",
      net_rent_per_m2: "Miete/m²",
      total_net_rent_per_year: "IST-Nettokaltmiete p.a.",
      total_net_rent_per_month: "Gesamt Netto p.m.",
      tax: "Steuern in %",
      total_gross_net_rent_per_month: "Gesamt Brutto p.m.",
      total_gross_net_rent_per_year: "Gesamt Brutto p.a.",
      others_incomings_eur: "NK netto p.m.",
      empty_space: "Leerstand in m² ",
      empty_space_in_euro: "Potenzielle monatliche Mieteinnahmen",
      empty_space_since_date: "Leerstand seit",
      empty_space_at_purchase: "Leerstand bei Ankauf",
      addIndividualColumns: "Individuelle Spalten hinzufügen",
      individualColumns: "Individuelle Spalt(en)",
      createArea: "Fläche Erstellen",
      propertyTenantTypeIsRequired: "Typ des Mieters ist erforderlich",
      propertyTenantCategoryIsRequired: "Mieterkategorie ist erforderlich",
      propertyTenantWasSuccessfullyCreated: "Mieter wurde erfolgreich erstellt",
      pdf: "PDF",
      export: "Export",
      vacancyInSqm: "Leerstand in m²",
      vacancyInEUR: "Leerstand EUR",
      url: "URL",
      immoscoutUpload: "Immoscout Upload",
      immoweltUpload: "Immowelt Upload",
      ebayUpload: "Ebay Upload",
      commissionedBroker: "Makler beauftragt (nicht exklusiv)",
      unlimited: "Unbefristet",
      option: "Möglichkeit",
      firstTenancy: "Vormietrecht",
      constOfConversion: "Kosten Umbau",
      rentFree: "Mietfrei in €",
      parkingLots: "Anzahl Stellplätze",
      miscellaneousEUR: "Sonstiges EUR",
      rentEUR: "Miete EUR/Stellplätze",
      rentalContact: "Mietvertrag",
      terminated: "Mietvertrag gekündigt",
      dateBKA: "Datum",
      amountBKA: "Betrag",
      paymentDateBKA: "Bezahldatum",
      upload: "Hochladen",
      chooseAFile: "Datei auswählen",
      noBKA: "Keine BKA",
      warning: "Mahnung",
      legalDunning: "Gerichtliches Mahnverfahren",
      installment: "Ratenzahlung/Kündigung",
      viewFile: "Datei anzeigen",
      wasSuccessfullyUpdated: "wurde erfolgreich erstellt",
      tenantList: "Mieterliste",
      type: "Typ",
      types: "typ(en)",
      areaName: "Flächenbezeichnung",
      netRentPerMonth: "Netto Mieteinnahmen pro Monat",
      areaInM2: "Fläche in m²",
      startRentDate: "Mietbeginn",
      endRentDate: "Mietende",
      canceledOrNotRenewed: "gekün. / n.verl",
      cancellationUntil: "Kündigung bis zum",
      conclusionOrRentalContract: "Abschluss MV",
      specialRightOfCancellation: "Sonderkündigunsrecht",
      assetManager: "AM",
      remainingTerm: "Restlaufzeit",
      remaining: "Restlaufzeit  in Euro",
      tenantCategory: "Kategorie",
      indexing: "Indexierung",
      netRentPerM2: "Miete pro m²",
      netRentPerYear: "Netto Mieteinnahmen pro Jahr",
      totalNetRentPerMonth: "Gesamt Netto p.m.",
      grossPerMonth: "Brutto Mieteinnahmen pro Monat",
      grossPerYear: "Brutto Mieteinnahmen pro Jahr",
      additionalCosts: "Nebenkosten pro Monat",
      additionalCostsPerYear: "Nebenkosten pro Jahr",
      emptySpace: "Leerstand in m²",
      emptySpaceInEuro: "Potenzielle monatliche Mieteinnahmen",
      emptySpaceSince: "Leerstand seit",
      emptySpaceAtPurchase: "Leerstand bei Ankauf",
      ok: "✓",
      cancel: "X",
      error: "Fehler",
      warningTextIsRequired: "Bitte Warntext eingeben",
      legalTextIsRequired: "Bitte Rechtstext eingeben",
      installmentTextIsRequired: "Bitte Ratentext eingeben",
      immoscoutRequired: "Bitte immoscout-URL eingeben",
      immowelRequired: "Bitte immowelt URL eingeben",
      ebayRequired: "Bitte geben Sie die eBay-URL ein",
      agentCommissionedRequired:
        "Bitte geben Sie die vom Agenten beauftragte URL ein",
      tenantUpdated: "Der Mieter wurde erfolgreich aktualisiert",
      surface: "Fläche",
      vacancyInfo: "Leerstand Infos",
      vacancyAds: "Leerstand Annoncen",
      rentalContract: "Mietvertrag",
      bka: "Betriebskostenabrechnung",
      warnings: "Mahnung",
      rentalAgreement: "Mietvertrag",
      tenant: "Mieter",
      vacancy: "Leerstand",
      tenantsList: "Mieterliste",
      commercialRented: "Commercial rented",
      livingRented: "Living rented",
      livingNotRented: "Living not rented",
      confirmation: "Ohne Speichern verlassen?",
      requests: "Anfragen",
      readings: "Lesungen",
      year: "Jahr",
      bkaTitle: "Betriebskostenabrechnung",
      history: {
        history: "Verlauf",
        extend: "Vertrag verlängern",
        show: "Details anzeigen",
        addFutureTenant: "Zukünftiger Mieter",
        success: "Zukünftiger Mieter wurde erfolgreich hinzugefügt",
        updateSuccess: "Zukünftiger Mieter erfolgreich aktualisiert",
        futureTenant: "Future tenant",
        pastTenants: "Mieterverlauf",
      },
      dateFuture: "Datum liegt in der Zukunft",
      editTenant: "Mieter bearbeiten",
      counters: "Zähler",
      selectedMoreThenOneTenantPartOne: "und",
      selectedMoreThenOneTenantPartTwo: "weitere",
      credentialsWasSent: "Berechtigungsnachweise wurden gesendet",
      tenantUserForMobileApp: "Nutzer von Mietern für die mobile App",
      tenantContact: "Kontakt zum Mieter",
      mobileUser: "Mobile Mieter App",
      inviteUser: "Benutzer einladen",
      inviteUserSuccessfully: "Benutzer erfolgreich einladen",
      resendCredentialSuccessfully:
        "Anmeldeinformationen erfolgreich erneut gesendet",
      invite: "Einladen",
      BKAYear: "BKA Jahr",
      BKACreatedSuccessfully: "BKA erfolgreich erstellt",
      download: "herunterladen",
      reminderSentDate: "Das Versanddatum der Erinnerung ist erforderlich",
      tenantContactInformation: "Mieterkontaktdaten",
      revenueOverview: "Einnahmenübersicht",
      detailsAboutTheRentedArea: "Angaben zur vermieteten Fläche",
      furtherDetails: "weitere Details",
      inviteMobileUserLimit: "Das Einladungslimit wurde erreicht",
      mobileUserText:
        "Die Mobile App für die Erfassung und Bearbeitung von Mieteranfragen und der Angabe von Zählerstandsinformationen kann von bis zu 3 Nutzern von den Mieter vorgeschlagenen Nutzer verwendet werden.",
      invoiceDate: "Rechnungsdatum",
      costType: "Kostentyp",
      dateOfPayment: "Zahlungsdatum",
      BKAUpdatedSuccessfully: "BKA erfolgreich aktualisiert",
      openingCost: "Eröffnungskosten",
    },
    offers: {
      offer: "Angebote",
      amount: "Betrag",
      offersPerTable: "Angebote pro tisch",
      topicsPerPage: "Themen pro seite",
      updatedSuccessfully: "Angebot erfolgreich aktualisiert",
      createOffer: "Angebot erstellen",
      uploadfile: "Angebotsdatei hochladen",
      selectTopic: "Angebotsthema auswählen",
      uploadPicture: "Bild hochladen",
      offerSaveSuccessfully: "Angebot erfolgreich gespeichert",
      show: {
        createdBy: "Erstellt von",
        contact: "Kontakt",
        file: "Datei",
        amount: "Betrag",
        picture: "Bild",
        amStatus: "AM Status",
        frStatus: "FR Status",
        releaseDate: "Veröffentlichungsdatum",
        assetManager: "Asset Manager",
        finalReleaser: "Finale Freigabe",
      },
      contact: "Kontakt",
      fileName: "Dateiname",
      topicId: "Themen-ID",
      releaseDate: "Freigabe",
      createdBy: "User",
      add: "Hinzufügen",
      delete: "Löschen",
      reset: "Zurücksetzen",
      topic: "Thema",
      topicWasSuccessfullyCreated: "Thema wurde erfolgreich erstellt",
      error: {
        fileId: "Angebotsdatei ist erforderlich",
        contactId: "Kontakt ist erforderlich",
        amount: "Betrag ist erforderlich",
        topic: "Angebotsthema ist erforderlich",
        pdfOnly: "Es sind nur PDF-Dateien erlaubt",
      },
      date: "Angebotsdatum",
      create: "Angebotstitel Erstellen",
      addOffers: "Angebote Hinzufügen",
      uploadedOn: "Hochgeladen am",
      providerOffers: {
        providerOffers: "Provider offers",
      },
    },
    active: "Aktiv",
    activeInvoice: "Rechnung aktiviert",
    address: "Adresse",
    addContacts: "Kontakte hinzufügen",
    analyticsDashboard: "Analyse Dashboard",
    birthday: "Geburtstag",
    dashboard: "Dashboard",
    disableFuture: "Das Datum sollte in der Vergangenheit liegen",
    setMinYear: `Sie können das Jahr ${MINIMUM_YEAR} oder später angeben`,
    users: "Nutzer",
    usersPerPage: "Nutzer pro Seite",
    user: {
      employees: "Mitarbeiterübersicht",
      management: "Benutzerverwaltung",
      activity: "Nutzeraktivitäten",
      ownership: "Unternehmenseigentum",
      vacationRequests: "Urlaubsanträge",
    },
    properties: "Objekte",
    createRroperties: "Eigenschaften erstellen",
    create: "Erstellen",
    post: "Post",
    name: "Name",
    createdAt: "Rechnungsdatum",
    addedAt: "Hinzugefügt am",
    comment: "Kommentare",
    contact: "Rechnungssteller",
    assignTo: "Zuweisen",
    invoiceName: "Rechnungsname",
    history: "Verlauf",
    invoiceFileName: "Name der Rechnungsdatei",
    personalInformation: "Persönliche Angaben",
    contactInformation: "Kontaktinformation",
    passwordChange: "Passwort ändern",
    search: "Suchen",
    typeToSearch: "Tippe um zu suchen",
    selected: "Wählten",
    state: "Bundesland",
    pages: "Seiten",
    list: "List",
    tag: "Tag",
    characters: "zeichen",
    close: "Schliessen",
    contacts: "Kontakte",
    files: "Dateien",
    uploadedBy: "Hochgeladen von",
    uploadedAt: "Hochgeladen am",
    createUser: "Benutzer erstellen",
    defaultDashboard: "Standard Dashboard",
    email: "E-mail",
    emailAddress: "E-Mail Adresse",
    emailIsRequired: "E-Mail ist erforderlich",
    english: "English",
    enterYourEmailToResetPassword: "Bitte erstellen Sie Ihr neues Passwort",
    enterYourEmailToResetYourPassword:
      "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen",
    errorSomethingWentWrong: "Fehler, etwas ist schief gelaufen!",
    onlyOneFutureTenant: "Es kann in Zukunft nur noch einen Mietvertrag geben",
    finish: "Beenden",
    firstName: "Vorname",
    forgotPassword: "Passwort vergessen",
    german: "Deutsch",
    invalidEmailAddress: "Ungültige E-Mail Adresse",
    lastName: "Nachname",
    mobileNumber: "Handy Nummer",
    mustBeValidEmail: "Muss eine gültige E-Mail sein",
    newPassword: "Neues Passwort",
    nextStep: "Nächste",
    password: "Passwort",
    oldPassword: "altes Kennwort",
    optionalColumns: "Optionale spalten",
    passwordConfirmation: "Passwort Bestätigung",
    confirmNewPassword: "Bestätige neues Passwort",
    passwordConfirmationIsRequired: "Passwortbestätigung ist erforderlich",
    passwordIsRequired: "Passwort wird benötigt",
    passwordMustBeAtLeast: "Passwort muss mindestens sein",
    passwordsMustMatch: "Passwörter müssen übereinstimmen",
    phoneNumber: "Telefonnummer",
    profilePicture: "Profilbild",
    crop: "Zuschneiden",
    updateImage: "Bild aktualisieren",
    profilePictureHintFOrFormat: `Die besten Ergebnisse erzielen Sie, wenn Sie ein Bild im Format .jpg verwenden, das mindestens 128 Pixel mal 128 Pixel groß ist.`,
    salutation: {
      label: "Anrede",
      mr: "Herr",
      mrs: "Frau",
      other: "Divers",
    },
    prevStep: "Zurück",
    reset: "Zurücksetzen",
    resetPassword: "Passwort zurücksetzen",
    sendLink: "Link senden",
    sendResetPasswordLink: "Link zum Zurücksetzen des Passworts senden",
    signIn: "Anmelden",
    signOut: "Ausloggen",
    somethingWentWrong: "Etwas ist schief gelaufen",
    stepperFinishMessage: "Alle Schritte abgeschlossen - Sie sind fertig",
    update: "Aktualisieren",
    userWasSuccessfullyCreated: "Benutzer wurde erfolgreich erstellt",
    userWasSuccessfullyUpdated: "Benutzer wurde erfolgreich aktualisiert",
    userWasSuccessfullyAdded: "Benutzer wurde erfolgreich hinzugefügt!",
    phoneNumberOrMobileNumberIsRequired:
      "Telefonnummer oder Handynummer ist erforderlich",
    weHaveMissedYou: "Wir haben dich vermisst",
    welcomeBack: "Willkommen zurück",
    weSentTheResetPasswordLinkOnYourEmail:
      "Wir haben den Link zum Zurücksetzen des Passworts per E-Mail gesendet",
    delete: "Löschen",
    cancel: "Abbrechen",
    save: "Speichern",
    deleteConfirmation: "Willst du wirklich löschen?",
    deleteOldUserActivity: "Wollen Sie wirklich alte Aktivitäten löschen?",
    externalCompany: "Extern Firma",
    sessionExpireTitle: "Ihre Sitzung ist abgelaufen",
    sessionExpireContent:
      "Ihre Sitzung ist abgelaufen, bitte melden Sie sich erneut an",
    greaterOrEqual: "muss größer oder gleich sein als",
    smallerOrEqual: "muss größer oder gleich sein als",
    wrongPhoneNumberFormat:
      "Falsches Zahlenformat. Beispiel: +49152901820 or 0049152901820",
    yes: "Ja",
    no: "Nein",
    pages404: {
      noClient: "Fehlender Kundenname",
      provideClientName: "Bitte geben Sie einen Kundennamen in der URL an",
      pageNotFound: "Seite nicht gefunden",
      pageWasRemoved:
        "Die von Ihnen gesuchte Seite wurde möglicherweise entfernt",
      returnToWebsite: "Zurück zur Website",
    },
    pages403: {
      noRightsForRoute: "Sie haben keine Berechtigung, diese Seite zu sehen",
      contactAdmin:
        "Wenden Sie sich für weitere Informationen an Ihren Administrator",
    },
    home: {
      seeMore: "Mehr anzeigen",
      allInvoices: "Alle Rechnungen",
      expiringTenants: "Ablaufende Mietverträge",
      lockDashboard: "Dashboard sperren",
      allInsurances: "Alle Versicherungen",
      allOffers: "Angebote",
      allContracts: "Alle Verträge",
      bankAccounts: "Bankkonten",
    },
    widgets: {
      users: "Benutzer",
      areaRented: "Vermietete Fläche",
      netRent: "Nettokaltmieteinnahmen",
      potential: "Potenzielle Mieteinnahmen",
      contacts: "Kontakte",
      expiringTenants: "Ablaufende Mietverträge",
      invoices: "Rechnungen",
      insurances: "Versicherung",
      offers: "Angebot",
      tenantTickets: "Mieteranfragen",
      contracts: "Verträge",
      properties: "Objekte (Karte)",
      total: "Anzahl",
      comments: "Kommentar",
      bankAccountPayments: "Zahlungen auf Bankkonten",
      accounts: "Konten",
      apiDATEvWidget: "API-DATEv",
    },
    provider: "Rechnungssteller",
    date: "Datum",
    dueDate: "Fälligkeitsdatum",
    propertyName: "Objektname",
    amount: "Rechnungbetrag",
    netRentalIncome: "Potenzielle Netto Mieteinnahmen pro Monat",
    total: "Umlagefähig Betrag",
    until: "bis zum",
    createdBy: "Hinzugefügt von",
    releaseDate: "Freigabedatum",
    debit: "Lastschrift",
    apportionable: "Umlagefähig",
    permanent: "Daurrechnung",
    contractFileName: "Vertrag",
    offerFileName: "Angebot",
    startTyping: "Beginnen Sie mit der Eingabe, um Optionen anzuzeigen",
    searchUser: "Nutzer suchen",
    send: "Senden",
    areaRented: "Vermietete Fläche",
    netRent: "Nettokaltmieteinnahmen",
    potential: "Potenzielle Mieteinnahmen",
    average: "Durchschnittlich",
    invoices: "Rechnungen",
    outgoingInvoice: "Ausgangsrechnungen",
    noOptions: "Keine Optionen",
    rename: "Umbenennen",
    createFolder: "Ordner erstellen",
    moveTo: "Umzug nach",
    uploadFile: "Datei hochladen",
    download: "Herunterladen",
    remove: "Entfernen",
    gotIt: "Ich habs",
    notifications: {
      markAsUnread: "Als ungelesen markieren",
      markAsRead: "Als gelesen markieren",
      markAllAsRead: "Alle als gelesen markieren",
      youHaveReadAllYourNotifications:
        "Sie haben keine neuen Benachrichtigungen",
    },
    fileManagement: {
      title: "Dateiverwaltung",
      folderWasSuccessfullyCreated: "Ordner wurde erfolgreich erstellt",
      nameWasSuccessfullyUpdated: "Name wurde erfolgreich aktualisiert",
      itemWasSuccessfullyDeleted: "Element wurde erfolgreich gelöscht",
      itemWasSuccessfullyMoved: "Artikel wurde erfolgreich verschoben",
      fileWasSuccessfullyUploaded: "Datei wurde erfolgreich hochgeladen",
      startingFileDownload: "Dateidownload starten",
      dropFilesHere: "Legen Sie die Dateien hier ab ...",
      dragAndDropSomeFilesHere:
        "Ziehen Sie einige Dateien hierher, oder klicken Sie, um Dateien auszuwählen",
      fileTypeIsNotSupported: "Dateityp wird nicht unterstützt",
      clickToDownloadArchive:
        "Klicken Sie hier, um das Archiv herunterzuladen.",
      fileIsDownloading: "Datei wird heruntergeladen...",
      list: {
        fileName: "Dateiname",
        size: "Grösse",
        type: "Typ",
        updatedAt: "Zuletzt bearbeitet",
        newFolder: "Neuer Ordner",
        folder: "Ordner",
      },
    },
    footer: {
      imprint: "Impressum",
      privacyTitle: "Datenschutzerklärung",
      privacy: "Datenschutzerklärung",
    },
    export: {
      pdf: "PDF exportieren",
      excel: "Excel exportieren",
    },
    from: "Von",
    to: "An",
    counter: {
      counter: "Zähler",
      heatingNumber: "Heiznummer",
      reading: "Lektüre",
      updatedBy: "aktualisiert von",
      searchCounter: "Zähler suchen",
      deleteConfirmation: "Sind Sie sicher, dass Sie löschen möchten?",
      noReadings: "Es liegen keine Messwerte vor",
      sidebarTitle: "Zählerstände",
      meter: "Zähler",
      penultimateReading: "Vorletzter Zählerstand",
      penultimateReadingDate: "Datum der vorletzten Zählerstandsablesung",
      lastReading: "Letzter Zählerstand",
      lastReadingDate: "Datum der letzten Zählerstandsablesung",
      unit: "Maßeinheit",
      searchProperty: "Objekt auswählen",
      searchArea: "Fläche auswählen",
      searchType: "Zählertyp auswählen",
      meterNumber: "Zählernummer",
      penultimateMeterReading: "Vorletzter Zählerstand",
      create: {
        createCounter: "Zähler erstellen",
        numberOfHeating: "Anzahl der Heizung",
        heatingCounter: "Heizungszähler",
        dateOfReading: "Datum der Lesung",
        heatingRequired: "Heizungsnummer ist erforderlich",
        heatingValueRequired: "Heizwert ist erforderlich",
        createdSuccess: "Zählerstand erfolgreich hinzugefügt",
        number: "Rechnungsnummer",
        sucessMessage: "Zähler erfolgreich erstellt",
        createMeter: "Zählerstand Hinzufügen",
        meterNumber: "Zählernummer angeben",
        searchMeterNumber: "Zählernummer Suchen",
        meterReadings: "Zählerständ hinzufügen",
      },
      readings: {
        createdBy: "Erstellt von",
        date: "Datum",
        value: "Wert",
        action: "Aktion",
        create: "Lesen schaffen",
      },
      images: "Bilder",
      counterId: "Zähler-ID",
      selectPropertyFirst: "Bitte Eigenschaft auswählen",
    },
    requests: {
      title: "Titel",
      description: "Beschreibung",
      status: "Status",
      all: "Alle",
      sendCredentialsSuccess:
        "Anmeldeinformationen wurden erfolgreich gesendet",
      create: {
        createRequest: "Anfragen",
        createdSuccess: "Anfragen wurde erfolgreicher  gestaltet",
        title: "Dateiverwaltung",
        description: "Bezeichnung",
      },
      edit: {
        editRequest: "Anfrage bearbeiten",
        editdSuccess: "Anfrage erfolgreich aktualisiert",
      },
      history: {
        title: "Verlauf aktualisieren",
        name: "Name",
        updatedAt: "aktualisiert am",
      },
    },
    employeeOverview: {
      newEntry: "Neuer Eintrag",
      locationOfficeManagement: "Standortmanagement",
      vacationHolidaysManagement: "Verwaltung der Feiertage",
      previous: "Vorherige",
      next: "Nächste",
      hide: "Ausblenden",
      locationName: "Standortnamen",
      createLocation: "Standort erstellen",
      editLocation: "Standort bearbeiten",
      hideEmployee: "Mitarbeiter ausblenden",
      user: "Benutzer",
      vacationHolidayWasSuccessfullyCreated:
        "Öffentlicher Feiertag wurde erfolgreich hinzugefügt",
      newScheduleWasSuccessfullyCreated:
        "Neuer Zeitplan wurde erfolgreich erstellt",
      vacationHolidayUserSelectionValidation: "Bitte Benutzer auswählen",
      allLocations: "Alle orte",
      errortheNameFieldIsRequired: "Das Namensfeld ist erforderlich.",
      description: "Beschreibung",
      employees: "Mitarbeiter",
      locationOffice: "Standort / Büro",
      locationOfficeIsRequired: "Standort / Büro ist erforderlich",
      homeOffice: "Heimbüro",
      scheduleWasSuccessfullyUpdated: "Zeitplan wurde erfolgreich aktualisiert",
      fieldService: "Außendienst",
      createdAt: "Created at",
      roles: "Rollen",
      allRoles: "Alle Rollen",
      vacationHolidayDatesRequired: "Bitte Datum auswählen",
      federalState: "Bundesländer",
      year: "Jahr",
      noUsersAttachedToCurrentRole:
        "Der aktuellen Rolle sind keine Benutzer zugeordnet",
    },
    companyOwnership: {
      title: "Firmeneigentum",
      brand: "Marke",
      type: "Typ",
      licensePlateSerialNumber: "Nummernschild / Seriennummer",
      employeeName: "Name des Mitarbeiters",
      receivedOnDate: "Empfangen am Datum",
      whoAdded: "Wer hat hinzugefügt",
      createdDate: "Erstellt am",
      user: "Benutzer",
      operation: "Betrieb",
      inventoryHistory: "Bestandshistorie",
      itemTypeIsRequired: "Artikelart ist erforderlich",
      brandIsRequired: "Marke ist erforderlich",
      departmentIsRequired: "Abteilung ist erforderlich",
      licensePlateSerialNumberIsRequired:
        "Nummernschild oder Seriennummer ist erforderlich",
      receivedOnDateIsRequired: "Empfangsdatum ist erforderlich",
      inventoryItemWasSuccessfullyCreated:
        "Inventarartikel wurde erfolgreich erstellt",
      inventoryItemWasSuccessfullyUpdated:
        "Inventarartikel wurde erfolgreich aktualisiert",
      department: "Abteilung",
      addEmployee: "Mitarbeiter hinzufügen",
      employeeRemovedSuccessfully: "Mitarbeiter erfolgreich entfernt",
      createCompanyOwnership: "Arbeitsmittel hinzufügen",
      continueCreatingInventory:
        "Möchten Sie weitere Arbeitsmittel hinzufügen?",
    },
    vacationRequests: {
      vacationList: "Urlaubsliste",
      approvalProcess: "Genehmigungsverfahren",
      managingVacationLengths: "Verwaltung der Urlaubsdauer",
      vacationDuration: "Dauer des Ferienaufenthalts",
      daysRemaining: "Verbleibende Tage",
      accepted: "akzeptiert",
      rejected: "abgelehnt",
      statusWasUpdated: "Der Status wurde aktualisiert!",
      allStatuses: "Alle Zustände",
      user: "Nutzer",
      vacationRequestsWasCreatedSuccessfully:
        "Der Urlaubsantrag wurde erfolgreich erstellt!",
      vacationRequestsWasUpdatedSuccessfully:
        "Der Urlaubsantrag wurde erfolgreich aktualisiert!",
      youWillHave: "Sie haben",
      daysRemainingSmall: "verbleibende Tage",
      totalVacationDays: "Urlaubstage insgesamt",
      totalVacationDaysIsRequired: "Gesamte Urlaubstage sind erforderlich",
      totalVacationWasChangedSuccessfully:
        "Gesamturlaub wurde erfolgreich geändert",
    },
    bankAccount: {
      chooseBank: "Wählen Sie eine Bank aus",
      attachPropertyToBankAccount: "Eigentum an Bankkonto anhängen",
      assignPaymentToArea: "Zahlungsbereich zuweisen",
      description: "Bezeichnung",
      sender: "Absender",
      isIgnored: "Ignoriert",
      bankName: "Bank name",
      bookingDate: "Buchungsdatum",
      bankAccount: "Bankkonto",
      assignProperty: "Eigentum zuweisen",
      title: "Bankkonto",
      allSales: "Alle Verkäufe",
      propertyAssignedSuccessfully: "Eigentum erfolgreich zugewiesen",
      linkNewBankAccount: "Verknüpfen Sie ein neues Bankkonto",
      addAccountToAnExistingBank: "Konto zu einer bestehenden Bank hinzufügen",
      importSales: "Verkäufe importieren",
      deleteBankAccount: "Bankkonto löschen",
      proposals: "Vorschläge",
      assigned: "Zugewiesen",
      notAssigned: "Nicht zugeordnet",
      automaticallyAssigned: "Automatisch zugewiesen",
      ignored: "Ignoriert",
      downloadSalesExampleFile: "Verkaufsbeispieldatei herunterladen",
      assign: "Zuordnen",
      paymentDescription: "Zahlungs-Beschreibung",
      paymentSender: "Zahlungsabsender",
      checkboxForIgnoringPayment: "Kontrollkästchen zum Ignorieren der Zahlung",
      amount: "Menge",
      assignPayment: "Zahlung zuweisen",
      assignPaymentListTitle: "Bereich & Mieter Zuordnen",
      rentedArea: "Vermietete Fläche",
      options: {
        assignProperty: "Eigenschaft zuweisen",
        importSales: "Verkäufe importieren",
        downloadSalesSampleFile: "Download Verkaufsmusterdatei",
        changeAccessData: "Zugangsdaten ändern",
        editBankAccountName: "Bankkontoname bearbeiten",
        delete: "Löschen",
      },
      create: {
        manual: "Manual",
        createDialogTitle: "Bankkonto verknüpfen",
        whatDoYouWantToDo: "Was möchtest Du machen?",
        LinkANewBankAccount: "Konto einer neuen Bank verknüpfen",
        AddAccountToAnExistingBank:
          "Konto zu einer bestehenden Bank hinzufügen",
        WhichBankIsYourAccountWith: "Bei welcher Bank ist Dein Konto?",
        SelectBankAccountForWhichYouWantToAddAnAccount:
          "Wähle eine Bankverbindung aus für die Du ein Konto hinzufügen möchtest",
        myAccount: "Mein Konto",
        goBack: "Abbrechen",
      },
      accountBalance: "Kontostand",
      paymentDate: "Zahlungsdatum",
      assignAreaToPayment: "Bereich der Zahlung zuordnen",
      incomingPayment: "Zahlungseingang",
      payment: "Zahlung",
    },
    bankAccounts: "Bankkonten",
    userActivity: {
      dateAndTime: "Datum und Uhrzeit",
      allTypes: "Alle Typen",
      activity: "Benutzeraktivität",
    },
    swot: {
      swot: "SWOT",
      strength: "Stärken",
      weakness: "Schwächen",
      opportunities: "Chancen",
      threads: "Risiken",
    },
    number: "Rechnungsnummer",
    location: {
      analysis: "Standortanalyse",
    },
    connect: "Verbinden",
    areas: "Flächen",
    area: "Bereich",
    areaName: "Bereichsname",
    typeError: "Bitte geben Sie eine gültige Nummer ein",
    minPercent: "Minimum mindestens 0",
    maxPercent: "Zulässiger Höchstwert ist 100",
    releaseMaxCharacter: "Erlaubte maximale Zeichen ist 15",
    assign: "Zuordnen",
    snackbar: {
      updated: "Aktualisiert",
      imported: "Importiert",
      somethingWentWrong: "Etwas ist schief gelaufen",
    },
    importContacts: "Kontakte importieren",
    or: "oder",
    browseFile: "Datei durchsuchen",
    chooseAFile: "Datei auswählen",
    upload: "Hochladen",
    statusButtons: {
      invoices: {
        new: "Neue Rechnungen",
        pending: "Rechnungen in Prüfung",
        finalReleased: "Freigegebene Rechnungen",
        rejected: "Abgelehnte Rechnungen",
        forwardedToReleaser: "Meine offenen Rechnungen",
        history: "Rechnungshistorie",
        forwarded: "Freizugebende",
      },
      contracts: {
        new: "Neue Verträge",
        pending: "Verträge in Prüfung",
        finalReleased: "Freigegebene Verträge",
        rejected: "Abgelehnte Verträge",
        existingOldContracts: "Bestehende Altverträge",
        forwardedToReleaser: "Meine offenen Verträge",
        oldContracts: "Altverträge",
        history: "Rechnungshistorie",
        forwarded: "Freizugebende",
      },
      offers: {
        new: "Neue Angebote",
        pending: "Angebote in Prüfung",
        finalReleased: "Freigegebene Angebote",
        rejected: "Abgelehnte Angebote",
        forwardedToReleaser: "Meine offenen Angebote",
        history: "Rechnungshistorie",
        forwarded: "Freizugebende",
      },
      insurances: {
        building: "Gebäudeversicherung",
        liability: "Haftpflichtversicherung",
        new: "Neue Versicherungen",
        pending: "Versicherungen in Prüfung",
        finalReleased: "Freigegebene Versicherungen",
        rejected: "Abgelehnte Versicherungen",
        forwardedToReleaser: "Meine offenen Versicherungen",
        history: "Rechnungshistorie",
        forwarded: "Freizugebende",
      },
    },
    inbox: {
      title: "Posteingang",
      complete: "Vollständig",
      incomplete: "Unvollständig",
      addedBy: "Hinzugefügt durch",
      forwardTitle: "Posteingang weiterleiten",
      forward: {
        forward: "Weiterleiten",
        title: "Posteingang weiterleiten",
        subject: "Betreff",
        recipientEmail: "Empfänger E-mail",
        text: "Text",
        includeComments: "Kommentare einbeziehen",
        success: "Posteingang wurde erfolgreich weitergeleitet",
        validation: {
          subject: "Bitte Betreff eingeben",
          message: "Bitte Text eingeben",
        },
      },
      create: {
        title: "Post hochladen",
        inboxCreatedSuccessfully: "Posteingang erfolgreich erstellt!",
        fileRequired: "Bitte Datei auswählen",
        pdfFileType: "Die Datei muss pdf sein",
        remove: "Entfernen",
      },
      propertyRemovedSuccessfully: "Eigenschaft erfolgreich entfernt",
      dataType: "Datentyp",
      pleaseSelectProperty: "Bitte Eigenschaft auswählen",
      pleaseMoveToProgress:
        'Um es bearbeiten zu können, verschieben Sie es bitte auf "Fortschritt"',
      selectOrCreateInsuranceTopic:
        "Versicherungsthema auswählen oder erstellen",
      selectTopic: "Thema auswählen",
      orTextInCapitalLetters: "ODER",
      all: "Alles",
      general: "Allgemein",
      providerWasAdded: "Anbieter wurde erfolgreich hinzugefügt",
      invoiceAmount: "Rechnungsbetrag",
      assignTitle: "Zuordnen",
      reassignTitle: "Ändern",
    },
    meter: {
      title: "Zählerstände",
      unit: "Einheit",
      number: "Anzahl",
      meterDateIsRequired: "Datum ist erforderlich",
    },
    openInNewTab: "In neuem Tab öffnen",
    tenantRequests: {
      title: "Mieteranfragen",
      userName: "Nutzername",
      new: "Neu",
      inProgress: "Im Gange",
      completed: "Vollendet",
      inCoordination: "In Abstimmung",
      invitationSentOn: "Einladung versendet am",
      assetManagerUserName: "Zugewiesener Asset Manager",
      unassignedTenantRequest: "Nicht zugewiesene Mieteranfrage",
      outdated: "Veraltet",
    },
    hr: "HR",
    type: "Typ",
    assignedUser: "Zugewiesener Benutzer",
    manager: "Managerin",
    forwardToAdmin: "Weiterleitung an admin",
    resetPasswordEmailWasSend:
      "Der Link zum Zurücksetzen des Passworts wurde bereits an Ihre E-Mail gesendet",
    reminder: {
      title: "Mahnungen",
      create: {
        reminderCreatedSuccessfully: "Erinnerung erfolgreich erstellt",
        title: "Mahnung hochladen",
      },
      update: {
        reminderUpdatedSuccessfully: "Erinnerung erfolgreich aktualisiert",
      },
      status: {
        new: "Neue Mahnungen",
        completed: "Erledigte Mahnungen",
      },
      createReminder: "Mahnung hochladen",
      invoiceRemovedSuccessfully: "Rechnung erfolgreich entfernt",
      assignInvoice: "Rechnung zuordnen",
      relationIdRequired: "Rechnung ist erforderlich",
      reminderSentDate: "Mahnung versendet am",
      reminderComplete: "Mahnung erledigt",
    },
    pdfViewer: {
      noPdfError: "KEIN PDF ZUR VORSCHAU",
    },
    seeMore: "Mehr sehen",
    seeLess: "Weniger sehen",
    seeAllComments: "Alle Kommentare anzeigen",
    leaveComment: "Hinterlasse einen Kommentar...",
    message: "Nachricht...",
    start: "Mit dem",
    typing: "Tippen beginnen",
    fileFormateMustBe: "Die Datei muss pdf sein",
    fileSizeMustBe: "Dateigröße muss sein",
    chats: "Chats",
    uploading: "Hochladen...",
    invalidDate: "Bitte gültiges Datum eingeben",
    invalidYear: "Bitte geben Sie ein gültiges Jahr ein",
    lastRenovated: "Zuletzt renoviert",
    floor: "Etage",
    scrollLeft: "Scrollen Sie nach links",
    scrollRight: "Scrollen Sie nach rechts",
    months: {
      january: "Januar",
      february: "Februar",
      march: "März",
      april: "April",
      may: "Mai",
      june: "Juni",
      july: "Juli",
      august: "August",
      september: "September",
      october: "Oktober",
      november: "November",
      december: "Dezember",
    },
    providerInfoTable: {
      name: "Name",
      companyName: "Firma",
      email: "E-mail",
      address: "Adresse",
      businessPhone: "Telefon Geschäftlich",
    },
    forward: "Weiterleiten",
    fileSize100MB: "Dateigröße muss kleiner als 100 MB sein",
    fileLimit10Images: "Maximal 10 Bilder erlaubt",
    fileLimit10Video: "Maximal 10 Video erlaubt",
    minImagePixelSize:
      "Es ist eine Mindestbildgröße von 400 x 255 erforderlich",
    generalDocuments: {
      title: "Allgemeine Dokumente",
      generalDocumentStatusSuccessfullyUpdated:
        "Allgemeiner Dokumentstatus erfolgreich aktualisiert",
      complete: "Vollständig",
      incomplete: "Unvollständig",
      addedBy: "Hinzugefügt durch",
      forwardTitle: "Allgemeines Dokument weiterleiten",
      forward: {
        forward: "Weiterleiten",
        title: "Allgemeines Dokument weiterleiten",
        subject: "Betreff",
        recipientEmail: "Empfänger E-mail",
        text: "Text",
        includeComments: "Kommentare einbeziehen",
        success: "Allgemeines Dokument wird erfolgreich weitergeleitet",
        validation: {
          subject: "Bitte Betreff eingeben",
          message: "Bitte Text eingeben",
        },
      },
      create: {
        title: "Erstellen",
        generalDocumentCreatedSuccessfully:
          "Allgemeines Dokument erfolgreich erstellt",
        fileRequired: "Bitte Datei auswählen",
        pdfFileType: "Die Datei muss pdf sein",
        remove: "Entfernen",
      },
      propertyRemovedSuccessfully: "Eigenschaft erfolgreich entfernt",
      propertyAssignedSuccessfully: "Eigenschaft wurde erfolgreich zugewiesen",
      dataType: "Datentyp",
      pleaseSelectProperty: "Bitte Eigenschaft auswählen",
      pleaseMoveToProgress:
        'Um es bearbeiten zu können, verschieben Sie es bitte auf "Fortschritt"',
      selectOrCreateInsuranceTopic:
        "Versicherungsthema auswählen oder erstellen",
      selectTopic: "Thema auswählen",
      orTextInCapitalLetters: "ODER",
      all: "Alles",
      general: "Allgemein",
      providerWasAdded: "Anbieter wurde erfolgreich hinzugefügt",
      invoiceAmount: "Rechnungsbetrag",
      assignTitle: "Zuordnen",
      assignUsers: "Nutzer zuweisen",
      assignedUsers: "Zugewiesene Nutzer",
      userIsAmOfTheProperty: "Benutzer ist AM der Eigenschaft",
    },
  },
};
