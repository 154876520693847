import { FormikValues } from "formik";
import { OfferTopic as FEOfferTopic } from "../../../../types/fe.interfaces";
import { TableCell } from "../../../../types/table";
import { createOfferTopic } from "../../../../api/offerTopic";
import { HandleTopicCreateSubmitProps, ProviderOfferListFilter } from "./types";
import {
  RELASE_BUTTONS_AM,
  RELASE_BUTTONS_PM,
  RELEASE_BUTTONS_FR,
  RELEASE_BUTTONS_USER,
} from "../../utils";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "property_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertyName",
    align: "left",
  },
  {
    id: "file_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "offers.fileName",
  },
  {
    id: "contact_fullName",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "offers.contact",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "offers.amount",
    align: "right",
  },
  {
    id: "latest_comment",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "comment",
  },
  {
    id: "created_by_fullName",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "uploadedBy",
  },
  {
    id: "upload_on",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "offers.uploadedOn",
    align: "center",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "createdAt",
    align: "center",
  },
  // Waiting api to finish this task
  // {
  //   id: "recommendation",
  //   numeric: false,
  //   disablePadding: false,
  //   sortable: false,
  //   label: "insurance.recommendation",
  //   align: "left",
  // },
  {
    id: RELEASE_BUTTONS_FR,
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "property.navLinks.finalReleaser",
    align: "center",
  },
  {
    id: RELASE_BUTTONS_AM,
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "propertyName",
    align: "center",
  },
  {
    id: RELASE_BUTTONS_PM,
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "PM",
    align: "center",
  },
  {
    id: RELEASE_BUTTONS_USER,
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "User",
    align: "center",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "",
  },
];
/* istanbul ignore next */
export const handleTopicCreateSubmit = ({
  fetchOfferTopics,
  setCreateOfferTopicFormMode,
  setIsSuccess,
}: HandleTopicCreateSubmitProps) => {
  return async (
    topic: FEOfferTopic,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const response = await createOfferTopic(topic);
    if (response.status === 201) {
      setIsSuccess(true);
      setCreateOfferTopicFormMode(null);
      await fetchOfferTopics();
    } else {
      setStatus({
        success: false,
        errors: {
          name: `Error`,
        },
      });
    }
    setSubmitting(false);
  };
};

/* istanbul ignore next */
export const mapFESortField = (field: string): string => {
  switch (field) {
    case "file_name":
      return "file.name";
    case "property_name":
      return "property.object_name";
    case "contact_fullName":
      return "contact.full_name";
    case "amount":
      return "amount";
    case "created_by_fullName":
      return "created_by.first_name";
    case "upload_on":
      return "uploaded_at";
    default:
      return field;
  }
};

export const providerOfferListFilterInit: ProviderOfferListFilter = {
  contact_id: null,
};

export const historyModalInitState = {
  isVisible: false,
  historyId: 0,
};
