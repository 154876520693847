/* istanbul ignore next */
import styled from "styled-components/macro";
import { Typography } from "@mui/material";

export const AutocompletePropertyTenantName = styled(Typography)`
  display: block;
`;

export const AutocompletePropertyTenantEmail = styled(Typography)`
  font-size: 12px;
  color: #b0bec5;
  display: block;
`;

export const AutocompleteListItem = styled.li`
  font-size: 11px;
  font-weight: 500;
`;
