export default {
  transactionManager: "Transaction Manager",
  objectName: "Object name",
  date: "Date",
  sent: "Sent",
  pdf: "PDF",
  transactionManagerEmail: "Transaction manager email",
  recipientCompanyName: "Recipient: company name",
  recipientContactPerson: "Recipient: contact person",
  recipientStreet: "Recipient: street",
  recipientCity: "Recipient: city",
  city: "City",
  purchasePrice: "Purchase price",
  purchasePriceInWords: "Purchase price in words",
  firstLineOfEmail: "First line of email",
  htmlMessage: "Message",
  titleOfTransactionManager: "Title of Transaction manager",
  selectTransactionManagerForDB: "Select transaction manager for DB",
  titleOfTransactionManagerIsRequired: "Transaction manager title is required",
  transactionManagerIsRequired: "Transaction Manager is required",
  search: "Search",
  preview: "Preview",
  loi: "LOI",
  recipientStreetIsRequired: "Recipient street is required",
  recipientCityIsRequired: "Recipient city is required",
  sendLOI: "Send LOI",
  sendLOISuccessfully: "Send LOI successfully",
};
