import React, { ReactElement } from "react";
import {
  DeleteIcon,
  EditIcon,
  ShowIcon,
} from "../../../../../../../ui/table/styled";
import { TableCell } from "../../../../../styled";
import { Typography } from "@mui/material";
import { NumericFormat } from "react-number-format";
import {
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  Measurement_Units,
  PermissionsUpdate,
  THOUSAND_SEPARATOR,
  UserRoles,
} from "../../../../../../../constants";
import { InsuranceListRowProps } from "../../types";
import { useTranslation } from "react-i18next";
import { monthDict } from "../../utils";
import { useParams } from "react-router-dom";
import ExpandableComment from "../../../../../../../ui/expandableComment/ExpandableComment";
import { formatTimestamp } from "../../../../../../../utils/date";
import { RELEASE_LEVEL } from "../../../../../../../utils/common";
import RedirectProperty from "../../../../../../../ui/redirectProperty";
import DocumentRelease from "../../../../../components/documentRelease";
import {
  DOCUMENT_RELEASE_LEVEL,
  DOCUMENT_RELEASE_MODULE,
} from "../../../../../../../types/be/releaseFlow";
import {
  RELASE_BUTTONS_AM,
  RELASE_BUTTONS_PM,
  RELEASE_BUTTONS_FR,
  RELEASE_BUTTONS_USER,
  isAvailableReleaseButtonTableCell,
} from "../../../../../utils";
import { DocumentReleaseHistoryIcon } from "../../../../../components/history/styled";
import useAccessControl from "../../../../../../../hooks/useAccessControl";
import FileTableCell from "../../../../../components/fileTableCell";

/* istanbul ignore next */
const InsuranceListRow = ({
  useOnWidget,
  row,
  handleCellClick,
  handleEditClick,
  openConfirmToRemoveModal,
  handleOpenComments,
  handleShowClick,
  fetchInsuranceTopics,
  handleReleaseDocument,
  handleForwardDocument,
  releasePermission,
  isDocumentReleaseLoading,
  configuration,
  handleHistoryModal,
}: InsuranceListRowProps): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { isRole, can } = useAccessControl();

  return (
    <>
      {useOnWidget ? (
        <TableCell align="left">
          <RedirectProperty property={row?.property} />
        </TableCell>
      ) : null}
      <FileTableCell file={row.file} />
      <TableCell align="right">
        <Typography noWrap>
          <NumericFormat
            thousandSeparator={THOUSAND_SEPARATOR}
            decimalSeparator={DECIMAL_SEPARATOR}
            decimalScale={DECIMAL_SCALE}
            fixedDecimalScale
            displayType={"text"}
            value={row.amount}
          />{" "}
          {row.amount !== null && Measurement_Units.EURO}
        </Typography>
      </TableCell>
      <TableCell>
        {`${row.cancellation_period} ${t(
          `insurance.${monthDict[row.cancellation_period]}`
        )}`}
      </TableCell>
      <TableCell align="left">
        <ExpandableComment
          seeAllComment={() => handleOpenComments(row)}
          commentData={row?.latest_comment}
        ></ExpandableComment>
      </TableCell>
      <TableCell align="left" mw="130px">
        {row.created_by?.first_name} {row.created_by?.last_name}
      </TableCell>
      <TableCell align="center">{formatTimestamp(row.uploaded_at)}</TableCell>
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELEASE_BUTTONS_FR,
          configuration
        ) ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.fr}
              releaseStatus={row?.fr_status}
              forwardedAt={row?.fr_forwarded_at}
              forwardedBy={row?.fr_forwarded_by}
              overdue={row?.fr_overdue}
              afterSuccess={fetchInsuranceTopics}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              level={RELEASE_LEVEL.FIRST_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
                  row?.assigned_user
                )
              }
              hasReleasePermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL]
              }
              relation={DOCUMENT_RELEASE_MODULE.INSURANCE}
              loading={isDocumentReleaseLoading}
              isButtonVisible={{
                forwardButton: false,
              }}
            />
          </TableCell>
        ) : null
      }
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELASE_BUTTONS_AM,
          configuration
        ) ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.am}
              releaseStatus={row?.am_status}
              forwardedAt={row?.am_forwarded_at}
              forwardedBy={row?.am_forwarded_by}
              overdue={row?.am_overdue}
              afterSuccess={fetchInsuranceTopics}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              level={RELEASE_LEVEL.SECOND_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
                  row?.assigned_user
                )
              }
              hasReleasePermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL]
              }
              relation={DOCUMENT_RELEASE_MODULE.INSURANCE}
              loading={isDocumentReleaseLoading}
              isButtonVisible={{
                forwardButton: false,
              }}
            />
          </TableCell>
        ) : null
      }
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELASE_BUTTONS_PM,
          configuration
        ) ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.pm}
              releaseStatus={row?.pm_status}
              forwardedAt={row?.pm_forwarded_at}
              forwardedBy={row?.pm_forwarded_by}
              overdue={row?.pm_overdue}
              afterSuccess={fetchInsuranceTopics}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              level={RELEASE_LEVEL.THIRD_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL](
                  row?.assigned_user
                )
              }
              hasReleasePermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL]
              }
              relation={DOCUMENT_RELEASE_MODULE.INSURANCE}
              loading={isDocumentReleaseLoading}
              isButtonVisible={{
                forwardButton: false,
              }}
            />
          </TableCell>
        ) : null
      }
      {
        /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
          RELEASE_BUTTONS_USER,
          configuration
        ) ? (
          <TableCell align="center">
            <DocumentRelease
              id={row?.id}
              releaser={row?.au}
              releaseStatus={row?.au_status}
              forwardedAt={row?.au_forwarded_at}
              forwardedBy={row?.au_forwarded_by}
              overdue={row?.au_overdue}
              afterSuccess={fetchInsuranceTopics}
              handleReleaseDocument={handleReleaseDocument}
              handleForwardDocument={handleForwardDocument}
              level={RELEASE_LEVEL.FOURTH_LEVEL_RELEASE}
              hasForwardPermission={
                releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL] ||
                releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL]
              }
              hasReleasePermission={releasePermission[
                DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
              ](row?.assigned_user)}
              relation={DOCUMENT_RELEASE_MODULE.INSURANCE}
              assignedUser={row?.assigned_user}
              loading={isDocumentReleaseLoading}
              isButtonVisible={{
                forwardButton: false,
                assignUserButton: false,
              }}
              hasAssignUserPermission={can(PermissionsUpdate.INSURANCE)}
            />
          </TableCell>
        ) : null
      }
      <TableCell mw="130px" align={"left"}>
        {row.contact ? <>{row.contact.full_name}</> : EMPTY_DATA}
      </TableCell>
      <TableCell
        align="right"
        mw="100px"
        onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
          handleCellClick(e)
        }
        style={{ whiteSpace: "nowrap" }}
      >
        {isRole(UserRoles.ADMIN) && (
          <DocumentReleaseHistoryIcon
            role={"showHistoryRole"}
            onClick={() => {
              handleHistoryModal(row);
            }}
          />
        )}
        <ShowIcon
          role={"showIconRole"}
          size={18}
          onClick={(): void =>
            handleShowClick({
              id: row.id,
              propertyId: id ? Number(id) : undefined,
            })
          }
        />
        <EditIcon
          role={"editIconRole"}
          onClick={(): void =>
            handleEditClick({
              id: row.id,
              propertyId: id ? Number(id) : undefined,
            })
          }
          size={18}
        />
        <DeleteIcon
          onClick={(): void => openConfirmToRemoveModal(row)}
          size={20}
          role={"deleteIconRole"}
        />
      </TableCell>
    </>
  );
};

export default InsuranceListRow;
