/* istanbul ignore file */

import React, {
  HTMLAttributes,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import TextField from "../../../ui/formsFields/text";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { Grid, Paper } from "@mui/material";
import Snackbar from "../../../ui/Snackbar";
import { Form, FormikValues } from "formik";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  AutocompleteListItem,
  EditableSection,
  EditSectionButton,
  HeaderText,
  LocationText,
  SaveSectionButton,
  CancelSectionButton,
  EmptyEditableSection,
  LabelText,
} from "../../properties/edit/general/styled";
import debounce from "lodash/debounce";
import {
  EMPTY_DATA,
  MAX_PROPERTY_IMAGES_LIMIT,
  PermissionsUpdate,
  SEARCH_PARAM_MIN_CHARACTERS,
  VIDEO_FILE_TYPE,
} from "../../../constants";
import { getPlaceDetails, getPlacePredictions } from "../../../api/places";
import { Dropdown } from "../../../ui/autocomplete/styled";
import {
  Location,
  PlaceDetails,
  PlaceDetailsResponse,
  Prediction,
} from "../../../types/be/place";
import Loader from "../../Loader";
import { getJson } from "../../../utils/http";
import { REACT_APP_GOOGLE_API_KEY } from "../../../config";
import GoogleMapReact, { Maps } from "google-map-react";
import PinMarker from "../../../ui/map/marker/default";
import FileUploader from "../../../ui/fileUploader";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import {
  DEFAULT_MAP_LAT,
  DEFAULT_MAP_LNG,
  DEFAULT_MAP_ZOOM,
} from "../../../ui/map/utils";
import {
  mapAddressComponentsToPlaceAddress,
  placeErrorText,
} from "../../properties/utils";
import camelCase from "lodash/camelCase";
import { EDITABLE_FIELDS_TYPE } from "../../properties/edit/general/utils";
import { isNodeEnv } from "../../../utils/env";
import {
  findFiveDigitZipCode,
  prepareQueryParams,
} from "../../../utils/common";
import { useParams } from "react-router-dom";
import { updatePropertyWithFormData } from "../../../api/property";
import useSnackbar from "../../../ui/snackbar1/useSnackbar";
import Carousel from "../../../ui/carousel";
import EditableField from "../../properties/edit/general/EditableField";
import DoneIcon from "@mui/icons-material/Done";
import useAccessControl from "../../../hooks/useAccessControl";
function CreatePropertyForm({
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  values,
  status,
  setFieldValue,
  getPropertyData,
  images,
  videos,
}: any & FormikValues): ReactElement {
  const [loading, setLoading] = useState(false);
  const [placePredictions, setPlacePredictions] = useState<
    readonly Prediction[]
  >([]);
  const { t } = useTranslation();
  const { id = "" } = useParams();
  const { Snackbar: SnackbarMsg, snackbar } = useSnackbar();
  const { can } = useAccessControl();

  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [zoom, setZoom] = useState<number>(8);
  const [, setPlaceDetails] = useState<PlaceDetails | null>(null);
  const [placeLocation, setPlaceLocation] = useState<Location>();
  const [isPlaceValidationError, setIsPlaceValidationError] = useState(false);
  const [placeValidationErrorText, setPlaceValidationErrorText] = useState("");
  const [placeInputValue, setPlaceInputValue] = useState("");
  const [editSection, setEditSection] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0.01);
  const [placeAutocompleteFieldValue, setPlaceAutocompleteFieldValue] =
    useState("");
  const editableDefaultProps = {
    formikProps: {
      values,
      status,
      setFieldValue,
      handleBlur,
      handleChange,
    },
  };
  useEffect(() => {
    const { location } = values;
    setPlaceLocation({ lat: location.lat, lng: location.lng });
  }, [values]);

  useEffect(() => {
    setPlaceInputValue(values?.location.full_address);
  }, []);

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  useEffect(() => {
    const str = placeErrorText(errors.location);
    setIsPlaceValidationError(!!str.length);
    setPlaceValidationErrorText(
      t(`google.place.autocomplete.${camelCase(str)}`)
    );
  }, [errors.location]);

  useEffect(() => {
    const str = placeErrorText(errors.location);
    setIsPlaceValidationError(!!str.length);
    setPlaceValidationErrorText(
      t(`google.place.autocomplete.${camelCase(str)}`)
    );
  }, [errors]);

  useEffect(() => {
    const newZipCode = findFiveDigitZipCode(placeAutocompleteFieldValue);
    newZipCode && setFieldValue("location.zip_code", newZipCode);
    newZipCode &&
      setFieldValue("location.full_address", placeAutocompleteFieldValue);
  }, [placeAutocompleteFieldValue]);

  const onLocationSearchKeyDown = React.useCallback(
    debounce(async (search: string): Promise<void> => {
      /* istanbul ignore next */
      if (search.length < SEARCH_PARAM_MIN_CHARACTERS) setPlacePredictions([]);
      else {
        setLoading(true);
        const params = prepareQueryParams("", {
          input: search,
        });
        getPlacePredictions(params)
          .then(getJson)
          .then(({ data: { predictions } }) => {
            setPlacePredictions(predictions);
            setLoading(false);
          })
          .catch(() => {
            setPlacePredictions([]);
          });
      }
    }, 300),
    []
  );
  /* istanbul ignore next */
  const getMapOptions = (maps: Maps): any => {
    return {
      fullscreenControl: true,
      mapTypeId: maps.MapTypeId.HYBRID,
      scaleControl: true,
      scrollwheel: false,
    };
  };

  const handlePlacePredictionSelect = ({ place_id }: Prediction): void => {
    const params = prepareQueryParams("", {
      place_id,
      language: "en",
    });
    getPlaceDetails(params)
      .then(getJson)
      .then(({ data: { result } }: PlaceDetailsResponse) => {
        setPlaceDetails(result);
        setPlaceLocation(result.geometry.location);
        setZoom(15);
        const { geometry } = result;
        const { address_components } = result;
        const { place_id } = result;
        const mapResult =
          mapAddressComponentsToPlaceAddress(address_components);
        setPlaceInputValue(result.formatted_address);
        setFieldValue("location", {
          full_address: result.formatted_address!,
          city: mapResult.city || "",
          country_code: mapResult.country_code!,
          state_code: mapResult.state_code || "",
          house_number: mapResult.house_number || "",
          zip_code: mapResult.zip_code || "",
          street: mapResult.street || "",
          place_id: place_id!,
          lat: geometry.location.lat!,
          lng: geometry.location.lng!,
        });
      });
  };

  /* istanbul ignore next */
  const handleAutocompleteOnChange = (
    _: SyntheticEvent,
    value: Prediction | null
  ): void => {
    if (value) handlePlacePredictionSelect(value);
    else {
      setPlaceDetails(null);
    }
  };

  const handleSnackbarClose = (): void => setIsSnackbarVisible(false);

  const validatePlaceLocation = (
    placeLocation: Location | undefined
  ): boolean => !!(placeLocation && placeLocation.lat && placeLocation.lng);

  const handleRenderInput = (
    params: AutocompleteRenderInputParams
  ): ReactNode => (
    <TextField
      {...params}
      label={t("company.companyLocation")}
      onChange={(e) => {
        setPlaceInputValue(e.target.value);
        onLocationSearchKeyDown(e.target.value);
        setPlaceAutocompleteFieldValue(e.target.value);
      }}
      size="small"
      placeholder={t("google.place.autocomplete.placeholder")}
      value={values.place_id}
      onBlur={handleBlur}
      error={isPlaceValidationError}
      helperText={isPlaceValidationError && placeValidationErrorText}
      status={status}
    />
  );

  const isMaxImagesLimitReached = (newFilesLength: number): boolean =>
    images.length >= MAX_PROPERTY_IMAGES_LIMIT ||
    images.length + newFilesLength > MAX_PROPERTY_IMAGES_LIMIT;

  const handleOnFileChange = async (filesToUpload: File[]): Promise<void> => {
    if (isMaxImagesLimitReached(filesToUpload.length)) {
      snackbar.error(t("property.general.fileUploadLimit"));
      return;
    }

    if (filesToUpload.length) {
      const formData = new FormData();
      for (let i = 0; filesToUpload.length > i; i++) {
        if (filesToUpload[i].type === "video/mp4") {
          formData.append(`videos[]`, filesToUpload[i]);
        } else {
          formData.append(`images[]`, filesToUpload[i]);
        }
      }

      formData.append("purchase_price", values.purchase_price);
      formData.append(`_method`, "PUT");

      updatePropertyWithFormData(id, formData, (event) => {
        const percentage = (event.loaded * 100) / event.total;
        setUploadProgress(percentage);
      })
        .then(() => {
          snackbar.success(t("tenantRequest.uploaded"));
          getPropertyData();
        })
        .catch((err) => snackbar.error(err.toString()));
    }
  };

  const handleOnFileDelete = (filesToUpload: File[]): void => {
    setFieldValue("images", [...filesToUpload]);
  };

  const uploadedFiles = useMemo(() => {
    const imageFiles = images || values.images;
    const videosFiles = videos || values.videos;
    return [...imageFiles, ...videosFiles];
  }, [values.images, images]);

  const resetEditableField = (): void => {
    setEditSection(false);
  };

  useEffect(() => {
    if (!isSubmitting && Object.keys(errors).length === 0) {
      resetEditableField();
    }
  }, [isSubmitting]);

  return (
    <>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <EmptyEditableSection />
        <EditableSection sx={{ p: 2, mb: 4 }}>
          <Grid container sx={{ mb: 3, mt: 2 }} justifyItems="center">
            <Grid
              item
              xs={12}
              sm={6}
              alignItems="center"
              sx={{ display: "flex" }}
            >
              {!editSection ? (
                <Carousel
                  images={uploadedFiles}
                  defaultImagePath="/static/img/files/propertyDefault.png"
                />
              ) : (
                <FileUploader
                  accept={["image", VIDEO_FILE_TYPE]}
                  isDisabled={isSubmitting}
                  onFileChange={handleOnFileChange}
                  onFileDelete={handleOnFileDelete}
                  refreshData={getPropertyData}
                  filesInitState={uploadedFiles}
                  uploadDisabled={
                    isSubmitting || images.length >= MAX_PROPERTY_IMAGES_LIMIT
                  }
                  maxAllowed={MAX_PROPERTY_IMAGES_LIMIT}
                  placeHolderText={t("property.imageUploadPlaceHolder")}
                  uploadButtonText={t("property.uploadFiles")}
                  progressPercentage={uploadProgress}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} p={4}>
              <Grid container justifyItems={"space-between"} height="inherit">
                {!editSection ? (
                  <>
                    <Grid item xs={12}>
                      <HeaderText>{values.object_name}</HeaderText>
                      <LocationText>{placeInputValue}</LocationText>
                    </Grid>
                  </>
                ) : (
                  <>
                    <EditableField
                      {...{
                        ...editableDefaultProps,
                        name: "object_name",
                        label: "property.objectName",
                        isEdit: editSection,
                        type: EDITABLE_FIELDS_TYPE.TEXT,
                        gridSize: 12,
                        dataTestId: "object-name",
                        value: values.object_name,
                      }}
                    />
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{ mb: 3 }}
                      padding={2}
                    >
                      <Autocomplete
                        fullWidth
                        loading={loading}
                        loadingText={`${t("loading")}...`}
                        inputValue={placeInputValue ?? ""}
                        popupIcon={
                          loading ? <Loader size={25} /> : <ArrowDropDownIcon />
                        }
                        options={placePredictions!}
                        isOptionEqualToValue={handleIsOptionEqualToValue}
                        getOptionLabel={handleGetOptionLabel}
                        filterOptions={handleFilterOptions}
                        onChange={handleAutocompleteOnChange}
                        renderOption={handleRenderOption}
                        PaperComponent={autocompletePaperComponent}
                        renderInput={handleRenderInput}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} mt={editSection ? 0 : 10}>
                  {editSection ? (
                    <EditableField
                      {...{
                        ...editableDefaultProps,
                        name: "notes",
                        label: "property.notes",
                        isEdit: editSection,
                        type: EDITABLE_FIELDS_TYPE.MULTILINE,
                        gridSize: 12,
                        dataTestId: "notes",
                        value: values.notes,
                      }}
                    />
                  ) : (
                    <Grid item xs={12}>
                      <LabelText>{t("property.notes")}</LabelText>
                      <HeaderText>{values.notes || EMPTY_DATA}</HeaderText>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {can([PermissionsUpdate.PROPERTY]) &&
            (!editSection ? (
              <EditSectionButton
                className="editable-delay"
                onClick={() => setEditSection(!editSection)}
              />
            ) : (
              <>
                <CancelSectionButton
                  onClick={() => setEditSection(!editSection)}
                />
                <SaveSectionButton
                  title={t("save")}
                  type="submit"
                  startIcon={<DoneIcon />}
                  color="success"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  variant="outlined"
                  size="small"
                />
              </>
            ))}
        </EditableSection>
        <Paper sx={{ p: 2, mb: 4 }}>
          <Grid container sx={{ mb: 3, mt: 2 }}>
            <Grid item xs={12} height={200}>
              {!isNodeEnv("test") && (
                /* istanbul ignore next */
                <GoogleMapReact
                  options={getMapOptions}
                  bootstrapURLKeys={{ key: REACT_APP_GOOGLE_API_KEY! }}
                  defaultCenter={{ lat: DEFAULT_MAP_LAT, lng: DEFAULT_MAP_LNG }}
                  defaultZoom={DEFAULT_MAP_ZOOM}
                  center={
                    validatePlaceLocation(placeLocation)
                      ? placeLocation
                      : {
                          lat: DEFAULT_MAP_LAT,
                          lng: DEFAULT_MAP_LNG,
                        }
                  }
                  zoom={zoom}
                >
                  {
                    /* istanbul ignore next */ placeLocation &&
                      placeLocation.lat &&
                      placeLocation.lng && (
                        <PinMarker
                          lat={placeLocation.lat}
                          lng={placeLocation.lng}
                        />
                      )
                  }
                </GoogleMapReact>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Form>
      {SnackbarMsg}
      <Snackbar
        open={isSnackbarVisible}
        message={t("property.propertyWasSuccessfullyUpdated")}
        color="success"
        handleClose={handleSnackbarClose}
      />
    </>
  );
}

const handleRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  placePrediction: Prediction
): ReactNode => (
  <AutocompleteListItem {...props} key={placePrediction.description}>
    - {placePrediction.description}
  </AutocompleteListItem>
);
/* istanbul ignore next */
const handleIsOptionEqualToValue = (
  option: Prediction,
  value: Prediction
): boolean => option.place_id === value.place_id;

const handleGetOptionLabel = (placePrediction: Prediction): string =>
  placePrediction.description;

const handleFilterOptions = (options: Prediction[]): Prediction[] => options;

const autocompletePaperComponent = ({
  children,
}: {
  children?: ReactNode;
}): ReactElement | null => <Dropdown>{children}</Dropdown>;

export default CreatePropertyForm;
