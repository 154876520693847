/* istanbul ignore file */

import React, { ReactElement, useEffect } from "react";
import { DialogActions, DialogContent, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "../button/Button";
import useComments from "../сomments/useComments";
import Comments from "../сomments";
import { Comment } from "./types";
import DialogTitle from "../dialog/DialogTitle";
import MentionedUsers from "./MentionedUsers";
import { useFetch } from "../../hooks/useFetch";
import { User } from "../../types/models";
import { getUsers } from "../../api/users";
import { IS_ACTIVE_PARAM, PAGE_LIMIT } from "../../constants";
import { prepareQueryParams } from "../../utils/common";
import Loader from "../../components/Loader";
import { Dialog, LoaderRoot } from "./styled";

const CommentsPopup = ({
  handleCloseDialog,
  id,
  relation,
}: Comment): ReactElement => {
  const { t } = useTranslation();
  const {
    comments,
    content,
    setContent,
    currentPage,
    totalPages,
    handleCommentSave,
    handleCommentDelete,
    handlePaginationChange,
    deleting,
    loading,
    mentionedUsers,
    isMentionedUsersLoading,
    setMentionedUsers,
    onAvatarLoaded,
    avatarsLoaded,
  } = useComments({
    relation: relation,
    relation_id: id!,
  });

  const {
    data: users,
    run: runUsers,
    isLoading: isUsersLoading,
  } = useFetch<User[]>();

  useEffect(() => {
    const params = prepareQueryParams("", {
      limit: PAGE_LIMIT._1000,
      [IS_ACTIVE_PARAM]: 1,
    });
    runUsers(getUsers(params));
  }, []);

  const commentsWithAvatars = comments.filter((comment) => comment.user.avatar);

  const isLoading =
    isMentionedUsersLoading ||
    (Boolean(commentsWithAvatars.length) && !avatarsLoaded);

  return (
    <>
      <Dialog
        role="release-comment"
        fullWidth
        maxWidth="md"
        open={true}
        onClose={handleCloseDialog}
        aria-labelledby="max-width-dialog-title"
        data-testid="comment-modal"
        disableEnforceFocus
        $loading={isLoading}
      >
        <DialogTitle
          title={t("comment")}
          onClose={handleCloseDialog}
        ></DialogTitle>
        <DialogContent dividers sx={{ overflowX: "hidden" }}>
          <Grid container sx={{ display: "flex", gap: 4, flexFlow: "row" }}>
            <Grid item xs={9}>
              <Comments
                editorContent={content}
                setEditorContent={setContent}
                handleSaveComment={handleCommentSave}
                comments={comments}
                currentPage={currentPage}
                totalPages={totalPages}
                handleDeleteComment={handleCommentDelete}
                handlePaginationChange={handlePaginationChange}
                deleting={deleting}
                onAvatarLoaded={onAvatarLoaded}
                loading={loading}
                isPopup={true}
              />
            </Grid>
            <Grid item xs={3}>
              {mentionedUsers && (
                <MentionedUsers
                  users={users}
                  mentionedUsers={mentionedUsers}
                  isUsersLoading={isUsersLoading}
                  relation={relation}
                  relationId={id!}
                  setMentionedUsers={setMentionedUsers}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            testId="comment-close"
            title={t("cancel")}
            color="primary"
            variant="text"
            size="large"
            onClick={handleCloseDialog}
          />
        </DialogActions>
      </Dialog>
      {isLoading && (
        <LoaderRoot>
          <Loader />
        </LoaderRoot>
      )}
    </>
  );
};

export default CommentsPopup;
