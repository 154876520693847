import { FormikValues } from "formik/dist/types";
import {
  FormMessageErrorState,
  FormMessageInItState,
} from "../../../../../constants";
import { TableCell } from "../../../../../types/table";
import { updatePropertyWithPost } from "../../../../../api/property";
import { mapBackendValidationErrors } from "../../../../../utils/form";
import { Property } from "../../../../../types/be/property";
import { mapBEPropertyToFEPropertyUpdate } from "../../../edit/utils";
import { constructFormData } from "../../../../../utils/common";
import { generalInfoSubmitProps } from "./types";

/* istanbul ignore next */
export const handleUpdateGeneralInformationFormSubmit = ({
  setFormMessage,
  setProperty,
  setIsUploadingPropertyLogo,
  onSuccess,
}: generalInfoSubmitProps) => {
  return async (
    data: Property,
    { setStatus, setSubmitting, setTouched }: FormikValues
  ): Promise<void> => {
    setIsUploadingPropertyLogo(true);
    const preparedProperty = mapBEPropertyToFEPropertyUpdate(data);

    const picked = {
      id: preparedProperty.id,
      caretaker_id: preparedProperty.caretaker_id,
      asset_managers: preparedProperty.asset_managers,
      expenses: preparedProperty.expenses,
      portfolio: preparedProperty.portfolio,
      phone_number: preparedProperty.phone_number,
      tax_number: preparedProperty.tax_number,
      vat: preparedProperty.vat,
      architect: preparedProperty.architect,
      purchase_price: preparedProperty.purchase_price,
      registration_number: preparedProperty.registration_number,
      tax_id: preparedProperty.tax_id,
      outgoing_address: preparedProperty.outgoing_address,
      managing_director: preparedProperty.managing_director,
      email: preparedProperty.email,
    };

    if (!preparedProperty?.logo?.id) {
      Object.assign(picked, { logo: preparedProperty.logo });
    }
    const payload = constructFormData({
      ...picked,
      supervisor_id: data.supervisor?.id ?? "",
    });
    picked.asset_managers.map((am) =>
      payload.append(
        "asset_managers[]",
        typeof am === "number" ? am.toString() : am.id.toString()
      )
    );
    payload.append("_method", "PUT");
    const response = await updatePropertyWithPost(data.id, payload);
    const json = await response.json();
    if (response.ok) {
      const { data } = json;
      setProperty((prevState) => ({ ...prevState, data }));
      setStatus({
        success: true,
        errors: {},
      });

      setTouched(touchedInitState);
      setFormMessage(FormMessageInItState);
      setIsUploadingPropertyLogo(false);
      onSuccess();
    } else {
      setIsUploadingPropertyLogo(false);
      setFormMessage(FormMessageErrorState);
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json),
      });
      setSubmitting(false);
    }
  };
};

const touchedInitState = {
  asset_managers: false,
  supervisor_id: false,
  caretaker_id: false,
  expenses: false,
  wault: false,
  street: false,
  house_number: false,
  zip_code: false,
  portfolio: false,
  company_id: false,
  phone_number: false,
  tax_number: false,
  vat: false,
  architect: false,
};

export const itemsListHeadCells: TableCell[] = [
  {
    id: "contact",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.anchorTenant",
  },
  {
    id: "area",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.rentedArea",
    align: "right",
  },
  {
    id: "rent_start",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.rentStartDate",
    align: "right",
  },
  {
    id: "rent_end",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.rentEnd",
    align: "right",
  },
  {
    id: "net_rent_per_year",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.netRentPerYear",
    align: "right",
  },
  {
    id: "rental_income",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.rentalIncome",
    align: "right",
  },
];

export const itemsListHeadCellsForAMHistory: TableCell[] = [
  {
    id: "note",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.assetManager",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "addedAt",
    align: "right",
  },
];
