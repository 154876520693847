import React, { ReactElement } from "react";
import { Dialog, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ListDialogProps } from "../types";
import CloseButton from "../../../ui/dialog/CloseButton";
import { DialogContent, DialogTitleContainer } from "../styled";
import OpenCommentsWidgetTabs from "./tabs";

const OpenCommentsListDialog = ({
  visible,
  toggleListModal,
}: ListDialogProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      open={visible}
      onClose={toggleListModal}
      maxWidth="xl"
      data-testid="open-comment-dialog"
    >
      <DialogTitle sx={{ pt: 0, pb: 0 }}>
        <DialogTitleContainer>
          <Typography
            variant="h6"
            component="div"
            justifyContent="space-around"
            width="100%"
          >
            {t("Comments")}
          </Typography>
          <CloseButton onClick={toggleListModal} />
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        <div>
          <OpenCommentsWidgetTabs />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OpenCommentsListDialog;
