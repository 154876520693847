import React, { ReactElement, useState, useEffect, useCallback } from "react";
import { Formik } from "formik";
import Form from "./Form";
import { Stack, Grid } from "@mui/material";
import { FormMessageInItState, PAGE_LIMIT } from "../../../../constants";
import FromAlert from "../../../../ui/formAlert/FormAlert";
import useValidation from "../../../../hooks/useValidation";
import * as Yup from "yup";
import { FormWrapper } from "../searchProfile/styled";
import { financeProfileFormInitState, handleFinanceFormSubmit } from "./utils";
import FinanceTable from "./Table";
import { fetchGermanStates } from "../../../../api/countryStates";
import { getFinanceProfile } from "../../../../api/financeProfile";
import Loader from "../../../Loader";
import { TabPanelItemProps } from "../types";
import { prepareQueryParams } from "../../../../utils/common";
import { State } from "../../../../types/be/country";

const FinanceProfile = ({ id, contact }: TabPanelItemProps): ReactElement => {
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [initialValues, setInitialValues] = useState(
    financeProfileFormInitState
  );
  const [initialStates, setInitialStates] = useState<State[]>([]);
  const [loading, setLoading] = useState(false);
  const [financeProfileUpdated, setFinanceProfileUpdated] =
    useState<boolean>(false);
  const [isFinanceProfileData, setIsFinanceProfileData] = useState<boolean>(
    Boolean(contact.finance_profiles?.length)
  );
  const {
    zip_code,
    state_id,
    finance_volume_from_value,
    finance_volume_to_value,
    avg_repayment,
    ltv,
    avg_interest_rate,
  } = useValidation();

  const fetchAndSetStateOptions = async (): Promise<void> => {
    const params = prepareQueryParams("", {
      country_code: "DE",
      limit: PAGE_LIMIT._20,
      order: "asc",
    });
    const res = (await (await fetchGermanStates(params)).json()).data;
    setInitialStates(res);
  };

  useEffect(() => {
    fetchAndSetStateOptions();
  }, []);

  const handleEditClick = useCallback(async (id: number): Promise<void> => {
    window.scrollTo(0, 0);
    setLoading(true);
    const res = await getFinanceProfile(id);
    const { data } = await res.json();

    setInitialValues({ ...data, state_id: data.state.id });
    setLoading(false);
  }, []);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        <Grid container>
          <Grid item xs={12}>
            <FormWrapper>
              {formMessage.text && (
                <FromAlert
                  formMessage={formMessage}
                  sx={{ marginBottom: "10px" }}
                />
              )}
              {loading ? (
                <Loader />
              ) : (
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={Yup.object().shape({
                    zip_code,
                    state_id,
                    finance_volume_from_value,
                    finance_volume_to_value,
                    ltv,
                    avg_interest_rate,
                    avg_repayment,
                  })}
                  onSubmit={handleFinanceFormSubmit({
                    id,
                    setFinanceProfileUpdated,
                    setIsFinanceProfileData,
                    setFormMessage,
                  })}
                >
                  {(props): ReactElement => (
                    <Form
                      {...{
                        ...props,
                        initialStates,
                      }}
                    />
                  )}
                </Formik>
              )}
            </FormWrapper>
          </Grid>
        </Grid>
      </Stack>
      {isFinanceProfileData ? (
        <FinanceTable
          handleEditClick={handleEditClick}
          id={id}
          financeProfileUpdated={financeProfileUpdated}
          setFinanceProfileUpdate={setFinanceProfileUpdated}
          setIsFinanceProfileData={setIsFinanceProfileData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default FinanceProfile;
