import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorBox } from "../../properties/objectDataSheet/tabs/generalInformation/styled";
import {
  COMMENTABLE,
  FormMessageInItState,
  PermissionsRead,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../constants";
import Loader from "../../Loader";
import { route } from "../../../utils/url";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Paper } from "@mui/material";
import useComments from "../../../ui/сomments/useComments";
import Comments from "../../../ui/сomments";
import useAccessControl from "../../../hooks/useAccessControl";
import { getJson } from "../../../utils/http";
import { replaceNullOrUndefinedByEmptyString } from "../../../utils/common";
import { getProperty } from "../../../api/property";
import { Email } from "@mui/icons-material";
import useValidation from "../../../hooks/useValidation";
import { mapPropertyToUpdatePropertyGeneralData } from "./utils";
import FormAlert from "../../../ui/formAlert/FormAlert";
import { Formik } from "formik";
import * as Yup from "yup";
import { handleUpdatePropertyFormSubmit } from "../../properties/edit/utils";
import Form from "./Form";
import { FormWrapper } from "../../properties/edit/general/styled";
import TabPageWrapper from "../../../ui/tabPageWrapper";

/* istanbul ignore next */
const EditCompany = (): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [property, setProperty] = useState<any | undefined>(undefined);
  const [isPropertyLoading, setIsPropertyLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [formMessage, setFormMessage] = useState(FormMessageInItState);

  const navigate = useNavigate();
  const { can } = useAccessControl();

  const {
    location,
    property_type_id,
    purchase_price,
    object_name,
    notes,
    optional_percentage,
  } = useValidation();

  const {
    comments,
    content,
    setContent,
    currentPage,
    totalPages,
    handleCommentSave,
    handleCommentDelete,
    handlePaginationChange,
    deleting,
    loading,
  } = useComments({
    relation: COMMENTABLE.PROPERTY,
    relation_id: id!,
  });

  useEffect(() => {
    setIsPropertyLoading(true);
    getPropertyData();
    setIsPropertyLoading(false);
  }, [id]);

  const getPropertyData = (): void => {
    getProperty(id!)
      .then(getJson)
      .then(({ data }) => {
        setProperty(replaceNullOrUndefinedByEmptyString(data));
      })
      .catch(
        /* istanbul ignore next */ (err) => {
          console.error(err);
          setIsError(true);
        }
      );
  };
  const breadcrumbs = [
    {
      to: route("companies"),
      name: t("company.companies"),
    },
  ];

  if (isError) {
    return (
      <ErrorBox
        formMessage={{ type: "error", text: SOMETHING_WENT_WRONG_ERROR }}
      />
    );
  }

  if (isPropertyLoading || !property) {
    return <Loader />;
  }

  const initialValues = mapPropertyToUpdatePropertyGeneralData(property);

  return (
    <TabPageWrapper
      title={String(property?.object_name)}
      breadcrumbs={breadcrumbs}
    >
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} xl={8}>
            <Paper>
              <Grid container direction={"column"} sx={{ pt: 2 }}>
                <Grid item>
                  <Grid
                    container
                    spacing={3}
                    justifyContent={"space-between"}
                    sx={{ px: 5 }}
                  >
                    <Grid item>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        sx={{ py: 2 }}
                      >
                        <Grid item>
                          <Grid container justifyContent={"end"} spacing={1}>
                            {can([PermissionsRead.INBOX]) && (
                              <Grid
                                item
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <Email
                                  color="primary"
                                  width={22}
                                  fontSize="small"
                                  height={22}
                                  onClick={() =>
                                    navigate(route("properties.inbox", id))
                                  }
                                  data-testid="email-icon"
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <FormWrapper>
                {formMessage.text && (
                  <FormAlert formMessage={formMessage} sx={{ mb: 2.5 }} />
                )}
                {property && (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                      location,
                      property_type_id,
                      purchase_price,
                      object_name,
                      notes,
                      taxes: optional_percentage,
                      non_circulating_property_management: optional_percentage,
                      operating_costs_non_reversible: optional_percentage,
                      net_rent_target: optional_percentage,
                    })}
                    onSubmit={handleUpdatePropertyFormSubmit({
                      setFormMessage,
                      initialValues,
                    })}
                    enableReinitialize
                  >
                    {(props): ReactElement => (
                      <Form
                        {...{
                          ...props,
                          ...mapPropertyToUpdatePropertyGeneralData(property),
                          getPropertyData,
                        }}
                      />
                    )}
                  </Formik>
                )}
              </FormWrapper>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={4}>
            <Comments
              editorContent={content}
              setEditorContent={setContent}
              handleSaveComment={handleCommentSave}
              comments={comments}
              currentPage={currentPage}
              totalPages={totalPages}
              handleDeleteComment={handleCommentDelete}
              handlePaginationChange={handlePaginationChange}
              deleting={deleting}
              loading={loading}
            />
          </Grid>
        </Grid>
      </>
    </TabPageWrapper>
  );
};

export default EditCompany;
