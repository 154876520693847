import styled from "styled-components/macro";
import { Paper } from "@mui/material";
/* istanbul ignore next */
export const FileWrapper = styled(Paper).attrs(
  ({ $height, $width }: { $height: string; $width: string }) => ({
    $height: $height || "120px",
    $width: $width || "120px",
  })
)`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  object-fit: cover;
  img {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
  }
`;

/* istanbul ignore next */
export const HoverMask = styled.div.attrs(
  ({ $isHovered }: { $isHovered: boolean }) => ({
    $isHovered: $isHovered || false,
  })
)`
  position: relative;
  width: 120px;
  height: 120px;
  background: #0000008a;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  transition: opacity 0.1s;
`;
