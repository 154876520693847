export default {
  description: "Beschreibung",
  contactName: "Kontaktname",
  report: "Wartungsbericht",
  inspectionAndMaintenanceInterval:
    "Inspektions- und Wartungsintervalle (gesetzlich)",
  contract: "Vertrag",
  order: "Wartungsauftrag",
  units: "Einheiten",
  lastMaintenance: "Letzte Wartung",
  title: "Wartung",
  maintenanceType: "Wartungstyp",
  type: "Typ",
  typeManagement: "Typverwaltung",
  typeCreatedSuccessfully: "Typ erfolgreich erstellt",
  pleaseSelectMaintenanceType: "Bitte wählen Sie den Wartungstyp",
  pleaseSelectInspection:
    "Bitte wählen Sie die Inspektions- und Wartungsintervalle (gesetzlich)",
  descriptionLengthError:
    "Die Beschreibung darf höchstens 255 Zeichen lang sein",
  createUnit: "Einheit erstellen",
  unitCreatedSuccessfully: "Einheit erfolgreich erstellt",
  resolved: "Erledigt",
};
