import { TableCell } from "../../../types/table";
import { UploadFileType } from "../../../types/fe/property";
import { FormikValues } from "formik";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { mapBackendValidationErrors } from "../../../utils/form";
import { handleManullyPropertyUploadProps } from "../../properties/list/types";
import { uploadFile } from "../../../api/property";
import { HTTP_STATUS_CODES } from "../../../types/server";
import {
  setDownloadLink,
  setFileName,
} from "../../../redux/slices/importValidation/importValidation";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "object_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "company.companyColumnName",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "address",
  },
  {
    id: "notice",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "company.notice",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];

export const uploadDialogInitState: UploadFileType = {
  file: "",
};
/* istanbul ignore next */
export const handleManullyPropertyUpload = ({
  setFormMessage,
  onSuccess,
  dispatchStore,
}: handleManullyPropertyUploadProps) => {
  return async (
    data: UploadFileType,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const reqData = new FormData();
    reqData.append("file", data.file!);
    const response = await uploadFile(reqData);
    const json = await response.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      const {
        data: {
          file: { name, download_link },
        },
      } = json;
      dispatchStore(setFileName(name));
      dispatchStore(setDownloadLink(download_link));
      setStatus({
        success: true,
        errors: {},
      });
      setFormMessage({ type: undefined, text: "" });
      onSuccess();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setSubmitting(false);
    }
  };
};
