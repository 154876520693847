import { useEffect } from "react";

const useOutsideClick = (
  contextMenuRef: React.MutableRefObject<HTMLElement | null>,
  callback: () => void
): void => {
  const handleClickOutside = (event: MouseEvent): void => {
    event.target instanceof Element &&
      !contextMenuRef?.current?.contains(event.target) &&
      callback();
  };
  /* istanbul ignore next */
  const handleEscClick = (event: KeyboardEvent): void => {
    event.key === "Escape" && callback();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscClick);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscClick);
    };
  });
};

export default useOutsideClick;
