import DialogContent from "@mui/material/DialogContent";
import { Grid, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { UpdateLeaseModalProps } from "./types";
import Button from "../../../../../ui/button/Button";
import { FormMessageInItState } from "../../../../../constants";
import * as Yup from "yup";
import { FormikValues } from "formik/dist/types";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "../../../../../ui/formsFields/text";
import { Formik } from "formik";
import { handleDatepicker } from "../../../../../utils/form";
import { handleUpdateTenantLease, updateLeaseInitState } from "./utils";
import useValidation from "../../../../../hooks/useValidation";
import AlertCustom from "../../../../../ui/formAlert/FormAlert";
import DialogTitle from "../../../../../ui/dialog/DialogTitle";

/* istanbul ignore next */
export const UpdateLeaseForActiveTenantModal = ({
  area,
  isConfirmToRemoveModalOpen,
  handleConfirmToRemoveModalClose,
  fetchAreasAfterLeaseUpdate,
}: UpdateLeaseModalProps): ReactElement => {
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const { t } = useTranslation();
  const { lease_cancel_date: rent_end } = useValidation();

  return (
    <Dialog
      maxWidth={"xs"}
      open={isConfirmToRemoveModalOpen}
      onClose={handleConfirmToRemoveModalClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="dialog-popup"
    >
      <DialogTitle
        title="property.area.updateTenantLease"
        onClose={handleConfirmToRemoveModalClose}
      />
      <DialogContent>
        {formMessage.text && (
          <AlertCustom
            formMessage={formMessage}
            sx={{ marginBottom: "10px" }}
          />
        )}
        <Formik
          enableReinitialize
          initialValues={updateLeaseInitState}
          validationSchema={Yup.object().shape({
            rent_end,
          })}
          onSubmit={handleUpdateTenantLease({
            area,
            setFormMessage,
            handleConfirmToRemoveModalClose,
            fetchAreasAfterLeaseUpdate,
          })}
        >
          {({
            handleSubmit,
            isSubmitting,
            status,
            setFieldValue,
            values,
          }: FormikValues): ReactElement => (
            <form onSubmit={handleSubmit}>
              <Grid container columns={12} mb={6} mt={2}>
                <Grid item sm={7}>
                  <Typography
                    variant="inherit"
                    gutterBottom
                    component="div"
                    paddingTop={2}
                  >
                    {t("property.area.cancelTheLeaseOn")}
                  </Typography>
                </Grid>
                <Grid item sm={5}>
                  <DatePicker
                    openTo="year"
                    views={["year", "month", "day"]}
                    inputFormat="dd/MM/yyyy"
                    value={values.rent_end}
                    label={t("tenant.endRentDate")}
                    onChange={handleDatepicker({ setFieldValue }, "rent_end")}
                    minDate={
                      new Date(Number(area.active_tenant?.rent_start) * 1000)
                    }
                    maxDate={
                      area.future_tenant?.rent_start
                        ? new Date(
                            Number(area.future_tenant?.rent_start) * 1000
                          )
                        : area.active_tenant?.rent_end
                        ? new Date(Number(area.active_tenant?.rent_end) * 1000)
                        : undefined
                    }
                    renderInput={(params) => (
                      <TextField
                        type="date"
                        fullWidth
                        size="small"
                        name="rent_end"
                        value={values.rent_end}
                        error={false}
                        status={status}
                        {...params}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Grid item>
                  <Button
                    onClick={handleConfirmToRemoveModalClose}
                    title={t("cancel")}
                    testId="cancel-button"
                    variant={"text"}
                    size="large"
                    type="button"
                  />
                </Grid>
                <Grid item>
                  <Button
                    title={t("property.area.submit")}
                    type="submit"
                    sx={{ ml: "1rem" }}
                    size="large"
                    color="success"
                    testId="delete-button"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateLeaseForActiveTenantModal;
