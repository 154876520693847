import React, { ReactElement } from "react";
import { useLocation, useParams } from "react-router-dom";
import { UsePropertyWrapperReturnType } from "./types";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";
import { useTranslation } from "react-i18next";
import { route } from "../../../utils/url";
import { BreadcrumbItem } from "../../../ui/pageWrapper/PageWrapper";
import EditProperty from "../edit";
import ObjectDataSheet from "../objectDataSheet/ObjectDataSheet";
import MaintenanceList from "../maintenance/list";
import CreateMaintenance from "../maintenance/create";
import EditMaintenance from "../maintenance/edit";
import MaintenanceUnits from "../maintenance/MaintenanceUnits";
import InvoiceList from "../invoices/list/InvoiceList";
import InvoiceCreate from "../invoices/create/InvoiceCreate";
import InvoiceEdit from "../invoices/edit/InvoiceEdit";
import InvoiceShow from "../invoices/show/InvoiceShow";
import ShowDateVInvoice from "../invoices/showDateVInvoice";
import CreateDateVInvoice from "../invoices/createDateVInvoice";
import EditDateVInvoice from "../invoices/editDateVInvoice";
import ReminderList from "../invoices/reminder/list";
import ReminderCreate from "../invoices/reminder/create";
import OutgoingInvoiceList from "../outgoingInvoices/list";
import OutgoingInvoiceShow from "../outgoingInvoices/show";
import OutgoingInvoiceCreate from "../outgoingInvoices/create";
import OutgoingInvoiceEdit from "../outgoingInvoices/edit";
import { camelCase } from "lodash";
import APIDateV from "../invoices/apidatev";
import ContractList from "../contracts/list/ContractList";
import ContractCreate from "../contracts/create/ContractCreate";
import ContractEdit from "../contracts/edit/ContractEdit";
import ContractShow from "../contracts/show/ContractShow";
import TopicList from "../offers/list/topicList";
import OfferCreate from "../offers/create";
import OfferEdit from "../offers/edit/OfferEdit";
import OfferShow from "../offers/show/OfferShow";
import InsuranceTopicList from "../insurances/list/topicList";
import CreateInsurance from "../insurances/create";
import EditInsurance from "../insurances/edit/EditInsurance";
import InsuranceDetails from "../insurances/show";
import InboxList from "../../inbox/list";
import InboxCreate from "../../inbox/create";
import AreaList from "../areas/list";
import AreaEdit from "../areas/edit";
import { TABS } from "../invoices/apidatev/utils";
import CreateCreditorRequest from "../invoices/apidatev/creditor/create";
import CreateDebtor from "../invoices/apidatev/debtor/create";
import { CreateDebitAccount } from "../invoices/apidatev/debitAccount/create";
import { AreaListProvider } from "../areas/list/AreaListContext";
import { AreaEditProvider } from "../areas/edit/AreaEditContext";
import { getRoute } from "./utils";
import EditCompany from "../../company/edit";
import GeneralDocumentList from "../../generalDocuments/list";
import GeneralDocumentCreate from "../../generalDocuments/create";
import GeneralDocumentShow from "../../generalDocuments/show";
import FileManagement from "../../fileManagement";

/* istanbul ignore next */
const usePropertyWrapper = (): UsePropertyWrapperReturnType => {
  const {
    id,
    tab,
    maintenanceId,
    status,
    invoiceId,
    contractId,
    topicId,
    type,
    insuranceId,
    areaId,
    documentId,
    offerId,
  } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { propertyBreadcrumbs } = useBreadcrumbs();

  const getTitle = (): string => {
    switch (pathname) {
      case getRoute("edit", id, tab):
        return propertyBreadcrumbs?.object_name ?? "";
      case route("properties.object-data-sheet.tab", id, tab):
        return t("property.objectDataSheet.title");
      case route("properties.maintenance", id):
        return t("property.maintenance.title");
      case route("properties.maintenance.create", id):
        return t("create");
      case route("properties.maintenance.edit", id, maintenanceId):
        return t("edit");
      case route("properties.maintenance.units", id, maintenanceId):
        return t("property.maintenance.units");
      case getRoute("invoices", id):
        return t("invoices");
      case getRoute("invoices.tab", id, status):
        return t("invoices");
      case getRoute("invoices.create", id):
        return t("create");
      case getRoute("invoices.edit", id, invoiceId):
        return t("edit");
      case getRoute("invoices.show", id, invoiceId):
        return t("show");
      case getRoute("invoice.datev.create", id):
        return t("documentRelease.invoice.dateVInvoice.upload");
      case getRoute("invoices.show.datev", id, invoiceId):
        return t("show");
      case getRoute("invoices.edit.datev", id, invoiceId):
        return t("edit");
      case getRoute("invoices.reminders", id):
        return t("reminder.title");
      case getRoute("invoice.reminders.tab", id, status):
        return t("reminder.title");
      case getRoute("invoice.reminders.create", id):
        return t("create");
      case getRoute("outgoing-invoices", id):
        return t("outgoingInvoice");
      case getRoute("outgoing-invoices.show", id, invoiceId):
        return t("show");
      case getRoute("outgoing-invoices.create", id):
        return t("create");
      case getRoute("outgoing-invoices.edit", id, invoiceId):
        return t("edit");
      case getRoute("invoices.apidatev.tab", id, tab):
        return t(`documentRelease.invoice.apidatev.${camelCase(tab)}`);
      case getRoute("contracts", id):
        return t("contracts.contracts");
      case getRoute("contracts.tab", id, status):
        return t("contracts.contracts");
      case getRoute("contracts.create", id):
        return t("create");
      case getRoute("contracts.edit", id, contractId):
        return t("edit");
      case getRoute("contracts.show", id, contractId):
        return t("show");
      case getRoute("offers", id):
        return t("offers.offer");
      case getRoute("offers.tab", id, status):
        return t("offers.offer");
      case getRoute("offers.create", id, topicId):
        return t("create");
      case getRoute("offers.edit", id, offerId):
        return t("edit");
      case getRoute("offers.show", id, offerId):
        return t("show");
      case getRoute("insurances", id):
        return t("property.navLinks.insurances");
      case getRoute("insurances.type.status", id, type, status):
        return t("property.navLinks.insurances");
      case getRoute("insurances.history", id, status):
        return t("property.navLinks.insurances");
      case getRoute("insurances.create", id, topicId):
        return t("create");
      case getRoute("insurances.edit", id, insuranceId):
        return t("edit");
      case getRoute("insurances.show", id, insuranceId):
        return t("show");
      case getRoute("inbox", id):
        return t("inbox.title");
      case getRoute("inbox.tab", id, status):
        return t("inbox.title");
      case getRoute("inbox.create", id):
        return t("create");
      // General documents
      case getRoute("general-documents", id):
        return t("generalDocuments.title");
      case getRoute("general-documents.tab", id, status):
        return t("generalDocuments.title");
      case getRoute("general-documents.create", id):
        return t("generalDocuments.create");
      case getRoute("general-documents.show", id, documentId):
        return t("generalDocuments.show");

      // Areas
      case route("properties.areas", id):
        return t("areas");
      case route("properties.areas.edit.tab", id, areaId, tab):
        return tab || "";
      case getRoute("invoices.apidatev.tab.create", id, TABS.CREDITOR):
      case getRoute("invoices.apidatev.tab.create", id, TABS.DEBTOR):
      case getRoute("invoices.apidatev.tab.create", id, TABS.DEBITACCOUNT):
        return t("create");

      default:
        return "";
    }
  };

  const getBreadcrumb = (): BreadcrumbItem[] => {
    const breadcrumb = [];

    if (pathname.includes("properties")) {
      breadcrumb.push({
        to: route("properties"),
        name: t("properties"),
      });
    } else {
      breadcrumb.push({
        to: route("companies"),
        name: t("company.companies"),
      });
    }

    switch (pathname) {
      case route("properties.maintenance.create", id):
        breadcrumb.push({
          to: route("properties.maintenance", id),
          name: t("property.maintenance.title"),
        });
        break;
      case route("properties.maintenance.edit", id, maintenanceId):
        breadcrumb.push({
          to: route("properties.maintenance", id),
          name: t("property.maintenance.title"),
        });
        break;
      case route("properties.maintenance.units", id, maintenanceId):
        breadcrumb.push({
          to: route("properties.maintenance", id),
          name: t("property.maintenance.title"),
        });
        break;
      case getRoute("invoices.create", id):
        breadcrumb.push({
          to: getRoute("invoices", id),
          name: t("invoices"),
        });
        break;
      case getRoute("invoices.edit", id, invoiceId):
        breadcrumb.push({
          to: getRoute("invoices", id),
          name: t("invoices"),
        });
        break;
      case getRoute("invoices.show", id, invoiceId):
        breadcrumb.push({
          to: getRoute("invoices", id),
          name: t("invoices"),
        });
        break;
      case getRoute("invoice.datev.create", id):
        breadcrumb.push({
          to: getRoute("invoices", id),
          name: t("invoices"),
        });
        break;
      case getRoute("invoices.show.datev", id, invoiceId):
        breadcrumb.push({
          to: getRoute("invoices", id),
          name: t("invoices"),
        });
        break;
      case getRoute("invoices.edit.datev", id, invoiceId):
        breadcrumb.push({
          to: getRoute("invoices", id),
          name: t("invoices"),
        });
        break;
      case getRoute("invoice.reminders.create", id):
        breadcrumb.push({
          to: getRoute("invoices.reminders", id),
          name: t("reminder.title"),
        });
        break;
      case getRoute("outgoing-invoices.show", id, invoiceId):
        breadcrumb.push({
          to: getRoute("outgoing-invoices", id),
          name: t("outgoingInvoice"),
        });
        break;
      case getRoute("outgoing-invoices.create", id):
        breadcrumb.push({
          to: getRoute("outgoing-invoices", id),
          name: t("outgoingInvoice"),
        });
        break;
      case getRoute("outgoing-invoices.edit", id, invoiceId):
        breadcrumb.push({
          to: getRoute("outgoing-invoices", id),
          name: t("outgoingInvoice"),
        });
        break;
      case getRoute("contracts.create", id):
        breadcrumb.push({
          to: getRoute("contracts", id),
          name: t("contracts.contracts"),
        });
        break;
      case getRoute("contracts.edit", id, contractId):
        breadcrumb.push({
          to: getRoute("contracts", id),
          name: t("contracts.contracts"),
        });
        break;
      case getRoute("contracts.show", id, contractId):
        breadcrumb.push({
          to: getRoute("contracts", id),
          name: t("contracts.contracts"),
        });
        break;
      case getRoute("offers.create", id, topicId):
        breadcrumb.push({
          to: getRoute("offers", id),
          name: t("offers.offer"),
        });
        break;
      case getRoute("offers.edit", id, offerId):
        breadcrumb.push({
          to: getRoute("offers", id),
          name: t("offers.offer"),
        });
        break;
      case getRoute("offers.show", id, offerId):
        breadcrumb.push({
          to: getRoute("offers", id),
          name: t("offers.offer"),
        });
        break;
      case getRoute("insurances.create", id, topicId):
        breadcrumb.push({
          to: getRoute("insurances", id),
          name: t("property.navLinks.insurances"),
        });
        break;
      case getRoute("insurances.edit", id, insuranceId):
        breadcrumb.push({
          to: getRoute("insurances", id),
          name: t("property.navLinks.insurances"),
        });
        break;
      case getRoute("insurances.show", id, insuranceId):
        breadcrumb.push({
          to: getRoute("insurances", id),
          name: t("property.navLinks.insurances"),
        });
        break;
      case getRoute("inbox.create", id):
        breadcrumb.push({
          to: getRoute("inbox", id),
          name: t("inbox.title"),
        });
        break;
      case getRoute("general-documents.create", id):
        breadcrumb.push({
          to: getRoute("general-documents", id),
          name: t("generalDocuments.title"),
        });
        break;
      case getRoute("general-documents.show", id, documentId):
        breadcrumb.push({
          to: getRoute("general-documents", id),
          name: t("generalDocuments.title"),
        });
        break;

      case route("properties.areas.edit.tab", id, areaId, tab):
        breadcrumb.push({
          to: route("properties.areas", id),
          name: t("areas"),
        });
        break;
      case getRoute("invoices.apidatev.tab.create", id, TABS.CREDITOR):
        breadcrumb.push({
          to: getRoute("invoices.apidatev.tab", id, tab),
          name: t(`documentRelease.invoice.apidatev.${TABS.CREDITOR}`),
        });
        break;
      case getRoute("invoices.apidatev.tab.create", id, TABS.DEBTOR):
        breadcrumb.push({
          to: getRoute("invoices.apidatev.tab", id, tab),
          name: t(`documentRelease.invoice.apidatev.${TABS.DEBTOR}`),
        });
        break;
      case getRoute("invoices.apidatev.tab.create", id, TABS.DEBITACCOUNT):
        breadcrumb.push({
          to: getRoute("invoices.apidatev.tab", id, tab),
          name: t(`documentRelease.invoice.apidatev.${TABS.DEBITACCOUNT}`),
        });
        break;
    }

    return breadcrumb;
  };

  const getComponent = (): ReactElement => {
    switch (pathname) {
      case route("properties.edit", id, tab):
        return <EditProperty />;
      case route("companies.edit", id):
        return <EditCompany />;
      case route("properties.object-data-sheet.tab", id, tab):
        return <ObjectDataSheet />;
      case route("properties.maintenance", id):
        return <MaintenanceList />;
      case route("properties.maintenance.create", id):
        return <CreateMaintenance />;
      case route("properties.maintenance.edit", id, maintenanceId):
        return <EditMaintenance />;
      case route("properties.maintenance.units", id, maintenanceId):
        return <MaintenanceUnits />;
      case getRoute("invoices", id):
        return <InvoiceList />;
      case getRoute("invoices.tab", id, status):
        return <InvoiceList />;
      case getRoute("invoices.create", id):
        return <InvoiceCreate />;
      case getRoute("invoices.edit", id, invoiceId):
        return <InvoiceEdit />;
      case getRoute("invoices.show", id, invoiceId):
        return <InvoiceShow />;
      case getRoute("invoice.datev.create", id):
        return <CreateDateVInvoice />;
      case getRoute("invoices.show.datev", id, invoiceId):
        return <ShowDateVInvoice />;
      case getRoute("invoices.edit.datev", id, invoiceId):
        return <EditDateVInvoice />;
      case getRoute("invoices.reminders", id):
        return <ReminderList />;
      case getRoute("invoice.reminders.tab", id, status):
        return <ReminderList />;
      case getRoute("invoice.reminders.create", id):
        return <ReminderCreate />;
      case getRoute("outgoing-invoices", id):
        return <OutgoingInvoiceList />;
      case getRoute("outgoing-invoices.show", id, invoiceId):
        return <OutgoingInvoiceShow />;
      case getRoute("outgoing-invoices.create", id):
        return <OutgoingInvoiceCreate />;
      case getRoute("outgoing-invoices.edit", id, invoiceId):
        return <OutgoingInvoiceEdit />;
      case getRoute("invoices.apidatev.tab", id, tab):
        return <APIDateV />;
      case getRoute("contracts", id):
        return <ContractList />;
      case getRoute("contracts.tab", id, status):
        return <ContractList />;
      case getRoute("contracts.create", id):
        return <ContractCreate />;
      case getRoute("contracts.edit", id, contractId):
        return <ContractEdit />;
      case getRoute("contracts.show", id, contractId):
        return <ContractShow />;
      case getRoute("offers", id):
        return <TopicList />;
      case getRoute("offers.tab", id, status):
        return <TopicList />;
      case getRoute("offers.create", id, topicId):
        return <OfferCreate />;
      case getRoute("offers.edit", id, offerId):
        return <OfferEdit />;
      case getRoute("offers.show", id, offerId):
        return <OfferShow />;
      case getRoute("insurances", id):
        return <InsuranceTopicList />;
      case getRoute("insurances.type.status", id, type, status):
        return <InsuranceTopicList />;
      case getRoute("insurances.history", id, status):
        return <InsuranceTopicList />;
      case getRoute("insurances.create", id, topicId):
        return <CreateInsurance />;
      case getRoute("insurances.edit", id, insuranceId):
        return <EditInsurance />;
      case getRoute("insurances.show", id, insuranceId):
        return <InsuranceDetails />;
      case getRoute("inbox", id):
        return <InboxList />;
      case getRoute("inbox.tab", id, status):
        return <InboxList />;
      case getRoute("inbox.create", id):
        return <InboxCreate />;
      // General documents
      case getRoute("general-documents", id):
        return <GeneralDocumentList />;
      case getRoute("general-documents.tab", id, status):
        return <GeneralDocumentList />;
      case getRoute("general-documents.create", id):
        return <GeneralDocumentCreate />;
      case getRoute("general-documents.show", id, documentId):
        return <GeneralDocumentShow />;
      // Areas
      case route("properties.areas", id):
        return (
          <AreaListProvider>
            <AreaList />
          </AreaListProvider>
        );
      case route("properties.areas.edit.tab", id, areaId, tab):
        return (
          <AreaEditProvider>
            <AreaEdit />
          </AreaEditProvider>
        );
      case getRoute("invoices.apidatev.tab.create", id, TABS.CREDITOR):
        return <CreateCreditorRequest />;
      case getRoute("invoices.apidatev.tab.create", id, TABS.DEBTOR):
        return <CreateDebtor />;
      case route(
        "properties.invoices.apidatev.tab.create",
        id,
        TABS.DEBITACCOUNT
      ):
        return <CreateDebitAccount />;
      // File management
      case getRoute("file-management", id):
        return <FileManagement />;
      default:
        return <></>;
    }
  };

  return {
    getTitle,
    getBreadcrumb,
    propertyBreadcrumbs,
    getComponent,
  };
};

export default usePropertyWrapper;
