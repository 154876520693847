/* istanbul ignore file */
import { Grid } from "@mui/material";
import styled from "styled-components";
import { MinusCircle, PlusCircle } from "react-feather";
import {
  CloudUpload as MuiCloudUpload,
  Download as MuiDownloadIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
export const ItemWrapper = styled(Grid)`
  border-top: ${(props): string => props.theme.spacing(0.25)} solid
    ${(props): string => props.theme.border.color};
  border-bottom: ${(props): string => props.theme.spacing(0.25)} solid
    ${(props): string => props.theme.border.color};
  margin-top: ${(props): string => props.theme.spacing(6)};
  margin-left: ${(props): string => props.theme.spacing(6)};
  padding-bottom: ${(props): string => props.theme.spacing(4)} !important;
`;

export const PlusIcon = styled(PlusCircle)`
  margin-left: ${(props): string => props.theme.spacing(2)};
  margin-top: ${(props): string => props.theme.spacing(3)};
  width: ${(props): string => props.theme.spacing(7)};
  height: ${(props): string => props.theme.spacing(7)};
`;

export const MinusIcon = styled(MinusCircle)`
  margin-left: ${(props): string => props.theme.spacing(2)};
  margin-top: ${(props): string => props.theme.spacing(3)};
  width: ${(props): string => props.theme.spacing(7)};
  height: ${(props): string => props.theme.spacing(7)};
`;

export const FileUploadInput = styled.input`
  display: none;
`;

export const CloudUpload = styled(MuiCloudUpload)(spacing);

export const DownloadIcon = styled(MuiDownloadIcon)(spacing);

export const StyledGrid = styled(Grid)`
  display: flex;
  justify-content: center;
`;
