import { FormikHelpers } from "formik";
import { CreateTenantReminder, SubmitCreatTenantReminderProps } from "./types";
import { formatDateToTimespamp } from "../../../../../../../utils/date";
import { createReminders } from "../../../../../../../api/reminder";
import { HTTP_STATUS_CODES } from "../../../../../../../types/server";
import { mapBackendValidationErrors } from "../../../../../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../../constants";

export const createTenantReminderInitState: CreateTenantReminder = {
  file: "",
  date: null,
};
/* istanbul ignore next */
export const handleCreateReminders = ({
  handleCreateModalClose,
  t,
  fetchReminder,
  snackbar,
}: SubmitCreatTenantReminderProps) => {
  return async (
    values: CreateTenantReminder,
    { setStatus }: FormikHelpers<CreateTenantReminder>
  ): Promise<void | Response> => {
    const formData = new FormData();
    values?.file && formData.append("file", values?.file);
    values?.date &&
      formData.append("date", formatDateToTimespamp(values?.date).toString());
    const response = await createReminders(formData);
    const { errors } = await response.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response?.status
      )
    ) {
      fetchReminder();
      handleCreateModalClose();
      snackbar.success(t("reminder.create.reminderCreatedSuccessfully"));
      return response;
    } else if (response?.status == HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(errors),
      });
    } else {
      snackbar.error(SOMETHING_WENT_WRONG_ERROR);
    }
  };
};
