export default {
  attachments: "Attachments",
  fileName: "File name",
  uploadedBy: "Uploaded by",
  uploadedAt: "Uploaded at",
  files: "Attachments",
  archived: "Archived attachments",
  archive: "Archive",
  unArchive: "Unarchive",
  attachmentUploadedSuccessfully: "Attachment uploaded successfully",
  attachmentCreatedSuccessfully: "Attachment created successfully",
  sendEmail: "Send email",
  mailSendSuccessfully: "Email send successfully",
  uploadFile: "Upload File",
  fileArchivedSuccessfully: "File archive successfully",
  fileUnArchivedSuccessfully: "File unarchive successfully",
};
