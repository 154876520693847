import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Input, Search, SearchIconWrapper } from "./styled";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { FileSearchItem as BEFileSearchItem } from "../../types/be.interfaces";
import { Autocomplete, CircularProgress } from "@mui/material";
import { getFilesSearch } from "../../api/files";
import {
  FileTreeEnum,
  GENERAL_SEARCH_PARAM_MIN_CHARACTERS,
  PAGE_LIMIT,
} from "../../constants";
import debounce from "lodash/debounce";
import useIsMounted from "../../hooks/useIsMounted";
import { prepareQueryParams } from "../../utils/common";
import { DEFAULT_PAGE, mutateFileTree } from "./utils";
import { FileManagementContext } from "./contexts/FileManagementContext";
import { composeNodeId } from "../properties/components/fileTree/utils";
import head from "lodash/head";
import { StyledPaper } from "../contacts/autocomplete/styled";

const FileManagementSearch = (): ReactElement => {
  const { t } = useTranslation();
  const { setExpanded, setSelected, cachedFolders, runFolderSortedByDate } =
    useContext(FileManagementContext);
  const [options, setOptions] = useState<readonly BEFileSearchItem[] | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const filePath = (foldersArray: number[]): string[] => {
    return foldersArray.reverse().map((id: number) => FileTreeEnum.FOLDER + id);
  };

  const handleClickMenuItem = (parent_folders: number[]) => async () => {
    const itemId = String(head(parent_folders));

    runFolderSortedByDate(itemId);
    setSelected(composeNodeId(itemId));

    const newExpanded = filePath(parent_folders);
    /* istanbul ignore next */
    if (!cachedFolders) return;
    await mutateFileTree(newExpanded, cachedFolders);
    setExpanded(newExpanded);
    handleClose();
  };

  const handleClose = (): void => setOpen(false);

  const handleOpen = (): void => setOpen(true);

  const resetOptions = (): void => setOptions(null);

  const onSearchFieldTextChange = useCallback(
    debounce(async (search: string): Promise<void> => {
      if (search.length < GENERAL_SEARCH_PARAM_MIN_CHARACTERS) {
        resetOptions();
      } else {
        isMount() && (await handleServerResponse(search));
      }
    }, 500),
    [getFilesSearch]
  );

  const handleServerResponse = async (search: string): Promise<void> => {
    setIsLoading(true);
    const params = prepareQueryParams("", {
      search,
      limit: String(PAGE_LIMIT._30),
      page: String(DEFAULT_PAGE),
    });
    const response = await getFilesSearch(params);
    response.status === 200 && setOptions((await response.json()).data);
    setIsLoading(false);
  };

  const isMount = useIsMounted();

  useEffect(() => {
    !open && resetOptions();
  }, [open]);

  return (
    <>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <Autocomplete
          id="grouped-demo"
          open={open}
          loading={isLoading}
          ListboxProps={{ style: { maxHeight: "none" } }}
          onOpen={handleOpen}
          onClose={handleClose}
          options={options || []}
          getOptionLabel={(option) => option.name}
          noOptionsText={!options ? t("startTyping") : t("noOptions")}
          filterOptions={(options) => options}
          PaperComponent={StyledPaper}
          renderOption={(props, { id, parent_folders, ...option }) => {
            return (
              <li
                {...props}
                key={id}
                onClick={handleClickMenuItem(parent_folders)}
              >
                {option.name}
              </li>
            );
          }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input
                placeholder={t("search")}
                inputProps={{ ...params.inputProps }}
                data-testid="file-management-search"
                onChange={(e) => onSearchFieldTextChange(e.target.value)}
                endAdornment={
                  <>
                    {isLoading && (
                      <CircularProgress color="inherit" size={20} />
                    )}
                  </>
                }
              />
            </div>
          )}
        />
      </Search>
    </>
  );
};

export default FileManagementSearch;
