import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { TableCell } from "@mui/material";
import useIsMounted from "../../../hooks/useIsMounted";
import {
  LogType as BELogType,
  UserActivity as BEUserActivity,
} from "../../../types/be/userActivity";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { ShowIcon } from "../../../ui/table/styled";
import Table from "../../../ui/table/Table";
import useTable from "../../../ui/table/useTable";
import {
  formatTimestamp,
  replaceMomentByFormatedString,
} from "../../../utils/date";
import {
  objectGetParamsToString,
  prepareQueryParams,
} from "../../../utils/common";
import { handleServerError } from "../../../utils/http";
import {
  ACTIVITY_DATE_AND_TIME_FORMAT,
  initUserActivityRange,
  itemsListHeadCells,
} from "./utils";
import UserActivityToolbar from "./toolbar";
import DeleteConfirmationModal from "../../../ui/table/DeleteConfirmationModal";
import { useFetch } from "../../../hooks/useFetch";
import { User } from "../../../types/models";
import { getUsers } from "../../../api/users";
import { LoadingBox } from "../../contacts/styled";
import Loader from "../../Loader";
import {
  deleteLastUserActivities,
  getLogTypes,
  getUserActivities,
} from "../../../api/userActivity";
import {
  DATE_FROM_PARAM,
  DATE_TO_PARAM,
  LOG_TYPE_ID_PARAM,
  USERS_ACTIVITY_ID_PARAM,
} from "../../../constants";
import moment from "moment";
import head from "lodash/head";
import last from "lodash/last";
import { DateRange } from "./types";
import ShowModal from "./show";
import { ActivityProps } from "./types";
import { useTranslation } from "react-i18next";

const UserActivity = (): ReactElement => {
  const { t } = useTranslation();
  const [showActivityModal, setActivityModal] = useState<ActivityProps>({
    data: null,
    open: false,
  });
  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleTableSearch,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    setTotal,
  } = useTable<BEUserActivity>();

  const [deleteOldActivitiesError, setDeleteOldActivitiesError] = useState("");
  const [isConfirmToDeleteModalOpen, setIsConfirmToDeleteModalOpen] =
    useState(false);
  const [activeTypeId, setActiveTypeId] = useState<number>(0);
  const [dateRange, setDateRange] = useState<DateRange>(initUserActivityRange);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

  const isMounted = useIsMounted();

  const {
    data: logTypes,
    run: runLogTypes,
    isLoading: isLoadingLogTypes,
  } = useFetch<BELogType[]>();

  const {
    data: users,
    run: runUsers,
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
  } = useFetch<User[]>();

  useEffect(() => {
    const params = prepareQueryParams("", {
      limit: "100",
    });
    runUsers(getUsers(params));
    runLogTypes(getLogTypes());
  }, []);

  useEffect(() => {
    isMounted() && queryParams && fetchUsersActivity();
  }, [queryParams, activeTypeId, dateRange, selectedUsers]);

  const fetchUsersActivity = useCallback(async () => {
    setIsLoading(true);
    const params: Record<string, string | number> = {
      [DATE_FROM_PARAM]: replaceMomentByFormatedString(moment(head(dateRange))),
      [DATE_TO_PARAM]: replaceMomentByFormatedString(moment(last(dateRange))),
    };
    if (activeTypeId) params[LOG_TYPE_ID_PARAM] = activeTypeId;
    const response = await getUserActivities(
      `${queryParams}&${objectGetParamsToString(params)}${
        selectedUsers.length > 0
          ? selectedUsers.map((item) => `&${USERS_ACTIVITY_ID_PARAM}=${item}`)
          : ""
      }`
    );
    isMounted() && handleServerResponse(response);
  }, [
    queryParams,
    activeTypeId,
    dateRange,
    selectedUsers,
    setIsLoading,
    setTotal,
    setIsLoading,
  ]);

  const openCreateCompanyModal = (): void =>
    setIsConfirmToDeleteModalOpen(true);

  const onConfirmToRemoveModalClose = (): void =>
    setIsConfirmToDeleteModalOpen(false);

  const handleServerResponse = async (res: Response): Promise<void> => {
    if (res.status !== HTTP_STATUS_CODES.OK) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const {
        data,
        meta: { total },
      } = await res.json();
      setTotal(total);
      setData(data);
      setIsLoading(false);
    }
  };

  const handleDeleteOldActivity = useCallback(async (): Promise<void> => {
    const response = await deleteLastUserActivities();
    if (response.status === HTTP_STATUS_CODES.OK) {
      await fetchUsersActivity();
      setIsConfirmToDeleteModalOpen(false);
    } else {
      const { errorMessage } = handleServerError(response);
      setDeleteOldActivitiesError(errorMessage);
    }
  }, [fetchUsersActivity]);

  const handleShowActivityModal = useCallback(
    (open: boolean, data: BEUserActivity | null) => {
      return setActivityModal({ data, open });
    },
    []
  );

  const renderRow = useCallback((row: BEUserActivity): ReactElement => {
    const {
      user: { first_name, last_name, email },
      created_at,
      type,
    } = row;
    return (
      <>
        <TableCell align="left">{`${first_name} ${last_name}`}</TableCell>
        <TableCell align="left">{email}</TableCell>
        <TableCell align="center">
          {formatTimestamp(created_at, ACTIVITY_DATE_AND_TIME_FORMAT)}
        </TableCell>
        <TableCell align="left">{type.name}</TableCell>
        <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
          <ShowIcon
            role={"showIconRole"}
            size={18}
            onClick={() => handleShowActivityModal(true, row)}
            data-testid="show-modal"
          />
        </TableCell>
      </>
    );
  }, []);

  if (isLoadingUsers || isLoadingLogTypes)
    return (
      <div data-testid="loader">
        <LoadingBox>
          <Loader />
        </LoadingBox>
      </div>
    );

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error && isErrorUsers}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={
          <UserActivityToolbar
            handleTableSearch={handleTableSearch}
            openCreateCompanyModal={openCreateCompanyModal}
            setActiveTypeId={setActiveTypeId}
            activeTypeId={activeTypeId}
            logTypes={logTypes!}
            dateRange={dateRange}
            setDateRange={setDateRange}
            users={users}
            setSelectedUsers={setSelectedUsers}
          />
        }
        noDataIsAvailablePlaceholder={"table.noActivitiesAreAvailable"}
      />
      <ShowModal
        data={showActivityModal.data}
        showActivityModal={showActivityModal.open}
        handleCloseActivityModal={() => handleShowActivityModal(false, null)}
      />
      <DeleteConfirmationModal
        isConfirmToRemoveModalOpen={isConfirmToDeleteModalOpen}
        handleConfirmToRemoveModalClose={onConfirmToRemoveModalClose}
        onRowRemove={handleDeleteOldActivity}
        confirmationText={t("deleteOldUserActivity")}
        deletingError={deleteOldActivitiesError}
      />
    </>
  );
};

export default UserActivity;
