import React, { ReactElement, useEffect, useState } from "react";
import { Formik } from "formik";
import Form from "../CreateAndEditInventoryItemForm";
import { FormWrapper } from "../styled";
import { Stack } from "@mui/material";
import {
  FormMessageErrorState,
  FormMessageInItState,
} from "../../../constants";
import FormAlert from "../../../ui/formAlert/FormAlert";
import useValidation from "../../../hooks/useValidation";
import * as Yup from "yup";
import { prepareInventoryItemBeforeForm } from "./utils";
import { FormMessageInterface } from "../../../types/form";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFetch";
import {
  InventoryItem,
  TypeOfInventoryItem,
} from "../../../types/be/companyOwnership";
import {
  getInventoryItemById,
  getTypesOfInventoryItems,
} from "../../../api/companyOwnership";
import Loader from "../../Loader";
import { ErrorBox } from "../../properties/objectDataSheet/tabs/generalInformation/styled";
import { handleEditInventoryItemFormSubmit } from "../addEmployeeDialog/utils";
import PageWrapper from "../../../ui/pageWrapper/PageWrapper";
import { route } from "../../../utils/url";
import { useTranslation } from "react-i18next";

const EditInventoryItem = (): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const {
    inventory_type_id: type_id,
    brand,
    serial_number: number,
    department,
  } = useValidation();

  const {
    data: typesOfInventoryItems,
    isError: isTypesOfInventoryItemsError,
    run: runTypesOfInventoryItems,
  } = useFetch<TypeOfInventoryItem[]>();

  const {
    data: inventoryItem,
    isError: isInventoryItemError,
    run: runInventoryItem,
  } = useFetch<InventoryItem>();

  useEffect(() => {
    getData();
  }, []);

  const getData = (): void => {
    runInventoryItem(getInventoryItemById(id!));
    runTypesOfInventoryItems(getTypesOfInventoryItems());
  };

  if (isInventoryItemError || isTypesOfInventoryItemsError) {
    return <ErrorBox formMessage={FormMessageErrorState} />;
  }

  const breadcrumbs = [
    {
      to: route("human-resources.company-ownership"),
      name: t("sidebar.companyOwnership"),
    },
  ];

  return (
    <PageWrapper title={t("edit")} breadcrumbs={breadcrumbs}>
      <>
        {inventoryItem && typesOfInventoryItems ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            <FormWrapper>
              {formMessage.text && (
                <FormAlert
                  formMessage={formMessage}
                  sx={{ marginBottom: "10px" }}
                />
              )}
              <Formik
                initialValues={prepareInventoryItemBeforeForm(inventoryItem)}
                validationSchema={Yup.object().shape({
                  type_id,
                  brand,
                  number,
                  department,
                })}
                onSubmit={handleEditInventoryItemFormSubmit(id!, {
                  setFormMessage,
                })}
              >
                {(props): ReactElement => (
                  <Form
                    {...props}
                    typesOfInventoryItems={typesOfInventoryItems}
                    edit={true}
                  />
                )}
              </Formik>
            </FormWrapper>
          </Stack>
        ) : (
          <Loader />
        )}
      </>
    </PageWrapper>
  );
};

export default EditInventoryItem;
