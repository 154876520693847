import styled from "styled-components/macro";
import { Grid } from "@mui/material";

export const PaidBy = styled.span`
  font-size: 10px;
  font-weight: bold;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PaidByContainer = styled(Grid)`
  height: 0;
  top: -4px;
  position: relative;
`;

export const PaidByItem = styled(Grid)`
  height: 0;
  position: relative;
  bottom: 2px;
`;
