/* istanbul ignore file */

import React, { ReactElement } from "react";
import { FloorPlanSubdivisionProps } from "./types";
import { CalculationTableHead, CalculationTableTitle } from "./styled";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import {
  THOUSAND_SEPARATOR,
  DECIMAL_SEPARATOR,
  Measurement_Units,
} from "../../../../../../constants";

const FloorPlanSubdivision = ({
  commercial_area,
  living_area,
  full_area,
}: FloorPlanSubdivisionProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <CalculationTableHead>
        <CalculationTableTitle>
          {t("property.tenants.calculationResult.commercialAndLivingArea")}
        </CalculationTableTitle>
      </CalculationTableHead>
      <Table size="small" aria-label="a dense table">
        <TableHead></TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left" size="small">
              {t("property.tenants.calculationResult.businessInSqm")}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                displayType={"text"}
                value={commercial_area}
              />{" "}
              {commercial_area !== null && Measurement_Units.SQUARE_METER}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" size="small">
              {t("property.tenants.calculationResult.livingInSqm")}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                displayType={"text"}
                value={living_area}
              />{" "}
              {living_area !== null && Measurement_Units.SQUARE_METER}
            </TableCell>
          </TableRow>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell align="left" size="small">
              {" "}
              {t("property.tenants.calculationResult.totalArea")}
            </TableCell>
            <TableCell align="right" size="small">
              <NumericFormat
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                displayType={"text"}
                value={full_area}
              />{" "}
              {full_area !== null && Measurement_Units.SQUARE_METER}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FloorPlanSubdivision;
