import { itemsListHeadCells, snackbarInitState } from "./utils";
import Table from "../../../ui/table/Table";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { IdPropType, User } from "../../../types/models";
import useTable from "../../../ui/table/useTable";
import { Button, Grid, TableCell } from "@mui/material";
import { NavLink } from "react-router-dom";
import { route } from "../../../utils/url";
import { Search as SearchIcon } from "react-feather";
import useIsMounted from "../../../hooks/useIsMounted";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  DeleteIcon,
  EditIcon,
  HistoryIcon,
  Input,
  Search,
  SearchIconWrapper,
} from "../../../ui/table/styled";
import { handleServerError } from "../../../utils/http";
import useAccessControl from "../../../hooks/useAccessControl";
import {
  deleteInventoryItem,
  getInventoryList,
  getTypesOfInventoryItems,
  updateInventoryItem,
} from "../../../api/companyOwnership";
import {
  InventoryItem,
  OwnershipType,
} from "../../../types/be/companyOwnership";
import { formatTimestamp } from "../../../utils/date";
import OwnershipTypeFilter from "./filters/ownershipFiter";
import { objectGetParamsToString } from "../../../utils/common";
import {
  EMPTY_DATA,
  OWNERSHIP_TYPE_ID_PARAM,
  PermissionsCreate,
  PermissionsDelete,
  PermissionsUpdate,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../constants";
import AddEmployeeDialog from "../addEmployeeDialog";
import CloseButton from "../../../ui/dialog/CloseButton";
import Snackbar from "../../../ui/Snackbar";
import { SnackbarMessage } from "../../../types/fe/companyOwnership";
import { HTTP_STATUS_CODES } from "../../../types/server";
import Loader from "../../Loader";
import { StyledButton } from "../../../ui/button/styled";

const InventoryList = (): ReactElement => {
  const [ownershipTypes, setOwnershipTypes] = useState<OwnershipType[]>([]);
  const [ownershipTypeId, setOwnershipTypeId] = useState<number>(0);
  const navigate = useNavigate();
  const { can } = useAccessControl();
  const { t } = useTranslation();
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleDeleteLastPageData,
  } = useTable<User>();

  const [id, setId] = useState<string | null>(null);
  const [snackbarData, setSnackbarData] =
    useState<SnackbarMessage>(snackbarInitState);
  const [isRemovingEmployee, setIsRemovingEmployee] = useState<string | null>(
    null
  );

  const handleCloseSnackbar = (): void => setSnackbarData(snackbarInitState);

  useEffect(() => {
    fetchOwnershipTypes();
  }, []);

  useEffect(() => {
    if (isMounted()) fetchInventoryList();
  }, [queryParams, ownershipTypes, ownershipTypeId]);

  const fetchInventoryList = useCallback(
    async (loading = true) => {
      setIsLoading(loading);
      let params = queryParams;
      if (ownershipTypeId) {
        params = `${queryParams}&${objectGetParamsToString({
          [OWNERSHIP_TYPE_ID_PARAM]: ownershipTypeId,
        })}`;
      }
      const res = await getInventoryList(params);
      if (!res.ok) {
        const { errorMessage } = handleServerError(res);
        setError(errorMessage);
      } else {
        const resJson = await res.json();
        setTotal(resJson.meta.total);
        setData(resJson.data);
        setIsLoading(false);
      }
    },
    [
      queryParams,
      ownershipTypeId,
      setIsLoading,
      setTotal,
      setData,
      setIsLoading,
    ]
  );

  const fetchOwnershipTypes = async (): Promise<void> => {
    setIsLoading(true);
    const res = await getTypesOfInventoryItems();
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const resJson = await res.json();
      setOwnershipTypes(resJson.data);
      setIsLoading(false);
    }
  };

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteInventoryItem(rowToDelete);
    handleDeleteLastPageData(fetchInventoryList);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteInventoryItem,
    fetchInventoryList,
    handleConfirmToRemoveModalClose,
  ]);

  const handleEditClick = useCallback(
    ({ id }: IdPropType): void => navigate(`${id}/edit`),
    []
  );
  const handleHistoryClick = useCallback(
    ({ id }: IdPropType): void => navigate(`${id}/history`),
    []
  );

  const afterAddEmployee = useCallback(() => {
    fetchInventoryList();
    setId(null);
  }, [fetchInventoryList]);

  const handleRemoveEmployee = useCallback(
    async (id: string): Promise<void> => {
      setIsRemovingEmployee(id);
      const response = await updateInventoryItem(id, { user_id: "" });
      if (response.status === HTTP_STATUS_CODES.OK) {
        await fetchInventoryList(false);
        setSnackbarData({
          visible: true,
          text: t("companyOwnership.employeeRemovedSuccessfully"),
          color: "success",
        });
      } else {
        setSnackbarData({
          visible: true,
          text: t(SOMETHING_WENT_WRONG_ERROR),
          color: "error",
        });
      }
      setIsRemovingEmployee(null);
    },
    [updateInventoryItem, fetchInventoryList]
  );

  const renderRow = (row: InventoryItem): ReactElement => {
    return (
      <>
        <TableCell>
          {row.user ? (
            <Grid
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <span>{`${row.user.first_name} ${row.user.last_name}`} </span>
              {isRemovingEmployee === String(row?.id) ? (
                <Loader size={15} />
              ) : can(PermissionsUpdate.COMPANY_OWNERSHIP) ? (
                <CloseButton
                  onClick={() => handleRemoveEmployee(String(row?.id))}
                />
              ) : (
                <></>
              )}
            </Grid>
          ) : can(PermissionsUpdate.COMPANY_OWNERSHIP) ? (
            <Button
              color="primary"
              size="small"
              variant="outlined"
              onClick={() => setId(String(row?.id))}
            >
              {t("companyOwnership.addEmployee")}
            </Button>
          ) : (
            <>{EMPTY_DATA}</>
          )}
        </TableCell>
        <TableCell align="left">{row.type.name}</TableCell>
        <TableCell align="left">{row.brand}</TableCell>
        <TableCell align="right">{row.number}</TableCell>
        <TableCell align="center">
          {row.user ? formatTimestamp(row.received_date) : "---"}
        </TableCell>
        <TableCell align="left">
          {row.creator.first_name} {row.creator.last_name}
        </TableCell>
        <TableCell align="center">{formatTimestamp(row.created_at)}</TableCell>
        <TableCell align="left">
          {row.department ? row.department : "---"}
        </TableCell>
        <TableCell
          align="right"
          onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
            handleCellClick(e)
          }
          style={{ whiteSpace: "nowrap" }}
        >
          {can(PermissionsUpdate.COMPANY_OWNERSHIP) && (
            <EditIcon
              role={"editIconRole"}
              onClick={(): void => handleEditClick(row)}
              size={20}
            />
          )}
          <HistoryIcon
            role={"historyIconRole"}
            onClick={(): void => handleHistoryClick(row)}
          />
          {can(PermissionsDelete.COMPANY_OWNERSHIP) && (
            <DeleteIcon
              role={"deleteIconRole"}
              onClick={(): void => openConfirmToRemoveModal(row)}
              size={22}
              data-testid="delete-icon"
            />
          )}
        </TableCell>
      </>
    );
  };

  const TableToolbar = (
    <Grid container sx={{ pt: 5 }}>
      <Grid
        item
        xs={5}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
      >
        {can(PermissionsCreate.COMPANY_OWNERSHIP) && (
          <Grid minWidth={200}>
            <StyledButton
              data-testid={"dataItems-link"}
              component={NavLink}
              to={route(`company-ownership.create`)}
              size="small"
              color="success"
              variant="contained"
            >
              {t("companyOwnership.createCompanyOwnership")}
            </StyledButton>
          </Grid>
        )}
        {ownershipTypes && (
          <Grid>
            <OwnershipTypeFilter
              ownershipTypes={ownershipTypes}
              setOwnershipTypeId={setOwnershipTypeId}
              ownershipTypeId={ownershipTypeId}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs />
      <Grid item>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Input placeholder={t("search")} onChange={handleTableSearch} />
        </Search>
      </Grid>
    </Grid>
  );

  const isMounted = useIsMounted();

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onRowRemove={handleRowRemove}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
        noDataIsAvailablePlaceholder={"table.noCompanyOwnershipsAreAvailable"}
      />
      {id ? (
        <AddEmployeeDialog
          id={id}
          onClose={() => setId(null)}
          open={true}
          afterAddEmployee={afterAddEmployee}
        />
      ) : null}
      <Snackbar
        message={snackbarData.text}
        color={snackbarData.color}
        open={snackbarData.visible}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

export default InventoryList;
