import React, { ReactElement, useCallback, useEffect } from "react";
import { itemsListHeadCells } from "./utils";
import Table from "../../../../ui/table/Table";
import { SearchProfilesTableProps } from "./types";
import {
  deleteSearchProfile,
  getSearchProfilesByContactId,
} from "../../../../api/searchProfile";
import { SearchProfile } from "../../../../types/models";
import useTable from "../../../../ui/table/useTable";
import { TableCell as MUITableCell } from "@mui/material";
import {
  DeleteIcon,
  EditIcon,
  Input,
  Search,
  SearchIconWrapper,
} from "../../../../ui/table/styled";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import useIsMounted from "../../../../hooks/useIsMounted";
import { handleServerError } from "../../../../utils/http";
import moment from "moment";
import { StatusWrapper } from "./styled";
import { NumericFormat } from "react-number-format";
import { THOUSAND_SEPARATOR, DECIMAL_SEPARATOR } from "../../../../constants";
import { TextWithTooltip } from "../../../../ui/table/TextWithTooltip";

const SearchProfilesTable = ({
  id,
  handleEditClick,
  searchProfileUpdated,
  setSearchProfileUpdated,
  setIsSearchProfileData,
}: SearchProfilesTableProps): ReactElement => {
  const { t } = useTranslation();
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    handleTableSearch,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    setError,
    handleDeleteLastPageData,
  } = useTable<SearchProfile>();

  useEffect(() => {
    if (isMounted()) fetchData();
  }, [queryParams]);

  useEffect(() => {
    if (isMounted() && searchProfileUpdated) fetchData();
  }, [searchProfileUpdated]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const response = await getSearchProfilesByContactId(id!, queryParams);
    if (response.status !== 200) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const resJson = await response.json();
      setTotal(resJson.meta.total);
      setData(resJson.data);
      setIsSearchProfileData(Boolean(resJson.data.length));
      setIsLoading(false);
    }
    setSearchProfileUpdated(false);
  }, [queryParams, setTotal, setData, setIsLoading]);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteSearchProfile(rowToDelete.id);
    handleDeleteLastPageData(fetchData);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteSearchProfile,
    fetchData,
    handleConfirmToRemoveModalClose,
  ]);

  const TableToolbar = (
    <Grid container sx={{ pt: 5 }} justifyContent={"end"}>
      <Grid item>
        <Search>
          <SearchIconWrapper>
            <SearchIconWrapper />
          </SearchIconWrapper>
          <Input placeholder={t("search")} onChange={handleTableSearch} />
        </Search>
      </Grid>
    </Grid>
  );
  const isMounted = useIsMounted();
  const renderRow = (row: SearchProfile): ReactElement => (
    <>
      <MUITableCell align="left">
        <TextWithTooltip value={row.title}></TextWithTooltip>
      </MUITableCell>
      <MUITableCell align="left">
        <TextWithTooltip value={row.type?.name}></TextWithTooltip>
      </MUITableCell>
      <MUITableCell align="left">
        <TextWithTooltip value={row.property_type?.title}></TextWithTooltip>
      </MUITableCell>
      <MUITableCell align="left">
        <TextWithTooltip value={row.usage?.name}></TextWithTooltip>
      </MUITableCell>
      <MUITableCell align="left">
        <TextWithTooltip value={row.source}></TextWithTooltip>
      </MUITableCell>
      <MUITableCell align="left">
        <TextWithTooltip value={row.zip_code}></TextWithTooltip>
      </MUITableCell>
      <MUITableCell align="left">
        <TextWithTooltip value={row.floors}></TextWithTooltip>
      </MUITableCell>
      <MUITableCell align="left">
        <TextWithTooltip value={row.renovation}></TextWithTooltip>
      </MUITableCell>
      <MUITableCell align="left">
        <TextWithTooltip value={row.ranking?.text}></TextWithTooltip>
      </MUITableCell>
      <MUITableCell align="left">
        {<StatusWrapper type={row.expansion ? "success" : "error"} />}
      </MUITableCell>
      <MUITableCell align="left">
        {
          <StatusWrapper
            type={row.is_holiday_destination ? "success" : "error"}
          />
        }
      </MUITableCell>
      <MUITableCell align="left">
        {<StatusWrapper type={row.main_road ? "success" : "error"} />}
      </MUITableCell>
      <MUITableCell align="left">
        {row.bnl !== null && moment.unix(row.bnl).format("DD/MM/yyyy")}
      </MUITableCell>

      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.plot_area_from}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.plot_area_to}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.sale_area_from}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.sale_area_to}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.rentable_area_from}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.rentable_area_to}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.office_area_from}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.office_area_to}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.practice_area_from}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.practice_area_to}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.commercial_area_from}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.commercial_area_to}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.living_area_from}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.living_area_to}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.other_area_from}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.other_area_to}
        />
      </MUITableCell>
      <MUITableCell align="right">{row.construction_year}</MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.inhabitants_from}
        />
      </MUITableCell>
      <MUITableCell align="right">
        <NumericFormat
          thousandSeparator={THOUSAND_SEPARATOR}
          decimalSeparator={DECIMAL_SEPARATOR}
          displayType={"text"}
          value={row.inhabitants_to}
        />
      </MUITableCell>

      <MUITableCell align="left">
        <TextWithTooltip value={row.notes}></TextWithTooltip>
      </MUITableCell>
      <MUITableCell
        padding="none"
        align="right"
        sx={{ minWidth: 60 }}
        onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
          handleCellClick(e)
        }
      >
        <EditIcon
          role={"editIconRole"}
          onClick={(): void => handleEditClick(row.id)}
          size={18}
        />
        <DeleteIcon
          onClick={(): void => openConfirmToRemoveModal(row)}
          size={20}
          data-testid="delete-icon"
        />
      </MUITableCell>
    </>
  );
  return (
    <Table
      data={data}
      total={total}
      currentPage={currentPage}
      order={order}
      orderBy={orderBy}
      error={error}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={handleChangePage}
      onRowRemove={handleRowRemove}
      onSortChange={handleSortChange}
      onSelectAllClick={handleSelectAllClick}
      onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
      rowsPerPage={rowsPerPage}
      isLoading={isLoading}
      isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
      listHeadCells={itemsListHeadCells}
      renderRow={renderRow}
      tableToolbar={TableToolbar}
    />
  );
};

export default SearchProfilesTable;
