import React, { ReactElement, useRef, useState } from "react";
import { Formik } from "formik";
import {
  EditableSection,
  EditFieldContainer,
  EditSectionButton,
  LabelContainer,
  SaveSectionButton,
} from "./styled";
import { EDITABLE_FIELDS_TYPE } from "../../components/properties/edit/general/utils";
import useOutsideClick from "../../hooks/useOutsideClick";
import DoneIcon from "@mui/icons-material/Done";
import InputField from "./InputFields";
import useAccessControl from "../../hooks/useAccessControl";

/* istanbul ignore next */
type EditableFieldFieldProps = {
  label: JSX.Element;
  initialValues: Record<string, unknown>;
  onSave: (key: string, value: string) => Promise<void>;
  isDisabled?: boolean;
  type: EDITABLE_FIELDS_TYPE;
  name: string;
  permission?: string | string[];
};

/* istanbul ignore next */
export const TableEditableText = ({
  label,
  initialValues,
  onSave,
  isDisabled = false,
  type,
  name,
  permission,
}: EditableFieldFieldProps): ReactElement => {
  const RenderLabel = (): JSX.Element => label;
  const elRef = useRef<HTMLDivElement | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { can } = useAccessControl();
  useOutsideClick(elRef, () => {
    !isOpen && setIsEdit(false);
  });

  const saveClicked = async (
    values: Record<string, unknown>
  ): Promise<void> => {
    const newValue = values[name];
    if (newValue && String(newValue) !== String(initialValues[name])) {
      setIsLoading(true);
      if (typeof newValue === "string") {
        await onSave(name, newValue);
      }
      setIsLoading(false);
    }
    setIsEdit(false);
  };

  if (isDisabled) {
    return <RenderLabel />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={saveClicked}
      enableReinitialize
    >
      {({ handleSubmit, ...rest }) => (
        <form noValidate onSubmit={handleSubmit}>
          <EditableSection ref={elRef}>
            {!isEdit ? (
              <LabelContainer>
                <RenderLabel />
                {permission && can(permission) && (
                  <EditSectionButton
                    onClick={() => {
                      setIsEdit(!isEdit);
                      rest.setFieldValue(name, initialValues[name]);
                    }}
                  />
                )}
              </LabelContainer>
            ) : (
              <EditFieldContainer>
                <InputField
                  {...rest}
                  handleSubmit={handleSubmit}
                  label={label}
                  isDisabled={isDisabled}
                  type={type}
                  name={name}
                  setIsopen={setIsopen}
                />
                <SaveSectionButton
                  title={""}
                  type="submit"
                  startIcon={!isLoading ? <DoneIcon></DoneIcon> : <></>}
                  color="success"
                  disabled={isLoading}
                  isLoading={isLoading}
                  variant="contained"
                  size="small"
                />
              </EditFieldContainer>
            )}
          </EditableSection>
        </form>
      )}
    </Formik>
  );
};

export default Text;
