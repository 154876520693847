import { Paper as MuiPaper } from "@mui/material";
import { blue } from "@mui/material/colors";
import styled from "styled-components/macro";

export const BreadcrumbsLinkText = styled.div`
  width: 225px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;
/* istanbul ignore next */
export const DataBox = styled(MuiPaper)`
  width: 100%;
  display: inline-flex;
  color: ${(props): string => props.theme.breadcrumb.color};
  font-size: 15px;
  font-weight: bold;
  align-items: center;
  padding: ${(props): string => props.theme.spacing(3.75, 0)};
  border-radius: ${(props): string => props.theme.spacing(1)};
  background-color: transparent;
  border: 2px solid ${blue[700]};

  div {
    height: 100%;
    padding: ${(props): string => props.theme.spacing(0, 5.5, 0, 3)};
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: calc(50% - 5px);
      right: 0;
      height: 10px;
      width: 10px;
      border-radius: 100%;
      background: ${blue[700]};
    }
  }
  div:last-child {
    &:after {
      content: none;
    }
  }
`;
