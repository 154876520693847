// Themes
import { Status } from "./types/be/status";
import { FormMessageInterface } from "./types/form";

export enum NODE_ENVIRONMENTS {
  PRODUCTION = "production",
  TEST = "test",
  DEVELOPMENT = "development",
}

export enum APP_ENVIRONMENTS {
  LOCAL = "local",
  DEVELOPMENT = "development",
  STAGING = "staging",
  PROD = "production",
}

export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export enum BE_THEMES {
  LIGHT = "light",
  DARK = "dark",
}

export const crimsonColor = "rgba(222, 5, 0, 0.37)";
export const DATE_FORMAT = "DD/MM/YYYY";
export const PASSWORD_MIN_CHARACTERS = 8;
export const PASSWORD_MAX_CHARACTERS = 255;
export const FIRST_NAME_MAX_CHARACTERS = 20;
export const FIRST_NAME_MIN_CHARACTERS = 2;
export const LAST_NAME_MAX_CHARACTERS = 20;
export const LAST_NAME_MIN_CHARACTERS = 2;
export const MOBILE_MIN_CHARACTERS = 4;
export const MOBILE_MAX_CHARACTERS = 15;
export const PHONE_MAX_CHARACTERS = 13;
export const SEARCH_PARAM_MIN_CHARACTERS = 2;
export const GENERAL_SEARCH_PARAM_MIN_CHARACTERS = 4;
export const NOTES_MAX_CHARACTERS = 255;
export const OBJECT_NAME_MIN_CHARACTERS = 2;
export const OBJECT_NAME_MAX_CHARACTERS = 255;
export const PURCHASE_PRICE_MIN_CHARACTERS = 1;
export const PURCHASE_PRICE_MAX_CHARACTERS = 255;
export const FILE_NAME_MIN_CHARACTERS = 2;
export const TENANT_USERS_FILTER = "&property_area_user_id[]=";
export const PROPERTY_ID_FILTER = "&property_id=";
export const COMPANY_FILTER = "&property_category_id=2";
export const DESCRIPTION_MAX_CHARACTERS = 255;
export const MAX_PROPERTY_IMAGES_LIMIT = 10;
export const PERMISSION_CODE = "permission_code[]";
export const MAX_PROPERTY_VIDEO_LIMIT = 10;
export const MAX_VIDEO_FILE_SIZE = 100;
export const MAX_PDF_FILE_SIZE = 100;
export const CAROUSEL_MAX_FILE_LIMIT = 20;
export const IMAGE_EXTENSION = ["gif", "png", "bmp", "jpeg", "jpg", "webp"];

export const FormMessageInItState: FormMessageInterface = {
  type: undefined,
  text: "",
};

export const COMPANY_NAME = "immowin24";

export const ADMINISTRATIVE_EXPENSES = [
  0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6,
];

//TODO: remove and don't use, ROLE_IDS instead
export const COMPANY_TYPE_ID_PROPERTY_MANAGEMENT = "2";

// export enum ROLE_IDS {
//   ADMIN = "1",
//   PROPERTY_MANAGEMENT = "2",
//   ASSET_MANAGER = "3",
//   CARETAKER = "5",
//   TENANT = "13646",
// }

export const SOMETHING_WENT_WRONG_ERROR = "errorSomethingWentWrong";
export const FORMTYPE_SURFACE = "Surface";
export const FormMessageErrorState: FormMessageInterface = {
  type: "error",
  text: SOMETHING_WENT_WRONG_ERROR,
};
export const ONLY_ONE_FUTURE_TENANT = "onlyOneFutureTenant";
export const RESET_PASSWORD_EMAIL_WAS_SEND = "resetPasswordEmailWasSend";

export const COMPANY_GENERAL_TYPE_ID = "1";
export const COMPANY_PROPERTY_MANAGEMENT_TYPE_ID = "2";

export const NO_DATA_IS_AVAILABLE = "table.noDataIsAvailable";

export const STATUS_TEXT_500 = "Internal Server Error";

export const ZIP_CODE_LENGTH = 5;
export const YEAR_LENGTH = 4;
export const CURRENT_YEAR = new Date().getFullYear();
export const MINIMUM_YEAR = 1700;

export const IBAN_CHARACTERS_NUMBER = 22;
export const INVOICE_MONTHLY_NUMBER = 28;

export const TENANT_TYPE_ID_PARAM = "tenant_type_id[]";
export const AREA_TYPE_ID_PARAM = "type_id";
export const BANK_ACCOUNT_ID_PARAM = "bank_account_id[]";
export const LOG_TYPE_ID_PARAM = "type_id";
export const DATE_FROM_PARAM = "date_from";
export const DATE_TO_PARAM = "date_to";
export const USERS_ACTIVITY_ID_PARAM = "users[]";
export const TENANT_ID_PARAM = "tenant_id[]";
export const PROPERTY_ID_PARAM = "property_id";
export const PERMISSION_CODE_PARAM = "permission_code[]";
export const IS_RENTED_PARAM = "is_rented";
export const YEARS_PARAM = "year[]";
export const FR_STATUS_ID_PARAM = "fr_status_id";
export const EXCLUDE_ID_PARAM = "exclude_id[]";
export const IS_ACTIVE_PARAM = "is_active";
export const ROLE_CODE_PARAM = "role_code[]";

export enum UserRoles {
  ADMIN = "admin",
  ASSET_MANAGER = "asset_manager",
  PROPERTY_MANAGER = "property_manager",
  SERVICE_PROVIDER = "service_provider",
  CARETAKER = "caretaker",
  TAX_CONSULTANT = "tax_consultant",
  FINAL_RELEASER = "final_releaser",
  OTHER = "other",
  TENANT = "tenant",
  TRANSACTION_MANAGER = "transaction_manager",
}

export enum AreaTypeCodes {
  COMMERCIAL = "commercial",
  LIVING = "living",
}

export enum TenantTypes {
  COMMERCIAL_RENTED = "commercial_rented",
  LIVING_RENTED = "living_rented",
  COMMERCIAL_NOT_RENTED = "commercial_not_rented",
  LIVING_NOT_RENTED = "living_not_rented",
}

export enum ContactTypes {
  TENANT = "tenant",
  SERVICE_PROVIDER = "property_management",
  PROPERTY_MANAGEMENT = "property_management",
  BANK = "bank",
  CARETAKER = "caretaker",
  PRIVATE = "private",
  OTHER = "other",
}

export enum UserPermissions {
  PROPERTY_RELEASE = "property.release",
  USER_LIST = "user.list",
  NEWS_EDIT = "news.edit",
  USER_SCHEDULE_HIDE_EMPLOYEE = "user-schedule.hide-employee",
  USER_SCHEDULE_ACCESS = "user-schedule.access",
  PROPERTY_ALL = "property.all",
  PROPERTY_ASSET_MANAGER = "property.asset_manager",
}

export enum FileTreeEnum {
  FOLDER = "FOLDER",
  FILE = "FILE",
  SEPARATOR = "-",
}

export enum Measurement_Units {
  EURO = "€",
  DOLLAR = "$",
  SQUARE_METER = "m²",
  PERCENTAGE = "%",
}

export enum MIME_TYPES {
  PDF = "application/pdf",
}

export enum FOLDER_NAMES {
  INVOICE = "Rechnungen",
  CONTRACT = "Verträge",
  OFFER = "Angebote",
}

export const TIME_FORMAT = "DD.MM.yyyy";
export const FINAL_RELEASER_STATUS_ID = 3;

export const TOPICS_PER_PAGE = 5;
export const STARTING_PAGE = 0;

export const OFFER_UPLOAD_FOLDER = "Angebote";
export const OFFER_TOPIC_STATUS = 1;
export const DEFAULT_FILE_TYPES = "image/jpg,image/png,image/jpeg";

export const FORMAT_NUMBER_DE = "formatNum";
export const THOUSAND_SEPARATOR = ".";
export const DECIMAL_SEPARATOR = ",";
export const DECIMAL_SCALE = 2;

export const SALES_MIN_CHARACTERS = "1";
export const SALES_MAX_CHARACTERS = "2";

export const INPUT_MIN_HEIGHT = "78px";
export const INPUT_MIN_WIDTH = "100px";
export const FORBIDDEN_CHARACTERS_IN_NUMBERS = ["e", "E", "-"];
export const COMPUTED_HEIGHT_RATIO = 1.42;

export enum COMMENTABLE {
  PROPERTY = "property",
  OFFER = "offer",
  INVOICE = "invoice",
  CONTRACT = "contract",
  AREA = "area",
  INSURANCE = "insurance",
  TENANT_REQUEST = "tenant_request",
  INBOX = "inbox",
  ATTACHMENT = "attachment",
  REMINDER = "reminder",
  MAINTENANCE = "maintenance",
  PROPERTY_TENANT = "property_tenant",
  BKA = "bka",
  OUTGOING_INVOICES = "outgoing_invoice",
  GENERAL_DOCUMENT = "general_document",
}

export enum INVOICE_STATUSES {
  NEW = "new",
  PENDING = "pending",
  FINAL_RELEASED = "final_released",
  REJECTED = "rejected",
}

export const HOVER_DELAY = 1000;
export const MAX_NOTIFICATIONS_LIMIT = 30;

export const DATEPICKER_DATE_FORMAT = "dd/MM/yyyy";

export const MAX_FILES_UPLOAD = 1;

export enum EMPLOYEE_OPTION {
  HOME_OFFICE = "home_office",
  VACATION = "vacation",
  OUT_OF_OFFICE = "out_of_office",
  FIELD_SERVICE = "field_service",
  PUBLIC_HOLIDAY = "public_holiday",
}

export enum REQUEST_STATUS_IDS {
  PENDING = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
  NEW = 1,
}

export const PROPOSALS_TABS_ID = 1;

export const FORM_SELECT_OPTION_ID = -1;

export enum NOTIFICATIONS {
  CREATED = "notification.created",
  ARCHIVE_FOLDER = "notification.archive-folder",
  IMPORT_VALIDATION = "notification.import-validation",
  CHAT_MESSAGE_CREATED = "notification.chat-message-created",
}

export const PERCENTAGE_MAX_VALUE = 100;
export const PERCENTAGE_MIN_VALUE = 0;
export const XLSX_FILE_TYPE = "xlsx";
export const CSV_FILE_TYPE = "csv";
export const VIDEO_FILE_TYPE = "mp4";
export const IMAGE = "image";
export const MAX_RELEASE_INPUT = 15;
export const ALL_FILE_TYPE = "*";
export const MIN_REPEAT_DAY = 1;
export const MAX_REPEAT_DAY = 28;

export const XLSX_MIME_TYPE =
  "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const EMPTY_DATA = "---";

export const CURRENCIES = ["EUR", "USD"];

export const OWNERSHIP_TYPE_ID_PARAM = "type_id";

export const PFDJS_WORKER_BASE = "//cdnjs.cloudflare.com/ajax/libs/pdf.js";
export const PFDJS_WORKER_BASE_FILE = "pdf.worker.js";
export const PDF_ZOOM_RATION = 0.1;

export const INVOICE_PERIOD_TYPES = [
  { label: "documentRelease.invoice.monthly", value: 1 },
  { label: "documentRelease.invoice.twoMonths", value: 2 },
  { label: "documentRelease.invoice.quarterly", value: 3 },
  { label: "documentRelease.invoice.halfYearly", value: 6 },
  { label: "documentRelease.invoice.annual", value: 12 },
];
export const VACATION_REQUEST_STATUS_ID_PARAM = "vacation_request_status_id";

export enum RELEASE_STATUS {
  NEW = "new",
  PENDING = "pending",
  FINAL_RELEASED = "final_released",
  REJECTED = "rejected",
  FORWARD_TO_ADMIN = "forward_to_admin",
  FORWARDED = "forwaded",
}

export enum PAGE_LIMIT {
  _10 = 10,
  _20 = 20,
  _30 = 30,
  _50 = 50,
  _1000 = 1000,
}

export enum LANGUAGE {
  EN = "en",
  DE = "de",
}

export const FORWADED_STATUS: Status = {
  id: -1,
  code: "forwarded",
  name: "Forwarded",
};

export const FORWARDED_ROUTE = "forwarded";

export const HISTORY_STATUS: Status = {
  id: -2,
  code: "history",
  name: "History",
};
export const OLD_CONTRACT: Status = {
  id: -3,
  code: "old-contracts",
  name: "Old contracts",
};

export const NEW_STATUS_ROUTE = "new";

export enum PermissionsRead {
  INVOICE = "invoice.read",
  CONFIDENTIAL_INVOICE = "confidential_invoice.read",
  OFFER = "offer.read",
  CONTRACT = "contract.read",
  CONTACT = "contact.read",
  INSURANCE = "insurance.read",
  BANK_ACCOUNT = "bank_account.read",
  USER_MANAGEMENT = "user_management.read",
  METER = "meter.read",
  SERVICE_REQUEST = "service_request.read",
  EMPLOYEE_OVERVIEW = "employee_overview.read",
  VACATION_REQUEST = "vacation_request.read",
  INBOX = "inbox.read",
  FILE_MANAGEMENT = "file_management.read",
  PROPERTY = "property.read",
  TENANT = "tenant.read",
  AREA = "area.read",
  TENANT_REQUEST = "tenant_request.read",
  LOI = "loi.read",
  SETTINGS_ROLE = "role.read",
  SETTINGS_RELEASE = "release.read",
  DASHBOARD = "dashboard.read",
  MAINTENANCE = "maintenance.read",
  COMPANY_OWNERSHIP = "company_ownership.read",
  GENERAL_DOCUMENT = "general_document.read",
  DATEV_INVOICE = "datev_invoice.read",
  OUTGOING_INVOICE = "outgoing_invoice.read",
}

export enum PermissionsCreate {
  USER_MANAGEMENT = "user_management.create",
  PROPERTY = "property.create",
  INVOICE = "invoice.create",
  OFFER = "offer.create",
  CONTRACT = "contract.create",
  INSURANCE = "insurance.create",
  CONTACT = "contact.create",
  METER = "meter.create",
  INBOX = "inbox.create",
  LOI = "loi.create",
  MAINTENANCE = "maintenance.create",
  COMPANY_OWNERSHIP = "company_ownership.create",
  VACATION_REQUEST = "vacation_request.create",
  SETTINGS_RELEASE = "release.create",
  TENANT_REQUEST = "tenant_request.create",
  EMPLOYEE_OVERVIEW = "employee_overview.create",
  AREA = "area.create",
  TENANT = "tenant.create",
  SETTINGS_ROLE = "role.create",
  CREDITOR = "api_datev_creditor.create",
  GENERAL_DOCUMENT = "general_document.create",
  DATEV_INVOICE = "datev_invoice.create",
  OUTGOING_INVOICE = "outgoing_invoice.create",
}

export enum PermissionsUpdate {
  USER_MANAGEMENT = "user_management.update",
  PROPERTY = "property.update",
  INVOICE = "invoice.update",
  OFFER = "offer.update",
  CONTRACT = "contract.update",
  INSURANCE = "insurance.update",
  CONTACT = "contact.update",
  INBOX = "inbox.update",
  LOI = "loi.update",
  MAINTENANCE = "maintenance.update",
  COMPANY_OWNERSHIP = "company_ownership.update",
  SETTINGS_ROLE = "role.update",
  VACATION_REQUEST = "vacation_request.update",
  SETTINGS_RELEASE = "release.update",
  TENANT_REQUEST = "tenant_request.update",
  EMPLOYEE_OVERVIEW = "employee_overview.update",
  FILE_MANAGEMENT = "file_management.update",
  AREA = "area.update",
  TENANT = "tenant.update",
  CREDITOR = "api_datev_creditor.update",
  GENERAL_DOCUMENT = "general_document.update",
  DATEV_INVOICE = "datev_invoice.update",
  OUTGOING_INVOICE = "outgoing_invoice.update",
}

export enum PermissionsDelete {
  USER_MANAGEMENT = "user_management.delete",
  PROPERTY = "property.delete",
  INVOICE = "invoice.delete",
  OFFER = "offer.delete",
  CONTRACT = "contract.delete",
  INSURANCE = "insurance.delete",
  CONTACT = "contact.delete",
  INBOX = "inbox.delete",
  LOI = "loi.delete",
  MAINTENANCE = "maintenance.delete",
  COMPANY_OWNERSHIP = "company_ownership.delete",
  VACATION_REQUEST = "vacation_request.delete",
  TENANT_REQUEST = "tenant_request.delete",
  FILE_MANAGEMENT = "file_management.delete",
  AREA = "area.delete",
  TENANT = "tenant.delete",
  CREDITOR = "api_datev_creditor.delete",
  GENERAL_DOCUMENT = "general_document.delete",
  DATEV_INVOICE = "datev_invoice.delete",
  OUTGOING_INVOICE = "outgoing_invoice.delete",
}

export enum REMINDER_STATUS {
  NEW = "new",
  COMPLETE = "complete",
}
export const DATE_TYPE = "DATE";

export const MIN_YEAR_1850 = "1850, 01, 01";

export const MAX_PRELOADED_OPTIONS = 10;

export const VERSION_CONTROL_KEY = "control-ui-version";

export const MIN_TABLE_WIDTH = 750;
export const HALF_TABLE_MIN_WIDTH = 500;

export const oneDayInUnix = 86400;

export const STRING_LENGTH = 10;

export enum SUBMIT_STATUS {
  PREVIEW = "preview",
  CREATE_OR_EDIT = "createOrEdit",
}

export const VAT_INCL_OPTIONS = [0, 5, 7, 16, 19];

export enum TENANT_STATUS {
  IS_FUTURE = "is_future",
  IS_CURRENT = "is_current",
  IS_PAST = "is_past",
}

export const MONTHS = Object.freeze([
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
]);

export const FORM_SELECT_ALL_OPTION_ID = -1;
