import { FormikValues } from "formik";
import { Grid } from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../ui/button/Button";
import Text from "../../../../../../ui/formsFields/text";
import { route } from "../../../../../../utils/url";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router";

const NewDebitAccountForm = ({
  values,
  handleSubmit,
  isSubmitting,
  status,
}: FormikValues): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Text
            name="konto"
            label={t("documentRelease.sollkonto.account")}
            placeholder={t("documentRelease.sollkonto.account")}
            value={values?.konto}
            status={status}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Text
            name="account_caption"
            label={t("documentRelease.sollkonto.accountCaption")}
            value={values?.account_caption}
            status={status}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Text
            name="language_id"
            label={t("documentRelease.sollkonto.languageId")}
            value={values?.language_id}
            status={status}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Text
            name="account_label_long"
            label={t("documentRelease.sollkonto.accountLabelLong")}
            value={values?.account_label_long}
            status={status}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mt={3}
      >
        <Grid item>
          <Button
            type="button"
            color="primary"
            variant="text"
            sx={{ mt: "1rem" }}
            title={t("cancel")}
            disabled={isSubmitting}
            size="large"
            onClick={
              /* istanbul ignore next */ () =>
                doesAnyHistoryEntryExist
                  ? navigate(-1)
                  : navigate(
                      route("properties.invoices.apidatev.debitaccount", id)
                    )
            }
          />
        </Grid>
        <Grid item>
          <Button
            title={t("create")}
            type="submit"
            color="success"
            size="large"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default NewDebitAccountForm;
