import { useCallback, useState } from "react";
import { AutocompleteOption } from "../ui/dropdown/types";
import { FILTER_TYPE } from "../utils/common";

interface propertySelectProps {
  property: AutocompleteOption | null;
  propertyId: string | undefined;
  isGeneral: string;
  onPropertySelect: (
    id?: string,
    event?: AutocompleteOption | null
  ) => Promise<void>;
}
/* istanbul ignore next */
export const usePropertySelect = (
  id?: string,
  onSuccess?: () => void
): propertySelectProps => {
  const [propertyId, setPropertyId] = useState(id);
  const [property, setProperty] = useState<AutocompleteOption | null>(null);
  const [isGeneral, setIsGeneral] = useState<string>(FILTER_TYPE.ALL);

  const onPropertySelect = useCallback(
    async (id?: string, event?: AutocompleteOption | null): Promise<void> => {
      if (event?.object_name === FILTER_TYPE.GENERAL) {
        setProperty(null);
        setPropertyId("");
        setIsGeneral(FILTER_TYPE.GENERAL);
      } else {
        setProperty(event || null);
        setPropertyId(id?.toString() || "");
        setIsGeneral(FILTER_TYPE.ALL);
      }
      onSuccess?.();
    },
    []
  );

  return {
    property,
    propertyId,
    isGeneral,
    onPropertySelect,
  };
};
