import React, { ReactElement, useCallback } from "react";
import { Grid } from "@mui/material";

import kebabCase from "lodash/kebabCase";
import camelCase from "lodash/camelCase";
import { useNavigate, useParams, useResolvedPath } from "react-router-dom";
import {
  Status as ContractStatus,
  Status as OfferStatus,
} from "../../../../types/be/status";
import { Status as InvoiceStatus } from "../../../../types/be/status";
import { useTranslation } from "react-i18next";
import { getReleaseRoute } from "../../utils";
import { TabButton } from "./styled";

function StatusButtons<T extends InvoiceStatus | ContractStatus | OfferStatus>({
  subPath,
  statuses,
  handleStatusChange,
  navigateOnChange = true,
  activeStatusId,
  useSpecialLabels,
  tabPosition = "center",
  defaultStatus = "new",
}: {
  subPath: string;
  statuses: T[];
  handleStatusChange: (id: number, code: string) => void;
  navigateOnChange?: boolean;
  activeStatusId?: number | null;
  useSpecialLabels?: boolean;
  tabPosition?: string;
  defaultStatus?: string;
}): ReactElement {
  const { id } = useParams();
  const navigate = useNavigate();
  const resolved = useResolvedPath("");
  const { t } = useTranslation();

  const handleTabClick = useCallback(
    (status: T) => {
      handleStatusChange(status.id, status.code);
      navigateOnChange &&
        navigate(
          getReleaseRoute(`${subPath}.${kebabCase(status.code)}`, Number(id))
        );
    },
    [id, handleStatusChange]
  );

  return (
    <Grid
      container
      justifyContent={tabPosition}
      spacing={3}
      sx={{ width: "auto" }}
    >
      {statuses.map((status: T) => {
        const codeKebabCase = kebabCase(status.code);
        const codeCamelCase = camelCase(status.code);
        return (
          <Grid
            item
            key={`${subPath}-status-option-${status.code}`}
            className="status-button"
          >
            <TabButton
              size="small"
              disabled={
                resolved.pathname ===
                  getReleaseRoute(`${subPath}.${codeKebabCase}`, Number(id)) ||
                activeStatusId === status.id ||
                (resolved.pathname === getReleaseRoute(subPath, Number(id)) &&
                  /* istanbul ignore next */ status.code === defaultStatus)
              }
              variant="text"
              onClick={(): void => handleTabClick(status)}
              data-testid={`${subPath}-status-option-${codeKebabCase}`}
            >
              {t(
                useSpecialLabels
                  ? `statusButtons.${subPath}.${codeCamelCase}`
                  : /* istanbul ignore next */ `property.navLinks.${codeCamelCase}`
              )}
            </TabButton>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default StatusButtons;
