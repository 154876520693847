import { TableCell } from "../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "object_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "property.objectName",
  },
  {
    id: "number",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "counter.meterNumber",
  },
  {
    id: "penultimate_reading_date",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "center",
    label: "counter.penultimateReadingDate",
  },
  {
    id: "penultimate_reading",
    numeric: true,
    disablePadding: false,
    sortable: true,
    align: "center",
    label: "counter.penultimateMeterReading",
  },
  {
    id: "last_reading_date",
    numeric: false,
    disablePadding: false,
    sortable: true,
    align: "center",
    label: "counter.lastReadingDate",
  },
  {
    id: "last_reading",
    numeric: true,
    disablePadding: false,
    sortable: true,
    align: "right",
    label: "counter.lastReading",
  },
  {
    id: "unit",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "counter.unit",
  },
];
