export default {
  dateFrom: "Leistungsdatum vom",
  dateTo: "Leistungsdatum bis",
  description: "Bezeichnung",
  taxPrice: "Umsatzsteuer",
  price: "Betrag",
  tax: "Umsatzsteuer",
  quantity: "Menge",
  totalPrice: "Gesamtpreis",
  fullInvoicePrice: "Vollständiger Rechnungspreis",
  fullInvoicePriceWithTax: "Vollständiger Rechnungspreis mit Steuer",
  fullInvoiceTax: "Volle Rechnungssteuer",
  services: "Dienstleistungen",
  addServices: "Dienste hinzufügen",
  deleteServices: "Dienste löschen",
  searchReceiver: "Empfänger suchen",
  debitor: "Debitor",
  debitorSearch: "Debitor suchen",
  notice: "Kommentar",
  sentDate: "E-Mail wurde am Datum gesendet",
  sendInvoice: "Send invoice",
  selectInvoiceToSend: "Zum Versenden wählen Sie bitte Rechnung",
  selectInvoiceToDownload: "Zum Herunterladen wählen Sie bitte die Rechnung",
  sendConformation: "Sind Sie sicher, dass Sie eine ausgewählte Rechnung senden möchten?",
  upload: "Hochladen",
  chooseFile: "Datei auswählen",
  error: {
    receiverIsRequired: "Empfänger ist erforderlich",
    priceIsRequired: "Preis ist erforderlich",
    descriptionIsRequired: "Beschreibung ist erforderlich",
    taxIsRequired: "Steuer ist erforderlich",
    serviceDateFrom: "Leistungsdatum von ist erforderlich",
    serviceDateTo: "Zustellungsdatum bis ist erforderlich",
    dateToDateShouldNotBeGreaterThanDateFrom:
      "Das Datum der Dienstleistung bis sollte nicht größer sein als das Datum der Dienstleistung von",
    quantityIsRequired: "Menge ist erforderlich",
    quantityNeedsToBePositiveNumber: "Die Menge muss eine positive Zahl sein",
    noticeIsRequired: "Kommentar ist erforderlich",
  },
};
