import invoice from "./invoice/de";
import contract from "./contract/de";
import sollkonto from "./sollkonto/de";
import outgoingInvoice from "./outgoingInvoice/de";
import history from "./history/de";

export default {
  finalReleaser: "Finale Freigabe",
  assetManager: "Asset Manager",
  propertyManager: "Hausverwaltung",
  documentRelease: "Dokumente freigeben",
  invoice,
  contract,
  sollkonto,
  outgoingInvoice,
  documentForwardedSuccessfully: "Dokument erfolgreich weitergeleitet",
  statusUpdatedSuccessfully: "Status erfolgreich aktualisiert",
  history,
};
