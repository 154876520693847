import { TableCell } from "../../../../../../types/table";

export const DATE_TIME_FORMAT = "DD.MM.YYYY";
export const STRING_LENGTH = 10;

export const itemsListHeadCells: TableCell[] = [
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "tenantRequests.userName",
  },
  {
    id: "konto",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "datev.creditor.account",
  },
  {
    id: "company_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "datev.creditor.companyName",
  },
  {
    id: "addressee_type",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "datev.creditor.addresseeType",
  },
  {
    id: "address_type",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "datev.creditor.addressType",
  },
  {
    id: "abbreviation",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "datev.creditor.abbreviation",
  },
  {
    id: "street",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "datev.creditor.street",
  },
  {
    id: "postal_code",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "datev.creditor.postalCode",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "datev.creditor.location",
  },
  {
    id: "code_correspondence_address",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "datev.creditor.codeCorrespondenceAddress",
  },
  {
    id: "address_valid_from",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "datev.creditor.addressValidFrom",
    align: "center",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];
