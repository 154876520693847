import { FormikValues } from "formik/dist/types";
import { mapBackendValidationErrors } from "../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR, SUBMIT_STATUS } from "../../../constants";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { createLOI, editLOI, previewLOIPdf } from "../../../api/loi";
import {
  replaceMomentByTimestamp,
  replaceTimestampByMomentDatepicker,
} from "../../../utils/date";
import {
  parseNumber,
  removeEmptyValues,
  replaceNullOrUndefinedByEmptyString,
} from "../../../utils/common";
import omit from "lodash/omit";
import { LOI as FEloi } from "../../../types/fe/loi";
import { LOI as BEloi } from "../../../types/be/loi";
import { route } from "../../../utils/url";
import { LOICreateOrEditTypes } from "./types";

export const createLOIInitState: FEloi = {
  company_id: "",
  contact_id: "",
  recipient_street: "",
  recipient_city: "",
  city: "",
  date: null,
  number: "",
  purchase_price: null,
  object_name: "",
  first_line_of_email: "",
  message: "",
  title_transaction_manager: "",
  user_id: "",
};

export const touchedInitState = {
  company_id: false,
  contact_id: false,
  recipient_street: false,
  recipient_city: false,
  city: false,
  date: false,
  number: false,
  purchase_price: false,
  object_name: false,
  first_line_of_email: false,
  message: false,
  title_transaction_manager: false,
  user_id: false,
};
/* istanbul ignore next */
export const prepareLOIItemBeforeSubmission = (reqData: FEloi): FEloi => {
  const newObj = { ...reqData };
  newObj.date = newObj.date ? replaceMomentByTimestamp(newObj.date) : null;
  newObj.purchase_price = newObj.purchase_price
    ? parseNumber(newObj.purchase_price)
    : null;
  return removeEmptyValues(newObj);
};
/* istanbul ignore next */
export const prepareLOIItemBeforeForm = (data: BEloi): any => ({
  ...data,
  ...replaceNullOrUndefinedByEmptyString(omit(data, ["date"])),
  company_id: data.company.id,
  contact_id: data.contact.id,
  user_id: data.user.id,
  date: replaceTimestampByMomentDatepicker(data.date),
});
/* istanbul ignore next */
export const handleCreateOrEditLOIFormSubmit = ({
  submitAction,
  setFormMessage,
  setPrevImage,
  loiId,
  navigate,
}: LOICreateOrEditTypes) => {
  return async (
    values: FEloi,
    { setStatus, setSubmitting, setValues, setTouched }: FormikValues
  ): Promise<void> => {
    const response =
      submitAction === SUBMIT_STATUS.PREVIEW
        ? await previewLOIPdf(prepareLOIItemBeforeSubmission(values))
        : loiId
        ? await editLOI(loiId, prepareLOIItemBeforeSubmission(values))
        : await createLOI(prepareLOIItemBeforeSubmission(values));
    if (submitAction === SUBMIT_STATUS.PREVIEW) {
      response.blob().then((myBlob) => {
        const objectURL = URL.createObjectURL(myBlob);
        setPrevImage(objectURL);
      });
      return;
    }
    const json = await response.json();
    if (
      response.status === HTTP_STATUS_CODES.CREATED ||
      response.status === HTTP_STATUS_CODES.OK
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      setValues(createLOIInitState);
      setTouched(touchedInitState);
      navigate && navigate(route("loi"));
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
      setSubmitting(false);
    }
  };
};
