import React, { useState } from "react";
import { Grid } from "@mui/material";
import { Card } from "./styled";
import { useParams } from "react-router-dom";
import UIButton from "../../../../../../../ui/button/Button";
import { Dialog } from "@mui/material";
import * as Yup from "yup";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { FormMessageInterface } from "../../../../../../../types/form";
import { FormMessageInItState } from "../../../../../../../constants";
import FormAlert from "../../../../../../../ui/formAlert/FormAlert";
import {
  createTopicFormInitData,
  handleCreateTopic,
  initCreateTopicRange,
} from "./utils";
import useValidation from "../../../../../../../hooks/useValidation";
import ToggleButtons from "../../../../../../../ui/toggleButtons";
import { CreateTopicProps } from "../../types";
import TopicDateRangeFilter from "../../../../../components/dataRangeFilter";
import { DialogTitle } from "../../../../create/styled";
import Dropdown from "../../../../../../../ui/dropdown";
import { getProperties } from "../../../../../../../api/property";
import { generalPropertyOption } from "../../../../../../../utils/common";

const CreateTopic = ({
  handleDialog,
  fetchInsuranceTopics,
  afterCreateTopic,
}: CreateTopicProps): React.ReactElement => {
  const { t } = useTranslation();
  const { type, property_id } = useValidation();
  const { id } = useParams();
  const [range, setRange] = useState<(Date | null)[]>(initCreateTopicRange);
  const [formMessage, setFormMessage] =
    React.useState<FormMessageInterface>(FormMessageInItState);
  const options = [
    {
      name: t("insurance.building"),
      value: true,
    },
    {
      name: t("insurance.liability"),
      value: false,
    },
  ];

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="xs"
      aria-labelledby="max-width-dialog-title"
      data-testid="create-topic-modal"
    >
      <DialogTitle title="insurance.createTopic" onClose={handleDialog} />
      <DialogContent>
        <Card>
          <Formik
            initialValues={{
              ...createTopicFormInitData,
              property_id: String(id || ""),
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({ type, property_id })}
            onSubmit={handleCreateTopic({
              setFormMessage,
              handleDialog,
              range,
              fetchInsuranceTopics,
              afterCreateTopic
            })}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleSubmit,
              isSubmitting,
              status,
            }) => (
              <form style={{ width: "400px" }}>
                {formMessage.text && (
                  <FormAlert formMessage={formMessage} sx={{ mb: "10px" }} />
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ToggleButtons
                      value={values.type}
                      options={options}
                      helperText={
                        (touched.type && status?.errors.type) || errors.type
                      }
                      error={Boolean(
                        (touched.type && status?.errors.type) || errors.type
                      )}
                      onChange={(
                        e: React.MouseEvent<HTMLElement>,
                        value: boolean | null
                      ): void => {
                        value !== null && setFieldValue("type", value);
                      }}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TopicDateRangeFilter
                      range={range}
                      setRange={setRange}
                      calenderContainerClass={"fixed-position"}
                    />
                  </Grid>
                  {!id && (
                    <Grid item xs={12} sm={12} mt={4}>
                      <Dropdown
                        id="property-autocomplete"
                        name="property_id"
                        label={t("form.propertyId")}
                        placeholder={t("form.propertyId")}
                        getOptions={getProperties}
                        onSelectOption={(id?: string) =>
                          setFieldValue("property_id", id)
                        }
                        optionLabel="object_name"
                        optionValue="id"
                        size="small"
                        helperText={
                          status?.errors.property_id ||
                          (touched.property_id && errors.property_id)
                        }
                        error={Boolean(
                          status?.errors.property_id ||
                            (touched.property_id && errors.property_id)
                        )}
                        defaultOption={[generalPropertyOption]}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={1} sx={{ mt: 4 }}>
                    <UIButton
                      title={t("save")}
                      type="button"
                      onClick={handleSubmit}
                      fullWidth
                      color="success"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      testId="create-topic-save"
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default CreateTopic;
