export default {
  creditor: {
    account: "Konto",
    user: "Benutzer",
    property: "Eigentum",
    companyName: "Name der Firma",
    addresseeType: "Adresstyp",
    abbreviation: "Abkürzung",
    addressType: "Adresstyp",
    street: "Straße",
    postalCode: "Postleitzahl",
    location: "Standort",
    codeCorrespondenceAddress: "Code-Korrespondenzadresse",
    addressValidFrom: "Adresse gültig ab",
    creditorRequestsWasCreatedSuccessfully:
      "Gläubigerantrag wurde erfolgreich erstellt!",
    creditorRequestsWasUpdatedSuccessfully:
      "Gläubigeranfrage wurde erfolgreich aktualisiert!",
    creditor: "Creditor",
    debtor: "Schuldner",
    sollkonto: "Soll-Konto",
    create: "Erstellen",
    deleteAll: "Alles löschen",

    creditorWasSuccessfullyCreated: "Gläubiger wurde erfolgreich angelegt!",
    deleteAllConfirmationMessage:
      "Sind Sie sicher, dass Sie alle Kreditoren löschen möchten?",

    formFields: {
      konto: "Konto",
      propertyId: "Eigenschafts-ID",
      companyName: "Name der Firma",
      addresseeType: "Adressatentyp",
      abbreviation: "Abkürzung",
      addresstype: "Adresstyp",
      street: "Straße",
      postalcode: "Postleitzahl",
      location: "Standort",
      codeCorrespondenceAddress: "Code-Korrespondenzadresse",
      addressValidFrom: "Adresse gültig ab",
    },

    error: {
      konto: "Konto ist erforderlich",
      propertyId: "Objekt-ID ist erforderlich",
      companyName: "Firmenname ist erforderlich",
      addresseeType: "Adressattyp ist erforderlich",
      abbreviation: "Abkürzung ist erforderlich",
      addressType: "Adresstyp ist erforderlich",
      street: "Straße ist erforderlich",
      postalCode: "Postleitzahl ist erforderlich",
      location: "Der Standort ist erforderlich",
      codeCorrespondenceAddress: "Code-Korrespondenzadresse ist erforderlich",
      addressValidFrom: "Das gültige Datum der Adresse ist erforderlich",
    },
  },
};
