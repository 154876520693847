import {
  useEffect,
  useState,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";
import { useParams } from "react-router-dom";
import { getFolder, getFolders } from "../api/folders";
import { getPropertyFileTree } from "../api/property";
import {
  findFolderById,
  mutateFolder,
} from "../components/fileManagement/utils";
import { composeNodeId } from "../components/properties/components/fileTree/utils";
import { FileTreeFolder as BEFileTreeFolder } from "../types/be.interfaces";
import { FetchFlags, useFetch } from "./useFetch";
/* istanbul ignore next */
interface UseFileManagement {
  data: BEFileTreeFolder[];
  folder: BEFileTreeFolder | null;
  setFolders: FetchFlags<BEFileTreeFolder[]>["setState"];
  runFolders: FetchFlags<BEFileTreeFolder[]>["run"];
  runFolder: FetchFlags<BEFileTreeFolder[]>["run"];
  isFoldersLoading: FetchFlags<BEFileTreeFolder[]>["isLoading"];
  isFolderLoading: FetchFlags<BEFileTreeFolder[]>["isLoading"];
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
  expanded: string[];
  setExpanded: Dispatch<SetStateAction<string[]>>;
  handleFolderClick: (id: string, name: string) => void;
}

interface UseFileManagementProps {
  extensions?: string[];
}

/* istanbul ignore next */
const useFileManagement = (
  init?: UseFileManagementProps
): UseFileManagement => {
  const { id } = useParams();

  const {
    data: folders,
    run: runFolders,
    setState: setFolders,
    isLoading: isFoldersLoading,
  } = useFetch<BEFileTreeFolder[]>();

  const {
    data: folder,
    run: runFolder,
    isLoading: isFolderLoading,
  } = useFetch<BEFileTreeFolder>();

  const [selected, setSelected] = useState("");
  const [expanded, setExpanded] = useState<string[]>([]);

  useEffect(() => {
    runFolders(id ? getPropertyFileTree(id) : getFolders());
  }, []);

  const handleFolderClick = useCallback((id: string, name: string): void => {
    const nodeId = composeNodeId(id, name);
    setSelected(nodeId);
    if (expanded.includes(nodeId)) {
      setExpanded((expanded) => [...expanded.filter((st) => st !== nodeId)]);
    } else {
      setExpanded((expanded) => [...expanded, nodeId]);
      runFolder(getFolder(id));
    }
  }, []);

  const cachedFolders = useMemo(() => {
    const id = folder?.id;
    if (id && folders) {
      const obj = findFolderById(folders, +id);
      obj &&
        mutateFolder({
          folder: obj,
          children: folder?.children,
          files: folder?.files.filter(({ id, extension }) =>
            init?.extensions?.length
              ? [...init.extensions].includes(extension)
              : id
          ),
        });
    }
    return folders;
  }, [folders, folder, selected]);

  const data = useMemo(
    () => [
      {
        children: cachedFolders || [],
        files: [],
        id: 0,
        name: "",
        can_remove: false,
      },
    ],
    [cachedFolders]
  );

  return {
    data,
    folder,
    setFolders,
    runFolders,
    runFolder,
    selected,
    setSelected,
    expanded,
    setExpanded,
    isFoldersLoading,
    isFolderLoading,
    handleFolderClick,
  };
};

export default useFileManagement;
