import { FormProps } from "../../../types/form";
import { FormControl, FormGroup, Grid, Stack } from "@mui/material";
import React, {
  HTMLAttributes,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../ui/button/Button";
import {
  COMPANY_GENERAL_TYPE_ID,
  COMPANY_PROPERTY_MANAGEMENT_TYPE_ID,
  LANGUAGE,
  SEARCH_PARAM_MIN_CHARACTERS,
} from "../../../constants";
import TextField from "../../../ui/formsFields/text";
import { FormControlLabel } from "@mui/material";
import { CreateCompanyFormData } from "./types";
import { Android12Switch } from "../../../ui/formsFields/switch/styled";
import Autocomplete from "@mui/material/Autocomplete";
import Loader from "../../Loader";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  PlaceDetails,
  PlaceDetailsResponse,
  Prediction,
} from "../../../types/be/place";
import { AutocompleteListItem } from "../../properties/edit/general/styled";
import { Dropdown } from "../../../ui/autocomplete/styled";
import { getPlaceDetails, getPlacePredictions } from "../../../api/places";
import { getJson } from "../../../utils/http";
import { mapAddressComponentsToPlaceAddress } from "../../properties/utils";
import intersection from "lodash/intersection";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import debounce from "lodash/debounce";
import {
  findFiveDigitZipCode,
  prepareQueryParams,
} from "../../../utils/common";

/* istanbul ignore next */
function Form({
  handleSubmit,
  status,
  isSubmitting,
  values,
  errors,
  handleBlur,
  setFieldValue,
  setInitialValues,
  handleCreateCompanyModalClose,
  isPropertyManagerRole,
}: FormProps): React.ReactElement {
  const [loading, setLoading] = useState(false);
  const [isPlaceValidationError, setIsPlaceValidationError] = useState(false);
  const [, setPlaceDetails] = useState<PlaceDetails | null>(null);
  const [placeInputValue, setPlaceInputValue] = useState("");
  const [placePredictions, setPlacePredictions] = useState<
    readonly Prediction[]
  >([]);
  const [checkedCompanyType, setCheckedCompanyType] = useState(false);
  const { t } = useTranslation();

  const companyTypeGeneral = t("createCompany.companyTypeGeneral");
  const companyTypePropertyManagement = t(
    "createCompany.companyTypePropertyManagement"
  );
  const [placeAutocompleteFieldValue, setPlaceAutocompleteFieldValue] =
    useState("");

  const handleChangeCompanyType = (
    e: React.ChangeEvent<HTMLInputElement>,
    values: CreateCompanyFormData
  ): void => {
    setInitialValues({
      ...values,
      company_type_id: isPropertyManagerRole
        ? /* istanbul ignore next */
          COMPANY_PROPERTY_MANAGEMENT_TYPE_ID
        : checkedCompanyType
        ? COMPANY_GENERAL_TYPE_ID
        : COMPANY_PROPERTY_MANAGEMENT_TYPE_ID,
    });
    setCheckedCompanyType(e.target.checked);
  };

  const handlePlacePredictionSelect = ({ place_id }: Prediction): void => {
    const params = prepareQueryParams("", {
      place_id,
      language: LANGUAGE.EN,
    });
    getPlaceDetails(params)
      .then(getJson)
      .then(({ data: { result } }: PlaceDetailsResponse) => {
        setPlaceDetails(result);
        const { geometry } = result;
        const { address_components } = result;
        const { place_id } = result;
        const mapResult =
          mapAddressComponentsToPlaceAddress(address_components);
        setPlaceInputValue(result.formatted_address);
        setFieldValue("location", {
          full_address: result.formatted_address!,
          city: mapResult.city || "",
          country_code: mapResult.country_code!,
          state_code: mapResult.state_code || "",
          state: mapResult.state || "",
          house_number: mapResult.house_number || "",
          zip_code: mapResult.zip_code || "",
          street: mapResult.street || "",
          place_id: place_id!,
          lat: geometry.location.lat!,
          lng: geometry.location.lng!,
        });
      });
  };

  useEffect(() => {
    setIsPlaceValidationError(
      !!intersection(Object.keys(errors), [
        "country",
        "place_id",
        "state_code",
        "house_number",
        "street",
      ]).length
    );
  }, [errors]);

  useEffect(() => {
    const newZipCode = findFiveDigitZipCode(placeAutocompleteFieldValue);
    newZipCode && setFieldValue("location.zip_code", newZipCode);
    newZipCode &&
      setFieldValue("location.full_address", placeAutocompleteFieldValue);
  }, [placeAutocompleteFieldValue]);

  const handleAutocompleteOnChange = (
    _: SyntheticEvent,
    value: Prediction | null
  ): void => {
    if (value) handlePlacePredictionSelect(value);
    else {
      setPlaceDetails(null);
    }
  };

  const handleRenderInput = (
    params: AutocompleteRenderInputParams
  ): ReactNode => (
    <TextField
      {...params}
      label={t("createContact.contactOrCompanyAddress")}
      onChange={(e) => {
        setPlaceInputValue(e.target.value);
        onLocationSearchKeyDown(e.target.value);
        setPlaceAutocompleteFieldValue(e.target.value);
      }}
      placeholder={t("google.place.autocomplete.placeholder")}
      value={values.place_id}
      onBlur={handleBlur}
      error={isPlaceValidationError}
      helperText={
        isPlaceValidationError && t("google.place.autocomplete.validationError")
      }
      status={status}
    />
  );

  const onLocationSearchKeyDown = React.useCallback(
    debounce(async (search: string): Promise<void> => {
      if (search.length < SEARCH_PARAM_MIN_CHARACTERS) setPlacePredictions([]);
      else {
        setLoading(true);
        const params = prepareQueryParams("", {
          input: search,
        });
        getPlacePredictions(params)
          .then(getJson)
          .then(({ data: { predictions } }) => {
            setPlacePredictions(predictions);
            setLoading(false);
          })
          .catch(() => {
            setPlacePredictions([]);
          });
      }
    }, 300),
    []
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={6} columns={12}>
          <Grid
            item
            xs={12}
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={2}
          >
            <Grid item xs={6}>
              <TextField
                name="title"
                label={t("createContact.title")}
                value={values.title || ""}
                inputProps={{
                  "data-testid": "company-title-input",
                }}
                sx={{ marginTop: 1.5 }}
                status={status}
              />
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <FormControl component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Android12Switch
                          checked={
                            isPropertyManagerRole
                              ? isPropertyManagerRole
                              : checkedCompanyType
                          }
                          onChange={(e): void =>
                            handleChangeCompanyType(e, values)
                          }
                          disabled={isPropertyManagerRole}
                          name="checkedCompanyType"
                          data-testid="company-type-switcher"
                        />
                      }
                      label={
                        !checkedCompanyType && !isPropertyManagerRole
                          ? companyTypeGeneral
                          : companyTypePropertyManagement
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ mb: 3 }}>
            <Autocomplete
              fullWidth
              loading={loading}
              loadingText={`${t("loading")}...`}
              inputValue={placeInputValue ?? ""}
              popupIcon={loading ? <Loader size={25} /> : <ArrowDropDownIcon />}
              options={placePredictions!}
              isOptionEqualToValue={handleIsOptionEqualToValue}
              getOptionLabel={handleGetOptionLabel}
              filterOptions={handleFilterOptions}
              onChange={handleAutocompleteOnChange}
              renderOption={handleRenderOption}
              PaperComponent={autocompletePaperComponent}
              renderInput={handleRenderInput}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Grid item>
            <Button
              onClick={handleCreateCompanyModalClose}
              title={t("cancel")}
              testId={"cancel-create-company-button"}
              variant={"text"}
              size="large"
              type="button"
            />
          </Grid>
          <Grid item>
            <Button
              title={t("create")}
              type="submit"
              sx={{ ml: "1rem" }}
              color="success"
              size="large"
              testId={"create-company-button"}
              disabled={isSubmitting}
              isLoading={isSubmitting}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}

/* istanbul ignore next */
const handleRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  placePrediction: Prediction
): ReactNode => (
  <AutocompleteListItem {...props} key={placePrediction.description}>
    - {placePrediction.description}
  </AutocompleteListItem>
);

/* istanbul ignore next */
const handleIsOptionEqualToValue = (
  option: Prediction,
  value: Prediction
): boolean => option.place_id === value.place_id;
/* istanbul ignore next */
const handleGetOptionLabel = (placePrediction: Prediction): string =>
  placePrediction.description;
/* istanbul ignore next */
const handleFilterOptions = (options: Prediction[]): Prediction[] => options;

/* istanbul ignore next */
const autocompletePaperComponent = ({
  children,
}: {
  children?: ReactNode;
}): ReactElement | null => <Dropdown>{children}</Dropdown>;

export default Form;
