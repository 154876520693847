import React, { ReactElement, useCallback, useMemo } from "react";
import { Grid, Tooltip } from "@mui/material";
import { LoadingButton as Button } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { RELEASE_STATUS } from "../../../../constants";
import {
  DocumentReleasePropsForAssignedUser,
  DocumentReleasePropsForReleaser,
} from "./types";
import { StyledSendIcon } from "./styled";
import { Cancel, DoneAll, HourglassEmpty } from "@mui/icons-material";
import StatusLeftBy from "../statusLeftBy/StatusLeftBy";
import AssignUser from "./AssignUser";
import { RELEASE_LEVEL } from "../../../../utils/common";

/* istanbul ignore next */
const DocumentRelease = ({
  releaseStatus,
  releaser,
  handleReleaseDocument,
  id,
  level,
  afterSuccess,
  hasReleasePermission,
  hasForwardPermission,
  hasAssignUserPermission,
  overdue,
  forwardedBy,
  relation,
  assignedUser,
  handleForwardDocument,
  loading,
  propertyId,
  isButtonVisible,
  tooltip,
}:
  | DocumentReleasePropsForAssignedUser
  | DocumentReleasePropsForReleaser): ReactElement => {
  const { t } = useTranslation();

  const preparedIsVisibile = useMemo(() => {
    return {
      forwardButton: isButtonVisible?.forwardButton ?? true,
      pendingButton: isButtonVisible?.pendingButton ?? true,
      rejectButton: isButtonVisible?.rejectButton ?? true,
      releaseButton: isButtonVisible?.releaseButton ?? true,
      assignUserButton: isButtonVisible?.assignUserButton ?? true,
    };
  }, [isButtonVisible]);

  const preparedTooltip = useMemo(() => {
    return {
      forwardButton: tooltip?.forwardButton ?? "forward",
      releaseButton: tooltip?.releaseButton ?? "property.navLinks.released",
      pendingButton: tooltip?.pendingButton ?? "property.navLinks.pending",
      rejectButton: tooltip?.rejectButton ?? "property.navLinks.rejected",
    };
  }, [tooltip]);

  const checkLoading = useCallback(
    (releaseStatus: RELEASE_STATUS) => {
      return (
        loading?.id === id &&
        loading?.level === level &&
        loading?.status === releaseStatus
      );
    },
    [loading, id, level]
  );

  const findColor = useCallback(
    (statusCodeToCheck: RELEASE_STATUS) => {
      if (releaseStatus?.code === statusCodeToCheck) {
        switch (statusCodeToCheck) {
          case RELEASE_STATUS.FINAL_RELEASED:
            return "success";
          case RELEASE_STATUS.REJECTED:
            return "error";
          case RELEASE_STATUS.PENDING:
            return "warning";
          default:
            return "inherit";
        }
      } else {
        return "inherit";
      }
    },
    [releaseStatus]
  );

  const findVariant = useCallback(
    (releaseStatusToCheck: RELEASE_STATUS) => {
      return releaseStatus?.code === releaseStatusToCheck
        ? "contained"
        : "outlined";
    },
    [releaseStatus]
  );

  const findCursor = useCallback(
    (releaseStatusToCheck: RELEASE_STATUS) =>
      hasReleasePermission && releaseStatus?.code !== releaseStatusToCheck
        ? "pointer"
        : "not-allowed",
    [releaseStatus]
  );

  return (
    <Grid
      container
      spacing={1}
      flexDirection="column"
      alignItems={"end"}
      sx={{ mt: 0 }}
    >
      <Grid
        container
        spacing={1}
        rowSpacing={4}
        sx={{
          flexWrap: "nowrap",
          pt: 0,
        }}
      >
        {preparedIsVisibile.forwardButton ? (
          <Grid item sx={{ mt: 0 }}>
            <Tooltip title={t(preparedTooltip.forwardButton)} placement="top">
              <span>
                <Button
                  color={forwardedBy ? (overdue ? "error" : "info") : "inherit"}
                  variant={forwardedBy ? "contained" : "outlined"}
                  size="small"
                  sx={{
                    minWidth: 0,
                    cursor:
                      !hasReleasePermission &&
                      hasForwardPermission &&
                      !forwardedBy
                        ? "pointer"
                        : "not-allowed",
                  }}
                  onClick={() =>
                    handleForwardDocument({
                      id,
                      level,
                      afterSuccess,
                      successMessage: t(
                        "documentRelease.documentForwardedSuccessfully"
                      ),
                      hasPermission:
                        !hasReleasePermission &&
                        hasForwardPermission &&
                        !forwardedBy,
                    })
                  }
                  loading={checkLoading(RELEASE_STATUS.FORWARD_TO_ADMIN)}
                >
                  <StyledSendIcon />
                </Button>
              </span>
            </Tooltip>
            {forwardedBy ? (
              <Grid item>
                <StatusLeftBy {...forwardedBy} tooltip align="end" />
              </Grid>
            ) : null}
          </Grid>
        ) : null}
        {preparedIsVisibile.releaseButton ? (
          <Grid item>
            <Tooltip title={t(preparedTooltip.releaseButton)} placement="top">
              <span>
                <Button
                  color={findColor(RELEASE_STATUS.FINAL_RELEASED)}
                  variant={findVariant(RELEASE_STATUS.FINAL_RELEASED)}
                  size="small"
                  sx={{
                    minWidth: 0,
                    cursor: findCursor(RELEASE_STATUS.FINAL_RELEASED),
                  }}
                  onClick={() =>
                    handleReleaseDocument({
                      id,
                      level,
                      status: RELEASE_STATUS.FINAL_RELEASED,
                      afterSuccess,
                      successMessage: t(
                        "documentRelease.statusUpdatedSuccessfully"
                      ),
                      hasPermission:
                        hasReleasePermission &&
                        releaseStatus?.code !== RELEASE_STATUS.FINAL_RELEASED,
                    })
                  }
                  loading={checkLoading(RELEASE_STATUS.FINAL_RELEASED)}
                >
                  <DoneAll />
                </Button>
              </span>
            </Tooltip>
          </Grid>
        ) : null}
        {preparedIsVisibile.pendingButton ? (
          <Grid item>
            <Tooltip title={t(preparedTooltip.pendingButton)} placement="top">
              <span>
                <Button
                  color={findColor(RELEASE_STATUS.PENDING)}
                  variant={findVariant(RELEASE_STATUS.PENDING)}
                  sx={{
                    minWidth: 0,
                    cursor: findCursor(RELEASE_STATUS.PENDING),
                  }}
                  size="small"
                  onClick={() =>
                    handleReleaseDocument({
                      id,
                      level,
                      status: RELEASE_STATUS.PENDING,
                      afterSuccess,
                      successMessage: t(
                        "documentRelease.statusUpdatedSuccessfully"
                      ),
                      hasPermission:
                        hasReleasePermission &&
                        releaseStatus?.code !== RELEASE_STATUS.PENDING,
                    })
                  }
                  loading={checkLoading(RELEASE_STATUS.PENDING)}
                >
                  <HourglassEmpty />
                </Button>
              </span>
            </Tooltip>
          </Grid>
        ) : null}
        {preparedIsVisibile.rejectButton ? (
          <Grid item>
            <Tooltip title={t(preparedTooltip.rejectButton)} placement="top">
              <span>
                <Button
                  color={findColor(RELEASE_STATUS.REJECTED)}
                  variant={findVariant(RELEASE_STATUS.REJECTED)}
                  size="small"
                  sx={{
                    minWidth: 0,
                    cursor: findCursor(RELEASE_STATUS.REJECTED),
                  }}
                  onClick={() =>
                    handleReleaseDocument({
                      id,
                      level,
                      status: RELEASE_STATUS.REJECTED,
                      afterSuccess,
                      successMessage: t(
                        "documentRelease.statusUpdatedSuccessfully"
                      ),
                      hasPermission:
                        hasReleasePermission &&
                        releaseStatus?.code !== RELEASE_STATUS.REJECTED,
                    })
                  }
                  loading={checkLoading(RELEASE_STATUS.REJECTED)}
                >
                  <Cancel />
                </Button>
              </span>
            </Tooltip>
            {releaser ? (
              <Grid item>
                <StatusLeftBy {...releaser} tooltip align="end" />
              </Grid>
            ) : null}
          </Grid>
        ) : null}
        {level === RELEASE_LEVEL.FOURTH_LEVEL_RELEASE &&
        preparedIsVisibile.assignUserButton ? (
          <Grid item>
            <AssignUser
              relation={relation}
              relationId={id}
              afterAssignUser={afterSuccess}
              assignedUser={assignedUser}
              propertyId={propertyId}
              hasAssignUserPermission={hasAssignUserPermission}
            />
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default DocumentRelease;
