import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import styled from "styled-components/macro";
import { Line } from "react-chartjs-2";
import {
  Card as MuiCard,
  CardHeader,
  CardContent as MuiCardContent,
  Menu,
  MenuItem,
  Grid,
  Checkbox,
  Autocomplete,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { useTranslation } from "react-i18next";
import {
  getBankAccounts,
  getBankPaymentsGraph,
} from "../../../api/bankAccount";
import { DatePickerWrapper } from "../../../ui/datePicker/styled";
import DatePicker, { registerLocale } from "react-datepicker";
import {
  BANK_ACCOUNT_ID_PARAM,
  DATEPICKER_DATE_FORMAT,
  FormMessageErrorState,
} from "../../../constants";
import { TextField } from "../../vacationRequests/vacationList/createAndEdit/styled";
import { CloseIconStyled } from "../../employeeOverview/vacationHolidayManagementModal/styled";
import { initInsuranceRange, langDict } from "./utils";
import { BankAccount as BEBankAccount } from "../../../types/be/bankAccount";
import { getRandomColor, objectGetParamsToString } from "../../../utils/common";
import { replaceMomentByTimestamp } from "../../../utils/date";
import { BankAccount, LineChartProps } from "./types";
import { SelectBox } from "../../activity/list/toolbar/styled";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useFetch } from "../../../hooks/useFetch";
import { ErrorBox } from "../styled";
import { StyledPaper } from "../../contacts/autocomplete/styled";

ChartJS.register(
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

const currentLang = window.localStorage.getItem("lang") || "en";
currentLang && registerLocale("lang", langDict[currentLang]);

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 290px;
  position: relative;
`;

export const CardContent = styled(MuiCardContent)`
  > div > .MuiPaper-root {
    padding: ${(props): string => props.theme.spacing(0)};
  }
`;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
/* istanbul ignore next */
const LineChart = ({
  toggleBankAccountModal,
}: LineChartProps): ReactElement => {
  const { t } = useTranslation();
  const [anchorMenu, setAnchorMenu] = useState<Element | null>(null);
  const [selectedBankAccounts, setSelectedBankAccounts] = useState<number[]>(
    []
  );
  const [amounts, setAmounts] = useState<(number | null)[][]>([]);
  const [dates, setDates] = useState<(string | null)[]>([]);
  const [range, setRange] = useState<(Date | null)[]>(initInsuranceRange);
  const [startDate, endDate] = range;

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(0,0,0,0.0)",
        },
      },
      y: {
        grid: {
          color: "rgba(0,0,0,0.0375)",
          fontColor: "#fff",
        },
      },
    },
  };

  const closeMenu = (): void => {
    setAnchorMenu(null);
  };

  const handleSeeMore = (): void => {
    toggleBankAccountModal();
    closeMenu();
  };

  const handleClearRange = (event: React.MouseEvent<SVGSVGElement>): void => {
    event.stopPropagation();
    setRange(initInsuranceRange);
  };

  const handleChange = (value: BEBankAccount[]): void => {
    const bankAccountsId = value.map(({ id }: BEBankAccount) => id);
    setSelectedBankAccounts(bankAccountsId);
  };

  const {
    data: bankAccountsData,
    run: runBankAccountsData,
    isLoading: isBankAccountsDataLoading,
    isError: isBankAccountsDataError,
  } = useFetch<BEBankAccount[]>();

  useEffect(() => {
    runBankAccountsData(getBankAccounts("?limit=100"));
  }, []);

  useEffect(() => {
    selectedBankAccounts.length > 0 &&
      startDate &&
      endDate &&
      handleBankAccountPayments();
  }, [endDate, selectedBankAccounts]);

  const datasets = useMemo(() => {
    return amounts.map((amountSet, index) => ({
      label: `Dataset ${index + 1}`,
      data: amountSet,
      borderColor: getRandomColor(),
      fill: false,
      spanGaps: true,
    }));
  }, [amounts]);

  const data = {
    labels: dates,
    datasets: datasets,
  };

  const handleBankAccountPayments = useCallback(async () => {
    const params = {
      from: replaceMomentByTimestamp(startDate),
      to: replaceMomentByTimestamp(endDate),
    };
    const response = await getBankPaymentsGraph(
      `?${objectGetParamsToString(params)}${
        selectedBankAccounts.length > 0
          ? selectedBankAccounts.map(
              (item) => `&${BANK_ACCOUNT_ID_PARAM}=${item}`
            )
          : ""
      }`
    );
    const { data }: { data: BankAccount[][] } = await response.json();

    const allDates = Array.from(
      new Set(
        Object.values(data).flatMap((bankAccounts) =>
          bankAccounts.map((account) => account.date)
        )
      )
    );
    const amountsArray: number[][] = [];
    for (const sublist of Object.values(data)) {
      const amounts: number[] = Array(allDates.length).fill(NaN);
      for (const item of sublist) {
        const index: number = allDates.indexOf(item.date);
        amounts[index] = item.amount;
      }
      amountsArray.push(amounts);
    }
    setAmounts(amountsArray);
    setDates(allDates);
  }, [selectedBankAccounts, startDate, endDate]);

  if (isBankAccountsDataError)
    return <ErrorBox formMessage={FormMessageErrorState} />;

  return (
    <Card mb={6} sx={{ height: "100%", overflowY: "auto" }}>
      <CardHeader
        action={
          <Button
            aria-label="settings"
            size="small"
            aria-haspopup={true}
            onClick={handleSeeMore}
            color="primary"
            data-testid="bank-show-icon-button"
          >
            {t("home.seeMore")}
          </Button>
        }
        title={`${t("widgets.bankAccountPayments")}`}
      />
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleSeeMore}>{t("home.seeMore")}</MenuItem>
      </Menu>
      <Grid container spacing={6} columns={12}>
        <Grid item xs={12} sm={5.7} sx={{ mt: 0.5, ml: 5 }}>
          {bankAccountsData && (
            <Autocomplete
              id="bank_accounts_selection"
              options={bankAccountsData!}
              multiple
              loading={isBankAccountsDataLoading}
              data-testid="search-accounts"
              disableCloseOnSelect
              getOptionLabel={(option: BEBankAccount) => option.bank_name}
              onChange={(_, value: BEBankAccount[]) => {
                handleChange(value);
              }}
              PaperComponent={StyledPaper}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.bank_name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("widgets.accounts")}
                  placeholder={t("search")}
                />
              )}
              renderTags={(list) => {
                const displayList = list.map((item) => item.bank_name);
                return (
                  <SelectBox>
                    <div>{`${t("selected")} ${displayList.length} ${t(
                      "widgets.accounts"
                    )}`}</div>
                  </SelectBox>
                );
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={5.7} sx={{ mt: 0.6 }}>
          <DatePickerWrapper>
            <DatePicker
              locale={currentLang}
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={
                /* istanbul ignore next */ (update) => {
                  setRange(update);
                }
              }
              maxDate={new Date()}
              dateFormat={DATEPICKER_DATE_FORMAT}
              customInput={
                <TextField
                  inputProps={{
                    readOnly: true,
                    "data-testid": "date-range-input",
                  }}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <CloseIconStyled
                        data-testid="remove-from-date-range"
                        onClick={handleClearRange}
                      />
                    ),
                  }}
                />
              }
            />
          </DatePickerWrapper>
        </Grid>
      </Grid>
      <CardContent>
        <ChartWrapper>
          <Line data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default LineChart;
