import Table from "../../../../ui/table/Table";
import useTable from "../../../../ui/table/useTable";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Search as SearchIcon } from "react-feather";
import { Button, Grid, TableCell, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@mui/material";
import { formatTimestamp } from "../../../../utils/date";
import { CommentLeftBy } from "../styled";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { FILTER_TYPES, commentsItemsListHeadCells } from "./utils";
import { getComments, updateCommentStatus } from "../../../../api/comments";
import { prepareQueryParams } from "../../../../utils/common";
import { handleServerError } from "../../../../utils/http";
import { Search, SearchIconWrapper, Input } from "../../../../ui/table/styled";
import camelCase from "lodash/camelCase";
import { TABS } from "../tabs/utils";
import { CommentsListProps } from "./types";
import { generateUrl } from "../../../../ui/notification/notificationBox/utils";
import {
  CommentsStatusIds,
  OpenCommentsListWidget,
} from "../../../../types/be/comment";
import ExpandableComment from "../../../../ui/expandableComment/ExpandableComment";
import RedirectProperty from "../../../../ui/redirectProperty";

const CommentsList = ({
  statusId: statusIdProp,
  showToolbar = true,
  userId,
  tableToolbar,
  editableStatus = true,
}: CommentsListProps): ReactElement => {
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleRowRemove,
  } = useTable<OpenCommentsListWidget>();
  const [statusId, setStatusId] = useState<number>(statusIdProp ?? -1);

  const { t } = useTranslation();
  const fetchComments = useCallback(async () => {
    setIsLoading(true);
    const res = await getComments(
      prepareQueryParams(queryParams, {
        status_id: statusId === -1 ? "" : statusId,
        user_id: userId,
      })
    );
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const resJson = await res.json();
      setTotal(resJson.meta.total);
      setData(resJson.data);
      setIsLoading(false);
    }
  }, [queryParams, setIsLoading, setTotal, setData, setIsLoading, statusId]);

  const buttonTitleDict: Record<CommentsStatusIds, TABS> = {
    [CommentsStatusIds.OPEN_COMMENTS]: t(`comments.${camelCase(TABS.DONE)}`),
    [CommentsStatusIds.DONE]: t(`comments.open`),
  };

  useEffect(() => {
    if (!queryParams) return;
    fetchComments();
  }, [queryParams, statusId]);

  const handleStatusChange =
    (id: number, statusId: CommentsStatusIds) => async (): Promise<void> => {
      const response = await updateCommentStatus(id, {
        status_id:
          statusId === CommentsStatusIds.OPEN_COMMENTS
            ? CommentsStatusIds.DONE
            : CommentsStatusIds.OPEN_COMMENTS,
      });
      if ([200, 201].includes(response.status)) {
        fetchComments();
      } else {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      }
    };

  const tableToolbarDefault = (
    <Grid container sx={{ pt: 5 }} className="toolbar-container">
      <Grid item xs={3} lg={2} sm={6}>
        <TextField
          value={statusId}
          onChange={(event) => setStatusId(Number(event?.target?.value))}
          select
          fullWidth={true}
          variant="outlined"
          size="small"
          inputProps={{
            "aria-label": "filter-select",
          }}
        >
          {FILTER_TYPES.map((filter) => (
            <MenuItem key={filter.value} value={filter.value}>
              {t(filter.name)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs />
      <Grid item>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Input placeholder={t("search")} onChange={handleTableSearch} />
        </Search>
      </Grid>
    </Grid>
  );

  const renderRow = ({
    id,
    body,
    updated_at,
    property,
    user,
    entity,
    status,
    created_at,
  }: OpenCommentsListWidget): ReactElement => {
    return (
      <>
        <TableCell>
          <RedirectProperty property={property} />
        </TableCell>
        <TableCell>
          <CommentLeftBy>{`$${user?.first_name} ${user?.last_name}`}</CommentLeftBy>
        </TableCell>
        <TableCell>
          <ExpandableComment
            commentData={{
              id,
              body,
              created_at,
              user,
            }}
          ></ExpandableComment>
        </TableCell>
        <TableCell>
          {formatTimestamp(updated_at, "DD.MM.yyyy HH:mm:ss")}
        </TableCell>
        <TableCell>
          <Link
            to={generateUrl(entity)}
            key={property?.id + "" + Math.random()}
          >
            <InsertLinkIcon sx={{ color: "white" }} />
          </Link>
        </TableCell>
        {editableStatus && (
          <TableCell align="left">
            <Button
              variant="contained"
              onClick={handleStatusChange(
                id,
                status?.id || CommentsStatusIds.DONE
              )}
              data-testid="status-button"
            >
              {buttonTitleDict[status?.id || CommentsStatusIds.DONE]}
            </Button>
          </TableCell>
        )}
      </>
    );
  };

  const memoedCommentsItemsListHeadCells = useMemo(() => {
    if (editableStatus) return commentsItemsListHeadCells;
    return commentsItemsListHeadCells.filter(
      (el) => el.label !== "comments.openOrDone"
    );
  }, [commentsItemsListHeadCells]);

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onRowRemove={handleRowRemove}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={memoedCommentsItemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={showToolbar ? tableToolbar || tableToolbarDefault : <></>}
        tableDataMaxHeight={"100%"}
      />
    </>
  );
};

export default CommentsList;
