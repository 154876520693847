import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import {
  Button,
  Divider as MuiDivider,
  FormGroup,
  Paper,
  Typography as MuiTypography,
  TableRow as MuiTableRow,
} from "@mui/material";
import MUIDialogContent from "@mui/material/DialogContent";
import FormAlert from "../../ui/formAlert/FormAlert";

export const Divider = styled(MuiDivider)(spacing);
export const Typography = styled(MuiTypography)(spacing);

export const ErrorBox = styled(FormAlert)(spacing);

export const DialogTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props): string => `${props.theme.spacing(4, 3, 0, 6)}`};
`;

export const DialogContent = styled(MUIDialogContent)`
  & > div {
    max-height: 80vh;
    width: 100%;
    margin-top: ${(props) => props.theme.spacing(1.5)};
  }
`;
/* istanbul ignore next */
export const WidgetsList = styled(Paper).attrs(
  ({ $isHidden }: { $isHidden: boolean }) => ({
    $isHidden: $isHidden || false,
  })
)`
  width: 200px;
  z-index: 99;
  position: fixed;
  right: ${({ $isHidden }): string => {
    return $isHidden ? "-200px" : "-5px";
  }};
  top: 75px;
  transition: right 0.2s ease-in-out;
`;
/* istanbul ignore next */
export const WidgetsSideBarButton = styled(Button).attrs(
  ({ $isHidden }: { $isHidden: boolean }) => ({
    $isHidden: $isHidden || false,
  })
)`
  position: relative;
  justify-content: ${({ $isHidden }): string => {
    return $isHidden ? "center" : "start";
  }};
  right: ${({ $isHidden }): string => {
    return $isHidden ? "35px" : "0";
  }};
  width: ${({ $isHidden }): string => {
    return $isHidden ? "120%" : "100%";
  }};
  top: 0;
  z-index: 99;
  > .MuiButton-startIcon {
    position: relative !important;
    right: ${({ $isHidden }): string => {
      return $isHidden ? "70px" : "0";
    }};
  }
`;

export const DashboardLockSection = styled(FormGroup)`
  border-bottom: 1px solid ${(props) => props.theme.palette.background.hover};
  padding: 0 ${(props) => props.theme.spacing(2)};
`;

export const DescriptionContainer = styled.div`
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DialogContainWrapper = styled.div`
  .table-toolbar {
    margin-top: ${(props): string => props.theme.spacing(0)};
  }
  .status-button {
    padding-top: ${(props): string => props.theme.spacing(2)};
  }
  .filter-container {
    margin-top: ${(props): string => props.theme.spacing(3)};
  }
  .status-button-container {
    padding-bottom: ${(props): string => props.theme.spacing(3)};
  }
  .bread-crumbs-container {
    padding-bottom: ${(props): string => props.theme.spacing(0)};
  }
  .toolbar-container {
    padding-top: ${(props): string => props.theme.spacing(0)};
    padding-bottom: ${(props): string => props.theme.spacing(0)};
    .history-button {
      margin-top: ${(props): string => props.theme.spacing(-2)};
    }
  }
`;

export const TableBodyRow = styled(MuiTableRow)<{ $stripe?: boolean }>`
  height: ${(props) => props.theme.spacing(10.5)};
  background-color: ${(props): string =>
    props?.$stripe
      ? props.theme.table.defaultBGColor
      : props.theme.table.lightBgColor} !important;
`;

export const TableHeadRow = styled(MuiTableRow)`
  height: ${(props) => props.theme.spacing(10.5)};
  background-color: ${(props): string =>
    props.theme.table.headerBGColor} !important;
`;
