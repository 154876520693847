import { TableCell } from "../../../../types/table";
import { HISTORY } from "./types";
export const itemsListHeadCells: TableCell[] = [
  {
    id: "property_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertyName",
    align: "left",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.history.userName",
    align: "left",
  },
  {
    id: "clickedButton",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.history.clickedButton",
    align: "left",
  },
  {
    id: "fileName",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.history.fileName",
    align: "left",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "amount",
    align: "right",
  },
  {
    id: "date_of_update",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.history.dateOfUpdate",
    align: "center",
  },
  {
    id: "current_invoice_status",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.history.currentInvoiceStatus",
    align: "left",
  },
  {
    id: "comment",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.history.comment",
    align: "left",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "",
  },
];

export const documentReleaseHistoryScrollId = 2;

export const StatusButtonsDict: Record<HISTORY, string> = {
  invoice: "invoices",
  contract: "contracts",
  offer: "offers",
  insurance: "insurances",
};
