import http from "../utils/http";
import { CreateUserFormInitState } from "../components/users/create/types";
import { IdPropType, User } from "../types/models";
import { ResetPasswordForm } from "../components/auth/reset-password/types";
import { ForgotPasswordForm } from "../components/auth/forgot-password/types";
import { NotificationSettingsTypes } from "../components/profile/tabs/notificationSettings/types";
import { BE_THEMES } from "../constants";
import { dashboardConfigurationsProps } from "../components/home/types";
/* istanbul ignore next */
export const getUsers = async (params = ""): Promise<Response> => {
  return await http.get(`/api/users${params}`, true);
};

/* istanbul ignore next */
export const getUsersSearch = async (
  data: Record<string, unknown>
): Promise<Response> => {
  return await http.post("/api/users/search?limit=10000", data);
};

export const getUser = async (id: string): Promise<Response> => {
  return await http.get(`/api/users/${id}`);
};

export const editUser = async (id: string, values: User): Promise<Response> => {
  return await http.put(`/api/users/${id}`, values);
};

export const createUser = async (
  values: CreateUserFormInitState
): Promise<Response> => {
  return await http.post("/api/users", values);
};

export const deleteUser = async ({ id }: IdPropType): Promise<Response> => {
  return await http.delete(`/api/users/${id}`);
};

/* istanbul ignore next */
export const searchUser = async (search: string): Promise<Response> => {
  return await http.get(`/api/users?limit=10&search=${search}`, true);
};
/* istanbul ignore next */
export const postProfilePicture = async (
  id: number,
  avatarFile: FormData
): Promise<Response> => {
  return await http.post(`/api/users/${id}/avatar`, avatarFile);
};

export const deleteProfilePicture = async (id: number): Promise<Response> => {
  return await http.delete(`/api/users/${id}/avatar`);
};

export const resetPasswordByToken = async (
  values: ResetPasswordForm
): Promise<Response> => {
  return await http.post(`/api/password/reset`, values);
};
/* istanbul ignore next */
export const createPermission = async (
  permission_id: number,
  user_ids: string[]
): Promise<Response> => {
  return await http.post(`/api/users/permissions`, {
    permission_id: permission_id,
    users: user_ids,
  });
};
/* istanbul ignore next */
export const removePermission = async (
  permission_id: number,
  user_ids: string[]
): Promise<Response> => {
  return await http.delete(`/api/users/permissions`, {
    permission_id: permission_id,
    users: user_ids,
  });
};
/* istanbul ignore next */
export const getUsersByPermission = async (
  search: string,
  permissionId: number
): Promise<Response> => {
  return await http.get(
    `/api/users?limit=10&search=${search}&permission_id[]=${permissionId}`,
    true
  );
};

export const updateUserLocale = async (
  userId: number,
  locale: string
): Promise<Response> => {
  return await http.patch(`/api/users/${userId}/locale`, { locale });
};
/* istanbul ignore next */
export const sendCredentialsToUser = async (id: number): Promise<Response> => {
  return await http.post(`/api/users/${id}/send-credential`, {});
};

export const forgotPassword = async (
  data: ForgotPasswordForm
): Promise<Response> => {
  return await http.post(`/api/password/email`, data);
};

export const getPermissions = async (params: string): Promise<Response> => {
  return await http.get(`/api/permissions${params}`);
};
/* istanbul ignore next */
export const getUsersPermissions = async (
  params?: string
): Promise<Response> => {
  return await http.get(`/api/users/permissions${params}`);
};
/* istanbul ignore next */
export const getUserNotificationSettings = async (
  id: number
): Promise<Response> => {
  return await http.get(`/api/users/${id}/email-settings`);
};
/* istanbul ignore next */
export const updateUserNotificationSettings = async (
  id: number,
  data: NotificationSettingsTypes
): Promise<Response> => {
  return await http.put(`/api/users/${id}/email-settings`, data);
};
/* istanbul ignore next */
export const resetUserPassword = async (
  userId: number,
  data: ResetPasswordForm
): Promise<Response> => {
  return await http.patch(`/api/users/${userId}/password`, data);
};

export const updateTheme = async (
  userId: number,
  theme: BE_THEMES
): Promise<Response> => {
  return await http.patch(`/api/users/${userId}/theme`, { theme });
};

export const updateUserConfiguration = async (
  data: dashboardConfigurationsProps
): Promise<Response> => {
  return await http.post(`/api/users/configurations`, data);
};
