import { DEFAULT_FILE_TYPES } from "../../constants";

export const getAcceptedTypesObject = (): Record<string, string[]> =>
  [...DEFAULT_FILE_TYPES.split(","), "application/pdf"].reduce(
    (obj, type) => ({
      ...obj,
      [type]: [`.${type.split("/").pop()}`],
    }),
    {}
  );
