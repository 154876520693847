import React, { ReactElement } from "react";
import ImageGallery from "react-image-gallery";

import { ImageViewerContainer, CloseIcon } from "./styles";
import { ImageViewerProps } from "./types";

import "react-image-gallery/styles/css/image-gallery.css";

const ImageViewer = ({
  images,
  handleClose,
  startIndex,
}: ImageViewerProps): ReactElement => {
  return (
    <ImageViewerContainer data-testid="image-viewer">
      <CloseIcon
        fontSize="large"
        onClick={handleClose}
        data-testid="close-image-viewer"
      />
      <ImageGallery
        items={images}
        startIndex={startIndex}
        lazyLoad={true}
        onClick={/* istanbul ignore next */ (e) => e.stopPropagation()}
      />
    </ImageViewerContainer>
  );
};

export default ImageViewer;
