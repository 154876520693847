export const areasHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "area",
  },
  {
    id: "object_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.property",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "",
  },
];
