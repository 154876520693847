export default {
  dashboard: "Dashboard",
  properties: "Properties",
  companies: "Companies",
  transactionManager: "Transaction management",
  loi: "LOI",
  documentRelease: "Document release",
  invoices: "Invoices",
  list: "List",
  reminders: "Reminders",
  outgoingInvoices: "Outgoing invoices",
  apidatev: "API-DATEv",
  contracts: "Contracts",
  insurances: "Insurances",
  offers: "Offers",
  users: "Users",
  contacts: "Contacts",
  userManagement: "User management",
  activity: "Activity",
  companyOwnership: "Company ownership",
  hr: "HR",
  employeeOverview: "Employee overview",
  vacationRequests: "Vacation requests",
  bankAccount: "Bank account",
  fileManagement: "File management",
  inbox: "Inbox",
  meter: "Meter",
  tenantRequests: "Tenant requests",
  settings: "Settings",
  roles: "Roles",
  release: "Release",
  vacancies: "Vacancies",
  generalDocuments: "General documents",
};
