import React, { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Loader from "../Loader";
import DeleteConfirmationModal from "../../ui/table/DeleteConfirmationModal";
import { snackbarDict } from "./utils";
import FileManagementContextMenu from "./FileManagementContextMenu";
import FileManagementDialog from "./FileManagementDialog";
import FileManagementFolderContainer from "./fileManagementFolderContainer";
import FileManagementFileTree from "./FileManagementFileTree";
import Snackbar from "../../ui/Snackbar";
import FileManagementSearch from "./FileManagementSearch";
import { FileManagementFolderContainerProvider } from "./contexts/FileManagementFolderContainerContext";
import FileManagementViewButtons from "./FileManagementViewButtons";
import {
  FileManagementContext,
  FileManagementProvider,
} from "./contexts/FileManagementContext";
import TabPageWrapper from "../../ui/tabPageWrapper";
import { useParams } from "react-router-dom";
import { route } from "../../utils/url";

const FileManagement = (): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    memoedFolder,
    layout,
    setLayout,
    isFoldersLoading,
    contextMenuVisibility,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    mode,
    isSnackbarVisible,
    showErrorSnackbar,
    handleCloseSnackbar,
    handlePageContextMenu,
    handleItemRemove,
  } = useContext(FileManagementContext);

  if (isFoldersLoading) return <Loader />;

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
  ];

  return (
    <TabPageWrapper
      title={t("fileManagement.title")}
      breadcrumbs={breadcrumbs}
      wrap={!id}
    >
      <>
        <Grid container>
          <Grid item xs></Grid>
          <Grid item sm={12} xs={7} md={5} lg={6} xl={7}>
            {memoedFolder && (
              <FileManagementViewButtons
                layout={layout}
                setLayout={setLayout}
              />
            )}
          </Grid>
          <Grid item sm={12} xs={5} md={4} lg={3} xl={2}>
            <FileManagementSearch />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          onContextMenu={handlePageContextMenu}
        >
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            onContextMenu={
              /* istanbul ignore next */ (e) => {
                e.stopPropagation();
                e.preventDefault();
              }
            }
          >
            <FileManagementFileTree />
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            <FileManagementFolderContainerProvider>
              <FileManagementFolderContainer />
            </FileManagementFolderContainerProvider>
            {contextMenuVisibility && <FileManagementContextMenu />}
            <DeleteConfirmationModal
              isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
              handleConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
              onRowRemove={handleItemRemove}
            />
            <FileManagementDialog />
            <Snackbar
              message={t(
                `fileManagement.${
                  showErrorSnackbar
                    ? "fileTypeIsNotSupported"
                    : snackbarDict[mode]
                }`
              )}
              color={showErrorSnackbar ? "error" : "success"}
              open={isSnackbarVisible}
              handleClose={handleCloseSnackbar}
              data-testid="file-management-snackbar"
            />
          </Grid>
        </Grid>
      </>
    </TabPageWrapper>
  );
};

const FileManagementWithContext = (): ReactElement => {
  return (
    <FileManagementProvider>
      <FileManagement />
    </FileManagementProvider>
  );
};

export default FileManagementWithContext;
