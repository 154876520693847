import React, { useEffect, useState } from "react";
import FormTextField from "../../../../../ui/formsFields/textField";
import { useTranslation } from "react-i18next";
import { Grid, Stack, Box } from "@mui/material";
import { Form } from "formik";
import { BankFormProps } from "./types";
import { BankTitle } from "./styled";
import Button from "../../../../../ui/button/Button";
import { PermissionsUpdate } from "../../../../../constants";
import useAccessControl from "../../../../../hooks/useAccessControl";

function BankForm({
  handleSubmit,
  isSubmitting,
  status,
  values,
  rentMode,
  newRent,
  isUpdatingRentBank,
  isUpdatingLoanBank,
  isRentIbanError,
  isLoanIbanError,
  onCancel,
  newIbanChecked,
  setIsSnackbarVisible,
}: BankFormProps): React.ReactElement {
  const { t } = useTranslation();
  const { can } = useAccessControl();
  const [ibanInitial] = useState(values?.iban);

  const fetchNewIbanData = (): void => {
    newRent(values?.iban.replace(/ /g, ""), rentMode, values.contact_person);
  };

  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={6} columns={12}>
        <Grid item xs={12} sm={12}>
          <BankTitle variant="h6">
            {rentMode
              ? t("property.bank.rentAccount")
              : t("property.bank.loanAccount")}
          </BankTitle>
          <FormTextField
            required
            name="iban"
            error={
              (rentMode && isRentIbanError && values?.iban !== ibanInitial) ||
              (!rentMode && isLoanIbanError && values?.iban !== ibanInitial)
            }
            helperText={
              (rentMode && isRentIbanError && values?.iban !== ibanInitial) ||
              (!rentMode && isLoanIbanError && values?.iban !== ibanInitial)
                ? t("property.bank.ibanErrorMessage")
                : ""
            }
            label={t("property.bank.iban")}
            inputProps={{ "data-testid": "iban-input" }}
          />
          {values.iban.replace(/ /g, "").length === 22 &&
            values?.iban !== ibanInitial &&
            values.iban !== newIbanChecked && (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  testId="check-iban-submit"
                  title={t("property.bank.checkIban")}
                  size="medium"
                  type="button"
                  sx={{ mb: "1rem", mt: "-1rem" }}
                  onClick={(): void => fetchNewIbanData()}
                  disabled={
                    (rentMode && isUpdatingRentBank) ||
                    (!rentMode && isUpdatingLoanBank)
                  }
                  isLoading={
                    (rentMode && isUpdatingRentBank) ||
                    (!rentMode && isUpdatingLoanBank)
                  }
                />
              </Box>
            )}
          <FormTextField
            name="name"
            disabled={true}
            label={t("property.bank.bankName")}
          />
          <FormTextField
            name="bic"
            disabled={true}
            label={t("property.bank.bic")}
          />
          <FormTextField
            name="code"
            disabled={true}
            label={t("property.bank.accountNumber")}
          />
          <FormTextField
            name="contact_person"
            label={t("property.bank.contactPerson")}
            disabled={!can(PermissionsUpdate.PROPERTY)}
            inputProps={{ "data-testid": "contact-input" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} mt={5}>
          {can(PermissionsUpdate.PROPERTY) && (
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={0}
            >
              {onCancel && (
                <Button
                  title={t("cancel")}
                  size="large"
                  type="button"
                  onClick={onCancel}
                  variant="text"
                  color="primary"
                  sx={{ mr: 2 }}
                />
              )}
              <Button
                testId="bank-submit"
                size="large"
                title={t("save")}
                type="submit"
                color="success"
                disabled={
                  isSubmitting ||
                  (values?.iban !== ibanInitial &&
                    values.iban !== newIbanChecked)
                }
                isLoading={isSubmitting}
              />
            </Stack>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}

export default BankForm;
