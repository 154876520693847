import * as React from "react";
import { Navigate, useParams } from "react-router-dom";
import useAccessControl from "../../hooks/useAccessControl";

interface AccessType {
  roles?: string[];
  permissions?: string[];
  children: React.ReactNode;
}

/* istanbul ignore next */
function AccessGuard({
  roles,
  permissions,
  children,
}: AccessType): React.ReactElement {
  const { client } = useParams();
  const { isRole, can } = useAccessControl();
  const redirectLink = <Navigate to={`/${client}/403`} />;
  const successReturn = <React.Fragment>{children}</React.Fragment>;
  if ((roles && isRole(roles)) || (permissions && can(permissions))) {
    return successReturn;
  }
  return redirectLink;
}

export default AccessGuard;
