import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Stack } from "@mui/material";
import {
  FormMessageErrorState,
  FormMessageInItState,
} from "../../../../../constants";
import { handleUpdatePropertyManagementFormSubmit } from "./utils";
import { TabPanelItemProps } from "../../types";
import { ErrorBox } from "../generalInformation/styled";
import Loader from "../../../../Loader";
import FormWrapper from "./form/FormWrapper";
import { preparePropertyBeforeForm } from "../../utils";
import { LoadingBox } from "../../styled";
import PropertyManagementShow from "./Show";
import { ObjectDataSheetContext } from "../../ObjectDataSheetContexts";

const PropertyManagement = ({
  setDisabled,
}: TabPanelItemProps): ReactElement => {
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [isEdit, setIsEdit] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);

  const {
    useFetchProperty: {
      data: property,
      isLoading: isPropertyLoading,
      isError: isPropertyError,
      setState: setProperty,
    },
    useFetchCaretakers: { data: caretakers, error: isCaretakersError },
    useFetchCompanies: { data: companies, error: isCompanyError },
    useFetchUsers: { data: users, isError: isUsersError },
  } = useContext(ObjectDataSheetContext);

  const isSuccess =
    property && caretakers && companies && (!property.company ? true : users);
  const isError =
    isPropertyError || isCaretakersError || isCompanyError || isUsersError;

  useEffect(() => {
    const isLoadingFinished = isError || isSuccess;
    setDisabled(Boolean(!isLoadingFinished));
  }, [isError, isSuccess]);

  /* istanbul ignore next */
  const handleAfterSubmit = useCallback(async () => {
    setIsEdit(false);
  }, []);

  if (isError) {
    return <ErrorBox formMessage={FormMessageErrorState} />;
  }

  return isSuccess && !isPropertyLoading ? (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={0}
      sx={{ py: 2 }}
    >
      {isEdit ? (
        <FormWrapper
          edit={isEdit}
          formMessage={formMessage}
          initialValues={preparePropertyBeforeForm(property)}
          onSubmit={handleUpdatePropertyManagementFormSubmit({
            setFormMessage,
            afterSubmit: handleAfterSubmit,
            setProperty,
          })}
          caretakers={caretakers}
          companies={companies}
          users={users}
          showSuccess={showSuccess}
          setShowSuccess={setShowSuccess}
          onCancel={() => setIsEdit(false)}
        />
      ) : (
        <PropertyManagementShow property={property} setIsEdit={setIsEdit} />
      )}
    </Stack>
  ) : (
    <LoadingBox>
      <Loader />
    </LoadingBox>
  );
};

export default PropertyManagement;
