import React, { ReactElement } from "react";
import { ObjectLink } from "../../components/properties/list/styled";
import { EMPTY_DATA } from "../../constants";
import { route } from "../../utils/url";
import { Typography } from "@mui/material";
import { RedirectPropertyProps } from "./types";

const RedirectProperty = ({
  property,
}: RedirectPropertyProps): ReactElement => {
  return (
    <>
      {property?.id ? (
        <ObjectLink
          to={route("properties.edit", property?.id, "general")}
          key={property?.id}
        >
          <Typography noWrap>{property?.object_name}</Typography>
        </ObjectLink>
      ) : (
        EMPTY_DATA
      )}
    </>
  );
};
export default RedirectProperty;
