import React, { ChangeEvent, memo, ReactElement, useState } from "react";
import { EmployeeOverviewRowProps, HandleOptionChangeProps } from "../types";
import { composeUniqueId, initSelectedValue } from "../utils";
import { TableCell } from "./styled";
import EmployeeOverviewCell from "./employeeOverviewCell";
import { isWeekend } from "../../../utils/date";
import { Avatar } from "@mui/material";
import { ProfilePhotoContainer } from "../../users/create/styled";
/* istanbul ignore next */
const EmployeeOverviewRow = ({
  row: { id, first_name, last_name, schedules, avatar_file },
  weekRange,
  handleSuccessfulUpdateScheduleSubmission,
  options,
  employeeOptionId,
}: EmployeeOverviewRowProps): ReactElement => {
  const [selected, setSelected] = useState(initSelectedValue);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);

  const handleCloseDescriptionModal = (): void => {
    setShowDescriptionModal(false);
    setSelected(initSelectedValue);
  };

  const handleFieldServiceOption = (
    formattedDate: string,
    value?: string
  ): void => {
    value && setSelected(composeUniqueId(id, formattedDate, value));
    value && setShowDescriptionModal(true);
  };

  const handleOptionClick =
    (formattedDate: string) =>
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      if (
        event.target instanceof HTMLElement &&
        event.target.dataset.value ===
          employeeOptionId.FIELD_SERVICE?.toString()
      )
        handleFieldServiceOption(formattedDate, event.target.dataset.value);
    };

  const handleOptionChange =
    ({ formattedDate, handleChange, submitForm }: HandleOptionChangeProps) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const {
        target: { value },
      } = event;
      handleChange(event);

      if (value.toString() === employeeOptionId.FIELD_SERVICE?.toString()) {
        handleFieldServiceOption(formattedDate, value);
      } else {
        submitForm();
      }
    };

  const handleEditIconClick = (formattedDate: string) => (): void => {
    setSelected(
      composeUniqueId(
        id,
        formattedDate,
        employeeOptionId.FIELD_SERVICE?.toString()
      )
    );
    setShowDescriptionModal(true);
  };

  const isCurrentTableCellSelected = (formattedDate: string): boolean =>
    selected ==
    composeUniqueId(
      id,
      formattedDate,
      employeeOptionId.FIELD_SERVICE?.toString()
    );
  const profilePhoto = avatar_file?.url;

  return (
    <>
      <TableCell align="left" sx={{ pr: 5 }}>
        {!profilePhoto ? (
          <ProfilePhotoContainer>
            <Avatar sx={{ bgcolor: "grey", textTransform: "uppercase" }}>{`${
              first_name && first_name[0]
            }${last_name && last_name[0]}`}</Avatar>
          </ProfilePhotoContainer>
        ) : (
          <ProfilePhotoContainer>
            <Avatar src={profilePhoto} />
          </ProfilePhotoContainer>
        )}
      </TableCell>
      <TableCell>{`${first_name} ${last_name}`}</TableCell>
      {weekRange
        .filter((day) => isWeekend(day))
        .map((day, ind) => (
          <EmployeeOverviewCell
            key={`${id}-${ind}`}
            showDescriptionModal={showDescriptionModal}
            handleCloseDescriptionModal={handleCloseDescriptionModal}
            options={options}
            schedules={schedules}
            day={day}
            id={id}
            handleSuccessfulUpdateScheduleSubmission={
              handleSuccessfulUpdateScheduleSubmission
            }
            handleOptionClick={handleOptionClick}
            handleOptionChange={handleOptionChange}
            isCurrentTableCellSelected={isCurrentTableCellSelected}
            handleEditIconClick={handleEditIconClick}
            ind={ind}
            employeeOptionId={employeeOptionId}
          />
        ))}
    </>
  );
};

export default memo(EmployeeOverviewRow);
