import styled from "styled-components/macro";
import { Box, Typography } from "@mui/material";
/* istanbul ignore next */
export const NotificationBox = styled(Box)`
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  max-width: ${(props) => props.theme.spacing(100)};
  &:hover {
    background: ${(props): string => props.theme.palette.background.hover};
  }
`;
/* istanbul ignore next */
export const CreatedAt = styled(Typography)`
  font-size: 10px;
  padding: 0 10px;
  color: ${(props): string => `1px solid ${props.theme.palette.textColor}`};
`;

export const MessageText = styled(Typography)`
  font-size: 12px;
  padding: 0 10px;
  font-weight: 500;
`;
