import { NavigateFunction } from "react-router-dom";
import { FormMessageInterface } from "../../../../../../types/form";
import { FormikValues } from "formik";
import { createCreditor } from "../../../../../../api/creditor";
import { replaceMomentByTimestamp } from "../../../../../../utils/date";
import { HTTP_STATUS_CODES } from "../../../../../../types/server";
import { route } from "../../../../../../utils/url";
import { mapBackendValidationErrors } from "../../../../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../constants";
import { FECreditor } from "../../../../../../types/fe/creditor";
import { getModifiedKeys } from "../../../../../../utils/common";
import { TABS } from "../../utils";

export const createCreditorInitState: FECreditor = {
  konto: "",
  property_id: null,
  company_name: "",
  addressee_type: "",
  abbreviation: "",
  address_type: "",
  street: "",
  postal_code: "",
  location: "",
  code_correspondence_address: "",
  address_valid_from: null,
};

export type FormSubmitHandlerProps = {
  setFormMessage: React.Dispatch<React.SetStateAction<FormMessageInterface>>;
  navigate: NavigateFunction;
  submitAction?: string | null;
  id?: string;
};

export const prepareCreditorItemBeforeSubmission = (
  reqData: FECreditor
): FECreditor => {
  const newObj = { ...reqData };
  newObj.address_valid_from = newObj.address_valid_from
    ? replaceMomentByTimestamp(newObj.address_valid_from).toString()
    : null;
  return newObj;
};

export const handleCreateCreditorFormSubmit = ({
  setFormMessage,
  navigate,
  id,
}: FormSubmitHandlerProps) => {
  return async (
    values: FECreditor,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const response = await createCreditor(
      getModifiedKeys(
        createCreditorInitState,
        prepareCreditorItemBeforeSubmission(values)
      )
    );

    const json = await response.json();

    if (
      response.status === HTTP_STATUS_CODES.OK ||
      response.status === HTTP_STATUS_CODES.CREATED
    ) {
      setStatus({ success: true, errors: {} });
      navigate &&
        navigate(route("properties.invoices.apidatev.tab", id, TABS.CREDITOR));
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({ type: "error", text: SOMETHING_WENT_WRONG_ERROR });
      setSubmitting(false);
    }
  };
};
