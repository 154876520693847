import React, { ReactElement, useEffect, useState } from "react";
import { Alert, Grid, Tooltip, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PageWrapper from "../../../../ui/pageWrapper/PageWrapper";
import { route } from "../../../../utils/url";
import {
  TenantNameLabel,
  Paper,
  Image,
  LoadingContainer,
  ShowHistoryButton,
} from "./styled";
import { useFetch } from "../../../../hooks/useFetch";
import { TenantRequest } from "../../../../types/be/tenantRequest";
import { Status } from "../../../../types/be/status";
import { fetchRequestStatuses, showRequest } from "../../../../api/requests";
import Loader from "../../../Loader";
import StatusButtons, { IconColorMap, IconMap } from "../list/StatusButtons";
import { COMMENTABLE, FormMessageInItState } from "../../../../constants";
import Comments from "../../../../ui/сomments";
import useComments from "../../../../ui/сomments/useComments";
import useAccessControl from "../../../../hooks/useAccessControl";
import { formatTimestamp } from "../../../../utils/date";
import { TABS } from "../../areas/edit/types";

const RequestShow = (): ReactElement => {
  const { t } = useTranslation();
  const { id, areaId, requestId } = useParams();
  const { isRole } = useAccessControl();

  const [formMessage, setFormMessage] = useState(FormMessageInItState);

  const { data, run, isLoading, error } = useFetch<TenantRequest>();
  const { data: statuses, run: runStatuses } = useFetch<Status[]>();

  const {
    comments,
    content,
    setContent,
    totalPages,
    handleCommentSave,
    handleCommentDelete,
    handlePaginationChange,
    currentPage,
    deleting,
    loading,
  } = useComments({
    relation: COMMENTABLE.TENANT_REQUEST,
    relation_id: requestId!,
  });

  const breadcrumbs = [
    {
      to: route("properties.areas.edit.tab", id, areaId, TABS.REQUESTS),
      name: t("tenant.editTenant"),
    },
  ];

  useEffect(() => {
    run(showRequest(String(requestId)));
    runStatuses(fetchRequestStatuses());
  }, []);

  const Icon = IconMap[data?.status?.code ?? ""];

  return (
    <>
      {isLoading ? (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      ) : (
        <>
          {data ? (
            <PageWrapper title={data?.title} breadcrumbs={breadcrumbs}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={8}>
                  <Paper variant="outlined">
                    {formMessage.text ? (
                      <Alert severity={formMessage.type} sx={{ my: 2 }}>
                        {formMessage.text}
                      </Alert>
                    ) : null}
                    <Grid container spacing={6}>
                      <Grid item xs={9}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md="auto">
                            <TenantNameLabel>
                              {data?.tenant?.full_name}
                            </TenantNameLabel>
                          </Grid>
                          <Grid item xs={12} md="auto">
                            <ShowHistoryButton>
                              {formatTimestamp(
                                data?.created_at,
                                "DD.MM.yyyy HH:mm A"
                              )}
                            </ShowHistoryButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        {isRole("admin") || isRole("asset_manager") ? (
                          <StatusButtons
                            requestId={Number(requestId!)}
                            statuses={statuses}
                            selectedStatus={data?.status.id}
                            updateHandler={() =>
                              run(showRequest(String(requestId)))
                            }
                            setFormMessage={setFormMessage}
                            justifyContent="flex-end"
                          />
                        ) : (
                          <Tooltip title={data.status.name} placement="top">
                            <Button
                              color={IconColorMap[data.status.code]}
                              variant="contained"
                              size="small"
                              sx={{ minWidth: 0 }}
                            >
                              <Icon color="inherit" />
                            </Button>
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="h2" variant="h2">
                          {data?.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" variant="body1">
                          {data?.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          {data?.files?.map((file) => (
                            <Grid
                              item
                              xs={4}
                              sm={2.2}
                              md={2}
                              lg={1.7}
                              key={file.id}
                            >
                              <Image src={file.url} />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Comments
                    editorContent={content}
                    setEditorContent={setContent}
                    handleSaveComment={handleCommentSave}
                    comments={comments}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handleDeleteComment={handleCommentDelete}
                    handlePaginationChange={handlePaginationChange}
                    deleting={deleting}
                    loading={loading}
                  />
                </Grid>
              </Grid>
            </PageWrapper>
          ) : (
            <Alert severity="error" sx={{ my: 2 }}>
              {error}
            </Alert>
          )}
        </>
      )}
    </>
  );
};

export default RequestShow;
