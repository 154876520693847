import React, { ReactElement, useEffect, useState } from "react";
import useAppSelector from "../../../hooks/useAppSelector";
import {
  CircularProgress,
  Divider,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { REACT_APP_SERVER_URL } from "../../../config";
import {
  ArchiveFolderAlert,
  CheckCircleTwoToneIcon,
  CircularProgressWithLabel,
  HourglassTopTwoToneIcon,
  Label,
} from "./styled";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Archive,
  setArchives,
  setOpenedIds,
} from "../../../redux/slices/archive/archive";

export const ArchiveFolder = (): ReactElement => {
  const { t } = useTranslation();
  const dispatchStore = useAppDispatch();
  const [firstRender, setFirstRender] = useState(true);
  const { archives, openedIds } = useAppSelector((state) => state.archive);
  const { client } = useParams();

  const getPublicUrl = (archiveId: number): string =>
    `${REACT_APP_SERVER_URL}/${client}/folders/export/zip/download/${archiveId}`;

  useEffect(() => {
    archives.forEach(async ({ id, finished }) => {
      if (!finished || openedIds.includes(id) || firstRender)
        return setFirstRender(false);

      window.open(getPublicUrl(id), "_blank");
      dispatchStore(
        setOpenedIds(
          [...openedIds, id].filter(
            (item, pos, self) => self.indexOf(item) == pos
          )
        )
      );
    });
  }, [archives, openedIds]);

  const handleDownloadFolderClick = (archiveId: number) => (): void => {
    archiveId && window.location.assign(getPublicUrl(archiveId));
  };
  /* istanbul ignore next */
  const onClose = (): void => {
    dispatchStore(setArchives([]));
    dispatchStore(setOpenedIds([]));
  };
  /* istanbul ignore next */
  useEffect(() => {
    window.addEventListener("beforeunload", onClose);

    return () => window.removeEventListener("beforeunload", onClose);
  }, []);

  return (
    <Snackbar
      open={archives.length > 0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transitionDuration={{ exit: 0 }}
    >
      <div>
        <ArchiveFolderAlert
          icon={<></>}
          onClose={onClose}
          severity="success"
          sx={{ width: "100%" }}
          data-testid="archive-alert"
        >
          <Grid container spacing={4}>
            {archives.map(({ id, name, progress }: Archive, index, arr) => {
              const isFinished = progress === 100;
              return (
                <Grid item key={id} display="flex">
                  <Grid container flexDirection="column">
                    <Grid item>
                      <Grid container alignItems="center">
                        <Grid item>
                          <CircularProgressWithLabel>
                            <CircularProgress
                              variant="determinate"
                              value={progress}
                            />
                            <Label>
                              <Typography
                                variant="caption"
                                component="div"
                                color="text.secondary"
                              >{`${Math.round(progress || 0)}%`}</Typography>
                            </Label>
                          </CircularProgressWithLabel>
                        </Grid>
                        <Grid item display="flex">
                          <Typography variant="h6" sx={{ marginLeft: 8 }}>
                            {name}
                          </Typography>
                          {isFinished ? (
                            <CheckCircleTwoToneIcon color="success" />
                          ) : (
                            <HourglassTopTwoToneIcon color="disabled" />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{ cursor: "pointer" }}
                        onClick={handleDownloadFolderClick(id)}
                      >
                        {t(
                          `fileManagement.${
                            isFinished
                              ? "clickToDownloadArchive"
                              : "fileIsDownloading"
                          }`
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  {arr.length - 1 !== index && (
                    <Divider
                      sx={{ width: "0.5px", background: "#999", ml: 6, mr: 2 }}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        </ArchiveFolderAlert>
      </div>
    </Snackbar>
  );
};
