/* istanbul ignore file */
import { FormikValues } from "formik";
import { DateVInvoice } from "../../../../types/fe/invoice";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { mapBackendValidationErrors } from "../../../../utils/form";
import { editCreditor } from "../../../../api/creditor";
import {
  AssignContactInitialValues,
  HandleConnectContactProps,
  HandleCreateInvoiceProps,
} from "./types";
import {
  FOLDER_NAMES,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../constants";
import { createDateVInvoice } from "../../../../api/invoices";
import { parseNumber } from "../../../../utils/common";
import { formatDateToTimespamp } from "../../../../utils/date";
import { multiUploadFiles } from "../../../../api/files";
import { File as BEFile } from "../../../../types/be/file";
import { Snackbar } from "../../../../ui/snackbar1/useSnackbar";
import { TFunction } from "i18next";

export const VAT_INCL_OPTIONS = [0, 5, 7, 16, 19];

export const createDateVInvoiceFormInitState = (
  defaultValues?: Partial<DateVInvoice>
): DateVInvoice => ({
  invoice_number: "",
  property_id: "",
  file_id: "",
  contact_id: "",
  amount: "",
  bic: "",
  iban: "",
  due_date: null,
  total: "",
  repetition_period_id: "",
  contract_id: "",
  offer_id: "",
  apportionable_percent: "",
  comment: "",
  date: new Date(),
  is_permanent: false,
  is_debited_automatically: false,
  is_apportionable: false,
  is_confidential: false,
  is_active: false,
  currency_code: "EUR",
  file_name: "",
  dynamic_name: false,
  area_id: "",
  booking_text: "",
  datev_creditor_id: "",
  invoice_id: "",
  performance_date: null,
  repeat_day: "",
  repeat_to: null,
  repeat_from: null,
  datev_invoice_positions: [
    {
      amount_brutto: 0,
      amount_netto: "",
      booking_text: "",
      datev_soll_konto_id: "",
      vat: "",
    },
  ],
  ...defaultValues,
});

export const handleConnectContact = ({
  id,
  snackbar,
  t,
  afterSuccess,
}: HandleConnectContactProps) => {
  return async (
    values: AssignContactInitialValues,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    setStatus({
      success: true,
      errors: {},
    });
    const payload = {
      contact_id: Number(values?.contact_id),
    };
    const response = await editCreditor(id, payload);
    const result = await response.json();
    if (response?.ok) {
      setStatus({
        success: true,
        errors: {},
      });
      setSubmitting(false);
      afterSuccess();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(result?.errors),
      });
    } else {
      snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
      setSubmitting(false);
    }
  };
};

export const prepareInvoiceBeforeSubmit = (
  values: DateVInvoice,
  isEdit = false
): DateVInvoice => {
  const payload: DateVInvoice = {
    total: values?.total ? parseNumber(values?.total) : "",
    date: values?.date ? formatDateToTimespamp(new Date(values?.date)) : null,
    datev_invoice_positions: values.datev_invoice_positions.map((item) => ({
      ...item,
      amount_netto: item?.amount_netto ? parseNumber(item?.amount_netto) : "",
    })),
    property_id: Number(values?.property_id) || null,
    area_id: values?.area_id ? Number(values?.area_id) : "",
    contract_id: values?.contract_id ? Number(values?.contract_id) : "",
    offer_id: values?.offer_id ? Number(values?.offer_id) : "",
    contact_id: values?.contact_id ? Number(values?.contact_id) : "",
    datev_creditor_id: values?.datev_creditor_id
      ? Number(values?.datev_creditor_id)
      : "",
    invoice_id: values?.invoice_id ? Number(values?.invoice_id) : "",
    file_id: values?.file_id ? Number(values?.file_id) : "",
    is_apportionable: values?.is_apportionable,
    is_active: values?.is_active,
    is_confidential: values?.is_confidential,
    is_debited_automatically: values?.is_debited_automatically,
    is_permanent: values?.is_permanent,
    dynamic_name: values?.dynamic_name,
    currency_code: values?.currency_code,
    file_name: values?.file_name,
    iban: values?.iban,
    bic: values?.bic,
  };
  if (values?.is_apportionable || isEdit) {
    payload.apportionable_percent = values?.apportionable_percent;
  }

  if (values?.invoice_number || isEdit) {
    payload.invoice_number = values?.invoice_number ?? "";
  }

  if (values?.is_permanent) {
    payload.repeat_day = values?.repeat_day;
    payload.repetition_period_id = values?.repetition_period_id
      ? Number(values?.repetition_period_id)
      : "";
    (payload.repeat_from = values?.repeat_from
      ? formatDateToTimespamp(new Date(values?.repeat_from))
      : null),
      (payload.repeat_to = values?.repeat_to
        ? formatDateToTimespamp(new Date(values?.repeat_to))
        : null);
  }

  if (values?.comment || isEdit) {
    payload.comment = values?.comment ?? "";
  }

  if (values?.due_date || isEdit) {
    payload.due_date = values?.due_date
      ? formatDateToTimespamp(new Date(values?.due_date))
      : null;
  }

  if (values?.performance_date || isEdit) {
    payload.performance_date = values?.performance_date
      ? formatDateToTimespamp(new Date(values?.performance_date))
      : null;
  }

  if (values?.booking_text || isEdit) {
    payload.booking_text = values?.booking_text ?? "";
  }

  return payload;
};

export const handleCreateDateVInvoice = ({
  snackbar,
  t,
  afterSuccess,
}: HandleCreateInvoiceProps) => {
  return async (
    values: DateVInvoice,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    setStatus({
      success: true,
      errors: {},
    });
    const payload = prepareInvoiceBeforeSubmit(values);
    const response = await createDateVInvoice(payload);
    const result = await response.json();
    if (response?.ok) {
      setStatus({
        success: true,
        errors: {},
      });
      setSubmitting(false);
      afterSuccess();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(result?.errors),
      });
    } else {
      snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
    }
    setSubmitting(false);
  };
};

export const handleMultiUploadFiles = async <T extends File>(
  file: FileList | T[] | null,
  snackbar: Snackbar,
  t: TFunction<"translation", undefined, "translation">,
  id?: string
): Promise<BEFile[]> => {
  if (!file?.length) {
    snackbar.error(t("fileManagement.fileTypeIsNotSupported"));
    return [];
  }
  const data = new FormData();
  Array.isArray(file) &&
    file.forEach((el) => {
      data.append("file[]", el);
    });
  id && data.append("property_id", id);
  data.append("folder", FOLDER_NAMES.INVOICE);
  const response = await multiUploadFiles(data);
  const uploadFileJson = await response.json();
  if (response?.ok) {
    const { data: filesData } = uploadFileJson;
    return filesData;
  } else {
    snackbar.error(uploadFileJson);
    return [];
  }
};
