import { Paper as MuiPaper } from "@mui/material";
import styled from "styled-components";

export const Paper = styled(MuiPaper)`
  padding: ${(props): string => props.theme.spacing(6)};
  min-width: 400px;
  max-width: ${(props): string => props.theme.spacing(200)};
  .drop-box {
    position: static;
    height: 40vh;
    margin: ${(props): string => props.theme.spacing(0)};
    .MuiBox-root {
      margin: ${(props): string => props.theme.spacing(0)};
    }
  }
`;

export const ImageItem = styled.div`
  width: ${(props) => props.theme.spacing(25)};
  height: ${(props) => props.theme.spacing(25)};
  position: relative;
  margin-right: ${(props) => props.theme.spacing(5)};
  margin-bottom: ${(props) => props.theme.spacing(5)};

  .removeIcon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background: ${(props): string => props.theme.palette.red[400]};
  }
`;

export const ReadingImage = styled.img`
  width: ${(props) => props.theme.spacing(25)};
  height: ${(props) => props.theme.spacing(25)};
`;

export const AddImageButton = styled.label`
  width: ${(props) => props.theme.spacing(25)};
  height: ${(props) => props.theme.spacing(25)};
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: ${(props) => props.theme.spacing(5)};
  margin-bottom: ${(props) => props.theme.spacing(5)};
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Input = styled("input")({
  display: "none",
});
