import {
  Box,
  Grid,
  Menu,
  MenuItem,
  TableCell,
  Typography,
} from "@mui/material";
import React, { ReactElement, useCallback, useEffect } from "react";
import { MoreVertical, Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { deleteContact, getContacts } from "../../../api/contacts";
import useIsMounted from "../../../hooks/useIsMounted";
import { IdPropType } from "../../../types/models";
import {
  DeleteIcon,
  EditIcon,
  Input,
  Search,
  SearchIconWrapper,
} from "../../../ui/table/styled";
import Table from "../../../ui/table/Table";
import useTable from "../../../ui/table/useTable";
import { handleServerError } from "../../../utils/http";
import { route } from "../../../utils/url";
import { ContactListData } from "./types";
import { itemsListHeadCells } from "./utils";
import { EDIT_CONTACT_TABS } from "../edit/utils";
import { MoreVerticalButton } from "../../properties/list/styled";
import { AnchorDict } from "../../properties/areas/list/components/toolbar/types";
import useAccessControl from "../../../hooks/useAccessControl";
import {
  PermissionsCreate,
  PermissionsDelete,
  PermissionsUpdate,
  UserRoles,
} from "../../../constants";
import UploadManuallyDialog from "./upload";
import { StyledButton } from "../../../ui/button/styled";

const ContactsList = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { can } = useAccessControl();
  const [anchorDict, setAnchorDict] = React.useState<AnchorDict>({
    importExport: null,
  });
  const [uploadModelVisible, setUploadModalVisible] = React.useState(false);
  const importExportTab = "importExport";
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleDeleteLastPageData,
  } = useTable<ContactListData>({ loading: true });
  const { isRole } = useAccessControl();

  const handleEditClick = useCallback(
    ({ id }: IdPropType): void =>
      navigate(`edit/${id}/${EDIT_CONTACT_TABS.CONTACT_DETAILS}`),
    []
  );

  const fetchContacts = useCallback(async () => {
    setIsLoading(true);
    const res = await getContacts(queryParams);
    isMounted() && handleServerResponse(res);
  }, [queryParams, setTotal, setData, setIsLoading]);

  const handleServerResponse = async (response: Response): Promise<void> => {
    if (response.status !== 200) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const resJson = await response.json();
      setTotal(resJson.meta.total);
      setData(resJson.data);
    }
    setIsLoading(false);
  };

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteContact(rowToDelete);
    handleDeleteLastPageData(fetchContacts);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteContact,
    fetchContacts,
    handleConfirmToRemoveModalClose,
  ]);

  const onDeleteIconClick = (row: ContactListData): void => {
    !row.has_relation && openConfirmToRemoveModal(row);
  };

  useEffect(() => {
    if (isMounted()) fetchContacts();
  }, [queryParams]);

  const renderRow = (row: ContactListData): ReactElement => (
    <>
      <TableCell>
        <Typography noWrap>{row.full_name}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography noWrap>{row.company_name}</Typography>
      </TableCell>
      <TableCell align="left">{row.email}</TableCell>
      <TableCell align="left">{row.telephone}</TableCell>
      <TableCell align="left">
        <Typography noWrap>
          {row.location?.full_address ?? row.address}
        </Typography>
      </TableCell>
      <TableCell
        align="right"
        onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
          handleCellClick(e)
        }
        style={{ whiteSpace: "nowrap" }}
      >
        {can(PermissionsUpdate.CONTACT) && (
          <EditIcon
            role={"editIconRole"}
            onClick={(): void => handleEditClick(row)}
            size={18}
          />
        )}
        {can(PermissionsDelete.CONTACT) && (
          <DeleteIcon
            onClick={(): void => onDeleteIconClick(row)}
            size={20}
            className={row?.has_relation ? "disabled" : ""}
            data-testid="delete-icon"
          />
        )}
      </TableCell>
    </>
  );

  const handleClose = (): void => {
    setAnchorDict({ importExport: null });
  };

  const handleClick =
    (tab: string) => (event: React.MouseEvent<HTMLElement>) => {
      const currentTab = event.currentTarget;
      setAnchorDict((d) => ({ ...d, [tab]: currentTab }));
    };

  const importCallback = (): void => {
    setUploadModalVisible(true);
  };

  const onUploadSuccess = (): void => {
    setUploadModalVisible(false);
    importSuccessCallback();
  };

  const importSuccessCallback = (): void => {
    fetchContacts();
  };

  const TableToolbar = (
    <Grid container sx={{ pt: 5, mb: 2 }}>
      <Grid item>
        {can(PermissionsCreate.CONTACT) && (
          <StyledButton
            data-testid={"dataItems-link"}
            component={NavLink}
            to={route(`contact.create`)}
            size="small"
            color="success"
            variant="contained"
          >
            {t("createContact.createContact")}
          </StyledButton>
        )}
      </Grid>
      <Grid item xs />
      <Grid item>
        <Box display="flex">
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input placeholder={t("search")} onChange={handleTableSearch} />
          </Search>
          {isRole([UserRoles.ADMIN, UserRoles.ASSET_MANAGER]) && (
            <>
              <MoreVerticalButton
                aria-haspopup="true"
                size={"small"}
                type="button"
                color="inherit"
                variant="text"
                disableElevation
                data-testid={"upload-manually-menu"}
                onClick={handleClick(importExportTab)}
              >
                <MoreVertical />
              </MoreVerticalButton>
              <Menu
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                anchorEl={anchorDict.importExport}
                open={Boolean(anchorDict.importExport)}
                data-testid={"menu"}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={(): void => {
                    importCallback();
                    handleClose();
                  }}
                  disableRipple
                  data-testid={"upload-link"}
                >
                  {t("property.import")}
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Grid>
      <Grid item />
      <UploadManuallyDialog
        onSuccess={onUploadSuccess}
        toggleModel={setUploadModalVisible}
        visible={uploadModelVisible}
      />
    </Grid>
  );

  const isMounted = useIsMounted();

  return (
    <Table
      data={data}
      total={total}
      currentPage={currentPage}
      order={order}
      orderBy={orderBy}
      error={error}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={handleChangePage}
      onRowRemove={handleRowRemove}
      onSortChange={handleSortChange}
      onSelectAllClick={handleSelectAllClick}
      onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
      rowsPerPage={rowsPerPage}
      isLoading={isLoading}
      isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
      listHeadCells={itemsListHeadCells}
      renderRow={renderRow}
      tableToolbar={TableToolbar}
      noDataIsAvailablePlaceholder={"table.noContactsAreAvailable"}
    />
  );
};

export default ContactsList;
