import styled from "styled-components/macro";

export const FileName = styled.div`
  width: ${(props): string => props.theme.spacing(50)};

  overflow-wrap: break-word;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(3.75)};
  word-break: break-all;
`;

export const DATEvImage = styled.img`
  width: ${(props) => props.theme.spacing(5.5)};
  height: ${(props) => props.theme.spacing(5.5)};
`;
