import React, { useEffect } from "react";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import { getDashboardMain } from "../../api/dashboard";
import { Grid } from "@mui/material";
import useWidgets from "./useWidgets";
import { NODE_ENVIRONMENTS } from "../../constants";
import { getJson } from "../../utils/http";

const ResponsiveGridLayout = WidthProvider(Responsive);
import useAccessControl from "../../hooks/useAccessControl";
import { isNodeEnv } from "../../utils/env";

function Default(): React.ReactElement {
  const {
    layout,
    responsiveLayout,
    onLayoutChange,
    widgetIdToComponentMap,
    setUsersWidgetData,
    setAreaRentedWidgetData,
    setNetRentWidgetData,
    setPotentialWidgetData,
    setContactsWidgetData,
    setExpiringTenantCountWidgetData,
    setInvoicesWidgetData,
    invoicesListDialog,
    expiringTenants,
    apiDATEv,
    setPropertyCount,
    widgetsSideBar,
    handleOnBreakpointChange,
    isDashboardLock,
    insurancesListDialog,
    setInsurancesWidgetData,
    offersListDialog,
    setOffersWidgetData,
    setContractsWidgetData,
    contractsListDialog,
    openCommentsListDialog,
    requestListModal,
    bankAccountsListModal,
    setIsDashboardWidgetDataLoading,
    setIsDragging,
    isDragging,
  } = useWidgets({ localStorageKey: "dashboard" });

  const { isRole } = useAccessControl();
  const isTenant = isRole("tenant");

  useEffect(() => {
    setIsDashboardWidgetDataLoading(true);
    getDashboardMain()
      .then(getJson)
      .then(({ data }) => {
        setUsersWidgetData(data.users);
        setAreaRentedWidgetData(data.area_rented);
        setNetRentWidgetData(data.net_rent);
        setPotentialWidgetData(data.potential);
        setContactsWidgetData(data.contacts);
        setExpiringTenantCountWidgetData(data.expiring_tenant_count);
        setInvoicesWidgetData(data.invoice);
        setInsurancesWidgetData(data.insurance);
        setOffersWidgetData(data.offer);
        setContractsWidgetData(data.contract);
        setIsDashboardWidgetDataLoading(false);
        setPropertyCount(data?.properties_count);
      });
  }, []);

  /* istanbul ignore next */
  const handleBreakpointChange = (newBreakpoint: string): void =>
    handleOnBreakpointChange(newBreakpoint);

  const isDragDropResizeAvailable = (): boolean =>
    !isDashboardLock && !isNodeEnv(NODE_ENVIRONMENTS.TEST);

  /* istanbul ignore next */
  const onDrag = (): void => {
    !isDragging && setIsDragging(true);
  };

  /* istanbul ignore next */
  const onDragStop = async (): Promise<void> => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    setIsDragging(false);
  };

  return (
    <>
      {!isTenant && (
        <>
          <Grid container>
            <Grid item sx={{ pl: 2 }}>
              {widgetsSideBar()}
            </Grid>
          </Grid>
          <Grid data-testid={"grid-layout-wrapper"}>
            <ResponsiveGridLayout
              onBreakpointChange={handleBreakpointChange}
              isDraggable={isDragDropResizeAvailable()}
              isDroppable={isDragDropResizeAvailable()}
              isResizable={isDragDropResizeAvailable()}
              onLayoutChange={(layout, layouts) =>
                onLayoutChange(layout, layouts)
              }
              onDrag={onDrag}
              onDragStop={onDragStop}
              layouts={responsiveLayout}
              rowHeight={30}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            >
              {layout.map((item: Layout) => widgetIdToComponentMap[item.i])}
            </ResponsiveGridLayout>
          </Grid>
          {invoicesListDialog()}
          {expiringTenants()}
          {apiDATEv()}
          {insurancesListDialog()}
          {offersListDialog()}
          {contractsListDialog()}
          {openCommentsListDialog()}
          {requestListModal()}
          {bankAccountsListModal()}
        </>
      )}
    </>
  );
}

export default Default;
