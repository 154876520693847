import styled from "styled-components/macro";
import { Paper as MuiPaper } from "@mui/material";
/* istanbul ignore next */
export const Paper = styled(MuiPaper)`
  padding: ${(props): string => props.theme.spacing(6)};
  min-width: 400px;
  .drop-box {
    position: static;
    height: 40vh;
    min-height: 260px;
    margin: ${(props): string => props.theme.spacing(0)};
    .MuiBox-root {
      margin: ${(props): string => props.theme.spacing(0)};
    }
  }
`;
/* istanbul ignore next */
export const FileInfo = styled.div`
  padding: ${(props): string =>
    `${props.theme.spacing(4)} ${props.theme.spacing(3)}`};
  margin: ${(props): string => props.theme.spacing(4)} 0;
  background-color: ${(props): string =>
    props.theme.palette.background.default};
  border-radius: ${(props): string => props.theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .file-name {
    color: ${(props): string => props.theme.palette.textColor.default};
  }
  .remove-btn {
    color: ${(props): string => props.theme.palette.red.main};
    text-decoration: underline;
    cursor: pointer;
  }
`;
