import { AreaRenter, CreateAreaTenant } from "../../../../../../types/be/area";
import { SubmitCreateAreaTenantProps } from "./types";
import { FormikHelpers } from "formik";
import { mapBackendValidationErrors } from "../../../../../../utils/form";
import { formatToNumber } from "../../../../../../utils/common";
import { replaceMomentByTimestamp } from "../../../../../../utils/date";
import { HTTP_STATUS_CODES } from "../../../../../../types/server";
import { createAreaTenant } from "../../../../../../api/area";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../constants";

export const createTenantFormInitState: CreateAreaTenant = {
  rent_start: null,
  rent_end: null,
  net_rent_per_month: "",
  contact_id: null,
};

export const handleSubmitCreateArea = (
  property_id: number,
  area_id: number,
  {
    handleCreateTenantModalClose,
    setRow,
    setSnackbarMessage,
    t,
  }: SubmitCreateAreaTenantProps
) => {
  return async (
    values: CreateAreaTenant,
    { setStatus }: FormikHelpers<CreateAreaTenant>
  ): Promise<void | Response> => {
    const data = {
      ...values,
      property_id: property_id,
      area_id: area_id,
      rent_start: replaceMomentByTimestamp(values?.rent_start),
      rent_end: values?.rent_end
        ? replaceMomentByTimestamp(values?.rent_end)
        : null,
      net_rent_per_month: formatToNumber(values?.net_rent_per_month),
    };
    const response = await createAreaTenant(data);
    const { errors } = await response.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response?.status
      )
    ) {
      setRow((row) => ({ ...row }));
      handleCreateTenantModalClose();
      setSnackbarMessage({
        visible: true,
        text: t("property.area.tenantWasSuccessfullyCreated"),
        color: "success",
      });
      return response;
    } else if (response?.status == HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(errors),
      });
    } else {
      setSnackbarMessage({
        visible: true,
        text: t(SOMETHING_WENT_WRONG_ERROR),
        color: "error",
      });
    }
  };
};

const checkIsRentStartDateAlreadyTaken =
  (calendarDateTime: number) =>
  (item: AreaRenter): boolean => {
    const renterRentEndTime = (item.rent_end || 0) * 1000,
      renterRentStartTime = item.rent_start * 1000;
    return (
      calendarDateTime >= renterRentStartTime &&
      (renterRentEndTime ? calendarDateTime <= renterRentEndTime : true)
    );
  };

export const getRentStartDisabledDate =
  (renters: AreaRenter[]) =>
  (date: Date): boolean => {
    const calendarDateTime = date.getTime();
    return renters.some(checkIsRentStartDateAlreadyTaken(calendarDateTime));
  };

const checkIsRentStartMonthAlreadyTaken =
  (
    calendarDateMonth: number,
    calendarDateYear: number,
    calendarDateTime: number
  ) =>
  (item: AreaRenter): boolean => {
    const renterRentStartTime = item.rent_start * 1000,
      rentStartDate = new Date(renterRentStartTime),
      renterRentStartYear = rentStartDate?.getFullYear(),
      renterRentStartMonth = rentStartDate?.getMonth(),
      renterRentEndTime = (item.rent_end || 0) * 1000,
      rentEndDate = new Date(renterRentEndTime),
      renterRentEndYear = rentEndDate?.getFullYear(),
      renterRentEndMonth = rentEndDate?.getMonth();

    return (
      calendarDateTime >= renterRentStartTime &&
      calendarDateYear >= renterRentStartYear &&
      (calendarDateYear > renterRentStartYear ||
        calendarDateMonth >= renterRentStartMonth) &&
      (renterRentEndTime
        ? calendarDateTime <= renterRentEndTime &&
          (calendarDateYear < renterRentEndYear ||
            calendarDateMonth < renterRentEndMonth)
        : true)
    );
  };

export const getRentStartDisabledMonth =
  (renters: AreaRenter[]) =>
  (date: Date): boolean => {
    const calendarDateMonth = date.getMonth(),
      calendarDateYear = date.getFullYear(),
      calendarDateTime = date.getTime();

    return renters.some(
      checkIsRentStartMonthAlreadyTaken(
        calendarDateMonth,
        calendarDateYear,
        calendarDateTime
      )
    );
  };

const checkRentStartYearAlreadyTaken =
  (calendarDateYear: number, calendarDateTime: number) =>
  (item: AreaRenter): boolean => {
    const renterRentStartTime = item.rent_start * 1000,
      renterRentStartYear = new Date(renterRentStartTime)?.getFullYear(),
      renterRentEndTime = (item.rent_end || 0) * 1000,
      renterRentEndYear = new Date(renterRentEndTime)?.getFullYear();
    return (
      calendarDateTime >= renterRentStartTime &&
      calendarDateYear >= renterRentStartYear &&
      (renterRentEndTime
        ? calendarDateTime <= renterRentEndTime &&
          calendarDateYear < renterRentEndYear
        : true)
    );
  };

export const getRentStartDisabledYears =
  (renters: AreaRenter[]) =>
  (date: Date): boolean => {
    const calendarDateYear = date.getFullYear(),
      calendarDateTime = date.getTime();
    return renters.some(
      checkRentStartYearAlreadyTaken(calendarDateYear, calendarDateTime)
    );
  };

const checkRentEndDateAlreadyTaken =
  (calendarDateTime: number, rentStartTime: number) =>
  (item: AreaRenter): boolean => {
    const renterRentStartTime = item.rent_start * 1000;
    return (
      rentStartTime >= calendarDateTime ||
      (rentStartTime < renterRentStartTime &&
        calendarDateTime >= renterRentStartTime)
    );
  };

export const getRentEndDisabledDate =
  (renters: AreaRenter[], rent_start: Date) =>
  (date: Date): boolean => {
    const rentStartTime = rent_start?.getTime() + 1,
      calendarDateTime = date.getTime();
    return renters.some(
      checkRentEndDateAlreadyTaken(calendarDateTime, rentStartTime)
    );
  };

const checkRentEndMonthAlreadyTaken =
  (
    calendarDateYear: number,
    calendarDateMonth: number,
    rentStartYear: number,
    rentStartMonth: number,
    calendarDateTime: number,
    rentStartTime: number
  ) =>
  (item: AreaRenter): boolean => {
    const renterRentStartTime = item.rent_start * 1000,
      rentStartDate = new Date(renterRentStartTime),
      renterRentStartYear = rentStartDate?.getFullYear(),
      renterRentStartMonth = rentStartDate?.getMonth();

    return (
      (rentStartTime >= calendarDateTime &&
        (rentStartYear > calendarDateYear ||
          (rentStartYear === calendarDateYear &&
            rentStartMonth > calendarDateMonth))) ||
      (rentStartTime < renterRentStartTime &&
        (rentStartYear < renterRentStartYear ||
          (rentStartYear === renterRentStartYear &&
            rentStartMonth <= renterRentStartMonth)) &&
        calendarDateTime >= renterRentStartTime &&
        (calendarDateYear > renterRentStartYear ||
          (calendarDateYear === renterRentStartYear &&
            calendarDateMonth > renterRentStartMonth)))
    );
  };

export const getRentEndDisabledMonth =
  (renters: AreaRenter[], rent_start: Date) =>
  (date: Date): boolean => {
    const rentStartTime = rent_start?.getTime(),
      calendarDateTime = date.getTime(),
      rentStartYear = rent_start?.getFullYear(),
      rentStartMonth = rent_start?.getMonth(),
      calendarDateYear = date.getFullYear(),
      calendarDateMonth = date.getMonth();

    return renters.some(
      checkRentEndMonthAlreadyTaken(
        calendarDateYear,
        calendarDateMonth,
        rentStartYear,
        rentStartMonth,
        calendarDateTime,
        rentStartTime
      )
    );
  };

const checkRentEndYearAlreadyTaken =
  (
    calendarDateYear: number,
    rentStartYear: number,
    calendarDateTime: number,
    rentStartTime: number
  ) =>
  (item: AreaRenter): boolean => {
    const renterRentStartTime = item.rent_start * 1000,
      renterRentStartYear = new Date(renterRentStartTime)?.getFullYear();
    return (
      (rentStartTime >= calendarDateTime && rentStartYear > calendarDateYear) ||
      (rentStartTime < renterRentStartTime &&
        rentStartYear <= renterRentStartYear &&
        calendarDateTime >= renterRentStartTime &&
        calendarDateYear > renterRentStartYear)
    );
  };

export const getRentEndDisabledYears =
  (renters: AreaRenter[], rent_start: Date) =>
  (date: Date): boolean => {
    const rentStartTime = rent_start?.getTime(),
      calendarDateTime = date.getTime(),
      rentStartYear = rent_start?.getFullYear(),
      calendarDateYear = date.getFullYear();
    return renters.some(
      checkRentEndYearAlreadyTaken(
        calendarDateYear,
        rentStartYear,
        calendarDateTime,
        rentStartTime
      )
    );
  };
