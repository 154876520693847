import React, { ReactElement, useEffect, useState } from "react";
import { Formik } from "formik";
import Form from "./Form";
import { Stack } from "@mui/material";
import {
  ContactTypes,
  FormMessageErrorState,
  FormMessageInItState,
} from "../../../../constants";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import * as Yup from "yup";
import { FormWrapper, LoadingBox } from "../styled";
import { FormMessageInterface } from "../../../../types/form";
import { useFetch } from "../../../../hooks/useFetch";
import { getSalutations } from "../../../../api/salutations";
import { addFocus, deleteFocus } from "../../../../api/contacts";
import { handleUpdateContactFormSubmit } from "./utils";
import useValidation from "../../../../hooks/useValidation";
import Loader from "../../../Loader";
import { Salutation } from "../../../../types/be/salutation";
import { ErrorBox } from "../../../properties/objectDataSheet/tabs/generalInformation/styled";
import { TabPanelItemProps } from "../types";
import useContactTypes from "../../../../hooks/useContactTypes";
import { setContactDetailsBeforeForm } from "./utils";
import { getRankings } from "../../../../api/ranking";
import { Focus, Tag } from "../../../../types/models";
import { addTag, deleteTag } from "../../../../api/tags";

const ContactDetails = ({
  id,
  contact,
  isModal,
}: TabPanelItemProps): ReactElement => {
  const [selectedContactType, setSelectedContactType] = useState("");
  const {
    contactTypes,
    isError: isContactTypesError,
    isLoading: isContactTypesLoading,
    getContactType,
  } = useContactTypes();
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const [tags, setTags] = useState<Tag[]>([]);
  const [focuses, setFocuses] = useState<Focus[]>([]);

  const {
    data: salutations,
    isError: isSalutationsError,
    isLoading: isSalutationsLoading,
    run: runSalutations,
  } = useFetch<Salutation[]>();

  const {
    data: rankings,
    isError: isRankingError,
    isLoading: isRankingLoading,
    run: runRankings,
  } = useFetch<[]>();

  const getData = (): void => {
    runSalutations(getSalutations());
    runRankings(getRankings());
  };

  const caretakerId = getContactType(ContactTypes.CARETAKER)?.id;
  const serviceProviderId = getContactType(ContactTypes.SERVICE_PROVIDER)?.id;
  const bankId = getContactType(ContactTypes.BANK)?.id;
  const propertyManagerId = getContactType(
    ContactTypes.PROPERTY_MANAGEMENT
  )?.id;
  const otherTypeId = getContactType(ContactTypes.OTHER)?.id;

  const requiredContactTypes = [
    propertyManagerId,
    caretakerId,
    serviceProviderId,
    bankId,
    otherTypeId,
  ];

  const {
    email,
    first_name,
    last_name,
    salutation_id,
    business_phone,
    mobile,
    private_phone,
    contact_type: type_id,
    company_id,
  } = useValidation();

  const handleRemoveFocus = async (value: Focus): Promise<void> => {
    const result = focuses.filter((focus) => focus.id !== value.id);
    setFocuses(result);
    await deleteFocus(String(id), value.id);
  };
  const handleChangeFocus = async (
    event: { key: string; target: { value: string } },
    value: Focus[]
  ): Promise<void> => {
    if (event.key === "Enter" && event.target.value.trim().length > 0) {
      const isAlreadyExist = [...value.slice(0, value.length - 1)].filter(
        (val) => val.title === value[value.length - 1] + ""
      );
      if (isAlreadyExist.length === 0) {
        const res = (
          await (await addFocus(String(id), value[value.length - 1])).json()
        ).data;
        setFocuses(res);
      }
    }
  };

  const handleRemoveTag = async (value: Tag): Promise<void> => {
    const result = tags.filter((tag) => tag.id !== value.id);
    setTags(result);
    await deleteTag(value.id);
  };
  const handleChangeTag = async (
    event: { key: string; target: { value: string } },
    values: Tag[]
  ): Promise<void> => {
    if (event.key === "Enter" && event.target.value.trim().length > 0) {
      const isAlreadyExist = [...values.slice(0, values.length - 1)].filter(
        (val) => val.title === values[values.length - 1] + ""
      );
      if (isAlreadyExist.length === 0) {
        const res = (
          await (await addTag(String(id), event.target.value)).json()
        ).data;
        setTags(res);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (isSalutationsLoading || isContactTypesLoading || isRankingLoading)
    return (
      <div data-testid="loader">
        <LoadingBox>
          <Loader />
        </LoadingBox>
      </div>
    );

  if (isSalutationsError || isContactTypesError || isRankingError) {
    return <ErrorBox formMessage={FormMessageErrorState} />;
  }

  return (
    <>
      {contactTypes && salutations && rankings && contact ? (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
        >
          <FormWrapper
            className={isModal ? "fullWidth" : ""}
            data-testid={"form-wrapper"}
          >
            {formMessage.text && (
              <FormAlert
                formMessage={formMessage}
                sx={{ marginBottom: "10px" }}
              />
            )}
            <Formik
              enableReinitialize
              initialValues={setContactDetailsBeforeForm(contact)}
              validationSchema={Yup.object().shape({
                email,
                first_name,
                last_name,
                salutation_id,
                business_phone,
                mobile,
                private_phone,
                type_id,
                company_id: requiredContactTypes.includes(
                  Number(selectedContactType)
                )
                  ? company_id.required
                  : company_id.optional,
              })}
              onSubmit={handleUpdateContactFormSubmit(id!, {
                setFormMessage,
              })}
            >
              {(props): ReactElement => (
                <Form
                  {...{
                    ...props,
                    salutations,
                    contactTypes,
                    rankings,
                    focuses,
                    tags,
                    handleChangeFocus,
                    handleRemoveFocus,
                    handleChangeTag,
                    handleRemoveTag,
                    propertyManagerId,
                    setSelectedContactType,
                    isModal,
                  }}
                />
              )}
            </Formik>
          </FormWrapper>
        </Stack>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ContactDetails;
