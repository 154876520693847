import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Grid, Paper } from "@mui/material";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { Text } from "../../../../ui/formsFields/text";
import PageWrapper from "../../../../ui/pageWrapper/PageWrapper";
import { handleSubmit, MAX_FILES, prepareForm } from "./utils";
import { Add, Close } from "@mui/icons-material";
import {
  AddImageButton,
  HiddenInput,
  ImageContainer,
  ReadingImage,
} from "./styled";
import {
  DEFAULT_FILE_TYPES,
  FormMessageErrorState,
  FormMessageInItState,
} from "../../../../constants";
import { ViewableFile } from "./types";
import Button from "../../../../ui/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import { route } from "../../../../utils/url";
import { useFetch } from "../../../../hooks/useFetch";
import { TenantRequest } from "../../../../types/be/tenantRequest";
import { deleteFile, fetchRequestById } from "../../../../api/requests";
import { ErrorBox, LoadingBox } from "./styled";
import Loader from "../../../Loader";
import Snackbar from "../../../../ui/Snackbar";
import * as Yup from "yup";
import useValidation from "../../../../hooks/useValidation";

const CreateRequest = (): ReactElement => {
  const { t } = useTranslation();
  const { id, areaId, requestId } = useParams();
  const navigate = useNavigate();

  const {
    data: request,
    run: runRequest,
    isLoading: requestLoading,
    isError: requestError,
  } = useFetch<TenantRequest>();

  const { title, description } = useValidation();

  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    runRequest(fetchRequestById(requestId!));
  }, []);

  const handleClose = useCallback(() => {
    setShowSnackbar(false);
  }, []);

  const handleFileChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      files: ViewableFile[],
      setFieldValue: (field: string, value: ViewableFile[]) => void
    ) => {
      const file = e?.target?.files?.[0];

      const newFiles: ViewableFile[] = [
        ...files,
        {
          file,
          url: file && URL.createObjectURL(file),
          id: Date.now(),
        },
      ];
      setFieldValue("file", newFiles);
      ref.current!.value = "";
    },
    []
  );

  const removeFile = useCallback(
    async (
      id: number,
      files: ViewableFile[],
      setFieldValue: (field: string, value: ViewableFile[]) => void
    ) => {
      const dfile = files.filter((item) => item.id === id);
      if (!dfile[0].file) {
        await deleteFile(dfile[0].id);
      }
      const newFiles = files.filter((item) => item.id !== id);
      setFieldValue("file", newFiles);
    },
    []
  );

  if (requestLoading)
    return (
      <LoadingBox>
        <Loader />
      </LoadingBox>
    );

  if (requestError || !request)
    return <ErrorBox formMessage={FormMessageErrorState} />;

  const breadcrumbs = [
    {
      to: route("properties.areas.edit", id, areaId),
      name: t("tenant.editTenant"),
    },
  ];

  return (
    <PageWrapper
      title={t("requests.edit.editRequest")}
      breadcrumbs={breadcrumbs}
    >
      <Grid container justifyContent={"center"} spacing={2}>
        <Grid item xs={12} lg={6} xl={4}>
          <Paper sx={{ padding: 6 }}>
            <Grid item xs={12}>
              {formMessage.text ? (
                <FormAlert formMessage={formMessage} />
              ) : null}
            </Grid>
            <Formik
              initialValues={prepareForm(request)}
              validationSchema={Yup.object().shape({
                title,
                description,
              })}
              onSubmit={handleSubmit(
                setFormMessage,
                navigate,
                id!,
                areaId!,
                String(requestId),
                setShowSnackbar
              )}
              enableReinitialize
            >
              {({
                values,
                setFieldValue,
                status,
                isSubmitting,
                handleSubmit,
              }) => (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  data-testid="request-form"
                >
                  <Grid item xs={12} mb={5}>
                    <Text
                      required
                      name="title"
                      label={t("requests.title")}
                      fullWidth
                      status={status}
                    />
                  </Grid>
                  <Grid item xs={12} mb={5}>
                    <Text
                      required
                      name="description"
                      label={t("requests.description")}
                      fullWidth
                      status={status}
                      multiline
                      rows={8}
                    />
                  </Grid>
                  <Grid container spacing={1}>
                    {values.file?.map((item, index) => (
                      <Grid item xs={4} sm={3} md={3} lg={3} key={index}>
                        <ImageContainer>
                          <ReadingImage src={item.url} />
                          <Close
                            className="removeIcon"
                            color="action"
                            onClick={() =>
                              removeFile(item.id, values.file, setFieldValue)
                            }
                            data-testid="remove-icon"
                          />
                        </ImageContainer>
                      </Grid>
                    ))}
                    {values.file?.length < MAX_FILES ? (
                      <Grid item sm={6} md={4} lg={3}>
                        <AddImageButton htmlFor="file">
                          <Add fontSize="large" />
                          <HiddenInput
                            accept={DEFAULT_FILE_TYPES}
                            type="file"
                            name="file"
                            id="file"
                            ref={ref}
                            onChange={(e) =>
                              handleFileChange(e, values.file, setFieldValue)
                            }
                            data-testid="select-file"
                          />
                        </AddImageButton>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ marginTop: 2 }}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      title={t("update")}
                      type="submit"
                      color="success"
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      size="medium"
                      testId="create-request"
                    />
                  </Grid>
                </Form>
              )}
            </Formik>
            <Snackbar
              message={t("requests.edit.editdSuccess")}
              color="success"
              open={showSnackbar}
              handleClose={handleClose}
              data-testid="snackbar-request-success"
            />
          </Paper>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default CreateRequest;
