import http from "../utils/http";
import { IdPropType } from "../types/models";
import { StatusProps } from "../components/vacationRequests/approvalProcess/types";
import { CreateEditVacationRequest } from "../components/vacationRequests/vacationList/createAndEdit/types";

export const getVacationList = async (params: string): Promise<Response> => {
  return await http.get(`/api/vacation-requests${params}`);
};

export const deleteVacationRequest = async ({
  id,
}: IdPropType): Promise<Response> => {
  return await http.delete(`/api/vacation-requests/${id}`);
};

export const getVacationRequestStatuses = async (): Promise<Response> => {
  return await http.get("/api/vacation-request-statuses");
};

export const patchVacationStatus = async (
  id: number,
  values: StatusProps
): Promise<Response> => {
  return await http.patch(`/api/vacation-requests/${id}`, values);
};

export const getVacationRequest = async (id: number): Promise<Response> => {
  return await http.get(`/api/vacation-requests/${id}`);
};

export const searchUserInVacationRequest = async (
  params: string
): Promise<Response> => {
  return await http.get(`/api/vacations${params}`, true);
};

export const createVacationRequest = async (
  body: CreateEditVacationRequest
): Promise<Response> => {
  return await http.post(`/api/vacation-requests`, body);
};

export const editVacationRequest = async (
  id: number,
  values: CreateEditVacationRequest
): Promise<Response> => {
  return await http.put(`/api/vacation-requests/${id}`, values);
};

export const getCalculateVacationRemainder = async (
  params: string
): Promise<Response> => {
  return await http.get(
    `/api/vacation-requests/calculate-vacation-remainder${params}`
  );
};

export const getVacations = async (params: string): Promise<Response> => {
  return await http.get(`/api/vacations${params}`);
};

export const updateUserVacation = async (
  id: number,
  data: FormData
): Promise<Response> => {
  return await http.post(`/api/vacations/${id}`, data);
};
