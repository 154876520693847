import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Tooltip,
  Paper,
  Link,
  FormControlLabel,
  Box,
  Button,
} from "@mui/material";
import { useFetch } from "../../../../hooks/useFetch";
import {
  getInvoiceById,
  toogleInvoicePaid,
  updateInvoice,
} from "../../../../api/invoices";
import { ErrorBox } from "../../objectDataSheet/tabs/generalInformation/styled";
import {
  COMMENTABLE,
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  FormMessageErrorState,
  Measurement_Units,
  PermissionsUpdate,
  THOUSAND_SEPARATOR,
} from "../../../../constants";
import { LoadingBox } from "../../../contacts/styled";
import Loader from "../../../Loader";
import { route } from "../../../../utils/url";
import { useTranslation } from "react-i18next";
import { Invoice } from "../../../../types/be.interfaces";
import { formatTimestamp } from "../../../../utils/date";
import { IconWrapper, LabelText } from "./styled";
import { InlineBlock, IsActiveIcon, TextEllipsis } from "../../../../styled";
import { CheckCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import Comments from "../../../../ui/сomments";
import useComments from "../../../../ui/сomments/useComments";
import {
  findReleaseLevelName,
  getReleaseRoute,
  isAvailableReleaseButtonTableCell,
  RELASE_BUTTONS_AM,
  RELASE_BUTTONS_PM,
  RELEASE_BUTTONS_FR,
  RELEASE_BUTTONS_USER,
} from "../../utils";
import { NumericFormat } from "react-number-format";
import { RELEASE_LEVEL } from "../../../../utils/common";
import useAccessControl from "../../../../hooks/useAccessControl";
import {
  DOCUMENT_RELEASE_LEVEL,
  DOCUMENT_RELEASE_MODULE,
} from "../../../../types/be/releaseFlow";
import { Android12Switch } from "../../../../ui/formsFields/switch/styled";
import PaidBy from "../list/paidBy";
import PdfViewer from "../../../../ui/pdfViewer";
import { HeaderText } from "../../edit/general/styled";
import HistoryIcon from "@mui/icons-material/History";
import EditIcon from "@mui/icons-material/Edit";
import DocumentRelease from "../../components/documentRelease";
import useDocumentRelease from "../../components/documentRelease/useDocumentRelease";
import TabPageWrapper from "../../../../ui/tabPageWrapper";
import HistoryPopup from "../../components/history/HistoryPopup";
import { HISTORY } from "../../components/history/types";

function InvoiceShow(): ReactElement {
  const { t } = useTranslation();
  const { id, invoiceId } = useParams();
  const { can } = useAccessControl();
  const {
    configuration,
    isConfigurationLoading,
    handleReleaseDocument,
    handleForwardDocument,
    releasePermission,
    snackbar,
    loading: isDocumentReleaseLoading,
  } = useDocumentRelease(DOCUMENT_RELEASE_MODULE.INVOICE);
  const navigate = useNavigate();
  const [isBookedError, setIsBookedError] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const pdfContainerRef = useRef<HTMLObjectElement | null>(null);
  const [invoiceFildId, setInvoiceFileId] = useState<number | undefined>();
  const { data: invoice, isLoading, isError, run } = useFetch<Invoice>();
  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);

  const {
    comments,
    content,
    setContent,
    totalPages,
    handleCommentSave,
    handleCommentDelete,
    handlePaginationChange,
    currentPage,
    deleting,
    loading,
  } = useComments({
    relation: COMMENTABLE.INVOICE,
    relation_id: invoiceId!,
  });

  const getData = async (): Promise<void> => {
    await run(getInvoiceById(invoiceId!));
    setIsDataLoaded(true);
  };

  useEffect(() => {
    invoice && invoice?.file?.id && setInvoiceFileId(invoice?.file?.id);
    invoice && setIsBooked(invoice.is_booked);
    invoice && setIsPaid(invoice.is_paid);
  }, [invoice]);

  useEffect(() => {
    getData();
  }, [id, invoiceId]);

  const markAsBooked = async (
    invoiceId: number,
    booked: boolean
  ): Promise<void> => {
    setIsBooked(!isBooked);
    const response = await updateInvoice({
      id: invoiceId,
      is_booked: !booked,
    });
    if (response.ok) {
      setIsBookedError(false);
      await getData();
    } else {
      setIsBookedError(true);
    }
  };

  const moveToPaid = async (invoiceId: number, paid: number): Promise<void> => {
    setIsPaid(!isPaid);
    await toogleInvoicePaid(invoiceId, paid);
    await getData();
  };

  const handleEditInvoice = (): void => {
    navigate(getReleaseRoute("invoices.edit", Number(id), Number(invoiceId)));
  };

  const invoiceFile = useMemo(() => {
    return { fileId: invoiceFildId };
  }, [invoiceFildId]);

  if (isError || isBookedError) {
    return <ErrorBox formMessage={FormMessageErrorState} />;
  }

  if (!isDataLoaded && (isLoading || !invoice || isConfigurationLoading)) {
    return (
      <div data-testid="loader">
        <LoadingBox>
          <Loader />
        </LoadingBox>
      </div>
    );
  }
  /* istanbul ignore next */
  if (!invoice) {
    return <></>;
  }

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("invoices", Number(id)),
      name: t("property.navLinks.invoices"),
    },
  ];

  return (
    <TabPageWrapper title={invoice.name} breadcrumbs={breadcrumbs} wrap={!id}>
      <Grid container spacing={4}>
        <Grid item xs={12} xl={12}>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12} p={4}>
              <Grid container spacing={4}>
                <Grid item xs={12} ref={pdfContainerRef}>
                  <PdfViewer
                    file={invoiceFile}
                    containerRef={pdfContainerRef}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Paper variant="outlined" sx={{ p: 4, textAlign: "center" }}>
                    <Grid
                      container
                      justifyContent={"center"}
                      spacing={4}
                      sx={{ textAlign: "left" }}
                    >
                      <Grid item xs={12} sm={4}>
                        <HeaderText> {invoice?.number} </HeaderText>
                        <LabelText>
                          <Link
                            href={invoice?.file?.url}
                            target="_blank"
                            sx={{
                              wordBreak: "break-all",
                              fontSize: "11px",
                            }}
                          >
                            {invoice?.file?.name}
                          </Link>{" "}
                          | {formatTimestamp(invoice.date)}
                        </LabelText>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <LabelText>
                          {t("documentRelease.invoice.iban")}:{" "}
                          {invoice.iban || EMPTY_DATA}
                        </LabelText>
                        <LabelText>
                          {t("documentRelease.invoice.bic")}:{" "}
                          {invoice?.bic || EMPTY_DATA}
                        </LabelText>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: 2,
                        }}
                      >
                        <Tooltip title={t("edit")}>
                          <Button
                            variant="outlined"
                            color="info"
                            onClick={handleEditInvoice}
                            role="edit-button"
                          >
                            <EditIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title={t("tenant.history.history")}>
                          <Button
                            variant="outlined"
                            color="warning"
                            onClick={() => setIsHistoryModalVisible(true)}
                            role="history-button"
                          >
                            <HistoryIcon />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent={"center"} mt={6}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          <NumericFormat
                            thousandSeparator={THOUSAND_SEPARATOR}
                            decimalSeparator={DECIMAL_SEPARATOR}
                            decimalScale={DECIMAL_SCALE}
                            fixedDecimalScale
                            displayType={"text"}
                            value={invoice.amount}
                          />{" "}
                          {invoice.amount !== null && Measurement_Units.EURO}
                        </HeaderText>
                        <LabelText>{t("amount")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          <Link
                            href={route(
                              "contacts.edit.first-tab",
                              invoice.contact.id
                            )}
                            target="_blank"
                          >
                            {`${invoice.contact.first_name} ${invoice.contact.last_name}`}
                          </Link>
                        </HeaderText>
                        <LabelText>{t("contact")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {invoice.created_by
                            ? `${invoice.created_by?.first_name} ${invoice.created_by?.last_name}`
                            : /* istanbul ignore next */ EMPTY_DATA}
                        </HeaderText>
                        <LabelText>{t("createdBy")}</LabelText>
                      </Grid>
                    </Grid>

                    <Grid container justifyContent={"center"} mt={6}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {invoice.apportionable_percent
                            ? `${invoice.apportionable_percent} %`
                            : EMPTY_DATA}
                        </HeaderText>
                        <LabelText>
                          {t("documentRelease.invoice.apportionablePercent")}
                        </LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          <Link
                            href={route(
                              "properties.edit.main",
                              invoice.property?.id
                            )}
                            target="_blank"
                          >
                            {invoice.property?.object_name}
                          </Link>
                        </HeaderText>
                        <LabelText>
                          {t("documentRelease.invoice.show.propertyName")}
                        </LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        {invoice.contract && invoice.contract.file ? (
                          <Tooltip title={invoice.contract.file?.name}>
                            <TextEllipsis>
                              <Link
                                href={invoice?.contract.file?.url}
                                target="_blank"
                              >
                                <HeaderText>
                                  {invoice.contract?.file?.name}
                                </HeaderText>
                              </Link>
                            </TextEllipsis>
                          </Tooltip>
                        ) : (
                          <HeaderText>{EMPTY_DATA}</HeaderText>
                        )}
                        <LabelText>{t("contracts.contract")}</LabelText>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent={"center"} mt={6}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          <NumericFormat
                            thousandSeparator={THOUSAND_SEPARATOR}
                            decimalSeparator={DECIMAL_SEPARATOR}
                            decimalScale={DECIMAL_SCALE}
                            fixedDecimalScale
                            displayType={"text"}
                            value={invoice.total}
                          />{" "}
                          {invoice.total !== null && Measurement_Units.EURO}
                        </HeaderText>
                        <LabelText>{t("total")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {invoice?.area?.name || EMPTY_DATA}
                        </HeaderText>
                        <LabelText>{t("areaName")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        {invoice.offer && invoice.offer.file ? (
                          <Tooltip title={invoice.offer.file?.name}>
                            <TextEllipsis w="100%">
                              <Link
                                href={invoice?.offer.file?.url}
                                target="_blank"
                              >
                                <HeaderText>
                                  {invoice.offer?.file?.name}
                                </HeaderText>
                              </Link>
                            </TextEllipsis>
                          </Tooltip>
                        ) : (
                          <HeaderText>{EMPTY_DATA}</HeaderText>
                        )}
                        <LabelText>{t("offerFileName")}</LabelText>
                      </Grid>
                    </Grid>

                    <Grid container justifyContent={"center"} mt={6}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {" "}
                          {formatTimestamp(invoice.created_at)}
                        </HeaderText>
                        <LabelText>
                          {t("documentRelease.invoice.show.createdAt")}
                        </LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {invoice.due_date
                            ? formatTimestamp(invoice.due_date)
                            : EMPTY_DATA}
                        </HeaderText>
                        <LabelText>{t("dueDate")}</LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {invoice.release_date
                            ? formatTimestamp(invoice.release_date)
                            : EMPTY_DATA}
                        </HeaderText>
                        <LabelText>
                          {" "}
                          {t("documentRelease.invoice.releaseDate")}
                        </LabelText>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper variant="outlined" sx={{ p: 4, mt: 4 }}>
                    <Grid container spacing={4}>
                      <Grid item sm={6} xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={5} md={6}>
                            <LabelText>
                              {t("documentRelease.invoice.confidential")}
                            </LabelText>
                          </Grid>
                          <Grid item xs={5} md={6}>
                            <IconWrapper>
                              {invoice.is_confidential ? (
                                <CheckCircleOutline
                                  color="success"
                                  fontSize="medium"
                                />
                              ) : (
                                <RemoveCircleOutline
                                  color="error"
                                  fontSize="medium"
                                />
                              )}
                            </IconWrapper>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={3}>
                          <Grid item xs={5} md={6}>
                            <LabelText>
                              {t("documentRelease.invoice.apportionable")}
                            </LabelText>
                          </Grid>
                          <Grid item xs={6}>
                            <IconWrapper>
                              {invoice.is_apportionable ? (
                                <CheckCircleOutline
                                  color="success"
                                  fontSize="medium"
                                />
                              ) : (
                                <RemoveCircleOutline
                                  color="error"
                                  fontSize="medium"
                                />
                              )}
                            </IconWrapper>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={3}>
                          <Grid item xs={5} md={6}>
                            <LabelText>
                              {t("documentRelease.invoice.permanent")}
                            </LabelText>
                          </Grid>
                          <Grid item xs={6}>
                            <IconWrapper>
                              {invoice.is_permanent ? (
                                <CheckCircleOutline
                                  color="success"
                                  fontSize="medium"
                                />
                              ) : (
                                <RemoveCircleOutline
                                  color="error"
                                  fontSize="medium"
                                />
                              )}
                            </IconWrapper>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={3}>
                          <Grid item xs={5} md={6}>
                            <LabelText>
                              {t(
                                "documentRelease.invoice.debitedAutomatically"
                              )}
                            </LabelText>
                          </Grid>
                          <Grid item xs={5} md={6}>
                            <IconWrapper>
                              {invoice.is_debited_automatically ? (
                                <CheckCircleOutline
                                  color="success"
                                  fontSize="medium"
                                />
                              ) : (
                                <RemoveCircleOutline
                                  color="error"
                                  fontSize="medium"
                                />
                              )}
                            </IconWrapper>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={3}>
                          <Grid item xs={5}>
                            <LabelText>
                              {t("documentRelease.invoice.markAsPaid")}
                            </LabelText>
                          </Grid>
                          <Grid item xs={6}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Android12Switch
                                    checked={
                                      isPaid ||
                                      Boolean(invoice.is_debited_automatically)
                                    }
                                    onChange={() =>
                                      moveToPaid(
                                        invoice.id,
                                        isPaid ||
                                          Boolean(
                                            invoice.is_debited_automatically
                                          )
                                          ? 0
                                          : 1
                                      )
                                    }
                                    data-testid="toggle-invoice-paid"
                                    disabled={!can("invoice.accountant")}
                                  />
                                }
                                label={t("documentRelease.invoice.paid")}
                                sx={{ margin: 0 }}
                              />
                              {(invoice.who_paid ||
                                Boolean(invoice.is_debited_automatically)) && (
                                <PaidBy
                                  {...invoice.who_paid}
                                  align="flex-start"
                                  sx={{ pl: 3 }}
                                  when_paid={invoice.when_paid}
                                  is_debited_automatically={Boolean(
                                    invoice.is_debited_automatically
                                  )}
                                />
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={3}>
                          <Grid item xs={5}>
                            <LabelText>
                              {t("documentRelease.invoice.markAsBooked")}
                            </LabelText>
                          </Grid>
                          <Grid item xs={7}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Android12Switch
                                    checked={isBooked}
                                    onChange={() =>
                                      markAsBooked(invoice.id, isBooked)
                                    }
                                    disabled={!can("invoice.accountant")}
                                    data-testid="toggle-invoice-booked"
                                  />
                                }
                                label={
                                  isBooked
                                    ? t("documentRelease.invoice.booked")
                                    : t("documentRelease.invoice.notBooked")
                                }
                                sx={{ margin: 0 }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Grid container spacing={2} mb={4}>
                          <Grid item xs={5}>
                            <LabelText>{t("activeInvoice")}</LabelText>
                          </Grid>
                          <Grid item xs={6}>
                            <InlineBlock>
                              <IsActiveIcon
                                type={invoice.is_active ? "success" : "error"}
                              />
                            </InlineBlock>
                          </Grid>
                        </Grid>
                        {
                          /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                            RELEASE_BUTTONS_FR,
                            configuration
                          ) ? (
                            <Grid container spacing={2}>
                              <Grid item xs>
                                <LabelText>
                                  {findReleaseLevelName(
                                    configuration,
                                    RELEASE_BUTTONS_FR
                                  )}
                                </LabelText>
                              </Grid>
                              <Grid item xs>
                                <DocumentRelease
                                  id={invoice?.id}
                                  releaser={invoice?.fr}
                                  releaseStatus={invoice?.fr_status}
                                  forwardedAt={invoice?.fr_forwarded_at}
                                  forwardedBy={invoice?.fr_forwarded_by}
                                  overdue={invoice?.fr_overdue}
                                  afterSuccess={getData}
                                  handleReleaseDocument={handleReleaseDocument}
                                  handleForwardDocument={handleForwardDocument}
                                  level={RELEASE_LEVEL.FIRST_LEVEL_RELEASE}
                                  hasForwardPermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                    ](invoice?.assigned_user)
                                  }
                                  hasReleasePermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                    ]
                                  }
                                  relation={DOCUMENT_RELEASE_MODULE.INVOICE}
                                  loading={isDocumentReleaseLoading}
                                />
                              </Grid>
                            </Grid>
                          ) : null
                        }
                        {
                          /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                            RELASE_BUTTONS_PM,
                            configuration
                          ) ? (
                            <Grid container spacing={2} mt={3}>
                              <Grid item xs>
                                <LabelText>
                                  {findReleaseLevelName(
                                    configuration,
                                    RELASE_BUTTONS_PM
                                  )}
                                </LabelText>
                              </Grid>
                              <Grid item xs>
                                <DocumentRelease
                                  id={invoice?.id}
                                  releaser={invoice?.pm}
                                  releaseStatus={invoice?.pm_status}
                                  forwardedAt={invoice?.pm_forwarded_at}
                                  forwardedBy={invoice?.pm_forwarded_by}
                                  overdue={invoice?.pm_overdue}
                                  afterSuccess={getData}
                                  handleReleaseDocument={handleReleaseDocument}
                                  handleForwardDocument={handleForwardDocument}
                                  level={RELEASE_LEVEL.THIRD_LEVEL_RELEASE}
                                  hasForwardPermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                    ](invoice?.assigned_user)
                                  }
                                  hasReleasePermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                    ]
                                  }
                                  relation={DOCUMENT_RELEASE_MODULE.INVOICE}
                                  loading={isDocumentReleaseLoading}
                                />
                              </Grid>
                            </Grid>
                          ) : null
                        }
                        {
                          /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                            RELASE_BUTTONS_AM,
                            configuration
                          ) ? (
                            <Grid container spacing={2} mt={3}>
                              <Grid item xs>
                                <LabelText>
                                  {findReleaseLevelName(
                                    configuration,
                                    RELASE_BUTTONS_AM
                                  )}
                                </LabelText>
                              </Grid>
                              <Grid item xs>
                                <DocumentRelease
                                  id={invoice?.id}
                                  releaser={invoice?.am}
                                  releaseStatus={invoice?.am_status}
                                  forwardedAt={invoice?.am_forwarded_at}
                                  forwardedBy={invoice?.am_forwarded_by}
                                  overdue={invoice?.am_overdue}
                                  afterSuccess={getData}
                                  handleReleaseDocument={handleReleaseDocument}
                                  handleForwardDocument={handleForwardDocument}
                                  level={RELEASE_LEVEL.SECOND_LEVEL_RELEASE}
                                  hasForwardPermission={
                                    /* istanbul ignore next */
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                    ](invoice?.assigned_user) ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                    ]
                                  }
                                  hasReleasePermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                    ]
                                  }
                                  relation={DOCUMENT_RELEASE_MODULE.INVOICE}
                                  loading={isDocumentReleaseLoading}
                                />
                              </Grid>
                            </Grid>
                          ) : null
                        }
                        {
                          /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                            RELEASE_BUTTONS_USER,
                            configuration
                          ) ? (
                            <Grid container spacing={2} mt={3}>
                              <Grid item xs>
                                <LabelText>
                                  {findReleaseLevelName(
                                    configuration,
                                    RELEASE_BUTTONS_USER
                                  )}
                                </LabelText>
                              </Grid>
                              <Grid item xs>
                                <DocumentRelease
                                  id={invoice?.id}
                                  releaser={invoice?.au}
                                  releaseStatus={invoice?.au_status}
                                  forwardedAt={invoice?.au_forwarded_at}
                                  forwardedBy={invoice?.au_forwarded_by}
                                  overdue={invoice?.au_overdue}
                                  afterSuccess={getData}
                                  handleReleaseDocument={handleReleaseDocument}
                                  handleForwardDocument={handleForwardDocument}
                                  level={RELEASE_LEVEL.FOURTH_LEVEL_RELEASE}
                                  hasForwardPermission={
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                    ] ||
                                    releasePermission[
                                      DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                    ]
                                  }
                                  hasReleasePermission={releasePermission[
                                    DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                  ](invoice?.assigned_user)}
                                  relation={DOCUMENT_RELEASE_MODULE.INVOICE}
                                  assignedUser={invoice?.assigned_user}
                                  loading={isDocumentReleaseLoading}
                                  hasAssignUserPermission={can(
                                    PermissionsUpdate.INVOICE
                                  )}
                                />
                              </Grid>
                            </Grid>
                          ) : null
                        }
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} pt={4}>
                  <Comments
                    editorContent={content}
                    setEditorContent={setContent}
                    handleSaveComment={handleCommentSave}
                    comments={comments}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handleDeleteComment={handleCommentDelete}
                    handlePaginationChange={handlePaginationChange}
                    deleting={deleting}
                    loading={loading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isHistoryModalVisible ? (
          <HistoryPopup
            handleCloseDialog={() => setIsHistoryModalVisible(false)}
            historyId={invoiceId!}
            documentRelease={HISTORY.INVOICE}
          />
        ) : null}
        {snackbar.Snackbar}
      </Grid>
    </TabPageWrapper>
  );
}

export default InvoiceShow;
