import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GlobalSearchState {
  path: string[];
  selectedFolder: string;
  fileLastFolderId: string;
}

const initialState: GlobalSearchState = {
  path: [],
  selectedFolder: "",
  fileLastFolderId: "",
};

export const globalSearchSlice = createSlice({
  name: "globalSearch",
  initialState,
  reducers: {
    setGlobalSearchFilePath: (
      state: GlobalSearchState,
      action: PayloadAction<string[]>
    ): void => {
      state.path = action.payload;
    },
    setGlobalSearchSelectedFolder: (
      state: GlobalSearchState,
      action: PayloadAction<string>
    ): void => {
      state.selectedFolder = action.payload;
    },
    setGlobalSearchFileLastFolderId: (
      state: GlobalSearchState,
      action: PayloadAction<string>
    ): void => {
      state.fileLastFolderId = action.payload;
    },
  },
});

export const {
  setGlobalSearchFilePath,
  setGlobalSearchSelectedFolder,
  setGlobalSearchFileLastFolderId,
} = globalSearchSlice.actions;

export default globalSearchSlice.reducer;
