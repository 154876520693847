import { FormikHelpers } from "formik";
import { CreateMobileUser, SubmitInviteUserProps } from "./types";
import { HTTP_STATUS_CODES } from "../../../../../../../types/server";
import { mapBackendValidationErrors } from "../../../../../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../../../../../constants";
import { inviteTenantMobileUser } from "../../../../../../../api/tenantRequest";

/* istanbul ignore next */
export const createMobileUserInitState: CreateMobileUser = {
  first_name: "",
  last_name: "",
  email: "",
};

/* istanbul ignore next */
export const handleInviteUser = ({
  activeTenantId,
  t,
  snackbar,
  onSuccess,
}: SubmitInviteUserProps) => {
  return async (
    values: CreateMobileUser,
    { setStatus }: FormikHelpers<CreateMobileUser>
  ): Promise<void | Response> => {
    const payload = { ...values, property_area_id: activeTenantId };
    const response = await inviteTenantMobileUser(payload);
    const result = await response.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response?.status
      )
    ) {
      onSuccess();
      snackbar.success(t("tenant.inviteUserSuccessfully"));
    } else if (response?.status == HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(result.errors),
      });
    } else {
      snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
    }
  };
};
