import React, { ReactElement, useCallback } from "react";
import { Button, Grid, SvgIconTypeMap, Tooltip } from "@mui/material";
import { PendingActions, DoneAll, HourglassTop } from "@mui/icons-material";
import { StatusButtonProps } from "./types";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { changeRequestStatus } from "../../../../api/requests";
import { handleServerError } from "../../../../utils/http";

export const IconMap: Record<
  string,
  OverridableComponent<SvgIconTypeMap<unknown, "svg">> & {
    muiName: string;
  }
> = {
  pending: PendingActions,
  in_coordination: HourglassTop,
  in_progress: HourglassTop,
  completed: DoneAll,
};

export const IconColorMap: Record<string, "warning" | "primary" | "warning" | "success"> = {
  pending: "warning",
  in_coordination: "warning",
  in_progress: "primary",
  completed: "success",
};

const StatusButtons = ({
  statuses,
  selectedStatus,
  setFormMessage,
  requestId,
  updateHandler,
  justifyContent = "center",
}: StatusButtonProps): ReactElement => {
  const patchStatus = useCallback(
    async (requestId: number, statusId: number) => {
      const response = await changeRequestStatus(requestId, statusId);
      if (response.status !== 200) {
        const { errorMessage } = handleServerError(response);
        setFormMessage({ text: errorMessage, type: "error" });
      } else {
        await updateHandler();
      }
    },
    []
  );

  return (
    <Grid
      container
      spacing={1}
      display="flex"
      justifyContent={justifyContent}
      sx={{ flexWrap: "nowrap" }}
    >
      {statuses?.map((status) => {
        const Icon = IconMap[status.code];

        return (
          <Grid item key={status.id}>
            <Tooltip title={status.name} placement="top">
              <Button
                color={IconColorMap[status.code]}
                variant={
                  status.id === selectedStatus ? "contained" : "outlined"
                }
                size="small"
                sx={{ minWidth: 0 }}
                onClick={() => patchStatus(requestId, status.id)}
                data-testid={`update-status-${status.code}`}
              >
                <Icon
                  color={
                    status.id === selectedStatus
                      ? "inherit"
                      : IconColorMap[status.code]
                  }
                />
              </Button>
            </Tooltip>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default StatusButtons;
