import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormikValues } from "formik/dist/types";
import FormAlert from "../../../../../../ui/formAlert/FormAlert";
import {
  Card,
  EditableSection,
  EditableFormHeader,
  SaveButton,
  TextField,
} from "../../styled";
import head from "lodash/head";
import { Grid, MenuItem, Tooltip, Typography } from "@mui/material";
import { Type, User } from "../../../../../../types/models";
import {
  EMPTY_DATA,
  MAX_PROPERTY_IMAGES_LIMIT,
  Measurement_Units,
  SOMETHING_WENT_WRONG_ERROR,
  UserRoles,
} from "../../../../../../constants";
import Button from "../../../../../../ui/button/Button";
import ConfirmationModal from "../../../../../../ui/confirmationModal";
import { useCallbackPrompt } from "../../../../../../hooks/useCallbackPrompt";
import useAccessControl from "../../../../../../hooks/useAccessControl";
import FileUploader from "../../../../../../ui/fileUploader";
import useSnackbar from "../../../../../../ui/snackbar1/useSnackbar";
import { HTTP_STATUS_CODES } from "../../../../../../types/server";
import { updateAreaWithFormData } from "../../../../../../api/area";
import EditableField from "../../../../edit/general/EditableField";
import { EDITABLE_FIELDS_TYPE } from "../../../../edit/general/utils";
import {
  EditSectionButton,
  HeaderText,
  LabelText,
} from "../../../../edit/general/styled";
import {
  AreaCategory,
  AreaFloor,
  AreaType,
} from "../../../../../../types/be/area";
import AssetManagersAutocomplete from "../../../../objectDataSheet/autocomplete/AssetManagers";

/*istanbul ignore next*/
function AreaForm({
  values,
  handleSubmit,
  isSubmitting,
  status,
  dirty,
  formMessage,
  updateLoading,
  initialValues,
  handleBlur,
  setFieldValue,
  setConfirmationVisible,
  areaEditContext,
  handleChange,
  editMainSection,
  setEditMainSection,
  touched,
  errors,
}: FormikValues): React.ReactElement {
  const { isRole } = useAccessControl();
  const { showPrompt, confirmNavigation, cancelNavigation } =
    useCallbackPrompt(dirty);
  const { t } = useTranslation();

  const {
    areaTypes,
    areaCategories,
    assetManagers = [],
    areaFloors,
  } = areaEditContext;
  const { snackbar, Snackbar } = useSnackbar();

  const tooltipTitle =
    values.asset_managers.length === 0
      ? null
      : values.asset_managers.map(
          ({ id, first_name, last_name }: User, index: number) => (
            <Typography key={id + " " + index}>
              {first_name + " " + last_name}
            </Typography>
          )
        );

  /* istanbul ignore next */
  const isMaxImagesLimitReached = (newFilesLength: number): boolean =>
    values?.images_rented.length >= MAX_PROPERTY_IMAGES_LIMIT ||
    values?.images_rented.length + newFilesLength > MAX_PROPERTY_IMAGES_LIMIT;

  /* istanbul ignore next */
  const handleOnFileChange = async (filesToUpload: File[]): Promise<void> => {
    if (isMaxImagesLimitReached(filesToUpload.length)) {
      snackbar.error(t("property.general.fileUploadLimit"));
      return;
    }
    setFieldValue("images_rented", filesToUpload);

    const formData = new FormData();
    formData.append("_method", "PUT");
    filesToUpload.forEach((file) => {
      formData.append("images_rented[]", file);
    });

    const response = await updateAreaWithFormData(String(values?.id), formData);

    if (response?.status !== HTTP_STATUS_CODES.OK) {
      snackbar.error(t(SOMETHING_WENT_WRONG_ERROR));
    } else {
      snackbar.success(t("tenantRequest.uploaded"));
      areaEditContext?.fetchArea && areaEditContext?.fetchArea();
    }
  };

  /* istanbul ignore next */
  const handleOnFileDelete = (filesToUpload: File[]): void => {
    setFieldValue("images_rented", [...filesToUpload]);
  };

  const editableDefaultProps = {
    formikProps: {
      values,
      status,
      setFieldValue,
      handleBlur,
      handleChange,
    },
  };

  const areaTypeName = useMemo(() => {
    return areaTypes?.find(
      (type: AreaType) => String(type.id) === String(values?.type?.id)
    );
  }, [values?.type?.id, areaTypes]);

  const areaCategoriesName = useMemo(() => {
    return areaCategories?.find(
      (usage: AreaCategory) => String(usage.id) === String(values?.usage?.id)
    );
  }, [values?.usage?.id, areaCategories]);

  const areaFloorName = useMemo(() => {
    return areaFloors?.find(
      (type: AreaFloor) =>
        String(type.id) === String(values?.floor_id || values?.floor?.id)
    );
  }, [values?.floor?.id, areaFloors, values?.floor_id]);

  const AssetManagerTitle = useCallback(() => {
    if (values.asset_managers.length === 0) {
      return <>{EMPTY_DATA}</>;
    } else if (values.asset_managers.length === 1) {
      const user = head<User>(values.asset_managers);
      return <>{`${user?.first_name} ${user?.last_name}`}</>;
    } else {
      const user = head<User>(values.asset_managers);
      return (
        <>
          {user?.first_name} {user?.last_name}{" "}
          <span>
            {t("tenant.selectedMoreThenOneTenantPartOne")}{" "}
            {values.asset_managers.length - 1}{" "}
            {t("tenant.selectedMoreThenOneTenantPartTwo")}
          </span>
        </>
      );
    }
  }, [values.asset_managers, assetManagers]);

  return (
    <>
      <Card>
        {formMessage.text && (
          <FormAlert formMessage={formMessage} sx={{ mb: "10px" }} />
        )}
        <EditableSection sx={{ p: 2, mb: 4 }}>
          <EditableFormHeader sx={{ pt: 0 }}>
            {t("tenant.area")}
          </EditableFormHeader>
          <Grid container sx={{ mb: 6 }} spacing={2}>
            <Grid item sm={5} lg={5}>
              {!editMainSection ? (
                <>
                  <Tooltip placement="top" title={tooltipTitle}>
                    <HeaderText>
                      <AssetManagerTitle />
                    </HeaderText>
                  </Tooltip>
                  <LabelText>{t("property.assetManager")}</LabelText>
                </>
              ) : (
                <Grid container>
                  <Grid item xs={12} p={2}>
                    <AssetManagersAutocomplete
                      values={values}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      assetManagers={assetManagers}
                      touched={touched}
                      errors={errors}
                      size="small"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <EditableField
              {...{
                ...editableDefaultProps,
                name: "name",
                label: "property.tenants.areaDesignation",
                isEdit: editMainSection,
                type: EDITABLE_FIELDS_TYPE.TEXT,
                gridSize: 4,
                dataTestId: "area-name",
                value: values.name,
                disabled: !initialValues.is_active,
              }}
            ></EditableField>
            <Grid item sm={3}>
              {!editMainSection ? (
                <>
                  <HeaderText>{areaTypeName?.name ?? EMPTY_DATA}</HeaderText>
                  <LabelText>{t("tenant.type")}</LabelText>
                </>
              ) : (
                <Grid item p={2}>
                  <TextField
                    size="small"
                    select
                    minHeight={"50px"}
                    value={values?.type?.id ?? ""}
                    name="type.id"
                    label={t("tenant.type")}
                    status={status}
                    disabled={!initialValues.is_active}
                  >
                    {areaTypes?.map((type: Type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 6 }} spacing={2}>
            <EditableField
              {...{
                ...editableDefaultProps,
                name: "square_meters",
                label: "tenant.areaInM2",
                isEdit: editMainSection,
                type: EDITABLE_FIELDS_TYPE.FORMAT_NUMBER,
                gridSize: 5,
                dataTestId: "area",
                unit: Measurement_Units.SQUARE_METER,
                value: values.square_meters,
                disabled: !initialValues.is_active,
              }}
            ></EditableField>
            <Grid item sm={4}>
              {!editMainSection ? (
                <>
                  <HeaderText>
                    {areaCategoriesName?.name ?? EMPTY_DATA}
                  </HeaderText>
                  <LabelText>{t("property.tenants.use")}</LabelText>
                </>
              ) : (
                <Grid item p={2}>
                  <TextField
                    size="small"
                    select
                    minHeight={"50px"}
                    value={values?.usage?.id ?? ""}
                    name="usage.id"
                    label={t("property.tenants.use")}
                    status={status}
                    disabled={!initialValues.is_active}
                  >
                    {areaCategories?.map((category: Type) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
            </Grid>
            <EditableField
              {...{
                ...editableDefaultProps,
                name: "index",
                label: "property.tenants.indexing",
                isEdit: editMainSection,
                type: EDITABLE_FIELDS_TYPE.TEXT,
                gridSize: 3,
                dataTestId: "area",
                value: values.index,
                disabled: !initialValues.is_active,
              }}
            ></EditableField>
          </Grid>
          <Grid container spacing={2}>
            <EditableField
              {...{
                ...editableDefaultProps,
                name: "empty_space_eur",
                label: "tenant.emptySpaceInEuro",
                isEdit: editMainSection,
                type: EDITABLE_FIELDS_TYPE.FORMAT_NUMBER,
                gridSize: 5,
                dataTestId: "area",
                unit: Measurement_Units.EURO,
                value: values.empty_space_eur,
                disabled: initialValues.is_rented,
              }}
            ></EditableField>
            {/* <EditableField
              {...{
                ...editableDefaultProps,
                name: "last_modernization",
                label: "lastRenovated",
                isEdit: editMainSection,
                type: EDITABLE_FIELDS_TYPE.NUMBER,
                gridSize: 4,
                dataTestId: "lastModernization",
                value: values.last_modernization,
              }}
            ></EditableField> */}
            <EditableField
              {...{
                ...editableDefaultProps,
                name: "last_modernization",
                label: "lastRenovated",
                isEdit: editMainSection,
                type: EDITABLE_FIELDS_TYPE.DATE,
                gridSize: 4,
                dataTestId: "lastModernization",
                value: values?.last_modernization,
              }}
            ></EditableField>
            <Grid item sm={3}>
              {!editMainSection ? (
                <>
                  <HeaderText>{areaFloorName?.name ?? EMPTY_DATA}</HeaderText>
                  <LabelText>{t("floor")}</LabelText>
                </>
              ) : (
                <Grid item p={2}>
                  <TextField
                    size="small"
                    select
                    minHeight={"50px"}
                    value={values.floor_id ?? values?.floor?.id ?? ""}
                    name="floor_id"
                    label={t("floor")}
                    status={status}
                  >
                    {areaFloors?.map((floor: Type) => (
                      <MenuItem key={floor.id} value={floor.id}>
                        {floor.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
            </Grid>
          </Grid>
          {editMainSection ? (
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent={"flex-end"}
              gap={4}
            >
              <Button
                title={t("cancel")}
                variant="text"
                color="primary"
                size="large"
                onClick={() => setEditMainSection(!editMainSection)}
              />
              <Button
                title={t("save")}
                color="success"
                type="button"
                size="large"
                onClick={handleSubmit}
                disabled={isSubmitting}
                isLoading={isSubmitting}
              />
            </Grid>
          ) : null}
          {!editMainSection ? (
            <EditSectionButton
              onClick={() => setEditMainSection(!editMainSection)}
            ></EditSectionButton>
          ) : null}
        </EditableSection>
      </Card>
      <Card>
        <Grid container spacing={2} rowSpacing={1} p={2}>
          <Grid item xs={12}>
            <FileUploader
              accept="image"
              isDisabled={isSubmitting}
              onFileChange={handleOnFileChange}
              onFileDelete={handleOnFileDelete}
              filesInitState={values?.images_rented}
              uploadDisabled={
                isSubmitting ||
                values?.images_rented?.length >= MAX_PROPERTY_IMAGES_LIMIT
              }
              maxAllowed={MAX_PROPERTY_IMAGES_LIMIT}
              placeHolderText={t("property.imageUploadPlaceHolder")}
              uploadButtonText={t("property.uploadFiles")}
            />
          </Grid>
        </Grid>
      </Card>
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {(initialValues.is_active ||
          isRole(UserRoles.ASSET_MANAGER) ||
          isRole(UserRoles.ADMIN)) && (
          <SaveButton item xs={2}>
            <Button
              variant="text"
              title={
                initialValues.is_active
                  ? t("property.area.edit.deactivate")
                  : t("property.area.edit.activate")
              }
              type="button"
              onClick={() => {
                setConfirmationVisible(true);
              }}
              fullWidth
              color="error"
              disabled={isSubmitting || updateLoading}
              isLoading={updateLoading}
              testId="submit-surface"
              size="large"
            />
          </SaveButton>
        )}
        <ConfirmationModal
          titleText={t("property.area.edit.leaveWithoutSaving")}
          visible={showPrompt}
          confirmText={t("yes")}
          cancelText={t("no")}
          setVisible={showPrompt}
          handleCancel={cancelNavigation}
          handleConfirm={confirmNavigation}
        />
        {Snackbar}
      </Grid>
    </>
  );
}

export default AreaForm;
