import styled from "styled-components";
import { Card as MuiCard, Grid as MuiGrid } from "@mui/material";

export const FilterCard = styled(MuiCard)`
  padding: ${(props): string => props.theme.spacing(4)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InfoContainer = styled(MuiGrid)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props): string => `${props.theme.spacing(1)}`};
  flex-direction: column;
  padding: ${(props): string => `${props.theme.spacing(4)}`};
  &.row-start {
    justify-content: flex-start;
    flex-direction: row;
  }
  &.border-bottom {
    border-bottom: ${(props): string => `${props.theme.spacing(0.5)}`} solid
      ${(props): string => props.theme.border.color};
  }
  &.align-start {
    align-items: flex-start;
  }
`;

export const StatisticsCard = styled(MuiCard)`
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${(props): string => `${props.theme.spacing(2)}`}
      ${(props): string => `${props.theme.spacing(3)}`};
    border-bottom: ${(props): string => `${props.theme.spacing(0.25)}`} solid
      ${(props): string => props.theme.border.color};
    &.row-end {
      justify-content: flex-end;
    }
  }
`;
