import { FormikValues } from "formik/dist/types";
import moment from "moment";
import { mapBackendValidationErrors } from "../../../utils/form";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { createMultipleInvoices, updateInvoice } from "../../../api/invoices";
import { Invoice as FEInvoice } from "../../../types/fe.interfaces";
import { Invoice as BEInvoice } from "../../../types/be.interfaces";
import { HandleAllInvoicesFormSubmit, SnackbarMessage } from "./types";
import {
  generalPropertyOption,
  parseNumber,
  replaceEntityById,
  replaceNullOrUndefinedByEmptyString,
} from "../../../utils/common";
import {
  replaceMomentByTimestamp,
  replaceTimestampByMomentDateOrNull,
  replaceTimestampByMomentDatepicker,
} from "../../../utils/date";
import pick from "lodash/pick";
import omit from "lodash/omit";
import { getReleaseRoute } from "../utils";
import { HTTP_STATUS_CODES } from "../../../types/server";
import { createInvoiceFromInbox } from "../../../api/inbox";
import { FormSubmitHandlerProps } from "../../../types/form";
export const createInvoiceFormInitState = (
  defaultValues?: Partial<FEInvoice>
): FEInvoice => ({
  number: "",
  property_id: "",
  file_id: "",
  contact_id: "",
  amount: "",
  bic: "",
  iban: "",
  due_date: null,
  total: "",
  contract_id: "",
  offer_id: "",
  apportionable_percent: "",
  comment: "",
  date: new Date(),
  is_permanent: false,
  is_debited_automatically: false,
  is_apportionable: false,
  is_confidential: false,
  is_active: false,
  currency_code: "EUR",
  file_name: "",
  dynamic_name: false,
  repeat_from: "",
  repeat_to: "",
  repeat_day: "",
  repetition_period_id: "",
  ...defaultValues,
});

export const touchedInitState = {
  file_id: false,
  contact_id: false,
  amount: false,
  total: false,
  monthly: false,
  contract_id: false,
  offer_id: false,
  apportionable_percent: false,
  until: false,
  comment: false,
  date: false,
  is_permanent: false,
  is_debited_automatically: false,
  is_apportionable: false,
  is_confidential: false,
  is_active: false,
};
export const prepareInvoiceBeforeSubmission = (
  reqData: FEInvoice
): FEInvoice => {
  const newObj = { ...reqData };
  newObj.date = replaceMomentByTimestamp(newObj.date);
  newObj.amount = parseNumber(newObj.amount, true);
  if (!newObj.is_permanent) {
    delete newObj.total;
    delete newObj.repeat_from;
    delete newObj.repeat_to;
    delete newObj.repeat_day;
    delete newObj.repetition_period_id;
  } else {
    newObj.repeat_from = replaceMomentByTimestamp(newObj.repeat_from);
    newObj.repeat_to = replaceMomentByTimestamp(newObj.repeat_to);
    newObj.repeat_day = Number(newObj?.repeat_day);
  }
  newObj.due_date = replaceMomentByTimestamp(newObj.due_date);
  newObj.file_name = newObj.dynamic_name ? "" : newObj.file_name;
  !newObj.is_apportionable && delete newObj.apportionable_percent;
  return newObj;
};

export const prepareInvoiceBeforeForm = (data: BEInvoice): FEInvoice => ({
  ...data,
  ...replaceNullOrUndefinedByEmptyString(omit(data, ["due_date"])),
  contact: {
    ...pick({ ...replaceNullOrUndefinedByEmptyString(data.contact) }, [
      "id",
      "first_name",
      "last_name",
      "email",
      "company_name",
    ]),
  },
  ...replaceTimestampByMomentDateOrNull({
    due_date: data.due_date,
    repeat_from: data.repeat_from,
    repeat_to: data.repeat_to,
  }),
  date: replaceTimestampByMomentDatepicker(data.date),
  ...replaceEntityById({
    file_id: data.file,
    contact_id: data.contact,
    contract_id: data.contract,
    offer_id: data.offer,
    property_id: data?.property || generalPropertyOption,
    area_id: data?.area,
  }),
  is_permanent: Boolean(data.is_permanent),
  is_debited_automatically: Boolean(data.is_debited_automatically),
  is_apportionable: Boolean(data.is_apportionable),
  is_confidential: Boolean(data.is_confidential),
  is_active: Boolean(data.is_active),
  dynamic_name: false,
  file_name: data.file.name,
  offer: data.offer
    ? { ...data.offer, name: data.offer?.file?.name || "" }
    : undefined,
  repetition_period_id: data?.repetition_period?.id || "",
});
export const handleInvoiceFormSubmit = ({
  setFormMessage,
}: FormSubmitHandlerProps) => {
  return async (
    data: FEInvoice,
    { setStatus, setSubmitting, setTouched }: FormikValues
  ): Promise<void> => {
    const reqData = prepareInvoiceBeforeSubmission(data);
    const response = await updateInvoice({
      ...reqData,
      property_id:
        reqData.property_id === generalPropertyOption.id
          ? null
          : reqData.property_id,
    });
    const json = await response.json();
    if ([200, 201].includes(response.status)) {
      setStatus({
        success: true,
        errors: {},
      });
      setTouched(touchedInitState);
      setFormMessage({ type: undefined, text: "" });
    } else if (response.status === 422) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setSubmitting(false);
    }
  };
};
export const handleAllInvoicesFormSubmit = async ({
  data,
  setFormMessage,
  id,
  navigate,
  setIsSubmitting,
  inboxData,
  setSendCredentialsSnackbar,
  t,
}: HandleAllInvoicesFormSubmit): Promise<void> => {
  setIsSubmitting(true);
  const auxData = data.map((el) => {
    const auxEl = prepareInvoiceBeforeSubmission(el);
    auxEl.property_id =
      Number(el.property_id) === Number(generalPropertyOption.id)
        ? /* istanbul ignore next */ null
        : Number(el.property_id);
    auxEl.area_id = Number(el.area_id) || null;
    return auxEl;
  });
  const response = inboxData
    ? await createInvoiceFromInbox(inboxData.id, { ...auxData[0] })
    : await createMultipleInvoices({ invoice: auxData });
  if (
    [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(response.status)
  ) {
    setSendCredentialsSnackbar({
      text: t("documentRelease.invoice.invoiceSaveSuccessfully"),
      visible: true,
      color: "success",
    });
    navigate && !inboxData && navigate(getReleaseRoute("invoices", Number(id)));
    inboxData && window.history.back();
  } else {
    setFormMessage({
      type: "error",
      text: SOMETHING_WENT_WRONG_ERROR,
    });
  }
  setIsSubmitting(false);
};

export const sendCredentialsSnackbarInitState: SnackbarMessage = {
  text: "",
  visible: false,
  color: "success",
};

export const calculateDateDifference = ({
  from,
  to,
}: {
  from: Date;
  to: Date;
}): number => {
  const fromDate = moment(from);
  const toDate = moment(to);
  return toDate.diff(fromDate, "months");
};

export const calculatePeriodTotal = ({
  date,
  until,
  monthly,
  amount,
}: {
  date: Date;
  until: Date;
  monthly: number;
  amount: string;
}): number => {
  const invoiceDate = moment(date);
  const invoiceUntilDate = moment(until);
  const formattedAmount = parseNumber(amount);
  const differenceInMonths = invoiceUntilDate.diff(invoiceDate, "months");

  return (
    Math.floor(differenceInMonths / monthly) * formattedAmount + formattedAmount
  );
};
export const prepareFormKey = (file_id: number): string => `i-${file_id}`;
