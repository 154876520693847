import { TableCell } from "../../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "user",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "transactionManagement.loi.transactionManager",
  },
  {
    id: "object_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "transactionManagement.loi.objectName",
  },
  {
    id: "send_date",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "transactionManagement.loi.date",
    align: "center",
  },
  {
    id: "sent_date",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "transactionManagement.loi.sent",
    align: "center",
  },
  {
    id: "file_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "transactionManagement.loi.pdf",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];
