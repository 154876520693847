import { TableCell } from "../../../../types/table";

export const commentsItemsListHeadCells: TableCell[] = [
  {
    id: "property.object_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "comments.property",
  },
  {
    id: "user",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "comments.user",
  },
  {
    id: "body",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "comments.comment",
  },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "comments.date",
  },
  {
    id: "link",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "comments.link",
  },
  {
    id: "open",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "comments.openOrDone",
  },
];

export const usersItemsListHeadCells: TableCell[] = [
  {
    id: "property.object_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "comments.name",
  },
  {
    id: "empty",
    numeric: false,
    disablePadding: true,
    sortable: true,
    label: "",
  },
  {
    id: "user",
    numeric: false,
    disablePadding: true,
    sortable: true,
    label: "comments.count",
  },
];

export const FILTER_TYPES = [
  { value: -1, name: "comments.all" },
  {
    value: 1,
    name: "comments.openComments",
  },
  {
    value: 2,
    name: "comments.done",
  },
];
