import invoice from "./invoice/en";
import contract from "./contract/en";
import sollkonto from "./sollkonto/en";
import outgoingInvoice from "./outgoingInvoice/en";
import history from "./history/en";

export default {
  finalReleaser: "Final releaser",
  assetManager: "Asset manager",
  propertyManager: "Property manager",
  documentRelease: "Document release",
  invoice,
  contract,
  sollkonto,
  outgoingInvoice,
  documentForwardedSuccessfully: "Document forwarded successfully",
  statusUpdatedSuccessfully: "Status updated successfully",
  history,
};
