import React, { ReactElement } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import { DialogContent } from "./styled";
import { LocationsListDialogProps } from "./types";
import LocationList from "./lacationList/LocationList";
import Button from "../../../ui/button/Button";
import DialogTitle from "../../../ui/dialog/DialogTitle";

const LocatioModal = ({
  visible,
  toggleLocationListModal,
  handleSuccessfulNewLocation,
}: LocationsListDialogProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      open={visible}
      onClose={toggleLocationListModal}
      maxWidth="sm"
    >
      <DialogTitle
        title="employeeOverview.allLocations"
        onClose={toggleLocationListModal}
      />
      <DialogContent>
        <LocationList
          handleSuccessfulNewLocation={handleSuccessfulNewLocation}
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
        >
          <Grid item>
            <Button
              onClick={toggleLocationListModal}
              title={t("cancel")}
              variant={"text"}
              size="large"
              type="button"
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LocatioModal;
