import React, { ReactElement } from "react";
import styled, { withTheme, ThemeProps } from "styled-components/macro";
import { Doughnut } from "react-chartjs-2";

import { orange, red } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  Typography,
  Theme,
  Skeleton,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";

import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { useTranslation } from "react-i18next";
import { TextEllipsis } from "../../../styled";

import {
  THOUSAND_SEPARATOR,
  DECIMAL_SEPARATOR,
  DECIMAL_SCALE,
  Measurement_Units,
} from "../../../constants";
import { NumericFormat } from "react-number-format";
import {
  ContractDoughnutChartData,
  DoughnutChartListItem,
} from "../../../types/be/dashboard";
import { formatTimestamp } from "../../../utils/date";
import { TableBodyRow, TableHeadRow } from "../styled";
ChartJS.register(ArcElement, Tooltip);

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

interface DoughnutChartProps extends ThemeProps<Theme> {
  data: ContractDoughnutChartData;
  toggleInvoiceListModal: () => void;
  isLoading: boolean;
}

const DoughnutChart = ({
  theme,
  data,
  toggleInvoiceListModal,
  isLoading,
}: DoughnutChartProps): ReactElement => {
  const { t } = useTranslation();
  const {
    count,
    status: { new: newInvoices, final_released, existing_old_contracts },
    list,
  } = data;

  const doughnutData = {
    labels: ["New", "Released", "Existing old contract"],
    datasets: [
      {
        data: [newInvoices, final_released, existing_old_contracts],
        backgroundColor: [
          theme.palette.secondary.main,
          red[500],
          orange[500],
          theme.palette.success.dark,
        ],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: "80%",
  };

  const handleSeeMore = (): void => {
    toggleInvoiceListModal();
  };

  return (
    <Card mb={6} sx={{ height: "100%" }}>
      <CardHeader
        action={
          <Button
            aria-label="settings"
            size="small"
            aria-haspopup={true}
            onClick={handleSeeMore}
            color="primary"
            data-testid="contract-doughnut-icon-button"
          >
            {t("home.seeMore")}
          </Button>
        }
        title={`${t("property.navLinks.contracts")}`}
      />
      <CardContent>
        <ChartWrapper>
          {!isLoading && (
            <DoughnutInner>
              <Typography variant="h4">+{count}</Typography>
              <Typography variant="caption">{`${t(
                "property.navLinks.contracts"
              )}`}</Typography>
            </DoughnutInner>
          )}
          {isLoading ? (
            <Skeleton
              variant="circular"
              width={168}
              height={168}
              sx={{ marginX: "auto" }}
            />
          ) : (
            <Doughnut data={doughnutData} options={options} />
          )}
        </ChartWrapper>
        <Table sx={{ mt: 2 }}>
          {!isLoading && (
            <TableHead>
              <TableHeadRow>
                <TableCell>Name</TableCell>
                <TableCell>{`${t("provider")}`}</TableCell>
                <TableCell>{`${t("documentRelease.invoice.date")}`}</TableCell>
                <TableCell align="right">{`${t("amount")}`}</TableCell>
              </TableHeadRow>
            </TableHead>
          )}
          <TableBody>
            {isLoading && (
              <>
                {new Array(4).fill(0).map((_, index) => (
                  <TableBodyRow key={index}>
                    <TableCell colSpan={4} sx={{ border: "none" }}>
                      <Skeleton width="100%" height={49} />
                    </TableCell>
                  </TableBodyRow>
                ))}
              </>
            )}
            {list.map(
              (
                { id, name, provider, amount, date }: DoughnutChartListItem,
                index: number
              ) => (
                <TableBodyRow key={id} $stripe={index % 2 === 0}>
                  <TableCell>
                    <TextEllipsis w="400px" float="left">
                      {name}
                    </TextEllipsis>
                  </TableCell>
                  <TableCell>{provider}</TableCell>
                  <TableCell>{formatTimestamp(date)}</TableCell>
                  <TableCell align="right">
                    <NumericFormat
                      thousandSeparator={THOUSAND_SEPARATOR}
                      decimalSeparator={DECIMAL_SEPARATOR}
                      decimalScale={DECIMAL_SCALE}
                      fixedDecimalScale
                      displayType={"text"}
                      value={amount}
                    />{" "}
                    {Measurement_Units.EURO}
                  </TableCell>
                </TableBodyRow>
              )
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default withTheme(DoughnutChart);
