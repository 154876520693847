import React from "react";
import AuthGuard from "../../components/guards/AuthGuard";
import DashboardLayout from "../../layouts/dashboard";
import { PermissionsCreate, PermissionsRead } from "../../constants";
import AccessGuard from "../../components/guards/AccessGuard";
import GeneralDocumentList from "../../components/generalDocuments/list";
import GeneralDocumentCreate from "../../components/generalDocuments/create";
import GeneralDocumentShow from "../../components/generalDocuments/show";

export default {
  path: "/:client/general-documents",
  element: (
    <AuthGuard clientModuleName="general-documents">
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: (
        <AccessGuard permissions={[PermissionsRead.GENERAL_DOCUMENT]}>
          <GeneralDocumentList />
        </AccessGuard>
      ),
    },
    {
      path: ":status",
      element: (
        <AccessGuard permissions={[PermissionsRead.GENERAL_DOCUMENT]}>
          <GeneralDocumentList />
        </AccessGuard>
      ),
    },
    {
      path: "/:client/general-documents/create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.GENERAL_DOCUMENT]}>
          <GeneralDocumentCreate />
        </AccessGuard>
      ),
    },
    {
      path: "/:client/general-documents/:documentId/show",
      element: (
        <AccessGuard permissions={[PermissionsRead.GENERAL_DOCUMENT]}>
          <GeneralDocumentShow />
        </AccessGuard>
      ),
    },
  ],
};
