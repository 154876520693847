import styled from "styled-components/macro";
/* istanbul ignore file */
export const PageContainer = styled("div")({
  display: "flex",
  alignItems: "start",
  justifyContent: "start",
  outline: "1px solid #ccc"
});

export const PdfBox = styled("div")<{
  width: number;
  height: number;
}>`
  display: flex;
  width: ${(props): number => props.width + 10}px;
  height: ${(props): number => props.height}px;
  overflow: auto;
  background: ${(props): string => props.theme.palette.grey[400]};
  align-items:center;
  .react-pdf__Document {
    margin: auto;
    box-shadow: 0px 0px 20px ${(props): string => props.theme.palette.grey[500]};
  }
`;

export const PdfBoxLoading = styled("div")<{
  width: number;
  height: number;
}>`
  display: flex;
  width: ${(props): number => props.width}px;
  height: ${(props): number => props.height}px;
  justify-content: center;
  align-items: center;
  min-height:400px;
  border: 2px solid ${(props): string => props.theme.palette.grey[600]};
`;

export const PdfToolBar = styled("div")<{
  width: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px 2px 5px;
  width: ${(props): number => props.width}px;
  border-bottom: 2px solid ${(props): string => props.theme.palette.grey[600]};
  background: ${(props): string => props.theme.palette.grey[200]};
  color:${(props): string => props.theme.palette.grey[900]}
`;

export const NoPdfBox = styled("div")`
  overflow: auto;
  background: ${(props): string => props.theme.palette.grey[400]};
  align-items:center;
`;