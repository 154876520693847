import { FormikValues } from "formik";
import {
  FormMessageInItState,
  SOMETHING_WENT_WRONG_ERROR,
} from "../../../../../../../constants";
import { InsuranceEditTopic } from "../../../../../../../types/be.interfaces";
import { InsuranceTopicType } from "../../../../../../../types/be/insurance";
import { FormMessageInterface } from "../../../../../../../types/form";
import { editInsuranceTopic } from "../../../../../../../api/insuranceTopics";
import { replaceMomentDateByTimestamp } from "../../../../../../../utils/date";
import { mapBackendValidationErrors } from "../../../../../../../utils/form";

export interface EditInsuranceTopicTitleProps {
  setFormMessage: React.Dispatch<React.SetStateAction<FormMessageInterface>>;
  handleDialog: () => void;
  range: (Date | null)[];
  topicId: number;
  type: InsuranceTopicType;
  fetchInsuranceTopics?: () => Promise<void>;
}

export const editinsuranceTopicTitleFormInitData: InsuranceEditTopic = {
  from: null,
  to: null,
  type_id: "",
};

export const handleSubmit = ({
  setFormMessage,
  handleDialog,
  range,
  type,
  topicId,
  fetchInsuranceTopics,
}: EditInsuranceTopicTitleProps) => {
  return async (
    values: InsuranceEditTopic,
    { setSubmitting, setStatus }: FormikValues
  ): Promise<void> => {
    const [start, end] = range;

    const payload = {
      ...values,
      type_id: type?.id,
      ...replaceMomentDateByTimestamp({
        from: start,
        to: end,
      }),
    };
    const response = await editInsuranceTopic(topicId, payload);
    if (response.status === 200) {
      setFormMessage(FormMessageInItState);
      setSubmitting(false);
      handleDialog();
      fetchInsuranceTopics &&  await fetchInsuranceTopics();
    } else if (response?.status === 422) {
      const error = await response?.json();
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(error?.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setSubmitting(false);
    }
  };
};
