import http from "../utils/http";

export const getUserActivities = async (params: string): Promise<Response> => {
  return await http.get(`/api/user-activities${params}`);
};

export const getLogTypes = async (): Promise<Response> => {
  return await http.get("/api/user-activities/types");
};

export const deleteLastUserActivities = async (): Promise<Response> => {
  return await http.delete(`/api/user-activities/last`);
};
