import { TableCell } from "../../../types/table";
import { AssignedUsers, SnackbarMessage } from "../types";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "property_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertiesList.objectName",
  },
  {
    id: "provider",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "provider",
  },
  {
    id: "assinged_users",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "generalDocuments.assignedUsers",
  },
  {
    id: "comments_count",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "documentRelease.invoice.history.comment",
    align: "left",
  },
  {
    id: "file_name",
    numeric: false,
    disablePadding: true,
    sortable: true,
    label: "contracts.fileName",
  },
  {
    id: "status_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "table.status",
  },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "inbox.addedBy",
  },
  {
    id: "dateTime",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "companyOwnership.createdDate",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];

export const snackbarInitState: SnackbarMessage = {
  text: "",
  visible: false,
  color: "success",
};

export const DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm";

export const initAssignedUsersData: AssignedUsers = {
  documentId: null,
  data: [],
  propertyId: null,
  assetManagers: [],
};
