import React, {
  ReactElement,
  useEffect,
  useState,
  ReactNode,
  HTMLAttributes,
  SyntheticEvent,
} from "react";
import { FormikValues } from "formik";
import {
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  AutocompleteRenderInputParams,
  MenuItem,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DatePicker from "../../../../../../../ui/datePicker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Number from "../../../../../../../ui/formsFields/number";
import Text from "../../../../../../../ui/formsFields/text";
import Button from "../../../../../../../ui/button/Button";
import { useFetch } from "../../../../../../../hooks/useFetch";
import {
  EMPTY_DATA,
  FORMAT_NUMBER_DE,
  Measurement_Units,
  SEARCH_PARAM_MIN_CHARACTERS,
} from "../../../../../../../constants";
import TextField from "../../../../../../../ui/formsFields/textField";
import {
  getAreaMarketingTypes,
  getAreaPriceUnits,
  getAreaStates,
  getAreaStoreTypes,
  getAreaSubjectCommission,
} from "../../../../../../../api/area";
import Dropdown from "../../../../../../../ui/dropdown";
import { AutocompleteOption } from "../../../../../../../ui/dropdown/types";
import {
  PlaceDetailsResponse,
  Prediction,
} from "../../../../../../../types/be/place";
import { Dropdown as StyledDropdown } from "../../../../../../../ui/autocomplete/styled";
import Loader from "../../../../../../Loader";
import { AutocompleteListItem } from "../../../../../edit/general/styled";
import { camelCase, debounce } from "lodash";
import {
  findFiveDigitZipCode,
  prepareQueryParams,
} from "../../../../../../../utils/common";
import {
  getPlaceDetails,
  getPlacePredictions,
} from "../../../../../../../api/places";
import { getJson } from "../../../../../../../utils/http";
import {
  mapAddressComponentsToPlaceAddress,
  placeErrorText,
} from "../../../../../utils";
import { Property } from "../../../../../../../types/be/property";
import { getProperty } from "../../../../../../../api/property";
import { useParams } from "react-router-dom";
import { LANGUAGE } from "../../../../../../../constants";
import {
  EditInfoCard,
  RentedInfoContainer,
  SmallLightText,
} from "../../rentedForm/styled";
import { Edit } from "@mui/icons-material";
import NumberContainer from "../../rentedForm/conponents/NumberContainer";

/* istanbul ignore next */
const Form = ({
  values,
  status,
  errors,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  handleBlur,
  isEditInfo,
  setIsEditInfo,
  isEditDescription,
  setIsEditDescription,
  resetForm,
}: FormikValues): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [placeInputValue, setPlaceInputValue] = useState("");
  const [isPlaceValidationError, setIsPlaceValidationError] = useState(false);
  const [placeValidationErrorText, setPlaceValidationErrorText] = useState("");
  const [placePredictions, setPlacePredictions] = useState<
    readonly Prediction[]
  >([]);
  const [placeAutocompleteFieldValue, setPlaceAutocompleteFieldValue] =
    useState("");

  const {
    data: property,
    run: runProperty,
    isLoading: isLoadingProperty,
  } = useFetch<Property>();

  const handleIsOptionEqualToValue = (
    option: Prediction,
    value: Prediction
  ): boolean => option.place_id === value.place_id;

  const handleGetOptionLabel = (placePrediction: Prediction): string =>
    placePrediction.description;

  const handleFilterOptions = (options: Prediction[]): Prediction[] => options;

  const autocompletePaperComponent = ({
    children,
  }: {
    children?: ReactNode;
  }): ReactElement | null => <StyledDropdown>{children}</StyledDropdown>;

  const handleAutocompleteOnChange = (
    _: SyntheticEvent,
    value: Prediction | null
  ): void => {
    if (value) handlePlacePredictionSelect(value);
  };

  const handlePlacePredictionSelect = ({ place_id }: Prediction): void => {
    const params = prepareQueryParams("", {
      place_id,
      language: LANGUAGE.EN,
    });
    getPlaceDetails(params)
      .then(getJson)
      .then(({ data: { result } }: PlaceDetailsResponse) => {
        const { geometry } = result;
        const { address_components } = result;
        const { place_id } = result;
        const mapResult =
          mapAddressComponentsToPlaceAddress(address_components);
        setPlaceInputValue(result.formatted_address);
        setFieldValue("location", {
          full_address: result.formatted_address!,
          city: mapResult.city!,
          country_code: mapResult.country_code!,
          state_code: mapResult.state_code!,
          house_number: mapResult.house_number!,
          zip_code: mapResult.zip_code!,
          street: mapResult.street!,
          place_id: place_id!,
          lat: geometry.location.lat!,
          lng: geometry.location.lng!,
        });
      });
  };

  useEffect(() => {
    const newZipCode = findFiveDigitZipCode(placeAutocompleteFieldValue);
    newZipCode && setFieldValue("location.zip_code", newZipCode);
    newZipCode &&
      setFieldValue("location.full_address", placeAutocompleteFieldValue);
  }, [placeAutocompleteFieldValue]);

  const handleRenderInput = (
    params: AutocompleteRenderInputParams
  ): ReactNode => (
    <TextField
      {...params}
      label={t("form.propertyLocation")}
      onChange={(e) => {
        setPlaceInputValue(e.target.value);
        onLocationSearchKeyDown(e.target.value);
        setPlaceAutocompleteFieldValue(e.target.value);
      }}
      placeholder={t("google.place.autocomplete.placeholder")}
      value={values?.location?.place_id}
      onBlur={handleBlur}
      error={isPlaceValidationError}
      helperText={isPlaceValidationError && placeValidationErrorText}
      status={status}
      sx={{ minHeight: "auto" }}
    />
  );

  const onLocationSearchKeyDown = React.useCallback(
    debounce(async (search: string): Promise<void> => {
      if (search.length < SEARCH_PARAM_MIN_CHARACTERS) setPlacePredictions([]);
      else {
        setLoading(true);
        const params = prepareQueryParams("", {
          input: search,
        });
        getPlacePredictions(params)
          .then(getJson)
          .then(({ data: { predictions } }) => {
            setPlacePredictions(predictions);
            setLoading(false);
          })
          .catch(() => {
            setPlacePredictions([]);
          });
      }
    }, 300),
    []
  );

  const handleRenderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    placePrediction: Prediction
  ): ReactNode => (
    <AutocompleteListItem {...props} key={placePrediction.description}>
      - {placePrediction.description}
    </AutocompleteListItem>
  );

  const handleChangeStoreType = (
    _?: unknown,
    option?: AutocompleteOption | null
  ): void => {
    setFieldValue("store_type", option);
  };

  const handleChangeState = (
    _?: unknown,
    option?: AutocompleteOption | null
  ): void => {
    setFieldValue("state", option);
  };

  const handleChangeMarketingType = (
    _?: unknown,
    option?: AutocompleteOption | null
  ): void => {
    setFieldValue("marketing_type", option);
  };

  const handleChangePriceUnit = (
    _?: unknown,
    option?: AutocompleteOption | null
  ): void => {
    setFieldValue("price_unit", option);
  };

  const handleChangeSubjectCommission = (
    _?: unknown,
    option?: AutocompleteOption | null
  ): void => {
    setFieldValue("subject_commission", option);
  };

  useEffect(() => {
    runProperty(getProperty(id));
    setPlaceInputValue(values?.location?.full_address ?? "");
  }, []);

  useEffect(() => {
    const str = placeErrorText(errors.location!);
    setIsPlaceValidationError(!!str.length);
    setPlaceValidationErrorText(
      t(`google.place.autocomplete.${camelCase(str)}`)
    );
  }, [errors.location]);

  useEffect(() => {
    const str = placeErrorText(errors.location!);
    setIsPlaceValidationError(!!str.length);
    setPlaceValidationErrorText(
      t(`google.place.autocomplete.${camelCase(str)}`)
    );
  }, [errors]);

  if (isLoadingProperty || !property?.asset_managers) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
      <Grid container spacing={7.5} sx={{ py: 2 }}>
        <Grid item sm={12}>
          <EditInfoCard className="edit-card">
            {isEditInfo ? (
              <Grid container>
                <RentedInfoContainer item sm={4}>
                  <Text
                    size="medium"
                    label={t("property.emptyAreaForm.exposeHeadline")}
                    placeholder={t("property.emptyAreaForm.exposeHeadline")}
                    name="headline"
                    status={status}
                    value={values?.headline || ""}
                    required
                    fullWidth
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item xs={4}>
                  <Autocomplete
                    fullWidth
                    loading={loading}
                    size="medium"
                    loadingText={`${t("loading")}...`}
                    inputValue={placeInputValue ?? ""}
                    popupIcon={
                      loading ? <Loader size={25} /> : <ArrowDropDownIcon />
                    }
                    options={placePredictions!}
                    isOptionEqualToValue={handleIsOptionEqualToValue}
                    getOptionLabel={handleGetOptionLabel}
                    filterOptions={handleFilterOptions}
                    onChange={handleAutocompleteOnChange}
                    renderOption={handleRenderOption}
                    PaperComponent={autocompletePaperComponent}
                    renderInput={handleRenderInput}
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="is_publish_property_address"
                          onChange={(event) => {
                            setFieldValue(
                              "is_publish_property_address",
                              event.target.checked
                            );
                          }}
                          value={Boolean(values?.is_publish_property_address)}
                          checked={Boolean(values?.is_publish_property_address)}
                          data-testid="is_publish_property_address"
                        />
                      }
                      label={t("property.emptyAreaForm.publishPropertyAddress")}
                    />
                  </FormControl>
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4}>
                  <TextField
                    name="contact_asset_manager_id"
                    select
                    label={t("property.emptyAreaForm.contactPerson")}
                    status={status}
                    size="medium"
                    sx={{ minHeight: "auto" }}
                    fullWidth
                  >
                    {property?.asset_managers?.map((item) => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {`${item.first_name} ${item.last_name}`}
                      </MenuItem>
                    ))}
                  </TextField>
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4}>
                  <Dropdown
                    size="medium"
                    label={t("property.emptyAreaForm.storeType")}
                    placeholder={t("property.emptyAreaForm.storeType")}
                    getOptions={getAreaStoreTypes}
                    name="store_type"
                    value={values?.store_type}
                    defaultValue={values?.store_type}
                    onSelectOption={handleChangeStoreType}
                    id={"store_type"}
                    fullWidth
                    error={Boolean(
                      status?.errors?.store_type || errors?.store_type
                    )}
                    helperText={
                      status?.errors?.store_type || errors?.store_type
                    }
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4}>
                  <TextField
                    size="medium"
                    label={t("property.emptyAreaForm.deposit")}
                    name="deposit"
                    status={status}
                    value={values?.deposit || ""}
                    fullWidth
                    inputProps={{
                      filter: FORMAT_NUMBER_DE,
                      unit: Measurement_Units.EURO,
                    }}
                    sx={{ minHeight: "auto" }}
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4}>
                  <Dropdown
                    size="medium"
                    label={t("property.emptyAreaForm.propertyState")}
                    placeholder={t("property.emptyAreaForm.propertyState")}
                    getOptions={getAreaStates}
                    name="state"
                    value={values?.state}
                    defaultValue={values?.state}
                    onSelectOption={handleChangeState}
                    fullWidth
                    id={"state"}
                    error={Boolean(status?.errors?.state || errors?.state)}
                    helperText={status?.errors?.state || errors?.state}
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4}>
                  <DatePicker
                    openTo="year"
                    views={["year"]}
                    name="built"
                    inputFormat="yyyy"
                    label={t("property.emptyAreaForm.constructionYear")}
                    status={status}
                    size="medium"
                    disableFuture
                    fullWidth
                    value={
                      values?.built ? new Date(String(values?.built)) : null
                    }
                    minHeight={"50px"}
                    sx={{ minHeight: "auto" }}
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4}>
                  <Number
                    size="medium"
                    label={t("property.emptyAreaForm.numberOfParkingLots")}
                    name="parking_lots"
                    fullWidth
                    status={status}
                    value={values?.parking_lots}
                    type="number"
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4}>
                  <DatePicker
                    openTo="year"
                    views={["year"]}
                    inputFormat="yyyy"
                    name="last_modernization"
                    label={t("property.emptyAreaForm.lastModernization")}
                    status={status}
                    fullWidth
                    size="medium"
                    disableFuture
                    value={
                      values?.last_modernization
                        ? new Date(String(values?.last_modernization))
                        : null
                    }
                    minHeight={"50px"}
                    sx={{ minHeight: "auto" }}
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4}>
                  <Dropdown
                    size="medium"
                    label={t("property.emptyAreaForm.marketingType")}
                    placeholder={t("property.emptyAreaForm.marketingType")}
                    getOptions={getAreaMarketingTypes}
                    name="marketing_type"
                    value={values?.marketing_type}
                    defaultValue={values?.marketing_type}
                    onSelectOption={handleChangeMarketingType}
                    id={"marketing_type"}
                    fullWidth
                    error={Boolean(
                      status?.errors?.marketing_type || errors?.marketing_type
                    )}
                    helperText={
                      status?.errors?.marketing_type || errors?.marketing_type
                    }
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4}>
                  <TextField
                    size="medium"
                    label={t("property.emptyAreaForm.price")}
                    name="price"
                    status={status}
                    value={values?.price || ""}
                    fullWidth
                    inputProps={{
                      filter: FORMAT_NUMBER_DE,
                      unit: Measurement_Units.EURO,
                    }}
                    sx={{ minHeight: "auto" }}
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4}>
                  <Dropdown
                    size="medium"
                    label={t(
                      "property.emptyAreaForm.validPriceForAreaOrPeriod"
                    )}
                    placeholder={t(
                      "property.emptyAreaForm.validPriceForAreaOrPeriod"
                    )}
                    getOptions={getAreaPriceUnits}
                    name="price_unit"
                    value={values?.price_unit}
                    defaultValue={values?.price_unit}
                    onSelectOption={handleChangePriceUnit}
                    fullWidth
                    id={"price_unit"}
                    error={Boolean(
                      status?.errors?.price_unit || errors?.price_unit
                    )}
                    helperText={
                      status?.errors?.price_unit || errors?.price_unit
                    }
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4}>
                  <TextField
                    size="medium"
                    label={t("property.emptyAreaForm.salesArea")}
                    name="square_meters"
                    status={status}
                    value={values?.square_meters}
                    fullWidth
                    inputProps={{
                      filter: FORMAT_NUMBER_DE,
                      unit: Measurement_Units.EURO,
                    }}
                    sx={{ minHeight: "auto" }}
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4}>
                  <TextField
                    size="medium"
                    label={t("property.emptyAreaForm.totalArea")}
                    name="sales_square_meters"
                    status={status}
                    fullWidth
                    value={values?.sales_square_meters}
                    inputProps={{
                      filter: FORMAT_NUMBER_DE,
                      unit: Measurement_Units.EURO,
                    }}
                    sx={{ minHeight: "auto" }}
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={4}>
                  <Dropdown
                    size="medium"
                    label={t("property.emptyAreaForm.subjectToCommission")}
                    placeholder={t(
                      "property.emptyAreaForm.subjectToCommission"
                    )}
                    getOptions={getAreaSubjectCommission}
                    name="subject_commission"
                    value={values?.subject_commission}
                    defaultValue={values?.subject_commission}
                    onSelectOption={handleChangeSubjectCommission}
                    id={"subject_commission"}
                    fullWidth
                    error={Boolean(
                      status?.errors?.subject_commission ||
                        errors?.subject_commission
                    )}
                    helperText={
                      status?.errors?.subject_commission ||
                      errors?.subject_commission
                    }
                  />
                </RentedInfoContainer>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent={"flex-end"}
                  gap={4}
                  m={4}
                >
                  <Button
                    title={t("cancel")}
                    variant="text"
                    type="button"
                    color="primary"
                    size="large"
                    onClick={() => {
                      resetForm();
                      setIsEditInfo(false);
                    }}
                  />
                  <Button
                    title={t("save")}
                    color="success"
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    size="large"
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Edit
                  className="edit-icon"
                  data-testid="edit-info-icon"
                  onClick={() => setIsEditInfo(true)}
                />
                <Grid container textAlign={"center"}>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {values?.headline || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("property.emptyAreaForm.exposeHeadline")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {values?.location?.full_address || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("form.propertyLocation")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {values?.is_publish_property_address ? t("yes") : t("no")}
                    </Typography>
                    <SmallLightText>
                      {t("property.emptyAreaForm.publishPropertyAddress")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {values?.contact_asset_manager
                        ? `${values?.contact_asset_manager?.first_name} ${values?.contact_asset_manager?.last_name}`
                        : EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("property.emptyAreaForm.contactPerson")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {values?.store_type?.name || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("property.emptyAreaForm.storeType")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <NumberContainer value={values?.deposit} />
                    <SmallLightText>
                      {t("property.emptyAreaForm.deposit")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {values?.state?.name || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("property.emptyAreaForm.propertyState")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {values?.built || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("property.emptyAreaForm.constructionYear")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {values?.parking_lots ?? EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("property.emptyAreaForm.numberOfParkingLots")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {values?.last_modernization || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("property.emptyAreaForm.lastModernization")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {values?.marketing_type?.name || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("property.emptyAreaForm.marketingType")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <NumberContainer value={values?.price} />
                    <SmallLightText>
                      {t("property.emptyAreaForm.price")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {values?.price_unit?.name || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("property.emptyAreaForm.validPriceForAreaOrPeriod")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <NumberContainer value={values?.square_meters} />
                    <SmallLightText>
                      {t("property.emptyAreaForm.salesArea")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <NumberContainer value={values?.sales_square_meters} />
                    <SmallLightText>
                      {t("property.emptyAreaForm.totalArea")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={4} md={3}>
                    <Typography variant="h4">
                      {values?.subject_commission?.name || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("property.emptyAreaForm.subjectToCommission")}
                    </SmallLightText>
                  </RentedInfoContainer>
                </Grid>
              </>
            )}
          </EditInfoCard>
        </Grid>
        <Grid item sm={12}>
          <EditInfoCard className="edit-card">
            {isEditDescription ? (
              <Grid container>
                <RentedInfoContainer item sm={6}>
                  <Text
                    label={t("property.emptyAreaForm.propertyDescription")}
                    name="description"
                    status={status}
                    value={values?.description || ""}
                    rows={4}
                    multiline
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={6}>
                  <Text
                    label={t("property.emptyAreaForm.location")}
                    name="location_info"
                    status={status}
                    value={values?.location_info || ""}
                    rows={4}
                    multiline
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={6}>
                  <Text
                    label={t("property.emptyAreaForm.equipment")}
                    name="equipment"
                    status={status}
                    value={values?.equipment || ""}
                    rows={4}
                    multiline
                  />
                </RentedInfoContainer>
                <RentedInfoContainer item sm={6}>
                  <Text
                    label={t("property.emptyAreaForm.otherInformation")}
                    name="other_information"
                    status={status}
                    value={values?.other_information || ""}
                    rows={4}
                    multiline
                  />
                </RentedInfoContainer>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent={"flex-end"}
                  gap={4}
                  m={4}
                >
                  <Button
                    title={t("cancel")}
                    variant="text"
                    type="button"
                    color="primary"
                    size="large"
                    onClick={() => {
                      resetForm();
                      setIsEditDescription(true);
                    }}
                  />
                  <Button
                    title={t("save")}
                    color="success"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Edit
                  className="edit-icon"
                  data-testid="edit-description-icon"
                  onClick={() => setIsEditDescription(true)}
                />
                <Grid container textAlign={"center"}>
                  <RentedInfoContainer item sm={6}>
                    <Typography variant="h4">
                      {values?.description || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("property.emptyAreaForm.propertyDescription")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={6}>
                    <Typography variant="h4">
                      {values?.location_info || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("property.emptyAreaForm.location")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={6}>
                    <Typography variant="h4">
                      {values?.equipment || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("property.emptyAreaForm.equipment")}
                    </SmallLightText>
                  </RentedInfoContainer>
                  <RentedInfoContainer item sm={6}>
                    <Typography variant="h4">
                      {values?.other_information || EMPTY_DATA}
                    </Typography>
                    <SmallLightText>
                      {t("property.emptyAreaForm.otherInformation")}
                    </SmallLightText>
                  </RentedInfoContainer>
                </Grid>
              </>
            )}
          </EditInfoCard>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
