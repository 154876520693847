import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Offer as BEOffer } from "../../../../../../../../types/be/offer";
import useTable from "../../../../../../../../ui/table/useTable";
import { deleteOffer, getOffers } from "../../../../../../../../api/offer";
import Table from "../../../../../../../../ui/table/Table";
import { Dialog, Typography, DialogTitle, Grid } from "@mui/material";
import { itemsListHeadCells } from "../../../../utils";
import { EditOfferPropType, ProviderOfferListProps } from "../../../../types";
import OfferListRow from "../../OfferListRow";
import { handleServerError } from "../../../../../../../../utils/http";
import { useTranslation } from "react-i18next";
import filter from "lodash/filter";
import {
  getPropertyHeadByWidget,
  prepareQueryParams,
  replaceNullOrUndefinedByEmptyString,
} from "../../../../../../../../utils/common";
import CommentsPopup from "../../../../../../../../ui/commentsPopUp";
import { COMMENTABLE } from "../../../../../../../../constants";
import { showCommentsModalInitState } from "../../../../../../../../ui/commentsPopUp/utils";
import useIsMounted from "../../../../../../../../hooks/useIsMounted";
import {
  getItemsWithActiveReleasePermissions,
  getReleaseRoute,
  providerTableScrollerId,
} from "../../../../../../utils";
import useAppSelector from "../../../../../../../../hooks/useAppSelector";
import { OffersFilterProps } from "../../../../../../insurances/list/topicList/types";
import ContactShowDialog from "../../../../../../components/provider/contactShowDialog";
import {
  DialogContainWrapper,
  DialogContent,
  DialogTitleContainer,
} from "../../../../../../../home/styled";
import CloseButton from "../../../../../../../../ui/dialog/CloseButton";
import PageWrapper from "../../../../../../../../ui/pageWrapper/PageWrapper";
import { Contact } from "../../../../../../../contacts/edit/types";
import { useFetch } from "../../../../../../../../hooks/useFetch";
import { getContact } from "../../../../../../../../api/contacts";
import { ProviderInfoTableWrapper } from "../../../../../../components/provider/styled";
import ProviderInfoTable from "../../../../../../components/provider/ProviderInfoTable";
import HZScrollController from "../../../../../../../../ui/HZScrollController";

/* istanbul ignore next */
const ProviderOfferList = ({
  onClose,
  parentFilter,
  configuration,
  handleForwardDocument,
  handleReleaseDocument,
  releasePermission,
  isDocumentReleaseLoading,
  handleHistoryModal,
}: ProviderOfferListProps): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isShowCommentsModal, setIsShowCommentsModal] = useState(
    showCommentsModalInitState
  );
  const { id } = useParams();
  const { optionalColumnIds, selectedOptionalColumnsIds } = useAppSelector(
    (state) => state.offers
  );

  const {
    data: contact,
    run: runContact,
    isSuccess: isContactSuccess,
  } = useFetch<Contact>();

  useEffect(() => {
    parentFilter.contact_id &&
      runContact(getContact(String(parentFilter.contact_id)));
  }, []);

  const {
    order,
    orderBy,
    isLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleCellClick,
    isConfirmToRemoveModalOpen,
    rowToDelete,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    setIsLoading,
    queryParams,
    setTotal,
    setData,
    data,
    total,
  } = useTable<BEOffer>();
  const [contactShowId, setContactShowId] = useState<string | null>(null);

  useEffect(() => {
    isMounted() && fetchOffers();
  }, [queryParams, parentFilter]);

  /* istanbul ignore next */
  const fetchOffers = useCallback(
    async (loading = true) => {
      setIsLoading(loading);

      const params: OffersFilterProps = {};

      const { contact_id } = parentFilter;

      if (id) {
        params.property_id = id;
      }

      if (contact_id) {
        params.contact_id = contact_id;
      }

      const response = await getOffers(
        prepareQueryParams(
          queryParams,
          replaceNullOrUndefinedByEmptyString(params),
          true
        )
      );

      if (response.status !== 200) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        const resJson = await response.json();
        setTotal(resJson.meta.total);
        setData(resJson.data);
        setIsLoading(false);
      }
    },
    [queryParams, setIsLoading, setTotal, setData, setError, parentFilter]
  );

  const handleRowRemove = useCallback(async (): Promise<void> => {
    const response = await deleteOffer(rowToDelete);
    if (response.status === 200) {
      await fetchOffers();
      isMounted() && handleConfirmToRemoveModalClose();
    } else {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    }
  }, [rowToDelete, deleteOffer, fetchOffers, handleConfirmToRemoveModalClose]);

  const handleEditClick = useCallback(
    ({ id: offer_id }: EditOfferPropType): void =>
      navigate(
        getReleaseRoute("offers.edit", id ? Number(id) : undefined, offer_id)
      ),
    []
  );

  const handleShowClick = useCallback(
    ({ id: offer_id }: EditOfferPropType): void =>
      navigate(
        getReleaseRoute("offers.show", id ? Number(id) : undefined, offer_id)
      ),
    []
  );

  const tableHeaderTitles = useMemo(() => {
    let filteredItems = itemsListHeadCells;
    /* istanbul ignore next */
    filteredItems = filter(
      filteredItems,
      (o) =>
        !optionalColumnIds.includes(o.id) ||
        selectedOptionalColumnsIds.includes(o.id)
    );

    filteredItems = getPropertyHeadByWidget(
      filteredItems,
      "property_name",
      false
    );

    return getItemsWithActiveReleasePermissions(filteredItems, configuration);
  }, [selectedOptionalColumnsIds, itemsListHeadCells, configuration]);

  const TableToolbar = isContactSuccess ? (
    <Grid container pt={4}>
      <Grid item>
        <HZScrollController scrollerId={providerTableScrollerId} />
      </Grid>
    </Grid>
  ) : (
    <></>
  );

  const handleOpenComments = (row: BEOffer): void => {
    setIsShowCommentsModal({ isVisible: true, rowId: row?.id });
  };

  const handleCloseCommentsModal = (): void => {
    setIsShowCommentsModal(showCommentsModalInitState);
    /* istanbul ignore next */
    fetchOffers(false);
  };

  const renderRow = (row: BEOffer): ReactElement => (
    <OfferListRow
      row={row}
      topicStatusId={undefined}
      handleCellClick={handleCellClick}
      handleEditClick={handleEditClick}
      handleShowClick={handleShowClick}
      openConfirmToRemoveModal={openConfirmToRemoveModal}
      handleOpenComments={handleOpenComments}
      fetchOfferTopics={fetchOffers}
      useOnWidget={true}
      setContactShowId={setContactShowId}
      handleForwardDocument={handleForwardDocument}
      handleReleaseDocument={handleReleaseDocument}
      releasePermission={releasePermission}
      isDocumentReleaseLoading={isDocumentReleaseLoading}
      configuration={configuration}
      handleHistoryModal={handleHistoryModal}
    />
  );

  const isMounted = useIsMounted();

  const breadcrumbs: { to: string; name: string }[] = [];

  const pageTitle = "";

  return (
    <Dialog
      role={"provider-invoices-list-modal"}
      fullWidth
      open={Boolean(parentFilter.contact_id)}
      onClose={onClose}
      maxWidth="xl"
    >
      <DialogTitle sx={{ pt: 0, pb: 0 }}>
        <DialogTitleContainer>
          <Typography
            variant="h6"
            component="div"
            justifyContent="space-around"
            width="100%"
          >
            Infos
          </Typography>
          <CloseButton onClick={onClose} />
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        <div>
          <DialogContainWrapper>
            <PageWrapper breadcrumbs={breadcrumbs} title={pageTitle}>
              <>
                {contact && (
                  <ProviderInfoTableWrapper>
                    <ProviderInfoTable contact={contact} />
                  </ProviderInfoTableWrapper>
                )}
                <Table
                  data={data}
                  total={total}
                  currentPage={currentPage}
                  order={order}
                  orderBy={orderBy}
                  error={error}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  onPageChange={handleChangePage}
                  onRowRemove={handleRowRemove}
                  onSortChange={handleSortChange}
                  onSelectAllClick={handleSelectAllClick}
                  onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
                  rowsPerPage={rowsPerPage}
                  isLoading={isLoading}
                  isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
                  listHeadCells={tableHeaderTitles}
                  renderRow={renderRow}
                  tableToolbar={TableToolbar}
                  labelRowsPerPage={t("offers.offersPerTable")}
                  tableDataMaxHeight={"65vh"}
                  refId={`hzScroll${providerTableScrollerId}`}
                />
                {isShowCommentsModal.isVisible && (
                  <CommentsPopup
                    handleCloseDialog={handleCloseCommentsModal}
                    id={String(isShowCommentsModal?.rowId)}
                    relation={COMMENTABLE.OFFER}
                  />
                )}
                {contactShowId && (
                  <ContactShowDialog
                    id={contactShowId}
                    onClose={() => setContactShowId(null)}
                    open={true}
                  />
                )}
              </>
            </PageWrapper>
          </DialogContainWrapper>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProviderOfferList;
