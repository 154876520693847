import {
  prepareQueryParams,
  replaceEntityById,
  replaceNullOrUndefinedByEmptyString,
  replaceStringByParsedNumber,
} from "../../../utils/common";
import {
  removeCommaAndZeroFromString,
  replaceMomentDateByTimestamp,
  replaceTimestampByMomentDateOrNull,
  replaceTimestampByStringDate,
} from "../../../utils/date";
import { TabAreaControls } from "./types";
import omit from "lodash/omit";
import { Property } from "../../../types/be/property";
import { PERMISSION_CODE_PARAM, UserPermissions } from "../../../constants";
import { getUsers } from "../../../api/users";
import { Company, User } from "../../../types/models";
import { companiesAutocomplete } from "../../../api/companies";
import uniqBy from "lodash/uniqBy";

export const simpleTabProps = function (index: number): TabAreaControls {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const preparePropertyBeforeForm = (data: Property): Property => ({
  ...data,
  ...replaceNullOrUndefinedByEmptyString(
    omit(data, ["contract_start", "contract_end", "contract_cancelled"])
  ),
  ...replaceTimestampByMomentDateOrNull({
    contract_start: data.contract_start,
    contract_end: data.contract_end,
    contract_cancelled: data.contract_cancelled,
  }),
  ...replaceEntityById({
    caretaker_id: data.caretaker,
    company_id: data.company,
    supervisor_id: data.supervisor,
    company_user_id: data.company_user,
    contract_id: data.contract,
  }),
  ...replaceTimestampByStringDate({ takeover: data.takeover }),
  expenses: removeCommaAndZeroFromString(data.expenses),
});
/* istanbul ignore next */
export const preparePropertyAfterForm = (data: Property): Property => {
  !data.supervisor_id && delete data.supervisor_id;
  !data.caretaker_id && delete data.caretaker_id;
  delete data.percentage_net_rent;
  return {
    ...data,
    company_user_id: data.company_user_id ? data.company_user_id : "",
    ...replaceMomentDateByTimestamp({
      contract_start: data.contract_start,
      contract_end: data.contract_end,
      contract_cancelled: data.contract_cancelled,
    }),
    ...replaceStringByParsedNumber({
      property_management_cost: data.property_management_cost,
    }),
  };
};
/* istanbul ignore next */
export const handleOnChangeAutocompleteInputText = async (
  search: string
): Promise<User[]> => {
  const params = prepareQueryParams("", {
    search,
    [PERMISSION_CODE_PARAM]: UserPermissions.PROPERTY_ASSET_MANAGER,
  });
  const response = await getUsers(params);

  const { data } = await response.json();

  return data;
};
/* istanbul ignore next */
export const handleCompanyAutocompleteInputChange = async (
  search: string
): Promise<Company[]> => {
  const params = prepareQueryParams("", {
    search,
  });
  const response = await companiesAutocomplete(params);
  const { data } = await response.json();
  return data;
};

export const removeDuplicatesFromCompanies = (
  companies: Company[]
): Company[] => uniqBy(companies, (company) => company.id);
