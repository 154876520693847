import React, { ReactElement, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Grid, TableCell, Typography } from "@mui/material";
import * as Yup from "yup";
import { CreateMaintenanceType, MaintenanceTypeDialogProps } from "./types";
import Table from "../../../../ui/table/Table";
import DialogTitle from "../../../../ui/dialog/DialogTitle";
import useTable from "../../../../ui/table/useTable";
import { handleCreateMaintenanceType, itemsListHeadCells } from "./utils";
import { DeleteIcon } from "../../../../ui/table/styled";
import {
  deleteMaintenanceType,
  getMaintenanceType,
} from "../../../../api/maintenance";
import { useParams } from "react-router-dom";
import {
  generalPropertyOption,
  prepareQueryParams,
} from "../../../../utils/common";
import { HTTP_STATUS_CODES } from "../../../../types/server";
import { handleServerError } from "../../../../utils/http";
import { MaintenanceType } from "../../../../types/be/maintenance";
import Button from "../../../../ui/button/Button";
import { Formik } from "formik";
import Text from "../../../../ui/formsFields/text";
import useValidation from "../../../../hooks/useValidation";
import { DialogContent } from "./styled";
import useSnackbar from "../../../../ui/snackbar1/useSnackbar";
import Dropdown from "../../../../ui/dropdown";
import { getProperties } from "../../../../api/property";
import useAccessControl from "../../../../hooks/useAccessControl";
import { PermissionsCreate, PermissionsDelete } from "../../../../constants";

const MaintenanceTypeDialog = ({
  open,
  onClose,
}: MaintenanceTypeDialogProps): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { name, property_id } = useValidation();
  const { Snackbar, snackbar } = useSnackbar();
  const { can } = useAccessControl();

  const {
    data,
    setData,
    total,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    queryParams,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    isConfirmToRemoveModalOpen,
    handleSortChange,
    handleSelectAllClick,
    setTotal,
    openConfirmToRemoveModal,
    rowToDelete,
    handleDeleteLastPageData,
    handleConfirmToRemoveModalClose,
  } = useTable<MaintenanceType>();

  const handleFetchList = useCallback(
    async (loading = true) => {
      setIsLoading(loading);
      const params = prepareQueryParams(queryParams, {
        property_id: id,
      });
      const response = await getMaintenanceType(params);

      if (response.status !== HTTP_STATUS_CODES.OK) {
        const { errorMessage } = handleServerError(response);
        setError(errorMessage);
      } else {
        const json = await response.json();
        setData(json?.data);
        setTotal(json?.meta?.total);
      }

      setIsLoading(false);
    },
    [queryParams, id]
  );

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteMaintenanceType(rowToDelete.id);
    handleDeleteLastPageData(handleFetchList);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteMaintenanceType,
    handleFetchList,
    handleConfirmToRemoveModalClose,
  ]);

  const createTypeInitialValues: CreateMaintenanceType = {
    name: "",
    property_id: id || "",
  };

  const TableToolbar = (
    <>
      {can(PermissionsCreate.MAINTENANCE) && (
        <Formik
          initialValues={createTypeInitialValues}
          validationSchema={Yup.object().shape({
            name,
            property_id,
          })}
          onSubmit={handleCreateMaintenanceType(t, snackbar, handleFetchList)}
        >
          {({
            values,
            status,
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldValue,
            touched,
            errors,
          }) => (
            <form noValidate onSubmit={handleSubmit} autoComplete="off">
              <Grid container gap={2} alignItems="center" sx={{ py: 2 }}>
                <Grid item>
                  <Text
                    required
                    name="name"
                    value={values?.name}
                    label={t("name")}
                    placeholder={t("name")}
                    type="text"
                    status={status}
                  />
                </Grid>
                {!id && (
                  <Grid item md={4} sm={4}>
                    <Dropdown
                      id="property-autocomplete"
                      name="property_id"
                      label={t("form.propertyId")}
                      placeholder={t("form.propertyId")}
                      getOptions={getProperties}
                      onSelectOption={
                        /* istanbul ignore next */
                        (id?: string) => setFieldValue("property_id", id)
                      }
                      optionLabel="object_name"
                      optionValue="id"
                      size="medium"
                      helperText={
                        status?.errors.property_id ||
                        (touched.property_id && errors.property_id)
                      }
                      error={Boolean(
                        status?.errors.property_id ||
                          (touched.property_id && errors.property_id)
                      )}
                      defaultOption={[generalPropertyOption]}
                    />
                  </Grid>
                )}
                <Grid item>
                  <Button
                    title={t("create")}
                    type="submit"
                    size="large"
                    isLoading={isSubmitting}
                    disabled={isSubmitting || !isValid}
                    color="success"
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </>
  );

  const renderRow = (row: MaintenanceType): ReactElement => (
    <>
      <TableCell>
        <Typography noWrap>{row?.name}</Typography>
      </TableCell>
      <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
        {can(PermissionsDelete.MAINTENANCE) && (
          <DeleteIcon
            role="DeleteIconRole"
            size={18}
            onClick={() => openConfirmToRemoveModal(row)}
          />
        )}
      </TableCell>
    </>
  );

  useEffect(() => {
    handleFetchList();
  }, [id, queryParams]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      data-testid="maintenance-type-dialog"
      onClose={onClose}
    >
      <DialogTitle
        title="property.maintenance.maintenanceType"
        onClose={onClose}
      />
      <DialogContent>
        <Table
          data={data}
          total={total}
          currentPage={currentPage}
          order={order}
          orderBy={orderBy}
          error={error}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          onSortChange={handleSortChange}
          onSelectAllClick={handleSelectAllClick}
          onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
          isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
          listHeadCells={itemsListHeadCells}
          renderRow={renderRow}
          tableToolbar={TableToolbar}
          onRowRemove={handleRowRemove}
          tableDataMaxHeight={"50vh"}
        />
        {Snackbar}
      </DialogContent>
    </Dialog>
  );
};

export default MaintenanceTypeDialog;
