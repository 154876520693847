import { TableCell } from "../../../types/table";
import http from "../../../utils/http";
import { IdPropType } from "../../../types/models";
import { UploadFileType } from "../../../types/fe/property";
import { FormikValues } from "formik";
import { SOMETHING_WENT_WRONG_ERROR } from "../../../constants";
import { mapBackendValidationErrors } from "../../../utils/form";
import { handleManullyPropertyUploadProps } from "./types";
import { uploadFile } from "../../../api/property";
import { HTTP_STATUS_CODES } from "../../../types/server";
import {
  setDownloadLink,
  setFileName,
} from "../../../redux/slices/importValidation/importValidation";

export const handleDeleteClick = async ({
  id,
}: IdPropType): Promise<Response> => {
  return await http.delete(`/api/properties/${id}`);
};

export const itemsListHeadCells: TableCell[] = [
  {
    id: "object_name",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "propertiesList.objectName",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "address",
  },
  {
    id: "property_type_title",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertiesList.propertyType",
  },
  {
    id: "rented_area",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertiesList.rentedArea",
    align: "right",
  },
  {
    id: "total_area",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertiesList.totalArea",
    align: "right",
  },
  {
    id: "rented_out",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: `propertiesList.rentedOut`,
    subLabel: `propertiesList.inPercentage`,
    align: "right",
  },
  {
    id: "net_rent_per_year",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertiesList.netRentPerYear",
    subLabel: "propertiesList.coldRent",
    align: "right",
  },
  {
    id: "potencial_per_year",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertiesList.potencialPerYear",
    subLabel: "propertiesList.perYear",
    align: "right",
  },
  {
    id: "wault",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertiesList.wault",
    align: "right",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];

export const itemsListHeadCellsTenantUser: TableCell[] = [
  {
    id: "object_name",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "propertiesList.objectName",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "address",
  },
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertiesList.state",
  },
  {
    id: "property_type_title",
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: "propertiesList.propertyType",
  },
];

export const uploadDialogInitState: UploadFileType = {
  file: "",
};

export const handleManullyPropertyUpload = ({
  setFormMessage,
  onSuccess,
  dispatchStore,
}: handleManullyPropertyUploadProps) => {
  return async (
    data: UploadFileType,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const reqData = new FormData();
    reqData.append("file", data.file!);
    const response = await uploadFile(reqData);
    const json = await response.json();
    if (
      [HTTP_STATUS_CODES.OK, HTTP_STATUS_CODES.CREATED].includes(
        response.status
      )
    ) {
      const {
        data: {
          file: { name, download_link },
        },
      } = json;
      dispatchStore(setFileName(name));
      dispatchStore(setDownloadLink(download_link));
      setStatus({
        success: true,
        errors: {},
      });
      setFormMessage({ type: undefined, text: "" });
      onSuccess();
    } else if (response.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
      setStatus({
        success: false,
        errors: mapBackendValidationErrors(json.errors),
      });
      setSubmitting(false);
    } else {
      setFormMessage({
        type: "error",
        text: SOMETHING_WENT_WRONG_ERROR,
      });
      setSubmitting(false);
    }
  };
};
