/* istanbul ignore file */

import { Property } from "../../../../../../types/be/property";
import pick from "lodash/pick";
import {
  AverageNetRentPerSquareMeterProps,
  CalculatedRemainingTermProps,
  FloorPlanSubdivisionProps,
  NetColdRentProps,
  RentedAndVacantSpaceSubdivisionProps,
  VacancyRateProps,
  ValuedFreeSpaceProps,
} from "./types";

export function extractNetColdRentFromProperty(
  property: Property
): NetColdRentProps {
  return pick(property, [
    "commercial_net_rent_per_month",
    "commercial_net_rent_per_year",
    "commercial_net_rent_percentage",
    "living_net_rent_per_month",
    "living_net_rent_per_year",
    "living_net_rent_percentage",
    "total_net_rent_per_month",
    "total_net_rent_per_year",
  ]);
}

export function extractValuedFreeSpaceFromProperty(
  property: Property
): ValuedFreeSpaceProps {
  return pick(property, [
    "commercial_valued_free_space_per_month",
    "commercial_valued_free_space_per_year",
    "living_valued_free_space_per_month",
    "living_valued_free_space_per_year",
    "total_valued_free_space_per_month",
    "total_valued_free_space_per_year",
  ]);
}

export function extractFloorPlanSubdivisionFromProperty(
  property: Property
): FloorPlanSubdivisionProps {
  return pick(property, [
    "commercial_area",
    "living_area",
    "total_not_rented_area",
    "full_area",
  ]);
}

export function extractAverageNetRentPerSquareMeterFromProperty(
  property: Property
): AverageNetRentPerSquareMeterProps {
  return pick(property, ["commercial_avg_per_month", "living_avg_per_month"]);
}

export function extractCalculatedRemainingTermFromProperty(
  property: Property
): CalculatedRemainingTermProps {
  return pick(property, ["remaining_in_eur", "shortest_remaining_term"]);
}

export function extractSubdivisionOfRentedAndVacantSpaceFromProperty(
  property: Property
): RentedAndVacantSpaceSubdivisionProps {
  return pick(property, [
    "commercial_not_rented_area",
    "commercial_rented_area",
    "commercial_area",
    "living_not_rented_area",
    "living_rented_area",
    "living_area",
  ]);
}

export function extractVacancyRateFromProperty(
  property: Property
): VacancyRateProps {
  return pick(property, ["total_area_percentage"]);
}
