import React, { ReactElement } from "react";
import { Box, Grid } from "@mui/material";
import { DataBox } from "./styled";
import { Property as BEProperty } from "../../types/be/property";
import PlaceIcon from "@mui/icons-material/Place";

interface PropertyDataProps {
  property: BEProperty;
}
/* istanbul ignore next */
const PropertyData = ({
  property: { object_name, location, asset_managers, property_status },
}: PropertyDataProps): ReactElement => {
  return (
    <Grid container>
      <Grid item xs={12} pb={2}>
        <DataBox>
          <Box sx={{ display: "flex" }}>
            <PlaceIcon sx={{ mr: "5px" }} /> {object_name}
          </Box>
          <Box>
            <span>Location: </span>
            {location.full_address}
          </Box>
          {Boolean(asset_managers.length) && (
            <Box>
              <span>AM: </span>
              {asset_managers.map(({ id, first_name, last_name }, i, self) => (
                <span key={id}>
                  {`${first_name} ${last_name}${
                    i !== self.length - 1 ? ", " : ""
                  }`}
                </span>
              ))}
            </Box>
          )}
          {property_status?.name ? (
            <Box>
              <span>Status: </span>
              {property_status.name}
            </Box>
          ) : null}
        </DataBox>
      </Grid>
    </Grid>
  );
};

export default PropertyData;
