import React, { MouseEvent, ReactElement } from "react";
import { useDropzone } from "react-dropzone";
import { Typography } from "@mui/material";
import { Upload, Loader } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  DeleteIconContainer,
  DropZoneBox,
  PropertyLogo,
  SmallLightText,
} from "./styled";
import { DeleteIcon } from "../../../counters/list/CounterList";
import { MAX_FILES_UPLOAD } from "../../../../../constants";
import { FILE_SIZE_5_MB } from "../../../../../utils/common";
import { UploadContractProps } from "./types";

/* istanbul ignore next */
const UploadLogo = ({
  file,
  fileBlob,
  onDrop,
  isLoading,
  handleDelete,
  isDeletingPropertyLogo,
  title,
  disabled = false,
  isGeneralAllEditable,
}: UploadContractProps): ReactElement => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      maxFiles: MAX_FILES_UPLOAD,
      accept: { "image/*": [".png", ".jpeg", ".jpg"] },
      onDrop,
      minSize: 0,
      maxSize: FILE_SIZE_5_MB,
      disabled: isLoading || disabled || !isGeneralAllEditable,
    });

  const additionalStyle = isDragAccept
    ? "accept"
    : isDragReject
    ? "reject"
    : "";

  return (
    <DropZoneBox
      additionalstyle={additionalStyle}
      data-testid="drop-box"
      className="drop-box"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isLoading ? (
        <>
          <Upload size={60} />
          <Typography variant="h3">{t("uploading")}</Typography>
          <Loader />
        </>
      ) : !isGeneralAllEditable && !file ? (
        <SmallLightText>
          {t("documentRelease.invoice.noLogoAvailable")}
        </SmallLightText>
      ) : file?.url ? (
        <>
          {file?.url && <PropertyLogo src={file.url}></PropertyLogo>}
          {file?.url && handleDelete && isGeneralAllEditable && (
            <DeleteIconContainer>
              {isDeletingPropertyLogo ? (
                <Loader size={16} />
              ) : (
                <DeleteIcon
                  onClick={(e: MouseEvent<SVGElement>) => {
                    e.stopPropagation();
                    handleDelete();
                  }}
                />
              )}
            </DeleteIconContainer>
          )}
        </>
      ) : fileBlob ? (
        <PropertyLogo
          src={URL.createObjectURL(fileBlob).toString()}
        ></PropertyLogo>
      ) : (
        <>
          <Upload size={60} />
          <Typography variant="h3">{title}</Typography>
          <div>
            <SmallLightText>
              {t("fileSizeMustBe")} <strong>{"<5mb"}</strong>
            </SmallLightText>
          </div>
        </>
      )}
    </DropZoneBox>
  );
};

export default UploadLogo;
