import { TableCell } from "../../../../types/table";

export const itemsListHeadCells: TableCell[] = [
  {
    id: "contactName",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.maintenance.contactName",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.maintenance.description",
    align: "left",
  },
  {
    id: "inspection",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.maintenance.inspectionAndMaintenanceInterval",
    style: { maxWidth: 200, whiteSpace: "normal" },
    align: "center",
  },
  {
    id: "comment",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "documentRelease.invoice.history.comment",
    align: "left",
  },
  {
    id: "contract",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.maintenance.contract",
  },
  {
    id: "report",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.maintenance.report",
  },
  {
    id: "order",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.maintenance.order",
  },
  {
    id: "lastMaintenance",
    numeric: false,
    disablePadding: true,
    sortable: false,
    label: "property.maintenance.lastMaintenance",
  },
  {
    id: "units",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "property.maintenance.units",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "",
  },
];
