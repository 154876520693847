import { File } from "./file";

export interface propertiesItem {
  title: string;
  status: {
    id: number;
    name: string;
    code: string;
  };
  tenant: {
    id: number;
    full_name: string;
    email: string;
  };
  manager: null | {
    first_name: string;
    last_name: string;
    id: number;
  };
  description: string;
  area: {
    id: number;
    name: string;
  };
  property: {
    id: number;
    name: string;
  };
  files: File[];
}

export enum TenantRequestEvent {
  CREATED = "created",
  UPDATED = "updated",
}

export interface TenantRequestHistory {
  id: number;
  event: TenantRequestEvent;
  description: string;
  causer: {
    id: number;
    first_name: string;
    last_name: string;
  };
  properties: {
    old?: propertiesItem;
    attributes: propertiesItem;
  };
  created_at: number;
}
