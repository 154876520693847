export default {
  place: {
    autocomplete: {
      validationError:
        "Land, Stadt, Straße und Hausnummer müssen angegeben werden",
      placeholder: "Ort, Straße, Hausnummer",
      countryIsRequired: "Land ist erforderlich",
      houseNumberIsRequired: "Hausnummer ist erforderlich",
      streetIsRequired: "Straße ist erforderlich",
      countryHouseNumberAreRequired: "Landhausnummer erforderlich",
      houseNumberStreetAreRequired: "Hausnummer Straße erforderlich",
      streetCountryHouseNumberAreRequired:
        "Straße, Land, Hausnummer sind erforderlich",
      streetHouseNumberAreRequired: "Hausnummer Straße erforderlich",
      cityStreetHouseNumberCountryAreRequired:
        "Stadt Straße Hausnummer Land Erforderlich",
      countryCodeIsRequired: "Land ist erforderlich",
    },
  },
};
