import { Checkbox, Grid, ListItemText, OutlinedInput } from "@mui/material";
import { Form, Formik } from "formik";
import React, { ReactElement, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useAppDispatch from "../../../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../../../hooks/useAppSelector";
import {
  setAdditionalFields,
  setAreaTypeId,
  setAreaIsRentedOption,
} from "../../../../../../redux/slices/area/area";
import { AreaType } from "../../../../../../types/be/area";
import {
  MenuItem,
  MultiSelect,
  SelectBox,
  SelectDefault,
  TextField,
} from "./styled";
import { AreaTableFilterProps } from "./types";
import { handleAdditionalFieldsChange, isRentedOptions } from "./utils";
import {
  handleAreaTypeIdChange,
  handleAreaIsRentedOptionChange,
} from "../../utils";
import { useParams } from "react-router-dom";

const AreaTableFilters = ({
  areaTypes,
  setQueryParams,
}: AreaTableFilterProps): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { additionalFields, areaTypeId, areaIsRentedOption } = useAppSelector(
    (state) => state.area
  );
  const [isAllSelected, setIsAllSelected] = useState(false);

  const visibleAdditionalFieldsNames = useMemo(() => {
    const selected = additionalFields
      .filter((el) => el.visible)
      .map((el) => el.label);
    setIsAllSelected(additionalFields.length === selected.length);
    return selected;
  }, [additionalFields]);
  const dispatch = useAppDispatch();

  /* istanbul ignore next */
  return (
    <>
      <Formik
        initialValues={{
          additional_fields: visibleAdditionalFieldsNames,
          area_type_id: areaTypeId,
          area_is_rented_option: areaIsRentedOption,
        }}
        enableReinitialize
        onSubmit={() => {}}
      >
        {({ values, status, submitForm }) => {
          return (
            <Form>
              <Grid
                data-testid="table-filters"
                container
                alignItems="center"
                justifyContent="flex-start"
                sx={{ flexWrap: "nowrap" }}
              >
                <Grid item xs={12} sm={12} md={4} sx={{ mr: 2, maxHeight: 36 }}>
                  <TextField
                    name="area_type_id"
                    fullWidth
                    select
                    size="small"
                    value={values.area_type_id}
                    inputProps={{
                      "aria-label": "area-type-select",
                    }}
                    onChange={handleAreaTypeIdChange({
                      id,
                      dispatch,
                      setQueryParams,
                      setAreaTypeId,
                      submitForm,
                    })}
                  >
                    <MenuItem key={0} value={0}>
                      {t("userActivity.allTypes")}
                    </MenuItem>
                    {areaTypes?.map(({ id, name }: AreaType) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  sx={{ mr: 2, maxHeight: 36, minWidth: 100 }}
                >
                  <TextField
                    fullWidth
                    name={values.area_is_rented_option?.toString()}
                    select
                    label={t("property.area.isRented")}
                    size="small"
                    value={values.area_is_rented_option}
                    inputProps={{
                      "aria-label": "area-type-select",
                    }}
                    onChange={handleAreaIsRentedOptionChange({
                      dispatch,
                      setQueryParams,
                      setAreaIsRentedOption,
                      submitForm,
                    })}
                  >
                    <MenuItem key={-1} value={-1}>
                      {t("settings.role.all")}
                    </MenuItem>
                    {isRentedOptions.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {t(name)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  sx={{ maxHeight: 36, minWidth: 250 }}
                >
                  <MultiSelect
                    size="small"
                    name="additional_fields"
                    value={values.additional_fields}
                    displayEmpty
                    sx={{ height: "36px" }}
                    MenuProps={{
                      sx: {
                        maxHeight: 350,
                      },
                    }}
                    inputProps={{
                      "aria-label": t("tenant.addIndividualColumns"),
                    }}
                    input={<OutlinedInput />}
                    renderValue={(selected: string[]): ReactNode =>
                      Object.keys(additionalFields)?.length && (
                        <SelectBox>
                          {selected.length === 0 ? (
                            <SelectDefault>
                              {t("tenant.addIndividualColumns")}
                            </SelectDefault>
                          ) : (
                            <SelectDefault>
                              {`${t("selected")} ${selected.length} ${t(
                                "tenant.individualColumns"
                              )}`}
                            </SelectDefault>
                          )}
                        </SelectBox>
                      )
                    }
                    status={status}
                    onChange={handleAdditionalFieldsChange({
                      dispatch,
                      setAdditionalFields,
                    })}
                  >
                    <MenuItem value="all">
                      <Checkbox checked={isAllSelected} />
                      <ListItemText primary={t("property.area.selectAll")} />
                    </MenuItem>
                    {additionalFields.map((field) => (
                      <MenuItem key={field.name} value={field.label}>
                        <Checkbox checked={field.visible} />
                        <ListItemText primary={t(String(field.label))} />
                      </MenuItem>
                    ))}
                  </MultiSelect>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AreaTableFilters;
