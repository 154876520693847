import styled from "styled-components/macro";
import { Grid } from "@mui/material";
/* istanbul ignore next */
export const ExpandableCommentWrapper = styled(Grid)`
  display: block;
  min-width: ${(props): string => props.theme.spacing(100)};
`;
/* istanbul ignore next */
export const CommentWrapper = styled("div")<{
  maxHeight: string;
  breakWord: boolean;
}>`
  .comment-user {
    font-size: 13px;
    font-weight: bold;
    color: ${(props): string => props.theme.comments.usernameColor};
  }
  .comment {
    font-size: 12px;
    max-height: ${(props): string => props.maxHeight};
    width: ${(props): string =>
      props.breakWord ? props.theme.spacing(100) : "100%"};
    overflow: hidden;
    word-wrap: ${(props): string =>
      props.breakWord ? "break-word" : "normal"};
    p {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }

    img {
      max-height: ${(props): string => props.theme.spacing(9)};
    }
    a {
      color: ${(props): string => props.theme.palette.primary.main};
    }
  }
`;
