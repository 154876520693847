import React, { ReactElement, useState } from "react";
import FormAlert from "../../../ui/formAlert/FormAlert";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Dropzone from "../../../ui/dropzone";
import Snackbar from "../../../ui/Snackbar";
import { FileInfo, Paper } from "../styled";
import { InboxCreateInitialValuesType, SnackbarMessage } from "../types";
import {
  handleInboxCreate,
  inboxCreateInitialValues,
  sendCredentialsSnackbarInitState,
} from "../utils";
import Button from "../../../ui/button/Button";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import useValidation from "../../../hooks/useValidation";
import { route } from "../../../utils/url";
import TabPageWrapper from "../../../ui/tabPageWrapper";
import { useLocation } from "react-router";

const InboxCreate = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const { pdfFile } = useValidation();
  const { id = "" } = useParams();

  const [isDropzoneVisible, setIsDropzoneVisible] = useState(true);
  const [sendCredentialsSnackbar, setSendCredentialsSnackbar] =
    useState<SnackbarMessage>(sendCredentialsSnackbarInitState);

  const handleCloseSnackbar = (): void =>
    setSendCredentialsSnackbar(sendCredentialsSnackbarInitState);

  const breadcrumbsItems = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: route("inbox"),
      name: t("inbox.title"),
    },
  ];

  return (
    <TabPageWrapper
      title={t("create")}
      breadcrumbs={breadcrumbsItems}
      wrap={!id}
    >
      <Grid container justifyContent={"center"}>
        <Grid
          item
          xs={12}
          md={10}
          lg={11}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper>
            <Formik
              initialValues={inboxCreateInitialValues}
              onSubmit={handleInboxCreate({
                setSendCredentialsSnackbar,
                t,
                navigate,
                property_id: id,
              })}
              validationSchema={Yup.object().shape({
                file: pdfFile,
              })}
            >
              {({
                isSubmitting,
                handleSubmit,
                setFieldValue,
                errors,
                values,
              }: FormikProps<InboxCreateInitialValuesType>) => (
                <Grid
                  container
                  data-testid="dropzone-container"
                  sx={{
                    height: "100%",
                    position: "relative",
                    justifyContent: "center",
                  }}
                  onDragOver={() => {
                    setIsDropzoneVisible(true);
                  }}
                  spacing={4}
                >
                  <Grid item sm={12}>
                    {errors?.file && (
                      <FormAlert
                        formMessage={{
                          type: "error",
                          text: errors?.file,
                        }}
                        sx={{ marginBottom: "10px" }}
                      />
                    )}
                  </Grid>
                  <Grid item sm={12}>
                    <Dropzone
                      open={isDropzoneVisible}
                      onDrop={async <T extends File>(
                        files: FileList | T[] | null
                      ): Promise<void> => {
                        setFieldValue("file", files?.[0]);
                      }}
                    />
                  </Grid>
                  {values?.file && !errors?.file ? (
                    <Grid item xs={12}>
                      <FileInfo>
                        <span className="file-name">{values?.file?.name}</span>
                        <span
                          onClick={() => setFieldValue("file", null)}
                          className="remove-btn"
                        >
                          {t("inbox.create.remove")}
                        </span>
                      </FileInfo>
                    </Grid>
                  ) : null}
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      type="button"
                      color="primary"
                      variant="text"
                      title={t("cancel")}
                      disabled={isSubmitting}
                      size="large"
                      onClick={
                        /* istanbul ignore next */ () =>
                          doesAnyHistoryEntryExist
                            ? navigate(-1)
                            : id
                            ? navigate(route("properties.inbox", id))
                            : navigate(route("inbox"))
                      }
                    />
                    <Button
                      title={t("create")}
                      size="large"
                      testId="createBtn"
                      color="success"
                      onClick={handleSubmit}
                      isLoading={isSubmitting}
                    />
                  </Grid>
                </Grid>
              )}
            </Formik>
          </Paper>
        </Grid>
        <Snackbar
          data-testid={"send-credentials-snackbar"}
          message={sendCredentialsSnackbar.text}
          color={sendCredentialsSnackbar.color}
          open={sendCredentialsSnackbar.visible}
          handleClose={handleCloseSnackbar}
        />
      </Grid>
    </TabPageWrapper>
  );
};

export default InboxCreate;
