import http from "../utils/http";

export const fetchRequests = async (params: string): Promise<Response> => {
  return await http.get(`/api/tenant-requests${params}`);
};

export const fetchRequestStatuses = async (): Promise<Response> => {
  return await http.get("/api/tenant-requests/statuses");
};

export const changeRequestStatus = async (
  requestId: number,
  statusId: number
): Promise<Response> => {
  return await http.patch(
    `/api/tenant-requests/${requestId}/status?status_id=${statusId}`,
    {}
  );
};
/* istanbul ignore next */
export const sendCredentials = async (email: string): Promise<Response> => {
  return await http.post(`/api/contacts/send-credential?email=${email}`, {});
};

export const fetchRequestById = async (id: string): Promise<Response> => {
  return await http.get(`/api/tenant-requests/${id}`);
};

export const editRequest = async (
  id: string,
  payload: FormData
): Promise<Response> => {
  return await http.post(`/api/tenant-requests/${id}`, payload);
};

export const createRequest = async (payload: FormData): Promise<Response> => {
  return await http.post(`/api/tenant-requests`, payload);
};

export const deleteRequest = async (requestId: number): Promise<Response> => {
  return await http.delete(`/api/tenant-requests/${requestId}`);
};

/* istanbul ignore next */
export const showRequest = async (requestId: string): Promise<Response> => {
  return await http.get(`/api/tenant-requests/${requestId}`);
};

export const deleteFile = async (id: number): Promise<Response> => {
  return await http.delete(`/api/files/${id}`);
};

export const fetchRequestCounts = async (): Promise<Response> => {
  return await http.get(`/api/tenant-request/count-by-statuses`);
};
