import AuthGuard from "../../components/guards/AuthGuard";
import DashboardLayout from "../../layouts/dashboard";
import FileManagement from "../../components/fileManagement";
import React from "react";
import { PermissionsRead } from "../../constants";
import AccessGuard from "../../components/guards/AccessGuard";

export default {
  path: "/:client/file-management",
  element: (
    <AuthGuard clientModuleName="file-management">
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: (
        <AccessGuard permissions={[PermissionsRead.FILE_MANAGEMENT]}>
          <FileManagement />
        </AccessGuard>
      ),
    },
  ],
};
