/* istanbul ignore file */
import styled from "styled-components";
import { spacing } from "@mui/system";
import FormAlert from "../../../../ui/formAlert/FormAlert";

export const LabelText = styled.div`
  font-weight: 600;
  font-size: 11px;
  white-space: break-spaces;
  color: ${(props): string => props.theme.comments.textColor};
`;

export const LoadingBox = styled.div`
  width: 100%;
  height: 50vh;
`;

export const ErrorBox = styled(FormAlert)(spacing);
