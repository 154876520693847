import React, { ReactElement, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InsuranceListToolbarProps } from "../types";
import { formatTimestamp } from "../../../../../../utils/date";
import {
  DATE_FORMAT,
  PermissionsCreate,
  PermissionsDelete,
  PermissionsUpdate,
} from "../../../../../../constants";
import { Calendar } from "react-feather";
import { CalendarIconWrapper } from "./styled";
import EditInsuranceTopicTitle from "./editInsuranceTopicTitle/EditInsuranceTopicTitle";
import {
  RELASE_BUTTONS_AM,
  RELASE_BUTTONS_PM,
  RELEASE_BUTTONS_FR,
  RELEASE_BUTTONS_USER,
  getReleaseRoute,
  isAvailableReleaseButtonTableCell,
} from "../../../../utils";
import { StyledButton } from "../../../../../../ui/button/styled";
import { NEW_STATUS_CODE } from "../utils";
import useAccessControl from "../../../../../../hooks/useAccessControl";
import HZScrollController from "../../../../../../ui/HZScrollController";
import DocumentRelease from "../../../../components/documentRelease";
import { RELEASE_LEVEL } from "../../../../../../utils/common";
import {
  DOCUMENT_RELEASE_LEVEL,
  DOCUMENT_RELEASE_MODULE,
} from "../../../../../../types/be/releaseFlow";

const InsuranceListToolbar = ({
  from,
  to,
  id,
  type,
  topicId,
  fetchInsuranceTopics,
  handleTopicReleaseRequest,
  openConfirmToRemoveTopicModal,
  propertyName,
  isSentFinalReleaserEmails,
  configuration,
  insuranceTopic,
  handleReleaseDocument,
  handleForwardDocument,
  releasePermission,
  isDocumentReleaseLoading,
}: InsuranceListToolbarProps): ReactElement => {
  const { t } = useTranslation();
  const { can } = useAccessControl();
  const [modalState, setModalState] = useState(false);
  const handleDialog = (): void => {
    setModalState((modalState) => !modalState);
  };
  const { status } = useParams();

  return (
    <>
      {modalState && (
        <EditInsuranceTopicTitle
          handleDialog={handleDialog}
          to={to}
          from={from}
          topicId={topicId}
          type={type}
          fetchInsuranceTopics={fetchInsuranceTopics}
        />
      )}
      <Grid
        container
        mt={5}
        mb={2}
        alignItems={"center"}
        justifyContent={"space-between"}
        className="table-toolbar"
      >
        <Grid item>
          <Typography
            component="h2"
            variant="h5"
            align="center"
            display="flex"
            gutterBottom
          >
            <Grid mr={2} item>
              {propertyName}
            </Grid>
            {formatTimestamp(from, DATE_FORMAT)} -{" "}
            {formatTimestamp(to, DATE_FORMAT)}
            <CalendarIconWrapper>
              <Calendar
                role={"calendarIconRole"}
                onClick={handleDialog}
                size={22}
              />
            </CalendarIconWrapper>
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={3}
            justifyContent={"start"}
            alignItems={"start"}
          >
            <Grid item pr={2} alignItems="center" display="flex">
              <HZScrollController scrollerId={topicId} />
            </Grid>
            {status === NEW_STATUS_CODE && (
              <>
                {can(PermissionsCreate.INSURANCE) && (
                  <Grid item>
                    <StyledButton
                      component={NavLink}
                      to={getReleaseRoute(
                        "insurances.create",
                        Number(id),
                        topicId
                      )}
                      size="small"
                      color="success"
                      variant="contained"
                      sx={{ mr: 2 }}
                    >
                      {t("offers.add")}
                    </StyledButton>
                  </Grid>
                )}
                <Grid item>
                  {
                    /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                      RELEASE_BUTTONS_FR,
                      configuration
                    ) ? (
                      <DocumentRelease
                        id={insuranceTopic?.id}
                        releaser={null}
                        releaseStatus={null}
                        forwardedAt={insuranceTopic?.fr_forwarded_at}
                        forwardedBy={insuranceTopic?.fr_forwarded_by}
                        overdue={insuranceTopic?.fr_overdue}
                        afterSuccess={fetchInsuranceTopics}
                        handleReleaseDocument={handleReleaseDocument}
                        handleForwardDocument={handleForwardDocument}
                        level={RELEASE_LEVEL.FIRST_LEVEL_RELEASE}
                        hasForwardPermission={
                          releasePermission[
                            DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                          ] ||
                          releasePermission[
                            DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                          ] ||
                          releasePermission[
                            DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                          ](insuranceTopic.assigned_user)
                        }
                        hasReleasePermission={
                          releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL]
                        }
                        relation={DOCUMENT_RELEASE_MODULE.INSURANCE_TOPIC}
                        loading={isDocumentReleaseLoading}
                        isButtonVisible={{
                          pendingButton: false,
                          rejectButton: false,
                          releaseButton: false,
                        }}
                      />
                    ) : null
                  }
                </Grid>
                <Grid item>
                  {
                    /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                      RELASE_BUTTONS_AM,
                      configuration
                    ) ? (
                      <DocumentRelease
                        id={insuranceTopic?.id}
                        releaser={null}
                        releaseStatus={null}
                        forwardedAt={insuranceTopic?.am_forwarded_at}
                        forwardedBy={insuranceTopic?.am_forwarded_by}
                        overdue={insuranceTopic?.am_overdue}
                        afterSuccess={fetchInsuranceTopics}
                        handleReleaseDocument={handleReleaseDocument}
                        handleForwardDocument={handleForwardDocument}
                        level={RELEASE_LEVEL.SECOND_LEVEL_RELEASE}
                        hasForwardPermission={
                          releasePermission[
                            DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                          ] ||
                          releasePermission[
                            DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                          ] ||
                          releasePermission[
                            DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                          ](insuranceTopic.assigned_user)
                        }
                        hasReleasePermission={
                          releasePermission[DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL]
                        }
                        relation={DOCUMENT_RELEASE_MODULE.INSURANCE_TOPIC}
                        loading={isDocumentReleaseLoading}
                        isButtonVisible={{
                          pendingButton: false,
                          rejectButton: false,
                          releaseButton: false,
                        }}
                      />
                    ) : null
                  }
                </Grid>
                <Grid item>
                  {
                    /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                      RELASE_BUTTONS_PM,
                      configuration
                    ) ? (
                      <DocumentRelease
                        id={insuranceTopic?.id}
                        releaser={null}
                        releaseStatus={null}
                        forwardedAt={insuranceTopic?.pm_forwarded_at}
                        forwardedBy={insuranceTopic?.pm_forwarded_by}
                        overdue={insuranceTopic?.pm_overdue}
                        afterSuccess={fetchInsuranceTopics}
                        handleReleaseDocument={handleReleaseDocument}
                        handleForwardDocument={handleForwardDocument}
                        level={RELEASE_LEVEL.THIRD_LEVEL_RELEASE}
                        hasForwardPermission={
                          releasePermission[
                            DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                          ] ||
                          releasePermission[
                            DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                          ] ||
                          releasePermission[
                            DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                          ](insuranceTopic.assigned_user)
                        }
                        hasReleasePermission={
                          releasePermission[DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL]
                        }
                        relation={DOCUMENT_RELEASE_MODULE.INSURANCE_TOPIC}
                        loading={isDocumentReleaseLoading}
                        isButtonVisible={{
                          pendingButton: false,
                          rejectButton: false,
                          releaseButton: false,
                        }}
                      />
                    ) : null
                  }
                </Grid>
                <Grid item>
                  {
                    /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                      RELEASE_BUTTONS_USER,
                      configuration
                    ) ? (
                      <DocumentRelease
                        id={insuranceTopic?.id}
                        releaser={null}
                        releaseStatus={null}
                        forwardedAt={insuranceTopic?.au_forwarded_at}
                        forwardedBy={insuranceTopic?.au_forwarded_by}
                        overdue={insuranceTopic?.au_overdue}
                        afterSuccess={fetchInsuranceTopics}
                        handleReleaseDocument={handleReleaseDocument}
                        handleForwardDocument={handleForwardDocument}
                        level={RELEASE_LEVEL.FOURTH_LEVEL_RELEASE}
                        hasForwardPermission={
                          releasePermission[
                            DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                          ] ||
                          releasePermission[
                            DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                          ] ||
                          releasePermission[DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL]
                        }
                        hasReleasePermission={releasePermission[
                          DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                        ](null)}
                        relation={DOCUMENT_RELEASE_MODULE.INSURANCE_TOPIC}
                        loading={isDocumentReleaseLoading}
                        isButtonVisible={{
                          pendingButton: false,
                          rejectButton: false,
                          releaseButton: false,
                        }}
                        assignedUser={insuranceTopic.assigned_user}
                        hasAssignUserPermission={can(
                          PermissionsUpdate.INSURANCE
                        )}
                      />
                    ) : null
                  }
                </Grid>
                <Grid item>
                  <StyledButton
                    data-testid="request-release-button"
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handleTopicReleaseRequest}
                    sx={{ mr: 2 }}
                    disabled={isSentFinalReleaserEmails}
                  >
                    {t("insurance.requestRelease")}
                  </StyledButton>
                </Grid>
              </>
            )}
            {can(PermissionsDelete.INSURANCE) && (
              <Grid item>
                <StyledButton
                  data-testid="delete-topic-button"
                  size="small"
                  color="error"
                  variant="contained"
                  onClick={openConfirmToRemoveTopicModal}
                  sx={{ mr: 2 }}
                >
                  {t("offers.delete")}
                </StyledButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InsuranceListToolbar;
