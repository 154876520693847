import React, { useEffect } from "react";
import {
  REACT_APP_ENVIRONMENT,
  REACT_APP_TAWK_BASE_PATH,
  REACT_APP_TAWK_PROPERTY_ID,
  REACT_APP_TAWK_WIDGET_ID,
} from "../../config";
import { loadScript } from "./utils";
function TawkMessenger(): React.ReactElement {
  /* istanbul ignore next */
  useEffect(() => {
    REACT_APP_ENVIRONMENT !== 'local' &&
      REACT_APP_TAWK_PROPERTY_ID &&
      REACT_APP_TAWK_WIDGET_ID &&
      REACT_APP_TAWK_BASE_PATH &&
      loadScript({
        propertyId: REACT_APP_TAWK_PROPERTY_ID,
        widgetId: REACT_APP_TAWK_WIDGET_ID,
        basePath: REACT_APP_TAWK_BASE_PATH,
      });
  }, []);

  return <></>;
}

export default TawkMessenger;
