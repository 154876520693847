import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useFetch } from "../../../../hooks/useFetch";
import { getContractById } from "../../../../api/contracts";
import { ErrorBox } from "../../objectDataSheet/tabs/generalInformation/styled";
import {
  COMMENTABLE,
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  FormMessageErrorState,
  Measurement_Units,
  THOUSAND_SEPARATOR,
} from "../../../../constants";
import { LoadingBox } from "../../../contacts/styled";
import Loader from "../../../Loader";
import { route } from "../../../../utils/url";
import { useTranslation } from "react-i18next";
import { Contract } from "../../../../types/be/contract";
import { LabelText } from "../../invoices/show/styled";
import { formatTimestamp } from "../../../../utils/date";
import Comments from "../../../../ui/сomments";
import useComments from "../../../../ui/сomments/useComments";
import {
  findReleaseLevelName,
  getReleaseRoute,
  isAvailableReleaseButtonTableCell,
  RELASE_BUTTONS_AM,
  RELASE_BUTTONS_PM,
  RELEASE_BUTTONS_FR,
  RELEASE_BUTTONS_USER,
} from "../../utils";
import { NumericFormat } from "react-number-format";
import { RELEASE_LEVEL } from "../../../../utils/common";
import {
  DOCUMENT_RELEASE_LEVEL,
  DOCUMENT_RELEASE_MODULE,
} from "../../../../types/be/releaseFlow";
import PdfViewer from "../../../../ui/pdfViewer";
import { HeaderText } from "../../edit/general/styled";
import DocumentRelease from "../../components/documentRelease";
import useDocumentRelease from "../../components/documentRelease/useDocumentRelease";
import TabPageWrapper from "../../../../ui/tabPageWrapper";

/* istanbul ignore next */
function ContractShow(): ReactElement {
  const { t } = useTranslation();
  const { id, contractId } = useParams();
  const [contractFileId, setContractFileId] = useState<number | undefined>();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const pdfContainerRef = useRef<HTMLObjectElement | null>(null);
  const { data: contract, isLoading, isError, run } = useFetch<Contract>();

  const {
    configuration,
    isConfigurationLoading,
    handleReleaseDocument,
    handleForwardDocument,
    releasePermission,
    snackbar,
    loading: isDocumentReleaseLoading,
  } = useDocumentRelease(DOCUMENT_RELEASE_MODULE.CONTRACT);

  const getData = async (): Promise<void> => {
    run(getContractById(contractId!));
  };
  const contractFile = useMemo(() => {
    return { fileId: contractFileId };
  }, [contractFileId]);

  useEffect(() => {
    contract && contract.file && setContractFileId(contract.file.id);
    !isDataLoaded && setIsDataLoaded(true);
  }, [contract]);
  const {
    comments,
    content,
    setContent,
    totalPages,
    handleCommentSave,
    handleCommentDelete,
    handlePaginationChange,
    currentPage,
    deleting,
    loading,
  } = useComments({
    relation: COMMENTABLE.CONTRACT,
    relation_id: contractId!,
  });

  useEffect(() => {
    getData();
  }, []);

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("contracts", Number(id)),
      name: t("property.navLinks.contracts"),
    },
  ];

  if (isError) {
    return <ErrorBox formMessage={FormMessageErrorState} />;
  }

  if (!isDataLoaded && (isLoading || isConfigurationLoading)) {
    return (
      <div data-testid="loader">
        <LoadingBox>
          <Loader />
        </LoadingBox>
      </div>
    );
  }
  return (
    <>
      {contract && (
        <TabPageWrapper
          title={contract.name!}
          breadcrumbs={breadcrumbs}
          wrap={!id}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12} p={2}>
                  <Grid item xs={12} ref={pdfContainerRef}>
                    <PdfViewer
                      file={contractFile}
                      containerRef={pdfContainerRef}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <Paper variant="outlined" sx={{ p: 4 }}>
                        <Grid
                          container
                          justifyContent={"center"}
                          spacing={4}
                          sx={{ textAlign: "left" }}
                        >
                          <Grid item xs={12}>
                            {contract.file ? (
                              <HeaderText>
                                <Link href={contract.file.url}>
                                  {contract.file.name}
                                </Link>
                              </HeaderText>
                            ) : (
                              <HeaderText>{EMPTY_DATA}</HeaderText>
                            )}
                            <LabelText>
                              {t("contracts.startDate")}:{" "}
                              {formatTimestamp(
                                contract.start_date,
                                "MM/DD/YYYY"
                              )}
                              {" - "}
                              {t("contracts.endDate")}:{" "}
                              {formatTimestamp(contract.end_date, "MM/DD/YYYY")}
                            </LabelText>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          justifyContent={"center"}
                          spacing={4}
                          mt={6}
                        >
                          <Grid item xs={12} md={4}>
                            <HeaderText>
                              <NumericFormat
                                thousandSeparator={THOUSAND_SEPARATOR}
                                decimalSeparator={DECIMAL_SEPARATOR}
                                decimalScale={DECIMAL_SCALE}
                                fixedDecimalScale
                                displayType={"text"}
                                value={contract.amount}
                              />{" "}
                              {contract.amount !== null &&
                                Measurement_Units.EURO}
                            </HeaderText>
                            <LabelText>{t("contracts.amount")}</LabelText>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <HeaderText>
                              {contract.created_by
                                ? `
                              ${contract.created_by?.first_name}
                               ${contract.created_by?.last_name}`
                                : EMPTY_DATA}
                            </HeaderText>
                            <LabelText>{t("contracts.createdBy")}</LabelText>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <HeaderText>{contract?.category?.name}</HeaderText>
                            <LabelText>{t("contracts.category")}</LabelText>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          justifyContent={"center"}
                          spacing={4}
                          mt={6}
                        >
                          <Grid item xs={12} md={4}>
                            <HeaderText>
                              {formatTimestamp(
                                contract.cancellation_date,
                                "MM/DD/YYYY"
                              )}
                            </HeaderText>
                            <LabelText>
                              {t("contracts.cancellationUpTo")}
                            </LabelText>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <HeaderText>
                              {contract.am
                                ? `${contract.am?.first_name} ${contract.am?.last_name}`
                                : EMPTY_DATA}
                            </HeaderText>
                            <LabelText>{t("contracts.assetManager")}</LabelText>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <HeaderText>
                              {contract.contact
                                ? `${contract.contact?.first_name} ${contract.contact?.last_name}`
                                : EMPTY_DATA}
                            </HeaderText>
                            <LabelText>{t("contracts.contactName")}</LabelText>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          justifyContent={"center"}
                          spacing={4}
                          mt={6}
                        >
                          <Grid item xs={12} md={8}>
                            <HeaderText>{contract.name}</HeaderText>
                            <LabelText>{t("contracts.objectName")}</LabelText>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <HeaderText>
                              {formatTimestamp(
                                contract.cancelled_at,
                                "MM/DD/YYYY"
                              )}
                            </HeaderText>
                            <LabelText>{t("contracts.canceledAt")}</LabelText>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper variant="outlined" sx={{ pr: 4, mt: 4 }}>
                        <Grid container justifyContent={"center"}>
                          <Grid item xs={12} md={6}>
                            <Table aria-label="contract-show">
                              <TableBody>
                                {
                                  /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                                    RELEASE_BUTTONS_USER,
                                    configuration
                                  ) ? (
                                    <TableRow>
                                      <TableCell
                                        scope="row"
                                        sx={{ border: "none" }}
                                      >
                                        <LabelText>
                                          {findReleaseLevelName(
                                            configuration,
                                            RELEASE_BUTTONS_USER
                                          )}
                                        </LabelText>
                                      </TableCell>
                                      <TableCell
                                        align={"center"}
                                        scope="row"
                                        sx={{ border: "none" }}
                                      >
                                        <DocumentRelease
                                          id={contract?.id}
                                          releaser={contract?.au}
                                          releaseStatus={contract?.au_status}
                                          forwardedAt={
                                            contract?.au_forwarded_at
                                          }
                                          forwardedBy={
                                            contract?.au_forwarded_by
                                          }
                                          overdue={contract?.au_overdue}
                                          afterSuccess={getData}
                                          handleReleaseDocument={
                                            handleReleaseDocument
                                          }
                                          handleForwardDocument={
                                            handleForwardDocument
                                          }
                                          level={
                                            RELEASE_LEVEL.FOURTH_LEVEL_RELEASE
                                          }
                                          hasForwardPermission={
                                            releasePermission[
                                              DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                            ] ||
                                            releasePermission[
                                              DOCUMENT_RELEASE_LEVEL
                                                .SECOND_LEVEL
                                            ] ||
                                            releasePermission[
                                              DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                            ]
                                          }
                                          hasReleasePermission={releasePermission[
                                            DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                          ](contract?.assigned_user)}
                                          relation={
                                            DOCUMENT_RELEASE_MODULE.CONTRACT
                                          }
                                          assignedUser={contract?.assigned_user}
                                          loading={isDocumentReleaseLoading}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ) : null
                                }
                                {
                                  /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                                    RELEASE_BUTTONS_FR,
                                    configuration
                                  ) ? (
                                    <TableRow>
                                      <TableCell
                                        scope="row"
                                        sx={{ border: "none" }}
                                      >
                                        <LabelText>
                                          {findReleaseLevelName(
                                            configuration,
                                            RELEASE_BUTTONS_FR
                                          )}
                                        </LabelText>
                                      </TableCell>
                                      <TableCell
                                        align={"center"}
                                        scope="row"
                                        sx={{ border: "none" }}
                                      >
                                        <DocumentRelease
                                          id={contract?.id}
                                          releaser={contract?.fr}
                                          releaseStatus={contract?.fr_status}
                                          forwardedAt={
                                            contract?.fr_forwarded_at
                                          }
                                          forwardedBy={
                                            contract?.fr_forwarded_by
                                          }
                                          overdue={contract?.fr_overdue}
                                          afterSuccess={getData}
                                          handleReleaseDocument={
                                            handleReleaseDocument
                                          }
                                          handleForwardDocument={
                                            handleForwardDocument
                                          }
                                          level={
                                            RELEASE_LEVEL.FIRST_LEVEL_RELEASE
                                          }
                                          hasForwardPermission={
                                            releasePermission[
                                              DOCUMENT_RELEASE_LEVEL
                                                .SECOND_LEVEL
                                            ] ||
                                            releasePermission[
                                              DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                            ] ||
                                            releasePermission[
                                              DOCUMENT_RELEASE_LEVEL
                                                .FOURTH_LEVEL
                                            ](contract?.assigned_user)
                                          }
                                          hasReleasePermission={
                                            releasePermission[
                                              DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                            ]
                                          }
                                          relation={
                                            DOCUMENT_RELEASE_MODULE.CONTRACT
                                          }
                                          loading={isDocumentReleaseLoading}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ) : null
                                }
                              </TableBody>
                            </Table>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Table aria-label="contract-show">
                              <TableBody>
                                {
                                  /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                                    RELASE_BUTTONS_AM,
                                    configuration
                                  ) ? (
                                    <TableRow>
                                      <TableCell
                                        scope="row"
                                        sx={{ border: "none" }}
                                      >
                                        <LabelText>
                                          {findReleaseLevelName(
                                            configuration,
                                            RELASE_BUTTONS_AM
                                          )}
                                        </LabelText>
                                      </TableCell>
                                      <TableCell
                                        align={"center"}
                                        scope="row"
                                        sx={{ border: "none" }}
                                      >
                                        <DocumentRelease
                                          id={contract?.id}
                                          releaser={contract?.am}
                                          releaseStatus={contract?.am_status}
                                          forwardedAt={
                                            contract?.am_forwarded_at
                                          }
                                          forwardedBy={
                                            contract?.am_forwarded_by
                                          }
                                          overdue={contract?.am_overdue}
                                          afterSuccess={getData}
                                          handleReleaseDocument={
                                            handleReleaseDocument
                                          }
                                          handleForwardDocument={
                                            handleForwardDocument
                                          }
                                          level={
                                            RELEASE_LEVEL.SECOND_LEVEL_RELEASE
                                          }
                                          hasForwardPermission={
                                            releasePermission[
                                              DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                            ] ||
                                            releasePermission[
                                              DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                            ] ||
                                            releasePermission[
                                              DOCUMENT_RELEASE_LEVEL
                                                .FOURTH_LEVEL
                                            ](contract?.assigned_user)
                                          }
                                          hasReleasePermission={
                                            releasePermission[
                                              DOCUMENT_RELEASE_LEVEL
                                                .SECOND_LEVEL
                                            ]
                                          }
                                          relation={
                                            DOCUMENT_RELEASE_MODULE.CONTRACT
                                          }
                                          loading={isDocumentReleaseLoading}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ) : null
                                }
                                {
                                  /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                                    RELASE_BUTTONS_PM,
                                    configuration
                                  ) && (
                                    <TableRow sx={{ border: "none" }}>
                                      <TableCell
                                        scope="row"
                                        sx={{ border: "none" }}
                                      >
                                        <LabelText>
                                          {findReleaseLevelName(
                                            configuration,
                                            RELASE_BUTTONS_PM
                                          )}
                                        </LabelText>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{ border: "none" }}
                                      >
                                        <DocumentRelease
                                          id={contract?.id}
                                          releaser={contract?.pm}
                                          releaseStatus={contract?.pm_status}
                                          forwardedAt={
                                            contract?.pm_forwarded_at
                                          }
                                          forwardedBy={
                                            contract?.pm_forwarded_by
                                          }
                                          overdue={contract?.pm_overdue}
                                          afterSuccess={getData}
                                          handleReleaseDocument={
                                            handleReleaseDocument
                                          }
                                          handleForwardDocument={
                                            handleForwardDocument
                                          }
                                          level={
                                            RELEASE_LEVEL.THIRD_LEVEL_RELEASE
                                          }
                                          hasForwardPermission={
                                            releasePermission[
                                              DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                            ] ||
                                            releasePermission[
                                              DOCUMENT_RELEASE_LEVEL
                                                .SECOND_LEVEL
                                            ] ||
                                            releasePermission[
                                              DOCUMENT_RELEASE_LEVEL
                                                .FOURTH_LEVEL
                                            ](contract?.assigned_user)
                                          }
                                          hasReleasePermission={
                                            releasePermission[
                                              DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                            ]
                                          }
                                          relation={
                                            DOCUMENT_RELEASE_MODULE.CONTRACT
                                          }
                                          loading={isDocumentReleaseLoading}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  )
                                }
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} pt={4}>
                      <Comments
                        editorContent={content}
                        setEditorContent={setContent}
                        handleSaveComment={handleCommentSave}
                        comments={comments}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        handleDeleteComment={handleCommentDelete}
                        handlePaginationChange={handlePaginationChange}
                        deleting={deleting}
                        loading={loading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {snackbar.Snackbar}
            </Grid>
          </Grid>
        </TabPageWrapper>
      )}
    </>
  );
}

export default ContractShow;
