import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Tooltip,
  Paper,
  Link,
  FormControlLabel,
  Box,
} from "@mui/material";
import { useFetch } from "../../../../hooks/useFetch";
import {
  getDateVInvoiceById,
  toogleInvoicePaid,
  updateInvoice,
} from "../../../../api/invoices";
import { ErrorBox } from "../../objectDataSheet/tabs/generalInformation/styled";
import {
  COMMENTABLE,
  DECIMAL_SCALE,
  DECIMAL_SEPARATOR,
  EMPTY_DATA,
  FormMessageErrorState,
  Measurement_Units,
  PermissionsUpdate,
  THOUSAND_SEPARATOR,
} from "../../../../constants";
import { LoadingBox } from "../../../contacts/styled";
import Loader from "../../../Loader";
import { route } from "../../../../utils/url";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "../../../../utils/date";
import { IconWrapper, LabelText } from "../show/styled";
import { InlineBlock, IsActiveIcon, TextEllipsis } from "../../../../styled";
import { CheckCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import Comments from "../../../../ui/сomments";
import useComments from "../../../../ui/сomments/useComments";
import {
  findReleaseLevelName,
  getReleaseRoute,
  isAvailableReleaseButtonTableCell,
  RELASE_BUTTONS_AM,
  RELASE_BUTTONS_PM,
  RELEASE_BUTTONS_FR,
  RELEASE_BUTTONS_USER,
} from "../../utils";
import { NumericFormat } from "react-number-format";
import { RELEASE_LEVEL } from "../../../../utils/common";
import useAccessControl from "../../../../hooks/useAccessControl";
import {
  DOCUMENT_RELEASE_LEVEL,
  DOCUMENT_RELEASE_MODULE,
} from "../../../../types/be/releaseFlow";
import { Android12Switch } from "../../../../ui/formsFields/switch/styled";
import PaidBy from "../list/paidBy";
import PdfViewer from "../../../../ui/pdfViewer";
import { HeaderText } from "../../edit/general/styled";
import { DateVInvoice } from "../../../../types/be/invoice";
import DocumentRelease from "../../components/documentRelease";
import useDocumentRelease from "../../components/documentRelease/useDocumentRelease";
import TabPageWrapper from "../../../../ui/tabPageWrapper";

/* istanbul ignore next */
function ShowDateVInvoice(): ReactElement {
  const { t } = useTranslation();
  const { id, invoiceId } = useParams();
  const { can } = useAccessControl();
  const [isBookedError, setIsBookedError] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const pdfContainerRef = useRef<HTMLObjectElement | null>(null);
  const [invoiceFildId, setInvoiceFileId] = useState<number | undefined>();
  const {
    data: dateVInvoice,
    isLoading,
    isError,
    run,
  } = useFetch<DateVInvoice>();
  const {
    configuration,
    isConfigurationLoading,
    handleReleaseDocument,
    handleForwardDocument,
    releasePermission,
    snackbar,
    loading: isDocumentReleaseLoading,
  } = useDocumentRelease(DOCUMENT_RELEASE_MODULE.INVOICE);

  const {
    comments,
    content,
    setContent,
    totalPages,
    handleCommentSave,
    handleCommentDelete,
    handlePaginationChange,
    currentPage,
    deleting,
    loading,
  } = useComments({
    relation: COMMENTABLE.INVOICE,
    relation_id: invoiceId!,
  });

  const getData = async (): Promise<void> => {
    await run(getDateVInvoiceById(invoiceId!));
    setIsDataLoaded(true);
  };

  useEffect(() => {
    dateVInvoice &&
      dateVInvoice?.file?.id &&
      setInvoiceFileId(dateVInvoice?.file?.id);
    dateVInvoice && setIsBooked(dateVInvoice?.invoice?.is_booked);
    dateVInvoice && setIsPaid(dateVInvoice?.invoice?.is_paid);
  }, [dateVInvoice]);

  useEffect(() => {
    getData();
  }, [id, invoiceId]);

  const markAsBooked = async (
    invoiceId: number,
    booked: boolean
  ): Promise<void> => {
    setIsBooked(!isBooked);
    const response = await updateInvoice({
      id: invoiceId,
      is_booked: !booked,
    });
    if (response.ok) {
      setIsBookedError(false);
      await getData();
    } else {
      setIsBookedError(true);
    }
  };

  const moveToPaid = async (invoiceId: number, paid: number): Promise<void> => {
    setIsPaid(!isPaid);
    await toogleInvoicePaid(invoiceId, paid);
    await getData();
  };

  const invoiceFile = useMemo(() => {
    return { fileId: invoiceFildId };
  }, [invoiceFildId]);

  if (isError || isBookedError) {
    return <ErrorBox formMessage={FormMessageErrorState} />;
  }

  if (!isDataLoaded && (isLoading || !dateVInvoice || isConfigurationLoading)) {
    return (
      <div data-testid="loader">
        <LoadingBox>
          <Loader />
        </LoadingBox>
      </div>
    );
  }
  /* istanbul ignore next */
  if (!dateVInvoice) {
    return <></>;
  }

  const breadcrumbs = [
    {
      to: route("root"),
      name: "Dashboard",
    },
    {
      to: getReleaseRoute("invoices", Number(id)),
      name: t("property.navLinks.invoices"),
    },
  ];

  return (
    <TabPageWrapper
      title={dateVInvoice?.invoice?.name}
      breadcrumbs={breadcrumbs}
      wrap={!id}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} xl={9}>
          <Grid container spacing={4}>
            <Grid item md={4.3} xs={12} p={4}>
              <Grid container spacing={4}>
                <Grid item xs={12} ref={pdfContainerRef}>
                  <PdfViewer
                    file={invoiceFile}
                    containerRef={pdfContainerRef}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={7.7}>
              <Paper variant="outlined" sx={{ p: 4, textAlign: "center" }}>
                <Grid
                  container
                  justifyContent={"center"}
                  spacing={4}
                  sx={{ textAlign: "left" }}
                >
                  <Grid item xs={12} sm={6}>
                    <HeaderText> {dateVInvoice?.invoice.number} </HeaderText>
                    <LabelText>
                      <Link
                        href={dateVInvoice?.file?.url}
                        target="_blank"
                        sx={{
                          wordBreak: "break-all",
                          fontSize: "11px",
                        }}
                      >
                        {dateVInvoice?.file?.name}
                      </Link>{" "}
                      | {formatTimestamp(dateVInvoice?.invoice?.date)}
                    </LabelText>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelText>
                      {t("documentRelease.invoice.iban")}:{" "}
                      {dateVInvoice?.invoice?.iban || EMPTY_DATA}
                    </LabelText>
                    <LabelText>
                      {t("documentRelease.invoice.bic")}:{" "}
                      {dateVInvoice?.invoice?.bic || EMPTY_DATA}
                    </LabelText>
                  </Grid>
                </Grid>
                <Grid container justifyContent={"center"} mt={6}>
                  <Grid item xs={4} sm={4}>
                    <HeaderText>
                      <NumericFormat
                        thousandSeparator={THOUSAND_SEPARATOR}
                        decimalSeparator={DECIMAL_SEPARATOR}
                        decimalScale={DECIMAL_SCALE}
                        fixedDecimalScale
                        displayType={"text"}
                        value={dateVInvoice?.invoice.amount}
                      />{" "}
                      {dateVInvoice?.invoice.amount !== null &&
                        Measurement_Units.EURO}
                    </HeaderText>
                    <LabelText>{t("amount")}</LabelText>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <HeaderText>
                      {dateVInvoice?.invoice?.contact ? (
                        <Link
                          href={route(
                            "contacts.edit.first-tab",
                            dateVInvoice?.contact_id
                          )}
                          target="_blank"
                        >
                          {`${dateVInvoice?.invoice?.contact?.first_name} ${dateVInvoice?.invoice?.contact?.last_name}`}
                        </Link>
                      ) : (
                        EMPTY_DATA
                      )}
                    </HeaderText>
                    <LabelText>{t("contact")}</LabelText>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <HeaderText>
                      {dateVInvoice?.invoice?.created_by
                        ? `${dateVInvoice?.invoice?.created_by?.first_name} ${dateVInvoice?.invoice.created_by?.last_name}`
                        : /* istanbul ignore next */ EMPTY_DATA}
                    </HeaderText>
                    <LabelText>{t("createdBy")}</LabelText>
                  </Grid>
                </Grid>

                <Grid container justifyContent={"center"} mt={6}>
                  <Grid item xs={4} sm={4}>
                    <HeaderText>
                      {dateVInvoice?.invoice?.apportionable_percent
                        ? `${dateVInvoice?.invoice?.apportionable_percent} %`
                        : EMPTY_DATA}
                    </HeaderText>
                    <LabelText>
                      {t("documentRelease.invoice.apportionablePercent")}
                    </LabelText>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <HeaderText>
                      <Link
                        href={route(
                          "properties.edit.main",
                          dateVInvoice.property?.id
                        )}
                        target="_blank"
                      >
                        {dateVInvoice.property?.object_name}
                      </Link>
                    </HeaderText>
                    <LabelText>
                      {t("documentRelease.invoice.show.propertyName")}
                    </LabelText>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {dateVInvoice?.invoice?.contract &&
                    dateVInvoice?.invoice?.contract?.file ? (
                      <Tooltip
                        title={dateVInvoice?.invoice?.contract?.file?.name}
                      >
                        <TextEllipsis>
                          <Link
                            href={dateVInvoice?.invoice?.contract?.file?.url}
                            target="_blank"
                          >
                            <HeaderText>
                              {dateVInvoice?.invoice?.contract?.file?.name}
                            </HeaderText>
                          </Link>
                        </TextEllipsis>
                      </Tooltip>
                    ) : (
                      <HeaderText>{EMPTY_DATA}</HeaderText>
                    )}
                    <LabelText>{t("contracts.contract")}</LabelText>
                  </Grid>
                </Grid>
                <Grid container justifyContent={"center"} mt={6}>
                  <Grid item xs={4} sm={4}>
                    <HeaderText>
                      <NumericFormat
                        thousandSeparator={THOUSAND_SEPARATOR}
                        decimalSeparator={DECIMAL_SEPARATOR}
                        decimalScale={DECIMAL_SCALE}
                        fixedDecimalScale
                        displayType={"text"}
                        value={dateVInvoice?.invoice?.total}
                      />{" "}
                      {dateVInvoice?.invoice.total !== null &&
                        Measurement_Units.EURO}
                    </HeaderText>
                    <LabelText>{t("total")}</LabelText>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <HeaderText>
                      {dateVInvoice?.invoice?.area?.name || EMPTY_DATA}
                    </HeaderText>
                    <LabelText>{t("areaName")}</LabelText>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    {dateVInvoice?.invoice?.offer &&
                    dateVInvoice?.invoice?.offer?.file ? (
                      <Tooltip title={dateVInvoice?.invoice?.offer?.file?.name}>
                        <TextEllipsis w="100%">
                          <Link
                            href={dateVInvoice?.invoice?.offer?.file?.url}
                            target="_blank"
                          >
                            <HeaderText>
                              {dateVInvoice?.invoice.offer?.file?.name}
                            </HeaderText>
                          </Link>
                        </TextEllipsis>
                      </Tooltip>
                    ) : (
                      <HeaderText>{EMPTY_DATA}</HeaderText>
                    )}
                    <LabelText>{t("offerFileName")}</LabelText>
                  </Grid>
                </Grid>

                <Grid container justifyContent={"center"} mt={6}>
                  <Grid item xs={4} sm={4}>
                    <HeaderText>
                      {" "}
                      {formatTimestamp(dateVInvoice?.invoice?.created_at)}
                    </HeaderText>
                    <LabelText>
                      {t("documentRelease.invoice.show.createdAt")}
                    </LabelText>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <HeaderText>
                      {dateVInvoice?.invoice?.due_date
                        ? formatTimestamp(dateVInvoice?.invoice.due_date)
                        : EMPTY_DATA}
                    </HeaderText>
                    <LabelText>{t("dueDate")}</LabelText>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <HeaderText>
                      {dateVInvoice?.invoice?.release_date
                        ? formatTimestamp(dateVInvoice?.invoice?.release_date)
                        : EMPTY_DATA}
                    </HeaderText>
                    <LabelText>
                      {" "}
                      {t("documentRelease.invoice.releaseDate")}
                    </LabelText>
                  </Grid>
                </Grid>
                <Grid container justifyContent={"flex-start"} mt={6}>
                  <Grid item xs={4} sm={4}>
                    <HeaderText>
                      {" "}
                      {dateVInvoice?.booking_text || EMPTY_DATA}
                    </HeaderText>
                    <LabelText>
                      {t("documentRelease.invoice.dateVInvoice.bookingText")}
                    </LabelText>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                variant="outlined"
                sx={{ p: 4, textAlign: "center", mt: 4 }}
              >
                <Grid
                  container
                  justifyContent={"flex-start"}
                  spacing={4}
                  sx={{ textAlign: "left", pb: 2 }}
                >
                  <Grid item xs={12} sm={6}>
                    <HeaderText>
                      {t("documentRelease.invoice.dateVInvoice.postingItems")}
                    </HeaderText>
                  </Grid>
                </Grid>
                {dateVInvoice?.positions?.map((position) => (
                  <Grid
                    container
                    key={position?.id}
                    borderBottom={"1px solid gray"}
                    py={2}
                  >
                    <Grid container justifyContent={"center"}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          <NumericFormat
                            thousandSeparator={THOUSAND_SEPARATOR}
                            decimalSeparator={DECIMAL_SEPARATOR}
                            decimalScale={DECIMAL_SCALE}
                            fixedDecimalScale
                            displayType={"text"}
                            value={position?.amount_netto}
                          />{" "}
                          {dateVInvoice?.invoice.amount !== null &&
                            Measurement_Units.EURO}
                        </HeaderText>
                        <LabelText>
                          {t("documentRelease.invoice.dateVInvoice.netAmount")}
                        </LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          <NumericFormat
                            thousandSeparator={THOUSAND_SEPARATOR}
                            decimalSeparator={DECIMAL_SEPARATOR}
                            decimalScale={DECIMAL_SCALE}
                            fixedDecimalScale
                            displayType={"text"}
                            value={position?.amount_brutto}
                          />{" "}
                          {dateVInvoice?.invoice.amount !== null &&
                            Measurement_Units.EURO}
                        </HeaderText>
                        <LabelText>
                          {t(
                            "documentRelease.invoice.dateVInvoice.amountGross"
                          )}
                        </LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          <NumericFormat
                            thousandSeparator={THOUSAND_SEPARATOR}
                            decimalSeparator={DECIMAL_SEPARATOR}
                            decimalScale={DECIMAL_SCALE}
                            fixedDecimalScale
                            displayType={"text"}
                            value={position?.vat}
                          />{" "}
                          {position?.vat !== null &&
                            Measurement_Units.PERCENTAGE}
                        </HeaderText>
                        <LabelText>
                          {t("documentRelease.invoice.dateVInvoice.vatIncl")}
                        </LabelText>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent={"start"} mt={6}>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {position?.booking_text || EMPTY_DATA}
                        </HeaderText>
                        <LabelText>
                          {t(
                            "documentRelease.invoice.dateVInvoice.bookingText"
                          )}
                        </LabelText>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <HeaderText>
                          {position?.soll_konto?.konto || EMPTY_DATA}
                        </HeaderText>
                        <LabelText>
                          {t(
                            "documentRelease.invoice.dateVInvoice.debitAccount"
                          )}
                        </LabelText>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                <Grid container justifyContent={"flex-start"} mt={6}>
                  <Grid item xs={4} sm={4}>
                    <HeaderText>
                      <NumericFormat
                        thousandSeparator={THOUSAND_SEPARATOR}
                        decimalSeparator={DECIMAL_SEPARATOR}
                        decimalScale={DECIMAL_SCALE}
                        fixedDecimalScale
                        displayType={"text"}
                        value={dateVInvoice?.amount_netto}
                      />{" "}
                      {dateVInvoice?.invoice.amount !== null &&
                        Measurement_Units.EURO}
                    </HeaderText>
                    <LabelText>
                      {t(
                        "documentRelease.invoice.dateVInvoice.invoiceAmountNet"
                      )}
                    </LabelText>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <HeaderText>
                      <NumericFormat
                        thousandSeparator={THOUSAND_SEPARATOR}
                        decimalSeparator={DECIMAL_SEPARATOR}
                        decimalScale={DECIMAL_SCALE}
                        fixedDecimalScale
                        displayType={"text"}
                        value={dateVInvoice?.amount_brutto}
                      />{" "}
                      {dateVInvoice?.invoice.amount !== null &&
                        Measurement_Units.EURO}
                    </HeaderText>
                    <LabelText>
                      {t(
                        "documentRelease.invoice.dateVInvoice.grossInvoiceAmount"
                      )}
                    </LabelText>
                  </Grid>
                </Grid>
              </Paper>
              <Paper variant="outlined" sx={{ p: 4, mt: 4 }}>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={5} md={6}>
                        <LabelText>
                          {t("documentRelease.invoice.confidential")}
                        </LabelText>
                      </Grid>
                      <Grid item xs={5} md={6}>
                        <IconWrapper>
                          {dateVInvoice?.invoice?.is_confidential ? (
                            <CheckCircleOutline
                              color="success"
                              fontSize="medium"
                            />
                          ) : (
                            <RemoveCircleOutline
                              color="error"
                              fontSize="medium"
                            />
                          )}
                        </IconWrapper>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={3}>
                      <Grid item xs={5} md={6}>
                        <LabelText>
                          {t("documentRelease.invoice.apportionable")}
                        </LabelText>
                      </Grid>
                      <Grid item xs={6}>
                        <IconWrapper>
                          {dateVInvoice?.invoice?.is_apportionable ? (
                            <CheckCircleOutline
                              color="success"
                              fontSize="medium"
                            />
                          ) : (
                            <RemoveCircleOutline
                              color="error"
                              fontSize="medium"
                            />
                          )}
                        </IconWrapper>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={3}>
                      <Grid item xs={5} md={6}>
                        <LabelText>
                          {t("documentRelease.invoice.permanent")}
                        </LabelText>
                      </Grid>
                      <Grid item xs={6}>
                        <IconWrapper>
                          {dateVInvoice?.invoice?.is_permanent ? (
                            <CheckCircleOutline
                              color="success"
                              fontSize="medium"
                            />
                          ) : (
                            <RemoveCircleOutline
                              color="error"
                              fontSize="medium"
                            />
                          )}
                        </IconWrapper>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={3}>
                      <Grid item xs={5} md={6}>
                        <LabelText>
                          {t("documentRelease.invoice.debitedAutomatically")}
                        </LabelText>
                      </Grid>
                      <Grid item xs={5} md={6}>
                        <IconWrapper>
                          {dateVInvoice?.invoice.is_debited_automatically ? (
                            <CheckCircleOutline
                              color="success"
                              fontSize="medium"
                            />
                          ) : (
                            <RemoveCircleOutline
                              color="error"
                              fontSize="medium"
                            />
                          )}
                        </IconWrapper>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={3}>
                      <Grid item xs={5}>
                        <LabelText>
                          {t("documentRelease.invoice.markAsPaid")}
                        </LabelText>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Android12Switch
                                checked={
                                  isPaid ||
                                  Boolean(
                                    dateVInvoice?.invoice
                                      ?.is_debited_automatically
                                  )
                                }
                                onChange={() =>
                                  moveToPaid(
                                    dateVInvoice?.invoice?.id,
                                    isPaid ||
                                      Boolean(
                                        dateVInvoice?.invoice
                                          .is_debited_automatically
                                      )
                                      ? 0
                                      : 1
                                  )
                                }
                                data-testid="toggle-invoice-paid"
                                disabled={!can("invoice.accountant")}
                              />
                            }
                            label={t("documentRelease.invoice.paid")}
                            sx={{ margin: 0 }}
                          />
                          {(dateVInvoice?.invoice?.who_paid ||
                            Boolean(
                              dateVInvoice?.invoice?.is_debited_automatically
                            )) && (
                            <PaidBy
                              {...dateVInvoice?.invoice?.who_paid}
                              align="flex-start"
                              sx={{ pl: 3 }}
                              when_paid={dateVInvoice?.invoice?.when_paid}
                              is_debited_automatically={Boolean(
                                dateVInvoice?.invoice?.is_debited_automatically
                              )}
                            />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={3}>
                      <Grid item xs={5}>
                        <LabelText>
                          {t("documentRelease.invoice.markAsBooked")}
                        </LabelText>
                      </Grid>
                      <Grid item xs={7}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Android12Switch
                                checked={isBooked}
                                onChange={() =>
                                  markAsBooked(
                                    dateVInvoice?.invoice?.id,
                                    isBooked
                                  )
                                }
                                disabled={!can("invoice.accountant")}
                                data-testid="toggle-invoice-booked"
                              />
                            }
                            label={
                              isBooked
                                ? t("documentRelease.invoice.booked")
                                : t("documentRelease.invoice.notBooked")
                            }
                            sx={{ margin: 0 }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Grid container spacing={2} mb={4}>
                      <Grid item xs={5}>
                        <LabelText>{t("activeInvoice")}</LabelText>
                      </Grid>
                      <Grid item xs={6}>
                        <InlineBlock>
                          <IsActiveIcon
                            type={
                              dateVInvoice?.invoice.is_active
                                ? "success"
                                : "error"
                            }
                          />
                        </InlineBlock>
                      </Grid>
                    </Grid>
                    {
                      /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                        RELEASE_BUTTONS_FR,
                        configuration
                      ) ? (
                        <Grid container spacing={2}>
                          <Grid item xs>
                            <LabelText>
                              {findReleaseLevelName(
                                configuration,
                                RELEASE_BUTTONS_FR
                              )}
                            </LabelText>
                          </Grid>
                          <Grid item xs>
                            <DocumentRelease
                              id={dateVInvoice?.invoice?.id}
                              releaser={dateVInvoice?.invoice?.fr}
                              releaseStatus={dateVInvoice?.invoice?.fr_status}
                              forwardedAt={
                                dateVInvoice?.invoice?.fr_forwarded_at
                              }
                              forwardedBy={
                                dateVInvoice?.invoice?.fr_forwarded_by
                              }
                              overdue={dateVInvoice?.invoice?.fr_overdue}
                              afterSuccess={getData}
                              handleReleaseDocument={handleReleaseDocument}
                              handleForwardDocument={handleForwardDocument}
                              level={RELEASE_LEVEL.FIRST_LEVEL_RELEASE}
                              hasForwardPermission={
                                releasePermission[
                                  DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                ] ||
                                releasePermission[
                                  DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                ] ||
                                releasePermission[
                                  DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                ](dateVInvoice?.invoice?.assigned_user)
                              }
                              hasReleasePermission={
                                releasePermission[
                                  DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                ]
                              }
                              relation={DOCUMENT_RELEASE_MODULE.INVOICE}
                              loading={isDocumentReleaseLoading}
                            />
                          </Grid>
                        </Grid>
                      ) : null
                    }
                    {
                      /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                        RELASE_BUTTONS_PM,
                        configuration
                      ) ? (
                        <Grid container spacing={2} mt={3}>
                          <Grid item xs>
                            <LabelText>
                              {findReleaseLevelName(
                                configuration,
                                RELASE_BUTTONS_PM
                              )}
                            </LabelText>
                          </Grid>
                          <Grid item xs>
                            <DocumentRelease
                              id={dateVInvoice?.invoice?.id}
                              releaser={dateVInvoice?.invoice?.pm}
                              releaseStatus={dateVInvoice?.invoice?.pm_status}
                              forwardedAt={
                                dateVInvoice?.invoice?.pm_forwarded_at
                              }
                              forwardedBy={
                                dateVInvoice?.invoice?.pm_forwarded_by
                              }
                              overdue={dateVInvoice?.invoice?.pm_overdue}
                              afterSuccess={getData}
                              handleReleaseDocument={handleReleaseDocument}
                              handleForwardDocument={handleForwardDocument}
                              level={RELEASE_LEVEL.THIRD_LEVEL_RELEASE}
                              hasForwardPermission={
                                releasePermission[
                                  DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                ] ||
                                releasePermission[
                                  DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                ] ||
                                releasePermission[
                                  DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                ](dateVInvoice?.invoice?.assigned_user)
                              }
                              hasReleasePermission={
                                releasePermission[
                                  DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                ]
                              }
                              relation={DOCUMENT_RELEASE_MODULE.INVOICE}
                              loading={isDocumentReleaseLoading}
                            />
                          </Grid>
                        </Grid>
                      ) : null
                    }
                    {
                      /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                        RELASE_BUTTONS_AM,
                        configuration
                      ) ? (
                        <Grid container spacing={2} mt={3}>
                          <Grid item xs>
                            <LabelText>
                              {findReleaseLevelName(
                                configuration,
                                RELASE_BUTTONS_AM
                              )}
                            </LabelText>
                          </Grid>
                          <Grid item xs>
                            <DocumentRelease
                              id={dateVInvoice?.invoice?.id}
                              releaser={dateVInvoice?.invoice?.am}
                              releaseStatus={dateVInvoice?.invoice?.am_status}
                              forwardedAt={
                                dateVInvoice?.invoice?.am_forwarded_at
                              }
                              forwardedBy={
                                dateVInvoice?.invoice?.am_forwarded_by
                              }
                              overdue={dateVInvoice?.invoice?.am_overdue}
                              afterSuccess={getData}
                              handleReleaseDocument={handleReleaseDocument}
                              handleForwardDocument={handleForwardDocument}
                              level={RELEASE_LEVEL.SECOND_LEVEL_RELEASE}
                              hasForwardPermission={
                                releasePermission[
                                  DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                ] ||
                                releasePermission[
                                  DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                ] ||
                                releasePermission[
                                  DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                                ](dateVInvoice?.invoice?.assigned_user)
                              }
                              hasReleasePermission={
                                releasePermission[
                                  DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                ]
                              }
                              relation={DOCUMENT_RELEASE_MODULE.INVOICE}
                              loading={isDocumentReleaseLoading}
                            />
                          </Grid>
                        </Grid>
                      ) : null
                    }
                    {
                      /*istanbul ignore next */ isAvailableReleaseButtonTableCell(
                        RELEASE_BUTTONS_USER,
                        configuration
                      ) ? (
                        <Grid container spacing={2} mt={3}>
                          <Grid item xs>
                            <LabelText>
                              {findReleaseLevelName(
                                configuration,
                                RELEASE_BUTTONS_USER
                              )}
                            </LabelText>
                          </Grid>
                          <Grid item xs>
                            <DocumentRelease
                              id={dateVInvoice?.invoice?.id}
                              releaser={dateVInvoice?.invoice?.au}
                              releaseStatus={dateVInvoice?.invoice?.au_status}
                              forwardedAt={
                                dateVInvoice?.invoice?.au_forwarded_at
                              }
                              forwardedBy={
                                dateVInvoice?.invoice?.au_forwarded_by
                              }
                              overdue={dateVInvoice?.invoice?.au_overdue}
                              afterSuccess={getData}
                              handleReleaseDocument={handleReleaseDocument}
                              handleForwardDocument={handleForwardDocument}
                              level={RELEASE_LEVEL.FOURTH_LEVEL_RELEASE}
                              hasForwardPermission={
                                releasePermission[
                                  DOCUMENT_RELEASE_LEVEL.FIRST_LEVEL
                                ] ||
                                releasePermission[
                                  DOCUMENT_RELEASE_LEVEL.SECOND_LEVEL
                                ] ||
                                releasePermission[
                                  DOCUMENT_RELEASE_LEVEL.THIRD_LEVEL
                                ]
                              }
                              hasReleasePermission={releasePermission[
                                DOCUMENT_RELEASE_LEVEL.FOURTH_LEVEL
                              ](dateVInvoice?.invoice?.assigned_user)}
                              relation={DOCUMENT_RELEASE_MODULE.INVOICE}
                              assignedUser={
                                dateVInvoice?.invoice?.assigned_user
                              }
                              loading={isDocumentReleaseLoading}
                              hasAssignUserPermission={can(
                                PermissionsUpdate.DATEV_INVOICE
                              )}
                            />
                          </Grid>
                        </Grid>
                      ) : null
                    }
                  </Grid>
                </Grid>
              </Paper>
              {snackbar.Snackbar}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={3}>
          <Comments
            editorContent={content}
            setEditorContent={setContent}
            handleSaveComment={handleCommentSave}
            comments={comments}
            currentPage={currentPage}
            totalPages={totalPages}
            handleDeleteComment={handleCommentDelete}
            handlePaginationChange={handlePaginationChange}
            deleting={deleting}
            loading={loading}
          />
        </Grid>
      </Grid>
    </TabPageWrapper>
  );
}

export default ShowDateVInvoice;
