import React, { ReactElement } from "react";
import { TableCell, Tooltip, Typography } from "@mui/material";

import { AddressBox, ObjectLink } from "../styled";
import { route } from "../../../../utils/url";
import { EMPTY_DATA, PermissionsDelete } from "../../../../constants";
import { DeleteIcon } from "../../../../ui/table/styled";
import { ListViewProps } from "./types";
import useAccessControl from "../../../../hooks/useAccessControl";

/* istanbul ignore next */
const CompanyListView = ({
  row,
  handleCellClick,
  openConfirmToRemoveModal,
}: ListViewProps): ReactElement => {
  const { can } = useAccessControl();

  return (
    <>
      <TableCell align="left">
        <ObjectLink to={route("companies.edit", row.id)} key={row.id}>
          <Typography noWrap>{row.object_name}</Typography>
        </ObjectLink>
      </TableCell>
      <TableCell align="left">
        {row.location.full_address ? (
          <Tooltip title={`${row.location.full_address}`}>
            <AddressBox>{`${row.location.full_address}`}</AddressBox>
          </Tooltip>
        ) : (
          EMPTY_DATA
        )}
      </TableCell>
      <TableCell align="left">{row.notes ? row.notes : EMPTY_DATA}</TableCell>
      <TableCell
        align="right"
        onClick={(e: React.MouseEvent<HTMLTableCellElement>) =>
          handleCellClick(e)
        }
        style={{ whiteSpace: "nowrap" }}
      >
        {can(PermissionsDelete.PROPERTY) && (
          <DeleteIcon
            onClick={() => openConfirmToRemoveModal(row)}
            size={20}
            data-testid="delete-icon"
          />
        )}
      </TableCell>
    </>
  );
};

export default CompanyListView;
