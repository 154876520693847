import { ReleaseLevelPayload } from "../components/properties/components/documentRelease/types";
import { DOCUMENT_RELEASE_MODULE } from "../types/be/releaseFlow";
import {
  ReleaseConfiguration,
  ReleaseConfigurations,
} from "../types/fe/releaseFlow";
import { RELEASE_LEVEL } from "../utils/common";
import http from "../utils/http";
/* istanbul ignore next */
export const getModules = async (): Promise<Response> => {
  return await http.get("/api/modules");
};
/* istanbul ignore next */
export const getConfiguration = async (params: string): Promise<Response> => {
  return await http.get(`/api/configurations${params}`);
};
/* istanbul ignore next */
export const updateConfiguration = async (
  id: number,
  data: ReleaseConfiguration
): Promise<Response> => {
  return await http.put(`/api/configurations/${id}`, data);
};
/* istanbul ignore next */
export const multiUpdateConfiguration = async (
  data: ReleaseConfigurations
): Promise<Response> => {
  return await http.put("/api/configurations/multi-update", data);
};

/* istanbul ignore next */
export const updateReleaseLevel = async (
  module: DOCUMENT_RELEASE_MODULE,
  id: number | string,
  level: RELEASE_LEVEL,
  params: string
): Promise<Response> => {
  return await http.patch(`/api/${module}s/${id}/${level}${params}`, {});
};

/* istanbul ignore next */
export const forwardDocument = async (
  level: RELEASE_LEVEL,
  payload: ReleaseLevelPayload
): Promise<Response> => {
  return await http.patch(`/api/forward/${level}`, payload);
};
