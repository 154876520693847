import React, { ReactElement, useState, useEffect } from "react";
import Button from "../../../../ui/button/Button";
import FileInputField from "../../../../ui/formsFields/fileInput";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { FormikValues } from "formik/dist/types";
import { Form } from "formik";
import Snackbar from "../../../../ui/Snackbar";
import SelectOrCreateContactsAutocompleteField from "../../../contacts/autocomplete/index";
import {
  FORMAT_NUMBER_DE,
  Measurement_Units,
  OFFER_UPLOAD_FOLDER,
} from "../../../../constants";
import { FormatNumber } from "../../../../ui/formsFields/formatNumber";
import Dropdown from "../../../../ui/dropdown";
import { getAreas } from "../../../../api/area";
import { route } from "../../../../utils/url";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

function OfferForm({
  errors,
  status,
  handleBlur,
  handleChange,
  isSubmitting,
  handleSubmit,
  touched,
  values,
  propertyId,
  setFieldValue,
  fileName,
  setFileName,
  setFormInitState,
  offersTopicsPropertyId,
}: FormikValues): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const handleClose = (): void => setIsSnackbarVisible(false);
  useEffect(() => {
    setIsSnackbarVisible(status?.success);
  }, [status]);

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={6}>
        <Grid item sm={6} md={6}>
          {offersTopicsPropertyId || propertyId ? (
            <Dropdown
              id="property-area-autocomplete"
              name="area_id"
              value={values?.area_id || ""}
              label={t("counter.searchArea")}
              placeholder={t("counter.searchArea")}
              getOptions={getAreas}
              onSelectOption={(id?: string) => {
                setFieldValue("area_id", id);
              }}
              size="medium"
              defaultValue={values?.area}
              error={Boolean(status?.errors?.area_id || errors?.area_id)}
              helperText={errors?.area_id || status?.errors?.area_id}
              isAreaSearch={true}
              propertyId={offersTopicsPropertyId || propertyId}
              isShowAllOption={true}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FileInputField
            required
            name="file_id"
            label={t("offers.uploadfile")}
            error={Boolean(
              touched?.file_id && (status?.errors?.file_id || errors?.file_id)
            )}
            helperText={
              touched?.file_id && (status?.errors?.file_id || errors?.file_id)
            }
            foldername={OFFER_UPLOAD_FOLDER}
            propertyid={propertyId}
            testId="offer-file"
            inputProps={{ "data-testid": "offer-file-text" }}
            accept="application/pdf"
            errorText={t("offers.error.pdfOnly")}
            outerFileName={fileName}
            setOuterFileName={setFileName}
            setFormInitState={setFormInitState}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <SelectOrCreateContactsAutocompleteField
            {...{
              errors,
              values,
              touched,
              status,
              handleBlur,
              handleChange,
              setFieldValue,
            }}
            label={t("createContact.searchContact")}
            placeholder={t("createContact.searchContact")}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormatNumber
            name="amount"
            allowNegative
            label={t("offers.amount")}
            required
            size="medium"
            status={status}
            fullWidth
            inputProps={{
              "data-testid": "amount",
              filter: FORMAT_NUMBER_DE,
              unit: Measurement_Units.EURO,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FileInputField
            name="picture_id"
            label={t("offers.uploadPicture")}
            error={Boolean(
              touched.picture_id &&
                (status?.errors.picture_id || errors.picture_id)
            )}
            helperText={
              touched.picture_id &&
              (status?.errors.picture_id || errors.picture_id)
            }
            foldername="Fotos"
            propertyid={propertyId}
            testId="offer-picture"
            inputProps={{ "data-testid": "offer-picture-text" }}
          />
        </Grid>
      </Grid>
      <Grid container display="flex" justifyContent="space-between" mt={4}>
        <Grid item xs={1}>
          <Button
            type="button"
            color="primary"
            variant="text"
            title={t("cancel")}
            disabled={isSubmitting}
            size="large"
            onClick={
              /* istanbul ignore next */ () =>
                doesAnyHistoryEntryExist
                  ? navigate(-1)
                  : propertyId
                  ? navigate(route("properties.offers.new", propertyId))
                  : navigate(route("offers.new"))
            }
          />
        </Grid>
        <Grid item xs={1}>
          <Button
            testId="create-offer"
            title={t("create")}
            type="submit"
            color="success"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            size="large"
          />
        </Grid>
      </Grid>
      <Snackbar
        message={t("offers.offerSaveSuccessfully")}
        color="success"
        open={isSnackbarVisible}
        handleClose={handleClose}
        data-testid="snackbar-offer-success"
      />
    </Form>
  );
}

export default OfferForm;
