import intersection from "lodash/intersection";
import { AddressComponent } from "../../types/be/place";
import { route } from "../../utils/url";
import { PlaceAddress } from "./edit/general/types";
import { PropertyLocation } from "../../types/be/property";
import { ReleaseConfiguration as BEReleaseConfiguration } from "../../types/be/releaseFlow";
import { TableCell } from "../../types/table";
import { FormikErrors } from "formik";
import { AreaRenter } from "../../types/be/area";
import { FORM_SELECT_ALL_OPTION_ID, MONTHS } from "../../constants";
import { PaidUnpaidFilter } from "./types";
import { Status } from "../../types/be/status";

export const alignment = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
} as Record<any, string>;
/* istanbul ignore next */
export const placeErrorText = (
  errors: FormikErrors<Partial<PropertyLocation>>
): string => {
  if (!errors) return "";
  const placeErrors = intersection(Object.keys(errors), [
    "country_code",
    "place_id",
    "state_code",
    "house_number",
    "city",
    "street",
  ]);
  if (placeErrors.length) {
    const countryCityStreetHouse = intersection(placeErrors, [
      "country_code",
      "house_number",
      "city",
      "street",
    ]).map((item) => {
      switch (item) {
        case "country_code":
          return "country";
        case "house_number":
          return "house number";
        case "city":
          return "city";
        default:
          return item;
      }
    });
    const isOrAre = countryCityStreetHouse.length > 1 ? " are" : " is";
    return countryCityStreetHouse.join(" ") + isOrAre + " required";
  }
  return "";
};

export const initPlaceAddressData = {
  place_id: undefined,
  street: undefined,
  house_number: undefined,
  zip_code: undefined,
  city: undefined,
  state_code: undefined,
  state: undefined,
  country_code: undefined,
};

/* istanbul ignore next */
export const mapAddressComponentsToPlaceAddress = (
  addressComponents: AddressComponent[]
): PlaceAddress => {
  const result: PlaceAddress = { ...initPlaceAddressData };
  addressComponents.forEach((item: AddressComponent) => {
    if (item.types.includes("street_number"))
      result.house_number = item.short_name;
    if (item.types.includes("route")) result.street = item.short_name;
    if (item.types.includes("locality")) result.city = item.short_name;
    if (item.types.includes("administrative_area_level_1")) {
      result.state_code = item.short_name;
      result.state = item.long_name;
    }
    if (item.types.includes("country")) result.country_code = item.short_name;
    if (item.types.includes("postal_code")) result.zip_code = item.short_name;
  });
  return result;
};

export const getReleaseRoute = (
  path: string,
  propertyId?: number,
  releaseId?: number
): string => {
  const propertyPathArr = ["", "properties."];
  return route(
    `${propertyPathArr[Number(Boolean(propertyId))]}${path}`,
    ...[propertyId, releaseId].filter((el) => el)
  );
};

export const RELEASE_BUTTONS_FR = "release_buttons_fr";
export const RELASE_BUTTONS_AM = "release_buttons_am";
export const RELASE_BUTTONS_PM = "release_buttons_pm";
export const RELEASE_BUTTONS_USER = "release_buttons_user";

export const releasePermissionsLevels: Record<string, string> = {
  [RELEASE_BUTTONS_FR]: "I",
  [RELASE_BUTTONS_AM]: "II",
  [RELASE_BUTTONS_PM]: "III",
  [RELEASE_BUTTONS_USER]: "IV",
};

export const isReleaseButtonTableCell = (id: string): boolean =>
  Object.keys(releasePermissionsLevels).includes(id);

export const isActiveReleaseButtonTableCell = (
  id: string,
  configuration: BEReleaseConfiguration[] | null
): boolean =>
  Boolean(
    configuration?.find(
      ({ level: { code } }) => code === releasePermissionsLevels[id]
    )?.is_active
  );

export const findReleaseLevelName = (
  configuration: BEReleaseConfiguration[] | null,
  releaseButtonLevelName: string
): string | undefined => {
  return configuration?.find(
    (item) =>
      item.level.code === releasePermissionsLevels[releaseButtonLevelName]
  )?.name;
};

export const hasPermissionReleaseButtonTableCell = (
  id: string,
  configuration: BEReleaseConfiguration[] | null,
  isRole: (role: string | string[]) => boolean
): boolean => {
  const configurationRoles = configuration?.find(
    ({ level: { code } }) => code === releasePermissionsLevels[id]
  )?.roles;
  if (!configurationRoles) return false;
  return isRole([...configurationRoles.map(({ code }) => code)]);
};
/* istanbul ignore next */
export const isAvailableReleaseButtonTableCell = (
  id: string,
  configuration: BEReleaseConfiguration[] | null
): boolean => {
  const active = isActiveReleaseButtonTableCell(id, configuration);
  return active;
};

/* istanbul ignore next */
export const getItemsWithActiveReleasePermissions = (
  filteredItems: TableCell[],
  configuration: BEReleaseConfiguration[] | null
): TableCell[] =>
  filteredItems
    .filter((item) => {
      let boolean;
      if (!isReleaseButtonTableCell(item.id)) {
        boolean = true;
      } else {
        boolean = isAvailableReleaseButtonTableCell(item.id, configuration);
      }
      return boolean;
    })
    .map((item) => {
      if (!isReleaseButtonTableCell(item.id)) return item;
      const itemConfiguration = configuration?.find(
        ({ level: { code } }) => code === releasePermissionsLevels[item.id]
      );
      return {
        ...item,
        label: itemConfiguration?.name || item.label,
      };
    });

/* istanbul ignore next */
export const filterNullTenants = (arr: AreaRenter[] | null): AreaRenter[] =>
  arr ? arr?.filter((renter) => renter.rent_start !== null) : [];

/* istanbul ignore next */
export const filterCurrentTenants = (
  arr: AreaRenter[] | null,
  id: number
): AreaRenter[] => {
  return arr
    ? arr?.filter((renter) => renter.rent_start !== null && renter.id !== id)
    : [];
};

export const ALL_STATUS: Status = {
  id: FORM_SELECT_ALL_OPTION_ID,
  code: "all",
  name: "All",
};

export const paidUnpaidFilter = [
  { name: "documentRelease.invoice.all", value: PaidUnpaidFilter.all },
  { name: "documentRelease.invoice.paid", value: PaidUnpaidFilter.paid },
  { name: "documentRelease.invoice.unpaid", value: PaidUnpaidFilter.unpaid },
];
/* istanbul ignore next */
const currentYear = new Date().getFullYear();
/* istanbul ignore next */
export const getFilterByYearOptions = (): { name: string; value: number }[] => {
  const result = [];
  for (let i = currentYear - 4; i <= currentYear; i++) {
    result.push({ name: i.toString(), value: i });
  }
  return result;
};
/* istanbul ignore next */
export const getFilterByMonthOptions = (): {
  name: string;
  value: number;
}[] => {
  const result: {
    name: string;
    value: number;
  }[] = [];
  MONTHS.forEach((month, i) => result.push({ name: month, value: i }));

  return result;
};

export const statusCodesToExclude = [
  "forwarded_to_releaser",
  "existing_old_contracts",
  "new",
];

export const providerTableScrollerId = 1;
