import { MouseEvent } from "react";
import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { Dispatch, SetStateAction } from "react";
import {
  Area,
  AreaCategory,
  AreaCategory as BEAreaCategory,
  AreaType as BEAreaType,
} from "../../../../types/be/area";
import { User, User as BEUser } from "../../../../types/be/user";
import {
  Area as FEArea,
  AreaActiveTenantField,
  AreaFieldType,
} from "../../../../types/fe/area";
import { Measurement_Units } from "../../../../constants";

export const SET_AREA = "SET_AREA";
export const SET_AREA_CATEGORIES = "SET_AREA_CATEGORIES";
export const SET_TENANT_USERS = "SET_TENANT_USERS";
export const SET_IS_AREA_CATEGORIES_LOADING = "SET_IS_AREA_CATEGORIES_LOADING";
export const SET_IS_TENANT_USERS_LOADING = "SET_IS_TENANT_USERS_LOADING";

export interface AreaListContextType {
  area: Area | null;
  areaFormData: any;
  areaCategories: AreaCategory[] | null;
  tenantUsers: User[] | null;
  isTenantUsersLoading: boolean;
  isAreaCategoriesLoading: boolean;
  setActiveArea: (id: number) => void;
}

export type AreaListStateType = {
  area: Area | null;
  areaCategories: AreaCategory[] | null;
  isAreaCategoriesLoading: boolean;
  isTenantUsersLoading: boolean;
  tenantUsers: User[] | null;
  areaFormData: any;
};

export interface AreaListRowProps {
  row: Area;
  handleCellClick: (event: MouseEvent<HTMLTableCellElement>) => void;
  setSendCredentialsSnackbar: Dispatch<SetStateAction<SnackbarMessage>>;
  additionalFields: AreaActiveTenantField[];
  openConfirmToRemoveModal: (row: FEArea) => void;
  optionsDict: OptionsDict;
  fetchAreas: (params: string) => void;
  refreshAreaList: () => void;
  isTenant: boolean;
}

export interface TableCellNumberProps {
  unit?: string;
  children?: number;
  sx?: SxProps<Theme>;
  isActive: boolean;
}

export interface TableCellSelectProps<T> {
  name: string;
  options: T;
  children?: number;
  sx?: SxProps<Theme>;
  isActive: boolean;
}

export type OptionsDict = Record<
  string,
  BEUser[] | BEAreaType[] | BEAreaCategory[] | null
>;

export type TypeDict = Record<AreaFieldType, JSX.Element>;

export interface AreaTableCellProps<T> {
  options: T;
  type: AreaFieldType;
  name: string;
  unit?: Measurement_Units.EURO | Measurement_Units.SQUARE_METER | " ";
  sx?: SxProps<Theme>;
  isActive: boolean;
}

export interface CreateTenantCellProps {
  onClick: () => void;
  isRented: boolean;
  isActive: boolean;
}

export interface SnackbarMessage {
  color: "success" | "error";
  text: string;
  visible: boolean;
}

export interface ActionsTableCellProps {
  row: FEArea;
  handleCellClick: (event: React.MouseEvent<HTMLTableCellElement>) => void;
  handleSendCredentials: () => Promise<void>;
  setIsShowCommentsModal: Dispatch<
    SetStateAction<{
      isVisible: boolean;
      rowId: number;
    }>
  >;
  openConfirmToRemoveModal: (row: FEArea) => void;
}
