import { spacing } from "@mui/system";
import styled from "styled-components";
import FormAlert from "../../../../ui/formAlert/FormAlert";

export const ImageContainer = styled.div`
  width: ${(props) => props.theme.spacing(25)};
  height: ${(props) => props.theme.spacing(25)};
  position: relative;

  .removeIcon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background: ${(props): string => props.theme.palette.red[400]};
  }
`;

export const ReadingImage = styled.img`
  width: ${(props) => props.theme.spacing(25)};
  height: ${(props) => props.theme.spacing(25)};
`;

export const AddImageButton = styled.label`
  width: ${(props) => props.theme.spacing(25)};
  height: ${(props) => props.theme.spacing(25)};
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const LoadingBox = styled.div`
  width: 100%;
  height: 50vh;
`;

export const ErrorBox = styled(FormAlert)(spacing);

export const HiddenInput = styled("input")({
  display: "none",
});