import { FormikValues } from "formik";
import { createGeneralDocument } from "../../api/generalDocuments";
import { SOMETHING_WENT_WRONG_ERROR } from "../../constants";
import { HTTP_STATUS_CODES } from "../../types/server";
import { route } from "../../utils/url";
import {
  HandleGeneralDocumentCreateProps,
  GeneralDocumentCreateInitialValuesType,
  SnackbarMessage,
} from "./types";

export const generalDocumentCreateInitialValues = {
  file: null,
};
/* istanbul ignore next */
export const handleGeneralDocumentCreate = ({
  setSendCredentialsSnackbar,
  t,
  navigate,
  property_id,
}: HandleGeneralDocumentCreateProps) => {
  return async (
    data: GeneralDocumentCreateInitialValuesType,
    { setStatus, setSubmitting }: FormikValues
  ): Promise<void> => {
    const formData = new FormData();
    data?.file && formData.append("file", data?.file);
    property_id && formData.append("property_id", property_id);
    const response = await createGeneralDocument(formData);
    if (
      [HTTP_STATUS_CODES.CREATED, HTTP_STATUS_CODES.OK].includes(
        response.status
      )
    ) {
      setStatus({
        success: true,
        errors: {},
      });
      navigate(
        property_id
          ? route("properties.general-documents", property_id)
          : route("general-documents")
      );
      setSendCredentialsSnackbar({
        visible: true,
        text: t("generalDocuments.create.generalDocumentCreatedSuccessfully"),
        color: "success",
      });
    } else {
      setSendCredentialsSnackbar({
        visible: true,
        text: t(SOMETHING_WENT_WRONG_ERROR),
        color: "error",
      });
      setSubmitting(false);
    }
  };
};

export const sendCredentialsSnackbarInitState: SnackbarMessage = {
  text: "",
  visible: false,
  color: "success",
};
