import Table from "../../../../../ui/table/Table";
import useTable from "../../../../../ui/table/useTable";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Search as SearchIcon } from "react-feather";
import {
  Box,
  Button,
  DialogTitle,
  Grid,
  TableCell,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { usersItemsListHeadCells } from "../utils";
import { getOpenCommentsCountPerUser } from "../../../../../api/comments";
import { handleServerError } from "../../../../../utils/http";
import {
  Search,
  SearchIconWrapper,
  Input,
} from "../../../../../ui/table/styled";
import { Dialog } from "../../styled";
import { DialogContent, DialogTitleContainer } from "../../../styled";
import CloseButton from "../../../../../ui/dialog/CloseButton";
import CommentsList from "../CommentsList";
import { OpenCommentsUsersListWidget } from "../../../../../types/be/comment";

const UsersList = (): ReactElement => {
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleRowRemove,
  } = useTable<OpenCommentsUsersListWidget>();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [userId, setUserId] = useState<number | null>(null);
  const { t } = useTranslation();

  const handleOpenDialog = (id: number): void => {
    setUserId(id);
    setDialogVisible(true);
  };

  const handleCloseDialog = (): void => {
    setDialogVisible(false);
    setUserId(null);
  };

  const fetchComments = useCallback(async () => {
    setIsLoading(true);
    const res = await getOpenCommentsCountPerUser(queryParams);
    if (res.status !== 200) {
      const { errorMessage } = handleServerError(res);
      setError(errorMessage);
    } else {
      const resJson = await res.json();
      setTotal(resJson.meta.total);
      setData(resJson.data);
      setIsLoading(false);
    }
  }, [queryParams, setIsLoading, setTotal, setData, setIsLoading]);

  useEffect(() => {
    if (!queryParams) return;
    fetchComments();
  }, [queryParams]);

  const tableToolbar = (
    <>
      <Grid container sx={{ pt: 5, top: -65 }} position="absolute">
        <Grid item xs />
        <Grid item>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input placeholder={t("search")} onChange={handleTableSearch} />
          </Search>
        </Grid>
      </Grid>
    </>
  );

  const renderRow = ({
    id,
    first_name,
    last_name,
    comments_count,
  }: OpenCommentsUsersListWidget): ReactElement => {
    return (
      <>
        <TableCell>{`${first_name} ${last_name}`}</TableCell>
        <TableCell style={{ width: "1000px" }}></TableCell>
        <TableCell>
          <Box
            onClick={() => {
              handleOpenDialog(id);
            }}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {comments_count}
          </Box>
        </TableCell>
      </>
    );
  };

  const customTableToolbar = (
    <Grid container mb={2}>
      <Grid item xs={3} lg={2} sm={6}>
        <Button variant="outlined" color="success" onClick={handleCloseDialog}>
          {t("prevStep")}
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onRowRemove={handleRowRemove}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={usersItemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={tableToolbar}
      />
      <Dialog
        fullWidth
        open={dialogVisible}
        onClose={handleCloseDialog}
        maxWidth="xl"
      >
        <DialogTitle>
          <DialogTitleContainer>
            <Typography
              variant="h6"
              component="div"
              justifyContent="space-around"
              width="100%"
            >
              {t("Comments")}
            </Typography>
            <CloseButton onClick={handleCloseDialog} />
          </DialogTitleContainer>
        </DialogTitle>
        <DialogContent>
          <div>
            {userId && (
              <CommentsList
                tableToolbar={customTableToolbar}
                userId={userId}
                editableStatus={false}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UsersList;
