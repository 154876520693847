import styled from "styled-components";
import Button from "../../../ui/button/Button";
import { blue } from "@mui/material/colors";
import { Badge, TableCell as MuiTableCell } from "@mui/material";
/* istanbul ignore next */
export const NavButton = styled(Button)`
  font-size: ${(props): string => props.theme.typography.fontSize};
  line-height: 26px;
  cursor: pointer;
  background: ${(props) => (props.disabled ? blue[700] : "transparent")};
  font-weight: 500;
  color: ${(props) =>
    props.disabled ? "white" : props.theme.tabs.textColor} !important;
  padding: ${(props): string => props.theme.spacing(1.25, 3)};
  border-radius: ${(props) => props.theme.spacing(1)};
  border: 0;
  font-size: ${(props) => props.theme.spacing(3.25)};
`;
/* istanbul ignore next */
export const MessageBadgeCounter = styled(Badge)`
  cursor: pointer;
`;
/* istanbul ignore next */
export const TableCell = styled(MuiTableCell)`
  white-space: nowrap;
`;
