import { FESollKonto } from "../components/properties/invoices/apidatev/debitAccount/create/types";
import { IdPropType } from "../types/models";
import http from "../utils/http";

export const getDebitAccount = async (params: string): Promise<Response> => {
  return await http.get(`/api/datev/soll-kontos${params}`);
};

export const createDebitAccount = async (
  payload: FESollKonto
): Promise<Response> => {
  return await http.post(`/api/datev/soll-kontos`, payload);
};

export const deleteDebitAccount = async ({
  id,
}: IdPropType): Promise<Response> => {
  return await http.delete(`/api/datev/soll-kontos/${id}`);
};

export const deleteAllDebitAccount = async (): Promise<Response> => {
  return await http.delete(`/api/datev/soll-kontos`);
};
