import { Dispatch, SetStateAction } from "react";
import { FetchState } from "../../hooks/useFetch";
import {
  FileTreeFile as BEFileTreeFile,
  FileTreeFile,
  FileTreeFolder as BEFileTreeFolder,
  FileTreeFolder,
  FileTreeItem as BEFileTreeItem,
} from "../../types/be.interfaces";
import { ActionMap } from "../../types/fe/context";

export type FileManagementItem = "folder" | "file";

export type FileManagementMode =
  | "rename"
  | "delete"
  | "move"
  | "create"
  | "upload"
  | "download";

export interface FileManagementFolderContainerProps {
  folder: BEFileTreeFolder | null;
  handleDoubleClick: (id: string, name: string) => () => void;
  onRightClick: (
    id: string,
    name: string,
    itemType: FileManagementItem,
    url: string | null,
    canRemove?: boolean,
    hasRelation?: boolean
  ) => (event: React.MouseEvent<Element, MouseEvent>) => void;
  actionItemId: string | null;
  setActionItemId: Dispatch<SetStateAction<string | null>>;
  setActionItemUrl: Dispatch<SetStateAction<string | null>>;
  setActionItemType: Dispatch<SetStateAction<FileManagementItem>>;
  actionItemType: FileManagementItem;
  handleFile: <T extends File>(file: FileList | T[] | null) => Promise<void>;
  open: boolean;
  handleCloseDropzone: () => void;
  isDropzoneLoading: boolean;
  setIsDropzoneVisible: Dispatch<SetStateAction<boolean>>;
  handleContextMenuDeleteClick: () => void;
  handleContextMenuRenameClick: () => void;
  handleContextMenuMoveToClick: () => void;
  handleContextMenuCreateFolderClick: () => void;
  handleContextMenuDownloadClick: () => void;
  handleContextMenuUploadFileClick: () => void;
  layout: PageViewTypes;
  handleFolderClick: (id: string, isIconClicked: string) => Promise<void>;
}

export interface FileManagementFileTreeProps {
  folders: BEFileTreeFolder[] | null;
  handleFolderClick: (id: string, name: string) => void;
  selected: string;
  expanded: string[];
  isLoading?: boolean;
  onRightClick: FileManagementFolderContainerProps["onRightClick"];
}

export interface FileManagementContextMenuProps {
  contextMenuTop: number;
  contextMenuLeft: number;
  onClose: () => void;
  contextMenuClickedOnItem: boolean;
  disabledDelete: boolean;
  handleContextMenuDeleteClick: () => void;
  handleContextMenuRenameClick: () => void;
  handleContextMenuMoveToClick: () => void;
  handleContextMenuCreateFolderClick: () => void;
  handleContextMenuDownloadClick: () => void;
  handleContextMenuUploadFileClick: () => void;
  actionItemType: FileManagementItem;
  hasRelation: boolean;
}

export interface TooltipProps {
  $actionItem?: boolean;
}

export interface FileManagementDialogProps
  extends Omit<HandleItemRenameOrCreateSubmitProps, "type"> {
  dialogVisible: boolean;
  actionItemId: string | null;
  actionItemName: string;
  actionItemType: FileManagementItem;
  setActionItemName: Dispatch<SetStateAction<string>>;
  folders: FileTreeFolder[] | null;
  runFolder: (promise: Promise<Response>) => Promise<void>;
  setDialogVisible: Dispatch<SetStateAction<boolean>>;
}

export interface HandleItemRenameOrCreateSubmitProps {
  selected: string;
  type: FileManagementItem;
  mode: FileManagementMode;
  handleCloseDialog: (refresh?: boolean) => () => Promise<void>;
  setFolders: Dispatch<SetStateAction<FetchState<FileTreeFolder[]>>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
  runFolder: (promise: Promise<Response>) => Promise<void>;
}

export interface ContextMenuProperties {
  contextMenuVisibility: boolean;
  contextMenuTop: number;
  contextMenuLeft: number;
}

export interface FileManagementSearchProps {
  setExpanded: Dispatch<SetStateAction<string[]>>;
  setSelected: Dispatch<SetStateAction<string>>;
  setFolderId: Dispatch<SetStateAction<string>>;
}

export enum HandleExpandedTypes {
  INCLUDE_CURRENT = "include-current",
  EXCLUDE_CURRENT_AND_CHILDREN = "exclude-current-and-children",
  EXCLUDE_CHILDREN = "exclude-children",
}

export interface PreviewFile extends BEFileTreeFile {
  previewUrl: string;
}

export const SET_IS_DIALOG_VISIBLE = "SET_IS_DIALOG_VISIBLE";
export const SET_PREVIEW_FILE = "SET_PREVIEW_FILE";
export const SET_VISIBILITY = "SET_VISIBILITY";
export const SET_OPTIMIZED = "SET_OPTIMIZED";
export const SET_PRELOADING = "SET_PRELOADING";
export const SET_IS_FILE_LOADING = "SET_IS_FILE_LOADING";

export enum PreviewDialogVisibility {
  HIDDEN = "hidden",
  UNSET = "unset",
}

export interface FileManagementFolderContainerContextState {
  isDialogVisible: boolean;
  previewFile: PreviewFile | null;
  visibility: PreviewDialogVisibility;
  optimized: boolean;
  preloading: boolean;
  isFileLoading: boolean;
}

export type FileManagementFolderContainerActionTypes = {
  [SET_IS_DIALOG_VISIBLE]: FileManagementFolderContainerContextState["isDialogVisible"];
  [SET_PREVIEW_FILE]: FileManagementFolderContainerContextState["previewFile"];
  [SET_VISIBILITY]: FileManagementFolderContainerContextState["visibility"];
  [SET_OPTIMIZED]: FileManagementFolderContainerContextState["optimized"];
  [SET_PRELOADING]: FileManagementFolderContainerContextState["preloading"];
  [SET_IS_FILE_LOADING]: FileManagementFolderContainerContextState["isFileLoading"];
};

export interface FileManagementFolderContainerContextType {
  state: FileManagementFolderContainerContextState;
  dispatch: Dispatch<
    ActionMap<FileManagementFolderContainerActionTypes>[keyof ActionMap<FileManagementFolderContainerActionTypes>]
  >;
}

export interface FileManagementPreviewProps {
  handleCloseDialog: () => void;
  handleDownloadFromPreview: () => void;
}

export enum PageViewTypes {
  LIST = "list",
  ICONS = "icons",
}

export type FileManagementListViewItem = BEFileTreeItem &
  Omit<Partial<BEFileTreeFolder>, "id"> &
  Omit<Partial<BEFileTreeFile>, "id">;

export interface FileManagementViewButtonsProps {
  layout: PageViewTypes;
  setLayout: Dispatch<SetStateAction<PageViewTypes>>;
}

export interface FileManagementContextType {
  runFolder: (promise: Promise<Response>) => Promise<void>;
  setFolders: Dispatch<SetStateAction<FetchState<FileTreeFolder[]>>>;
  isFolderLoading: boolean;
  runFolderSortedByDate: (folderId?: string | undefined) => Promise<void>;
  layout: PageViewTypes;
  setLayout: Dispatch<SetStateAction<PageViewTypes>>;
  expanded: string[];
  setExpanded: Dispatch<SetStateAction<string[]>>;
  selected: string;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  isFoldersLoading: boolean;
  cachedFolders: FileTreeFolder[] | null;
  actionItemId: string | null;
  setActionItemId: React.Dispatch<React.SetStateAction<string | null>>;
  actionItemType: FileManagementItem;
  setActionItemType: React.Dispatch<React.SetStateAction<FileManagementItem>>;
  setActionItemUrl: React.Dispatch<React.SetStateAction<string | null>>;
  memoedFolder: FileTreeFolder | null;
  isDropzoneVisible: boolean;
  isDropzoneLoading: boolean;
  setIsDropzoneVisible: React.Dispatch<React.SetStateAction<boolean>>;
  contextMenuTop: number;
  contextMenuLeft: number;
  contextMenuVisibility: boolean;
  isConfirmToRemoveModalOpen: boolean;
  dialogVisible: boolean;
  setDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseDialog: (refresh?: boolean) => () => Promise<void>;
  handleConfirmToRemoveModalClose: () => void;
  onClose: () => void;
  contextMenuClickedOnItem: boolean;
  disabledDelete: boolean;
  hasRelation: boolean;
  mode: FileManagementMode;
  actionItemName: string;
  setActionItemName: React.Dispatch<React.SetStateAction<string>>;
  isSnackbarVisible: boolean;
  showErrorSnackbar: boolean;
  handleCloseSnackbar: () => void;
  handleCloseDropzone: () => void;
  handlePageContextMenu: (event: React.MouseEvent<Element, MouseEvent>) => void;
  handleFolderClick: (id: string, isIconClicked: string) => Promise<void>;
  onRightClick: (
    id: string,
    name: string,
    itemType: FileManagementItem,
    url: string | null,
    canRemove?: boolean,
    hasRelation?: boolean
  ) => (event: React.MouseEvent<Element, MouseEvent>) => void;
  handleDoubleClick: (id: string, name: string) => () => void;
  handleFile: <T extends File>(file: FileList | T[] | null) => Promise<void>;
  handleContextMenuDeleteClick: () => void;
  handleContextMenuRenameClick: () => void;
  handleContextMenuMoveToClick: () => void;
  handleContextMenuCreateFolderClick: () => void;
  handleContextMenuDownloadClick: () => void;
  handleContextMenuUploadFileClick: () => void;
  handleItemRemove: () => Promise<void>;
}

export interface ListViewProps {
  handleFileDoubleClick: ({
    file,
    dispatch,
    handleOpenDialog,
  }: {
    file: FileTreeFile;
    dispatch: FileManagementFolderContainerContextType["dispatch"];
    handleOpenDialog: () => void;
  }) => () => Promise<void>;
  handleOpenDialog: () => void;
}
