import React, { ReactElement } from "react";
import { Grid, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OwnershipTypeFilterProps } from "./types";
import { TextField } from "./styled";
import { OwnershipType } from "../../../../types/be/companyOwnership";

const OwnershipTypeFilter = ({
  ownershipTypes,
  setOwnershipTypeId,
  ownershipTypeId,
}: OwnershipTypeFilterProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" columns={12}>
      <Grid item xs={12} minWidth={200} maxHeight={36}>
        <TextField
          name="ownership_type_id"
          fullWidth
          select
          size="small"
          value={ownershipTypeId}
          onChange={(e) => setOwnershipTypeId(parseInt(e.target.value))}
          inputProps={{
            "aria-label": "ownership-type-select",
          }}
        >
          <MenuItem value={0} selected>
            {t("userActivity.allTypes")}
          </MenuItem>
          {ownershipTypes.map(({ id, name }: OwnershipType) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default OwnershipTypeFilter;
