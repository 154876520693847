import React, { createContext, ReactElement, useEffect, useMemo } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { Property as BEProperty } from "../../../types/be/property";
import { User as BEUserModel } from "../../../types/models";
import { Company as BECompany, User as Caretaker } from "../../../types/models";
import { getProperty } from "../../../api/property";
import { fetchUsersByRoleId } from "../../../api/roles";
import { prepareQueryParams } from "../../../utils/common";
import {
  COMPANY_TYPE_ID_PROPERTY_MANAGEMENT,
  PAGE_LIMIT,
  PERMISSION_CODE_PARAM,
  UserPermissions,
  UserRoles,
} from "../../../constants";
import { getUsers } from "../../../api/users";
import { useParams } from "react-router-dom";
import { ObjectDataSheetContextType } from "./types";
import {
  fetchPropertyManagementCompanies,
  fetchUsersByCompanyId,
} from "../../../api/companies";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";

const ObjectDataSheetContext = createContext<ObjectDataSheetContextType>(
  undefined!
);

/* istanbul ignore next */
function ObjectDataSheetContextProvider({
  children,
}: {
  children: ReactElement;
}): React.ReactElement {
  const { id } = useParams();

  const useFetchProperty = useFetch<BEProperty>();
  const useFetchCaretakers = useFetch<Caretaker[]>();
  const useFetchAllAssetManagers = useFetch<BEUserModel[]>();
  const useFetchCompanies = useFetch<BECompany[]>();
  const useFetchUsers = useFetch<BEUserModel[]>();
  const {
    propertyBreadcrumbs,
    isPropertyBreadcrumbsLoading,
    handleFetchPropertyBreadcrumbs,
  } = useBreadcrumbs();

  useEffect(() => {
    useFetchProperty.run(getProperty(id));
    useFetchCaretakers.run(fetchUsersByRoleId(UserRoles.CARETAKER));
  }, [id]);

  useEffect(() => {
    useFetchProperty.data?.company?.id &&
      useFetchUsers.run(
        fetchUsersByCompanyId(useFetchProperty.data?.company?.id)
      );
  }, [useFetchProperty.data?.company]);

  useEffect(() => {
    const { data: property } = useFetchProperty;
    if (!property) return;

    const { asset_managers, supervisor, company } = property;

    const asset_managers_ids = asset_managers.map((el) => el.id);

    const assetManagersParams = prepareQueryParams(
      "",
      {
        limit: PAGE_LIMIT._10,
        [PERMISSION_CODE_PARAM]: UserPermissions.PROPERTY_ASSET_MANAGER,
      },
      false,
      [...asset_managers_ids, supervisor?.id]
    );
    useFetchAllAssetManagers.run(getUsers(assetManagersParams));

    const companiesParams = prepareQueryParams(
      "",
      {
        limit: PAGE_LIMIT._10,
        company_type_id: COMPANY_TYPE_ID_PROPERTY_MANAGEMENT,
      },
      false,
      [company?.id]
    );
    useFetchCompanies.run(fetchPropertyManagementCompanies(companiesParams));
  }, [useFetchProperty.data]);

  const objectDataSheetContext: ObjectDataSheetContextType = useMemo(
    () => ({
      useFetchProperty,
      useFetchCaretakers,
      useFetchAllAssetManagers,
      useFetchCompanies,
      useFetchUsers,
      propertyBreadcrumbs,
      isPropertyBreadcrumbsLoading,
      handleFetchPropertyBreadcrumbs,
    }),
    [
      useFetchProperty,
      useFetchCaretakers,
      useFetchAllAssetManagers,
      useFetchCompanies,
      useFetchUsers,
      propertyBreadcrumbs,
      isPropertyBreadcrumbsLoading,
      handleFetchPropertyBreadcrumbs,
    ]
  );

  return (
    <ObjectDataSheetContext.Provider value={objectDataSheetContext}>
      {children}
    </ObjectDataSheetContext.Provider>
  );
}

export { ObjectDataSheetContextProvider, ObjectDataSheetContext };
