import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Input, Search, SearchIconWrapper } from "./styled";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { SearchItem as FESearchItem } from "../../types/fe.interfaces";
import { Autocomplete, CircularProgress, Box } from "@mui/material";
import { route } from "../../utils/url";
import { useNavigate } from "react-router";
import { getSearch } from "../../api/search";
import {
  FileTreeEnum,
  GENERAL_SEARCH_PARAM_MIN_CHARACTERS,
} from "../../constants";
import {
  composeOptions,
  contactsSearchKey,
  propertiesSearchKey,
} from "./utils";
import debounce from "lodash/debounce";
import useIsMounted from "../../hooks/useIsMounted";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import useAppDispatch from "../../hooks/useAppDispatch";
import {
  setGlobalSearchFileLastFolderId,
  setGlobalSearchFilePath,
  setGlobalSearchSelectedFolder,
} from "../../redux/slices/globalSearch";
import { ThemeContext } from "styled-components";
import { prepareQueryParams } from "../../utils/common";
import { StyledPaper } from "../contacts/autocomplete/styled";

function NavbarSearch(): ReactElement {
  const {
    palette: { textColor, primary },
  } = useContext(ThemeContext);
  const dispatchStore = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [options, setOptions] = useState<readonly FESearchItem[] | null>(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleLinkClick = (routeName: string, id: number): void =>
    navigate(route(routeName, id));

  const filePath = (foldersArray: number[]): string[] => {
    return foldersArray.reverse().map((id: number) => FileTreeEnum.FOLDER + id);
  };

  const handleClickMenuItem =
    (routeName: string, id: number, parent_folders?: number[]) => () => {
      parent_folders?.length &&
        dispatchStore(
          setGlobalSearchFileLastFolderId(String(parent_folders[0]))
        );
      parent_folders?.length &&
        dispatchStore(
          setGlobalSearchSelectedFolder(FileTreeEnum.FOLDER + parent_folders[0])
        );
      parent_folders?.length &&
        dispatchStore(setGlobalSearchFilePath(filePath(parent_folders)));
      handleLinkClick(routeName, id);
      handleClose();
    };

  const handleClose = (): void => setOpen(false);
  const handleOpen = (): void => setOpen(true);
  const resetOptions = (): void => setOptions(null);
  /* istanbul ignore next */
  const onSearchFieldTextChange = useCallback(
    debounce(async (search: string): Promise<void> => {
      if (search.length < GENERAL_SEARCH_PARAM_MIN_CHARACTERS) {
        resetOptions();
      } else {
        isMount() && (await handleServerResponse(search));
      }
    }, 500),
    [getSearch]
  );

  const handleServerResponse = async (search: string): Promise<void> => {
    setIsLoading(true);
    const params = prepareQueryParams("", {
      search,
    });
    const response = await getSearch(params);
    response.status === 200 &&
      setOptions(composeOptions((await response.json()).data));
    setIsLoading(false);
  };

  const isMount = useIsMounted();

  useEffect(() => {
    !open && resetOptions();
  }, [open]);

  return (
    <>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <Autocomplete
          id="grouped-demo"
          open={open}
          loading={isLoading}
          ListboxProps={{ style: { maxHeight: "70vh" } }}
          onOpen={handleOpen}
          onClose={handleClose}
          options={options || []}
          groupBy={(option) => t(option.type)}
          getOptionLabel={(option) => option.name}
          noOptionsText={!options ? t("startTyping") : t("noOptions")}
          filterOptions={(options) => options}
          sx={{ width: 300 }}
          PaperComponent={StyledPaper}
          renderOption={(
            props,
            { id, type, parent_folders, ...option },
            { inputValue }
          ) => {
            const propertyQuery = ` ${option.name} ${
              option.city ||
              option.street ||
              option.house_number ||
              option.zip_code
                ? ">"
                : ""
            } ${option.city ? option.city : ""} ${
              option.street ? option.street : ""
            } ${option.house_number ? option.house_number : ""} ${
              option.zip_code ? option.zip_code : ""
            }`;
            const matches = match(
              type === "properties" ? propertyQuery : option.name,
              inputValue
            );
            const parts = parse(
              type === "properties" ? propertyQuery : option.name,
              matches
            );
            return (
              <li
                {...props}
                key={id}
                onClick={handleClickMenuItem(
                  type === propertiesSearchKey
                    ? "properties.edit.main"
                    : type === contactsSearchKey
                    ? "contacts.edit.first-tab"
                    : "file-management",
                  id,
                  parent_folders
                )}
              >
                <div>
                  {parts.map((part, index) => (
                    <Box
                      component="span"
                      key={index}
                      sx={{
                        fontWeight: part.highlight ? 700 : 400,
                        color: part.highlight
                          ? primary.main
                          : textColor.default,
                      }}
                    >
                      {part.text}
                    </Box>
                  ))}
                </div>
              </li>
            );
          }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input
                placeholder={t("search")}
                inputProps={{ ...params.inputProps }}
                onChange={(e) => onSearchFieldTextChange(e.target.value)}
                endAdornment={
                  <>
                    {isLoading && (
                      <CircularProgress color="inherit" size={20} />
                    )}
                  </>
                }
              />
            </div>
          )}
        />
      </Search>
    </>
  );
}

export default NavbarSearch;
