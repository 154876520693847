import React, { forwardRef, ForwardedRef, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AreaProps } from "../../types";
import { Formik, FormikProps } from "formik";
import { FormMessageInterface } from "../../../../../../types/form";
import { FormMessageInItState } from "../../../../../../constants";
import { handleUpdateArea, prepareForm } from "../../utils";
import * as Yup from "yup";
import useValidation from "../../../../../../hooks/useValidation";
import { Area } from "../../../../../../types/be/area";
import { updateAreaStatus } from "../../../../../../api/area";
import { HTTP_STATUS_CODES } from "../../../../../../types/server";
import ConfirmationModal from "../../../../../../ui/confirmationModal";
import Form from "./Form";
import { AreaEditContext } from "../../AreaEditContext";
import Loader from "../../../../../Loader";
/*istanbul ignore next*/
const SurfaceForm = (
  { submitCallback }: AreaProps,
  ref: ForwardedRef<FormikProps<Area>>
): React.ReactElement => {
  const { t } = useTranslation();
  const {
    immoscout_url,
    immowelt_url,
    ebay_url,
    agent_commissioned_url,
    empty_space_eur,
  } = useValidation();
  const [editMainSection, setEditMainSection] = useState(false);
  const [formMessage, setFormMessage] =
    useState<FormMessageInterface>(FormMessageInItState);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);

  const areaEditContext = useContext(AreaEditContext);
  if (
    !areaEditContext ||
    !areaEditContext.area ||
    !areaEditContext.areaTypes ||
    !areaEditContext.areaCategories ||
    !areaEditContext.assetManagers
  )
    return <Loader />;
  const { area } = areaEditContext;

  const updateStatus = async (): Promise<void> => {
    setConfirmationVisible(false);
    setUpdateLoading(true);
    const response = await updateAreaStatus(area.id, !area.is_active);
    response.status === HTTP_STATUS_CODES.OK && submitCallback();

    setUpdateLoading(false);
  };

  const afterSubmit = (): void => {
    submitCallback();
    setEditMainSection(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          ...prepareForm(area),
        }}
        onSubmit={handleUpdateArea(area, setFormMessage, afterSubmit)}
        validationSchema={
          area.active_tenant
            ? Yup.object().shape({
                active_tenant: Yup.object({
                  advertisement: Yup.object({
                    immoscout_url,
                    immowelt_url,
                    ebay_url,
                    agent_commissioned_url,
                  }),
                }),
              })
            : Yup.object().shape({ empty_space_eur })
        }
        enableReinitialize
        innerRef={ref}
      >
        {(props): React.ReactElement => (
          <Form
            formMessage={formMessage}
            updateLoading={updateLoading}
            setConfirmationVisible={setConfirmationVisible}
            areaEditContext={areaEditContext}
            editMainSection={editMainSection}
            setEditMainSection={setEditMainSection}
            {...props}
          />
        )}
      </Formik>
      <ConfirmationModal
        titleText={
          area.is_active
            ? t("property.area.edit.deactivateConfirm")
            : t("property.area.edit.activateConfirm")
        }
        visible={confirmationVisible}
        confirmText={t("yes")}
        cancelText={t("no")}
        setVisible={setConfirmationVisible}
        handleCancel={() => setConfirmationVisible(false)}
        handleConfirm={updateStatus}
      />
    </>
  );
};

export default forwardRef<FormikProps<Area>, AreaProps>(SurfaceForm);
