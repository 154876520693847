import React from "react";
import {
  PermissionsCreate,
  PermissionsRead,
  PermissionsUpdate,
} from "../../../constants";
import AccessGuard from "../../../components/guards/AccessGuard";
import PropertyWrapper from "../../../components/properties/propertyWrapper";

export default [
  {
    path: ":id/maintenance",
    element: (
      <AccessGuard permissions={[PermissionsRead.MAINTENANCE]}>
        <PropertyWrapper />
      </AccessGuard>
    ),
  },
  {
    path: ":id/maintenance/create",
    element: (
      <AccessGuard permissions={[PermissionsCreate.MAINTENANCE]}>
        <PropertyWrapper />
      </AccessGuard>
    ),
  },
  {
    path: ":id/maintenance/:maintenanceId/edit",
    element: (
      <AccessGuard permissions={[PermissionsUpdate.MAINTENANCE]}>
        <PropertyWrapper />
      </AccessGuard>
    ),
  },
  {
    path: ":id/maintenance/:maintenanceId/units",
    element: (
      <AccessGuard permissions={[PermissionsRead.MAINTENANCE]}>
        <PropertyWrapper />
      </AccessGuard>
    ),
  },
];
