/* istanbul ignore file */

import React, { ReactElement, useState } from "react";
import { FormMessageInItState } from "../../../../constants";
import { Formik } from "formik";
import Form from "./Form";
import FormAlert from "../../../../ui/formAlert/FormAlert";
import { FormWrapper } from "./styled";
import { useParams } from "react-router-dom";
import { handleUpdatePropertyAdditionalCosts } from "./utils";
import * as Yup from "yup";
import useValidation from "../../../../hooks/useValidation";
import { CostPropsTypes } from "../types";
import { mapBEPropertyToFEPropertyUpdate } from "../utils";

const Costs = ({
  property,
  updateSuccessCallback,
}: CostPropsTypes): ReactElement => {
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const { id } = useParams();
  const {
    purchase_price,
    building_in_percents,
    land_in_percents,
    property_transfer_tax,
    real_estate_agent,
    notary,
    due_dilligence,
    other,
    buffer,
  } = useValidation();
  return (
    <>
      <FormWrapper>
        {formMessage.text && (
          <FormAlert formMessage={formMessage} sx={{ mb: 2.5 }} />
        )}
        {property && (
          <Formik
            initialValues={mapBEPropertyToFEPropertyUpdate(property)}
            validationSchema={Yup.object().shape({
              purchase_price,
              building_in_percents,
              land_in_percents,
              property_transfer_tax,
              real_estate_agent,
              notary,
              due_dilligence,
              other,
              buffer,
            })}
            onSubmit={handleUpdatePropertyAdditionalCosts(
              id!,
              updateSuccessCallback,
              {
                setFormMessage,
              }
            )}
            enableReinitialize
          >
            {(props): ReactElement => <Form {...{ ...props, ...property }} />}
          </Formik>
        )}
      </FormWrapper>
    </>
  );
};

export default Costs;
