export default {
  new: "Neu",
  search: "Suchen",
  inProgress: "In Bearbeitung",
  inCoordination: "In Koordinierung",
  completed: "Fertiggestellt",
  areaName: "Flächenbezeichnung",
  createdAt: "Erstellt am",
  description: "Beschreibung",
  propertyName: "Objektname",
  ticket: "Bearbeitungsnummer",
  title: "Titel",
  tenant: "Mieter",
  status: "Status",
  manager: "Asset Manager",
  back: "Zurück",
  delete: "Löschen",
  wantToDelete: "Want to delete",
  cancel: "Abbrechen",
  update: "Aktualisieren",
  area: "Fläche",
  titleIsRequired: "Titel ist erforderlich",
  descriptionIsRequired: `Beschreibung ist erforderlich`,
  dragAndDropYourFilesHereOr: "Ziehen Sie die Dateien hierher",
  uploadFile: "Datei auswählen",
  create: "Ticket Erstellen",
  uploaded: "Hochgeladen",
  ticketNumber: "Bearbeitungsnummer",
  property: "Objekt",
  propertyIsRequired: "Objekt ist erforderlich",
  tenantIsRequired: "Tenant ist erforderlich",
  areaIsRequired: "Property area ist erforderlich",
  youNeedToSelectPropertyFirst: "Sie müssen zuerst ein Objekt auswählen",
  youNeedToSelectPropertyAreaFirst: "Sie müssen zuerst eine Fläche auswählen",
  event: "Ereignis",
  created: "Mieteranfrage wurde erstellt",
  eventData: {
    created: "erstellt",
    updatedStatusOf: "aktualisierter Stand von",
    from: "aus",
    to: "Zu",
    udpatedStatusOf: "aktualisierter Stand von",
    updatedDescriptionOf: "aktualisierte Beschreibung von",
    updatedPropertyAreaOf: "aktualisierte Grundstücksfläche von",
    udpateManagerOf: "aktualisierter Manager von",
    udpateTenantOf: "aktualisierter Mieter von",
    udpateTitleOf: "aktualisierter Titel von",
    udpatePropertyOf: "aktualisierte Eigenschaft von",
    udpateFilesOf: "aktualisierte Dateien von",
  },
  selectedAreaDoesNotHaveTenants: "Ausgewählter Bereich hat keine Mieter",
};
