import AuthGuard from "../../components/guards/AuthGuard";
import DashboardLayout from "../../layouts/dashboard";
import ContactsList from "../../components/contacts/list/ContactsList";
import CreateContact from "../../components/contacts/create/CreateContact";
import EditContact from "../../components/contacts/edit/EditContactTabs";
import React from "react";
import {
  PermissionsCreate,
  PermissionsRead,
  PermissionsUpdate,
} from "../../constants";
import AccessGuard from "../../components/guards/AccessGuard";

export default {
  path: "/:client/contacts",
  element: (
    <AuthGuard>
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: (
        <AccessGuard permissions={[PermissionsRead.CONTACT]}>
          <ContactsList />
        </AccessGuard>
      ),
    },
    {
      path: "create",
      element: (
        <AccessGuard permissions={[PermissionsCreate.CONTACT]}>
          <CreateContact />
        </AccessGuard>
      ),
    },
    {
      path: "edit/:id/:tab",
      element: (
        <AccessGuard permissions={[PermissionsUpdate.CONTACT]}>
          <EditContact />
        </AccessGuard>
      ),
    },
  ],
};
