import React, { ReactElement } from "react";
import {
  RentedInfoContainer,
  SmallLightText,
} from "../../../areas/edit/tabs/rentedForm/styled";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Edit } from "@mui/icons-material";
import { EMPTY_DATA, PermissionsUpdate } from "../../../../../constants";
import { FormWrapper } from "../propertyManagement/styled";
import useAccessControl from "../../../../../hooks/useAccessControl";
import { BankShowProps } from "./types";
import Snackbar from "../../../../../ui/Snackbar";

/* istanbul ignore next */
const BankShow = ({
  bank,
  setIsEdit,
  rentMode,
  isSnackbarVisible,
  handleClose,
}: BankShowProps): ReactElement => {
  const { t } = useTranslation();
  const { can } = useAccessControl();

  return (
    <FormWrapper>
      {can(PermissionsUpdate.PROPERTY) && (
        <Edit
          className="edit-icon"
          data-testid={`edit-icon-${rentMode ? "rent" : "loan"}`}
          onClick={() => setIsEdit(true)}
        />
      )}
      <Grid container>
        <RentedInfoContainer item md={12} mb={2}>
          <Typography variant="h3">
            {rentMode
              ? t("property.bank.rentAccount")
              : t("property.bank.loanAccount")}
          </Typography>
        </RentedInfoContainer>
        <RentedInfoContainer item md={12}>
          <Typography variant="h4">{bank?.iban || EMPTY_DATA}</Typography>
          <SmallLightText>{t("property.bank.iban")}</SmallLightText>
        </RentedInfoContainer>
        <RentedInfoContainer item md={12}>
          <Typography variant="h4">{bank?.name || EMPTY_DATA}</Typography>
          <SmallLightText>{t("property.bank.bankName")}</SmallLightText>
        </RentedInfoContainer>
        <RentedInfoContainer item md={12}>
          <Typography variant="h4">{bank?.bic || EMPTY_DATA}</Typography>
          <SmallLightText>{t("property.bank.bic")}</SmallLightText>
        </RentedInfoContainer>
        <RentedInfoContainer item md={12}>
          <Typography variant="h4">{bank?.code || EMPTY_DATA}</Typography>
          <SmallLightText>{t("property.bank.accountNumber")}</SmallLightText>
        </RentedInfoContainer>
        <RentedInfoContainer item md={12}>
          <Typography variant="h4" textAlign="center">
            {bank?.contact_person || EMPTY_DATA}
          </Typography>
          <SmallLightText>{t("property.bank.contactPerson")}</SmallLightText>
        </RentedInfoContainer>
      </Grid>
      <Snackbar
        message={t("property.bank.bankWasSuccessfullyUpdated")}
        color="success"
        open={isSnackbarVisible}
        handleClose={handleClose}
      />
    </FormWrapper>
  );
};

export default BankShow;
