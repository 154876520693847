import styled from "styled-components/macro";
import { Paper } from "@mui/material";

export const FormWrapper = styled(Paper)`
  padding-left: ${(props): string => props.theme.spacing(6)};
  ${(props): string => props.theme.breakpoints.up("lg")} {
    max-width: 80%;
  }
  ${(props): string => props.theme.breakpoints.up("xl")} {
    &.popup-wrapper {
      max-width: 90%;
    }
    max-width: 60%;
  }
  ${(props): string => props.theme.breakpoints.down("xs")} {
    max-width: 100%;
  }
`;
