import React, { ReactElement, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useTable from "../../../ui/table/useTable";
import Table from "../../../ui/table/Table";
import { OUTDATED_STATUS_ID, itemsListHeadCells } from "./utils";
import { TenantRequest } from "../../../types/be/tenantRequest";
import { Button, Grid, TableCell } from "@mui/material";
import {
  deleteTenantRequest,
  getOutdatedTenantRequests,
} from "../../../api/tenantRequest";
import { handleServerError } from "../../../utils/http";
import {
  DeleteIcon,
  Input,
  Search,
  SearchIconWrapper,
  ShowIcon,
} from "../../../ui/table/styled";
import { route } from "../../../utils/url";
import { useNavigate } from "react-router-dom";
import { Search as SearchIcon } from "react-feather";
import useIsMounted from "../../../hooks/useIsMounted";
import { PermissionsDelete } from "../../../constants";
import useAccessControl from "../../../hooks/useAccessControl";
import { prepareQueryParams } from "../../../utils/common";

const OutdatedTenantRequests = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { can } = useAccessControl();
  const {
    data,
    setData,
    total,
    setTotal,
    order,
    orderBy,
    isLoading,
    setIsLoading,
    error,
    setError,
    rowsPerPage,
    currentPage,
    handleChangeRowsPerPage,
    handleChangePage,
    rowToDelete,
    queryParams,
    isConfirmToRemoveModalOpen,
    handleConfirmToRemoveModalClose,
    openConfirmToRemoveModal,
    handleSortChange,
    handleSelectAllClick,
    handleTableSearch,
    handleDeleteLastPageData,
  } = useTable<TenantRequest>();

  const handleFetchList = useCallback(async () => {
    setIsLoading(true);
    const params = prepareQueryParams(queryParams, {
      status_id: OUTDATED_STATUS_ID,
    });
    const response = await getOutdatedTenantRequests(params);
    if (!response?.ok) {
      const { errorMessage } = handleServerError(response);
      setError(errorMessage);
    } else {
      const json = await response.json();
      setData(json?.data);
      setTotal(json?.meta?.total);
    }
    setIsLoading(false);
  }, [queryParams, getOutdatedTenantRequests]);

  useEffect(() => {
    if (isMounted()) {
      handleFetchList();
    }
  }, [queryParams]);

  const handleRowRemove = useCallback(async (): Promise<void> => {
    await deleteTenantRequest(rowToDelete.id);
    handleDeleteLastPageData(handleFetchList);
    handleConfirmToRemoveModalClose();
  }, [
    rowToDelete,
    deleteTenantRequest,
    handleFetchList,
    handleConfirmToRemoveModalClose,
  ]);

  const handleShowDetailsIconClick = (tenantRequest: TenantRequest): void => {
    return navigate(
      `${route("tenant-requests.edit", tenantRequest.id)}?isShow=true`
    );
  };

  const handleOnBackClick = (): void => {
    return navigate(route("tenant-requests"));
  };

  const TableToolbar = (
    <>
      <Grid container justifyContent={"space-between"} sx={{ pt: 5 }}>
        <Grid item>
          <Grid item>
            <Button
              data-testid={"dataItems-link"}
              size="medium"
              color="success"
              variant="contained"
              onClick={() => handleOnBackClick()}
            >
              {t("tenantRequest.back")}
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input
              size={"small"}
              placeholder={t("search")}
              onChange={handleTableSearch}
            />
          </Search>
        </Grid>
      </Grid>
    </>
  );

  const renderRow = (tenantRequest: TenantRequest): ReactElement => (
    <>
      <TableCell align="left">{tenantRequest.ticket_number}</TableCell>
      <TableCell align="center">{tenantRequest.title}</TableCell>
      <TableCell align="center">{tenantRequest.area_name}</TableCell>
      <TableCell align="center">{tenantRequest.property_name}</TableCell>
      <TableCell align="center">{tenantRequest.status.name}</TableCell>
      <TableCell align="right">
        <ShowIcon
          role={"editIconRole"}
          onClick={(): void => handleShowDetailsIconClick(tenantRequest)}
          size={18}
        />
        {can([PermissionsDelete.TENANT_REQUEST]) && (
          <DeleteIcon
            onClick={(): void => openConfirmToRemoveModal(tenantRequest)}
            size={20}
            role="deleteIconRole"
          />
        )}
      </TableCell>
    </>
  );

  const isMounted = useIsMounted();

  return (
    <>
      <Table
        data={data}
        total={total}
        currentPage={currentPage}
        order={order}
        orderBy={orderBy}
        error={error}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onRowRemove={handleRowRemove}
        onSortChange={handleSortChange}
        onSelectAllClick={handleSelectAllClick}
        onConfirmToRemoveModalClose={handleConfirmToRemoveModalClose}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        isConfirmToRemoveModalOpen={isConfirmToRemoveModalOpen}
        listHeadCells={itemsListHeadCells}
        renderRow={renderRow}
        tableToolbar={TableToolbar}
      />
    </>
  );
};

export default OutdatedTenantRequests;
