import styled from "styled-components/macro";
import { Grid } from "@mui/material";
import MUIDialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

export const StyledTabContent = styled.div`
  background: transparent;
  width: 100%;
  ${(props): string => props.theme.breakpoints.up("md")} {
    padding: ${(props): string => props.theme.spacing(0)};
    padding-top: ${(props): string => props.theme.spacing(0)};
  }
`;

export const StyledGridTabsContent = styled(Grid)``;

export const StyledGridTabs = styled(Grid)`
  margin-top: ${(props): string => props.theme.spacing(0)};
`;

export const DialogTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const DialogContent = styled(MUIDialogContent)`
  & > div {
    max-height: 80vh;
    width: 100%;
    margin-top: ${(props) => props.theme.spacing(1.5)};
  }
`;

export const DialogBox = styled(Dialog)`
  width: 100%;
  max-width: ${(props): string => props.theme.spacing(94)};
  margin: 0 auto;
`;
