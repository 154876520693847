import React, {
  HTMLAttributes,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import Loader from "../../../../Loader";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { getJson } from "../../../../../utils/http";
import { Dropdown } from "../../../../../ui/autocomplete/styled";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import { Grid, TextField } from "@mui/material";
import debounce from "lodash/debounce";
import {
  NODE_ENVIRONMENTS,
  SEARCH_PARAM_MIN_CHARACTERS,
} from "../../../../../constants";
import { User } from "../../../../../types/be/user";
import { getUsers } from "../../../../../api/users";
import {
  AutocompleteListItem,
  AutocompleteManageFullName,
  AutocompleteManagerEmail,
} from "./styled";
import { isNodeEnv } from "../../../../../utils/env";
import { DEBOUNCE_TIME } from "../../../../../utils/common";

const ManagerAutocomplete = ({
  onManagerSelect,
}: {
  onManagerSelect: (area: User | null) => void;
}): ReactElement => {
  const [isLoading, setLoading] = useState(false);
  const [manager, setManager] = useState<User | null>(null);
  const [propertyAutocompleteInputValue, setPropertyAutocompleteInputValue] =
    useState("");
  const [predictions, setPredictions] = useState<User[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const val = manager ? `${manager.first_name} ${manager.last_name}` : "";
    setPropertyAutocompleteInputValue(val);
    onManagerSelect(manager);
  }, [manager]);

  const handlePropertyTenantSelect = (manager: User): void => {
    onManagerSelect(manager);
    setManager(manager);
  };

  const handleAutocompleteOnChange = (
    _: SyntheticEvent,
    manager: User | null
  ): void => {
    if (manager) handlePropertyTenantSelect(manager);
    else {
      setManager(null);
    }
  };

  const onLocationSearchKeyDown = React.useCallback(
    debounce(
      async (search: string): Promise<void> => {
        if (search.length < SEARCH_PARAM_MIN_CHARACTERS) setPredictions([]);
        else {
          setLoading(true);
          getUsers(`?search=${search}&limit=1000`)
            .then(getJson)
            .then(({ data }) => {
              setPredictions(data);
              setLoading(false);
            })
            .catch(() => {
              setPredictions([]);
            });
        }
      },
      /* istanbul ignore next */ isNodeEnv(NODE_ENVIRONMENTS.TEST)
        ? 0
        : DEBOUNCE_TIME
    ),
    []
  );

  const handleRenderInput = (
    params: AutocompleteRenderInputParams
  ): ReactNode => (
    <TextField
      {...params}
      size={"small"}
      label={t("tenantRequest.manager")}
      onChange={(e) => {
        setPropertyAutocompleteInputValue(e.target.value);
        onLocationSearchKeyDown(e.target.value);
      }}
      placeholder={t("search")}
    />
  );

  return (
    <Autocomplete
      role={"manager-filter"}
      fullWidth
      loading={isLoading}
      loadingText={`${t("loading")}...`}
      inputValue={propertyAutocompleteInputValue}
      popupIcon={isLoading ? <Loader size={25} /> : <ArrowDropDownIcon />}
      options={predictions!}
      isOptionEqualToValue={handleIsOptionEqualToValue}
      getOptionLabel={handleGetOptionLabel}
      filterOptions={handleFilterOptions}
      onChange={handleAutocompleteOnChange}
      renderOption={handleRenderOption}
      PaperComponent={autocompletePaperComponent}
      renderInput={handleRenderInput}
    />
  );
};

export default ManagerAutocomplete;

const handleIsOptionEqualToValue = (option: User, value: User): boolean => {
  return option.id === value.id;
};

const handleGetOptionLabel = (manager: User): string => {
  return `${manager.first_name} ${manager.last_name}`;
};

const handleFilterOptions = (options: User[]): User[] => options;

const handleRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  manager: User
): ReactNode => {
  return (
    <AutocompleteListItem {...props} key={manager.id}>
      <Grid container direction={"column"}>
        <Grid item>
          <AutocompleteManageFullName>
            {manager.first_name} {manager.last_name}
          </AutocompleteManageFullName>
        </Grid>
        <Grid item>
          <AutocompleteManagerEmail>{manager.email}</AutocompleteManagerEmail>
        </Grid>
      </Grid>
    </AutocompleteListItem>
  );
};

const autocompletePaperComponent = ({
  children,
}: {
  children?: ReactNode;
}): ReactElement | null => <Dropdown>{children}</Dropdown>;
